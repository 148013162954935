import { useQuery } from '@apollo/client'
import { EuiCallOut, EuiFieldText, EuiFlexGroup, EuiFlexItem, EuiSpacer } from '@elastic/eui'
import { useDebouncedValue } from 'rooks'
import { ContactMediumRole, SearchContactsByEmailDocument } from '../api/generated-types'
import { ContactMediumRoleBadge } from './contact-medium-role-badge'

export interface ContactFormEmailFieldProps {
  contactId: string | undefined // undefined for a brand new contact
  email: string
  onChange: (value: string) => void
  showValidationErrors: boolean
  emailValid: boolean
  mustBeUnique: boolean
  disabled?: boolean
}

export const ContactFormEmailField = ({
  contactId,
  email,
  onChange,
  mustBeUnique,
  disabled,
  emailValid,
  ...props
}: ContactFormEmailFieldProps) => {
  const [debouncedEmail] = useDebouncedValue(email, 150)

  //todo move all unique and duplicate validation to this ContactDetailValidator, zod async and add safeParseAsync
  // No need to query until we have a valid email address and also don't need to query if
  // this email does not need to be unique
  const { data, error, loading } = useQuery(SearchContactsByEmailDocument, {
    skip: !debouncedEmail.length || !mustBeUnique || !emailValid,
    variables: {
      input: {
        email: debouncedEmail
      }
    }
  })

  const contacts = data?.searchContactsByEmail?.results ?? []

  const duplicates = mustBeUnique ? contacts.filter((contact) => contact.id !== contactId) : []
  const isDuplicate = duplicates.length > 0

  const showValidationErrors = props.showValidationErrors || (mustBeUnique && isDuplicate)

  return (
    <>
      <EuiFlexGroup alignItems="center" gutterSize="s">
        <EuiFlexItem grow={false} style={{ minWidth: '80%' }}>
          <EuiFieldText
            autoComplete="off"
            placeholder="Enter email..."
            icon="email"
            value={email}
            onChange={(e) => onChange(e.target.value)}
            isInvalid={showValidationErrors && (!emailValid || isDuplicate)}
            isLoading={loading}
            disabled={disabled}
          />
        </EuiFlexItem>
        <EuiFlexItem grow={false} style={{ width: '80px' }}>
          <ContactMediumRoleBadge role={ContactMediumRole.Primary} />
        </EuiFlexItem>
        <EuiFlexItem grow={true} />
      </EuiFlexGroup>
      {error && (
        <>
          <EuiSpacer size="s" />
          <EuiCallOut iconType="branchUser" title="Error checking for duplicate email" color="danger" size="s">
            {error.message}
          </EuiCallOut>
        </>
      )}
      {isDuplicate && (
        <>
          <EuiSpacer size="s" />
          <EuiCallOut
            iconType="branchUser"
            title="Email address is already in use on another contact"
            color="danger"
            size="s"
          />
        </>
      )}
    </>
  )
}
