import { useQuery, useSubscription } from '@apollo/client'
import { EuiButton, EuiFlexGroup, EuiFlexItem, EuiText } from '@elastic/eui'
import React, { useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import {
  GetActiveInteractionMessageDocument,
  InteractionMessageLinkFragment,
  InteractionMessageMutationType,
  OnUpdateInteractionMessageDocument
} from '../api/generated-types'
import { useNotification } from '../app/notification-provider'
import { useAuthenticated } from '../auth/authenticated-context'
import { formatPhone } from '../common/phone'

interface InteractionServiceProviderProps {
  children: React.ReactNode
}

export interface IInteractionService {
  activeInteractionMessage?: InteractionMessageLinkFragment
  loading: boolean
}

export const InteractionService = React.createContext<IInteractionService | undefined>(undefined)

export const InteractionServiceProvider = (props: InteractionServiceProviderProps) => {
  console.log('initializing interaction service provider')
  const notification = useNotification()

  const history = useHistory()

  const { userFragment } = useAuthenticated()

  const { data, error, loading, refetch } = useQuery(GetActiveInteractionMessageDocument, {
    variables: {
      input: {
        userId: userFragment.id
      }
    },
    fetchPolicy: 'cache-and-network'
  })

  useEffect(() => {
    console.log('interaction service provider GetActiveInteractionMessageDocument change', data, error, loading)
  }, [data, error, loading])

  // Assigned
  const { data: assignedData } = useSubscription(OnUpdateInteractionMessageDocument, {
    variables: {
      tenantId: 'fallonsolutions',
      assignedUserId: userFragment.id,
      kind: InteractionMessageMutationType.AssignInteractionMessage
    },
    shouldResubscribe: true,
    onData: ({ data }) => {
      console.log('assigned user subscription', data.data?.onUpdateInteractionMessage?.kind, data)
      refetch()
    }
  })

  // Unassigned
  const { data: unassignedData } = useSubscription(OnUpdateInteractionMessageDocument, {
    variables: {
      tenantId: 'fallonsolutions',
      previousAssignedUserId: userFragment.id,
      kind: InteractionMessageMutationType.AssignInteractionMessage
    },
    shouldResubscribe: true,
    onData: ({ data }) => {
      console.log('previous assigned user subscription', data.data?.onUpdateInteractionMessage?.kind, data)
      refetch()
    }
  })

  // Status updates or other (stats etc)
  useSubscription(OnUpdateInteractionMessageDocument, {
    variables: {
      tenantId: 'fallonsolutions',
      assignedUserId: userFragment.id
    },
    shouldResubscribe: true,
    onData: ({ data }) => {
      console.log('interaction message subscription', data.data?.onUpdateInteractionMessage?.kind, data)
      refetch()
    }
  })

  if (error) {
    console.error('InteractionServiceProvider error', error)
  }

  const activeInteractionMessage = data?.getActiveInteractionMessage.interactionMessage ?? undefined

  // Show toast on newly assigned interaction message
  const assignedInteractionMessage =
    assignedData?.onUpdateInteractionMessage?.assignedUserId === userFragment.id
      ? assignedData?.onUpdateInteractionMessage?.interactionMessage
      : undefined
  useEffect(() => {
    if (assignedInteractionMessage) {
      const contactName = assignedInteractionMessage.contact?.fullName ?? 'Unknown'
      const phoneNumber = assignedInteractionMessage.externalNumber
        ? formatPhone(assignedInteractionMessage.externalNumber)
        : 'Private number'
      const toastId = uuidv4()
      const onClickOpen = () => {
        history.push(`/interaction-message/${assignedInteractionMessage.id}`)
        notification.removeToast(toastId)
      }
      notification.createToast({
        id: toastId,
        title: 'Incoming call',
        iconType: 'email',
        color: 'success',
        toastLifeTimeMs: 10000,
        text: (
          <>
            <EuiFlexGroup justifyContent="flexEnd" alignItems="center" gutterSize="s">
              <EuiFlexItem grow={true}>
                <EuiText size="s">
                  <strong>{contactName}</strong>
                  <br />
                  {phoneNumber}
                </EuiText>
              </EuiFlexItem>

              <EuiFlexItem grow={false}>
                <EuiButton size="s" minWidth="50px" onClick={() => onClickOpen()}>
                  Open
                </EuiButton>
              </EuiFlexItem>
            </EuiFlexGroup>
          </>
        )
      })
    }
  }, [assignedInteractionMessage, userFragment.id])

  // Show toast on interaction message completion
  const unassignedInteractionMessage =
    unassignedData?.onUpdateInteractionMessage?.previousAssignedUserId === userFragment.id
      ? unassignedData?.onUpdateInteractionMessage?.interactionMessage
      : undefined
  useEffect(() => {
    if (unassignedInteractionMessage) {
      notification.createToast({
        id: uuidv4(),
        title: 'Call complete',
        iconType: 'email',
        color: 'success',
        text: `Call completed/transferred`
      })
    }
  }, [unassignedInteractionMessage, userFragment.id])

  const interactionService: IInteractionService = {
    activeInteractionMessage,
    loading
  }

  return <InteractionService.Provider value={interactionService}>{props.children}</InteractionService.Provider>
}

export const useInteractionService = () => {
  const context = useContext(InteractionService)
  if (!context) {
    throw new Error('needs InteractionService as provider')
  }
  return context
}
