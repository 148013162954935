import { useMutation } from '@apollo/client'
import {
  EuiButton,
  EuiButtonEmpty,
  EuiCallOut,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiLink,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiOverlayMask,
  EuiSpacer,
  EuiText,
  EuiTextArea
} from '@elastic/eui'
import { addressFragmentToAddressInput } from '@fallonsolutions/address'
import { useEffect, useState } from 'react'
import {
  ContactDetailFragment,
  ContactFragment,
  ContactInput,
  CreateContactDocument,
  CustomerLinkFragment,
  EditContactDocument
} from '../api/generated-types'
import { parsePhone } from '../common/phone'
import { ContactDetailForm, ContactDetailFormUpdate, createEmptyContactDetailFragment } from './contact-detail-form'
import { ContactDuplicateCheck } from './contact-duplicate-check'
import { ContactSearchProvider } from './contact-search/contact-search-provider'

export interface ContactFormV2Props {
  contact?: ContactFragment
  customer?: CustomerLinkFragment // optional customer to link contact to on create
  onClose: () => void
  duplicateCheck?: boolean
}

export const ContactFormV2 = (props: ContactFormV2Props) => {
  const { contact, customer, onClose } = props

  const duplicateCheck = props.duplicateCheck ?? false

  // Allow contactId to be changed if a duplicate is found and selected
  const [contactId, setContactId] = useState(contact?.id)
  const [detail, setDetail] = useState<ContactDetailFragment>(contact?.detail ?? createEmptyContactDetailFragment())
  const [isValid, setIsValid] = useState(true)
  const [notes, setNotes] = useState(contact?.detail?.notes ?? '')
  const [showValidationErrors, setShowValidationErrors] = useState(false)
  const [showNotes, setShowNotes] = useState((contact?.detail?.notes && contact?.detail?.notes?.length > 0) ?? false)

  const { firstName, lastName, email, address } = detail

  const [createContact, { data: createData, loading: createLoading, error: createError }] = useMutation(
    CreateContactDocument,
    {
      refetchQueries: ['SearchContacts', 'SearchContactCustomers', 'GetContact', 'GetCustomer', 'GetCustomerLink'],
      awaitRefetchQueries: true
    }
  )
  const [editContact, { data: editData, loading: editLoading, error: editError }] = useMutation(EditContactDocument, {
    refetchQueries: ['SearchContacts', 'SearchContactCustomers', 'GetContact', 'GetCustomer', 'GetCustomerLink'],
    awaitRefetchQueries: true
  })
  const loading = editLoading || createLoading
  const error = editError ?? createError

  const handleSubmit = () => {
    if (!isValid) {
      setShowValidationErrors(true)
      return
    }
    if (!firstName) {
      return
    }
    const detailInput: ContactInput = {
      firstName,
      lastName,
      email: email && email.length > 0 ? email : undefined,
      phone: parsePhone(detail.phone)?.number.toString() ?? undefined,
      alternatePhone: parsePhone(detail.alternatePhone)?.number.toString() ?? undefined,
      notes,
      ...(address && { address: addressFragmentToAddressInput(address) })
    }
    if (contactId) {
      console.log('edit contact')
      editContact({
        variables: {
          input: {
            contactId,
            detail: detailInput
          }
        }
      })
    } else {
      console.log('create contact')
      createContact({
        variables: {
          input: {
            detail: detailInput,
            customers: customer?.id
              ? [
                  {
                    customerId: customer.id,
                    roles: []
                  }
                ]
              : []
          }
        }
      })
    }
  }

  useEffect(() => {
    if (createData?.createContact?.contact || editData?.editContact?.contact) {
      onClose()
    }
  }, [createData, editData, onClose])

  const onDuplicateSelected = (contact: ContactFragment) => {
    setDetail(contact.detail ?? createEmptyContactDetailFragment())
    setNotes(contact.detail?.notes ?? '')
    setShowNotes((contact?.detail?.notes && contact?.detail?.notes?.length > 0) ?? false)
    setContactId(contact.id)
  }

  const onChangeDetail = (updateFn: ContactDetailFormUpdate) => {
    const updated = updateFn({ detail, isValid })
    console.log('onChangeDetail.isValid', updated.isValid)
    setDetail(updated.detail)
    setIsValid(updated.isValid)
  }

  return (
    <EuiOverlayMask>
      <EuiModal
        onClose={onClose}
        style={{ minHeight: '900px', maxHeight: '900px', maxInlineSize: 'calc(100vw - 300px)' }}
      >
        <EuiModalHeader>
          <EuiModalHeaderTitle>{contactId ? 'Edit' : 'Create'} contact</EuiModalHeaderTitle>
        </EuiModalHeader>
        <EuiModalBody>
          <EuiFlexGroup alignItems="flexStart" gutterSize="none">
            <EuiFlexItem style={{ paddingRight: '24px' }}>
              {error && (
                <>
                  <EuiCallOut title={`Error ${contactId ? 'editing' : 'creating'} contact`} color="danger">
                    {error.message}
                  </EuiCallOut>
                  <EuiSpacer />
                </>
              )}

              {customer && (
                <>
                  <EuiFormRow label="Customer">
                    <EuiText>{customer.number}</EuiText>
                  </EuiFormRow>
                  <EuiSpacer size="m" />
                </>
              )}

              <ContactDetailForm
                contactId={contactId}
                detail={detail}
                onChange={onChangeDetail}
                showValidationErrors={showValidationErrors}
                addressRequired={true}
              />
              <EuiSpacer size="m" />

              {showNotes ? (
                <EuiFormRow label="Notes" fullWidth>
                  <EuiTextArea value={notes} onChange={(e) => setNotes(e.target.value)} />
                </EuiFormRow>
              ) : (
                <>
                  <EuiSpacer size="m" />
                  <EuiLink onClick={() => setShowNotes(true)} style={{ fontSize: '12px' }}>
                    Add contact notes
                  </EuiLink>
                  <EuiSpacer />
                </>
              )}
            </EuiFlexItem>

            {duplicateCheck && (
              <ContactSearchProvider>
                <EuiFlexItem style={{ width: '540px' }}>
                  <div className="eui-yScroll" style={{ maxHeight: '700px' }}>
                    <ContactDuplicateCheck detail={detail} onDuplicateSelected={onDuplicateSelected} />
                  </div>
                </EuiFlexItem>
              </ContactSearchProvider>
            )}
          </EuiFlexGroup>
        </EuiModalBody>
        <EuiModalFooter>
          {!loading && (
            <EuiButtonEmpty onClick={onClose} disabled={loading}>
              Cancel
            </EuiButtonEmpty>
          )}
          <EuiButton fill onClick={() => handleSubmit()} isLoading={loading}>
            {contactId ? 'Edit' : 'Create'} contact
          </EuiButton>
        </EuiModalFooter>
      </EuiModal>
    </EuiOverlayMask>
  )
}
