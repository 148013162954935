import { EuiAvatar, EuiFlexGroup, EuiFlexItem, EuiSpacer, EuiText } from '@elastic/eui'
import { ContactDetailFragment } from '../api/generated-types'
import { formatPhone } from '../common/phone'
import { ContactDetailMatch } from './contact-list-card-view'

export interface ContactCardDetailProps {
  detail: ContactDetailFragment
  showAvatar?: boolean
  reference?: string
  addressDisplay?: ContactCardAddressDisplay
  showDataQuality?: boolean
  showHubspot?: boolean
  showDataQualityFooter?: boolean
  matchScore?: ContactDetailMatch | undefined
}

export enum ContactCardAddressDisplay {
  None = 'none',
  Suburb = 'suburb',
  SuburbLabel = 'suburb-label',
  FullAddress = 'full-address'
}

//
//temporary contact card without edit functions like data quality for interaction message
//
export const ContactDetailCard = (props: ContactCardDetailProps) => {
  const { detail, reference } = props

  const showAvatar = props.showAvatar ?? true
  const addressDisplay = props.addressDisplay ?? ContactCardAddressDisplay.FullAddress
  const fullName = detail?.fullName ?? 'No name'
  const formattedAddress = getAddressString(detail, addressDisplay)
  const formattedPhone = formatPhone(detail?.phone || '')
  const formattedAlternatePhone = formatPhone(detail?.alternatePhone || '')

  return (
    <EuiFlexGroup gutterSize="none">
      <EuiFlexItem grow={true}>
        <EuiFlexGroup gutterSize="s">
          {showAvatar && (
            <EuiFlexItem grow={false}>
              <EuiAvatar size="l" name={fullName} />
              <EuiSpacer size="s" />
              <EuiFlexGroup gutterSize="none" alignItems="flexEnd"></EuiFlexGroup>
            </EuiFlexItem>
          )}
          <EuiFlexItem>
            <EuiText size="s">
              <div>
                <strong>{fullName}</strong>
              </div>
              {reference && <div>{reference}</div>}

              <div>{detail?.phone ? <a href={`tel:${detail?.phone}`}>{formattedPhone}</a> : 'No phone'}</div>
              <div>
                {detail?.alternatePhone && detail?.alternatePhone !== detail?.phone ? (
                  <a href={`tel:${detail?.alternatePhone}`}>{formattedAlternatePhone}</a>
                ) : (
                  ''
                )}
              </div>
              <div>{detail?.email ? <a href={`mailto:${detail?.email}`}>{detail?.email}</a> : 'No email'}</div>
              {formattedAddress ? (
                <>
                  <EuiSpacer size="s"></EuiSpacer>
                  <div>{formattedAddress}</div>
                </>
              ) : null}
              {detail?.notes ? (
                <>
                  <EuiSpacer size="s"></EuiSpacer>
                  <div>Notes: {detail?.notes}</div>
                </>
              ) : null}
            </EuiText>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </EuiFlexGroup>
  )
}

const getAddressString = (
  detail: ContactDetailFragment,
  addressDisplay: ContactCardAddressDisplay
): string | undefined => {
  const address = detail?.address
  switch (addressDisplay) {
    case ContactCardAddressDisplay.None:
      return undefined
    case ContactCardAddressDisplay.Suburb:
      return address?.suburb ?? undefined
    case ContactCardAddressDisplay.SuburbLabel:
      return address?.suburb
        ? `${address.suburb}, ${address.state} ${address.postcode}`
        : detail?.suburb
        ? detail?.suburb.label
        : undefined
    case ContactCardAddressDisplay.FullAddress:
      return address
        ? [address.street, address.street2, address.suburb, address.postcode, address.state, address.country]
            .filter((p) => p?.trim().length)
            .join(', ')
        : undefined
    default:
      return undefined
  }
}
