import { QuestionDefinitions, getNotesGenerator } from '../../helpers/notes-generator'
import {
  ApplianceRepairTopicActionResult,
  InstallEnum,
  RepairOrReplaceEnum,
  applianceRepairQuestionDefinitions
} from '../appliance-shared/appliance-model'
import { YesNoValue } from '../common/common-enums'
import { TopicQualification, TopicQualificationResult } from '../common/common-outcome'

export const DUEL_FUEL_SCRIPT =
  "Just so you know, our appliance technicians don't have a gas license. So if the issue is with the gas stove top, we won't be able to fix it."

export const DUEL_FUEL_INTERNAL_NOTE_MESSAGE =
  'These jobs can only be booked on Chris, as he has a limited gas license. If the job is on the southside, pls contact Appliance DSR to find suitable next available time'

export const BOOK_PLUMBER_AND_ELECTRICIAN_SAME_TIME_MESSAGE =
  'Book 2 trades: a Plumber and Electrician same day and time'

export const OVEN_WILL_NOT_FIT_DISCLAIMER =
  'Customer advised that we need the appliance onsite to provide accurate quoting.'
export interface TopicOvenActionResult extends ApplianceRepairTopicActionResult {
  ovenType?: OvenTypeEnum
  serviceType?: RepairOrReplaceEnum | InstallEnum
  happenedBefore?: YesNoValue
  removeOldOven?: YesNoValue
  willNewOvenFitCutOut?: YesNoValue
  isNewOvenOnsite?: YesNoValue
  gasType?: GasEnum
  duelFuelConfirmationToProceed?: YesNoValue
}

const questionDefinitions: QuestionDefinitions<TopicOvenActionResult> = {
  ovenType: { question: 'What type of oven do you have?', statement: 'Oven:' },
  serviceType: { question: 'Is this for a Repair or Replace?', statement: 'Service:' },
  ...applianceRepairQuestionDefinitions,
  gasType: { question: 'Is the oven LPG or natural gas?', statement: 'Gas:' },
  duelFuelConfirmationToProceed: {
    question: 'Are you still happy for us to attend?',
    statement: 'Proceed knowing that gas issues cant be fixed:'
  },
  issue: { question: 'What is the fault with the cooktop?', statement: 'Issue:' },
  removeOldOven: { question: 'Do we need to remove the old oven?', statement: 'Remove old:' },
  willNewOvenFitCutOut: {
    question: 'Will the new oven fit in the the existing cut out?',
    statement: 'Will Fit:',
    additionalStatement: (results: TopicOvenActionResult) => {
      const { willNewOvenFitCutOut } = results
      const answer = willNewOvenFitCutOut === YesNoValue.No ? OVEN_WILL_NOT_FIT_DISCLAIMER : undefined
      return answer
    }
  },

  isNewOvenOnsite: {
    question: 'Is the new oven onsite?',
    statement: 'Oven is on site. '
  }
}

export const ovenNotesGenerator = getNotesGenerator(questionDefinitions)

export const getQualification = (result: TopicOvenActionResult | undefined): TopicQualificationResult => {
  return { qualification: TopicQualification.Unknown }
}

export const enum OvenTypeEnum {
  Gas = 'Gas',
  DuelFuel = 'DuelFuel',
  Electric = 'Electric'
}
export const ovenTypeOptions = [
  { id: OvenTypeEnum.Gas, label: 'Gas' },
  { id: OvenTypeEnum.DuelFuel, label: 'Duel Fuel' },
  { id: OvenTypeEnum.Electric, label: 'Electric' }
]

export const enum GasEnum {
  LPG = 'LPG',
  Natural = 'Natural'
}

export const gasTypeOptions = [
  { id: GasEnum.LPG, label: 'LPG' },
  { id: GasEnum.Natural, label: 'Natural' }
]
