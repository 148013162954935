import { first } from 'lodash-es'
import { MediaContext, MediaItemFragment } from '../api/generated-types'
import { MediaItemView } from './media-item-view'
import '../static/css/media-item.css'
import { useState } from 'react'
import { JobPhotoUploader } from '../jobs/job-photo-uploader'
import { EuiFlexGrid, EuiFlexItem, EuiIcon } from '@elastic/eui'

export interface MediaItemStackViewProps {
  jobId?: string
  context?: MediaContext
  stack: MediaItemStack
  itemWidth: number
  onCreateMediaItems?: (mediaItems: MediaItemFragment[]) => void
  uploadEnabled?: boolean
}

enum MediaItemStackViewType {
  Single = 'single',
  Grid = 'grid'
}

export interface MediaItemStack {
  subject?: string
  mediaItems: MediaItemFragment[]
  showLabel?: boolean
}

export const MediaItemStackView = ({
  jobId,
  stack,
  context,
  itemWidth,
  onCreateMediaItems,
  uploadEnabled
}: MediaItemStackViewProps) => {
  const mediaItem = first(stack.mediaItems)

  const count = stack.mediaItems.length
  const [type] = useState(MediaItemStackViewType.Grid)

  const stackClassName = getStackClassName(count, type)

  const smallItemWidth = Math.floor((itemWidth - 4) / 2)

  return (
    <div className={stackClassName} style={{ width: `${itemWidth}px`, height: `${itemWidth + 24}px` }}>
      <div className="media-item-stack__content">
        {mediaItem ? (
          type === MediaItemStackViewType.Single || stack.mediaItems.length <= 1 ? (
            <MediaItemView mediaItem={mediaItem} width={itemWidth} showDescription={false} />
          ) : (
            <EuiFlexGrid columns={2} gutterSize="none">
              {Array.from(Array(3).keys()).map((i) => (
                <EuiFlexItem
                  key={stack.mediaItems[i]?.id || i}
                  grow={false}
                  style={{ margin: '1px', width: smallItemWidth, height: smallItemWidth }}
                >
                  {stack.mediaItems[i] ? (
                    <MediaItemView mediaItem={stack.mediaItems[i]} width={smallItemWidth} showDescription={true} />
                  ) : (
                    <div className="media-item-stack__blank">
                      <EuiIcon type="image" color="#ACAFB9" size="l" />
                    </div>
                  )}
                </EuiFlexItem>
              ))}
              <EuiFlexItem
                className="media-item-stack__upload"
                style={{ margin: '1px', width: smallItemWidth, height: smallItemWidth }}
                grow={false}
              >
                {jobId && context && (
                  <JobPhotoUploader jobId={jobId} context={context} subject={stack.subject ?? 'General'} />
                )}
              </EuiFlexItem>
            </EuiFlexGrid>
          )
        ) : uploadEnabled && jobId && context ? (
          <div className="media-item-stack__upload" style={{ height: `${itemWidth}px` }}>
            <JobPhotoUploader
              jobId={jobId}
              // label={stack.subject}
              context={context}
              subject={stack.subject}
              onCreateMediaItems={onCreateMediaItems}
            />
          </div>
        ) : undefined}
        {stack.showLabel !== false && (
          <div className="media-item-stack__subject">
            {stack.subject}
            {count > 0 && ` (${count})`}
          </div>
        )}
      </div>
    </div>
  )
}

const getStackClassName = (count: number, type: MediaItemStackViewType): string => {
  if (type === MediaItemStackViewType.Grid) {
    return 'media-item-stack'
  }
  if (count <= 0) {
    return 'media-item-stack media-item-stack--empty'
  } else if (count === 1) {
    return 'media-item-stack media-item-stack--single'
  } else if (count === 2) {
    return 'media-item-stack media-item-stack--couple'
  } else {
    return 'media-item-stack media-item-stack--multiple'
  }
}
