import {
  TopicQualification,
  TopicQualificationResult,
  TopicRequirementsResult,
  createOutcomeGenerator,
  createRequirements
} from '../common/common-outcome'
import { JobType, TradeType } from '../../../api/generated-types'
import { TopicSecurityInspectionActionResult } from './action-topic-security-inspection-model'

const SECURITY_INSPECTION_SKILL = 'SecurityAlarm'

const getRequirements = (result: TopicSecurityInspectionActionResult | undefined): TopicRequirementsResult => {
  if (!result) return {}
  return { requirements: createRequirements([SECURITY_INSPECTION_SKILL]) }
}

const getQualification = (result: TopicSecurityInspectionActionResult | undefined): TopicQualificationResult => {
  if (!result) return { qualification: TopicQualification.Unknown }
  const { isSpecificInspection } = result
  return {
    qualification: isSpecificInspection ? TopicQualification.InScope : TopicQualification.Unknown
  }
}

export const outcomeGenerator = createOutcomeGenerator<TopicSecurityInspectionActionResult>(
  {
    qualification: TopicQualification.Unknown,
    trade: TradeType.Security,
    type: JobType.Service,
    category: 'Security Inspection'
  },
  getQualification,
  getRequirements
)
