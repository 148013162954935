import { EuiButton, EuiSpacer } from '@elastic/eui'
import { Callout } from '../../common/callout'
import { CustomerDetailContainer } from '../../customers/customer-detail-container'
import { BaseActionInput, BaseActionResult, WorkflowActionProps } from '../workflow-model'
import { ContactActionResult } from './action-contact'
import { CustomerAction, CustomerActionResult } from './action-customer'

export interface UpdateCustomerActionInput extends BaseActionInput {
  contact?: ContactActionResult | undefined
}

export interface UpdateCustomerActionResult extends BaseActionResult {
  customer?: CustomerActionResult
}

export const UpdateCustomerAction = (
  props: WorkflowActionProps<UpdateCustomerActionInput, UpdateCustomerActionResult>
) => {
  const { onUpdate, result, input } = props
  const { contact } = input ?? {}
  const { customer } = result ?? {}

  const setCustomer = (customer: CustomerActionResult) => onUpdate({ ...result, customer })

  return (
    <>
      <CustomerAction input={{ contact }} result={customer} onUpdate={setCustomer} />

      {customer?.customer && (
        <>
          <Callout type="script">Excellent, so what exactly did you need changed?</Callout>
          <Callout type="note">
            Listen carefully and note down all required changes (including spelling out details phonetically)
          </Callout>
          <EuiSpacer />
          <div className="workflow__detail-wrapper">
            <CustomerDetailContainer id={customer.customer.id} showProperties={true} />
          </div>

          <EuiSpacer size="l" />
          <EuiButton fill color="primary" onClick={() => onUpdate({ ...result, actionCompleted: true })}>
            I've updated customer details
          </EuiButton>
        </>
      )}
    </>
  )
}
