import { EuiContextMenuPanelDescriptor, EuiPanel } from '@elastic/eui'
import { ScheduleEventFragment } from '../../api/generated-types'
import { CreateAppointmentForm } from '../schedule-create-appointment-form'

export interface ScheduleEventContextMenuCreateAppointmentPanelProps {
  event: ScheduleEventFragment
  onActionClick?: () => void
}

export const ScheduleEventContextMenuCreateAppointmentPanel = ({
  event,
  onActionClick
}: ScheduleEventContextMenuCreateAppointmentPanelProps): EuiContextMenuPanelDescriptor => {
  return {
    id: 'create-appointment',
    size: 's',
    title: 'Create appointment',
    width: 400,
    content: (
      <EuiPanel>
        <CreateAppointmentForm event={event} onSubmit={() => onActionClick && onActionClick()} />
      </EuiPanel>
    )
  }
}
