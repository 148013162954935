import { EuiFlexGroup, EuiFlexItem, EuiIcon, EuiText } from '@elastic/eui'
import { MediaItemFragment } from '../api/generated-types'
import { useS3SignedUrl } from '../s3/use-s3-signed-url'

export interface MediaItemDocumentViewProps {
  mediaItem: MediaItemFragment
  selectMode?: boolean
  direction?: 'row' | 'column'
}

export const MediaItemDocumentView = ({ mediaItem, selectMode, direction = 'column' }: MediaItemDocumentViewProps) => {
  const mediaItemExt = mediaItem?.object?.mimeType?.split('/')[1]
  const mediaItemName = `${mediaItem.name ?? mediaItem.object?.filename}.${mediaItemExt}`

  const { signedUrl } = useS3SignedUrl({
    bucket: mediaItem.object?.bucket,
    key: mediaItem.object?.key,
    filename: mediaItemName
  })

  return (
    <EuiFlexGroup
      direction={direction}
      alignItems={direction === 'column' ? 'center' : undefined}
      gutterSize="s"
      justifyContent={direction === 'column' ? 'spaceAround' : undefined}
    >
      <EuiFlexItem grow={false}>
        <EuiIcon type="document" size="xl" />
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        {signedUrl ? (
          <>
            {selectMode ? (
              <div style={{ cursor: 'pointer' }}>
                <EuiText size="s"> {mediaItem.name ?? mediaItem.type}</EuiText>
              </div>
            ) : (
              <a href={signedUrl} target="_blank" rel="noreferrer">
                {mediaItem.name ?? mediaItem.type}
              </a>
            )}
          </>
        ) : (
          mediaItem.name ?? mediaItem.type
        )}
      </EuiFlexItem>
    </EuiFlexGroup>
  )
}
