import { EuiButton, EuiSpacer } from '@elastic/eui'
import { includes, map } from 'lodash-es'
import { CustomerType, EnquiryAbortReason } from '../../../api/generated-types'
import { Callout } from '../../../common/callout'
import { HVACRoofAccessQuestion } from '../../helpers/hvac-commercial-roof-acccess-question'
import { getResultUpdater } from '../../helpers/results-updater'
import { useWorkflow } from '../../helpers/workflow-provider'
import { FreeTextQuestionField } from '../../question-fields/free-text-question-field'
import { SingleSelectField } from '../../question-fields/single-select-question-field'
import { WorkflowActionProps } from '../../workflow-model'
import { TopicActionInput } from '../action-topic-model'
import { TopicWorkflowAction } from '../action-topic-view'
import { YesNoValue } from '../common/common-enums'
import { TopicQualification } from '../common/common-outcome'
import {
  ACBrand,
  ACSystemAge,
  ACType,
  ACWarrantyStatus,
  TopicHVACRepairActionResult,
  hvacRepairNotesGenerator
} from './action-topic-hvac-repair-model'
import { outcomeGenerator } from './action-topic-hvac-repair-outcome'

export const TopicHVACRepairAction = (props: WorkflowActionProps<TopicActionInput, TopicHVACRepairActionResult>) => {
  const workflowContext = useWorkflow()
  const { result, input, onUpdate } = props
  const {
    acType,
    underWarranty,
    brand,
    faultDescription,
    indoorUnitLocation,
    outdoorUnitLocation,
    ladderRequired,
    lastServiceDate,
    systemAge,
    furnitureNeedToBeMoved,
    willingToVoidWarranty,
    replacement,
    replacementType,
    customerType,
    roofAccess,
    fallonInstalled
  } = result ?? {}
  const isCommercial = customerType === CustomerType.Commercial
  const notesGenerator = hvacRepairNotesGenerator

  const topicOutcome = outcomeGenerator(result)
  const isOutOfScope = topicOutcome.qualification === TopicQualification.OutOfScope
  const canComplete = topicOutcome.qualification === TopicQualification.InScope
  const newTopic = topicOutcome.newTopic

  const onNext = () =>
    onUpdate({
      ...result,
      ...topicOutcome,
      actionCompleted: true,
      workRequiredNotes: generatedNotes
    })

  const generatedNotes = notesGenerator.generateNotes(result ?? {})
  const updateResult = getResultUpdater(result ?? {}, onUpdate).updateResult

  const isOutsideOfWarranty =
    underWarranty === ACWarrantyStatus.OutsideOfWarranty ||
    (underWarranty === ACWarrantyStatus.Unsure && systemAge === ACSystemAge.OverFiveYears) ||
    ((underWarranty === ACWarrantyStatus.Unsure || underWarranty === ACWarrantyStatus.UnderWarranty) &&
      fallonInstalled === YesNoValue.Yes)

  const isUnderWarrantyAndWillingToVoid =
    (underWarranty === ACWarrantyStatus.UnderWarranty || underWarranty === ACWarrantyStatus.Unsure) &&
    willingToVoidWarranty === YesNoValue.Yes

  return (
    <TopicWorkflowAction input={input} onUpdate={onUpdate} result={result}>
      <SingleSelectField
        question={notesGenerator.getQuestion('acType')}
        options={map(ACType, (id) => ({ id }))}
        answer={acType}
        changeAnswer={(acType) => updateResult({ acType })}
      />

      {includes([ACType.SplitSystemOrMultiHead, ACType.Ducted], acType) && (
        <>
          <SingleSelectField
            question={notesGenerator.getQuestion('underWarranty')}
            options={map(ACWarrantyStatus, (id) => ({ id }))}
            answer={underWarranty}
            changeAnswer={(underWarranty) =>
              updateResult({ underWarranty, fallonInstalled: undefined, willingToVoidWarranty: undefined })
            }
          />

          {(underWarranty === ACWarrantyStatus.UnderWarranty || underWarranty === ACWarrantyStatus.Unsure) && (
            <>
              <SingleSelectField
                question={notesGenerator.getQuestion('fallonInstalled')}
                options={[
                  {
                    id: YesNoValue.Yes,
                    label: 'Yes',
                    icon: 'check'
                  },
                  {
                    id: YesNoValue.No,
                    label: 'No',
                    icon: 'cross'
                  }
                ]}
                answer={fallonInstalled}
                changeAnswer={(fallonInstalled) => updateResult({ fallonInstalled, willingToVoidWarranty: undefined })}
              />
              {fallonInstalled === YesNoValue.No && (
                <>
                  <SingleSelectField
                    question={notesGenerator.getQuestion('willingToVoidWarranty')}
                    options={[
                      {
                        id: YesNoValue.Yes,
                        label: 'Yes, willing to void warranty',
                        icon: 'check'
                      },
                      {
                        id: YesNoValue.No,
                        label: 'No',
                        icon: 'cross'
                      }
                    ]}
                    answer={willingToVoidWarranty}
                    changeAnswer={(willingToVoidWarranty) => updateResult({ willingToVoidWarranty })}
                  />
                </>
              )}
            </>
          )}

          {(isOutsideOfWarranty || isUnderWarrantyAndWillingToVoid) && (
            <>
              <SingleSelectField
                question={notesGenerator.getQuestion('brand')}
                options={map(ACBrand, (id) => ({ id }))}
                answer={brand}
                changeAnswer={(brand) => updateResult({ brand })}
              />

              <FreeTextQuestionField
                question={notesGenerator.getQuestion('faultDescription')}
                answer={faultDescription}
                changeAnswer={(faultDescription) => updateResult({ faultDescription })}
                rows={3}
              />
              <FreeTextQuestionField
                question={notesGenerator.getQuestion('indoorUnitLocation')}
                answer={indoorUnitLocation}
                changeAnswer={(indoorUnitLocation) => updateResult({ indoorUnitLocation })}
              />
              <FreeTextQuestionField
                question={notesGenerator.getQuestion('outdoorUnitLocation')}
                answer={outdoorUnitLocation}
                changeAnswer={(outdoorUnitLocation) => updateResult({ outdoorUnitLocation })}
              />
              <FreeTextQuestionField
                question={notesGenerator.getQuestion('ladderRequired')}
                answer={ladderRequired}
                changeAnswer={(ladderRequired) => updateResult({ ladderRequired })}
              />
              <FreeTextQuestionField
                question={notesGenerator.getQuestion('lastServiceDate')}
                answer={lastServiceDate}
                changeAnswer={(lastServiceDate) => updateResult({ lastServiceDate })}
              />
              <FreeTextQuestionField
                question={notesGenerator.getQuestion('furnitureNeedToBeMoved')}
                answer={furnitureNeedToBeMoved}
                changeAnswer={(furnitureNeedToBeMoved) => updateResult({ furnitureNeedToBeMoved })}
              />
            </>
          )}
        </>
      )}

      {includes([ACType.WindowBox, ACType.Portable], acType) && (
        <>
          <SingleSelectField
            question={notesGenerator.getQuestion('replacement')}
            options={[
              {
                id: YesNoValue.Yes,
                label: 'Yes, interested in replacement',
                icon: 'check'
              },
              {
                id: YesNoValue.No,
                label: 'No',
                icon: 'cross'
              }
            ]}
            answer={replacement}
            changeAnswer={(replacement) => updateResult({ replacement })}
          />

          {replacement === YesNoValue.Yes && (
            <>
              <SingleSelectField
                question={notesGenerator.getQuestion('replacementType')}
                options={[
                  {
                    id: ACType.SplitSystemOrMultiHead
                  },
                  {
                    id: ACType.Ducted
                  },
                  {
                    id: ACType.WindowBox
                  }
                ]}
                answer={replacementType}
                changeAnswer={(replacementType) => updateResult({ replacementType })}
              />
            </>
          )}
        </>
      )}

      {newTopic && (
        <EuiButton onClick={() => workflowContext.changeJobTopic(newTopic.reference)}>{newTopic.buttonLabel}</EuiButton>
      )}
      <HVACRoofAccessQuestion updateResults={updateResult} isCommercial={isCommercial} roofAccess={roofAccess} />

      {isOutOfScope && (
        <>
          <Callout type="script">
            {topicOutcome.qualificationMessage ?? <>Unfortunately this type of work is out of scope for us.</>}
          </Callout>
          <EuiSpacer />
          <EuiButton
            color="warning"
            onClick={() =>
              workflowContext.abort({
                abortReason: EnquiryAbortReason.CustomerNeedsAreOutOfService,
                notes: `Customer was enquiring about HVAC Repair`
              })
            }
          >
            Offer a voucher and end call
          </EuiButton>
        </>
      )}

      {!isOutOfScope && !newTopic && (
        <>
          {topicOutcome.requirements && (
            <>
              <EuiSpacer />
              <Callout type="note">
                {topicOutcome.requirements.attributes?.map((r) => r.attributeId).join(', ')}
              </Callout>
            </>
          )}
          <EuiSpacer />
          <EuiButton disabled={!canComplete} onClick={() => onNext()}>
            Next
          </EuiButton>
        </>
      )}
    </TopicWorkflowAction>
  )
}
