import { HotWaterRepairIssue, TopicHotWaterActionResult } from './action-topic-hot-water-model'
import { SingleSelectField } from '../../question-fields/single-select-question-field'
import { NotesGenerator } from '../../helpers/notes-generator'
import { ResultUpdater } from '../../helpers/results-updater'
import { map } from 'lodash-es'

export interface HotWaterRepairOtherProps {
  result?: TopicHotWaterActionResult
  notesGenerator: NotesGenerator<TopicHotWaterActionResult>
  updateResult: ResultUpdater<TopicHotWaterActionResult>
}

export const HotWaterRepairOther = (props: HotWaterRepairOtherProps) => {
  const { result, notesGenerator, updateResult } = props
  const { repairIssue } = result ?? {}
  return (
    <SingleSelectField
      question={notesGenerator.getQuestion('repairIssue')}
      options={map(
        [
          HotWaterRepairIssue.Bulging,
          HotWaterRepairIssue.Burst,
          HotWaterRepairIssue.DrainLineLeaking,
          HotWaterRepairIssue.Leaking,
          HotWaterRepairIssue.LukeWarm,
          HotWaterRepairIssue.NoHotWaterAtTap,
          HotWaterRepairIssue.NotStayingHot,
          HotWaterRepairIssue.OnALean,
          HotWaterRepairIssue.Relocate,
          HotWaterRepairIssue.Remove,
          HotWaterRepairIssue.RustedAndAnode,
          HotWaterRepairIssue.TooLongToGetHot,
          HotWaterRepairIssue.ValvesOrPipes
        ],
        (i) => ({ id: i })
      )}
      answer={repairIssue}
      changeAnswer={(repairIssue) => updateResult({ repairIssue })}
    />
  )
}
