import { BaseActionInput, BaseActionResult, WorkflowActionProps } from '../workflow-model'
import { WorkflowAction } from '../workflow-action'
import { EuiButton, EuiSpacer, EuiText } from '@elastic/eui'
import { Callout } from '../../common/callout'

export type EmploymentConfirmationActionInput = BaseActionInput

export type EmploymentConfirmationActionResult = BaseActionResult

export const EmploymentConfirmationAction = (
  props: WorkflowActionProps<EmploymentConfirmationActionInput, EmploymentConfirmationActionResult>
) => {
  const { result, onUpdate } = props
  const { actionCompleted } = result ?? {}
  const setActionCompleted = (actionCompleted: boolean | undefined) => onUpdate({ ...result, actionCompleted })
  return (
    <WorkflowAction
      title="Confirming employment"
      value={actionCompleted ? 'Completed' : undefined}
      onClickChange={() => setActionCompleted(false)}
    >
      <Callout type="script">
        <EuiText>
          <div>I just need you to send through an email with your:</div>
          <EuiSpacer size="s" />
          <ul>
            <li>name</li>
            <li>contact details</li>
            <li>what year(s) you worked with us</li>
            <li>what trade you were employed with, and</li>
            <li>what info your require</li>
          </ul>
          <div>
            to: <strong>enquiries@fallonsolutions.com.au</strong>
          </div>
          <EuiSpacer />
          <div>And we will send through that confirmation</div>
        </EuiText>
      </Callout>
      <EuiSpacer />
      <EuiButton fill color="primary" onClick={() => setActionCompleted(true)}>
        I've completed these steps
      </EuiButton>
    </WorkflowAction>
  )
}
