import { EuiBasicTable, EuiButtonEmpty, EuiFlexGroup, EuiFlexItem, EuiIcon, EuiSpacer, EuiText } from '@elastic/eui'
import { useState } from 'react'
import { ContactExpandedFragment } from '../api/generated-types'
import { ContactCustomerListColumns } from './contact-customer-list-table-list-columns'
import { ContactCustomerAssociationForm } from './create-contact-customer-association-form'

export interface ContactCustomerAssociationListProps {
  contact: ContactExpandedFragment
}

export const ContactCustomerAssociationList = ({ contact }: ContactCustomerAssociationListProps) => {
  const [isCreateAssociationModalOpen, setIsCreateAssociationModalOpen] = useState(false)

  const customers = contact.customers ?? []

  return (
    <>
      <EuiBasicTable items={customers} columns={ContactCustomerListColumns()} />
      <EuiSpacer size="m" />

      <EuiFlexGroup justifyContent="flexEnd" alignItems="center" gutterSize="s">
        <EuiFlexItem grow={false}>
          <EuiIcon type="warning" size="s" />
        </EuiFlexItem>
        <EuiFlexItem grow={true}>
          <EuiText size="xs">
            A contact can be linked to multiple customers with different roles. For example, a contact may be a home
            owner for one customer and a tenant for another.
          </EuiText>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiButtonEmpty size="xs" onClick={() => setIsCreateAssociationModalOpen(true)}>
            Create association
          </EuiButtonEmpty>
        </EuiFlexItem>
      </EuiFlexGroup>

      {isCreateAssociationModalOpen && (
        <ContactCustomerAssociationForm contact={contact} onClose={() => setIsCreateAssociationModalOpen(false)} />
      )}
    </>
  )
}
