import { EuiContextMenuPanelDescriptor, EuiLoadingSpinner, EuiPanel } from '@elastic/eui'
import {
  AttendedStatusType,
  ScheduleEventDetailFragment,
  ScheduleEventFragment,
  ScheduleEventStatusType
} from '../../api/generated-types'
import { CancelAppointmentForm } from '../schedule-cancel-appointment-form'

export interface ScheduleEventContextMenuCancelFormPanelProps {
  event: ScheduleEventFragment
  loading: boolean
  eventDetail?: ScheduleEventDetailFragment
  onActionClick?: (() => void) | undefined
}

export const ScheduleEventContextMenuCancelFormPanel = ({
  event,
  eventDetail,
  loading,
  onActionClick
}: ScheduleEventContextMenuCancelFormPanelProps): EuiContextMenuPanelDescriptor => {
  const job = eventDetail?.job
  const appointment = eventDetail?.appointment
  const scheduleEventAttended = event.status?.status
    ? [ScheduleEventStatusType.OnSite, ScheduleEventStatusType.InProgress, ScheduleEventStatusType.Completed].includes(
        event.status.status
      )
    : false
  const appointmentIsAttended = scheduleEventAttended || appointment?.attended === AttendedStatusType.Attended

  return {
    id: 'cancel-form',
    size: 's',
    title: 'Cancel appointment',
    width: 300,
    content: (
      <EuiPanel>
        {loading ? (
          <EuiLoadingSpinner />
        ) : (
          job &&
          appointment?.id && (
            <CancelAppointmentForm
              job={job}
              appointmentId={appointment.id}
              appointmentAttended={appointmentIsAttended}
              onSubmit={() => onActionClick && onActionClick()}
            />
          )
        )}
      </EuiPanel>
    )
  }
}
