import { AppointmentRequirements } from '@fallonsolutions/appointment'
import { PriorityRequirement } from '@fallonsolutions/priority'
import { JobTopicType } from '@fallonsolutions/types'
import { ReactNode } from 'react'
import { JobType, TradeType } from '../../../api/generated-types'

export interface TopicOutcome {
  qualification: TopicQualification
  qualificationMessage?: string | ReactNode
  trade: TradeType
  type: JobType
  category: string
  requirements?: AppointmentRequirements
  newTopic?: NewTopic
}

export interface NewTopic {
  reference: JobTopicType
  buttonLabel: string
  message?: string | ReactNode
}

export enum TopicQualification {
  InScope = 'In scope',
  OutOfScope = 'Out of scope',
  NewTopic = 'New topic',
  Unknown = 'Unknown' //MoreInformationNeeded
}

export type OutcomeGenerator<T> = (result: T | undefined) => TopicOutcome

export const createRequirements = (
  attributeIds: string[],
  priority?: PriorityRequirement | undefined
): AppointmentRequirements => {
  return {
    attributes: attributeIds.map((attributeId) => ({ attributeId })),
    ...(priority && { priority })
  }
}

export interface TopicQualificationResult {
  qualification: TopicQualification
  qualificationMessage?: string | ReactNode
  newTopic?: NewTopic
}

export interface TopicRequirementsResult {
  requirements?: AppointmentRequirements
  trade?: TradeType
  type?: JobType
  category?: string
}

export const createOutcomeGenerator = <T>(
  initialOutcome: TopicOutcome,
  qualificationFn: (result: T | undefined) => TopicQualificationResult,
  requirementsFn: (result: T | undefined) => TopicRequirementsResult
): OutcomeGenerator<T> => {
  return (result) => {
    const qualificationResult = qualificationFn(result)
    const { qualification, qualificationMessage } = qualificationResult
    switch (qualification) {
      case TopicQualification.OutOfScope:
        return { ...initialOutcome, ...qualificationResult }
      case TopicQualification.NewTopic:
        return { ...initialOutcome, ...qualificationResult }
      case TopicQualification.InScope: {
        const requirementsResult = requirementsFn(result)
        return {
          ...initialOutcome,
          ...(requirementsResult.requirements && {
            qualification,
            ...requirementsResult,
            ...(qualificationMessage && { qualificationMessage })
          })
        }
      }
      case TopicQualification.Unknown: {
        const requirements = requirementsFn(result)
        return {
          ...initialOutcome,
          ...qualificationResult,
          ...(!!requirements && {
            qualification,
            ...requirements,
            ...(qualificationMessage && { qualificationMessage })
          })
        }
      }
      default:
        return initialOutcome
    }
  }
}
