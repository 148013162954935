import { addressValidatorFactoryOptsDefault } from '@fallonsolutions/address'
import { contactDetailFragmentToContactDetail, contactDetailValidatorFactory } from '@fallonsolutions/contact'
import { safeParse } from '@falloncore/validation'
import { ContactDetailFragment } from '../api/generated-types'

export interface ValidateContactDetailParams {
  addressRequired: boolean
  detail: ContactDetailFragment
}

export const validateContactDetail = ({ detail, addressRequired }: ValidateContactDetailParams) => {
  const parsed = contactDetailFragmentToContactDetail(detail)
  const createContactDetailValidator = contactDetailValidatorFactory({
    fields: {
      address: {
        ...addressValidatorFactoryOptsDefault,
        isRequired: true
      }
    }
  })
  const result = safeParse(createContactDetailValidator, parsed)
  console.log(`contactDetail validation result address required(${addressRequired})`, parsed, result)
  return result
}
