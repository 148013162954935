import { useMutation } from '@apollo/client'
import { EuiButton, EuiFlexGroup, EuiLink, EuiModal, EuiModalBody, EuiModalFooter, EuiText } from '@elastic/eui'
import { PaymentTaskLinkFragment } from '@fallonsolutions/types'
import { SendPaymentLinkCustomerNotificationDocument } from '../api/generated-types'

interface SendPaymentLinkFormProps {
  paymentTask: PaymentTaskLinkFragment
  closeModal: () => void
}

export const SendPaymentLinkForm = (props: SendPaymentLinkFormProps) => {
  const { paymentTask, closeModal } = props
  const [sendPaymentLinkCustomerNotification, { data, loading }] = useMutation(
    SendPaymentLinkCustomerNotificationDocument
  )

  const handleAction = () => {
    sendPaymentLinkCustomerNotification({ variables: { input: { paymentTaskId: paymentTask.id } } })
  }

  return (
    <div>
      <EuiFlexGroup alignItems="flexStart">
        <EuiModal onClose={closeModal}>
          <EuiModalBody>
            <EuiText>Send SMS</EuiText>
          </EuiModalBody>
          <EuiModalFooter>
            <EuiButton
              fill
              onClick={() => handleAction()}
              disabled={loading || data?.sendPaymentLinkCustomerNotification.success === true}
            >
              Send SMS
            </EuiButton>
            <EuiLink onClick={closeModal} disabled={loading} />
          </EuiModalFooter>
        </EuiModal>
      </EuiFlexGroup>
    </div>
  )
}
