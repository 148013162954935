import { useMutation, useQuery, useSubscription } from '@apollo/client'
import {
  EuiContextMenu,
  EuiContextMenuPanelDescriptor,
  EuiContextMenuPanelItemDescriptor,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHeaderSectionItemButton,
  EuiIcon,
  EuiLink,
  EuiLoadingSpinner,
  EuiPopover
} from '@elastic/eui'
import { useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  BulkUpdateNotificationStatusDocument,
  NotificationStatusType,
  OnNotificationEventDocument,
  SearchNotificationsDocument
} from '../api/generated-types'
import { useAuthenticated } from '../auth/authenticated-context'
import { NotificationRow } from './notification-row'

export const NotificationMenu = () => {
  const history = useHistory()

  const [notificationsOpen, setNotificationsOpen] = useState(false)
  const bellRef = useRef<any>(undefined)
  const { userFragment, user } = useAuthenticated()
  const userId = userFragment.id
  const tenantId = user.tenantId ?? 'fallonsolutions'

  const gotoNotificationList = () => {
    history.push('/notifications')
    setNotificationsOpen(false)
  }

  const { data, refetch } = useQuery(SearchNotificationsDocument, {
    variables: {
      input: {
        filter: {
          userId
        },
        size: 10
      }
    }
  })

  const [bulkUpdateNotificationStatus, { loading: markAllAsReadLoading }] = useMutation(
    BulkUpdateNotificationStatusDocument,
    {
      refetchQueries: ['SearchNotifications']
    }
  )

  useSubscription(OnNotificationEventDocument, {
    variables: { tenantId, userId },
    onData: (data) => {
      refetch()
      if (data.data.data?.onNotificationEvent?.notification?.status.status === NotificationStatusType.Unread) {
        bellRef.current?.euiAnimate()
      }
    }
  })

  const notifications = data?.searchNotifications?.results ?? []
  const unreadNotificationCount = data?.searchNotifications?.unreadCount ?? 0

  const notificationsButton = () => {
    return (
      <EuiHeaderSectionItemButton
        ref={bellRef}
        onClick={() => setNotificationsOpen(!notificationsOpen)}
        notification={unreadNotificationCount}
      >
        <EuiIcon type="bell" size="m" />
      </EuiHeaderSectionItemButton>
    )
  }

  const clickMarkAllAsRead = () => {
    bulkUpdateNotificationStatus({
      variables: {
        input: {
          updates: notifications
            .filter((n) => n.status.status !== NotificationStatusType.Read)
            .map((notification) => ({
              notificationId: notification.id,
              status: NotificationStatusType.Read
            }))
        }
      }
    })
  }

  const closeMenu = () => {
    setNotificationsOpen(false)
  }

  const items: EuiContextMenuPanelItemDescriptor[] =
    notifications.length > 0
      ? [
          ...notifications.map((notification, _index) => ({
            renderItem: () => (
              <div
                style={{
                  borderBottom: '1px solid #e9e9e9'
                }}
              >
                <NotificationRow notification={notification} onClose={closeMenu} />
              </div>
            )
          })),
          {
            renderItem: () => (
              <div style={{ padding: '12px' }}>
                <EuiFlexGroup gutterSize="m">
                  {unreadNotificationCount > 0 && (
                    <EuiFlexItem grow={false}>
                      {markAllAsReadLoading ? (
                        <EuiLoadingSpinner size="m" />
                      ) : (
                        <EuiLink onClick={clickMarkAllAsRead}>Mark all as read</EuiLink>
                      )}
                    </EuiFlexItem>
                  )}
                  <EuiFlexItem grow={true} />
                  <EuiFlexItem grow={false}>
                    <EuiLink onClick={() => gotoNotificationList()}>
                      View all notifications{unreadNotificationCount > 0 ? ` (${unreadNotificationCount})` : ''}
                    </EuiLink>
                  </EuiFlexItem>
                </EuiFlexGroup>
              </div>
            )
          }
        ]
      : [
          {
            key: 'no-notifications',
            name: 'No recent notifications',
            icon: 'bell'
          }
        ]

  const contextMenuPanels: EuiContextMenuPanelDescriptor[] = [
    {
      id: 0,
      items,
      width: 460
    }
  ]

  return (
    <EuiPopover
      id="notificationsPanel"
      button={notificationsButton()}
      isOpen={notificationsOpen}
      closePopover={closeMenu}
      panelPaddingSize="none"
      anchorPosition="downCenter"
    >
      <EuiContextMenu initialPanelId={0} panels={contextMenuPanels} />
    </EuiPopover>
  )
}
