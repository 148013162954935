import { EuiFlexGroup, EuiFlexItem } from '@elastic/eui'

export interface PercentageBarProps {
  percentage: number
  label: string
  color: string
}

export const PercentageBar = ({ percentage, label, color }: PercentageBarProps) => {
  return (
    <EuiFlexGroup
      gutterSize="none"
      style={{
        background: `linear-gradient(to right, ${color}, ${color} ${percentage}%, transparent ${percentage}%)`
      }}
    >
      <EuiFlexItem grow={true}>{label}</EuiFlexItem>
    </EuiFlexGroup>
  )
}
