import {
  EuiBadge,
  EuiButton,
  EuiFieldSearch,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHighlight,
  EuiHorizontalRule,
  EuiIcon,
  EuiLink,
  EuiRadio,
  EuiSpacer,
  EuiSwitch,
  EuiText,
  EuiTitle,
  EuiTreeView
} from '@elastic/eui'
import { Node } from '@elastic/eui/src/components/tree_view/tree_view'
import { JobTopicCompletionStatus, JobTopicTreeItem, JobTopicType, JobTopics } from '@fallonsolutions/types'
import { useEffect, useState } from 'react'
import { elementScrollIntoView } from 'seamless-scroll-polyfill'
import { useApp } from '../../app/app-context'
import { useFeatureToggle } from '../../app/feature-toggle-service'
import { Callout } from '../../common/callout'
import { useDebounce } from '../../common/use-debounce'
import { useLocalStorage } from '../../common/use-local-storage'
import { makeHumanReadable } from '../../common/utils'
import { CustomerLocationScheduledServices } from '../../customer-locations/customer-location-scheduled-services'
import '../../static/css/action-select-job-topic.css'
import '../../static/css/job-topic.css'
import { WorkflowAction } from '../workflow-action'
import { WorkflowActionProps } from '../workflow-model'
import {
  SelectJobTopicActionInput,
  SelectJobTopicActionResult,
  SelectJobTopicActionUIProps
} from './action-select-job-topic'

interface FilterTopicResult {
  termFoundInLabel: boolean
  termFoundInTags: boolean
}

const filterTopic = (item: JobTopicTreeItem, searchTerm: string): FilterTopicResult => {
  return {
    termFoundInLabel: searchTerm.length > 0 ? item.label.toLowerCase().includes(searchTerm.toLowerCase()) : false,
    termFoundInTags:
      searchTerm.length > 0 && item.aliases ? item.aliases.toLowerCase().includes(searchTerm.toLowerCase()) : false
  }
}

interface CreateTreeItemProps {
  item: JobTopicTreeItem
  selectedTopicReference: JobTopicType | undefined
  searchTerm: string
  onSelect: (reference: JobTopicType | undefined) => void
  showBadges?: boolean
}

interface TreeItemViewModel {
  node: Node
  isMatch: boolean
  isAnyChildMatch: boolean
  isSelected: boolean
  isAnyChildSelected: boolean
}

const createTreeItem = (props: CreateTreeItemProps): TreeItemViewModel | undefined => {
  const { item, selectedTopicReference, searchTerm, onSelect } = props
  const showBadges = props.showBadges ?? false

  const isSearching = searchTerm.length > 0
  const matchResult: FilterTopicResult = isSearching
    ? filterTopic(item, searchTerm)
    : { termFoundInLabel: true, termFoundInTags: true }
  const isSelected = selectedTopicReference && selectedTopicReference === item.reference ? true : false
  const isMatch = matchResult.termFoundInLabel || matchResult.termFoundInTags
  const isFolder = !item.reference || (item.children && item.children.length > 0)

  const children: TreeItemViewModel[] =
    item.children && item.children.length > 0
      ? (item.children
          .map((i) => createTreeItem({ item: i, selectedTopicReference, searchTerm, onSelect, showBadges }))
          .filter((child) => !!child) as TreeItemViewModel[])
      : []

  // check if any child is matched/selected on the lower level
  const isAnyChildMatch = children && children.some((child) => child.isMatch || child.isAnyChildMatch)
  const isAnyChildSelected = children && children.some((child) => child.isSelected || child.isAnyChildSelected)

  const isNodeVisible = isSelected || isMatch || isAnyChildMatch || isAnyChildSelected

  if (!isNodeVisible) return undefined

  const className = isSelected ? 'job-topic--selected' : ''

  const getCompletionStatusBadgeColour = (status: JobTopicCompletionStatus) => {
    switch (status) {
      case JobTopicCompletionStatus.UnderDevelopment:
        return 'warning'
      case JobTopicCompletionStatus.UserAcceptanceTesting:
        return 'success'
    }
    return 'primary'
  }

  const node: Node = {
    id: item.label,
    label: (
      <>
        <div data-test-id={isFolder ? 'job-topic-folder' : 'job-topic-item'}>
          {isSearching && matchResult.termFoundInLabel ? (
            <EuiHighlight search={searchTerm.toLowerCase()}>{item.label}</EuiHighlight>
          ) : (
            item.label
          )}
          {showBadges && !!item.completionStatus && item.completionStatus !== JobTopicCompletionStatus.Ready && (
            <EuiBadge
              color={getCompletionStatusBadgeColour(item.completionStatus)}
              style={{ marginLeft: '8px' }}
              title={makeHumanReadable(item.completionStatus)}
            >
              {makeHumanReadable(item.completionStatus)}
            </EuiBadge>
          )}
        </div>
        <div>
          {isSearching && item.aliases && matchResult.termFoundInTags && (
            <EuiHighlight search={searchTerm.toLowerCase()} className="found-tags">
              {item.aliases}
            </EuiHighlight>
          )}
        </div>
      </>
    ),
    ...(item.reference && {
      callback: () => {
        onSelect(item.reference)
        return item.label
      }
    }),
    icon: <EuiIcon type={isFolder ? 'folderClosed' : isSelected ? 'check' : 'empty'} />,
    iconWhenExpanded: <EuiIcon type={isFolder ? 'folderOpen' : isSelected ? 'check' : 'empty'} />,
    ...(isSearching && isFolder && { isExpanded: true }),
    className,
    ...(children ? { children: children.map((child) => child.node) } : {})
  }

  return {
    node,
    isSelected,
    isAnyChildSelected,
    isMatch,
    isAnyChildMatch
  }
}

export const SelectJobTopicLegacyAction = (
  props: WorkflowActionProps<SelectJobTopicActionInput, SelectJobTopicActionResult> & SelectJobTopicActionUIProps
) => {
  const appContext = useApp()
  const featureToggle = useFeatureToggle()

  const { input, onUpdate, result, searchBoxDebounceInMilliseconds, jobTopicTrees } = props
  const { topic, actionCompleted } = result ?? {}
  const { customerLocationId } = input

  const setTopic = (topicReference: JobTopicType | undefined) => {
    const topic = JobTopics.find((t) => t.reference === topicReference)
    onUpdate({ ...result, topic, workflowV2: !!topic?.v2 })
  }
  const [treeViewId, setTreeViewId] = useState('select-topic')
  const [searchTerm, setSearchTerm] = useState('')
  const [isCompressedDisplay, setIsCompressedDisplay] = useLocalStorage('select-topic-compact', false)

  const setActionCompleted = (checked: boolean) => onUpdate({ ...result, actionCompleted: checked })

  const debouncedSearchTerm = useDebounce(searchTerm, searchBoxDebounceInMilliseconds ?? 150)
  const trimmedSearchTerm = debouncedSearchTerm.trim()

  useEffect(() => {
    const newTreeViewId = `select-topic-${trimmedSearchTerm}`
    setTreeViewId(newTreeViewId)

    const element = document.getElementById('search-topic')
    if (element) {
      const scrollOptions: ScrollIntoViewOptions = {
        block: 'start',
        behavior: 'auto'
      }
      setTimeout(() => {
        elementScrollIntoView(element, scrollOptions)
      }, 0)
    }
  }, [trimmedSearchTerm])

  const value = actionCompleted && topic ? topic.label : undefined

  const selectedTopicReference = topic?.reference
  const showBadges = appContext.isTest === false

  const jobTopicTreeModels = jobTopicTrees.map((tree) => {
    return {
      title: tree.title,
      completionStatus: tree.completionStatus,
      items: tree.items
        .map((item) =>
          createTreeItem({
            item,
            selectedTopicReference,
            searchTerm: trimmedSearchTerm,
            onSelect: setTopic,
            showBadges
          })
        )
        .filter((child) => !!child) as TreeItemViewModel[]
    }
  })

  return (
    <WorkflowAction
      title="Job topic"
      value={value}
      onClickChange={() => setActionCompleted(false)}
      editable={input.editable}
    >
      {featureToggle?.workflow.scheduledService && customerLocationId && (
        <>
          <Callout type="note" title="Scheduled services for this location" />
          <CustomerLocationScheduledServices customerLocationId={customerLocationId} />
          <EuiSpacer />
        </>
      )}

      <Callout type="note" title="Select most appropriate job topic for customer's initial description">
        <EuiText>
          If you can't find a relevant topic please select{' '}
          <EuiLink onClick={() => setTopic(JobTopicType.Other)}>Other</EuiLink> to continue
        </EuiText>
      </Callout>
      <EuiSpacer />

      <EuiFlexGroup alignItems="center">
        <EuiFlexItem grow={true}>
          <EuiFieldSearch
            id="search-topic"
            placeholder="Search job topics..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiSwitch
            checked={isCompressedDisplay}
            onChange={(e) => setIsCompressedDisplay(e.target.checked)}
            compressed={true}
            label="Compact"
          />
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiSpacer size="m" />

      <div className="job-topic-tree-container">
        {jobTopicTreeModels.map((tree) => (
          <div key={tree.title}>
            {!!tree.items.length && (
              <>
                <EuiTitle size={isCompressedDisplay ? 'xxs' : 'xs'}>
                  <h2>
                    {tree.title}
                    {!!tree.completionStatus && showBadges && (
                      <>
                        {' '}
                        <EuiBadge color="warning" style={{ marginLeft: '8px' }}>
                          {makeHumanReadable(tree.completionStatus)}
                        </EuiBadge>
                      </>
                    )}
                  </h2>
                </EuiTitle>
                <EuiSpacer size="s" />
                <EuiTreeView
                  key={`electrical-${treeViewId}`}
                  id={`electrical-${treeViewId}`}
                  aria-label="Select topic"
                  display={isCompressedDisplay ? 'compressed' : 'default'}
                  items={tree.items.map((item) => item.node)}
                  expandByDefault={true}
                />
                <EuiSpacer />
              </>
            )}
          </div>
        ))}

        <div>
          Cannot find your topic? Please select <EuiLink onClick={() => setTopic(JobTopicType.Other)}>other</EuiLink>{' '}
          for now
          <EuiSpacer />
        </div>
      </div>
      <EuiHorizontalRule />
      <EuiRadio
        id="select-topic-other"
        checked={selectedTopicReference === JobTopicType.Other}
        onChange={(e) => setTopic(e.target.checked ? JobTopicType.Other : undefined)}
        label="Other: none of the above match customer's description"
      />

      <EuiSpacer />
      <EuiButton id="select-topic-next-button" onClick={() => setActionCompleted(true)} disabled={!topic?.reference}>
        Next
      </EuiButton>
      <EuiSpacer />
    </WorkflowAction>
  )
}
