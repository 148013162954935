import { EuiButton, EuiSpacer } from '@elastic/eui'
import { JobType, TradeType } from '../../../api/generated-types'
import { Callout } from '../../../common/callout'
import { iconForJobType } from '../../../jobs/job-category-selector'
import { QuestionDefinitions, getNotesGenerator } from '../../helpers/notes-generator'
import { getResultUpdater } from '../../helpers/results-updater'
import { SingleSelectField } from '../../question-fields/single-select-question-field'
import { WorkflowActionProps } from '../../workflow-model'
import { TopicActionInput, TopicActionResult } from '../action-topic-model'
import { TopicWorkflowAction } from '../action-topic-view'

export enum CommercialCustomerType {
  NewCustomer = 'NewCustomer',
  ExistingCustomer = 'ExistingCustomer',
  AccountCustomer = 'AccountCustomer'
}

export interface TopicCommercialElectricalActionResult extends TopicActionResult {
  commercialCustomerType?: CommercialCustomerType
  jobType?: JobType
}

const questionDefinitions: QuestionDefinitions<TopicCommercialElectricalActionResult> = {}
const notesGenerator = getNotesGenerator(questionDefinitions)

export const TopicCommercialElectricalAction = (
  props: WorkflowActionProps<TopicActionInput, TopicCommercialElectricalActionResult>
) => {
  const { result, input, onUpdate } = props
  const { jobType, commercialCustomerType } = result ?? {}

  const onNext = () =>
    onUpdate({
      ...result,
      actionCompleted: true,
      trade: TradeType.Electrical,
      category: 'General',
      type: jobType,
      workRequiredNotes: generatedNotes
    })

  const generatedNotes = notesGenerator.generateNotes(result ?? {})
  const updateResult = getResultUpdater(result ?? {}, onUpdate).updateResult

  const canComplete = !!jobType

  return (
    <TopicWorkflowAction input={input} onUpdate={onUpdate} result={result}>
      <SingleSelectField
        question={notesGenerator.getQuestion('commercialCustomerType')}
        options={[
          {
            id: CommercialCustomerType.NewCustomer
          },
          {
            id: CommercialCustomerType.ExistingCustomer
          },
          {
            id: CommercialCustomerType.AccountCustomer
          }
        ]}
        answer={commercialCustomerType}
        changeAnswer={(commercialCustomerType) => updateResult({ commercialCustomerType })}
      />

      <SingleSelectField
        question={notesGenerator.getQuestion('jobType')}
        options={[
          {
            id: JobType.Service,
            icon: iconForJobType(JobType.Service)
          },
          {
            id: JobType.Quote,
            icon: iconForJobType(JobType.Quote)
          }
        ]}
        answer={jobType}
        changeAnswer={(jobType) => updateResult({ jobType })}
      />

      <EuiSpacer />
      <Callout type="note" title="Call Electrical DSR Q 99973" />

      <EuiSpacer />
      <EuiButton disabled={!canComplete} onClick={() => onNext()}>
        Next
      </EuiButton>
    </TopicWorkflowAction>
  )
}
