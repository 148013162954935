import { useMutation } from '@apollo/client'
import { EuiFlexGroup, EuiFlexItem, EuiLoadingSpinner, EuiSwitch } from '@elastic/eui'
import { pullAllBy } from 'lodash-es'
import {
  AttributeRequirement,
  AttributeRequirementInput,
  JobFragment,
  UpdateJobAttributesDocument
} from '../../api/generated-types'

interface AttributeCovidVaccinatedJobEditProps {
  editable?: boolean
  job: JobFragment
}

export const AttributeCovidVaccinatedJobEdit = ({ editable, job }: AttributeCovidVaccinatedJobEditProps) => {
  const [updateJobAttributes, { loading }] = useMutation(UpdateJobAttributesDocument)

  const Covid19Attribute = { attributeId: 'VaccinatedCovid19' }
  const attributes = job?.defaultAppointmentRequirements?.attributes

  const checked = !!attributes?.find(
    ({ attributeId }: AttributeRequirement) => attributeId === Covid19Attribute.attributeId
  )

  const handleOnChange = () => {
    const toggleChecked = !checked

    const changeAttributes: AttributeRequirementInput[] = attributes!.concat(Covid19Attribute)
    !toggleChecked && pullAllBy(changeAttributes, [Covid19Attribute], 'attributeId')

    console.log('update job attributes', changeAttributes)

    updateJobAttributes({
      variables: {
        input: { job: job.id!, attributes: changeAttributes.map(({ attributeId }) => ({ attributeId })) }
      }
    })
  }
  return (
    <EuiFlexGroup gutterSize="s" direction="row" alignItems="center">
      <EuiFlexItem grow={true}>
        <EuiSwitch
          label="COVID19 Vaccinated"
          checked={checked}
          onChange={handleOnChange}
          disabled={!attributes || !editable || loading}
        />
      </EuiFlexItem>
      <EuiFlexItem>{loading && <EuiLoadingSpinner size="m" />}</EuiFlexItem>
    </EuiFlexGroup>
  )
}
