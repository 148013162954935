import { EuiButton, EuiFlexGroup, EuiText } from '@elastic/eui'
import { CustomerType, JobTopicType } from '@fallonsolutions/types'
import '../../../../src/static/css/why-fallons.css'
import { TradeType } from '../../../api/generated-types'
import { Callout } from '../../../common/callout'
import { ContactRole } from '../action-customer-qualification'
import { JobCloseActionInput } from '../action-job-close'

export interface WhyFallonsViewProps {
  workflowCompleted: () => void
  input: JobCloseActionInput
}

export const WhyFallonsView = (props: WhyFallonsViewProps) => {
  const { workflowCompleted, input } = props
  const { jobClassification, jobTopic, customerQualification } = input ?? {}
  const customerTypeVal =
    (customerQualification.contactRole ?? ContactRole.HomeOwner) === ContactRole.HomeOwner
      ? CustomerType.Domestic
      : CustomerType.Commercial
  const { trade } = jobClassification.topic ?? {}
  const jobTopicReference = jobTopic?.topic?.reference ?? undefined
  const tradePoints = getTradeSpecificPoints(trade, jobTopicReference, customerTypeVal)

  const isHvacInspection =
    jobTopic.topic?.reference === JobTopicType.HVACInspection ||
    jobTopic.topic?.reference === JobTopicType.HVACConditionAndFilterCleanReport
  return (
    <>
      <Callout type="note">
        <EuiText> Explain to the customer why Fallons is the right choice</EuiText>
      </Callout>
      <EuiFlexGroup direction="column">
        <Callout type="script" title="Why Fallons">
          <ul className="why-fallons">
            <li key="why-fallons-1">
              You’re booking with Fallon’s, which has been trusted to look after Queensland homes and businesses for 60
              yrs
            </li>
            {!isHvacInspection && (
              <li key="why-fallons-2">
                We value your time. that’s why we strive to arrive on time, every time. In the event that we’re late,
                you can get up to $100 off!{' '}
              </li>
            )}
            {tradePoints.map((tradePoint, idx) => {
              return <li key={tradePoint + idx}>{tradePoint}</li>
            })}
          </ul>
        </Callout>
      </EuiFlexGroup>
      <EuiButton onClick={workflowCompleted}> Next</EuiButton>
    </>
  )
}

const getTradeSpecificPoints = (
  trade: TradeType | undefined,
  reference: JobTopicType | undefined,
  customerType: CustomerType
): string[] => {
  switch (trade) {
    case TradeType.Electrical:
      return [
        'Booking with Fallon’s means you get 24/7 support all year with after-hours support and a limited lifetime labor warranty',
        'Your job will be attended by technicians who receive fortnightly training, ensuring you only get the best quality workmanship and products'
      ]
    case TradeType.Carpentry:
      return [
        'You’ll receive peace of mind with upfront prices - no tradie bill shock, hidden prices or surprise add ons',
        'Your job will be attended by technicians who receive fortnightly training, ensuring you only get the best quality workmanship and products'
      ]
    case TradeType.Drains:
      return [
        'Your job will be attended by technicians who receive fortnightly training, ensuring you only get the best quality workmanship and products',
        'Use High-pressure water and CCTV to visually inspect the issue and offer Non-dig pipe repairs, ensuring theirs no unnecessary damage to your home'
      ]
    case TradeType.Security:
      return [
        'You’re booking with Fallon’s, one of the most trusted names in Security, who are part of the Australian security industry association',
        'Both our alarm and CCTV systems can be controlled and monitored from your mobile device, giving you peace of mind all year round, wherever you are'
      ]
    case TradeType.HVAC:
      if (reference === JobTopicType.HVACService) {
        return [
          'Booking with Fallons ensures you receive a deep clean and intensive tune-up of your AC, prolonging the life of your unit and reducing the operating costs',
          'We offer you different levels of service to suit your AC unit and your price range'
        ]
      } else if (reference === JobTopicType.HVACRepair) {
        return [
          'You will have your AC diagnosed professionally and accurately, much like when you go to the doctor AND get peace of mind with upfront prices - no tradie bill shock, hidden prices, or surprise add ons',
          'You’ll receive a comp membership with Fallons, giving you 15% off HVAC repairs, along with our other trades like Electrical + Plumbing, AND receive a series of complimentary trade visits'
        ]
      } else if (reference === JobTopicType.HVACQuoteInstall) {
        return [
          "We provide a Quote on the day, so you're not sitting around waiting and our units come with a limited lifetime labour warranty",
          'Our techs conduct a professional heat load calculation of your property to ensure you are getting the right size unit in the right location '
        ]
      } else if (reference === JobTopicType.HVACConditionAndFilterCleanReport) {
        return ['We offer you different levels of service to suit your AC unit and your price range']
      }
      return []
    case TradeType.Data:
      return [
        'All of our techs are registered open cablers and they receive fortnightly training to ensure that you only get the best quality workmanship and products',
        'We can also assist with your home internet needs and WIFI coverage'
      ]
    case TradeType.Solar:
      return [
        'You’ll be dealing with one of the most trusted names in solar - We only use blue chip solar panels and we install products that have a 25-year warranty',
        'We offer continued maintenance, cleaning, and service work on your system after we have installed it, and we offer both A/C and D/C solar to suit all your needs'
      ]
    case TradeType.Appliances:
      return [
        'You will have your appliance diagnosed professionally and accurately, much like when you go to the doctor AND get peace of mind with upfront prices - no tradie bill shock, hidden prices, or surprise add ons',
        ...(customerType === CustomerType.Domestic
          ? [
              'You’ll receive a comp membership with Fallons, giving you 15% off appliance repairs, along with our other trades like Electrical, Plumbing + HVAC, AND receive a series of complimentary trade visits'
            ]
          : [])
      ]
    case TradeType.Plumbing: {
      if (reference === JobTopicType.ToiletInstall || reference === JobTopicType.ToiletRepair) {
        return [
          'We pride ourselves on leaving the job site clean, this includes removing old toilets from the site and we’re able to source different components through our large supplier network, so we’ll have something to suit your needs',
          'Your job will be attended by technicians who receive fortnightly training, ensuring you only get the best quality workmanship and products'
        ]
      } else if (reference === JobTopicType.HotWater || reference === JobTopicType.HotWaterInspection99) {
        return [
          " At Fallon, we pride ourselves on doing same-day installations on replacements. If we can't install your new system in 24 hrs, you get $100 cash back",
          'We remove and recycle your old unit, and ensure your home is left clean and tidy, while giving your HWS a 10yr manufacturer warranty'
        ]
      } else if (reference === JobTopicType.TapInstall || reference === JobTopicType.TapRepair) {
        return [
          'We only use high-quality tapware with long warranties and we have a large range to suit your needs',
          'Your job will be attended by technicians who receive fortnightly training, ensuring you only get the best quality workmanship and products'
        ]
      } else if (reference === JobTopicType.WaterLeak || reference === JobTopicType.WaterLeakDetection) {
        return [
          'Our techs use sensitive acoustic sound equipment to detect leaks, eliminating unnecessary damage to your home',
          'Your job will be attended by technicians who receive fortnightly training, ensuring you only get the best quality workmanship and products'
        ]
      } else {
        return [
          'Your job will be attended by technicians who receive fortnightly training, ensuring you only get the best quality workmanship and products',
          ' You’ll receive peace of mind with upfront prices - no tradie bill shock, hidden prices or surprise add ons'
        ]
      }
    }
  }
  return []
}
