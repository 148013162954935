import { EuiFlexGroup, EuiFlexItem, EuiHorizontalRule, EuiIcon, EuiSpacer, EuiSwitch, EuiText } from '@elastic/eui'
import { CommunicationChannelSelection } from '../api/generated-types'
import { Callout } from '../common/callout'
import { formatPhone } from '../common/phone'

export enum BookingConfirmationPreferenceDisplayOptions {
  SwitchAtStart = 'SwitchAtStart',
  SwitchAtEnd = 'SwitchAtEnd'
}
export interface BookingConfirmationPreferenceProps {
  isChecked: boolean
  value: CommunicationChannelSelection
  setCommunicationChannel: (value: CommunicationChannelSelection) => void
  mobileNumbers: string[]
  emailAddresses: string[]
  displayType: BookingConfirmationPreferenceDisplayOptions
  isDisabled: boolean
  hubspotEmailEnabled: boolean
}

export const BookingConfirmationPreferenceComponent = ({
  isChecked,
  value,
  setCommunicationChannel,
  mobileNumbers,
  emailAddresses,
  displayType,
  isDisabled,
  hubspotEmailEnabled
}: BookingConfirmationPreferenceProps) => {
  const handleBookingConfirmationToggleChange = () => {
    if (value === undefined) {
      setCommunicationChannel(CommunicationChannelSelection.All)
    } else if (value === CommunicationChannelSelection.None) {
      setCommunicationChannel(CommunicationChannelSelection.All)
    } else {
      setCommunicationChannel(CommunicationChannelSelection.None)
    }
  }

  const mobileNumberProvided = mobileNumbers.length > 0

  return (
    <EuiFlexGroup gutterSize="none" direction="column" style={{ borderRadius: '6px', padding: '12px' }}>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="none" alignItems="center" justifyContent="spaceBetween">
          <EuiFlexItem grow={false}>
            {displayType === BookingConfirmationPreferenceDisplayOptions.SwitchAtStart && (
              <EuiSwitch
                checked={isChecked}
                label=""
                value={value}
                onChange={() => handleBookingConfirmationToggleChange()}
                aria-readonly={isDisabled}
              />
            )}
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiIcon type={'returnKey'} size="l" style={{ marginRight: '9px' }} />
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <div style={{ fontSize: '16px', fontWeight: 600 }}>Send Confirmation</div>
          </EuiFlexItem>
          <EuiFlexItem grow={true} />
          <EuiFlexItem grow={false}>
            {displayType === BookingConfirmationPreferenceDisplayOptions.SwitchAtEnd && (
              <EuiSwitch
                checked={isChecked}
                label=""
                value={value}
                onChange={() => handleBookingConfirmationToggleChange()}
              />
            )}
          </EuiFlexItem>
        </EuiFlexGroup>
        {isChecked ? (
          <>
            <EuiHorizontalRule margin="s" />
            <EuiFlexGroup direction="row" alignItems={'flexEnd'}>
              {mobileNumberProvided ? (
                <>
                  <EuiFlexItem grow={false}>
                    <EuiIcon type={'mobile'} size="l" style={{ marginRight: '9px' }} />
                  </EuiFlexItem>
                  <EuiFlexItem>
                    <EuiText>Send SMS confirmation: {mobileNumbers.map((p) => formatPhone(p)).join(', ')}.</EuiText>
                  </EuiFlexItem>
                </>
              ) : (
                <EuiFlexItem grow={true}>
                  <Callout type="warning" title="No mobile number provided" />
                </EuiFlexItem>
              )}
            </EuiFlexGroup>
            <EuiSpacer />
            {hubspotEmailEnabled ? (
              <EuiFlexGroup direction="row" alignItems={'flexEnd'}>
                <EuiFlexItem grow={false}>
                  <EuiIcon type={'email'} size="l" style={{ marginRight: '9px' }} />
                </EuiFlexItem>
                <EuiFlexItem>
                  <EuiText>
                    Send email confirmation:{' '}
                    {emailAddresses.length === 0 ? 'No email provided' : emailAddresses.join(', ')}.
                  </EuiText>
                </EuiFlexItem>
              </EuiFlexGroup>
            ) : (
              <EuiFlexGroup direction="row" alignItems={'flexStart'}>
                <EuiFlexItem grow={false}>
                  <EuiIcon type={'email'} size="l" style={{ marginRight: '9px' }} />
                </EuiFlexItem>
                <EuiFlexItem>
                  <EuiText>Send email confirmation: Off.</EuiText>
                  <EuiText>
                    Some trade(s) and all commercial customers require manual booking confirmation emails.
                  </EuiText>
                </EuiFlexItem>
              </EuiFlexGroup>
            )}
          </>
        ) : (
          <>
            <EuiSpacer />
            <EuiText size="s">Automatic email / sms confirmation off.</EuiText>
          </>
        )}
      </EuiFlexItem>
    </EuiFlexGroup>
  )
}
