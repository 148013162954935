import React, { useState } from 'react'
import { EuiLink } from '@elastic/eui'
import { CreatePayment } from './create-payment'
import { GetJobDocument, InvoiceFragment } from '../api/generated-types'
import { useQuery } from '@apollo/client'
import { JobFragment } from '@fallonsolutions/types'

export interface CreatePaymentButtonProps {
  invoice: InvoiceFragment
  job: JobFragment | undefined
}

export const CreatePaymentButton = (props: CreatePaymentButtonProps) => {
  const { invoice, job } = props
  const [isModalVisible, setIsModalVisible] = useState(false)

  const closeModal = () => setIsModalVisible(false)
  const showModal = () => setIsModalVisible(true)

  return (
    <div>
      <EuiLink onClick={showModal}>Create Payment</EuiLink>
      {isModalVisible && <CreatePayment invoice={invoice} closeModal={closeModal} job={job ?? undefined} />}
    </div>
  )
}

export interface CreatePaymentButtonWrapperProps {
  invoice: InvoiceFragment
}
export const CreatePaymentButtonWrapper = (props: CreatePaymentButtonWrapperProps) => {
  const { invoice } = props
  const id = invoice.job?.id
  const { data, loading } = useQuery(GetJobDocument, { variables: { id: id ?? '' }, skip: !id })
  const job = data?.getJob as JobFragment | undefined

  return <>{loading ? <div>Loading job </div> : <CreatePaymentButton invoice={invoice} job={job ?? undefined} />}</>
}
