import { EuiSpacer, EuiSwitch, EuiText } from '@elastic/eui'
import { EnquiryDirection } from '../../api/generated-types'
import { useAuthenticated } from '../../auth/authenticated-context'
import { Callout } from '../../common/callout'
import { useFastBooking } from '../../common/persisted-state'
import { PillGroup, PillGroupOption } from '../../pill/pill-group'
import { WorkflowAction } from '../workflow-action'
import { BaseActionInput, BaseActionResult, EnquiryTypeReference, WorkflowActionProps } from '../workflow-model'

export type EnquiryTypeActionInput = BaseActionInput

enum EnquiryTypeGroupStatus {
  Dev = 'dev',
  Draft = 'draft',
  Done = 'done'
}

export interface EnquiryTypeActionResult extends BaseActionResult {
  enquiryType?: EnquiryTypeReference
  direction?: EnquiryDirection
}

interface EnquiryTypeGroup {
  label: string
  options: PillGroupOption[]
  status?: EnquiryTypeGroupStatus | undefined
}

export const EnquiryTypeAction = (props: WorkflowActionProps<EnquiryTypeActionInput, EnquiryTypeActionResult>) => {
  const { onUpdate, onBack, result, input } = props

  const [fastBookingScript, setFastBookingScript] = useFastBooking(false)

  const { userFragment } = useAuthenticated()
  const canSetFastBookingScript = userFragment.permissions?.createBookingManual === true

  const session = useAuthenticated().user

  const enquiryTypeGroups: EnquiryTypeGroup[] = [
    {
      label: 'Common',
      options: [
        { id: EnquiryTypeReference.NewBooking, label: 'New job', icon: 'wrench' },
        {
          id: EnquiryTypeReference.Rebooking,
          label: 'Rebooking',
          icon: 'calendar'
        },
        { id: EnquiryTypeReference.UpdateJob, label: 'Update job', icon: 'pencil' },
        {
          id: EnquiryTypeReference.UpdateCustomer,
          label: 'Update customer details',
          icon: 'user'
        },
        {
          id: EnquiryTypeReference.CancelJob,
          label: 'Cancellation',
          icon: 'error'
        },
        {
          id: EnquiryTypeReference.ConfirmJob,
          label: 'Confirm job date/time',
          icon: 'clock'
        },
        { id: EnquiryTypeReference.ETA, label: 'Technician ETA', icon: 'clock' },
        { id: EnquiryTypeReference.Feedback, label: 'Feedback', icon: 'faceHappy' },
        {
          id: EnquiryTypeReference.Complaint,
          label: 'Complaint',
          icon: 'faceSad'
        },
        {
          id: EnquiryTypeReference.Transfer,
          label: 'Transfer call',
          icon: 'merge'
        }
      ]
    },
    {
      label: 'Finance',
      options: [
        {
          id: EnquiryTypeReference.PayInvoice,
          label: 'Pay invoice',
          icon: 'document'
        },
        {
          id: EnquiryTypeReference.ResendInvoice,
          label: 'Resend invoice',
          icon: 'email'
        },
        {
          id: EnquiryTypeReference.DisputeInvoice,
          label: 'Question or dispute invoice',
          icon: 'questionInCircle'
        }
      ]
    },
    {
      label: 'Other',
      options: [
        {
          id: EnquiryTypeReference.Employment,
          label: 'Employment',
          icon: 'users'
        },
        {
          id: EnquiryTypeReference.Membership,
          label: 'Membership',
          icon: 'starEmpty'
        },
        {
          id: EnquiryTypeReference.Media,
          label: 'Media or press',
          icon: 'videoPlayer'
        },
        {
          id: EnquiryTypeReference.Telemarketing,
          label: 'Telemarketing',
          icon: 'mobile'
        },
        { id: EnquiryTypeReference.Crisis, label: 'Crisis', icon: 'alert' },
        {
          id: EnquiryTypeReference.Other,
          label: 'Other',
          icon: 'questionInCircle'
        }
      ]
    }
  ]

  const enquiryTypeLabel = enquiryTypeGroups
    .map((g) => g.options.find((o) => o.id === result?.enquiryType)?.label)
    .filter((l) => !!l)[0]

  const handleOnSelectEnquiryType = (id: EnquiryTypeReference) => {
    onUpdate({ enquiryType: id, direction: EnquiryDirection.Inbound })
  }

  const handleChange = () => {
    onUpdate({ enquiryType: undefined, direction: EnquiryDirection.Inbound })
    onBack?.()
  }

  const value = result?.enquiryType ? enquiryTypeLabel : undefined

  // const actionElement = canSetFastBookingScript ? (
  //   <EuiSwitch
  //     label="Fast booking script"
  //     onChange={(event) => setFastBookingScript(event.target.checked)}
  //     checked={fastBookingScript}
  //     compressed
  //   />
  // ) : undefined

  return (
    <WorkflowAction
      title="Enquiry type"
      value={value}
      onClickChange={handleChange}
      editable={input.editable}
      // actionElement={actionElement}
    >
      {!fastBookingScript && (
        <>
          <Callout
            type="script"
            title={`Welcome to Fallon Solutions, you’re speaking with ${
              session?.firstName ?? '(agent)'
            } how may I help you?`}
          />
          <Callout type="script" iconType="help">
            <EuiText>
              <div>You are absolutely in the right place, that’s definitely something I can help with</div>
              <EuiSpacer size="s" />
              <div>To start can I please have your full name and suburb?</div>
            </EuiText>
          </Callout>
          <EuiSpacer />
        </>
      )}

      {enquiryTypeGroups.map((group) => (
        <div key={group.label}>
          <PillGroup
            title={group.label}
            options={group.options}
            value={result?.enquiryType}
            onSelect={(id) => handleOnSelectEnquiryType(id as EnquiryTypeReference)}
            onClear={() => handleChange()}
          />
          <EuiSpacer />
        </div>
      ))}
    </WorkflowAction>
  )
}
