import { EuiButton, EuiSpacer } from '@elastic/eui'
import { useEffect } from 'react'
import { CustomerType, JobType, TradeType } from '../../../api/generated-types'
import { JobCategorySelector } from '../../../jobs/job-category-selector'
import { ContactRole } from '../../actions/action-customer-qualification'
import { WorkflowActionProps } from '../../workflow-model'
import { TopicActionInput, TopicActionResult } from '../action-topic-model'
import { TopicWorkflowAction } from '../action-topic-view'

export const TopicOtherAction = (props: WorkflowActionProps<TopicActionInput, TopicActionResult>) => {
  const { onUpdate, result, input } = props

  const { customerType, trade, category, type } = result ?? {}

  const setCustomerType = (customerType?: CustomerType) => onUpdate({ ...result, customerType })
  const setType = (type?: JobType) => onUpdate({ ...result, type })
  const setTrade = (trade?: TradeType) => onUpdate({ ...result, trade, category: undefined })
  const setCategory = (category?: string) => onUpdate({ ...result, category })

  const onNext = () =>
    onUpdate({
      ...result,
      actionCompleted: true,
      trade,
      category,
      type,
      customerType
    })

  const jobClassificationCompleted = !!trade && !!type && !!category && !!customerType

  useEffect(() => {
    if (input.customerContactRole) {
      setCustomerType(
        input.customerContactRole === ContactRole.Commercial ? CustomerType.Commercial : CustomerType.Domestic
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <TopicWorkflowAction input={input} onUpdate={onUpdate} result={result}>
      <JobCategorySelector
        customerType={customerType}
        trade={trade}
        type={type}
        category={category}
        clearCustomerType={setCustomerType}
        clearTrade={setTrade}
        clearType={setType}
        clearCategory={setCategory}
        setCustomerType={setCustomerType}
        setTrade={setTrade}
        setType={setType}
        setCategory={setCategory}
        showAxiaCode={false}
      />

      {jobClassificationCompleted && (
        <>
          <EuiSpacer />
          <EuiButton onClick={() => onNext()}>Next</EuiButton>
          <EuiSpacer />
        </>
      )}
    </TopicWorkflowAction>
  )
}
