import { ReviewStatusType } from '../api/generated-types'
import '../static/css/review-status-badge.css'

export interface ReviewStatusBadgeProps {
  status: ReviewStatusType
  title?: string
}

export const ReviewStatusBadge = ({ status, title }: ReviewStatusBadgeProps) => {
  return (
    <span className={`review-status-badge review-status-badge--${status.toLowerCase()}`} title={title}>
      {status}
    </span>
  )
}
