import { useMutation } from '@apollo/client'
import {
  EuiAvatar,
  EuiButton,
  EuiButtonIcon,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHorizontalRule,
  EuiIcon,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiOverlayMask,
  EuiSpacer,
  EuiText
} from '@elastic/eui'
import { compact, first, groupBy, map } from 'lodash-es'
import { DateTime } from 'luxon'
import { useState } from 'react'
import {
  AppointmentLinkFragment,
  AppointmentSource,
  AppointmentStatusType,
  AttendedStatusType,
  JobFragment,
  JobStatusType,
  JobType,
  RestoreAppointmentDocument
} from '../api/generated-types'
import { dateConfig } from '../common/date-config-luxon'
import { CancelAppointmentForm } from '../schedule/schedule-cancel-appointment-form'
import { getJobTypeIcon } from '../schedule/schedule-event-context-menu/schedule-event-context-menu-job-type-panel'
import { appointmentString, compareAppointmentTime } from './job-utils'

export interface JobSummaryAppointmentsProps {
  job: JobFragment
}

export const JobSummaryAppointments = (props: JobSummaryAppointmentsProps) => {
  const { job } = props
  const appointments = compact(job.appointments ?? []).sort((a, b) => compareAppointmentTime(a, b))

  const appointmentsByDate = map(
    groupBy(appointments, (a) => {
      return a.scheduled?.from ? DateTime.fromISO(a.scheduled?.from).startOf('day').toISO() : 'No date'
    }),
    (appointments, date) => {
      return { date, appointments }
    }
  )

  return (
    <>
      {appointmentsByDate.map((group, groupIndex) => (
        <div key={group.date}>
          <EuiFlexGroup>
            <EuiFlexItem grow={false}>
              <EuiText size="s" style={{ fontWeight: 600, color: '#434A5C' }}>
                {DateTime.fromISO(group.date).toFormat(dateConfig.fullDate)}
              </EuiText>
            </EuiFlexItem>
            <EuiFlexItem grow={true} />
          </EuiFlexGroup>
          <EuiSpacer size="s" />
          {group.appointments.map((appointment, index) => (
            <JobSummaryAppointment
              key={appointment.id}
              job={job}
              appointment={appointment}
              horizontalRule={groupIndex < appointmentsByDate.length - 1 || index < group.appointments.length - 1}
            />
          ))}
          <EuiSpacer size="m" />
        </div>
      ))}

      {appointments.length <= 0 && (
        <>
          <EuiText>This job doesn't have any appointments.</EuiText>
          <EuiText>
            Job start date:{' '}
            {job.legacy?.startDate
              ? DateTime.fromISO(job.legacy?.startDate).toFormat(dateConfig.fullDate)
              : 'No start date'}
          </EuiText>
        </>
      )}
    </>
  )
}

interface JobSummaryAppointmentProps {
  job: JobFragment
  appointment: AppointmentLinkFragment
  idx?: number
  horizontalRule?: boolean
}

const JobSummaryAppointment = (props: JobSummaryAppointmentProps) => {
  const { job, appointment, horizontalRule } = props

  const [showCancelAppointmentMenu, setShowCancelAppointmentMenu] = useState<boolean>(false)
  const [showRestoreAppointmentMenu, setShowRestoreAppointmentMenu] = useState<boolean>(false)

  const isCancelled = appointment.status?.status === AppointmentStatusType.Cancelled
  const isAppointmentAttended = appointment.attended === AttendedStatusType.Attended

  const canCancelAppointment =
    appointment.source === AppointmentSource.Platform && !isCancelled && !isAppointmentAttended

  const jobStatusesToAppointmentPreventRestoring: JobStatusType[] = [JobStatusType.Cancelled, JobStatusType.Complete]
  const canRestoreAppointment =
    appointment.source === AppointmentSource.Platform &&
    isCancelled &&
    !jobStatusesToAppointmentPreventRestoring.includes(job.status?.status ?? JobStatusType.NotStarted)

  const closeCancelAppointmentMenu = () => setShowCancelAppointmentMenu(false)
  const closeRestoreAppointmentMenu = () => setShowRestoreAppointmentMenu(false)

  const AppointmentView = (props: { showActions: boolean; compactView: boolean }) => {
    const technician = first(appointment.technicians)
    const isCancelled = appointment.status?.status === AppointmentStatusType.Cancelled
    const cancelDate =
      isCancelled && appointment.status?.date
        ? DateTime.fromISO(appointment.status.date).setZone(dateConfig.defaultTimezone).toFormat(dateConfig.shortDate)
        : undefined

    const jobType = appointment.jobType ?? job.type

    return (
      <EuiFlexGroup
        key={appointment.id}
        data-test-id="job-summary-appointment"
        alignItems="center"
        justifyContent="flexStart"
        gutterSize="s"
      >
        <EuiFlexItem
          grow={false}
          className="truncate"
          style={{ width: '90px', textAlign: 'left', alignItems: 'flex-start' }}
        >
          <EuiFlexGroup gutterSize="xs" alignItems="center">
            <EuiFlexItem grow={false}>{getJobTypeIcon(jobType ?? JobType.None)}</EuiFlexItem>
            <EuiFlexItem grow={true}>{jobType}</EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
        <EuiFlexItem style={{ textAlign: 'left', whiteSpace: 'nowrap' }} grow={false}>
          <EuiText size="s" style={isCancelled ? { textDecoration: 'line-through', color: '#727888' } : {}}>
            {appointmentString(appointment, { includeDate: false })}
            {isAppointmentAttended && <EuiIcon type="check" color="success" size="s" style={{ marginLeft: '5px' }} />}
          </EuiText>
        </EuiFlexItem>

        <EuiFlexItem grow={true} />
        <EuiFlexItem
          grow={false}
          className="truncate"
          style={isCancelled ? { color: '#727888', textAlign: 'right' } : {}}
        >
          {isCancelled && cancelDate ? (
            <> cancelled {cancelDate}</>
          ) : technician ? (
            <EuiText size="s">
              <EuiAvatar
                size="s"
                name={technician?.contactDetail?.fullName ?? 'No name'}
                imageUrl={technician?.avatar ?? ''}
                style={{ lineHeight: '20px', blockSize: '18px', inlineSize: '18px', marginTop: '-2px' }}
              />
              <a
                href={`/users/${technician?.id}`}
                target="_blank"
                rel="noopener noreferrer"
                style={{ marginLeft: '3px' }}
              >
                {technician?.contactDetail?.fullName ?? 'No name'}
              </a>
            </EuiText>
          ) : (
            <EuiText size="s">No technician assigned</EuiText>
          )}
        </EuiFlexItem>

        {props.showActions && !canCancelAppointment && !canRestoreAppointment && (
          <EuiFlexItem grow={false} style={{ width: '24px' }} />
        )}

        {props.showActions && canCancelAppointment && (
          <EuiFlexItem grow={false}>
            <EuiButtonIcon
              color="text"
              iconType="error"
              title="Cancel appointment"
              aria-label="Cancel appointment"
              onClick={() => setShowCancelAppointmentMenu(true)}
            />
          </EuiFlexItem>
        )}
        {props.showActions && canRestoreAppointment && (
          <EuiFlexItem grow={false}>
            <EuiButtonIcon
              color="text"
              iconType="help"
              title="Restore appointment"
              aria-label="Restore appointment"
              onClick={() => setShowRestoreAppointmentMenu(true)}
            />
          </EuiFlexItem>
        )}
      </EuiFlexGroup>
    )
  }

  const CancelForm = () => {
    return (
      <EuiOverlayMask>
        <EuiModal onClose={closeCancelAppointmentMenu}>
          <EuiModalHeader>
            <EuiModalHeaderTitle>Cancel appointment</EuiModalHeaderTitle>
          </EuiModalHeader>
          <EuiModalBody>
            <AppointmentView showActions={false} compactView={true} />
            <EuiHorizontalRule />
            <CancelAppointmentForm
              job={job}
              appointmentId={appointment.id}
              appointmentAttended={isAppointmentAttended}
              onSubmit={() => setShowCancelAppointmentMenu(false)}
            />
          </EuiModalBody>
        </EuiModal>
      </EuiOverlayMask>
    )
  }

  const RestoreForm = () => {
    const [restoreAppointment, { loading: restoringAppointment }] = useMutation(RestoreAppointmentDocument)

    const handleRestoreAppointment = async () => {
      await restoreAppointment({ variables: { input: { id: appointment.id } } })
      setShowRestoreAppointmentMenu(false)
    }

    return (
      <EuiOverlayMask>
        <EuiModal onClose={closeRestoreAppointmentMenu}>
          <EuiModalHeader>
            <EuiModalHeaderTitle>Restore appointment</EuiModalHeaderTitle>
          </EuiModalHeader>
          <EuiModalBody>
            <AppointmentView showActions={false} compactView={true} />
            <EuiSpacer size="xl" />
          </EuiModalBody>
          <EuiModalFooter>
            <EuiButton
              onClick={handleRestoreAppointment}
              isLoading={restoringAppointment}
              fill
              disabled={!canRestoreAppointment}
            >
              Restore appointment
            </EuiButton>
          </EuiModalFooter>
        </EuiModal>
      </EuiOverlayMask>
    )
  }

  return (
    <>
      {showCancelAppointmentMenu && <CancelForm />}
      {showRestoreAppointmentMenu && <RestoreForm />}
      <AppointmentView showActions={true} compactView={false} />
      {horizontalRule && <EuiHorizontalRule size="full" margin="xs" />}
    </>
  )
}
