import { useMutation } from '@apollo/client'
import {
  EuiButtonIcon,
  EuiContextMenu,
  EuiContextMenuPanelDescriptor,
  EuiFlexGroup,
  EuiFlexItem,
  EuiLoadingSpinner,
  EuiPopover
} from '@elastic/eui'
import { compact, first } from 'lodash-es'
import { useState } from 'react'
import { UpdateJobReferrerDocument, UserLinkFragment } from '../api/generated-types'
import { useAuthenticated } from '../auth/authenticated-context'
import UserComboBox, { UserComboItem } from '../users/user-combo-box'
import UserLink, { UserLinkSubtitleDisplay } from '../users/user-link'

export interface JobReferrerProps {
  jobId: string
  referrer?: UserLinkFragment | undefined | null
}

export const JobReferrer = ({ jobId, referrer }: JobReferrerProps) => {
  const [isEditMode, setIsEditMode] = useState(false)
  const [isMenuVisible, setIsMenuVisible] = useState(false)
  const [newReferrer, setNewReferrer] = useState<UserComboItem | undefined>(undefined)

  const userFragment = useAuthenticated().userFragment
  const canUpdateJobReferrer = userFragment.permissions?.createBooking === true

  const [updateJobReferrer, { loading }] = useMutation(UpdateJobReferrerDocument, {
    refetchQueries: ['GetJob'],
    awaitRefetchQueries: true
  })

  const saveNewReferrer = () => {
    if (newReferrer) {
      updateJobReferrer({
        variables: {
          input: {
            jobId,
            referrerId: newReferrer.id
          }
        }
      })
    }
    setNewReferrer(undefined)
    setIsEditMode(false)
  }

  const cancelNewReferrer = () => {
    setNewReferrer(undefined)
    setIsEditMode(false)
  }

  const clearJobReferrer = () => {
    if (canUpdateJobReferrer) {
      updateJobReferrer({
        variables: {
          input: {
            jobId,
            referrerId: undefined
          }
        }
      })
    }
  }

  const menu: EuiContextMenuPanelDescriptor[] = [
    {
      id: 0,
      items: [
        {
          name: referrer ? 'Change referrer' : 'Add referrer',
          icon: 'plus',
          onClick: () => {
            setIsMenuVisible(false)
            setIsEditMode(true)
          }
        },
        ...(referrer
          ? [
              {
                name: 'Remove referrer',
                icon: 'trash',
                onClick: () => {
                  setIsMenuVisible(false)
                  clearJobReferrer()
                }
              }
            ]
          : [])
      ]
    }
  ]

  return (
    <>
      <div className="small-label">Referrer</div>
      {loading ? (
        <EuiLoadingSpinner />
      ) : isEditMode ? (
        <EuiFlexGroup gutterSize="s" alignItems="center">
          <EuiFlexItem grow={false}>
            <UserComboBox
              users={compact([newReferrer])}
              onChangeUsers={(users) => setNewReferrer(first(users))}
              singleSelection={true}
              initialEnabled={true}
              placeholder="Select referrer"
              label="Select referrer"
              compact={true}
            />
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiButtonIcon
              aria-label="Save"
              iconType="check"
              color="success"
              display="base"
              size="s"
              onClick={saveNewReferrer}
            />
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiButtonIcon
              aria-label="Cancel"
              iconType="cross"
              color="danger"
              display="base"
              size="s"
              onClick={cancelNewReferrer}
            />
          </EuiFlexItem>
        </EuiFlexGroup>
      ) : (
        <UserLink
          avatarSize="l"
          subtitle={[UserLinkSubtitleDisplay.Role]}
          user={loading ? undefined : referrer}
          emptyLabel="No referrer"
        >
          {canUpdateJobReferrer && (
            <EuiPopover
              isOpen={isMenuVisible}
              button={
                <EuiButtonIcon
                  aria-label="show actions"
                  iconType="boxesHorizontal"
                  color="text"
                  onClick={() => setIsMenuVisible(true)}
                />
              }
              closePopover={() => setIsMenuVisible(false)}
              ownFocus={true}
              panelPaddingSize="none"
            >
              <EuiContextMenu initialPanelId={0} panels={menu} />
            </EuiPopover>
          )}
        </UserLink>
      )}
    </>
  )
}
