import { SingleSelectField } from '../../question-fields/single-select-question-field'
import { NotesGenerator } from '../../helpers/notes-generator'
import { ResultUpdater } from '../../helpers/results-updater'
import { PoolPumpIssue, TopicPoolActionResult } from './action-topic-pool-model'
import { CustomerType } from '../../../api/generated-types'
import { includes } from 'lodash-es'
import { YesNoValue } from '../common/common-enums'

export interface PoolPumpsProps {
  result?: TopicPoolActionResult
  notesGenerator: NotesGenerator<TopicPoolActionResult>
  updateResult: ResultUpdater<TopicPoolActionResult>
}

export const PoolPumps = (props: PoolPumpsProps) => {
  const { result, notesGenerator, updateResult } = props
  const { pumpIssue, poolType, powerpointTripping } = result ?? {}

  const isBlockedOrLeaking = includes([PoolPumpIssue.Blocked, PoolPumpIssue.Leaking], pumpIssue)
  const isTrippingPower = pumpIssue === PoolPumpIssue.TrippingPower
  const isTrippingPowerPoolOnly = isTrippingPower && powerpointTripping === YesNoValue.No
  return (
    <>
      <SingleSelectField
        question={notesGenerator.getQuestion('pumpIssue')}
        options={[
          {
            id: PoolPumpIssue.Blocked
          },
          {
            id: PoolPumpIssue.Leaking
          },
          {
            id: PoolPumpIssue.TrippingPower
          }
        ]}
        answer={pumpIssue}
        changeAnswer={(pumpIssue) => updateResult({ pumpIssue, powerpointTripping: undefined, poolType: undefined })}
      />

      {isTrippingPower && (
        <>
          <SingleSelectField
            question={notesGenerator.getQuestion('powerpointTripping')}
            options={[
              {
                id: YesNoValue.Yes,
                label: 'Yes, still trips',
                icon: 'check'
              },
              {
                id: YesNoValue.No,
                icon: 'cross'
              }
            ]}
            answer={powerpointTripping}
            changeAnswer={(powerpointTripping) => updateResult({ powerpointTripping, poolType: undefined })}
          />
        </>
      )}

      {(isBlockedOrLeaking || isTrippingPowerPoolOnly) && (
        <>
          <SingleSelectField
            question={notesGenerator.getQuestion('poolType')}
            options={[
              {
                id: CustomerType.Domestic
              },
              {
                id: CustomerType.Commercial
              }
            ]}
            answer={poolType}
            changeAnswer={(poolType) => updateResult({ poolType })}
          />
        </>
      )}
    </>
  )
}
