import { useApolloClient } from '@apollo/client'
import {
  EuiBadge,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSelectableTemplateSitewide,
  EuiSelectableTemplateSitewideOption,
  EuiText,
  keys
} from '@elastic/eui'

import { useCallback, useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import {
  UniversalSearchDocument,
  UniversalSearchInput,
  UniversalSearchResult,
  UniversalSearchResultType
} from '../api/generated-types'
import { useDebounce } from '../common/use-debounce'
import '../static/css/universal-search.css'

export enum UniversalSearchSelectAction {
  RedirectToItem,
  UseOnSelectCallback
}

interface UniversalSearchProps {
  onSelectAction: UniversalSearchSelectAction
  placeholder?: string
  types?: UniversalSearchResultType[]
  compressed?: boolean
  onSelect?: (item: UniversalSearchItem) => void
}

export type UniversalSearchItem = Omit<UniversalSearchResult, 'created' | 'updated'>

const UniversalSearch = (props: UniversalSearchProps) => {
  const apolloClient = useApolloClient()

  const [term, setTerm] = useState('')
  const [searchRef, setSearchRef] = useState<HTMLInputElement | null>(null)
  const [options, setOptions] = useState<EuiSelectableTemplateSitewideOption[]>([])
  const [loading, setLoading] = useState(false)
  const [, setLastRequestId] = useState('')

  const debounceTerm = useDebounce(term, 150)

  const convertResultsToOptions = useCallback(
    (results: UniversalSearchItem[], requestId: string) => {
      const newOptions: EuiSelectableTemplateSitewideOption[] =
        results.map((result: UniversalSearchItem) => {
          let path: string
          let icon: string
          switch (result.type) {
            case UniversalSearchResultType.Job:
              path = '/jobs/'
              icon = 'wrench'
              break
            case UniversalSearchResultType.Customer:
              path = '/customers/'
              icon = 'user'
              break
            case UniversalSearchResultType.Property:
              path = '/properties/'
              icon = 'home'
              break
            case UniversalSearchResultType.User:
              path = '/users/'
              icon = result.image ?? 'user'
              break
            case UniversalSearchResultType.Payment:
              path = '/payments/'
              icon = 'documents'
              break
            case UniversalSearchResultType.Invoice:
              path = '/invoices/'
              icon = 'documents'
              break
            case UniversalSearchResultType.Pricelist:
              path = '/pricelists/'
              icon = 'list'
              break
            case UniversalSearchResultType.Cost:
              path = '/costs/'
              icon = 'documents'
              break
            case UniversalSearchResultType.Item:
              path = '/items/'
              icon = 'tag'
              break
            case UniversalSearchResultType.Contact:
              path = '/contacts/'
              icon = 'wrench'
              break
            default:
              icon = 'documents'
              path = '/'
          }
          const option: EuiSelectableTemplateSitewideOption = {
            key: result.id,
            label: result.label,
            item: result,
            searchableLabel: debounceTerm, // Hack to ensure EuiSelectable doesn't ignore fuzzy match results
            description: result.label,
            meta: [
              ...(result.reference
                ? [
                    {
                      text: result.reference!,
                      type: 'reference',
                      highlightSearchString: true
                    }
                  ]
                : []),
              { text: result.type, type: 'type' },
              ...(result.keywords?.length
                ? result.keywords
                    .filter((k) => !!k)
                    .map((keyword) => ({
                      text: keyword!,
                      type: 'keyword',
                      highlightSearchString: true
                    }))
                : [])
            ],
            icon: {
              size: result.type === UniversalSearchResultType.User ? 'l' : 'm',
              type: icon,
              color: 'subdued'
            },
            url: `${path}${result.id}`
          }
          return option
        }) ?? []
      setLastRequestId((lastRequestId) => {
        if (requestId === lastRequestId) setOptions(newOptions)
        return lastRequestId
      })
    },
    [debounceTerm]
  )

  useEffect(() => {
    const requestId = uuidv4()
    setLastRequestId(requestId)

    const universalSearch = async () => {
      const input: UniversalSearchInput = {
        query: debounceTerm,
        types: props.types,
        size: 10
      }
      setLoading(true)
      try {
        const result = await apolloClient.query({ query: UniversalSearchDocument, variables: { input } })
        return result.data.universalSearch.results
      } catch (e) {
        console.log('Error loading results', e)
        return []
      } finally {
        setLoading(false)
      }
    }
    const promise = debounceTerm ? universalSearch() : Promise.resolve([])
    promise.then((results) => {
      convertResultsToOptions(results, requestId)
    })
  }, [debounceTerm, apolloClient, props.types, convertResultsToOptions])

  /**
   * Hook up the keyboard shortcut for ctrl+/ to initiate focus into search input
   */
  useEffect(() => {
    window.addEventListener('keydown', onWindowKeyDown)
    return function cleanup() {
      window.removeEventListener('resize', onWindowKeyDown)
    }
  })

  const onWindowKeyDown = (e: any) => {
    if (e.ctrlKey && e.key.toLowerCase() === '/') {
      window.addEventListener('keyup', onWindowKeyUp)
    }
  }

  const onWindowKeyUp = () => {
    searchRef && searchRef.focus()
    window.removeEventListener('keyup', onWindowKeyUp)
  }

  const onKeyUpCapture = (e: any) => {
    const newTerm = e.currentTarget.value
    setTerm(newTerm)
    if (e.keyCode === 13 && term === newTerm && options.length === 1) {
      executeAction(options[0])
    }
  }

  const closePopover = () => {
    // Close the popover
    if (searchRef) {
      searchRef.dispatchEvent(new KeyboardEvent('keydown', { key: keys.ESCAPE, bubbles: true }))
      searchRef.value = ''
      // Make sure the change in value wil be exposed as a change event,
      // so the clear button in the input box vanishes
      searchRef.dispatchEvent(new InputEvent('change'))
      ;(document.activeElement as HTMLElement).blur()
    }
  }

  const executeAction = (option: EuiSelectableTemplateSitewideOption) => {
    switch (props.onSelectAction) {
      case UniversalSearchSelectAction.RedirectToItem:
        if (option && option.url) window.location.href = option.url
        break
      case UniversalSearchSelectAction.UseOnSelectCallback:
        if (option.item && props.onSelect) {
          props.onSelect(option.item)
          closePopover()
        }
    }
  }

  const onChange = (updatedOptions: EuiSelectableTemplateSitewideOption[]) => {
    const clickedItem = updatedOptions.find((option: EuiSelectableTemplateSitewideOption) => option.checked === 'on')
    if (clickedItem) executeAction(clickedItem)
  }

  const classNames = [
    'universal-search__list',
    props.onSelectAction === UniversalSearchSelectAction.UseOnSelectCallback
      ? 'universal-search__item__badge--hidden'
      : ''
  ]
    .filter((c) => !!c)
    .join(' ')

  return (
    <form
      style={{ minWidth: '280px' }}
      autoComplete="off"
      onSubmit={(event) => {
        event.preventDefault()
        return false
      }}
    >
      <EuiSelectableTemplateSitewide
        isLoading={loading}
        onChange={onChange}
        options={options}
        listProps={{
          rowHeight: 48,
          className: classNames
        }}
        singleSelection={true}
        searchable={false}
        searchProps={{
          // append: shortCutKey,
          compressed: props.compressed ?? true,
          onKeyUpCapture: onKeyUpCapture,
          className: 'customSearchClass',
          inputRef: setSearchRef,
          placeholder: props.placeholder ?? `Search`,
          autoComplete: 'off',
          autoCapitalize: 'none',
          autoCorrect: 'off',
          spellCheck: 'false',
          style: { minWidth: '280px' }
        }}
        popoverProps={{ width: 400 }}
        popoverFooter={
          <EuiText color="subdued" size="xs">
            <EuiFlexGroup alignItems="center" gutterSize="s" responsive={false} wrap>
              <EuiFlexItem />
              <EuiFlexItem grow={false}>Quickly search using</EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiFlexGroup gutterSize="none">
                  <EuiFlexItem grow={false}>
                    <EuiBadge>Ctrl</EuiBadge>
                  </EuiFlexItem>
                  <EuiFlexItem grow={false}>
                    <span style={{ padding: '0 3px' }}>+</span>
                  </EuiFlexItem>
                  <EuiFlexItem grow={false}>
                    <EuiBadge>/</EuiBadge>
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiText>
        }
      />
    </form>
  )
}

export default UniversalSearch
