import { AppointmentRequirements } from '@fallonsolutions/appointment'
import { PriorityRequirement } from '@fallonsolutions/priority'
import { CustomerType } from '../../../api/generated-types'
import { YesNoValue } from '../common/common-enums'
import { TopicHVACMaintenanceActionResult } from './action-topic-hvac-maintenance-model'

const HVAC_MAINTENANCE_SKILL = 'HVACMaintenance'

export const getHVACMaintenanceRequirements = (
  result: TopicHVACMaintenanceActionResult | undefined
): AppointmentRequirements | undefined => {
  if (!result) return undefined
  const { existingContract, dsrAdviseToBook, roofAccess, customerType } = result
  if (customerType === CustomerType.Commercial) {
    if (!roofAccess) {
      return undefined
    }
  }
  if (!!existingContract && dsrAdviseToBook === YesNoValue.Yes) return createRequirements([HVAC_MAINTENANCE_SKILL])
  return undefined
}

export const getHVACMaintenanceOutOfScope = (result: TopicHVACMaintenanceActionResult | undefined): boolean => {
  if (!result || !result.existingContract) return false
  const { customerType } = result
  if (result.roofAccess === YesNoValue.No && customerType === CustomerType.Commercial) return true
  if (result.dsrAdviseToBook === YesNoValue.No) return true
  return false
}

const createRequirements = (
  attributeIds: string[],
  priority?: PriorityRequirement | undefined
): AppointmentRequirements => {
  return {
    attributes: attributeIds.map((attributeId) => ({ attributeId })),
    ...(priority && { priority })
  }
}
