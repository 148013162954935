import { EuiSpacer } from '@elastic/eui'
import { JobTopicType } from '@fallonsolutions/types'
import { includes } from 'lodash-es'
import { Discount, DiscountMethod, ItemType, JobType, ServiceArea, TradeType } from '../../api/generated-types'
import { ItemSupplier } from '../../workflow/topics/action-topic-model'
import { ContactRole } from '../actions/action-customer-qualification'
import { SewerIssue, StormwaterIssue } from '../topics/drain/action-topic-plumbing-drain-model'
// TODO
// Move this logic to a centralised package (eg. common/travel-fee) to use the same logic on backend and
// automate creation of option sheet with appropriate travel option
// Also need to cater to other statements such as hourly rate for commercial and minimum times (30 mins or 1 hour etc)

const parkingCharge = 'Customer on-charge for parking'

interface CreateLabourRateStatementOpts {
  labourRate: string
  chargeInterval?: string
  minimumPeriod?: string
}

const createLabourRateStatement = ({ labourRate, chargeInterval, minimumPeriod }: CreateLabourRateStatementOpts) => {
  return `${labourRate} per ${chargeInterval ?? '15 minutes'}, minimum ${minimumPeriod ?? '30 minutes'} + materials`
}

interface CreateFeeInclusionStatementOpts {
  feeName?: string
  labourAmount: string
  additional?: string
}

const createFeeInclusionStatement = ({ feeName, labourAmount, additional }: CreateFeeInclusionStatementOpts) => {
  return `${feeName ?? 'Fee'} includes service call, ${labourAmount} of labour${additional ? ` and ${additional}` : ''}`
}

interface CreateCommercialJobClosingStatementProps extends CreateLabourRateStatementOpts {
  additionalNotes?: string
  travelFeeAmount?: string
  travelFeeLabel?: string
}
// need this because formatting off the internal notes statement is impossible
const CreateClosingCommercialStatement = ({
  labourRate,
  chargeInterval,
  minimumPeriod,
  additionalNotes,
  travelFeeAmount,
  travelFeeLabel
}: CreateCommercialJobClosingStatementProps) => {
  return (
    <>
      <ul>
        {travelFeeAmount && travelFeeLabel ? (
          <>
            <li>
              Our qualified technician has a {travelFeeLabel} of <strong>{travelFeeAmount}</strong>. This will get them
              to site
            </li>
          </>
        ) : (
          <>
            <li>The travel fee is complimentary this time</li>
          </>
        )}
        <li>
          our labour fees are charged at <strong>{labourRate}</strong> for each {chargeInterval ?? '30 minutes'}
        </li>
        <li>
          We do have a minimum labour rate of <strong>{minimumPeriod ?? '60 minutes'} </strong> + materials and charge
          for parking (if required)
        </li>
      </ul>
    </>
  )
}

interface CreateAfterHoursCommercialJobClosingStatementProps {
  travelFeeAmount: string
  travelFeeLabel: string
}
const CreateClosingAfterHoursCommercialStatement = ({
  travelFeeAmount,
  travelFeeLabel
}: CreateAfterHoursCommercialJobClosingStatementProps) => {
  return (
    <>
      <ul>
        <li>Our qualified technician will come to your house, discuss the issue</li>
        <li>and provide you with solution options</li>

        <EuiSpacer />
        {travelFeeAmount && travelFeeLabel ? (
          <li>
            There’s a {travelFeeLabel} of <strong>{travelFeeAmount}</strong> to cover the cost of the technician
            travelling to your home
          </li>
        ) : (
          <>
            <li>The travel fee is complimentary this time</li>
          </>
        )}
        <EuiSpacer />

        <li>The technician will provide you with the upfront cost, which,</li>
        <li>if you’re happy with, you can sign off on and he’ll get that work underway</li>
      </ul>
    </>
  )
}

export interface TravelFee {
  amount: string
  label: string
  additionalNotes?: string
  closingStatement?: JSX.Element
}

export interface GetTravelFeeOpts {
  existingMember: boolean
  afterHours: boolean
  contactRole: ContactRole
  trade: TradeType
  jobType: JobType
  jobTopicReference: JobTopicType
  travelFeeWaived?: boolean
  issueType?: StormwaterIssue | SewerIssue
  serviceArea?: ServiceArea
  categoryType?: string
  topic?: Record<string, any> // checking for a specific key off the security topic
  appliedDiscount?: Discount
}

const applianceFeeModel = { amount: '$225', label: 'with repairs priced separately' }
const standardTravelFee = '$88.00'

const standardTravelFeeMemberPricing = { amount: '$74.80', label: 'travel fee' }
const standardTravelFeeNonMemberPricing = { amount: standardTravelFee, label: 'travel fee' }
const hvacDuctedCategory = 'Ducted'

// eslint-disable-next-line complexity
const generateTravelFee = (props: GetTravelFeeOpts): TravelFee | undefined => {
  const {
    existingMember,
    afterHours,
    contactRole,
    trade,
    jobType,
    jobTopicReference,
    travelFeeWaived,
    issueType,
    categoryType,
    topic
  } = props

  const jobTopic = topic ?? {}

  const isDomestic = contactRole === ContactRole.HomeOwner

  const flatRateFee = existingMember ? '$74.80' : '$88.00'

  const commercialLabourRate = '$50 + GST'
  const minimumChargePeriod = '60 minutes'
  const chargeInterval = '15 minutes'
  const travelFeeAmount = '$80 + GST'
  const travelFeeLabel = 'commercial callout fee'

  // TODO: bring in service area and check branch | JobTrade | JobType | Topic.category
  // e.g sunnycoast | HVAC | Quote | Ducted -> $88 travel fee
  // only check
  const override = serviceAreaTravelFeeOverride({ ...props, isDomestic })
  if (override) return override
  const isHppInspection = jobTopicReference === JobTopicType.Inspection
  if (jobType === JobType.Callback) return undefined
  if (jobType === JobType.Quote && trade !== TradeType.Carpentry && travelFeeWaived && isDomestic) return undefined
  if (travelFeeWaived) return undefined

  if (!isDomestic) {
    // Drains Commercial After Hours
    const isPremiumCommercialIssue = includes(
      [StormwaterIssue.BlockedOrOverflowing, SewerIssue.BlockedOrOverflowing, SewerIssue.SlowDraining],
      issueType
    )
    const applyAfterHoursFee = afterHours || isPremiumCommercialIssue
    if (trade === TradeType.Drains && applyAfterHoursFee) {
      return {
        amount: '$454 + GST',
        label: 'minimum charge',
        additionalNotes: `${createFeeInclusionStatement({
          feeName: 'Charge',
          labourAmount: '1 hour',
          additional: 'machinery use'
        })}. ${parkingCharge}`
      }

      // commercial backflow testing
    } else if (jobTopicReference === JobTopicType.BackFlowTesting) {
      return {
        amount: '$200 + GST',
        label: 'this is the cost for travel and testing of the first valve',
        additionalNotes: 'Any additional valves needing testing will be $88 + GST'
      }
    }
    // Commercial Appliances
    else if (trade === TradeType.Appliances) {
      return applianceFeeModel

      // Commercial HVAC Repairs
    } else if (trade === TradeType.HVAC && jobTopicReference === JobTopicType.HVACRepair) {
      if (categoryType === hvacDuctedCategory) {
        return {
          amount: '$349',
          label: 'diagnostics fee for the first unit (repairs priced separately)'
        }
      }
      return {
        amount: '$289',
        label: 'diagnostics fee for the first unit (repairs priced separately)'
      }
      // HVAC Quote
    } else if (trade === TradeType.HVAC && jobTopicReference === JobTopicType.HVACQuoteInstall) {
      return {
        amount: standardTravelFee,
        label: 'quote fee of'
      }
    }
    // Commercial Carpentry
    else if (trade === TradeType.Carpentry) {
      if (jobType === JobType.Quote) {
        return { amount: '$80 + GST', label: 'quote fee', additionalNotes: parkingCharge }
      } else {
        return {
          amount: '$80 + GST',
          label: 'travel fee',
          additionalNotes: `${createLabourRateStatement({
            labourRate: commercialLabourRate,
            minimumPeriod: minimumChargePeriod
          })}. ${parkingCharge}`
        }
      }
      // Commercial After Hours
    } else {
      const commercialFeeStatement = `${createLabourRateStatement({
        labourRate: commercialLabourRate,
        minimumPeriod: minimumChargePeriod
      })}. ${parkingCharge}`
      return afterHours
        ? getCommercialAfterHoursTravelFee(props)
        : {
            amount: travelFeeAmount,
            label: travelFeeLabel,
            additionalNotes: `${commercialFeeStatement}`,
            closingStatement: CreateClosingCommercialStatement({
              chargeInterval,
              labourRate: commercialLabourRate,
              minimumPeriod: minimumChargePeriod,
              travelFeeAmount,
              travelFeeLabel
            })
          }
    }
  } else if (afterHours) {
    return { amount: '$231', label: 'after hours fee' }
  } else if (jobTopicReference === JobTopicType.DrainClear99) {
    return { amount: '$198', label: 'inspection fee' }
  } else if (jobTopicReference === JobTopicType.HVACConditionAndFilterCleanReport) {
    return { amount: '$99', label: 'inspection fee' }
  } else if (jobTopicReference === JobTopicType.SolarInspection) {
    return { amount: '$198', label: 'inspection fee' }
  } else if (jobTopicReference === JobTopicType.HomeEnergyAudit159) {
    return { amount: '$198', label: 'home energy audit fee' }
  } else if (jobTopicReference === JobTopicType.HotWaterInspection99) {
    return { amount: '$198', label: 'hot water inspection fee' }
  } else if (jobTopicReference === JobTopicType.SwitchBoardInspection99) {
    return { amount: '$198', label: 'switchboard inspection fee' }
  } else if (jobTopicReference === JobTopicType.SecurityInspection) {
    return { amount: '$198', label: 'security inspection fee' }
  } else if (trade === TradeType.Appliances) {
    return applianceFeeModel
  } else if (trade === TradeType.Solar && isHppInspection) {
    return undefined
  } else if (trade === TradeType.Solar) {
    return { amount: '$198', label: 'solar inspection fee' }
  } else if (trade === TradeType.Security && jobType === JobType.Inspection) {
    // either $99 smoke alarm or $99 security inspection
    return { amount: '$198', label: 'fee' }
  } else if (jobTopicReference === JobTopicType.SmokeAlarmInspection) {
    return { amount: '$198', label: 'inspection fee' }
  } else if (trade === TradeType.Security) {
    const { itemSupplier } = jobTopic
    if (itemSupplier === ItemSupplier.Customer) {
      return existingMember ? standardTravelFeeMemberPricing : standardTravelFeeNonMemberPricing
    }
  } else if (jobType === JobType.Inspection) {
    return undefined
  } else if (trade === TradeType.HVAC) {
    if (jobTopicReference === JobTopicType.HVACRepair) {
      if (categoryType === hvacDuctedCategory) {
        return {
          amount: '$349',
          label: 'diagnostics fee for the first unit (repairs priced separately)'
        }
      }
      return {
        amount: '$289',
        label: 'diagnostic fee for the first unit (repairs priced separately)',
        additionalNotes: 'additional diagnostics are to be charged at $189.76 ea'
      }
    } else if (jobTopicReference === JobTopicType.HVACQuoteInstall) {
      return {
        amount: `${flatRateFee}`,
        label: 'quote fee of'
      }
    } else {
      return { amount: flatRateFee, label: isDomestic ? 'travel fee' : 'service fee' }
    }
  } else if (jobTopicReference === JobTopicType.BackFlowTesting) {
    return {
      amount: '$88 + GST',
      label: 'minimum charge'
    }
  }
  return { amount: flatRateFee, label: 'travel fee' }
}

export const getTravelFee = (props: GetTravelFeeOpts): TravelFee | undefined => {
  const travelFee = generateTravelFee(props)
  // if applied discount is not undefined
  const { appliedDiscount } = props
  // many discounts can be applied, only alter the travel fee amount if the discount is for travel items
  if (appliedDiscount && travelFee?.amount && appliedDiscount.itemTypes?.includes(ItemType.Travel)) {
    const amount = parseFloat(travelFee?.amount?.trim()?.match(/(\d|\.\d)+/)?.[0] ?? '0.00')
    if (!isNaN(amount) && amount > 0) {
      const rate = appliedDiscount.rate
      const newAmount = appliedDiscount.method === DiscountMethod.Percentage ? amount - amount * rate : amount - rate
      travelFee.amount = travelFee.amount.replace(/(\d|\.\d)+/, newAmount.toFixed(2))
    }
  }
  return travelFee
}

const GOLD_COAST_BRANCH_ID = 'gold-coast'
const SUNSHINE_COAST_BRANCH_ID = 'sunshine-coast'
export interface ServiceAreaTravelFeeOverrideProps extends GetTravelFeeOpts {
  isDomestic: boolean
}
// e.g gold coast | Security | New Installation | -> $88 travel fee
const serviceAreaTravelFeeOverride = (props: ServiceAreaTravelFeeOverrideProps): TravelFee | undefined => {
  const { trade, serviceArea, isDomestic, travelFeeWaived, existingMember } = props
  // travel fee is waived on new installs, if it has been waived but it is a gold coast job, override it
  if (serviceArea?.branchId === GOLD_COAST_BRANCH_ID && trade === TradeType.Security && travelFeeWaived && isDomestic) {
    return existingMember ? standardTravelFeeMemberPricing : standardTravelFeeNonMemberPricing
  }
}

const getCommercialAfterHoursTravelFee = (props: GetTravelFeeOpts): TravelFee | undefined => {
  //const isCommercialElectrical = props.trade === Trade.Electrical
  // return !isCommercialElectrical
  //   ? {
  //       amount: '$297 + GST',
  //       label: 'after hours commercial callout fee',
  //       additionalNotes: `${createFeeInclusionStatement({
  //         feeName: 'Fee',
  //         labourAmount: '1 hour'
  //       })}. ${parkingCharge}`
  //     }
  //   : {
  //       amount: '$231 + GST',
  //       label: 'after hours commercial callout fee'
  //     }

  const travelFeeAmount = '$231 + GST'
  const travelFeeLabel = 'after hours commercial callout fee'

  return {
    amount: travelFeeAmount,
    label: travelFeeLabel,
    closingStatement: CreateClosingAfterHoursCommercialStatement({
      travelFeeAmount,
      travelFeeLabel
    })
  }
}
