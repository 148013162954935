import { AppointmentFragment, AppointmentSource, TimeWindowFragment } from '../api/generated-types'

export const getBookingWindow = (
  appointment: AppointmentFragment | null | undefined
): TimeWindowFragment | undefined => {
  switch (appointment?.source) {
    case AppointmentSource.Platform:
      return appointment?.bookingWindow ?? appointment?.scheduled ?? undefined
    case AppointmentSource.Legacy:
    default:
      return appointment?.scheduled ?? undefined
  }
}
