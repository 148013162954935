import { QuestionDefinitions, getNotesGenerator } from '../../helpers/notes-generator'
import {
  ApplianceRepairTopicActionResult,
  InstallEnum,
  RepairOrReplaceEnum,
  applianceRepairQuestionDefinitions
} from '../appliance-shared/appliance-model'
import { YesNoValue } from '../common/common-enums'

export interface TopicCooktopActionResult extends ApplianceRepairTopicActionResult {
  cooktopType?: CooktopEnum
  serviceType?: InstallEnum | RepairOrReplaceEnum
  removeOldCooktop?: YesNoValue
  willNewCooktopFitCutOut?: YesNoValue
  isNewCooktopOnsite?: YesNoValue
  happenedBefore?: YesNoValue
}

const questionDefinitions: QuestionDefinitions<TopicCooktopActionResult> = {
  cooktopType: { question: 'What type of cooktop do you have?', statement: 'Type:' },
  serviceType: { question: 'Is this for a Repair or Replace?', statement: 'Service:' },
  ...applianceRepairQuestionDefinitions,
  issue: { question: 'What is the fault with the cooktop?', statement: 'Issue:' },
  removeOldCooktop: { question: 'Do we need to remove the old cooktop?', statement: 'Remove:' },
  willNewCooktopFitCutOut: { question: 'Will the new cooktop fit the existing cut out?', statement: 'Will fit:' },
  isNewCooktopOnsite: {
    question: 'Is the new cooktop on site already?',
    statement: 'Cooktop is on site. Customer advised that we need the appliance onsite to provide accurate quoting:'
  }
}
export const cooktopNotesGenerator = getNotesGenerator(questionDefinitions)

export enum CooktopEnum {
  Gas = 'Gas',
  Induction = 'Induction',
  Electric = 'Electric'
}

export interface ICookTopTypeOption {
  label: string
  id: CooktopEnum
  description?: string
}

export const cooktopTypeOptions: ICookTopTypeOption[] = [
  { id: CooktopEnum.Gas, label: 'Gas' },
  {
    id: CooktopEnum.Induction,
    label: 'Induction',
    description: `A clear glass cooktop with heated coils underneath that doesn’t glow red when turned on`
  },
  { id: CooktopEnum.Electric, label: 'Electric' }
]
