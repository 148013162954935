import {
  EuiAccordion,
  EuiCheckbox,
  EuiComboBoxOptionOption,
  EuiFacetButton,
  EuiFacetGroup,
  EuiFieldSearch,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHorizontalRule,
  EuiIcon,
  EuiSpacer
} from '@elastic/eui'
import { JobTopic } from '@fallonsolutions/types'
import { compact, map, omit, pick } from 'lodash-es'
import moment, { Moment } from 'moment-timezone'
import { useEffect, useState } from 'react'
import { StringParam, useQueryParam } from 'use-query-params'
import {
  AfterHours,
  CustomerType,
  JobFilters,
  JobType,
  MembershipLevel,
  PropertyFragment,
  Range,
  UserRole
} from '../api/generated-types'
import { BranchComboBox } from '../branches/branch-combo-box'
import AmountFacets, { RevenueFacet } from '../common/amount-facets'
import { dateConfig } from '../common/date-config'
import DatePicker from '../common/date-picker'
import { EnumTypeComboBox } from '../common/enum-combo-box'
import { createDateFilter } from '../common/filters'
import { useDebounce } from '../common/use-debounce'
import { useLocalStorage } from '../common/use-local-storage'
import VerticalDivider from '../common/vertical-divider'
import CustomerComboBox, { CustomerComboBoxItem } from '../customers/customer-combo-box'
import { DiscountComboBox, DiscountComboItem } from '../discounts/discount-combo-box'
import TradeFacets from '../jobs/trade-facets'
import { PropertyComboBox } from '../properties/property-combo-box'
import createPersistedState from '../use-persisted-state'
import UserComboBox, { UserComboItem } from '../users/user-combo-box'
import CustomerTypeFacets from './customer-type-facets'
import JobTopicComboBox from './job-topic-combo-box'
import JobTypeComboBox from './job-type-combo-box'

const useSandbox = createPersistedState<boolean>('sandbox')

interface JobListFiltersProps {
  onChangeFilters: (input: JobFilters) => void
}

const OFF_COLOR = '#E5E8F0'
const ON_COLOR = '#34C759'

interface Facet {
  label: string
  enabled: boolean
  onClick: () => void
}

// eslint-disable-next-line max-lines-per-function
export const JobListFilters = (props: JobListFiltersProps) => {
  const { onChangeFilters } = props

  const [startDate, setStartDate] = useQueryParam('from', StringParam, { updateType: 'replaceIn' })
  const [endDate, setEndDate] = useQueryParam('to', StringParam, { updateType: 'replaceIn' })
  const [jobsStartDateInPeriod, setJobsStartDateInPeriod] = useState(false)
  const [jobsWithAppointments, setJobsWithAppointments] = useState(true)

  const [sandbox] = useSandbox(false)
  const [term, setTerm] = useState('')
  const [technicians, setTechnicians] = useState<UserComboItem[]>([])
  const [referrers, setReferrers] = useState<UserComboItem[]>([])
  const [customers, setCustomers] = useState<CustomerComboBoxItem[]>([])
  const [properties, setProperties] = useState<PropertyFragment[]>([])
  const [jobTypes, setJobTypes] = useState<JobType[]>([])
  const [branches, setBranches] = useState<EuiComboBoxOptionOption<string>[]>([])
  const [jobTopics, setJobTopics] = useState<JobTopic[]>([])
  const [customerTypes, setCustomerTypes] = useState<CustomerType[]>([])
  const [discounts, setDiscounts] = useState<DiscountComboItem[]>([])
  const [membershipLevels, setMembershipLevels] = useState<MembershipLevel[]>([])
  const [trades, setTrades] = useLocalStorage('trades', [])
  const [isAttended, setIsAttended] = useState(false)
  const [isNoPrimarySoldJob, setIsNoPrimarySoldJob] = useState(false)
  const [isFutileJob, setIsFutileJob] = useState(false)
  const [isZeroDollarJob, setIsZeroDollarJob] = useState(false)
  const [hasUnsoldOptions, setHasUnsoldOptions] = useState(false)
  const [hasNoTravelFee, setHasNoTravelFee] = useState(false)
  const [hasOptionSheet, setHasOptionSheet] = useState(false)
  const [hasNoInvoice, setHasNoInvoice] = useState(false)
  const [hasNoAppointment, setHasNoAppointment] = useState(false)

  const [hasInspection, setHasInspection] = useState(false)
  const [hasNoInspection, setHasNoInspection] = useState(false)
  const [hasSubcontractor, setHasSubcontractor] = useState(false)

  const [hasNoAcceptance, setHasNoAcceptance] = useState(false)
  const [hasReferrer, setHasReferrer] = useState(false)
  const [hasExcessiveDiscount, setHasExcessiveDiscount] = useState(false)
  const [revenueFacets, setRevenueFacets] = useState<RevenueFacet[]>([])
  const [afterHours, setAfterHours] = useState<AfterHours[]>([])

  const onSetStartDate = (newDate: Moment) =>
    newDate ? setStartDate(newDate.format(dateConfig.basic)) : setStartDate(null)
  const onSetEndDate = (newDate: Moment) => (newDate ? setEndDate(newDate.format(dateConfig.basic)) : setEndDate(null))

  const debounceTerm = useDebounce(term, 150)

  useEffect(() => {
    const input: JobFilters = {
      ...(sandbox === true && { sandbox: true }),
      ...(debounceTerm.length > 0 && { query: debounceTerm }),
      ...(trades.length > 0 && { trades }),
      ...(technicians.length && { technicians: compact(map(technicians, 'id')) }),
      ...(referrers.length && { referrers: compact(map(referrers, 'id')) }),
      ...(customers.length > 0 && { customers: map(customers, 'id') }),
      ...(properties.length > 0 && { properties: map(properties, 'id') }),
      ...(jobTypes.length > 0 && { types: map(jobTypes, 'id') }),
      ...(branches.length > 0 && { branches: compact(map(branches, 'id')) }),
      ...(jobTopics.length > 0 && { topics: compact(map(jobTopics, 'reference')) }),
      ...(discounts.length > 0 && { discountCodes: compact(map(discounts, 'code')) }),
      ...(membershipLevels.length > 0 && { membershipLevels: compact(membershipLevels) }),
      ...(customerTypes.length > 0 && { customerTypes }),
      ...((startDate || endDate) && { appointmentDate: createDateFilter(startDate, endDate) }),
      jobsWithAppointments,
      ...(jobsStartDateInPeriod === true ? { jobsStartedInPeriod: true } : { jobsStartedInPeriod: false }),
      ...(isAttended && { isAttended }),
      ...(isNoPrimarySoldJob && { isNoPrimarySoldJob }),
      ...(hasNoTravelFee && { hasTravelFee: false }),
      ...(isFutileJob && { isFutileJob }),
      ...(isZeroDollarJob && { isZeroDollarJob }),
      ...(hasUnsoldOptions && { hasUnsoldOptions }),
      ...(hasOptionSheet && { hasOptionSheet }),
      ...(hasNoInvoice && { hasInvoice: false }),
      ...(hasNoAppointment && { hasAppointment: false }),
      ...(hasInspection && { hasInspection: true }),
      ...(hasNoInspection && { hasInspection: false }),
      ...(hasNoAcceptance && { hasAcceptance: false }),
      ...(hasSubcontractor && { hasSubcontractor: true }),
      ...(hasReferrer && { isReferral: true }),
      ...(hasExcessiveDiscount && { discount: { min: 0.03 } }),
      ...(revenueFacets.length > 0 && {
        totalRanges: createRevenueFacetFilter(revenueFacets)
      }),
      ...(afterHours.length > 0 && { afterHours })
    }
    //   ...(legacyStatus.length > 0 && { legacyStatus }),
    onChangeFilters(input)
  }, [
    onChangeFilters,
    branches,
    customers,
    customerTypes,
    debounceTerm,
    discounts,
    endDate,
    hasExcessiveDiscount,
    hasInspection,
    hasNoAcceptance,
    hasNoAppointment,
    hasNoInvoice,
    hasNoTravelFee,
    hasOptionSheet,
    hasReferrer,
    hasSubcontractor,
    hasUnsoldOptions,
    isAttended,
    isFutileJob,
    isNoPrimarySoldJob,
    isZeroDollarJob,
    jobsStartDateInPeriod,
    jobsWithAppointments,
    jobTopics,
    jobTypes,
    membershipLevels,
    properties,
    revenueFacets,
    sandbox,
    startDate,
    technicians,
    trades,
    hasNoInspection,
    afterHours,
    referrers
  ])

  const facets: Facet[] = [
    {
      label: 'Has option sheet',
      enabled: hasOptionSheet,
      onClick: () => setHasOptionSheet(!hasOptionSheet)
    },
    {
      label: 'Has inspection',
      enabled: hasInspection,
      onClick: () => setHasInspection(!hasInspection)
    },
    {
      label: 'Has no inspection',
      enabled: hasNoInspection,
      onClick: () => setHasNoInspection(!hasNoInspection)
    },
    {
      label: 'Excessive discount (over 3%)',
      enabled: hasExcessiveDiscount,
      onClick: () => setHasExcessiveDiscount(!hasExcessiveDiscount)
    },
    {
      label: 'No primary sold',
      enabled: isNoPrimarySoldJob,
      onClick: () => setIsNoPrimarySoldJob(!isNoPrimarySoldJob)
    },
    {
      label: 'Unsold options',
      enabled: hasUnsoldOptions,
      onClick: () => setHasUnsoldOptions(!hasUnsoldOptions)
    },
    {
      label: 'No travel fee',
      enabled: hasNoTravelFee,
      onClick: () => setHasNoTravelFee(!hasNoTravelFee)
    },
    {
      label: 'No appointments',
      enabled: hasNoAppointment,
      onClick: () => setHasNoAppointment(!hasNoAppointment)
    },
    {
      label: 'No invoices',
      enabled: hasNoInvoice,
      onClick: () => setHasNoInvoice(!hasNoInvoice)
    },
    {
      label: 'Missing acceptance',
      enabled: hasNoAcceptance,
      onClick: () => setHasNoAcceptance(!hasNoAcceptance)
    },
    {
      label: 'Attended',
      enabled: isAttended,
      onClick: () => setIsAttended(!isAttended)
    },
    {
      label: 'Futile job',
      enabled: isFutileJob,
      onClick: () => setIsFutileJob(!isFutileJob)
    },
    {
      label: 'Is referred job',
      enabled: hasReferrer,
      onClick: () => setHasReferrer(!hasReferrer)
    },
    {
      label: '$0 job',
      enabled: isZeroDollarJob,
      onClick: () => setIsZeroDollarJob(!isZeroDollarJob)
    },
    {
      label: 'Has subcontractor',
      enabled: hasSubcontractor,
      onClick: () => setHasSubcontractor(!hasSubcontractor)
    }
  ]

  return (
    <div data-test-subj="job-filters">
      <EuiFlexGroup justifyContent="flexStart" alignItems="center">
        <EuiFlexItem grow={false}>
          <TradeFacets trades={trades} setTrades={setTrades} />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <VerticalDivider height="24px" />
        </EuiFlexItem>
        <EuiFlexItem grow={true}>
          <CustomerTypeFacets customerTypes={customerTypes} setCustomerTypes={setCustomerTypes} />
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiHorizontalRule />

      <EuiFlexGroup justifyContent="flexStart" alignItems="flexStart" wrap={true}>
        <EuiFlexItem grow={false}>
          <EuiFieldSearch
            placeholder="Job number"
            value={term}
            onChange={(event) => setTerm(event.target.value)}
            style={{ minWidth: '230px' }}
          />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <JobTypeComboBox jobTypes={jobTypes} onChangeJobTypes={setJobTypes} />
        </EuiFlexItem>

        <EuiFlexItem grow={false}>
          <UserComboBox
            users={technicians}
            onChangeUsers={setTechnicians}
            roles={[UserRole.Technician, UserRole.Supervisor]}
          />
        </EuiFlexItem>

        <EuiFlexItem grow={false}>
          <JobTopicComboBox topics={jobTopics} onChangeTopics={setJobTopics} />
        </EuiFlexItem>

        <EuiFlexItem grow={false}>
          <CustomerComboBox customers={customers} onChangeCustomers={setCustomers} />
        </EuiFlexItem>

        <EuiFlexItem grow={false}>
          <PropertyComboBox properties={properties} onChangeProperties={setProperties} />
        </EuiFlexItem>

        <EuiFlexItem grow={false}>
          <BranchComboBox branches={branches} onChangeBranches={setBranches} />
        </EuiFlexItem>

        <EuiFlexItem grow={false}>
          <DiscountComboBox discounts={discounts} onChangeDiscounts={setDiscounts} />
        </EuiFlexItem>

        <EuiFlexItem grow={false}>
          <EnumTypeComboBox
            placeholder="Existing membership"
            label="Existing membership"
            type={MembershipLevel}
            values={membershipLevels}
            onChange={(membershipLevels) => setMembershipLevels(membershipLevels as MembershipLevel[])}
          />
        </EuiFlexItem>

        <EuiFlexItem grow={false}>
          <EnumTypeComboBox
            placeholder="After hours"
            label="After hours"
            type={omit(AfterHours, AfterHours.None)}
            values={afterHours}
            onChange={(afterHours) => setAfterHours(afterHours as AfterHours[])}
          />
        </EuiFlexItem>

        <EuiFlexItem grow={false}>
          <UserComboBox
            key="referTechnicians"
            placeholder="Referral technician"
            label="Referrer"
            users={referrers}
            onChangeUsers={setReferrers}
            roles={[UserRole.Technician, UserRole.Supervisor]}
          />
        </EuiFlexItem>

        <EuiFlexItem grow={true} />
        <EuiFlexItem grow={false}>
          <DatePicker
            startDate={startDate ? moment(startDate, dateConfig.basic) : null}
            endDate={endDate ? moment(endDate, dateConfig.basic) : null}
            setStartDate={onSetStartDate}
            setEndDate={onSetEndDate}
            quickNav={true}
          />
          <EuiAccordion id="jobDate" buttonContent="Show jobs">
            <div>
              <EuiSpacer size="s" />
              <EuiCheckbox
                id="job-start-date-in-period"
                label={<span style={{ fontSize: '14px' }}>Started in period</span>}
                checked={jobsStartDateInPeriod}
                onChange={(e) => setJobsStartDateInPeriod(e.target.checked)}
                disabled={!startDate && !endDate}
              />
            </div>
            <div>
              <EuiSpacer size="s" />
              <EuiCheckbox
                id="job-with-appointments"
                label={<span style={{ fontSize: '14px' }}>With appointments</span>}
                checked={jobsWithAppointments}
                onChange={(e) => setJobsWithAppointments(e.target.checked)}
                disabled={!startDate && !endDate}
              />
            </div>
          </EuiAccordion>
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiFlexGroup justifyContent="flexStart" alignItems="center" wrap={true}>
        <EuiFlexItem grow={false} style={{ maxWidth: '400px' }}>
          <AmountFacets selectFacets={setRevenueFacets} />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <VerticalDivider height="24px" />
        </EuiFlexItem>
        <EuiFlexItem grow={true}>
          <EuiFacetGroup layout="horizontal">
            {facets.map((facet) => (
              <EuiFacetButton
                key={`job-facet-${facet.label}`}
                icon={<EuiIcon type="dot" color={facet.enabled ? ON_COLOR : OFF_COLOR} />}
                isSelected={facet.enabled}
                onClick={facet.onClick}
                style={{ minInlineSize: '40px', padding: '0px' }}
              >
                {facet.label}
              </EuiFacetButton>
            ))}
          </EuiFacetGroup>
        </EuiFlexItem>
      </EuiFlexGroup>
    </div>
  )
}

const createRevenueFacetFilter = (revenueFacets: RevenueFacet[]): Range[] => {
  return map(revenueFacets, (r: RevenueFacet) => pick(r, ['min', 'max']))
}
