import { TextColor } from '@elastic/eui/src/components/text/text_color'
import { find, first } from 'lodash-es'
import { AnswerOption } from '../../question-fields/combo-select-question-field'
import { YesNoValue } from '../common/common-enums'
import allBrands from './brands.json'

export const serviceableBrands = allBrands.filter((b: BrandType) => b.serviceable === true)

export const brandOptions = allBrands.map((brand: BrandType): AnswerOption<string> => {
  return {
    id: brand.id,
    label: brand.label,
    color: brand.serviceable === true ? 'success' : brand.serviceable === false ? 'danger' : 'disabled'
  }
})

export interface BrandType {
  id: string
  label: string
  serviceable: boolean
  warning?: string
}

//BrandOption on/off
export interface BrandOption extends BrandType {
  color?: TextColor
  checked?: string // on/off
}

export interface BrandResult {
  brandServiceable?: YesNoValue
  brand?: string
  selectedBrand?: BrandOption
}

//Given the selectedOptions View Model, work out what was selected, or if a new custom option was provided.
//TODO: check id/label diff for Scott Sheran, etc.
export const transformBrandSelections = (selectedOptions: BrandOption[]): BrandResult => {
  const result =
    first(
      selectedOptions.map((selectedOption: BrandOption) => {
        const brand = selectedOption?.label || '?'
        const selectedBrand: BrandType | undefined = find(allBrands, (brand) => {
          return brand.id === selectedOption.id
        })
        //custom option
        if (!selectedBrand) {
          //custom options, assume default is unserviceable, ...selectedOption?
          return {
            brand,
            selectedBrand: { serviceable: false, id: brand, label: brand },
            brandServiceable: YesNoValue.No
          }
        } else {
          const brandServiceable =
            !!selectedBrand && selectedBrand.serviceable === true ? YesNoValue.Yes : YesNoValue.No
          return { brandServiceable, selectedBrand, brand }
        }
      })
    ) ?? {}
  return result as unknown as BrandResult
}
