import { ContactFragment, CustomerFragment } from '../../api/generated-types'
import { SuburbSelectOption } from '../../suburbs/suburb-select'

export interface ContactSearchFields {
  fuzzy: boolean
  firstName: string
  lastName: string
  phone: string
  street: string
  suburb: string
  suburbs: SuburbSelectOption[]
  suburbId: string
  //customer fields
  reference: string
  company: string
  activeOnly?: boolean
}

export interface ContactSearchModel {
  searchFields: ContactSearchFields
  createNew: boolean
  selectedContact?: ContactFragment | undefined
  selectedCustomer?: CustomerFragment | undefined
}

export const emptySearchFields: ContactSearchFields = {
  firstName: '',
  lastName: '',
  phone: '',
  street: '',
  suburb: '',
  suburbId: '',
  suburbs: [],
  fuzzy: true,
  reference: '',
  company: ''
}

export const emptyContactSearchModel: ContactSearchModel = {
  searchFields: emptySearchFields,
  selectedContact: undefined,
  selectedCustomer: undefined,
  createNew: false
}
