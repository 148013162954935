import { GetContactDocument, GetContactQuery } from '../api/generated-types'
import { DetailContainer } from '../common/detail-container'
import { ContactDetailView } from './contact-detail-view'

export interface ContactDetailContainerProps {
  contactId: string
  showBreadcrumbs?: boolean
}

export const ContactDetailContainer = ({ contactId, showBreadcrumbs }: ContactDetailContainerProps) => {
  const view = (data: GetContactQuery) => {
    const contact = data.getContact?.contact

    return contact ? <ContactDetailView contact={contact} /> : <></>
  }

  return (
    <DetailContainer
      variables={{ input: { contactId } }}
      view={view}
      parentPath={'/contacts'}
      documentNode={GetContactDocument}
      modelName="contact"
      modelNamePlural="contacts"
      getTitle={(data) => data?.getContact?.contact?.detail?.fullName ?? data?.getContact.contact?.id ?? 'loading'}
      showBreadcrumbs={showBreadcrumbs ?? true}
    />
  )
}

export default ContactDetailContainer
