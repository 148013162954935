import { EuiFlexGroup, EuiFlexItem, EuiHorizontalRule, EuiTitle } from '@elastic/eui'
import ReactMarkdown from 'react-markdown'
import { InvoiceFragment, InvoiceSource } from '../api/generated-types'
import RawJSON from '../common/raw-json'
import '../static/css/invoice.css'
import { InvoiceActions } from './invoice-actions'
import InvoiceHeader from './invoice-header'
import { InvoiceLegacyMessage } from './invoice-legacy-message'
import InvoiceSummary from './invoice-summary'

interface InvoiceCardProps {
  invoice: InvoiceFragment
}

const InvoiceCard = (props: InvoiceCardProps) => {
  const { invoice } = props
  return (
    <div className="invoice-wrapper">
      <div className="invoice">
        <InvoiceHeader invoice={invoice} />

        {invoice?.source === InvoiceSource.Legacy ? <InvoiceLegacyMessage /> : <EuiHorizontalRule />}

        <InvoiceSummary invoice={invoice as InvoiceFragment} startExpanded={false} />

        <EuiFlexGroup>
          <EuiFlexItem>
            <div style={{ padding: '12px' }}>
              <EuiTitle size="xs">
                <h3>Customer notes</h3>
              </EuiTitle>
              <ReactMarkdown className="markdown-body">{invoice.notes ?? 'No customer notes'}</ReactMarkdown>
            </div>
          </EuiFlexItem>
        </EuiFlexGroup>

        {invoice?.creator && (
          <div style={{ padding: '12px' }}>
            Created by{' '}
            <a href={`/users/${invoice?.creator?.id}`} target="_blank" rel="noopener noreferrer">
              {invoice?.creator?.contactDetail?.fullName}
            </a>
          </div>
        )}

        <InvoiceActions invoice={invoice} />
      </div>

      <RawJSON data={invoice} />
    </div>
  )
}

export default InvoiceCard
