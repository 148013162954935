import {
  TopicQualification,
  TopicQualificationResult,
  TopicRequirementsResult,
  createOutcomeGenerator,
  createRequirements
} from '../common/common-outcome'
import { JobType, TradeType } from '../../../api/generated-types'
import {
  DrainDiameter,
  PlumbingInspectionType,
  TopicPlumbingInspectionActionResult
} from './action-topic-plumbing-inspection-model'
import { includes } from 'lodash-es'

const DRAIN_INSPECTION_SKILL = 'DrainInspection'
const GENERAL_PLUMBING_SKILL = 'GeneralPlumbing'

const getRequirements = (result: TopicPlumbingInspectionActionResult | undefined): TopicRequirementsResult => {
  if (!result) return {}
  const { inspectionType, drainDiameter } = result
  switch (inspectionType) {
    case PlumbingInspectionType.GeneralPlumbing:
      return { requirements: createRequirements([GENERAL_PLUMBING_SKILL]) }
    case PlumbingInspectionType.DrainCameraCheck:
    case PlumbingInspectionType.Both:
      return drainDiameter === DrainDiameter.Between90and150
        ? { requirements: createRequirements([DRAIN_INSPECTION_SKILL]), trade: TradeType.Drains }
        : {}
    default:
      return {}
  }
}

const getQualification = (result: TopicPlumbingInspectionActionResult | undefined): TopicQualificationResult => {
  if (!result) return { qualification: TopicQualification.Unknown }
  const { inspectionType, drainDiameter } = result
  const isDrainInspection = includes(
    [PlumbingInspectionType.DrainCameraCheck, PlumbingInspectionType.Both],
    inspectionType
  )
  if (isDrainInspection && drainDiameter === DrainDiameter.OutsideOf90and150)
    return { qualification: TopicQualification.OutOfScope }
  return { qualification: TopicQualification.InScope }
}

export const outcomeGenerator = createOutcomeGenerator<TopicPlumbingInspectionActionResult>(
  {
    qualification: TopicQualification.Unknown,
    trade: TradeType.Plumbing,
    type: JobType.Inspection,
    category: 'General'
  },
  getQualification,
  getRequirements
)
