import { EuiSpacer } from '@elastic/eui'
import { Callout } from '../../common/callout'
import { PillGroup, PillGroupOption } from '../../pill/pill-group'
import { WorkflowAction } from '../workflow-action'
import { BaseActionInput, BaseActionResult, WorkflowActionProps } from '../workflow-model'

export interface TransferToCustomerExperienceManagerActionInput extends BaseActionInput {
  situationRequiringEmergencyServices: boolean
  hasFirstCallResolutionBeenOffered: boolean
}
export interface TransferToCustomerExperienceManagerActionResult extends BaseActionResult {
  callTransferredToManager?: boolean
}

export const TransferToCustomerExperienceManagerAction = (
  props: WorkflowActionProps<
    TransferToCustomerExperienceManagerActionInput,
    TransferToCustomerExperienceManagerActionResult
  >
) => {
  const { onUpdate, input, result } = props

  const completed = result?.callTransferredToManager !== undefined

  const transferOptions: PillGroupOption[] = [
    { id: 'true', label: "I've transferred the call to the manager", icon: 'frameNext' },
    { id: 'false', label: 'I cannot contact the manager now', icon: 'pause' }
  ]

  const handleOnSelectTransferOption = (id: string) => {
    onUpdate({ callTransferredToManager: id === 'true' })
  }

  const handleChange = () => {
    onUpdate({ callTransferredToManager: undefined })
  }

  const callTransferredToManager = completed ? `${result?.callTransferredToManager}` : undefined
  const transferOptionLabel = transferOptions.filter((o) => o.id === callTransferredToManager)?.[0]?.label

  return (
    <>
      <WorkflowAction title="Customer Experience Manager" value={transferOptionLabel} onClickChange={handleChange}>
        {input.situationRequiringEmergencyServices === true && (
          <>
            <Callout type="script" title="I'm sorry - can I confirm you have contacted emergency services?" />
          </>
        )}
        <Callout type="script">
          <ul>
            {input.hasFirstCallResolutionBeenOffered && <li>Sorry to hear this outcome is not satisfactory.</li>}
            {!input.hasFirstCallResolutionBeenOffered && <li>I'm sorry to hear that.</li>}
            <li>
              To best assist you today I will transfer you through to our Customer Experience Manager, who will take
              over from here.
            </li>
            <li>Do you mind if I put you on a brief hold?</li>
          </ul>
        </Callout>
        <Callout type="note">
          Put the customer on hold, try to contact Customer Experience Manager and pass all the details to them.
        </Callout>
        <EuiSpacer />
        <PillGroup
          options={transferOptions}
          value={callTransferredToManager}
          onSelect={handleOnSelectTransferOption}
          onClear={handleChange}
        />
      </WorkflowAction>
      {completed && result.callTransferredToManager === false && (
        <>
          <Callout type="script">
            <ul>
              <li>I'm sorry, our Customer Experience Manager is on a call right now.</li>
              <li>I have taken note of your situation and will pass these details onto them immediately.</li>
              <li>They will contact you back as soon as possible, usually within 30 mins.</li>
            </ul>
          </Callout>
        </>
      )}
    </>
  )
}
