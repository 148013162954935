import { useQuery } from '@apollo/client'
import { GetUserAuthDocument } from '../api/generated-types'
import { useAuthenticated } from './authenticated-context'

export const useAuthService = () => {
  const session = useAuthenticated().user
  const { data, loading, error } = useQuery(GetUserAuthDocument, {
    ...(session.id && { variables: { id: session.id } })
  })

  return {
    user: data?.getUser,
    loading,
    error
  }
}
