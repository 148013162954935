import { useState } from 'react'
import {
  AppointmentSource,
  AppointmentStatusType,
  GetJobDocument,
  JobFragment,
  JobStatusType,
  RestoreAppointmentDocument,
  RestoreJobDocument,
  RestoreJobInput
} from '../api/generated-types'
import {
  EuiButton,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiOverlayMask,
  EuiSwitch
} from '@elastic/eui'
import { useMutation, useQuery } from '@apollo/client'

export interface JobRestoreFormProps {
  job: JobFragment
  closeModal: () => void
}

export const JobRestoreForm = (props: JobRestoreFormProps) => {
  const { job, closeModal } = props
  const [restoreAppointments, setRestoreAppointments] = useState(true)
  const [loading, setLoading] = useState(false)
  const [restoreJob] = useMutation(RestoreJobDocument)
  const [restoreAppointment] = useMutation(RestoreAppointmentDocument)
  const { refetch } = useQuery(GetJobDocument)

  const canRestoreJob = job.status?.status === JobStatusType.Cancelled

  const handleRestore = async () => {
    const input: RestoreJobInput = {
      id: job.id
    }
    try {
      setLoading(true)
      await restoreJob({ variables: { input } })
      if (restoreAppointments) {
        const loadJobResult = await refetch({ id: job.id })
        const appointments = loadJobResult.data.getJob?.appointments ?? []
        const cancellableAppointments = appointments.filter(
          (a) => a && a?.source === AppointmentSource.Platform && a.status?.status === AppointmentStatusType.Cancelled
        )
        for (const appointment of cancellableAppointments) {
          if (appointment && appointment.id) await restoreAppointment({ variables: { input: { id: appointment.id } } })
        }
      }
      closeModal()
    } catch (error) {
      setLoading(false)
      console.error('error performing mutation', error)
    }
  }

  return (
    <EuiOverlayMask>
      <EuiModal onClose={closeModal}>
        <EuiModalHeader>
          <EuiModalHeaderTitle>Restore job {job.number}</EuiModalHeaderTitle>
        </EuiModalHeader>
        <EuiModalBody>
          <EuiSwitch
            disabled={loading}
            label="Restore all appointments too"
            checked={restoreAppointments}
            onChange={(e) => setRestoreAppointments(e.target.checked)}
          />
        </EuiModalBody>
        <EuiModalFooter>
          <EuiButton onClick={handleRestore} isLoading={loading} fill disabled={!canRestoreJob}>
            Restore job
          </EuiButton>
        </EuiModalFooter>
      </EuiModal>
    </EuiOverlayMask>
  )
}
