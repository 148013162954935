import { useMutation } from '@apollo/client'
import { EuiContextMenu, EuiContextMenuPanelDescriptor, EuiLoadingSpinner, EuiPopover } from '@elastic/eui'
import { useState } from 'react'
import { JobFragment, MembershipLevel, SetJobMembershipLevelDocument } from '../api/generated-types'
import { useAuthenticated } from '../auth/authenticated-context'
import { MembershipLabel } from '../memberships/membership-label'
import { getPricingChangesLocked } from '../option-sheets/member-status'
import { JobMembershipStatus } from './job-membership-status'

interface JobMembershipStatusEditProps {
  job: JobFragment
}

interface MembershipStatusOption {
  id: MembershipLevel
}

export const JobMembershipStatusEdit = (props: JobMembershipStatusEditProps) => {
  const { job } = props
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const { userFragment } = useAuthenticated()

  // Fallback to optionSheet.membershipLevel until data has been migrated on old jobs
  const membershipLevel = job.membershipLevel ?? job.optionSheet?.membershipLevel ?? MembershipLevel.None
  const hasSetJobMembershipLevel = userFragment.permissions?.setJobMembershipLevel === true
  const canEditMembershipLevel = hasSetJobMembershipLevel && !getPricingChangesLocked(job)

  const [setMembershipLevel, { loading }] = useMutation(SetJobMembershipLevelDocument, {
    awaitRefetchQueries: true,
    refetchQueries: ['GetJob']
  })

  const membershipLevelOptions: MembershipStatusOption[] = [
    { id: MembershipLevel.None },
    { id: MembershipLevel.Complimentary },
    { id: MembershipLevel.Standard },
    { id: MembershipLevel.Premium }
  ]

  const handleMembershipStatusUpdate = async (membershipLevel: MembershipLevel) => {
    setIsMenuOpen(false)
    try {
      await setMembershipLevel({
        variables: { input: { jobId: job.id, membershipLevel } },
        refetchQueries: ['GetJob'],
        awaitRefetchQueries: true
      })
    } catch (err) {
      console.error('TODO: toast this? error changing membership level', err)
    }
  }

  const menu: EuiContextMenuPanelDescriptor[] = [
    {
      id: 0,
      title: 'Set job membership level',
      items: membershipLevelOptions.map((option) => {
        return {
          name: <MembershipLabel membershipLevel={option.id} />,
          disabled: !canEditMembershipLevel,
          toolTipContent: canEditMembershipLevel ? undefined : 'Membership status cannot be updated',
          onClick: () => handleMembershipStatusUpdate(option.id)
        }
      })
    }
  ]

  return canEditMembershipLevel ? (
    <EuiPopover
      isOpen={isMenuOpen}
      ownFocus={true}
      closePopover={() => setIsMenuOpen(false)}
      panelPaddingSize="none"
      button={
        <button onClick={() => setIsMenuOpen(!isMenuOpen)}>
          {loading ? (
            <EuiLoadingSpinner size="m" />
          ) : (
            <JobMembershipStatus membershipLevel={membershipLevel} editable={true} />
          )}
        </button>
      }
    >
      <EuiContextMenu initialPanelId={0} panels={menu}></EuiContextMenu>
    </EuiPopover>
  ) : (
    <>
      <JobMembershipStatus membershipLevel={membershipLevel} />
    </>
  )
}
