import { EuiAvatar, EuiFlexGroup, EuiFlexItem, EuiLink, EuiText } from '@elastic/eui'
import { compact } from 'lodash-es'
import { UserLinkFragment } from '../api/generated-types'
import EuiCustomLink from '../common/eui-custom-link'
import { formatPhone } from '../common/phone'

interface UserCardProps {
  user: UserLinkFragment | null | undefined
  avatarSize?: 's' | 'm' | 'l' | 'xl'
  target?: string
  emptyLabel?: string
  children?: React.ReactNode
  subtitle?: (UserLinkSubtitleDisplay | string)[]
  showAvatar?: boolean
  showCompanyName?: boolean
  showName?: boolean
  growChildren?: boolean
  subtitleSize?: 'xs' | 's' | 'm'
}

export enum UserLinkSubtitleDisplay {
  Department,
  Phone,
  Role
}

const UserLink = (props: UserCardProps) => {
  const {
    user,
    subtitle,
    avatarSize,
    target,
    children,
    emptyLabel,
    showCompanyName = false,
    subtitleSize = 'xs',
    showAvatar = true,
    showName = true,
    growChildren = false
  } = props

  const avatarProps: any = {
    name: user?.contactDetail?.fullName ?? 'No name'
  }
  if (user?.avatar) {
    avatarProps.imageUrl = user.avatar
  }
  const subtitleText = compact(
    subtitle?.map((s) => {
      switch (s) {
        case UserLinkSubtitleDisplay.Department:
          return user?.department
        case UserLinkSubtitleDisplay.Role:
          return user?.role
        case UserLinkSubtitleDisplay.Phone:
          return user?.contactDetail?.phone
        default:
          return s
      }
    })
  ).join(', ')

  return (
    <>
      <EuiFlexGroup alignItems="center" justifyContent="flexStart" gutterSize="s">
        {user ? (
          <>
            {showAvatar && (
              <EuiFlexItem grow={false}>
                <EuiAvatar size={avatarSize ?? 's'} {...avatarProps} />
              </EuiFlexItem>
            )}
            {showName && (
              <EuiFlexItem style={{ textAlign: 'left' }}>
                <EuiCustomLink to={`/users/${user?.id}`} target={target}>
                  {user?.contactDetail?.fullName}
                </EuiCustomLink>
                {showCompanyName && user?.company?.companyName && (
                  <EuiText color="default" size={subtitleSize}>
                    {user.company.companyName}
                  </EuiText>
                )}
                {subtitleText.length > 0 && (
                  <EuiText color="subdued" size={subtitleSize}>
                    {subtitleText}
                  </EuiText>
                )}
                {subtitle?.includes(UserLinkSubtitleDisplay.Phone) && (
                  <EuiLink
                    color="subdued"
                    href={user.contactDetail?.phone ? `tel:${user.contactDetail.phone}` : undefined}
                  >
                    {user.contactDetail?.phone ? formatPhone(user.contactDetail.phone) : 'No phone'}
                  </EuiLink>
                )}
              </EuiFlexItem>
            )}
          </>
        ) : (
          <EuiFlexItem grow={false}>{emptyLabel ?? 'No user'}</EuiFlexItem>
        )}
        {children && <EuiFlexItem grow={growChildren}>{children}</EuiFlexItem>}
      </EuiFlexGroup>
    </>
  )
}

export default UserLink
