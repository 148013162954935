import {
  EuiButtonEmpty,
  EuiCheckbox,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiFormRow,
  EuiSpacer
} from '@elastic/eui'
import { useState } from 'react'
import { SuburbSelect, SuburbSelectOptionDetail } from '../../suburbs/suburb-select'
import { useContactSearch } from './contact-search-hook'
import { ContactSearchDispatchActionType } from './contact-search-reducer'

export const ContactSearchForm = () => {
  const [searchState, dispatch] = useContactSearch()

  const { searchFields } = searchState
  const { phone, firstName, lastName, suburbs, street, fuzzy, reference, company, activeOnly } = searchFields

  const setSearchField = (field: string, value: string) => {
    const type = `change_${field}` as ContactSearchDispatchActionType
    return dispatch({
      type,
      payload: { searchFields: { [`${field}`]: value } }
    })
  }

  const handleSuburbSelect = (suburbs: SuburbSelectOptionDetail[]) => {
    return dispatch({ type: `change_suburbs`, payload: { searchFields: { suburbs } } })
  }

  const setFuzzy = (value: boolean | undefined) => {
    return dispatch({ type: 'change_fuzzy', payload: { searchFields: { fuzzy: value } } })
  }

  const [isMoreFiltersVisible, setIsMoreFiltersVisible] = useState(false)

  return (
    <form autoComplete="off" autoCorrect="off">
      <EuiForm>
        <EuiFlexGroup wrap={true}>
          <EuiFlexItem grow={false} key="phone">
            <EuiFormRow label="Phone">
              <EuiFieldText
                aria-label="Phone"
                icon="mobile"
                width={100}
                size={13}
                value={phone}
                onChange={(e) => setSearchField('phone', e.target.value)}
                autoComplete="off"
              />
            </EuiFormRow>
          </EuiFlexItem>
          <EuiFlexItem grow={false} key="firstName">
            <EuiFormRow label="First name">
              <EuiFieldText
                aria-label="First name"
                value={firstName}
                onChange={(e) => setSearchField('firstName', e.target.value)}
                autoComplete="off"
              />
            </EuiFormRow>
          </EuiFlexItem>
          <EuiFlexItem grow={false} key="lastName">
            <EuiFormRow label="Last name">
              <EuiFieldText
                aria-label="Last name"
                value={lastName}
                onChange={(e) => setSearchField('lastName', e.target.value)}
                autoComplete="off"
              />
            </EuiFormRow>
          </EuiFlexItem>
          <EuiFlexItem grow={false} key="street">
            <EuiFormRow label="Property street">
              <EuiFieldText
                aria-label="Property street"
                icon="home"
                value={street}
                onChange={(e) => setSearchField('street', e.target.value)}
                autoComplete="off"
              />
            </EuiFormRow>
          </EuiFlexItem>
          <EuiFlexItem grow={false} key="suburb">
            <EuiFormRow label="Property suburb">
              <SuburbSelect suburbs={suburbs} onChangeSuburbs={handleSuburbSelect} />
              {/* <EuiFieldText
                aria-label="Property suburb"
                value={suburb}
                onChange={(e) => setSearchField('suburb', e.target.value)}
                autoComplete="off"
              /> */}
            </EuiFormRow>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiFormRow label="&nbsp;">
              <EuiCheckbox
                id="fuzzy"
                checked={fuzzy}
                onChange={(event) => setFuzzy(event.target.checked)}
                label="Fuzzy search"
              />
            </EuiFormRow>
          </EuiFlexItem>
        </EuiFlexGroup>
        <EuiSpacer size="s" />
        {isMoreFiltersVisible && (
          <EuiFlexGroup>
            <EuiFlexItem grow={false} key="reference" style={{ width: '100px' }}>
              <EuiFormRow label="Reference">
                <EuiFieldText
                  value={reference}
                  onChange={(e) => setSearchField('reference', e.target.value)}
                  autoComplete="off"
                />
              </EuiFormRow>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiFormRow label="Company">
                <EuiFieldText
                  value={company}
                  onChange={(e) => setSearchField('company', e.target.value)}
                  autoComplete="off"
                />
              </EuiFormRow>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiFormRow label="&nbsp;">
                <EuiCheckbox
                  id="activeOnly"
                  checked={activeOnly}
                  onChange={(event) =>
                    dispatch({
                      type: `change_activeOnly`,
                      payload: { searchFields: { activeOnly: event.target.checked } }
                    })
                  }
                  label="Active only"
                />
              </EuiFormRow>
            </EuiFlexItem>
          </EuiFlexGroup>
        )}
        <EuiSpacer size="s" />
        <EuiButtonEmpty
          color="text"
          size="s"
          iconType={isMoreFiltersVisible ? 'arrowUp' : 'arrowDown'}
          iconSide="left"
          onClick={() => setIsMoreFiltersVisible(!isMoreFiltersVisible)}
        >
          {isMoreFiltersVisible ? 'Less' : 'More'} filters
        </EuiButtonEmpty>
      </EuiForm>
    </form>
  )
}
