import { EuiButton, EuiSpacer } from '@elastic/eui'
import { EnquiryAbortReason } from '../../api/generated-types'
import { useWorkflow } from './workflow-provider'

export interface OutOfScopeButtonProps {
  issue: string
  buttonText?: string
  abortReason?: EnquiryAbortReason
}

const defaultButtonText = 'Offer a voucher and end call'
export const OutOfScopeButton = (props: OutOfScopeButtonProps) => {
  const { issue, buttonText, abortReason } = props
  const workflowContext = useWorkflow()
  return (
    <>
      <EuiSpacer />
      <EuiButton
        color="warning"
        onClick={() =>
          workflowContext.abort({
            abortReason: abortReason ?? EnquiryAbortReason.CustomerNeedsAreOutOfService,
            notes: `Customer was enquiring about ${issue}`
          })
        }
        data-test-id="out-of-scope-button"
      >
        {buttonText ?? defaultButtonText}
      </EuiButton>
      <EuiSpacer />
    </>
  )
}
