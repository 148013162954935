import {
  CustomerLocationFragment,
  CustomerLocationMembershipFragment,
  MembershipStatusType
} from '@fallonsolutions/types'
import { first, orderBy } from 'lodash-es'
import { SelectedMembership } from '../workflow/actions/action-customer-property'

const defaultMembership: SelectedMembership = { id: undefined, membershipLevel: undefined, membershipStatus: undefined }

export const getCustomerPropertyMembership = (
  customerLocation: CustomerLocationFragment | undefined
): SelectedMembership => {
  if (!customerLocation) return defaultMembership
  const activeMemberships: CustomerLocationMembershipFragment[] | undefined = customerLocation?.memberships?.filter(
    (m) => m.status.status === MembershipStatusType.Active
  )
  const currentMembership = first(orderBy(activeMemberships, 'startDate', 'asc'))

  const membership: SelectedMembership = currentMembership
    ? {
        id: currentMembership.id,
        membershipLevel: currentMembership.level,
        membershipStatus: currentMembership.status.status
      }
    : defaultMembership

  return membership
}
