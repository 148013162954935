import { EuiFlexGroup, EuiFlexItem } from '@elastic/eui'
import { ReactNode } from 'react'

export interface PaginatedCardViewProps<T> {
  rows: T[]
  renderCardView: (row: T) => ReactNode
}

export const PaginatedCardView = <T,>({ rows, renderCardView }: PaginatedCardViewProps<T>) => {
  return (
    <EuiFlexGroup wrap={true}>
      {rows.map((row, index) => (
        <EuiFlexItem key={index} grow={false}>
          {renderCardView(row)}
        </EuiFlexItem>
      ))}
    </EuiFlexGroup>
  )
}
