import { EuiFieldText, EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiLink, EuiSpacer } from '@elastic/eui'
import { AddressEntryMode } from '@fallonsolutions/address'
import { AsYouType } from 'libphonenumber-js'
import { useState } from 'react'
import { AddressField, AddressFieldResult } from '../address/address-field'
import { AddressFragment, ContactDetailFragment } from '../api/generated-types'
import { formatPhone } from '../common/phone'
import { ContactFormEmailField } from './contact-form-email-field'
import { ContactFormPhoneField } from './contact-form-phone-field'
import { validateContactDetail } from './validate-contact-detail'

export interface ContactDetailFormResult {
  detail: ContactDetailFragment
  isValid: boolean
}

export type ContactDetailFormUpdate = (result: ContactDetailFormResult) => ContactDetailFormResult

export interface ContactDetailFormProps {
  contactId?: string
  detail: ContactDetailFragment
  onChange: (fn: ContactDetailFormUpdate) => void
  showValidationErrors: boolean
  emailDisabled?: boolean
  addressRequired: boolean
}

export const ContactDetailForm = ({
  contactId,
  onChange,
  showValidationErrors,
  detail,
  emailDisabled,
  ...props
}: ContactDetailFormProps) => {
  const addressRequired = props.addressRequired ?? false

  const [isAlternatePhoneVisible, setIsAlternatePhoneVisible] = useState(detail?.alternatePhone?.length > 0)

  const updateDetail = (updated: Partial<ContactDetailFragment>) => {
    return onChange((prev) => {
      const newDetail = { ...prev.detail, ...updated }
      const { isValid } = validateContactDetail({
        detail: newDetail,
        addressRequired
      })
      return { detail: newDetail, isValid }
    })
  }

  const setFirstName = (firstName: string) => updateDetail({ firstName })
  const setLastName = (lastName: string) => updateDetail({ lastName })
  const setEmail = (email: string) => updateDetail({ email })
  const setPhone = (phone: string) => {
    const formattedPhone = new AsYouType('AU').input(phone)
    updateDetail({ phone: formattedPhone })
  }
  const setAlternatePhone = (alternatePhone: string) => {
    const formattedPhone = new AsYouType('AU').input(alternatePhone)
    updateDetail({ alternatePhone: formattedPhone })
  }
  const setAddress = (result: AddressFieldResult | undefined) => {
    updateDetail({ address: result?.address })
  }

  const onRemoveAlternatePhone = () => {
    updateDetail({ alternatePhone: '' })
    setIsAlternatePhoneVisible(false)
  }

  // Some fields need to be displayed differently to user
  const detailDisplay: ContactDetailFragment = {
    ...detail,
    phone: detail.phone ? formatPhone(detail.phone) : '',
    alternatePhone: detail.alternatePhone ? formatPhone(detail.alternatePhone) : ''
  }

  const { firstName, lastName, email, phone, alternatePhone } = detailDisplay

  const validationResult = validateContactDetail({
    detail,
    addressRequired
  })
  const firstNameValid = !validationResult.errors.find(({ field }) => field === 'firstName')
  const lastNameValid = !validationResult.errors.find(({ field }) => field === 'lastName')
  const phoneValid = !validationResult.errors.find(({ field }) => field === 'phone')
  const alternatePhoneValid = !validationResult.errors.find(({ field }) => field === 'alternatePhone')
  const emailValid = !validationResult.errors.find(({ field }) => field === 'email')

  return (
    <>
      <EuiFlexGroup gutterSize="m">
        <EuiFlexItem grow={true}>
          <EuiFormRow label="First name" isInvalid={showValidationErrors && !firstNameValid} fullWidth>
            <EuiFieldText
              autoComplete="off"
              value={firstName ?? ''}
              onChange={(e) => setFirstName(e.target.value)}
              isInvalid={showValidationErrors && !firstNameValid}
            />
          </EuiFormRow>
        </EuiFlexItem>
        <EuiFlexItem grow={true}>
          <EuiFormRow label="Last name" isInvalid={showValidationErrors && !lastNameValid} fullWidth>
            <EuiFieldText
              autoComplete="off"
              value={lastName ?? ''}
              onChange={(e) => setLastName(e.target.value)}
              isInvalid={showValidationErrors && !lastNameValid}
            />
          </EuiFormRow>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer size="m" />

      <EuiFormRow label="Phone" fullWidth>
        <>
          <ContactFormPhoneField
            phone={phone}
            placeholder="Primary phone..."
            onChange={setPhone}
            showValidationErrors={showValidationErrors && !phoneValid}
            isPrimary={true}
          />
          <EuiSpacer size="s" />
          {isAlternatePhoneVisible && (
            <>
              <ContactFormPhoneField
                phone={alternatePhone}
                placeholder="Alternate phone..."
                onChange={setAlternatePhone}
                showValidationErrors={showValidationErrors && !alternatePhoneValid}
                isPrimary={false}
                canRemove={true}
                onRemove={onRemoveAlternatePhone}
              />
              <EuiSpacer size="s" />
            </>
          )}
          {!isAlternatePhoneVisible && (
            <EuiLink
              onClick={() => setIsAlternatePhoneVisible(true)}
              style={{ fontSize: '12px' }}
              disabled={isAlternatePhoneVisible}
            >
              Add alternate phone
            </EuiLink>
          )}
        </>
      </EuiFormRow>
      <EuiSpacer size="m" />

      <EuiFormRow label="Email" fullWidth>
        <>
          <ContactFormEmailField
            contactId={contactId}
            email={email ?? ''}
            onChange={setEmail}
            mustBeUnique={true}
            showValidationErrors={showValidationErrors}
            emailValid={emailValid}
            disabled={emailDisabled}
          />
          {/*
          <EuiSpacer size="xs" />
          <EuiLink onClick={() => console.log('add email')} style={{ fontSize: '12px' }} disabled={true}>
            Add another email
          </EuiLink> */}
        </>
      </EuiFormRow>
      <EuiSpacer size="m" />

      <EuiFlexGroup>
        <EuiFlexItem grow={true}>
          <AddressField
            initialEntryMode={AddressEntryMode.Autocomplete}
            address={detail?.address ?? undefined}
            onChange={(result) => setAddress(result)}
            showValidationErrors={showValidationErrors}
          />
        </EuiFlexItem>
      </EuiFlexGroup>
    </>
  )
}

export const createEmptyContactDetailFragment = (address?: AddressFragment): ContactDetailFragment => ({
  firstName: '',
  lastName: '',
  fullName: '',
  phone: '',
  email: '',
  address: address ?? {
    street: '',
    suburb: '',
    postcode: '',
    state: '',
    country: '',
    entryMode: AddressEntryMode.Autocomplete
  }
})
