import { EuiText } from '@elastic/eui'
import { Callout } from '../../../../common/callout'

export const CommercialCustomerDisclaimer = ({ isCustomerCommercial }: { isCustomerCommercial: boolean }) => {
  return (
    <>
      {isCustomerCommercial && (
        <Callout type="warning">
          <EuiText>
            <p>Please contact DSR for booking commercial jobs</p>
          </EuiText>
        </Callout>
      )}
    </>
  )
}
