import { WorkflowActionProps } from '../../workflow-model'
import { EuiButton, EuiSpacer } from '@elastic/eui'
import { Callout } from '../../../common/callout'
import { JobType, TradeType } from '../../../api/generated-types'
import { ItemInstaller, ItemSupplier, TopicActionInput } from '../action-topic-model'
import { TopicWorkflowAction } from '../action-topic-view'
import { SingleSelectField } from '../../question-fields/single-select-question-field'
import { FreeTextQuestionField } from '../../question-fields/free-text-question-field'
import { QuestionDefinitions, getNotesGenerator } from '../../helpers/notes-generator'
import { getResultUpdater } from '../../helpers/results-updater'
import { decameliseIfNotNil, makeHumanReadable } from '../../../common/utils'
import { CustomerSuppliedCompliant, IsItemAustralianCompliant } from '../../helpers/item-installer-questions'
import { OutOfScope } from '../../helpers/out-of-topic'
import { CustomerSuppliedTopicActionResult } from '../../action-topic-customer-supplied'
import { SecurityBrandQuestion } from '../../helpers/security-brand-question'
import { SecurityResidencyCheck } from '../../helpers/gold-coast-security-questions'

export enum AlarmServiceType {
  Installation = 'Installation',
  Repair = 'Repair',
  Inspection = 'Inspection'
}

export enum MonitoringType {
  Monitored = 'Monitored',
  Unmonitored = 'Unmonitored'
}

export enum AlarmInstallType {
  ExistingSystem = 'ExistingSystem',
  NewSystem = 'NewSystem'
}

export enum InstallDate {
  LessThanOrEqualTo3Years = 'Less than 3 years ago',
  Over3Years = 'Over 3 years ago'
}

export interface TopicAlarmActionResult extends CustomerSuppliedTopicActionResult {
  serviceType?: AlarmServiceType
  installType?: AlarmInstallType
  itemInstaller?: ItemInstaller
  installDate?: InstallDate
  propertyLevels?: string
  location?: string
  itemSupplier?: ItemSupplier
  monitoringType?: MonitoringType
  systemAge?: string
  fault?: string
  faultAge?: string
  brandType?: string
}

const questionDefinitions: QuestionDefinitions<TopicAlarmActionResult> = {
  serviceType: {
    question: 'Is this for an install, repair or health check/inspection?',
    statement: 'Service type:',
    makeAnswerHumanReadable: decameliseIfNotNil
  },
  installType: {
    question: 'Do you currently have an existing system?',
    statement: 'Existing system:',
    makeAnswerHumanReadable: decameliseIfNotNil
  },
  propertyLevels: {
    question: 'Double or single storey?',
    statement: 'ingle or double storey:'
  },
  location: {
    question: 'What areas of the house are we alarming?',
    statement: 'Location for alarms:'
  },
  itemInstaller: {
    question: 'Who installed this system?',
    statement: 'Item installer:'
  },
  installDate: {
    question: 'When did Fallon install?',
    makeAnswerHumanReadable: makeHumanReadable
  },
  itemSupplier: {
    question: 'Who is supplying the alarm system?',
    statement: 'Hardware supplied by:',
    makeAnswerHumanReadable: decameliseIfNotNil
  },
  monitoringType: {
    question: 'Is this going to be monitored or unmonitored?',
    statement: 'Monitoring:'
  },
  systemAge: {
    question: 'How old is the system?',
    statement: 'System age:'
  },
  fault: {
    question: 'What is the issue or fault?',
    statement: 'Issue or fault:'
  },
  faultAge: {
    question: 'How long has it been happening for?',
    statement: 'Fault age:'
  },
  brandType: {
    question: 'What brand/manufacture is the system?',
    statement: 'Brand/Manufacture:'
  }
}
const notesGenerator = getNotesGenerator(questionDefinitions)

export const TopicAlarmAction = (props: WorkflowActionProps<TopicActionInput, TopicAlarmActionResult>) => {
  const { result, input, onUpdate } = props
  const {
    serviceType,
    itemInstaller,
    installDate,
    installType,
    propertyLevels,
    location,
    itemSupplier,
    monitoringType,
    systemAge,
    fault,
    faultAge,
    australianCompliant,
    brandType
  } = result ?? {}
  const { serviceArea } = input

  const getJobType = () => {
    if (serviceType === AlarmServiceType.Repair) {
      return itemInstaller === ItemInstaller.Fallon && installDate === InstallDate.LessThanOrEqualTo3Years
        ? JobType.Callback
        : JobType.Service
    } else if (serviceType === AlarmServiceType.Installation) {
      return JobType.Quote
    } else if (serviceType === AlarmServiceType.Inspection) {
      return JobType.Inspection
    } else {
      return JobType.Service
    }
  }

  const onNext = () =>
    onUpdate({
      ...result,
      actionCompleted: true,
      trade: TradeType.Security,
      category: 'Alarm',
      type: getJobType(),
      workRequiredNotes: generatedNotes,
      requirements: {
        travelFeeWaived: serviceType === AlarmServiceType.Installation && itemSupplier === ItemSupplier.Fallon,
        attributes: [{ attributeId: 'Alarm' }]
      }
    })

  const generatedNotes = notesGenerator.generateNotes(result ?? {})
  const updateResult = getResultUpdater(result ?? {}, onUpdate).updateResult
  const updateBrandTypeQuestion = (brandType?: string) => updateResult({ brandType })
  const { jobIsCompliant, jobIsNotCompliant } = CustomerSuppliedCompliant({ australianCompliant })
  const installComplete =
    serviceType === AlarmServiceType.Installation &&
    installType &&
    propertyLevels &&
    location &&
    itemSupplier &&
    monitoringType &&
    jobIsCompliant
  const revisitComplete = serviceType === AlarmServiceType.Repair && itemInstaller === ItemInstaller.Fallon
  const repairComplete =
    serviceType === AlarmServiceType.Repair &&
    itemInstaller === ItemInstaller.SomeoneElse &&
    systemAge &&
    fault &&
    faultAge &&
    !!brandType
  const inspectionComplete = serviceType === AlarmServiceType.Inspection && systemAge && !!brandType
  const canComplete = installComplete || revisitComplete || repairComplete || inspectionComplete

  return (
    <TopicWorkflowAction input={input} onUpdate={onUpdate} result={result}>
      <SingleSelectField
        question={notesGenerator.getQuestion('serviceType')}
        options={[
          {
            id: AlarmServiceType.Installation,
            label: 'Installation',
            icon: 'plus'
          },
          {
            id: AlarmServiceType.Inspection,
            label: 'Health check / inspection',
            icon: 'search'
          },
          {
            id: AlarmServiceType.Repair,
            label: 'Repair',
            icon: 'wrench'
          }
        ]}
        answer={serviceType}
        changeAnswer={(serviceType) =>
          updateResult({
            serviceType,
            itemInstaller: undefined,
            itemSupplier: undefined
          })
        }
      />

      {serviceType === AlarmServiceType.Installation && (
        <>
          <SingleSelectField
            question={notesGenerator.getQuestion('installType')}
            options={[
              {
                id: AlarmInstallType.ExistingSystem,
                label: 'Existing system'
              },
              {
                id: AlarmInstallType.NewSystem,
                label: 'New system'
              }
            ]}
            answer={installType}
            changeAnswer={(installType) => updateResult({ installType })}
          />
          <FreeTextQuestionField
            question={notesGenerator.getQuestion('propertyLevels')}
            answer={propertyLevels}
            changeAnswer={(propertyLevels) => updateResult({ propertyLevels })}
          />
          <FreeTextQuestionField
            question={notesGenerator.getQuestion('location')}
            answer={location}
            changeAnswer={(location) => updateResult({ location })}
            rows={3}
          />
          <SingleSelectField
            question={notesGenerator.getQuestion('itemSupplier')}
            options={[
              {
                id: ItemSupplier.Fallon,
                icon: 'check'
              },
              {
                id: ItemSupplier.Customer,
                icon: 'user'
              }
            ]}
            answer={itemSupplier}
            changeAnswer={(itemSupplier) => updateResult({ itemSupplier })}
          />
          <IsItemAustralianCompliant
            updateResult={updateResult}
            itemSupplier={itemSupplier}
            suppliedAnswer={australianCompliant}
          />
          {jobIsNotCompliant ? (
            <OutOfScope issue={'Security alarms'} isOutOfScope={true} />
          ) : (
            <>
              <SingleSelectField
                question={notesGenerator.getQuestion('monitoringType')}
                options={[
                  {
                    id: MonitoringType.Monitored,

                    icon: 'check'
                  },
                  {
                    id: MonitoringType.Unmonitored,
                    icon: 'cross'
                  }
                ]}
                answer={monitoringType}
                changeAnswer={(monitoringType) => updateResult({ monitoringType })}
              />
            </>
          )}
        </>
      )}

      {serviceType === AlarmServiceType.Inspection && (
        <>
          <FreeTextQuestionField
            question={notesGenerator.getQuestion('systemAge')}
            answer={systemAge}
            changeAnswer={(systemAge) => updateResult({ systemAge })}
          />
          <SecurityBrandQuestion
            question={notesGenerator.getQuestion('brandType')}
            questionAnswer={brandType}
            updateQuestion={updateBrandTypeQuestion}
          />
        </>
      )}

      {serviceType === AlarmServiceType.Repair && (
        <>
          <SingleSelectField
            question={notesGenerator.getQuestion('itemInstaller')}
            options={[
              {
                id: ItemInstaller.Fallon,
                label: 'Fallon installed',
                icon: 'check'
              },
              {
                id: ItemInstaller.SomeoneElse,
                label: 'Someone else installed',
                icon: 'user'
              }
            ]}
            answer={itemInstaller}
            changeAnswer={(itemInstaller) => updateResult({ itemInstaller })}
          />
          <SecurityBrandQuestion
            question={notesGenerator.getQuestion('brandType')}
            questionAnswer={brandType}
            updateQuestion={updateBrandTypeQuestion}
          />
        </>
      )}

      {serviceType === AlarmServiceType.Repair && itemInstaller === ItemInstaller.Fallon && (
        <>
          <SingleSelectField
            question={notesGenerator.getQuestion('installDate')}
            options={[
              {
                id: InstallDate.LessThanOrEqualTo3Years,
                label: 'Within last 3 years'
              },
              {
                id: InstallDate.Over3Years,
                label: 'Over 3 years ago'
              }
            ]}
            answer={installDate}
            changeAnswer={(installDate) => updateResult({ installDate })}
          />
          {itemInstaller === ItemInstaller.Fallon && !!installDate && (
            <>
              <Callout
                type="note"
                title={
                  installDate === InstallDate.LessThanOrEqualTo3Years
                    ? 'Revisit with no travel fee'
                    : 'Normal service job with travel fee'
                }
              />
              <EuiSpacer />
            </>
          )}
        </>
      )}

      {serviceType === AlarmServiceType.Repair && itemInstaller === ItemInstaller.SomeoneElse && (
        <>
          <FreeTextQuestionField
            question={notesGenerator.getQuestion('systemAge')}
            answer={systemAge}
            changeAnswer={(systemAge) => updateResult({ systemAge })}
          />
          <FreeTextQuestionField
            question={notesGenerator.getQuestion('fault')}
            answer={fault}
            changeAnswer={(fault) => updateResult({ fault })}
            rows={3}
          />
          <FreeTextQuestionField
            question={notesGenerator.getQuestion('faultAge')}
            answer={faultAge}
            changeAnswer={(faultAge) => updateResult({ faultAge })}
          />
        </>
      )}

      <EuiSpacer />
      {!!serviceType && (
        <>
          <SecurityResidencyCheck serviceArea={serviceArea} />
        </>
      )}
      <EuiButton disabled={!canComplete} onClick={() => onNext()}>
        Next
      </EuiButton>
    </TopicWorkflowAction>
  )
}
