import { useState } from 'react'
import { EuiButtonIcon, EuiFieldText, EuiFlexGroup, EuiFlexItem, EuiLink, EuiLoadingSpinner } from '@elastic/eui'

export interface EditableSingleLineLabelProps {
  value: string | null | undefined
  saving: boolean
  onValueChange: (value: string | null | undefined) => void
}

export const EditableSingleLineLabel = (props: EditableSingleLineLabelProps) => {
  const { value, onValueChange, saving: loading } = props
  const [editing, setEditing] = useState(false)
  const [localValue, setLocalValue] = useState(value)

  const save = () => {
    setEditing(false)
    onValueChange(localValue)
  }

  const cancel = () => {
    setEditing(false)
    setLocalValue(value)
  }

  return (
    <>
      {!editing && !loading && (
        <EuiLink color="subdued" onClick={() => setEditing(true)}>
          {localValue || 'Not provided'}
        </EuiLink>
      )}
      {editing && (
        <EuiFlexGroup gutterSize="none">
          <EuiFlexItem grow={false}>
            <EuiFieldText
              style={{ width: 300 }}
              value={localValue || ''}
              onChange={(e) => setLocalValue(e.target.value)}
            ></EuiFieldText>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiButtonIcon size="m" iconType="check" color="primary" aria-label="save" onClick={save}></EuiButtonIcon>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiButtonIcon
              size="m"
              iconType="cross"
              color="accent"
              aria-label="cancel"
              onClick={cancel}
            ></EuiButtonIcon>
          </EuiFlexItem>
        </EuiFlexGroup>
      )}
      {loading && (
        <EuiFlexGroup gutterSize="none">
          <EuiFlexItem grow={false}>
            <EuiFieldText
              style={{ width: 300 }}
              value={localValue || ''}
              onChange={(e) => setLocalValue(e.target.value)}
            ></EuiFieldText>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiLoadingSpinner size="l" style={{ marginTop: '7px', marginLeft: '7px' }} />
          </EuiFlexItem>
        </EuiFlexGroup>
      )}
    </>
  )
}
