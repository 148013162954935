import React, { MouseEventHandler } from 'react'
import { EuiButton, EuiCallOut, EuiLink, EuiSpacer, EuiText } from '@elastic/eui'

interface LoadErrorProps {
  message: string
  retryClick?: MouseEventHandler<HTMLButtonElement>
}
export const LoadingError = ({ message, retryClick }: LoadErrorProps) => {
  return (
    <div style={{ padding: '24px' }}>
      <EuiCallOut title="Error loading app" iconType="alert">
        <EuiText>
          <div>{message}</div>
          <div>
            Please try again and if the problem doesn't resolve, contact{' '}
            <EuiLink href="mailto:dev@fallonsolutions.com.au">dev@fallonsolutions.com.au</EuiLink>.
          </div>
        </EuiText>
        <EuiSpacer size="m" />
        <EuiButton onClick={retryClick}>Retry</EuiButton>
      </EuiCallOut>
    </div>
  )
}
