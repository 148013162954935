import { EuiFlexGroup, EuiFlexItem, EuiText } from '@elastic/eui'
import { makeHumanReadable } from '@falloncore/util'
import { dateConfig, dateUtils } from '@fallonsolutions/date'
import {
  ActionRequiredJobStatusDetailFragment,
  JobStatusFragment,
  JobStatusType,
  OnHoldJobStatusDetailFragment,
  ReviewJobStatusDetailFragment
} from '@fallonsolutions/types'
import { JobStatusBadge } from './job-status-badge'

export interface JobStatusBadgeExtendedProps {
  status?: JobStatusFragment
  editable?: boolean
}

export const JobStatusBadgeExtended = ({ status, editable }: JobStatusBadgeExtendedProps) => {
  const jobStatusDetail = status?.detail
  const jobStatusReason =
    (jobStatusDetail as OnHoldJobStatusDetailFragment)?.onHoldReason ??
    (jobStatusDetail as ReviewJobStatusDetailFragment)?.reviewReason ??
    (jobStatusDetail as ActionRequiredJobStatusDetailFragment)?.actionRequiredReason
  const jobStatusExpiryDate = (jobStatusDetail as OnHoldJobStatusDetailFragment)?.expiryDate
  const jobStatusExpiryDateStr = jobStatusExpiryDate
    ? dateUtils.fromISO(jobStatusExpiryDate).toFormat(dateConfig.luxonFormat.dateWithYear)
    : undefined

  return (
    <EuiFlexGroup gutterSize="s" alignItems="center">
      <EuiFlexItem grow={false}>
        <JobStatusBadge status={status?.status ?? JobStatusType.NotStarted} editable={editable} />
      </EuiFlexItem>
      {jobStatusReason && (
        <EuiFlexItem grow={false}>
          <EuiFlexGroup direction="column" gutterSize="none">
            <EuiFlexItem grow={false}>
              <EuiText size="xs" color="subdued">
                {makeHumanReadable(jobStatusReason ?? '')}
              </EuiText>
            </EuiFlexItem>
            {jobStatusExpiryDateStr && (
              <EuiFlexItem grow={false}>
                <EuiText size="xs" color="subdued">
                  ETA {jobStatusExpiryDateStr}
                </EuiText>
              </EuiFlexItem>
            )}
          </EuiFlexGroup>
        </EuiFlexItem>
      )}
    </EuiFlexGroup>
  )
}
