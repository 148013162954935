import React from 'react'
import { JobTopicType } from '@fallonsolutions/types'
import { EnquiryAbortDetails } from '../workflow-model'

export interface IWorkflowProvidedContext {
  enquiryId?: string
  changeJobTopic: (jobTopic: JobTopicType) => void
  abort: (abortDetails: EnquiryAbortDetails) => void
}

const throwErrorAboutMissingContext = () => {
  throw new Error('Please wrap your component with <WorkflowProvider>')
}

export const WorkflowProviderContext = React.createContext<IWorkflowProvidedContext>({
  enquiryId: undefined,
  changeJobTopic: () => {
    throwErrorAboutMissingContext()
  },
  abort: () => {
    throwErrorAboutMissingContext()
  }
})

export const useWorkflow = () => React.useContext(WorkflowProviderContext)

export interface WorkflowProviderProps {
  enquiryId?: string
  onChangeJobTopic: (jobTopic: JobTopicType) => void
  onAbort: (abortDetails: EnquiryAbortDetails) => void
  children: React.ReactNode
}

export const WorkflowProvider = (props: WorkflowProviderProps) => {
  const { enquiryId, onChangeJobTopic, onAbort } = props

  return (
    <WorkflowProviderContext.Provider value={{ changeJobTopic: onChangeJobTopic, abort: onAbort, enquiryId }}>
      {props.children}
    </WorkflowProviderContext.Provider>
  )
}
