import { first, last } from 'lodash-es'
import { DateTime } from 'luxon'
import { AppointmentFragment, JobFragment, JobSearchBetaResultFragment } from '../api/generated-types'
import { getBookingWindow } from '../appointments/appointment-helpers'
import { dateConfig } from '../common/date-config-luxon'

export const jobDateString = (job: JobSearchBetaResultFragment | JobFragment): string => {
  if (!job.appointments || job.appointments.length <= 0) {
    return 'No appointments'
  }
  const firstAppointment = last(job.appointments)
  const lastAppointment = job.appointments.length > 1 ? first(job.appointments) : null
  const firstBookingWindow = getBookingWindow(firstAppointment)
  const lastBookingWindow = getBookingWindow(lastAppointment)
  if (!firstBookingWindow) {
    return 'No appointments'
  }

  const firstScheduledDate = DateTime.fromISO(firstBookingWindow.from)
  const lastScheduledDate = lastBookingWindow ? DateTime.fromISO(lastBookingWindow.from) : null
  if (lastScheduledDate && !firstScheduledDate.hasSame(lastScheduledDate, 'day')) {
    let startDateFormat = dateConfig.dateWithYear
    let endDateFormat = dateConfig.dateWithYear
    if (firstScheduledDate.hasSame(lastScheduledDate, 'month')) {
      startDateFormat = dateConfig.shortDateNoMonth
      endDateFormat = dateConfig.fullDate
    } else if (firstScheduledDate.hasSame(lastScheduledDate, 'year')) {
      startDateFormat = dateConfig.shortDate
    }
    const start = firstScheduledDate.toFormat(startDateFormat)
    const end = lastScheduledDate.toFormat(endDateFormat)
    return `${start} - ${end}`
  } else {
    return firstScheduledDate.toFormat(dateConfig.fullDate)
  }
}

export interface AppointmentStringOptions {
  includeDate?: boolean
}

export const appointmentString = (appointment: AppointmentFragment | null, opts?: AppointmentStringOptions): string => {
  if (!appointment?.scheduled?.from) {
    return 'No scheduled window'
  }
  const date = DateTime.fromISO(appointment.scheduled.from)
  if (opts?.includeDate === false) {
    return date.toFormat(dateConfig.time).toLowerCase()
  } else {
    return `${date.toFormat(dateConfig.fullDate)} - ${date.toFormat(dateConfig.time).toLowerCase()}`
  }
}

export const compareAppointmentTime = (
  appointmentA: AppointmentFragment,
  appointmentB: AppointmentFragment
): number => {
  const scheduledFromA = appointmentA.scheduled?.from
  const scheduledFromB = appointmentB.scheduled?.from
  const startDateA = scheduledFromA ? DateTime.fromISO(scheduledFromA) : DateTime.utc(0)
  const startDateB = scheduledFromB ? DateTime.fromISO(scheduledFromB) : DateTime.utc(0)
  const differenceInMilliseconds = startDateA.diff(startDateB).shiftTo('milliseconds').toObject().milliseconds
  return differenceInMilliseconds ?? 0
}
