import { CustomerFragment, GetCustomerDocument } from '../api/generated-types'
import { PreviewCard } from '../common/preview/preview-card'
import { CustomerDetail } from './customer-detail'

export interface CustomerPreviewProps {
  customerId: string
}

export const CustomerPreview = ({ customerId }: CustomerPreviewProps) => {
  const renderCustomer = (customer: CustomerFragment | undefined) => {
    if (!customer) {
      return <></>
    }
    return (
      <div style={{ padding: '10px' }}>
        <CustomerDetail customer={customer} />
      </div>
    )
  }

  return (
    <PreviewCard
      title="Customer"
      link={{
        url: `/customers/${customerId}`,
        label: 'Open Customer'
      }}
      query={GetCustomerDocument}
      variables={{ id: customerId }}
      modelName="customer"
    >
      {({ data }) => renderCustomer(data.getCustomer ?? undefined)}
    </PreviewCard>
  )
}
