import { EuiComboBoxOptionOption, EuiFieldSearch, EuiFlexGroup, EuiFlexItem, EuiSpacer } from '@elastic/eui'
import { dateConfig } from '@fallonsolutions/date'
import { CallCenterQueueSubGroup } from '@fallonsolutions/types'
import { compact } from 'lodash-es'
import moment, { Moment } from 'moment-timezone'
import { useEffect, useState } from 'react'
import { useDebouncedValue } from 'rooks'
import { StringParam, useQueryParam } from 'use-query-params'
import {
  CallCenterQueueGroup,
  ContactSource,
  InteractionAnswerStatus,
  InteractionDirection,
  InteractionMessageSource,
  InteractionMessageStatusType,
  SearchInteractionMessagesFilter
} from '../../api/generated-types'
import { useAuthenticated } from '../../auth/authenticated-context.tsx'
import { CallCenterQueueComboBox } from '../../call-centre/call-center-queue/call-center-queue-combo-box'
import DatePicker from '../../common/date-picker'
import EnumTypeFacets from '../../common/enum-type-facets'
import { createDateFilter } from '../../common/filters'
import VerticalDivider from '../../common/vertical-divider'
import CustomerComboBox, { CustomerComboBoxItem } from '../../customers/customer-combo-box'
import { DispositionComboBox } from '../../dispositions/disposition-combo-box'
import JobComboBox, { JobComboBoxValue } from '../../jobs/job-combo-box'
import { CampaignComboBox } from '../../marketing/campaign/campaign-combo-box'
import { PhoneNumberComboBox } from '../../phone-number/phone-number-combo-box'
import UserComboBox, { UserComboItem } from '../../users/user-combo-box'

interface InteractionMessageListFiltersProps {
  onChangeInput: (input: SearchInteractionMessagesFilter) => void
}

export const InteractionMessageListFilters = (props: InteractionMessageListFiltersProps) => {
  const userFragment = useAuthenticated().userFragment
  const hasDeveloperFeatures = userFragment.permissions?.developerFeatures === true

  const { onChangeInput } = props

  const [query, setQuery] = useState('')
  const [source, setSource] = useState<InteractionMessageSource[]>([])
  const [campaign, setCampaign] = useState<EuiComboBoxOptionOption<string>[]>([])
  const [assignedUser, setAssignedUser] = useState<UserComboItem[]>([])
  const [startDate, setStartDate] = useQueryParam('from', StringParam, { updateType: 'replaceIn' })
  const [endDate, setEndDate] = useQueryParam('to', StringParam, { updateType: 'replaceIn' })
  const [internalPhoneNumber, setInternalPhoneNumber] = useState<EuiComboBoxOptionOption<string>[]>([])
  const [status, setStatus] = useState<InteractionMessageStatusType[]>([])
  const [direction, setDirection] = useState<InteractionDirection[]>([InteractionDirection.Inbound])
  const [callCenterQueue, setCallCenterQueue] = useState<EuiComboBoxOptionOption[]>([])
  const [firstCallCenterQueueGroup, setFirstCallCenterQueueGroup] = useState<CallCenterQueueGroup[]>([
    CallCenterQueueGroup.CustomerService
  ])
  const [tailCallCenterQueueSubGroupInclude, setTailCallCenterQueueSubGroupInclude] = useState<
    undefined | CallCenterQueueSubGroup[]
  >([])
  const [tailCallCenterQueueSubGroupExclude, setTailCallCenterQueueSubGroupExclude] = useState<
    undefined | CallCenterQueueSubGroup[]
  >([])
  const [answerStatus, setAnswerStatus] = useState<InteractionAnswerStatus[]>([])
  const [contactSource, setContactSource] = useState<ContactSource[]>([ContactSource.External])
  const [disposition, setDisposition] = useState<EuiComboBoxOptionOption[]>([])
  const [customerId, setCustomerId] = useState<CustomerComboBoxItem[]>([])
  const [jobIds, setJobIds] = useState<JobComboBoxValue[]>([])

  const onSetStartDate = (newDate: Moment) =>
    newDate ? setStartDate(newDate.format(dateConfig.format.basic)) : setStartDate(null)
  const onSetEndDate = (newDate: Moment) =>
    newDate ? setEndDate(newDate.format(dateConfig.format.basic)) : setEndDate(null)

  const [debouncedQuery] = useDebouncedValue(query, 150)

  useEffect(() => {
    const buildFilters = () => {
      const newInput: SearchInteractionMessagesFilter = {
        ...(debouncedQuery && debouncedQuery.length && { query: debouncedQuery }),
        ...(source.length && { source }),
        ...(campaign.length && { campaignId: compact(campaign.map((c) => c.id)) }),
        ...(assignedUser.length && { assignedUserId: compact(assignedUser.map((u) => u.id)) }),
        ...((startDate || endDate) && { startDate: createDateFilter(startDate, endDate) }),
        ...(internalPhoneNumber.length && { internalPhoneNumberId: compact(internalPhoneNumber.map((p) => p.id)) }),
        ...(status.length && { status }),
        ...(direction.length && { direction }),
        ...(callCenterQueue.length && { callCenterQueueId: compact(callCenterQueue.map((q) => q.id)) }),
        ...(firstCallCenterQueueGroup.length && { firstCallCenterQueueGroup }),
        ...(tailCallCenterQueueSubGroupInclude?.length && {
          tailCallCenterQueueSubGroup: tailCallCenterQueueSubGroupInclude
        }),
        ...(tailCallCenterQueueSubGroupExclude?.length && { tailCallCenterQueueSubGroupExclude }),
        ...(answerStatus.length && { answerStatus }),
        ...(contactSource.length && { contactSource }),
        ...(disposition.length && { dispositionId: compact(disposition.map((d) => d.id)) }),
        ...(customerId.length && { customerId: compact(customerId.map((c) => c.id)) }),
        ...(jobIds.length && { jobIds: compact(jobIds.map((j) => j.id)) })
      }
      return newInput
    }
    const newInput = buildFilters()
    onChangeInput(newInput)
  }, [
    answerStatus,
    assignedUser,
    campaign,
    contactSource,
    customerId,
    debouncedQuery,
    direction,
    disposition,
    endDate,
    callCenterQueue,
    firstCallCenterQueueGroup,
    tailCallCenterQueueSubGroupInclude,
    tailCallCenterQueueSubGroupExclude,
    internalPhoneNumber,
    jobIds,
    onChangeInput,
    source,
    startDate,
    status
  ])

  return (
    <div>
      <EuiFlexGroup justifyContent="flexStart" alignItems="flexStart" wrap={true}>
        <EuiFlexItem grow={false}>
          <EuiFlexGroup justifyContent="flexStart" alignItems="center" wrap={true}>
            <EuiFlexItem grow={false}>
              <EuiFieldSearch
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                placeholder="Contact or external number"
                style={{ minWidth: '300px' }}
              />
            </EuiFlexItem>
            {/* <EuiFlexItem grow={false}>
              <EnumTypeFacets
                enumType={InteractionMessageSource}
                types={source}
                setTypes={(values: string[]) => setSource(values as InteractionMessageSource[])}
              />
            </EuiFlexItem>

            <EuiFlexItem grow={false}>
              <VerticalDivider height="24px" />
            </EuiFlexItem> */}

            <EuiFlexItem grow={false}>
              <CampaignComboBox campaigns={campaign} onChange={setCampaign} />
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <PhoneNumberComboBox
                label="Internal number"
                value={internalPhoneNumber}
                onChange={setInternalPhoneNumber}
              />
            </EuiFlexItem>

            <EuiFlexItem grow={false}>
              <CallCenterQueueComboBox queues={callCenterQueue} onChange={setCallCenterQueue} />
            </EuiFlexItem>

            <EuiFlexItem grow={false}>
              <DispositionComboBox dispositions={disposition} onChange={setDisposition} />
            </EuiFlexItem>

            <EuiFlexItem grow={false}>
              <UserComboBox
                users={assignedUser}
                onChangeUsers={setAssignedUser}
                label="Assigned user"
                placeholder="Assigned users"
              />
            </EuiFlexItem>

            <EuiFlexItem grow={false}>
              <JobComboBox jobs={jobIds} onChangeJobs={setJobIds} />
            </EuiFlexItem>

            <EuiFlexItem grow={false}>
              <CustomerComboBox customers={customerId} onChangeCustomers={setCustomerId} />
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>

        <EuiFlexItem grow={true} />

        <EuiFlexItem grow={false}>
          <DatePicker
            startDate={startDate ? moment(startDate, dateConfig.format.basic) : null}
            endDate={endDate ? moment(endDate, dateConfig.format.basic) : null}
            setStartDate={onSetStartDate}
            setEndDate={onSetEndDate}
            quickNav={true}
          />
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiFlexGroup justifyContent="flexStart" alignItems="flexStart" wrap={true}>
        <EuiFlexItem grow={false}>
          <EnumTypeFacets
            enumType={InteractionAnswerStatus}
            types={answerStatus}
            setTypes={(values: string[]) => setAnswerStatus(values as InteractionAnswerStatus[])}
          />
        </EuiFlexItem>

        <EuiFlexItem grow={false}>
          <VerticalDivider height="24px" />
        </EuiFlexItem>

        <EuiFlexItem grow={false}>
          <EnumTypeFacets
            enumType={InteractionDirection}
            types={direction}
            setTypes={(values: string[]) => setDirection(values as InteractionDirection[])}
          />
        </EuiFlexItem>

        <EuiFlexItem grow={false}>
          <VerticalDivider height="24px" />
        </EuiFlexItem>

        <EuiFlexItem grow={false}>
          <EnumTypeFacets
            enumType={ContactSource}
            types={contactSource}
            setTypes={(values: string[]) => setContactSource(values as ContactSource[])}
          />
        </EuiFlexItem>

        <EuiFlexItem grow={true} />
      </EuiFlexGroup>

      <EuiSpacer />
      <EuiFlexGroup justifyContent="flexStart" alignItems="flexStart" wrap={true}>
        <EuiFlexItem grow={false}>
          <EuiFlexGroup justifyContent="flexStart" alignItems="center" wrap={true}>
            <EuiFlexItem grow={false}>
              <EuiFlexGroup justifyContent="flexStart" wrap={true} direction="column" gutterSize="none">
                <EuiFlexItem grow={false}>
                  <EnumTypeFacets
                    enumType={CallCenterQueueGroup}
                    types={firstCallCenterQueueGroup}
                    setTypes={(values: string[]) => setFirstCallCenterQueueGroup(values as CallCenterQueueGroup[])}
                  />
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <EnumTypeFacets
                    labels={{
                      External: 'Offshore'
                    }}
                    allowExclude={true}
                    enumType={CallCenterQueueSubGroup}
                    types={tailCallCenterQueueSubGroupInclude ?? []}
                    setTypes={(values: string[]) =>
                      setTailCallCenterQueueSubGroupInclude(values as CallCenterQueueSubGroup[])
                    }
                    typesExclude={tailCallCenterQueueSubGroupExclude ?? []}
                    setTypesExclude={(values: string[]) =>
                      setTailCallCenterQueueSubGroupExclude(values as CallCenterQueueSubGroup[])
                    }
                  />
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlexItem>

            <EuiFlexItem grow={false}>
              <VerticalDivider height="48px" />
            </EuiFlexItem>

            <EuiFlexItem grow={false}>
              <EnumTypeFacets
                enumType={InteractionMessageStatusType}
                types={status}
                setTypes={(values: string[]) => setStatus(values as InteractionMessageStatusType[])}
              />
            </EuiFlexItem>
            <EuiFlexItem grow={true} />
          </EuiFlexGroup>
        </EuiFlexItem>
      </EuiFlexGroup>
    </div>
  )
}
