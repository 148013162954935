import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiLink,
  EuiLoadingSpinner,
  EuiPanel,
  EuiSpacer,
  EuiText,
  EuiTitle
} from '@elastic/eui'
import { contactFragmentToContactInput } from '@fallonsolutions/contact'
import { isEqual } from 'lodash-es'
import { useEffect, useState } from 'react'
import { ContactFragment, ContactInput, CustomerLinkFragment, InteractionMessageFragment } from '../api/generated-types'
import { useAuthenticated } from '../auth/authenticated-context'
import { Callout } from '../common/callout'
import { formatPhone } from '../common/phone'
import { ContactSearchContainer } from '../contacts/contact-search/contact-search-container'
import { useContactSearch } from '../contacts/contact-search/contact-search-hook'
import {
  ContactSearchFields,
  ContactSearchModel,
  emptyContactSearchModel
} from '../contacts/contact-search/contact-search-model'
import { CustomerSearchFields } from '../customers/customer-search'
import { useInteractionMessageCreateEnquiryService } from './interaction-message-create-enquiry-service'
import { OnChangeContactProps, toSelectedCustomer } from './workflow-interaction-message-model'
import { NewEnquiryResult } from './workflow-new-enquiry-model'

interface InteractionMessageNewEnquiryLauncherProps {
  interactionMessage: InteractionMessageFragment
  customer?: CustomerLinkFragment | undefined
  onChangeContact: ({ customer, contactId, contactInput }: OnChangeContactProps) => Promise<void>
  onEnquiryCreated: (enquiryId: string, initialResult: any) => void
}

export const InteractionMessageNewEnquiryLauncher = ({
  interactionMessage,
  customer,
  onChangeContact,
  onEnquiryCreated
}: InteractionMessageNewEnquiryLauncherProps) => {
  const session = useAuthenticated().user
  const userFragment = useAuthenticated().userFragment

  //authorize(CreateBookingMutation, data)
  //authorize(CreateBookingManualMutation, data)
  const canCreateBooking = userFragment.permissions?.createBooking === true

  const { contact } = interactionMessage
  const [selectedCustomer, setSelectedCustomer] = useState<CustomerLinkFragment | undefined>(customer ?? undefined)

  const [searchState, dispatch] = useContactSearch()

  useEffect(() => {
    if (customer?.id !== selectedCustomer?.id && !selectedCustomer?.id) {
      setSelectedCustomer(customer)
      dispatch({ type: 'select_contact_customer', payload: { selectedCustomer: customer } })
    }
  }, [customer, selectedCustomer?.id])

  //initially set searchFields state, if empty
  useEffect(() => {
    const street = (contact?.address?.street ?? '' + !contact?.address?.street2) ? '' : `, ${contact?.address?.street2}`
    const initialSearchFields: ContactSearchFields = {
      ...emptyContactSearchModel,
      phone: getFormattedPhone() ?? '',
      firstName: contact?.firstName ?? '',
      lastName: contact?.lastName ?? '',
      street,
      suburb: contact?.address?.suburb ?? contact?.suburb?.label ?? '',
      suburbId: contact?.address?.suburbId ?? contact?.suburb?.id ?? '',
      suburbs: searchState.searchFields.suburbs ?? [],
      fuzzy: searchState.searchFields.fuzzy ?? true,
      reference: searchState.searchFields.reference ?? '',
      company: searchState.searchFields.company ?? ''
    }

    console.log('interaction-message-new-enquiry-launcher', 'initialSearchFields', initialSearchFields, searchState)
    if (!isEqual(initialSearchFields, searchState.searchFields)) {
      dispatch({ type: 'change_phone', payload: { searchFields: { phone: getFormattedPhone() } } })
      dispatch({ type: 'set_search_fields', payload: { searchFields: initialSearchFields } })
    }
  }, [contact?.firstName, contact?.lastName, interactionMessage.externalNumber])

  const [createNewEnquiryForInteractionMessage, { loading }] =
    useInteractionMessageCreateEnquiryService<NewEnquiryResult>()

  const handleSelectCustomer = async ({
    customer,
    contact
  }: {
    customer: CustomerLinkFragment
    contact?: ContactFragment
  }) => {
    setSelectedCustomer(customer)
    const contactInput = contact
      ? contactFragmentToContactInput(contact)
      : customer.mainContact
        ? contactFragmentToContactInput(customer.mainContact)
        : undefined

    await onChangeContact({
      customer,
      contactId: contact?.id ?? customer?.mainContact?.id ?? undefined,
      contactInput
    })
    const enquiryId = await createNewEnquiryForInteractionMessage({
      interactionMessageId: interactionMessage.id,
      customerId: customer.id
    })
    onEnquiryCreated(enquiryId, undefined) //let initial result be created
  }

  const handleSelectContact = async (contact: ContactFragment) => {
    const contactInput = contactFragmentToContactInput(contact)
    await onChangeContact({
      customer,
      contactId: contact.id,
      contactInput
    })
    const enquiryId = await createNewEnquiryForInteractionMessage({
      interactionMessageId: interactionMessage.id,
      contactId: contact.id,
      customerId: customer?.id
    })
    onEnquiryCreated(enquiryId, undefined) //let initial result be created
  }

  const handleContactSearchAction = (contactAction: ContactSearchModel) => {
    console.log('handleContactSearchAction', contactAction)
    if (contactAction.createNew === true) {
      handleNewCustomer(contactAction.searchFields)
    } else if (contactAction.selectedCustomer) {
      handleSelectCustomer({ customer: contactAction.selectedCustomer, contact: contactAction.selectedContact })
    } else if (contactAction.selectedContact) {
      handleSelectContact(contactAction.selectedContact)
    } else {
      console.log('handleContactSearchAction', 'unhandled')
    }
  }

  const handleNewCustomer = async (searchFields: CustomerSearchFields) => {
    const { firstName, lastName, phone, street, suburb, suburbId } = searchFields
    const contactParams = {
      firstName,
      lastName,
      phone,
      street,
      suburb,
      suburbId
    }

    //selecting a new customer, to create an enquiry with some default values for "creating a new customer" for new Booking to avoid asking repeat questions
    const initialContactActionCompleted = !!firstName && !!lastName && !!phone && !!suburbId && !!suburb
    const initialResult: NewEnquiryResult = {
      contact: {
        firstName: contactParams.firstName ?? contact?.firstName,
        lastName: contactParams.lastName ?? contact?.lastName,
        phone: contactParams.phone ?? contact?.phone,
        suburb: {
          id: contactParams.suburbId ?? contact?.address?.suburbId,
          label: contactParams.suburb ?? contact?.address?.suburb
        },
        actionCompleted: initialContactActionCompleted
      },
      ...(selectedCustomer
        ? selectedCustomer.mainContact && {
            customer: { customer: toSelectedCustomer(selectedCustomer), actionCompleted: initialContactActionCompleted }
          }
        : {
            customer: { customer: undefined, actionCompleted: true },
            newBooking: {
              customer: { customer: undefined, actionCompleted: true }
            }
          })
    }

    //provide more contact details if available
    const contactInput: ContactInput = {
      firstName,
      lastName,
      phone,
      ...(suburbId &&
        suburb && {
          suburb: { id: suburbId, label: suburb }
        })
    }
    //duplicate update, remove update contact leave to create new enquiry service
    console.log('interaction-message-new-enquiry-launcher', 'handleNewCustomer', 'onChangeContact')
    await onChangeContact({
      customer: undefined,
      contactId: undefined,
      contactInput
    })

    const enquiryId = await createNewEnquiryForInteractionMessage({
      interactionMessageId: interactionMessage.id,
      initialResult
    })
    onEnquiryCreated(enquiryId, initialResult)
  }

  const handleSkip = async () => {
    const initialResult: NewEnquiryResult = {
      contact: {
        actionCompleted: true
      }
    }
    const enquiryId = await createNewEnquiryForInteractionMessage({
      interactionMessageId: interactionMessage.id,
      initialResult
    })
    onEnquiryCreated(enquiryId, initialResult)
  }

  const getFormattedPhone = () => {
    if (contact?.phone) {
      return formatPhone(contact.phone)
    } else if (interactionMessage.externalNumber) {
      return formatPhone(interactionMessage.externalNumber)
    } else {
      return undefined
    }
  }
  const suburbId = contact?.address?.suburbId ?? contact?.suburb?.id
  const suburb = contact?.address?.suburb ?? contact?.suburb?.label

  console.log('interaction-message-new-enquiry-launcher', searchState.searchFields, 'suburb-select', suburbId, suburb)

  return (
    <>
      <EuiPanel style={{ minHeight: '300px' }}>
        {loading ? (
          <EuiLoadingSpinner />
        ) : (
          <>
            {canCreateBooking && (
              <>
                <EuiFlexGroup alignItems="center">
                  <EuiFlexItem grow={true}>
                    <EuiTitle size="s">
                      <h2>New enquiry</h2>
                    </EuiTitle>
                  </EuiFlexItem>
                  <EuiFlexItem grow={false}>
                    <EuiLink onClick={handleSkip}>Skip</EuiLink>
                  </EuiFlexItem>
                </EuiFlexGroup>

                <EuiSpacer size="m" />
                <Callout
                  type="script"
                  title={`Welcome to Fallon Solutions, you’re speaking with ${
                    session?.firstName ?? '(agent)'
                  } how may I help you?`}
                />
                <Callout type="script">
                  <EuiText>
                    <div>You are absolutely in the right place, that’s definitely something I can help with</div>
                    <EuiSpacer size="s" />
                    <div>To start can I please have your full name and suburb?</div>
                  </EuiText>
                </Callout>
                <EuiSpacer />

                <ContactSearchContainer allowCreate={true} onAction={handleContactSearchAction} />
              </>
            )}
          </>
        )}
      </EuiPanel>
    </>
  )
}
