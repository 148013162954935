import { useMutation } from '@apollo/client'
import {
  EuiBottomBar,
  EuiButton,
  EuiButtonEmpty,
  EuiButtonGroup,
  EuiButtonGroupOptionProps,
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
  EuiPopover,
  EuiSpacer,
  EuiSwitch,
  EuiText
} from '@elastic/eui'
import { compact } from 'lodash-es'
import { useState } from 'react'
import {
  DeleteMediaItemsDocument,
  MediaItemFragment,
  MediaType,
  RequestMediaItemsBatchDownloadDocument,
  RestoreMediaItemsDocument
} from '../api/generated-types'
import { useMixpanel } from '../app/mixpanel-provider'
import createPersistedState from '../use-persisted-state'
import { MediaItemList, MediaItemListDisplayType } from './media-item-list'

const useMediaItemWidth = createPersistedState<number>('media-item-width')
const useMediaItemListDisplayType = createPersistedState<MediaItemListDisplayType>('media-item-list-display-type')

interface MediaContainerProps {
  allMedia: MediaItemFragment[]
  jobId?: string
  viewDeletedStateChanged?: (deleted: boolean) => void
  viewDeleted?: boolean
  totalMediaItems?: number
  showJobLink?: boolean
}

interface MediaContainerWithJobContextProps extends Omit<MediaContainerProps, 'uploadMedia' | 'jobId'> {
  jobId: string
  uploadMedia: true
}

export enum PhotoSelectionMode {
  View = 'View',
  Select = 'Select'
}

type MediaContainerType = MediaContainerProps | MediaContainerWithJobContextProps
const MAX_MEDIA_ITEMS_TO_DOWNLOAD = 1000

export const MediaItemsContainer = (props: MediaContainerType) => {
  const { allMedia, jobId, viewDeletedStateChanged, viewDeleted, totalMediaItems, showJobLink } = props
  const [displayType, setDisplayType] = useMediaItemListDisplayType(MediaItemListDisplayType.GroupByHour)
  const [requestSuccess, setRequestSuccess] = useState<boolean>(false)
  const mixpanel = useMixpanel()

  // change batch edit link to to indicate when loading is going on
  const [requestMediaDownload, { data, loading: downloadMediaLoading }] = useMutation(
    RequestMediaItemsBatchDownloadDocument
  )
  const displayTypeOptions: EuiButtonGroupOptionProps[] = [
    { id: MediaItemListDisplayType.GroupByHour, label: 'Hour' },
    { id: MediaItemListDisplayType.GroupByDate, label: 'Date' },
    { id: MediaItemListDisplayType.GroupByContext, label: 'Category' }
  ]
  const [mediaItemWidth, setMediaItemWidth] = useMediaItemWidth(120)
  const [selectionMode, setSelectionMode] = useState<PhotoSelectionMode>(PhotoSelectionMode.View)

  const [selectedPhotos, setSelectedPhotos] = useState<string[]>([])
  const photoSelected = (photoIds: string[]) => {
    if (selectionMode === PhotoSelectionMode.View) {
      return
    }
    const newSelection = selectedPhotos
    const crossOvers = selectedPhotos.filter((id) => photoIds.includes(id))
    const photosFromNewBatchToInclude =
      compact(
        photoIds.map((id) => {
          if (!crossOvers.includes(id)) {
            return id
          }
          return null
        })
      ) ?? []
    const results = newSelection.filter((id) => !crossOvers.includes(id)).concat(photosFromNewBatchToInclude)
    setSelectedPhotos(results)
  }

  const [deleteMediaItems] = useMutation(DeleteMediaItemsDocument, {
    refetchQueries: ['GetJobMedia', 'GetJob']
  })
  const [restoreMediaItems] = useMutation(RestoreMediaItemsDocument, {
    refetchQueries: ['GetJobMedia', 'GetJob']
  })
  const handleRestoreMediaItems = (ids: string[]) => {
    const deletedIds = allMedia.filter((m) => m.deleted && ids.includes(m.id)).map((m) => m.id)
    restoreMediaItems({
      variables: {
        input: {
          ids: deletedIds
        }
      }
    })
  }
  const handleDeleteMediaItems = (ids: string[]) => {
    deleteMediaItems({
      variables: {
        input: {
          ids
        }
      }
    })
  }

  const handleMediaDownload = (mediaItemIds: string[]) => {
    setRequestSuccess(true)
    setTimeout(() => {
      setRequestSuccess(false)
    }, 6000)
    mixpanel?.track('DownloadedBatchMedia')
    requestMediaDownload({
      variables: {
        input: {
          filters: {
            mediaItemIds
          }
        }
      }
    })
  }

  const handleBatchMediaDownload = () => {
    handleMediaDownload(selectedPhotos)
    setSelectedPhotos([])
    setSelectionMode(PhotoSelectionMode.View)
  }

  const handleBatchDeleteMediaItems = () => {
    handleDeleteMediaItems(selectedPhotos)
    setSelectedPhotos([])
    setSelectionMode(PhotoSelectionMode.View)
  }
  const handleBatchRestoreMediaItems = () => {
    handleRestoreMediaItems(selectedPhotos)
    setSelectedPhotos([])
    setSelectionMode(PhotoSelectionMode.View)
  }

  const handleCancelEdit = () => {
    setSelectedPhotos([])
    setSelectionMode(PhotoSelectionMode.View)
  }

  const downloadImagesOnly = () => {
    const mediaItemsToDownloadIds: string[] = allMedia.filter((m) => m.type === MediaType.Image).map((m) => m.id)
    if (mediaItemsToDownloadIds.length > 0) {
      handleMediaDownload(mediaItemsToDownloadIds)
    }
  }

  const downloadImagesAndDocuments = () => {
    const mediaItemsToDownloadIds: string[] = allMedia
      .filter((m) => {
        return m.type === MediaType.Image || m.type === MediaType.Document
      })
      .map((m) => m.id)
    if (mediaItemsToDownloadIds.length > 0) {
      handleMediaDownload(mediaItemsToDownloadIds)
    }
  }

  const showSuccess = requestSuccess && data?.requestMediaItemsBatchDownload?.id
  const [downloadMediaPopOverOpen, setDownloadMediaPopOverOpen] = useState(false)
  const popOverButtonClick = () => {
    setDownloadMediaPopOverOpen(!downloadMediaPopOverOpen)
  }
  const canDownloadFilteredMediaItems = !!totalMediaItems && totalMediaItems <= MAX_MEDIA_ITEMS_TO_DOWNLOAD
  const popOverButton = () => {
    return (
      <EuiButtonEmpty onClick={popOverButtonClick} iconType="download" disabled={canDownloadFilteredMediaItems}>
        Download media
      </EuiButtonEmpty>
    )
  }
  const disableDownloadButton = allMedia.length === 0
  return (
    <>
      <EuiFlexGroup alignItems="center" gutterSize="m">
        <EuiFlexItem grow={false}>
          <EuiButtonGroup
            idSelected={displayType}
            options={displayTypeOptions}
            onChange={(id: string) => setDisplayType(id as MediaItemListDisplayType)}
            legend="View"
            color="primary"
            buttonSize="compressed"
          />
        </EuiFlexItem>
        {viewDeleted !== undefined && (
          <EuiFlexItem grow={false}>
            <EuiSwitch
              label="Include deleted"
              checked={viewDeleted}
              compressed={true}
              onChange={() => !!viewDeletedStateChanged && viewDeletedStateChanged(!viewDeleted)}
            />
          </EuiFlexItem>
        )}
        <EuiFlexItem grow={true} />
        {selectionMode !== PhotoSelectionMode.Select && (
          <>
            {showSuccess ? (
              <EuiFlexItem grow={false}>
                <EuiFlexGroup alignItems="center" gutterSize="xs" direction="row">
                  <EuiFlexItem grow={false}>
                    <EuiIcon type="check" color="green" />
                  </EuiFlexItem>
                  <EuiFlexItem grow={false}>
                    <EuiText color="green">download request received</EuiText>
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiFlexItem>
            ) : (
              <>
                <EuiFlexGroup direction="row" gutterSize="s" alignItems="center">
                  <EuiFlexItem grow={true}></EuiFlexItem>

                  <EuiFlexItem grow={false}>
                    <EuiPopover
                      button={popOverButton()}
                      isOpen={downloadMediaPopOverOpen}
                      closePopover={() => setDownloadMediaPopOverOpen(false)}
                    >
                      <EuiFlexGroup direction="column" gutterSize="s" alignItems="flexStart">
                        <EuiFlexItem grow={false}>
                          <EuiButtonEmpty
                            onClick={downloadImagesAndDocuments}
                            disabled={disableDownloadButton}
                            iconType="document"
                          >
                            <EuiText size="xs">Download images and documents</EuiText>
                          </EuiButtonEmpty>
                        </EuiFlexItem>
                        <EuiFlexItem grow={false}>
                          <EuiButtonEmpty
                            onClick={downloadImagesOnly}
                            disabled={disableDownloadButton}
                            iconType="image"
                          >
                            <EuiText size="xs">Download images only</EuiText>
                          </EuiButtonEmpty>
                        </EuiFlexItem>
                      </EuiFlexGroup>
                    </EuiPopover>
                  </EuiFlexItem>

                  <EuiFlexItem grow={false}>
                    <EuiButtonEmpty
                      size="s"
                      iconType="pencil"
                      onClick={() => setSelectionMode(PhotoSelectionMode.Select)}
                      disabled={downloadMediaLoading}
                    >
                      Batch edit
                    </EuiButtonEmpty>
                  </EuiFlexItem>
                </EuiFlexGroup>
              </>
            )}
          </>
        )}
      </EuiFlexGroup>

      <EuiSpacer />

      <MediaItemList
        displayType={displayType}
        mediaItems={allMedia}
        jobId={jobId}
        selectionMode={selectionMode}
        selectedPhotos={selectedPhotos}
        selectMediaItems={photoSelected}
        mediaItemWidth={mediaItemWidth}
        setMediaItemWidth={setMediaItemWidth}
        showJobLink={showJobLink}
      />

      {(selectionMode === PhotoSelectionMode.Select || selectedPhotos.length > 0) && (
        <>
          {/* made z index a million to get over the crisp widget */}
          <EuiBottomBar paddingSize="s" style={{ zIndex: '1000000', paddingBottom: '5vh' }}>
            <EuiFlexGroup direction="row" justifyContent="spaceBetween" alignItems="center">
              <EuiFlexItem grow={false}>
                {selectedPhotos.length > 0 ? (
                  <EuiFlexGroup gutterSize="s" direction="row" justifyContent="center" alignItems="center">
                    <EuiFlexItem grow={false}>
                      <EuiButton color="text" size="s" iconType="help" onClick={handleBatchRestoreMediaItems}>
                        Restore selected ({selectedPhotos.length})
                      </EuiButton>
                    </EuiFlexItem>
                    <EuiFlexItem grow={false}>
                      <EuiButton color="text" size="s" iconType="trash" onClick={handleBatchDeleteMediaItems}>
                        Delete selected ({selectedPhotos.length})
                      </EuiButton>
                    </EuiFlexItem>

                    <EuiFlexItem grow={false}>
                      <EuiButton color="text" size="s" iconType="download" onClick={handleBatchMediaDownload}>
                        Download selected ({selectedPhotos.length})
                      </EuiButton>
                    </EuiFlexItem>
                  </EuiFlexGroup>
                ) : (
                  <EuiFlexGroup alignItems="center" gutterSize="s">
                    <EuiFlexItem grow={false}>
                      <EuiIcon type="pencil" size="s" />
                    </EuiFlexItem>
                    <EuiFlexItem grow={true}>
                      <EuiText size="s">
                        <strong>Batch edit:</strong> Click to select images to edit
                      </EuiText>
                    </EuiFlexItem>
                  </EuiFlexGroup>
                )}
              </EuiFlexItem>

              <EuiFlexItem grow={false}>
                <EuiButton color="text" size="s" iconType="cross" onClick={handleCancelEdit}>
                  Cancel
                </EuiButton>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiBottomBar>
        </>
      )}
    </>
  )
}
