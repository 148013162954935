import { useFastBooking, useWorkflowV2 } from '../../common/persisted-state'
import { WorkflowActionProps } from '../workflow-model'
import { JobClassificationActionFastBooking } from './action-job-classification-fast-booking'
import { JobClassificationActionLegacy } from './action-job-classification-legacy'
import { JobClassificationActionInput, JobClassificationActionResult } from './action-job-classification-types'
import { JobClassificationActionV2 } from './action-job-classification-v2'

export const JobClassificationAction = (
  props: WorkflowActionProps<JobClassificationActionInput, JobClassificationActionResult>
) => {
  const [fastBooking] = useFastBooking(false)
  const [userWorkflowV2] = useWorkflowV2(false)
  const topicWorkflowV2 = !!props.input.workflowV2
  return (
    <>
      {fastBooking ? (
        <JobClassificationActionFastBooking {...props} />
      ) : userWorkflowV2 && topicWorkflowV2 ? (
        <>
          <JobClassificationActionV2 {...props} />
        </>
      ) : (
        <JobClassificationActionLegacy {...props} />
      )}
    </>
  )
}
