import { EuiButton, EuiSpacer } from '@elastic/eui'
import { EnquiryAbortReason, JobType, TradeType } from '../../../api/generated-types'
import { Callout } from '../../../common/callout'
import { makeHumanReadable } from '../../../common/utils'
import { CustomerSuppliedTopicActionResult } from '../../action-topic-customer-supplied'
import { CustomerSuppliedCompliant, IsItemAustralianCompliant } from '../../helpers/item-installer-questions'
import { QuestionDefinitions, getNotesGenerator } from '../../helpers/notes-generator'
import { OutOfScope } from '../../helpers/out-of-topic'
import { getResultUpdater } from '../../helpers/results-updater'
import { useWorkflow } from '../../helpers/workflow-provider'
import { FreeTextQuestionField } from '../../question-fields/free-text-question-field'
import { SingleSelectField } from '../../question-fields/single-select-question-field'
import { WorkflowActionProps } from '../../workflow-model'
import { ItemSupplier, TopicActionInput } from '../action-topic-model'
import { TopicWorkflowAction } from '../action-topic-view'
import { reset } from '../common/common-utils'

export enum NewOrSecondHand {
  New = 'New',
  SecondHand = 'SecondHand'
}

export enum BrandNewInstallOrReplacement {
  BrandNewInstall = 'BrandNewInstall',
  Replacement = 'Replacement'
}

export interface TopicPlumbingTapInstallActionResult extends CustomerSuppliedTopicActionResult {
  itemSupplier?: ItemSupplier
  wasTapNewOrSecondHandWhenPurchased?: NewOrSecondHand
  installationOrReplacement?: BrandNewInstallOrReplacement
  installationLocation?: string
}

const questionDefinitions: QuestionDefinitions<TopicPlumbingTapInstallActionResult> = {
  itemSupplier: {
    question: 'Is this a Fallon supplied or customer supplied tap?',
    makeAnswerHumanReadable: makeHumanReadable
  },
  wasTapNewOrSecondHandWhenPurchased: {
    question: 'Was the tap new or second-hand when purchased?',
    makeAnswerHumanReadable: makeHumanReadable
  },
  installationOrReplacement: {
    question: 'Is it brand new install or replacing an existing tap fixture?',
    makeAnswerHumanReadable: makeHumanReadable
  },
  installationLocation: {
    question: 'Where would you like it installed?'
  }
}
const notesGenerator = getNotesGenerator(questionDefinitions)

export const TopicPlumbingTapInstallAction = (
  props: WorkflowActionProps<TopicActionInput, TopicPlumbingTapInstallActionResult>
) => {
  const workflowContext = useWorkflow()
  const { result, input, onUpdate } = props
  const {
    itemSupplier,
    wasTapNewOrSecondHandWhenPurchased,
    installationOrReplacement,
    installationLocation,
    australianCompliant,
    jobDescription,
    customerType
  } = result ?? {}
  const { jobIsCompliant, jobIsNotCompliant } = CustomerSuppliedCompliant({ australianCompliant })

  const onNext = () =>
    onUpdate({
      ...result,
      actionCompleted: true,
      trade: TradeType.Plumbing,
      category: 'Tap',
      type: JobType.Service,

      workRequiredNotes: generatedNotes,
      requirements: {
        attributes: [{ attributeId: 'GeneralPlumbing' }]
      }
    })

  const generatedNotes = notesGenerator.generateNotes(result ?? {})
  const updateResult = getResultUpdater(result ?? {}, onUpdate).updateResult

  const canContinue =
    itemSupplier === ItemSupplier.Fallon ||
    (itemSupplier === ItemSupplier.Customer &&
      wasTapNewOrSecondHandWhenPurchased === NewOrSecondHand.New &&
      jobIsCompliant)

  const canComplete = canContinue && !!installationOrReplacement && !!installationLocation

  return (
    <TopicWorkflowAction input={input} onUpdate={onUpdate} result={result}>
      <SingleSelectField
        question={notesGenerator.getQuestion('itemSupplier')}
        options={[
          {
            id: ItemSupplier.Fallon,
            label: 'Fallon',
            icon: 'check'
          },
          {
            id: ItemSupplier.Customer,
            label: 'Customer',
            icon: 'user'
          }
        ]}
        answer={itemSupplier}
        changeAnswer={(itemSupplier) =>
          updateResult({
            ...reset(result ?? {}),
            customerType,
            itemSupplier,
            jobDescription
          })
        }
      />
      {itemSupplier === ItemSupplier.Customer && (
        <>
          <SingleSelectField
            question={notesGenerator.getQuestion('wasTapNewOrSecondHandWhenPurchased')}
            options={[
              {
                id: NewOrSecondHand.New,
                label: 'New'
              },
              {
                id: NewOrSecondHand.SecondHand,
                label: 'Second-hand'
              }
            ]}
            answer={wasTapNewOrSecondHandWhenPurchased}
            changeAnswer={(wasTapNewOrSecondHandWhenPurchased) => updateResult({ wasTapNewOrSecondHandWhenPurchased })}
          />
          {wasTapNewOrSecondHandWhenPurchased === NewOrSecondHand.SecondHand ? (
            <>
              <Callout type="script">Unfortunately Fallon’s don’t install second-hand taps.</Callout>
              <EuiButton
                color="warning"
                onClick={() =>
                  workflowContext.abort({
                    abortReason: EnquiryAbortReason.CustomerNeedsAreOutOfService,
                    notes: 'Customer wanted to install a second-hand tap'
                  })
                }
              >
                Offer a voucher and end call
              </EuiButton>
            </>
          ) : (
            <>
              <IsItemAustralianCompliant
                updateResult={updateResult}
                itemSupplier={itemSupplier}
                suppliedAnswer={australianCompliant}
              />
              <OutOfScope issue={'Tap Install'} isOutOfScope={jobIsNotCompliant} />
            </>
          )}
        </>
      )}
      {canContinue && (
        <>
          <SingleSelectField
            question={notesGenerator.getQuestion('installationOrReplacement')}
            options={[
              {
                id: BrandNewInstallOrReplacement.BrandNewInstall,
                label: 'Brand-new installation'
              },
              {
                id: BrandNewInstallOrReplacement.Replacement,
                label: 'Replacement'
              }
            ]}
            answer={installationOrReplacement}
            changeAnswer={(installationOrReplacement) => updateResult({ installationOrReplacement })}
          />
          <FreeTextQuestionField
            question={notesGenerator.getQuestion('installationLocation')}
            rows={3}
            answer={installationLocation}
            changeAnswer={(installationLocation) => updateResult({ installationLocation })}
          />
        </>
      )}

      <EuiSpacer />
      <EuiButton disabled={!canComplete} onClick={() => onNext()}>
        Next
      </EuiButton>
    </TopicWorkflowAction>
  )
}
