import { EuiSpacer, EuiText } from '@elastic/eui'
import { Callout } from '../../../../common/callout'

export const ApplianceOvenCooktopDisclaimer = ({
  isApplianceJob,
  isOvenOrCooktop
}: {
  isApplianceJob: boolean
  isOvenOrCooktop: boolean
}) => {
  return (
    <>
      {isApplianceJob && isOvenOrCooktop && (
        <>
          <Callout type="warning">
            <EuiText>
              <p>PLEASE NOTE:</p>
              <ul>
                <li>Northside jobs = book on Jason Hildreth, reach out to DSR if needed</li>
                <li>Southside/Goldcoast jobs = book on Denes Dudas, reach out to DSR if needed</li>
              </ul>
            </EuiText>
          </Callout>
          <EuiSpacer />
        </>
      )}
    </>
  )
}
