import { ApolloError, useLazyQuery } from '@apollo/client'
import { captureMessage } from '@sentry/react'
import React, { useCallback, useEffect, useState } from 'react'
import { GetUserAuthDocument, UserAuthFragment } from '../api/generated-types'
import { useApp } from '../app/app-context'
import { loaderContextDefaultRetry } from '../app/loader-context'
import { AuthorizationContext, AuthorizationLoaderContext, IAuthorizationContext } from '../auth/authorization-context'
import { IAMUtils, IIAMUtils } from '../auth/iam-utils'
import { userAttributesFromUserAuthFragment } from '../user-attributes/user-attributes'
import { useSession } from './session-context'

interface AuthorizationProps {
  children: React.ReactNode
}

const AuthorizationProvider = (props: AuthorizationProps) => {
  const appContext = useApp()
  const session = useSession()

  const [loadingUser, setLoadingUser] = useState(true)
  const [iamUtils, setIAMUtils] = useState<IIAMUtils | undefined>(undefined)
  const [error, setError] = useState('')
  const [userFragment, setUserFragment] = useState<UserAuthFragment | undefined>(undefined)

  const initIAMUtils = useCallback(async () => {
    console.log('authorization-provider: initIAMUtils')
    console.time('authorization-provider: iamUtils init')
    const iamUtilsOption = await IAMUtils.withContext(appContext)
    const iamUtils = new IAMUtils(iamUtilsOption)
    console.timeEnd('authorization-provider: iamUtils init')
    return iamUtils
  }, [appContext])

  const [loadUser] = useLazyQuery(GetUserAuthDocument, {
    onCompleted: (data) => {
      if (data.getUser) {
        setUserFragment(data.getUser)
      }
      setLoadingUser(false)
    },
    fetchPolicy: 'cache-and-network',
    onError: (error: ApolloError) => {
      console.error('authorization-provider: error loading user')
      captureMessage(`failed to load user`)
      setError(error?.message)
    }
  })

  const resetAuthorization = (loading?: boolean) => {
    setLoadingUser(loading ?? true)
    setUserFragment(undefined)
    setIAMUtils(undefined)
    setError('')
  }

  useEffect(() => {
    if (!session.loaded) {
      resetAuthorization()
    }
  }, [session.loaded])

  useEffect(() => {
    // Wait for auth status first
    if (session.loading) {
      return
    }
    if (session.loaded && session.data?.user?.id) {
      setLoadingUser(true)
      initIAMUtils().then((iamUtils) => {
        console.log('authorization-provider: iamUtils ready', iamUtils)
        setIAMUtils(iamUtils)
      })
      loadUser({
        variables: {
          id: session.data?.user.id
        }
      })
    } else {
      resetAuthorization(false)
    }
  }, [initIAMUtils, session.loading, session.loaded, session.data?.user, loadUser])

  const needsAuthorization = !session.loading && session.loaded

  const authorizationData: IAuthorizationContext = {
    userFragment: userFragment ?? undefined,
    userAttributes: userFragment ? userAttributesFromUserAuthFragment(userFragment) : undefined,
    iamUtils
  }

  const authorization: AuthorizationLoaderContext = {
    loading: !error && needsAuthorization && (loadingUser || !iamUtils || !iamUtils.ready),
    loaded: !!authorizationData.userFragment,
    error,
    data: authorizationData,
    retry: loaderContextDefaultRetry
  }

  return <AuthorizationContext.Provider value={authorization}>{props.children}</AuthorizationContext.Provider>
}

export default AuthorizationProvider
