import { QuestionDefinitions, getNotesGenerator } from '../../helpers/notes-generator'
import { ApplianceRepairTopicActionResult } from '../appliance-shared/appliance-model'
import { ServiceType } from '../action-topic-model'
export interface TopicRangehoodActionResult extends ApplianceRepairTopicActionResult {
  serviceType?: ServiceType
}

const questionDefinitions: QuestionDefinitions<TopicRangehoodActionResult> = {
  serviceType: {
    question: 'Is this for installation or repair?',
    statement: 'Install or repair:'
  },
  issue: {
    question: 'What is the issue with your rangehood?',
    statement: 'Issue:'
  },
  outletWorksWithOtherDevice: {
    question: 'Does the power point work with other devices?',
    statement: 'Outlet works with other device:'
  }
}

export const rangehoodNotesGenerator = getNotesGenerator(questionDefinitions)
