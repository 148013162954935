import { EuiButton, EuiSpacer } from '@elastic/eui'
import { JobType, TradeType } from '../../../api/generated-types'
import { Callout } from '../../../common/callout'
import { decameliseIfNotNil, makeHumanReadable } from '../../../common/utils'
import { QuestionDefinitions, getNotesGenerator } from '../../helpers/notes-generator'
import { getResultUpdater } from '../../helpers/results-updater'
import { useWorkflow } from '../../helpers/workflow-provider'
import { JobTopicType } from '@fallonsolutions/types'
import { FreeTextQuestionField } from '../../question-fields/free-text-question-field'
import { SingleSelectField } from '../../question-fields/single-select-question-field'
import { WorkflowActionProps } from '../../workflow-model'
import { ItemInstaller, TopicActionInput, TopicActionResult } from '../action-topic-model'
import { TopicWorkflowAction } from '../action-topic-view'

export enum FanControlType {
  Remote = 'Remote',
  WallSwitch = 'WallSwitch'
}

export enum RepairToReplacement {
  Yes = 'Yes',
  No = 'No'
}

export enum InsideOrOutside {
  Inside = 'Inside',
  Outside = 'Outside',
  Both = 'Both'
}

export enum PowerPointServiceType {
  Installation = 'Installation',
  Repair = 'Repair',
  PowerOutageRelated = 'PowerOutageRelated'
}

export enum InstallDate {
  LessThanOrEqualTo12Months = 'Within last 12 months',
  Over12Months = 'Over 12 months ago'
}

export interface TopicPowerPointActionResult extends TopicActionResult {
  insideOrOutside?: InsideOrOutside
  serviceType?: PowerPointServiceType
  itemInstaller?: ItemInstaller
  installDate?: InstallDate
  quantityAndLocation?: string
  exposedWiring?: string
  trippingPower?: string
  happenedBefore?: string
  anyoneReceivedShock?: string
}

const questionDefinitions: QuestionDefinitions<TopicPowerPointActionResult> = {
  insideOrOutside: {
    question: 'Is this for inside or outside the house?',
    statement: 'Inside or outside:',
    makeAnswerHumanReadable: decameliseIfNotNil
  },
  serviceType: {
    question: 'Is this for an install or repair?',
    statement: 'Service type:',
    makeAnswerHumanReadable: decameliseIfNotNil
  },
  itemInstaller: {
    question: 'Who installed the power point(s)?',
    statement: 'Power point(s) installed by:',
    makeAnswerHumanReadable: decameliseIfNotNil
  },
  installDate: {
    question: 'When did Fallon install?',
    makeAnswerHumanReadable: makeHumanReadable
  },
  quantityAndLocation: {
    question: 'How many do you want and where do you want them installed?',
    statement: 'Quantity and location:',
    makeAnswerHumanReadable: decameliseIfNotNil
  },
  exposedWiring: {
    question: 'Is there any exposed wiring?',
    statement: 'Exposed wiring:'
  },
  trippingPower: {
    question: 'Is it tripping power?',
    statement: 'Tripping power:'
  },
  happenedBefore: {
    question: 'Has this happened before?',
    statement: 'Happened before:'
  },
  anyoneReceivedShock: {
    question: 'Has anyone received a shock?',
    statement: 'Anyone received a shock:'
  }
}
const notesGenerator = getNotesGenerator(questionDefinitions)

export const TopicPowerPointAction = (props: WorkflowActionProps<TopicActionInput, TopicPowerPointActionResult>) => {
  const workflowContext = useWorkflow()

  const { result, input, onUpdate } = props
  const {
    serviceType,
    itemInstaller,
    installDate,
    quantityAndLocation,
    exposedWiring,
    trippingPower,
    happenedBefore,
    anyoneReceivedShock
  } = result ?? {}

  const isRevisit =
    serviceType === PowerPointServiceType.Repair &&
    itemInstaller === ItemInstaller.Fallon &&
    installDate === InstallDate.LessThanOrEqualTo12Months
  const isOutsideRevisitScope =
    serviceType === PowerPointServiceType.Repair &&
    itemInstaller === ItemInstaller.Fallon &&
    installDate === InstallDate.Over12Months

  const onNext = () =>
    onUpdate({
      ...result,
      actionCompleted: true,
      trade: TradeType.Electrical,
      category: 'PowerPoint',
      type: isRevisit ? JobType.Callback : JobType.Service,

      workRequiredNotes: generatedNotes,
      requirements: {
        attributes: [{ attributeId: 'GeneralElectrical' }]
      }
    })

  const generatedNotes = notesGenerator.generateNotes(result ?? {})
  const updateResult = getResultUpdater(result ?? {}, onUpdate).updateResult

  const handlePowerOutage = () => {
    workflowContext.changeJobTopic(JobTopicType.LossOfPower)
  }

  const validInstall = serviceType === PowerPointServiceType.Installation && !!quantityAndLocation
  const validRepair =
    serviceType === PowerPointServiceType.Repair &&
    itemInstaller === ItemInstaller.SomeoneElse &&
    !!exposedWiring &&
    !!trippingPower &&
    !!happenedBefore &&
    !!anyoneReceivedShock

  const canComplete = isRevisit || isOutsideRevisitScope || validInstall || validRepair

  return (
    <TopicWorkflowAction input={input} onUpdate={onUpdate} result={result}>
      <SingleSelectField
        question={notesGenerator.getQuestion('serviceType')}
        options={[
          {
            id: PowerPointServiceType.Installation,
            icon: 'check'
          },
          {
            id: PowerPointServiceType.Repair,
            icon: 'wrench'
          },
          {
            id: PowerPointServiceType.PowerOutageRelated,
            icon: 'bolt'
          }
        ]}
        answer={serviceType}
        changeAnswer={(serviceType) =>
          updateResult({
            serviceType,
            itemInstaller: undefined,
            quantityAndLocation: undefined,
            exposedWiring: undefined,
            trippingPower: undefined,
            anyoneReceivedShock: undefined
          })
        }
      />

      {serviceType === PowerPointServiceType.Repair && (
        <>
          <SingleSelectField
            question={notesGenerator.getQuestion('itemInstaller')}
            options={[
              {
                id: ItemInstaller.Fallon,
                label: 'Fallon installed',
                icon: 'check'
              },
              {
                id: ItemInstaller.SomeoneElse,
                label: 'Someone else installed',
                icon: 'user'
              }
            ]}
            answer={itemInstaller}
            changeAnswer={(itemInstaller) => updateResult({ itemInstaller })}
          />
        </>
      )}

      {serviceType === PowerPointServiceType.Installation && (
        <>
          <FreeTextQuestionField
            question={notesGenerator.getQuestion('quantityAndLocation')}
            answer={quantityAndLocation}
            changeAnswer={(quantityAndLocation) => updateResult({ quantityAndLocation })}
            rows={3}
          />
        </>
      )}

      {serviceType === PowerPointServiceType.Repair && itemInstaller === ItemInstaller.SomeoneElse && (
        <>
          <FreeTextQuestionField
            question={notesGenerator.getQuestion('exposedWiring')}
            answer={exposedWiring}
            changeAnswer={(exposedWiring) => updateResult({ exposedWiring })}
          />
          <FreeTextQuestionField
            question={notesGenerator.getQuestion('trippingPower')}
            answer={trippingPower}
            changeAnswer={(trippingPower) => updateResult({ trippingPower })}
          />
          <FreeTextQuestionField
            question={notesGenerator.getQuestion('happenedBefore')}
            answer={happenedBefore}
            changeAnswer={(happenedBefore) => updateResult({ happenedBefore })}
          />
          <FreeTextQuestionField
            question={notesGenerator.getQuestion('anyoneReceivedShock')}
            answer={anyoneReceivedShock}
            changeAnswer={(anyoneReceivedShock) => updateResult({ anyoneReceivedShock })}
          />
        </>
      )}

      {serviceType === PowerPointServiceType.PowerOutageRelated && (
        <>
          <EuiSpacer />
          <EuiButton onClick={() => handlePowerOutage()}>Switch topic to Loss of Power</EuiButton>
        </>
      )}

      {serviceType === PowerPointServiceType.Repair && itemInstaller === ItemInstaller.Fallon && (
        <>
          <SingleSelectField
            question={notesGenerator.getQuestion('installDate')}
            options={[
              {
                id: InstallDate.LessThanOrEqualTo12Months,
                label: 'Within last 12 months'
              },
              {
                id: InstallDate.Over12Months,
                label: 'Over 12 months ago'
              }
            ]}
            answer={installDate}
            changeAnswer={(installDate) => updateResult({ installDate })}
          />
          {itemInstaller === ItemInstaller.Fallon && !!installDate && (
            <>
              <Callout
                type="note"
                title={
                  installDate === InstallDate.LessThanOrEqualTo12Months
                    ? 'Revisit with no travel fee'
                    : 'Normal service job with travel fee'
                }
              />
              <EuiSpacer />
            </>
          )}
        </>
      )}

      {isRevisit && (
        <>
          <Callout type="note">
            <ul>
              <li>
                Book same technician as the original Job and send work chat to Electrical team or email Electrical DSR
                Team
              </li>
              <li>If time not suitable or technician not available call Electrical DSR 99973</li>
            </ul>
          </Callout>
          <EuiSpacer />
        </>
      )}

      <EuiSpacer />
      <EuiButton disabled={!canComplete} onClick={() => onNext()}>
        Next
      </EuiButton>
    </TopicWorkflowAction>
  )
}
