import { EuiFacetButton, EuiFacetGroup, EuiIcon } from '@elastic/eui'
import { filter, includes } from 'lodash-es'

interface CustomerTypeFacetsProps {
  customerTypes: any[]
  setCustomerTypes: (facets: any[]) => void
}

const CustomerTypeFacets = (props: CustomerTypeFacetsProps) => {
  const allCustomerTypes = ['Domestic', 'Commercial']

  const offColor = '#E5E8F0'
  const onColor = '#34C759'

  const customerTypeFacets = allCustomerTypes.map((customerType) => {
    return {
      id: customerType,
      label: customerType,
      enabled: includes(props.customerTypes, customerType),
      iconColor: includes(props.customerTypes, customerType) ? onColor : offColor
    }
  })

  const onClickFacet = (facet: any) => {
    let updatedCustomerTypes = props.customerTypes
    if (includes(props.customerTypes, facet.id)) {
      updatedCustomerTypes = filter(updatedCustomerTypes, (t) => t !== facet.id)
    } else {
      updatedCustomerTypes = updatedCustomerTypes.concat(facet.id)
    }
    props.setCustomerTypes(updatedCustomerTypes)
  }

  return (
    <EuiFacetGroup layout="horizontal">
      {customerTypeFacets.map((facet: any) => (
        <EuiFacetButton
          key={facet.id}
          icon={<EuiIcon type="dot" color={facet.iconColor} />}
          isSelected={facet.enabled}
          onClick={() => onClickFacet(facet)}
        >
          {facet.label}
        </EuiFacetButton>
      ))}
    </EuiFacetGroup>
  )
}

export default CustomerTypeFacets
