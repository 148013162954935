import { CampaignLinkFragment } from '../../api/generated-types'
import EuiCustomLink from '../../common/eui-custom-link'

export interface CampaignLinkProps {
  campaign: CampaignLinkFragment
}

export const CampaignLink = (props: CampaignLinkProps) => {
  return <EuiCustomLink to={`/marketing/campaigns/${props.campaign.id}`}>{props.campaign.name}</EuiCustomLink>
}
