import { EuiFlexGroup, EuiFlexItem } from '@elastic/eui'
import { ContactLinkFragment } from '../api/generated-types'
import EuiCustomLink from '../common/eui-custom-link'
import { IdenticonAvatar } from '../common/identicon-avatar'

export interface ContactLinkProps {
  contact: ContactLinkFragment
  includeLink?: boolean
  openInNewTab?: boolean
}

export const ContactLink = ({ contact, openInNewTab, ...props }: ContactLinkProps) => {
  const includeLink = props.includeLink ?? true
  const fullName = contact.detail?.fullName ?? 'Unknown'
  return (
    <EuiFlexGroup gutterSize="m" alignItems="center">
      <EuiFlexItem grow={false}>
        <IdenticonAvatar username={contact.id ?? 'none'} width="24" height="24" />
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        {includeLink ? (
          <EuiCustomLink to={`/contacts/${contact.id}`} target={openInNewTab ? '_blank' : ''}>
            {fullName}
          </EuiCustomLink>
        ) : (
          fullName
        )}
      </EuiFlexItem>
    </EuiFlexGroup>
  )
}
