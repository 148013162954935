import {
  EuiAvatar,
  EuiContextMenu,
  EuiContextMenuPanelDescriptor,
  EuiContextMenuPanelItemDescriptor,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHeader,
  EuiHeaderLinks,
  EuiHeaderLogo,
  EuiHeaderSection,
  EuiHeaderSectionItem,
  EuiHeaderSectionItemButton,
  EuiIcon,
  EuiPopover
} from '@elastic/eui'
import { MouseEvent, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useApp } from '../app/app-context'
import { AppVersionLabel } from '../common/app-version'
import { ConfettiView } from '../common/confetti'
import EuiCustomHeaderLink from '../common/eui-custom-header-link'
import { SandboxSwitch } from '../common/sandbox-switch'
import UniversalSearch, { UniversalSearchSelectAction } from '../search/universal-search'
import '../static/css/header.css'
import quicksightIcon from '../static/images/aws-quicksight-icon.svg'
import fsSvg from '../static/images/fs-logo-square.svg'
import geotabIcon from '../static/images/geotab-icon.png'
import hubspotIcon from '../static/images/hubspot-icon.png'
import createPersistedState from '../use-persisted-state'
import { EnvBanner } from './env-banner'
import { useEventTheme } from './event-theme'
// import { TenantSelectView } from './tenant-select-view'
import { sortBy } from 'lodash-es'
import { Department, UserCallCenterParticipationType, UserRole } from '../api/generated-types'
import { useAuthenticated } from '../auth/authenticated-context'
import VerticalDivider from '../common/vertical-divider'
import { AllAction, useDecisionPoint } from '../decision-point/use-decision-point'
import { ActiveInteractionMessageBar } from '../interactions/interaction-message-bar/active-interaction-message-bar'
import {
  InteractionMessageListType,
  RecentInteractionMessageListContainer
} from '../interactions/recent-interaction-message-list-container'
import { NotificationMenu } from '../notifications/notification-menu'
import { UseSandbox } from '../workflow/helpers/get-sandbox-state'
import { NavItem } from './nav-item'
import { TenantSelectView } from './tenant-select-view'

const useNavigationIcons = createPersistedState<boolean>('navigation-icons')

// eslint-disable-next-line complexity
const Header = () => {
  const session = useAuthenticated()
  const appContext = useApp()

  const { hubspotAccountId } = appContext

  const { eventTheme, decorations, confettiSource, setDecorations, setConfettiSource } = useEventTheme()

  const [sandbox, setSandbox] = UseSandbox()
  const [navigationIcons] = useNavigationIcons(true)

  // const fieldAppPermission = session.user.permissions?.fieldApp === true
  const webAppPermission = session.userFragment.permissions?.webApp === true
  const reportingPermission = session.userFragment.permissions?.webApp === true
  const sandboxPermission = session.userFragment.permissions?.sandbox === true
  const canViewSchedule = session.userFragment.permissions?.viewSchedule === true
  const canViewActiveInteractions =
    session.userFragment.callCenter?.participation === UserCallCenterParticipationType.Active
  const developerFeatures = session.userFragment.permissions?.developerFeatures === true
  const hasViewPerformancePay = session.userFragment.permissions?.viewPerformancePay === true
  const isFinanceUser = session.userFragment.role === UserRole.Finance
  const isCustomerServiceUser = session.userFragment.department === Department.CustomerService
  const hasReviewAccess = session.userFragment.permissions?.viewReviews === true
  const hasViewTimesheets = session.userFragment.permissions?.viewTimesheets === true
  const hasViewReports = session.userFragment.permissions?.developerFeatures === true
  const canEditBookingConfig = session.userFragment.permissions?.editBookingConfig === true
  const canViewComplaints = session.userFragment.permissions?.viewComplaints === true

  const { canViewCosts } = useDecisionPoint(AllAction.ViewCosts)
  const userCanViewCosts = !!canViewCosts().ok
  const [settingsOpen, setSettingsOpen] = useState(false)

  const history = useHistory()
  const toggleSettings = () => {
    setSettingsOpen(!settingsOpen)
  }

  const closeSettings = () => {
    setSettingsOpen(false)
  }

  const settingsButton = () => {
    return (
      <EuiHeaderSectionItemButton onClick={toggleSettings}>
        <EuiAvatar size="s" name={session.user.username ?? 'unknown'} imageUrl={session.userAttributes.avatar} />
      </EuiHeaderSectionItemButton>
    )
  }

  const separator: EuiContextMenuPanelItemDescriptor = {
    isSeparator: true
  }

  const signout: EuiContextMenuPanelItemDescriptor = {
    name: 'Sign out',
    onClick: () => {
      closeSettings()
      session.signOut()
    }
  }

  const settings: EuiContextMenuPanelItemDescriptor = {
    name: NavItem.Settings.name,
    onClick: () => {
      history.push(NavItem.Settings.url)
      closeSettings()
    }
  }

  const quicksight: EuiContextMenuPanelItemDescriptor = {
    name: 'Quicksight',
    icon: quicksightIcon,
    target: '_blank',
    href: `https://quicksight.aws.amazon.com/sn/start?directory_alias=${
      appContext.isDev ? 'fallonsolutions-dev' : 'fallonsolutions'
    }`
  }
  const hubspot: EuiContextMenuPanelItemDescriptor = {
    name: 'Hubspot',
    icon: hubspotIcon,
    target: '_blank',
    href: `https://app.hubspot.com/home?portalId=${hubspotAccountId}` // TODO: get Hubspot accountId from AppConfig
  }
  const geotab: EuiContextMenuPanelItemDescriptor = {
    name: 'Geotab',
    icon: geotabIcon,
    target: '_blank',
    href: 'https://my.geotab.com/fallon/#map,liveVehicleIds:all,mapBounds:(ne:(lat:-26.60821596533505,lng:153.65112967078818),sw:(lat:-28.293539170332554,lng:152.54151053016318))'
  }

  const links: EuiContextMenuPanelItemDescriptor[] = sortBy(
    [
      NavItem.ActivityList,
      ...(canEditBookingConfig ? [NavItem.BookingConfig] : []),
      ...(!isCustomerServiceUser ? [NavItem.BookingList] : []),
      NavItem.CallCentre,
      ...(canViewComplaints ? [NavItem.ComplaintList] : []),
      NavItem.ContactList,
      ...(!isFinanceUser && userCanViewCosts ? [NavItem.CostList] : []),
      NavItem.DiscountList,
      NavItem.ExportList,
      NavItem.Glossary,
      ...(isFinanceUser || isCustomerServiceUser ? [NavItem.InspectionList] : []),
      ...(!isFinanceUser ? [NavItem.InvoiceList] : []),
      NavItem.JobTypeList,
      ...(!isCustomerServiceUser ? [NavItem.LeaderboardList] : []),
      NavItem.Marketing,
      NavItem.PaymentList,
      ...(hasViewPerformancePay ? [NavItem.Performance] : []),
      NavItem.PropertyList,
      NavItem.MediaItemList,
      NavItem.MembershipList,
      NavItem.PublicHolidayList,
      ...(hasViewReports ? [NavItem.ReportList] : []),
      ...(hasReviewAccess ? [NavItem.ReviewList] : []),
      ...(isFinanceUser || isCustomerServiceUser ? [NavItem.SalesEventList] : []),
      ...(developerFeatures ? [NavItem.ScheduledServiceList] : []),
      NavItem.SkillList,
      NavItem.ServiceAreaList,
      ...(hasViewTimesheets ? [NavItem.Timesheets] : [])
    ],
    'name'
  ).map((link) => ({
    name: link.name,
    ...(navigationIcons && { icon: link.icon ?? 'empty' }),
    onClick: () => {
      history.push(link.url)
      closeSettings()
    }
  }))

  const buildVersion: EuiContextMenuPanelItemDescriptor = {
    name: <AppVersionLabel style={{ paddingTop: '15px' }} />,
    toolTipContent: 'Click to copy',
    onClick: closeSettings
  }

  const docs: EuiContextMenuPanelItemDescriptor = {
    name: 'Documentation',
    target: '_blank',
    icon: 'documentation',
    href: '/docs'
  }

  const footer = [separator, docs, settings, signout, buildVersion] as any[]

  let settingsContextItems: EuiContextMenuPanelItemDescriptor[] = []
  if (webAppPermission) {
    settingsContextItems = [...links, separator, geotab, hubspot, ...(reportingPermission ? [quicksight] : [])]
  }
  settingsContextItems = settingsContextItems.concat(footer)

  const contextMenuPanels: EuiContextMenuPanelDescriptor[] = [
    {
      id: 0,
      items: settingsContextItems
    }
  ]

  const webAppNavItems = [
    ...(canViewSchedule ? [NavItem.Schedule] : []),
    NavItem.JobList,
    ...(!isFinanceUser && !isCustomerServiceUser ? [NavItem.InspectionList, NavItem.SalesEventList] : []),
    ...(!isFinanceUser && isCustomerServiceUser ? [NavItem.BookingList, NavItem.LeaderboardList] : []),
    ...(userCanViewCosts ? [NavItem.TransactionList] : []),
    ...(isFinanceUser ? [NavItem.InvoiceList, NavItem.CostList] : []),
    NavItem.AppointmentList,
    NavItem.JobReportList,
    NavItem.CustomerList,
    NavItem.PricelistList,
    NavItem.UserList
  ]

  const webAppLinks = (
    <EuiHeaderLinks popoverBreakpoints={['xs', 's', 'm', 'l']}>
      <EuiCustomHeaderLink to="/">
        <EuiFlexGroup alignItems="center" gutterSize="s">
          <EuiFlexItem>
            <EuiIcon type="home" />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiCustomHeaderLink>
      <VerticalDivider height="18px" />
      {webAppNavItems.map((navItem, index) => (
        <EuiCustomHeaderLink key={index} to={navItem.url}>
          <EuiFlexGroup alignItems="center" gutterSize="s">
            {navigationIcons && (
              <EuiFlexItem grow={false}>{navItem.icon && <EuiIcon type={navItem.icon} color="#727888" />}</EuiFlexItem>
            )}
            <EuiFlexItem>{navItem.name}</EuiFlexItem>
          </EuiFlexGroup>
        </EuiCustomHeaderLink>
      ))}
    </EuiHeaderLinks>
  )

  const fieldAppLinks = (
    <EuiHeaderLinks>
      <EuiCustomHeaderLink to="/">
        <EuiIcon type="home" />
      </EuiCustomHeaderLink>
    </EuiHeaderLinks>
  )

  const stage = appContext.stage.toLowerCase()

  const onLogoClick = (event: MouseEvent) => {
    if (eventTheme) {
      if (eventTheme.confetti?.enabled) {
        if (!decorations) {
          setConfettiSource({ x: event.clientX, y: event.clientY })
        } else {
          setConfettiSource(undefined)
        }
      }
      if (eventTheme.decorations?.enabled) {
        setDecorations(!decorations)
      }
    } else {
      history.push('/')
    }
  }

  return (
    <header className={`header header--${stage}`}>
      {eventTheme?.confetti?.enabled && confettiSource && (
        <ConfettiView source={confettiSource} colors={eventTheme.confetti.colors} />
      )}
      <EnvBanner fixed={true} />
      <EuiHeader
        theme="dark"
        className={
          eventTheme?.decorations?.enabled && decorations
            ? `${eventTheme.decorations.className} primary-header`
            : 'primary-header'
        }
      >
        <EuiHeaderSection>
          <EuiHeaderSectionItem>
            <EuiHeaderLogo
              className="logo"
              iconType={eventTheme?.logo ?? fsSvg}
              onClick={onLogoClick}
              style={{ ...(decorations && { transform: 'rotate(360deg)' }) }}
              title={eventTheme?.title ?? ''}
            />
            <TenantSelectView />
            {/* <OwnershipSelector /> */}
          </EuiHeaderSectionItem>
        </EuiHeaderSection>

        {canViewActiveInteractions && <ActiveInteractionMessageBar />}

        <EuiHeaderSection>
          {canViewActiveInteractions && (
            <EuiHeaderSectionItem className="eui-hideFor--xs">
              <RecentInteractionMessageListContainer type={InteractionMessageListType.Menu} />
              <div style={{ width: 15 }} />
            </EuiHeaderSectionItem>
          )}

          {webAppPermission && (
            <EuiHeaderSectionItem className="eui-hideFor--xs">
              <UniversalSearch onSelectAction={UniversalSearchSelectAction.RedirectToItem} />
              <div style={{ width: 8 }} />
            </EuiHeaderSectionItem>
          )}

          <EuiHeaderSectionItem>
            <NotificationMenu />

            <EuiPopover
              id="settingsPanel"
              button={settingsButton()}
              isOpen={settingsOpen}
              closePopover={closeSettings}
              panelPaddingSize="s"
              anchorPosition="downCenter"
            >
              <EuiContextMenu initialPanelId={0} panels={contextMenuPanels} />
            </EuiPopover>
          </EuiHeaderSectionItem>
        </EuiHeaderSection>
      </EuiHeader>
      <EuiHeader>
        <EuiHeaderSection grow={true}>{webAppPermission ? webAppLinks : fieldAppLinks}</EuiHeaderSection>
        {sandboxPermission && (
          <EuiHeaderSection grow={false}>
            <EuiHeaderSectionItem>
              <SandboxSwitch enabled={sandbox} onChange={setSandbox} />
            </EuiHeaderSectionItem>
          </EuiHeaderSection>
        )}
      </EuiHeader>
    </header>
  )
}

export default Header
