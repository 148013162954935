import { JobType, TradeType } from '../../../api/generated-types'
import { GeneralApplianceIssueType, RepairOrReplaceEnum, isBrandServiceable } from '../appliance-shared/appliance-model'
import { getOvenOrCooktopRequirementsElectricRepair } from '../appliance-shared/repair-electrical-outcome'
import { YesNoValue } from '../common/common-enums'
import {
  TopicQualification,
  TopicQualificationResult,
  TopicRequirementsResult,
  createOutcomeGenerator,
  createRequirements
} from '../common/common-outcome'
import { GAS_SKILL, GENERAL_ELECTRICAL_SKILL } from '../common/skill-constants'
import { CooktopEnum, TopicCooktopActionResult } from './action-topic-cooktop-model'

export const getQualification = (result: TopicCooktopActionResult | undefined): TopicQualificationResult => {
  const {
    cooktopType,
    serviceType,
    issue,
    confirmedPartsAvailableWithDSR,
    brandServiceable,
    removeOldCooktop,
    willNewCooktopFitCutOut,
    isNewCooktopOnsite,
    outletWorksWithOtherDevice,
    happenedBefore,
    confirmCookTopIsElectric
  } = result ?? {}

  const answeredAllReplacementQuestions =
    serviceType === RepairOrReplaceEnum.Replace &&
    !!removeOldCooktop &&
    !!willNewCooktopFitCutOut &&
    !!isNewCooktopOnsite

  const answeredAllElectricRepairQuestions =
    serviceType === RepairOrReplaceEnum.Repair &&
    ((issue === GeneralApplianceIssueType.PowerSupply && !!outletWorksWithOtherDevice) ||
      (issue === GeneralApplianceIssueType.OtherFault && !!happenedBefore)) &&
    !!confirmCookTopIsElectric

  switch (cooktopType) {
    case CooktopEnum.Gas:
    case CooktopEnum.Induction:
      return {
        qualification:
          serviceType === RepairOrReplaceEnum.Repair
            ? TopicQualification.OutOfScope
            : answeredAllReplacementQuestions
              ? TopicQualification.InScope
              : TopicQualification.Unknown
      }
    case CooktopEnum.Electric:
      if (serviceType === RepairOrReplaceEnum.Replace) {
        return {
          qualification: answeredAllReplacementQuestions ? TopicQualification.InScope : TopicQualification.Unknown
        }
      } else {
        if (!!brandServiceable && !!confirmedPartsAvailableWithDSR && !isBrandServiceable(result)) {
          return { qualification: TopicQualification.OutOfScope }
        }

        if (!answeredAllElectricRepairQuestions) {
          return { qualification: TopicQualification.Unknown }
        } else {
          if (confirmCookTopIsElectric === YesNoValue.No) {
            return { qualification: TopicQualification.OutOfScope }
          }
          return {
            qualification: TopicQualification.InScope
          }
        }
      }

    default:
      return { qualification: TopicQualification.Unknown }
  }
}

const getRequirements = (result: TopicCooktopActionResult | undefined): TopicRequirementsResult => {
  const { cooktopType, serviceType } = result ?? {}

  if (serviceType === RepairOrReplaceEnum.Repair && cooktopType !== CooktopEnum.Electric) {
    return {}
  }

  switch (cooktopType) {
    case CooktopEnum.Gas:
      return {
        requirements: createRequirements([GAS_SKILL])
      }

    case CooktopEnum.Induction:
      return {
        requirements: createRequirements([GENERAL_ELECTRICAL_SKILL]),
        trade: TradeType.Electrical
      }
    case CooktopEnum.Electric:
      if (serviceType === RepairOrReplaceEnum.Replace) {
        return {
          requirements: createRequirements([GENERAL_ELECTRICAL_SKILL]),
          trade: TradeType.Electrical
        }
      } else {
        return getOvenOrCooktopRequirementsElectricRepair(result!)
      }
    default:
      return {}
  }
}

export const outcomeGenerator = createOutcomeGenerator<TopicCooktopActionResult>(
  { qualification: TopicQualification.Unknown, trade: TradeType.Plumbing, type: JobType.Service, category: 'General' },
  getQualification,
  getRequirements
)
