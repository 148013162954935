import { EuiLoadingSpinner } from '@elastic/eui'
import { dateConfig } from '@fallonsolutions/date'
import { compact } from 'lodash-es'
import { DateTime } from 'luxon'
import { useEffect, useState } from 'react'
import { MediaItemFragment, UserLinkFragment } from '../api/generated-types'
import { UploadFile } from '../media/media-service-upload'
import { ImageViewCaption } from './image-view-caption'

export interface ImageDimensions {
  width?: number
  height?: number
}

export interface ImageViewProps {
  mediaItem: MediaItemFragment
  src?: string
  file?: UploadFile
  width?: number
  height?: number
  caption?: string
  author?: UserLinkFragment
  captureDate?: string
  uploadDate?: string
  loading?: boolean
  dimensions?: ImageDimensions
  mediaItemId: string
  position?: {
    lat: number
    lon: number
  }
}

export const ImageView = (props: ImageViewProps) => {
  const { src, file, caption, loading, dimensions, author, captureDate, mediaItemId, mediaItem } = props
  const isDeleted = !!mediaItem.deleted
  const [fileSrc, setFileSrc] = useState<string | undefined>(undefined)

  useEffect(() => {
    if (file) {
      setFileSrc(window.URL.createObjectURL(file))
    }
  }, [file])

  const aspectRatio = dimensions?.width && dimensions?.height ? dimensions.width / dimensions.height : 1
  let w: number
  let h: number

  if (props.width && props.height) {
    w = props.width
    h = props.height
  } else {
    const minSize = props.width ?? 200
    if (aspectRatio > 1) {
      h = minSize
      w = parseInt((minSize * aspectRatio).toFixed(0))
    } else {
      w = minSize
      h = parseInt((minSize / aspectRatio).toFixed(0))
    }
  }

  // x2 to better display on mobile/high res screens
  // TODO: can we get pixel density from browser to only do 2x when useful?
  const resizeString = `?d=${w * 2}x${h * 2}`
  const date = captureDate
    ? DateTime.fromISO(captureDate).setZone(dateConfig.defaultTimezone).toFormat(dateConfig.luxonFormat.shortDateTime)
    : undefined
  const imageCaption = compact([caption, author?.contactDetail?.fullName, date]).join(' • ')

  return fileSrc || src ? (
    <>
      <a
        className="image-view image-view__link"
        href={fileSrc || src}
        target="_blank"
        rel="noreferrer"
        data-pswp-width={dimensions?.width}
        data-pswp-height={dimensions?.height}
        data-cropped="true"
      >
        <img
          onClick={(event) => event.preventDefault()}
          src={fileSrc ?? `${src}${resizeString}`}
          style={{ width: `${props.width}px`, height: `${props.height ?? props.width}px`, objectFit: 'cover' }}
          alt={imageCaption}
          loading="lazy"
          media-item-id={mediaItemId}
          deleted-status={isDeleted ? 'deleted' : 'not-deleted'}
          //        crossOrigin="use-credentials"
        />
      </a>
      <ImageViewCaption {...props} />
    </>
  ) : loading ? (
    <div>
      <EuiLoadingSpinner size="xl" style={{ margin: 'auto' }} />
    </div>
  ) : (
    <div>No image found</div>
  )
}
