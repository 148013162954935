import { EuiButton, EuiSpacer } from '@elastic/eui'
import { EnquiryAbortReason } from '../../../api/generated-types'
import { Callout } from '../../../common/callout'
import { makeHumanReadable } from '../../../common/utils'
import { QuestionDefinitions, getNotesGenerator } from '../../helpers/notes-generator'
import { getRequirementsGenerator } from '../../helpers/requirements-helper'
import { getResultUpdater } from '../../helpers/results-updater'
import { useWorkflow } from '../../helpers/workflow-provider'
import { SingleSelectField } from '../../question-fields/single-select-question-field'
import { WorkflowActionProps } from '../../workflow-model'
import { TopicActionInput } from '../action-topic-model'
import { TopicWorkflowAction } from '../action-topic-view'
import { TopicQualification } from '../common/common-outcome'
import { reset } from '../common/common-utils'
import {
  InsideOrOutside,
  TopicPlumbingWaterLeakDetectionActionResult
} from './action-topic-plumbing-water-leak-detection-model'
import { outcomeGenerator } from './action-topic-plumbing-water-leak-detection-requirements'
import { WaterLeakInside } from './plumbing-water-leak-detection-inside'
import { WaterLeakOutside } from './plumbing-water-leak-detection-outside'

const questionDefinitions: QuestionDefinitions<TopicPlumbingWaterLeakDetectionActionResult> = {
  insideOrOutside: {
    question: 'Is the leak inside or outside?'
  },
  symptom: {
    question: 'Where have you noticed the leak?',
    makeAnswerHumanReadable: makeHumanReadable
  },
  ceilingLeaking: {
    question: 'Is the ceiling leaking?'
  },
  canHearWaterRunningInside: { question: 'Can you hear water running inside?' },
  canSeeWetPatchOnFloor: { question: 'Can you see a wet patch on the floor?' },
  canSeeShowerLeakingBehindWall: { question: 'Can see a shower leaking behind a wall?' },
  suspectWaterIsLeakingBehindWall: { question: 'Do you suspect water is leaking behind the wall?' },
  canSmellDampOrWetCarpet: { question: 'Can smell damp or wet carpet?' },
  otherWetPatchesOnWalls: { question: 'Have you noticed any other wet patches on walls in adjacent rooms?' },
  swollenFloorboards: { question: 'Noticed any swollen floorboards?' },
  canIsolateWater: {
    question: 'Can you isolate the water leak?'
  },
  poolWaterLevelDropping: {
    question: 'Have you noticed if the pool water level is dropping?'
  },
  bathroomLaundryOrToiletAboveTheWetArea: {
    question: 'Are there any bathroom, laundries or toilets above the leak/wet area?'
  },
  ductedACInstalled: {
    question: 'Do you have ducted AC?'
  },
  ductedACRunning: {
    question: 'Have you been running your AC?'
  },
  ductedACCheckedForLeaks: {
    question: 'Has it been checked for leaks?'
  },
  outsideSymptomDetail: {
    question: 'Please choose?'
  }
}
const notesGenerator = getNotesGenerator(questionDefinitions)

export const TopicPlumbingWaterLeakDetectionAction = (
  props: WorkflowActionProps<TopicActionInput, TopicPlumbingWaterLeakDetectionActionResult>
) => {
  const { result, input, onUpdate } = props
  const { jobDescription, customerType, insideOrOutside } = result ?? {}
  const workflowContext = useWorkflow()
  const topicOutcome = outcomeGenerator(result)
  const { requirements } = topicOutcome
  const requirementsGenerator = getRequirementsGenerator(requirements)
  const requirementCallouts = requirementsGenerator.getCallouts()
  const isOutOfScope = topicOutcome.qualification === TopicQualification.OutOfScope
  const canComplete = topicOutcome.qualification === TopicQualification.InScope
  const newTopic = topicOutcome.newTopic

  const onNext = () =>
    onUpdate({
      ...result,
      actionCompleted: true,
      ...topicOutcome,

      workRequiredNotes: generatedNotes,
      requirements
    })

  const generatedNotes = notesGenerator.generateNotes(result ?? {})
  const updateResult = getResultUpdater(result ?? {}, onUpdate).updateResult

  return (
    <TopicWorkflowAction input={input} onUpdate={onUpdate} result={result}>
      <SingleSelectField
        question={notesGenerator.getQuestion('insideOrOutside')}
        options={[
          {
            id: InsideOrOutside.Inside,
            label: 'Inside',
            icon: 'home'
          },
          { id: InsideOrOutside.Outside, label: 'Outside' }
        ]}
        answer={insideOrOutside}
        changeAnswer={(insideOrOutside) =>
          updateResult({
            ...reset(result),
            customerType,
            jobDescription,
            insideOrOutside
          })
        }
      />
      <EuiSpacer />
      {insideOrOutside === InsideOrOutside.Inside && (
        <WaterLeakInside result={result} notesGenerator={notesGenerator} updateResult={updateResult} />
      )}
      {insideOrOutside === InsideOrOutside.Outside && (
        <WaterLeakOutside result={result} notesGenerator={notesGenerator} updateResult={updateResult} />
      )}

      {requirementCallouts}
      <EuiSpacer />
      {newTopic && (
        <EuiButton onClick={() => workflowContext.changeJobTopic(newTopic.reference)}>{newTopic.buttonLabel}</EuiButton>
      )}
      {isOutOfScope && !newTopic && (
        <>
          <Callout type="script">
            {topicOutcome.qualificationMessage
              ? topicOutcome.qualificationMessage
              : 'Unfortunately this type of work is out of scope for us.'}
          </Callout>
          <EuiSpacer />
          <EuiButton
            color="warning"
            onClick={() =>
              workflowContext.abort({
                abortReason: EnquiryAbortReason.CustomerNeedsAreOutOfService,
                notes: `Customer was enquiring about: ${topicOutcome.qualificationMessage}`
              })
            }
          >
            Offer a voucher and end call
          </EuiButton>
        </>
      )}

      {!isOutOfScope && !newTopic && (
        <EuiButton disabled={!canComplete} onClick={() => onNext()}>
          Next
        </EuiButton>
      )}
    </TopicWorkflowAction>
  )
}
