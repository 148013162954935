import { EuiButton, EuiSpacer } from '@elastic/eui'
import { Callout } from '../../../common/callout'
import { getResultUpdater } from '../../helpers/results-updater'
import { FreeTextQuestionField } from '../../question-fields/free-text-question-field'
import { WorkflowActionProps } from '../../workflow-model'
import { TopicActionInput } from '../action-topic-model'
import { TopicWorkflowAction } from '../action-topic-view'
import { TopicQualification } from '../common/common-outcome'
import { TopicWaterPressureActionResult, waterPressureNotesGenerator } from './action-topic-water-pressure-model'
import { outcomeGenerator } from './action-topic-water-pressure-outcome'

export const TopicWaterPressureAction = (
  props: WorkflowActionProps<TopicActionInput, TopicWaterPressureActionResult>
) => {
  const { result, input, onUpdate } = props
  const { location, waterTemperature, isolatedIssue, additionalInformation } = result ?? {}

  const notesGenerator = waterPressureNotesGenerator

  const topicOutcome = outcomeGenerator(result)
  const { requirements, qualification } = topicOutcome
  const isOutOfScope = qualification === TopicQualification.OutOfScope

  const onNext = () =>
    onUpdate({
      ...result,
      ...topicOutcome,
      actionCompleted: true,

      workRequiredNotes: generatedNotes
    })

  const generatedNotes = notesGenerator.generateNotes(result ?? {})
  const updateResult = getResultUpdater(result ?? {}, onUpdate).updateResult

  const canComplete = !!requirements

  return (
    <TopicWorkflowAction input={input} onUpdate={onUpdate} result={result}>
      <FreeTextQuestionField
        question={notesGenerator.getQuestion('location')}
        answer={location}
        changeAnswer={(location) => updateResult({ location })}
      />

      <FreeTextQuestionField
        question={notesGenerator.getQuestion('waterTemperature')}
        answer={waterTemperature}
        changeAnswer={(waterTemperature) => updateResult({ waterTemperature })}
      />

      <FreeTextQuestionField
        question={notesGenerator.getQuestion('isolatedIssue')}
        answer={isolatedIssue}
        changeAnswer={(isolatedIssue) => updateResult({ isolatedIssue })}
      />

      <FreeTextQuestionField
        question={notesGenerator.getQuestion('additionalInformation')}
        answer={additionalInformation}
        changeAnswer={(additionalInformation) => updateResult({ additionalInformation })}
        rows={3}
      />

      {requirements && (
        <>
          <EuiSpacer />
          <Callout type="note">{requirements.attributes?.map((r) => r.attributeId).join(', ')}</Callout>
        </>
      )}

      {!isOutOfScope && (
        <>
          <EuiSpacer />
          <EuiButton disabled={!canComplete} onClick={() => onNext()}>
            Next
          </EuiButton>
        </>
      )}
    </TopicWorkflowAction>
  )
}
