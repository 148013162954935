import { EnquiryType } from '../../api/generated-types'
import { EnquiryTypeReference } from '../workflow-model'

export const enquiryTypeAdapter = (reference: EnquiryTypeReference) => {
  switch (reference) {
    case EnquiryTypeReference.CancelJob:
      return EnquiryType.CancelJob
    case EnquiryTypeReference.Crisis:
      return EnquiryType.Crisis
    case EnquiryTypeReference.Complaint:
      return EnquiryType.Complaint
    case EnquiryTypeReference.ConfirmJob:
      return EnquiryType.ConfirmJob
    case EnquiryTypeReference.DisputeInvoice:
      return EnquiryType.DisputeInvoice
    case EnquiryTypeReference.Employment:
      return EnquiryType.Employment
    case EnquiryTypeReference.ETA:
      return EnquiryType.ETA
    case EnquiryTypeReference.Feedback:
      return EnquiryType.Feedback
    case EnquiryTypeReference.Media:
      return EnquiryType.Media
    case EnquiryTypeReference.Membership:
      return EnquiryType.Membership
    case EnquiryTypeReference.NewBooking:
      return EnquiryType.NewBooking
    case EnquiryTypeReference.Other:
      return EnquiryType.Other
    case EnquiryTypeReference.PayInvoice:
      return EnquiryType.PayInvoice
    case EnquiryTypeReference.Rebooking:
      return EnquiryType.Rebooking
    case EnquiryTypeReference.ResendInvoice:
      return EnquiryType.ResendInvoice
    case EnquiryTypeReference.Telemarketing:
      return EnquiryType.Telemarketing
    case EnquiryTypeReference.Transfer:
      return EnquiryType.Transfer
    case EnquiryTypeReference.UpdateCustomer:
      return EnquiryType.UpdateCustomer
    case EnquiryTypeReference.UpdateJob:
      return EnquiryType.UpdateJob
    default:
      return EnquiryType.Other
  }
}
