import {
  EuiAvatar,
  EuiButton,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiFormRow,
  EuiLink,
  EuiSpacer,
  EuiTitle
} from '@elastic/eui'
import { AsYouType } from 'libphonenumber-js'
import { compact, first } from 'lodash-es'
import { useEffect } from 'react'
import { SuburbSelect, SuburbSelectOptionDetail } from '../../suburbs/suburb-select'
import { BaseActionInput, BaseActionResult, WorkflowActionProps } from '../workflow-model'

export type ContactActionInput = BaseActionInput

export interface ContactActionResult extends BaseActionResult {
  id?: string
  customerId?: string
  suburb?: {
    id: string
    label: string
  }
  firstName?: string
  lastName?: string
  phone?: string
  email?: string
}

export interface ContactDetails {
  suburb?: {
    id: string
    label: string
  }
  firstName: string
  lastName?: string
  phone: string
  email?: string
}

export interface CustomerContactDetails extends ContactDetails {
  id: string
  customerId: string
}

export interface ContactActionProps {
  completed: boolean
  setCompleted: (completed: boolean) => void
  contact: ContactDetails
  setContact: (contact: ContactDetails) => void
}

export const ContactAction = (
  props: WorkflowActionProps<ContactActionInput, ContactActionResult> & ContactActionProps
) => {
  const { onUpdate, onBack, setCompleted, completed, contact, setContact } = props

  const updateResult = (contact: ContactDetails) => {
    onUpdate({
      firstName: contact.firstName,
      lastName: contact.lastName,
      phone: contact.phone,
      suburb: contact.suburb
    })
  }

  useEffect(() => {
    updateResult(contact)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleComplete = () => {
    setCompleted(true)
  }

  const handleChange = () => {
    setCompleted(false)
    onBack?.()
  }

  const setFirstName = (value: string) => {
    const updatedContact = {
      ...contact,
      firstName: value
    }
    setContact(updatedContact)
    updateResult(updatedContact)
  }

  const setLastName = (value: string) => {
    const updatedContact = {
      ...contact,
      lastName: value
    }
    setContact(updatedContact)
    updateResult(updatedContact)
  }

  const onChangePhone = (phone: string) => {
    const formattedPhone = new AsYouType('AU').input(phone)
    const updatedContact = {
      ...contact,
      phone: formattedPhone
    }
    setContact(updatedContact)
    updateResult(updatedContact)
  }

  const handleSuburbSelect = (suburbs: SuburbSelectOptionDetail[]) => {
    const selectedSuburb = first(suburbs)
    const updatedContact = {
      ...contact,
      suburb: selectedSuburb ? { id: selectedSuburb.id, label: selectedSuburb.label } : undefined
    }
    setContact(updatedContact)
    updateResult(updatedContact)
  }

  const hasFullName = !!contact.firstName.trim().length || !!contact.lastName?.trim().length
  const noName = 'Anonymous'

  return (
    <>
      <EuiTitle size="xs">
        <h2>Contact details</h2>
      </EuiTitle>
      <EuiSpacer size="m" />

      {!completed && (
        <>
          <EuiForm>
            <EuiFlexGroup>
              <EuiFlexItem>
                <EuiFormRow label="First name">
                  <EuiFieldText value={contact.firstName} onChange={(e) => setFirstName(e.target.value)} />
                </EuiFormRow>
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiFormRow label="Last name">
                  <EuiFieldText value={contact.lastName ?? ''} onChange={(e) => setLastName(e.target.value)} />
                </EuiFormRow>
              </EuiFlexItem>
            </EuiFlexGroup>
            <EuiSpacer size="m" />
            <EuiFormRow label="Suburb">
              <SuburbSelect
                suburbs={contact.suburb ? [{ ...contact.suburb, color: '' }] : []}
                onChangeSuburbs={handleSuburbSelect}
              />
            </EuiFormRow>
            <EuiSpacer size="m" />

            <EuiFlexGroup alignItems="flexEnd">
              <EuiFlexItem grow={true}>
                <EuiFormRow label="Phone">
                  <EuiFieldText
                    style={{ width: '140px' }}
                    value={contact.phone}
                    onChange={(e) => onChangePhone(e.target.value)}
                  />
                </EuiFormRow>
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiButton onClick={handleComplete} color="primary">
                  Keep
                </EuiButton>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiForm>
          <EuiSpacer />
        </>
      )}
      {completed && (
        <>
          <EuiFlexGroup>
            <EuiFlexItem>
              <EuiForm>
                <EuiFlexGroup alignItems="flexStart" gutterSize="m">
                  <EuiFlexItem grow={false}>
                    <EuiAvatar
                      name={hasFullName ? `${compact([contact.firstName, contact.lastName]).join(' ')}` : '?'}
                      size="l"
                    />
                  </EuiFlexItem>
                  <EuiFlexItem grow={true}>
                    <div>
                      <strong>
                        {hasFullName ? `${compact([contact.firstName, contact.lastName]).join(' ')}` : noName}
                      </strong>
                    </div>
                    <EuiSpacer size="xs" />
                    <div>
                      {contact.phone && (
                        <a href={`tel:${contact.phone}`} rel="noreferrer noopener">
                          {contact.phone}
                        </a>
                      )}
                      {!contact.phone && <>No phone number</>}
                    </div>
                    <EuiSpacer size="xs" />
                    {contact.suburb && <>{contact.suburb.label}</>}
                    {!contact.suburb && <>No suburb</>}
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiForm>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiLink onClick={handleChange}>Change</EuiLink>
            </EuiFlexItem>
          </EuiFlexGroup>
          <EuiSpacer />
        </>
      )}
    </>
  )
}
