import { EuiButton, EuiSpacer } from '@elastic/eui'
import { CustomerType, JobType, TradeType } from '../../../api/generated-types'
import { getNotesGenerator } from '../../helpers/notes-generator'
import { getResultUpdater } from '../../helpers/results-updater'
import { FreeTextQuestionField } from '../../question-fields/free-text-question-field'
import { SingleSelectField } from '../../question-fields/single-select-question-field'
import { WorkflowActionProps } from '../../workflow-model'
import { TopicActionInput } from '../action-topic-model'
import { TopicWorkflowAction } from '../action-topic-view'
import { YesNoValue } from '../common/common-enums'
import { TopicSolarActionResult, solarQuestionDefinitions } from './action-topic-solar'
import { OutOfScope } from '../../helpers/out-of-topic'

const notesGenerator = getNotesGenerator(solarQuestionDefinitions)

export const TopicSolarInspection = (props: WorkflowActionProps<TopicActionInput, TopicSolarActionResult>) => {
  const { result, onUpdate, input } = props
  const { existingSystemHasBattery, solarSystemAge, batteryAge, customerType } = result ?? {}
  const generatedNotes = notesGenerator.generateNotes(result ?? {})
  const updateResult = getResultUpdater(result ?? {}, onUpdate).updateResult
  const canComplete =
    (existingSystemHasBattery === YesNoValue.Yes && batteryAge) ||
    (existingSystemHasBattery === YesNoValue.No && solarSystemAge)

  const attributeIds = ['SolarInspection']
  const onNext = () => {
    onUpdate({
      ...result,
      actionCompleted: true,
      trade: TradeType.Solar,
      category: 'Solar Inspection',
      type: JobType.Service,
      workRequiredNotes: generatedNotes,
      requirements: {
        attributes: attributeIds.map((attributeId) => ({ attributeId }))
      }
    })
  }
  const isDomestic = customerType === CustomerType.Domestic
  return (
    <>
      {isDomestic ? (
        <>
          <TopicWorkflowAction input={input} onUpdate={onUpdate} result={result}>
            <SingleSelectField
              question={notesGenerator.getQuestion('existingSystemHasBattery')}
              options={[
                {
                  id: YesNoValue.Yes,
                  label: 'Yes, has battery'
                },
                {
                  id: YesNoValue.No,
                  label: 'No'
                }
              ]}
              answer={existingSystemHasBattery}
              changeAnswer={(existingSystemHasBattery) => updateResult({ existingSystemHasBattery })}
            />
            {existingSystemHasBattery === YesNoValue.No && (
              <>
                <FreeTextQuestionField
                  question={notesGenerator.getQuestion('solarSystemAge')}
                  answer={solarSystemAge}
                  changeAnswer={(solarSystemAge) => updateResult({ solarSystemAge })}
                />
              </>
            )}
            {existingSystemHasBattery === YesNoValue.Yes && (
              <FreeTextQuestionField
                question={notesGenerator.getQuestion('batteryAge')}
                answer={batteryAge}
                changeAnswer={(batteryAge) => updateResult({ batteryAge })}
              />
            )}

            <EuiSpacer />
            <EuiButton disabled={!canComplete} onClick={() => onNext()}>
              Next
            </EuiButton>
          </TopicWorkflowAction>
        </>
      ) : (
        <>
          {' '}
          <OutOfScope
            isOutOfScope={true}
            issue={'commercial $99 solar inspection'}
            reason="We do not offer the $99 solar inspection to non domestic customers"
          />
        </>
      )}
    </>
  )
}
