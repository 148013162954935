import { EuiButton, EuiSpacer } from '@elastic/eui'
import { find } from 'lodash-es'
import { EnquiryAbortReason } from '../../../api/generated-types'
import { Callout } from '../../../common/callout'
import { getResultUpdater } from '../../helpers/results-updater'
import { useWorkflow } from '../../helpers/workflow-provider'
import { SingleSelectField } from '../../question-fields/single-select-question-field'
import { WorkflowActionProps } from '../../workflow-model'
import { TopicActionInput } from '../action-topic-model'
import { TopicWorkflowAction } from '../action-topic-view'
import {
  ApplianceTypeEnum,
  NEW_APPLIANCE_NOT_ONSITE_MESSAGE,
  RepairOrReplaceEnum,
  repairOrReplaceOptions
} from '../appliance-shared/appliance-model'
import { RepairElectricAppliance } from '../appliance-shared/repair-electric-appliance'
import { YesNoValue } from '../common/common-enums'
import { yesNoOptions } from '../common/common-options'
import { TopicQualification } from '../common/common-outcome'
import {
  CooktopEnum,
  TopicCooktopActionResult,
  cooktopNotesGenerator,
  cooktopTypeOptions
} from './action-topic-cooktop-model'
import { outcomeGenerator } from './action-topic-cooktop-outcome'

export const TopicCooktopAction = (props: WorkflowActionProps<TopicActionInput, TopicCooktopActionResult>) => {
  const { result, input, onUpdate } = props
  const { serviceType, cooktopType, removeOldCooktop, willNewCooktopFitCutOut, isNewCooktopOnsite } = result ?? {
    applianceType: ApplianceTypeEnum.Cooktop
  }

  const workflowContext = useWorkflow()
  const notesGenerator = cooktopNotesGenerator
  const generatedNotes = notesGenerator.generateNotes(result ?? {})

  const topicOutcome = outcomeGenerator(result)
  const { requirements, qualification } = topicOutcome
  const isOutOfScope = qualification === TopicQualification.OutOfScope
  const inScope = qualification === TopicQualification.InScope
  const newTopic = topicOutcome.newTopic

  const updateResult = getResultUpdater(result ?? {}, onUpdate).updateResult

  const canComplete = !!requirements
  const cookTopTypeDescription = find(cooktopTypeOptions, { id: cooktopType })?.description
  const inductionCookTop = find(cooktopTypeOptions, { id: CooktopEnum.Induction })
  const onNext = () =>
    onUpdate({
      ...result,
      ...topicOutcome,
      actionCompleted: true,

      workRequiredNotes: generatedNotes
    })

  return (
    <TopicWorkflowAction input={input} onUpdate={onUpdate} result={result}>
      <SingleSelectField
        question={notesGenerator.getQuestion('serviceType')}
        options={repairOrReplaceOptions}
        answer={serviceType}
        changeAnswer={(serviceType) => updateResult({ serviceType })}
      />
      <EuiSpacer size="m" />

      {!!serviceType && (
        <>
          <SingleSelectField
            question={notesGenerator.getQuestion('cooktopType')}
            options={cooktopTypeOptions}
            answer={cooktopType}
            changeAnswer={(cooktopType) => updateResult({ cooktopType })}
          />
          {cookTopTypeDescription && (
            <>
              <EuiSpacer size="s" />
              <Callout title={cookTopTypeDescription} />
              <EuiSpacer size="s" />
            </>
          )}
        </>
      )}

      {!!cooktopType && serviceType === RepairOrReplaceEnum.Replace && (
        <>
          <SingleSelectField
            question={notesGenerator.getQuestion('removeOldCooktop')}
            options={yesNoOptions}
            answer={removeOldCooktop}
            changeAnswer={(removeOldCooktop) => updateResult({ removeOldCooktop })}
          />
          <SingleSelectField
            question={notesGenerator.getQuestion('willNewCooktopFitCutOut')}
            options={yesNoOptions}
            answer={willNewCooktopFitCutOut}
            changeAnswer={(willNewCooktopFitCutOut) => updateResult({ willNewCooktopFitCutOut })}
          />

          {!!removeOldCooktop && !!willNewCooktopFitCutOut && (
            <SingleSelectField
              question={notesGenerator.getQuestion('isNewCooktopOnsite')}
              options={yesNoOptions}
              answer={isNewCooktopOnsite}
              changeAnswer={(isNewCooktopOnsite) => updateResult({ isNewCooktopOnsite })}
            />
          )}
          {isNewCooktopOnsite === YesNoValue.No && <Callout type="note" title={NEW_APPLIANCE_NOT_ONSITE_MESSAGE} />}
        </>
      )}

      {cooktopType === CooktopEnum.Electric && serviceType === RepairOrReplaceEnum.Repair && (
        <RepairElectricAppliance
          notesGenerator={notesGenerator}
          updateResult={updateResult}
          result={result}
          isCooktopWorkflow={true}
        />
      )}

      {requirements && requirements.attributes && !isOutOfScope && inScope && (
        <>
          <EuiSpacer />
          <Callout type="note">
            Book a technician with {requirements.attributes?.map((r) => r.attributeId).join(', ')} skills
          </Callout>
        </>
      )}

      {!isOutOfScope && !newTopic && inScope && (
        <>
          <EuiSpacer />
          <EuiButton disabled={!canComplete} onClick={() => onNext()}>
            Next
          </EuiButton>
        </>
      )}
      {newTopic && (
        <EuiButton onClick={() => workflowContext.changeJobTopic(newTopic.reference)}>{newTopic.buttonLabel}</EuiButton>
      )}

      {isOutOfScope && (
        <>
          <Callout type="script">
            {topicOutcome.qualificationMessage
              ? topicOutcome.qualificationMessage
              : 'Unfortunately this type of work is out of scope for us.'}
          </Callout>
          <EuiSpacer />
          <EuiButton
            color="warning"
            onClick={() =>
              workflowContext.abort({
                abortReason: EnquiryAbortReason.CustomerNeedsAreOutOfService,
                notes: `Customer was enquiring about: ${topicOutcome.qualificationMessage}`
              })
            }
          >
            Offer a voucher and end call
          </EuiButton>
        </>
      )}
    </TopicWorkflowAction>
  )
}
