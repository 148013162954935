import {
  EuiContextMenu,
  EuiContextMenuItemIcon,
  EuiContextMenuPanelDescriptor,
  EuiContextMenuPanelItemDescriptor,
  EuiPopover
} from '@elastic/eui'
import {
  DeleteMediaItemsDocument,
  MediaContext,
  MediaItemFragment,
  MediaItemOperationType,
  MediaStatus,
  MediaType,
  PerformMediaItemOperationDocument,
  RestoreMediaItemsDocument,
  SetMediaItemClassificationDocument
} from '../api/generated-types'
import { useAuthenticated } from '../auth/authenticated-context'
import { AllAction, useDecisionPoint } from '../decision-point/use-decision-point'
import '../static/css/media-item.css'
import { ReactElement } from 'react'
import { saveAs } from 'file-saver'
import { useMutation } from '@apollo/client'
import { compact } from 'lodash-es'
import { MediaItemSubject } from './media-item-subject'

export interface AdditionalContextMenuItems {
  func: (mediaItem: string) => void
  label: string
  icon: EuiContextMenuItemIcon
}

export interface MediaItemContextMenuProps {
  isVisible: boolean
  setIsVisible: (isVisible: boolean) => void
  mediaItem: MediaItemFragment
  children: ReactElement
  additionalContextMenuItems?: AdditionalContextMenuItems[]
}

export const MediaItemContextMenu = ({
  isVisible,
  setIsVisible,
  mediaItem,
  children,
  additionalContextMenuItems
}: MediaItemContextMenuProps) => {
  const { canDeletePhotosOnJobOk } = useDecisionPoint(AllAction.DeletePhotosOnJob).canDeletePhotosOnJob({ mediaItem })
  const { canClassifyMediaItemOk } = useDecisionPoint(AllAction.ClassifyMediaItem).canClassifyMediaItem({ mediaItem })
  const { user: session, userFragment } = useAuthenticated()
  const hasDeveloperFeatures = userFragment.permissions?.developerFeatures === true

  const position = mediaItem.location?.position
  const mapUrl = position && `https://www.google.com/maps/search/?api=1&query=${position.lat}%2C${position.lon}`

  const isProductImage = mediaItem.type === MediaType.ProductImage

  const [performMediaItemOperation] = useMutation(PerformMediaItemOperationDocument)
  const [deleteMediaItems] = useMutation(DeleteMediaItemsDocument)
  const [restoreMediaItems] = useMutation(RestoreMediaItemsDocument)
  const [setMediaItemClassification] = useMutation(SetMediaItemClassificationDocument)

  const handleRestoreMediaItem = () => {
    restoreMediaItems({
      variables: {
        input: {
          ids: [mediaItem.id]
        }
      }
    })
    closeContextMenu()
  }
  const handleDeleteMediaItem = () => {
    if (!canDeletePhotosOnJobOk) {
      return
    }
    deleteMediaItems({
      variables: {
        input: {
          ids: [mediaItem.id]
        }
      }
    })
    closeContextMenu()
  }

  const job = mediaItem.job
  const url = mediaItem.status !== MediaStatus.WaitingForUpload ? mediaItem.url : undefined
  const extension = mediaItem.object?.mimeType?.split('/')[1] ?? 'jpg'
  const createImageName = () => {
    if (extension === 'pdf') {
      return `${compact([job?.number ?? 'document', mediaItem.id]).join('-')}.pdf`
    }
    return `${compact([job?.number ?? 'image', mediaItem.subject, mediaItem.id]).join('-')}.jpg`
  }

  const getDownloadUrl = () => {
    if (extension === 'pdf') {
      return `${url}/${mediaItem.id}.pdf`
    }
    return url
  }

  const downloadImage = async () => {
    console.log('media item', mediaItem.id)
    const imageName = createImageName()
    const url = getDownloadUrl()
    closeContextMenu()
    if (!url) {
      return
    }
    console.log('downloadImage', { url, imageName })

    const response = await fetch(url, {
      method: 'GET',
      mode: 'cors',
      credentials: 'include',
      cache: 'no-cache',
      headers: new Headers({
        Authorization: session.token || '',
        Accept: fetchAcceptanceType()
      })
    })
    console.log('fetchResponse', response)
    if (response.ok) {
      const blob = await response.blob()
      saveAs(blob, imageName)
    }
  }
  const fetchAcceptanceType = () => {
    const extension = mediaItem.object?.mimeType ?? 'jpg'
    switch (extension) {
      case 'jpg':
        return 'image/jpeg'
      case 'pdf':
        return 'application/pdf'
      default:
        return 'image/jpeg'
    }
  }

  const closeContextMenu = () => setIsVisible(false)

  const handleRotate = (operationType: MediaItemOperationType) => {
    closeContextMenu()
    performMediaItemOperation({
      variables: {
        input: {
          mediaItemId: mediaItem.id,
          operationType
        }
      }
    })
  }

  const handleChangeClassification = (updatedClassification: { context: MediaContext; subject: string }) => {
    closeContextMenu()

    if (!canClassifyMediaItemOk) {
      return
    }

    const input = { mediaItemId: mediaItem.id, ...updatedClassification }
    setMediaItemClassification({
      variables: { input }
    })
  }

  const copyToClipboard = (text: string) => {
    closeContextMenu()
    try {
      navigator.clipboard.writeText(text)
    } catch (err) {
      console.error('err copying text', err)
    }
  }

  const openInNewTab = () => {
    closeContextMenu()
    const imageName = createImageName()
    const downloadUrl = `${mediaItem.url}/${imageName}`
    console.log('openInNewTab', { downloadUrl })
    window.open(downloadUrl, '_blank')
  }

  const openMap = () => {
    closeContextMenu()
    if (mapUrl) {
      window.open(mapUrl, '_blank')
    }
  }

  //provide a quick change menu for the current subject with all context combinations and base menu options
  const subjectContextOptions = (mediaItem: MediaItemFragment) => {
    const contextOptions = [MediaContext.General, MediaContext.Before, MediaContext.After].map((context) => {
      return { subject: mediaItem.subject ?? 'None', context }
    })
    const noneOptions =
      mediaItem.subject !== MediaItemSubject.None && !!mediaItem.context
        ? [{ subject: 'None', context: mediaItem.context }]
        : ([] as MediaItemClassification[])

    const switchboardOptions =
      mediaItem.subject !== MediaItemSubject.Switchboard && !!mediaItem.context
        ? [{ subject: 'Switchboard', context: mediaItem.context }]
        : []
    const riskAssessmentOptions =
      mediaItem.subject !== MediaItemSubject.RiskAssessment && !!mediaItem.context
        ? [{ subject: 'Risk assessment', context: mediaItem.context }]
        : []
    const hotWaterSystemOptions =
      mediaItem.subject !== MediaItemSubject.HotWaterSystem && !!mediaItem.context
        ? [{ subject: 'Hot Water System', context: mediaItem.context }]
        : []

    return {
      contextOptions,
      noneOptions,
      switchboardOptions,
      riskAssessmentOptions,
      hotWaterSystemOptions
    }
  }

  // const initialValue = contextOptions
  // const menu = quickChangeClassificationBaseMenu.reduce((prev, curr) => {
  //   if (prev.filter((o) => o.subject === curr.subject && o.context === curr.context)?.length !== 0) {
  //     return prev
  //   } else {
  //     return [...prev, curr]
  //   }
  // }, initialValue)
  // return menu
  // }

  const quickChangeAllSubjectsMenu = (mediaItem: MediaItemFragment): MediaItemClassification[] => {
    type MediaItemSubjectKey = keyof typeof MediaItemSubject
    const mediaItemSubjectKeys = Object.keys(MediaItemSubject) as MediaItemSubjectKey[]
    //todo: exclude the primary options
    return mediaItemSubjectKeys.map((key) => {
      return { subject: MediaItemSubject[key], context: mediaItem.context ?? MediaContext.General }
    })
  }

  const buildQuickChangeClassificationMenu = (mediaItem: MediaItemFragment): EuiContextMenuPanelItemDescriptor[] => {
    const { contextOptions, noneOptions, riskAssessmentOptions, switchboardOptions, hotWaterSystemOptions } =
      subjectContextOptions(mediaItem)
    const getOption = (quickMenuItem: MediaItemClassification) => ({
      name: `${quickMenuItem.subject}, ${quickMenuItem.context}`,
      icon:
        quickMenuItem.subject === mediaItem?.subject && quickMenuItem.context === mediaItem?.context
          ? 'check'
          : 'empty',
      onClick: () => {
        handleChangeClassification({ subject: quickMenuItem.subject, context: quickMenuItem.context })
      }
    })
    const menu: EuiContextMenuPanelItemDescriptor[] = contextOptions.map(getOption)
    return menu
      .concat([{ isSeparator: true, key: 'sep2' }])
      .concat(noneOptions.map(getOption) ?? [])
      .concat([{ isSeparator: true, key: 'sep3' }])
      .concat(switchboardOptions.map(getOption) ?? [])
      .concat([{ isSeparator: true, key: 'sep4' }])
      .concat(riskAssessmentOptions.map(getOption) ?? [])
      .concat([{ isSeparator: true, key: 'sep5' }])
      .concat(hotWaterSystemOptions.map(getOption) ?? [])
  }

  interface MediaItemClassification {
    context: MediaContext
    subject: string
  }

  const buildQuickChangeClassificationSecondaryMenu = (
    mediaItem: MediaItemFragment
  ): EuiContextMenuPanelItemDescriptor[] => {
    return quickChangeAllSubjectsMenu(mediaItem).map((quickMenuItem) => ({
      name: `${quickMenuItem.subject}`,
      icon:
        quickMenuItem.subject === mediaItem?.subject && quickMenuItem.context === mediaItem?.context
          ? 'check'
          : 'empty',
      onClick: () => {
        handleChangeClassification({ subject: quickMenuItem.subject, context: quickMenuItem.context })
      }
    }))
  }

  const contextMenuPanels: EuiContextMenuPanelDescriptor[] = [
    {
      id: 0,
      items: [
        ...(url
          ? [
              {
                icon: 'download',
                name: 'Download',
                onClick: downloadImage
              },
              {
                icon: 'popout',
                name: 'Open in new tab',
                onClick: () => openInNewTab()
              }
            ]
          : []),
        ...(mapUrl
          ? [
              {
                icon: 'mapMarker',
                name: 'View on map',
                onClick: () => openMap()
              }
            ]
          : []),
        {
          isSeparator: true
        },
        ...(!isProductImage
          ? [
              {
                icon: 'editorUndo',
                name: 'Rotate left',
                onClick: () => handleRotate(MediaItemOperationType.Rotate270)
              },
              {
                icon: 'editorRedo',
                name: 'Rotate right',
                onClick: () => handleRotate(MediaItemOperationType.Rotate90)
              }
            ]
          : []),

        {
          isSeparator: true
        },
        ...(canClassifyMediaItemOk
          ? [
              {
                icon: 'folderOpen',
                name: `Change classification`,
                panel: 'change-classification'
              }
            ]
          : []),
        ...(canDeletePhotosOnJobOk && !isProductImage
          ? ([
              {
                isSeparator: true
              },
              ...(mediaItem.deleted
                ? [
                    {
                      icon: 'help',
                      name: 'Restore',
                      onClick: handleRestoreMediaItem
                    }
                  ]
                : [
                    {
                      icon: 'trash',
                      name: 'Delete',
                      onClick: handleDeleteMediaItem
                    }
                  ])
            ] as EuiContextMenuPanelItemDescriptor[])
          : []),
        ...(hasDeveloperFeatures
          ? ([
              {
                isSeparator: true
              },
              {
                icon: 'copy',
                name: 'Copy ID',
                onClick: () => copyToClipboard(mediaItem.id)
              }
            ] as EuiContextMenuPanelItemDescriptor[])
          : []),
        ...(additionalContextMenuItems
          ? ([
              { isSeparator: true },
              ...additionalContextMenuItems.map((item) => ({
                icon: item.icon,
                name: item.label,
                onClick: () => {
                  setIsVisible(false)
                  item.func(mediaItem.id)
                }
              }))
            ] as EuiContextMenuPanelItemDescriptor[])
          : [])
      ]
    },
    ...(canClassifyMediaItemOk
      ? [
          {
            id: 'change-classification',
            title: `Change classification`,
            items: buildQuickChangeClassificationMenu(mediaItem).concat([
              {
                isSeparator: true,
                key: 'sep-change-classification'
              },
              {
                name: 'More...',
                panel: 'change-classification-all-subjects'
              }
            ])
          },
          {
            id: 'change-classification-all-subjects',
            title: `Change classification > More...`,
            items: buildQuickChangeClassificationSecondaryMenu(mediaItem)
          }
        ]
      : [])
  ]

  return (
    <>
      <EuiPopover
        id="export-popover"
        button={children}
        isOpen={isVisible}
        closePopover={closeContextMenu}
        panelPaddingSize="none"
        anchorPosition="downLeft"
        ownFocus={false}
      >
        <EuiContextMenu panels={contextMenuPanels} initialPanelId={0} />
      </EuiPopover>
    </>
  )
}
