import { WorkflowActionProps } from '../../workflow-model'
import { EuiButton, EuiSpacer } from '@elastic/eui'
import { Callout } from '../../../common/callout'
import { JobType, TradeType } from '../../../api/generated-types'
import { TopicActionInput, TopicActionResult } from '../action-topic-model'
import { TopicWorkflowAction } from '../action-topic-view'
import { decameliseIfNotNil } from '../../../common/utils'
import { SingleSelectField } from '../../question-fields/single-select-question-field'
import { FreeTextQuestionField } from '../../question-fields/free-text-question-field'
import { QuestionDefinitions, getNotesGenerator } from '../../helpers/notes-generator'
import { getResultUpdater } from '../../helpers/results-updater'
import { ItemInstaller, ServiceType } from '../common/common-enums'

export interface TopicAntennaActionResult extends TopicActionResult {
  serviceType?: ServiceType
  itemInstaller?: ItemInstaller
  desiredAntennaPointsInTheBuilding?: string
}

const questionDefinitions: QuestionDefinitions<TopicAntennaActionResult> = {
  serviceType: {
    question: 'Is that an installation or a repair?',
    statement: 'Service type:',
    makeAnswerHumanReadable: decameliseIfNotNil
  },
  itemInstaller: {
    question: 'Who installed the antenna?',
    statement: 'Antenna installed by:',
    makeAnswerHumanReadable: decameliseIfNotNil
  },
  desiredAntennaPointsInTheBuilding: {
    question: 'How many antenna points do you want to go to in the house?',
    statement: 'Desired number of antenna points in the house:'
  }
}
const notesGenerator = getNotesGenerator(questionDefinitions)

export const TopicAntennaAction = (props: WorkflowActionProps<TopicActionInput, TopicAntennaActionResult>) => {
  const { result, input, onUpdate } = props
  const { serviceType, itemInstaller, desiredAntennaPointsInTheBuilding } = result ?? {}

  const isRevisit = serviceType === ServiceType.Repair && itemInstaller === ItemInstaller.Fallon

  const onNext = () =>
    onUpdate({
      ...result,
      actionCompleted: true,
      trade: TradeType.Data,
      category: 'Antenna',
      type: isRevisit ? JobType.Callback : JobType.Service,
      workRequiredNotes: generatedNotes,
      requirements: {
        attributes: [{ attributeId: 'TVAndAntenna' }]
      }
    })

  const generatedNotes = notesGenerator.generateNotes(result ?? {})
  const updateResult = getResultUpdater(result ?? {}, onUpdate).updateResult

  const canComplete =
    !!serviceType &&
    ((serviceType === ServiceType.Installation && !!desiredAntennaPointsInTheBuilding) ||
      (serviceType === ServiceType.Repair && !!itemInstaller))

  return (
    <TopicWorkflowAction input={input} onUpdate={onUpdate} result={result}>
      <SingleSelectField
        question={notesGenerator.getQuestion('serviceType')}
        options={[
          {
            id: ServiceType.Installation,
            label: 'Installation',
            icon: 'check'
          },
          {
            id: ServiceType.Repair,
            label: 'Repair',
            icon: 'wrench'
          }
        ]}
        answer={serviceType}
        changeAnswer={(serviceType) =>
          updateResult({
            serviceType,
            itemInstaller: undefined,
            desiredAntennaPointsInTheBuilding: undefined
          })
        }
      />

      {serviceType === ServiceType.Installation && (
        <>
          <FreeTextQuestionField
            question={notesGenerator.getQuestion('desiredAntennaPointsInTheBuilding')}
            answer={desiredAntennaPointsInTheBuilding}
            changeAnswer={(desiredAntennaPointsInTheBuilding) => updateResult({ desiredAntennaPointsInTheBuilding })}
          />
        </>
      )}

      {serviceType === ServiceType.Repair && (
        <>
          <SingleSelectField
            question={notesGenerator.getQuestion('itemInstaller')}
            options={[
              {
                id: ItemInstaller.Fallon,
                label: 'Fallon installed',
                icon: 'check'
              },
              {
                id: ItemInstaller.SomeoneElse,
                label: 'Someone else installed',
                icon: 'user'
              }
            ]}
            answer={itemInstaller}
            changeAnswer={(itemInstaller) =>
              updateResult({ itemInstaller, desiredAntennaPointsInTheBuilding: undefined })
            }
          />
        </>
      )}

      {/* TODO: This looks like a very common question asked during almost every job booking
      <Callout type="note" title="Is it double or single story house?" /> */}

      {isRevisit && (
        <>
          <Callout type="note">
            <ul>
              <li>
                Book same technician as the original Job and send work chat to Electrical team or email Electrical DSR
                Team
              </li>
              <li>If time not suitable or technician not available call Electrical DSR 99973</li>
            </ul>
          </Callout>
        </>
      )}
      <Callout type="note" title="Do NOT put on 7am technicians" />

      {canComplete && (
        <>
          <EuiSpacer />
          <EuiButton onClick={() => onNext()}>Next</EuiButton>
        </>
      )}
    </TopicWorkflowAction>
  )
}
