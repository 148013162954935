import { EuiFlexGroup, EuiFlexItem, EuiIcon } from '@elastic/eui'
import { MembershipLevel } from '../api/generated-types'
import NoneMemberLogo from '../static/images/member-logo-none.svg'
import PremiumMemberLogo from '../static/images/member-logo-premium.svg'
import StandardMemberLogo from '../static/images/member-logo-standard.svg'

export interface MembershipLabelProps {
  membershipLevel: MembershipLevel
}

export const MembershipLabel = ({ membershipLevel }: MembershipLabelProps) => {
  const label = getMembershipLabel(membershipLevel)
  const membershipIcon = getMembershipIcon(membershipLevel)
  return (
    <EuiFlexGroup gutterSize="none" alignItems="center">
      <EuiFlexItem grow={false}>
        <EuiIcon type={membershipIcon} size="m" style={{ marginRight: '9px' }} />
      </EuiFlexItem>
      <EuiFlexItem grow={true} className="nowrap">
        {label}
      </EuiFlexItem>
    </EuiFlexGroup>
  )
}

const getMembershipLabel = (membershipLevel: MembershipLevel) => {
  switch (membershipLevel) {
    case MembershipLevel.Complimentary:
      return 'Complimentary'
    case MembershipLevel.Standard:
      return '1 Year Member'
    case MembershipLevel.Premium:
      return '3 Year Member'
    case MembershipLevel.None:
    default:
      return 'No membership'
  }
}

const getMembershipIcon = (membershipLevel: MembershipLevel) => {
  switch (membershipLevel) {
    case MembershipLevel.Complimentary:
      return StandardMemberLogo
    case MembershipLevel.Standard:
      return StandardMemberLogo
    case MembershipLevel.Premium:
      return PremiumMemberLogo
    case MembershipLevel.None:
    default:
      return NoneMemberLogo
  }
}
