import { ContactFragment } from '../api/generated-types'
import createPersistedState from '../use-persisted-state'
import { ContactFormV2 } from './contact-form-v2'

const useContactDuplicateCheck = createPersistedState<boolean>('contactDuplicateCheck')

export interface EditContactFormProps {
  contact: ContactFragment
  onClose: () => void
}

export const EditContactForm = (props: EditContactFormProps) => {
  const [duplicateCheck] = useContactDuplicateCheck(false)
  return <ContactFormV2 duplicateCheck={duplicateCheck} {...props} />
}
