import { QuestionDefinitions, getNotesGenerator } from '../../helpers/notes-generator'
import { TopicActionResult } from '../action-topic-model'
import { YesNoValue } from '../common/common-enums'

export interface TopicHVACMaintenanceActionResult extends TopicActionResult {
  existingContract?: YesNoValue
  dsrAdviseToBook?: YesNoValue
  roofAccess?: YesNoValue
}

const hvacMaintenanceQuestionDefinitions: QuestionDefinitions<TopicHVACMaintenanceActionResult> = {
  existingContract: {
    question: 'Do you have an existing maintenance contract with Fallon Solutions?',
    statement: 'Existing maintenance contract:'
  },
  dsrAdviseToBook: {
    question: 'Did DSR advise you to book this job?',
    statement: 'DSR advised to book:',
    excludeFromNotes: true
  }
}

export const hvacMaintenanceNotesGenerator = getNotesGenerator(hvacMaintenanceQuestionDefinitions)
