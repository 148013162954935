import { EuiButton, EuiSpacer } from '@elastic/eui'
import { Callout } from '../../../common/callout'
import { getResultUpdater } from '../../helpers/results-updater'
import { FreeTextQuestionField } from '../../question-fields/free-text-question-field'
import { WorkflowActionProps } from '../../workflow-model'
import { TopicActionInput } from '../action-topic-model'
import { TopicWorkflowAction } from '../action-topic-view'
import { TopicQualification } from '../common/common-outcome'
import { TopicWaterLeakActionResult, waterLeakNotesGenerator } from './action-topic-water-leak-model'
import { outcomeGenerator } from './action-topic-water-leak-outcome'

export const TopicWaterLeakAction = (props: WorkflowActionProps<TopicActionInput, TopicWaterLeakActionResult>) => {
  const { result, input, onUpdate } = props
  const { leakLocation, leakAge, canLeakBeIsolated, additionalInformation } = result ?? {}

  const notesGenerator = waterLeakNotesGenerator

  const topicOutcome = outcomeGenerator(result)
  const { requirements, qualification } = topicOutcome
  const isOutOfScope = qualification === TopicQualification.OutOfScope

  const onNext = () =>
    onUpdate({
      ...result,
      ...topicOutcome,
      actionCompleted: true,

      workRequiredNotes: generatedNotes
    })

  const generatedNotes = notesGenerator.generateNotes(result ?? {})
  const updateResult = getResultUpdater(result ?? {}, onUpdate).updateResult

  const canComplete = !!requirements

  return (
    <TopicWorkflowAction input={input} onUpdate={onUpdate} result={result}>
      <FreeTextQuestionField
        question={notesGenerator.getQuestion('leakLocation')}
        answer={leakLocation}
        changeAnswer={(leakLocation) => updateResult({ leakLocation })}
      />

      <FreeTextQuestionField
        question={notesGenerator.getQuestion('leakAge')}
        answer={leakAge}
        changeAnswer={(leakAge) => updateResult({ leakAge })}
      />

      <FreeTextQuestionField
        question={notesGenerator.getQuestion('canLeakBeIsolated')}
        answer={canLeakBeIsolated}
        changeAnswer={(canLeakBeIsolated) => updateResult({ canLeakBeIsolated })}
      />

      <FreeTextQuestionField
        question={notesGenerator.getQuestion('additionalInformation')}
        answer={additionalInformation}
        changeAnswer={(additionalInformation) => updateResult({ additionalInformation })}
        rows={3}
      />

      {requirements && (
        <>
          <EuiSpacer />
          <Callout type="note">{requirements.attributes?.map((r) => r.attributeId).join(', ')}</Callout>
        </>
      )}

      {!isOutOfScope && (
        <>
          <EuiSpacer />
          <EuiButton disabled={!canComplete} onClick={() => onNext()}>
            Next
          </EuiButton>
        </>
      )}
    </TopicWorkflowAction>
  )
}
