import React, { useState } from 'react'
import { EuiLink } from '@elastic/eui'
import { CreateCustomerRefund } from './create-customer-refund'
import { InvoiceFragment } from '../api/generated-types'

export interface CreateCustomerRefundButtonProps {
  invoice: InvoiceFragment
}

export const CreateCustomerRefundButton = (props: CreateCustomerRefundButtonProps) => {
  const { invoice } = props
  const [isModalVisible, setIsModalVisible] = useState(false)

  const closeModal = () => setIsModalVisible(false)
  const showModal = () => setIsModalVisible(true)

  return (
    <div>
      <EuiLink onClick={showModal}>Create Customer Refund</EuiLink>
      {isModalVisible && <CreateCustomerRefund invoice={invoice} closeModal={closeModal} />}
    </div>
  )
}
