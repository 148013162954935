import { EuiButton, EuiSpacer } from '@elastic/eui'
import { JobType, TradeType } from '../../../api/generated-types'
import { QuestionDefinitions, getNotesGenerator } from '../../helpers/notes-generator'
import { getResultUpdater } from '../../helpers/results-updater'
import { FreeTextQuestionField } from '../../question-fields/free-text-question-field'
import { WorkflowActionProps } from '../../workflow-model'
import { TopicActionInput, TopicActionResult } from '../action-topic-model'
import { TopicWorkflowAction } from '../action-topic-view'

export interface TopicSwitchboardActionResult extends TopicActionResult {
  reasonForUpgrade?: string
}

const questionDefinitions: QuestionDefinitions<TopicSwitchboardActionResult> = {
  reasonForUpgrade: {
    question: 'Whats your motivation for Switchboard upgrade?',
    statement: 'Motivation:'
  }
}
const notesGenerator = getNotesGenerator(questionDefinitions)
type TopicActionSwitchBoardInspectionInput = TopicActionInput & { switchBoardSpecial?: boolean }
export const TopicSwitchboardAction = (
  props: WorkflowActionProps<TopicActionSwitchBoardInspectionInput, TopicSwitchboardActionResult>
) => {
  const { result, input, onUpdate } = props
  const { switchBoardSpecial } = input
  const { reasonForUpgrade } = result ?? {}
  const category = switchBoardSpecial ? '99 Switchboard Inspection' : 'Switchboard'
  const onNext = () =>
    onUpdate({
      ...result,
      actionCompleted: true,
      trade: TradeType.Electrical,
      category,
      type: !switchBoardSpecial ? JobType.Quote : JobType.Service, // specials are classed as services
      workRequiredNotes: generatedNotes,
      requirements: {
        travelFeeWaived: !switchBoardSpecial, // travel fee is not waived for switchboard special as $99 charge is considered a travel fee
        attributes: [{ attributeId: 'Switchboards' }]
      }
    })

  const generatedNotes = notesGenerator.generateNotes(result ?? {})
  const updateResult = getResultUpdater(result ?? {}, onUpdate).updateResult

  const canComplete = reasonForUpgrade && reasonForUpgrade.length > 0

  return (
    <TopicWorkflowAction input={input} onUpdate={onUpdate} result={result}>
      <FreeTextQuestionField
        question={notesGenerator.getQuestion('reasonForUpgrade')}
        answer={reasonForUpgrade}
        changeAnswer={(reasonForUpgrade) => updateResult({ reasonForUpgrade })}
        rows={3}
      />
      {canComplete && (
        <>
          <EuiSpacer />
          <EuiButton onClick={() => onNext()}>Next</EuiButton>
        </>
      )}
    </TopicWorkflowAction>
  )
}
