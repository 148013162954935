import { EuiButton, EuiSpacer } from '@elastic/eui'
import { JobType, TradeType } from '../../../api/generated-types'
import { QuestionDefinitions, getNotesGenerator } from '../../helpers/notes-generator'
import { getResultUpdater } from '../../helpers/results-updater'
import { FreeTextQuestionField } from '../../question-fields/free-text-question-field'
import { WorkflowActionProps } from '../../workflow-model'
import { TopicActionInput, TopicActionResult } from '../action-topic-model'
import { TopicWorkflowAction } from '../action-topic-view'

export interface TopicPlumbingInsuranceQuotesActionResult extends TopicActionResult {
  whatHappened?: string
  affectedAreas?: string
  damageDescription?: string
}

const questionDefinitions: QuestionDefinitions<TopicPlumbingInsuranceQuotesActionResult> = {
  whatHappened: {
    question: 'What is it that has happened on site?'
  },
  affectedAreas: {
    question: 'What areas are being affected?'
  },
  damageDescription: {
    question: 'Damage that has taken place?'
  }
}
const notesGenerator = getNotesGenerator(questionDefinitions)

export const TopicPlumbingInsuranceQuotesAction = (
  props: WorkflowActionProps<TopicActionInput, TopicPlumbingInsuranceQuotesActionResult>
) => {
  const { result, input, onUpdate } = props
  const { whatHappened, affectedAreas, damageDescription } = result ?? {}

  const onNext = () =>
    onUpdate({
      ...result,
      actionCompleted: true,
      trade: TradeType.Plumbing,
      category: 'InsuranceQuote',
      type: JobType.Service,

      workRequiredNotes: generatedNotes,
      requirements: {
        attributes: [{ attributeId: 'GeneralPlumbing' }]
      }
    })

  const generatedNotes = notesGenerator.generateNotes(result ?? {})
  const updateResult = getResultUpdater(result ?? {}, onUpdate).updateResult

  const canComplete = !!whatHappened && !!affectedAreas && !!damageDescription

  return (
    <TopicWorkflowAction input={input} onUpdate={onUpdate} result={result}>
      <FreeTextQuestionField
        question={notesGenerator.getQuestion('whatHappened')}
        answer={whatHappened}
        rows={3}
        changeAnswer={(whatHappened) => updateResult({ whatHappened })}
      />
      <FreeTextQuestionField
        question={notesGenerator.getQuestion('affectedAreas')}
        answer={affectedAreas}
        rows={3}
        changeAnswer={(affectedAreas) => updateResult({ affectedAreas })}
      />
      <FreeTextQuestionField
        question={notesGenerator.getQuestion('damageDescription')}
        hint={'Take down as much info as the customer explains the reason for the report'}
        answer={damageDescription}
        rows={10}
        changeAnswer={(damageDescription) => updateResult({ damageDescription })}
      />

      <EuiSpacer />
      <EuiButton disabled={!canComplete} onClick={() => onNext()}>
        Next
      </EuiButton>
    </TopicWorkflowAction>
  )
}
