import { useMutation } from '@apollo/client'
import {
  DeleteMediaItemsDocument,
  MediaItemFragment,
  MediaItemOperationType,
  PerformMediaItemOperationDocument
} from '../../api/generated-types'

export enum GalleryViewButtonActions {
  RotateLeft,
  RotateRight,
  Delete
}

export type GalleryViewCallBackReturnType<T> = T extends GalleryViewButtonActions.RotateRight
  ? MediaItemFragment | undefined
  : T extends GalleryViewButtonActions.RotateLeft
    ? MediaItemFragment | undefined
    : T extends GalleryViewButtonActions.Delete
      ? MediaItemFragment | undefined
      : undefined

export type GalleryMediaCallBackFunction = <T extends GalleryViewButtonActions>(
  action: T,
  mediaItemId: string
) => Promise<GalleryViewCallBackReturnType<T>>
export const GalleryViewCallBack = () => {
  const [performMediaItemOperation] = useMutation(PerformMediaItemOperationDocument)
  const [deleteMediaItems] = useMutation(DeleteMediaItemsDocument)

  const handleRotate = async (operationType: MediaItemOperationType, mediaId: string) => {
    return await performMediaItemOperation({
      variables: {
        input: {
          mediaItemId: mediaId,
          operationType
        }
      }
    })
  }
  const handleDelete = async (mediaId: string) => {
    return await deleteMediaItems({
      variables: {
        input: {
          ids: [mediaId]
        }
      }
    })
  }
  // Need to type the function explicitly because conditional return types don't work well with generics if it isn't all part of the same type
  const handleActionCommands: GalleryMediaCallBackFunction = async (action, mediaItemId) => {
    let result: any
    switch (action) {
      case GalleryViewButtonActions.RotateLeft: {
        const response = await handleRotate(MediaItemOperationType.Rotate270, mediaItemId)
        result = (response?.data?.performMediaItemOperation?.mediaItem as MediaItemFragment) ?? undefined
        return result
      }
      case GalleryViewButtonActions.RotateRight: {
        const response2 = await handleRotate(MediaItemOperationType.Rotate90, mediaItemId)
        result = response2?.data?.performMediaItemOperation?.mediaItem as MediaItemFragment
        return result
      }
      case GalleryViewButtonActions.Delete: {
        const response3 = await handleDelete(mediaItemId)
        result = response3?.data?.deleteMediaItems?.mediaItems?.[0] as MediaItemFragment
        return result
      }
      default:
        return undefined
    }
  }

  return handleActionCommands
}
