import {
  TopicQualification,
  TopicQualificationResult,
  TopicRequirementsResult,
  createOutcomeGenerator,
  createRequirements
} from '../common/common-outcome'
import { JobType, PriorityType, TradeType } from '../../../api/generated-types'
import { YesNoValue } from '../common/common-enums'
import { includes } from 'lodash-es'
import { PriorityRequirement } from '@fallonsolutions/priority'
import {
  HEAT_PUMP_HVAC_ISSUES,
  HotWaterBoosterType,
  HotWaterRepairIssue,
  HotWaterServiceType,
  HotWaterSystemAge,
  HotWaterSystemType,
  HotWaterThermostatsAge,
  Temperature,
  TopicHotWaterActionResult
} from './action-topic-hot-water-model'

const GENERAL_HOT_WATER_SKILL = 'HotWaterSystem'
const SOLAR_HOT_WATER_SKILL = 'SolarHotWaterSystem'
const GAS_HOT_WATER_SKILL = 'GasHotWaterSystem'
const HEAT_PUMP_HOT_WATER_SKILL = 'HeatPumpHotWaterSystem'
const HVAC_HEAT_PUMP_SKILL = 'HeatPumpAC'
const GENERAL_ELECTRICAL_SKILL = 'GeneralElectrical'

const COMMERCIAL_CUSTOMER_TYPE = 'Commercial'
const GENERAL_PLUMBING_SKILL = 'GeneralPlumbing'

const PRIORITY_ISSUES = [
  HotWaterRepairIssue.Burst,
  HotWaterRepairIssue.NoHotWaterAtTap,
  HotWaterRepairIssue.SmellGas,
  HotWaterRepairIssue.NoHotWater,
  HotWaterRepairIssue.LeakingPipes,
  HotWaterRepairIssue.ColdAtTap,
  HotWaterRepairIssue.NotLighting,
  HotWaterRepairIssue.TankLeaking,
  HotWaterRepairIssue.Rusted,
  HotWaterRepairIssue.Bulging,
  HotWaterRepairIssue.Leaking
]

// Hot water has some complicated paths and eventual requirements
// Split into repair and replace to make it a little easier to follow the logic
const getRequirements = (result: TopicHotWaterActionResult | undefined): TopicRequirementsResult => {
  if (!result || !result.serviceType) return {}
  return result.serviceType === HotWaterServiceType.Repair ? repairRequirements(result) : replaceRequirements(result)
}

const replaceRequirements = (result: TopicHotWaterActionResult): TopicRequirementsResult => {
  const { existingHwsType, replacementHwsType } = result
  const isGasSystem = includes([HotWaterSystemType.Gas, HotWaterSystemType.GasInstant], existingHwsType)
  // Tristan request 28th of June 2024 to remove gas from priority list
  const priorityP1: PriorityRequirement | undefined = !isGasSystem ? { required: PriorityType.P1 } : undefined
  const priority = priorityP1

  switch (existingHwsType) {
    case HotWaterSystemType.Gas:
    case HotWaterSystemType.GasInstant:
      return { requirements: createRequirements([GAS_HOT_WATER_SKILL], priority) }
    case HotWaterSystemType.Other:
      return { requirements: createRequirements([COMMERCIAL_CUSTOMER_TYPE, GENERAL_PLUMBING_SKILL], priority) }
    default:
      switch (replacementHwsType) {
        case HotWaterSystemType.ElectricTank:
          return { requirements: createRequirements([GENERAL_HOT_WATER_SKILL], priority) }
        case HotWaterSystemType.Gas:
        case HotWaterSystemType.GasInstant:
          return { requirements: createRequirements([GAS_HOT_WATER_SKILL], priority) }
        case HotWaterSystemType.HeatPump:
          return { requirements: createRequirements([GENERAL_HOT_WATER_SKILL], priority) }
        case HotWaterSystemType.Solar:
          return { requirements: createRequirements([SOLAR_HOT_WATER_SKILL], priority) }
        default:
          return {}
      }
  }
}

const repairRequirements = (result: TopicHotWaterActionResult): TopicRequirementsResult => {
  const {
    repairIssue,
    existingHwsType,
    systemAge,
    boosterType,
    hasHotWaterWithBooster,
    tankTemperature,
    thermostatAge
  } = result
  if (!repairIssue) return {}
  const isThermoStatIssue = repairIssue === HotWaterRepairIssue.ElementOrThermostat
  if (isThermoStatIssue) {
    if (!thermostatAge) return {}
    switch (thermostatAge) {
      case HotWaterThermostatsAge.MoreThan8Years:
        return { requirements: createRequirements([GENERAL_PLUMBING_SKILL]) }
      case HotWaterThermostatsAge.LessThan8Years:
        return {
          trade: TradeType.Electrical,
          requirements: createRequirements([GENERAL_ELECTRICAL_SKILL])
        }
    }
  }
  const priority: PriorityRequirement | undefined = includes(PRIORITY_ISSUES, repairIssue)
    ? { required: PriorityType.P1 }
    : undefined
  switch (existingHwsType) {
    case HotWaterSystemType.ElectricTank:
      if (repairIssue === HotWaterRepairIssue.NoHotWaterAtTap) {
        if (!tankTemperature) return {}
        switch (tankTemperature) {
          case Temperature.Hot:
            return { requirements: createRequirements([GENERAL_HOT_WATER_SKILL], priority) }
          case Temperature.Cold:
          case Temperature.Unknown:
          default:
            if (!systemAge) return {}
            switch (systemAge) {
              case HotWaterSystemAge.Over8Years:
                return { requirements: createRequirements([GENERAL_HOT_WATER_SKILL], priority) }
              default:
                return {
                  requirements: createRequirements([GENERAL_ELECTRICAL_SKILL], priority),
                  trade: TradeType.Electrical
                }
            }
        }
      } else {
        return { requirements: createRequirements([GENERAL_HOT_WATER_SKILL], priority) }
      }

    case HotWaterSystemType.Gas:
    case HotWaterSystemType.GasInstant:
      return systemAge ? { requirements: createRequirements([GAS_HOT_WATER_SKILL]) } : {}

    case HotWaterSystemType.Solar:
      if (repairIssue === HotWaterRepairIssue.NoHotWaterAtTap) {
        switch (hasHotWaterWithBooster) {
          case YesNoValue.Yes:
            return { requirements: createRequirements([SOLAR_HOT_WATER_SKILL], priority) }
          case YesNoValue.No:
            return {
              requirements: createRequirements([GENERAL_ELECTRICAL_SKILL], priority),
              trade: TradeType.Electrical
            }
          default:
            return {}
        }
      } else {
        switch (boosterType) {
          case HotWaterBoosterType.Gas:
            return { requirements: createRequirements([SOLAR_HOT_WATER_SKILL, GAS_HOT_WATER_SKILL], priority) }
          case HotWaterBoosterType.Electric:
            return { requirements: createRequirements([SOLAR_HOT_WATER_SKILL], priority) }
          default:
            return {}
        }
      }

    case HotWaterSystemType.HeatPump:
      const hasHotWater = repairIssue !== HotWaterRepairIssue.NoHotWaterAtTap
      if (includes(HEAT_PUMP_HVAC_ISSUES, repairIssue)) {
        return { requirements: createRequirements([HVAC_HEAT_PUMP_SKILL], priority), trade: TradeType.HVAC }
      } else if (hasHotWater) {
        if (repairIssue === HotWaterRepairIssue.NotStayingHot) {
          if (!systemAge) {
            return {}
          }
          if (systemAge === HotWaterSystemAge.Over8Years) {
            return { requirements: createRequirements([HEAT_PUMP_HOT_WATER_SKILL], priority) }
          } else {
            return { requirements: createRequirements([HVAC_HEAT_PUMP_SKILL], priority), trade: TradeType.HVAC }
          }
        } else {
          return { requirements: createRequirements([HEAT_PUMP_HOT_WATER_SKILL], priority) }
        }
      } else if (!hasHotWater && tankTemperature === Temperature.Hot) {
        return { requirements: createRequirements([HEAT_PUMP_HOT_WATER_SKILL], priority) }
      } else if (!hasHotWater && includes([Temperature.Cold, Temperature.Unknown], tankTemperature)) {
        if (systemAge === HotWaterSystemAge.Over8Years) {
          return { requirements: createRequirements([HEAT_PUMP_HOT_WATER_SKILL], priority) }
        } else if (systemAge) {
          return { requirements: createRequirements([GENERAL_ELECTRICAL_SKILL], priority), trade: TradeType.Electrical }
        } else {
          return {}
        }
      } else {
        return {}
      }

    case HotWaterSystemType.Other:
      return { requirements: createRequirements([COMMERCIAL_CUSTOMER_TYPE, GENERAL_PLUMBING_SKILL], priority) }

    default:
      return {}
  }
}

const getQualification = (result: TopicHotWaterActionResult | undefined): TopicQualificationResult => {
  const { thermostatAge, repairIssue } = result ?? {}
  if (!repairIssue) return { qualification: TopicQualification.InScope }

  if (repairIssue === HotWaterRepairIssue.ElementOrThermostat) {
    if (!thermostatAge) return { qualification: TopicQualification.Unknown }
  }
  return { qualification: TopicQualification.InScope }
}

export const outcomeGenerator = (category: string) =>
  createOutcomeGenerator<TopicHotWaterActionResult>(
    {
      qualification: TopicQualification.Unknown,
      trade: TradeType.Plumbing,
      type: JobType.Service,
      category
    },
    getQualification,
    getRequirements
  )
