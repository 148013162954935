import '../static/css/summary-row.css'

export interface SummaryRowProps {
  label: string
  value: string
  bold?: boolean
  paddingRight?: string
  labelAlignment?: 'left' | 'right'
  tooltip?: string
  color?: string
}

export const SummaryRow = ({ label, value, bold, paddingRight, labelAlignment, tooltip, color }: SummaryRowProps) => {
  return (
    <div
      className={`summary__row ${labelAlignment === 'left' ? 'summary__row--align-left' : ''} ${
        bold === true ? 'summary__row--bold' : ''
      }`}
    >
      <div className={`summary__row__label `} {...(color && { style: { color } })} {...(tooltip && { title: tooltip })}>
        {label}
      </div>
      <div className="summary__row__value" style={{ paddingRight: paddingRight ?? '24px', ...(color && { color }) }}>
        {value}
      </div>
    </div>
  )
}
