import { EuiSpacer } from '@elastic/eui'
import { Callout } from '../../common/callout'
import { PillGroup, PillGroupOption } from '../../pill/pill-group'
import { WorkflowAction } from '../workflow-action'
import { BaseActionInput, BaseActionResult, WorkflowActionProps } from '../workflow-model'

export type FirstCallComplaintResolutionActionInput = BaseActionInput

export interface FirstCallComplaintResolutionActionResult extends BaseActionResult {
  firstCallResolutionWorked?: boolean
}

export const FirstCallComplaintResolutionAction = (
  props: WorkflowActionProps<FirstCallComplaintResolutionActionInput, FirstCallComplaintResolutionActionResult>
) => {
  const { onUpdate, result } = props

  const completed = result?.firstCallResolutionWorked !== undefined

  const solutionOptions: PillGroupOption[] = [
    { id: 'true', label: "I've managed to solve the problem", icon: 'frameNext' },
    { id: 'false', label: 'I could not fix the problem', icon: 'pause' }
  ]

  const handleOnSelectOption = (id: string) => {
    onUpdate({ firstCallResolutionWorked: id === 'true' })
  }

  const handleChange = () => {
    onUpdate({ firstCallResolutionWorked: undefined })
  }

  const firstResolutionCallWorkedOption = completed ? `${result?.firstCallResolutionWorked}` : undefined
  const optionLabel = solutionOptions.filter((o) => o.id === firstResolutionCallWorkedOption)?.[0]?.label

  return (
    <WorkflowAction title="First Call Resolution" value={optionLabel} onClickChange={handleChange}>
      <Callout type="script" title="Offer first call resolution">
        Thank you for relaying that information to me. What I can do is: (offer first call resolution).
      </Callout>
      <EuiSpacer />
      <PillGroup
        options={solutionOptions}
        value={firstResolutionCallWorkedOption}
        onSelect={handleOnSelectOption}
        onClear={handleChange}
      />
    </WorkflowAction>
  )
}
