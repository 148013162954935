import { EuiProgress } from '@elastic/eui'
import { MediaItemFragment, MediaStatus } from '../api/generated-types'
import { useApp } from '../app/app-context'
import { ImageView } from '../common/image-view'
import { useMediaService } from '../media/media-service'
import { MediaServiceUploadStatus } from '../media/media-service-upload'

export type PhotoCardProps = {
  media: MediaItemFragment
  width?: number
  height?: number
  showCaption?: boolean
}

const PhotoCard = (props: PhotoCardProps) => {
  const mediaService = useMediaService()
  const appContext = useApp()

  const media = props?.media

  const width = props.width
  const height = props.height
  const showCaption = props.showCaption ?? false
  const caption = showCaption ? media.description ?? media.name ?? undefined : undefined

  const url =
    (!!media.object || !!media.url) && media.status !== MediaStatus.WaitingForUpload
      ? media.url
        ? media.url
        : `https://${appContext.mediaDomain}/${media?.object?.key}`
      : undefined

  const upload = mediaService.getUpload(media.id)

  const uploadFile = upload?.file
  const uploadProgress = upload?.progress ?? 1.0
  const isUploading = upload?.status === MediaServiceUploadStatus.Uploading

  return (
    <div style={{ position: 'relative', height: '100%' }}>
      {isUploading && (
        <div
          style={{
            position: 'absolute',
            top: '50%',
            marginTop: '-3px',
            left: '6px',
            right: '6px'
          }}
        >
          <EuiProgress size="m" value={uploadProgress} max={1.0} />
        </div>
      )}
      <ImageView
        mediaItem={media}
        src={url}
        file={uploadFile}
        loading={isUploading}
        width={width}
        height={height}
        caption={caption}
        author={media?.author ?? undefined}
        captureDate={media?.date ?? undefined}
        uploadDate={media?.created ?? undefined}
        dimensions={{ width: media.metadata?.width, height: media.metadata?.height }}
        position={media.location?.position ?? undefined}
        mediaItemId={media.id}
      />
    </div>
  )
}

export default PhotoCard
