import {
  EuiBasicTableColumn,
  EuiButtonIcon,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHealth,
  EuiIcon,
  EuiPopover,
  EuiToolTip
} from '@elastic/eui'
import { dateConfig } from '@fallonsolutions/date'
import { DateTime } from 'luxon'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  CampaignLinkFragment,
  CustomerFragment,
  DispositionLinkFragment,
  EnquiryLinkFragment,
  InteractionAnswerStatus,
  InteractionAssignmentFragment,
  InteractionDirection,
  InteractionMessageFragment,
  InteractionMessageStatusType,
  InteractionRecordingFragment,
  PhoneNumberLinkFragment
} from '../../api/generated-types'
import { useAuthenticated } from '../../auth/authenticated-context'
import EuiCustomLink from '../../common/eui-custom-link'
import { IdenticonAvatar } from '../../common/identicon-avatar'
import { PercentageBar } from '../../common/percentage-bar'
import { formatPhone } from '../../common/phone'
import { timeFormatterSeconds } from '../../common/time'
import { decamelise } from '../../common/utils'
import { CustomerPreview } from '../../customers/customer-preview'
import { JobPreview } from '../../jobs/job-preview'
import { CampaignLink } from '../../marketing/campaign/campaign-link'
import UserLink from '../../users/user-link'
import { InteractionMessageStatusBadge } from '../interaction-message-status-badge'
import { InteractionRecordingsPreview } from '../interaction-recording-preview'

interface InteractionMessageListPreview {
  rowId: string
  field: PreviewField
  targetId?: string // default to rowId but useful for multiple jobs in one row etc
}

enum PreviewField {
  Job = 'job',
  Customer = 'customer',
  Recording = 'recording'
}

export interface InteractionMessageListColumnsProps {
  onClickAssignToSelf?: (interactionMessage: InteractionMessageFragment) => void
}

export const InteractionMessageListColumns = ({
  onClickAssignToSelf
}: InteractionMessageListColumnsProps): Array<EuiBasicTableColumn<InteractionMessageFragment>> => {
  const userFragment = useAuthenticated().userFragment
  const canViewInteractionRecording = userFragment.permissions?.viewInteractionRecording === true

  const history = useHistory()
  const [preview, setPreview] = useState<InteractionMessageListPreview | undefined>(undefined)

  return [
    {
      field: 'customer',
      name: 'Contact',
      render: (customer: CustomerFragment, interactionMessage: InteractionMessageFragment) => {
        let element: any = undefined
        if (customer) {
          element = (
            <EuiPopover
              isOpen={preview?.rowId === interactionMessage.id && preview.field === PreviewField.Customer}
              button={
                <span
                  onClick={() => setPreview({ rowId: interactionMessage.id, field: PreviewField.Customer })}
                  style={{ borderBottom: '1px dotted #ACAFB9', cursor: 'pointer' }}
                >
                  {interactionMessage.contact?.fullName ||
                    interactionMessage?.customer?.mainContact?.detail?.fullName ||
                    'Name missing'}
                </span>
              }
              closePopover={() => setPreview(undefined)}
              panelPaddingSize="none"
              anchorPosition="downLeft"
            >
              <CustomerPreview customerId={customer.id} />
            </EuiPopover>
          )
        } else if (interactionMessage.contact?.fullName) {
          element = interactionMessage.contact.fullName
        } else {
          element = <span style={{ color: '#727888' }}>Unknown</span>
        }
        return (
          <EuiFlexGroup gutterSize="m" alignItems="center">
            <EuiFlexItem grow={false}>
              <IdenticonAvatar
                username={
                  interactionMessage.contact?.fullName ??
                  interactionMessage.contact?.phone ??
                  interactionMessage.externalNumber ??
                  interactionMessage.reference
                }
                width="24"
                height="24"
              />
            </EuiFlexItem>
            <EuiFlexItem grow={false}>{element}</EuiFlexItem>
          </EuiFlexGroup>
        )
      }
    },
    {
      field: 'externalNumber',
      name: 'External number',
      width: '130px',
      render: (externalNumber: string | undefined) => {
        return externalNumber ? formatPhone(externalNumber) : 'Private number'
      }
    },
    {
      field: 'direction',
      name: <EuiIcon type="merge" color="subdued" size="m" />,
      width: '40px',
      render: (direction: InteractionDirection) => {
        const isInbound = direction === InteractionDirection.Inbound
        return (
          <EuiIcon
            type={isInbound ? 'sortRight' : 'sortLeft'}
            color={isInbound ? '#23A042' : '#E96236'}
            size="m"
            title={direction}
          />
        )
      }
    },
    {
      field: 'status.status',
      name: 'Status',
      width: '110px',
      render: (status?: InteractionMessageStatusType) =>
        status ? <InteractionMessageStatusBadge status={status} /> : ''
    },
    // {
    //   field: 'type',
    //   name: 'Type',
    //   width: '120px',
    //   render: (type: InteractionMessageType) => decamelise(type)
    // },
    {
      field: 'assignment',
      name: 'Assignment',
      render: (assignment: InteractionAssignmentFragment | undefined) => {
        const user = assignment?.user
        return user ? <UserLink user={user} /> : ''
      }
    },
    {
      field: 'phoneNumber',
      name: 'Phone number',
      width: '140px',
      render: (phoneNumber: PhoneNumberLinkFragment | undefined) =>
        phoneNumber ? (
          <EuiCustomLink to={`/marketing/numbers/${phoneNumber.id}`}>{formatPhone(phoneNumber.number)}</EuiCustomLink>
        ) : (
          ''
        )
    },
    {
      field: 'campaign',
      name: 'Campaign',
      render: (campaign: CampaignLinkFragment | undefined) => (campaign ? <CampaignLink campaign={campaign} /> : '')
    },
    {
      field: 'enquiries',
      name: 'Enquiries',
      width: '260px',
      render: (enquiries: EnquiryLinkFragment[] | undefined, interactionMessage: InteractionMessageFragment) => {
        return (
          <EuiFlexGroup direction="column" gutterSize="none">
            {enquiries?.map((enquiry) => (
              <EuiFlexItem key={enquiry.id}>
                <EuiFlexGroup gutterSize="none">
                  <EuiFlexItem grow={false} className="truncate" style={{ width: '140px' }}>
                    {decamelise(enquiry.type)}
                  </EuiFlexItem>
                  <EuiFlexItem grow={false} style={{ width: '120px' }}>
                    {enquiry.job ? (
                      <EuiPopover
                        isOpen={
                          preview?.rowId === interactionMessage.id &&
                          preview.field === PreviewField.Job &&
                          preview.targetId === enquiry.job.id
                        }
                        button={
                          <span
                            onClick={() =>
                              setPreview({
                                rowId: interactionMessage.id,
                                field: PreviewField.Job,
                                targetId: enquiry.job?.id
                              })
                            }
                            style={{ borderBottom: '1px dotted #ACAFB9', cursor: 'pointer' }}
                          >
                            {enquiry.job.number}
                          </span>
                        }
                        closePopover={() => setPreview(undefined)}
                        panelPaddingSize="none"
                        anchorPosition="downLeft"
                      >
                        <JobPreview jobId={enquiry.job.id} jobNumber={enquiry.job.number} />
                      </EuiPopover>
                    ) : enquiry.aborted?.reason ? (
                      <>{decamelise(enquiry.aborted.reason)}</>
                    ) : (
                      <></>
                    )}
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiFlexItem>
            ))}
          </EuiFlexGroup>
        )
      }
    },
    {
      field: 'dispositions',
      name: 'Disposition(s)',
      className: 'truncate',
      render: (dispositions: DispositionLinkFragment[], interactionMessage) => {
        return (
          <EuiToolTip content={<p>{interactionMessage.dispositionNotes}</p>}>
            <EuiFlexGroup direction="column" gutterSize="none">
              {(dispositions ?? []).map((disposition) => (
                <EuiFlexItem grow={false} key={disposition.id}>
                  {disposition.label}
                </EuiFlexItem>
              ))}
            </EuiFlexGroup>
          </EuiToolTip>
        )
      }
    },
    {
      field: 'answerStatus',
      name: 'Answer status',
      width: '160px',
      render: (answerStatus: InteractionAnswerStatus) => renderAnswerStatus(answerStatus)
    },
    {
      field: 'stats.talkDuration',
      name: 'Talk duration',
      width: '120px',
      align: 'left',
      render: (talkDuration?: number) => {
        if (!talkDuration) {
          return ''
        }
        const longCallDuration = 8 * 60 // 8 minutes
        const percentage = Math.round((talkDuration / longCallDuration) * 100)
        const color = '#EAFDEF' //'rgba(0, 255, 209, 0.15)'
        return <PercentageBar percentage={percentage} label={timeFormatterSeconds(talkDuration)} color={color} />
      }
    },
    {
      field: 'recordings',
      name: <EuiIcon type="playFilled" size="s" color={canViewInteractionRecording ? 'text' : 'subdued'} />,
      width: '60px',
      align: 'center',
      render: (
        recordings: InteractionRecordingFragment[] | undefined,
        interactionMessage: InteractionMessageFragment
      ) => {
        return recordings?.length ? (
          <EuiPopover
            isOpen={preview?.rowId === interactionMessage.id && preview.field === PreviewField.Recording}
            button={
              <EuiButtonIcon
                iconType="playFilled"
                size="xs"
                iconSize="s"
                display="base"
                onClick={() => setPreview({ rowId: interactionMessage.id, field: PreviewField.Recording })}
                color="text"
                disabled={!canViewInteractionRecording}
                title={
                  canViewInteractionRecording ? `View recordings` : 'You do not have permission to play recordings'
                }
              />
            }
            closePopover={() => setPreview(undefined)}
            panelPaddingSize="none"
            anchorPosition="downLeft"
          >
            <InteractionRecordingsPreview interactionMessageId={interactionMessage.id} firstAudio autoplay />
          </EuiPopover>
        ) : (
          ''
        )
      }
    },
    {
      field: 'startDate',
      name: 'Start',
      width: '80px',
      render: (startDate?: string) => {
        return startDate
          ? DateTime.fromISO(startDate, { zone: dateConfig.defaultTimezone })
              .toFormat(dateConfig.luxonFormat.time)
              .toLowerCase()
          : ''
      }
    },
    {
      field: 'endDate',
      name: 'End',
      width: '80px',
      render: (endDate?: string) => {
        return endDate
          ? DateTime.fromISO(endDate, { zone: dateConfig.defaultTimezone })
              .toFormat(dateConfig.luxonFormat.time)
              .toLowerCase()
          : ''
      }
    },
    {
      field: 'startDate',
      name: 'Date',
      width: '150px',
      render: (startDate?: string) =>
        startDate ? DateTime.fromISO(startDate).toFormat(dateConfig.luxonFormat.fullDate) : ''
    },
    {
      name: '',
      width: '40px',
      actions: [
        // {
        //   available: (interactionMessage) =>
        //     canAssignInteractionMessage &&
        //     interactionMessage.status.status !== InteractionMessageStatusType.Complete &&
        //     !!onClickAssignToSelf,
        //   name: 'Assign',
        //   description: 'Assign to self',
        //   icon: 'frameNext',
        //   type: 'icon',
        //   onClick: (interactionMessage) => onClickAssignToSelf && onClickAssignToSelf(interactionMessage)
        // },
        {
          available: () => true,
          name: 'View interaction',
          description: 'View interaction',
          icon: 'popout',
          type: 'icon',
          href: (interactionMessage) => `/interaction-message/${interactionMessage.id}`
        }
      ]
    }
  ]
}

const renderAnswerStatus = (answerStatus: InteractionAnswerStatus) => {
  const color = colorForInteractionAnswerStatus(answerStatus)
  return <EuiHealth color={color}>{decamelise(answerStatus)}</EuiHealth>
}

export const colorForInteractionAnswerStatus = (answerStatus: InteractionAnswerStatus) => {
  switch (answerStatus) {
    case InteractionAnswerStatus.Answered:
      return '#9AD5A1' // green
    case InteractionAnswerStatus.Missed:
      return '#D76035' // dark red
    case InteractionAnswerStatus.Abandoned:
      return '#F19A77' // medium red
    case InteractionAnswerStatus.ShortAbandoned:
      return '#FCF083' // yellow
    case InteractionAnswerStatus.AfterHours:
      return '#B2BAC9' // medium grey
    case InteractionAnswerStatus.Overflow:
      return '#C990EA' // purple
  }
}
