import { EuiFlexGroup, EuiFlexItem, EuiHorizontalRule } from '@elastic/eui'
import { MoneyUtils } from '@fallonsolutions/money'
import Dinero from 'dinero.js'
import { Currency, InvoiceFragment, InvoiceSource, InvoiceType } from '../api/generated-types'
import { useAuthenticated } from '../auth/authenticated-context'
import { CreatePaymentButtonWrapper } from '../payments/create-payment-button'
import '../static/css/invoice.css'
import { CreateCreditNoteButton } from './create-credit-note-button'
import { CreateCustomerRefundButton } from './create-customer-refund-button'

interface InvoiceActionsProps {
  invoice: InvoiceFragment
}

export const InvoiceActions = (props: InvoiceActionsProps) => {
  const { invoice } = props

  const { userFragment } = useAuthenticated()

  const zero = Dinero({ amount: 0, currency: Currency.AUD })
  const paid = MoneyUtils.fromString(invoice?.paid?.total?.amount ?? '0.00')
  const refund = MoneyUtils.fromString(invoice?.refund?.total?.amount ?? '0.00')
  const balance = MoneyUtils.fromString(invoice.balance?.amount ?? '0.0')

  const hasOutstandingBalance = balance.greaterThan(zero)

  const hasRefundableAmount = paid.subtract(refund).greaterThan(zero)
  const platformInvoicingWeb = userFragment.permissions?.platformInvoicingWeb === true
  const createCreditNote = userFragment.permissions?.creditNote === true
  const createCustomerRefund = userFragment.permissions?.customerRefund === true

  if (invoice.type === InvoiceType.Invoice && invoice.source === InvoiceSource.Platform) {
    return (
      <div style={{ marginBottom: '-12px' }}>
        <EuiHorizontalRule />
        <EuiFlexGroup gutterSize="s" justifyContent="spaceAround">
          <EuiFlexItem grow={false}>
            {platformInvoicingWeb === true && hasOutstandingBalance && <CreatePaymentButtonWrapper invoice={invoice} />}
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            {createCreditNote === true && hasOutstandingBalance && <CreateCreditNoteButton invoice={invoice} />}
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            {createCustomerRefund === true && hasRefundableAmount && <CreateCustomerRefundButton invoice={invoice} />}
          </EuiFlexItem>
        </EuiFlexGroup>
      </div>
    )
  } else {
    return <div></div>
  }
}
