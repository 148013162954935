import { FreeTextQuestionField } from '../question-fields/free-text-question-field'

export interface SecurityBrandQuestionProps {
  question: string
  questionAnswer?: string
  updateQuestion: (question?: string) => void
}
export const SecurityBrandQuestion = (props: SecurityBrandQuestionProps) => {
  const { question, questionAnswer, updateQuestion } = props
  return (
    <>
      <FreeTextQuestionField question={question} answer={questionAnswer} changeAnswer={updateQuestion} />
    </>
  )
}
