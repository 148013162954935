import { useMutation } from '@apollo/client'
import {
  EuiButton,
  EuiButtonEmpty,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiFormRow,
  EuiLoadingSpinner,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiTextArea
} from '@elastic/eui'

import { useQuery } from '@apollo/client'
import { useEffect, useState } from 'react'
import {
  AuditCheckResultInput,
  AuditCheckResultType,
  CreateAuditDocument,
  GetAuditTemplateDocument
} from '../api/generated-types'
import { AuditCheckResult, AuditCheckResultSize } from './audit-check-result'

export interface CreateAuditFormProps {
  auditTemplateId: string
  jobId?: string
  onClose: () => void
}

export const CreateAuditForm = ({ auditTemplateId, jobId, onClose }: CreateAuditFormProps) => {
  const { data: templateData, loading: templateLoading } = useQuery(GetAuditTemplateDocument, {
    variables: { input: { id: auditTemplateId } }
  })

  const template = templateData?.getAuditTemplate.auditTemplate

  const [createAudit, { data: createData, loading: createLoading }] = useMutation(CreateAuditDocument, {
    refetchQueries: ['GetJob', 'GetJobActivity'],
    awaitRefetchQueries: true
  })

  // State
  const [checks, setChecks] = useState<AuditCheckResultInput[]>([])
  const [comment, setComment] = useState('')
  const [error, setError] = useState<Error | undefined>(undefined)

  useEffect(() => {
    if (template) {
      setChecks(
        template.checkGroups.flatMap((checkGroup) =>
          checkGroup.checks.map((c) => ({ reference: c.reference, result: AuditCheckResultType.None }))
        )
      )
    }
  }, [template?.checkGroups])

  const handleSubmit = async () => {
    if (!comment || comment.length === 0) {
      setError(new Error('Please enter a comment'))
      return
    }
    const incompleteChecks = checks.filter((check) => check.result === AuditCheckResultType.None)
    if (incompleteChecks.length > 0) {
      setError(new Error('Please complete all quality checks'))
      return
    }
    await createAudit({
      variables: {
        input: {
          templateId: auditTemplateId,
          jobId,
          checks,
          comment
        }
      }
    })
  }

  useEffect(() => {
    if (createData?.createAudit.audit?.id) {
      onClose()
    }
  }, [createData])

  const onChangeCheck = (reference: string, result: AuditCheckResultType) => {
    setChecks((checks) =>
      checks.map((c) => {
        if (c.reference === reference) {
          return { ...c, result }
        }
        return c
      })
    )
  }

  const resultOptions = [
    AuditCheckResultType.NotApplicable,
    AuditCheckResultType.Fail,
    AuditCheckResultType.Pass,
    AuditCheckResultType.Good
  ]

  return (
    <>
      <EuiModal onClose={onClose}>
        {templateLoading ? (
          <>
            <EuiModalHeader>
              <EuiModalHeaderTitle>
                <EuiLoadingSpinner />
              </EuiModalHeaderTitle>
            </EuiModalHeader>
            <EuiModalBody style={{ maxWidth: '440px' }}></EuiModalBody>
          </>
        ) : template ? (
          <>
            <EuiModalHeader>
              <EuiModalHeaderTitle>{template.name}</EuiModalHeaderTitle>
            </EuiModalHeader>
            <EuiModalBody style={{ maxWidth: '440px' }}>
              <EuiForm>
                {template.checkGroups.map((checkGroup) => (
                  <div key={checkGroup.reference}>
                    {checkGroup.checks.map((check) => (
                      <EuiFormRow
                        key={check.reference}
                        label={check.name}
                        hasChildLabel={false}
                        helpText={check.description}
                        style={{ marginBottom: '24px' }}
                      >
                        <EuiFlexGroup gutterSize="s">
                          {resultOptions.map((result) => (
                            <EuiFlexItem key={result} grow={false}>
                              <AuditCheckResult
                                size={AuditCheckResultSize.Medium}
                                result={result}
                                selected={checks.find((c) => c.reference === check.reference)?.result === result}
                                editable={true}
                                onClick={() => onChangeCheck(check.reference, result)}
                              />
                            </EuiFlexItem>
                          ))}
                        </EuiFlexGroup>
                      </EuiFormRow>
                    ))}
                  </div>
                ))}

                <EuiFormRow
                  label="Review notes (required)"
                  hasChildLabel={false}
                  isInvalid={!!error}
                  error={error ? error.message : undefined}
                  helpText={
                    <>
                      Comments about this job or a simple statement that you have reviewed the job. This will be visible
                      to other users.
                    </>
                  }
                >
                  <EuiTextArea rows={3} value={comment} onChange={(value) => setComment(value.target.value)} />
                </EuiFormRow>
              </EuiForm>
            </EuiModalBody>

            <EuiModalFooter>
              <EuiFlexGroup direction="row" gutterSize="l">
                <EuiFlexItem grow={true} />
                <EuiFlexItem grow={false}>
                  <EuiButtonEmpty onClick={onClose} disabled={createLoading}>
                    Cancel
                  </EuiButtonEmpty>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <EuiButton fill onClick={handleSubmit} isLoading={createLoading}>
                    Submit
                  </EuiButton>
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiModalFooter>
          </>
        ) : null}
      </EuiModal>
    </>
  )
}
