import { ScheduledServiceStatusType } from '../api/generated-types'
import '../static/css/scheduled-service-status.css'

export interface ScheduledServiceStatusBadgeProps {
  status: ScheduledServiceStatusType
  title?: string
  size?: 'xs' | 'm'
}

export const ScheduledServiceStatusBadge = ({ status, title, ...props }: ScheduledServiceStatusBadgeProps) => {
  const size = props.size ?? 'm'
  return (
    <span
      className={`scheduled-service-status-badge scheduled-service-status-badge--${status.toLowerCase()} scheduled-service-status-badge--${size}`}
      title={title}
    >
      {size === 'm' ? status : ''}
    </span>
  )
}
