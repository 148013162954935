import { EuiContextMenuPanelDescriptor, EuiContextMenuPanelItemDescriptor, EuiToken } from '@elastic/eui'
import { omit, values } from 'lodash-es'
import {
  JobType,
  ScheduleEventFragment,
  UpdateAppointmentJobClassificationMutationVariables,
  UpdateJobAppointmentRule,
  UpdateJobClassificationMutationVariables
} from '../../api/generated-types'
import { iconForJobType } from '../../jobs/job-category-selector'

export interface ScheduleEventContextMenuJobTypePanelProps {
  event: ScheduleEventFragment
  updateAppointmentJobClassification: (params: {
    variables: UpdateAppointmentJobClassificationMutationVariables
  }) => void
  updateJobClassification: (params: { variables: UpdateJobClassificationMutationVariables }) => void
  onActionClick?: (() => void) | undefined
}

export const ScheduleEventContextMenuJobTypePanel = ({
  event,
  updateAppointmentJobClassification,
  updateJobClassification,
  onActionClick
}: ScheduleEventContextMenuJobTypePanelProps): EuiContextMenuPanelDescriptor => {
  const jobTypeOptions = values(omit(JobType, JobType.None))

  const getJobTypeMenuItem = (jobType: JobType): EuiContextMenuPanelItemDescriptor => {
    return {
      name: jobType,
      icon: getJobTypeIcon(jobType),
      onClick: () => {
        if (!event.appointmentId || !event.job?.id) {
          return
        }
        const jobAppointmentCount = event.jobAppointmentCount ?? 1
        if (jobAppointmentCount > 1) {
          updateAppointmentJobClassification({
            variables: {
              input: {
                id: event.appointmentId,
                jobType: jobType
              }
            }
          })
        } else {
          updateJobClassification({
            variables: {
              input: {
                job: event.job.id,
                appointmentUpdateRule: UpdateJobAppointmentRule.AllAppointments,
                type: jobType
              }
            }
          })
        }
        onActionClick && onActionClick()
      }
    }
  }

  return {
    id: 'change-job-type',
    size: 's',
    title: 'Change job type',
    items: jobTypeOptions.map((jobType) => getJobTypeMenuItem(jobType))
  }
}

export const getJobTypeIcon = (jobType?: JobType) => {
  return <EuiToken iconType={iconForJobType(jobType)} color={getJobTypeColor(jobType)} size="s" fill="dark" />
}

export const getJobTypeColor = (jobType?: JobType): string => {
  switch (jobType) {
    case JobType.Callback:
      return '#CDA6F2'
    case JobType.Inspection:
      return '#FFE587'
    case JobType.Quote:
      return '#A2E398'
    case JobType.Warranty:
      return '#FEA184'
    case JobType.Parts:
      return '#8DE3D9'
    case JobType.Project:
      return '#8CB0F5'
    case JobType.Maintenance:
      return '#83d5eb'
    case JobType.Service:
    default:
      return '#FFB82F'
  }
}
