import { JobTopicType } from '@fallonsolutions/types'
import { first } from 'lodash-es'
import { ContactFragment, MembershipLevel, MembershipStatusType } from '../api/generated-types'
import { CancelJobAction, CancelJobActionResult } from './actions/action-cancel-job'
import { ComplaintAction, ComplaintActionResult } from './actions/action-complaint'
import { ConfirmJobAction, ConfirmJobActionResult } from './actions/action-confirm-job'
import { ContactActionResult } from './actions/action-contact'
import { CrisisAction, CrisisActionResult } from './actions/action-crisis'
import { CustomerActionResult } from './actions/action-customer'
import { CustomerPropertyActionResult } from './actions/action-customer-property'
import { DisputeInvoiceAction } from './actions/action-dispute-invoice'
import { EmploymentAction, EmploymentActionResult } from './actions/action-employment'
import { EnquiryTypeActionResult } from './actions/action-enquiry-type'
import { ETAAction, ETAActionResult } from './actions/action-eta'
import { FeedbackAction, FeedbackActionResult } from './actions/action-feedback'
import { MediaAction } from './actions/action-media'
import { MembershipAction, MembershipActionResult } from './actions/action-membership'
import { NewJobAction, NewJobActionResult } from './actions/action-new-job'
import { OtherAction } from './actions/action-other'
import { PayInvoiceAction, PayInvoiceActionResult } from './actions/action-pay-invoice'
import { RebookingAction, RebookingActionResult } from './actions/action-rebooking'
import { ResendInvoiceAction, ResendInvoiceActionResult } from './actions/action-resend-invoice'
import { TelemarketingAction } from './actions/action-telemarketing'
import { TransferCallAction, TransferCallActionResult } from './actions/action-transfer-call'
import { UpdateCustomerAction, UpdateCustomerActionResult } from './actions/action-update-customer'
import { UpdateJobAction, UpdateJobActionResult } from './actions/action-update-job'
import { EnquiryAbortActionResult, EnquiryTypeReference } from './workflow-model'

export interface NewEnquiryResult {
  abort?: EnquiryAbortActionResult
  cancelJob?: CancelJobActionResult
  complaint?: ComplaintActionResult
  confirmJob?: ConfirmJobActionResult
  contact?: ContactActionResult
  crisis?: CrisisActionResult
  customer?: CustomerActionResult
  employment?: EmploymentActionResult
  enquiryType?: EnquiryTypeActionResult
  eta?: ETAActionResult
  feedback?: FeedbackActionResult
  membership?: MembershipActionResult
  newBooking?: NewJobActionResult
  payInvoice?: PayInvoiceActionResult
  property?: CustomerPropertyActionResult
  rebooking?: RebookingActionResult
  resendInvoice?: ResendInvoiceActionResult
  transferCall?: TransferCallActionResult
  updateCustomer?: UpdateCustomerActionResult
  updateJob?: UpdateJobActionResult
}

export interface ActionForEnquiryTypeProps {
  handleActionUpdate: any
  result: NewEnquiryResult
  enquiryType: EnquiryTypeReference
  goBack: any
  completeEnquiry: () => void
  updateContainerCustomer?: (customerId: string) => void
  updateEnquiryType?: (enquiryType: EnquiryTypeActionResult) => void
}

export const ActionForEnquiryType = ({
  result,
  enquiryType,
  handleActionUpdate,
  goBack,
  completeEnquiry,
  updateContainerCustomer,
  updateEnquiryType
}: ActionForEnquiryTypeProps) => {
  switch (enquiryType) {
    case EnquiryTypeReference.CancelJob:
      return (
        <CancelJobAction
          input={{ contact: result.contact, updateEnquiryType }}
          result={result.cancelJob ?? { customer: result.customer }}
          onUpdate={(r) => handleActionUpdate('cancelJob', r)}
          onBack={() => goBack(EnquiryTypeReference.CancelJob)}
        />
      )
    case EnquiryTypeReference.Crisis:
      return (
        <CrisisAction
          input={{ contact: result.contact }}
          result={result.crisis ?? { customer: result.customer }}
          onUpdate={(r) => handleActionUpdate('crisis', r)}
          onBack={() => goBack(EnquiryTypeReference.Crisis)}
        />
      )
    case EnquiryTypeReference.Complaint:
      return (
        <ComplaintAction
          input={{}}
          result={result.complaint ?? { customer: result.customer }}
          onUpdate={(r) => handleActionUpdate('complaint', r)}
          onBack={() => goBack(EnquiryTypeReference.Complaint)}
        />
      )
    case EnquiryTypeReference.ConfirmJob:
      return (
        <ConfirmJobAction
          input={{ contact: result.contact }}
          result={result.confirmJob ?? { customer: result.customer }}
          onUpdate={(r) => handleActionUpdate('confirmJob', r)}
          onBack={() => goBack(EnquiryTypeReference.ConfirmJob)}
        />
      )
    case EnquiryTypeReference.DisputeInvoice:
      return (
        <DisputeInvoiceAction
          input={{ firstName: result.contact?.firstName }}
          onUpdate={completeEnquiry}
          onBack={() => goBack(EnquiryTypeReference.DisputeInvoice)}
        />
      )
    case EnquiryTypeReference.Employment:
      return (
        <EmploymentAction input={{}} result={result.employment} onUpdate={(r) => handleActionUpdate('employment', r)} />
      )
    case EnquiryTypeReference.ETA:
      return (
        <ETAAction
          input={{ contact: result.contact }}
          result={result.eta ?? { customer: result.customer }}
          onUpdate={(r) => handleActionUpdate('eta', r)}
          onBack={() => goBack(EnquiryTypeReference.ETA)}
        />
      )
    case EnquiryTypeReference.Feedback:
      return (
        <FeedbackAction
          input={{ contact: result.contact }}
          result={result.feedback ?? { customer: result.customer }}
          onUpdate={(r) => handleActionUpdate('feedback', r)}
          onBack={() => goBack(EnquiryTypeReference.Feedback)}
        />
      )
    case EnquiryTypeReference.Media:
      return <MediaAction input={{}} onUpdate={completeEnquiry} onBack={() => goBack(EnquiryTypeReference.Media)} />
    case EnquiryTypeReference.Membership:
      return (
        <MembershipAction
          input={{ contact: result.contact }}
          result={result.membership}
          onUpdate={(r) => handleActionUpdate('membership', r)}
          onBack={() => goBack(EnquiryTypeReference.Membership)}
        />
      )
    case EnquiryTypeReference.NewBooking:
      return (
        <NewJobAction
          input={{ contact: result.contact, customer: result.customer, updateContainerCustomer }}
          result={result.newBooking}
          onUpdate={(r) => handleActionUpdate('newBooking', r)}
          onBack={() => goBack(EnquiryTypeReference.NewBooking)}
        />
      )
    case EnquiryTypeReference.Other:
      return <OtherAction input={{}} onUpdate={completeEnquiry} onBack={() => goBack(EnquiryTypeReference.Other)} />
    case EnquiryTypeReference.PayInvoice:
      return (
        <PayInvoiceAction
          input={{}}
          result={result.payInvoice ?? { customer: result.customer }}
          onUpdate={(r) => handleActionUpdate('payInvoice', r)}
          onBack={() => goBack(EnquiryTypeReference.PayInvoice)}
        />
      )
    case EnquiryTypeReference.Rebooking:
      return (
        <RebookingAction
          input={{ contact: result.contact }}
          result={result.rebooking ?? { customer: result.customer }}
          onUpdate={(r) => handleActionUpdate('rebooking', r)}
          onBack={() => goBack(EnquiryTypeReference.Rebooking)}
        />
      )
    case EnquiryTypeReference.ResendInvoice:
      return (
        <ResendInvoiceAction
          input={{ contact: result.contact }}
          result={result.resendInvoice ?? { customer: result.customer }}
          onUpdate={(r) => handleActionUpdate('resendInvoice', r)}
          onBack={() => goBack(EnquiryTypeReference.ResendInvoice)}
        />
      )
    case EnquiryTypeReference.Telemarketing:
      return (
        <TelemarketingAction
          input={{}}
          onUpdate={completeEnquiry}
          onBack={() => goBack(EnquiryTypeReference.Telemarketing)}
        />
      )
    case EnquiryTypeReference.Transfer:
      return (
        <TransferCallAction
          input={{ contact: result.contact }}
          result={result.transferCall ?? { customer: result.customer }}
          onUpdate={(r) => handleActionUpdate('transferCall', r)}
          onBack={() => goBack(EnquiryTypeReference.Transfer)}
        />
      )
    case EnquiryTypeReference.UpdateCustomer:
      return (
        <UpdateCustomerAction
          input={{ contact: result.contact }}
          result={result.updateCustomer ?? { customer: result.customer }}
          onUpdate={(r) => handleActionUpdate('updateCustomer', r)}
          onBack={() => goBack(EnquiryTypeReference.UpdateCustomer)}
        />
      )
    case EnquiryTypeReference.UpdateJob:
      return (
        <UpdateJobAction
          input={{ contact: result.contact }}
          result={result.updateJob ?? { customer: result.customer }}
          onUpdate={(r) => handleActionUpdate('updateJob', r)}
          onBack={() => goBack(EnquiryTypeReference.UpdateJob)}
        />
      )

    default:
      return <></>
  }
}
interface WorkflowNewEnquiryFields {
  enquiryTypeReference?: EnquiryTypeReference | undefined
  jobId?: string | undefined
  contactId?: string | undefined
  customerId?: string | undefined
  contact?: ContactFragment | undefined
  topicReference?: JobTopicType | undefined
  membershipStatus?: MembershipStatusType | undefined
  membershipLevel?: MembershipLevel

  //memberStatus: string | undefined
}

export const getWorkflowFields = (current: NewEnquiryResult): WorkflowNewEnquiryFields => {
  const fields: WorkflowNewEnquiryFields = {}

  fields.enquiryTypeReference = current.enquiryType?.enquiryType

  switch (fields.enquiryTypeReference) {
    case EnquiryTypeReference.NewBooking:
      fields.jobId = current.newBooking?.createJob?.job?.id
      fields.contactId =
        current.newBooking?.createCustomer?.customer?.contactId ?? current.newBooking?.customer?.contact?.id
      fields.customerId = current.newBooking?.createCustomer?.customer?.id ?? current.newBooking?.customer?.customer?.id
      fields.contact =
        first(current.newBooking?.createCustomer?.customer?.contacts)?.contact ?? current.newBooking?.customer?.contact
      fields.topicReference = current.newBooking?.jobTopic?.topic?.reference

      fields.membershipStatus = current.newBooking?.customerProperty?.membership?.membershipStatus
      fields.membershipLevel = current.newBooking?.customerProperty?.membership?.membershipLevel
      break
    case EnquiryTypeReference.CancelJob:
      fields.customerId = current.cancelJob?.customer?.customer?.id
      fields.contactId = current.cancelJob?.customer?.contact?.id
      fields.contact = current.cancelJob?.customer?.contact
      fields.jobId = current.cancelJob?.job?.job?.id
      break
    case EnquiryTypeReference.Complaint:
      fields.customerId = current.complaint?.customer?.customer?.id
      fields.contactId = current.complaint?.customer?.contact?.id
      fields.contact = current.complaint?.customer?.contact
      fields.jobId = current.complaint?.job?.job?.id
      break
    case EnquiryTypeReference.Crisis:
      fields.customerId = current.crisis?.customer?.customer?.id
      fields.contactId = current.crisis?.customer?.contact?.id
      fields.contact = current.crisis?.customer?.contact
      break
    case EnquiryTypeReference.ETA:
      fields.customerId = current.eta?.customer?.customer?.id
      fields.contactId = current.eta?.customer?.contact?.id
      fields.contact = current.eta?.customer?.contact
      fields.jobId = current.eta?.job?.job?.id
      break
    case EnquiryTypeReference.ConfirmJob:
      fields.customerId = current.confirmJob?.customer?.customer?.id
      fields.contactId = current.confirmJob?.customer?.contact?.id
      fields.contact = current.confirmJob?.customer?.contact
      fields.jobId = current.confirmJob?.job?.job?.id
      break
    case EnquiryTypeReference.Feedback:
      fields.customerId = current.feedback?.customer?.customer?.id
      fields.contactId = current.feedback?.customer?.contact?.id
      fields.contact = current.feedback?.customer?.contact
      fields.jobId = current.feedback?.job?.job?.id
      break
    case EnquiryTypeReference.PayInvoice:
      fields.customerId = current.payInvoice?.customer?.customer?.id
      fields.contactId = current.payInvoice?.customer?.contact?.id
      fields.contact = current.payInvoice?.customer?.contact
      fields.jobId = current.payInvoice?.invoice?.invoice?.jobId
      break
    case EnquiryTypeReference.Rebooking:
      fields.customerId = current.rebooking?.customer?.customer?.id
      fields.contactId = current.rebooking?.customer?.contact?.id
      fields.contact = current.rebooking?.customer?.contact
      fields.jobId = current.rebooking?.job?.job?.id
      break
    case EnquiryTypeReference.UpdateJob:
      fields.customerId = current.updateJob?.customer?.customer?.id
      fields.contactId = current.updateJob?.customer?.contact?.id
      fields.contact = current.updateJob?.customer?.contact
      fields.jobId = current.updateJob?.job?.job?.id
      break
    case EnquiryTypeReference.ResendInvoice:
      fields.customerId = current.resendInvoice?.customer?.customer?.id
      fields.contactId = current.resendInvoice?.customer?.contact?.id
      fields.contact = current.resendInvoice?.customer?.contact
      fields.jobId = current.resendInvoice?.invoice?.invoice?.jobId
      break
    case EnquiryTypeReference.UpdateCustomer:
      fields.customerId = current.updateCustomer?.customer?.customer?.id
      fields.contactId = current.updateCustomer?.customer?.contact?.id
      fields.contact = current.updateCustomer?.customer?.contact
      break
    case EnquiryTypeReference.Transfer:
      fields.customerId = current.transferCall?.customer?.customer?.id
      fields.contactId = current.transferCall?.customer?.contact?.id
      fields.contact = current.transferCall?.customer?.contact
      break
    case EnquiryTypeReference.Membership:
    case EnquiryTypeReference.DisputeInvoice:
    case EnquiryTypeReference.Employment:
    case EnquiryTypeReference.Media:
    case EnquiryTypeReference.Other:
    default:
    //do nothing
  }
  return fields
}
