import { Duration } from 'luxon'

export const timeFormatter = (minutes?: number | undefined): string => {
  if (!minutes) {
    return '0m'
  }
  const duration = Duration.fromDurationLike({ minutes })
  if (duration.as('hours') >= 1) {
    const zeroMinutes = duration.shiftTo('hours', 'minutes').toObject().minutes === 0
    return zeroMinutes ? duration.toFormat(`h'h'`) : duration.toFormat(`h'h' m'm'`)
  } else {
    return duration.toFormat(`m'm'`)
  }
}

export const timeFormatterSeconds = (seconds?: number | undefined): string => {
  if (!seconds) {
    return '0s'
  }
  const duration = Duration.fromDurationLike({ seconds })
  if (duration.as('hours') >= 1) {
    return timeFormatter(duration.as('minutes'))
  }
  const zeroMinutes = duration.shiftTo('minutes', 'seconds').toObject().minutes === 0
  return zeroMinutes ? duration.toFormat(`s's'`) : duration.toFormat(`m'm' s's'`)
}
