import { TopicActionResult } from '../action-topic-model'
import { QuestionDefinitions, getNotesGenerator } from '../../helpers/notes-generator'

export interface TopicWaterLeakActionResult extends TopicActionResult {
  leakLocation?: string
  leakAge?: string
  canLeakBeIsolated?: string
  additionalInformation?: string
}

const questionDefinitions: QuestionDefinitions<TopicWaterLeakActionResult> = {
  leakLocation: {
    question: 'Where is the water leak coming from?',
    statement: 'Leak location:'
  },
  leakAge: {
    question: 'How long has it been happening for?',
    statement: 'How long has is been happening:'
  },
  canLeakBeIsolated: {
    question: 'Can you isolate the leak?',
    statement: 'Can you isolate the leak:'
  },
  additionalInformation: {
    question: 'Any other information that might be useful for our tech? (optional)',
    statement: 'Additional information:'
  }
}

export const waterLeakNotesGenerator = getNotesGenerator(questionDefinitions)
