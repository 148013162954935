import { useQuery } from '@apollo/client'
import { EuiButtonEmpty, EuiCallOut, EuiLoadingSpinner } from '@elastic/eui'
import { GetJobDocument } from '../api/generated-types'
import { JobSummary } from './job-summary'

export interface JobSummaryContainerProps {
  jobId: string
}

export const JobSummaryContainer = (props: JobSummaryContainerProps) => {
  const { jobId } = props
  const { loading, error, data, refetch } = useQuery(GetJobDocument, { variables: { id: jobId } })

  const job = data?.getJob ?? null

  const errorView = () => <EuiCallOut color="danger">Encountered error: {error?.message}</EuiCallOut>

  const dataView = () => (
    <>
      {loading && <EuiLoadingSpinner size="l" />}
      {job && (
        <>
          <EuiButtonEmpty iconType="refresh" onClick={() => refetch()} size="xs">
            Reload job details
          </EuiButtonEmpty>
          <JobSummary job={job} />
        </>
      )}
    </>
  )

  return <>{error ? errorView() : dataView()}</>
}
