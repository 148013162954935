import {
  DefaultItemAction,
  EuiBasicTableColumn,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHealth,
  EuiIcon,
  EuiText
} from '@elastic/eui'
import { DateTime } from 'luxon'
import {
  CustomerLinkFragment,
  CustomerLocationMembershipFragment,
  JobLinkFragment,
  MembershipFragment,
  MembershipLevel,
  MembershipPurchaseContext,
  MembershipStatusType,
  UserLinkFragment
} from '../api/generated-types'
import { dateConfig } from '../common/date-config-luxon'
import EuiCustomLink from '../common/eui-custom-link'
import { decamelise } from '../common/utils'
import PremiumMemberLogo from '../static/images/member-logo-premium.svg'
import StandardMemberLogo from '../static/images/member-logo-standard.svg'
import UserLink from '../users/user-link'
import { MembershipStatusBadge } from './membership-status-badge'

export interface MembershipListColumnsProps {
  onClickCancel?: (membership: MembershipFragment) => void
  onClickRestore?: (membership: MembershipFragment) => void
  onClickEdit?: (membership: MembershipFragment) => void
  onChangeProperty?: (membership: MembershipFragment) => void
}

export const MembershipListColumns = (
  props?: MembershipListColumnsProps
): Array<EuiBasicTableColumn<MembershipFragment>> => {
  const { onClickCancel, onClickRestore, onClickEdit, onChangeProperty } = props ?? {}

  return [
    {
      field: 'reference',
      name: 'Membership no.',
      width: '240px',
      render: (reference: string, membership: MembershipFragment) => (
        <EuiCustomLink to={`/memberships/${membership.id}`}>
          <EuiIcon
            type={membership.level === MembershipLevel.Premium ? PremiumMemberLogo : StandardMemberLogo}
            style={{ marginRight: '6px' }}
            size="l"
          />
          {reference}
        </EuiCustomLink>
      )
    },
    {
      field: 'customer',
      name: 'Customer',
      className: 'truncate',
      render: (customer: CustomerLinkFragment) => (
        <EuiCustomLink to={`/customers/${customer.id}`}>
          {customer.mainContact?.detail?.fullName ?? 'No name'}
        </EuiCustomLink>
      )
    },
    {
      field: 'property',
      name: 'Property',
      className: 'truncate',
      render: (l, membership) => {
        const location = membership.location
        return (
          <>
            {location?.propertyId ? (
              <EuiCustomLink to={`/properties/${location.propertyId}`}>
                {location.formattedAddress ?? 'No name'}
              </EuiCustomLink>
            ) : (
              <EuiText>{location?.formattedAddress ?? 'No address'}</EuiText>
            )}
          </>
        )
      }
    },
    {
      field: 'purchaseContext',
      name: 'Purchase context',
      width: '150px',
      render: (purchaseContext: MembershipPurchaseContext) => {
        const icon = iconForPurchaseContext(purchaseContext)
        return (
          <EuiFlexGroup alignItems="center" gutterSize="s">
            <EuiFlexItem grow={false}>
              <EuiIcon type={icon} color="subdued" />
            </EuiFlexItem>
            <EuiFlexItem grow={true}>{decamelise(purchaseContext)}</EuiFlexItem>
          </EuiFlexGroup>
        )
      }
    },
    {
      field: 'level',
      name: 'Level',
      width: '100px'
    },
    {
      field: 'job',
      name: 'Job',
      render: (job: JobLinkFragment) => <EuiCustomLink to={`/jobs/${job.id}`}>{job.number}</EuiCustomLink>
    },
    {
      field: 'salesPerson',
      name: 'Salesperson',
      render: (salesPerson: UserLinkFragment) => <UserLink user={salesPerson} />
    },
    {
      field: 'status.status',
      name: 'Status',
      render: (status: MembershipStatusType) => status && <MembershipStatusBadge status={status} />
    },
    {
      field: 'startDate',
      name: 'Start date',
      render: (startDate: string) => {
        const date = DateTime.fromISO(startDate)
        return date.isValid ? date.toFormat(dateConfig.fullDate) : ''
      }
    },
    {
      field: 'expiryDate',
      name: 'Expiry date',
      render: (expiryDate: string) => {
        const date = DateTime.fromISO(expiryDate)
        return date.isValid ? date.toFormat(dateConfig.fullDate) : ''
      }
    },
    {
      field: 'created',
      name: 'Date created',
      width: '140px',
      align: 'right',
      render: (value: any) => {
        const eventDate = DateTime.fromISO(value)
        return eventDate.isValid ? eventDate.toFormat(dateConfig.fullDate) : ''
      }
    },
    ...(onClickEdit || onClickCancel || onClickRestore || onChangeProperty
      ? [
          {
            name: '',
            width: '72px',
            actions: [
              ...(onClickEdit
                ? [
                    {
                      name: 'Edit membership',
                      description: 'Edit membership',
                      icon: 'pencil',
                      type: 'icon',
                      onClick: (membership: MembershipFragment) => onClickEdit(membership)
                    } as DefaultItemAction<MembershipFragment>
                  ]
                : []),
              ...(onClickCancel
                ? [
                    {
                      name: 'Cancel membership',
                      description: 'Cancel membership',
                      icon: 'trash',
                      type: 'icon',
                      onClick: (membership: MembershipFragment) => onClickCancel(membership)
                    } as DefaultItemAction<MembershipFragment>
                  ]
                : []),
              ...(onClickRestore
                ? [
                    {
                      name: 'Restore membership',
                      description: 'Restore membership',
                      icon: 'refresh',
                      type: 'icon',
                      onClick: (membership: MembershipFragment) => onClickRestore(membership)
                    } as DefaultItemAction<MembershipFragment>
                  ]
                : []),
              ...(onChangeProperty
                ? [
                    {
                      name: 'Change property',
                      description: 'Change property',
                      icon: 'refresh',
                      type: 'icon',
                      onClick: (membership: MembershipFragment) => onChangeProperty(membership)
                    } as DefaultItemAction<MembershipFragment>
                  ]
                : [])
            ]
          }
        ]
      : [])
  ]
}

export const BouncingBallCustomerMembershipColumns = (): Array<
  EuiBasicTableColumn<MembershipFragment | CustomerLocationMembershipFragment>
> => {
  return [
    {
      field: 'property',
      name: 'Property',
      className: 'truncate',
      width: '300px',
      render: (l, membership) => {
        const location = membership.location
        return (
          <>
            {location ? (
              <EuiCustomLink to={`/properties/${location.propertyId}`}>
                {location.formattedAddress ?? 'No address'}
              </EuiCustomLink>
            ) : (
              <EuiText>
                <p>No address</p>
              </EuiText>
            )}
          </>
        )
      }
    },
    {
      field: 'level',
      name: 'Level',
      width: '200px',
      render: (level: MembershipLevel) => <EuiHealth color={colorForLevel(level)}>{labelForLevel(level)}</EuiHealth>
    },
    {
      field: 'job',
      name: 'Job',
      width: '100px',
      render: (job: JobLinkFragment) => <EuiCustomLink to={`/jobs/${job.id}`}>{job.number}</EuiCustomLink>
    },
    {
      field: 'status.status',
      name: 'Status',
      width: '150px',
      render: (status: MembershipStatusType) => status && <MembershipStatusBadge status={status} />
    },
    {
      field: 'startDate',
      name: 'Start date',
      width: '150px',
      render: (startDate: string) => {
        const date = DateTime.fromISO(startDate)
        return date.isValid ? date.toFormat(dateConfig.fullDate) : ''
      }
    },
    {
      field: 'expiryDate',
      name: 'Expiry date',
      width: '150px',
      render: (expiryDate: string) => {
        const date = DateTime.fromISO(expiryDate)
        return date.isValid ? date.toFormat(dateConfig.fullDate) : ''
      }
    }
  ]
}

const labelForLevel = (level: MembershipLevel) => {
  switch (level) {
    case MembershipLevel.Premium:
      return 'Black plan member'
    case MembershipLevel.Complimentary:
    case MembershipLevel.Standard:
      return 'Red plan member'
    default:
      return 'Unknown'
  }
}
const colorForLevel = (level: MembershipLevel) => {
  switch (level) {
    case MembershipLevel.Complimentary:
    case MembershipLevel.Standard:
      return 'danger'
    case MembershipLevel.Premium:
      return '#000000'
    case MembershipLevel.None:
      return 'subdued'
  }
}

const iconForPurchaseContext = (purchaseContext: string): string => {
  switch (purchaseContext) {
    case MembershipPurchaseContext.ActiveRenewal:
    case MembershipPurchaseContext.InactiveRenewal:
      return 'refresh'
    case MembershipPurchaseContext.Upgrade:
      return 'arrowUp'
    case MembershipPurchaseContext.New:
    default:
      return 'plus'
  }
}
