import { Callout } from '../../../common/callout'

export interface EndOfTopicStatementProps {
  statements: string[] | null
  show: boolean
}
export const EndOfTopicStatement = ({ statements, show }: EndOfTopicStatementProps) => {
  if (!statements || statements.length === 0 || !show) {
    return null
  }
  return (
    <>
      {statements.map((statement, index) => {
        return (
          <Callout type="warning" key={index}>
            {statement}
          </Callout>
        )
      })}
    </>
  )
}
