import { QuestionDefinitions, getNotesGenerator } from '../../helpers/notes-generator'
import { TopicActionResult } from '../action-topic-model'
import { YesNoValue } from '../common/common-enums'

export interface TopicPlumbingRevisitActionResult extends TopicActionResult {
  sameIssueAsOriginalJob?: YesNoValue
  issueWithNewInstall?: YesNoValue
  qualityOfWork?: YesNoValue
}

const questionDefinitions: QuestionDefinitions<TopicPlumbingRevisitActionResult> = {
  sameIssueAsOriginalJob: {
    question: 'Is it the same issue as the original job?',
    statement: 'Same issue as original job:'
  },
  issueWithNewInstall: {
    question: 'Is it an issue with a new install?',
    statement: 'New install issue:'
  },
  qualityOfWork: {
    question: 'Is it a quality of work issue?',
    statement: 'Quality of work issue:'
  }
}

export const plumbingRevisitNotesGenerator = getNotesGenerator(questionDefinitions)
