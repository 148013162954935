import { useMutation } from '@apollo/client'
import {
  EuiButton,
  EuiButtonEmpty,
  EuiCallOut,
  EuiForm,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiOverlayMask,
  EuiSpacer
} from '@elastic/eui'
import { useState } from 'react'
import {
  CustomerType,
  JobFragment,
  JobType,
  TradeType,
  UpdateJobClassificationDocument,
  UpdateJobClassificationInput
} from '../api/generated-types'
import { AllAction, useDecisionPoint } from '../decision-point/use-decision-point'
import { JobCategorySelector } from './job-category-selector'

export interface JobClassificationEditProps {
  job: JobFragment
  closeModal: () => void
}

export const JobClassificationEdit = (props: JobClassificationEditProps) => {
  const { job, closeModal } = props
  const [customerType, setCustomerType] = useState<CustomerType | undefined>(job.customerType || undefined)
  const [trade, setTrade] = useState<TradeType | undefined>(job.tradeType || undefined)
  const [type, setType] = useState<JobType | undefined>(job.type || undefined)
  const [category, setCategory] = useState<string | undefined>(job.category || undefined)
  const { canUpdateJobClassification } = useDecisionPoint(AllAction.UpdateJobClassification)
  const { canUpdateJobClassificationOk } = canUpdateJobClassification()
  const userCanUpdateJobClassification = !job.optionSheet || !!canUpdateJobClassificationOk
  const [updateJobRequirements, { error, loading }] = useMutation(UpdateJobClassificationDocument)

  const handleUpdate = async () => {
    const isAllRequiredDataPresent = !!job.id && !!customerType && !!trade && !!type
    if (!isAllRequiredDataPresent) {
      console.log('missing data, cannot update job classification')
      return
      //closeModal()
    }
    const input: UpdateJobClassificationInput = {
      job: job.id,
      customerType,
      trade,
      type,
      category
    }
    try {
      await updateJobRequirements({ variables: { input } })
      closeModal()
    } catch (error) {
      console.error('error performing mutation', error)
    }
  }
  const clearCustomerType = () => setCustomerType(undefined)

  const clearTrade = () => {
    setTrade(undefined)
    setCategory(undefined)
  }

  const clearType = () => setType(undefined)
  const clearCategory = () => setCategory(undefined)

  const form = (
    <EuiForm>
      {error && (
        <>
          <EuiCallOut color="danger" title="Error updating job classification">
            <p>{error.toString()}</p>
          </EuiCallOut>
          <EuiSpacer />
        </>
      )}

      <JobCategorySelector
        customerType={customerType}
        trade={trade}
        type={type}
        category={category}
        clearCustomerType={clearCustomerType}
        clearTrade={clearTrade}
        clearType={clearType}
        clearCategory={clearCategory}
        setCustomerType={setCustomerType}
        setTrade={(trade) => {
          setTrade(trade)
          setCategory(undefined)
        }}
        setType={setType}
        setCategory={setCategory}
      />
    </EuiForm>
  )

  return (
    <EuiOverlayMask>
      <EuiModal onClose={closeModal} style={{ width: '1000px', maxWidth: '90vw', minHeight: '600px' }}>
        <EuiModalHeader>
          <EuiModalHeaderTitle>Job classification: {job.number}</EuiModalHeaderTitle>
        </EuiModalHeader>
        <EuiModalBody>
          {!userCanUpdateJobClassification && (
            <>
              <EuiCallOut
                color="danger"
                title="Attendance has been recorded against this job, please contact a supervisor if you feel the job classification needs to change"
              />
              <EuiSpacer size="m" />
            </>
          )}
          <div>{form}</div>
        </EuiModalBody>
        <EuiModalFooter>
          <EuiButtonEmpty onClick={closeModal} isDisabled={loading}>
            Cancel
          </EuiButtonEmpty>
          <EuiButton onClick={handleUpdate} isLoading={loading} disabled={!userCanUpdateJobClassification} fill>
            Done
          </EuiButton>
        </EuiModalFooter>
      </EuiModal>
    </EuiOverlayMask>
  )
}
