import { QuestionDefinitions, getNotesGenerator } from '../../helpers/notes-generator'
import { TopicActionResult } from '../action-topic-model'

export interface TopicPlumbingInspectionActionResult extends TopicActionResult {
  inspectionType?: PlumbingInspectionType
  drainDiameter?: DrainDiameter
}

export enum PlumbingInspectionType {
  GeneralPlumbing = 'General plumbing inspection',
  DrainCameraCheck = 'Drain camera check',
  Both = 'Both'
}

export enum DrainDiameter {
  Between90and150 = 'Between 90mm and 150mm',
  OutsideOf90and150 = 'Outside of 90mm and 150mm'
}

const questionDefinitions: QuestionDefinitions<TopicPlumbingInspectionActionResult> = {
  inspectionType: {
    question: 'Is this for your general plumbing inspection or drain camera check or both?',
    statement: 'Inspection type:'
  },
  drainDiameter: {
    question: 'How big is the drain diameter?',
    statement: 'Drain diameter:'
  }
}

export const plumbingInspectionNotesGenerator = getNotesGenerator(questionDefinitions)
