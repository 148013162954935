import { BaseActionInput, BaseActionResult, WorkflowActionProps } from '../workflow-model'
import { EuiAccordion, EuiButton, EuiSpacer, EuiStepProps, EuiSteps, EuiSubSteps, EuiText } from '@elastic/eui'
import { Callout } from '../../common/callout'
import { useState } from 'react'
import { Task } from '../../common/task'

export type MediaActionInput = BaseActionInput
export type MediaActionResult = BaseActionResult

export const MediaAction = (props: WorkflowActionProps<MediaActionInput, MediaActionResult>) => {
  const { onUpdate } = props

  const [detailsPassedOn, setDetailsPassedOn] = useState(false)

  const steps: EuiStepProps[] = [
    {
      title: 'Politely put the person on hold and carefully read below',
      children: (
        <Callout type="warning" title="Taking the call">
          <EuiText>
            <ul>
              <li>
                Whether it is the media or lawyers, they may try to get information from you and try to get you to say
                things that they can use as sound bites or record as evidence.
              </li>
              <li>Be very cautious of this and be careful not to give away any information.</li>
              <li>Simply ask for their details so that the relevant contact can get back to them.</li>
              <li>Do not let them coerce you into saying anything more. Repeat the above until they cooperate.</li>
            </ul>
          </EuiText>
        </Callout>
      )
    },
    {
      title: 'Pass on details to Company Spokesperson',
      children: (
        <EuiSubSteps>
          <EuiText>
            <ol>
              <li>Ask for their details and pass these details on the Company Spokesperson to call them back.</li>
              <li>
                Try call CEO Sean Simmons (EXTENSION). If unavailable, pass the details directly to Mark Denning
                (EXTENSION). Where possible, please do not disclose job titles of the spokesperson. Refer to them as the
                "Company Spokesperson".
              </li>
              <li>
                DO NOT disclose any information regarding the issue or with regards to our business operations. Ask only
                for their details and assure them you will pass them on to the relevant contact to get back to them
                immediately.
              </li>
            </ol>
          </EuiText>
        </EuiSubSteps>
      )
    }
  ]

  return (
    <>
      <Callout type="script">
        <EuiText>
          <div>Unfortunately I am not in a position to discuss any of those matters.</div>
          <div>
            However, if you would like to leave me with you name and contact details, I can pass it on to the relevant
            stakeholders
          </div>
        </EuiText>
      </Callout>
      <Task
        label="Capture details and pass on to relevant stakeholder(s)"
        checked={detailsPassedOn}
        onChange={setDetailsPassedOn}
        indent={true}
      />
      {detailsPassedOn && (
        <>
          <EuiSpacer />

          <EuiButton fill color="primary" onClick={() => onUpdate({})}>
            I've completed these steps
          </EuiButton>

          <EuiSpacer size="xl" />
          <EuiAccordion id="previous-media-action-content" buttonContent="Show previous content">
            <EuiSpacer />
            <EuiSteps titleSize="xs" steps={steps} />
            <EuiText style={{ background: 'yellow' }}>
              Damien comment: Maybe this should be email referral rather than attempting to reach on extension
              immediately?
            </EuiText>
          </EuiAccordion>
        </>
      )}
    </>
  )
}
