import { EuiLink, EuiSpacer } from '@elastic/eui'
import { first, orderBy } from 'lodash-es'
import { useState } from 'react'
import {
  CustomerLocationFragment,
  CustomerLocationMembershipFragment,
  MembershipLevel,
  MembershipStatusType
} from '../../api/generated-types'
import { CreatePropertyForm } from '../../properties/create-property-form'
import { CustomerLocationSelector } from '../../properties/customer-location-selector'
import { WorkflowAction } from '../workflow-action'
import { BaseActionInput, BaseActionResult, WorkflowActionProps } from '../workflow-model'
import { CustomerQualificationActionResult } from './action-customer-qualification'
import { Membership } from '@fallonsolutions/membership'
import { getCustomerPropertyMembership } from '../../common/get-current-customer-location-membership'
import { getSelectCustomerProperty } from '../../common/get-customer-property'

export interface CustomerPropertyActionInput extends BaseActionInput {
  customerId: string
  customerQualification?: CustomerQualificationActionResult
}

export interface CustomerPropertyActionResult extends BaseActionResult {
  property?: SelectedProperty
  membership?: SelectedMembership
}

export interface SelectedMembership {
  id: string | undefined
  membershipLevel: MembershipLevel | undefined
  membershipStatus: MembershipStatusType | undefined
}

export interface SelectedProperty {
  id: string
  label: string
  suburb?: {
    id: string
    label: string
  }
}

export const CustomerPropertyAction = (
  props: WorkflowActionProps<CustomerPropertyActionInput, CustomerPropertyActionResult>
) => {
  const { input, onUpdate, result } = props
  const { customerId } = input
  const { property } = result ?? {}
  const [selectedProperty, setSelectedProperty] = useState<SelectedProperty | undefined>(property)
  const propertyId = selectedProperty?.id
  const [showCreateForm, setShowCreateForm] = useState(false)

  const onSelectLocation = (location: CustomerLocationFragment) => {
    setShowCreateForm(false)
    const membership = getCustomerPropertyMembership(location)
    const property = location.property
    const selectedProperty = getSelectCustomerProperty(location)
    setSelectedProperty(selectedProperty)
    onUpdate({ property: selectedProperty, membership })
  }

  const onBackToSearch = () => {
    setShowCreateForm(false)
    setSelectedProperty(undefined)
    onUpdate({})
  }

  const onNew = () => {
    setShowCreateForm(true)
  }

  const value = selectedProperty ? `${selectedProperty.label}` : undefined

  return (
    <WorkflowAction title="Property" value={value} onClickChange={onBackToSearch} editable={input.editable}>
      {!propertyId && !showCreateForm && (
        <>
          <CustomerLocationSelector customerId={input.customerId} onSelect={onSelectLocation} onNew={onNew} />
          <EuiSpacer />
        </>
      )}
      {showCreateForm && (
        <>
          <EuiLink onClick={onBackToSearch}>Back to search</EuiLink>
          <EuiSpacer />
          <CreatePropertyForm
            query={''}
            customerId={customerId}
            redirectToProfileAfterCreation={false}
            onCustomerLocationCreated={onSelectLocation}
          />
        </>
      )}
    </WorkflowAction>
  )
}
