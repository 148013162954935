import { SingleSelectField } from '../question-fields/single-select-question-field'
import { YesNoValue } from '../topics/common/common-enums'
import { yesNoOptions } from '../topics/common/common-options'

interface results {
  roofAccess: YesNoValue | undefined
}
type resultsFunction = (result: results) => void
interface HVACRoofAccessQuestionProps {
  roofAccess?: YesNoValue
  updateResults: resultsFunction
  isCommercial?: boolean
}
const ROOF_ACCESS_QUESTION =
  'Can you please confirm the technician will have access to the outdoor unit? This includes roof/maintenance rooms and the required keys/passcodes will be provided to the tech?'
export const HVACRoofAccessQuestion = (props: HVACRoofAccessQuestionProps) => {
  const { updateResults, roofAccess, isCommercial } = props
  return (
    <>
      {isCommercial && (
        <SingleSelectField
          question={ROOF_ACCESS_QUESTION}
          options={yesNoOptions}
          answer={roofAccess}
          changeAnswer={(roofAccess) => updateResults({ roofAccess })}
        />
      )}
    </>
  )
}
