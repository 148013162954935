import { EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiIconTip, EuiLink, EuiSpacer, EuiTextArea } from '@elastic/eui'
import { useEffect, useState } from 'react'
import { AddressField } from '../address/address-field'
import { AddressFragment, PropertyFragment } from '../api/generated-types'

interface PropertyFormProps {
  property: PropertyFragment
  showValidationErrors: boolean
  canAddressBeProvided: boolean
  onChangeProperty: (property: PropertyFragment) => void
  onValidationChange: (valid: boolean) => void
}

export const PropertyForm = (props: PropertyFormProps) => {
  const { property, showValidationErrors, onChangeProperty, onValidationChange, canAddressBeProvided } = props
  const { address, accessInstructions } = property
  const [showAccess, setShowAccess] = useState(!!props.property.accessInstructions)
  const [addressValid, setAddressValid] = useState(true)

  const setAddress = (address: AddressFragment) => onChangeProperty({ ...property, address })
  const setAccessInstructions = (accessInstructions: string) => onChangeProperty({ ...property, accessInstructions })

  const isNewProperty = !property.id

  const formErrors: boolean[] = [!addressValid].filter((invalid) => invalid)
  const isFormValid = !formErrors.length

  useEffect(() => {
    onValidationChange(isFormValid)
  }, [isFormValid])

  const renderPropertyMetadataFields = () =>
    showAccess ? (
      <EuiFormRow label="Access instructions">
        <EuiTextArea value={accessInstructions || ''} onChange={(e) => setAccessInstructions(e.target.value)} />
      </EuiFormRow>
    ) : (
      <EuiLink style={{ fontSize: '12px' }} onClick={() => setShowAccess(!showAccess)}>
        + Access instructions
      </EuiLink>
    )

  return (
    <EuiFlexGroup alignItems="flexStart">
      <EuiFlexItem grow={true}>
        {canAddressBeProvided ? (
          <AddressField
            showValidationErrors={showValidationErrors}
            address={address ?? undefined}
            onChange={(result) => {
              setAddressValid(result.isValid)
              setAddress(result.address)
            }}
          />
        ) : (
          <EuiFormRow label="Address">
            <div>
              {property.formattedAddress + ' '}
              <EuiIconTip content="You need permission to update property address" color="#0061a6" />
            </div>
          </EuiFormRow>
        )}
        <EuiSpacer />
        {!isNewProperty && <>{renderPropertyMetadataFields()}</>}
      </EuiFlexItem>
    </EuiFlexGroup>
  )
}
