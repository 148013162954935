import { BaseActionInput, BaseActionResult, WorkflowActionProps } from '../workflow-model'
import { WorkflowAction } from '../workflow-action'
import { EuiButton, EuiSpacer } from '@elastic/eui'
import { JobAppointmentsSelect } from '../../appointments/job-appointments-select'
import { AppointmentFragment } from '../../api/generated-types'
import { DateTime } from 'luxon'
import { dateConfig } from '@fallonsolutions/date'

export interface SelectAppointmentActionInput extends BaseActionInput {
  jobId: string
}

export interface SelectAppointmentActionResult extends BaseActionResult {
  appointment?: {
    id: string
    label: string
  }
}

export const SelectAppointmentAction = (
  props: WorkflowActionProps<SelectAppointmentActionInput, SelectAppointmentActionResult>
) => {
  const { input, result, onUpdate } = props

  const { jobId } = input ?? {}
  const { actionCompleted, appointment } = result ?? {}

  const value = actionCompleted && appointment ? appointment.label : undefined
  const setAppointment = (appointment: AppointmentFragment | undefined) => {
    if (appointment) {
      const date = DateTime.fromISO(appointment.scheduled?.from, {
        zone: dateConfig.defaultTimezone
      })
      const from = DateTime.fromISO(appointment.bookingWindow?.from, {
        zone: dateConfig.defaultTimezone
      })
      const to = DateTime.fromISO(appointment.bookingWindow?.to, {
        zone: dateConfig.defaultTimezone
      })
      const dateString = date.toFormat(dateConfig.luxonFormat.shortDate)
      const fromString = from.toFormat(dateConfig.luxonFormat.time).toLowerCase()
      const toString = to.toFormat(dateConfig.luxonFormat.time).toLowerCase()
      onUpdate({
        ...result,
        appointment: {
          id: appointment.id,
          label: `${dateString}, ${fromString} - ${toString}`
        }
      })
    } else {
      onUpdate({})
    }
  }
  const clearJob = () => onUpdate({})

  return (
    <WorkflowAction title="Select appointment" value={value} onClickChange={clearJob}>
      <JobAppointmentsSelect jobId={jobId} selectedAppointmentId={appointment?.id} onSelect={setAppointment} />

      <EuiSpacer />
      <EuiButton onClick={() => onUpdate({ ...result, actionCompleted: true })} disabled={!appointment?.id}>
        Select
      </EuiButton>
    </WorkflowAction>
  )
}
