import { EuiButton, EuiSpacer } from '@elastic/eui'
import { EnquiryAbortReason } from '../../../api/generated-types'
import { Callout } from '../../../common/callout'
import { getResultUpdater } from '../../helpers/results-updater'
import { useWorkflow } from '../../helpers/workflow-provider'
import { ComboSelectField } from '../../question-fields/combo-select-question-field'
import { SingleSelectField } from '../../question-fields/single-select-question-field'
import { WorkflowActionProps } from '../../workflow-model'
import { ServiceType, TopicActionInput } from '../action-topic-model'
import { TopicWorkflowAction } from '../action-topic-view'
import { brandOptions, transformBrandSelections } from '../appliance-shared/appliance-brand-model'
import {
  ApplianceAgeEnum,
  WARRANTY_SCRIPT,
  applianceAgeOptions,
  canProceedWithRepair,
  isBrandServiceable,
  serviceTypeOptions
} from '../appliance-shared/appliance-model'
import { YesNoValue } from '../common/common-enums'
import { yesNoOptions } from '../common/common-options'
import { TopicQualification } from '../common/common-outcome'
import {
  TopicWashingMachineActionResult,
  WashingMachineFaultEnum,
  washingMachineFaultOptions,
  washingMachineRepairNotesGenerator
} from './action-topic-washing-machine-model'
import { outcomeGenerator } from './action-topic-washing-machine-outcome'

export const TopicWashingMachineAction = (
  props: WorkflowActionProps<TopicActionInput, TopicWashingMachineActionResult>
) => {
  const { result, input, onUpdate } = props
  const {
    applianceAge,
    brandServiceable,
    confirmedPartsAvailableWithDSR,
    mayVoidWarrantyConfirmationToProceed,
    outletWorksWithOtherDevice,
    selectedBrand,
    powerOutletNeeded,
    issuesWithExistingTaps,
    serviceType,
    fault
  } = result ?? {}
  const workflowContext = useWorkflow()
  const notesGenerator = washingMachineRepairNotesGenerator
  const brandWarning = selectedBrand?.warning
  const topicOutcome = outcomeGenerator(result)
  const { requirements, qualification } = topicOutcome
  const isOutOfScope = qualification === TopicQualification.OutOfScope
  const newTopic = topicOutcome.newTopic

  const onNext = () =>
    onUpdate({
      ...result,
      ...topicOutcome,
      actionCompleted: true,

      workRequiredNotes: generatedNotes
    })

  const generatedNotes = notesGenerator.generateNotes(result ?? {})
  const updateResult = getResultUpdater(result ?? {}, onUpdate).updateResult

  const mayProceed = canProceedWithRepair(result)
  const canComplete = !!requirements

  const handleBrandSelect = (selectedOptions: any) => {
    const { selectedBrand, brand, brandServiceable } = transformBrandSelections(selectedOptions)
    updateResult({ selectedBrand, brand, brandServiceable })
  }
  return (
    <TopicWorkflowAction input={input} onUpdate={onUpdate} result={result}>
      <SingleSelectField
        question={notesGenerator.getQuestion('serviceType')}
        options={serviceTypeOptions}
        answer={serviceType}
        changeAnswer={(serviceType) => updateResult({ serviceType })}
      />
      <EuiSpacer size="m" />

      {serviceType === ServiceType.Installation && (
        <>
          <SingleSelectField
            question={notesGenerator.getQuestion('powerOutletNeeded')}
            options={yesNoOptions}
            answer={powerOutletNeeded}
            changeAnswer={(powerOutletNeeded) => updateResult({ powerOutletNeeded })}
          />
          <SingleSelectField
            question={notesGenerator.getQuestion('issuesWithExistingTaps')}
            options={yesNoOptions}
            answer={issuesWithExistingTaps}
            changeAnswer={(issuesWithExistingTaps) => updateResult({ issuesWithExistingTaps })}
          />
        </>
      )}
      {serviceType === ServiceType.Repair && (
        <>
          <SingleSelectField
            question={notesGenerator.getQuestion('applianceAge')}
            options={applianceAgeOptions}
            answer={applianceAge}
            changeAnswer={(applianceAge) => updateResult({ applianceAge })}
          />
          <EuiSpacer size="m" />

          {applianceAge === ApplianceAgeEnum.LessThan2yrsOld && (
            <>
              <Callout type="script" title={WARRANTY_SCRIPT} />
              <SingleSelectField
                question={notesGenerator.getQuestion('mayVoidWarrantyConfirmationToProceed')}
                options={yesNoOptions}
                answer={mayVoidWarrantyConfirmationToProceed}
                changeAnswer={(mayVoidWarrantyConfirmationToProceed) =>
                  updateResult({ mayVoidWarrantyConfirmationToProceed })
                }
              />
            </>
          )}

          {!!applianceAge && mayProceed && (
            <>
              <ComboSelectField
                question={notesGenerator.getQuestion('brand')}
                options={brandOptions}
                // FIXME: broken type here
                answer={selectedBrand as any}
                changeAnswer={handleBrandSelect}
                brandWarning={brandWarning}
              />
              <EuiSpacer size="m" />
              {!!selectedBrand && (
                <>
                  {brandServiceable === YesNoValue.No && (
                    <>
                      <SingleSelectField
                        question={notesGenerator.getQuestion('confirmedPartsAvailableWithDSR')}
                        answer={confirmedPartsAvailableWithDSR}
                        changeAnswer={(confirmedPartsAvailableWithDSR) =>
                          updateResult({ confirmedPartsAvailableWithDSR })
                        }
                        options={yesNoOptions}
                      />
                    </>
                  )}

                  {!!selectedBrand && isBrandServiceable(result) === true && (
                    <>
                      <SingleSelectField
                        question={notesGenerator.getQuestion('fault')}
                        options={washingMachineFaultOptions}
                        answer={fault}
                        changeAnswer={(fault) => updateResult({ fault })}
                      />
                      <EuiSpacer size="m" />

                      {fault === WashingMachineFaultEnum.PowerSupply && (
                        <>
                          <SingleSelectField
                            question={notesGenerator.getQuestion('outletWorksWithOtherDevice')}
                            answer={outletWorksWithOtherDevice}
                            changeAnswer={(outletWorksWithOtherDevice) => updateResult({ outletWorksWithOtherDevice })}
                            options={yesNoOptions}
                          />
                        </>
                      )}
                      <EuiSpacer size="m" />

                      {fault === WashingMachineFaultEnum.OtherFault && <></>}
                    </>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}

      {requirements && requirements.attributes && (
        <>
          <EuiSpacer />
          <Callout type="note">
            Book a technician with {requirements.attributes?.map((r) => r.attributeId).join(', ')} skills
          </Callout>
        </>
      )}

      {!isOutOfScope && !newTopic && (
        <>
          <EuiSpacer />
          <EuiButton disabled={!canComplete} onClick={() => onNext()}>
            Next
          </EuiButton>
        </>
      )}
      {newTopic && (
        <EuiButton onClick={() => workflowContext.changeJobTopic(newTopic.reference)}>{newTopic.buttonLabel}</EuiButton>
      )}

      {isOutOfScope && (
        <>
          <Callout type="script">
            {topicOutcome.qualificationMessage
              ? topicOutcome.qualificationMessage
              : 'Unfortunately this type of work is out of scope for us.'}
          </Callout>
          <EuiSpacer />
          <EuiButton
            color="warning"
            onClick={() =>
              workflowContext.abort({
                abortReason: EnquiryAbortReason.CustomerNeedsAreOutOfService,
                notes: `Customer was enquiring about: ${topicOutcome.qualificationMessage}`
              })
            }
          >
            Offer a voucher and end call
          </EuiButton>
        </>
      )}
    </TopicWorkflowAction>
  )
}
