import { useMutation, useQuery } from '@apollo/client'
import {
  EuiButton,
  EuiButtonEmpty,
  EuiButtonGroup,
  EuiButtonGroupOptionProps,
  EuiCallOut,
  EuiFormRow,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiOverlayMask
} from '@elastic/eui'
import { dataQualityStatusValidOrError } from '@fallonsolutions/data-quality'
import { map } from 'lodash-es'
import { useEffect, useState } from 'react'
import { DataQualityStatus, GetContactDocument, UpdateContactDataQualityDocument } from '../api/generated-types'
import { decamelise } from '../common/utils'

export interface EditContactDataQualityFormProps {
  contactId: string
  onClose: () => void
}

export const UpdateContactDataQualityForm = (props: EditContactDataQualityFormProps) => {
  const { contactId, onClose } = props
  const [status, setStatus] = useState<DataQualityStatus>(DataQualityStatus.NotChecked)

  const { data: contactData, loading: contactLoading } = useQuery(GetContactDocument, {
    variables: { input: { contactId } },
    onCompleted: (data) => {
      setStatus(data?.getContact.contact?.dataQuality?.status ?? DataQualityStatus.NotChecked)
    }
  })
  const contact = contactData?.getContact.contact

  const [updateContactDataQuality, { data, loading: updateLoading, error }] = useMutation(
    UpdateContactDataQualityDocument,
    {
      refetchQueries: ['SearchContacts', 'GetContact'],
      awaitRefetchQueries: true
    }
  )

  const loading = contactLoading || updateLoading

  const handleSubmit = () => {
    updateContactDataQuality({
      variables: {
        input: {
          contactId,
          status,
          issues: []
        }
      }
    })
  }

  useEffect(() => {
    if (data?.updateContactDataQuality?.contact) {
      onClose()
    }
  }, [data, onClose])

  enum DataQualityStatusSorted {
    NotChecked = DataQualityStatus.NotChecked,
    Spam = DataQualityStatus.Spam,
    Invalid = DataQualityStatus.Invalid,
    Valid = DataQualityStatus.Valid,
    Error = DataQualityStatus.Error
  }
  const statusOptions: EuiButtonGroupOptionProps[] = map(DataQualityStatusSorted, (id) => ({
    id: id as unknown as string,
    label: decamelise(id as unknown as string)
  }))

  const hubspotContactExistsCallout =
    contact?.hubspotId &&
    !dataQualityStatusValidOrError(status) &&
    dataQualityStatusValidOrError(contact?.dataQuality?.status)

  return (
    <EuiOverlayMask>
      <EuiModal onClose={onClose}>
        <EuiModalHeader>
          <EuiModalHeaderTitle>{contact?.detail?.fullName}</EuiModalHeaderTitle>
        </EuiModalHeader>
        <EuiModalBody>
          {error && <EuiCallOut color="danger">Error editing contact: {error.message}</EuiCallOut>}
          <EuiFormRow label="Status">
            <EuiButtonGroup
              legend="Status"
              options={statusOptions}
              type="single"
              idSelected={status}
              onChange={(id: string) => setStatus(id as DataQualityStatus)}
              buttonSize="compressed"
              color="primary"
              isDisabled={loading}
            />
          </EuiFormRow>
          {hubspotContactExistsCallout && (
            <EuiCallOut title="Hubspot contact exists" color="danger" iconType="error"></EuiCallOut>
          )}
        </EuiModalBody>

        <EuiModalFooter>
          {!loading && (
            <EuiButtonEmpty onClick={onClose} isLoading={updateLoading}>
              Cancel
            </EuiButtonEmpty>
          )}
          <EuiButton fill onClick={() => handleSubmit()} isLoading={loading} disabled={!contact}>
            Edit Contact
          </EuiButton>
        </EuiModalFooter>
      </EuiModal>
    </EuiOverlayMask>
  )
}
