import {
  TopicQualification,
  TopicQualificationResult,
  TopicRequirementsResult,
  createOutcomeGenerator,
  createRequirements
} from '../common/common-outcome'
import { JobType, TradeType } from '../../../api/generated-types'
import { TopicSmokeAlarmInspectionResult } from './action-topic-smoke-alarm-inspection-model'

const SMOKE_ALARM_INSPECTION_SKILL = 'SmokeAlarms'

const getRequirements = (result: TopicSmokeAlarmInspectionResult | undefined): TopicRequirementsResult => {
  if (!result) return {}
  return { requirements: createRequirements([SMOKE_ALARM_INSPECTION_SKILL]) }
}

const getQualification = (result: TopicSmokeAlarmInspectionResult | undefined): TopicQualificationResult => {
  if (!result) return { qualification: TopicQualification.Unknown }
  const { isSpecificInspection } = result
  return {
    qualification: isSpecificInspection ? TopicQualification.InScope : TopicQualification.Unknown
  }
}

export const outcomeGenerator = createOutcomeGenerator<TopicSmokeAlarmInspectionResult>(
  {
    qualification: TopicQualification.Unknown,
    trade: TradeType.Electrical,
    type: JobType.Service,
    category: 'Smoke Alarm Inspection'
  },
  getQualification,
  getRequirements
)
