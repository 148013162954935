import { BaseActionInput, BaseActionResult, WorkflowActionProps } from '../workflow-model'
import { EuiButton, EuiLink, EuiSpacer, EuiText } from '@elastic/eui'
import { Callout } from '../../common/callout'
import { CustomerAction, CustomerActionResult } from './action-customer'
import { SelectJobAction, SelectJobActionResult } from './action-select-job'
import { JobSummaryContainer } from '../../jobs/job-summary-container'
import { ContactActionResult } from './action-contact'

export interface UpdateJobActionInput extends BaseActionInput {
  contact?: ContactActionResult
}

export interface UpdateJobActionResult extends BaseActionResult {
  customer?: CustomerActionResult
  job?: SelectJobActionResult
}

export const UpdateJobAction = (props: WorkflowActionProps<UpdateJobActionInput, UpdateJobActionResult>) => {
  const { onUpdate, result, input } = props
  const { contact } = input
  const { customer, job } = result ?? {}
  const selectedCustomer = customer?.customer ?? undefined

  const setCustomer = (customer: CustomerActionResult) => onUpdate({ ...result, customer })
  const setJob = (job: SelectJobActionResult) => onUpdate({ ...result, job })

  const jobId = job?.actionCompleted ? job?.job?.id : undefined
  const customerId = customer?.actionCompleted ? customer?.customer?.id : selectedCustomer?.id ?? undefined

  return (
    <>
      <CustomerAction input={{ contact }} result={customer} onUpdate={setCustomer} />

      {customerId && (
        <>
          <SelectJobAction input={{ customerId }} result={job} onUpdate={setJob} />
          {jobId && (
            <>
              <Callout type="script">
                <EuiText>Excellent, so what exactly did you need changed?</EuiText>
              </Callout>
              <Callout type="note" title="Carefully update job's notes to reflect all required changes." />
              <EuiSpacer />
              <div className="workflow__detail-wrapper">
                {jobId && (
                  <>
                    <JobSummaryContainer jobId={jobId} />
                    <EuiSpacer />
                    <EuiLink href={`/jobs/${jobId}`} target="_blank">
                      Open job card for more details
                    </EuiLink>
                  </>
                )}
              </div>
              <EuiSpacer />
              <EuiButton fill color="primary" onClick={() => onUpdate({ ...result, actionCompleted: true })}>
                I've updated job
              </EuiButton>
            </>
          )}
        </>
      )}
    </>
  )
}
