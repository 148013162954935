import { useApolloClient, useMutation } from '@apollo/client'
import { PutObjectCommandInput, Tag } from '@aws-sdk/client-s3'
import { compact, isNumber, merge } from 'lodash-es'
import React, { useContext, useState } from 'react'
import {
  CreateMediaItemInput,
  CreateMediaItemsDocument,
  CreateMediaItemsInput,
  CreateProductMediaItemsDocument,
  CreateProductMediaItemsInput,
  GetJobMediaDocument,
  MediaContext,
  MediaItemFragment,
  MediaSource,
  MediaStatus,
  MediaType,
  PhotoInput,
  S3ObjectInput
} from '../api/generated-types'
import { useApp } from '../app/app-context'
import { useAuthenticated } from '../auth/authenticated-context'
import { MediaServiceUpload, MediaServiceUploadStatus, UploadFile } from './media-service-upload'
import { hash } from '../common/hash-util'
import { dateConfig, dateUtils } from '@fallonsolutions/date'
import { v4 } from 'uuid'
import { DateTime } from 'luxon'

interface MediaServiceProviderProps {
  children: React.ReactNode
}

const JOB_ID_TAG_KEY = 'jobId'
const AUTHOR_TAG_KEY = 'author'
const IMAGE_WIDTH_KEY = 'width'
const IMAGE_HEIGHT_KEY = 'height'

export interface UploadProductImagesParams {
  tenantId: string
  files: MediaServiceUpload[]
  onCreateMediaItems?: (mediaItems: MediaItemFragment[]) => void
  onStartUpload?: () => void
}

export interface UploadSignatureParams {
  dataUrl: string
  type: MediaType
  jobId: string
  context: MediaContext
  subject: string
}
export interface UploadSignatureResponse {
  s3Object: S3ObjectInput
  photoInput: PhotoInput
}

export interface FetchImageParams {
  url: string
  fileName: string
}
export const fetchLocalImageUrl = async (params: FetchImageParams): Promise<UploadFile> =>
  fetch(params.url)
    .then((r) => r.blob())
    .then((blob) => new File([blob], params.fileName, { type: blob.type }))
    .then(async (file) => {
      const fileBuffer = await file.arrayBuffer()
      const sha256 = await hash(fileBuffer)
      return merge(file, { sha256 })
    })

export interface IMediaService {
  getUpload: (id: string) => MediaServiceUpload | undefined
  getUploads: () => MediaServiceUpload[]
  uploadSignature: (params: UploadSignatureParams) => Promise<UploadSignatureResponse>
  upload: (
    jobId: string,
    files: MediaServiceUpload[],
    onCreateMediaItems?: (mediaItems: MediaItemFragment[]) => void
  ) => Promise<void>
  uploadProductImages: (props: UploadProductImagesParams) => Promise<void>
}

export const MediaService = React.createContext<IMediaService | undefined>(undefined)

export const MediaServiceProvider = (props: MediaServiceProviderProps) => {
  const appContext = useApp()
  const apolloClient = useApolloClient()
  const { userFragment, iamUtils } = useAuthenticated()

  const bucketName = appContext.documentBucketName
  const uploadDirectory = appContext.jobsMediaUploadDirectory
  const productImageUploadDirectory = 'products'
  const userId = userFragment?.id

  const [uploads, setUploads] = useState<MediaServiceUpload[]>([])

  const updateUploadStatus = (ids: string[], status: MediaServiceUploadStatus, progress: number) => {
    setUploads((uploads) => {
      return uploads.map((upload) => {
        if (ids.includes(upload.id)) {
          return { ...upload, status, progress }
        }
        return upload
      })
    })
  }

  const clearUploads = (ids: string[]) => {
    setUploads((uploads) => uploads.filter((u) => !ids.includes(u.id)))
  }

  const [createMediaItems] = useMutation(CreateMediaItemsDocument, {
    refetchQueries: ['GetJobMedia'],
    awaitRefetchQueries: true
  })

  const [createProductMediaItems] = useMutation(CreateProductMediaItemsDocument, {
    refetchQueries: ['GetItemDraft', 'SearchItemDrafts', 'SearchCategoryItemDrafts', 'GetCategory', 'GetItem'],
    awaitRefetchQueries: true
  })

  const getJobMedia = async (jobId: string): Promise<MediaItemFragment[]> => {
    const result = await apolloClient.query({
      query: GetJobMediaDocument,
      variables: { jobId },
      fetchPolicy: 'network-only'
    })
    return compact(result.data?.getJob?.media ?? [])
  }

  const createPutObjectRequestForUpload = (
    upload: MediaServiceUpload,
    directory = uploadDirectory
  ): PutObjectCommandInput => {
    const extension = getExtension(upload.file.type)
    const key = `${directory}/${upload.id}${extension}`
    return {
      Bucket: bucketName,
      Key: key,
      Body: upload.file
    }
  }

  const createTagsForUpload = (upload: MediaServiceUpload, userId: string): Tag[] => {
    return [
      { Key: JOB_ID_TAG_KEY, Value: upload.jobId },
      { Key: AUTHOR_TAG_KEY, Value: userId },
      ...(upload.file.width ? [{ Key: IMAGE_WIDTH_KEY, Value: upload.file.width.toString() }] : []),
      ...(upload.file.height ? [{ Key: IMAGE_HEIGHT_KEY, Value: upload.file.height.toString() }] : [])
    ]
  }

  const createInputForUpload = (
    upload: MediaServiceUpload,
    putObjectCommandInput: PutObjectCommandInput
  ): CreateMediaItemInput => {
    return {
      id: upload.id,
      job: upload.jobId,
      date: upload.date.toISO(),
      ...(upload.type === MediaType.Document && { name: upload.name }),
      file: {
        bucket: putObjectCommandInput.Bucket ?? 'missing',
        key: putObjectCommandInput.Key ?? 'missing',
        mimeType: upload.file.type,
        region: appContext.region
      },
      type: upload.type,
      ...(isNumber(upload.file.width) &&
        isNumber(upload.file.height) && {
          metadata: {
            width: upload.file.width,
            height: upload.file.height
          }
        }),
      context: upload.context,
      subject: upload.subject,
      status: MediaStatus.WaitingForUpload,
      source: MediaSource.Technician
    }
  }

  const mediaService: IMediaService = {
    getUploads: () => uploads,

    getUpload: (id: string) => uploads.find((u) => u.id === id),

    upload: async (jobId, uploads, onCreateMediaItems) => {
      console.log(`mediaService: upload ${uploads.length} files`)
      setUploads((prevUploads) => [...prevUploads, ...uploads])

      // Check for duplicates before uploading
      console.log('checking for duplicate media items')
      const existingJobMedia = await getJobMedia(jobId)
      const existingMediaHashes = existingJobMedia.map((m) => m?.metadata?.sha256)

      const duplicateUploads = uploads.filter((u) => existingMediaHashes.includes(u.file.sha256))
      const duplicateUploadIds = duplicateUploads.map((u) => u.id)
      const newUploads = uploads.filter((u) => !existingMediaHashes.includes(u.file.sha256))
      setUploads((prevUploads) => prevUploads.filter((u) => !duplicateUploadIds.includes(u.id)))

      // Simulate onCreateMediaItems result for duplicate items so secondary processes can still apply (eg. add media to option)
      if (duplicateUploads.length > 0 && onCreateMediaItems) {
        const duplicateMediaItems: MediaItemFragment[] = compact(
          duplicateUploads.map((u) => {
            return existingJobMedia.find((m) => m?.metadata?.sha256 === u.file.sha256)
          })
        )
        console.log(' Simulate onCreateMediaItems for duplicates', duplicateMediaItems)
        onCreateMediaItems(duplicateMediaItems)
      }

      if (newUploads.length <= 0) {
        console.log('no new media items to upload')
        return
      }

      const input: CreateMediaItemsInput = {
        jobId,
        items: newUploads.map((upload) => {
          const putObjectRequest = createPutObjectRequestForUpload(upload)
          return createInputForUpload(upload, putObjectRequest)
        })
      }

      const result = await createMediaItems({
        variables: {
          input
        }
      })
      const mediaItems = result.data?.createMediaItems?.mediaItems
      if (!mediaItems?.length) {
        return
      }
      const mediaItemIds = mediaItems.map((m) => m.id)
      onCreateMediaItems && onCreateMediaItems(mediaItems)
      updateUploadStatus(mediaItemIds, MediaServiceUploadStatus.Uploading, 0)

      try {
        await Promise.all(
          newUploads.map(async (upload) => {
            console.log('starting s3 upload', upload.id)
            const params = createPutObjectRequestForUpload(upload)
            const tags = createTagsForUpload(upload, userId)
            console.log('uploading', params.Bucket, params.Key)

            const managedUpload = iamUtils.getManagedUpload({ params, tags })
            managedUpload.on('httpUploadProgress', (progress) => {
              const loaded = progress.loaded ?? 1
              const total = progress.total ?? 1
              const percentage = loaded / total
              updateUploadStatus([upload.id], MediaServiceUploadStatus.Uploading, percentage)
            })
            await managedUpload.done()
            updateUploadStatus([upload.id], MediaServiceUploadStatus.Complete, 1.0)
          })
        )
        clearUploads(mediaItemIds)
        return
      } catch (err) {
        console.error(err)
      }
    },

    uploadSignature: async (params: UploadSignatureParams): Promise<UploadSignatureResponse> => {
      const { dataUrl, jobId, context, subject, type } = params
      const imageId = v4()
      const fileName = `${imageId}.png`
      const key = `${uploadDirectory}/${fileName}`
      const file = await fetchLocalImageUrl({ url: dataUrl, fileName })
      const upload: MediaServiceUpload = {
        id: imageId,
        file,
        name: file.name,
        type,
        date: dateUtils.now(),
        jobId,
        context,
        subject,
        status: MediaServiceUploadStatus.Uploading,
        progress: 0
      }

      await mediaService.upload(jobId, [upload])
      const s3ObjectInput: S3ObjectInput = {
        bucket: bucketName,
        key: key,
        filename: fileName,
        mimeType: 'image/png',
        region: 'ap-southeast-2'
      }
      const photoInput: PhotoInput = {
        file: s3ObjectInput,
        id: imageId,
        created: DateTime.now().setZone(dateConfig.defaultTimezone).toISO()
      }
      return { s3Object: s3ObjectInput, photoInput }
    },
    uploadProductImages: async (props: UploadProductImagesParams) => {
      const { tenantId, files: uploads, onCreateMediaItems, onStartUpload } = props
      console.log(`mediaService: upload ${uploads.length} files`)
      setUploads((prevUploads) => [...prevUploads, ...uploads])

      const newUploads = uploads

      if (newUploads.length <= 0) {
        console.log('no new media items to upload')
        return
      }

      const input: CreateProductMediaItemsInput = {
        tenantId,
        items: newUploads.map((upload) => {
          const putObjectRequest = createPutObjectRequestForUpload(upload, productImageUploadDirectory)
          return createInputForUpload(upload, putObjectRequest)
        })
      }

      onStartUpload && onStartUpload()

      const result = await createProductMediaItems({
        variables: {
          input
        }
      })
      const mediaItems = result.data?.createProductMediaItems?.mediaItems
      if (!mediaItems?.length) {
        return
      }
      const mediaItemIds = mediaItems.map((m) => m.id)
      onCreateMediaItems && onCreateMediaItems(mediaItems)
      updateUploadStatus(mediaItemIds, MediaServiceUploadStatus.Uploading, 0)

      try {
        await Promise.all(
          newUploads.map(async (upload) => {
            console.log('starting s3 upload', upload.id)
            const params = createPutObjectRequestForUpload(upload, productImageUploadDirectory)
            const tags = createTagsForUpload(upload, userId)
            console.log('uploading', params.Bucket, params.Key)

            const managedUpload = iamUtils.getManagedUpload({ params, tags })
            managedUpload.on('httpUploadProgress', (progress) => {
              const loaded = progress.loaded ?? 1
              const total = progress.total ?? 1
              const percentage = loaded / total
              updateUploadStatus([upload.id], MediaServiceUploadStatus.Uploading, percentage)
            })
            await managedUpload.done()
            updateUploadStatus([upload.id], MediaServiceUploadStatus.Complete, 1.0)
          })
        )
        clearUploads(mediaItemIds)
        return
      } catch (err) {
        console.error(err)
      }
    }
  }

  return <MediaService.Provider value={mediaService}>{props.children}</MediaService.Provider>
}

export const getExtension = (fileType: string) => {
  switch (fileType) {
    case 'image/jpeg':
      return '.jpg'
    case 'image/png':
      return '.png'
    case 'image/webp':
    case 'application/pdf':
      return '.pdf'
    default:
      return '.webp'
  }
}

export const useMediaService = () => {
  const context = useContext(MediaService)
  if (!context) {
    throw new Error('needs MediaService as provider')
  }
  return context
}
