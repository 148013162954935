import { EuiContextMenuPanelItemDescriptor, EuiFlexGroup, EuiFlexItem, EuiLoadingSpinner } from '@elastic/eui'
import { dateConfig } from '@fallonsolutions/date'
import { DateTime } from 'luxon'
import { ScheduleEventDetailFragment, ScheduleEventFragment, ScheduleEventType } from '../../api/generated-types'
import { descriptionForEvent, subtitleForEvent, titleForEvent } from '../schedule-event-adapter'

export interface ScheduleEventContextMenuCopyToClipboardProps {
  event: ScheduleEventFragment
  loading: boolean
  eventDetail?: ScheduleEventDetailFragment
  onActionClick?: () => void
}

export const ScheduleEventContextMenuCopyToClipboard = ({
  event,
  eventDetail,
  loading,
  onActionClick
}: ScheduleEventContextMenuCopyToClipboardProps): EuiContextMenuPanelItemDescriptor => {
  const copyToClipboard = () => {
    console.log('copy to clipboard')
    const title = titleForEvent(event)
    const description = descriptionForEvent(event)
    const date =
      'DATE AND TIME:\n' +
      DateTime.fromISO(event.scheduled.from)
        .setZone(dateConfig.defaultTimezone)
        .toFormat(dateConfig.luxonFormat.shortDate)
    const subtitle = `Booking window: ${subtitleForEvent(event)}`
    const address = 'ADDRESS:\n' + eventDetail?.job?.property?.formattedAddress
    let notes = 'NOTES:\n' + (eventDetail?.job?.notes ?? eventDetail?.notes ?? '')
    if (event.type === ScheduleEventType.Appointment) {
      notes = notes + '\n\nWORK REQUIRED:\n' + (eventDetail?.job?.workRequired ?? '')
    }

    const eventAsText = [title, description, '', date, subtitle, '', address, '', notes]
    try {
      navigator.clipboard.writeText(eventAsText.join('\n'))
    } catch (err) {
      console.error('err copying text', err)
    }
    onActionClick && onActionClick()
  }

  return {
    name: (
      <EuiFlexGroup gutterSize="none" alignItems="center" justifyContent="spaceBetween">
        <EuiFlexItem grow={true}>Copy as text</EuiFlexItem>
        <EuiFlexItem grow={false}>{loading && <EuiLoadingSpinner />}</EuiFlexItem>
      </EuiFlexGroup>
    ),
    icon: 'copyClipboard',
    onClick: copyToClipboard,
    disabled: loading
  }
}
