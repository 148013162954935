export type ResultUpdater<T> = (partialResult: T, transform?: ((partialResult: T) => T) | undefined) => void

export const getResultUpdater = <T,>(result: T, onUpdate: (result: T) => void) => {
  const updateResult = (partialResult: T, transform?: (partialResult: T) => T) => {
    const combinedResult = { ...result, ...partialResult }
    const transformedResult = transform ? transform(combinedResult) : combinedResult
    onUpdate(transformedResult)
  }
  return {
    updateResult
  }
}
