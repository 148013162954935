import { EuiFlexGroup, EuiFlexItem } from '@elastic/eui'
import { isNil } from 'lodash-es'
import { JobSummaryAggregationFragment } from '../api/generated-types'
import { Metric, MetricType } from '../common/metrics'
import VerticalDivider from '../common/vertical-divider'

interface JobListMetricsProps {
  summary?: JobSummaryAggregationFragment
}

export const JobListMetrics = ({ summary }: JobListMetricsProps) => {
  const {
    count,
    sold,
    soldAverage,
    invoiced,
    invoicedAverage,
    optionsPresented,
    optionsPresentedAverage,
    optionsSold,
    optionsSoldAverage,
    optionSheetCount
  } = summary ?? {}

  return (
    <div>
      <div className="small-text" style={{ marginBottom: '12px' }}>
        Search summary
      </div>
      <EuiFlexGroup gutterSize="s" className="spacing">
        {!isNil(count) && !isNil(optionSheetCount) && (
          <EuiFlexItem grow={false}>
            <Metric
              primary={{ label: 'Jobs', value: count }}
              secondary={{
                label: 'Option Sheets',
                value: optionSheetCount
              }}
            />
          </EuiFlexItem>
        )}
        <EuiFlexItem grow={false}>
          <VerticalDivider />
        </EuiFlexItem>
        {!isNil(sold) && !isNil(soldAverage) && (
          <EuiFlexItem grow={false}>
            <Metric
              primary={{
                label: 'Total Sold',
                value: sold,
                type: MetricType.Currency
              }}
              secondary={{
                label: 'Avg. per job',
                value: soldAverage,
                type: MetricType.Currency
              }}
            />
          </EuiFlexItem>
        )}
        {!isNil(invoiced) && !isNil(invoicedAverage) && (
          <EuiFlexItem grow={false}>
            <Metric
              primary={{
                label: 'Total Invoiced',
                value: invoiced,
                type: MetricType.Currency
              }}
              secondary={{
                label: 'Avg. per job',
                value: invoicedAverage,
                type: MetricType.Currency
              }}
            />
          </EuiFlexItem>
        )}
        <EuiFlexItem grow={false}>
          <VerticalDivider />
        </EuiFlexItem>
        {!isNil(optionsPresented) && !isNil(optionsPresentedAverage) && (
          <EuiFlexItem grow={false}>
            <Metric
              primary={{
                label: 'Avg. Options Presented',
                value: optionsPresentedAverage,
                type: MetricType.Float
              }}
              secondary={{
                label: 'Total Options Presented',
                value: optionsPresented
              }}
            />
          </EuiFlexItem>
        )}
        {!isNil(optionsSold) && !isNil(optionsSoldAverage) && (
          <EuiFlexItem grow={false}>
            <Metric
              primary={{
                label: 'Avg. Options Sold',
                value: optionsSoldAverage,
                type: MetricType.Float
              }}
              secondary={{
                label: 'Total Options Sold',
                value: optionsSold
              }}
            />
          </EuiFlexItem>
        )}
        <EuiFlexItem grow={true} />
      </EuiFlexGroup>
      <div className="metrics__note" style={{ paddingTop: '9px' }}>
        Note numbers include total for each job even when presented, sold or invoiced at appointments outside the search
        period.
      </div>
    </div>
  )
}
