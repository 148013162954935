import { EuiButton, EuiSpacer } from '@elastic/eui'
import { EnquiryAbortReason, JobType, TradeType } from '../../../api/generated-types'
import { Callout } from '../../../common/callout'
import { makeHumanReadable } from '../../../common/utils'
import { CustomerSuppliedTopicActionResult } from '../../action-topic-customer-supplied'
import { CustomerSuppliedCompliant, IsItemAustralianCompliant } from '../../helpers/item-installer-questions'
import { QuestionDefinitions, getNotesGenerator } from '../../helpers/notes-generator'
import { OutOfScope } from '../../helpers/out-of-topic'
import { getResultUpdater } from '../../helpers/results-updater'
import { useWorkflow } from '../../helpers/workflow-provider'
import { FreeTextQuestionField } from '../../question-fields/free-text-question-field'
import { SingleSelectField } from '../../question-fields/single-select-question-field'
import { WorkflowActionProps } from '../../workflow-model'
import { ItemSupplier, TopicActionInput } from '../action-topic-model'
import { TopicWorkflowAction } from '../action-topic-view'
import { YesNoValue } from '../common/common-enums'

export enum NewOrSecondHand {
  New = 'New',
  SecondHand = 'SecondHand'
}

export interface TopicPlumbingSinkInstallActionResult extends CustomerSuppliedTopicActionResult {
  itemSupplier?: ItemSupplier
  wasSinkNewOrSecondHandWhenPurchased?: NewOrSecondHand
  installationLocation?: string
  preExistingTapConnections?: YesNoValue
  doYouWantUsToInstallTapConnections?: YesNoValue
}

const questionDefinitions: QuestionDefinitions<TopicPlumbingSinkInstallActionResult> = {
  itemSupplier: {
    question: 'Is this a Fallon supplied or customer supplied vanity/sink?',
    makeAnswerHumanReadable: makeHumanReadable
  },
  wasSinkNewOrSecondHandWhenPurchased: {
    question: 'Was the vanity/sink new or second-hand when purchased?',
    makeAnswerHumanReadable: makeHumanReadable
  },
  installationLocation: {
    question: 'Where in the home do you want to install it?'
  },
  preExistingTapConnections: {
    question: 'Do you have tap fixtures already in place?',
    makeAnswerHumanReadable: makeHumanReadable
  },
  doYouWantUsToInstallTapConnections: {
    question: 'Would you like us to install tap connections first?',
    makeAnswerHumanReadable: makeHumanReadable
  }
}
const notesGenerator = getNotesGenerator(questionDefinitions)

export const TopicPlumbingSinkInstallAction = (
  props: WorkflowActionProps<TopicActionInput, TopicPlumbingSinkInstallActionResult>
) => {
  const workflowContext = useWorkflow()
  const { result, input, onUpdate } = props
  const {
    itemSupplier,
    wasSinkNewOrSecondHandWhenPurchased,
    installationLocation,
    preExistingTapConnections,
    doYouWantUsToInstallTapConnections,
    australianCompliant
  } = result ?? {}
  const { jobIsCompliant, jobIsNotCompliant } = CustomerSuppliedCompliant({ australianCompliant })

  const onNext = () =>
    onUpdate({
      ...result,
      actionCompleted: true,
      trade: TradeType.Plumbing,
      category: 'Sink',
      type: JobType.Service,

      workRequiredNotes: generatedNotes,
      requirements: {
        attributes: [{ attributeId: 'GeneralPlumbing' }]
      }
    })

  const generatedNotes = notesGenerator.generateNotes(result ?? {})
  const updateResult = getResultUpdater(result ?? {}, onUpdate).updateResult

  const canContinue =
    itemSupplier === ItemSupplier.Fallon ||
    (itemSupplier === ItemSupplier.Customer &&
      wasSinkNewOrSecondHandWhenPurchased === NewOrSecondHand.New &&
      jobIsCompliant)

  const canComplete =
    canContinue &&
    !!installationLocation &&
    (preExistingTapConnections === YesNoValue.Yes ||
      (preExistingTapConnections === YesNoValue.No && !!doYouWantUsToInstallTapConnections))

  return (
    <TopicWorkflowAction input={input} onUpdate={onUpdate} result={result}>
      <SingleSelectField
        question={notesGenerator.getQuestion('itemSupplier')}
        options={[
          {
            id: ItemSupplier.Fallon,
            label: 'Fallon',
            icon: 'check'
          },
          {
            id: ItemSupplier.Customer,
            label: 'Customer',
            icon: 'user'
          }
        ]}
        answer={itemSupplier}
        changeAnswer={(itemSupplier) =>
          updateResult({
            itemSupplier,
            wasSinkNewOrSecondHandWhenPurchased: undefined,
            installationLocation: undefined
          })
        }
      />
      {itemSupplier === ItemSupplier.Customer && (
        <>
          <SingleSelectField
            question={notesGenerator.getQuestion('wasSinkNewOrSecondHandWhenPurchased')}
            options={[
              {
                id: NewOrSecondHand.New,
                label: 'New'
              },
              {
                id: NewOrSecondHand.SecondHand,
                label: 'Second-hand'
              }
            ]}
            answer={wasSinkNewOrSecondHandWhenPurchased}
            changeAnswer={(wasSinkNewOrSecondHandWhenPurchased) =>
              updateResult({ wasSinkNewOrSecondHandWhenPurchased })
            }
          />
          {wasSinkNewOrSecondHandWhenPurchased === NewOrSecondHand.SecondHand ? (
            <>
              <Callout type="script">Unfortunately Fallon’s don’t install second-hand vanities/sinks.</Callout>
              <EuiButton
                color="warning"
                onClick={() =>
                  workflowContext.abort({
                    abortReason: EnquiryAbortReason.CustomerNeedsAreOutOfService,
                    notes: 'Customer wanted to install a second-hand vanity/sink'
                  })
                }
              >
                Offer a voucher and end call
              </EuiButton>
            </>
          ) : (
            <>
              <IsItemAustralianCompliant
                updateResult={updateResult}
                itemSupplier={itemSupplier}
                suppliedAnswer={australianCompliant}
              />
              <OutOfScope issue={'Sink Installation'} isOutOfScope={jobIsNotCompliant} />
            </>
          )}
        </>
      )}
      {canContinue && (
        <>
          <FreeTextQuestionField
            question={notesGenerator.getQuestion('installationLocation')}
            answer={installationLocation}
            changeAnswer={(installationLocation) => updateResult({ installationLocation })}
          />
          <SingleSelectField
            question={notesGenerator.getQuestion('preExistingTapConnections')}
            options={[
              {
                id: YesNoValue.Yes,
                label: 'Yes',
                icon: 'check'
              },
              {
                id: YesNoValue.No,
                label: 'No',
                icon: 'cross'
              }
            ]}
            answer={preExistingTapConnections}
            changeAnswer={(preExistingTapConnections) => updateResult({ preExistingTapConnections })}
          />
          {preExistingTapConnections === YesNoValue.No && (
            <SingleSelectField
              question={notesGenerator.getQuestion('doYouWantUsToInstallTapConnections')}
              options={[
                {
                  id: YesNoValue.Yes,
                  label: 'Yes',
                  icon: 'check'
                },
                {
                  id: YesNoValue.No,
                  label: 'No',
                  icon: 'cross'
                }
              ]}
              answer={doYouWantUsToInstallTapConnections}
              changeAnswer={(doYouWantUsToInstallTapConnections) =>
                updateResult({ doYouWantUsToInstallTapConnections })
              }
            />
          )}
        </>
      )}

      <EuiSpacer />
      <EuiButton disabled={!canComplete} onClick={() => onNext()}>
        Next
      </EuiButton>
    </TopicWorkflowAction>
  )
}
