import { EuiButton, EuiSpacer, EuiText } from '@elastic/eui'
import { EnquiryAbortReason, JobType, TradeType } from '../../../api/generated-types'
import { Callout } from '../../../common/callout'
import { decameliseIfNotNil, makeHumanReadable } from '../../../common/utils'
import { CustomerSuppliedTopicActionResult } from '../../action-topic-customer-supplied'
import { CustomerSuppliedCompliant, IsItemAustralianCompliant } from '../../helpers/item-installer-questions'
import { QuestionDefinitions, getNotesGenerator } from '../../helpers/notes-generator'
import { OutOfScope } from '../../helpers/out-of-topic'
import { getResultUpdater } from '../../helpers/results-updater'
import { useWorkflow } from '../../helpers/workflow-provider'
import { FreeTextQuestionField } from '../../question-fields/free-text-question-field'
import { SingleSelectField } from '../../question-fields/single-select-question-field'
import { WorkflowActionProps } from '../../workflow-model'
import { InstallType, ItemInstaller, ItemSupplier, ServiceType, TopicActionInput } from '../action-topic-model'
import { TopicWorkflowAction } from '../action-topic-view'
import { reset } from '../common/common-utils'

export enum FanControlType {
  Remote = 'Remote',
  WallSwitch = 'WallSwitch'
}

export enum RepairToReplacement {
  Yes = 'Yes',
  No = 'No'
}

export enum InstallDate {
  LessThanOrEqualTo3Years = 'Less than 3 years ago',
  Over3Years = 'Over 3 years ago'
}

export interface TopicCeilingFanActionResult extends CustomerSuppliedTopicActionResult {
  serviceType?: ServiceType
  itemSupplier?: ItemSupplier
  itemInstaller?: ItemInstaller
  installDate?: InstallDate
  installType?: InstallType
  numberAndLocations?: string
  quantityAndLocation?: string
  controlType?: FanControlType
  repairToReplacement?: RepairToReplacement
}

const questionDefinitions: QuestionDefinitions<TopicCeilingFanActionResult> = {
  serviceType: {
    question: 'Is that an installation or repair?',
    statement: 'Service type:',
    makeAnswerHumanReadable: decameliseIfNotNil
  },
  itemSupplier: {
    question: 'Who is supplying the fan?',
    statement: 'Fan supplied by:',
    makeAnswerHumanReadable: decameliseIfNotNil
  },
  itemInstaller: {
    question: 'Who installed the fan?',
    statement: 'Fan installed by:',
    makeAnswerHumanReadable: decameliseIfNotNil
  },
  installDate: {
    question: 'When did Fallon install?',
    statement: 'Install date:',
    makeAnswerHumanReadable: makeHumanReadable
  },
  repairToReplacement: {
    question: 'Would you like me to book in a technician to come out and arrange this?',
    statement: 'Replace instead of repair:',
    makeAnswerHumanReadable: decameliseIfNotNil
  },
  installType: {
    question: 'Is this for brand new install or replacement?',
    statement: 'Install type:',
    makeAnswerHumanReadable: decameliseIfNotNil
  },
  quantityAndLocation: {
    question: 'How many fans and which rooms?',
    statement: 'Quantity and location(s):'
  },
  controlType: {
    question: 'With a remote or wall switch?',
    statement: 'Fan control type:',
    makeAnswerHumanReadable: decameliseIfNotNil
  }
}
const notesGenerator = getNotesGenerator(questionDefinitions)

export const TopicCeilingFanAction = (props: WorkflowActionProps<TopicActionInput, TopicCeilingFanActionResult>) => {
  const workflowContext = useWorkflow()
  const { result, input, onUpdate } = props
  const {
    serviceType,
    itemSupplier,
    itemInstaller,
    installDate,
    installType,
    quantityAndLocation,
    controlType,
    repairToReplacement,
    australianCompliant,
    jobDescription,
    customerType
  } = result ?? {}
  const { jobIsCompliant, jobIsNotCompliant } = CustomerSuppliedCompliant({ australianCompliant })

  const isRevisit =
    serviceType === ServiceType.Repair &&
    itemInstaller === ItemInstaller.Fallon &&
    installDate === InstallDate.LessThanOrEqualTo3Years
  const isOutsideRevisitScope =
    serviceType === ServiceType.Repair &&
    itemInstaller === ItemInstaller.Fallon &&
    installDate === InstallDate.Over3Years

  const installedByFallonWithinThreeYears =
    itemInstaller === ItemInstaller.Fallon &&
    serviceType === ServiceType.Repair &&
    installDate === InstallDate.LessThanOrEqualTo3Years

  const installedByFallonOverThreeYears =
    itemInstaller === ItemInstaller.Fallon && installDate === InstallDate.Over3Years
  const onNext = () =>
    onUpdate({
      ...result,
      actionCompleted: true,
      trade: TradeType.Electrical,
      category: 'CeilingFan',
      type: isRevisit ? JobType.Callback : JobType.Service,

      workRequiredNotes: generatedNotes,
      requirements: {
        attributes: [{ attributeId: 'GeneralElectrical' }]
      }
    })

  const generatedNotes = notesGenerator.generateNotes(result ?? {})
  const updateResult = getResultUpdater(result ?? {}, onUpdate).updateResult

  const canComplete =
    isRevisit ||
    isOutsideRevisitScope ||
    (!!serviceType &&
      (!!itemSupplier || !!itemInstaller) &&
      !!installType &&
      !!quantityAndLocation &&
      !!controlType &&
      jobIsCompliant)

  const bookReplacement = () => {
    return (
      <>
        <Callout type="script" className="callout--flat-list">
          <EuiText>
            <ul>
              <li>Unfortunately due to the complexity of ceiling fans we do not repair them</li>
              <li>It is more cost effective to replace them as you get an upgrade and a warranty</li>
            </ul>
          </EuiText>
        </Callout>
        <SingleSelectField
          question={notesGenerator.getQuestion('repairToReplacement')}
          options={[
            {
              id: RepairToReplacement.Yes,
              label: 'Yes, book in replacement',
              icon: 'check'
            },
            {
              id: RepairToReplacement.No,
              label: 'No thanks',
              icon: 'cross'
            }
          ]}
          answer={repairToReplacement}
          changeAnswer={(repairToReplacement) =>
            updateResult({
              repairToReplacement
            })
          }
        />
        {repairToReplacement === RepairToReplacement.No && (
          <>
            <Callout type="script">
              <EuiText>
                <p>
                  Can I please get your email address as I would like to send you through a <strong>$40 voucher</strong>{' '}
                  that you can use next time you chose to book with us
                </p>
              </EuiText>
            </Callout>
            <EuiSpacer />
            <EuiButton
              color="warning"
              onClick={() => workflowContext.abort({ abortReason: EnquiryAbortReason.CustomerNeedsAreOutOfService })}
            >
              End call
            </EuiButton>
          </>
        )}
      </>
    )
  }
  return (
    <TopicWorkflowAction input={input} onUpdate={onUpdate} result={result}>
      <SingleSelectField
        question={notesGenerator.getQuestion('serviceType')}
        options={[
          {
            id: ServiceType.Installation,
            label: 'Installation',
            icon: 'check'
          },
          {
            id: ServiceType.Repair,
            label: 'Repair',
            icon: 'wrench'
          }
        ]}
        answer={serviceType}
        changeAnswer={(serviceType) =>
          updateResult({
            ...reset(result ?? {}),
            customerType,
            serviceType,
            jobDescription
          })
        }
      />

      {serviceType === ServiceType.Repair && (
        <>
          <SingleSelectField
            question={notesGenerator.getQuestion('itemInstaller')}
            options={[
              {
                id: ItemInstaller.Fallon,
                label: 'Fallon installed',
                icon: 'check'
              },
              {
                id: ItemInstaller.SomeoneElse,
                label: 'Someone else installed',
                icon: 'user'
              }
            ]}
            answer={itemInstaller}
            changeAnswer={(itemInstaller) => updateResult({ itemInstaller })}
          />
        </>
      )}

      {serviceType === ServiceType.Repair && itemInstaller === ItemInstaller.SomeoneElse && <>{bookReplacement()}</>}

      {serviceType === ServiceType.Repair && itemInstaller === ItemInstaller.Fallon && (
        <>
          <SingleSelectField
            question={notesGenerator.getQuestion('installDate')}
            options={[
              {
                id: InstallDate.LessThanOrEqualTo3Years,
                label: 'Within last 3 years'
              },
              {
                id: InstallDate.Over3Years,
                label: 'Over 3 years agos'
              }
            ]}
            answer={installDate}
            changeAnswer={(installDate) => updateResult({ installDate })}
          />
          {itemInstaller === ItemInstaller.Fallon && !!installDate && installedByFallonWithinThreeYears && (
            <>
              <Callout type="note" title={'Revisit with no travel fee'} />

              <EuiSpacer />
            </>
          )}
          {installedByFallonOverThreeYears && <>{bookReplacement()}</>}
        </>
      )}

      {serviceType === ServiceType.Installation && (
        <>
          <SingleSelectField
            question={notesGenerator.getQuestion('itemSupplier')}
            options={[
              {
                id: ItemSupplier.Fallon,
                label: 'Fallon',
                icon: 'check'
              },
              {
                id: ItemSupplier.Customer,
                label: 'Customer',
                icon: 'user'
              }
            ]}
            answer={itemSupplier}
            changeAnswer={(itemSupplier) =>
              updateResult({
                itemSupplier,
                installType: undefined,
                quantityAndLocation: undefined,
                controlType: undefined
              })
            }
          />
          <IsItemAustralianCompliant
            updateResult={updateResult}
            itemSupplier={itemSupplier}
            suppliedAnswer={australianCompliant}
          />
        </>
      )}
      {jobIsNotCompliant ? (
        <OutOfScope issue={'Ceiling Fans'} isOutOfScope={true} />
      ) : (
        <>
          {(serviceType === ServiceType.Installation ||
            (serviceType === ServiceType.Repair && repairToReplacement === RepairToReplacement.Yes)) &&
            !installedByFallonWithinThreeYears && (
              <>
                <SingleSelectField
                  question={notesGenerator.getQuestion('installType')}
                  options={[
                    {
                      id: InstallType.NewInstall,
                      label: 'New install'
                    },
                    {
                      id: InstallType.Replacement,
                      label: 'Replacement'
                    }
                  ]}
                  answer={installType}
                  changeAnswer={(installType) => updateResult({ installType })}
                />

                <SingleSelectField
                  question={notesGenerator.getQuestion('controlType')}
                  options={[
                    {
                      id: FanControlType.Remote,
                      label: 'Remote'
                    },
                    {
                      id: FanControlType.WallSwitch,
                      label: 'Wall switch'
                    }
                  ]}
                  answer={controlType}
                  changeAnswer={(controlType) => updateResult({ controlType })}
                />

                <FreeTextQuestionField
                  question={notesGenerator.getQuestion('quantityAndLocation')}
                  answer={quantityAndLocation}
                  changeAnswer={(quantityAndLocation) => updateResult({ quantityAndLocation })}
                  rows={3}
                />
              </>
            )}
        </>
      )}

      <EuiSpacer />
      <EuiButton disabled={!canComplete} onClick={() => onNext()}>
        Next
      </EuiButton>
    </TopicWorkflowAction>
  )
}
