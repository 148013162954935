import { AppointmentRequirements } from '@fallonsolutions/appointment'
import { CustomerType, JobType, ServiceArea, TradeType } from '../../api/generated-types'
import { ContactActionResult } from '../actions/action-contact'
import { SelectedProperty } from '../actions/action-customer-property'
import { ContactRole } from '../actions/action-customer-qualification'
import { SelectedCustomer } from '../workflow-customer-model'
import { BaseActionInput } from '../workflow-model'

export interface TopicActionInput extends BaseActionInput {
  topicName: string
  customer?: SelectedCustomer
  property?: SelectedProperty
  contact?: ContactActionResult
  customerContactRole?: ContactRole
  existingCustomer?: boolean
  showJobDescription?: boolean // default: true
  showJobDescriptionFollowup?: boolean // default: true
  serviceArea?: ServiceArea
}

export interface ActionResult {
  value?: string // string representation of outcome
  actionCompleted?: boolean
}

export interface TopicActionResult extends ActionResult {
  trade?: TradeType
  type?: JobType
  category?: string
  customerType?: CustomerType
  requirements?: AppointmentRequirements
  workRequiredNotes?: string
  jobDescription?: string // customer's description of the issue to resolve
}

export enum ServiceType {
  Installation = 'Installation',
  Repair = 'Repair'
}

export const enum InstallType {
  NewInstall = 'NewInstall',
  Replacement = 'Replacement'
}

export const enum ItemSupplier {
  Fallon = 'Fallon',
  Customer = 'Customer'
}

export const enum ItemInstaller {
  Fallon = 'Fallon',
  SomeoneElse = 'SomeoneElse'
}
