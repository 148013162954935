import { useEffect, useState } from 'react'
import { Callout } from '../../common/callout'
import RegulatedComplianceMark from '../../static/images/regulated-compliance-mark.svg'
import { AustralianCompliant } from '../action-topic-customer-supplied'
import { SingleSelectField } from '../question-fields/single-select-question-field'
import { ItemSupplier } from '../topics/action-topic-model'
import { YesNoValue } from '../topics/common/common-enums'

const customerSuppliedQuestion = 'Do your supplied parts have the Australian compliance tick on them?'
// on button click, returns a result which is out of scope
export interface IsItemAustralianCompliantProps {
  updateResult: <T extends {}>(result: T) => void
  itemSupplier?: ItemSupplier
  suppliedAnswer?: AustralianCompliant
}

const complianceOptions = [
  {
    id: YesNoValue.Yes,
    label: 'Yes',
    icon: RegulatedComplianceMark
  },
  {
    id: YesNoValue.No,
    label: 'No',
    icon: 'cross'
  }
]
export const IsItemAustralianCompliant = (props: IsItemAustralianCompliantProps) => {
  // supplied answer is so we can default the question to yes in our tests
  const { updateResult, itemSupplier, suppliedAnswer } = props
  const [selectedAnswer, setSelectedAnswer] = useState<YesNoValue | undefined>(undefined)

  const handleAnswerChange = (answer: YesNoValue | undefined) => {
    if (answer === undefined) {
      return updateResult({ australianCompliant: undefined })
    }
    setSelectedAnswer(answer)
    const answerToAustralianType = answer === YesNoValue.Yes ? AustralianCompliant.Yes : AustralianCompliant.No
    updateResult({ australianCompliant: answerToAustralianType })
  }

  // if component is rendered, default to australian compliant to unknown,
  // this is because an undefined is treated as a yes i.e the question isn't rendered so the job must therefore be compliant
  useEffect(() => {
    !suppliedAnswer && updateResult({ australianCompliant: AustralianCompliant.Unknown })
  }, [])

  // hook to ensure that the australianCompliant field is properly updated on supplier change
  useEffect(() => {
    if (itemSupplier === ItemSupplier.Customer) {
      // ensure CSR has to select yes or no before proceeding if they change from fallon to customer
      updateResult({ australianCompliant: AustralianCompliant.Unknown })
      setSelectedAnswer(undefined)
      return
    }
    // if not customer, must be Fallon
    updateResult({ australianCompliant: AustralianCompliant.Yes })
  }, [itemSupplier])
  return (
    <>
      {itemSupplier === ItemSupplier.Customer && (
        <>
          <SingleSelectField
            question={customerSuppliedQuestion}
            options={complianceOptions}
            answer={selectedAnswer}
            hint="This looks like a triangle with a tick inside."
            changeAnswer={(answer) => handleAnswerChange(answer)}
          />
        </>
      )}
      {suppliedAnswer === AustralianCompliant.No && itemSupplier === ItemSupplier.Customer && (
        <>
          <Callout
            type="warning"
            data-test-id="workflow-question-hint"
            title="We cannot proceed with products not in compliance with Australian standards "
          />
        </>
      )}
    </>
  )
}

export interface AustralianCompliantProps {
  australianCompliant?: AustralianCompliant
}

// return whether the item is out of scope and whether it is compliant
export const CustomerSuppliedCompliant = (props: AustralianCompliantProps) => {
  const { australianCompliant } = props
  const jobIsNotCompliant = australianCompliant === AustralianCompliant.No
  const jobIsCompliant = australianCompliant === AustralianCompliant.Yes || !australianCompliant
  return { jobIsCompliant, jobIsNotCompliant }
}
