export interface VideoPlayerProps {
  url: string
  autoplay?: boolean
}

export const VideoPlayer = ({ url, ...props }: VideoPlayerProps) => {
  const autoplay = props.autoplay ?? false
  return (
    <video controls autoPlay={autoplay}>
      <source src={url} type="video/mp4" />
    </video>
  )
}
