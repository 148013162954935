import { EuiText } from '@elastic/eui'
import { Callout } from '../../../../common/callout'

export const SolarHotWaterDisclaimer = ({ isSolarHotWater }: { isSolarHotWater: boolean }) => {
  return (
    <>
      {isSolarHotWater && (
        <>
          <Callout type="warning">
            <EuiText>
              <p>DO NOT BOOK SOLAR HOT WATER JOBS ON 7 AM SLOT! BOOK FOR A 10AM - 1PM SLOT</p>
            </EuiText>
          </Callout>
        </>
      )}
    </>
  )
}
