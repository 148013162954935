import {
  EuiButton,
  EuiButtonEmpty,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiFormRow,
  EuiSpacer,
  EuiTitle
} from '@elastic/eui'
import { AsYouType } from 'libphonenumber-js'
import { first } from 'lodash-es'
import { useState } from 'react'
import { ContactInput } from '../../api/generated-types'
import { SuburbSelect, SuburbSelectOption, SuburbSelectOptionDetail } from '../../suburbs/suburb-select'

export const ContactCapture = ({
  contactInput,
  completed = false,
  onComplete,
  onCancel
}: {
  contactInput: ContactInput
  completed: boolean
  onComplete: (contact: ContactInput) => void
  onCancel: () => void
}) => {
  const [contact, setContact] = useState<ContactInput>(contactInput)

  const setFirstName = (value: string) => {
    const updatedContact = {
      ...contact,
      firstName: value
    }
    setContact(updatedContact)
  }

  const setLastName = (value: string) => {
    const updatedContact = {
      ...contact,
      lastName: value
    }
    setContact(updatedContact)
  }

  const onChangePhone = (phone: string) => {
    const formattedPhone = new AsYouType('AU').input(phone)
    const updatedContact = {
      ...contact,
      phone: formattedPhone
    }
    setContact(updatedContact)
  }

  const onChangeEmail = (email: string) => {
    const updatedContact = {
      ...contact,
      email
    }
    setContact(updatedContact)
  }

  const handleSuburbSelect = (suburbs: SuburbSelectOptionDetail[]) => {
    const selectedSuburb = first(suburbs)
    console.log('contact-capture', 'selectedSuburb', selectedSuburb)
    const updatedContact = {
      ...contact,
      suburb: selectedSuburb ? { id: selectedSuburb.id, label: selectedSuburb.label } : undefined
    }
    setContact(updatedContact)
  }

  const selectedSuburbOptions: SuburbSelectOption[] =
    contact.suburb && contact.suburb.id && contact.suburb.label
      ? [{ id: contact.suburb.id, label: contact.suburb.label, color: '' }]
      : []

  console.log('contact-capture', 'selectedSuburbOptions', selectedSuburbOptions)
  return (
    <>
      <EuiTitle size="xs">
        <h2>Contact details</h2>
      </EuiTitle>
      <EuiSpacer size="m" />
      {!completed && (
        <>
          <EuiForm>
            <EuiFlexGroup>
              <EuiFlexItem>
                <EuiFormRow label="First name">
                  <EuiFieldText value={contact.firstName} onChange={(e) => setFirstName(e.target.value)} />
                </EuiFormRow>
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiFormRow label="Last name">
                  <EuiFieldText value={contact.lastName ?? ''} onChange={(e) => setLastName(e.target.value)} />
                </EuiFormRow>
              </EuiFlexItem>
            </EuiFlexGroup>
            <EuiSpacer size="m" />
            <EuiFormRow label="Suburb">
              <SuburbSelect suburbs={selectedSuburbOptions} onChangeSuburbs={handleSuburbSelect} />
            </EuiFormRow>
            <EuiSpacer size="m" />

            <EuiFlexGroup alignItems="flexEnd">
              <EuiFlexItem grow={true}>
                <EuiFormRow label="Phone">
                  <EuiFieldText
                    style={{ width: '140px' }}
                    value={contact.phone}
                    onChange={(e) => onChangePhone(e.target.value)}
                  />
                </EuiFormRow>
              </EuiFlexItem>
            </EuiFlexGroup>
            <EuiFlexGroup alignItems="flexEnd">
              <EuiFlexItem grow={true}></EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiButtonEmpty onClick={onCancel}>Cancel</EuiButtonEmpty>
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiButton onClick={() => onComplete(contact)} color="primary">
                  Keep
                </EuiButton>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiForm>
          <EuiSpacer />
        </>
      )}
    </>
  )
}
