import { compact, first } from 'lodash-es'
import {
  AppointmentFragment,
  GetJobAppointmentsDocument,
  LegacyBookingWindow,
  UserFragment
} from '../api/generated-types'
import { EuiBasicTable, EuiBasicTableColumn, EuiFlexGroup, EuiFlexItem, EuiRadio } from '@elastic/eui'
import { DateTime } from 'luxon'
import { dateConfig } from '@fallonsolutions/date'
import { useQuery } from '@apollo/client'
import { Callout } from '../common/callout'
import UserLink from '../users/user-link'

interface JobAppointmentsSelectProps {
  jobId: string
  selectedAppointmentId: string | undefined
  onSelect: (appointment: AppointmentFragment) => void
}

export const JobAppointmentsSelect = (props: JobAppointmentsSelectProps) => {
  const { jobId, selectedAppointmentId, onSelect } = props

  const { data, loading, error } = useQuery(GetJobAppointmentsDocument, {
    variables: { id: jobId }
  })
  const appointments = compact(data?.getJob?.appointments ?? [])

  return error ? (
    <Callout type="failure" title="Error loading appointments for job" />
  ) : (
    <JobAppointmentsTable
      appointments={appointments}
      onSelect={onSelect}
      selectedAppointmentId={selectedAppointmentId}
      loading={loading}
    />
  )
}

interface JobAppointmentsTableProps {
  selectedAppointmentId: string | undefined
  appointments: AppointmentFragment[]
  onSelect: (appointment: AppointmentFragment) => void
  loading: boolean
}

export const JobAppointmentsTable = (props: JobAppointmentsTableProps) => {
  const { onSelect, loading, selectedAppointmentId, appointments } = props

  const renderTime = (date: string | undefined) => {
    return date
      ? DateTime.fromISO(date, { zone: dateConfig.defaultTimezone }).toFormat(dateConfig.luxonFormat.time).toLowerCase()
      : ''
  }

  const renderDate = (date: string | undefined) => {
    return date
      ? DateTime.fromISO(date, { zone: dateConfig.defaultTimezone }).toFormat(dateConfig.luxonFormat.shortDate)
      : ''
  }

  console.log('appointments', appointments)

  const columns: EuiBasicTableColumn<AppointmentFragment>[] = [
    {
      field: 'scheduled.from',
      name: 'Date',
      width: '120px',
      render: (date: string | undefined, appointment: AppointmentFragment) => (
        <EuiFlexGroup gutterSize="none" alignItems="center">
          <EuiFlexItem grow={false}>
            <EuiRadio
              checked={appointment.id === selectedAppointmentId}
              onChange={() => onSelect(appointment)}
              style={{ marginRight: '12px' }}
            />
          </EuiFlexItem>
          <EuiFlexItem grow={false}>{renderDate(date)}</EuiFlexItem>
        </EuiFlexGroup>
      )
    },
    {
      field: 'legacyBookingWindow',
      name: 'Booking window',
      width: '130px',
      render: (legacyBookingWindow: LegacyBookingWindow | undefined) => legacyBookingWindowLabel(legacyBookingWindow)
    },
    {
      field: 'technicians',
      name: 'Technician',
      render: (technicians: UserFragment[]) => {
        const technician = first(technicians)
        return technician ? <UserLink user={technician} /> : 'No technician assigned'
      }
    },
    {
      field: 'status.status',
      name: 'Status'
    },
    {
      field: 'bookingWindow.from',
      name: 'Scheduled',
      render: (date: string | undefined, appointment: AppointmentFragment) => {
        const from = renderTime(appointment.scheduled?.from)
        const to = renderTime(appointment.scheduled?.to)
        return `${from} - ${to}`
      }
    }
  ]

  return (
    <EuiBasicTable
      className="basic-table--minimal"
      loading={loading}
      items={appointments}
      columns={columns}
      rowProps={(appointment) => ({ onClick: () => onSelect(appointment) })}
      noItemsMessage={loading ? 'Searching...' : 'No appointments found'}
    />
  )
}

const legacyBookingWindowLabel = (bookingWindow: LegacyBookingWindow | undefined): string => {
  switch (bookingWindow) {
    case LegacyBookingWindow.Guarantee7:
      return 'Guaranteed 7am'
    case LegacyBookingWindow.Window7to8:
      return '7 - 8am'
    case LegacyBookingWindow.Window8to11:
      return '8am - 11am'
    case LegacyBookingWindow.Window10to13:
      return '10am - 1pm'
    case LegacyBookingWindow.Window10to14:
      return '10am - 2pm'
    case LegacyBookingWindow.Window12to16:
      return '12pm - 4pm'
    case LegacyBookingWindow.ToBeAdvised:
      return 'To be advised'
    case LegacyBookingWindow.AfterHours:
      return 'After hours'
    default:
      return 'Unknown'
  }
}
