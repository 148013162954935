import { useMutation } from '@apollo/client'
import { EuiButtonEmpty, EuiContextMenuItem, EuiContextMenuPanel, EuiIcon, EuiPopover, EuiText } from '@elastic/eui'
import { useState } from 'react'
import { JobCSVReportAudience, JobFilters, RequestJobCSVDocument } from '../api/generated-types'
import { useMixpanel } from '../app/mixpanel-provider'

interface JobCSVButtonProps {
  filters: JobFilters
  resultCount: number
}

export const JobCSVButton = (props: JobCSVButtonProps) => {
  const mixpanel = useMixpanel()
  const { filters, resultCount } = props

  const modelName = resultCount === 1 ? 'job' : 'jobs'
  const rowExportLimit = 10000
  const disabled = resultCount <= 0 || resultCount > rowExportLimit
  const disabledMessage =
    resultCount > rowExportLimit
      ? `Cannot export more than ${rowExportLimit} ${modelName}`
      : `Cannot export zero ${modelName}`

  const [showSuccess, setShowSuccess] = useState(false)
  const [isPopoverOpen, setPopover] = useState(false)
  const [requestCSV, { data, loading, error }] = useMutation(RequestJobCSVDocument)

  const closePopover = () => setPopover(false)
  const buttonClick = () => setPopover(!isPopoverOpen)

  const handleRequestCSV = (audience: JobCSVReportAudience) => {
    closePopover()
    setShowSuccess(true)
    setTimeout(() => setShowSuccess(false), 3000)
    mixpanel?.track('ExportedJobCSV')
    return requestCSV({
      variables: {
        input: {
          filters,
          audience
        }
      }
    })
  }

  const successView = (
    <EuiText size="s" style={{ color: '#34C759' }}>
      Export requested
      <EuiIcon type="checkInCircleFilled" color="#34C759" style={{ marginLeft: '3px' }} />
    </EuiText>
  )
  const errorView = <EuiText size="s">Error encountered</EuiText>
  const button = (
    <EuiButtonEmpty
      size="xs"
      flush="both"
      onClick={buttonClick}
      iconType="exportAction"
      iconSide="right"
      isLoading={loading}
      disabled={disabled}
      title={disabled ? disabledMessage : ''}
    >
      {disabled ? disabledMessage : `Export ${resultCount} ${modelName} to CSV`}
    </EuiButtonEmpty>
  )

  const exportOptions = [
    <EuiContextMenuItem
      key="Job export for General audience"
      onClick={() => {
        handleRequestCSV(JobCSVReportAudience.General)
      }}
    >
      General (all columns)
    </EuiContextMenuItem>,
    <EuiContextMenuItem
      key="Job export for Sales"
      onClick={() => {
        handleRequestCSV(JobCSVReportAudience.Sales)
      }}
    >
      Sales
    </EuiContextMenuItem>
  ]

  const popover = (
    <EuiPopover
      id="export-popover"
      button={button}
      isOpen={isPopoverOpen}
      closePopover={closePopover}
      panelPaddingSize="none"
      anchorPosition="downLeft"
    >
      <EuiContextMenuPanel items={exportOptions} />
    </EuiPopover>
  )

  const success = data?.requestJobCSVv2?.csvRequest?.id && showSuccess
  const dataView = success ? successView : popover

  return <div>{error ? errorView : dataView}</div>
}
