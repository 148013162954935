import { Discount } from '../../../api/generated-types'
import { Callout } from '../../../common/callout'
import { EuiCheckboxGroup, EuiDescriptionList, EuiSpacer } from '@elastic/eui'
import { ReactNode, useMemo, useState } from 'react'
import { EuiCheckboxGroupIdToSelectedMap } from '@elastic/eui/src/components/form/checkbox/checkbox_group'
import { useAppConfigLoader } from '../../../config/app-config-loader-provider'

type EuiListItem = { title: NonNullable<ReactNode>; description: NonNullable<ReactNode> }

const newCustomerTitle = 'New customer'

const DISCOUNT_RULES: EuiListItem[] = [
  {
    title: 'HWS bookings',
    description: `If customer objects, we give half price travel fee on selected days (Tues – Thurs) and only
        with Mike’s okay`
  },
  {
    title: 'Electrical P1 Job bookings',
    description: 'If customer objects, we give half price travel fee'
  },
  {
    title: newCustomerTitle,
    description:
      'If customer is new and the travel fee is the standard $88, the customer is eligible to have their travel fee waived'
  }
]

export interface DiscountViewProps {
  handleDiscountChanged: (discount: Discount | undefined) => void
  discounts: Discount[]
}

export const DiscountView = (props: DiscountViewProps) => {
  const { handleDiscountChanged, discounts } = props
  const tenant = useAppConfigLoader().data?.tenant
  const newMemberEligibleForFreeTravelFee = tenant?.modules?.booking?.freeCustomerTravelFeeFlag === true
  const discountOptionGroup = useMemo(() => discounts.map((d) => ({ id: d.id, label: d.name })), [discounts])
  const [highlightedDiscount, setHighlightedDiscount] = useState<EuiCheckboxGroupIdToSelectedMap>({})
  const onChangeOption = (optionId: string) => {
    const revertOtherSelectionsToFalse: EuiCheckboxGroupIdToSelectedMap = {}
    Object.keys(highlightedDiscount).map((d) => {
      revertOtherSelectionsToFalse[d] = false
    })

    const newHighlightedDiscounts = {
      ...revertOtherSelectionsToFalse,
      ...{
        [optionId]: !highlightedDiscount[optionId]
      }
    }

    const optionHighlighted = newHighlightedDiscounts[optionId]
    setHighlightedDiscount(newHighlightedDiscounts)
    const activeDiscount = optionHighlighted ? discounts.find((d) => d.id === optionId) : undefined
    handleDiscountChanged(activeDiscount)
  }

  const lineItems = newMemberEligibleForFreeTravelFee
    ? DISCOUNT_RULES
    : DISCOUNT_RULES.filter((rule) => rule.title !== newCustomerTitle)

  return (
    <>
      <Callout type="note" title={'Discount Rules'} className="callout--flat-list">
        Please note you are only able to apply discount in the following scenarios
        <EuiSpacer />
        <EuiDescriptionList listItems={lineItems} />
        <EuiSpacer size="l" />
        <EuiCheckboxGroup
          options={discountOptionGroup}
          idToSelectedMap={highlightedDiscount}
          onChange={onChangeOption}
        />
      </Callout>
      <EuiSpacer size="l" />
    </>
  )
}
