import { EuiButtonIcon, EuiContextMenu, EuiContextMenuPanelDescriptor, EuiPopover } from '@elastic/eui'
import { MoneyUtils } from '@fallonsolutions/money'
import { compact } from 'lodash-es'
import { useState } from 'react'
import { AttendedStatusType, JobFragment, JobSource, JobStatusType } from '../api/generated-types'
import { useAuthenticated } from '../auth/authenticated-context'
import { CreateReviewForm } from '../reviews/create-review-form'
import { CreateFutileJobApprovalForm } from './create-futile-job-approval-form'
import { JobCancelForm } from './job-cancel-form'
import { JobClassificationEdit } from './job-classification-edit'
import { JobPropertyChange } from './job-property-change'
import { JobRequirementsEdit } from './job-requirements-edit'
import { JobRestoreForm } from './job-restore-form'

export enum JobContextMenuActionType {
  EditJobClassification,
  EditJobRequirements,
  ChangeJobProperty,
  ApproveFutileJob,
  CancelJob,
  RestoreJob,
  CreateReview
}

export interface JobContextMenuAction {
  type: JobContextMenuActionType
  job: JobFragment
}

export interface JobContextMenuProps {
  job: JobFragment
  createNewJob?: (link: boolean) => void
}

export const JobContextMenu = ({ job, createNewJob }: JobContextMenuProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const [action, setAction] = useState<JobContextMenuAction | undefined>(undefined)

  const userFragment = useAuthenticated().userFragment
  const canEditJobRequirements = userFragment.permissions?.customDiscounts === true
  const canApproveFutileJob = userFragment.permissions?.jobApproval === true
  const canCreateReview = userFragment.permissions?.manageReviews === true
  const canCancelJob =
    job.source === JobSource.Platform &&
    job.attended !== AttendedStatusType.Attended &&
    job.status?.status !== JobStatusType.Cancelled &&
    job.status?.status !== JobStatusType.Complete
  const canRestoreJob = job.source === JobSource.Platform && job.status?.status === JobStatusType.Cancelled
  const optionSoldAmount = MoneyUtils.fromString(job.acceptedPrice?.subtotal.total.amount ?? '0.0')
  const isFutileJob = optionSoldAmount.getAmount() <= 0

  const onSelectAction = (action: JobContextMenuAction | undefined) => {
    setIsOpen(false)
    setAction(action)
  }

  const panels = [
    {
      id: 0,
      items: [
        {
          name: 'Create new job (linked)',
          icon: 'wrench',
          onClick: () => createNewJob?.(true)
        },
        {
          name: 'Create new job (un-linked)',
          icon: 'wrench',
          onClick: () => createNewJob?.(false)
        },
        {
          isSeparator: true,
          key: 'sep'
        },
        {
          name: 'Edit job classification',
          icon: 'aggregate',
          onClick: () => onSelectAction({ job, type: JobContextMenuActionType.EditJobClassification })
        },
        ...(canEditJobRequirements
          ? [
              {
                name: 'Edit requirements',
                icon: 'documentEdit',
                onClick: () => onSelectAction({ job, type: JobContextMenuActionType.EditJobRequirements })
              }
            ]
          : []),
        ...(canApproveFutileJob && isFutileJob
          ? [
              {
                name: 'Approve futile job',
                icon: 'lockOpen',
                onClick: () => onSelectAction({ job, type: JobContextMenuActionType.ApproveFutileJob })
              }
            ]
          : []),
        ...(canCancelJob
          ? [
              {
                name: 'Cancel job',
                icon: 'error',
                onClick: () => onSelectAction({ job, type: JobContextMenuActionType.CancelJob })
              }
            ]
          : []),
        ...(canRestoreJob
          ? [
              {
                name: 'Restore job',
                icon: 'help',
                onClick: () => onSelectAction({ job, type: JobContextMenuActionType.RestoreJob })
              }
            ]
          : []),
        ...(canCreateReview
          ? [
              {
                name: 'Create review',
                icon: 'starEmptySpace',
                onClick: () => onSelectAction({ job, type: JobContextMenuActionType.CreateReview })
              }
            ]
          : [])
      ]
    }
  ] as EuiContextMenuPanelDescriptor[]

  const clearAction = () => setAction(undefined)

  return (
    <>
      <EuiPopover
        isOpen={isOpen}
        button={
          <EuiButtonIcon
            aria-label="show actions"
            iconType="boxesHorizontal"
            color="text"
            onClick={() => setIsOpen(true)}
          />
        }
        closePopover={() => setIsOpen(false)}
        ownFocus={true}
        panelPaddingSize="none"
      >
        <EuiContextMenu initialPanelId={0} panels={panels} />
      </EuiPopover>

      {action?.type === JobContextMenuActionType.EditJobRequirements && (
        <JobRequirementsEdit job={job} closeModal={clearAction} />
      )}
      {action?.type === JobContextMenuActionType.ChangeJobProperty && (
        <JobPropertyChange job={job} closeModal={clearAction} />
      )}
      {action?.type === JobContextMenuActionType.EditJobClassification && (
        <JobClassificationEdit job={job} closeModal={clearAction} />
      )}
      {action?.type === JobContextMenuActionType.CancelJob && <JobCancelForm job={job} closeModal={clearAction} />}
      {action?.type === JobContextMenuActionType.RestoreJob && <JobRestoreForm job={job} closeModal={clearAction} />}
      {action?.type === JobContextMenuActionType.ApproveFutileJob && (
        <CreateFutileJobApprovalForm job={job} closeModal={clearAction} />
      )}
      {action?.type === JobContextMenuActionType.CreateReview && (
        <CreateReviewForm
          customerId={job.customer?.id}
          jobIds={[job.id]}
          userIds={compact(job.technicians).map((t) => t.id)}
          onClose={clearAction}
        />
      )}
    </>
  )
}
