import { SingleSelectField } from '../../question-fields/single-select-question-field'
import { NotesGenerator } from '../../helpers/notes-generator'
import { ResultUpdater } from '../../helpers/results-updater'
import { PoolLeakLocation, TopicPoolActionResult } from './action-topic-pool-model'
import { map } from 'lodash-es'

export interface PoolLeaksProps {
  result?: TopicPoolActionResult
  notesGenerator: NotesGenerator<TopicPoolActionResult>
  updateResult: ResultUpdater<TopicPoolActionResult>
}

export const PoolLeaks = (props: PoolLeaksProps) => {
  const { result, notesGenerator, updateResult } = props
  const { leakLocation } = result ?? {}
  return (
    <>
      <SingleSelectField
        question={notesGenerator.getQuestion('leakLocation')}
        options={map(PoolLeakLocation, (id) => ({ id }))}
        answer={leakLocation}
        changeAnswer={(leakLocation) => updateResult({ leakLocation })}
      />
    </>
  )
}
