import { EuiButton, EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiSpacer, EuiText } from '@elastic/eui'
import { dateConfig } from '@fallonsolutions/date'
import { compact } from 'lodash-es'
import { DateTime } from 'luxon'
import ReactMarkdown from 'react-markdown'
import { Callout } from '../../common/callout'
import { formatPhone } from '../../common/phone'
import { decamelise } from '../../common/utils'
import { WorkflowAction } from '../workflow-action'
import { SelectedCustomer } from '../workflow-customer-model'
import { BaseActionInput, BaseActionResult, WorkflowActionProps } from '../workflow-model'
import { SelectedProperty } from './action-customer-property'
import { ReservationDetail } from './schedule-reservation/action-schedule-reservation'

export interface JobSummaryActionInput extends BaseActionInput {
  customer: SelectedCustomer
  property: SelectedProperty
  reservationDetail: ReservationDetail
  defaultWorkRequiredNotes: string
  defaultInternalNotes: string
}

export type JobSummaryActionResult = BaseActionResult

export const JobSummaryAction = (props: WorkflowActionProps<JobSummaryActionInput, JobSummaryActionResult>) => {
  const { input, onUpdate, result } = props
  const { customer, property, reservationDetail, defaultWorkRequiredNotes, defaultInternalNotes } = input ?? {}
  const { actionCompleted } = result ?? {}

  const onContinue = () => onUpdate({ ...result, actionCompleted: true })
  const clearResult = () => onUpdate({ ...result, actionCompleted: false })

  const now = DateTime.now().setZone(dateConfig.defaultTimezone)
  const tomorrow = now.plus({ days: 1 })
  const fromDate = DateTime.fromISO(reservationDetail.startDate, { zone: dateConfig.defaultTimezone })
  const isToday = now.hasSame(fromDate, 'day')
  const isTomorrow = tomorrow.hasSame(fromDate, 'day')
  const dateString = isToday
    ? 'today'
    : isTomorrow
      ? 'tomorrow'
      : `on ${fromDate.toFormat(dateConfig.luxonFormat.shortDate)}`

  const fromString = reservationDetail.timeWindow
    ? DateTime.fromISO(reservationDetail.timeWindow.from, { zone: dateConfig.defaultTimezone })
        .toFormat(dateConfig.luxonFormat.time)
        .toLowerCase()
    : undefined
  const toString = reservationDetail.timeWindow
    ? DateTime.fromISO(reservationDetail.timeWindow.to, { zone: dateConfig.defaultTimezone })
        .toFormat(dateConfig.luxonFormat.time)
        .toLowerCase()
    : undefined

  const value = actionCompleted ? compact([formatPhone(customer.phone ?? ''), customer.email]).join(' | ') : undefined

  return (
    <WorkflowAction title="Job summary" value={value} onClickChange={clearResult} editable={input.editable}>
      <EuiFlexGroup>
        <EuiFlexItem grow={true}>
          <EuiFormRow label="Work required (to be shared with customer)" fullWidth>
            <>
              <EuiSpacer size="s" />
              <ReactMarkdown children={defaultWorkRequiredNotes} className="markdown-body markdown-body--compact" />
            </>
          </EuiFormRow>
        </EuiFlexItem>
        <EuiFlexItem grow={true}>
          <EuiFormRow label="Internal notes for technician and DSR" fullWidth>
            <>
              <EuiSpacer size="s" />
              <ReactMarkdown children={defaultInternalNotes} className="markdown-body markdown-body--compact" />
            </>
          </EuiFormRow>
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiSpacer />

      <Callout type="script" title="Summary" data-test-id="job-summary-container">
        <EuiText style={{ fontSize: '18px', lineHeight: '24px' }}>
          {fromString && toString ? (
            <>
              <div>
                We are coming out to your property at <strong>{property.label}</strong>
              </div>
              {reservationDetail.reservedScheduleEventId && (
                <>
                  <EuiSpacer size="m" />
                  <div>
                    <strong>{dateString}</strong> arriving between <strong>{fromString}</strong> and{' '}
                    <strong>{toString}</strong>
                  </div>
                </>
              )}
              <EuiSpacer size="m" />
            </>
          ) : (
            <>
              <div>
                We are coming out to your property at <strong>{property.label}</strong>
              </div>
              <EuiSpacer size="m" />
              <div>
                job status is{' '}
                <strong>
                  {reservationDetail.bookingWindow.label}
                  {reservationDetail.delayedJobStatus ? ` - ${decamelise(reservationDetail.delayedJobStatus)}` : ''}
                </strong>
              </div>
              <EuiSpacer size="m" />
            </>
          )}

          <div>and we are going to... (paraphrase work required)</div>
          <EuiSpacer size="m" />
          {customer.email ? (
            <>
              <div>
                and we will send a confirmation email to <strong>{customer.email}</strong>
              </div>
              <EuiSpacer size="m" />
            </>
          ) : (
            <>
              <div>
                and confirming you have <strong>no email address</strong>
              </div>
              <EuiSpacer size="m" />
            </>
          )}
          <div>
            and will call you on <strong>{formatPhone(customer.phone ?? '')}</strong>
          </div>
          <EuiSpacer size="l" />
          <div>And a reminder that payment is required on the day</div>
          <EuiSpacer size="l" />
          <div>Does that make sense?</div>
        </EuiText>
      </Callout>

      <EuiSpacer />
      <EuiButton onClick={() => onContinue()}>Next</EuiButton>
    </WorkflowAction>
  )
}
