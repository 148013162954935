import { EuiFormRow } from '@elastic/eui'
import { map } from 'lodash-es'
import { ReviewSource } from '../api/generated-types'
import { ExtendedButtonGroup, ExtendedButtonOption } from '../common/extended-button-group'
import { makeHumanReadable } from '../common/utils'

export interface ReviewFormSourceProps {
  source: ReviewSource
  setSource: (source: ReviewSource) => void
}

export const ReviewFormSource = ({ source, setSource }: ReviewFormSourceProps) => {
  const primaryReviewSourceOptions: ExtendedButtonOption[] = [
    ReviewSource.Google,
    ReviewSource.Facebook,
    ReviewSource.ProductReview
  ].map((s) => ({
    id: s,
    text: makeHumanReadable(s)
  }))
  const additionalReviewSourceOptions: ExtendedButtonOption[] = map(ReviewSource, (s) => ({
    id: s,
    text: makeHumanReadable(s)
  })).filter((s) => !primaryReviewSourceOptions.map((o) => o.id).includes(s.id))

  return (
    <EuiFormRow label="Source" fullWidth>
      <ExtendedButtonGroup
        legend="Source"
        otherLabel="Other…"
        selected={source}
        onChange={(s) => setSource(s as ReviewSource)}
        primaryOptions={primaryReviewSourceOptions}
        additionalOptions={additionalReviewSourceOptions}
      />
    </EuiFormRow>
  )
}
