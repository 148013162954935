import { EuiButton, EuiSpacer, EuiText } from '@elastic/eui'
import { CustomerType, EnquiryAbortReason, JobType, TradeType } from '../../../api/generated-types'
import { Callout } from '../../../common/callout'
import { decameliseIfNotNil } from '../../../common/utils'
import { QuestionDefinitions, getNotesGenerator } from '../../helpers/notes-generator'
import { getResultUpdater } from '../../helpers/results-updater'
import { TransferScript } from '../../helpers/transfer'
import { useWorkflow } from '../../helpers/workflow-provider'
import { FreeTextQuestionField } from '../../question-fields/free-text-question-field'
import { SingleSelectField } from '../../question-fields/single-select-question-field'
import { WorkflowActionProps } from '../../workflow-model'
import { ItemInstaller, TopicActionInput, TopicActionResult } from '../action-topic-model'
import { TopicWorkflowAction } from '../action-topic-view'
import { YesNoValue } from '../common/common-enums'

export enum SolarInstallType {
  ExistingSystem = 'ExistingSystem',
  NewSystem = 'NewSystem'
}

export enum SolarBatteryJobType {
  Install = 'Install',
  Inspection = 'Inspection',
  Repair = 'Repair'
}

export enum SolarCategory {
  SolarBatteries = 'SolarBatteries',
  Quote = 'Quote',
  Inspection = 'Inspection',
  Fault = 'Fault',
  SolarOffGrid = 'SolarOffGrid'
}

export interface TopicSolarActionResult extends TopicActionResult {
  solarCategory?: SolarCategory
  itemInstaller?: ItemInstaller
  batteryInstaller?: ItemInstaller
  installType?: SolarInstallType
  existingSystemHasBattery?: YesNoValue
  solarSystemAge?: string
  natureOfTheIssue?: string
  transferred?: boolean
  solarBatteryJobType?: SolarBatteryJobType
  batteryAge?: string
  reasonForUpgrade?: string
}

export const solarQuestionDefinitions: QuestionDefinitions<TopicSolarActionResult> = {
  solarCategory: {
    question: 'What is the most relevant solar category?',
    statement: 'Solar category:'
  },
  itemInstaller: {
    question: 'Who installed the system?',
    statement: 'Solar system installed by:',
    makeAnswerHumanReadable: decameliseIfNotNil
  },
  batteryInstaller: {
    question: 'Who installed the battery?',
    statement: 'Solar battery installed by:',
    makeAnswerHumanReadable: decameliseIfNotNil
  },
  installType: {
    question: 'Is this for a new or existing system?',
    statement: 'Install type:',
    makeAnswerHumanReadable: decameliseIfNotNil
  },
  solarSystemAge: {
    question: 'Age of the system?',
    statement: 'Solar system age:'
  },
  existingSystemHasBattery: {
    question: 'Does your solar system have a battery?',
    statement: 'Has battery',
    makeAnswerHumanReadable: decameliseIfNotNil
  },
  natureOfTheIssue: {
    question: 'What is the nature of the issue?',
    statement: 'Nature of fault:'
  },
  solarBatteryJobType: {
    question: 'Is this for an install, inspection or fault/repair?',
    statement: 'Solar battery job type:',
    makeAnswerHumanReadable: decameliseIfNotNil
  },
  batteryAge: {
    question: 'How old is the battery?',
    statement: 'Battery age:'
  },
  reasonForUpgrade: {
    question: 'What is the reason for wanting a new system?',
    statement: 'Reason for upgrade:'
  }
}
const notesGenerator = getNotesGenerator(solarQuestionDefinitions)

export const TopicSolarAction = (props: WorkflowActionProps<TopicActionInput, TopicSolarActionResult>) => {
  const { result, input, onUpdate } = props
  const { contact } = input ?? {}

  const workflowContext = useWorkflow()
  const {
    solarCategory,
    installType,
    existingSystemHasBattery,
    natureOfTheIssue,
    solarSystemAge,
    transferred,
    itemInstaller,
    solarBatteryJobType,
    batteryInstaller,
    batteryAge,
    reasonForUpgrade
  } = result ?? {}

  const getJobType = () => {
    if (itemInstaller === ItemInstaller.Fallon || batteryInstaller === ItemInstaller.Fallon) {
      return JobType.Callback
    }
    switch (solarCategory) {
      case SolarCategory.Quote:
        return installType === SolarInstallType.ExistingSystem ? JobType.Inspection : JobType.Quote
      case SolarCategory.Inspection:
        return JobType.Service
      case SolarCategory.SolarOffGrid:
        return JobType.Inspection
      case SolarCategory.SolarBatteries:
        switch (solarBatteryJobType) {
          case SolarBatteryJobType.Repair:
            return JobType.Service
          case SolarBatteryJobType.Inspection:
            return JobType.Inspection
          case SolarBatteryJobType.Install:
            return installType === SolarInstallType.ExistingSystem ? JobType.Inspection : JobType.Quote
          default:
            return JobType.Service
        }
      case SolarCategory.Fault:
      default:
        return JobType.Service
    }
  }

  const jobType = getJobType()

  const getAttributeIds = (): string[] => {
    if (solarCategory === SolarCategory.SolarBatteries) {
      return ['SolarHybridOrBattery']
    }
    switch (jobType) {
      case JobType.Inspection:
      case JobType.Quote:
      case JobType.Service:
      case JobType.Callback:
        return ['SolarInspection']
      default:
        return []
    }
  }

  const attributeIds = getAttributeIds()

  const onNext = () => {
    const traveFeeWaived =
      solarBatteryJobType === SolarBatteryJobType.Install ||
      solarBatteryJobType === SolarBatteryJobType.Repair ||
      solarCategory === SolarCategory.Quote
    onUpdate({
      ...result,
      actionCompleted: true,
      trade: TradeType.Solar,
      category: 'General',
      type: jobType,
      workRequiredNotes: generatedNotes,
      requirements: {
        travelFeeWaived: traveFeeWaived,
        attributes: attributeIds.map((attributeId) => ({ attributeId }))
      }
    })
  }

  const setTransferred = (transferred: boolean) => onUpdate({ ...result, transferred })
  const generatedNotes = notesGenerator.generateNotes(result ?? {})
  const updateResult = getResultUpdater(result ?? {}, onUpdate).updateResult

  // const canComplete = workRequired && workRequired.length > 0
  const solarQuoteComplete = solarCategory === SolarCategory.Quote && installType

  const solarOffGridComplete =
    solarCategory === SolarCategory.SolarOffGrid && solarSystemAge && natureOfTheIssue && transferred
  const solarFaultComplete = solarCategory === SolarCategory.Fault && solarSystemAge && natureOfTheIssue
  const solarBatteryInspectionComplete =
    solarCategory === SolarCategory.SolarBatteries &&
    solarBatteryJobType === SolarBatteryJobType.Inspection &&
    batteryAge
  const solarBatteryRepairComplete =
    solarCategory === SolarCategory.SolarBatteries &&
    solarBatteryJobType === SolarBatteryJobType.Repair &&
    batteryInstaller === ItemInstaller.SomeoneElse &&
    batteryAge
  const solarBatteryInstallNewComplete =
    solarCategory === SolarCategory.SolarBatteries &&
    solarBatteryJobType === SolarBatteryJobType.Install &&
    installType === SolarInstallType.NewSystem
  const solarBatteryInstallExistingComplete =
    solarCategory === SolarCategory.SolarBatteries &&
    solarBatteryJobType === SolarBatteryJobType.Install &&
    installType === SolarInstallType.ExistingSystem &&
    batteryAge &&
    reasonForUpgrade
  const solarRevisitComplete = itemInstaller === ItemInstaller.Fallon || batteryInstaller === ItemInstaller.Fallon
  const canComplete =
    solarCategory &&
    (solarQuoteComplete ||
      solarOffGridComplete ||
      solarBatteryInspectionComplete ||
      solarBatteryRepairComplete ||
      solarBatteryInstallNewComplete ||
      solarBatteryInstallExistingComplete ||
      solarRevisitComplete ||
      solarFaultComplete)

  const customerType = result?.customerType ?? CustomerType.Domestic
  const isOutOfScope = customerType !== CustomerType.Domestic

  return (
    <TopicWorkflowAction input={{ ...input, showJobDescription: !isOutOfScope }} onUpdate={onUpdate} result={result}>
      {isOutOfScope ? (
        <>
          <Callout type="script">{'Unfortunately commercial solar is out of scope for us.'}</Callout>
          <EuiSpacer />
          <EuiButton
            color="warning"
            onClick={() =>
              workflowContext.abort({
                abortReason: EnquiryAbortReason.CustomerNeedsAreOutOfService,
                notes: `Customer was enquiring about: Commercial Solar`
              })
            }
          >
            End call
          </EuiButton>
        </>
      ) : (
        <>
          <SingleSelectField
            question={notesGenerator.getQuestion('solarCategory')}
            options={[
              {
                id: SolarCategory.SolarBatteries,
                icon: 'empty'
              },
              {
                id: SolarCategory.Quote,
                icon: 'documentEdit'
              },
              {
                id: SolarCategory.Fault,
                icon: 'wrench'
              },
              {
                id: SolarCategory.SolarOffGrid,
                icon: 'empty'
              }
            ]}
            answer={solarCategory}
            changeAnswer={(solarCategory) => updateResult({ solarCategory })}
          />

          {solarCategory === SolarCategory.Quote && (
            <>
              <SingleSelectField
                question={notesGenerator.getQuestion('installType')}
                options={[
                  {
                    id: SolarInstallType.ExistingSystem,
                    label: 'Upgrade existing system'
                  },
                  {
                    id: SolarInstallType.NewSystem,
                    label: 'Brand new install'
                  }
                ]}
                answer={installType}
                changeAnswer={(installType) => updateResult({ installType })}
              />
            </>
          )}

          {solarCategory === SolarCategory.SolarOffGrid && (
            <>
              <FreeTextQuestionField
                question={notesGenerator.getQuestion('solarSystemAge')}
                answer={solarSystemAge}
                changeAnswer={(solarSystemAge) => updateResult({ solarSystemAge })}
              />
              <FreeTextQuestionField
                question={notesGenerator.getQuestion('natureOfTheIssue')}
                answer={natureOfTheIssue}
                changeAnswer={(natureOfTheIssue) => updateResult({ natureOfTheIssue })}
              />
              <Callout type="note" title="Call Electrical DSR to find schedule availability and technician" />
              <TransferScript
                transferTarget="our electrical team"
                contactName={contact?.firstName}
                onTransferComplete={setTransferred}
              />
            </>
          )}

          {solarCategory === SolarCategory.Fault && (
            <>
              <SingleSelectField
                question={notesGenerator.getQuestion('itemInstaller')}
                options={[
                  {
                    id: ItemInstaller.Fallon,
                    label: 'Fallon Solutions'
                  },
                  {
                    id: ItemInstaller.SomeoneElse,
                    label: 'Someone else'
                  }
                ]}
                answer={itemInstaller}
                changeAnswer={(itemInstaller) => updateResult({ itemInstaller })}
              />
              {itemInstaller === ItemInstaller.SomeoneElse && (
                <>
                  <FreeTextQuestionField
                    question={notesGenerator.getQuestion('solarSystemAge')}
                    answer={solarSystemAge}
                    changeAnswer={(solarSystemAge) => updateResult({ solarSystemAge })}
                  />
                  <FreeTextQuestionField
                    question={notesGenerator.getQuestion('natureOfTheIssue')}
                    answer={natureOfTheIssue}
                    rows={3}
                    changeAnswer={(natureOfTheIssue) => updateResult({ natureOfTheIssue })}
                  />
                </>
              )}
            </>
          )}

          {solarCategory === SolarCategory.SolarBatteries && (
            <>
              <SingleSelectField
                question={notesGenerator.getQuestion('solarBatteryJobType')}
                options={[
                  ...(solarCategory === SolarCategory.SolarBatteries
                    ? [
                        {
                          id: SolarBatteryJobType.Install,
                          icon: 'plus'
                        }
                      ]
                    : []),
                  {
                    id: SolarBatteryJobType.Inspection,
                    icon: 'search'
                  },
                  {
                    id: SolarBatteryJobType.Repair,
                    label: 'Fault/repair',
                    icon: 'wrench'
                  }
                ]}
                answer={solarBatteryJobType}
                changeAnswer={(solarBatteryJobType) => updateResult({ solarBatteryJobType })}
              />
              {solarBatteryJobType === SolarBatteryJobType.Install && (
                <>
                  <SingleSelectField
                    question={notesGenerator.getQuestion('installType')}
                    options={[
                      {
                        id: SolarInstallType.ExistingSystem,
                        label: 'Upgrade existing system'
                      },
                      {
                        id: SolarInstallType.NewSystem,
                        label: 'Brand new install'
                      }
                    ]}
                    answer={installType}
                    changeAnswer={(installType) => updateResult({ installType })}
                  />
                  {installType === SolarInstallType.ExistingSystem && (
                    <>
                      <FreeTextQuestionField
                        question={notesGenerator.getQuestion('batteryAge')}
                        answer={batteryAge}
                        changeAnswer={(batteryAge) => updateResult({ batteryAge })}
                      />
                      <FreeTextQuestionField
                        question={notesGenerator.getQuestion('reasonForUpgrade')}
                        answer={reasonForUpgrade}
                        changeAnswer={(reasonForUpgrade) => updateResult({ reasonForUpgrade })}
                        rows={3}
                      />
                    </>
                  )}
                </>
              )}
              {solarBatteryJobType === SolarBatteryJobType.Inspection && (
                <>
                  <FreeTextQuestionField
                    question={notesGenerator.getQuestion('batteryAge')}
                    answer={batteryAge}
                    changeAnswer={(batteryAge) => updateResult({ batteryAge })}
                  />
                </>
              )}
              {solarBatteryJobType === SolarBatteryJobType.Repair && (
                <>
                  <SingleSelectField
                    question={notesGenerator.getQuestion('batteryInstaller')}
                    options={[
                      {
                        id: ItemInstaller.Fallon,
                        label: 'Fallon Solutions'
                      },
                      {
                        id: ItemInstaller.SomeoneElse,
                        label: 'Someone else'
                      }
                    ]}
                    answer={batteryInstaller}
                    changeAnswer={(batteryInstaller) => updateResult({ batteryInstaller })}
                  />
                  {batteryInstaller === ItemInstaller.SomeoneElse && (
                    <>
                      <FreeTextQuestionField
                        question={notesGenerator.getQuestion('batteryAge')}
                        answer={batteryAge}
                        changeAnswer={(batteryAge) => updateResult({ batteryAge })}
                      />
                    </>
                  )}
                </>
              )}
            </>
          )}

          {solarCategory === SolarCategory.SolarBatteries && (
            <>
              <Callout type="note" title="Please contact Electrical DSR who will advise">
                <EuiText>
                  <ul>
                    <li>what priority lane to book the job on</li>
                    <li>
                      confirm they will manually move the job from priority lane to appropriate tech (likely Alan
                      Wilson)
                    </li>
                  </ul>
                </EuiText>
              </Callout>
            </>
          )}

          {(itemInstaller === ItemInstaller.Fallon || batteryInstaller === ItemInstaller.Fallon) && (
            <>
              <Callout type="note">
                <ul>
                  <li>Book this as an Electrical Revisit and follow standard revisit process</li>
                </ul>
              </Callout>
            </>
          )}

          <EuiSpacer />
          <EuiButton disabled={!canComplete} onClick={() => onNext()}>
            Next
          </EuiButton>
        </>
      )}
    </TopicWorkflowAction>
  )
}
