import {
  EuiBottomBar,
  EuiCallOut,
  EuiFlexGroup,
  EuiFlexItem,
  EuiLoadingSpinner,
  EuiProgress,
  EuiSpacer
} from '@elastic/eui'
import { dateConfig } from '@fallonsolutions/date'
import { compact, first, groupBy, map, orderBy, uniq } from 'lodash-es'
import { DateTime } from 'luxon'
import { useEffect, useState } from 'react'
import { MediaContext, MediaItemFragment } from '../api/generated-types'
import { JobPhotoUploader } from '../jobs/job-photo-uploader'
import createPersistedState from '../use-persisted-state'
import { GalleryViewCallBack } from './helpers/media-gallery-actions'
import { MediaItemGallery } from './media-item-gallery'
import { MediaItemGroup, MediaItemGroupView } from './media-item-group'
import { MediaItemListViewOptions } from './media-item-list-view-options'
import { PhotoSelectionMode } from './media-items-container'
import { useMediaService } from './media-service'
import { MediaServiceUploadStatus } from './media-service-upload'

createPersistedState<number>('media-item-width')

export enum MediaItemListDisplayType {
  GroupByHour = 'GroupByHour',
  GroupByDate = 'GroupByDate',
  GroupByContext = 'GroupByContext',
  GroupByJob = 'GroupByJob',
  GroupByNone = 'GroupByNone'
}

const DEFAULT_IMG_WIDTH = 120
const MISCELLANEOUS_SUBJECT = 'General'

export interface MediaItemListProps {
  jobId?: string
  mediaItems: MediaItemFragment[]
  totalMediaItemCount?: number // For pagination we need to know the total count
  displayType?: MediaItemListDisplayType
  showJobLink?: boolean
  selectionMode?: PhotoSelectionMode
  selectedPhotos?: string[]
  selectMediaItems?: (photos: string[]) => void
  mediaItemWidth: number
  setMediaItemWidth?: (width: number) => void
  viewingDeletedMedia?: boolean
}

export const MediaItemList = ({
  jobId,
  mediaItems,
  totalMediaItemCount,
  displayType,
  showJobLink,
  selectionMode,
  selectedPhotos,
  selectMediaItems,
  mediaItemWidth,
  setMediaItemWidth
}: MediaItemListProps) => {
  const mediaService = useMediaService()

  const [showUploadProgress, setShowUploadProgress] = useState(false)
  const groups: MediaItemGroup[] = getMediaItemGroups(mediaItems, displayType ?? MediaItemListDisplayType.GroupByDate)
  const [downloadError] = useState<boolean>(false)

  const uploads = mediaService.getUploads()
  useEffect(() => {
    if (uploads.length && !showUploadProgress) {
      setShowUploadProgress(true)
    } else if (!uploads.length) {
      // Don't hide immediately
      if (showUploadProgress) {
        setTimeout(() => {
          setShowUploadProgress(false)
        }, 1000)
      }
    }
  }, [uploads, showUploadProgress])

  const totalUploads = uploads.length
  const completedUploads = uploads.filter((u) => u.status === MediaServiceUploadStatus.Complete).length
  const uploadProgress = totalUploads > 1 ? completedUploads / totalUploads : (first(uploads)?.progress ?? 1)
  const itemWidth = mediaItemWidth ?? DEFAULT_IMG_WIDTH

  const uploadEnabled = !!jobId
  const showContextUploaders =
    selectionMode !== PhotoSelectionMode.Select && displayType !== MediaItemListDisplayType.GroupByContext

  return (
    <div className={selectionMode === PhotoSelectionMode.Select ? 'media-item-list--selection-mode' : ''}>
      <EuiFlexGroup alignItems="center">
        <EuiFlexItem grow={false}>
          Found {(totalMediaItemCount ?? mediaItems.length).toLocaleString()} items
        </EuiFlexItem>
        <EuiFlexItem grow={true} />
        {!!setMediaItemWidth && (
          <EuiFlexItem grow={false}>
            <MediaItemListViewOptions width={itemWidth} onChangeWidth={setMediaItemWidth} />
          </EuiFlexItem>
        )}
      </EuiFlexGroup>
      {!!downloadError && (
        <>
          <EuiCallOut title={'Failed to download image from server'} color="danger" iconType="alert" />
        </>
      )}
      <EuiSpacer size="s" />

      {showUploadProgress && (
        <EuiBottomBar>
          <EuiFlexGroup alignItems="center">
            <EuiFlexItem grow={false}>
              <EuiLoadingSpinner />
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              Uploading {completedUploads} / {totalUploads} files
            </EuiFlexItem>
            <EuiFlexItem grow={true}>
              <EuiProgress value={uploadProgress} max={1} />
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiBottomBar>
      )}

      {uploadEnabled && showContextUploaders && (
        <>
          <EuiFlexGroup gutterSize="none" wrap={true} alignItems="center">
            {[MediaContext.General, MediaContext.Before, MediaContext.After].map((context) => (
              <EuiFlexItem
                key={context}
                grow={false}
                style={{ width: mediaItemWidth, height: mediaItemWidth, margin: '1px' }}
              >
                <JobPhotoUploader jobId={jobId} label={context} context={context} subject={MISCELLANEOUS_SUBJECT} />
              </EuiFlexItem>
            ))}
          </EuiFlexGroup>
          <EuiSpacer />
        </>
      )}

      <MediaItemGallery selectionMode={selectionMode} galleryCallback={GalleryViewCallBack()}>
        {groups.map((group, index) => (
          <div key={`${group.title}-${index}`}>
            <MediaItemGroupView
              jobId={jobId}
              group={group}
              itemWidth={itemWidth}
              uploadEnabled={uploadEnabled}
              showJobLink={showJobLink}
              selectMediaItem={selectMediaItems}
              selectedPhotos={selectedPhotos}
              selectMode={selectionMode === PhotoSelectionMode.Select}
            />
            <EuiSpacer size="xl" />
          </div>
        ))}
      </MediaItemGallery>
    </div>
  )
}

const getMediaItemGroups = (
  mediaItems: MediaItemFragment[],
  displayType: MediaItemListDisplayType
): MediaItemGroup[] => {
  switch (displayType) {
    case MediaItemListDisplayType.GroupByHour:
      return groupMediaItemsByHour(mediaItems)
    case MediaItemListDisplayType.GroupByDate:
      return groupMediaItemsByDate(mediaItems)
    case MediaItemListDisplayType.GroupByContext:
      return groupMediaItemsByContext(mediaItems)
    case MediaItemListDisplayType.GroupByJob:
      return groupMediaItemsByJob(mediaItems)
    case MediaItemListDisplayType.GroupByNone:
    default:
      return [
        {
          key: '0',
          title: 'All',
          stacks: [
            {
              mediaItems
            }
          ]
        }
      ]
  }
}

const groupMediaItemsByDate = (mediaItems: MediaItemFragment[]): MediaItemGroup[] => {
  const grouped = groupBy(mediaItems, (m) =>
    DateTime.fromISO(m.date ?? m.created)
      .startOf('day')
      .toISO()
  )
  return orderBy(
    map(grouped, (mediaItems, key) => {
      const dateStr = DateTime.fromISO(key).toFormat(dateConfig.luxonFormat.fullDate)
      return {
        key,
        title: dateStr,
        stacks: [
          {
            mediaItems: mediaItems.sort(
              (a, b) =>
                DateTime.fromISO(b.date ?? b.created).toMillis() - DateTime.fromISO(a.date ?? a.created).toMillis()
            )
          }
        ]
      }
    }),
    ['key'],
    ['desc']
  )
}

const groupMediaItemsByHour = (mediaItems: MediaItemFragment[]): MediaItemGroup[] => {
  const grouped = groupBy(mediaItems, (m) =>
    DateTime.fromISO(m.date ?? m.created)
      .startOf('hour')
      .toISO()
  )
  return orderBy(
    map(grouped, (mediaItems, key) => {
      const from = DateTime.fromISO(key)
      const to = DateTime.fromISO(key).plus({ hours: 1 })
      const dateStr = from.toFormat(dateConfig.luxonFormat.fullDate)
      const timeStr = `${from.toFormat(dateConfig.luxonFormat.timeHour)} - ${to.toFormat(
        dateConfig.luxonFormat.timeHour
      )}`.toLowerCase()
      return {
        key,
        title: timeStr,
        subtitle: dateStr,
        stacks: [
          {
            mediaItems: mediaItems.sort(
              (a, b) =>
                DateTime.fromISO(b.date ?? b.created).toMillis() - DateTime.fromISO(a.date ?? a.created).toMillis()
            )
          }
        ]
      }
    }),
    ['key'],
    ['desc']
  )
}

const groupMediaItemsByJob = (mediaItems: MediaItemFragment[]): MediaItemGroup[] => {
  const grouped = groupBy(mediaItems, (m) => m.job?.number ?? 'No job')
  return orderBy(
    map(grouped, (mediaItems, key) => {
      return {
        key,
        title: key,
        stacks: [
          {
            mediaItems: mediaItems.sort(
              (a, b) =>
                DateTime.fromISO(b.date ?? b.created).toMillis() - DateTime.fromISO(a.date ?? a.created).toMillis()
            )
          }
        ]
      }
    }),
    ['key'],
    ['desc']
  )
}

const mediaContextSortOrder = {
  [MediaContext.General]: 0,
  [MediaContext.RiskAssessment]: 0,
  [MediaContext.Before]: 1,
  [MediaContext.After]: 2
}

const groupMediaItemsByContext = (mediaItems: MediaItemFragment[]): MediaItemGroup[] => {
  const contexts = map(MediaContext).sort((a, b) => mediaContextSortOrder[a] - mediaContextSortOrder[b])
  return contexts.map((context) => {
    const contextMediaItems = mediaItems.filter((m) => (m.context ?? MediaContext.General) === context)
    const itemSubjects = compact(contextMediaItems.map((m) => m.subject))
    const subjects = uniq(subjectsForContext(context).concat(itemSubjects))
    return {
      key: context,
      title: context,
      context,
      uploadEnabled: true,
      stacks: subjects.map((subject) => ({
        subject,
        mediaItems: contextMediaItems.filter((m) => m.subject === subject),
        uploadEnabled: true
      }))
    }
  })
}

const subjectsForContext = (context?: MediaContext): string[] => {
  switch (context) {
    case MediaContext.Before:
      return ['Risk assessment', 'General']
    case MediaContext.After:
      return ['General']
    case MediaContext.General:
      return ['General']
    default:
      return []
  }
}
