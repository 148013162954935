import { EuiButton, EuiSpacer } from '@elastic/eui'
import { JobType, TradeType } from '../../../api/generated-types'
import { QuestionDefinitions, getNotesGenerator } from '../../helpers/notes-generator'
import { getResultUpdater } from '../../helpers/results-updater'
import { FreeTextQuestionField } from '../../question-fields/free-text-question-field'
import { WorkflowActionProps } from '../../workflow-model'
import { TopicActionInput, TopicActionResult } from '../action-topic-model'
import { TopicWorkflowAction } from '../action-topic-view'

export interface TopicPlumbingSubmeteringActionResult extends TopicActionResult {
  numberOfUnits?: string
  mainMeterLocation?: string
  additionalNotes?: string
}

const questionDefinitions: QuestionDefinitions<TopicPlumbingSubmeteringActionResult> = {
  numberOfUnits: {
    question: 'How many units or town houses?',
    statement: 'Number of units/town houses:'
  },
  mainMeterLocation: {
    question: 'Where is the main water meter? Is onsite access easy?',
    statement: 'Main water meter location and access:'
  },
  additionalNotes: {
    question: 'Capture any other information from customer',
    statement: 'Additional notes:'
  }
}
const notesGenerator = getNotesGenerator(questionDefinitions)

export const TopicPlumbingSubmeteringAction = (
  props: WorkflowActionProps<TopicActionInput, TopicPlumbingSubmeteringActionResult>
) => {
  const { result, input, onUpdate } = props
  const { numberOfUnits, mainMeterLocation, additionalNotes } = result ?? {}

  const onNext = () =>
    onUpdate({
      ...result,
      actionCompleted: true,
      trade: TradeType.Plumbing,
      category: 'Submetering',
      type: JobType.Service,
      workRequiredNotes: generatedNotes,
      requirements: {
        attributes: [{ attributeId: 'GeneralPlumbing' }]
      }
    })

  const generatedNotes = notesGenerator.generateNotes(result ?? {})
  const updateResult = getResultUpdater(result ?? {}, onUpdate).updateResult

  const canComplete = numberOfUnits?.length && mainMeterLocation?.length

  return (
    <TopicWorkflowAction input={input} onUpdate={onUpdate} result={result}>
      <FreeTextQuestionField
        question={notesGenerator.getQuestion('numberOfUnits')}
        answer={numberOfUnits}
        changeAnswer={(numberOfUnits) => updateResult({ numberOfUnits })}
      />

      <FreeTextQuestionField
        question={notesGenerator.getQuestion('mainMeterLocation')}
        answer={mainMeterLocation}
        changeAnswer={(mainMeterLocation) => updateResult({ mainMeterLocation })}
      />

      <FreeTextQuestionField
        question={notesGenerator.getQuestion('additionalNotes')}
        answer={additionalNotes}
        changeAnswer={(additionalNotes) => updateResult({ additionalNotes })}
        rows={3}
      />

      <EuiSpacer />
      <EuiButton disabled={!canComplete} onClick={() => onNext()}>
        Next
      </EuiButton>
    </TopicWorkflowAction>
  )
}
