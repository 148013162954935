import { ContactFragment, CustomerLinkFragment } from '../api/generated-types'
import { ContactCard, ContactCardAddressDisplay } from '../contacts/contact-card'

export interface CustomerCardProps {
  customer: CustomerLinkFragment
  showAvatar?: boolean
  showReference?: boolean
  addressDisplay?: ContactCardAddressDisplay
}

export const CustomerCard = (props: CustomerCardProps) => {
  const { customer, addressDisplay } = props
  const showAvatar = props.showAvatar ?? true
  const showReference = props.showReference ?? false
  const mainContact = customer.mainContact as ContactFragment
  const referenceProps = showReference && customer.number ? { reference: customer.number } : {}
  return (
    <div>
      {mainContact.detail ? (
        <ContactCard
          contact={mainContact}
          showAvatar={showAvatar}
          {...referenceProps}
          addressDisplay={addressDisplay}
          key={mainContact.id}
          showHubspot={true}
        />
      ) : (
        <div>No contacts</div>
      )}
    </div>
  )
}

export default CustomerCard
