import { QuestionDefinitions, getNotesGenerator } from '../../helpers/notes-generator'
import { TopicActionResult } from '../action-topic-model'
import { YesNoValue } from '../common/common-enums'

export interface TopicGutterActionResult extends TopicActionResult {
  propertyLevels?: PropertyLevels
  issue?: GutterIssue
  size?: GutterSize
  gutterGuardLength?: string
  replacementSectionSize?: GutterSize
  downpipesBlocked?: YesNoValue
  gutterCleanInScope?: YesNoValue
}

export enum GutterIssue {
  Rusty = 'Rusty',
  Leaking = 'Leaking',
  Damage = 'Damage or repair',
  Blocked = 'Blocked',
  Clean = 'Clean',
  InstallGutterGuard = 'Install gutter guard',
  Replacement = 'Replacement'
}

export enum PropertyLevels {
  SingleStorey = 'Single storey',
  DoubleStorey = 'Double storey'
}

export enum GutterSize {
  SixMetresOrLess = '6 metres or less',
  GreaterThanSixMetres = 'Greater than 6 metres'
}

const questionDefinitions: QuestionDefinitions<TopicGutterActionResult> = {
  propertyLevels: {
    question: 'Is this a single or double storey?',
    statement: 'Property levels:'
  },
  issue: {
    question: 'What is the issue?',
    statement: 'Issue:'
  },
  size: {
    question: 'Is it less than or greater than 6 metres?',
    statement: 'Gutter size:'
  },
  gutterGuardLength: {
    question: 'How much or what length?',
    statement: 'Gutter guard length:'
  },
  replacementSectionSize: {
    question: 'Is the section less or more than 6 metres?',
    statement: 'Replacement section length:'
  },
  downpipesBlocked: {
    question: 'Are the downpipes blocked?',
    statement: 'Downpipes blocked:'
  },
  gutterCleanInScope: {
    question: `I'm just going to check with our dispatch team as to whether this is in scope`,
    statement: 'Gutter clean in scope:'
  }
}

export const gutterNotesGenerator = getNotesGenerator(questionDefinitions)
