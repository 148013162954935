import { EuiButton, EuiSpacer, EuiText } from '@elastic/eui'
import { CustomerLocationFragment } from '@fallonsolutions/types'
import { CustomerFragment, CustomerType } from '../../api/generated-types'
import { Callout } from '../../common/callout'
import { CreateCustomerFormV2 } from '../../customers/create-customer-form-v2'
import { CustomerDetailContainer } from '../../customers/customer-detail-container'
import { WorkflowAction } from '../workflow-action'
import { SelectedCustomer } from '../workflow-customer-model'
import { BaseActionInput, BaseActionResult, WorkflowActionProps } from '../workflow-model'
import { ContactActionResult } from './action-contact'
import { CustomerActionResult } from './action-customer'
import { ContactRole } from './action-customer-qualification'

export interface CreateCustomerActionInput extends BaseActionInput {
  contact?: ContactActionResult
  customerResult?: CustomerActionResult
  customerContactRole?: ContactRole
}

export interface CreateCustomerActionResult extends BaseActionResult {
  customer?: SelectedCustomer
}

export const CreateCustomerAction = (
  props: WorkflowActionProps<CreateCustomerActionInput, CreateCustomerActionResult>
) => {
  const { input, result, onUpdate } = props ?? {}
  const { contact, customerContactRole, customerResult } = input
  const { customer, actionCompleted } = result ?? {}

  const handleOnCustomerCreated = (customer: CustomerFragment) => {
    console.log('customer created', customer)
    onUpdate({
      ...result,
      customer: {
        id: customer.id,
        reference: customer.number ?? 'Unknown',
        firstName: customer.mainContact?.detail?.firstName ?? 'Unknown',
        fullName: customer.mainContact?.detail?.fullName ?? 'Unknown',
        label: customer.mainContact?.detail?.fullName ?? 'Unknown',
        email: customer.mainContact?.detail?.email,
        phone: customer.mainContact?.detail?.phone,
        alternatePhone: customer.mainContact?.detail?.alternatePhone
      }
    })
  }
  const handleOnCustomerLocationCreated = (customerLocation: CustomerLocationFragment) => {
    console.log('customerLocation created', customerLocation)
  }

  const onContinue = () => {
    onUpdate({ ...result, actionCompleted: true })
  }

  const clearResult = () => onUpdate({ ...result, actionCompleted: false })

  const value = actionCompleted === true && customer ? `${customer.label} (${customer.reference})` : undefined

  const initialCustomerType =
    customerContactRole === ContactRole.Commercial ? CustomerType.Commercial : CustomerType.Domestic

  return (
    <WorkflowAction title="Create customer" value={value} onClickChange={clearResult} editable={input.editable}>
      <Callout type="script">
        <EuiText>
          <div>Let’s lock that in for you</div>
          <EuiSpacer size="s" />
          <div>Firstly by confirming the spelling of your surname</div>
        </EuiText>
      </Callout>

      <EuiSpacer />

      {customer ? (
        <>
          <EuiSpacer />
          <div className="workflow__detail-wrapper">
            <CustomerDetailContainer id={customer.id} showProperties={true} />
          </div>
          <EuiSpacer size="s" />
          <EuiButton onClick={onContinue}>Next</EuiButton>
        </>
      ) : (
        <div className="workflow__detail-wrapper">
          <CreateCustomerFormV2
            initialContactId={customerResult?.contact?.id}
            initialContactDetail={
              customerResult?.contact?.detail ?? {
                firstName: contact?.firstName,
                lastName: contact?.lastName,
                phone: contact?.phone
              }
            }
            customerType={initialCustomerType}
            onCustomerCreated={handleOnCustomerCreated}
            onCustomerLocationCreated={handleOnCustomerLocationCreated}
          />
        </div>
      )}
    </WorkflowAction>
  )
}
