import { EuiButtonEmpty, EuiComboBox, EuiFlexGroup, EuiFlexItem, EuiHighlight } from '@elastic/eui'
import { JobTopic, JobTopics } from '@fallonsolutions/types'
import { map } from 'lodash-es'
import { useState } from 'react'

interface JobTopicComboBoxProps {
  topics: JobTopic[]
  onChangeTopics: (topics: JobTopic[]) => void
}

export const JobTopicComboBox = (props: JobTopicComboBoxProps) => {
  const { topics, onChangeTopics } = props

  const [enabled, setEnabled] = useState(topics.length > 0)
  const [comboInput, setComboInput] = useState<HTMLInputElement | null>(null)

  const allTopics = map(JobTopics, (topic: JobTopic) => {
    return {
      id: topic.reference,
      reference: topic.reference,
      label: topic.label
    }
  })

  const renderJobTopicOption = (option: any, searchValue: any, contentClassName: any) => {
    return (
      <EuiFlexGroup gutterSize="s">
        <EuiFlexItem>
          <span className={contentClassName}>
            <EuiHighlight search={searchValue}>{option.label}</EuiHighlight>
          </span>
        </EuiFlexItem>
      </EuiFlexGroup>
    )
  }

  const onAddFilter = () => {
    setEnabled(true)
    setTimeout(() => comboInput?.focus(), 50)
  }

  const onBlur = () => {
    if (topics.length <= 0) {
      setEnabled(false)
    }
  }

  return (
    <form autoComplete="off">
      <EuiComboBox
        inputRef={setComboInput}
        placeholder="Topic"
        options={allTopics}
        selectedOptions={topics}
        onChange={(options) => onChangeTopics(options as JobTopic[])}
        isClearable={true}
        singleSelection={false}
        renderOption={renderJobTopicOption}
        rowHeight={40}
        isLoading={allTopics.length <= 0}
        style={{ minWidth: '200px' }}
        hidden={!enabled}
        onBlur={onBlur}
        aria-label="job-Topic"
        data-test-subj="job-Topic"
      />
      {!enabled && (
        <EuiButtonEmpty iconType="filter" flush="both" onClick={onAddFilter}>
          Topic
        </EuiButtonEmpty>
      )}
    </form>
  )
}

export default JobTopicComboBox
