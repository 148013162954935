import { EuiAvatar, EuiFlexGroup, EuiFlexItem, EuiIcon, EuiLink } from '@elastic/eui'
import { MouseEventHandler, useState } from 'react'
import { MediaItemFragment, MediaType } from '../api/generated-types'
import { ImageView } from '../common/image-view'
import PhotoCard from '../jobs/photo-card'
import '../static/css/media-item.css'
import { AdditionalContextMenuItems, MediaItemContextMenu } from './media-item-context-menu'
import { MediaItemDocumentView } from './media-item-document-view'

export enum MediaItemContentMode {
  Fill = 'fill',
  Fit = 'fit'
}

export interface MediaItemViewProps {
  mediaItem: MediaItemFragment
  width?: number
  height?: number
  showDescription?: boolean
  contentMode?: MediaItemContentMode
  showJobLink?: boolean
  isSelected?: boolean
  mediaItemSelected?: (mediaItem: string[]) => void
  selectedMode?: boolean
  additionalContextMenuItems?: AdditionalContextMenuItems[]
}

export const MediaItemView = ({
  mediaItem,
  width,
  height,
  showDescription,
  showJobLink,
  isSelected,
  mediaItemSelected,
  additionalContextMenuItems,
  selectedMode
}: MediaItemViewProps) => {
  const [isContextMenuVisible, setIsContextMenuVisible] = useState(false)

  const caption = mediaItem.description ?? mediaItem.name ?? undefined

  const itemWidth = width ?? 120
  const itemHeight = itemWidth * 1

  const author = mediaItem.author
  const job = mediaItem.job

  const createView = () => {
    switch (mediaItem.type ?? MediaType.ExternalImage) {
      case MediaType.ExternalImage:
        return (
          <ImageView
            mediaItem={mediaItem}
            loading={false}
            src={mediaItem.link ?? mediaItem.url}
            width={itemWidth}
            height={height}
            dimensions={{ width: mediaItem.metadata?.width, height: mediaItem.metadata?.height }}
            caption={showDescription ? caption : undefined}
            mediaItemId={mediaItem.id}
          />
        )
      case MediaType.ProductImage:
      case MediaType.Image:
        return <PhotoCard media={mediaItem} showCaption={showDescription ?? true} width={itemWidth} height={height} />
      case MediaType.Document:
        return <MediaItemDocumentView mediaItem={mediaItem} selectMode={!!selectedMode} />
      default:
        return (
          <div style={{ width: `${width}px` }}>
            <EuiFlexGroup direction="column" alignItems="center" gutterSize="s" justifyContent="spaceAround">
              <EuiFlexItem grow={false}>Not implemented: {mediaItem.name ?? mediaItem.type}</EuiFlexItem>
            </EuiFlexGroup>
          </div>
        )
    }
  }

  const handleContextMenuClick: MouseEventHandler<any> = (event) => {
    event.preventDefault()
    setIsContextMenuVisible(true)
  }

  const handlerClick = () => {
    setIsContextMenuVisible(false)
    mediaItemSelected?.([mediaItem.id])
  }
  return (
    <div
      className={`media-item-wrapper ${isSelected ? 'media-selected-background' : ''}`}
      style={{ width: `${itemWidth}px` }}
    >
      <MediaItemContextMenu
        mediaItem={mediaItem}
        isVisible={isContextMenuVisible}
        setIsVisible={setIsContextMenuVisible}
        additionalContextMenuItems={additionalContextMenuItems}
      >
        <div onContextMenu={handleContextMenuClick} onClick={handlerClick}>
          <div
            className={`media-item-${isSelected ? 'selected' : 'not-selected'} media-item ${
              mediaItem.deleted ? 'media-item--deleted' : ''
            }`}
            style={{ width: `${itemWidth}px`, height: `${height ?? itemHeight}px`, position: 'relative' }}
          >
            {!!isSelected && (
              <div>
                <div className="media-item__selected-tick">
                  <EuiIcon type="check" color="white" size="l" />
                </div>
                <div className="media-item__selected-background"> </div>
              </div>
            )}

            {!isSelected && selectedMode && (
              <div>
                <div className="media-item__not-selected-background"> </div>
              </div>
            )}
            {createView()}
            {author && itemWidth > 100 && (
              <div className="media-item__avatar">
                <EuiAvatar
                  size={itemWidth > 150 ? 'm' : 's'}
                  name={author.contactDetail?.fullName ?? 'No name'}
                  imageUrl={author?.avatar}
                />
              </div>
            )}
            {mediaItem.deleted === true && (
              <div className="media-item__deleted-icon">
                <EuiIcon type="trash" color="#ffffff" display="fill" size="s" title="Deleted" />
              </div>
            )}
          </div>
        </div>
      </MediaItemContextMenu>

      {showJobLink && job && (
        <EuiFlexGroup alignItems="center" gutterSize="s" style={{ paddingBottom: '12px' }}>
          <EuiFlexItem grow={false} style={{ paddingLeft: '6px', ...(mediaItem.subject && { maxWidth: '90px' }) }}>
            <EuiLink className="truncate" href={`/jobs/${job.id}/photos`} target="_blank" external={false}>
              {job.number}
            </EuiLink>
          </EuiFlexItem>
          <EuiFlexItem grow={true} />
          {mediaItem.subject && (
            <EuiFlexItem
              className="truncate"
              grow={true}
              style={{ textAlign: 'right', paddingRight: '6px', maxWidth: '90px' }}
              title={mediaItem.subject}
            >
              {mediaItem.subject}
            </EuiFlexItem>
          )}
        </EuiFlexGroup>
      )}
    </div>
  )
}
