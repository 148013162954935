import { EuiButton, EuiSpacer } from '@elastic/eui'
import React from 'react'
import { JobType, TradeType } from '../../../api/generated-types'
import { Callout } from '../../../common/callout'
import { QuestionDefinitions, getNotesGenerator } from '../../helpers/notes-generator'
import { getResultUpdater } from '../../helpers/results-updater'
import { FreeTextQuestionField } from '../../question-fields/free-text-question-field'
import { SingleSelectField } from '../../question-fields/single-select-question-field'
import { WorkflowActionProps } from '../../workflow-model'
import { TopicActionInput, TopicActionResult } from '../action-topic-model'
import { TopicWorkflowAction } from '../action-topic-view'

export enum BackflowTestType {
  Test = 'Test',
  RepairValve = 'RepairValve',
  Truck = 'Truck'
}
const backflowTestTypeOptions = [
  { id: BackflowTestType.Test, label: 'Test' },
  { id: BackflowTestType.RepairValve, label: 'RepairValve' },
  { id: BackflowTestType.Truck, label: 'Truck' }
]
export interface TopicPlumbingBackflowTestingActionResult extends TopicActionResult {
  backflowTestType?: BackflowTestType
  numberOfDevices?: string
  truckLocation?: string //address
}

const questionDefinitions: QuestionDefinitions<TopicPlumbingBackflowTestingActionResult> = {
  backflowTestType: {
    question: 'What type of testing?'
  },
  truckLocation: {
    question: 'Where will the truck(s) be located?'
  },
  numberOfDevices: {
    question: 'How many devices need testing?'
  }
}

const notesGenerator = getNotesGenerator(questionDefinitions)

export const TopicPlumbingBackflowTestingAction = (
  props: WorkflowActionProps<TopicActionInput, TopicPlumbingBackflowTestingActionResult>
) => {
  const { input, result, onUpdate } = props
  const { backflowTestType, truckLocation, numberOfDevices } = result ?? {}

  const onNext = () =>
    onUpdate({
      ...result,
      actionCompleted: true,
      trade: TradeType.Plumbing,
      category: `Backflow`,
      type: JobType.Service,
      workRequiredNotes: generatedNotes,
      requirements: {
        attributes: [{ attributeId: 'Backflow' }]
      }
    })

  const updateResult = getResultUpdater(result ?? {}, onUpdate).updateResult
  const generatedNotes = notesGenerator.generateNotes(result ?? {})

  const canComplete =
    (backflowTestType !== BackflowTestType.Truck ? !!backflowTestType : !!truckLocation) && !!numberOfDevices

  return (
    <TopicWorkflowAction input={input} onUpdate={onUpdate} result={result}>
      <SingleSelectField
        question={notesGenerator.getQuestion('backflowTestType')}
        answer={backflowTestType}
        changeAnswer={(backflowTestType) => updateResult({ backflowTestType })}
        options={backflowTestTypeOptions}
      />
      <EuiSpacer size="m" />
      {backflowTestTypeOptions && (
        <FreeTextQuestionField
          question={notesGenerator.getQuestion('numberOfDevices')}
          answer={numberOfDevices}
          changeAnswer={(numberOfDevices) => {
            updateResult({ numberOfDevices })
          }}
        />
      )}
      <EuiSpacer size="m" />
      {backflowTestType && (
        <Callout
          type="script"
          title="Can you please email a copy of the council test notice showing the location(s) of the device(s)"
        />
      )}
      <EuiSpacer size="m" />
      {backflowTestType !== BackflowTestType.Truck && <Callout type="note" title="Book a backflow plumber" />}
      {backflowTestType === BackflowTestType.Truck && (
        <>
          <Callout type="warning" title="Get the physical address and verify it is within service area" />
          <FreeTextQuestionField
            question={notesGenerator.getQuestion('truckLocation')}
            rows={3}
            answer={truckLocation}
            changeAnswer={(truckLocation) => updateResult({ truckLocation })}
          />
        </>
      )}
      <EuiSpacer size="m" />
      <EuiButton disabled={!canComplete} onClick={() => onNext()}>
        Next
      </EuiButton>
    </TopicWorkflowAction>
  )
}
