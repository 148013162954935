import { useMutation } from '@apollo/client'
import {
  EuiAccordion,
  EuiAvatar,
  EuiBasicTable,
  EuiButton,
  EuiButtonEmpty,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHorizontalRule,
  EuiSpacer,
  EuiText,
  EuiTitle
} from '@elastic/eui'
import { ContactLinkFragment } from '@fallonsolutions/types'
import omitDeep from 'omit-deep-lodash'
import {
  ContactFragment,
  DataQualityIssueInput,
  DataQualityStatus,
  UpdateContactDataQualityDocument
} from '../api/generated-types'
import { useApp } from '../app/app-context'
import { formatPhone } from '../common/phone'
import { DataQualityStatusBadge } from '../data-quality/data-quality-status-badge'
import hubspotIcon from '../static/images/hubspot-icon.png'
import { ContactDetailMatch } from './contact-list-card-view'
import { ContactColumnName, ContactListColumns } from './contact-list-columns'

export interface ContactCardSelectionState {
  isSelected: boolean
  onSelect: () => void
}

export interface ContactCardProps {
  contact: ContactFragment
  showAvatar?: boolean
  reference?: string
  addressDisplay?: ContactCardAddressDisplay
  selection?: ContactCardSelectionState
  selectButtonLabel?: string
  showDataQuality?: boolean
  showHubspot?: boolean
  showDataQualityFooter?: boolean
  matchScore?: ContactDetailMatch | undefined
}

export enum ContactCardAddressDisplay {
  None = 'none',
  Suburb = 'suburb',
  SuburbLabel = 'suburb-label',
  FullAddress = 'full-address'
}

export const ContactCard = (props: ContactCardProps) => {
  const { contact, reference, selection } = props
  const { detail } = contact
  const selectButtonLabel = props.selectButtonLabel ?? 'Select'
  const showAvatar = props.showAvatar ?? true
  const showHubspot = props.showHubspot ?? true

  const [updateContactDataQuality, { loading }] = useMutation(UpdateContactDataQualityDocument, {
    refetchQueries: ['SearchContacts', 'GetContact', 'GetCustomer', 'GetCustomerLink'],
    awaitRefetchQueries: true
  })

  const showDataQuality = props.showDataQuality ?? true
  const addressDisplay = props.addressDisplay ?? ContactCardAddressDisplay.FullAddress
  const appContext = useApp()
  const hubspotAccountId = appContext.hubspotAccountId
  const showDataQualityFooter = props?.showDataQualityFooter ?? true

  const fullName = detail?.fullName ?? 'No name'
  const matchScore = props.matchScore
  const formattedAddress = getAddressString(contact, addressDisplay)

  const formattedPhone = formatPhone(detail?.phone || '')
  const formattedAlternatePhone = formatPhone(detail?.alternatePhone || '')

  const handleConfirmDataQuality = () => {
    const issues =
      contact.dataQuality?.issues?.map((issue) => omitDeep(issue, ['__typename']) as DataQualityIssueInput) ?? []

    updateContactDataQuality({
      variables: {
        input: {
          contactId: contact.id,
          status: DataQualityStatus.Valid,
          issues
        }
      }
    })
  }

  return (
    <EuiFlexGroup gutterSize="none">
      <EuiFlexItem grow={true}>
        <EuiFlexGroup gutterSize="s">
          {showAvatar && (
            <EuiFlexItem grow={false}>
              <EuiAvatar size="l" name={fullName} />
              <EuiSpacer size="s" />
              <EuiFlexGroup gutterSize="none" alignItems="flexEnd">
                {showHubspot && contact.hubspotId && (
                  <EuiFlexItem grow={false}>
                    <EuiButtonEmpty
                      href={`https://app.hubspot.com/contacts/${hubspotAccountId}/${contact.hubspotId}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      iconType={hubspotIcon}
                      size="xs"
                    ></EuiButtonEmpty>
                  </EuiFlexItem>
                )}
              </EuiFlexGroup>
            </EuiFlexItem>
          )}
          <EuiFlexItem>
            <EuiText size="s">
              <div>
                <strong title={`${matchScore?.firstName} ${matchScore?.lastName}`}>{fullName}</strong>
              </div>
              {reference && <div>{reference}</div>}

              <div>
                {contact.detail?.phone ? <a href={`tel:${contact.detail?.phone}`}>{formattedPhone}</a> : 'No phone'}
              </div>
              <div>
                {contact.detail?.alternatePhone && contact.detail?.alternatePhone !== contact.detail?.phone ? (
                  <a href={`tel:${contact.detail?.alternatePhone}`}>{formattedAlternatePhone}</a>
                ) : (
                  ''
                )}
              </div>
              <div>
                {contact.detail?.email ? (
                  <a href={`mailto:${contact.detail?.email}`}>{contact.detail?.email}</a>
                ) : (
                  'No email'
                )}
              </div>
              {formattedAddress ? (
                <>
                  <EuiSpacer size="s"></EuiSpacer>
                  <div>{formattedAddress}</div>
                </>
              ) : null}
              {contact.detail?.notes ? (
                <>
                  <EuiSpacer size="s"></EuiSpacer>
                  <div>Notes: {contact.detail?.notes}</div>
                </>
              ) : null}
            </EuiText>
          </EuiFlexItem>
          {selection && (
            <EuiFlexItem grow={false}>
              <>
                {selection.isSelected ? (
                  <EuiText size="s">Selected</EuiText>
                ) : (
                  <EuiButton size="s" style={{ minWidth: '50px' }} onClick={() => selection.onSelect()}>
                    {selectButtonLabel}
                  </EuiButton>
                )}
                {showDataQuality && (
                  <>
                    <EuiSpacer size="s"></EuiSpacer>
                    <DataQualityStatusBadge status={contact.dataQuality?.status ?? DataQualityStatus.NotChecked} />
                  </>
                )}
              </>
            </EuiFlexItem>
          )}
        </EuiFlexGroup>
        {showDataQualityFooter && (!contact.dataQuality || contact.dataQuality?.status !== DataQualityStatus.Valid) && (
          <EuiFlexItem grow={true}>
            <EuiSpacer size="s" />
            <EuiHorizontalRule margin="none" />
            <EuiSpacer size="m" />
            <EuiTitle size="xxxs">
              <h4 style={{ color: '#525B72' }}>Contact data quality</h4>
            </EuiTitle>
            <EuiSpacer size="s" />
            <EuiFlexGroup gutterSize="s" alignItems="center">
              <EuiFlexItem grow={false}>
                <DataQualityStatusBadge status={contact.dataQuality?.status ?? DataQualityStatus.NotChecked} />
              </EuiFlexItem>
              <EuiFlexItem grow={true}>
                {/* <EuiText size="xs" title="Data quality issues detected">
                  {contact.dataQuality && contact.dataQuality.issues?.length > 0 ? (
                    <ul>
                      {contact.dataQuality.issues?.map((issue, idx) => (
                        <li key={idx}>
                          {issue.field}: {issue.issueType}
                        </li>
                      ))}
                    </ul>            
                    {/* )} */}
                <EuiText size="xs">Please review and correct any issues with contact details.</EuiText>
              </EuiFlexItem>
            </EuiFlexGroup>
            <EuiSpacer size="s" />
            <EuiFlexGroup direction="column" alignItems="flexStart">
              <EuiFlexItem grow={false}>
                <EuiButtonEmpty
                  size="xs"
                  iconType="checkInCircleFilled"
                  color="success"
                  onClick={() => handleConfirmDataQuality()}
                  isLoading={loading}
                >
                  I confirm contact details are accurate
                </EuiButtonEmpty>
              </EuiFlexItem>

              {contact.hubspotContactIssues && contact.hubspotContactIssues.length > 0 && (
                <EuiFlexItem grow={false}>
                  <>
                    <EuiSpacer size="xs" />
                    <ContactHubspotConflictAccordion issues={contact.hubspotContactIssues} />
                  </>
                </EuiFlexItem>
              )}
            </EuiFlexGroup>
          </EuiFlexItem>
        )}
      </EuiFlexItem>
    </EuiFlexGroup>
  )
}

const getAddressString = (contact: ContactFragment, addressDisplay: ContactCardAddressDisplay): string | undefined => {
  const address = contact.detail?.address
  switch (addressDisplay) {
    case ContactCardAddressDisplay.None:
      return undefined
    case ContactCardAddressDisplay.Suburb:
      return address?.suburb ?? undefined
    case ContactCardAddressDisplay.SuburbLabel:
      return address?.suburb
        ? `${address.suburb}, ${address.state} ${address.postcode}`
        : contact.detail?.suburb
          ? contact.detail?.suburb.label
          : undefined
    case ContactCardAddressDisplay.FullAddress:
      return address
        ? [address.street, address.street2, address.suburb, address.postcode, address.state, address.country]
            .filter((p) => p?.trim().length)
            .join(', ')
        : undefined
    default:
      return undefined
  }
}

export interface ContactHubspotConflictAccordionProps {
  issues: ContactLinkFragment[]
}
export const ContactHubspotConflictAccordion = ({ issues }: ContactHubspotConflictAccordionProps) => {
  const columns = ContactListColumns({
    includeContactLink: true,
    openContactInNewTab: true,
    columnsToRemove: [
      ContactColumnName.Lifecycle,
      ContactColumnName.Merge,
      ContactColumnName.Customers,
      ContactColumnName.DataQuality,
      ContactColumnName.Actions,
      ContactColumnName.Created
    ]
  })
  return (
    <>
      <EuiAccordion id="contact-hubspot-conflict-accordion" buttonContent="Contacts with the same email">
        <EuiBasicTable columns={columns} items={issues} />
      </EuiAccordion>
    </>
  )
}
