import { EuiFlexGroup, EuiFlexItem, EuiSpacer } from '@elastic/eui'
import { dateConfig } from '@fallonsolutions/date'
import { compact } from 'lodash-es'
import { DateTime } from 'luxon'
import { JobFragment, UserFragment } from '../api/generated-types'
import { AttributeCovidVaccinatedJobEdit } from '../attributes/attribute-covid-vaccinated-job-edit/attribute-covid-vaccinated-job-edit'
import { useAuthenticated } from '../auth/authenticated-context'
import { decamelise } from '../common/utils'
import UserLink from '../users/user-link'
import { JobAcquisition } from './job-acquisition'
import { JobBranchEdit } from './job-branch-edit'
import { JobReferrer } from './job-referrer'

enum JobSummaryModal {
  None,
  JobRequirements,
  JobProperty,
  JobBranch
}

interface JobSummaryFooterProps {
  job: JobFragment
  setJobSummaryModal: (modal: JobSummaryModal) => void
}

export const JobSummaryFooter = ({ job, setJobSummaryModal }: JobSummaryFooterProps) => {
  const userFragment = useAuthenticated().userFragment
  const canEditJobRequirements = userFragment.permissions?.customDiscounts === true

  return (
    <>
      <EuiFlexGroup alignItems="flexStart">
        <EuiFlexItem grow={false}>
          <div className="small-label">Branch</div>
          <JobBranchEdit
            job={job}
            editable={canEditJobRequirements}
            closeModal={() => setJobSummaryModal(JobSummaryModal.None)}
          />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <JobReferrer jobId={job.id} referrer={job.referrer} />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <JobAcquisition acquisition={job.acquisition ?? undefined} />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <AttributeCovidVaccinatedJobEdit job={job} editable={true} />
        </EuiFlexItem>
      </EuiFlexGroup>
      {compact(job.approvals).length > 0 && (
        <>
          <EuiSpacer />
          <div className="small-label">APPROVALS</div>
          {compact(job.approvals).map((approval, index) => (
            <div key={index}>
              <UserLink user={approval.manager as UserFragment | null | undefined} />
              <span>{approval.type} approval</span>
              <span
                style={{ marginLeft: '6px' }}
                title={DateTime.fromISO(approval.date, { zone: dateConfig.defaultTimezone }).toFormat(
                  dateConfig.luxonFormat.fullDateAndTime
                )}
              >
                {DateTime.fromISO(approval.date).toRelative()}
              </span>
              {(!!approval.reason || !!approval.comments) && (
                <div>{compact([decamelise(approval.reason), approval.comments]).join(': ')}</div>
              )}
              <EuiSpacer size="s" />
            </div>
          ))}
        </>
      )}
    </>
  )
}
