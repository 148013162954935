import { EuiButton, EuiSpacer, EuiText } from '@elastic/eui'
import { EnquiryAbortReason, JobType, TradeType } from '../../../api/generated-types'
import { Callout } from '../../../common/callout'
import { decameliseIfNotNil, makeHumanReadable } from '../../../common/utils'
import { CustomerSuppliedTopicActionResult } from '../../action-topic-customer-supplied'
import { CustomerSuppliedCompliant, IsItemAustralianCompliant } from '../../helpers/item-installer-questions'
import { QuestionDefinitions, getNotesGenerator } from '../../helpers/notes-generator'
import { OutOfScope } from '../../helpers/out-of-topic'
import { getResultUpdater } from '../../helpers/results-updater'
import { useWorkflow } from '../../helpers/workflow-provider'
import { SingleSelectField } from '../../question-fields/single-select-question-field'
import { WorkflowActionProps } from '../../workflow-model'
import { InstallType, ItemInstaller, ItemSupplier, ServiceType, TopicActionInput } from '../action-topic-model'
import { TopicWorkflowAction } from '../action-topic-view'
import { reset } from '../common/common-utils'

export enum RepairToReplacement {
  Yes = 'Yes',
  No = 'No'
}

export enum HeatLight {
  Yes = 'Yes',
  No = 'No'
}

export enum ExhaustFanLocation {
  Kitchen = 'Kitchen',
  Toilet = 'Toilet',
  Bathroom = 'Bathroom'
}

export enum InstallDate {
  LessThanOrEqualTo3Years = 'Less than 3 years ago',
  Over3Years = 'Over 3 years ago'
}

export interface TopicExhaustFanActionResult extends CustomerSuppliedTopicActionResult {
  serviceType?: ServiceType
  itemSupplier?: ItemSupplier
  itemInstaller?: ItemInstaller
  installDate?: InstallDate
  installType?: InstallType
  numberAndLocations?: string
  repairToReplacement?: RepairToReplacement
  location?: ExhaustFanLocation
  heatLight?: HeatLight
}

const questionDefinitions: QuestionDefinitions<TopicExhaustFanActionResult> = {
  serviceType: {
    question: 'Is that an installation or repair?',
    statement: 'Service type:',
    makeAnswerHumanReadable: decameliseIfNotNil
  },
  itemInstaller: {
    question: 'Who installed the fan?',
    statement: 'Fan installed by:',
    makeAnswerHumanReadable: decameliseIfNotNil
  },
  installDate: {
    question: 'When did Fallon install?',
    statement: 'Install date:',
    makeAnswerHumanReadable: makeHumanReadable
  },
  repairToReplacement: {
    question: 'Would you like me to book in a technician to come out and arrange this?',
    statement: 'Replace instead of repair:',
    makeAnswerHumanReadable: decameliseIfNotNil
  },
  installType: {
    question: 'Is this for brand new install or replacement?',
    statement: 'Install type:',
    makeAnswerHumanReadable: decameliseIfNotNil
  },
  location: {
    question: 'Which location?',
    statement: 'Location:',
    makeAnswerHumanReadable: decameliseIfNotNil
  },
  itemSupplier: {
    question: 'Who is supplying the fan?',
    statement: 'Fan supplied by:',
    makeAnswerHumanReadable: decameliseIfNotNil
  },
  heatLight: {
    question: 'Do you want a heat light?',
    statement: 'Heat light requested:',
    makeAnswerHumanReadable: decameliseIfNotNil
  }
}
const notesGenerator = getNotesGenerator(questionDefinitions)

export const TopicExhaustFanAction = (props: WorkflowActionProps<TopicActionInput, TopicExhaustFanActionResult>) => {
  const workflowContext = useWorkflow()
  const { result, input, onUpdate } = props
  const {
    serviceType,
    itemSupplier,
    itemInstaller,
    installDate,
    installType,
    repairToReplacement,
    location,
    heatLight,
    australianCompliant,
    jobDescription,
    customerType
  } = result ?? {}

  const { jobIsCompliant, jobIsNotCompliant } = CustomerSuppliedCompliant({ australianCompliant })
  const isRevisit =
    serviceType === ServiceType.Repair &&
    itemInstaller === ItemInstaller.Fallon &&
    installDate === InstallDate.LessThanOrEqualTo3Years
  const isOutsideRevisitScope =
    serviceType === ServiceType.Repair &&
    itemInstaller === ItemInstaller.Fallon &&
    installDate === InstallDate.Over3Years

  const onNext = () =>
    onUpdate({
      ...result,
      actionCompleted: true,
      trade: TradeType.Electrical,
      category: 'ExhaustFan',
      type: isRevisit ? JobType.Callback : JobType.Service,

      workRequiredNotes: generatedNotes,
      requirements: {
        attributes: [{ attributeId: 'GeneralElectrical' }]
      }
    })

  const generatedNotes = notesGenerator.generateNotes(result ?? {})
  const updateResult = getResultUpdater(result ?? {}, onUpdate).updateResult

  const canComplete =
    isRevisit ||
    isOutsideRevisitScope ||
    (!!serviceType &&
      (!!itemSupplier || !!itemInstaller) &&
      !!installType &&
      !!location &&
      !!HeatLight &&
      jobIsCompliant)

  return (
    <TopicWorkflowAction input={input} onUpdate={onUpdate} result={result}>
      <SingleSelectField
        question={notesGenerator.getQuestion('serviceType')}
        options={[
          {
            id: ServiceType.Installation,
            label: 'Installation',
            icon: 'check'
          },
          {
            id: ServiceType.Repair,
            label: 'Repair',
            icon: 'wrench'
          }
        ]}
        answer={serviceType}
        changeAnswer={(serviceType) =>
          updateResult({
            ...reset(result ?? {}),
            customerType,
            serviceType,
            jobDescription
          })
        }
      />

      {serviceType === ServiceType.Repair && (
        <>
          <SingleSelectField
            question={notesGenerator.getQuestion('itemInstaller')}
            options={[
              {
                id: ItemInstaller.Fallon,
                label: 'Fallon installed',
                icon: 'check'
              },
              {
                id: ItemInstaller.SomeoneElse,
                label: 'Someone else installed',
                icon: 'user'
              }
            ]}
            answer={itemInstaller}
            changeAnswer={(itemInstaller) => updateResult({ itemInstaller })}
          />
        </>
      )}

      {serviceType === ServiceType.Repair && itemInstaller === ItemInstaller.SomeoneElse && (
        <>
          <Callout type="script" className="callout--flat-list">
            <EuiText>
              <ul>
                <li>Unfortunately due to the complexity of exhaust fans we do not repair them</li>
                <li>It is more cost effective to replace them as you get an upgrade and a warranty</li>
              </ul>
            </EuiText>
          </Callout>
          <SingleSelectField
            question={notesGenerator.getQuestion('repairToReplacement')}
            options={[
              {
                id: RepairToReplacement.Yes,
                label: 'Yes, book in replacement',
                icon: 'check'
              },
              {
                id: RepairToReplacement.No,
                label: 'No thanks',
                icon: 'cross'
              }
            ]}
            answer={repairToReplacement}
            changeAnswer={(repairToReplacement) =>
              updateResult({
                repairToReplacement,
                installType: undefined,
                location: undefined,
                heatLight: undefined
              })
            }
          />
          {repairToReplacement === RepairToReplacement.No && (
            <>
              <Callout type="script">
                <EuiText>
                  <p>
                    Can I please get your email address as I would like to send you through a{' '}
                    <strong>$40 voucher</strong> that you can use next time you chose to book with us
                  </p>
                </EuiText>
              </Callout>
              <EuiSpacer />
              <EuiButton
                color="warning"
                onClick={() => workflowContext.abort({ abortReason: EnquiryAbortReason.CustomerNeedsAreOutOfService })}
              >
                End call
              </EuiButton>
            </>
          )}
        </>
      )}

      {serviceType === ServiceType.Repair && itemInstaller === ItemInstaller.Fallon && (
        <>
          <SingleSelectField
            question={notesGenerator.getQuestion('installDate')}
            options={[
              {
                id: InstallDate.LessThanOrEqualTo3Years,
                label: 'Within last 3 years'
              },
              {
                id: InstallDate.Over3Years,
                label: 'Over 3 years ago'
              }
            ]}
            answer={installDate}
            changeAnswer={(installDate) => updateResult({ installDate })}
          />
          {itemInstaller === ItemInstaller.Fallon && !!installDate && (
            <>
              <Callout
                type="note"
                title={
                  installDate === InstallDate.LessThanOrEqualTo3Years
                    ? 'Revisit with no travel fee'
                    : 'Normal service job with travel fee'
                }
              />
              <EuiSpacer />
            </>
          )}
        </>
      )}

      {serviceType === ServiceType.Installation && (
        <>
          <SingleSelectField
            question={notesGenerator.getQuestion('itemSupplier')}
            options={[
              {
                id: ItemSupplier.Fallon,
                label: 'Fallon',
                icon: 'check'
              },
              {
                id: ItemSupplier.Customer,
                label: 'Customer',
                icon: 'user'
              }
            ]}
            answer={itemSupplier}
            changeAnswer={(itemSupplier) =>
              updateResult({
                itemSupplier,
                installType: undefined,
                location: undefined,
                heatLight: undefined
              })
            }
          />
          <IsItemAustralianCompliant
            updateResult={updateResult}
            itemSupplier={itemSupplier}
            suppliedAnswer={australianCompliant}
          />
        </>
      )}

      {jobIsNotCompliant && serviceType === ServiceType.Installation ? (
        <>
          <OutOfScope issue={'Exhaust Fans'} isOutOfScope={true} />
        </>
      ) : (
        <>
          {(serviceType === ServiceType.Installation ||
            (serviceType === ServiceType.Repair && repairToReplacement === RepairToReplacement.Yes)) && (
            <>
              <SingleSelectField
                question={notesGenerator.getQuestion('installType')}
                options={[
                  {
                    id: InstallType.NewInstall,
                    label: 'New install'
                  },
                  {
                    id: InstallType.Replacement,
                    label: 'Replacement'
                  }
                ]}
                answer={installType}
                changeAnswer={(installType) => updateResult({ installType })}
              />

              <SingleSelectField
                question={notesGenerator.getQuestion('location')}
                options={[
                  {
                    id: ExhaustFanLocation.Kitchen,
                    label: 'Kitchen'
                  },
                  {
                    id: ExhaustFanLocation.Toilet,
                    label: 'Toilet'
                  },
                  {
                    id: ExhaustFanLocation.Bathroom,
                    label: 'Bathroom'
                  }
                ]}
                answer={location}
                changeAnswer={(location) => updateResult({ location })}
              />

              <SingleSelectField
                question={notesGenerator.getQuestion('heatLight')}
                options={[
                  {
                    id: HeatLight.Yes,
                    label: 'Yes, with heat light'
                  },
                  {
                    id: HeatLight.No,
                    label: 'No'
                  }
                ]}
                answer={heatLight}
                changeAnswer={(heatLight) => updateResult({ heatLight })}
              />
            </>
          )}
        </>
      )}

      <EuiSpacer />
      <EuiButton disabled={!canComplete} onClick={() => onNext()}>
        Next
      </EuiButton>
    </TopicWorkflowAction>
  )
}
