import { EuiIcon } from '@elastic/eui'
import { ContactMergeStatus } from '../api/generated-types'

export interface ContactMergeStatusIconProps {
  status: ContactMergeStatus
}

export const ContactMergeStatusIcon = ({ status }: ContactMergeStatusIconProps) => {
  switch (status) {
    case ContactMergeStatus.Master:
      return <EuiIcon type="accessibility" />
    case ContactMergeStatus.Merged:
      return <EuiIcon type="branchUser" />
    case ContactMergeStatus.None:
      return <EuiIcon type="empty" />
  }
}
