import {
  EuiAvatar,
  EuiButtonIcon,
  EuiCommentList,
  EuiCommentProps,
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
  EuiText
} from '@elastic/eui'
import { EventType } from '@fallonsolutions/domain-event'
import { orderBy } from 'lodash-es'
import { DateTime } from 'luxon'
import ReactMarkdown from 'react-markdown'
import { ActivityFragment, CommentFragment } from '../api/generated-types'
import { dateConfig } from '../common/date-config-luxon'
import '../static/css/comment.css'
import { removeBotComments } from './remove-bot-comments'

interface CommentListProps {
  comments: CommentFragment[]
  activities: ActivityFragment[]
  filterBot?: boolean
}

const copy = (text: string) => {
  navigator.clipboard.writeText(text)
}

const copyAction = (text: string) => {
  return (
    <EuiButtonIcon
      title="Copy comment"
      aria-label="Copy comment"
      color="text"
      iconType="copy"
      onClick={() => copy(text)}
    />
  )
}

const CommentList = (props: CommentListProps) => {
  const comments = props.filterBot ? (props.comments ?? []).filter(removeBotComments) : (props.comments ?? [])
  const activities = props.activities ?? []

  const allItems = [...comments, ...activities]
  const allComments: (CommentFragment | ActivityFragment)[] = orderBy(allItems, ['created'], ['desc'])

  const euiComments = allComments.map((item) => {
    if ((item as ActivityFragment).description) {
      return activityToEuiComment(item as ActivityFragment)
    } else {
      return commentToEuiComment(item as CommentFragment)
    }
  })

  return <EuiCommentList comments={euiComments} className="euiCommentList--emphasisedTimestamp" />
}

export default CommentList

const commentToEuiComment = (comment: CommentFragment): EuiCommentProps => {
  const createdDate = DateTime.fromISO(comment.created)
  const dateStr = 'on ' + createdDate.toFormat(dateConfig.dateAndTime)
  const author = comment.author?.contactDetail?.fullName ?? 'Unknown user'
  const avatar = <EuiAvatar size="m" name={author} imageUrl={comment.author.avatar ?? ''} />

  const timesheetWeekLabel = comment.timesheetWeekId ? ' for the Week' : ''
  return {
    username: avatar,
    event: <ReactMarkdown>{`**${author}** added a comment ${dateStr}${timesheetWeekLabel}`}</ReactMarkdown>,
    timelineAvatar: <EuiIcon type="editorComment" size="l" style={{ backgroundColor: 'rgb(250,251,253)' }} />,
    children: (
      <EuiText size="s">
        <ReactMarkdown>{comment.message ?? ''}</ReactMarkdown>
      </EuiText>
    ),
    actions: copyAction(comment.message ?? '')
  }
}

const activityToEuiComment = (activity: ActivityFragment): EuiCommentProps => {
  const createdDate = DateTime.fromISO(activity.event?.date ?? activity.created)
  const dateStr = 'on ' + createdDate.toFormat(dateConfig.dateAndTime)
  const fullDateStr = `${createdDate.toFormat(dateConfig.fullDate)}, ${createdDate.toFormat(dateConfig.timeWithSeconds).toLowerCase()}`

  const icon = iconForActivity(activity.event?.type)

  const username = activity.user?.name ?? activity.user?.username ?? 'Unknown user'
  const avatar = username ? (
    <EuiAvatar size="m" name={username} style={{ marginRight: 5 }} imageUrl={activity.user?.avatar} />
  ) : (
    <EuiAvatar size="m" name={'Unknown user'} initials={'?'} />
  )

  const title = `**${username}** ${dateStr}`
  const description = `${activity.description}`

  return {
    username: '',
    event: (
      <>
        <EuiFlexGroup gutterSize="none" alignItems="center">
          <EuiFlexItem grow={false}>{avatar}</EuiFlexItem>
          <EuiFlexItem title={fullDateStr}>
            <ReactMarkdown>{title ?? ''}</ReactMarkdown>
            <ReactMarkdown>{description ?? ''}</ReactMarkdown>
          </EuiFlexItem>
        </EuiFlexGroup>
      </>
    ),
    timelineAvatar: icon
  }
}

// eslint-disable-next-line complexity
const iconForActivity = (event: string | undefined) => {
  if (!event) {
    return 'dotInCircle'
  }
  const backgroundColor = 'rgb(250,251,253)'
  switch (event) {
    case EventType.OptionSheetAcceptanceConfirmed:
      return <EuiIcon type="checkInCircleFilled" style={{ color: '#34C759', backgroundColor }} />
    case EventType.OptionSheetAcceptanceChanged:
      return <EuiIcon type="check" style={{ backgroundColor }} />
    case EventType.OptionGroupDeleted:
    case EventType.OptionDeleted:
    case EventType.OptionSheetDiscountDeleted:
      return <EuiIcon type="minus" style={{ backgroundColor }} />
    case EventType.OptionSheetDiscountAdded:
      return <EuiIcon type="tag" style={{ backgroundColor }} />
    case EventType.OptionAdded:
    case EventType.OptionGroupAdded:
      return <EuiIcon type="plus" size="m" style={{ backgroundColor }} />
    case EventType.InvoiceCreated:
    case EventType.CreditNoteCreated:
      return <EuiIcon type="document" size="m" style={{ backgroundColor }} />
    case EventType.OptionSheetCreated:
      return <EuiIcon type="plusInCircleFilled" size="m" style={{ backgroundColor }} />
    case EventType.OptionSheetUnlocked:
    case EventType.OptionUnlocked:
      return <EuiIcon type="lockOpen" size="m" style={{ backgroundColor }} />
    case EventType.OptionStatusUpdated:
      return <EuiIcon type="indexEdit" size="m" style={{ backgroundColor }} />
    case EventType.OptionEdited:
      return <EuiIcon type="pencil" size="m" style={{ backgroundColor }} />
    case EventType.OptionSheetMembershipLevelChanged:
      return <EuiIcon type="starEmpty" size="m" style={{ backgroundColor }} />
    case EventType.AppointmentReminderDispatched:
    case EventType.ScheduleEventSendCustomerEmailConfirmation:
    case EventType.EmailSent:
      return <EuiIcon type="email" size="m" style={{ backgroundColor }} />
    case EventType.JobReportPDFCreated:
      return <EuiIcon type="document" size="m" style={{ backgroundColor }} />
    case EventType.JobRequirementsUpdated:
      return <EuiIcon type="controlsHorizontal" size="m" style={{ backgroundColor }} />
    case EventType.JobReportCreated:
      return <EuiIcon type="dotInCircle" size="m" style={{ backgroundColor }} />
    case EventType.PaymentTaskCreated:
      return <EuiIcon type="bell" size="m" style={{ backgroundColor }} />
    case EventType.CustomerRefund:
    case EventType.PaymentCreated:
    case EventType.PaymentVoided:
      return <EuiIcon type="dotInCircle" size="m" style={{ backgroundColor }} />
    case EventType.AppointmentCreated:
    case EventType.AppointmentBookingWindowUpdated:
    case EventType.AppointmentStatusUpdated:
      return <EuiIcon type="calendar" size="m" style={{ backgroundColor }} />
    case EventType.AppointmentCancelled:
    case EventType.AppointmentAborted:
      return <EuiIcon type="calendar" size="m" style={{ color: 'accent', backgroundColor }} />
    case EventType.ScheduleEventCreated:
    case EventType.ScheduleEventMoved:
    case EventType.ScheduleEventDispatched:
      return <EuiIcon type="calendar" size="m" style={{ color: '#BD8AF1', backgroundColor }} />
    case EventType.SendSMSRequested:
      return <EuiIcon type="user" size="m" style={{ backgroundColor }} />
    case EventType.ScheduleEventCancelled:
    case EventType.ScheduleEventAborted:
    case EventType.ScheduleEventTypeUpdated:
    case EventType.ScheduleEventNotesUpdated:
    case EventType.ScheduleEventStatusUpdated:
    case EventType.ScheduleEventConfirmationUpdated:
    case EventType.ScheduleEventPriorityUpdated:
    case EventType.ScheduleEventReservationExtended:
    case EventType.ScheduleEventAssignedToAppointment:
      return <EuiIcon type="calendar" size="m" style={{ backgroundColor }} />
    case EventType.ScheduleEventDeleted:
      return <EuiIcon type="trash" size="m" style={{ backgroundColor }} />
    default:
      return <EuiIcon type="dotInCircle" size="m" style={{ backgroundColor }} />
  }
}
