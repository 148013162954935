import { QuestionDefinitions, getNotesGenerator } from '../../helpers/notes-generator'
import { TopicActionResult } from '../action-topic-model'
import { YesNoValue } from '../common/common-enums'

export interface TopicSmokeAlarmInspectionResult extends TopicActionResult {
  isSpecificInspection?: YesNoValue
  additionalNotes?: string
}

const questionDefinitions: QuestionDefinitions<TopicSmokeAlarmInspectionResult> = {
  isSpecificInspection: {
    question: 'Was there anything in particular you wanted the technician to look at or just a general check up?',
    statement: 'Specific issue:',
    excludeFromNotes: true
  },
  additionalNotes: {
    question: 'Not a problem at all. Can you please explain what you would like the technician to look at?',
    statement: 'Specific issue to inspect:'
  }
}

export const inspectionNotesGenerator = getNotesGenerator(questionDefinitions)
