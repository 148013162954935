import { EuiFlexGroup, EuiFlexItem, EuiSpacer, EuiText, EuiTitle } from '@elastic/eui'
import { MediaContext, MediaItemFragment } from '../api/generated-types'
import { useAuthenticated } from '../auth/authenticated-context'
import { JobPhotoUploader } from '../jobs/job-photo-uploader'
import { AdditionalContextMenuItems } from './media-item-context-menu'
import { MediaItemStack, MediaItemStackView } from './media-item-stack-view'
import { MediaItemView } from './media-item-view'

export interface MediaItemGroup {
  key: string
  title?: string
  subtitle?: string
  context?: MediaContext
  stacks: MediaItemStack[]
  defaultSubject?: string
}

export interface MediaItemGroupViewProps {
  jobId?: string
  group: MediaItemGroup
  itemWidth: number
  showJobLink?: boolean
  uploadEnabled?: boolean
  selectedPhotos?: string[]
  onCreateMediaItems?: (mediaItems: MediaItemFragment[]) => void
  selectMediaItem?: (photoIds: string[]) => void
  mediaSubject?: string
  selectMode?: boolean
  additionalContextMenuItems?: AdditionalContextMenuItems[]
}

export const MediaItemGroupView = ({
  jobId,
  group,
  itemWidth,
  showJobLink,
  selectedPhotos,
  onCreateMediaItems,
  uploadEnabled,
  selectMediaItem,
  selectMode,
  additionalContextMenuItems
}: MediaItemGroupViewProps) => {
  const userFragment = useAuthenticated().userFragment
  const hasDeveloperFeatures = userFragment.permissions?.developerFeatures === true

  const mediaCount = group.stacks.reduce((count, stack) => count + stack.mediaItems.length, 0)

  return (
    <div>
      {group.title && (
        <>
          <EuiFlexGroup alignItems="flexEnd">
            <EuiFlexItem grow={true}>
              <EuiTitle size="s">
                <h2>{group.title}</h2>
              </EuiTitle>
              {group.subtitle && (
                <>
                  <EuiSpacer size="xs" />
                  <EuiText size="s">{group.subtitle}</EuiText>
                </>
              )}
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiText size="s">{mediaCount} photos</EuiText>
            </EuiFlexItem>
          </EuiFlexGroup>
          <EuiSpacer size="m" />
        </>
      )}
      <EuiFlexGroup gutterSize="none" wrap={true} alignItems="center">
        {hasDeveloperFeatures && uploadEnabled && jobId && group.context && (
          <EuiFlexItem grow={false} style={{ textAlign: 'center', padding: '1px' }}>
            <div
              className="media-item-stack media-item-stack--empty"
              style={{ width: `${itemWidth}px`, height: `${itemWidth}px` }}
            >
              <div className="media-item-stack__content">
                <div className="media-item-stack__upload" style={{ height: `${itemWidth}px`, width: `${itemWidth}px` }}>
                  <JobPhotoUploader
                    jobId={jobId}
                    label="Upload"
                    subject={group.defaultSubject}
                    context={group.context}
                    onCreateMediaItems={onCreateMediaItems}
                  />
                </div>
                {/* <div className="media-item-stack__subject">New subject</div> */}
              </div>
            </div>
          </EuiFlexItem>
        )}
        {group.stacks.map((stack, index) =>
          stack.subject ? (
            <EuiFlexItem key={`${stack.subject}-${index}`} grow={false} style={{ textAlign: 'center', padding: '1px' }}>
              <>
                <MediaItemStackView
                  jobId={jobId}
                  itemWidth={itemWidth}
                  stack={stack}
                  context={group.context}
                  uploadEnabled={uploadEnabled}
                  onCreateMediaItems={onCreateMediaItems}
                />
              </>
            </EuiFlexItem>
          ) : (
            stack.mediaItems.map((mediaItem) => (
              <EuiFlexItem key={mediaItem.id} grow={false} style={{ textAlign: 'center', padding: '1px' }}>
                <MediaItemView
                  mediaItem={mediaItem}
                  width={itemWidth}
                  showDescription={true}
                  showJobLink={showJobLink}
                  mediaItemSelected={selectMediaItem}
                  isSelected={selectedPhotos && selectedPhotos.includes(mediaItem.id)}
                  additionalContextMenuItems={additionalContextMenuItems}
                  selectedMode={selectMode}
                />
              </EuiFlexItem>
            ))
          )
        )}
      </EuiFlexGroup>
    </div>
  )
}
