import { BaseActionInput, BaseActionResult, WorkflowActionProps } from '../workflow-model'
import { EuiButton } from '@elastic/eui'
import { ContactActionResult } from './action-contact'
import { CustomerAction, CustomerActionResult } from './action-customer'
import {
  CaptureBlackMemberNomineesAction,
  CaptureBlackMemberNomineesActionResult
} from './action-capture-black-member-nominees'

export interface BlackMemberNomineesActionInput extends BaseActionInput {
  contact?: ContactActionResult
}

export interface BlackMemberNomineesActionResult extends BaseActionResult {
  customer?: CustomerActionResult
  captureBlackMemberNominees?: CaptureBlackMemberNomineesActionResult
}

export const BlackMemberNomineesAction = (
  props: WorkflowActionProps<BlackMemberNomineesActionInput, BlackMemberNomineesActionResult>
) => {
  const { input, onUpdate, result } = props
  const { contact } = input ?? {}
  const { customer, captureBlackMemberNominees } = result ?? {}

  const setCustomer = (customer?: CustomerActionResult) => onUpdate({ ...result, customer })
  const setCaptureBlackMemberNominees = (captureBlackMemberNominees?: CustomerActionResult) =>
    onUpdate({ ...result, captureBlackMemberNominees })

  const allTasksCompleted = customer?.actionCompleted && captureBlackMemberNominees?.actionCompleted

  return (
    <>
      <CustomerAction input={{ contact }} result={customer} onUpdate={setCustomer} />
      {customer?.actionCompleted && (
        <CaptureBlackMemberNomineesAction
          input={{}}
          result={captureBlackMemberNominees}
          onUpdate={setCaptureBlackMemberNominees}
        />
      )}
      {allTasksCompleted && (
        <>
          <EuiButton
            fill
            color="primary"
            onClick={() => onUpdate({ ...result, actionCompleted: true })}
            disabled={!allTasksCompleted}
          >
            I've completed these steps
          </EuiButton>
        </>
      )}
    </>
  )
}
