import {
  TopicQualification,
  TopicQualificationResult,
  TopicRequirementsResult,
  createOutcomeGenerator,
  createRequirements
} from '../common/common-outcome'
import { CustomerType, JobType, TradeType } from '../../../api/generated-types'
import {
  POOL_OUT_OF_SCOPE_ISSUES,
  PoolChlorinatorIssue,
  PoolHeatPumpAge,
  PoolHeatingType,
  PoolIssue,
  PoolLeakLocation,
  PoolPumpIssue,
  TopicPoolActionResult
} from './action-topic-pool-model'
import { includes } from 'lodash-es'
import { YesNoValue } from '../common/common-enums'

const POOL_PLUMBING_SKILL = 'PoolPlumbing'
const GENERAL_ELECTRICAL_SKILL = 'GeneralElectrical'

const getRequirements = (result: TopicPoolActionResult | undefined): TopicRequirementsResult => {
  if (!result) return {}
  const {
    issue,
    filterIssue,
    pumpIssue,
    additionalNotes,
    heatPumpAge,
    heatingType,
    leakLocation,
    chlorinatorIssue,
    powerpointTripping,
    poolType
  } = result
  switch (issue) {
    case PoolIssue.Pumps:
      if (pumpIssue === PoolPumpIssue.TrippingPower && powerpointTripping === YesNoValue.Yes) {
        return { requirements: createRequirements([GENERAL_ELECTRICAL_SKILL]), trade: TradeType.Electrical }
      } else if (poolType === CustomerType.Domestic) {
        return { requirements: createRequirements([POOL_PLUMBING_SKILL]) }
      } else {
        return {}
      }
    case PoolIssue.Filters:
      return !!filterIssue && additionalNotes?.length ? { requirements: createRequirements([POOL_PLUMBING_SKILL]) } : {}
    case PoolIssue.Chlorinators:
      if (includes([PoolChlorinatorIssue.NotTurningOn, PoolChlorinatorIssue.TrippingPower], chlorinatorIssue)) {
        switch (powerpointTripping) {
          case YesNoValue.Yes:
            return { requirements: createRequirements([GENERAL_ELECTRICAL_SKILL]), trade: TradeType.Electrical }
          case YesNoValue.No:
            return { requirements: createRequirements([POOL_PLUMBING_SKILL]) }
          default:
            return {}
        }
      } else if (chlorinatorIssue) {
        return { requirements: createRequirements([POOL_PLUMBING_SKILL]) }
      } else {
        return {}
      }
    case PoolIssue.Leaks:
      if (leakLocation === PoolLeakLocation.PoolBody) {
        return {} // out of scope
      } else if (leakLocation) {
        return { requirements: createRequirements([POOL_PLUMBING_SKILL]) }
      } else {
        return {}
      }
    case PoolIssue.Heating:
      if (heatingType === PoolHeatingType.Solar) {
        return { requirements: createRequirements([POOL_PLUMBING_SKILL]) }
      } else if (heatingType === PoolHeatingType.HeatPump && heatPumpAge === PoolHeatPumpAge.OlderThan5Years) {
        return { requirements: createRequirements([POOL_PLUMBING_SKILL]) }
      } else {
        return {}
      }
    default:
      return {}
  }
}

const getQualification = (result: TopicPoolActionResult | undefined): TopicQualificationResult => {
  if (!result || !result.issue) return { qualification: TopicQualification.Unknown }
  const { issue, leakLocation, poolType, heatingType, heatPumpAge } = result
  if (includes(POOL_OUT_OF_SCOPE_ISSUES, issue)) {
    return { qualification: TopicQualification.OutOfScope }
  }
  if (issue === PoolIssue.Leaks && leakLocation === PoolLeakLocation.PoolBody) {
    return { qualification: TopicQualification.OutOfScope }
  }
  if (issue === PoolIssue.Pumps && poolType === CustomerType.Commercial) {
    return { qualification: TopicQualification.OutOfScope }
  }
  if (
    issue === PoolIssue.Heating &&
    heatingType === PoolHeatingType.HeatPump &&
    heatPumpAge === PoolHeatPumpAge.Years5OrLess
  ) {
    return { qualification: TopicQualification.OutOfScope }
  }
  return { qualification: TopicQualification.InScope }
}

export const outcomeGenerator = createOutcomeGenerator<TopicPoolActionResult>(
  {
    qualification: TopicQualification.Unknown,
    trade: TradeType.Plumbing,
    type: JobType.Service,
    category: 'Pool'
  },
  getQualification,
  getRequirements
)
