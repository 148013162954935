import { EuiButton, EuiSpacer } from '@elastic/eui'
import { JobTopicType } from '@fallonsolutions/types'
import { EnquiryAbortReason } from '../../../api/generated-types'
import { Callout } from '../../../common/callout'
import { NotesGenerator } from '../../helpers/notes-generator'
import { ResultUpdater } from '../../helpers/results-updater'
import { useWorkflow } from '../../helpers/workflow-provider'
import { FreeTextQuestionField } from '../../question-fields/free-text-question-field'
import { SingleSelectField } from '../../question-fields/single-select-question-field'
import { YesNoValue } from '../common/common-enums'
import { yesNoOptions } from '../common/common-options'
import { TopicPlumbingDrainInput } from './action-topic-plumbing-drain'
import {
  StormwaterIssue,
  TopicPlumbingDrainActionResult,
  newInstallOrReplaceOptions,
  stormwaterBlockedPossibleCauseOptions,
  stormwaterConnectOptions,
  stormwaterOptions,
  stormwaterSymptomOptions
} from './action-topic-plumbing-drain-model'
import { ChangeJobTopicToRegularDrains } from './change-topic-to-drains'

export interface DrainStormwaterProps {
  result?: TopicPlumbingDrainActionResult
  notesGenerator: NotesGenerator<TopicPlumbingDrainActionResult>
  updateResult: ResultUpdater<TopicPlumbingDrainActionResult>
  input?: TopicPlumbingDrainInput
}

export const DrainStormwater = (props: DrainStormwaterProps) => {
  const { result, notesGenerator, updateResult, input } = props
  const { drainClearSpecial: isSpecial } = input ?? {}
  const {
    itemIssue,
    drainIssue,
    stormwaterConnect,
    stormwaterSymptom,
    stormwaterBlockedPossibleCause,
    drainCameraEligibility,
    newInstallOrReplace,
    houseBuild,
    blockedPrior,
    causeNote
  } = result ?? {}
  const workflowContext = useWorkflow()
  return (
    <>
      {isSpecial ? (
        <>
          <ChangeJobTopicToRegularDrains
            message={
              'Regrettably, this particular offer is exclusively applicable to sewer jobs. However, we are more than willing to dispatch a technician to assess your stormwater issue at our standard rates. Would you like us to schedule a visit for further examination?'
            }
            handleJobTopicChanged={input?.handleChangeJobTopic}
            results={result}
          />
        </>
      ) : (
        <>
          <SingleSelectField
            question={notesGenerator.getQuestion('drainIssue')}
            options={stormwaterOptions}
            answer={drainIssue}
            changeAnswer={(drainIssue) => {
              updateResult({ drainIssue })
            }}
          />
          {drainIssue === StormwaterIssue.Broken && (
            <FreeTextQuestionField
              question={notesGenerator.getQuestion('itemIssue')}
              answer={itemIssue}
              rows={3}
              changeAnswer={(itemIssue) => updateResult({ itemIssue })}
            ></FreeTextQuestionField>
          )}
          {drainIssue === StormwaterIssue.ConnectStormwater && (
            <SingleSelectField
              question={notesGenerator.getQuestion('stormwaterConnect')}
              options={stormwaterConnectOptions}
              answer={stormwaterConnect}
              changeAnswer={(stormwaterConnect) => {
                updateResult({ stormwaterConnect })
              }}
            />
          )}
          {drainIssue === StormwaterIssue.SolutionsOrImprovements && (
            <SingleSelectField
              question={notesGenerator.getQuestion('stormwaterSymptom')}
              options={stormwaterSymptomOptions}
              answer={stormwaterSymptom}
              changeAnswer={(stormwaterSymptom) => {
                updateResult({ stormwaterSymptom })
              }}
            />
          )}
          {drainIssue === StormwaterIssue.BlockedOrOverflowing && (
            <>
              <SingleSelectField
                question={notesGenerator.getQuestion('blockedPrior')}
                options={yesNoOptions}
                answer={blockedPrior}
                changeAnswer={(blockedPrior) => {
                  updateResult({ blockedPrior })
                }}
              />
              {blockedPrior === YesNoValue.Yes && (
                <FreeTextQuestionField
                  question={notesGenerator.getQuestion('causeNote')}
                  answer={causeNote}
                  rows={3}
                  changeAnswer={(causeNote) => updateResult({ causeNote })}
                />
              )}
            </>
          )}
          {drainIssue === StormwaterIssue.CameraInspections && (
            <>
              <SingleSelectField
                question={notesGenerator.getQuestion('stormwaterBlockedPossibleCause')}
                options={stormwaterBlockedPossibleCauseOptions}
                answer={stormwaterBlockedPossibleCause}
                changeAnswer={(stormwaterBlockedPossibleCause) => {
                  updateResult({ stormwaterBlockedPossibleCause })
                }}
              />
              {!!stormwaterBlockedPossibleCause && (
                <SingleSelectField
                  question={notesGenerator.getQuestion('drainCameraEligibility')}
                  options={yesNoOptions}
                  answer={drainCameraEligibility}
                  changeAnswer={(drainCameraEligibility) => {
                    updateResult({ drainCameraEligibility })
                  }}
                />
              )}
              {!!stormwaterBlockedPossibleCause && drainCameraEligibility === YesNoValue.No && (
                <>
                  <Callout type="warning" title="Out of Scope" />
                  <Callout type="script">Unfortunately this type of work is out of scope for us.</Callout>
                  <EuiSpacer />
                  <EuiButton
                    color="warning"
                    onClick={() =>
                      workflowContext.abort({
                        abortReason: EnquiryAbortReason.CustomerNeedsAreOutOfService,
                        notes: `Customer was enquiring about : ${drainIssue}`
                      })
                    }
                  >
                    Offer a voucher and end call
                  </EuiButton>
                </>
              )}
            </>
          )}
          {/* {!!stormwaterBlockedPossibleCause && (
        <Callout
          type="script"
          title="Our technician can look into this for you. They may need to conduct a camera inspection."
        />
      )} */}

          {drainIssue === StormwaterIssue.NewOrReplace && (
            <>
              <SingleSelectField
                question={notesGenerator.getQuestion('newInstallOrReplace')}
                options={newInstallOrReplaceOptions}
                answer={newInstallOrReplace}
                changeAnswer={(newInstallOrReplace) => {
                  updateResult({ newInstallOrReplace })
                }}
              />

              {!!newInstallOrReplace && (
                <>
                  <SingleSelectField
                    question={notesGenerator.getQuestion('houseBuild')}
                    answer={houseBuild}
                    changeAnswer={(houseBuild) => updateResult({ houseBuild })}
                    options={yesNoOptions}
                  />
                  {houseBuild === YesNoValue.Yes && (
                    <>
                      <Callout type="script">Unfortunately this type of work is out of scope for us.</Callout>
                      <EuiSpacer />
                      <EuiButton
                        color="warning"
                        onClick={() =>
                          workflowContext.abort({
                            abortReason: EnquiryAbortReason.CustomerNeedsAreOutOfService,
                            notes: `Customer was enquiring about Stormwater drain: ${drainIssue}`
                          })
                        }
                      >
                        Offer a voucher and end call
                      </EuiButton>
                    </>
                  )}
                </>
              )}
            </>
          )}
          {drainIssue === StormwaterIssue.Downpipe && (
            <>
              <EuiButton onClick={() => workflowContext.changeJobTopic(JobTopicType.Gutters)} color="warning">
                Switch topic to Gutters
              </EuiButton>
            </>
          )}
          {drainIssue === StormwaterIssue.Leaking && (
            <EuiButton onClick={() => workflowContext.changeJobTopic(JobTopicType.WaterLeak)} color="warning">
              Switch topic to Water Leak
            </EuiButton>
          )}
        </>
      )}
    </>
  )
}
