import { EuiButtonGroup, EuiButtonGroupOptionProps, EuiFlexGroup, EuiFlexItem, EuiIcon } from '@elastic/eui'
import { useEffect, useState } from 'react'
import { MembershipLevel } from '../api/generated-types'
import '../static/css/member-status.css'
import StandardMemberLogo from '../static/images/member-logo-standard.svg'

interface MemberStatusProps {
  membershipLevel: MembershipLevel
  editable: boolean
  onChange: (membershipLevel: MembershipLevel) => void
  loading?: boolean
  compressed?: boolean
  label?: string
  disableSelection?: boolean
}

export const MembershipLevelToggle = ({
  membershipLevel,
  onChange,
  editable,
  loading,
  disableSelection,
  compressed,
  label
}: MemberStatusProps) => {
  // local state used so we can immediately respond to user event while waiting for any async operation to complete
  const [localMembershipLevel, setLocalMembershipLevel] = useState(membershipLevel)
  useEffect(() => {
    setLocalMembershipLevel(membershipLevel)
  }, [membershipLevel])

  const handleSetMembershipLevel = (membershipLevel: MembershipLevel) => {
    setLocalMembershipLevel(membershipLevel)
    onChange(membershipLevel)
  }

  const options: EuiButtonGroupOptionProps[] = [
    MembershipLevel.None,
    MembershipLevel.Complimentary,
    MembershipLevel.Standard,
    MembershipLevel.Premium
  ].map((level) => ({
    id: level,
    label: membershipLevelLabel(level)
  }))

  return (
    <EuiFlexGroup
      alignItems="center"
      justifyContent="spaceBetween"
      gutterSize="none"
      className={`member-status ${compressed ? 'members-status--compressed' : ''}`}
    >
      <EuiFlexItem grow={false}>
        <EuiIcon type={StandardMemberLogo} size="l" style={{ marginRight: '9px' }} />
      </EuiFlexItem>
      <EuiFlexItem grow={false} className="member-status__label">
        <div style={{ fontSize: '16px', fontWeight: 600 }}>{label ?? 'Home Protection Plan'}</div>
      </EuiFlexItem>
      <EuiFlexItem grow={true} />
      <EuiFlexItem grow={false} className="member-status__value">
        {editable ? (
          <EuiButtonGroup
            legend="Membership status"
            options={options}
            idSelected={localMembershipLevel}
            onChange={(id: string) => handleSetMembershipLevel(id as MembershipLevel)}
            buttonSize="compressed"
            color="primary"
            isDisabled={disableSelection || loading}
          />
        ) : (
          <>{membershipLevelLabel(membershipLevel)}</>
        )}
      </EuiFlexItem>
    </EuiFlexGroup>
  )
}

const membershipLevelLabel = (membershipLevel: MembershipLevel): string => {
  switch (membershipLevel) {
    case MembershipLevel.None:
      return 'Non-member'
    case MembershipLevel.Complimentary:
      return 'Complimentary'
    case MembershipLevel.Standard:
      return '1 Year Member'
    case MembershipLevel.Premium:
      return '3 Year Member'
  }
}
