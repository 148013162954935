import { useMutation } from '@apollo/client'
import {
  EuiButton,
  EuiButtonEmpty,
  EuiFormRow,
  EuiHorizontalRule,
  EuiLink,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiOverlayMask,
  EuiSpacer,
  EuiSwitch
} from '@elastic/eui'
import { compact, first } from 'lodash-es'
import { useEffect, useState } from 'react'
import { ContactCustomerRole, ContactFragment, SetContactCustomerRolesDocument } from '../api/generated-types'
import { CustomerCardContainer } from '../customers/customer-card-container'
import CustomerComboBox, { CustomerComboBoxItem } from '../customers/customer-combo-box'

export interface CreateContactCustomerAssociationFormProps {
  contact: ContactFragment
  onClose: () => void
}

export const ContactCustomerAssociationForm = ({ contact, onClose }: CreateContactCustomerAssociationFormProps) => {
  const [isPrimary, setIsPrimary] = useState(false)
  const [isBilling, setIsBilling] = useState(false)
  const [customer, setCustomer] = useState<CustomerComboBoxItem | undefined>(undefined)
  const notes = ''

  const [setContactCustomerRoles, { data, loading }] = useMutation(SetContactCustomerRolesDocument)

  const handleSubmit = () => {
    if (!customer) {
      return
    }
    setContactCustomerRoles({
      variables: {
        input: {
          contactId: contact.id,
          customerId: customer?.id,
          roles: [
            ...(isPrimary ? [ContactCustomerRole.Primary] : []),
            ...(isBilling ? [ContactCustomerRole.Billing] : [])
          ],
          ...(notes.trim().length > 0 && { notes: notes.trim() })
        }
      }
    })
  }

  useEffect(() => {
    if (data?.setContactCustomerRoles?.contact) {
      onClose()
    }
  }, [data, onClose])

  return (
    <>
      <EuiOverlayMask>
        <EuiModal onClose={onClose} style={{ minHeight: '700px', minWidth: '500px' }}>
          <EuiModalHeader>
            <EuiModalHeaderTitle>Create customer association</EuiModalHeaderTitle>
          </EuiModalHeader>
          <EuiModalBody>
            <EuiFormRow label="Select customer">
              <>
                <CustomerComboBox
                  singleSelection={true}
                  customers={compact([customer])}
                  onChangeCustomers={(customers) => setCustomer(first(customers))}
                />
              </>
            </EuiFormRow>
            <EuiSpacer />
            {customer && (
              <>
                <CustomerCardContainer customerId={customer.id} />
                <EuiSpacer size="m" />
                <EuiLink target="_blank" href={`/customers/${customer.id}`}>
                  View customer
                </EuiLink>

                <EuiHorizontalRule />
                <EuiFormRow label={`Select ${contact.detail?.fullName ?? 'No name'} roles`}>
                  <>
                    <EuiSpacer size="s" />
                    <EuiSwitch
                      label="Primary"
                      checked={isPrimary}
                      onChange={() => setIsPrimary(!isPrimary)}
                      compressed
                    />
                    <EuiSpacer size="s" />
                    <EuiSwitch
                      label="Billing"
                      checked={isBilling}
                      onChange={() => setIsBilling(!isBilling)}
                      compressed
                    />
                  </>
                </EuiFormRow>
              </>
            )}
          </EuiModalBody>
          <EuiModalFooter>
            {!loading && (
              <EuiButtonEmpty onClick={onClose} disabled={loading}>
                Cancel
              </EuiButtonEmpty>
            )}
            <EuiButton fill disabled={!customer} onClick={() => handleSubmit()} isLoading={loading}>
              Create association
            </EuiButton>
          </EuiModalFooter>
        </EuiModal>
      </EuiOverlayMask>
    </>
  )
}
