import { clone, filter, findIndex } from 'lodash-es'
import { useEffect, useState } from 'react'
import { EuiFacetButton, EuiFacetGroup, EuiIcon } from '@elastic/eui'

interface AmountFacetsProps {
  selectFacets: (facets: RevenueFacet[]) => void
}

export interface RevenueFacet {
  id: string
  label: string
  min?: number
  max?: number
  enabled: boolean
  iconColor: string
}

const AmountFacets = (props: AmountFacetsProps) => {
  const offColor = '#E5E8F0'
  const onColor = '#34C759'
  const facetDefaults: RevenueFacet[] = [
    {
      id: 'facet-100',
      label: 'Under $100',
      min: 0,
      max: 99,
      enabled: false,
      iconColor: offColor
    },
    {
      id: 'facet-500',
      label: '$100 - $500',
      min: 100,
      max: 500,
      enabled: false,
      iconColor: offColor
    },
    {
      id: 'facet-1000',
      label: '$500 - $1,000',
      min: 500,
      max: 1000,
      enabled: false,
      iconColor: offColor
    },
    {
      id: 'facet-5000',
      label: '$1,000 - $5,000',
      min: 1000,
      max: 5000,
      enabled: false,
      iconColor: offColor
    },
    {
      id: 'facet-over-5000',
      label: 'Over $5,000',
      min: 5000,
      enabled: false,
      iconColor: offColor
    }
  ]
  const { selectFacets } = props
  const [facets, setFacets] = useState(facetDefaults)

  const onClickFacet = (facet: RevenueFacet) => {
    const updatedFacets = clone(facets)
    const f = findIndex(updatedFacets, { id: facet.id })
    if (f >= 0) {
      updatedFacets[f].enabled = !updatedFacets[f].enabled
      updatedFacets[f].iconColor = updatedFacets[f].enabled ? onColor : offColor
    }
    setFacets(updatedFacets)
  }

  useEffect(() => {
    selectFacets(filter(facets, 'enabled'))
  }, [selectFacets, facets])

  return (
    <EuiFacetGroup layout="horizontal">
      {facets.map((facet: RevenueFacet) => (
        <EuiFacetButton
          key={facet.id}
          // quantity={facet.quantity}
          icon={<EuiIcon type="dot" color={facet.iconColor} />}
          isSelected={facet.enabled}
          onClick={() => onClickFacet(facet)}
          style={{ minInlineSize: '40px', padding: '0px' }}
        >
          {facet.label}
        </EuiFacetButton>
      ))}
    </EuiFacetGroup>
  )
}

export default AmountFacets
