import { SingleSelectField } from '../../question-fields/single-select-question-field'
import { NotesGenerator } from '../../helpers/notes-generator'
import { ResultUpdater } from '../../helpers/results-updater'
import { GutterSize, TopicGutterActionResult } from './action-topic-gutter-model'
import { map } from 'lodash-es'

export interface GutterReplaceProps {
  result?: TopicGutterActionResult
  notesGenerator: NotesGenerator<TopicGutterActionResult>
  updateResult: ResultUpdater<TopicGutterActionResult>
}

export const GutterReplace = (props: GutterReplaceProps) => {
  const { result, notesGenerator, updateResult } = props
  const { replacementSectionSize } = result ?? {}
  return (
    <>
      <SingleSelectField
        question={notesGenerator.getQuestion('replacementSectionSize')}
        options={map(GutterSize, (id) => ({ id }))}
        answer={replacementSectionSize}
        changeAnswer={(replacementSectionSize) => updateResult({ replacementSectionSize })}
      />
    </>
  )
}
