import { useQuery } from '@apollo/client'
import { EuiLoadingSpinner } from '@elastic/eui'
import { GetReviewDocument } from '../api/generated-types'
import { ReviewForm } from './review-form'

export interface EditReviewFormProps {
  reviewId: string
  onClose: () => void
  onFiveStarReview?: () => void
}

export const EditReviewForm = ({ onClose, reviewId }: EditReviewFormProps) => {
  const { data, loading } = useQuery(GetReviewDocument, {
    variables: {
      input: {
        reviewId
      }
    }
  })

  const review = data?.getReview?.review
  console.log('getting review', review)
  return loading || !review ? (
    <EuiLoadingSpinner />
  ) : (
    <ReviewForm
      review={review}
      customerId={review.customerId ?? undefined}
      jobIds={review.jobIds ?? []}
      userIds={review.userIds ?? []}
      onClose={onClose}
    />
  )
}
