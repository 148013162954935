import React from 'react'
import { EuiFlexGroup, EuiFlexItem, EuiFormLabel, EuiText } from '@elastic/eui'
import '../static/css/invoice.css'
import { InvoiceFragment, InvoiceSource } from '../api/generated-types'
import { DateTime } from 'luxon'
import { dateConfig } from '../common/date-config-luxon'
import { myobInvoiceLink } from '../myob/myob-links'
import UserCard from '../users/user-card'

interface InvoiceHeaderProps {
  invoice: InvoiceFragment
}

const InvoiceHeader = (props: InvoiceHeaderProps) => {
  const { invoice } = props

  let issueDate: string
  if (invoice?.postDate) {
    issueDate = DateTime.fromISO(invoice.postDate).toFormat(dateConfig.fullDate)
  } else {
    issueDate = 'Unknown issue date'
  }

  const isLegacyInvoice = invoice.source === InvoiceSource.Legacy
  const invoiceNumber = !isLegacyInvoice ? invoice.number : `M${invoice.number}`

  return (
    <div className="invoice-header">
      <EuiFlexGroup>
        <EuiFlexItem>
          <div className="medium-label">
            <a href={`/invoices/${invoice.id}`}>{invoiceNumber}</a>
          </div>
        </EuiFlexItem>
        <EuiFlexItem style={{ textAlign: 'right' }}>
          {!isLegacyInvoice && (
            <div>
              {invoice && (
                <a href={myobInvoiceLink(invoice)} target="_blank" rel="noopener noreferrer">
                  View in MYOB
                </a>
              )}
            </div>
          )}
        </EuiFlexItem>
      </EuiFlexGroup>
      <EuiFlexGroup>
        <EuiFlexItem>
          {invoice.technician && <UserCard user={invoice.technician} avatarSize="s" showContact={false} />}
        </EuiFlexItem>
        <EuiFlexItem>
          {invoice.purchaseOrder && (
            <div>
              <EuiFormLabel>Purchase order</EuiFormLabel>
              <EuiText style={{ fontSize: '11px', paddingTop: '6px' }}>{invoice.purchaseOrder}</EuiText>
            </div>
          )}
        </EuiFlexItem>
        <EuiFlexItem style={{ textAlign: 'right' }}>Issued {issueDate}</EuiFlexItem>
      </EuiFlexGroup>
    </div>
  )
}

export default InvoiceHeader
