import { useSubscription } from '@apollo/client'
import { EuiLink, EuiText } from '@elastic/eui'
import { getDepartmentForRiskAssessment } from '@fallonsolutions/risk-assessment'
import { v4 } from 'uuid'
import { Department, OnRiskAssessmentCreatedDocument, RiskAssessmentStatusType, UserRole } from '../api/generated-types'
import { useAuthenticated } from '../auth/authenticated-context'
import { useNotification } from './notification-provider'

export const RiskAssessmentSubscription = () => {
  const userFragment = useAuthenticated().userFragment
  const isDev = userFragment?.permissions?.developerFeatures === true
  const isSupervisor = userFragment?.role === UserRole.Supervisor && isDev
  const department = getDepartmentForRiskAssessment(userFragment?.department ?? Department.None)
  const notification = useNotification()

  useSubscription(OnRiskAssessmentCreatedDocument, {
    variables: {
      tenant: 'fallonsolutions',
      jobDepartment: department,
      status: RiskAssessmentStatusType.InReview
    },
    skip: !isSupervisor,
    onError: (error) => {
      console.log('risk assessment subscription error', error)
    },
    onData: ({ data }) => {
      const riskAssessment = data?.data?.onRiskAssessmentCreated?.riskAssessment
      console.log('risk assessment created', riskAssessment)
      notification?.createToast({
        id: v4(),
        toastLifeTimeMs: 10000,
        title: (
          <>
            <EuiLink href={`/jobs/${riskAssessment?.jobId}/risk-assessments`} external={true}>
              Risk assessment created for job {riskAssessment?.job?.number}
            </EuiLink>
          </>
        ),
        color: 'success',
        text: (
          <>
            <EuiText size="s">A risk assessment requiring supervisor input has been created.</EuiText>
          </>
        )
      })
    }
  })

  return <></>
}
