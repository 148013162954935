import { ContactMediumRole } from '../api/generated-types'
import '../static/css/contact-medium-role-badge.css'

export interface ContactMediumRoleBadgeProps {
  role: ContactMediumRole
  title?: string
}

export const ContactMediumRoleBadge = ({ role, title }: ContactMediumRoleBadgeProps) => {
  return (
    <span className={`contact-medium-role-badge contact-medium-role-badge--${role.toLowerCase()}`} title={title}>
      {role}
    </span>
  )
}
