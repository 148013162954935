import { ContactInput, CustomerLinkFragment } from '../api/generated-types'
import { SelectedCustomer } from './workflow-customer-model'
import { EnquiryTypeReference } from './workflow-model'

export interface OnChangeContactProps {
  customer?: CustomerLinkFragment
  contactId?: string
  contactInput?: ContactInput
}

export const toSelectedCustomer = (customer: CustomerLinkFragment): SelectedCustomer => {
  return {
    id: customer.id,
    reference: customer.number ?? 'Unknown',
    firstName: customer.mainContact?.detail?.firstName ?? 'Unknown',
    fullName: customer.mainContact?.detail?.fullName ?? 'Unknown',
    label: customer.mainContact?.detail?.fullName ?? 'Unknown',
    email: customer.mainContact?.detail?.email,
    phone: customer.mainContact?.detail?.phone,
    alternatePhone: customer.mainContact?.detail?.alternatePhone,
    contacts: customer.contacts ?? []
  }
}

export const getCustomerForEnquiryType = (
  enquiryType: EnquiryTypeReference,
  customer: CustomerLinkFragment
): { customer: SelectedCustomer; customerKey: string } | undefined => {
  switch (enquiryType) {
    case EnquiryTypeReference.NewBooking:
      return {
        customer: toSelectedCustomer(customer),
        customerKey: 'customer'
      }
    default:
      return undefined
  }
}
