import { ContactExpandedFragment, GetContactDocument } from '../api/generated-types'
import { PreviewCard } from '../common/preview/preview-card'
import { ContactCard, ContactCardAddressDisplay } from './contact-card'

interface ContactPreviewProps {
  contactId: string
}

export const ContactPreview = ({ contactId }: ContactPreviewProps) => {
  const renderContact = (contact: ContactExpandedFragment | undefined): React.ReactNode => {
    if (!contact) {
      return <>Not Found</>
    }

    return contact.detail ? (
      <ContactCard contact={contact} reference={''} addressDisplay={ContactCardAddressDisplay.FullAddress} />
    ) : (
      <>Contact details not found</>
    )
  }

  return (
    <PreviewCard
      title="Contact"
      link={{
        url: `/contacts/${contactId}`,
        label: 'Open Contact'
      }}
      query={GetContactDocument}
      variables={{ input: { contactId } }}
      modelName="Contact"
    >
      {({ data }) => renderContact(data?.getContact?.contact ?? undefined)}
    </PreviewCard>
  )
}
