import { AppointmentRequirements } from '@fallonsolutions/appointment'
import { PriorityType } from '../../api/generated-types'
import { Callout } from '../../common/callout'
import { decamelise } from '../../common/utils'

export type getRequirements<T> = { (result: T | undefined): AppointmentRequirements | undefined }

export interface RequirementsGenerator {
  getCallouts: any
}

export type GetRequirementsGeneratorFn = { (requirements: AppointmentRequirements | undefined): RequirementsGenerator }

export const getRequirementsGenerator = (requirements: AppointmentRequirements | undefined): RequirementsGenerator => {
  return {
    getCallouts: () => (
      <>
        {requirements?.attributes && requirements?.attributes.length > 0 ? (
          <Callout type="note" title={`Book a ${decamelise(requirements.attributes[0].attributeId)} technician`} />
        ) : (
          <></>
        )}
        {requirements?.priority?.required === PriorityType.P1 && (
          <Callout
            type="warning"
            title="this is a priority 1 job, pls book on the drains priority N/S/GC lane OR on the priority 1 allocated spots for drains technicians (if travel time is suitable)"
          />
        )}
        {requirements?.priority?.required === PriorityType.P2 && (
          <Callout
            type="warning"
            title="This is a Priority 2 job, please book on a drains tech without using the designated P1 allocations. Contact DSR if customer rejects next available"
          />
        )}
        {requirements?.priority?.required === PriorityType.P3 && (
          <Callout
            type="warning"
            title="This is a Priority 3 job, please book on a drains tech without using the designated P1 allocations. Contact DSR if customer rejects next available"
          />
        )}
      </>
    )
  }
}
