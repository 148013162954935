import { includes, reject } from 'lodash-es'
import { TradeType } from '../api/generated-types'
import { labelForTrade } from '../jobs/job-category-selector'
import { PillGroup } from '../pill/pill-group'
import AppliancesIcon from '../static/images/trade-appliances.svg'
import CarpentryIcon from '../static/images/trade-carpentry.svg'
import DataIcon from '../static/images/trade-data.svg'
import DrainsIcon from '../static/images/trade-drains.svg'
import ElectricalIcon from '../static/images/trade-electrical.svg'
import HVACIcon from '../static/images/trade-hvac.svg'
import PaintingIcon from '../static/images/trade-painting.svg'
import PlumbingIcon from '../static/images/trade-plumbing.svg'
import RoofingIcon from '../static/images/trade-roofing.svg'
import SecurityIcon from '../static/images/trade-security.svg'
import SolarIcon from '../static/images/trade-solar.svg'

interface TradeSelectorProps {
  title?: string | undefined
  trade: TradeType | undefined
  onSelect: (trade: TradeType) => void
  onClear: () => void
  direction?: any
}

export const TradeSelector = (props: TradeSelectorProps) => {
  const { title, trade, onSelect, onClear, direction } = props

  const excludedTrades = [TradeType.None, TradeType.SportsLighting, TradeType.HotWater]

  const options = reject(Object.keys(TradeType), (t) => includes(excludedTrades, t)).map((t: any) => {
    let icon: any
    switch (t) {
      case TradeType.Appliances:
        icon = AppliancesIcon
        break
      case TradeType.Carpentry:
        icon = CarpentryIcon
        break
      case TradeType.Data:
        icon = DataIcon
        break
      case TradeType.Drains:
        icon = DrainsIcon
        break
      case TradeType.Electrical:
        icon = ElectricalIcon
        break
      case TradeType.HVAC:
        icon = HVACIcon
        break
      case TradeType.Painting:
        icon = PaintingIcon
        break
      case TradeType.Plumbing:
        icon = PlumbingIcon
        break
      case TradeType.Roofing:
        icon = RoofingIcon
        break
      case TradeType.Security:
        icon = SecurityIcon
        break
      case TradeType.Solar:
        icon = SolarIcon
        break
      default:
        icon = HVACIcon
    }
    return {
      id: t,
      label: labelForTrade(t),
      icon,
      isSelected: t === trade
    }
  })

  return (
    <PillGroup
      title={title}
      direction={direction}
      options={options}
      value={trade as string}
      iconSize="xl"
      onSelect={(id) => onSelect(id as TradeType)}
      onClear={onClear}
    />
  )
}
