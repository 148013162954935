import { CustomerFragment, WebFormFragment } from '@fallonsolutions/types'
import { useCreateEnquiryService } from '../workflow/create-enquiry-service'
import { toSelectedCustomer } from '../workflow/workflow-interaction-message-model'
import { NewEnquiryResult } from '../workflow/workflow-new-enquiry-model'
import { formatPhone } from './phone'

export type CreateInitialEnquiryFn = (params: CreateInitialEnquiryParams) => Promise<string>

export type CustomerFields = Pick<CustomerFragment, 'id' | 'mainContact'>
export type WebFormFields = Pick<WebFormFragment, 'id' | 'contact'>
export interface CreateInitialEnquiryParams {
  customer?: CustomerFields
  webForm?: WebFormFields
}

export const useCreateInitialEnquiryService = (): [CreateInitialEnquiryFn] => {
  const [createNewEnquiry] = useCreateEnquiryService<NewEnquiryResult>()

  const createEnquiry = async ({ customer, webForm }: CreateInitialEnquiryParams): Promise<string> => {
    const webFormId = webForm?.id
    const webContact = webForm?.contact

    const customerSuburb =
      customer?.mainContact?.detail?.address?.suburbId && customer?.mainContact?.detail?.address?.suburb
        ? {
            id: customer.mainContact.detail.address.suburbId,
            label: customer.mainContact.detail.address.suburb
          }
        : undefined

    const initialResult: NewEnquiryResult = {
      ...(webContact && {
        contact: {
          id: webContact.id,
          firstName: webContact.detail?.firstName ?? '',
          lastName: webContact.detail?.lastName ?? '',
          phone: webContact.detail?.phone ? formatPhone(webContact.detail?.phone) : '',
          actionCompleted: true
        }
      }),
      ...(customer && {
        contact: {
          id: customer.mainContact?.id ?? undefined,
          customerId: customer.id,
          firstName: customer.mainContact?.detail?.firstName ?? '',
          lastName: customer.mainContact?.detail?.lastName ?? '',
          phone: customer.mainContact?.detail?.phone ? formatPhone(customer.mainContact.detail?.phone) : '',
          ...(customerSuburb && { suburb: customerSuburb }),
          actionCompleted: true
        },
        customer: { customer: toSelectedCustomer(customer), actionCompleted: true, value: customer?.id }
      })
    }

    const enquiryId = await createNewEnquiry({ initialResult, webFormId })
    return enquiryId
  }

  return [createEnquiry]
}
