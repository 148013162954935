import { EuiText } from '@elastic/eui'
import { useEffect, useState } from 'react'
import { useAuthenticated } from '../../auth/authenticated-context'
import { Callout } from '../../common/callout'
import { Task } from '../../common/task'

export interface TransferScriptProps {
  contactName?: string
  transferTarget?: string
  transferReason?: string
  onTransferComplete?: (complete: boolean) => void
}

export const TransferScript = (props: TransferScriptProps) => {
  const { contactName, transferTarget, transferReason, onTransferComplete } = props

  const session = useAuthenticated().user
  const userFirstName = session.firstName

  const [onHold, setOnHold] = useState(false)
  const [transferred, setTransferred] = useState(false)

  useEffect(() => {
    onTransferComplete && onTransferComplete(onHold && transferred)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onHold, transferred])

  return (
    <>
      <Callout type="script" className="callout--flat-list">
        <EuiText>
          <ul>
            <li>
              I'm just going to transfer you to {transferTarget ? <strong>{transferTarget}</strong> : 'someone'} who can
              better help you from here
            </li>
            <li>
              I'm going to put you on a brief hold, bring {transferTarget ?? 'them'} up to speed and then I'll transfer
              you across
            </li>
            <li>Won't be too long {contactName ?? ''}</li>
          </ul>
        </EuiText>
      </Callout>
      <Task
        label="Place contact on hold, pass on all details, select correct transfer destination/extension number"
        checked={onHold}
        onChange={setOnHold}
        indent={true}
      />
      <Callout type="script" title="Transfer script" className="callout--flat-list">
        <EuiText>
          <ul>
            <li>Hi (employee name), it's {userFirstName ?? '(my name)'}</li>
            <li>
              I just have <strong>{contactName ?? 'a contact'}</strong> here that{' '}
              {transferReason ?? '(reason for transfer/explain issue)'}
            </li>
          </ul>
          <ul>
            <li>Their details are (give customer details) - are you able to assist?</li>
          </ul>
        </EuiText>
      </Callout>
      <Task
        label="Bring customer through and join together with no handover"
        checked={transferred}
        onChange={setTransferred}
        indent={true}
      />
    </>
  )
}
