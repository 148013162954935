import { BaseActionInput, BaseActionResult, WorkflowActionProps } from '../workflow-model'
import { EuiButton, EuiSpacer, EuiText } from '@elastic/eui'
import { Callout } from '../../common/callout'

export type TelemarketingActionInput = BaseActionInput
export type TelemarketingActionResult = BaseActionResult

const email = 'enquiries@fallonsolutions.com.au'

export const TelemarketingAction = (
  props: WorkflowActionProps<TelemarketingActionInput, TelemarketingActionResult>
) => {
  const { onUpdate } = props

  return (
    <>
      <Callout type="script">
        <EuiText>
          <div>Thank you for your call, unfortunately I am not in a position to help you today.</div>
          <EuiSpacer size="s" />
          <div>However, I will give you an email address that you can send through your enquiry to okay?</div>
        </EuiText>
      </Callout>
      <Callout type="note" title={`Refer telemarketer to email ${email}`} />
      <EuiSpacer />

      <EuiButton fill color="primary" onClick={() => onUpdate({})}>
        I've completed these steps
      </EuiButton>
    </>
  )
}
