import { EuiHorizontalRule, EuiPanel, EuiSpacer } from '@elastic/eui'
import { Fragment, useState } from 'react'
import { JobFragment } from '../api/generated-types'
import { JobPropertyChange } from './job-property-change'
import { JobSummaryBody } from './job-summary-body'
import { JobSummaryCards } from './job-summary-cards'
import { LinkedJobsView } from './job-summary-child-view'
import { JobSummaryFooter } from './job-summary-footer'
import { JobSummaryHeader } from './job-summary-header'
import { JobSummaryTimeline } from './job-summary-timeline'

interface JobSummaryProps {
  job: JobFragment
}

enum JobSummaryModal {
  None,
  JobRequirements,
  JobProperty,
  JobBranch
}

export const JobSummary = (props: JobSummaryProps) => {
  const { job } = props

  const [jobSummaryModal, setJobSummaryModal] = useState(JobSummaryModal.None)

  const childJobIds = (job.childJobs ?? []).map((cj) => cj.id)
  const parentJobId = job.parentJob?.id
  const linkedIds = [...(childJobIds ?? []), ...(parentJobId ? [parentJobId] : [])]
  return (
    <Fragment>
      <EuiSpacer size="s" />
      <EuiPanel>
        <JobSummaryHeader job={job} />
        <EuiSpacer size="m" />
        <JobSummaryTimeline job={job} />
        <EuiSpacer size="l" />
        <JobSummaryBody job={job} />
        <EuiHorizontalRule />
        <JobSummaryFooter job={job} setJobSummaryModal={setJobSummaryModal} />
      </EuiPanel>

      <EuiSpacer />
      <JobSummaryCards job={job} setJobSummaryModal={setJobSummaryModal} />
      {linkedIds.length > 0 && (
        <>
          <EuiSpacer size="l" />
          <LinkedJobsView currentJob={job} linkedIds={linkedIds} />
        </>
      )}

      {jobSummaryModal === JobSummaryModal.JobProperty && (
        <JobPropertyChange job={job} closeModal={() => setJobSummaryModal(JobSummaryModal.None)} />
      )}
    </Fragment>
  )
}
