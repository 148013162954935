import { ACSystemAge, ACType, ACWarrantyStatus, TopicHVACRepairActionResult } from './action-topic-hvac-repair-model'
import {
  TopicQualification,
  TopicQualificationResult,
  TopicRequirementsResult,
  createOutcomeGenerator,
  createRequirements
} from '../common/common-outcome'
import { CustomerType, JobType, TradeType } from '../../../api/generated-types'
import { YesNoValue } from '../common/common-enums'
import { includes } from 'lodash-es'
import { JobTopicType } from '@fallonsolutions/types'

const SPLIT_SYSTEM_SKILL = 'SplitSystemAC'
const DUCTED_SKILL = 'DuctedAC'
const CARPENTRY_SKILL = 'GeneralCarpentry'

const getRequirements = (result: TopicHVACRepairActionResult | undefined): TopicRequirementsResult => {
  if (!result) return {}
  const {
    acType,
    faultDescription,
    indoorUnitLocation,
    outdoorUnitLocation,
    ladderRequired,
    lastServiceDate,
    furnitureNeedToBeMoved,
    replacement,
    replacementType
  } = result

  if (
    includes([ACType.Portable, ACType.WindowBox], acType) &&
    replacement === YesNoValue.Yes &&
    replacementType === ACType.WindowBox
  ) {
    return { requirements: createRequirements([CARPENTRY_SKILL]), trade: TradeType.Carpentry, category: 'General' }
  }

  const hasLength = (str: string | undefined) => (str ?? '').trim().length > 0
  if (
    hasLength(faultDescription) &&
    hasLength(indoorUnitLocation) &&
    hasLength(outdoorUnitLocation) &&
    hasLength(ladderRequired) &&
    hasLength(lastServiceDate) &&
    hasLength(furnitureNeedToBeMoved)
  ) {
    if (acType === ACType.SplitSystemOrMultiHead)
      return { requirements: createRequirements([SPLIT_SYSTEM_SKILL]), category: 'SplitSystem' }
    if (acType === ACType.Ducted) return { requirements: createRequirements([DUCTED_SKILL]), category: 'Ducted' }
  }
  return {}
}

const getQualification = (result: TopicHVACRepairActionResult | undefined): TopicQualificationResult => {
  if (!result || !result.acType) return { qualification: TopicQualification.Unknown }
  const {
    acType,
    replacement,
    underWarranty,
    systemAge,
    willingToVoidWarranty,
    replacementType,
    customerType,
    roofAccess,
    fallonInstalled
  } = result
  if (!acType) return { qualification: TopicQualification.Unknown }
  const roofCheck = roofAccessCheck(result)
  if (roofCheck) {
    return roofCheck
  }
  switch (acType) {
    case ACType.Car:
      return {
        qualification: TopicQualification.OutOfScope,
        qualificationMessage: `Unfortunately we do not repair car ACs. I would recommend contacting a car mechanic to fix it.`
      }
    case ACType.SplitSystemOrMultiHead:
    case ACType.Ducted: {
      const isUnderWarranty =
        underWarranty === ACWarrantyStatus.UnderWarranty || underWarranty === ACWarrantyStatus.Unsure

      const isOutsideOfWarranty =
        underWarranty === ACWarrantyStatus.OutsideOfWarranty ||
        (underWarranty === ACWarrantyStatus.Unsure && systemAge === ACSystemAge.OverFiveYears) ||
        ((underWarranty === ACWarrantyStatus.Unsure || underWarranty === ACWarrantyStatus.UnderWarranty) &&
          fallonInstalled === YesNoValue.Yes)

      const isUnderWarrantyAndWillingToVoid =
        (underWarranty === ACWarrantyStatus.UnderWarranty || underWarranty === ACWarrantyStatus.Unsure) &&
        willingToVoidWarranty === YesNoValue.Yes

      if (isUnderWarranty && willingToVoidWarranty === YesNoValue.No) {
        return { qualification: TopicQualification.OutOfScope }
      }
      if (isOutsideOfWarranty || isUnderWarrantyAndWillingToVoid) return { qualification: TopicQualification.InScope }
      return { qualification: TopicQualification.Unknown }
    }
    case ACType.WindowBox:
    case ACType.Portable:
      if (replacement === YesNoValue.Yes) {
        switch (replacementType) {
          case ACType.WindowBox:
            return { qualification: TopicQualification.InScope }
          case ACType.SplitSystemOrMultiHead:
          case ACType.Ducted:
            return {
              qualification: TopicQualification.NewTopic,
              newTopic: {
                reference: JobTopicType.HVACQuoteInstall,
                buttonLabel: 'Switch to HVAC Quote/Install'
              }
            }
          default:
            return { qualification: TopicQualification.Unknown }
        }
      }
      if (replacement === YesNoValue.No) return { qualification: TopicQualification.OutOfScope }
      return { qualification: TopicQualification.Unknown }
    default:
      return { qualification: TopicQualification.Unknown }
  }
}
export const roofAccessCheck = (result: TopicHVACRepairActionResult | undefined) => {
  const { customerType, roofAccess } = result ?? {}
  if (customerType === CustomerType.Commercial) {
    if (!roofAccess) {
      return { qualification: TopicQualification.Unknown }
    }
    if (roofAccess === YesNoValue.No) {
      return { qualification: TopicQualification.OutOfScope }
    }
  }
}

export const outcomeGenerator = createOutcomeGenerator<TopicHVACRepairActionResult>(
  {
    qualification: TopicQualification.Unknown,
    trade: TradeType.HVAC,
    type: JobType.Service,
    category: 'General'
  },
  getQualification,
  getRequirements
)
