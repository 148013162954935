export const SolarBatteryFaultRepairStatement = () => {
  return (
    <ul>
      <li>
        I will send out an electrician to your home who will conduct a complete complimentary 26 point solar performance
        and safety check on the panels and the inverter.
      </li>
      <li>The technician will than discuss all findings with you.</li>
      <li>
        Any recommendations or extra work required will be discussed with you prior and only undertaken with your
        permission.
      </li>
    </ul>
  )
}
