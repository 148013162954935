import { CSSProperties, ReactElement } from 'react'
import { EuiFlexGroup, EuiFlexItem } from '@elastic/eui'

export enum ColorScaleOrder {
  Ascending,
  Descending
}

export interface ColorScaleProps {
  order: ColorScaleOrder
  min: number
  max: number
  rgb: string
}

export interface BackgroundColorScaleProps {
  value?: number
  scale?: ColorScaleProps
  style?: CSSProperties
  children: React.ReactNode
}

const MAX_COLOR_ALPHA = 0.25

export const BackgroundColorScale = ({ style, value, scale, children }: BackgroundColorScaleProps): ReactElement => {
  if (!value || !scale) {
    return <div style={{ ...style }}>{children}</div>
  }
  let normalised = 0
  switch (scale.order) {
    case ColorScaleOrder.Ascending:
      normalised = Math.min((value - scale.min) / scale.max, 1)
      break
    case ColorScaleOrder.Descending:
    default:
      normalised = Math.min((value - scale.min) / scale.max, 1)
  }
  const alpha = normalised * MAX_COLOR_ALPHA
  return (
    <EuiFlexGroup
      gutterSize="none"
      style={{ ...style, background: `rgba(${scale.rgb}, ${alpha})`, textAlign: 'right' }}
    >
      <EuiFlexItem>{children}</EuiFlexItem>
    </EuiFlexGroup>
  )
}
