import { CustomerLocationFragment } from '@fallonsolutions/types'
import { NewJobActionResult } from './actions/action-new-job'
import { getSelectCustomerProperty } from '../common/get-customer-property'
import { getCustomerPropertyMembership } from '../common/get-current-customer-location-membership'

export const createNewBookingState = (
  customerId: string | undefined,
  existingJobId: string | undefined,
  customerLocation?: CustomerLocationFragment
): NewJobActionResult => {
  return {
    existingJobId,
    fastTrack: {
      fastBooking: true,
      newCustomer: !!customerId
    },
    ...(customerLocation && {
      customerProperty: {
        property: getSelectCustomerProperty(customerLocation),
        membership: getCustomerPropertyMembership(customerLocation)
      }
    })
  }
}
