import React from 'react'
import { EuiIcon, EuiSpacer } from '@elastic/eui'

interface OperationSuccessProps {
  message?: string
  link?: string
  linkLabel?: string
}

export const OperationSuccess = (props: OperationSuccessProps) => {
  const message = props.message ?? 'Success'
  const { link, linkLabel } = props

  const showLink = link && linkLabel

  return (
    <div style={{ textAlign: 'center' }}>
      <EuiIcon size="xxl" type="checkInCircleFilled" color="#42CB64" />
      <EuiSpacer size="s" />
      <p>{message}</p>
      <EuiSpacer size="s" />
      {showLink && <a href={link}>{linkLabel}</a>}
    </div>
  )
}
