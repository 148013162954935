import { BaseActionInput, BaseActionResult, WorkflowActionProps } from '../workflow-model'
import { WorkflowAction } from '../workflow-action'
import { EuiButton, EuiSpacer } from '@elastic/eui'
import { CustomerInvoicesSelect } from '../../invoices/customer-invoices-select'
import { InvoiceFragment } from '../../api/generated-types'
import { DateTime } from 'luxon'
import { dateConfig } from '@fallonsolutions/date'

export interface SelectInvoiceActionInput extends BaseActionInput {
  customerId: string
}

export interface SelectInvoiceActionResult extends BaseActionResult {
  invoice?: {
    id: string
    reference: string
    label: string
    date: string
    jobId?: string
  }
}

export const SelectInvoiceAction = (
  props: WorkflowActionProps<SelectInvoiceActionInput, SelectInvoiceActionResult>
) => {
  const { input, result, onUpdate } = props

  const { customerId } = input ?? {}
  const { actionCompleted, invoice } = result ?? {}

  const value = actionCompleted && invoice ? invoice.label : undefined
  const setInvoice = (invoice: InvoiceFragment | undefined) => {
    if (invoice) {
      const date = DateTime.fromISO(invoice.postDate, {
        zone: dateConfig.defaultTimezone
      })
      const dateString = date.toFormat(dateConfig.luxonFormat.shortDate)
      onUpdate({
        ...result,
        invoice: {
          id: invoice.id,
          reference: invoice.number,
          label: `Invoice ${invoice.number} for job ${invoice.job?.number} (${dateString})`,
          date: dateString,
          jobId: invoice.job?.id ?? ''
        }
      })
    } else {
      onUpdate({ ...result, invoice: undefined })
    }
  }
  const clearResult = () => onUpdate({})

  return (
    <WorkflowAction title="Select invoice" value={value} onClickChange={clearResult}>
      <CustomerInvoicesSelect customerId={customerId} selectedInvoiceId={invoice?.id} onSelect={setInvoice} />
      <EuiSpacer />
      <EuiButton onClick={() => onUpdate({ ...result, actionCompleted: true })} disabled={!invoice?.id}>
        Select
      </EuiButton>
    </WorkflowAction>
  )
}
