import { first } from 'lodash-es'
import { ContactSearchModel, emptySearchFields } from './contact-search-model'

export type ContactSearchDispatchActionType =
  | 'change_phone'
  | 'change_firstName'
  | 'change_lastName'
  | 'change_street'
  | 'change_suburb'
  | 'change_suburbs'
  | 'change_fuzzy'
  | 'change_company'
  | 'change_reference'
  | 'change_activeOnly'
  | 'select_contact_customer'
  | 'select_create_new_contact'
  | 'set_search_fields'
  | 'reset'

export interface ContactSearchActionPayload {
  searchFields?: Partial<ContactSearchModel['searchFields']>
  selectedContact?: ContactSearchModel['selectedContact']
  selectedCustomer?: ContactSearchModel['selectedCustomer']
  createNew?: ContactSearchModel['createNew']
}
export interface ContactSearchAction {
  type: ContactSearchDispatchActionType
  payload?: ContactSearchActionPayload
}

export type ContactSearchDispatchFn = (action: ContactSearchAction) => ContactSearchModel

// eslint-disable-next-line complexity
export function contactSearchReducer(state: ContactSearchModel, action: ContactSearchAction): ContactSearchModel {
  // console.log('contactSearchReducer', 'action', action, 'state', state)
  switch (action.type) {
    case 'change_phone':
      return {
        ...state,
        searchFields: {
          ...state.searchFields,
          phone: action.payload?.searchFields?.phone ?? ''
        }
      }
    case 'change_firstName':
      return {
        ...state,
        searchFields: {
          ...state.searchFields,
          firstName: action.payload?.searchFields?.firstName ?? ''
        }
      }
    case 'change_lastName':
      return {
        ...state,
        searchFields: {
          ...state.searchFields,
          lastName: action.payload?.searchFields?.lastName ?? ''
        }
      }
    case 'change_street':
      return {
        ...state,
        searchFields: {
          ...state.searchFields,
          street: action.payload?.searchFields?.street ?? ''
        }
      }
    case 'change_suburb':
      return {
        ...state,
        searchFields: {
          ...state.searchFields,
          suburb: action.payload?.searchFields?.suburb ?? ''
        }
      }
    case 'change_suburbs':
      return {
        ...state,
        searchFields: {
          ...state.searchFields,
          suburbs: action.payload?.searchFields?.suburbs ?? [],
          suburb: first(action.payload?.searchFields?.suburbs)?.label ?? '',
          suburbId: first(action.payload?.searchFields?.suburbs)?.id ?? ''
        }
      }
    case 'change_fuzzy':
      return {
        ...state,
        searchFields: {
          ...state.searchFields,
          fuzzy: action.payload?.searchFields?.fuzzy ?? true
        }
      }
    case 'change_company':
      return {
        ...state,
        searchFields: {
          ...state.searchFields,
          company: action.payload?.searchFields?.company ?? ''
        }
      }
    case 'change_reference':
      return {
        ...state,
        searchFields: {
          ...state.searchFields,
          reference: action.payload?.searchFields?.reference ?? ''
        }
      }
    case 'change_activeOnly':
      return {
        ...state,
        searchFields: {
          ...state.searchFields,
          activeOnly: action.payload?.searchFields?.activeOnly
        }
      }
    case 'select_contact_customer':
      return {
        ...state,
        selectedContact: action.payload?.selectedContact ?? undefined,
        selectedCustomer: action.payload?.selectedCustomer ?? undefined,
        createNew: action.payload?.createNew ?? false
      }
    case 'select_create_new_contact':
      return {
        ...state,
        createNew: action.payload?.createNew ?? true,
        selectedContact: undefined,
        selectedCustomer: undefined
      }
    case 'reset':
      return {
        searchFields: emptySearchFields,
        selectedContact: undefined,
        selectedCustomer: undefined,
        createNew: false
      }
    case 'set_search_fields':
      return {
        ...state,
        searchFields: { ...state.searchFields, ...action.payload?.searchFields }
      }
    default:
      return state
  }
}
