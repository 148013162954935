import { useMutation } from '@apollo/client'
import { EuiCallOut, EuiFlexGroup, EuiFlexItem, EuiHorizontalRule, EuiLink, EuiSpacer } from '@elastic/eui'
import { scroller } from 'react-scroll'
import {
  CustomerType,
  JobFragment,
  JobSource,
  UpdateJobPurchaseOrderNumberDocument,
  UpdateJobPurchaseOrderNumberInput
} from '../api/generated-types'
import CreateCommentForm from '../comments/create-comment-form'
import { EditableSingleLineLabel } from '../common/editable-single-line-label'
import { ScrollTargetName } from '../common/scroll-target-name'
import { JobActivityList } from './job-activity-list'
import { JobNotes } from './job-notes'
import { JobStatusHistory } from './job-status-detail'
import { JobWorkRequired } from './job-work-required'

interface JobSummaryBodyProps {
  job: JobFragment
}

export const JobSummaryBody = (props: JobSummaryBodyProps) => {
  const { job } = props

  const [updateJobPurchaseOrderNumber, { loading: savingPurchaseOrderNumber }] = useMutation(
    UpdateJobPurchaseOrderNumberDocument
  )

  const handleUpdateJobPurchaseOrderNumber = (purchaseOrderNumber: string | undefined | null) => {
    const input: UpdateJobPurchaseOrderNumberInput = {
      job: job.id,
      purchaseOrderNumber
    }
    updateJobPurchaseOrderNumber({
      variables: { input }
    })
  }

  const scrollToJobActivityList = () => {
    scroller.scrollTo(ScrollTargetName.JobActivityList, {
      duration: 300,
      smooth: 'easeInOutQuad',
      offset: -50
    })
  }

  return (
    <>
      {job.customerType === CustomerType.Commercial && (
        <>
          <EuiFlexGroup>
            <EuiFlexItem>
              <div>
                <div className="small-label">Purchase Order Number</div>
                <EditableSingleLineLabel
                  value={job.legacy?.purchaseOrderNumber}
                  saving={savingPurchaseOrderNumber}
                  onValueChange={handleUpdateJobPurchaseOrderNumber}
                />
              </div>
            </EuiFlexItem>
          </EuiFlexGroup>
          <EuiSpacer />
        </>
      )}

      {job.source !== JobSource.Platform && (
        <>
          <EuiCallOut iconType="alert" color="warning" title="Axia job - please edit job details in Axia" size="s" />
          <EuiSpacer />
        </>
      )}

      <EuiFlexGroup alignItems="flexStart">
        <EuiFlexItem grow={true} style={{ width: '60%' }}>
          <JobWorkRequired
            jobId={job.id}
            workRequired={job.workRequired ?? ''}
            isEditable={job.source === JobSource.Platform}
          />
          <EuiHorizontalRule />
          <JobNotes jobId={job.id} notes={job.notes ?? ['']} isEditable={job.source === JobSource.Platform} />
          {job.status?.comment && (
            <>
              <EuiHorizontalRule />
              <div className="medium-label">STATUS HISTORY</div>
              <JobStatusHistory jobId={job.id} />
            </>
          )}
        </EuiFlexItem>
        <EuiFlexItem grow={false} style={{ width: '40%' }}>
          <div className="medium-label">COMMENTS</div>
          <EuiSpacer size="m" />
          <CreateCommentForm jobId={job.id} />
          <JobActivityList jobId={job.id} includeActivities={false} maxComments={4} />
          <EuiSpacer />
          <EuiLink style={{ marginLeft: '40px' }} onClick={scrollToJobActivityList}>
            View all activity and comments
          </EuiLink>
        </EuiFlexItem>
      </EuiFlexGroup>

      {job.source !== JobSource.Platform && (
        <EuiFlexGroup>
          <EuiFlexItem grow={false}>
            <div className="small-label">Legacy status</div>
            <span>{job.legacy?.status}</span>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <div className="small-label">Legacy job type</div>
            <span>{job.legacy?.type}</span>
          </EuiFlexItem>
        </EuiFlexGroup>
      )}
    </>
  )
}
