import { EuiButton, EuiSpacer } from '@elastic/eui'
import { JobType, TradeType } from '../../../api/generated-types'
import { makeHumanReadable } from '../../../common/utils'
import { QuestionDefinitions, getNotesGenerator } from '../../helpers/notes-generator'
import { getResultUpdater } from '../../helpers/results-updater'
import { FreeTextQuestionField } from '../../question-fields/free-text-question-field'
import { SingleSelectField } from '../../question-fields/single-select-question-field'
import { WorkflowActionProps } from '../../workflow-model'
import { TopicActionInput, TopicActionResult } from '../action-topic-model'
import { TopicWorkflowAction } from '../action-topic-view'
import { YesNoValue } from '../common/common-enums'

export interface TopicPlumbingToiletRepairActionResult extends TopicActionResult {
  toiletAge?: string
  isToiletLeaking?: YesNoValue
  isItLeakingIntoFloor?: YesNoValue
  howLongIsThatHappeningFor?: string
}

const questionDefinitions: QuestionDefinitions<TopicPlumbingToiletRepairActionResult> = {
  toiletAge: {
    question: 'How old is the toilet?'
  },
  isToiletLeaking: {
    question: 'Is the toilet leaking?',
    makeAnswerHumanReadable: makeHumanReadable
  },
  isItLeakingIntoFloor: {
    question: 'Does that leak leak onto the floor?',
    makeAnswerHumanReadable: makeHumanReadable
  },
  howLongIsThatHappeningFor: {
    question: 'How long has this been happening for?'
  }
}
const notesGenerator = getNotesGenerator(questionDefinitions)

export const TopicPlumbingToiletRepairAction = (
  props: WorkflowActionProps<TopicActionInput, TopicPlumbingToiletRepairActionResult>
) => {
  const { result, input, onUpdate } = props
  const { toiletAge, isToiletLeaking, isItLeakingIntoFloor, howLongIsThatHappeningFor } = result ?? {}

  const onNext = () =>
    onUpdate({
      ...result,
      actionCompleted: true,
      trade: TradeType.Plumbing,
      category: 'Toilet',
      type: JobType.Service,

      workRequiredNotes: generatedNotes,
      requirements: {
        attributes: [{ attributeId: 'GeneralPlumbing' }]
      }
    })

  const generatedNotes = notesGenerator.generateNotes(result ?? {})
  const updateResult = getResultUpdater(result ?? {}, onUpdate).updateResult

  const canComplete =
    !!toiletAge &&
    (isToiletLeaking === YesNoValue.No || (isToiletLeaking === YesNoValue.Yes && !!isItLeakingIntoFloor)) &&
    !!howLongIsThatHappeningFor

  return (
    <TopicWorkflowAction input={input} onUpdate={onUpdate} result={result}>
      <FreeTextQuestionField
        question={notesGenerator.getQuestion('toiletAge')}
        answer={toiletAge}
        changeAnswer={(toiletAge) => updateResult({ toiletAge })}
      />
      <SingleSelectField
        question={notesGenerator.getQuestion('isToiletLeaking')}
        options={[
          {
            id: YesNoValue.Yes,
            label: 'Yes'
          },
          {
            id: YesNoValue.No,
            label: 'No'
          }
        ]}
        answer={isToiletLeaking}
        changeAnswer={(isToiletLeaking) => updateResult({ isToiletLeaking })}
      />
      {isToiletLeaking === YesNoValue.Yes && (
        <>
          <SingleSelectField
            question={notesGenerator.getQuestion('isItLeakingIntoFloor')}
            options={[
              {
                id: YesNoValue.Yes,
                label: 'Yes'
              },
              {
                id: YesNoValue.No,
                label: 'No'
              }
            ]}
            answer={isItLeakingIntoFloor}
            changeAnswer={(isItLeakingIntoFloor) => updateResult({ isItLeakingIntoFloor })}
          />
        </>
      )}
      <FreeTextQuestionField
        question={notesGenerator.getQuestion('howLongIsThatHappeningFor')}
        answer={howLongIsThatHappeningFor}
        changeAnswer={(howLongIsThatHappeningFor) => updateResult({ howLongIsThatHappeningFor })}
      />

      <EuiSpacer />
      <EuiButton disabled={!canComplete} onClick={() => onNext()}>
        Next
      </EuiButton>
    </TopicWorkflowAction>
  )
}
