import { EuiDatePicker, EuiDatePickerRange } from '@elastic/eui'
import { random } from 'lodash-es'
import { Moment } from 'moment-timezone'
import { DateQuickNav } from './date-quick-nav'

interface DatePickerProps {
  startDate: Moment | null | undefined
  endDate: Moment | null | undefined
  setStartDate: (date: Moment) => void
  setEndDate: (date: Moment) => void
  quickNav?: boolean
  isStartClearable?: boolean // default true
  isEndClearable?: boolean // default true
  isDisabled?: boolean
}

const DatePicker = (props: DatePickerProps) => {
  const quickNav = props.quickNav ?? false
  const { isStartClearable = true, isEndClearable = true } = props

  const validDateRange = (): boolean => {
    if (!props.startDate || !props.endDate) {
      return false
    } else {
      return props.startDate.isAfter(props.endDate) || false
    }
  }

  const randomDateRangePlaceholders = () => {
    const placeholders = [
      ['The beginning', 'The end of all time']
      // ['The big bang', 'The big chill']
    ]
    const randomIndex = random(0, placeholders.length - 1)
    return placeholders[randomIndex]
  }
  const dateRangePlaceholders = randomDateRangePlaceholders()

  const onSetDateRange = (from: any, to: any) => {
    props.setStartDate(from)
    props.setEndDate(to)
  }

  //TODO set setStartDate: (date: Moment | null) => void and update all usages
  // @ts-ignore
  const startClearable = isStartClearable ? () => props.setStartDate(null) : undefined
  //TODO set setEndDate: (date: Moment | null) => void and update all usages
  // @ts-ignore
  const endClearable = isEndClearable ? () => props.setEndDate(null) : undefined

  return (
    <div>
      <EuiDatePickerRange
        disabled={props.isDisabled}
        startDateControl={
          <EuiDatePicker
            selected={props.startDate}
            onChange={props.setStartDate}
            placeholder={dateRangePlaceholders[0]}
            dateFormat="ddd D MMM YYYY"
            onClear={startClearable}
            isInvalid={validDateRange()}
            aria-label="Start date"
          />
        }
        endDateControl={
          <EuiDatePicker
            selected={props.endDate}
            onChange={props.setEndDate}
            onClear={endClearable}
            placeholder={dateRangePlaceholders[1]}
            dateFormat="ddd D MMM YYYY"
            isInvalid={validDateRange()}
            aria-label="End date"
          />
        }
      />
      {quickNav && <DateQuickNav startDate={props.startDate} endDate={props.endDate} onSetDateRange={onSetDateRange} />}
    </div>
  )
}

export default DatePicker
