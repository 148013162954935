import { EuiSpacer, EuiText } from '@elastic/eui'
import { Callout } from '../../../common/callout'
import { NotesGenerator } from '../../helpers/notes-generator'
import { ResultUpdater } from '../../helpers/results-updater'
import { FreeTextQuestionField } from '../../question-fields/free-text-question-field'
import { SingleSelectField } from '../../question-fields/single-select-question-field'
import { YesNoValue } from '../common/common-enums'
import { TopicInspectionActionResult } from './action-topic-inspection-model'

export interface InspectionElectricalProps {
  result?: TopicInspectionActionResult
  notesGenerator: NotesGenerator<TopicInspectionActionResult>
  updateResult: ResultUpdater<TopicInspectionActionResult>
}

export const InspectionElectrical = (props: InspectionElectricalProps) => {
  const { result, notesGenerator, updateResult } = props
  const { isSpecificInspection, additionalNotes } = result ?? {}
  return (
    <>
      <SingleSelectField
        question={notesGenerator.getQuestion('isSpecificInspection')}
        options={[
          {
            id: YesNoValue.Yes,
            label: 'Yes, specific issue to inspect',
            icon: 'check'
          },
          {
            id: YesNoValue.No,
            icon: 'cross'
          }
        ]}
        answer={isSpecificInspection}
        changeAnswer={(isSpecificInspection) => updateResult({ isSpecificInspection })}
      />
      {isSpecificInspection === YesNoValue.Yes && (
        <FreeTextQuestionField
          question={notesGenerator.getQuestion('additionalNotes')}
          answer={additionalNotes}
          changeAnswer={(additionalNotes) => updateResult({ additionalNotes })}
          rows={3}
        />
      )}
      {isSpecificInspection === YesNoValue.No && (
        <Callout type="script">
          <EuiText>
            <div>Not a problem at all.</div>
            <EuiSpacer size="m" />
            <div>I will let the technician know to conduct and thorough electrical inspection of the property.</div>
          </EuiText>
        </Callout>
      )}
    </>
  )
}
