import moment from 'moment-timezone'
import { dateConfig } from './date-config'

export const createDateFilter = (startDate?: string | null, endDate?: string | null) => {
  const range: any = {}
  if (startDate) {
    range.from = moment(startDate, dateConfig.basic).tz('Australia/Brisbane').startOf('day').toISOString(true)
  }
  if (endDate) {
    range.to = moment(endDate, dateConfig.basic).tz('Australia/Brisbane').endOf('day').toISOString(true)
  }
  return range
}
