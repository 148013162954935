import { EuiSpacer } from '@elastic/eui'
import { JobTopic, JobTopicType, JobType, ServiceArea, TradeType } from '@fallonsolutions/types'
import { HotWaterSystemType, TopicHotWaterActionResult } from '../../topics/hot-water/action-topic-hot-water-model'
import { ContactRole, CustomerQualificationActionResult } from '../action-customer-qualification'
import { JobClassificationActionResult } from '../action-job-classification-types'
import { ApplianceGoldCoastDisclaimer } from './disclaimers/appliance-goldcoast-disclaimer'
import { CommercialCustomerDisclaimer } from './disclaimers/commercial-customer-disclaimer'
import { InspectionDisclaimer } from './disclaimers/inspection-disclaimer'
import { ApplianceOvenCooktopDisclaimer } from './disclaimers/oven-cooktop-appliance-disclaimer'
import { PriorityDisclaimer } from './disclaimers/priority-disclaimer'
import { SolarHotWaterDisclaimer } from './disclaimers/solar-hot-water-disclaimer'

export interface ScheduleReservationDisclaimersProps {
  customerContact: CustomerQualificationActionResult | undefined
  jobClassification: JobClassificationActionResult | undefined
  jobTopic?: JobTopic | undefined
  serviceArea: ServiceArea | null | undefined
}

const GOLD_COAST_BRANCH_ID = 'gold-coast'

export const ScheduleReservationDisclaimers = (props: ScheduleReservationDisclaimersProps) => {
  const { customerContact, jobClassification, serviceArea, jobTopic } = props

  const isCustomerCommercial = customerContact?.contactRole
    ? customerContact?.contactRole !== ContactRole.HomeOwner
    : false

  const { topic } = jobClassification ?? {}
  const { existingHwsType } = (topic as unknown as TopicHotWaterActionResult) ?? {}

  const isSolarHotWater = !!existingHwsType && existingHwsType === HotWaterSystemType.Solar
  const { requirements } = topic ?? {}

  const isInspection = jobClassification?.type === JobType.Inspection ? true : false

  const isAppliance = jobClassification?.topic?.trade === TradeType.Appliances

  const isGoldCoastJob = serviceArea?.branchId === GOLD_COAST_BRANCH_ID

  const priority = requirements?.priority?.required

  const isOvenOrCooktopJob = jobTopic?.reference === JobTopicType.Oven || jobTopic?.reference === JobTopicType.Cooktop

  const messageIsDisplayed = isCustomerCommercial || isInspection || isAppliance || !!priority || isSolarHotWater

  return (
    <>
      <SolarHotWaterDisclaimer isSolarHotWater={isSolarHotWater} />
      <CommercialCustomerDisclaimer isCustomerCommercial={isCustomerCommercial} />
      <InspectionDisclaimer isInspection={isInspection} />
      <ApplianceGoldCoastDisclaimer isAppliance={isAppliance} isGoldCoastJob={isGoldCoastJob} />
      <ApplianceOvenCooktopDisclaimer isApplianceJob={isAppliance} isOvenOrCooktop={isOvenOrCooktopJob} />
      <PriorityDisclaimer priority={priority} />
      {messageIsDisplayed && <EuiSpacer size="s" />}
    </>
  )
}
