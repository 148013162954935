import { EuiButton, EuiSpacer } from '@elastic/eui'
import { EnquiryAbortReason } from '../../../api/generated-types'
import { Callout } from '../../../common/callout'
import { getResultUpdater } from '../../helpers/results-updater'
import { useWorkflow } from '../../helpers/workflow-provider'
import { SingleSelectField } from '../../question-fields/single-select-question-field'
import { WorkflowActionProps } from '../../workflow-model'
import { ServiceType, TopicActionInput } from '../action-topic-model'
import { TopicWorkflowAction } from '../action-topic-view'
import {
  GeneralApplianceIssueType,
  generalApplianceIssueTypeOptions,
  getMechanicalIssueOnlyMessage,
  serviceTypeOptions
} from '../appliance-shared/appliance-model'
import { YesNoValue } from '../common/common-enums'
import { yesNoOptions } from '../common/common-options'
import { TopicQualification } from '../common/common-outcome'
import { TopicFridgeFreezerActionResult, existingOrNewOptions, fridgeNotesGenerator } from './action-topic-fridge-model'
import { outcomeGenerator } from './action-topic-fridge-outcome'

export const TopicFridgeFreezerAction = (
  props: WorkflowActionProps<TopicActionInput, TopicFridgeFreezerActionResult>
) => {
  const { result, input, onUpdate } = props
  const { issue, outletWorksWithOtherDevice, serviceType, existingTap, existingOrNew } = result ?? {}
  const workflowContext = useWorkflow()
  const notesGenerator = fridgeNotesGenerator

  const topicOutcome = outcomeGenerator(result)
  const { requirements, qualification } = topicOutcome
  const isOutOfScope = qualification === TopicQualification.OutOfScope
  const onNext = () =>
    onUpdate({
      ...result,
      ...topicOutcome,
      actionCompleted: true,

      workRequiredNotes: generatedNotes
    })
  const generatedNotes = notesGenerator.generateNotes(result ?? {})
  const updateResult = getResultUpdater(result ?? {}, onUpdate).updateResult
  const canComplete = !!requirements
  return (
    <TopicWorkflowAction input={input} onUpdate={onUpdate} result={result}>
      <SingleSelectField
        question={notesGenerator.getQuestion('serviceType')}
        options={serviceTypeOptions}
        answer={serviceType}
        changeAnswer={(serviceType) => updateResult({ serviceType })}
      />
      <EuiSpacer size="m" />
      {serviceType === ServiceType.Installation && (
        <>
          <SingleSelectField
            question={notesGenerator.getQuestion('existingTap')}
            options={yesNoOptions}
            answer={existingTap}
            changeAnswer={(existingTap) => updateResult({ existingTap })}
          />
          <EuiSpacer size="m" />
          {!!existingTap && (
            <SingleSelectField
              question={notesGenerator.getQuestion('existingOrNew')}
              options={existingOrNewOptions}
              answer={existingOrNew}
              changeAnswer={(existingOrNew) => updateResult({ existingOrNew })}
            />
          )}
        </>
      )}

      {serviceType === ServiceType.Repair && (
        <>
          <SingleSelectField
            question={notesGenerator.getQuestion('issue')}
            options={generalApplianceIssueTypeOptions}
            answer={issue}
            changeAnswer={(issue) => updateResult({ issue })}
          />
          <EuiSpacer size="m" />

          {!!issue && issue === GeneralApplianceIssueType.PowerSupply && (
            <>
              <SingleSelectField
                question={notesGenerator.getQuestion('outletWorksWithOtherDevice')}
                answer={outletWorksWithOtherDevice}
                changeAnswer={(outletWorksWithOtherDevice) => updateResult({ outletWorksWithOtherDevice })}
                options={yesNoOptions}
              />
              {outletWorksWithOtherDevice === YesNoValue.Yes && (
                <Callout type="script" title={getMechanicalIssueOnlyMessage('fridge / freezer')} />
              )}
            </>
          )}
        </>
      )}

      {requirements && requirements.attributes && (
        <>
          <EuiSpacer />
          <Callout type="note">
            Book a technician with {requirements.attributes?.map((r) => r.attributeId).join(', ')} skills
          </Callout>
        </>
      )}

      {!isOutOfScope && (
        <>
          <EuiSpacer />
          <EuiButton disabled={!canComplete} onClick={() => onNext()}>
            Next
          </EuiButton>
        </>
      )}
      {isOutOfScope && (
        <>
          <Callout type="script">
            {topicOutcome.qualificationMessage
              ? topicOutcome.qualificationMessage
              : 'Unfortunately this type of work is out of scope for us.'}
          </Callout>
          <EuiSpacer />
          <EuiButton
            color="warning"
            onClick={() =>
              workflowContext.abort({
                abortReason: EnquiryAbortReason.CustomerNeedsAreOutOfService,
                notes: `Customer was enquiring about: ${topicOutcome.qualificationMessage}`
              })
            }
          >
            Offer a voucher and end call
          </EuiButton>
        </>
      )}
    </TopicWorkflowAction>
  )
}
