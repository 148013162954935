import {
  EuiCallOut,
  EuiFlexGrid,
  EuiFlexItem,
  EuiHealth,
  EuiHighlight,
  EuiIcon,
  EuiLink,
  EuiSelectable,
  EuiSpacer,
  IconColor,
  IconType
} from '@elastic/eui'
import { Fragment, useState } from 'react'
import { Callout } from '../../common/callout'
import { decamelise } from '../../common/utils'
import { QuestionFieldProps } from './question-field-props'

interface ConvertibleToString {
  toString: () => string
}

export interface AnswerOption<T> {
  id: T
  label?: string // defaults to decamelised id
  icon?: IconType
  color?: IconColor
}

export interface ComboSelectQuestionFieldProps<TAnswer> extends QuestionFieldProps<TAnswer> {
  options: AnswerOption<TAnswer>[]
  searchable?: boolean
  isMultiSelect?: boolean
  allowCustomOption?: boolean
  brandWarning?: string | null | undefined
}

export const ComboSelectField = <TAnswer extends ConvertibleToString>(
  props: ComboSelectQuestionFieldProps<TAnswer>
) => {
  const { question, questionType, hint, answer, changeAnswer, options, searchable, isMultiSelect, brandWarning } = props

  const showQuestion = props.showQuestion ?? true
  const [editable, setEditable] = useState<boolean>(answer === undefined)

  const id = (answer as any)?.id

  const selectedOption = answer ? options.find((option) => option.id.toString() === id) ?? (answer as any) : undefined

  const selectableOptions: any = options.map((option) => {
    return {
      id: option.id.toString(),
      label: option.label ?? decamelise(option.id.toString()),
      color: option.color,
      ...(selectedOption && selectedOption.id.toString() === option.id.toString() && { checked: 'on' })
    }
  })

  if (answer) {
    const selectedItemIdx = selectableOptions.findIndex((item: any) => item.id === answer)
    if (selectedItemIdx >= 0) {
      selectableOptions[selectedItemIdx].checked = 'on'
    }
  }

  const [useCustomOption, setUseCustomOption] = useState<boolean>(false)
  const [searchValue, setSearchValue] = useState<string | undefined>(selectedOption?.id)
  const renderOption = (option: any, searchValue: string) => {
    return (
      <>
        <EuiFlexGrid columns={2} gutterSize="s">
          <EuiFlexItem style={{ maxWidth: '20px' }}>
            {option?.checked === 'on' ? <EuiIcon type="check" /> : <></>}
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiHealth key={option.id} color={option.color}>
              <EuiHighlight search={searchValue}>{option.label}</EuiHighlight>
            </EuiHealth>
          </EuiFlexItem>
        </EuiFlexGrid>
        {/* <EuiTextColor color="subdued">
          <small>
            <EuiHighlight search={searchValue}>Not serviceable</EuiHighlight>
          </small>
        </EuiTextColor> */}
      </>
    )
  }

  const handleChange = (newOptions: any) => {
    const selectedOptions = newOptions.filter((option: any) => option.checked === 'on')
    if (selectedOptions) {
      changeAnswer && changeAnswer(selectedOptions)
    }
    if (selectedOptions?.length > 0) {
      setEditable(false)
    }
  }

  const handleCustomOption = () => {
    const newCustomOption = { id: searchValue, label: searchValue, checked: 'on', color: 'warning' }
    handleChange([newCustomOption])
  }

  const onSearchKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      if (useCustomOption) {
        handleCustomOption()
      }
    }
  }

  const onSearch = (searchValue: string, matchingOptions: any[]) => {
    if ((matchingOptions?.length ?? 0) === 0) {
      setUseCustomOption(true)
    } else {
      setUseCustomOption(false)
    }
    setSearchValue(searchValue)
  }

  const customProps: any = {
    height: 200,
    bordered: 'true'
  }

  return (
    <>
      {showQuestion && (
        <Callout type={questionType ?? 'script'} data-test-id="workflow-question">
          {question}
        </Callout>
      )}
      {hint && (
        <Callout type="note" data-test-id="workflow-question-hint">
          {hint}
        </Callout>
      )}
      {editable === true ? (
        <>
          <EuiSelectable
            aria-label="brand selection"
            options={selectableOptions}
            searchable={searchable ?? true}
            searchProps={{ onSearch, placeholder: selectedOption?.id, onKeyDown: onSearchKeyDown }}
            renderOption={renderOption}
            placeholder={selectedOption?.id ?? 'Filter options'}
            {...customProps}
            onChange={handleChange}
            singleSelection={isMultiSelect ? !isMultiSelect : true}
            listProps={{ rowHeight: 50, showIcons: false }}
          >
            {(list, search) => (
              <Fragment>
                {search}
                {list}
                {useCustomOption && (
                  <>
                    <EuiLink onClick={handleCustomOption}>
                      Select <i>"{searchValue}"</i>
                    </EuiLink>
                  </>
                )}
              </Fragment>
            )}
          </EuiSelectable>
          <EuiSpacer />
        </>
      ) : (
        <>
          <EuiSpacer size="s" />
          {selectedOption ? (
            <EuiLink aria-label="change" onClick={() => setEditable(!editable)}>
              <EuiHealth key={id} color={selectedOption.color}>
                <span style={{ textDecoration: 'underline' }}>{selectedOption?.label}</span>
              </EuiHealth>
            </EuiLink>
          ) : (
            <EuiLink aria-label="select" onClick={() => setEditable(!editable)}>
              <span style={{ textDecoration: 'underline' }}>Select Brand</span>
            </EuiLink>
          )}
        </>
      )}
      {!!brandWarning && (
        <>
          <EuiSpacer size="m" />
          <EuiCallOut size="s" title={brandWarning} color="warning" iconType="alert" />
          <EuiSpacer size="m" />
        </>
      )}
    </>
  )
}
