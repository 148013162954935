import { JobType, TradeType } from '../../../api/generated-types'
import { Callout } from '../../../common/callout'
import { YesNoValue } from '../common/common-enums'
import {
  TopicQualification,
  TopicQualificationResult,
  TopicRequirementsResult,
  createOutcomeGenerator
} from '../common/common-outcome'
import { GasServiceType, TopicPlumbingGasActionResult } from './action-topic-plumbing-gas-model'

export const REQUIRED_GAS_SKILL = { attributeId: 'Gas' }
export const getRequirements = (result?: TopicPlumbingGasActionResult): TopicRequirementsResult => {
  const { gasServiceType, gasConversion, customerHasConversionKit } = result ?? {}
  switch (gasConversion) {
    case YesNoValue.Yes:
      return customerHasConversionKit === YesNoValue.Yes ? { requirements: { attributes: [REQUIRED_GAS_SKILL] } } : {}
    default:
      return gasServiceType ? { requirements: { attributes: [REQUIRED_GAS_SKILL] } } : {}
  }
}

const getQualification = (result: TopicPlumbingGasActionResult | undefined): TopicQualificationResult => {
  const { gasServiceType, gasConversion, customerHasConversionKit } = result ?? {}
  const BOOK_WITH_ELECTRICIAN = 'You need to book a Gas plumber and an electrician at the same time'
  const NOT_FOR_VEHICLES_OR_BOATS = 'Not for mobile vehicles or boats.'
  const CUSTOMER_SUPPLIED_CONVERSION_KIT_REQUIRED =
    'Unfortunately this work is out of scope for us as we do not currently supply conversion kits. Once you have purchased one, we would be more than happy to come out and assist'

  if (gasConversion === YesNoValue.Yes && !!customerHasConversionKit) {
    if (customerHasConversionKit === YesNoValue.No) {
      return {
        qualification: TopicQualification.OutOfScope,
        qualificationMessage: CUSTOMER_SUPPLIED_CONVERSION_KIT_REQUIRED
      }
    } else {
      return {
        qualification: TopicQualification.InScope
      }
    }
  }

  if (gasServiceType === GasServiceType.InstallOrChangeOverGasCookTopWithElecOven) {
    console.log(BOOK_WITH_ELECTRICIAN)
    return {
      qualification: TopicQualification.InScope,
      qualificationMessage: <Callout type="warning" title={BOOK_WITH_ELECTRICIAN} />
    }
  }

  if (gasServiceType) {
    return {
      qualification: TopicQualification.InScope,
      ...(gasServiceType === GasServiceType.ComplianceCertificate && {
        qualificationMessage: <Callout type="warning" title={NOT_FOR_VEHICLES_OR_BOATS} />
      })
    }
  } else {
    return {
      qualification: TopicQualification.Unknown
    }
  }
}

export const outcomeGenerator = createOutcomeGenerator<TopicPlumbingGasActionResult>(
  {
    qualification: TopicQualification.Unknown,
    trade: TradeType.Plumbing,
    type: JobType.Service,
    category: 'Gas'
  },
  getQualification,
  getRequirements
)
