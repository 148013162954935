import { useQuery } from '@apollo/client'
import { EnquiryStatusType, GetEnquiryDocument, InteractionMessageFragment } from '../../api/generated-types'
import { EuiCallOut, EuiLoadingSpinner } from '@elastic/eui'
import { WorkflowInteractionMessageNewEnquiryWorkflow } from '../../workflow/workflow-interaction-message-new-enquiry'
import { OnChangeContactProps } from '../../workflow/workflow-interaction-message-model'
import { InteractionMessageDetailEnquiryDetail } from './interaction-message-detail-enquiry-detail'

export interface InteractionMessageDetailEnquiryContainerProps {
  enquiryId: string
  interactionMessage: InteractionMessageFragment
  onChangeContact: (props: OnChangeContactProps) => Promise<void>
  result?: any
}

export const InteractionMessageDetailEnquiryContainer = ({
  enquiryId,
  result,
  interactionMessage,
  onChangeContact
}: InteractionMessageDetailEnquiryContainerProps) => {
  const { data, loading } = useQuery(GetEnquiryDocument, {
    variables: { input: { id: enquiryId } }
  })

  const enquiry = data?.getEnquiry?.enquiry

  return loading ? (
    <EuiLoadingSpinner />
  ) : enquiry ? (
    <>
      {loading ? (
        <EuiLoadingSpinner />
      ) : (
        <>
          {enquiry && (
            <>
              {enquiry.status.status === EnquiryStatusType.Active ? (
                <WorkflowInteractionMessageNewEnquiryWorkflow
                  interactionMessage={interactionMessage}
                  enquiry={enquiry}
                  initialResult={result}
                  onChangeContact={onChangeContact}
                />
              ) : (
                <>
                  <InteractionMessageDetailEnquiryDetail enquiry={enquiry} />
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  ) : (
    <EuiCallOut title="Error loading enquiry" color="danger" />
  )
}
