import { EuiButton, EuiCallOut, EuiSpacer } from '@elastic/eui'

export interface SessionExpiredProps {
  onRefresh?: () => void
}

export const SessionExpired = ({ onRefresh }: SessionExpiredProps) => {
  return (
    <EuiCallOut title="You do not seem to be logged in" iconType="alert" size="m">
      <div>
        Please refresh the page and sign in if required.
        <br />
        <EuiSpacer />
        <EuiButton
          iconType="refresh"
          aria-label="refresh"
          onClick={() => (onRefresh ? onRefresh() : window.location.reload())}
        >
          Refresh
        </EuiButton>
      </div>
      <EuiSpacer />
      <p>If the problem persists please check your network connection.</p>
    </EuiCallOut>
  )
}
