import { EuiButton, EuiSpacer } from '@elastic/eui'
import { CustomerType, EnquiryAbortReason } from '../../../api/generated-types'
import { Callout } from '../../../common/callout'
import { HVACRoofAccessQuestion } from '../../helpers/hvac-commercial-roof-acccess-question'
import { QuestionDefinitions, getNotesGenerator } from '../../helpers/notes-generator'
import { getResultUpdater } from '../../helpers/results-updater'
import { useWorkflow } from '../../helpers/workflow-provider'
import { FreeTextQuestionField } from '../../question-fields/free-text-question-field'
import { SingleSelectField } from '../../question-fields/single-select-question-field'
import { WorkflowActionProps } from '../../workflow-model'
import { TopicActionInput } from '../action-topic-model'
import { TopicWorkflowAction } from '../action-topic-view'
import { YesNoValue } from '../common/common-enums'
import { yesNoOptions } from '../common/common-options'
import { TopicQualification } from '../common/common-outcome'
import { reset } from '../common/common-utils'
import {
  ACBrand,
  ACType,
  TopicHVACServiceActionResult,
  acTypeOptions,
  serviceLevelOptions,
  splitSystemBrandOptions,
  windowBoxReplacementOptions
} from './action-topic-hvac-service-model'
import { getRequirements, outcomeGenerator } from './action-topic-hvac-service-requirements'
import { OutOfScope } from '../../helpers/out-of-topic'

const questionDefinitions: QuestionDefinitions<TopicHVACServiceActionResult> = {
  hasIssue: {
    excludeFromNotes: true,
    question:
      'Are there any mechanical issues that need repairing – such as a poorly performing unit or any unusual noises/leaking/error msgs – or are you wanting a yearly service?'
  },
  serviceLevel: {
    statement: 'Service level:',
    question:
      'A deep clean can enhance performance and reduce power bills. Did you want to have the unit cleaned or did you also want a condition report on the electrical components/gas levels as well?'
  },
  hvacType: {
    statement: 'Unit type:',
    question: 'What type of AC unit is it?'
  },
  brand: {
    statement: 'Brand:',
    question: 'What brand is your AC?'
  },
  unitLocations: {
    statement: 'Location(s):',
    question: 'Location of each AC unit(s)?'
  },
  outdoorUnitLocations: {
    statement: 'Outdoor unit location(s):',
    question: 'Outdoor unit location (if relevant, more than 2m high)?'
  },
  unitCount: {
    statement: 'Number of units:',
    question: 'How many AC units do you have?'
  },
  manHoleAccess: {
    statement: 'Manhole access:',
    question: 'Is there man hole access?'
  },
  unitAge: {
    statement: 'Age of unit(s):',
    question: 'Age of AC unit(s)?'
  },
  lastServiceDate: {
    statement: 'Last service date:',
    question: 'Last service date?'
  },
  doesFurnitureNeedMoving: {
    statement: 'Furniture needs moving:',
    question: 'Does any furniture need to be moved?'
  }
}
const notesGenerator = getNotesGenerator(questionDefinitions)

export interface TopicHVACServiceActionInput extends TopicActionInput {
  isSpecial: boolean
}

const conditionAndFilterCleanCategory = 'HVAC Condition and Filter Report'
const hvacCategory = 'HVAC service'

export const TopicHVACServiceAction = (
  props: WorkflowActionProps<TopicHVACServiceActionInput, TopicHVACServiceActionResult>
) => {
  const { result, input, onUpdate } = props
  const { isSpecial: hvacSpecial } = input
  const {
    jobDescription,
    customerType,
    hasIssue,
    serviceLevel,
    brand,
    brandOther,
    doesFurnitureNeedMoving,
    hvacType,
    lastServiceDate,
    manHoleAccess,
    outdoorUnitLocations,
    unitAge,
    unitCount,
    unitLocations,
    considerReplacement,
    replacementType,

    roofAccess
  } = result ?? {}

  const { requirements, category } = getRequirements(hvacSpecial)(result ?? {})
  const topicOutcome = outcomeGenerator(hvacSpecial)(result)
  const isCommercial = customerType === CustomerType.Commercial
  const isOutOfScope = topicOutcome.qualification === TopicQualification.OutOfScope
  const canComplete = topicOutcome.qualification === TopicQualification.InScope
  const newTopic = topicOutcome.newTopic

  const onNext = () =>
    onUpdate({
      ...result,
      actionCompleted: true,
      ...topicOutcome,
      category: hvacSpecial ? conditionAndFilterCleanCategory : category,
      workRequiredNotes: generatedNotes,
      requirements
    })

  const generatedNotes = notesGenerator.generateNotes(result ?? {})
  const updateResult = getResultUpdater<TopicHVACServiceActionResult>(result ?? {}, onUpdate).updateResult

  const workflowContext = useWorkflow()
  const isDomestic = customerType === CustomerType.Domestic
  const allowCustomerToContinue = hvacSpecial ? isDomestic : true
  const serviceLevelQuestionAnswered = hvacSpecial ? hasIssue === YesNoValue.No : !!serviceLevel

  return (
    <>
      {allowCustomerToContinue ? (
        <TopicWorkflowAction input={input} onUpdate={onUpdate} result={result}>
          <>
            <SingleSelectField
              question={notesGenerator.getQuestion('hasIssue')}
              answer={hasIssue}
              options={yesNoOptions}
              changeAnswer={(hasIssue) => {
                updateResult({ hasIssue })
              }}
            />
            {hasIssue === YesNoValue.No && (
              <>
                {!hvacSpecial && (
                  <SingleSelectField
                    question={notesGenerator.getQuestion('serviceLevel')}
                    answer={serviceLevel}
                    options={serviceLevelOptions}
                    changeAnswer={(serviceLevel) => {
                      updateResult({ serviceLevel })
                    }}
                  />
                )}

                {serviceLevelQuestionAnswered && (
                  <>
                    <SingleSelectField
                      question={notesGenerator.getQuestion('hvacType')}
                      options={acTypeOptions}
                      answer={hvacType}
                      changeAnswer={(hvacType) => {
                        updateResult({
                          ...reset(result),
                          customerType,
                          jobDescription,
                          hasIssue,
                          serviceLevel,
                          hvacType
                        })
                      }}
                    />
                    {!!hvacType &&
                      (hvacType === ACType.SplitSystemOrMultiHead || hvacType === ACType.DuctedOrCassette) && (
                        <>
                          <SingleSelectField
                            question={notesGenerator.getQuestion('brand')}
                            options={splitSystemBrandOptions}
                            answer={brand}
                            changeAnswer={(brand) => {
                              updateResult({ brand })
                            }}
                          />
                          {!!brand && brand === ACBrand.Other && (
                            <FreeTextQuestionField
                              question={notesGenerator.getQuestion('brandOther')}
                              answer={brandOther}
                              changeAnswer={(brandOther) => {
                                updateResult({ brandOther })
                              }}
                            />
                          )}
                          {!!brand && (brand !== ACBrand.Other || (brand === ACBrand.Other && !!brandOther)) && (
                            <>
                              <FreeTextQuestionField
                                question={notesGenerator.getQuestion('unitCount')}
                                answer={unitCount}
                                changeAnswer={(unitCount) => {
                                  updateResult({ unitCount })
                                }}
                              />
                              <FreeTextQuestionField
                                question={notesGenerator.getQuestion('unitLocations')}
                                answer={unitLocations}
                                changeAnswer={(unitLocations) => {
                                  updateResult({ unitLocations })
                                }}
                              />
                              <FreeTextQuestionField
                                question={notesGenerator.getQuestion('outdoorUnitLocations')}
                                answer={outdoorUnitLocations}
                                changeAnswer={(outdoorUnitLocations) => {
                                  updateResult({ outdoorUnitLocations })
                                }}
                              />
                              <SingleSelectField
                                question={notesGenerator.getQuestion('manHoleAccess')}
                                options={yesNoOptions}
                                answer={manHoleAccess}
                                changeAnswer={(manHoleAccess) => updateResult({ manHoleAccess })}
                              />
                              <FreeTextQuestionField
                                question={notesGenerator.getQuestion('lastServiceDate')}
                                answer={lastServiceDate}
                                changeAnswer={(lastServiceDate) => {
                                  updateResult({ lastServiceDate })
                                }}
                              />
                              <FreeTextQuestionField
                                question={notesGenerator.getQuestion('unitAge')}
                                answer={unitAge}
                                changeAnswer={(unitAge) => {
                                  updateResult({ unitAge })
                                }}
                              />
                              {!hvacSpecial && (
                                <SingleSelectField
                                  question={notesGenerator.getQuestion('doesFurnitureNeedMoving')}
                                  options={yesNoOptions}
                                  answer={doesFurnitureNeedMoving}
                                  changeAnswer={(doesFurnitureNeedMoving) => updateResult({ doesFurnitureNeedMoving })}
                                />
                              )}
                            </>
                          )}
                        </>
                      )}
                    {/** These questions are out of scope for the hvac special so do not include them if it is a special */}
                    {!!hvacType && !hvacSpecial && (hvacType === ACType.WindowBox || hvacType === ACType.Portable) && (
                      <>
                        <SingleSelectField
                          question={notesGenerator.getQuestion('considerReplacement')}
                          answer={considerReplacement}
                          options={yesNoOptions}
                          changeAnswer={(considerReplacement) => updateResult({ considerReplacement })}
                        />
                        {considerReplacement === YesNoValue.Yes && (
                          <SingleSelectField
                            question={notesGenerator.getQuestion('replacementType')}
                            answer={replacementType}
                            options={windowBoxReplacementOptions}
                            changeAnswer={(replacementType) => {
                              updateResult({ replacementType })
                            }}
                          />
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            )}
            <EuiSpacer />

            {newTopic && (
              <EuiButton onClick={() => workflowContext.changeJobTopic(newTopic.reference)}>
                {newTopic.buttonLabel}
              </EuiButton>
            )}
            <HVACRoofAccessQuestion updateResults={updateResult} isCommercial={isCommercial} roofAccess={roofAccess} />
            {isOutOfScope && !newTopic && (
              <>
                <Callout type="script">
                  {topicOutcome.qualificationMessage
                    ? topicOutcome.qualificationMessage
                    : 'Unfortunately this type of work is out of scope for us.'}
                </Callout>
                <EuiSpacer />
                <EuiButton
                  color="warning"
                  onClick={() =>
                    workflowContext.abort({
                      abortReason: EnquiryAbortReason.CustomerNeedsAreOutOfService,
                      notes: `Customer was enquiring about: ${topicOutcome.qualificationMessage}`
                    })
                  }
                >
                  Offer a voucher and end call
                </EuiButton>
              </>
            )}
            {!isOutOfScope && !newTopic && (
              <>
                {!!topicOutcome.qualificationMessage && (
                  <>
                    {topicOutcome.qualificationMessage}
                    <EuiSpacer size="m" />
                  </>
                )}

                <EuiButton disabled={!canComplete} onClick={() => onNext()}>
                  Next
                </EuiButton>
              </>
            )}
          </>
        </TopicWorkflowAction>
      ) : (
        <OutOfScope
          issue={'$99 HVAC condition & filter report'}
          reason="sorry we do not offer the $99 HVAC special for commercial customers."
          isOutOfScope={true}
        />
      )}
    </>
  )
}
