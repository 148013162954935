import { useQuery } from '@apollo/client'
import { EuiButton, EuiCallOut, EuiLink, EuiLoadingSpinner, EuiSpacer, EuiText } from '@elastic/eui'
import { dataQualityStatusValidOrError } from '@fallonsolutions/data-quality'
import { ReactNode } from 'react'
import {
  CustomerFragment,
  CustomerLinkFragment,
  DataQualityStatus,
  GetContactDocument,
  GetCustomerLinkDocument
} from '../../api/generated-types'
import { Callout } from '../../common/callout'
import ContactDetailContainer from '../../contacts/contact-detail-container'
import { ContactSearchContainer } from '../../contacts/contact-search/contact-search-container'
import { useContactSearch } from '../../contacts/contact-search/contact-search-hook'
import { CustomerDetailContainer } from '../../customers/customer-detail-container'
import { WorkflowAction } from '../workflow-action'
import { SelectedCustomer } from '../workflow-customer-model'
import { WorkflowActionProps } from '../workflow-model'
import { CustomerActionInput, CustomerActionResult } from './action-customer'

// eslint-disable-next-line complexity
export const ContactCustomerAction = (props: WorkflowActionProps<CustomerActionInput, CustomerActionResult>) => {
  const { input, result, onUpdate } = props

  const [searchState] = useContactSearch()

  const allowCreate = input?.allowCreate ?? false
  const allowContinueWithoutCustomer = input?.allowContinueWithoutCustomer ?? false

  const { contact, actionCompleted } = result ?? {}
  const customerSearchResults = useQuery(GetCustomerLinkDocument, {
    variables: {
      id: result?.customer?.id ?? ''
    },
    skip: !result?.customer?.id,
    nextFetchPolicy: 'network-only'
  })

  const customer = customerSearchResults?.data?.getCustomer

  const isCustomerInactive = customer?.active === false
  const onBackToSearch = () => onUpdate({ customer: undefined, contact: undefined })
  const onContinue = () => onUpdate({ ...result, actionCompleted: true })
  const clearResult = () => onUpdate({})

  const handleOnAction = () => {
    console.log('handleOnAction', searchState)
    const selectedCustomer = searchState.selectedCustomer ? toSelectedCustomer(searchState.selectedCustomer) : undefined
    const selectedContact = searchState.selectedContact
    const isNewCustomer = searchState.createNew === true
    onUpdate({ ...result, customer: selectedCustomer, contact: selectedContact })
    if (isNewCustomer && !actionCompleted) {
      onUpdate({ ...result, customer: undefined, contact: selectedContact, actionCompleted: true })
    }
  }

  const { data: customerQuery, loading: customerDataQualityLoading } = useQuery(GetCustomerLinkDocument, {
    variables: {
      id: searchState.selectedCustomer?.id ?? ''
    },
    skip: !searchState.selectedCustomer?.id
  })

  // handle contact only data quality check w/o customer
  const { data: contactQuery, loading: contactDataQualityLoading } = useQuery(GetContactDocument, {
    variables: {
      input: { contactId: searchState.selectedContact?.id ?? '' }
    },
    skip: !searchState.selectedContact?.id
  })

  const value = actionCompleted
    ? customer
      ? `${result?.customer?.label} (${result?.customer?.reference})`
      : 'New customer'
    : undefined

  const targetContact = contactQuery?.getContact.contact ?? customerQuery?.getCustomer?.mainContact

  const mainContactDataQualityStatus = targetContact?.dataQuality?.status
  const fixDataQuality = targetContact ? mainContactDataQualityStatus !== DataQualityStatus.Valid : true
  const canProceed = !fixDataQuality && isCustomerInactive === false
  const contactOnlyCanProceed = !customer && allowContinueWithoutCustomer && !fixDataQuality
  const loading = customerDataQualityLoading || contactDataQualityLoading

  const dataQualityWarning: ReactNode = (
    <>
      {loading ? (
        <EuiLoadingSpinner size="s" />
      ) : (
        <>
          {fixDataQuality && (
            <>
              <EuiSpacer size="s" />
              <Callout type="warning" title="Data quality check required" className="callout--flat-list">
                <EuiText>
                  <ul>
                    <li>Please review and correct any issues with contact details before continuing.</li>
                    <li>
                      Resolving contact detail issues at this time helps reduce customer frustration and improve
                      customer satisfaction.
                    </li>
                  </ul>
                </EuiText>
              </Callout>
              <EuiSpacer size="s" />
            </>
          )}
        </>
      )}
    </>
  )

  const customerInactiveWarning: ReactNode = (
    <>
      {isCustomerInactive && (
        <>
          <EuiSpacer size="m" />
          <Callout type="warning" title="Customer is inactive" className="callout--flat-list">
            <EuiText>
              <ul>
                <li>Please review and correct any issues with contact details before continuing.</li>
                <li>
                  Resolving contact detail issues at this time helps reduce customer frustration and improve customer
                  satisfaction.
                </li>
              </ul>
            </EuiText>
          </Callout>
        </>
      )}
    </>
  )

  return (
    <>
      <WorkflowAction
        title="Contact / Customer"
        value={actionCompleted ? value : undefined}
        onClickChange={clearResult}
        editable={input.editable}
      >
        {customer?.id ? (
          <>
            <EuiLink onClick={onBackToSearch}>Back to search</EuiLink>
            <EuiSpacer />
            <div className="workflow__detail-wrapper">
              <CustomerDetailContainer id={customer.id} showProperties={true} />
            </div>
            <EuiSpacer size="s" />
            {dataQualityWarning}
            {customerInactiveWarning}
            <EuiButton onClick={onContinue} isLoading={customerDataQualityLoading} isDisabled={!canProceed}>
              Next
            </EuiButton>
            <EuiSpacer />
          </>
        ) : contact?.id ? (
          <>
            <EuiLink onClick={onBackToSearch}>Back to search</EuiLink>
            <EuiSpacer />
            <EuiCallOut
              title="Contact only: note that this contact does not have a customer record yet. This can be created later in the workflow."
              size="s"
            />
            <EuiSpacer size="s" />
            <div className="workflow__detail-wrapper">
              <ContactDetailContainer contactId={contact.id} showBreadcrumbs={false} />
            </div>
            <EuiSpacer size="s" />
            {dataQualityWarning}
            {customerInactiveWarning}
            <EuiButton onClick={onContinue} isDisabled={!contactOnlyCanProceed}>
              Next
            </EuiButton>
            <EuiSpacer />
          </>
        ) : (
          <>
            <ContactSearchContainer allowCreate={allowCreate} onAction={handleOnAction} />
            <EuiSpacer />
            {!customer && allowContinueWithoutCustomer && (
              <EuiLink onClick={() => onContinue()}>Continue without customer</EuiLink>
            )}
          </>
        )}
      </WorkflowAction>
    </>
  )
}

export const toSelectedCustomer = (customer: CustomerLinkFragment | CustomerFragment): SelectedCustomer => {
  return {
    id: customer.id,
    reference: customer.number ?? 'Unknown',
    firstName: customer.mainContact?.detail?.firstName ?? 'Unknown',
    fullName: customer.mainContact?.detail?.fullName ?? 'Unknown',
    label: customer.mainContact?.detail?.fullName ?? 'Unknown',
    email: customer.mainContact?.detail?.email,
    phone: customer.mainContact?.detail?.phone,

    alternatePhone: customer.mainContact?.detail?.alternatePhone,
    contactId: customer.mainContact?.id, //cater to additional roles
    canSendTransactionalEmail:
      dataQualityStatusValidOrError(customer.mainContact?.dataQuality?.status) && customer.mainContact?.detail?.email
        ? true
        : false
  }
}
