import { useLazyQuery, useQuery } from '@apollo/client'
import {
  EuiButtonEmpty,
  EuiComboBox,
  EuiComboBoxOptionOption,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHighlight
} from '@elastic/eui'
import { compact } from 'lodash-es'
import { useCallback, useEffect, useState } from 'react'
import { GetJobsDocument, SearchJobsComboDocument } from '../api/generated-types'
import { useDebounce } from '../common/use-debounce'
import '../static/css/combo-box.css'

export enum JobComboBoxLabelType {
  Address = 'address',
  Customer = 'customer',
  None = 'none'
}

export interface JobComboBoxValue {
  id: string
  trade: string | undefined | null
  tradeId: string | undefined | null
  number: string | undefined | null
  label: string
  className?: string | undefined
}

export type JobComboItem = EuiComboBoxOptionOption<JobComboBoxValue>

interface JobComboBoxProps {
  initialJobIds?: string[]
  jobs: JobComboBoxValue[]
  onChangeJobs: (jobs: JobComboBoxValue[]) => void
  renderOption?: (
    option: EuiComboBoxOptionOption<unknown>,
    searchValue: string,
    contentClassName: string
  ) => JSX.Element
  sandbox?: boolean
  label?: string
  placeholder?: string
  singleSelection?: boolean
  labelType?: JobComboBoxLabelType
  startEnabled?: boolean
  disabled?: boolean
}

const labelForJob = (job: any, labelType: JobComboBoxLabelType): string => {
  switch (labelType) {
    case JobComboBoxLabelType.Address:
      return `${job.number}: ${job.property?.formattedAddress ?? ''}`
    case JobComboBoxLabelType.Customer:
      return `${job.number}: ${job.customer?.mainContact?.detail?.fullName ?? ''}`
    default:
      return job.number
  }
}

const JobComboBox = (props: JobComboBoxProps) => {
  const { jobs, initialJobIds, onChangeJobs, label, disabled } = props
  const singleSelection = props.singleSelection ?? false
  const labelType = props.labelType ?? JobComboBoxLabelType.None
  const [term, setTerm] = useState('')
  const sandbox = props.sandbox ?? false
  const placeholder = props.placeholder ?? 'Jobs'

  const [enabled, setEnabled] = useState(props.startEnabled || jobs.length > 0)
  const [comboInput, setComboInput] = useState<HTMLInputElement | null>(null)

  const [getInitialJobs, { data: initialJobsData }] = useLazyQuery(GetJobsDocument)
  const initialJobs = initialJobsData?.getJobs?.jobs
  useEffect(() => {
    if (initialJobIds && initialJobIds.length > 0) {
      getInitialJobs({
        variables: {
          input: {
            jobIds: initialJobIds
          }
        }
      })
    }
  }, [initialJobIds])
  useEffect(() => {
    if (initialJobs && initialJobs.length > 0) {
      setEnabled(true)
      onChangeJobs(
        initialJobs.map((job) => ({
          label: job.number,
          id: job.id,
          number: job.number,
          trade: job.tradeType,
          tradeId: job.tradeId
        }))
      )
    }
  }, [initialJobs])

  const debounceTerm = useDebounce(term, 150)

  const { data, loading, error } = useQuery(SearchJobsComboDocument, {
    variables: {
      input: {
        filter: {
          ...(debounceTerm.length > 0 ? { query: debounceTerm } : {}),
          ...(sandbox === true ? { sandbox: true } : {})
        },
        from: 0,
        size: 10
      }
    }
  })

  const options = data?.searchJobsBeta?.results
    ? data.searchJobsBeta.results.map((job) => {
        return {
          id: job.id,
          label: labelForJob(job, labelType),
          trade: job.tradeType,
          number: job.number,
          className: singleSelection ? 'combo-box-single-select' : ''
        } as JobComboBoxValue
      })
    : []

  if (error) {
    console.error('error', error)
  }

  const onSearchChange = useCallback((searchValue: string, _hasMatchingOptions?: boolean) => {
    setTerm(searchValue)
  }, [])

  useEffect(() => {
    // Simulate initial load
    onSearchChange('')
  }, [onSearchChange])

  const renderOption = (option: EuiComboBoxOptionOption<unknown>, searchValue: string, contentClassName: string) => {
    return (
      <EuiFlexGroup gutterSize="s" alignItems="center">
        <EuiFlexItem grow={true}>
          <span className={contentClassName}>
            <EuiHighlight search={searchValue}>{option.label}</EuiHighlight>
          </span>
        </EuiFlexItem>
      </EuiFlexGroup>
    )
  }

  const onAddFilter = () => {
    setEnabled(true)
    setTimeout(() => comboInput?.focus(), 50)
  }

  const onBlur = () => {
    if (jobs.length <= 0) {
      setEnabled(false)
    }
  }

  // typing is off. Function simply types the selected options as JobComboBoxValue
  const handleOnChangeJobs = (selectedOptions: JobComboItem[]) => {
    onChangeJobs(compact(selectedOptions.map((option) => option as JobComboBoxValue)))
  }

  return (
    <form autoComplete="off">
      <EuiComboBox
        async
        inputRef={setComboInput}
        placeholder={placeholder}
        options={options}
        selectedOptions={jobs}
        onChange={handleOnChangeJobs}
        isClearable={true}
        renderOption={props.renderOption ?? renderOption}
        rowHeight={40}
        isLoading={loading}
        onSearchChange={onSearchChange}
        style={{ minWidth: '140px' }}
        hidden={!enabled}
        onBlur={onBlur}
        singleSelection={singleSelection}
        data-test-subj="job-selector"
        isDisabled={disabled}
        aria-label="job-selector"
      />
      {!enabled && (
        <EuiButtonEmpty iconType="filter" flush="both" onClick={onAddFilter} data-test-subj="AppointmentJobButton">
          {label ?? 'Job'}
        </EuiButtonEmpty>
      )}
    </form>
  )
}

export default JobComboBox
