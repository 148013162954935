import { ContactFragment } from '@fallonsolutions/types'
import { ContactDetails, CustomerContactDetails } from '../workflow/actions/action-contact'
import { formatPhone } from './phone'

export interface createInitialContactDetailsParams {
  suburb: string | undefined | null
  suburbId: string | undefined | null
  firstName: string | undefined | null
  lastName: string | undefined | null
  phone: string | undefined | null
  contact: ContactFragment | undefined | null
  customerId: string | undefined | null
}

export const createInitialContactDetails = (
  params: createInitialContactDetailsParams
): CustomerContactDetails | ContactDetails => {
  const { suburb, firstName, lastName, phone, contact, suburbId, customerId } = params
  switch (true) {
    case !!contact:
      return {
        id: contact?.id ?? undefined,
        customerId: customerId ?? undefined,
        firstName: contact?.detail?.firstName ?? '',
        lastName: contact?.detail?.lastName ?? '',
        phone: contact?.detail?.phone ? formatPhone(contact?.detail.phone) : '',
        ...(contact?.detail?.address?.suburbId &&
          contact?.detail?.address?.suburb && {
            suburb: {
              id: contact.detail.address.suburbId,
              label: contact.detail.address.suburb
            }
          })
      }

    default:
    case !contact:
      return {
        id: undefined,
        customerId: undefined,
        firstName: firstName ?? '',
        lastName: lastName ?? '',
        phone: phone ? formatPhone(phone) : '',
        ...(suburbId &&
          suburb && {
            suburb: {
              id: suburbId,
              label: suburb
            }
          })
      }
  }
}
