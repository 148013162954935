import { JobType, TradeType } from '../../../api/generated-types'
import {
  TopicQualification,
  TopicQualificationResult,
  TopicRequirementsResult,
  createOutcomeGenerator,
  createRequirements
} from '../common/common-outcome'
import { TopicWashingMachineActionResult, WashingMachineFaultEnum } from './action-topic-washing-machine-model'
import { canProceedWithRepair, getOutOfScopeMessage, isBrandServiceable } from '../appliance-shared/appliance-model'
import { ServiceType } from '../action-topic-model'
import { YesNoValue } from '../common/common-enums'
import {
  APPLIANCE_FAULT_OR_REPAIR_SKILL,
  GENERAL_ELECTRICAL_SKILL,
  GENERAL_PLUMBING_SKILL
} from '../common/skill-constants'

const getRequirements = (result: TopicWashingMachineActionResult | undefined): TopicRequirementsResult => {
  const { serviceType, fault, applianceAge, outletWorksWithOtherDevice, powerOutletNeeded, issuesWithExistingTaps } =
    result ?? {}

  if (serviceType === ServiceType.Installation) {
    if (!!powerOutletNeeded && !!issuesWithExistingTaps) {
      return { requirements: createRequirements([GENERAL_PLUMBING_SKILL]), trade: TradeType.Plumbing }
    } else {
      return {}
    }
  }

  if (serviceType === ServiceType.Repair && !!applianceAge) {
    if (canProceedWithRepair(result) === undefined || !canProceedWithRepair(result)) {
      return {}
    }

    switch (fault) {
      case WashingMachineFaultEnum.PowerSupply:
        if (outletWorksWithOtherDevice) {
          if (outletWorksWithOtherDevice === YesNoValue.Yes) {
            return { requirements: createRequirements([APPLIANCE_FAULT_OR_REPAIR_SKILL]), trade: TradeType.Appliances }
          } else {
            return { requirements: createRequirements([GENERAL_ELECTRICAL_SKILL]), trade: TradeType.Electrical }
          }
        } else {
          return {}
        }
      case WashingMachineFaultEnum.OtherFault:
        if (isBrandServiceable(result)) {
          return { requirements: createRequirements([APPLIANCE_FAULT_OR_REPAIR_SKILL]), trade: TradeType.Appliances }
        } else {
          return {}
        }
      default:
        return {}
    }
  }

  return {}
}

const getQualification = (result: TopicWashingMachineActionResult | undefined): TopicQualificationResult => {
  const {
    serviceType,
    outletWorksWithOtherDevice,
    applianceAge,
    brandServiceable,
    confirmedPartsAvailableWithDSR,
    fault,
    powerOutletNeeded,
    issuesWithExistingTaps
  } = result ?? {}

  if (serviceType === ServiceType.Installation) {
    return {
      qualification:
        !!powerOutletNeeded && !!issuesWithExistingTaps ? TopicQualification.InScope : TopicQualification.Unknown
    }
  } else {
    if (!applianceAge || canProceedWithRepair(result) === undefined) {
      return { qualification: TopicQualification.Unknown }
    }
    if (!canProceedWithRepair(result)) {
      return {
        qualification: TopicQualification.OutOfScope,
        qualificationMessage: getOutOfScopeMessage(' Washing machine repair')
      }
    }

    if (brandServiceable === YesNoValue.No && confirmedPartsAvailableWithDSR === YesNoValue.No) {
      return {
        qualification: TopicQualification.OutOfScope,
        qualificationMessage: getOutOfScopeMessage(' Washing machine repair')
      }
    }
    if (fault) {
      switch (fault) {
        case WashingMachineFaultEnum.PowerSupply:
          return {
            qualification: outletWorksWithOtherDevice ? TopicQualification.InScope : TopicQualification.Unknown
          }
        case WashingMachineFaultEnum.OtherFault:
          return {
            qualification: isBrandServiceable(result) ? TopicQualification.InScope : TopicQualification.Unknown
          }
        default:
          return { qualification: TopicQualification.Unknown }
      }
    } else {
      return { qualification: TopicQualification.Unknown }
    }
  }
}
export const outcomeGenerator = createOutcomeGenerator<TopicWashingMachineActionResult>(
  { qualification: TopicQualification.Unknown, trade: TradeType.Plumbing, type: JobType.Service, category: 'General' },
  getQualification,
  getRequirements
)
