import { useMutation } from '@apollo/client'
import {
  EuiButton,
  EuiButtonEmpty,
  EuiCallOut,
  EuiCheckbox,
  EuiFieldNumber,
  EuiFieldText,
  EuiForm,
  EuiFormLabel,
  EuiHorizontalRule,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiOverlayMask,
  EuiSpacer,
  EuiText
} from '@elastic/eui'
import { MoneyUtils } from '@fallonsolutions/money'
import { omit } from 'lodash-es'
import { useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import {
  CreateCreditNoteDocument,
  CreateCreditNoteInput,
  CreateLineItemInput,
  CreditNoteApplicationType,
  InvoiceFragment,
  InvoiceLineItemType
} from '../api/generated-types'
import { OperationSuccess } from '../common/operation-success'

interface CreateCustomerRefundProps {
  invoice: InvoiceFragment
  closeModal: () => void
}

export const CreateCustomerRefund = (props: CreateCustomerRefundProps) => {
  const { invoice, closeModal } = props
  const [createCreditNote, { data, loading, error }] = useMutation(CreateCreditNoteDocument, {
    refetchQueries: ['GetJob', 'GetInvoiceWithPayments', 'GetPayment'],
    awaitRefetchQueries: true,
    errorPolicy: 'all'
  })

  const totalAmount = omit(invoice.totalAmount?.total, ['__typename']) ?? { amount: '0.00' }

  const initialCustomerEmail =
    invoice.customer?.billingContact?.detail?.email ?? invoice.customer?.mainContact?.detail?.email ?? ''

  const [id] = useState(uuidv4())
  const [amount, setAmount] = useState(totalAmount?.amount ?? '0.00')
  const [customerEmail, setCustomerEmail] = useState(initialCustomerEmail)
  const [sendCreditNote, setSendCreditNote] = useState(false)
  const [showErrors, setShowErrors] = useState(false)

  const validateAmount = (): string[] => {
    if (!amount) {
      return ['An amount is required']
    }
    try {
      const money = MoneyUtils.fromString(amount)
      const total = MoneyUtils.fromString(totalAmount?.amount ?? '0.00')
      if (money.greaterThan(total)) {
        return ['Refund cannot exceed invoice total']
      }
      return []
    } catch (err) {
      console.error(err)
      return ['Invalid amount value']
    }
  }

  const convertAmount = (value: string) => {
    try {
      const money = MoneyUtils.fromString(value)
      return money
    } catch (err) {
      console.error(err)
      return MoneyUtils.fromString('0.00')
    }
  }

  const onChangeAmount = (value: string) => setAmount(value)

  const [description, setDescription] = useState('Customer refund')
  const handleDescriptionChange = (e: any) => {
    setDescription(e.target.value)
  }

  const onBeginEditing = () => setShowErrors(false)

  const failed = (
    <div>
      <EuiCallOut size="s" color="danger" iconType="alert" title={error?.message}></EuiCallOut>
      <EuiSpacer />
    </div>
  )

  const form = (
    <EuiForm isInvalid={showErrors ? validateAmount().length > 0 : false} error={showErrors ? validateAmount() : []}>
      {error && failed}
      <EuiFormLabel>Invoice</EuiFormLabel>
      <EuiText>{invoice.number}</EuiText>
      <EuiHorizontalRule />

      <EuiFormLabel>Description</EuiFormLabel>
      <EuiFieldText value={description} onChange={handleDescriptionChange} />
      <EuiSpacer size="s" />
      <EuiFormLabel>Amount</EuiFormLabel>
      <EuiFieldNumber
        placeholder="Enter amount"
        value={amount === '0.00' ? '' : amount}
        onChange={(e) => onChangeAmount(e.target.value)}
        isInvalid={validateAmount().length > 0}
        style={{ width: '140px', textAlign: 'left' }}
        step="any"
        min={0}
        max={110000}
        onKeyDown={onBeginEditing}
      />
      <EuiSpacer size="s" />
      <EuiCheckbox
        id="send-credit-note"
        label="Send credit note email to customer"
        checked={sendCreditNote}
        onChange={(e: any) => setSendCreditNote(e.target.checked)}
        disabled={loading}
      />
      {sendCreditNote && <EuiFieldText value={customerEmail} onChange={(e) => setCustomerEmail(e.target.value)} />}
    </EuiForm>
  )

  const success = <OperationSuccess message={`Successfully created customer refund`} />

  const handleCreateCreditNote = () => {
    const jobId = invoice.job?.id
    if (!jobId) {
      return
    }
    if (validateAmount().length > 0) {
      setShowErrors(true)
      return
    }

    const money = convertAmount(amount)
    const moneyInput = MoneyUtils.moneyToObject(money)

    const creditLineItem = (): CreateLineItemInput => {
      return {
        name: description,
        type: InvoiceLineItemType.LineItem,
        amount: moneyInput
      }
    }

    const input: CreateCreditNoteInput = {
      id,
      job: jobId,
      invoice: invoice.id,
      lineItems: [creditLineItem()],
      discounts: [],
      fees: [],
      apply: CreditNoteApplicationType.Refund,
      sendCreditNote: sendCreditNote,
      customerEmail: customerEmail
    }
    return createCreditNote({ variables: { input } })
  }

  return (
    <EuiOverlayMask>
      <EuiModal onClose={closeModal}>
        <EuiModalHeader>
          <EuiModalHeaderTitle>Create Customer Refund</EuiModalHeaderTitle>
        </EuiModalHeader>
        <EuiModalBody>
          <div>{data && data.createCreditNote ? success : form}</div>
        </EuiModalBody>
        {!data ? (
          <EuiModalFooter>
            <EuiButtonEmpty onClick={closeModal} isDisabled={loading}>
              Cancel
            </EuiButtonEmpty>
            <EuiButton onClick={handleCreateCreditNote} fill isLoading={loading}>
              {loading ? 'Creating...' : 'Create Refund'}
            </EuiButton>
          </EuiModalFooter>
        ) : (
          <EuiButtonEmpty onClick={closeModal}>Done</EuiButtonEmpty>
        )}
      </EuiModal>
    </EuiOverlayMask>
  )
}
