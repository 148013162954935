import { useQuery } from '@apollo/client'
import {
  EuiContextMenu,
  EuiContextMenuPanelDescriptor,
  EuiContextMenuPanelItemDescriptor,
  EuiFlexGroup,
  EuiFlexItem,
  EuiLoadingSpinner
} from '@elastic/eui'
import { dateConfig } from '@fallonsolutions/date'
import { CustomerLinkFragment, ScheduleEventFragment } from '@fallonsolutions/types'
import { compact, includes } from 'lodash-es'
import { DateTime } from 'luxon'
import { useMemo } from 'react'
import {
  DeleteScheduleEventMutationVariables,
  DispatchScheduleEventMutationVariables,
  GetScheduleEventDetailDocument,
  JobType,
  ScheduleEventDetailFragment,
  ScheduleEventLockStatusType,
  ScheduleEventSource,
  ScheduleEventStatusType,
  ScheduleEventType,
  SendScheduleEventEmailMutationVariables,
  SendScheduleEventMessageMutationVariables,
  UpdateAppointmentJobClassificationMutationVariables,
  UpdateJobClassificationMutationVariables,
  UpdateScheduleEventConfirmationMutationVariables,
  UpdateScheduleEventNotesMutationVariables,
  UpdateScheduleEventStatusMutationVariables
} from '../../api/generated-types'
import { useAuthenticated } from '../../auth/authenticated-context'
import { isMobile } from '../../common/phone'
import { decamelise, labelOf } from '../../common/utils'
import '../../static/css/schedule-context-menu.css'
import { canUseFastBooking } from '../../workflow/workflow-new-enquiry-wrapper'
import { ScheduleEventContextMenuCancelFormPanel } from './schedule-event-context-menu-cancel-form-panel'
import { ScheduleEventContextMenuCopyEvent } from './schedule-event-context-menu-copy-event'
import { ScheduleEventContextMenuCopyToClipboard } from './schedule-event-context-menu-copy-to-clipboard'
import { ScheduleEventContextMenuCreateAppointmentPanel } from './schedule-event-context-menu-create-appointment-panel'
import { ScheduleEventContextMenuCutEvent } from './schedule-event-context-menu-cut-event'
import { ScheduleEventContextMenuJobTypePanel, getJobTypeIcon } from './schedule-event-context-menu-job-type-panel'
import { ScheduleEventContextMenuMessageCustomerPanel } from './schedule-event-context-menu-message-customer-panel'
import { ScheduleEventContextMenuStatusPanel, getStatusIcon } from './schedule-event-context-menu-status-panel'

export enum CreateNewJobAction {
  FollowOnProject = 'follow-on-project'
}

export interface CreateNewJobParams {
  customer: CustomerLinkFragment | undefined | null
  jobId: string | undefined | null
  customerLocationId: string | undefined | null
  contactId: string | undefined | null
  action: CreateNewJobAction
}

interface ScheduleEventContextMenuProps {
  event: ScheduleEventFragment
  confirmEvent: (params: { variables: UpdateScheduleEventConfirmationMutationVariables }) => void
  updateStatus: (params: { variables: UpdateScheduleEventStatusMutationVariables }) => void
  sendMessage: (params: { variables: SendScheduleEventMessageMutationVariables }) => void
  sendEmail: (params: { variables: SendScheduleEventEmailMutationVariables }) => void
  updateAppointmentJobClassification: (params: {
    variables: UpdateAppointmentJobClassificationMutationVariables
  }) => void
  updateJobClassification: (params: { variables: UpdateJobClassificationMutationVariables }) => void
  createNewJob?: (params: CreateNewJobParams) => void
  updateNotes: (params: { variables: UpdateScheduleEventNotesMutationVariables }) => void
  deleteEvent: (params: { variables: DeleteScheduleEventMutationVariables }) => void
  dispatchEvent: (params: { variables: DispatchScheduleEventMutationVariables }) => void
  onActionClick?: () => void
  onViewCardClick?: () => void
  cutEvent?: ScheduleEventFragment
  onCutEvent?: (event: ScheduleEventFragment) => void
  copyEvent?: ScheduleEventDetailFragment
  onCopyEvent?: (event: ScheduleEventDetailFragment) => void
  lockEvent?: (event: ScheduleEventFragment, status: ScheduleEventLockStatusType) => void
  canEdit?: boolean
  canMove?: boolean
  canCancelJob?: boolean
  canLock?: boolean
  canDispatchEvent?: boolean
}

// eslint-disable-next-line max-lines-per-function
export const ScheduleEventContextMenu = (props: ScheduleEventContextMenuProps) => {
  const {
    event,
    updateStatus,
    sendMessage,
    sendEmail,
    updateAppointmentJobClassification,
    updateJobClassification,
    dispatchEvent,
    deleteEvent,
    onActionClick,
    onViewCardClick,
    onCutEvent,
    cutEvent,
    canLock,
    createNewJob,
    copyEvent,
    onCopyEvent,
    lockEvent,
    canMove,
    canDispatchEvent
  } = props

  const { user, userFragment } = useAuthenticated()

  const from = useMemo(() => DateTime.fromISO(event.scheduled.from, { setZone: true }), [event.scheduled.from])
  const fromStr = useMemo(() => from.toFormat(dateConfig.luxonFormat.time), [from])

  const canEdit = useMemo(
    () => event.source === ScheduleEventSource.Platform && props.canEdit === true,
    [props.canEdit, event.source]
  )
  const canCreateNewBooking = useMemo(() => canUseFastBooking(userFragment) && !!event.job, [userFragment, event.job])
  // Fetch event detail on load for use in a few operations (eg. copy text)
  const { data, loading } = useQuery(GetScheduleEventDetailDocument, {
    variables: { input: { id: event.id } }
  })
  const eventDetail = useMemo(() => data?.getScheduleEvent.scheduleEvent, [data?.getScheduleEvent])

  const isPriority = useMemo(() => {
    return (
      event.type === ScheduleEventType.Priority1 ||
      event.type === ScheduleEventType.Priority2 ||
      event.type === ScheduleEventType.Priority3
    )
  }, [event.type])

  const canCreateAppointment = useMemo(
    () => (event.type === ScheduleEventType.ToBeAdvised || isPriority) && !event.appointmentId,
    [event.type, event.appointmentId, isPriority]
  )

  const contactHasMobile = useMemo(
    () =>
      compact([event.customerData?.mainContact?.phone, event.customerData?.mainContact?.alternatePhone]).filter(
        isMobile
      ).length > 0,
    [event.customerData?.mainContact?.phone, event.customerData?.mainContact?.alternatePhone]
  )

  const contactHasEmail = useMemo(
    () => event.customerData?.mainContact?.email && event.customerData?.mainContact?.email !== '',
    [event.customerData?.mainContact?.email]
  )

  const canMessageCustomer = useMemo(
    () => (contactHasMobile || contactHasEmail) && canDispatchEvent,
    [contactHasMobile, contactHasEmail, canDispatchEvent]
  )

  // Can archive any non-appointment events instantly. Appointments must be cancelled first with a reason
  const canDelete = useMemo(() => {
    switch (event.type) {
      case ScheduleEventType.Appointment:
        return canEdit && event.status?.status === ScheduleEventStatusType.Cancelled
      case ScheduleEventType.ToBeAdvised:
        return canEdit || event.createdBy?.id === user.id
      default:
        return canEdit
    }
  }, [canEdit, event.type, event.status, event.createdBy, user])

  const canChangeStatus = useMemo(() => {
    return (
      canEdit &&
      !isPriority &&
      event.type !== ScheduleEventType.ToBeAdvised &&
      (event.type !== ScheduleEventType.Appointment ||
        (event.type === ScheduleEventType.Appointment &&
          ![ScheduleEventStatusType.Cancelled, ScheduleEventStatusType.Aborted].includes(
            event.status?.status ?? ScheduleEventStatusType.Ready
          )))
    )
  }, [canEdit, event, isPriority])

  const canChangeJobType = event.jobType

  // Only makes sense to be able to cancel appointments
  // TODO: should it be possible to cancel a completed event?
  const canCancel = useMemo(() => {
    return (
      props.canCancelJob &&
      event.type === ScheduleEventType.Appointment &&
      !includes([ScheduleEventStatusType.Cancelled, ScheduleEventStatusType.Aborted], event.status?.status)
    )
  }, [props.canCancelJob, event])

  const canDispatch = useMemo(() => {
    return (
      canDispatchEvent &&
      event.source === ScheduleEventSource.Platform &&
      includes([ScheduleEventStatusType.Pending, ScheduleEventStatusType.Dispatched], event.status?.status) &&
      event.type !== ScheduleEventType.ToBeAdvised &&
      !isPriority &&
      !!eventDetail?.lane?.technician?.id
    )
  }, [canDispatchEvent, event, eventDetail])

  const canCutEvent = useMemo(() => {
    return canMove && !(copyEvent || cutEvent) && event.type === ScheduleEventType.Appointment
  }, [canMove, event.type, copyEvent, cutEvent])

  const canCopyEvent = canMove

  // Top level items
  const openCardItem: EuiContextMenuPanelItemDescriptor = {
    name: 'Open event card',
    icon: 'inspect',
    onClick: () => onViewCardClick && onViewCardClick()
  }
  const createAppointmentItem: EuiContextMenuPanelItemDescriptor = {
    name: 'Create appointment',
    icon: 'calendar',
    panel: 'create-appointment'
  }
  const jobTypeItem: EuiContextMenuPanelItemDescriptor = {
    name: (
      <EuiFlexGroup alignItems="center" justifyContent="center">
        <EuiFlexItem grow={true}>{event.jobType}</EuiFlexItem>
        <EuiFlexItem grow={false}>
          <span className="small-label flat">CHANGE</span>
        </EuiFlexItem>
      </EuiFlexGroup>
    ),
    icon: getJobTypeIcon(event.jobType ?? JobType.None),
    panel: 'change-job-type',
    disabled: !canEdit
  }
  const statusItem: EuiContextMenuPanelItemDescriptor = useMemo(
    () => ({
      name: (
        <EuiFlexGroup alignItems="center">
          <EuiFlexItem grow={true}>{labelOf(event.status?.status)}</EuiFlexItem>
          <EuiFlexItem grow={false}>
            <span className="small-label flat">CHANGE</span>
          </EuiFlexItem>
        </EuiFlexGroup>
      ),
      icon: getStatusIcon(event.status?.status),
      panel: 'change-status',
      disabled: !canEdit || !eventDetail || eventDetail?.availableOfficeStatuses?.length === 0
    }),
    [canEdit, event.status?.status, eventDetail]
  )

  const dispatchItem: EuiContextMenuPanelItemDescriptor = useMemo(
    () => ({
      name: `${event.status?.status === ScheduleEventStatusType.Dispatched ? 'Resend' : 'Dispatch'} to ${
        event?.technician?.contactDetail?.firstName ?? 'technician'
      }`,
      icon: 'push',
      onClick: () => {
        dispatchEvent({ variables: { input: { id: event.id } } })
        onActionClick && onActionClick()
      },
      disabled: !canDispatch
    }),
    [event, dispatchEvent, onActionClick, canDispatch]
  )

  const cancelItem: EuiContextMenuPanelItemDescriptor = {
    name: `Cancel appointment`,
    panel: 'cancel-form',
    icon: 'cross',
    disabled: loading
  }

  const createNewJobPanel: EuiContextMenuPanelDescriptor = {
    id: 'create-another-job',
    title: 'Create new job',
    items: [
      {
        name: 'Create new follow-on job (linked)',
        icon: 'wrench',
        disabled: loading || !eventDetail,
        onClick: () => {
          createNewJob &&
            createNewJob({
              action: CreateNewJobAction.FollowOnProject,
              customer: eventDetail?.job?.customer,
              contactId: eventDetail?.customerData?.mainContact?.id,
              jobId: eventDetail?.job?.id,
              customerLocationId: eventDetail?.job?.customerLocationId
            })
          onActionClick && onActionClick()
        }
      },
      {
        name: 'Create new customer job (not linked)',
        icon: 'wrench',
        disabled: loading || !eventDetail,
        onClick: () => {
          createNewJob &&
            createNewJob({
              action: CreateNewJobAction.FollowOnProject,
              customer: eventDetail?.job?.customer,
              contactId: eventDetail?.customerData?.mainContact?.id,
              jobId: undefined,
              customerLocationId: eventDetail?.job?.customerLocationId
            })
          onActionClick && onActionClick()
        }
      }
    ]
  }

  const lockItem: EuiContextMenuPanelItemDescriptor = {
    name: `${event.lockStatus?.status === ScheduleEventLockStatusType.Locked ? 'Unlock' : 'Lock'} event`,
    icon: 'lock',
    disabled: loading || canLock !== true,
    onClick: () => {
      const status: ScheduleEventLockStatusType =
        event.lockStatus?.status === ScheduleEventLockStatusType.Locked
          ? ScheduleEventLockStatusType.Unlocked
          : ScheduleEventLockStatusType.Locked
      lockEvent && lockEvent(event, status)
      onActionClick && onActionClick()
    }
  }

  const deleteItem: EuiContextMenuPanelItemDescriptor = {
    name: `Delete event`,
    icon: 'trash',
    onClick: () => {
      deleteEvent({ variables: { input: { id: event.id, tenant: event.tenant } } })
      onActionClick && onActionClick()
    }
  }
  const messageCustomerItem: EuiContextMenuPanelItemDescriptor = {
    name: (
      <EuiFlexGroup alignItems="center">
        <EuiFlexItem grow={true}>Message customer</EuiFlexItem>
        <EuiFlexItem grow={false}>{loading && <EuiLoadingSpinner />}</EuiFlexItem>
      </EuiFlexGroup>
    ),
    icon: 'discuss',
    panel: 'message-customer',
    disabled: !canMessageCustomer || !eventDetail || loading
  }

  // Panels
  const statusPanel = ScheduleEventContextMenuStatusPanel({
    event,
    updateStatus,
    onActionClick,
    eventDetail: eventDetail ?? undefined
  })
  const jobTypePanel = ScheduleEventContextMenuJobTypePanel({
    event,
    updateAppointmentJobClassification,
    updateJobClassification,
    onActionClick
  })
  const cancelPanel = ScheduleEventContextMenuCancelFormPanel({
    event,
    loading,
    eventDetail: eventDetail ?? undefined,
    onActionClick
  })
  const createAppointmentPanel = ScheduleEventContextMenuCreateAppointmentPanel({
    event,
    onActionClick
  })

  const messageCustomerPanels = ScheduleEventContextMenuMessageCustomerPanel({
    event,
    eventDetail: eventDetail ?? undefined,
    sendMessage,
    sendEmail,
    onActionClick
  })

  const panels: EuiContextMenuPanelDescriptor[] = [
    {
      id: 0,
      size: 's',
      title: `${decamelise(event.type)} at ${fromStr}`,
      items: [
        ...(onViewCardClick ? [openCardItem] : []),
        {
          isSeparator: true,
          key: 'sep1'
        },
        ...(canCreateAppointment ? [createAppointmentItem] : []),
        ScheduleEventContextMenuCopyToClipboard({
          event,
          eventDetail: eventDetail ?? undefined,
          loading,
          onActionClick
        }),
        ...(canDispatch ? [dispatchItem] : []),
        ...(canChangeStatus ? [statusItem] : []),
        ...(canChangeJobType ? [jobTypeItem] : []),
        {
          isSeparator: true,
          key: 'sep'
        },
        messageCustomerItem,
        {
          isSeparator: true,
          key: 'sep2'
        },
        ...(canCutEvent ? [ScheduleEventContextMenuCutEvent({ event, onCutEvent, onActionClick })] : []),
        ...(canCopyEvent
          ? [
              ScheduleEventContextMenuCopyEvent({
                event,
                eventDetail: eventDetail ?? undefined,
                cutEvent,
                copyEvent,
                loading,
                onActionClick,
                onCopyEvent
              })
            ]
          : []),
        {
          isSeparator: true,
          key: 'sep3'
        },
        {
          name: 'Create another job',
          icon: 'wrench',
          panel: 'create-another-job',
          disabled: !canCreateNewBooking
        },
        {
          isSeparator: true,
          key: 'sep4'
        },
        ...(canCancel ? [cancelItem] : []),
        ...(canDelete ? [deleteItem] : []),
        ...(canLock ? [lockItem] : [])
      ]
    },
    statusPanel,
    jobTypePanel,
    createNewJobPanel,
    cancelPanel,
    createAppointmentPanel,
    ...messageCustomerPanels
  ]

  return (
    <div key={`schedule-context-menu|${event.id}|${loading}`}>
      <EuiContextMenu initialPanelId={0} panels={panels} />
    </div>
  )
}
