import { EuiButton, EuiSpacer } from '@elastic/eui'
import { JobType, TradeType } from '../../../api/generated-types'
import { QuestionDefinitions, getNotesGenerator } from '../../helpers/notes-generator'
import { getResultUpdater } from '../../helpers/results-updater'
import { FreeTextQuestionField } from '../../question-fields/free-text-question-field'
import { WorkflowActionProps } from '../../workflow-model'
import { TopicActionInput, TopicActionResult } from '../action-topic-model'
import { TopicWorkflowAction } from '../action-topic-view'

export interface TopicPlumbingWaterEfficiencyCertificatesActionResult extends TopicActionResult {
  reasonsForNeedingCertificate?: string
  numberOfStoreys?: string
  numberOfHouseAreas?: string
  canCertificateBeEmailedOut?: string
}

const questionDefinitions: QuestionDefinitions<TopicPlumbingWaterEfficiencyCertificatesActionResult> = {
  reasonsForNeedingCertificate: {
    question: 'What is the reason for needing the certificate?'
  },
  numberOfStoreys: {
    question: 'Single or double storey home?'
  },
  numberOfHouseAreas: {
    question: 'How many bathrooms, laundry, kitchen/kitchenette areas?'
  },
  canCertificateBeEmailedOut: {
    question: 'Would you like the water efficiency certificate to be emailed out?'
  }
}
const notesGenerator = getNotesGenerator(questionDefinitions)

export const TopicPlumbingWaterEfficiencyCertificatesAction = (
  props: WorkflowActionProps<TopicActionInput, TopicPlumbingWaterEfficiencyCertificatesActionResult>
) => {
  const { result, input, onUpdate } = props
  const { reasonsForNeedingCertificate, numberOfStoreys, numberOfHouseAreas, canCertificateBeEmailedOut } = result ?? {}

  const onNext = () =>
    onUpdate({
      ...result,
      actionCompleted: true,
      trade: TradeType.Plumbing,
      category: 'WaterEfficiencyCertificate',
      type: JobType.Service,

      workRequiredNotes: generatedNotes,
      requirements: {
        attributes: [{ attributeId: 'GeneralPlumbing' }]
      }
    })

  const generatedNotes = notesGenerator.generateNotes(result ?? {})
  const updateResult = getResultUpdater(result ?? {}, onUpdate).updateResult

  const canComplete =
    !!reasonsForNeedingCertificate && !!numberOfStoreys && !!numberOfHouseAreas && !!canCertificateBeEmailedOut

  return (
    <TopicWorkflowAction input={input} onUpdate={onUpdate} result={result}>
      <FreeTextQuestionField
        question={notesGenerator.getQuestion('reasonsForNeedingCertificate')}
        answer={reasonsForNeedingCertificate}
        rows={3}
        changeAnswer={(reasonsForNeedingCertificate) => updateResult({ reasonsForNeedingCertificate })}
      />
      <FreeTextQuestionField
        question={notesGenerator.getQuestion('numberOfStoreys')}
        answer={numberOfStoreys}
        changeAnswer={(numberOfStoreys) => updateResult({ numberOfStoreys })}
      />
      <FreeTextQuestionField
        question={notesGenerator.getQuestion('numberOfHouseAreas')}
        answer={numberOfHouseAreas}
        changeAnswer={(numberOfHouseAreas) => updateResult({ numberOfHouseAreas })}
      />
      <FreeTextQuestionField
        question={notesGenerator.getQuestion('canCertificateBeEmailedOut')}
        answer={canCertificateBeEmailedOut}
        changeAnswer={(canCertificateBeEmailedOut) => updateResult({ canCertificateBeEmailedOut })}
      />

      <EuiSpacer />
      <EuiButton disabled={!canComplete} onClick={() => onNext()}>
        Next
      </EuiButton>
    </TopicWorkflowAction>
  )
}
