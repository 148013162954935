import { useQuery } from '@apollo/client'
import { EuiButtonEmpty, EuiComboBox, EuiComboBoxOptionOption } from '@elastic/eui'
import { useCallback, useEffect, useState } from 'react'
import { useDebouncedValue } from 'rooks'
import { PhoneNumberLinkFragment, SearchPhoneNumbersDocument, SearchPhoneNumbersInput } from '../api/generated-types'
import { formatPhone } from '../common/phone'
import '../static/css/combo-box.css'

export interface PhoneNumberComboBoxProps {
  value: EuiComboBoxOptionOption<string>[]
  onChange: (phoneNumbers: EuiComboBoxOptionOption<string>[]) => void
  singleSelection?: boolean
  initialEnabled?: boolean
  disabled?: boolean
  label?: string
}

export const PhoneNumberComboBox = (props: PhoneNumberComboBoxProps) => {
  const { value, onChange } = props
  const singleSelection = props.singleSelection ?? false
  const disabled = props.disabled ?? false
  const [term, setTerm] = useState('')
  const [enabled, setEnabled] = useState(props.initialEnabled ?? value.length > 0)
  const [comboInput, setComboInput] = useState<HTMLInputElement | null>(null)

  const label = props.label ?? 'Phone number'

  const [debouncedTerm] = useDebouncedValue(term, 150)

  const input: SearchPhoneNumbersInput = {
    filters: {
      ...(debouncedTerm && debouncedTerm.length > 0 && { query: debouncedTerm })
    },
    from: 0,
    size: 10
  }

  const variables = { input }
  const { data, loading, error } = useQuery(SearchPhoneNumbersDocument, {
    variables
  })

  const results = data?.searchPhoneNumbers?.results ?? []

  const options = (results ?? []).map((phoneNumber: PhoneNumberLinkFragment) => {
    return {
      id: phoneNumber.id,
      label: formatPhone(phoneNumber.number)
    }
  })

  if (error) {
    console.error('error', error)
  }

  const onSearchChange = useCallback((searchValue: string) => setTerm(searchValue), [])

  // Simulate initial load
  useEffect(() => onSearchChange(''), [onSearchChange])

  const onAddFilter = () => {
    setEnabled(true)
    setTimeout(() => comboInput?.focus(), 50)
  }

  const onBlur = () => value.length <= 0 && setEnabled(false)

  return (
    <form autoComplete="off">
      <EuiComboBox
        async
        inputRef={setComboInput}
        placeholder={label}
        options={options}
        selectedOptions={value}
        onChange={onChange}
        isClearable={true}
        rowHeight={40}
        isLoading={loading}
        isDisabled={disabled}
        onSearchChange={onSearchChange}
        style={{ minWidth: '300px' }}
        onBlur={onBlur}
        hidden={!enabled}
        aria-label={label}
        data-test-subj={label}
        singleSelection={singleSelection}
      />
      {!enabled && (
        <EuiButtonEmpty iconType="filter" flush="both" onClick={onAddFilter}>
          {label}
        </EuiButtonEmpty>
      )}
    </form>
  )
}
