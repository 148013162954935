import { EuiButton, EuiSpacer } from '@elastic/eui'
import { CustomerType, EnquiryAbortReason } from '../../../api/generated-types'
import { Callout } from '../../../common/callout'
import { getResultUpdater } from '../../helpers/results-updater'
import { useWorkflow } from '../../helpers/workflow-provider'
import { FreeTextQuestionField } from '../../question-fields/free-text-question-field'
import { SingleSelectField } from '../../question-fields/single-select-question-field'
import { WorkflowActionProps } from '../../workflow-model'
import { ServiceType, TopicActionInput } from '../action-topic-model'
import { TopicWorkflowAction } from '../action-topic-view'
import {
  ApplianceAgeEnum,
  WARRANTY_SCRIPT,
  applianceAgeOptions,
  canProceedWithRepair,
  serviceTypeOptions
} from '../appliance-shared/appliance-model'
import { yesNoOptions } from '../common/common-options'
import { TopicQualification } from '../common/common-outcome'
import {
  CoffeeMachineIssueEnum,
  TopicCoffeeMachineActionResult,
  coffeeMachineIssueOptions,
  coffeeMachineNotesGenerator
} from './action-topic-coffee-machine-model'
import { outcomeGenerator } from './action-topic-coffee-machine-outcome'

export const TopicCoffeeMachineAction = (
  props: WorkflowActionProps<TopicActionInput, TopicCoffeeMachineActionResult>
) => {
  const { result, input, onUpdate } = props
  const {
    applianceIssue,
    outletWorksWithOtherDevice,
    serviceType,
    applianceAge,
    applianceBrand,
    installationLocation,
    brandNew,
    mayVoidWarrantyConfirmationToProceed
  } = result ?? {}
  const workflowContext = useWorkflow()
  const notesGenerator = coffeeMachineNotesGenerator

  const topicOutcome = outcomeGenerator(result)
  const { requirements, qualification } = topicOutcome
  const isOutOfScope = qualification === TopicQualification.OutOfScope

  const onNext = () =>
    onUpdate({
      ...result,
      ...topicOutcome,
      actionCompleted: true,
      workRequiredNotes: generatedNotes
    })

  const generatedNotes = notesGenerator.generateNotes(result ?? {})
  const updateResult = getResultUpdater(result ?? {}, onUpdate).updateResult

  const repairFlow = !!serviceType && serviceType === ServiceType.Repair && canProceedWithRepair(result)
  const canComplete = !!requirements

  return (
    <TopicWorkflowAction input={input} onUpdate={onUpdate} result={result}>
      <SingleSelectField
        question={notesGenerator.getQuestion('serviceType')}
        options={serviceTypeOptions}
        answer={serviceType}
        changeAnswer={(serviceType) => updateResult({ serviceType })}
      />
      <EuiSpacer size="m" />

      {serviceType === ServiceType.Installation && result?.customerType === CustomerType.Commercial && (
        <>
          <FreeTextQuestionField
            question={notesGenerator.getQuestion('applianceBrand')}
            answer={applianceBrand}
            changeAnswer={(applianceBrand) => updateResult({ applianceBrand })}
          />
          <FreeTextQuestionField
            question={notesGenerator.getQuestion('installationLocation')}
            answer={installationLocation}
            changeAnswer={(installationLocation) => updateResult({ installationLocation })}
          />
          <SingleSelectField
            question={notesGenerator.getQuestion('brandNew')}
            options={yesNoOptions}
            answer={brandNew}
            changeAnswer={(brandNew) => updateResult({ brandNew })}
          />
        </>
      )}

      {serviceType === ServiceType.Repair && (
        <>
          <SingleSelectField
            question={notesGenerator.getQuestion('applianceAge')}
            options={applianceAgeOptions}
            answer={applianceAge}
            changeAnswer={(applianceAge) => updateResult({ applianceAge })}
          />
          <EuiSpacer size="m" />
          {applianceAge === ApplianceAgeEnum.LessThan2yrsOld && (
            <>
              <Callout type="script" title={WARRANTY_SCRIPT} />
              <SingleSelectField
                question={notesGenerator.getQuestion('mayVoidWarrantyConfirmationToProceed')}
                options={yesNoOptions}
                answer={mayVoidWarrantyConfirmationToProceed}
                changeAnswer={(mayVoidWarrantyConfirmationToProceed) =>
                  updateResult({ mayVoidWarrantyConfirmationToProceed })
                }
              />
            </>
          )}
          {repairFlow && (
            <>
              <SingleSelectField
                question={notesGenerator.getQuestion('applianceIssue')}
                options={coffeeMachineIssueOptions}
                answer={applianceIssue}
                changeAnswer={(applianceIssue) => updateResult({ applianceIssue })}
              />

              <EuiSpacer size="m" />
              {applianceIssue === CoffeeMachineIssueEnum.PowerSupply && (
                <SingleSelectField
                  question={notesGenerator.getQuestion('outletWorksWithOtherDevice')}
                  answer={outletWorksWithOtherDevice}
                  options={yesNoOptions}
                  changeAnswer={(outletWorksWithOtherDevice) => updateResult({ outletWorksWithOtherDevice })}
                />
              )}
            </>
          )}
        </>
      )}

      {requirements && requirements.attributes && (
        <>
          <EuiSpacer />
          <Callout type="note">
            Book a technician with {requirements.attributes?.map((r) => r.attributeId).join(', ')} skills
          </Callout>
        </>
      )}

      {!isOutOfScope && (
        <>
          <EuiSpacer />
          <EuiButton disabled={!canComplete} onClick={() => onNext()}>
            Next
          </EuiButton>
        </>
      )}
      {isOutOfScope && (
        <>
          <Callout type="script">
            {topicOutcome.qualificationMessage
              ? topicOutcome.qualificationMessage
              : 'Unfortunately this type of work is out of scope for us.'}
          </Callout>
          <EuiSpacer />
          <EuiButton
            color="warning"
            onClick={() =>
              workflowContext.abort({
                abortReason: EnquiryAbortReason.CustomerNeedsAreOutOfService,
                notes: `Customer was enquiring about: ${topicOutcome.qualificationMessage}`
              })
            }
          >
            Offer a voucher and end call
          </EuiButton>
        </>
      )}
    </TopicWorkflowAction>
  )
}
