import { EuiButton, EuiSpacer } from '@elastic/eui'
import { AppointmentRequirements } from '@fallonsolutions/appointment'
import { EnquiryAbortReason, PriorityType } from '../../../api/generated-types'
import { Callout } from '../../../common/callout'
import { makeHumanReadable } from '../../../common/utils'
import { NotesGenerator } from '../../helpers/notes-generator'
import { OutOfScope } from '../../helpers/out-of-topic'
import { ResultUpdater } from '../../helpers/results-updater'
import { IWorkflowProvidedContext, useWorkflow } from '../../helpers/workflow-provider'
import { FreeTextQuestionField } from '../../question-fields/free-text-question-field'
import { SingleSelectField } from '../../question-fields/single-select-question-field'
import { YesNoValue } from '../common/common-enums'
import { yesNoOptions } from '../common/common-options'
import { reset } from '../common/common-utils'
import { TopicPlumbingDrainInput } from './action-topic-plumbing-drain'
import {
  SewerIssue,
  SewerItemAffected,
  TopicPlumbingDrainActionResult,
  sewerIssueOptions,
  sewerItemAffectedOptions
} from './action-topic-plumbing-drain-model'
import { ChangeJobTopicToRegularDrains } from './change-topic-to-drains'

export interface DrainSewerProps {
  result?: TopicPlumbingDrainActionResult
  notesGenerator: NotesGenerator<TopicPlumbingDrainActionResult>
  updateResult: ResultUpdater<TopicPlumbingDrainActionResult>
  requirements?: AppointmentRequirements
  input?: TopicPlumbingDrainInput
}

export const DrainSewer = (props: DrainSewerProps) => {
  const { result, notesGenerator, updateResult, requirements, input } = props
  const { drainClearSpecial: isSpecial } = input ?? {}
  const {
    jobDescription,
    customerType,
    drainIssue,
    issueDescription,
    haveCouncilPlans,
    blockedPrior,
    causeNote,
    itemAffected,
    anotherToilet,
    dsrConfirmInScope,
    serviceType,
    overflowOrLeakedPrior,
    pumpedOutRecently,
    otherBlockages,
    otherIssueDescription,
    otherIssueIsInScope
  } = result ?? {}
  const workflowContext = useWorkflow()
  const questionsToRemove = [
    SewerItemAffected.OverflowingInYard,
    SewerItemAffected.OverflowingInspectionPoint,
    SewerItemAffected.SewerInYard,
    SewerItemAffected.LeakingSewer,
    ...(isSpecial ? [SewerItemAffected.SepticSystem, SewerItemAffected.GreaseTrap] : [])
  ]

  const sewerIssuesNotAcceptedIn99DrainsSpecial = [
    SewerIssue.CappingOff,
    SewerIssue.Reline,
    SewerIssue.Repair,
    SewerIssue.Other
  ]

  const specialBlockedOrOverflowingQuestionsToRemove = [
    SewerItemAffected.LeakingSewer,
    SewerItemAffected.GreaseTrap,
    SewerItemAffected.SepticSystem
  ]

  return (
    <>
      <SingleSelectField
        question={notesGenerator.getQuestion('drainIssue')}
        options={sewerIssueOptions}
        answer={drainIssue}
        changeAnswer={(drainIssue) => {
          updateResult({ drainIssue, issueDescription: '' })
        }}
      />
      {drainIssue && sewerIssuesNotAcceptedIn99DrainsSpecial.includes(drainIssue as SewerIssue) && isSpecial ? (
        <>
          <>
            <ChangeJobTopicToRegularDrains
              message={`Regrettably, this particular offer does not cover ${makeHumanReadable(
                drainIssue
              )} issues, would you like to book a job for your issue under our regular pricing?`}
              handleJobTopicChanged={input?.handleChangeJobTopic}
              results={result}
            />
          </>
        </>
      ) : (
        <>
          {(drainIssue === SewerIssue.Reline ||
            drainIssue === SewerIssue.Repair ||
            drainIssue === SewerIssue.Replace) && (
            <>
              <FreeTextQuestionField
                question={notesGenerator.getQuestion('issueDescription')}
                answer={issueDescription}
                rows={3}
                changeAnswer={(issueDescription) => updateResult({ issueDescription })}
              />
            </>
          )}
          {drainIssue === SewerIssue.CappingOff && (
            <>
              <SingleSelectField
                question={notesGenerator.getQuestion('haveCouncilPlans')}
                answer={haveCouncilPlans}
                changeAnswer={(haveCouncilPlans) => updateResult({ haveCouncilPlans })}
                options={yesNoOptions}
              />
              {haveCouncilPlans === YesNoValue.No && (
                <>
                  <Callout type="warning" title="Temporarily Out of Scope" />
                  <Callout type="script">Unfortunately this type of work is out of scope for us.</Callout>
                  <EuiSpacer />
                  <EuiButton
                    color="warning"
                    onClick={() =>
                      workflowContext.abort({
                        abortReason: EnquiryAbortReason.CustomerNeedsAreOutOfService,
                        notes: `Customer was enquiring about : ${drainIssue} without council plans.`
                      })
                    }
                  >
                    Offer a voucher and end call
                  </EuiButton>
                </>
              )}
            </>
          )}
          {drainIssue === SewerIssue.BlockedOrOverflowing && (
            <>
              <BlockedOrWaitingQuestions
                includeP2Question={true}
                notesGenerator={notesGenerator}
                result={result}
                updateResult={updateResult}
                workflowContext={workflowContext}
                requirements={requirements}
                initialQuestion={'itemAffected'}
                questionsToRemove={isSpecial ? specialBlockedOrOverflowingQuestionsToRemove : []}
              />
            </>
          )}
          {drainIssue === SewerIssue.SmellySewer && (
            <>
              <BlockedOrWaitingQuestions
                includeP2Question={false}
                notesGenerator={notesGenerator}
                result={result}
                updateResult={updateResult}
                workflowContext={workflowContext}
                requirements={requirements}
                questionsToRemove={questionsToRemove}
                initialQuestion="smellySewerAffected"
              />
            </>
          )}
          {drainIssue === SewerIssue.SlowDraining && (
            <>
              <BlockedOrWaitingQuestions
                includeP2Question={true}
                notesGenerator={notesGenerator}
                result={result}
                updateResult={updateResult}
                workflowContext={workflowContext}
                requirements={requirements}
                questionsToRemove={questionsToRemove}
                removeBlockedInOtherPlacesQuestionForOptions={[
                  SewerItemAffected.SepticSystem,
                  SewerItemAffected.GreaseTrap
                ]}
                initialQuestion="slowDrainAffected"
              />
            </>
          )}
          {drainIssue === SewerIssue.Other && (
            <>
              <FreeTextQuestionField
                question={notesGenerator.getQuestion('otherIssueDescription')}
                answer={otherIssueDescription}
                rows={3}
                changeAnswer={(otherIssueDescription) => updateResult({ otherIssueDescription })}
              />
              {!!otherIssueDescription && (
                <>
                  <Callout type="warning" title="Please contact DSR for scope booking" />
                  <SingleSelectField
                    question={notesGenerator.getQuestion('otherIssueIsInScope')}
                    options={yesNoOptions}
                    answer={otherIssueIsInScope}
                    changeAnswer={(otherIssueIsInScope) => {
                      updateResult({ otherIssueIsInScope })
                    }}
                  />
                  <OutOfScope
                    issue="Drains|Sewer|Other"
                    isOutOfScope={otherIssueIsInScope === YesNoValue.No}
                    reason="Sorry but your job is out of scope for us"
                  />
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  )
}

interface BlockedOrWaitingQuestionsProps {
  includeP2Question?: boolean
  notesGenerator: NotesGenerator<TopicPlumbingDrainActionResult>
  result?: TopicPlumbingDrainActionResult
  updateResult: ResultUpdater<TopicPlumbingDrainActionResult>
  workflowContext: IWorkflowProvidedContext
  requirements?: AppointmentRequirements
  questionsToRemove?: string[]
  removeBlockedInOtherPlacesQuestionForOptions?: SewerItemAffected[]
  initialQuestion: keyof TopicPlumbingDrainActionResult // used to make sure the correct question and statement is shown
}

const BlockedOrWaitingQuestions = (props: BlockedOrWaitingQuestionsProps) => {
  const {
    includeP2Question,
    notesGenerator,
    result,
    updateResult,
    workflowContext,
    requirements,
    questionsToRemove,
    removeBlockedInOtherPlacesQuestionForOptions,
    initialQuestion
  } = props
  const {
    jobDescription,
    customerType,
    drainIssue,
    issueDescription,
    haveCouncilPlans,
    blockedPrior,
    causeNote,
    itemAffected,
    anotherToilet,
    dsrConfirmInScope,
    serviceType,
    overflowOrLeakedPrior,
    pumpedOutRecently,
    otherBlockages,
    smellySewerAffected,
    slowDrainAffected
  } = result ?? {}
  const dontShowP2Question =
    !!removeBlockedInOtherPlacesQuestionForOptions && !!itemAffected
      ? removeBlockedInOtherPlacesQuestionForOptions?.includes(itemAffected)
      : false
  const askP2FollowUp =
    (requirements?.priority?.required === PriorityType.P2 || !!otherBlockages) &&
    !!includeP2Question &&
    !dontShowP2Question
  const questions = sewerItemAffectedOptions.filter((option) => !questionsToRemove?.includes(option.id))
  const isOutsideIssue =
    !!itemAffected &&
    [
      SewerItemAffected.SewerInYard,
      SewerItemAffected.OverflowingInYard,
      SewerItemAffected.OverflowingInspectionPoint,
      SewerItemAffected.SewerInYard,
      SewerItemAffected.LeakingSewer
    ].includes(itemAffected)
  const getItemAffected = () => {
    switch (initialQuestion) {
      case 'itemAffected':
        return itemAffected
      case 'smellySewerAffected':
        return smellySewerAffected
      case 'slowDrainAffected':
        return slowDrainAffected
    }
  }
  const affectedItem = getItemAffected()
  return (
    <>
      <SingleSelectField
        question={notesGenerator.getQuestion(initialQuestion)}
        options={questions}
        answer={affectedItem}
        changeAnswer={(itemAffected) =>
          updateResult({
            ...reset(result ?? {}),
            customerType,
            jobDescription,
            serviceType,
            drainIssue,
            [initialQuestion]: itemAffected
          })
        }
      />
      {(affectedItem === SewerItemAffected.Toilet || affectedItem === SewerItemAffected.WholeHouse) && (
        <>
          <SingleSelectField
            question={notesGenerator.getQuestion('blockedPrior')}
            options={yesNoOptions}
            answer={blockedPrior}
            changeAnswer={(blockedPrior) => {
              updateResult({ blockedPrior })
            }}
          />
          {blockedPrior === YesNoValue.Yes && (
            <FreeTextQuestionField
              question={notesGenerator.getQuestion('causeNote')}
              answer={causeNote}
              rows={3}
              changeAnswer={(causeNote) => updateResult({ causeNote })}
            />
          )}
          <SingleSelectField
            question={notesGenerator.getQuestion('anotherToilet')}
            options={yesNoOptions}
            answer={anotherToilet}
            changeAnswer={(anotherToilet) => {
              updateResult({ anotherToilet })
            }}
          />
        </>
      )}
      {(affectedItem === SewerItemAffected.GreaseTrap || affectedItem === SewerItemAffected.SepticSystem) && (
        <>
          <SingleSelectField
            question={notesGenerator.getQuestion('pumpedOutRecently')}
            answer={pumpedOutRecently}
            options={yesNoOptions}
            changeAnswer={(pumpedOutRecently) => updateResult({ pumpedOutRecently })}
          />
          {pumpedOutRecently === YesNoValue.No && (
            <>
              <Callout type="warning" title="Out of Scope" />
              <Callout type="script">Unfortunately this type of work is out of scope for us.</Callout>
              <EuiSpacer />
              <EuiButton
                color="warning"
                onClick={() =>
                  workflowContext.abort({
                    abortReason: EnquiryAbortReason.CustomerNeedsAreOutOfService,
                    notes: `Customer was enquiring about sewer drain: ${drainIssue} ${itemAffected}`
                  })
                }
              >
                Offer a voucher and end call
              </EuiButton>
            </>
          )}
        </>
      )}

      {askP2FollowUp && (
        <>
          <SingleSelectField
            question={notesGenerator.getQuestion('otherBlockages')}
            options={yesNoOptions}
            answer={otherBlockages}
            changeAnswer={(otherBlockages) => {
              updateResult({ otherBlockages })
            }}
          />
        </>
      )}
      {isOutsideIssue && (
        <>
          <SingleSelectField
            question={notesGenerator.getQuestion('overflowOrLeakedPrior')}
            answer={overflowOrLeakedPrior}
            options={yesNoOptions}
            changeAnswer={(overflowOrLeakedPrior) => updateResult({ overflowOrLeakedPrior })}
          />
          {overflowOrLeakedPrior === YesNoValue.Yes && (
            <FreeTextQuestionField
              question={notesGenerator.getQuestion('causeNote')}
              answer={causeNote}
              rows={3}
              changeAnswer={(causeNote) => updateResult({ causeNote })}
            />
          )}
        </>
      )}
    </>
  )
}
