import { EuiAccordion, EuiBasicTable, EuiBasicTableColumn, EuiSpacer } from '@elastic/eui'
import { Address, CustomerLocationFragment, CustomerLocationStatus } from '../../../api/generated-types'
import { CustomerLocationStatusBadge } from '../../../customer-locations/customer-location-status-badge'

type Location = CustomerLocationFragment
export interface PreviousCustomerLocationsAccordionProps {
  previousLocations: Location[]
  onChangeLocationStatus: (location: Location) => void
}
export const PreviousCustomerLocationsAccordion = (props: PreviousCustomerLocationsAccordionProps) => {
  const { previousLocations, onChangeLocationStatus } = props
  return (
    <>
      {previousLocations.length > 0 && (
        <>
          <EuiAccordion id="show-previous-properties" buttonContent="Show previous properties">
            <EuiSpacer size="l" />
            <EuiBasicTable
              items={previousLocations}
              columns={BouncingBallPreviousCustomerLocationsColumns({ changeLocationStatus: onChangeLocationStatus })}
            />
            <EuiSpacer size="l" />
          </EuiAccordion>
          <EuiSpacer size="l" />
        </>
      )}
    </>
  )
}

interface BouncingBallPreviousCustomerLocationsColumnsProps {
  changeLocationStatus: (location: Location) => void
}
const BouncingBallPreviousCustomerLocationsColumns = ({
  changeLocationStatus
}: BouncingBallPreviousCustomerLocationsColumnsProps): Array<EuiBasicTableColumn<Location>> => {
  return [
    {
      field: 'address',
      name: 'Street',
      width: '150px',
      sortable: true,
      render: (address: Address | undefined, location: Location) => {
        if (address) {
          return (
            <a href={`/properties/${location.property.id}`}>
              {[address.street, address.street2].filter((n) => !!n).join(', ')}
            </a>
          )
        }
        return '-'
      }
    },
    {
      field: 'formattedAddress',
      name: 'Full address'
    },
    {
      field: 'address.suburb',
      name: 'Suburb',
      sortable: true
    },
    {
      field: 'address.postcode',
      name: 'Postcode',
      sortable: true
    },
    {
      field: 'address.state',
      name: 'State',
      sortable: true
    },
    {
      field: 'status',
      name: 'Status',
      width: '100px',
      render: (status: CustomerLocationStatus) => {
        return <CustomerLocationStatusBadge status={status.status} title={'status'} />
      }
    },
    {
      name: 'Actions',
      actions: [
        {
          name: 'Change status',
          description: 'Change status',
          icon: 'inputOutput',
          type: 'icon',
          onClick: (location: Location) => changeLocationStatus(location)
        }
      ]
    }
  ]
}
