import { useQuery } from '@apollo/client'
import {
  EuiButtonEmpty,
  EuiComboBox,
  EuiComboBoxOptionOption,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHighlight
} from '@elastic/eui'
import { uniqBy } from 'lodash-es'
import { useCallback, useEffect, useState } from 'react'
import { useDebouncedValue } from 'rooks'
import { ContactLinkFragment, SearchContactsDocument } from '../api/generated-types'

interface ContactComboBoxProps {
  contacts: ContactLinkFragment[]
  onChangeContacts: (contacts: ContactLinkFragment[]) => void
  startEnabled?: boolean
  width?: string
}

export const ContactComboBox = (props: ContactComboBoxProps) => {
  const { contacts, onChangeContacts, startEnabled, width } = props

  const [enabled, setEnabled] = useState(startEnabled || contacts.length > 0)
  const [comboInput, setComboInput] = useState<HTMLInputElement | null>(null)
  const [term, setTerm] = useState('')

  const [debouncedTerm] = useDebouncedValue(term, 150)

  const { data, loading, error } = useQuery(SearchContactsDocument, {
    variables: {
      input: {
        filter: {
          ...(debouncedTerm && debouncedTerm.length > 0 && { query: debouncedTerm })
        }
      }
    }
  })

  const results = data?.searchContacts?.results ?? []

  const options: EuiComboBoxOptionOption<string>[] = results.map((contact) => ({
    id: contact.id,
    label: contact.detail?.fullName ?? 'No name'
  }))

  const selectedOptions: EuiComboBoxOptionOption<string>[] = contacts.map((contact) => ({
    id: contact.id,
    label: contact.detail?.fullName ?? 'No name'
  }))

  const onChange = (selectedOptions: EuiComboBoxOptionOption<string>[]) => {
    const filteredContacts = contacts.filter((contact) => selectedOptions.find((option) => option.id === contact.id))
    const newContacts = results.filter((r) => selectedOptions.find((option) => r.id === option.id))
    onChangeContacts(uniqBy([...filteredContacts, ...newContacts], 'id'))
  }

  if (error) {
    console.error('error', error)
  }

  const renderOption = (option: any, searchValue: any, contentClassName: any) => {
    return (
      <EuiFlexGroup gutterSize="s" alignItems="center">
        <EuiFlexItem grow={true}>
          <span className={contentClassName}>
            <EuiHighlight search={searchValue}>{option.label}</EuiHighlight>
          </span>
        </EuiFlexItem>
      </EuiFlexGroup>
    )
  }

  const onAddFilter = () => {
    setEnabled(true)
    setTimeout(() => comboInput?.focus(), 50)
  }

  const onBlur = () => {
    if (contacts.length <= 0) {
      setEnabled(false)
    }
  }

  const onSearchChange = useCallback((searchValue: string) => setTerm(searchValue), [])

  // Simulate initial load
  useEffect(() => onSearchChange(''), [onSearchChange])

  const label = 'contact'
  return (
    <form autoComplete="off">
      <EuiComboBox
        async
        inputRef={setComboInput}
        placeholder="Contacts"
        options={options}
        selectedOptions={selectedOptions}
        onChange={onChange}
        isClearable={true}
        renderOption={renderOption}
        rowHeight={40}
        isLoading={loading}
        onSearchChange={onSearchChange}
        style={{ minWidth: width ?? '280px' }}
        hidden={!enabled}
        onBlur={onBlur}
        aria-label={label}
        data-test-subj={label}
      />
      {!enabled && (
        <EuiButtonEmpty iconType="filter" flush="both" onClick={onAddFilter}>
          Contact
        </EuiButtonEmpty>
      )}
    </form>
  )
}
