import { WorkflowActionProps } from '../workflow-model'
import { EuiSpacer, EuiText } from '@elastic/eui'
import { Callout } from '../../common/callout'
import { TopicActionInput, TopicActionResult } from './action-topic-model'
import { WorkflowAction } from '../workflow-action'
import { FreeTextQuestionField } from '../question-fields/free-text-question-field'

export const TopicWorkflowAction = (props: WorkflowActionProps<TopicActionInput, TopicActionResult>) => {
  const { result, input, onUpdate } = props

  const { topicName, editable } = input
  const onChange = () => onUpdate({ ...result, actionCompleted: false, value: undefined })
  const jobDescription = result?.jobDescription ?? ''
  const showJobDescription = input.showJobDescription ?? true
  const showJobDescriptionFollowup = input.showJobDescriptionFollowup ?? true
  return (
    <WorkflowAction title="Job classification" value={result?.value} onClickChange={onChange} editable={editable}>
      {showJobDescription && (
        <>
          <Callout type="script">
            <EuiText>
              <div>
                I've noted down that you are calling in regard to <strong>{topicName}</strong>
              </div>
              <EuiSpacer size="s" />
              <div>Do you mind telling what's been happening or what the issue is?</div>
            </EuiText>
          </Callout>

          <FreeTextQuestionField
            question=""
            showQuestion={false}
            answer={jobDescription}
            changeAnswer={(jobDescription) => onUpdate({ ...result, jobDescription })}
            rows={5}
            helpText="Capture the customer's description of the issue or request"
          />
        </>
      )}

      {(!showJobDescription || jobDescription.trim().length > 0) && (
        <>
          {showJobDescription && showJobDescriptionFollowup && (
            <>
              <Callout type="script">
                <EuiText>
                  <div>Thanks for explaining that to me.</div>
                  <EuiSpacer size="s" />
                  <div>
                    To help our technician as much as possible I just want to clarify and ask a few more questions.
                  </div>
                </EuiText>
              </Callout>
              <EuiSpacer />
            </>
          )}
          {props.children}
        </>
      )}
    </WorkflowAction>
  )
}
