import { QuestionDefinitions } from '../../helpers/notes-generator'
import { ServiceType, TopicActionResult } from '../action-topic-model'
import { YesNoValue } from '../common/common-enums'
import { BrandType } from './appliance-brand-model'

export const WARRANTY_SCRIPT =
  'Because your appliance is less then 2 years old, there is a chance it could still be under warranty. By coming out and attempting to repair the appliance, we could void your warranty.'

export const NEW_APPLIANCE_NOT_ONSITE_MESSAGE =
  'Set expectation for customer to call back when it is so we can accurately quote'

export interface ApplianceTopicActionResult extends TopicActionResult {
  applianceType?: ApplianceTypeEnum
}
export interface ApplianceRepairTopicActionResult extends ApplianceTopicActionResult {
  applianceAge?: ApplianceAgeEnum
  mayVoidWarrantyConfirmationToProceed?: YesNoValue
  repairOrReplace?: RepairOrReplaceEnum
  brand?: string
  brandServiceable?: YesNoValue
  selectedBrand?: BrandType
  confirmedPartsAvailableWithDSR?: YesNoValue
  issue?: GeneralApplianceIssueType
  outletWorksWithOtherDevice?: YesNoValue
  confirmCookTopIsElectric?: YesNoValue
}

export const applianceRepairQuestionDefinitions: QuestionDefinitions<ApplianceRepairTopicActionResult> = {
  brand: { question: 'What brand is it?', statement: 'Brand:' },
  issue: {
    question: 'What is the issue?',
    statement: 'Issue:'
  },
  applianceAge: { question: 'How old is the appliance?', statement: 'Age:' },
  mayVoidWarrantyConfirmationToProceed: {
    question: 'Are you happy to proceed?',
    statement: 'Customer confirmation, understands warranty may become void:'
  },
  confirmedPartsAvailableWithDSR: {
    question: 'Has DSR confirmed spare parts are available and this is servicable?',
    statement: 'DSR confirmed parts available:'
  },
  outletWorksWithOtherDevice: {
    question: 'Does the power point work with other devices?',
    statement: 'Outlet works:'
  },
  confirmCookTopIsElectric: {
    question: `Just to confirm, this is for an electric cooktop and not an induction cooktop - induction being a clear glass top with heated coils underneath that doesn’t glow red when turned on?`
  }
}

export const enum ApplianceTypeEnum {
  Cooktop = 'Cooktop',
  Oven = 'Oven',
  Microwave = 'Microwave',
  Dishwasher = 'Dishwasher',
  WashingMachine = 'WashingMachine',
  CoffeeMachine = 'CoffeeMachine',
  Dryer = 'Dryer',
  FridgeFreezer = 'Fridge/Freezer',
  Rangehood = 'Rangehood'
}

export enum ApplianceAgeEnum {
  LessThan2yrsOld = 'Less than 2yrs old',
  MoreThan2yrsOld = 'Greater than 2yrs old'
}

export const canProceedWithRepair = (result: ApplianceRepairTopicActionResult | undefined) => {
  const { applianceAge, mayVoidWarrantyConfirmationToProceed } = result ?? {}
  if (!applianceAge || (applianceAge === ApplianceAgeEnum.LessThan2yrsOld && !mayVoidWarrantyConfirmationToProceed)) {
    return undefined
  }

  return (
    (applianceAge === ApplianceAgeEnum.LessThan2yrsOld && mayVoidWarrantyConfirmationToProceed === YesNoValue.Yes) ||
    applianceAge === ApplianceAgeEnum.MoreThan2yrsOld
  )
}

export const isBrandServiceable = (result: ApplianceRepairTopicActionResult | undefined): boolean => {
  const { brandServiceable, confirmedPartsAvailableWithDSR } = result ?? {}
  return (
    brandServiceable === YesNoValue.Yes ||
    (brandServiceable === YesNoValue.No && confirmedPartsAvailableWithDSR === YesNoValue.Yes)
  )
}

export enum GeneralApplianceIssueType {
  PowerSupply = 'PowerSupply',
  OtherFault = 'OtherFault'
}

export const getMechanicalIssueOnlyMessage = (value = '') => {
  return `Just so you are aware, we can't repair mechanical issues ${value ? 'with the ' + value : ''}`
}

export const getOutOfScopeMessage = (value = '') => {
  return `Unfortunately this type of ${value ? value + ' ' : ''}work is out of scope for us.`
}
export const generalApplianceIssueTypeOptions = [
  { id: GeneralApplianceIssueType.PowerSupply, label: 'Power supply' },
  { id: GeneralApplianceIssueType.OtherFault, label: 'Other fault' }
]
export const serviceTypeOptions = [
  { id: ServiceType.Installation, label: 'Installation' },
  { id: ServiceType.Repair, label: 'Repair' }
]

export enum RepairOrReplaceEnum {
  Repair = 'Repair',
  Replace = 'Replace'
}

export const repairOrReplaceOptions = [
  { id: RepairOrReplaceEnum.Repair, label: 'Repair' },
  { id: RepairOrReplaceEnum.Replace, label: 'Replace' }
]

export const enum InstallEnum {
  NewInstall = 'NewInstall',
  Install = 'Install'
}

export const fullServiceOptions = [
  { id: InstallEnum.NewInstall, label: 'New Install' },
  { id: RepairOrReplaceEnum.Replace, label: 'Replace' },
  { id: RepairOrReplaceEnum.Repair, label: 'Repair' }
]

export const installRepairReplaceOptions = [
  { id: InstallEnum.Install, label: 'Install' },
  { id: RepairOrReplaceEnum.Replace, label: 'Replace' },
  { id: RepairOrReplaceEnum.Repair, label: 'Repair' }
]

export const applianceAgeOptions = [
  { id: ApplianceAgeEnum.LessThan2yrsOld, label: 'Less than 2yrs old' },
  { id: ApplianceAgeEnum.MoreThan2yrsOld, label: 'More than 2yrs old' }
]
