import { UserFragment } from '../../api/generated-types'

export const getUserInitials = (user: UserFragment): string => {
  const { firstName, lastName, fullName } = user.contactDetail ?? {}

  if (firstName && lastName) {
    return `${firstName} ${lastName}` //generateUserInitials(firstName, lastName)
  } else if (fullName) {
    // if space exists in full name, separate and generate initials
    // const [firstName, lastName] = split(trim(fullName), spaceSeparator, 1)
    // if (firstName && lastName) {
    //   return generateUserInitials(firstName, lastName)
    // } else {
    //   return fullName
    // }
    return fullName
  }
  return ``
}
