import { AttributeRequirement } from '@fallonsolutions/attribute'
import { CustomerType, JobType, PriorityType, ServiceArea, TradeType } from '../../../api/generated-types'
import { ItemSupplier } from '../action-topic-model'
import { YesNoValue } from '../common/common-enums'
import {
  TopicQualification,
  TopicQualificationResult,
  TopicRequirementsResult,
  createOutcomeGenerator
} from '../common/common-outcome'
import {
  ACType,
  DwellingBuildStatus,
  DwellingType,
  HVACInstallType,
  InstallationTimeFrame,
  TopicHVACQuoteInstallActionResult
} from './action-topic-hvac-quote-install'

interface TopicHVACQuoteInstallActionResultOutcome extends TopicHVACQuoteInstallActionResult {
  existingCustomer: boolean | undefined
  serviceArea: ServiceArea | undefined
}

const getPriorityType = (result: TopicHVACQuoteInstallActionResultOutcome): PriorityType => {
  const { installType, otherQuotes, installationTimeFrame, existingCustomer, dwellingType } = result
  const priorities = [
    installType && [HVACInstallType.NewInstall, HVACInstallType.ReplaceExisting].includes(installType)
      ? PriorityType.P1
      : PriorityType.P2,
    otherQuotes === YesNoValue.No ? PriorityType.P1 : PriorityType.P2,
    installationTimeFrame === InstallationTimeFrame.Within4Weeks ? PriorityType.P1 : PriorityType.P2,
    existingCustomer === true ? PriorityType.P1 : PriorityType.P2,
    dwellingType === DwellingType.StandardDomesticSingleOrDoubleStoreyHouse ? PriorityType.P1 : PriorityType.P2
  ]

  const atLeastTwoP1s = priorities.filter((p) => p === PriorityType.P1).length >= 2
  return atLeastTwoP1s ? PriorityType.P1 : PriorityType.P2
}

const getRequirements = (result: TopicHVACQuoteInstallActionResultOutcome | undefined): TopicRequirementsResult => {
  if (!result) return {}
  const { acType } = result
  const priority = { required: getPriorityType(result) }
  const attributeId = acType === ACType.Ducted ? 'DuctedACQuoting' : 'SplitSystemACQuoting'
  const attributes: AttributeRequirement[] = [{ attributeId }]
  return {
    requirements: { priority, attributes, travelFeeWaived: false },
    category: acType === ACType.Ducted ? 'Ducted' : 'SplitSystem',
    type: JobType.Quote,
    trade: TradeType.HVAC
  }
}

const outOfScope = { qualification: TopicQualification.OutOfScope }
const unknownScope = { qualification: TopicQualification.Unknown }
const withinScope = { qualification: TopicQualification.InScope }
const SUNSHINE_COAST_BRANCH_ID = 'sunshine-coast'
const getQualification = (result: TopicHVACQuoteInstallActionResultOutcome | undefined): TopicQualificationResult => {
  if (!result) return unknownScope
  const {
    itemSupplier,
    dwellingBuildStatus,
    acType,
    installType,
    otherQuotes,
    installationTimeFrame,
    customerType,
    roofAccess,
    dwellingType,
    balconyAccess,
    compressorAvailability,
    organizeCompressAvailability,
    serviceArea,
    existingCustomer
  } = result

  const isCommercial = customerType === CustomerType.Commercial
  if (!acType || !installType || !installationTimeFrame || !otherQuotes || !itemSupplier || !dwellingType)
    return unknownScope

  if (itemSupplier && itemSupplier !== ItemSupplier.Fallon) return outOfScope

  if (dwellingBuildStatus && dwellingBuildStatus !== DwellingBuildStatus.CompleteDwelling) return outOfScope

  const canAccessRoof = isCommercial ? roofAccess === YesNoValue.Yes : true
  if (!canAccessRoof) return outOfScope
  switch (dwellingType) {
    case DwellingType.HouseOrTownHouse: {
      //return withinScope
      if (
        (installType === HVACInstallType.NewInstall || installType === HVACInstallType.ReplaceExisting) &&
        acType === ACType.SplitSystemOrMultiHead
      ) {
        return withinScope
      }

      if (
        (installType === HVACInstallType.NewInstall || installType === HVACInstallType.ReplaceExisting) &&
        acType === ACType.Ducted
      ) {
        return withinScope
      }
      return unknownScope
    }
    case DwellingType.ApartmentOrUnit: {
      if (installType === HVACInstallType.NewInstall && acType === ACType.SplitSystemOrMultiHead) {
        if (balconyAccess === YesNoValue.Yes) return withinScope
        if (balconyAccess === YesNoValue.No) return outOfScope
      }
      if (
        (installType === HVACInstallType.NewInstall || installType === HVACInstallType.ReplaceExisting) &&
        acType === ACType.Ducted
      ) {
        return outOfScope
      }

      if (installType === HVACInstallType.ReplaceExisting && acType === ACType.SplitSystemOrMultiHead) {
        if (compressorAvailability === YesNoValue.Yes) return withinScope
        if (compressorAvailability === YesNoValue.No) {
          if (!organizeCompressAvailability) return unknownScope
          if (organizeCompressAvailability === YesNoValue.Yes) return withinScope
          if (organizeCompressAvailability === YesNoValue.No) return outOfScope
        }
      }
      return unknownScope
    }
  }
  return unknownScope
}

export const hvacOutComeGenerator = createOutcomeGenerator<TopicHVACQuoteInstallActionResultOutcome>(
  {
    qualification: TopicQualification.Unknown,
    trade: TradeType.HVAC,
    type: JobType.Quote,
    category: 'SplitSystem'
  },
  getQualification,
  getRequirements
)
