import { MoneyUtils } from '@fallonsolutions/money'
import { Currency, Dinero } from 'dinero.js'
import { isNil } from 'lodash-es'
import { Money } from '../api/generated-types'

interface FormatMoneyOpts {
  currencySymbol?: boolean
  negative?: boolean
  format?: string // override default format
}

function isDinero(value: Dinero | Money): value is Dinero {
  return (value as Dinero).getAmount !== undefined
}

export const isZero = (value: Dinero | Money): value is Dinero => {
  return isDinero(value) ? (value as Dinero).isZero() : MoneyUtils.fromMoney(value).isZero()
}

export const formatMoney = (value: Money | Dinero, opts?: FormatMoneyOpts): string => {
  let amount: string
  let currency: Currency
  if (isDinero(value)) {
    amount = value.toFormat('0.00')
    currency = value.getCurrency()
  } else {
    amount = value.amount
    currency = value.currency
  }
  if (isNil(amount) || isNil(currency)) {
    return ''
  }

  const currencySymbol = opts?.currencySymbol ?? false
  const negative = opts?.negative ?? false
  let money = MoneyUtils.fromString(amount, { currency }).setLocale('en-AU')

  if (negative && money.getAmount() > 0) {
    money = money.multiply(-1)
  }
  const format = opts?.format ? opts.format : currencySymbol === true ? '$0,0.00' : '0,0.00'
  return money.toFormat(format)
}
