import { EuiFlexGroup, EuiFlexItem, EuiIcon, EuiText } from '@elastic/eui'

export interface ListNavOption {
  label: string
  value: string
  icon?: string
}

export interface ListNavOptionViewProps {
  option: ListNavOption
  isSelected?: boolean
  onClick: (option: ListNavOption) => void
  variant?: 'default' | 'fill'
  fontWeight?: number
  direction?: 'forward' | 'back'
  iconColor?: string
}

export const ListNavOptionView = ({ option, isSelected, onClick, ...props }: ListNavOptionViewProps) => {
  const { label, icon } = option

  const direction = props.direction ?? 'forward'

  const variant = props.variant ?? 'default'
  const fontWeight = props.fontWeight ?? 400

  const blue = '#007AFF'
  // const blueHover = '#007AFF'
  const lightGray = '#E5E8F0'
  const lightGrayHover = '#E5E8F0'
  const gray = '#727888'
  const textGray = '#525B72'
  const fillBackground = '#FAFBFD'

  const backgroundColor = variant === 'fill' ? fillBackground : 'transparent'
  const iconColor = props.iconColor ?? (isSelected ? blue : gray)
  const textColor = isSelected ? blue : textGray
  const borderColor = isSelected ? blue : lightGray
  const chevronColor = isSelected ? blue : gray
  const internalBorderColor = variant === 'fill' ? lightGrayHover : lightGray
  // const borderHoverColor = isSelected ? blueHover : lightGrayHover

  return (
    <EuiFlexGroup
      gutterSize="s"
      alignItems="center"
      onClick={() => onClick(option)}
      style={{
        cursor: 'pointer',
        border: '1px solid',
        borderColor,
        borderRadius: '6px',
        backgroundColor,
        color: textColor,
        fontWeight,
        padding: '8px'
      }}
    >
      {direction === 'back' && (
        <EuiFlexItem grow={false}>
          <EuiIcon type="arrowLeft" size="m" color={chevronColor} />
        </EuiFlexItem>
      )}
      {icon && (
        <EuiFlexItem grow={false}>
          <EuiIcon type={icon} size="l" color={iconColor} />
        </EuiFlexItem>
      )}
      <EuiFlexItem grow={true}>
        <EuiText
          size="m"
          style={{
            padding: '4px 4px 4px 12px',
            ...(icon && { borderLeft: '1px solid', borderColor: internalBorderColor })
          }}
        >
          {label}
        </EuiText>
      </EuiFlexItem>
      {direction === 'forward' && (
        <EuiFlexItem grow={false}>
          <EuiIcon type="arrowRight" size="m" color={chevronColor} />
        </EuiFlexItem>
      )}
    </EuiFlexGroup>
  )
}
