import { ReactNode } from 'react'
import { useHistory } from 'react-router-dom'
import { EuiHeaderLink } from '@elastic/eui'

interface EuiCustomHeaderLinkProps {
  to: string
  children?: ReactNode
}

const isModifiedEvent = (event: any) => !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey)

const isLeftClickEvent = (event: any) => event.button === 0

export default function EuiCustomHeaderLink({ to, ...props }: EuiCustomHeaderLinkProps) {
  const history = useHistory()

  function onClick(event: any) {
    if (event.defaultPrevented) {
      return
    }

    if (event.target.getAttribute('target')) {
      return
    }

    if (isModifiedEvent(event) || !isLeftClickEvent(event)) {
      return
    }

    event.preventDefault()
    history.push(to)
  }

  return <EuiHeaderLink {...props} href={to} onClick={onClick} />
}
