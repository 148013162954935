import {
  EuiBasicTableColumn,
  EuiButtonEmpty,
  EuiButtonIcon,
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
  EuiLink
} from '@elastic/eui'
import { dateConfig } from '@fallonsolutions/date'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import { DateTime } from 'luxon'
import {
  ContactFragment,
  ContactLifecycleStage,
  ContactMergeStatus,
  CustomerContactCustomerFragment,
  DataQualityStatus
} from '../api/generated-types'
import { useApp } from '../app/app-context'
import EuiCustomLink from '../common/eui-custom-link'
import { DataQualityStatusBadge } from '../data-quality/data-quality-status-badge'
import hubspotIcon from '../static/images/hubspot-icon.png'
import { ContactLink } from './contact-link'
import { ContactMergeStatusBadge } from './contact-merge-status-badge'
import { LifecycleStageBadge } from './lifecycle-stage-badge'
import { ContactLinkFragment } from '@fallonsolutions/types'
import React from 'react'

export enum ContactColumnName {
  Name = 'Contact name',
  Lifecycle = 'Lifecycle',
  Merge = 'Merge',
  Customers = 'Customers',
  Phone = 'Phone',
  Email = 'Email',
  Street = 'Street',
  Suburb = 'Suburb',
  Hubspot = 'Hubspot',
  DataQuality = 'Data Quality',
  Created = 'Created',
  Actions = 'Actions'
}

export interface ContactListColumnsProps {
  includeContactLink?: boolean
  onDataQualityEditClick?: (contact: ContactFragment | ContactLinkFragment) => void
  columnsToRemove?: ContactColumnName[]
  openContactInNewTab?: boolean
}

export const ContactListColumns = (
  props: ContactListColumnsProps
): Array<EuiBasicTableColumn<ContactFragment | ContactLinkFragment>> => {
  const { openContactInNewTab } = props
  const includeContactLink = props.includeContactLink ?? false
  const columnsToRemove = props.columnsToRemove
  const onDataQualityEditClick = props.onDataQualityEditClick
  const appContext = useApp()
  const hubspotAccountId = appContext.hubspotAccountId

  const formatPhone = (phone: string): string => {
    const phoneNumber = parsePhoneNumberFromString(phone)
    if (phoneNumber?.isValid()) {
      return phoneNumber.formatNational()
    } else {
      return '-'
    }
  }

  const columns = [
    {
      field: 'detail.fullName',
      name: ContactColumnName.Name,
      width: '180px',
      render: (fullName: string, contact: ContactFragment | ContactLinkFragment) => (
        <ContactLink contact={contact} includeLink={includeContactLink} openInNewTab={openContactInNewTab} />
      )
    },
    {
      field: 'lifecycleStage',
      name: ContactColumnName.Lifecycle,
      width: '96px',
      render: (lifecycleStage?: ContactLifecycleStage) => {
        if (!lifecycleStage) return ''
        return <LifecycleStageBadge stage={lifecycleStage} />
      }
    },
    {
      field: 'mergeStatus',
      name: ContactColumnName.Merge,
      width: '96px',
      render: (mergeStatus?: ContactMergeStatus) => {
        if (!mergeStatus) return ''
        return <ContactMergeStatusBadge status={mergeStatus} />
      }
    },
    {
      field: 'customers',
      name: ContactColumnName.Customers,
      width: '80px',
      render: (customers?: CustomerContactCustomerFragment[]) => {
        if (!customers || customers.length === 0) return ''
        return (
          <EuiFlexGroup direction="column" gutterSize="none" alignItems="flexStart">
            {customers.map((customer) => (
              <EuiFlexItem key={customer.customerId}>
                <EuiCustomLink to={`/customers/${customer.customerId}`}>
                  <span className="mono-text">{customer.customer.number}</span>
                </EuiCustomLink>
              </EuiFlexItem>
            ))}
          </EuiFlexGroup>
        )
      }
    },
    {
      field: 'detail.phone',
      name: ContactColumnName.Phone,
      width: '100px',
      render: (phone?: string) => {
        return phone ? <a href={`tel:${phone}`}>{formatPhone(phone)}</a> : <>No phone</>
      }
    },
    {
      field: 'detail.email',
      name: ContactColumnName.Email,
      width: '160px',
      render: (email?: string) => {
        return email ? <EuiLink href={`mailto:${email}`}>{email}</EuiLink> : 'No email'
      }
    },
    {
      field: 'detail.address.street',
      name: ContactColumnName.Street,
      width: '160px',
      render: (street?: string) => street ?? 'No address'
    },
    {
      field: 'detail.address.suburb',
      name: ContactColumnName.Suburb,
      width: '120px',
      render: (suburb?: string) => suburb ?? ''
    },
    {
      field: 'hubspotId',
      name: <EuiIcon type={hubspotIcon} size="l" />,
      width: '24px',
      render: (hubspotId?: string) => {
        if (!hubspotId) return ''

        return (
          <EuiButtonEmpty
            href={`https://app.hubspot.com/contacts/${hubspotAccountId}/${hubspotId}`}
            target="_blank"
            rel="noopener noreferrer"
            iconType={hubspotIcon}
            size="xs"
            style={{ fontWeight: 400, fontSize: '13px' }}
          ></EuiButtonEmpty>
        )
      }
    },
    {
      field: 'dataQuality.status',
      name: ContactColumnName.DataQuality,
      width: '100px',
      render: (status?: DataQualityStatus) => <DataQualityStatusBadge status={status ?? DataQualityStatus.NotChecked} />
    },
    {
      field: 'created',
      name: ContactColumnName.Created,
      width: '80px',
      render: (value?: string) => {
        if (!value) {
          return ''
        }
        const created = DateTime.fromISO(value)
        if (created.isValid) {
          return (
            <span
              title={created.toFormat(dateConfig.luxonFormat.fullDate)}
              style={{ whiteSpace: 'nowrap', overflowX: 'hidden', textOverflow: 'ellipsis' }}
            >
              {created.toRelative({ style: 'narrow' })}
            </span>
          )
        } else {
          return ''
        }
      }
    },
    {
      name: ContactColumnName.Actions,
      width: '60px',

      actions: [
        {
          render: (contact: ContactFragment | ContactLinkFragment) => (
            <EuiButtonIcon
              iconSize="s"
              iconType="check"
              color="text"
              display="empty"
              aria-label="Quality"
              onClick={() => onDataQualityEditClick?.(contact)}
            />
          )
        }
      ]
    }
  ]

  return columnsToRemove
    ? columns.filter(
        (column) =>
          !column.name ||
          (!React.isValidElement(column.name) && !columnsToRemove.includes(column.name as ContactColumnName)) ||
          React.isValidElement(column.name)
      )
    : columns
}
