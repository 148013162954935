import { JobTopic, JobTopicTree } from '@fallonsolutions/types'
import { BaseActionInput, BaseActionResult, WorkflowActionProps } from '../workflow-model'
import { SelectJobTopicLegacyAction } from './action-select-job-topic-legacy'

export type SelectJobTopicActionInput = BaseActionInput & { customerLocationId?: string }

export interface SelectJobTopicActionResult extends BaseActionResult {
  topic?: JobTopic
  workflowV2?: boolean
}

export interface SelectJobTopicActionUIProps {
  searchBoxDebounceInMilliseconds?: number
  jobTopicTrees: JobTopicTree[]
}

export const SelectJobTopicAction = (
  props: WorkflowActionProps<SelectJobTopicActionInput, SelectJobTopicActionResult> & SelectJobTopicActionUIProps
) => <SelectJobTopicLegacyAction {...props} />
