import { Money } from '@fallonsolutions/money'
import { cloneDeep, merge } from 'lodash-es'
import { UserAuthFragment, UserRole } from '../api/generated-types'

export enum UserAttributeStateActions {
  Clear = 'CLEAR_USER_ATTRIBUTES',
  Set = 'SET_USER_ATTRIBUTES'
}

export enum Pricelist {
  electrical = 'electrical',
  plumbing = 'plumbing',
  hvac = 'hvac',
  drains = 'drains',
  hotWater = 'hot-water',
  travel = 'travel'
}

export interface UserPermission {
  cancelJob: boolean
  createBooking: boolean
  createBookingManual: boolean
  createManualJobCost: boolean
  createUser?: boolean
  creditNote: boolean
  customDiscountLimit: Money | undefined
  customDiscounts: boolean
  customerFinance: boolean
  customerRefund: boolean
  customOptions: boolean
  developerFeatures: boolean
  dispatchScheduleEvent: boolean
  editCustomerDetails: boolean
  editSchedule: boolean
  editSuburb: boolean
  manageTimesheets: boolean
  editUserAttributes: boolean
  editUserSensitiveAttributes: boolean
  enquiryBeta: boolean
  exportCustomers: boolean
  exportJobs: boolean
  fieldApp: boolean
  inspection: boolean
  manageUsers: boolean
  moveScheduleEvent: boolean
  officeInvoice: boolean
  platformInvoicingApp: boolean
  platformInvoicingSandbox: boolean
  platformInvoicingWeb: boolean
  pricelists: Pricelist[]
  reporting: boolean
  sandbox: boolean
  editSoldAdjustment: boolean
  editSoldAdjustmentLimited: boolean
  sendOptionSheet: boolean
  setOptionStatus: boolean
  updatePropertyAddress: boolean
  viewPricingCalculation: boolean
  viewSchedule: boolean
  viewUserLocation: boolean
  voidPayment: boolean
  webApp: boolean
}

export interface UserAttributeState {
  id?: string
  role?: UserRole
  avatar: string
  permissions: UserPermission
  features?: {
    supportChat?: boolean
    generatedInternalNotes?: boolean
    jobPhotos?: boolean
    multiCompany?: boolean
  }
  active: boolean
}

const initialState: UserAttributeState = {
  id: undefined,
  active: false,
  role: undefined,
  avatar: 'https://fsp-public.s3-ap-southeast-2.amazonaws.com/images/fallon-logo.png',
  features: {
    supportChat: false,
    generatedInternalNotes: true,
    jobPhotos: false,
    multiCompany: false
  },
  permissions: {
    cancelJob: false,
    createBooking: false,
    createBookingManual: false,
    createManualJobCost: false,
    createUser: false,
    creditNote: false,
    customDiscountLimit: undefined,
    customDiscounts: false,
    customerFinance: false,
    customerRefund: false,
    customOptions: false,
    developerFeatures: false,
    dispatchScheduleEvent: false,
    editCustomerDetails: false,
    editSchedule: false,
    editSuburb: false,
    manageTimesheets: false,
    editUserAttributes: false,
    editUserSensitiveAttributes: false,
    enquiryBeta: false,
    exportCustomers: false,
    exportJobs: false,
    fieldApp: false,
    inspection: false,
    manageUsers: false,
    moveScheduleEvent: false,
    officeInvoice: false,
    editSoldAdjustment: false,
    editSoldAdjustmentLimited: false,
    platformInvoicingApp: false,
    platformInvoicingSandbox: false,
    platformInvoicingWeb: false,
    pricelists: [],
    reporting: false,
    sandbox: false,
    sendOptionSheet: false,
    setOptionStatus: false,
    updatePropertyAddress: false,
    viewPricingCalculation: false,
    viewSchedule: false,
    viewUserLocation: false,
    voidPayment: false,
    webApp: false
  }
}

export const userAttributesFromUserAuthFragment = (userFragment: UserAuthFragment): UserAttributeState => {
  const avatar = userFragment.avatar ?? initialState.avatar
  return merge(cloneDeep(initialState), cloneDeep(userFragment), { avatar })
}
