//

import { useState } from 'react'
import { JobTopic } from '@fallonsolutions/types'
import { JobTopicType } from '@fallonsolutions/types'
import { SingleSelectField } from '../../question-fields/single-select-question-field'
import { YesNoValue } from '../common/common-enums'
import { TopicActionResult } from '../action-topic-model'
import { OutOfScope } from '../../helpers/out-of-topic'

export interface ChangeJobTopicToRegularDrainsProps {
  message: string
  handleJobTopicChanged?: (jobTopic: JobTopic, topic?: TopicActionResult) => void
  results: TopicActionResult | undefined
}
const DRAINS_JOB_TOPIC: JobTopic & { actionCompleted?: boolean } = {
  actionCompleted: true,
  reference: JobTopicType.Drains,
  label: 'Drains'
}
export const ChangeJobTopicToRegularDrains = (props: ChangeJobTopicToRegularDrainsProps) => {
  const { message, handleJobTopicChanged } = props
  const [answer, setAnswer] = useState<YesNoValue | undefined>(undefined)
  return (
    <>
      <SingleSelectField
        question={message}
        options={[
          {
            id: 'Yes',
            label: 'Yes'
          },
          {
            id: 'No',
            label: 'No'
          }
        ]}
        answer={answer}
        changeAnswer={(answer) => {
          if (answer === 'Yes') {
            handleJobTopicChanged && handleJobTopicChanged(DRAINS_JOB_TOPIC, props.results)
            setAnswer(YesNoValue.Yes)
          } else {
            setAnswer(YesNoValue.No)
          }
        }}
      />

      <OutOfScope issue={'$99 drains clear special'} isOutOfScope={answer === YesNoValue.No} />
    </>
  )
}
