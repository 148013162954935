import { EuiButton, EuiSpacer } from '@elastic/eui'
import { QuestionDefinitions, getNotesGenerator } from '../../helpers/notes-generator'
import { getResultUpdater } from '../../helpers/results-updater'
import { WorkflowActionProps } from '../../workflow-model'
import { TopicActionInput } from '../action-topic-model'
import { TopicWorkflowAction } from '../action-topic-view'
import { EndOfTopicStatement } from '../common/end-of-topic-statements'
import { HotWaterServiceType, HotWaterSystemType, TopicHotWaterActionResult } from './action-topic-hot-water-model'
import { outcomeGenerator } from './action-topic-hot-water-outcome'
import { HotWaterRepairElectric } from './action-topic-hot-water-repair-electric'
import { HotWaterRepairGas } from './action-topic-hot-water-repair-gas'
import { HotWaterRepairHeatPump } from './action-topic-hot-water-repair-heat-pump'
import { HotWaterRepairOther } from './action-topic-hot-water-repair-other'
import { HotWaterRepairSolar } from './action-topic-hot-water-repair-solar'
import { HotWaterReplace } from './action-topic-hot-water-replace'
import { HotWaterSelectType } from './action-topic-hot-water-select-type'
import { hotWaterPriorityOutCome } from './hot-water-priority-outcome'
import { TopicQualification } from '../common/common-outcome'
import { SingleSelectField } from '../../question-fields/single-select-question-field'
import { YesNoValue } from '../common/common-enums'
import { OutOfScope } from '../../helpers/out-of-topic'

const questionDefinitions: QuestionDefinitions<TopicHotWaterActionResult> = {
  existingHwsType: {
    question: 'What type of hot water system is it?',
    statement: 'HWS type:',
    endOfTopicStatement: (results: TopicHotWaterActionResult) => {
      const { existingHwsType } = results
      if (existingHwsType === HotWaterSystemType.Solar) {
        return 'DO NOT BOOK SOLAR HOT WATER JOBS ON 7 AM SLOT! BOOK FOR A 10AM - 1PM SLOT'
      }
      return null
    }
  },
  tankAge: {
    question: 'Is tank under 5 years old',
    statement: 'Tank age: '
  },
  serviceType: {
    question: 'Is this for repair or replacement?',
    statement: 'Service type:'
  },
  repairIssue: {
    question: 'What is the main issue with the system?',
    statement: 'Issue:'
  },
  hasHotWaterWithBooster: {
    question: 'Do you have hot water when you turn on the booster?',
    statement: 'Has hot water when booster is on:'
  },
  tankTemperature: {
    question: 'Is the water at the tank hot or cold?',
    statement: 'Temperature at tank:'
  },
  systemAge: {
    question: 'How old is the system?',
    statement: 'System age:'
  },
  tankLocation: {
    question: 'Is the tank on the ground or roof?',
    statement: 'Tank location:'
  },
  boosterType: {
    question: 'Is this a gas or electric booster?',
    statement: 'Booster type:'
  },
  propertyLevels: {
    question: 'Is this for a double or single storey property?',
    statement: 'Property levels:'
  },
  extensionLadder: {
    question: 'Is an extension ladder required?',
    statement: 'Extension ladder required:'
  },
  replacementHwsType: {
    question: 'What type of hot water system did you want?',
    statement: 'New HWS type:'
  },
  replacementTimeframe: {
    question: 'When are you looking at getting it replaced?',
    statement: 'Replacement timeframe:'
  },
  otherQuotes: {
    question: 'Have you got other quotes?',
    statement: 'Other quotes:'
  },
  thermostatAge: {
    question: 'How old is element/thermostat ?',
    statement: 'Element/Thermostat system age:'
  }
}
const notesGenerator = getNotesGenerator(questionDefinitions)
type TopicActionHotWaterInput = TopicActionInput & { hotWaterSpecial: boolean }
export const TopicHotWaterAction = (
  props: WorkflowActionProps<TopicActionHotWaterInput, TopicHotWaterActionResult>
) => {
  const { result, input, onUpdate } = props
  const { hotWaterSpecial } = input
  const { existingHwsType, serviceType, tankAge } = result ?? {}

  const endOfTopicStatement = notesGenerator.generateEndOfTopicNotes(result ?? {}, questionDefinitions)
  const category = hotWaterSpecial ? 'Hot Water Special' : 'HotWater'
  const topicOutcome = outcomeGenerator(category)(result)
  const { requirements, qualification } = topicOutcome

  const priorityRequirements = hotWaterPriorityOutCome(requirements).getCallouts()

  const onNext = () =>
    onUpdate({
      ...result,
      ...topicOutcome,
      actionCompleted: true,
      workRequiredNotes: generatedNotes,
      ...(requirements && { requirements })
    })

  const generatedNotes = notesGenerator.generateNotes(result ?? {})

  const updateResult = getResultUpdater(result ?? {}, onUpdate).updateResult

  const proceedWithHotWaterWorkflow = !hotWaterSpecial || tankAge === YesNoValue.No

  return (
    <>
      <>
        <TopicWorkflowAction input={input} onUpdate={onUpdate} result={result}>
          {hotWaterSpecial && (
            <>
              <SingleSelectField
                options={[
                  {
                    id: YesNoValue.Yes
                  },
                  {
                    id: YesNoValue.No
                  }
                ]}
                question={notesGenerator.getQuestion('tankAge')}
                answer={tankAge}
                changeAnswer={(tankAge) => updateResult({ tankAge })}
              />
            </>
          )}
          <OutOfScope
            issue={'Tank under 5 years old for hot water promo'}
            isOutOfScope={!proceedWithHotWaterWorkflow}
            reason={
              'We wont be able to book this as there are rarely any issues if the system is under 5 yrs old, and the tank and valves will still be under warranty'
            }
          />
          {proceedWithHotWaterWorkflow && (
            <>
              <HotWaterSelectType result={result} updateResult={updateResult} notesGenerator={notesGenerator} />
              {serviceType === HotWaterServiceType.Repair && (
                <>
                  {existingHwsType === HotWaterSystemType.ElectricTank && (
                    <HotWaterRepairElectric
                      result={result}
                      updateResult={updateResult}
                      notesGenerator={notesGenerator}
                    />
                  )}
                  {(existingHwsType === HotWaterSystemType.Gas ||
                    existingHwsType === HotWaterSystemType.GasInstant) && (
                    <HotWaterRepairGas result={result} updateResult={updateResult} notesGenerator={notesGenerator} />
                  )}
                  {existingHwsType === HotWaterSystemType.Solar && (
                    <HotWaterRepairSolar result={result} updateResult={updateResult} notesGenerator={notesGenerator} />
                  )}
                  {existingHwsType === HotWaterSystemType.HeatPump && (
                    <HotWaterRepairHeatPump
                      result={result}
                      updateResult={updateResult}
                      notesGenerator={notesGenerator}
                    />
                  )}
                  {existingHwsType === HotWaterSystemType.Other && (
                    <HotWaterRepairOther result={result} updateResult={updateResult} notesGenerator={notesGenerator} />
                  )}
                </>
              )}

              {serviceType === HotWaterServiceType.Replace && (
                <HotWaterReplace result={result} updateResult={updateResult} notesGenerator={notesGenerator} />
              )}

              {priorityRequirements && (
                <>
                  <EuiSpacer />
                  {priorityRequirements}
                </>
              )}

              <EndOfTopicStatement statements={endOfTopicStatement} show={!!requirements} />
            </>
          )}

          <EuiSpacer />
          <EuiButton
            disabled={
              !requirements ||
              qualification === TopicQualification.OutOfScope ||
              qualification === TopicQualification.Unknown
            }
            onClick={() => onNext()}
          >
            Next
          </EuiButton>
        </TopicWorkflowAction>
      </>
    </>
  )
}
