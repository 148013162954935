import { useMutation } from '@apollo/client'
import { CustomerFragment, UpdateCustomerDocument } from '../api/generated-types'
import { NotesView } from '../common/notes'

export interface CustomerNotesViewProps {
  customer: CustomerFragment
}

export const CustomerNotesView = ({ customer }: CustomerNotesViewProps) => {
  const [updateCustomerNotes, { loading }] = useMutation(UpdateCustomerDocument, {
    refetchQueries: ['GetCustomer'],
    awaitRefetchQueries: true
  })

  const onSaveCustomerNotes = async (notes: string): Promise<boolean> => {
    await updateCustomerNotes({
      variables: {
        input: {
          id: customer.id,
          notes
        }
      }
    })
    return true
  }

  const notes = customer.notes ?? ''

  return (
    <NotesView
      label="NOTES"
      labelSize="small"
      notes={notes}
      isEditable={true}
      loading={loading}
      onSave={onSaveCustomerNotes}
    />
  )
}
