import {
  HotWaterBoosterType,
  HotWaterRepairIssue,
  HotWaterTankLocation,
  HotWaterThermostatsAge,
  PropertyLevels,
  TopicHotWaterActionResult
} from './action-topic-hot-water-model'
import { SingleSelectField } from '../../question-fields/single-select-question-field'
import { FreeTextQuestionField } from '../../question-fields/free-text-question-field'
import { NotesGenerator } from '../../helpers/notes-generator'
import { ResultUpdater } from '../../helpers/results-updater'
import { map } from 'lodash-es'
import { YesNoValue } from '../common/common-enums'

export interface HotWaterRepairSolarProps {
  result?: TopicHotWaterActionResult
  notesGenerator: NotesGenerator<TopicHotWaterActionResult>
  updateResult: ResultUpdater<TopicHotWaterActionResult>
}

export const HotWaterRepairSolar = (props: HotWaterRepairSolarProps) => {
  const { result, notesGenerator, updateResult } = props
  const {
    repairIssue,
    tankLocation,
    propertyLevels,
    boosterType,
    hasHotWaterWithBooster,
    extensionLadder,
    thermostatAge
  } = result ?? {}
  return (
    <>
      <SingleSelectField
        question={notesGenerator.getQuestion('repairIssue')}
        options={map(
          [
            HotWaterRepairIssue.Bulging,
            HotWaterRepairIssue.Burst,
            HotWaterRepairIssue.DrainLineLeaking,
            HotWaterRepairIssue.LeakingPipes,
            HotWaterRepairIssue.LukeWarm,
            HotWaterRepairIssue.NoHotWaterAtTap,
            HotWaterRepairIssue.LeanAndAnode,
            HotWaterRepairIssue.PumpNotWorking,
            HotWaterRepairIssue.PumpTrippingPower,
            HotWaterRepairIssue.Relocate,
            HotWaterRepairIssue.Remove,
            HotWaterRepairIssue.SolarPanelsLeaking,
            HotWaterRepairIssue.TankLeaking,
            HotWaterRepairIssue.TooLongToGetHot,
            HotWaterRepairIssue.ElementOrThermostat
          ],
          (i) => ({ id: i })
        )}
        answer={repairIssue}
        changeAnswer={(repairIssue) => updateResult({ repairIssue })}
      />

      {repairIssue && (
        <>
          {repairIssue === HotWaterRepairIssue.ElementOrThermostat && (
            <>
              <SingleSelectField
                question={notesGenerator.getQuestion('thermostatAge')}
                options={[
                  {
                    id: HotWaterThermostatsAge.LessThan8Years,
                    label: 'Less than 8 years old'
                  },
                  {
                    id: HotWaterThermostatsAge.MoreThan8Years,
                    label: 'More than 8 years old'
                  }
                ]}
                answer={thermostatAge}
                changeAnswer={(thermostatAge) => updateResult({ thermostatAge })}
              />
            </>
          )}

          {repairIssue !== HotWaterRepairIssue.NoHotWaterAtTap && (
            <>
              <SingleSelectField
                question={notesGenerator.getQuestion('tankLocation')}
                options={[
                  {
                    id: HotWaterTankLocation.Ground
                  },
                  {
                    id: HotWaterTankLocation.Roof
                  }
                ]}
                answer={tankLocation}
                changeAnswer={(tankLocation) => updateResult({ tankLocation })}
              />
            </>
          )}

          {repairIssue !== HotWaterRepairIssue.NoHotWaterAtTap && tankLocation === HotWaterTankLocation.Roof && (
            <>
              <SingleSelectField
                question={notesGenerator.getQuestion('propertyLevels')}
                options={[
                  {
                    id: PropertyLevels.SingleStorey
                  },
                  {
                    id: PropertyLevels.DoubleStorey
                  }
                ]}
                answer={propertyLevels}
                changeAnswer={(propertyLevels) => updateResult({ propertyLevels })}
              />
            </>
          )}

          {repairIssue !== HotWaterRepairIssue.NoHotWaterAtTap &&
            tankLocation === HotWaterTankLocation.Roof &&
            propertyLevels === PropertyLevels.DoubleStorey && (
              <>
                <FreeTextQuestionField
                  question={notesGenerator.getQuestion('extensionLadder')}
                  answer={extensionLadder}
                  changeAnswer={(extensionLadder) => updateResult({ extensionLadder })}
                />
              </>
            )}

          {repairIssue !== HotWaterRepairIssue.NoHotWaterAtTap &&
            (tankLocation === HotWaterTankLocation.Ground || tankLocation === HotWaterTankLocation.Roof) && (
              <>
                <SingleSelectField
                  question={notesGenerator.getQuestion('boosterType')}
                  options={[
                    {
                      id: HotWaterBoosterType.Gas
                    },
                    {
                      id: HotWaterBoosterType.Electric
                    }
                  ]}
                  answer={boosterType}
                  changeAnswer={(boosterType) => updateResult({ boosterType })}
                />
              </>
            )}

          {repairIssue === HotWaterRepairIssue.NoHotWaterAtTap && (
            <>
              <SingleSelectField
                question={notesGenerator.getQuestion('hasHotWaterWithBooster')}
                options={[
                  {
                    id: YesNoValue.Yes,
                    label: 'Yes',
                    icon: 'check'
                  },
                  {
                    id: YesNoValue.No,
                    label: 'No hot water with booster',
                    icon: 'cross'
                  }
                ]}
                answer={hasHotWaterWithBooster}
                changeAnswer={(hasHotWaterWithBooster) => updateResult({ hasHotWaterWithBooster })}
              />
            </>
          )}
        </>
      )}
    </>
  )
}
