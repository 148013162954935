import { EuiButton } from '@elastic/eui'
import { getResultUpdater } from '../../helpers/results-updater'
import { WorkflowActionProps } from '../../workflow-model'
import { TopicActionInput } from '../action-topic-model'
import { TopicWorkflowAction } from '../action-topic-view'
import { TopicEnergyAuditResult, energyAuditQuestionDefinitions } from './action-topic-home-energy-audit-model'
import { getNotesGenerator } from '../../helpers/notes-generator'
import { JobType, TradeType } from '../../../api/generated-types'
import { FreeTextQuestionField } from '../../question-fields/free-text-question-field'

const notesGenerator = getNotesGenerator(energyAuditQuestionDefinitions)

export const TopicEnergyAuditAction = (props: WorkflowActionProps<TopicActionInput, TopicEnergyAuditResult>) => {
  const { result, input, onUpdate } = props
  const { noteWorthyAudits } = result ?? {}
  const updateResult = getResultUpdater<TopicEnergyAuditResult>(result ?? {}, onUpdate).updateResult
  const canComplete = !!noteWorthyAudits
  const generatedNotes = notesGenerator.generateNotes(result ?? {})
  const onNext = () =>
    onUpdate({
      ...result,
      actionCompleted: true,
      workRequiredNotes: generatedNotes,
      type: JobType.Service,
      trade: TradeType.Electrical,
      category: 'Home Energy Audit',
      requirements: {
        attributes: [{ attributeId: 'EnergyAudit' }]
      }
    })
  return (
    <TopicWorkflowAction input={input} onUpdate={onUpdate} result={result}>
      <FreeTextQuestionField
        question={notesGenerator.getQuestion('noteWorthyAudits')}
        answer={noteWorthyAudits}
        rows={3}
        changeAnswer={(noteWorthyAudits) => updateResult({ noteWorthyAudits })}
      />
      {canComplete && (
        <>
          <EuiButton disabled={!canComplete} onClick={() => onNext()}>
            Next
          </EuiButton>
        </>
      )}
    </TopicWorkflowAction>
  )
}
