import { random } from 'lodash-es'
import PhotoSwipeLightbox from 'photoswipe/lightbox'
import 'photoswipe/style.css'
import React, { useEffect, useState } from 'react'
import { MediaItemFragment } from '../api/generated-types'
import '../static/css/media-item-gallery.css'
import { GalleryMediaCallBackFunction, GalleryViewButtonActions } from './helpers/media-gallery-actions'
import { PhotoSelectionMode } from './media-items-container'

export interface MediaItemGalleryProps {
  children: React.ReactNode
  selectionMode?: PhotoSelectionMode
  galleryCallback?: GalleryMediaCallBackFunction
}

// current issue is the getMediaItem function is stale whenever an image is deleted.
const SHOW_ANIMATION_DURATION = 200

export const MediaItemGallery = ({ children, selectionMode, galleryCallback }: MediaItemGalleryProps) => {
  const [galleryId] = useState(`gallery${random(1000)}`)
  const [loadingAction, setLoadingAction] = useState<boolean>(false)
  // TODO, if viewing deleted photos, you delete a photo and then go back to it, the gallery
  // let canDelete = true // will change on photoswipe events
  let lightbox: any | undefined = undefined

  const handleLoadingAction = (loadingState: boolean) => {
    setLoadingAction(loadingState)
    let doc: any
    if (loadingState) {
      doc = document.getElementsByClassName('pswp__lds-spinner')?.[0]
      if (doc) doc.style.display = 'inline-block'
    } else {
      doc = document.getElementsByClassName('pswp__lds-spinner')?.[0]
      if (doc) doc.style.display = 'none'
    }
  }
  useEffect(() => {
    lightbox = new PhotoSwipeLightbox({
      gallery: `#${galleryId}`,
      children: '.gallery--enabled .image-view__link',
      pswpModule: () => import('photoswipe'),
      showAnimationDuration: SHOW_ANIMATION_DURATION,
      hideAnimationDuration: 100
    })
    // new ObjectPosition(lightbox)

    lightbox.on('destroy', () => {
      // just in case the photoswipe library closes before a mutation can reset the loading state
      loadingAction && setLoadingAction(false)
    })
    // change cursor on delete button if media is soft deleted
    lightbox.on('change', () => {
      const image = getCurrentPhotoGalleryImage(lightbox)
      if (!image) return
      const deletedStatus = image?.getAttribute('deleted-status') === 'deleted'
      if (deletedStatus) {
        giveDeleteButtonNewCursorStyle('crosshair')
      } else {
        giveDeleteButtonNewCursorStyle('default')
      }
    })
    // rotate right
    lightbox.on('uiRegister', () => {
      lightbox?.pswp?.ui.registerElement({
        name: 'rotate-right',
        order: 10,
        appendTo: 'root',
        isButton: true,
        ariaLabel: 'Rotate right',
        title: 'Rotate right',
        html: '<svg height="48" width="48" xmlns="http://www.w3.org/2000/svg"><path d="m16.108 39.436h16.47c1.464 0 2.57-.366 3.318-1.097.747-.731 1.121-1.845 1.121-3.343v-16.421c0-1.497-.374-2.61-1.121-3.342-.748-.732-1.854-1.098-3.318-1.098h-16.47c-1.464 0-2.57.366-3.318 1.098-.748.731-1.122 1.845-1.122 3.342v16.421c0 1.498.374 2.612 1.122 3.343s1.854 1.097 3.318 1.097zm.127-2.944c-.552 0-.958-.123-1.218-.37s-.39-.658-.39-1.235v-16.2c0-.578.13-.99.39-1.237.26-.248.666-.371 1.218-.371h16.217c.562 0 .972.123 1.232.37.26.248.39.66.39 1.237v16.2c0 .578-.13.99-.39 1.236-.26.247-.67.37-1.232.37zm-11.712-19.076c.306 0 .57-.11.791-.332a1.08 1.08 0 0 0 .333-.792v-2.55c0-1.176.24-2.217.719-3.122a5.304 5.304 0 0 1 2.029-2.129c.873-.514 1.906-.771 3.098-.771h.425v2.052c0 .612.205.989.615 1.132s.85.04 1.318-.31l4.29-3.144c.353-.26.527-.537.521-.833-.006-.295-.18-.574-.522-.835l-4.29-3.177c-.469-.35-.908-.454-1.318-.31-.41.143-.614.532-.614 1.167v2.16h-.432c-1.61 0-3.02.34-4.232 1.022a7.26 7.26 0 0 0 -2.834 2.846c-.678 1.217-1.017 2.634-1.017 4.252v2.55c0 .306.11.57.331.79.22.222.484.333.789.333z" fill="#5a5a5a"/></svg>',
        onClick: () => {
          if (loadingAction) return
          const id = getMediaId(lightbox)
          if (!id) return
          if (galleryCallback) {
            handleLoadingAction(true)
            galleryCallback(GalleryViewButtonActions.RotateRight, id).then((mediaItem) => {
              handleLoadingAction(false)
              if (!mediaItem) {
                return
              }
              rotateImageInGallery(lightbox, mediaItem)
            })
          }
        }
      })
    })
    // rotate left
    lightbox.on('uiRegister', () => {
      lightbox?.pswp?.ui.registerElement({
        name: 'rotate-left',
        order: 10,
        appendTo: 'root',
        isButton: true,
        ariaLabel: 'Rotate left',
        title: 'Rotate Left',
        html: '<svg height="48" width="48" xmlns="http://www.w3.org/2000/svg"><path d="m16.108 39.436h16.47c1.464 0 2.57-.366 3.318-1.097.747-.731 1.121-1.845 1.121-3.343v-16.421c0-1.497-.374-2.61-1.121-3.342-.748-.732-1.854-1.098-3.318-1.098h-16.47c-1.464 0-2.57.366-3.318 1.098-.748.731-1.122 1.845-1.122 3.342v16.421c0 1.498.374 2.612 1.122 3.343s1.854 1.097 3.318 1.097zm.127-2.944c-.552 0-.958-.123-1.218-.37s-.39-.658-.39-1.235v-16.2c0-.578.13-.99.39-1.237.26-.248.666-.371 1.218-.371h16.217c.562 0 .972.123 1.232.37.26.248.39.66.39 1.237v16.2c0 .578-.13.99-.39 1.236-.26.247-.67.37-1.232.37zm27.943-19.076c.305 0 .569-.11.79-.332a1.08 1.08 0 0 0 .332-.792v-2.55c0-1.617-.339-3.034-1.017-4.25a7.246 7.246 0 0 0 -2.842-2.848c-1.217-.682-2.63-1.023-4.24-1.023h-.433v-2.159c0-.635-.202-1.024-.606-1.168-.404-.143-.846-.04-1.327.311l-4.275 3.177c-.351.261-.53.54-.536.835-.006.296.173.573.536.833l4.274 3.144c.48.35.922.453 1.326.31s.607-.52.607-1.132v-2.052h.426c1.191 0 2.224.257 3.097.771a5.31 5.31 0 0 1 2.03 2.13c.48.904.72 1.945.72 3.122v2.55c0 .306.11.57.332.79.221.222.49.333.805.333z" fill="#5a5a5a"/></svg>',
        onClick: () => {
          if (loadingAction) return
          const id = getMediaId(lightbox)
          if (!id) return
          if (galleryCallback) {
            handleLoadingAction(true)
            galleryCallback(GalleryViewButtonActions.RotateLeft, id).then((mediaItem) => {
              handleLoadingAction(false)
              if (!mediaItem) {
                return
              }
              rotateImageInGallery(lightbox, mediaItem)
            })
          }
        }
      })
    })

    // delete
    lightbox.on('uiRegister', () => {
      lightbox?.pswp?.ui.registerElement({
        name: 'delete',
        order: 10,
        appendTo: 'root',
        isButton: true,
        ariaLabel: 'Delete Image',
        title: 'Delete Image',
        html: '<svg fill="#FFFFFF" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 50 50" width="50px" height="50px"><path d="M 21 2 C 19.354545 2 18 3.3545455 18 5 L 18 7 L 10.154297 7 A 1.0001 1.0001 0 0 0 9.984375 6.9863281 A 1.0001 1.0001 0 0 0 9.8398438 7 L 8 7 A 1.0001 1.0001 0 1 0 8 9 L 9 9 L 9 45 C 9 46.645455 10.354545 48 12 48 L 38 48 C 39.645455 48 41 46.645455 41 45 L 41 9 L 42 9 A 1.0001 1.0001 0 1 0 42 7 L 40.167969 7 A 1.0001 1.0001 0 0 0 39.841797 7 L 32 7 L 32 5 C 32 3.3545455 30.645455 2 29 2 L 21 2 z M 21 4 L 29 4 C 29.554545 4 30 4.4454545 30 5 L 30 7 L 20 7 L 20 5 C 20 4.4454545 20.445455 4 21 4 z M 11 9 L 18.832031 9 A 1.0001 1.0001 0 0 0 19.158203 9 L 30.832031 9 A 1.0001 1.0001 0 0 0 31.158203 9 L 39 9 L 39 45 C 39 45.554545 38.554545 46 38 46 L 12 46 C 11.445455 46 11 45.554545 11 45 L 11 9 z M 18.984375 13.986328 A 1.0001 1.0001 0 0 0 18 15 L 18 40 A 1.0001 1.0001 0 1 0 20 40 L 20 15 A 1.0001 1.0001 0 0 0 18.984375 13.986328 z M 24.984375 13.986328 A 1.0001 1.0001 0 0 0 24 15 L 24 40 A 1.0001 1.0001 0 1 0 26 40 L 26 15 A 1.0001 1.0001 0 0 0 24.984375 13.986328 z M 30.984375 13.986328 A 1.0001 1.0001 0 0 0 30 15 L 30 40 A 1.0001 1.0001 0 1 0 32 40 L 32 15 A 1.0001 1.0001 0 0 0 30.984375 13.986328 z"/></svg>',
        onClick: () => {
          if (loadingAction) return
          if (cannotDeleteItem(lightbox)) return
          const id = getMediaId(lightbox)
          if (!id) return
          if (galleryCallback) {
            handleLoadingAction(true)
            galleryCallback(GalleryViewButtonActions.Delete, id).then((mediaItem) => {
              handleLoadingAction(false)
              if (!mediaItem) {
                return
              }
              // only remove media from photo gallery if not viewing deleted photos
              !isViewingDeletedMedia(lightbox) && deleteImageFromMediaGallery(lightbox, mediaItem)
            })
          }
        }
      })
    })

    lightbox.on('uiRegister', () => {
      lightbox?.pswp?.ui.registerElement({
        name: 'custom-caption',
        order: 9,
        isButton: false,
        appendTo: 'root',
        html: 'Caption text',

        // need to hook into on update function to update caption text
        onInit: (el: any, pswp: any) => {
          lightbox?.pswp?.on('change', () => {
            const currSlideElement = pswp?.currSlide.data.element

            let captionHTML: string | null | undefined = ''
            if (currSlideElement) {
              const nextSibling = currSlideElement.nextElementSibling

              if (nextSibling && nextSibling.classList.contains('hidden-caption-content')) {
                const hiddenCaption = nextSibling
                captionHTML = hiddenCaption.innerHTML
              } else {
                captionHTML = currSlideElement.querySelector('img')?.getAttribute('alt')
              }
            }
            el.innerHTML = captionHTML || ''
          })
        }
      })
    })

    // loading spinner
    lightbox.on('uiRegister', () => {
      lightbox?.pswp?.ui.registerElement({
        name: 'lds-spinner',
        order: 15,
        isButton: false,
        appendTo: 'root',
        html: '<div class="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>'
      })
    })

    lightbox.init()
    return () => {
      lightbox?.destroy()
      lightbox = undefined
    }
  }, [galleryId])

  return (
    <div>
      <div id={galleryId}>
        <div className={selectionMode !== PhotoSelectionMode.Select ? 'gallery--enabled' : ''}>
          <>{children}</>
        </div>
      </div>
    </div>
  )
}

const getMediaId = (lightbox: any): string | undefined => {
  const currSlideElement = lightbox?.pswp?.currSlide.data.element
  if (!currSlideElement) {
    return
  }
  return currSlideElement.querySelector('img')?.getAttribute('media-item-id')
}

const giveDeleteButtonNewCursorStyle = (cursorStyle: string) => {
  const deleteButton: any = document.getElementsByClassName('pswp__button--delete')?.[0]
  if (deleteButton) deleteButton.style.cursor = cursorStyle
}

const rotateImageInGallery = (lightbox: any, mediaItem: MediaItemFragment) => {
  if (!mediaItem || !mediaItem.url || !mediaItem.metadata) {
    console.log('nope')
    console.log(mediaItem)
    return
  }
  const { pswp } = lightbox ?? {}
  if (!pswp) return

  const dataSource = pswp.options?.dataSource as any
  if (!dataSource.items) {
    console.log('no datasource items')
    return
  }

  if (pswp.currSlide?.index === undefined || pswp.currSlide?.index < 0) {
    console.log('no current slide index')
    return
  }

  const index = pswp.currSlide.index as number

  const currSlideElement = pswp?.currSlide.data.element
  dataSource.items[index] = {
    src: mediaItem.url,
    width: mediaItem.metadata.width,
    height: mediaItem.metadata.height,
    element: currSlideElement
  }

  pswp?.updateSize(true)
  pswp?.refreshSlideContent(index)
}

const deleteImageFromMediaGallery = (lightbox: any, mediaItem: MediaItemFragment) => {
  if (!mediaItem || !mediaItem.url || !mediaItem.metadata) {
    console.log(mediaItem)
    return
  }

  const { pswp } = lightbox ?? {}
  if (!pswp || !pswp.options) {
    console.log('photoswipe library not properly initialized')
    return
  }

  const dataSource = pswp.options?.dataSource as any
  if (!dataSource.items) {
    console.log('no datasource items')
    return
  }
  console.log(pswp.currSlide?.index)
  if (pswp.currSlide?.index === undefined || pswp.currSlide?.index < 0) {
    console.log('no current slide index')
    return
  }
  //lightbox?.pswp?.ui?.update()
  const index = pswp.currSlide.index as number

  dataSource.items.splice(index, 1)
  console.log(dataSource.items)
  if (dataSource.items.length === 0) {
    // nothing left to render
    pswp?.close()
  } else {
    if (index === 0) {
      pswp.next()
    } else {
      pswp.goTo(index - 1)
    }
    pswp?.updateSize(true)
    pswp.refreshSlideContent(pswp.getNumItems() - 1)
  }
}

const cannotDeleteItem = (lightbox: any): boolean | undefined => {
  const image = getCurrentPhotoGalleryImage(lightbox)
  if (!image) {
    return
  }
  return image?.getAttribute('deleted-status') === 'deleted'
}

const getCurrentPhotoGalleryImage = (lightbox: any) => {
  const currSlideElement = lightbox?.pswp?.currSlide.data.element
  if (!currSlideElement) {
    return
  }
  return currSlideElement.querySelector('img')
}

const isViewingDeletedMedia = (lightbox: any) => {
  const dataSources = (lightbox?.pswp.options?.dataSource?.items as any[]) ?? []
  let isViewingDeletedMediaItems = false
  // loop through each image in the data store and see if any image apart from the current one is deleted
  dataSources.forEach((item: any) => {
    // each item is an a tag with a child img tag
    if (item?.querySelector('img')?.getAttribute('deleted-status') === 'deleted') {
      isViewingDeletedMediaItems = true
    }
  })
  console.log('viewing deleted media? ' + isViewingDeletedMediaItems)
  return isViewingDeletedMediaItems
}
