import { QuestionDefinitions, getNotesGenerator } from '../../helpers/notes-generator'
import {
  ApplianceRepairTopicActionResult,
  applianceRepairQuestionDefinitions
} from '../appliance-shared/appliance-model'
import { ServiceType } from '../action-topic-model'
import { YesNoValue } from '../common/common-enums'

export interface TopicWashingMachineActionResult extends ApplianceRepairTopicActionResult {
  serviceType?: ServiceType
  fault?: WashingMachineFaultEnum
  powerOutletNeeded?: YesNoValue
  issuesWithExistingTaps?: YesNoValue
}

const questionDefinitions: QuestionDefinitions<TopicWashingMachineActionResult> = {
  serviceType: {
    question: 'Is this for installation or repair?',
    statement: 'Service:'
  },
  ...applianceRepairQuestionDefinitions,
  fault: {
    question: 'What is the issue with your washing machine?',
    statement: 'Issue:'
  },
  brand: {
    question: 'What brand is your washing machine?',
    statement: 'Brand:'
  },
  powerOutletNeeded: {
    question: 'Do you need a power point installed?',
    statement: 'Powerpoint installation:'
  },
  issuesWithExistingTaps: {
    question: 'Any existing issues with hot/cold taps?'
  }
}

export const washingMachineRepairNotesGenerator = getNotesGenerator(questionDefinitions)

export const enum WashingMachineFaultEnum {
  PowerSupply = 'PowerSupply',
  OtherFault = 'OtherFault'
}

export const washingMachineFaultOptions = [
  { id: WashingMachineFaultEnum.PowerSupply, label: 'Power Supply' },
  { id: WashingMachineFaultEnum.OtherFault, label: 'Other fault' }
]
