import { EuiFlexGroup, EuiFlexItem, EuiIcon, EuiText, EuiToken } from '@elastic/eui'
import { dateConfig } from '@fallonsolutions/date'
import { DateTime } from 'luxon'
import { useEffect, useState } from 'react'
import {
  InteractionMessageLinkFragment,
  InteractionMessageSource,
  InteractionMessageStatusType
} from '../api/generated-types'
import { formatPhone } from '../common/phone'
import { timeFormatterSeconds } from '../common/time'
import VerticalDivider from '../common/vertical-divider'
import '../static/css/interaction-message-card.css'
import DialpadIcon from '../static/images/dialpad-icon.svg?react'
import PhoneIcon from '../static/images/icon-phone.svg?react'
import { InteractionMessageStatusBadge } from './interaction-message-status-badge'

export enum InteractionMessageCardDisplay {
  Card = 'card',
  Row = 'row',
  ContextMenuItem = 'context-menu-item'
}

export interface InteractionMessageCardProps {
  interactionMessage: InteractionMessageLinkFragment
  display?: InteractionMessageCardDisplay
}

const calculateDuration = (startDate: string, endDate?: string) => {
  const now = endDate
    ? DateTime.fromISO(endDate, { zone: dateConfig.defaultTimezone })
    : DateTime.now().setZone(dateConfig.defaultTimezone)
  const date = DateTime.fromISO(startDate, { zone: dateConfig.defaultTimezone })
  const runningDuration = now.diff(date, ['minutes', 'seconds'])
  return runningDuration
}

export const InteractionMessageCard = ({
  interactionMessage,
  display = InteractionMessageCardDisplay.Card
}: InteractionMessageCardProps) => {
  const startTime = DateTime.fromISO(interactionMessage.created, { zone: dateConfig.defaultTimezone })
    .toFormat(dateConfig.luxonFormat.time)
    .toLowerCase()

  const name = interactionMessage.contact?.fullName ?? 'Unknown'
  const phoneNumber = interactionMessage.externalNumber
    ? formatPhone(interactionMessage.externalNumber)
    : 'Private number'

  const [duration, setDuration] = useState(calculateDuration(interactionMessage.startDate, interactionMessage.endDate))

  useEffect(() => {
    const interval = setInterval(
      () => setDuration(calculateDuration(interactionMessage.startDate, interactionMessage.endDate)),
      1000
    )
    return () => clearInterval(interval)
  }, [interactionMessage.startDate, interactionMessage.endDate])

  const interactionMessageStartTime = <EuiFlexItem grow={false}>{startTime}</EuiFlexItem>

  const interactionMessageIcon = (
    <EuiFlexItem grow={false}>
      <EuiIcon size="s" type={PhoneIcon} color={colorForInteractionMessageStatus(interactionMessage.status.status)} />
    </EuiFlexItem>
  )

  const interactionMessageSource = (
    <EuiFlexItem grow={false} style={{ height: '24px' }}>
      {interactionMessage.source === InteractionMessageSource.Dialpad ? (
        <DialpadIcon style={{ width: '24px' }} />
      ) : (
        <EuiToken iconType="unlink" shape="square" color="#FFFAD1" size="s" style={{ margin: 'auto' }} />
      )}
    </EuiFlexItem>
  )

  const interactionMessageContactName = (
    <EuiFlexItem grow={false}>
      <EuiText color="text" size="s">
        <strong>{name}</strong>
      </EuiText>
    </EuiFlexItem>
  )

  const interactionMessageContactPhone = (
    <EuiFlexItem grow={false}>
      <EuiText color="text" size="s">
        {phoneNumber}
      </EuiText>
    </EuiFlexItem>
  )

  const interactionMessageStatus = (size: 'xs' | 'm') => (
    <EuiFlexItem grow={false}>
      <InteractionMessageStatusBadge status={interactionMessage.status.status} size={size} />
    </EuiFlexItem>
  )

  const interactionMessageDuration = (
    <EuiFlexItem style={{ textAlign: 'right' }} grow={false}>
      {timeFormatterSeconds(duration.as('seconds'))}
    </EuiFlexItem>
  )

  const verticalDivider = (
    <EuiFlexItem grow={false}>
      <VerticalDivider height="24px" />
    </EuiFlexItem>
  )

  switch (display) {
    case InteractionMessageCardDisplay.Row:
      return (
        <EuiFlexGroup
          className="interaction-message-card interaction-message-card--row"
          direction="row"
          gutterSize="m"
          alignItems="center"
        >
          {interactionMessageStatus('m')}
          {interactionMessageIcon}
          {interactionMessageContactPhone}
          {interactionMessageContactName}
          <EuiFlexItem grow={true} />
          {interactionMessageStartTime}
          {verticalDivider}
          {interactionMessageDuration}
          {interactionMessageSource}
        </EuiFlexGroup>
      )
    case InteractionMessageCardDisplay.Card:
      return (
        <EuiFlexGroup
          className="interaction-message-card interaction-message-card--card"
          direction="column"
          gutterSize="s"
          justifyContent="flexStart"
        >
          <EuiFlexItem grow={false}>
            <EuiFlexGroup alignItems="center" gutterSize="s">
              {interactionMessageIcon}
              {interactionMessageStartTime}
              <EuiFlexItem grow={true} />
              {interactionMessageSource}
            </EuiFlexGroup>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiFlexGroup direction="column" gutterSize="none">
              {interactionMessageContactName}
              {interactionMessageContactPhone}
            </EuiFlexGroup>
          </EuiFlexItem>

          <EuiFlexItem>
            <EuiFlexGroup alignItems="center">
              {interactionMessageStatus('m')}
              <EuiFlexItem grow={true} />
              {interactionMessageDuration}
            </EuiFlexGroup>
          </EuiFlexItem>
        </EuiFlexGroup>
      )
    case InteractionMessageCardDisplay.ContextMenuItem:
      return (
        <EuiFlexGroup
          className="interaction-message-card interaction-message-card--context-menu-item"
          direction="row"
          gutterSize="s"
          alignItems="flexStart"
          style={{ minWidth: '260px' }}
        >
          {interactionMessageSource}
          <EuiFlexItem grow={false}>
            <EuiFlexGroup direction="column" gutterSize="none">
              {interactionMessageContactName}
              {interactionMessageContactPhone}
            </EuiFlexGroup>
          </EuiFlexItem>

          <EuiFlexItem grow={true} />
          <EuiFlexItem grow={false}>
            <EuiFlexGroup direction="column" gutterSize="none">
              {interactionMessageStartTime}
            </EuiFlexGroup>
          </EuiFlexItem>
        </EuiFlexGroup>
      )
  }
}

export const colorForInteractionMessageStatus = (status: InteractionMessageStatusType): string => {
  switch (status) {
    case InteractionMessageStatusType.Active:
    case InteractionMessageStatusType.Talking:
      return '#99DDAB'
    case InteractionMessageStatusType.Ringing:
      return '#D796ED'
    case InteractionMessageStatusType.Complete:
      return '#B9C2D0'
    case InteractionMessageStatusType.OnHold:
      return '#99DDAB'
    case InteractionMessageStatusType.Queued:
      return '#99DDAB'
    default:
      return '#99DDAB'
  }
}
