import { BaseActionInput, BaseActionResult, WorkflowActionProps } from '../workflow-model'
import { EuiButton, EuiLink, EuiSpacer, EuiText } from '@elastic/eui'
import { Callout } from '../../common/callout'
import { PillGroup, PillGroupOption } from '../../pill/pill-group'
import { useState } from 'react'
import { DateTime } from 'luxon'
import { dateConfig } from '@fallonsolutions/date'
import { Task } from '../../common/task'
import { CustomerAction, CustomerActionResult } from './action-customer'
import { ContactActionResult } from './action-contact'
import { SelectJobAction, SelectJobActionResult } from './action-select-job'
import { JobSummaryContainer } from '../../jobs/job-summary-container'

export interface ETAActionInput extends BaseActionInput {
  contact?: ContactActionResult
}
export interface ETAActionResult extends BaseActionResult {
  customer?: CustomerActionResult
  job?: SelectJobActionResult
}

export enum ETAScheduleAllocation {
  OnTechnician = 'on-technician',
  OnQueue = 'on-queue'
}

export const ETAAction = (props: WorkflowActionProps<ETAActionInput, ETAActionResult>) => {
  const { onUpdate, input, result } = props
  const { contact } = input
  const { customer, job } = result ?? {}

  const setCustomer = (customer: CustomerActionResult) => onUpdate({ ...result, customer })
  const setJob = (job: SelectJobActionResult) => onUpdate({ ...result, job })
  const completeAction = () => onUpdate({ ...result, actionCompleted: true })

  const jobId = job?.actionCompleted ? job?.job?.id : undefined
  const customerId = customer?.actionCompleted ? customer?.customer?.id : undefined

  const now = DateTime.now().setZone(dateConfig.defaultTimezone)
  const escalationTime = now.startOf('day').set({ hour: 15, minute: 30 })

  const escalationToDSR = now >= escalationTime

  const [contactedDSR, setContactedDSR] = useState(false)
  const [customerOnHold, setCustomerOnHold] = useState(false)
  const [scheduleAllocation, setScheduleAllocation] = useState<ETAScheduleAllocation | undefined>(undefined)

  const scheduleAllocationOptions: PillGroupOption[] = [
    {
      id: ETAScheduleAllocation.OnTechnician,
      label: 'On a technician lane',
      icon: 'user'
    },
    {
      id: ETAScheduleAllocation.OnQueue,
      label: 'On a priority lane or queue',
      icon: 'clock'
    }
  ]

  return (
    <>
      <CustomerAction input={{ contact }} result={customer} onUpdate={setCustomer} />

      {customerId && <SelectJobAction input={{ customerId: customerId }} result={job} onUpdate={setJob} />}

      {jobId && (
        <div className="workflow__detail-wrapper">
          <>
            <JobSummaryContainer jobId={jobId} />
            <EuiSpacer />
            <EuiLink href={`/jobs/${jobId}`} target="_blank">
              Open job card for more details
            </EuiLink>
          </>
        </div>
      )}

      {!escalationToDSR && customerId && jobId && (
        <>
          <EuiSpacer />
          <Callout type="script">
            <EuiText>
              <div>Please bare with me whilst I check the schedule.</div>
            </EuiText>
          </Callout>
          <EuiSpacer size="s" />
          <Callout type="note">Where is the appointment placed?</Callout>
          <EuiSpacer size="s" />
          <PillGroup
            value={scheduleAllocation}
            options={scheduleAllocationOptions}
            onSelect={(id) => setScheduleAllocation(id as ETAScheduleAllocation)}
            collapseOnSelect={false}
          />
          <EuiSpacer />
        </>
      )}

      {customerId && jobId && (escalationToDSR || !!scheduleAllocation) && (
        <>
          {(escalationToDSR || scheduleAllocation === ETAScheduleAllocation.OnQueue) && (
            <>
              {escalationToDSR && (
                <>
                  <EuiSpacer />
                  <Callout type="note">It's after 3.30pm so escalate request to DSR</Callout>
                  <EuiSpacer />
                </>
              )}
              <Callout type="script">
                <EuiText>
                  <div>I will just put you on a brief hold whilst I check with our dispatch team.</div>
                  <EuiSpacer size="s" />
                  <div>Please bare with me for a moment.</div>
                </EuiText>
              </Callout>
              <Task label="Put customer on hold" checked={customerOnHold} onChange={setCustomerOnHold} indent={true} />
              <Task
                label="Contact DSR and confirm status of job"
                checked={contactedDSR}
                onChange={setContactedDSR}
                indent={true}
              />
            </>
          )}

          {(scheduleAllocation === ETAScheduleAllocation.OnTechnician || contactedDSR) && (
            <>
              <Callout type="script">
                <EuiText>
                  <div>Thanks for your patience there {contact?.firstName}.</div>
                  <EuiSpacer size="s" />
                  <div>I was able to confirm that... (relay information to customer)</div>
                </EuiText>
              </Callout>
              <EuiSpacer />

              <EuiButton fill color="primary" onClick={completeAction}>
                I've completed these steps
              </EuiButton>
            </>
          )}
        </>
      )}
    </>
  )
}
