import { SingleSelectField } from '../../question-fields/single-select-question-field'
import { NotesGenerator } from '../../helpers/notes-generator'
import { ResultUpdater } from '../../helpers/results-updater'
import { GutterSize, TopicGutterActionResult } from './action-topic-gutter-model'
import { map } from 'lodash-es'

export interface GutterGuardProps {
  result?: TopicGutterActionResult
  notesGenerator: NotesGenerator<TopicGutterActionResult>
  updateResult: ResultUpdater<TopicGutterActionResult>
}

export const GutterGuard = (props: GutterGuardProps) => {
  const { result, notesGenerator, updateResult } = props
  const { gutterGuardLength } = result ?? {}
  return (
    <>
      <SingleSelectField
        question={notesGenerator.getQuestion('gutterGuardLength')}
        options={map(GutterSize, (id) => ({ id }))}
        answer={gutterGuardLength}
        changeAnswer={(gutterGuardLength) => updateResult({ gutterGuardLength })}
      />
    </>
  )
}
