import {
  DrainRevisitAttendanceDate,
  DrainRevisitIssue,
  DrainServiceType,
  TopicDrainRevisitActionResult
} from './action-topic-drain-revisit-model'
import {
  TopicQualification,
  TopicQualificationResult,
  TopicRequirementsResult,
  createOutcomeGenerator,
  createRequirements
} from '../common/common-outcome'
import { JobType, TradeType } from '../../../api/generated-types'

const GENERAL_CATEGORY = 'General'
const SEWER_CATEGORY = 'Sewer'
const STORMWATER_CATEGORY = 'Stormwater'
const RELINE_CATEGORY = 'Reline'

const GENERAL_DRAIN_SKILL = 'GeneralDrains'
const STORMWATER_SKILL = 'StormwaterDrainageSolutions'

const getRequirements = (result: TopicDrainRevisitActionResult | undefined): TopicRequirementsResult => {
  if (!result) return {}
  const { serviceType, issue, attendanceDate } = result

  const isRevisit =
    serviceType === DrainServiceType.Reline ||
    issue === DrainRevisitIssue.QualityOfWork ||
    (issue === DrainRevisitIssue.DrainBlockedAgain &&
      attendanceDate === DrainRevisitAttendanceDate.LessThanOrEqualTo3Months)

  if (serviceType && issue) {
    if (issue === DrainRevisitIssue.DrainBlockedAgain && !attendanceDate) {
      return {}
    } else {
      const category = categoryForServiceType(serviceType)
      const skill = skillForServiceType(serviceType)
      return {
        requirements: createRequirements([skill]),
        type: isRevisit ? JobType.Callback : JobType.Service,
        category
      }
    }
  }
  return {}
}

const categoryForServiceType = (serviceType: DrainServiceType): string => {
  switch (serviceType) {
    case DrainServiceType.Stormwater:
      return STORMWATER_CATEGORY
    case DrainServiceType.Reline:
      return RELINE_CATEGORY
    case DrainServiceType.Sewer:
      return SEWER_CATEGORY
    default:
      return GENERAL_CATEGORY
  }
}

const skillForServiceType = (serviceType: DrainServiceType): string => {
  switch (serviceType) {
    case DrainServiceType.Stormwater:
      return STORMWATER_SKILL
    case DrainServiceType.Reline:
    case DrainServiceType.Sewer:
    default:
      return GENERAL_DRAIN_SKILL
  }
}

const getQualification = (result: TopicDrainRevisitActionResult | undefined): TopicQualificationResult => {
  if (
    !result?.serviceType ||
    !result?.issue ||
    (result?.issue === DrainRevisitIssue.DrainBlockedAgain && !result?.attendanceDate)
  ) {
    return { qualification: TopicQualification.Unknown }
  }
  return { qualification: TopicQualification.InScope }
}

export const outcomeGenerator = createOutcomeGenerator<TopicDrainRevisitActionResult>(
  {
    qualification: TopicQualification.Unknown,
    trade: TradeType.Drains,
    type: JobType.Callback,
    category: GENERAL_CATEGORY
  },
  getQualification,
  getRequirements
)
