import { PriorityType } from '../../../api/generated-types'
import { AppointmentRequirements } from '@fallonsolutions/appointment'
import { Callout } from '../../../common/callout'
import { EuiSpacer } from '@elastic/eui'

export interface hotWaterPriorityOutComeGenerator {
  getCallouts: JSX.Element
}

export const hotWaterPriorityOutCome = (requirements: AppointmentRequirements | undefined) => {
  return {
    getCallouts: () => (
      <>
        {!!requirements && (
          <>
            <EuiSpacer />
            <Callout type="note">{requirements?.attributes?.map((r) => r.attributeId).join(', ')}</Callout>
            <EuiSpacer />
            {requirements?.priority?.required === PriorityType.P1 && (
              <Callout
                type="warning"
                title="this is a priority 1 job, please book on the HW priority N/S/GC schedule lane"
              />
            )}
            {requirements?.priority?.required === PriorityType.P2 && (
              <Callout
                type="warning"
                title="This is a Priority 2 job, please book on a drains tech without using the designated P2 allocations. Contact DSR if customer rejects next available"
              />
            )}
            {requirements?.priority?.required === PriorityType.P3 && (
              <Callout
                type="warning"
                title="This is a Priority 3 job, please book on a drains tech without using the designated P3 allocations. Contact DSR if customer rejects next available"
              />
            )}
          </>
        )}
      </>
    )
  }
}
