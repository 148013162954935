import { CurveType } from '@elastic/charts'
import moment, { Moment } from 'moment-timezone'
import { DateHistogramInterval, InteractionAnswerStatus } from '../../api/generated-types'
import { ChartAxisType, ChartData, ChartSeriesDisplayType, HistogramChart } from '../../common/histogram-chart'
import { colorForInteractionAnswerStatus } from './interaction-message-list-columns'

export interface InteractionMessageListChartProps {
  interval: DateHistogramInterval
  setInterval: (interval: DateHistogramInterval) => void
}

export const InteractionMessageListChart = ({ interval, setInterval }: InteractionMessageListChartProps) => {
  return (start: Moment, end: Moment, data: any) => {
    const chartData: ChartData = {
      name: 'Calls',
      series: [
        {
          id: 'count',
          name: 'Total calls',
          axisType: ChartAxisType.Count,
          displayType: ChartSeriesDisplayType.Line,
          color: '#6E7689',
          lineSeriesStyle: {
            line: {
              opacity: 0
            }
          },
          data: (data.dateHistogram?.dates ?? []).map((item: any) => {
            return {
              date: moment(item.date).valueOf(),
              value: item.count ?? 0
            }
          })
        },
        {
          id: 'answered',
          name: 'Answered',
          axisType: ChartAxisType.Count,
          displayType: ChartSeriesDisplayType.Area,
          stackedGroup: 0,
          color: colorForInteractionAnswerStatus(InteractionAnswerStatus.Answered),
          curve: CurveType.LINEAR,
          data: (data.dateHistogram?.dates ?? []).map((item: any) => {
            return {
              date: moment(item.date).valueOf(),
              value: item.answeredCount ?? 0
            }
          })
        },
        {
          id: 'missed',
          name: 'Missed',
          axisType: ChartAxisType.Count,
          displayType: ChartSeriesDisplayType.Area,
          stackedGroup: 0,
          color: colorForInteractionAnswerStatus(InteractionAnswerStatus.Missed),
          curve: CurveType.LINEAR,
          data: (data.dateHistogram?.dates ?? []).map((item: any) => {
            return {
              date: moment(item.date).valueOf(),
              value: item.missedCount ?? 0
            }
          })
        },
        {
          id: 'abandoned',
          name: 'Abandoned',
          axisType: ChartAxisType.Count,
          displayType: ChartSeriesDisplayType.Area,
          stackedGroup: 0,
          color: colorForInteractionAnswerStatus(InteractionAnswerStatus.Abandoned),
          curve: CurveType.LINEAR,
          data: (data.dateHistogram?.dates ?? []).map((item: any) => {
            return {
              date: moment(item.date).valueOf(),
              value: item.abandonedCount ?? 0
            }
          })
        },
        {
          id: 'short-abandoned',
          name: 'Short abandoned',
          axisType: ChartAxisType.Count,
          displayType: ChartSeriesDisplayType.Area,
          stackedGroup: 0,
          color: colorForInteractionAnswerStatus(InteractionAnswerStatus.ShortAbandoned),
          curve: CurveType.LINEAR,
          data: (data.dateHistogram?.dates ?? []).map((item: any) => {
            return {
              date: moment(item.date).valueOf(),
              value: item.shortAbandonedCount ?? 0
            }
          })
        },
        {
          id: 'overflow',
          name: 'Overflow',
          axisType: ChartAxisType.Count,
          displayType: ChartSeriesDisplayType.Area,
          color: colorForInteractionAnswerStatus(InteractionAnswerStatus.Overflow),
          stackedGroup: 0,
          curve: CurveType.LINEAR,
          data: (data.dateHistogram?.dates ?? []).map((item: any) => {
            return {
              date: moment(item.date).valueOf(),
              value: item.overflowCount ?? 0
            }
          })
        },
        {
          id: 'after-hours',
          name: 'After hours',
          axisType: ChartAxisType.Count,
          displayType: ChartSeriesDisplayType.Area,
          color: colorForInteractionAnswerStatus(InteractionAnswerStatus.AfterHours),
          stackedGroup: 0,
          curve: CurveType.LINEAR,
          data: (data.dateHistogram?.dates ?? []).map((item: any) => {
            return {
              date: moment(item.date).valueOf(),
              value: item.afterHoursCount ?? 0
            }
          })
        }
      ],
      interval: 'day'
    }
    return (
      <HistogramChart startDate={start} endDate={end} data={chartData} interval={interval} setInterval={setInterval} />
    )
  }
}
