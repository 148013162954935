import { YesNoValue } from '../common/common-enums'
import { TopicHVACInspectionActionResult } from './action-topic-hvac-inspection-model'
import {
  TopicQualification,
  TopicQualificationResult,
  TopicRequirementsResult,
  createOutcomeGenerator,
  createRequirements
} from '../common/common-outcome'
import { CustomerType, JobType, TradeType } from '../../../api/generated-types'

const HVAC_INSPECTION_SKILL = 'HVACInspection'
const INSPECTION_CATEGORY = 'Inspection'

const getRequirements = (result: TopicHVACInspectionActionResult | undefined): TopicRequirementsResult => {
  if (!result) return {}
  const {
    isOutsidePeakPeriod,
    numberOfUnits,
    indoorUnitLocations,
    outdoorUnitLocations,
    hasManHoleAccess,
    lastServiceDate,
    systemAge,
    furnitureNeedToBeMoved
  } = result
  if (isOutsidePeakPeriod === YesNoValue.No) return {}
  const hasLength = (str: string | undefined) => (str ?? '').trim().length > 0
  if (
    hasLength(numberOfUnits) &&
    hasLength(indoorUnitLocations) &&
    hasLength(outdoorUnitLocations) &&
    hasLength(hasManHoleAccess) &&
    hasLength(lastServiceDate) &&
    hasLength(systemAge) &&
    hasLength(furnitureNeedToBeMoved)
  ) {
    return { requirements: createRequirements([HVAC_INSPECTION_SKILL]) }
  }
  return {}
}

const getQualification = (result: TopicHVACInspectionActionResult | undefined): TopicQualificationResult => {
  const { customerType, roofAccess, acType } = result ?? {}
  if (!result || !result.isOutsidePeakPeriod) return { qualification: TopicQualification.Unknown }
  if (customerType === CustomerType.Commercial) {
    if (!roofAccess) {
      return { qualification: TopicQualification.Unknown }
    }
    if (roofAccess === YesNoValue.No) {
      return { qualification: TopicQualification.OutOfScope }
    }
  }

  if (result.isOutsidePeakPeriod === YesNoValue.Yes) {
    if (acType) {
      return { qualification: TopicQualification.InScope }
    } else {
      return { qualification: TopicQualification.Unknown }
    }
  }
  return {
    qualification: TopicQualification.OutOfScope,
    qualificationMessage:
      'Unfortunately, due to capacity this is only available in off peak times - Between April and September.'
  }
}

export const outcomeGenerator = createOutcomeGenerator<TopicHVACInspectionActionResult>(
  {
    qualification: TopicQualification.Unknown,
    trade: TradeType.HVAC,
    type: JobType.Inspection,
    category: INSPECTION_CATEGORY
  },
  getQualification,
  getRequirements
)
