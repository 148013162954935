import { useQuery } from '@apollo/client'
import { EuiBasicTable, EuiCallOut, EuiLoadingSpinner } from '@elastic/eui'
import {
  ScheduledServiceFragment,
  ScheduledServiceStatusType,
  SearchScheduledServiceDocument
} from '../api/generated-types'
import { ScheduledServiceListColumns } from '../scheduled-service/scheduled-service-list-columns'

export interface CustomerLocationScheduledServicesProps {
  customerLocationId: string
  status?: ScheduledServiceStatusType[]
}

export const CustomerLocationScheduledServices = ({
  customerLocationId,
  ...props
}: CustomerLocationScheduledServicesProps) => {
  const status = props.status
    ? props.status
    : [ScheduledServiceStatusType.Pending, ScheduledServiceStatusType.Scheduled, ScheduledServiceStatusType.Cancelled]

  const { data, loading, error } = useQuery(SearchScheduledServiceDocument, {
    variables: {
      input: {
        filters: {
          status,
          customerLocationId: [customerLocationId]
        }
      }
    }
  })

  const onClickEdit = (scheduledService: ScheduledServiceFragment) => {
    console.log('onClickEdit', scheduledService)
  }

  const services = data?.searchScheduledService?.results ?? []
  const columns = ScheduledServiceListColumns({ onClickEdit })

  return loading ? (
    <EuiLoadingSpinner />
  ) : error ? (
    <EuiCallOut title="Error loading scheduled services" color="danger">
      {error.message}
    </EuiCallOut>
  ) : (
    <EuiBasicTable items={services} columns={columns} />
  )
}
