import { EuiButton, EuiSpacer, EuiText } from '@elastic/eui'
import { Callout } from '../../common/callout'
import { WorkflowAction } from '../workflow-action'
import { SelectedCustomer } from '../workflow-customer-model'
import { BaseActionInput, BaseActionResult, WorkflowActionProps } from '../workflow-model'
import { ReservationDetail } from './schedule-reservation/action-schedule-reservation'
import { SelectedJob } from './action-select-job'

export enum JobNotBookedReason {
  HPPPurchase = 'hpp-purchase',
  TravelFee = 'travel-fee',
  WantedAQuoteOnly = 'wanted-quote-only',
  OutOfScope = 'out-of-scope',
  OutOfArea = 'out-of-area',
  Other = 'other'
}

export interface PostBookingActionInput extends BaseActionInput {
  customer: SelectedCustomer
  job: SelectedJob
  existingCustomer: boolean
  reservationDetail?: ReservationDetail
  emailSent?: boolean
}
export type PostBookingActionResult = BaseActionResult

export const PostBookingAction = (props: WorkflowActionProps<PostBookingActionInput, PostBookingActionResult>) => {
  const { input, onUpdate, result } = props

  const { customer, existingCustomer } = input ?? {}
  const { actionCompleted } = result ?? {}

  const emailSent = input.emailSent ?? false
  // const enquiryOptions: PillGroupOption[] = [
  //   { id: JobNotBookedReason.HPPPurchase, label: 'HPP Purchase', icon: 'securitySignal' },
  //   { id: JobNotBookedReason.TravelFee, label: 'Travel Fee', icon: 'visMapRegion' },
  //   { id: JobNotBookedReason.WantedAQuoteOnly, label: 'Customer wanted a quote only', icon: 'iInCircle' },
  //   {
  //     id: JobNotBookedReason.OutOfArea,
  //     label: `We do not service the customer's suburb`,
  //     icon: 'offline'
  //   },
  //   { id: JobNotBookedReason.OutOfScope, label: 'We do not do what the customer wanted', icon: 'eyeClosed' },
  //   { id: JobNotBookedReason.Other, label: 'Other', icon: 'questionInCircle' }
  // ]

  const setActionCompleted = (actionCompleted: boolean) => onUpdate({ ...result, actionCompleted })

  const onContinue = async (actionCompleted: boolean) => {
    setActionCompleted(actionCompleted)
  }

  return (
    <WorkflowAction
      title="Post booking call"
      value={actionCompleted ? 'Completed' : undefined}
      onClickChange={() => setActionCompleted(false)}
    >
      <>
        <Callout type="note">
          <EuiText>
            <ul>
              {emailSent ? (
                <li>A booking confirmation for this job has been sent to the customer.</li>
              ) : (
                <li>
                  Booking confirmation email was not sent, follow the manual process to send booking confirmation.
                </li>
              )}
            </ul>
          </EuiText>
        </Callout>
      </>

      <EuiSpacer />
      <EuiButton fill color="primary" onClick={() => onContinue(true)}>
        I've completed this step
      </EuiButton>
    </WorkflowAction>
  )
}
