import { EuiButton, EuiSpacer } from '@elastic/eui'
import { JobType } from '../../../api/generated-types'
import { Callout } from '../../../common/callout'
import { getResultUpdater } from '../../helpers/results-updater'
import { SingleSelectField } from '../../question-fields/single-select-question-field'
import { WorkflowActionProps } from '../../workflow-model'
import { TopicActionInput } from '../action-topic-model'
import { TopicWorkflowAction } from '../action-topic-view'
import { YesNoValue } from '../common/common-enums'
import { TopicQualification } from '../common/common-outcome'
import { TopicPlumbingRevisitActionResult, plumbingRevisitNotesGenerator } from './action-topic-plumbing-revisit-model'
import { outcomeGenerator } from './action-topic-plumbing-revisit-outcome'

export const TopicPlumbingRevisitAction = (
  props: WorkflowActionProps<TopicActionInput, TopicPlumbingRevisitActionResult>
) => {
  const { result, input, onUpdate } = props
  const { sameIssueAsOriginalJob, issueWithNewInstall, qualityOfWork } = result ?? {}

  const notesGenerator = plumbingRevisitNotesGenerator
  const topicOutcome = outcomeGenerator(result)
  const canComplete = topicOutcome.qualification === TopicQualification.InScope

  const onNext = () =>
    onUpdate({
      ...result,
      actionCompleted: true,
      ...topicOutcome,

      workRequiredNotes: generatedNotes
    })

  const generatedNotes = notesGenerator.generateNotes(result ?? {})
  const updateResult = getResultUpdater(result ?? {}, onUpdate).updateResult

  return (
    <TopicWorkflowAction input={input} onUpdate={onUpdate} result={result}>
      <Callout type="note" title="Check previous job number and notes for details" />
      <EuiSpacer />

      <SingleSelectField
        question={notesGenerator.getQuestion('sameIssueAsOriginalJob')}
        options={[
          {
            id: YesNoValue.Yes,
            label: 'Yes, same issue as original job',
            icon: 'check'
          },
          {
            id: YesNoValue.No,
            label: 'No',
            icon: 'cross'
          }
        ]}
        answer={sameIssueAsOriginalJob}
        changeAnswer={(sameIssueAsOriginalJob) =>
          updateResult({ sameIssueAsOriginalJob, issueWithNewInstall: undefined, qualityOfWork: undefined })
        }
      />

      {sameIssueAsOriginalJob === YesNoValue.No && (
        <SingleSelectField
          question={notesGenerator.getQuestion('issueWithNewInstall')}
          options={[
            {
              id: YesNoValue.Yes,
              label: 'Yes, issue with new install',
              icon: 'check'
            },
            {
              id: YesNoValue.No,
              label: 'No',
              icon: 'cross'
            }
          ]}
          answer={issueWithNewInstall}
          changeAnswer={(issueWithNewInstall) => updateResult({ issueWithNewInstall, qualityOfWork: undefined })}
        />
      )}

      {issueWithNewInstall === YesNoValue.No && (
        <SingleSelectField
          question={notesGenerator.getQuestion('qualityOfWork')}
          options={[
            {
              id: YesNoValue.Yes,
              label: 'Yes, issue with quality of work',
              icon: 'check'
            },
            {
              id: YesNoValue.No,
              label: 'No',
              icon: 'cross'
            }
          ]}
          answer={qualityOfWork}
          changeAnswer={(qualityOfWork) => updateResult({ qualityOfWork })}
        />
      )}

      {topicOutcome.requirements && (
        <>
          <EuiSpacer />
          <Callout
            type="note"
            title={
              topicOutcome.type === JobType.Callback
                ? 'Revisit job'
                : 'Normal service job, tech will determine if travel fee required on site'
            }
          />
          <Callout type="note">{topicOutcome.requirements.attributes?.map((r) => r.attributeId).join(', ')}</Callout>
        </>
      )}

      <EuiSpacer />
      <EuiButton disabled={!canComplete} onClick={() => onNext()}>
        Next
      </EuiButton>
    </TopicWorkflowAction>
  )
}
