import { EuiContextMenuPanelDescriptor, EuiContextMenuPanelItemDescriptor, EuiHealth } from '@elastic/eui'
import { compact } from 'lodash-es'
import {
  ScheduleEventDetailFragment,
  ScheduleEventFragment,
  ScheduleEventStatusType,
  UpdateScheduleEventStatusMutationVariables
} from '../../api/generated-types'
import { labelOf } from '../../common/utils'
import { colorForScheduleEventStatusType } from '../schedule-event-status-type'

export interface ScheduleEventContextMenuStatusPanelProps {
  event: ScheduleEventFragment
  eventDetail?: ScheduleEventDetailFragment
  updateStatus: (params: { variables: UpdateScheduleEventStatusMutationVariables }) => void
  onActionClick?: (() => void) | undefined
}

export const ScheduleEventContextMenuStatusPanel = ({
  event,
  updateStatus,
  onActionClick,
  eventDetail
}: ScheduleEventContextMenuStatusPanelProps): EuiContextMenuPanelDescriptor => {
  const statusInput = (status: ScheduleEventStatusType) => {
    return {
      input: {
        id: event.id,
        status
      }
    }
  }

  //most likely status options based on current state
  const primaryStatusOptions = () => {
    return [
      ScheduleEventStatusType.WaitingForAllocation,
      ScheduleEventStatusType.Pending,
      ScheduleEventStatusType.Dispatched,
      ScheduleEventStatusType.Acknowledged,
      ScheduleEventStatusType.EnRoute,
      ScheduleEventStatusType.OnSite,
      ScheduleEventStatusType.InProgress,
      ScheduleEventStatusType.Completed
    ]
  }

  const availableItems = eventDetail?.availableOfficeStatuses?.map((status) => status.status) ?? primaryStatusOptions()

  const getStatusMenuItem = (status: ScheduleEventStatusType): EuiContextMenuPanelItemDescriptor => {
    return {
      name: labelOf(status),
      icon: getStatusIcon(status),
      onClick: () => {
        updateStatus({ variables: statusInput(status) })
        onActionClick && onActionClick()
      }
    }
  }

  return {
    id: 'change-status',
    size: 's',
    title: 'Change status',
    items: compact(availableItems).map(getStatusMenuItem)
  }
}

export const getStatusIcon = (status?: ScheduleEventStatusType) => {
  const color = colorForScheduleEventStatusType(status)
  return <EuiHealth color={color} style={{ width: '16px' }} />
}
