import { BaseActionInput, BaseActionResult, WorkflowActionProps } from '../workflow-model'
import { EuiButton, EuiIcon, EuiKeyPadMenu, EuiKeyPadMenuItem, EuiSpacer, EuiText } from '@elastic/eui'
import { Callout } from '../../common/callout'
import { WorkflowAction } from '../workflow-action'
import { RenewMembershipOption } from './action-renew-membership'

export type RenewMembershipOptionActionInput = BaseActionInput
export interface RenewMembershipOptionActionResult extends BaseActionResult {
  renewMembershipOption?: RenewMembershipOption
}

export const RenewMembershipOptionAction = (
  props: WorkflowActionProps<RenewMembershipOptionActionInput, RenewMembershipOptionActionResult>
) => {
  const { onUpdate, result, input } = props
  const { editable } = input
  const { actionCompleted, renewMembershipOption } = result ?? {}

  const options = [
    {
      id: RenewMembershipOption.HppExtensionRed,
      label: 'HPP Extension Red',
      icon: 'starEmpty',
      isSelected: renewMembershipOption === RenewMembershipOption.HppExtensionRed
    },
    {
      id: RenewMembershipOption.HppExtensionBlack,
      label: 'HPP Extension Black',
      icon: 'starFilled',
      isSelected: renewMembershipOption === RenewMembershipOption.HppExtensionBlack
    }
  ]

  const setActionCompleted = (actionCompleted: boolean) => onUpdate({ ...result, actionCompleted })

  const handleOnSelectOption = (id: string) => {
    onUpdate({ renewMembershipOption: id as RenewMembershipOption })
  }

  const handleChange = () => {
    onUpdate({ renewMembershipOption: undefined })
  }

  const selectedOption = result?.renewMembershipOption
  const selectedOptionLabel = options.filter((o) => o.id === selectedOption)?.[0]?.label

  const allTasksCompleted = !!renewMembershipOption

  return (
    <WorkflowAction
      title="Membership option"
      value={actionCompleted ? selectedOptionLabel : undefined}
      onClickChange={handleChange}
      editable={editable}
    >
      <Callout type="note" title="Select the membership plan" />
      <EuiSpacer />

      <EuiKeyPadMenu className="fs-keypadmenu">
        {options.map((option) => (
          <EuiKeyPadMenuItem
            key={option.id}
            id={option.id}
            label={option.label}
            isSelected={option.isSelected}
            onClick={() => handleOnSelectOption(option.id)}
          >
            <EuiIcon type={option.icon} size="xl" />
          </EuiKeyPadMenuItem>
        ))}
      </EuiKeyPadMenu>

      {renewMembershipOption === RenewMembershipOption.HppExtensionRed && (
        <>
          <EuiSpacer />
          <Callout type="script" className="callout--flat-list">
            <EuiText>
              <ul>
                <li>
                  Now in order to renew your membership, It will be just be a one off cost of{' '}
                  <strong>$60 inc. GST</strong>
                </li>
                <li>I can do that over the phone with you now if you’d like?</li>
              </ul>
            </EuiText>
          </Callout>
        </>
      )}
      {renewMembershipOption === RenewMembershipOption.HppExtensionBlack && (
        <>
          <EuiSpacer />
          <Callout type="script" className="callout--flat-list">
            <EuiText>
              <ul>
                <li>
                  Now in order to renew your membership, It will be just be a one off cost of{' '}
                  <strong>$165 inc. GST</strong>
                </li>
                <li>I can do that over the phone with you now if you’d like?</li>
              </ul>
            </EuiText>
          </Callout>
        </>
      )}
      <EuiSpacer size="s" />
      <EuiButton disabled={!allTasksCompleted} onClick={() => setActionCompleted(true)}>
        Next
      </EuiButton>
      <EuiSpacer />
    </WorkflowAction>
  )
}
