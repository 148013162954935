import { useMutation } from '@apollo/client'
import { EuiContextMenu, EuiContextMenuPanelDescriptor, EuiLoadingSpinner, EuiPopover } from '@elastic/eui'
import { find } from 'lodash-es'
import { useState } from 'react'
import { JobFragment, UpdateJobBranchDocument, UpdateJobBranchInput } from '../api/generated-types'
import { JobBranch } from './job-branch'

interface JobBranchEditProps {
  job: JobFragment
  closeModal: () => void
  editable?: boolean
}

export const JobBranchEdit = (props: JobBranchEditProps) => {
  const { job, editable } = props
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const canUpdateJobBranch = editable

  const [updateJobBranch, { loading }] = useMutation(UpdateJobBranchDocument)

  interface BranchOption {
    id: string
    label: string
  }

  const branchOptions: BranchOption[] = [
    { id: 'brisbane', label: 'Brisbane' },
    { id: 'sunshine-coast', label: 'Sunshine Coast' },
    {
      id: 'gold-coast',
      label: 'Gold Coast'
    }
  ]

  const currentBranch = find(branchOptions, (b) => b.label === job?.branch?.name) || null

  const handleBranchUpdate = async (branchId: string) => {
    setIsMenuOpen(false)
    try {
      const input: UpdateJobBranchInput = { job: job.id, branch: branchId }
      await updateJobBranch({ variables: { input }, refetchQueries: ['GetJob'], awaitRefetchQueries: true })
    } catch (err) {
      console.error('TODO: toast this? error changing branch', err)
    }
  }

  const menu: EuiContextMenuPanelDescriptor[] = [
    {
      id: 0,
      title: 'Set branch',
      items: branchOptions.map((option) => {
        return {
          name: option.label,
          disabled: !canUpdateJobBranch,
          toolTipContent: canUpdateJobBranch ? undefined : 'Branch cannot be updated',
          onClick: () => handleBranchUpdate(option.id)
        }
      })
    }
  ]

  return canUpdateJobBranch ? (
    <EuiPopover
      isOpen={isMenuOpen}
      ownFocus={true}
      closePopover={() => setIsMenuOpen(false)}
      panelPaddingSize="none"
      button={
        <button onClick={() => setIsMenuOpen(!isMenuOpen)}>
          {loading ? <EuiLoadingSpinner size="m" /> : <JobBranch branch={currentBranch?.label} editable={true} />}
        </button>
      }
    >
      <EuiContextMenu initialPanelId={0} panels={menu}></EuiContextMenu>
    </EuiPopover>
  ) : (
    <>
      <JobBranch branch={currentBranch?.label} editable={editable} />
    </>
  )
}
