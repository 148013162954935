import { useQuery } from '@apollo/client'
import { EuiComboBox, EuiComboBoxOptionOption, EuiHealth, EuiHighlight } from '@elastic/eui'
import { compact } from 'lodash-es'
import { useEffect, useState } from 'react'
import { CoordinatesInput, SearchServiceAreasDocument, ServiceAreaFilters } from '../api/generated-types'
import { useDebounce } from '../common/use-debounce'
import '../static/css/combo-box.css'

export interface SuburbSelectOption {
  id: string
  label: string
  color?: string | undefined
}

export interface SuburbSelectOptionDetail extends SuburbSelectOption {
  data: {
    postcode: string
    suburb: string
    state: string
    serviced: boolean
  }
}

interface SuburbSelectProps {
  suburbs: SuburbSelectOption[]
  onChangeSuburbs: (suburbs: SuburbSelectOptionDetail[]) => void
  singleSelection?: boolean
  compact?: boolean
  placeholder?: string
}

export const SuburbSelect = (props: SuburbSelectProps) => {
  const selectedOptions = props.suburbs
  const onChangeSelectedOptions = props.onChangeSuburbs

  const [term, setTerm] = useState('')
  const [options, setOptions] = useState<Array<EuiComboBoxOptionOption<string>>>(selectedOptions ?? [])
  // const [comboInput, setComboInput] = useState<HTMLInputElement | null>(null)

  const debounceTerm = useDebounce(term, 100)

  const singleSelection = props.singleSelection ?? true
  const placeholder = props.placeholder ?? undefined

  // Mansfield HQ
  const biasPosition: CoordinatesInput = {
    lon: 153.0982269,
    lat: -27.5279455
  }

  const filters: ServiceAreaFilters = {
    query: debounceTerm,
    biasPosition,
    companys: ['fallonsolutions']
  }

  const {
    data: serviceArea,
    loading: serviceAreaLoading,
    error: serviceAreaError
  } = useQuery(SearchServiceAreasDocument, {
    variables: { input: { filters } },
    skip: debounceTerm.length === 0 || selectedOptions.length > 0
  })

  if (serviceAreaError) {
    console.error('error', serviceAreaError)
  }

  useEffect(() => {
    if (serviceArea?.searchServiceAreas?.results?.length) {
      const serviceAreas = compact(serviceArea?.searchServiceAreas?.results ?? [])
      const newOptions: SuburbSelectOptionDetail[] = (debounceTerm.length > 0 ? serviceAreas : []).map(
        (serviceArea) => {
          const { id, serviced, state, postcode } = serviceArea
          const data = { serviced, suburb: serviceArea.name, state, postcode }
          return {
            id,
            label: `${serviceArea.name} ${state} ${postcode}`,
            color: serviced ? 'success' : 'danger',
            className: singleSelection ? 'combo-box-single-select' : '',
            data
          }
        }
      )
      setOptions(newOptions)
    }
  }, [serviceArea, debounceTerm.length, singleSelection])

  const renderSuburbOption = (option: any, searchValue: string, _contentClassName: string) => {
    return (
      <EuiHealth color={option.color}>
        <EuiHighlight search={searchValue}>{option.label}</EuiHighlight>
      </EuiHealth>
    )
  }

  // const isInvalid = () => {
  //   return selectedOptions.length > 0 && selectedOptions[0].color === 'danger'
  // }

  const handleOnChangeSuburbs = (options: Array<EuiComboBoxOptionOption<string>>) => {
    onChangeSelectedOptions(options as SuburbSelectOptionDetail[])
  }

  const onSearchChange = (searchValue: string, _hasMatchingOptions?: boolean) => setTerm(searchValue)

  return (
    <EuiComboBox
      async
      placeholder={placeholder}
      options={options}
      selectedOptions={selectedOptions}
      onChange={handleOnChangeSuburbs}
      isClearable={true}
      renderOption={renderSuburbOption}
      rowHeight={40}
      isLoading={serviceAreaLoading && options.length <= 0}
      style={{ minWidth: '260px' }}
      singleSelection={singleSelection ? { asPlainText: true } : false}
      onSearchChange={onSearchChange}
      compressed={props.compact ?? false}
      noSuggestions={debounceTerm.length <= 0}
      aria-label="suburb-select"
      data-test-subj="suburb-select"
      className="combo-box-single-select"
    />
  )
}
