import { Route, Switch, useRouteMatch } from 'react-router-dom'
import { InteractionMessageDetailContainer } from './interaction-message-detail/interaction-message-detail-container'
import { InteractionMessageListContainer } from './interaction-message-list/interaction-message-list-container'

const InteractionMessageRoutes = () => {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route exact path={path} component={InteractionMessageListContainer} />
      <Route path={`${path}/:id/:tab?`} component={InteractionMessageDetailContainer} />
      <Route exact path={`${path}/p/:index`} component={InteractionMessageListContainer} />
    </Switch>
  )
}

export default InteractionMessageRoutes
