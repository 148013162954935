import { useLazyQuery, useQuery } from '@apollo/client'
import {
  EuiAvatar,
  EuiButtonEmpty,
  EuiComboBox,
  EuiComboBoxOptionOption,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHighlight
} from '@elastic/eui'
import { useCallback, useEffect, useState } from 'react'
import {
  AllCustomersDocument,
  CustomerFilters,
  CustomerLinkFragment,
  GetCustomersDocument
} from '../api/generated-types'
import { useDebounce } from '../common/use-debounce'
import '../static/css/combo-box.css'

export interface CustomerComboBoxItem extends EuiComboBoxOptionOption<string> {
  id: string
  label: string
  name: string
  reference: string
  firstName?: string
  lastName?: string
}

export interface CustomerComboBoxProps {
  initialCustomerIds?: string[]
  singleSelection?: boolean
  customers: CustomerComboBoxItem[]
  onChangeCustomers: (customers: CustomerComboBoxItem[]) => void
}

const CustomerComboBox = (props: CustomerComboBoxProps) => {
  const { customers, onChangeCustomers, initialCustomerIds } = props
  const [term, setTerm] = useState('')
  const [enabled, setEnabled] = useState(customers.length > 0)
  const [comboInput, setComboInput] = useState<HTMLInputElement | null>(null)

  const singleSelection = props.singleSelection ?? false

  const [getInitialCustomers, { data: initialCustomersData }] = useLazyQuery(GetCustomersDocument)
  const initialCustomers = initialCustomersData?.getCustomers?.customers
  useEffect(() => {
    if (initialCustomerIds && initialCustomerIds.length > 0) {
      getInitialCustomers({
        variables: {
          input: {
            customerIds: initialCustomerIds
          }
        }
      })
    }
  }, [initialCustomerIds, getInitialCustomers])
  useEffect(() => {
    if (initialCustomers && initialCustomers.length > 0) {
      setEnabled(true)
      onChangeCustomers(
        initialCustomers.map((customer: CustomerLinkFragment) => ({
          id: customer.id,
          label: customer.mainContact?.detail?.fullName ?? customer.number ?? 'No name',
          name: customer.mainContact?.detail?.fullName ?? 'No name',
          reference: customer.number ?? 'No reference',
          firstName: customer.mainContact?.detail?.firstName ?? undefined,
          lastName: customer.mainContact?.detail?.lastName ?? undefined,
          className: singleSelection ? 'combo-box-single-select' : ''
        }))
      )
    }
  }, [initialCustomers])

  const debounceTerm = useDebounce(term, 150)

  const input: CustomerFilters = {}
  if (debounceTerm.length > 0) {
    input.query = debounceTerm
  }

  const variables = {
    input: input,
    from: 0,
    size: 10
  }
  const { data, loading, error } = useQuery(AllCustomersDocument, { variables })

  let options: any[]
  if (data && (data?.allCustomers?.customers?.length ?? 0) > 0) {
    options =
      data.allCustomers.customers.map((customer: any) => {
        return {
          id: customer.id,
          label: customer.mainContact?.detail?.fullName ?? customer.number ?? 'No name',
          name: customer.mainContact?.detail?.fullName ?? 'No name',
          reference: customer.number,
          firstName: customer.mainContact?.detail?.firstName ?? undefined,
          lastName: customer.mainContact?.detail?.lastName ?? undefined,
          className: singleSelection ? 'combo-box-single-select' : ''
        }
      }) ?? []
  } else {
    options = []
  }

  if (error) {
    console.error('error', error)
  }

  const onSearchChange = useCallback((searchValue: string, _hasMatchingOptions?: boolean) => {
    setTerm(searchValue)
  }, [])

  useEffect(() => {
    // Simulate initial load
    onSearchChange('')
  }, [onSearchChange])

  const renderCustomerOption = (option: any, searchValue: any, contentClassName: any) => {
    return (
      <EuiFlexGroup gutterSize="s" alignItems="center">
        <EuiFlexItem grow={false}>
          <EuiAvatar size="s" name={option.name} />
        </EuiFlexItem>
        <EuiFlexItem grow={true}>
          <span className={contentClassName}>
            <EuiHighlight search={searchValue}>{option.label}</EuiHighlight>
          </span>
        </EuiFlexItem>
        <EuiFlexItem grow={false} className="small-label" style={{ lineHeight: 'inherit' }}>
          {option.reference}
        </EuiFlexItem>
      </EuiFlexGroup>
    )
  }

  const onAddFilter = () => {
    setEnabled(true)
    setTimeout(() => comboInput?.focus(), 50)
  }

  const onBlur = () => {
    if (customers.length <= 0) {
      setEnabled(false)
    }
  }

  const label = 'customer'
  return (
    <form autoComplete="off" id="form123">
      <EuiComboBox
        inputRef={setComboInput}
        async
        placeholder="Customers"
        options={options}
        selectedOptions={customers}
        onChange={(customers) => onChangeCustomers(customers as CustomerComboBoxItem[])}
        isClearable={true}
        renderOption={renderCustomerOption}
        rowHeight={40}
        isLoading={loading}
        singleSelection={singleSelection}
        onSearchChange={onSearchChange}
        style={{ minWidth: '240px' }}
        hidden={!enabled}
        onBlur={onBlur}
        aria-label={label}
        data-test-subj={label}
      />
      {!enabled && (
        <EuiButtonEmpty iconType="filter" flush="both" onClick={onAddFilter}>
          Customer
        </EuiButtonEmpty>
      )}
    </form>
  )
}

export default CustomerComboBox
