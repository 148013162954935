import { QuestionDefinitions, getNotesGenerator } from '../../helpers/notes-generator'
import { TopicActionResult } from '../action-topic-model'

export interface TopicDrainRevisitActionResult extends TopicActionResult {
  serviceType?: DrainServiceType
  issue?: DrainRevisitIssue
  attendanceDate?: DrainRevisitAttendanceDate
}

export enum DrainServiceType {
  Stormwater = 'Stormwater',
  Sewer = 'Sewer',
  Reline = 'Reline'
}

export enum DrainRevisitIssue {
  DrainBlockedAgain = 'Drain blocked again',
  QualityOfWork = 'Quality of work',
  DrainBlockedAfterReline = 'Drain blocked after reline'
}

export enum DrainRevisitAttendanceDate {
  LessThanOrEqualTo3Months = 'Within last 3 months',
  GreaterThan3Months = 'Over 3 months ago'
}

const questionDefinitions: QuestionDefinitions<TopicDrainRevisitActionResult> = {
  serviceType: {
    question: 'What type of drain work was performed?',
    statement: 'Service:'
  },
  issue: {
    question: 'What is the issue?',
    statement: 'Issue:'
  },
  attendanceDate: {
    question: 'When did we last attend?',
    statement: 'Attendance date:'
  }
}

export const drainRevisitNotesGenerator = getNotesGenerator(questionDefinitions)
