import { EuiSwitch } from '@elastic/eui'

interface SandboxSwitchProps {
  enabled: boolean
  onChange: (enabled: boolean) => void
}

export const SandboxSwitch = (props: SandboxSwitchProps) => {
  const { enabled, onChange } = props

  return <EuiSwitch label="Sandbox" checked={enabled} onChange={() => onChange(!enabled)} compressed />
}
