import { EuiAvatar, EuiFlexGroup, EuiFlexItem, EuiText } from '@elastic/eui'
import { parsePhoneNumberFromString } from 'libphonenumber-js'

interface UserCardProps {
  user: any
  avatarSize?: 's' | 'm' | 'l' | 'xl'
  showContact?: boolean
}

const UserCard = (props: UserCardProps) => {
  const { user } = props
  const avatarSize = props.avatarSize ?? 'l'
  const showContact = props.showContact ?? true
  return (
    <EuiFlexGroup gutterSize={avatarSize === 'xl' ? 'm' : 's'}>
      <EuiFlexItem grow={false}>
        <EuiAvatar
          size={avatarSize}
          name={user?.contactDetail?.fullName ?? user?.label ?? 'No name'}
          imageUrl={user?.avatar}
        />
      </EuiFlexItem>
      <EuiFlexItem>
        <EuiText size={avatarSize === 'xl' ? 'm' : 's'}>
          <div>
            <strong>{user?.contactDetail?.fullName ?? user?.label}</strong>
          </div>
          {showContact && (
            <div>
              <div>
                <a href={`tel:${user?.contactDetail?.phone}`}>{formatPhone(user?.contactDetail?.phone)}</a>
              </div>
              {user?.contactDetail?.email && (
                <div>
                  <a href={`mailto:${user?.contactDetail?.email}}`}>{user?.contactDetail?.email}</a>
                </div>
              )}
            </div>
          )}
        </EuiText>
      </EuiFlexItem>
    </EuiFlexGroup>
  )
}

const formatPhone = (phone: string): string => {
  if (!phone) {
    return '-'
  }
  const phoneNumber = parsePhoneNumberFromString(phone)
  if (phoneNumber?.isValid()) {
    return phoneNumber.formatNational()
  } else {
    return '-'
  }
}

export default UserCard
