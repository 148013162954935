import { TopicPlumbingRevisitActionResult } from './action-topic-plumbing-revisit-model'
import {
  TopicQualification,
  TopicQualificationResult,
  TopicRequirementsResult,
  createOutcomeGenerator,
  createRequirements
} from '../common/common-outcome'
import { JobType, TradeType } from '../../../api/generated-types'
import { YesNoValue } from '../common/common-enums'

const GENERAL_CATEGORY = 'General'
const GENERAL_PLUMBING_SKILL = 'GeneralPlumbing'

const getRequirements = (result: TopicPlumbingRevisitActionResult | undefined): TopicRequirementsResult => {
  if (!result) return {}
  const { sameIssueAsOriginalJob, issueWithNewInstall, qualityOfWork } = result

  const isRevisit =
    sameIssueAsOriginalJob === YesNoValue.Yes ||
    issueWithNewInstall === YesNoValue.Yes ||
    qualityOfWork === YesNoValue.Yes

  console.log('isRevisit?', isRevisit)
  if (isRevisit || (sameIssueAsOriginalJob && issueWithNewInstall && qualityOfWork)) {
    return {
      requirements: createRequirements([GENERAL_PLUMBING_SKILL]),
      type: isRevisit ? JobType.Callback : JobType.Service,
      category: GENERAL_CATEGORY
    }
  } else {
    return {}
  }
}

const getQualification = (result: TopicPlumbingRevisitActionResult | undefined): TopicQualificationResult => {
  const { sameIssueAsOriginalJob, issueWithNewInstall, qualityOfWork } = result ?? {}
  const isRevisit =
    sameIssueAsOriginalJob === YesNoValue.Yes ||
    issueWithNewInstall === YesNoValue.Yes ||
    qualityOfWork === YesNoValue.Yes
  if (isRevisit || !!qualityOfWork) {
    return { qualification: TopicQualification.InScope }
  } else {
    return { qualification: TopicQualification.Unknown }
  }
}

export const outcomeGenerator = createOutcomeGenerator<TopicPlumbingRevisitActionResult>(
  {
    qualification: TopicQualification.Unknown,
    trade: TradeType.Plumbing,
    type: JobType.Callback,
    category: GENERAL_CATEGORY
  },
  getQualification,
  getRequirements
)
