import { useApp } from '../app/app-context'
import { useAuthenticated } from '../auth/authenticated-context'

import {
  EuiButton,
  EuiConfirmModal,
  EuiFlexGroup,
  EuiFlexItem,
  EuiLoadingSpinner,
  EuiPanel,
  EuiSpacer
} from '@elastic/eui'
import { JobTopic, JobTopicType, JobTopics } from '@fallonsolutions/types'
import { Dict } from 'mixpanel-browser'
import { ReactNode, useState } from 'react'
import {
  EnquiryFragment,
  EnquiryStatusType,
  InteractionMessageFragment,
  UserCallCenterParticipationType
} from '../api/generated-types'
import { useMixpanel } from '../app/mixpanel-provider'
import { formatPhone } from '../common/phone'
import RawJSON from '../common/raw-json'
import { AbortEnquiryAction } from './actions/action-abort-enquiry'
import { EnquiryTypeAction, EnquiryTypeActionResult } from './actions/action-enquiry-type'
import { useEnquiryService } from './enquiry-service'
import { WorkflowProvider } from './helpers/workflow-provider'
import { EnquiryPersistenceEnum } from './interaction-message-create-enquiry-service'
import { SelectedCustomer } from './workflow-customer-model'
import {
  OnChangeContactProps,
  getCustomerForEnquiryType,
  toSelectedCustomer
} from './workflow-interaction-message-model'
import { BaseActionResult, EnquiryAbortDetails, EnquiryTypeReference } from './workflow-model'
import { ActionForEnquiryType, NewEnquiryResult } from './workflow-new-enquiry-model'

export const NEW_CUSTOMER_KEY = 'NEW_CUSTOMER'

export interface InteractionMessageNewEnquiryWorkflowProps {
  interactionMessage: InteractionMessageFragment
  enquiry: EnquiryFragment
  onChangeContact: ({ contactInput, contactId, customer }: OnChangeContactProps) => Promise<void>
  initialResult?: NewEnquiryResult
}

export interface ActionUpdateEnquiryDefaults {
  customer: SelectedCustomer | undefined
  customerKey: string | undefined
  isNewCustomer?: boolean
}

export const WorkflowInteractionMessageNewEnquiryWorkflow = (props: InteractionMessageNewEnquiryWorkflowProps) => {
  const appContext = useApp()
  const mixpanel = useMixpanel()
  const userFragment = useAuthenticated().userFragment

  const canViewActiveInteractions = userFragment.callCenter?.participation === UserCallCenterParticipationType.Active

  const { interactionMessage, enquiry, initialResult, onChangeContact } = props
  const { contact, customer } = interactionMessage

  const persistence = !appContext.isProd
    ? EnquiryPersistenceEnum.Basic
    : appContext.isProd && canViewActiveInteractions
      ? EnquiryPersistenceEnum.Basic
      : EnquiryPersistenceEnum.None

  const initialContactActionCompleted = !!contact || !!customer

  //new customer or skip customer is undefined, needs to be skipped.
  //setup a workflow state with an existing customer or with contact fields from the interaction message
  //depends on existing enquiry.

  // console.log(
  //   'workflow interaction message new enquiry',
  //   enquiry.id,
  //   'props.initialResult',
  //   initialResult,
  //   interactionMessage.customer
  // )

  const [workflowState, { updateResult, addResponse, goBack, abortEnquiry, completeEnquiry, clearResponses }] =
    useEnquiryService<NewEnquiryResult>({
      enquiryId: enquiry.id,
      initialResult: initialResult ?? {
        contact: {
          lastName: contact?.lastName ?? undefined,
          firstName: contact?.firstName ?? '',
          phone: contact?.phone ? formatPhone(contact.phone) : '',
          ...(contact?.address?.suburbId &&
            contact?.address?.suburb && {
              suburb: {
                id: contact.address.suburbId,
                label: contact.address.suburb
              }
            }),
          ...(contact?.suburb?.id &&
            !contact?.address?.suburbId &&
            contact?.suburb?.label && {
              suburb: {
                id: contact.suburb.id,
                label: contact.suburb.label
              }
            }),
          actionCompleted: initialContactActionCompleted
        },
        //should have been set by the interaction message enquiry container via the launcher
        ...(customer
          ? customer.mainContact && {
              customer: { customer: toSelectedCustomer(customer), actionCompleted: false } //initialContactActionCompleted }
            }
          : { customer: { customer: undefined, actionCompleted: false } })
      },
      persistence
    })

  const [abortRequested, setAbortRequested] = useState(false)
  const [abortDetails, setAbortDetails] = useState<EnquiryAbortDetails | undefined>(undefined)

  const onAbortRequested = (details: EnquiryAbortDetails) => {
    setAbortDetails(details)
    setAbortRequested(true)
  }

  const onCancelAbort = () => setAbortRequested(false)

  const [topicChangeRequested, setTopicChangeRequested] = useState(false)
  const [topicChangeDetails, setTopicChangeDetails] = useState<JobTopic | undefined>(undefined)

  const onChangeTopicRequested = (jobTopic: JobTopicType) => {
    const topic = JobTopics.find((t) => t.reference === jobTopic)
    if (topic) {
      setTopicChangeDetails(topic)
      setTopicChangeRequested(true)
    }
  }

  const onCancelChangeTopic = () => {
    setTopicChangeDetails(undefined)
    setTopicChangeRequested(false)
  }

  const onChangeTopic = () => {
    if (topicChangeDetails) {
      updateResult((current) => ({
        ...current,
        newBooking: {
          ...current.newBooking,
          jobTopic: {
            topic: topicChangeDetails,
            actionCompleted: true
          },
          // Ensure we maintain these fields when changing topic
          jobClassification: {
            topic: {
              customerType: current.newBooking?.jobClassification?.topic?.customerType,
              jobDescription: current.newBooking?.jobClassification?.topic?.jobDescription
            }
          }
        }
      }))
      onCancelChangeTopic()
    }
  }

  const getTrackProperties = (result: NewEnquiryResult): Dict => {
    const enquiryType = result.enquiryType?.enquiryType
    const isAdminEnquiry = enquiryType !== EnquiryTypeReference.NewBooking
    const jobTopic = result.newBooking?.jobTopic?.topic?.reference
    return { enquiryType, isAdminEnquiry, jobTopic }
  }

  const handleCompleteEnquiry = () => {
    completeEnquiry(getTrackProperties(workflowState.result))
  }

  const handleAbortEnquiry = (abortDetails: EnquiryAbortDetails) => {
    abortEnquiry(abortDetails)
    setAbortRequested(false)
    setAbortDetails(undefined)
  }

  //handle the standard workflow action update
  //inject customer from the interaction message
  const handleActionUpdate = <
    K extends keyof NewEnquiryResult,
    T extends BaseActionResult,
    O extends ActionUpdateEnquiryDefaults
  >(
    key: K,
    result: T,
    opts?: O
  ) => {
    console.log('handleActionUpdate', key, result, opts)
    if (opts?.customer || opts?.isNewCustomer) {
      const { customer: customerData } = opts
      console.log('changing set workflow customer if no existing newBooking', customerData)
      updateResult((current) => ({
        ...current,
        [key]: result,
        newBooking: {
          ...current.newBooking,
          customer: { customer: customerData, actionCompleted: false, value: customerData?.id }
        }
      }))
    } else {
      updateResult((current) => ({ ...current, [key]: result }))
    }

    if (result.actionCompleted) {
      addResponse(
        { reference: key, value: result.value ?? 'missing-value', isEnquiryComplete: result.actionCompleted },
        getTrackProperties(workflowState.result)
      )
      handleCompleteEnquiry()
    }
  }

  const enquiryTypeDefaultCustomerList = [EnquiryTypeReference.NewBooking, EnquiryTypeReference.Rebooking]
  const handleEnquiryTypeUpdate = (enquiryType: EnquiryTypeActionResult) => {
    if (enquiryType.enquiryType && enquiryTypeDefaultCustomerList.includes(enquiryType.enquiryType)) {
      const customerData = customer ? getCustomerForEnquiryType(enquiryType.enquiryType, customer) : undefined
      console.log('changing set workflow customer', customerData)
      handleActionUpdate('enquiryType', enquiryType, {
        customer: customerData?.customer,
        customerKey: customerData?.customerKey
      })
    } else {
      handleActionUpdate('enquiryType', enquiryType)
    }
    if (enquiryType.enquiryType) {
      const isAdminEnquiry = enquiryType.enquiryType !== EnquiryTypeReference.NewBooking
      mixpanel?.track('EnquiryTypeSelected', { enquiryType: enquiryType.enquiryType, isAdminEnquiry })
    }
  }
  const clearEnquiryType = () => clearResponses(['enquiryType', 'rebooking'])

  const currentAction = (result: NewEnquiryResult): ReactNode | undefined => {
    const isJobCreated = !!result?.newBooking?.createJob?.job?.id
    const isHPPPurchased = !!result.membership?.renewMembership?.createMembership?.jobId
    const isChangingJobEnquiryDisabled = isJobCreated || isHPPPurchased

    if (workflowState.endDate && workflowState.completionType) {
      return (
        <>
          <EuiLoadingSpinner size="l" />
        </>
      )
    } else {
      return (
        <>
          <EnquiryTypeAction
            input={{ editable: !isChangingJobEnquiryDisabled }}
            result={result.enquiryType}
            onUpdate={handleEnquiryTypeUpdate}
            onBack={() => {
              clearEnquiryType()
              goBack('enquiry-type')
            }}
          />

          {result.enquiryType?.enquiryType && (
            <ActionForEnquiryType
              enquiryType={result.enquiryType.enquiryType}
              result={result}
              handleActionUpdate={handleActionUpdate}
              goBack={goBack}
              completeEnquiry={handleCompleteEnquiry}
            />
          )}
        </>
      )
    }
  }

  return (
    <>
      {enquiry.status.status === EnquiryStatusType.Active && (
        <>
          <WorkflowProvider
            enquiryId={workflowState.enquiryId}
            onChangeJobTopic={onChangeTopicRequested}
            onAbort={onAbortRequested}
          >
            {abortRequested && abortDetails && (
              <>
                <AbortEnquiryAction
                  contactId={interactionMessage.contactId ?? workflowState.result?.newBooking?.customer?.contact?.id}
                  details={{
                    ...abortDetails,
                    jobTopicReference: workflowState.result?.newBooking?.jobTopic?.topic?.reference
                  }}
                  onCancel={onCancelAbort}
                  onProceed={handleAbortEnquiry}
                  onChangeContact={onChangeContact}
                />
                <EuiSpacer size="l" />
              </>
            )}
            {topicChangeRequested && topicChangeDetails && (
              <EuiConfirmModal
                title="Change job topic"
                onCancel={onCancelChangeTopic}
                onConfirm={onChangeTopic}
                cancelButtonText="No"
                confirmButtonText="Yes, please"
                defaultFocusedButton="confirm"
              >
                <p>Are you sure you want to change the job topic to {topicChangeDetails.label}?</p>
              </EuiConfirmModal>
            )}
            <EuiFlexGroup alignItems="flexStart">
              <EuiFlexItem grow={true} />
              <EuiFlexItem grow={false}>
                <EuiButton onClick={() => onAbortRequested({})} color="warning" size="s">
                  Abort
                </EuiButton>
              </EuiFlexItem>
            </EuiFlexGroup>
            <EuiSpacer size="l" />
            <EuiFlexGroup alignItems="flexStart" className={'integration_message_detail__sidebar'}>
              <EuiFlexItem>
                <EuiPanel>
                  <div>
                    <div data-test-id="bouncing-ball-workflow">{currentAction(workflowState.result)}</div>
                  </div>
                </EuiPanel>
              </EuiFlexItem>
            </EuiFlexGroup>
            <EuiSpacer size="xxl" />
          </WorkflowProvider>
          <RawJSON data={workflowState} />
        </>
      )}
    </>
  )
}
