import { JobTopicType } from '@fallonsolutions/types'

export const getTopicName = (topicReference: JobTopicType): string => {
  switch (topicReference) {
    case JobTopicType.HomeEnergyAudit159:
      return 'Energy Audit'
    case JobTopicType.DrainClear99:
      return 'Drains'
    case JobTopicType.SwitchBoardInspection99:
      return 'Switchboard Inspection'
    case JobTopicType.HotWaterInspection99:
      return '99 Hot water special'
    case JobTopicType.SolarInspection:
      return 'Solar Inspection'
    case JobTopicType.AccessControl:
      return 'Access control'
    case JobTopicType.Lighting:
      return 'Lighting'
    case JobTopicType.Alarm:
      return 'Alarm system'
    case JobTopicType.Antenna:
      return 'Antenna'
    case JobTopicType.CCTV:
      return 'CCTV system'
    case JobTopicType.CeilingFan:
      return 'Ceiling fan'
    case JobTopicType.CommercialElectrical:
      return 'Commercial electrical works'
    case JobTopicType.ExhaustFan:
      return 'Exhaust fan'
    case JobTopicType.HighPowerBill:
      return 'High power bills'
    case JobTopicType.Intercom:
      return 'Intercom'
    case JobTopicType.TrippingPower:
      return 'Tripping power'
    case JobTopicType.LossOfPower:
      return 'Loss of power'
    case JobTopicType.PhoneLandLine:
      return 'Telephone / Landline'
    case JobTopicType.DataPoint:
      return 'Data points'
    case JobTopicType.PowerPoint:
      return 'Power points'
    case JobTopicType.WallMountTV:
      return 'Wall mount TV'
    case JobTopicType.HomeAutomation:
      return 'CBUS / Smart Switches / Home Automation'
    case JobTopicType.Rewire:
      return 'Rewire'
    case JobTopicType.SmokeAlarm:
      return 'Smoke alarms'
    case JobTopicType.SecurityMonitoring:
      return 'Security monitoring'
    case JobTopicType.Solar:
      return 'Solar'
    case JobTopicType.Switchboard:
      return 'Switchboard'
    case JobTopicType.Stereo:
      return 'AMP / Stereo / Set top box / Home Theatre'
    case JobTopicType.HVACInspection:
      return 'AC inspection'
    case JobTopicType.HVACQuoteInstall:
      return 'AC quote or install'
    case JobTopicType.HVACRepair:
      return 'AC repair'
    case JobTopicType.HVACService:
      return 'AC service'
    case JobTopicType.HVACMaintenance:
      return 'AC maintenance'
    case JobTopicType.CappingOffInterconnectionPlumbing:
      return 'Capping off interconnection'
    case JobTopicType.PrePurchaseInspectionPlumbing:
      return 'Pre-purchase plumbing inspection'
    case JobTopicType.VanitiesAndSinksInstall:
      return 'Vanity/sink installation'
    case JobTopicType.TapInstall:
      return 'Tap install'
    case JobTopicType.ToiletInstall:
      return 'Toilet install'
    case JobTopicType.InsinkeratorsOrGarbageDisposalsInstall:
      return 'Insinkerators/Garbage disposal installation'
    case JobTopicType.BidetInstall:
      return 'Bidet install'
    case JobTopicType.WaterFilters:
      return 'Water filter install'
    case JobTopicType.FlexiHoseInstall:
      return 'Flexi hose install'
    case JobTopicType.WaterHammering:
      return 'Water hammering'
    case JobTopicType.InsuranceQuotePlumbing:
      return 'Plumbing insurance quotes'
    case JobTopicType.WaterEfficiencyCertificates:
      return 'Water efficiency certificates'
    case JobTopicType.TapRepair:
      return 'Tap repair'
    case JobTopicType.ToiletRepair:
      return 'Toilet repair'
    case JobTopicType.WaterFilterComponentsRepair:
      return 'Water filter components repair'
    case JobTopicType.FlexiHoseRepair:
      return 'Flexi hose repair'
    case JobTopicType.BidetRepair:
      return 'Bidet repair'
    case JobTopicType.HotWater:
      return 'Hot water system'
    case JobTopicType.Pump:
      return 'Pumps'
    case JobTopicType.WaterLeakDetection:
      return 'Water leak detection'
    case JobTopicType.WaterLeak:
      return 'Water leaking'
    case JobTopicType.WaterPressure:
      return 'Water pressure issue'
    case JobTopicType.Shower:
      return 'Shower'
    case JobTopicType.BackFlowTesting:
      return 'Backflow testing'
    case JobTopicType.BurstWaterMains:
      return 'Burst water mains'
    case JobTopicType.Drains:
      return 'Drains'
    case JobTopicType.Submetering:
      return 'Submetering'
    case JobTopicType.Pool:
      return 'Pool'
    case JobTopicType.Gas:
      return 'Gas'
    case JobTopicType.Gutters:
      return 'Gutters'
    case JobTopicType.Downpipes:
      return 'Downpipes'
    case JobTopicType.InspectionPlumbing:
      return 'Plumbing inspection'
    case JobTopicType.DrainsRevisit:
      return 'Drains job'
    case JobTopicType.PlumbingRevisit:
      return 'Plumbing job'
    case JobTopicType.Microwave:
      return 'Microwave'
    case JobTopicType.Rangehood:
      return 'Rangehood'
    case JobTopicType.FridgeFreezer:
      return 'Fridge or freezer'
    case JobTopicType.CoffeeMachine:
      return 'Coffee Machine'
    case JobTopicType.WashingMachine:
      return 'Washing Machine'
    case JobTopicType.Inspection:
      return 'Inspection'
    case JobTopicType.SecurityInspection:
      return 'Security inspection'
    case JobTopicType.SmokeAlarmInspection:
      return 'Smoke alarm inspection'
    case JobTopicType.Dishwasher:
      return 'Dishwasher'
    case JobTopicType.Cooktop:
      return 'Cooktop'
    case JobTopicType.Oven:
      return 'Oven'
    case JobTopicType.Dryer:
      return 'Dryer'
    case JobTopicType.Other:
    default:
      return 'Other'
  }
}
