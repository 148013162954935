import { MouseEventHandler } from 'react'

export interface LoaderContext<T> {
  loading: boolean
  loaded: boolean
  error?: string //TODO more detailed
  data?: T
  retry?: MouseEventHandler<HTMLButtonElement>
}

export const loaderContextDefaultRetry = () => window.location.reload()
export const initialLoaderContext = { loading: false, loaded: false }
