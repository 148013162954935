import { EuiIcon } from '@elastic/eui'

interface JobBranchProps {
  branch?: string | undefined | null
  editable?: boolean
}

export const JobBranch = (props: JobBranchProps) => {
  const { branch, editable } = props
  const label = branch ? branch : 'Not Set'

  return (
    <div className="nowrap">
      {label}
      {editable && <EuiIcon size="s" type="arrowDown" style={{ marginLeft: '3px' }} />}
    </div>
  )
}
