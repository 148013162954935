import { EuiFacetButton, EuiFacetGroup, EuiIcon } from '@elastic/eui'
import moment, { Moment } from 'moment-timezone'

interface DatePickerProps {
  startDate: Moment | null | undefined
  endDate: Moment | null | undefined
  onSetDateRange: (from: Moment | null, to: Moment | null) => void
}

const timezone = 'Australia/Brisbane'

enum DateNavOption {
  Today = 'Today',
  Yesterday = 'Yesterday',
  ThisWeek = 'This week',
  ThisMonth = 'This month'
}

export const DateQuickNav = (props: DatePickerProps) => {
  const { startDate, endDate, onSetDateRange } = props

  const handleClick = (option: DateNavOption) => {
    const { from, to } = convertToDateRange(option)
    onSetDateRange(from, to)
  }

  const convertToDateRange = (option: DateNavOption): { from: Moment | null; to: Moment | null } => {
    switch (option) {
      case DateNavOption.Today:
        return {
          from: moment().tz(timezone).startOf('day'),
          to: moment().tz(timezone).endOf('day')
        }
      case DateNavOption.Yesterday:
        return {
          from: moment().tz(timezone).subtract(1, 'day').startOf('day'),
          to: moment().tz(timezone).subtract(1, 'day').endOf('day')
        }
      case DateNavOption.ThisWeek:
        return {
          from: moment().tz(timezone).startOf('week'),
          to: moment().tz(timezone).endOf('week')
        }
      case DateNavOption.ThisMonth:
        return {
          from: moment().tz(timezone).startOf('month'),
          to: moment().tz(timezone).endOf('month')
        }
    }
  }

  const doesDateRangeMatchOption = (option: DateNavOption) => {
    const { from, to } = convertToDateRange(option)

    const startDateNormalised = startDate?.clone().startOf('day')
    const endDateNormalised = endDate?.clone().endOf('day')

    return from?.isSame(startDateNormalised) && to?.isSame(endDateNormalised)
  }

  const offColor = '#E5E8F0'
  const onColor = '#34C759'

  return (
    <EuiFacetGroup layout="horizontal" gutterSize="s">
      {Object.values(DateNavOption).map((option) => {
        const isSelected = doesDateRangeMatchOption(option)
        return (
          <EuiFacetButton
            key={option}
            icon={<EuiIcon type="clock" color={isSelected ? onColor : offColor} />}
            isSelected={isSelected}
            onClick={() => handleClick(option)}
            style={{ minInlineSize: '40px', padding: '0px' }}
          >
            {option}
          </EuiFacetButton>
        )
      })}
    </EuiFacetGroup>
  )
}
