import { useQuery } from '@apollo/client'
import { EuiButtonEmpty, EuiFlexGroup, EuiFlexItem, EuiLoadingSpinner, EuiSpacer, EuiTitle } from '@elastic/eui'
import { useEffect } from 'react'
import { CustomerSource, GetCustomerDocument } from '../api/generated-types'
import { useMixpanel } from '../app/mixpanel-provider'
import { Callout } from '../common/callout'
import { myobCustomerLink } from '../myob/myob-links'
import myobIcon from '../static/images/myob-icon.png'
import { CustomerDetail } from './customer-detail'
import { CustomerPropertiesContainer } from './customer-properties-container'

interface CustomerDetailContainerProps {
  id: string
  showProperties?: boolean
  showMYOBLink?: boolean
}

export const CustomerDetailContainer = (props: CustomerDetailContainerProps) => {
  const mixpanel = useMixpanel()
  useEffect(() => {
    mixpanel?.track('CustomerDetail')
  }, [mixpanel])

  const { id, showMYOBLink } = props
  const showProperties = props.showProperties ?? false

  const { loading, error, data, refetch } = useQuery(GetCustomerDocument, { variables: { id } })
  if (error) {
    console.error('error', error)
  }

  const customer = data?.getCustomer

  return (
    <>
      {loading ? (
        <EuiLoadingSpinner size="l" />
      ) : customer ? (
        <>
          <EuiFlexGroup alignItems="center">
            <EuiFlexItem grow={false}>
              <EuiTitle size="s">
                <h2>Customer</h2>
              </EuiTitle>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiButtonEmpty iconType="refresh" onClick={() => refetch()} size="xs">
                Reload customer data
              </EuiButtonEmpty>
            </EuiFlexItem>
            <EuiFlexItem grow={true} />
            {showMYOBLink && customer && (
              <EuiFlexItem grow={false}>
                <EuiButtonEmpty
                  href={myobCustomerLink(customer)}
                  target="_blank"
                  rel="noopener noreferrer"
                  iconType={myobIcon}
                  style={{ fontWeight: 400, fontSize: '13px' }}
                >
                  View in MYOB
                </EuiButtonEmpty>
              </EuiFlexItem>
            )}
          </EuiFlexGroup>
          <EuiSpacer size="s" />
          <CustomerDetail customer={customer} />
          {showProperties && (
            <>
              <CustomerPropertiesContainer customerId={id} source={CustomerSource.Platform} />
            </>
          )}
        </>
      ) : (
        <Callout type="warning" title="Customer not found" />
      )}
    </>
  )
}
