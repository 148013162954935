import { EuiFlexGroup, EuiFlexItem, EuiLoadingSpinner, EuiPanel } from '@elastic/eui'
import { jaroWinkler } from '@skyra/jaro-winkler'
import { ContactDetailFragment, ContactFragment } from '../api/generated-types'
import { ContactCard, ContactCardSelectionState } from './contact-card'

export interface ContactListSelectionState {
  selectedContactId: string | undefined
  onSelectContact: (contact: ContactFragment | undefined) => void
}

export interface ContactListCardViewProps {
  loading: boolean
  contacts: ContactFragment[]
  selection?: ContactListSelectionState
  buttonLabel?: string
  compareWith?: ContactDetailFragment
}

//simple start with same shape as ContactDetailFragment
export type ContactDetailMatch = ContactDetailFragment

export const ContactListCardView = ({ loading, contacts, selection, ...props }: ContactListCardViewProps) => {
  const buttonLabel = props.buttonLabel ?? 'Select'
  const getCardSelection = (contact: ContactFragment): ContactCardSelectionState | undefined => {
    if (!selection) {
      return undefined
    }
    return {
      isSelected: selection.selectedContactId === contact.id,
      onSelect: () => selection.onSelectContact(contact)
    }
  }

  const score = (detail: ContactDetailFragment): ContactDetailMatch | undefined => {
    if (!props.compareWith) {
      return undefined
    }
    return {
      firstName: jaroWinkler(props.compareWith.firstName ?? '', detail?.firstName ?? '').toFixed(2),
      lastName: jaroWinkler(props.compareWith.lastName ?? '', detail?.lastName ?? '').toFixed(2)
    }
  }

  return (
    <EuiFlexGroup direction="column" gutterSize="m">
      {loading ? (
        <EuiFlexItem grow={true}>
          <EuiLoadingSpinner />
        </EuiFlexItem>
      ) : (
        contacts.map((contact, index) => (
          <EuiFlexItem key={index}>
            <EuiPanel
              paddingSize="s"
              hasBorder={false}
              hasShadow={false}
              style={{ boxShadow: '0 1px 6px rgba(82, 91, 114, 0.15)' }}
            >
              {contact.detail && (
                <>
                  <ContactCard
                    selectButtonLabel={buttonLabel}
                    contact={contact}
                    selection={getCardSelection(contact)}
                    matchScore={score(contact.detail)}
                  />
                </>
              )}
            </EuiPanel>
          </EuiFlexItem>
        ))
      )}
    </EuiFlexGroup>
  )
}
