import { InteractionMessageListColumns } from './interaction-message-list-columns'
import {
  AssignInteractionMessageDocument,
  DateHistogramInterval,
  InteractionMessageAggregations,
  InteractionMessageFragment,
  SearchInteractionMessagesDocument,
  SearchInteractionMessagesInput,
  SearchInteractionMessagesOptions
} from '../../api/generated-types'
import ListContainer, { ListContainerData } from '../../common/list-container'
import { useCallback, useMemo, useState } from 'react'
import { InteractionMessageListFilters } from './interaction-message-list-filters'
import { dateConfig } from '@fallonsolutions/date'
import { DateTime } from 'luxon'
import { InteractionMessageListMetrics } from './interaction-message-list-metrics'
import { InteractionMessageListChart } from './interaction-message-list-chart'
import { InteractionMessageCSVButton } from './interaction-message-csv-export'
import { useMutation } from '@apollo/client'
import { useAuthenticated } from '../../auth/authenticated-context'

export const InteractionMessageListContainer = () => {
  const [total, setTotal] = useState(0)

  const { userFragment } = useAuthenticated()

  const extractData = (response: any): ListContainerData<InteractionMessageFragment> => {
    return {
      rows: response?.searchInteractionMessages?.results ?? [],
      total: response?.searchInteractionMessages?.count ?? 0,
      summary: response?.searchInteractionMessages?.aggregations,
      dateHistogram: response?.searchInteractionMessages?.aggregations?.dateHistogram ?? undefined
    }
  }

  const initialInput = useMemo(() => {
    return {
      options: {
        summary: true,
        dateHistogram: true,
        dateHistogramInterval: DateHistogramInterval.Week
      }
    }
  }, [])

  const [assignInteractionMessage] = useMutation(AssignInteractionMessageDocument, {
    refetchQueries: ['SearchInteractionMessages'],
    awaitRefetchQueries: true
  })

  const onClickAssignToSelf = (interactionMessage: InteractionMessageFragment) => {
    console.log('assign interaction to self')
    assignInteractionMessage({
      variables: {
        input: {
          interactionMessageId: interactionMessage.id,
          userId: userFragment.id
        }
      }
    })
  }

  const [listInput, setListInput] = useState<SearchInteractionMessagesInput>(initialInput)
  const columns = InteractionMessageListColumns({ onClickAssignToSelf })

  const onChangeInput = useCallback((input: SearchInteractionMessagesInput) => {
    const { from, to } = input.filter?.startDate ?? {}
    let interval = DateHistogramInterval.Week
    if (from && to) {
      const fromDate = DateTime.fromISO(from, { zone: dateConfig.defaultTimezone })
      const toDate = DateTime.fromISO(to, { zone: dateConfig.defaultTimezone })
      const numDays = toDate.diff(fromDate, 'days').days
      if (numDays <= 31) {
        interval = DateHistogramInterval.Day
      } else if (numDays <= 160) {
        interval = DateHistogramInterval.Week
      } else if (numDays <= 730) {
        interval = DateHistogramInterval.Month
      } else {
        interval = DateHistogramInterval.Year
      }
    } else {
      interval = DateHistogramInterval.Week
    }
    setListInput((existingInput) => ({
      ...existingInput,
      ...input,
      options: { ...input.options, dateHistogramInterval: interval }
    }))
  }, [])

  const filters = useMemo(
    () => <InteractionMessageListFilters onChangeInput={(filter) => onChangeInput({ ...listInput, filter })} />,
    []
  )

  const metrics = (aggregations: InteractionMessageAggregations) => (
    <InteractionMessageListMetrics aggregations={aggregations} />
  )
  const csvButton = <InteractionMessageCSVButton input={listInput.filter ?? {}} resultCount={total} />

  const interval = listInput.options?.dateHistogramInterval ?? DateHistogramInterval.Week
  const options: SearchInteractionMessagesOptions = {
    summary: true,
    dateHistogram: true,
    dateHistogramInterval: interval
  }
  const chart = InteractionMessageListChart({ interval, setInterval })

  return (
    <ListContainer
      query={SearchInteractionMessagesDocument}
      title="Calls"
      modelName="calls"
      columns={columns}
      input={listInput}
      filters={filters}
      metrics={metrics}
      chart={chart}
      actions={csvButton}
      options={options}
      paginationOnInput={true}
      extractData={extractData}
      defaultPageSize={40}
      onChangeResultTotal={setTotal}
    />
  )
}

export default InteractionMessageListContainer
