import { compact, isNil } from 'lodash-es'
import { AllInvoicesDocument, InvoiceFragment, InvoiceType } from '../api/generated-types'
import { EuiBasicTable, EuiBasicTableColumn, EuiFlexGroup, EuiFlexItem, EuiRadio } from '@elastic/eui'
import { DateTime } from 'luxon'
import { dateConfig } from '@fallonsolutions/date'
import { useQuery } from '@apollo/client'
import { Callout } from '../common/callout'
import UserLink from '../users/user-link'
import { formatMoney } from '../common/money'

interface CustomerInvoicesSelectProps {
  customerId: string
  selectedInvoiceId: string | undefined
  onSelect: (invoice: InvoiceFragment) => void
}

export const CustomerInvoicesSelect = (props: CustomerInvoicesSelectProps) => {
  const { customerId, selectedInvoiceId, onSelect } = props

  const { data, loading, error } = useQuery(AllInvoicesDocument, {
    variables: { input: { customers: [customerId], types: [InvoiceType.Invoice] }, size: 10 }
  })
  const invoices = compact(data?.allInvoices?.results ?? [])

  return error ? (
    <Callout type="failure" title="Error loading invoices for customer" />
  ) : (
    <CustomerInvoicesTable
      invoices={invoices}
      onSelect={onSelect}
      selectedInvoiceId={selectedInvoiceId}
      loading={loading}
    />
  )
}

interface CustomerInvoicesTableProps {
  selectedInvoiceId: string | undefined
  invoices: InvoiceFragment[]
  onSelect: (invoice: InvoiceFragment) => void
  loading: boolean
}

export const CustomerInvoicesTable = (props: CustomerInvoicesTableProps) => {
  const { onSelect, loading, selectedInvoiceId, invoices } = props

  const renderDate = (date: string | undefined) => {
    return date
      ? DateTime.fromISO(date, { zone: dateConfig.defaultTimezone }).toFormat(dateConfig.luxonFormat.shortDate)
      : ''
  }

  const columns: EuiBasicTableColumn<InvoiceFragment>[] = [
    {
      field: 'number',
      name: 'Reference',
      width: '120px',
      render: (number: string | undefined, invoice: InvoiceFragment) => (
        <EuiFlexGroup gutterSize="none" alignItems="center">
          <EuiFlexItem grow={false}>
            <EuiRadio
              checked={invoice.id === selectedInvoiceId}
              onChange={() => onSelect(invoice)}
              style={{ marginRight: '12px' }}
            />
          </EuiFlexItem>
          <EuiFlexItem grow={false}>{number}</EuiFlexItem>
        </EuiFlexGroup>
      )
    },
    {
      field: 'job',
      name: 'Job',
      width: '100px',
      render: (job: any) => (
        <a href={`/jobs/${job.id}`} target="_blank" rel="noopener noreferrer">
          {job.number}
        </a>
      )
    },
    {
      field: 'appointment',
      name: 'Appointment',
      render: (appointment: any) => {
        if (!appointment) {
          return ''
        }
        const scheduled = DateTime.fromISO(appointment.scheduled?.from)
        const scheduledDate = scheduled.toFormat(dateConfig.luxonFormat.fullDate)
        const scheduledTime = scheduled.toFormat(dateConfig.luxonFormat.time).toLowerCase()
        return `${scheduledDate} ${scheduledTime}`
      }
    },
    {
      field: 'technician',
      name: 'Technician',
      render: (technician: any) => {
        if (!technician) {
          return 'No technician'
        }
        return <UserLink user={technician} />
      }
    },
    {
      field: 'totalAmount',
      name: 'Total',
      align: 'right',
      render: (totalAmount: any, invoice: any) => {
        const legacyTotal =
          invoice.total?.toLocaleString('en-AU', {
            style: 'currency',
            currency: 'AUD'
          }) ?? ''
        return !isNil(totalAmount?.total) ? formatMoney(totalAmount.total, { currencySymbol: true }) : legacyTotal
      }
    },
    {
      field: 'balance',
      name: 'Balance',
      align: 'right',
      render: (balance: any) => {
        return !isNil(balance) ? formatMoney(balance, { currencySymbol: true }) : ''
      }
    },
    {
      field: 'postDate',
      name: 'Invoice date',
      render: (date: string | undefined) => renderDate(date)
    }
  ]

  return (
    <EuiBasicTable
      className="basic-table--minimal"
      loading={loading}
      items={invoices}
      columns={columns}
      rowProps={(invoice) => ({ onClick: () => onSelect(invoice) })}
      noItemsMessage={loading ? 'Searching...' : 'No invoices found'}
    />
  )
}
