import { useState } from 'react'
import {
  ArrangementCancellationReason,
  AttendedStatusType,
  CancelJobDocument,
  CancelJobInput,
  JobFragment
} from '../api/generated-types'
import {
  EuiButton,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiOverlayMask
} from '@elastic/eui'
import { useMutation } from '@apollo/client'
import { ArrangementCancellationReasonSelector } from '../schedule/arrangement-cancellation-reason-selector'

export interface JobCancelFormProps {
  job: JobFragment
  closeModal: () => void
}

export const JobCancelForm = (props: JobCancelFormProps) => {
  const { job, closeModal } = props
  const [reason, setReason] = useState<ArrangementCancellationReason | undefined>(undefined)
  const [cancelJob, { loading }] = useMutation(CancelJobDocument)

  const canCancelJob = reason && job.attended !== AttendedStatusType.Attended

  const handleCancel = async () => {
    if (reason) {
      const input: CancelJobInput = {
        id: job.id,
        reason
      }
      try {
        await cancelJob({ variables: { input } })
        closeModal()
      } catch (error) {
        console.error('error performing mutation', error)
      }
    }
  }

  return (
    <EuiOverlayMask>
      <EuiModal onClose={closeModal}>
        <EuiModalHeader>
          <EuiModalHeaderTitle>Cancel job {job.number}</EuiModalHeaderTitle>
        </EuiModalHeader>
        <EuiModalBody>
          <ArrangementCancellationReasonSelector reason={reason} changeReason={setReason} />
        </EuiModalBody>
        <EuiModalFooter>
          <EuiButton onClick={handleCancel} isLoading={loading} fill disabled={!canCancelJob}>
            Cancel job
          </EuiButton>
        </EuiModalFooter>
      </EuiModal>
    </EuiOverlayMask>
  )
}
