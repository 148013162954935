import {
  SingleChoiceSubmitWorkflowStepDetailInput,
  SingleChoiceWorkflowStepDetailFragment
} from '@fallonsolutions/types'
import { AnswerOption, SingleSelectField } from '../../workflow/question-fields/single-select-question-field'
import { WorkflowV2StepProps } from './workflow-v2-step-interface'

export interface SingleChoiceWorkflowV2StepProps extends WorkflowV2StepProps {
  detail: SingleChoiceWorkflowStepDetailFragment
  result: Partial<SingleChoiceSubmitWorkflowStepDetailInput>
  onChange: (result: SingleChoiceSubmitWorkflowStepDetailInput) => void
}

export const SingleChoiceWorkflowV2Step = ({ result, detail, onChange }: SingleChoiceWorkflowV2StepProps) => {
  const onChangeAnswer = (choiceId: string | undefined) => {
    if (!choiceId) {
      return
    }
    onChange({
      choiceId
    })
  }

  const answers: AnswerOption<string>[] = detail.choices.map((choice) => ({
    id: choice.id,
    label: choice.label,
    platformIcon: choice.icon
  }))
  const answer = answers.find((answer) => answer.id === result.choiceId)?.id

  return (
    <>
      <SingleSelectField question={detail.question} options={answers} answer={answer} changeAnswer={onChangeAnswer} />
    </>
  )
}
