import { BaseActionInput, BaseActionResult, WorkflowActionProps } from '../workflow-model'
import { EuiButton, EuiIcon, EuiKeyPadMenu, EuiKeyPadMenuItem, EuiSpacer, EuiText } from '@elastic/eui'
import { WorkflowAction } from '../workflow-action'
import { useState } from 'react'
import { Callout } from '../../common/callout'
import { Task } from '../../common/task'

export interface DisputeInvoiceActionInput extends BaseActionInput {
  firstName?: string | undefined
}
export type DisputeInvoiceActionResult = BaseActionResult

export const DisputeInvoiceAction = (
  props: WorkflowActionProps<DisputeInvoiceActionInput, DisputeInvoiceActionResult>
) => {
  const { onUpdate, input } = props
  const { firstName } = input

  const [managerAvailable, setManagerAvailable] = useState<boolean | undefined>(undefined)
  const [transferedToManager, setTransferedToManager] = useState(false)
  const [sentEmail, setSentEmail] = useState(false)

  return (
    <WorkflowAction title="Dispute or question invoice">
      <EuiText>Is the Customer Experience Manager available?</EuiText>
      <EuiSpacer size="m" />
      <EuiKeyPadMenu className="fs-keypadmenu">
        <EuiKeyPadMenuItem
          id="manager-available-yes"
          isSelected={managerAvailable}
          label="Manager is available"
          onClick={() => setManagerAvailable(true)}
        >
          <EuiIcon size="xl" type="check" />
        </EuiKeyPadMenuItem>
        <EuiKeyPadMenuItem
          id="debit-card"
          isSelected={managerAvailable === false}
          label="Manager NOT available"
          onClick={() => setManagerAvailable(false)}
        >
          <EuiIcon size="xl" type="cross" />
        </EuiKeyPadMenuItem>
      </EuiKeyPadMenu>

      {managerAvailable === true && (
        <>
          <EuiSpacer />
          <Callout type="script">
            <EuiText>
              <div>Okay, so what I will need to do is transfer you through to our Customer Experience Manager</div>
              <EuiSpacer size="s" />
              <div>They will be able to assist you further okay {firstName}?</div>
            </EuiText>
          </Callout>
          <Task
            checked={transferedToManager}
            onChange={setTransferedToManager}
            label="Transfer call to Customer Experience Manager"
            indent={true}
          />
        </>
      )}

      {managerAvailable === false && (
        <>
          <EuiSpacer />
          <Callout type="script">
            <EuiText>
              <div>I'm sorry, Our customer experience Manager is on a call right now.</div>
              <EuiSpacer size="s" />
              <div>I have taken note of your situation and will pass these details onto them immediately.</div>
              <EuiSpacer size="s" />
              <div>They will contact you back as soon as possible.</div>
            </EuiText>
          </Callout>
          <Task
            checked={sentEmail}
            onChange={setSentEmail}
            label="Capture phone number and name of customer and email Customer Experience Manager to arrange call back at requested time"
            indent={true}
          />
        </>
      )}

      {(sentEmail || transferedToManager) && (
        <>
          <EuiSpacer />
          <EuiButton fill color="primary" onClick={() => onUpdate({})}>
            I've completed these steps
          </EuiButton>
        </>
      )}
    </WorkflowAction>
  )
}
