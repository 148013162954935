import { EuiFlexGroup, EuiFlexItem, EuiIcon } from '@elastic/eui'
import '../static/css/option-status.css'

interface JobTimeAllowedProps {
  timeAllowed: string | null | undefined
  editable?: boolean
}

export const JobTimeAllowed = (props: JobTimeAllowedProps) => {
  const { timeAllowed } = props
  const editable = props.editable ?? false
  const label = timeAllowed ?? 'Not set'
  return (
    <EuiFlexGroup gutterSize="none" alignItems="center">
      <EuiFlexItem grow={false}>{label}</EuiFlexItem>
      {editable && (
        <EuiFlexItem grow={false}>
          <EuiIcon size="s" type="arrowDown" style={{ marginLeft: '3px' }} />
        </EuiFlexItem>
      )}
    </EuiFlexGroup>
  )
}
