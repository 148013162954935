import { EuiFlexGroup, EuiFlexItem } from '@elastic/eui'
import { JobAcquisitionFragment } from '../api/generated-types'
import { CampaignLink } from '../marketing/campaign/campaign-link'
import { WebFormLink } from '../web-forms/web-form-link'

export interface JobAcquisitionProps {
  acquisition?: JobAcquisitionFragment
}

export const JobAcquisition = ({ acquisition }: JobAcquisitionProps) => {
  const campaign = acquisition?.campaign
  const webForm = acquisition?.webForm

  return campaign || webForm ? (
    <EuiFlexGroup>
      <EuiFlexItem grow={false}>
        <div className="small-label">CAMPAIGN</div>
        {acquisition?.campaign ? <CampaignLink campaign={acquisition?.campaign} /> : <>None</>}
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <div className="small-label">FORM</div>
        {acquisition?.webForm ? <WebFormLink webForm={acquisition?.webForm} /> : <>None</>}
      </EuiFlexItem>
    </EuiFlexGroup>
  ) : null
}
