import { JobType, TradeType } from '../../../api/generated-types'
import { InstallEnum, RepairOrReplaceEnum, isBrandServiceable } from '../appliance-shared/appliance-model'
import {
  TopicQualification,
  TopicQualificationResult,
  TopicRequirementsResult,
  createOutcomeGenerator,
  createRequirements
} from '../common/common-outcome'
import { CARPENTRY_QUOTING_SKILL } from '../common/skill-constants'
import { TopicDryerActionResult } from './action-topic-dryer-model'
import { getDryerRequirementsElectricRepair } from '../appliance-shared/repair-electrical-outcome'

export const getQualification = (result: TopicDryerActionResult | undefined): TopicQualificationResult => {
  const { issue, serviceType, wallMounted, customerHasBrackets, dryerRequiresDucting, customerHasDucting } =
    result ?? {}

  const answeredRepairQuestions = isBrandServiceable(result) && !!issue
  const answeredInstallReplaceQuestions =
    (serviceType === RepairOrReplaceEnum.Replace || serviceType === InstallEnum.Install) &&
    !!wallMounted &&
    !!dryerRequiresDucting &&
    !!customerHasBrackets &&
    !!customerHasDucting

  if (answeredRepairQuestions || answeredInstallReplaceQuestions) {
    return { qualification: TopicQualification.InScope }
  }

  return { qualification: TopicQualification.Unknown }
}

const getRequirements = (result: TopicDryerActionResult | undefined): TopicRequirementsResult => {
  const { serviceType, wallMounted, customerHasBrackets, customerHasDucting, dryerRequiresDucting } = result ?? {}

  if (serviceType === InstallEnum.Install || serviceType === RepairOrReplaceEnum.Replace) {
    if (!!wallMounted && !!customerHasBrackets && !!customerHasDucting && !!dryerRequiresDucting) {
      return { requirements: createRequirements([CARPENTRY_QUOTING_SKILL]), trade: TradeType.Carpentry }
    } else {
      return {}
    }
  }

  if (serviceType === RepairOrReplaceEnum.Repair && !!result) {
    return getDryerRequirementsElectricRepair(result)
  }
  return {}
}

export const outcomeGenerator = createOutcomeGenerator<TopicDryerActionResult>(
  { qualification: TopicQualification.Unknown, trade: TradeType.Carpentry, type: JobType.Service, category: 'General' },
  getQualification,
  getRequirements
)
