import { useQuery } from '@apollo/client'
import { EuiFlexGroup, EuiFlexItem, EuiText } from '@elastic/eui'
import { compact } from 'lodash-es'
import { JobFragment, JobSearchBetaResultFragment, SearchJobsDocument } from '../api/generated-types'
import { JobList } from './job-list-container'

export interface JobSummaryChildJobViewProps {
  currentJob: JobFragment
  linkedIds: string[]
}

export const LinkedJobsView = (props: JobSummaryChildJobViewProps) => {
  const { currentJob, linkedIds } = props

  const { data, loading } = useQuery(SearchJobsDocument, {
    variables: {
      input: {
        filter: {
          jobIds: [currentJob.id, ...linkedIds]
        }
      }
    }
  })

  const jobs: JobSearchBetaResultFragment[] = compact(data?.searchJobsBeta?.results ?? [])

  return (
    <>
      <EuiFlexGroup direction="column">
        <EuiFlexItem grow={false}>
          <EuiText color="subdued" size="m">
            <p>All jobs linked</p>
          </EuiText>
        </EuiFlexItem>
        {!loading && jobs && (
          <EuiFlexItem grow={false}>
            <JobList jobs={jobs} currentJobId={currentJob.id} />
          </EuiFlexItem>
        )}
      </EuiFlexGroup>
    </>
  )
}
