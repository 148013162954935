import { TopicActionResult } from './topics/action-topic-model'

export interface CustomerSuppliedTopicActionResult extends TopicActionResult {
  australianCompliant?: AustralianCompliant // supplied answer is so we can default the question to yes in our tests for is the appliance compliant question
}

export enum AustralianCompliant {
  Yes = 'Yes',
  No = 'No',
  Unknown = 'Unknown'
}
