import { EuiGlobalToastList } from '@elastic/eui'
// import { Toast } from '@elastic/eui/src/components/toast/global_toast_list'
import React, { useState } from 'react'

type Toast = any // TODO: fix elasticui import

export interface INotificationProviderContext {
  createToast: (toast: Toast) => void
  removeToast: (toastId: string) => void
}

export const NotificationProviderContext = React.createContext<INotificationProviderContext>({
  createToast: () => true,
  removeToast: () => true
})

export const useNotification = () => React.useContext(NotificationProviderContext)

interface NotificationProviderProps {
  children: React.ReactNode
}

export const NotificationProvider = (props: NotificationProviderProps) => {
  const [toasts, setToasts] = useState<Toast[]>([])

  const onCreateToast = (toast: Toast) => setToasts((toasts) => [...toasts, toast])
  const onRemoveToast = (toast: Toast) => setToasts((toasts) => toasts.filter((t) => t.id !== toast.id))

  const removeToast = (toastId: string) => setToasts((toasts) => toasts.filter((t) => t.id !== toastId))

  return (
    <NotificationProviderContext.Provider value={{ createToast: onCreateToast, removeToast }}>
      {props.children}
      <EuiGlobalToastList toasts={toasts} dismissToast={onRemoveToast} toastLifeTimeMs={6000} />
    </NotificationProviderContext.Provider>
  )
}
