import { EuiButton, EuiSpacer } from '@elastic/eui'
import { JobType, TradeType } from '../../../api/generated-types'
import { QuestionDefinitions, getNotesGenerator } from '../../helpers/notes-generator'
import { getResultUpdater } from '../../helpers/results-updater'
import { FreeTextQuestionField } from '../../question-fields/free-text-question-field'
import { WorkflowActionProps } from '../../workflow-model'
import { TopicActionInput, TopicActionResult } from '../action-topic-model'
import { TopicWorkflowAction } from '../action-topic-view'

export interface TopicPlumbingWaterHammeringActionResult extends TopicActionResult {
  affectedAreas?: string
  doesItHappenWhenTapFixturesAreInUse?: string
  anyRecentPlumbingAlterations?: string
  howLongHasThisIssueBeenHappeningFor?: string
}

const questionDefinitions: QuestionDefinitions<TopicPlumbingWaterHammeringActionResult> = {
  affectedAreas: {
    question: 'What areas of the plumbing are being effected?'
  },
  doesItHappenWhenTapFixturesAreInUse: {
    question: 'Does it happen when tap fixtures are in use?'
  },
  anyRecentPlumbingAlterations: {
    question: 'Has there been any alterations to the plumbing/has the pipe work been opened up recently?'
  },
  howLongHasThisIssueBeenHappeningFor: {
    question: 'How long has this been happening for?'
  }
}
const notesGenerator = getNotesGenerator(questionDefinitions)

export const TopicPlumbingWaterHammeringAction = (
  props: WorkflowActionProps<TopicActionInput, TopicPlumbingWaterHammeringActionResult>
) => {
  const { result, input, onUpdate } = props
  const {
    affectedAreas,
    doesItHappenWhenTapFixturesAreInUse,
    anyRecentPlumbingAlterations,
    howLongHasThisIssueBeenHappeningFor
  } = result ?? {}

  const onNext = () =>
    onUpdate({
      ...result,
      actionCompleted: true,
      trade: TradeType.Plumbing,
      category: 'WaterHammering',
      type: JobType.Service,

      workRequiredNotes: generatedNotes,
      requirements: {
        attributes: [{ attributeId: 'GeneralPlumbing' }]
      }
    })

  const generatedNotes = notesGenerator.generateNotes(result ?? {})
  const updateResult = getResultUpdater(result ?? {}, onUpdate).updateResult

  const canComplete =
    !!affectedAreas &&
    !!doesItHappenWhenTapFixturesAreInUse &&
    !!anyRecentPlumbingAlterations &&
    !!howLongHasThisIssueBeenHappeningFor

  return (
    <TopicWorkflowAction input={input} onUpdate={onUpdate} result={result}>
      <FreeTextQuestionField
        question={notesGenerator.getQuestion('affectedAreas')}
        answer={affectedAreas}
        rows={3}
        changeAnswer={(affectedAreas) => updateResult({ affectedAreas })}
      />
      <FreeTextQuestionField
        question={notesGenerator.getQuestion('doesItHappenWhenTapFixturesAreInUse')}
        answer={doesItHappenWhenTapFixturesAreInUse}
        changeAnswer={(doesItHappenWhenTapFixturesAreInUse) => updateResult({ doesItHappenWhenTapFixturesAreInUse })}
      />
      <FreeTextQuestionField
        question={notesGenerator.getQuestion('anyRecentPlumbingAlterations')}
        answer={anyRecentPlumbingAlterations}
        changeAnswer={(anyRecentPlumbingAlterations) => updateResult({ anyRecentPlumbingAlterations })}
      />
      <FreeTextQuestionField
        question={notesGenerator.getQuestion('howLongHasThisIssueBeenHappeningFor')}
        answer={howLongHasThisIssueBeenHappeningFor}
        changeAnswer={(howLongHasThisIssueBeenHappeningFor) => updateResult({ howLongHasThisIssueBeenHappeningFor })}
      />

      <EuiSpacer />
      <EuiButton disabled={!canComplete} onClick={() => onNext()}>
        Next
      </EuiButton>
    </TopicWorkflowAction>
  )
}
