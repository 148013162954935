import { EuiAccordion, EuiBasicTable, EuiSpacer } from '@elastic/eui'
import { CustomerLocationMembershipFragment, Membership, MembershipStatusType } from '../../../api/generated-types'
import { BouncingBallCustomerMembershipColumns } from '../../../memberships/membership-list-columns'

export interface ExpiredMembershipsAccordionProps {
  memberships: Membership[] | CustomerLocationMembershipFragment[] | undefined
}
export const ExpiredMembershipsAccordion = (props: ExpiredMembershipsAccordionProps) => {
  const { memberships } = props
  const expiredMemberships = memberships?.filter((m) => m.status.status === MembershipStatusType.Expired) ?? []
  return (
    <>
      {expiredMemberships.length > 0 && (
        <>
          <EuiAccordion id="show-expired-memberships" buttonContent="Show expired memberships">
            <EuiSpacer size="l" />
            <EuiBasicTable items={expiredMemberships} columns={BouncingBallCustomerMembershipColumns()} />
            <EuiSpacer size="l" />
          </EuiAccordion>
          <EuiSpacer size="l" />
        </>
      )}
    </>
  )
}
