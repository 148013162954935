import { useMutation } from '@apollo/client'
import {
  EuiButton,
  EuiButtonIcon,
  EuiCallOut,
  EuiCheckbox,
  EuiContextMenu,
  EuiContextMenuPanelDescriptor,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiFormRow,
  EuiHorizontalRule,
  EuiIcon,
  EuiLink,
  EuiPanel,
  EuiPopover,
  EuiRadioGroup,
  EuiRadioGroupOption,
  EuiSelect,
  EuiSelectOption,
  EuiSpacer,
  EuiTextArea,
  EuiTitle
} from '@elastic/eui'
import { addressFragmentToAddressInput } from '@fallonsolutions/address'
import { AcquisitionChannel } from '@fallonsolutions/customer'
import { UrlUtils } from '@fallonsolutions/domain'
import { compact, map } from 'lodash-es'
import { useMemo, useState } from 'react'
import {
  CompanyInput,
  ContactCustomerRole,
  ContactDetailFragment,
  ContactFragment,
  ContactInput,
  CreateCustomerContactInput,
  CreateCustomerV2Document,
  CreateCustomerV2Input,
  CreatePropertyDocument,
  CreatePropertyInput,
  CustomerCompanyFragment,
  CustomerFragment,
  CustomerLocationFragment,
  CustomerType
} from '../api/generated-types'
import { parsePhone } from '../common/phone'
import {
  ContactDetailForm,
  ContactDetailFormUpdate,
  createEmptyContactDetailFragment
} from '../contacts/contact-detail-form'
import { ContactDuplicateCheck } from '../contacts/contact-duplicate-check'
import { ContactSearchProvider } from '../contacts/contact-search/contact-search-provider'
import { validateContactDetail } from '../contacts/validate-contact-detail'
import createPersistedState from '../use-persisted-state'
import { CustomerCompanyDetailForm } from './customer-company-detail-form'
import { ContactCustomerRoleBadge } from './customer-contact-role-badge'
import { iconForContactCustomerRole } from './customer-contacts-view'

const useContactDuplicateCheck = createPersistedState<boolean>('contactDuplicateCheck')

const AcquisitionChannelOptions: EuiSelectOption[] = ([{ value: undefined, text: '' }] as EuiSelectOption[]).concat(
  map(AcquisitionChannel, (value) => ({ value, text: value }))
)

interface CreateCustomerFormV2Props {
  initialContactId?: string
  initialContactDetail?: ContactDetailFragment
  customerType?: CustomerType
  onCustomerCreated?: (customer: CustomerFragment) => void
  onCustomerLocationCreated?: (customerLocation: CustomerLocationFragment) => void
}

interface CustomerFormContact {
  contactId?: string
  roles: ContactCustomerRole[]
  detail: ContactDetailFragment
  isValid: boolean
}

export const CreateCustomerFormV2 = ({
  initialContactDetail,
  initialContactId,
  onCustomerCreated,
  onCustomerLocationCreated,
  ...props
}: CreateCustomerFormV2Props) => {
  const [duplicateCheck] = useContactDuplicateCheck(false)

  const initialContact = useMemo(
    () => createInitialContact(initialContactId, initialContactDetail),
    [initialContactId, initialContactDetail]
  )

  const [customerType, setCustomerType] = useState(props.customerType ?? CustomerType.Domestic)
  const [showValidationErrors, setShowValidationErrors] = useState(false)
  const [showNotes, setShowNotes] = useState(false)
  const [isContactDetailsMenuOpen, setIsContactDetailsMenuOpen] = useState<{
    [index: number]: boolean
  }>({})
  const [notes, setNotes] = useState('')
  const [acquisitionChannel, setAcquisitionChannel] = useState<string | undefined>(undefined)
  const [propertyAddressSameAsPrimaryContact, setPropertyAddressSameAsPrimaryContact] = useState(true)
  const [company, setCompany] = useState<CustomerCompanyFragment>({})
  const [companyValid, setCompanyValid] = useState(false)
  const [contacts, setContacts] = useState<CustomerFormContact[]>(initialContact)

  const customerTypeOptions: EuiRadioGroupOption[] = [
    {
      id: CustomerType.Domestic,
      label: 'Domestic'
    },
    {
      id: CustomerType.Commercial,
      label: 'Commercial'
    }
  ]

  const onChangeContact = (index: number, updateFn: ContactDetailFormUpdate) => {
    const result = updateFn({ detail: contacts[index].detail, isValid: contacts[index].isValid })
    const updated = [...contacts]
    updated[index].detail = result.detail
    updated[index].isValid = result.isValid
    setContacts(updated)
  }

  const addContact = () => {
    console.log('addContact')
    const firstContactAddress = contacts[0].detail.address ?? undefined
    const newContact: CustomerFormContact = {
      roles: [],
      detail: createEmptyContactDetailFragment(firstContactAddress),
      isValid: false
    }
    setContacts([...contacts, newContact])
  }

  const removeContact = (index: number) => {
    const updated = [...contacts]
    updated.splice(index, 1)
    setContacts(updated)
  }

  const addRoleToContact = (index: number, role: ContactCustomerRole) => {
    // Remove role from existing contact
    const updated = [...contacts].map((contact) => {
      contact.roles = contact.roles.filter((r) => r !== role)
      return contact
    })
    // Add to target
    updated[index].roles = [...updated[index].roles, role]
    setContacts(updated)
  }

  const makeContactContextMenu = (contact: CustomerFormContact, index: number) => {
    const roles = contact.roles ?? []
    const isBilling = roles.includes(ContactCustomerRole.Billing)
    const isPrimary = roles.includes(ContactCustomerRole.Primary)
    const canRemove = roles.length === 0
    return [
      {
        id: 0,
        items: [
          ...(!isBilling
            ? [
                {
                  name: `Set as billing contact`,
                  icon: iconForContactCustomerRole(ContactCustomerRole.Billing),
                  onClick: () => {
                    setIsContactDetailsMenuOpen({
                      ...isContactDetailsMenuOpen,
                      [index]: false
                    })
                    addRoleToContact(index, ContactCustomerRole.Billing)
                  }
                }
              ]
            : []),
          ...(!isPrimary
            ? [
                {
                  name: `Set as primary contact`,
                  icon: iconForContactCustomerRole(ContactCustomerRole.Primary),
                  onClick: () => {
                    setIsContactDetailsMenuOpen({
                      ...isContactDetailsMenuOpen,
                      [index]: false
                    })
                    addRoleToContact(index, ContactCustomerRole.Primary)
                  }
                }
              ]
            : []),
          ...(canRemove
            ? [
                {
                  isSeparator: true
                },
                {
                  name: 'Remove',
                  icon: 'error',
                  onClick: () => {
                    setIsContactDetailsMenuOpen({
                      ...isContactDetailsMenuOpen,
                      [index]: false
                    })
                    removeContact(index)
                  }
                }
              ]
            : [])
        ]
      }
    ] as EuiContextMenuPanelDescriptor[]
  }

  const [createCustomer, { loading: customerLoading, error: customerError }] = useMutation(CreateCustomerV2Document)
  const [createProperty, { loading: propertyLoading, error: propertyError }] = useMutation(CreatePropertyDocument)

  const createEntities = async () => {
    const primaryContact = contacts.find((c) => c.roles.includes(ContactCustomerRole.Primary))
    if (!primaryContact) {
      return
    }
    const companyWebNormalised = UrlUtils.tryParseUrl(company.web)
    const contactsInput: CreateCustomerContactInput[] = compact(
      contacts.map((c) => {
        if (!c.detail.firstName) {
          return undefined
        }
        const detailInput: ContactInput = {
          firstName: c.detail.firstName,
          lastName: c.detail.lastName,
          email: c.detail.email && c.detail.email.length > 0 ? c.detail.email : undefined,
          phone: parsePhone(c.detail.phone)?.number.toString() ?? undefined,
          alternatePhone: parsePhone(c.detail.alternatePhone)?.number.toString() ?? undefined,
          notes,
          ...(c.detail.address && { address: addressFragmentToAddressInput(c.detail.address) })
        }
        return {
          contactId: c.contactId,
          detail: detailInput,
          roles: c.roles
        }
      })
    )
    if (contactsInput.length <= 0) {
      return
    }
    if (!acquisitionChannel) {
      return
    }
    const companyInput: CompanyInput | undefined =
      customerType === CustomerType.Commercial && company.name
        ? {
            name: company.name.trim(),
            ...(company.abn?.trim().length && { abn: company.abn.trim() }),
            ...(companyWebNormalised && { web: companyWebNormalised })
          }
        : undefined

    const createCustomerInput: CreateCustomerV2Input = {
      type: customerType,
      contacts: contactsInput,
      ...(companyInput && { company: companyInput }),
      acquisitionChannel,
      ...(notes.trim().length && { notes: notes.trim() })
    }

    const createdCustomer = await createCustomer({ variables: { input: createCustomerInput } })

    const customer = createdCustomer.data?.createCustomerV2?.customer
    if (customer && propertyAddressSameAsPrimaryContact && primaryContact.detail.address) {
      const createPropertyInput: CreatePropertyInput = {
        customer: customer.id,
        address: addressFragmentToAddressInput(primaryContact.detail.address)
      }
      const createdProperty = await createProperty({ variables: { input: createPropertyInput } })
      const customerLocation = createdProperty.data?.createProperty?.customerLocation
      if (onCustomerLocationCreated && customerLocation) {
        onCustomerLocationCreated(customerLocation)
      }
    }
    if (onCustomerCreated && customer) {
      onCustomerCreated(customer)
    }
  }

  const formErrors = [
    ...contacts.filter((c) => !c.isValid),
    ...(customerType === CustomerType.Commercial && !companyValid ? [true] : [])
  ]

  const submit = async () => {
    setShowValidationErrors(true)
    if (formErrors.length <= 0) {
      await createEntities()
    }
  }

  const hideValidationErrors = () => setShowValidationErrors(false)

  const onDuplicateSelected = (index: number, contact: ContactFragment) => {
    console.log('onDuplicateSelected', index, contact)
    const updated = [...contacts]
    updated[index].contactId = contact.id
    updated[index].detail = contact.detail ?? createEmptyContactDetailFragment()
    setContacts(updated)
  }

  const acquisitionChannelValid = !!acquisitionChannel

  const loading = customerLoading || propertyLoading
  const error = customerError || propertyError

  return (
    <EuiForm isInvalid={showValidationErrors && (formErrors.length > 0 || !!error)} onInput={hideValidationErrors}>
      <EuiRadioGroup
        idSelected={customerType}
        options={customerTypeOptions}
        name="Customer type"
        onChange={(id) => setCustomerType(id as CustomerType)}
      />
      <EuiSpacer />

      {customerType === CustomerType.Commercial && (
        <>
          <EuiTitle size="xs">
            <h2>Company</h2>
          </EuiTitle>
          <EuiSpacer size="s" />
          <EuiPanel hasShadow={false} hasBorder={true}>
            <CustomerCompanyDetailForm
              company={company}
              onChange={setCompany}
              showValidationErrors={showValidationErrors}
              onValidationChange={(valid) => setCompanyValid(valid)}
            />
          </EuiPanel>
          <EuiSpacer />
        </>
      )}

      <EuiTitle size="xs">
        <h2>Contacts</h2>
      </EuiTitle>
      <EuiSpacer size="s" />
      <EuiFlexGroup direction="column" justifyContent="flexStart" alignItems="flexStart">
        {contacts.map((contact, index) => (
          <EuiFlexItem key={index} grow={false}>
            <EuiPanel hasShadow={false} hasBorder={true}>
              <EuiFlexGroup gutterSize="s" alignItems="center">
                {contact.roles.length > 0 ? (
                  contact.roles.map((role) => (
                    <EuiFlexItem key={role} grow={false}>
                      <ContactCustomerRoleBadge role={role} />
                    </EuiFlexItem>
                  ))
                ) : (
                  <EuiFlexItem grow={false}>
                    <ContactCustomerRoleBadge />
                  </EuiFlexItem>
                )}
                <EuiFlexItem grow={true} />
                {contact.isValid && (
                  <EuiFlexItem grow={false}>
                    <EuiIcon type="checkInCircleFilled" color="#34C759" />
                  </EuiFlexItem>
                )}
                <EuiFlexItem grow={false}>
                  <EuiPopover
                    isOpen={isContactDetailsMenuOpen[index]}
                    button={
                      <EuiButtonIcon
                        aria-label="show actions"
                        iconType="boxesHorizontal"
                        color="text"
                        disabled={contact.roles.length >= 2}
                        onClick={() =>
                          setIsContactDetailsMenuOpen({
                            ...isContactDetailsMenuOpen,
                            [index]: !isContactDetailsMenuOpen[index]
                          })
                        }
                      />
                    }
                    closePopover={() =>
                      setIsContactDetailsMenuOpen({
                        ...isContactDetailsMenuOpen,
                        [index]: false
                      })
                    }
                    ownFocus={true}
                    panelPaddingSize="none"
                  >
                    <EuiContextMenu initialPanelId={0} panels={makeContactContextMenu(contact, index)} />
                  </EuiPopover>
                </EuiFlexItem>
              </EuiFlexGroup>

              <EuiHorizontalRule margin="m" />

              <EuiFlexGroup alignItems="flexStart">
                <EuiFlexItem style={{ paddingRight: '24px', minWidth: '540px', maxWidth: '540px' }}>
                  {contact.contactId && (
                    <>
                      <EuiCallOut title="Editing existing contact" color="primary" size="s" />
                      <EuiSpacer />
                    </>
                  )}
                  <ContactDetailForm
                    contactId={contact.contactId}
                    detail={contact.detail}
                    onChange={(updateFn) => onChangeContact(index, updateFn)}
                    showValidationErrors={showValidationErrors}
                    addressRequired={true}
                  />
                  {contact.roles.includes(ContactCustomerRole.Primary) && (
                    <>
                      <EuiSpacer />
                      <EuiCheckbox
                        id="property-address-same"
                        label="Customer's property is at the same address"
                        checked={propertyAddressSameAsPrimaryContact}
                        onChange={(e) => setPropertyAddressSameAsPrimaryContact(e.target.checked)}
                      />
                    </>
                  )}
                </EuiFlexItem>
                {duplicateCheck && (
                  <ContactSearchProvider>
                    <EuiFlexItem style={{ width: '540px' }}>
                      <div className="eui-yScroll" style={{ maxHeight: '680px' }}>
                        <ContactDuplicateCheck
                          buttonLabel="Select"
                          detail={contact.detail}
                          onDuplicateSelected={(contact) => onDuplicateSelected(index, contact)}
                        />
                      </div>
                    </EuiFlexItem>
                  </ContactSearchProvider>
                )}
              </EuiFlexGroup>
            </EuiPanel>
          </EuiFlexItem>
        ))}
        <EuiFlexItem grow={false}>
          <EuiLink onClick={() => addContact()}>Add another contact</EuiLink>
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiSpacer />

      <EuiHorizontalRule />

      <EuiFormRow
        label="How did the customer hear about us?"
        isInvalid={showValidationErrors && !acquisitionChannelValid}
      >
        <EuiSelect
          isInvalid={showValidationErrors && !acquisitionChannelValid}
          options={AcquisitionChannelOptions}
          value={acquisitionChannel?.toString()}
          onChange={(e) => {
            console.log(e.target.value)
            setAcquisitionChannel(e.target.value)
          }}
        />
      </EuiFormRow>

      {showNotes ? (
        <>
          <EuiSpacer />
          <EuiFlexGroup>
            <EuiFlexItem grow={true}>
              <EuiFormRow label="Notes">
                <EuiTextArea
                  placeholder="Notes about the customer"
                  aria-label="Customer notes"
                  value={notes}
                  onChange={(e) => setNotes(e.target.value)}
                />
              </EuiFormRow>
            </EuiFlexItem>
          </EuiFlexGroup>
        </>
      ) : (
        <>
          <EuiSpacer />
          <EuiLink onClick={() => setShowNotes(true)} style={{ fontSize: '12px' }}>
            Add customer notes
          </EuiLink>
        </>
      )}

      <EuiSpacer />

      <EuiButton fill isLoading={loading} isDisabled={loading} onClick={submit}>
        Create customer
      </EuiButton>
      <div style={{ paddingBottom: '72px' }} />
    </EuiForm>
  )
}

const createInitialContact = (
  initialContactId?: string,
  initialContactDetail?: ContactDetailFragment
): CustomerFormContact[] => {
  console.log('createInitialContact')
  if (!initialContactId && !initialContactDetail) {
    return [
      {
        roles: [ContactCustomerRole.Primary, ContactCustomerRole.Billing],
        detail: createEmptyContactDetailFragment(),
        isValid: false
      }
    ]
  }
  return [
    {
      ...(initialContactId && { contactId: initialContactId }),
      roles: [ContactCustomerRole.Primary, ContactCustomerRole.Billing],
      detail: initialContactDetail ?? createEmptyContactDetailFragment(),
      isValid: initialContactDetail
        ? validateContactDetail({ detail: initialContactDetail, addressRequired: true }).isValid
        : false
    }
  ]
}
