import { useQuery } from '@apollo/client'
import { EuiBasicTable, EuiBasicTableColumn, EuiFlexGroup, EuiFlexItem, EuiRadio } from '@elastic/eui'
import { dateConfig } from '@fallonsolutions/date'
import { MoneyUtils } from '@fallonsolutions/money'
import { compact } from 'lodash-es'
import { DateTime } from 'luxon'
import {
  AddressFragment,
  GetCustomerJobsDocument,
  JobSearchBetaResultFragment,
  JobStatusType,
  TradeType
} from '../api/generated-types'
import { Callout } from '../common/callout'
import { ColorScaleOrder, ColorScaleProps } from '../common/color-scale'
import { formatMoney } from '../common/money'
import TradeTag from '../common/trade-tag'
import { useLocalStorage } from '../common/use-local-storage'
import { decamelise } from '../common/utils'

interface CustomerJobsSelectProps {
  customerId: string
  selectedJobId: string | undefined
  onSelect: (job: JobSearchBetaResultFragment) => void
}

export const CustomerJobsSelect = (props: CustomerJobsSelectProps) => {
  const { customerId, selectedJobId, onSelect } = props

  const [sandbox] = useLocalStorage('sandbox', false)
  const customers = [customerId]

  const { data, loading, error } = useQuery(GetCustomerJobsDocument, {
    variables: {
      input: {
        filter: { customers, sandbox },
        size: 10
      }
    }
  })
  const jobs = compact(data?.searchJobsBeta.results ?? [])

  return error ? (
    <Callout type="failure" title="Error loading jobs for customer" />
  ) : (
    <CustomerJobsTable jobs={jobs} onSelect={onSelect} selectedJobId={selectedJobId} loading={loading} />
  )
}

interface CustomerJobsTableProps {
  selectedJobId: string | undefined
  jobs: JobSearchBetaResultFragment[]
  onSelect: (job: JobSearchBetaResultFragment) => void
  loading: boolean
}

export const CustomerJobsTable = (props: CustomerJobsTableProps) => {
  const { onSelect, loading, selectedJobId, jobs } = props
  const columns: EuiBasicTableColumn<JobSearchBetaResultFragment>[] = [
    {
      field: 'number',
      name: 'Job no.',
      width: '120px',
      render: (number: string, job: JobSearchBetaResultFragment) => (
        <EuiFlexGroup gutterSize="none" alignItems="center">
          <EuiFlexItem grow={false}>
            <EuiRadio
              checked={job.id === selectedJobId}
              onChange={() => onSelect(job)}
              style={{ marginRight: '12px' }}
            />
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <span className="truncate" title={number}>
              {number}
            </span>
          </EuiFlexItem>
        </EuiFlexGroup>
      )
    },
    {
      field: 'created',
      name: 'Booked',
      width: '90px',
      render: (value: any) => {
        const created = DateTime.fromISO(value)
        if (created.isValid) {
          return (
            <span
              title={created.toFormat(dateConfig.luxonFormat.fullDate)}
              style={{ whiteSpace: 'nowrap', overflowX: 'hidden', textOverflow: 'ellipsis' }}
            >
              {created.toRelative({ style: 'short' })}
            </span>
          )
        } else {
          return ''
        }
      }
    },
    {
      field: 'legacy.startDate',
      name: 'Start date',
      render: (startDate: string | undefined) => {
        if (!startDate) {
          return ''
        }
        return DateTime.fromISO(startDate, { zone: dateConfig.defaultTimezone }).toFormat(
          dateConfig.luxonFormat.shortDate
        )
      },
      width: '110px'
    },
    {
      field: 'status.status',
      name: 'Status',
      truncateText: true,
      width: '90px',
      render: (status: JobStatusType) => decamelise(status)
    },
    {
      field: 'tradeType',
      name: 'Trade',
      width: '130px',
      render: (tradeType: TradeType | undefined) => {
        return (
          <EuiFlexGroup gutterSize="none" alignItems="center">
            <EuiFlexItem grow={false}>
              <TradeTag trade={tradeType ?? TradeType.None} style={{ marginRight: '9px' }} />
            </EuiFlexItem>
            <EuiFlexItem grow={true}>
              <span>{decamelise(tradeType)}</span>
            </EuiFlexItem>
          </EuiFlexGroup>
        )
      }
    },
    {
      field: 'type',
      name: 'Type',
      width: '90px'
    },
    {
      field: 'category',
      name: 'Category',
      width: '90px'
    },
    {
      field: 'property.address',
      name: 'Property',
      width: '200px',
      render: (address: AddressFragment | undefined) => {
        const addressString = `${address?.street}, ${address?.suburb}`
        return (
          <span title={addressString} className="truncate">
            {addressString}
          </span>
        )
      }
    },
    {
      field: 'invoiced',
      name: 'Invoiced',
      width: '100px',
      render: (invoiced: any) =>
        renderAmount(invoiced?.total?.amount, {
          rgb: '13, 255, 60',
          min: 200,
          max: 2000,
          order: ColorScaleOrder.Descending
        }),
      align: 'right'
    },
    {
      field: 'paid',
      name: 'Amount Paid',
      width: '100px',
      render: (paid: any) =>
        renderAmount(paid?.total?.amount, {
          rgb: '13, 255, 60',
          min: 200,
          max: 2000,
          order: ColorScaleOrder.Descending
        }),
      align: 'right'
    }
  ]

  return (
    <EuiBasicTable
      className="basic-table--minimal"
      loading={loading}
      items={jobs}
      columns={columns}
      rowProps={(job) => ({ onClick: () => onSelect(job) })}
      noItemsMessage={loading ? 'Searching...' : 'No jobs found'}
    />
  )
}

const renderAmount = (amount: string, scale: ColorScaleProps) => {
  if (amount) {
    const text = formatMoney(MoneyUtils.fromString(amount), {
      currencySymbol: true
    })
    return renderColorScale(parseFloat(amount), text, scale)
  } else {
    return ''
  }
}

const MAX_COLOR_ALPHA = 0.25

const renderColorScale = (value: number, display: string, scale: ColorScaleProps): any => {
  let normalised = 0
  switch (scale.order) {
    case ColorScaleOrder.Ascending:
      normalised = Math.min((value - scale.min) / scale.max, 1)
      break
    case ColorScaleOrder.Descending:
    default:
      normalised = Math.min((value - scale.min) / scale.max, 1)
  }
  const alpha = normalised * MAX_COLOR_ALPHA
  return (
    <EuiFlexGroup gutterSize="m" style={{ background: `rgba(${scale.rgb}, ${alpha})`, textAlign: 'right' }}>
      <EuiFlexItem>{display}</EuiFlexItem>
    </EuiFlexGroup>
  )
}
