export interface INavItem {
  url: string
  name: string
  icon?: string
  developerOnly?: boolean
}

export const NavItem: { [key: string]: INavItem } = {
  ActivityList: { url: '/activities', name: 'Activity log', icon: 'timeslider' },
  AppointmentList: { url: '/appointments', name: 'Appointments', icon: 'calendar' },
  BookingConfig: { url: '/booking/config', name: 'Booking config', icon: 'gear', developerOnly: true },
  BookingList: { url: '/call-centre/bookings', name: 'Bookings', icon: 'calendar' },
  CallCentre: { url: '/call-centre', name: 'Call centre', icon: 'editorComment' },
  ComplaintList: { url: '/complaints', name: 'Complaints', developerOnly: true, icon: 'flag' },
  ContactList: { url: '/contacts', name: 'Contacts', icon: 'users' },
  CostList: { url: '/finance/costs', name: 'Costs', icon: 'sortLeft' },
  CustomerList: { url: '/customers', name: 'Customers', icon: 'users' },
  DiscountList: { url: '/marketing/discounts', name: 'Discounts', icon: 'tag' },
  ExportList: { url: '/exports', name: 'Exports', icon: 'exportAction' },
  Glossary: { url: '/glossary', name: 'Glossary', icon: 'glasses' },
  InspectionList: { url: '/inspections', name: 'Inspections', icon: 'visGauge' },
  InvoiceList: { url: '/finance/invoices', name: 'Invoices', icon: 'sortRight' },
  JobList: { url: '/jobs', name: 'Jobs', icon: 'wrench' },
  JobReportList: { url: '/job-reports', name: 'Job reports', icon: 'document' },
  JobTypeList: { url: '/job-types', name: 'Job types', icon: 'editorUnorderedList' },
  LeaderboardList: { url: '/leaderboards', name: 'Leaderboards', icon: 'visBarVertical' },
  Marketing: { url: '/marketing', name: 'Marketing', icon: 'cheer' },
  MediaItemList: { url: '/media', name: 'Photos', icon: 'image' },
  MembershipList: { url: '/memberships', name: 'Memberships', icon: 'faceHappy' },
  PaymentList: { url: '/finance/payments', name: 'Payments', icon: 'payment' },
  Performance: { url: '/performance', name: 'Performance', icon: 'visBarVertical' },
  PricelistList: { url: '/pricelists', name: 'Pricelists', icon: 'documentation' },
  PropertyList: { url: '/properties', name: 'Properties', icon: 'home' },
  PublicHolidayList: { url: '/public-holidays', name: 'Public holidays', icon: 'cloudSunny' },
  ReportList: { url: '/reports', name: 'Reports', icon: 'visTable' },
  ReviewList: { url: '/reviews', name: 'Reviews', icon: 'starEmptySpace' },
  SalesEventList: { url: '/sales', name: 'Sales', icon: 'bullseye' },
  ScheduledServiceList: { url: '/scheduled-services', name: 'Scheduled services', icon: 'calendar' },
  Schedule: { url: '/schedule', name: 'Schedule', icon: 'calendar' },
  ServiceAreaList: { url: '/service-areas', name: 'Suburbs', icon: 'mapMarker' },
  Settings: { url: '/settings', name: 'Settings', icon: 'gear' },
  SkillList: { url: '/skills', name: 'Skills', icon: 'accessibility' },
  Timesheets: { url: '/timesheets', name: 'Timesheets', icon: 'clock' },
  TransactionList: { url: '/finance/transactions', name: 'Transactions', icon: 'merge' },
  UserList: { url: '/users', name: 'Users', icon: 'user' }
}
