import { useState } from 'react'
import Confetti from 'react-confetti'

export interface ConfettiViewProps {
  colors?: string[]
  source?: {
    x: number
    y: number
  }
}

export const ConfettiView = ({ source, colors }: ConfettiViewProps) => {
  const [showConfetti, setShowConfetti] = useState(true)

  const onComplete = () => setShowConfetti(false)

  return (
    <>
      {showConfetti && (
        <Confetti
          style={{ zIndex: 1000 }}
          initialVelocityX={4}
          initialVelocityY={6}
          wind={0.05}
          gravity={0.2}
          {...(source && { confettiSource: { x: source.x, y: source.y, w: 1, h: 1 } })}
          numberOfPieces={150}
          recycle={false}
          onConfettiComplete={onComplete}
          colors={colors}
        />
      )}
    </>
  )
}
