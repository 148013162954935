import { useMutation } from '@apollo/client'
import {
  EuiButtonEmpty,
  EuiButtonIcon,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHorizontalRule,
  EuiIcon,
  EuiIconTip,
  EuiPopover,
  EuiSpacer,
  EuiText
} from '@elastic/eui'
import { dateConfig } from '@fallonsolutions/date'
import { DateTime, Duration } from 'luxon'
import { ReactElement, useEffect, useState } from 'react'
import {
  CustomerType,
  DeleteScheduleEventDocument,
  DispatchScheduleEventDocument,
  PriorityType,
  ScheduleEventDetailFragment,
  ScheduleEventFragment,
  ScheduleEventType,
  ScheduleFlexibility,
  SendScheduleEventEmailDocument,
  SendScheduleEventMessageDocument,
  UpdateAppointmentJobClassificationDocument,
  UpdateJobClassificationDocument,
  UpdateScheduleEventConfirmationDocument,
  UpdateScheduleEventNotesDocument,
  UpdateScheduleEventStatusDocument
} from '../../api/generated-types'
import { useApp } from '../../app/app-context'
import { decamelise, labelOf } from '../../common/utils'
import '../../static/css/schedule-event-card.css'
import UserLink from '../../users/user-link'
import { titleForEvent } from '../schedule-event-adapter'
import { ScheduleEventContextMenu } from '../schedule-event-context-menu/schedule-event-context-menu'
import { ScheduleToken, ScheduleTokenType } from '../schedule-token'

export enum ScheduleEventCardSelectionBehaviour {
  Toggle, // if already selected, unselect
  ReSelect // if already selected, re-select
}

interface ScheduleEventCardProps {
  event: ScheduleEventFragment | ScheduleEventDetailFragment
  selected?: boolean | undefined
  selectedBadge?: string
  selectionBehaviour?: ScheduleEventCardSelectionBehaviour
  onSelect?: (selected: boolean) => void
  onExpire?: () => void
  style?: React.CSSProperties
  actions?: ReactElement[] | undefined
  showStatus?: boolean
  showCustomer?: boolean
  showFlexibility?: boolean
  children?: React.ReactNode
}

const formatDuration = (
  fromISOString: string,
  toISOString: string,
  durationInMinutes?: number | null
): { duration: string; startEndTimes: string } | undefined => {
  if (!fromISOString || !toISOString) return undefined

  const from = DateTime.fromISO(fromISOString, { setZone: true })
  const to = DateTime.fromISO(toISOString, { setZone: true })

  const startEndTimesFormatted = `${from.toFormat(dateConfig.luxonFormat.dateAndTime)} - ${to.toFormat(
    dateConfig.luxonFormat.dateAndTime
  )}`

  const duration = durationInMinutes
    ? Duration.fromObject({ minutes: Math.floor(durationInMinutes) })
    : to.diff(from, 'minutes')
  const durationComponents = duration.shiftTo('hours', 'minutes')
  const durationStr = [
    ...(durationComponents.hours > 0 ? [`${durationComponents.hours}h`] : []),
    ...(durationComponents.minutes > 0 ? [`${durationComponents.minutes}m`] : [])
  ].join(' ')
  return { duration: durationStr, startEndTimes: startEndTimesFormatted }
}

export const ScheduleEventCard = (props: ScheduleEventCardProps) => {
  const { event, selected, selectedBadge, onSelect, onExpire, style, actions } = props
  const appContext = useApp()
  const showStatus = props.showStatus ?? false
  const showCustomer = props.showCustomer ?? false
  const showFlexibility = props.showFlexibility ?? false
  const selectionBehaviour = props.selectionBehaviour ?? ScheduleEventCardSelectionBehaviour.Toggle

  const showTokens = !appContext.isProd
  const [isContextMenuVisible, setIsContextMenuVisible] = useState(false)

  const handleActionButtonClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    console.log(e)
    e.stopPropagation()
    setIsContextMenuVisible(!isContextMenuVisible)
  }

  const closeContextMenu = () => setIsContextMenuVisible(false)

  const [remainingSeconds, setRemainingSeconds] = useState<number | undefined>(
    event.ttl ? Math.round(event.ttl - DateTime.now().toSeconds()) : undefined
  )
  const [createdAgoInSeconds, setCreatedAgoInSeconds] = useState<number>(
    Math.round(DateTime.now().toSeconds() - DateTime.fromISO(event.created).toSeconds())
  )

  const from = DateTime.fromISO(event.scheduled.from, { setZone: true })
  const to = DateTime.fromISO(event.scheduled.to, { setZone: true })

  const dateStr = from.toFormat(dateConfig.luxonFormat.shortDate)
  const fromStr = from.toFormat(dateConfig.luxonFormat.time).toLocaleLowerCase()
  const toStr = to.toFormat(dateConfig.luxonFormat.time).toLocaleLowerCase()

  const { duration: plannedDurationFormatted } =
    formatDuration(event.scheduled.from, event.scheduled.to, event.duration) ?? {}

  const { duration: actualDurationFormatted, startEndTimes: actualStartEndTimesFormatted } =
    formatDuration(event.timesheetEntry?.time.from, event.timesheetEntry?.time.to, event.timesheetEntry?.duration) ?? {}

  useEffect(() => {
    const timer = setInterval(() => {
      setCreatedAgoInSeconds(Math.round(DateTime.now().toSeconds() - DateTime.fromISO(event.created).toSeconds()))
      if (event.ttl) {
        const currentRemainingSeconds = Math.round(event.ttl - DateTime.now().toSeconds())
        setRemainingSeconds(currentRemainingSeconds <= 0 ? 0 : currentRemainingSeconds)
        if (currentRemainingSeconds <= 0) {
          clearInterval(timer)
          onExpire?.()
          // unselect expired event
          if (selected) onSelect?.(false)
        }
      } else {
        clearInterval(timer)
      }
    }, 1000)
    return () => clearInterval(timer)
  }, [remainingSeconds, event.ttl, event.created, selected, onExpire, onSelect])

  const remainingSecondsAsDuration = Duration.fromObject({ seconds: remainingSeconds })
  const { display: ttlDisplay, highlightColour: ttlHighlightColour }: { display?: string; highlightColour?: string } =
    (() => {
      if (selected && selectedBadge) return { display: selectedBadge, highlightColour: 'green' }
      if (createdAgoInSeconds <= 30) return { display: 'NEW', highlightColour: 'green' }
      if (remainingSeconds === undefined || remainingSeconds > 6 * 60 - 1) return {}
      if (remainingSeconds <= 0) return { display: 'EXPIRED', highlightColour: 'red' }
      if (remainingSeconds <= 60) {
        const formattedDuration = remainingSecondsAsDuration?.toFormat(`s's'`)
        return { display: `${formattedDuration} remaining`, highlightColour: 'red' }
      } else {
        const formattedDuration = '> ' + remainingSecondsAsDuration?.toFormat(`m'm`)
        return { display: `${formattedDuration} remaining` }
      }
    })()

  const customerName = () => {
    if (!showCustomer || !event.customerData) return undefined

    const customerType = event.customerData.type ?? CustomerType.Commercial
    const mainContactFullName = event.customerData.mainContact?.fullName
    const companyName = event.customerData.company?.name

    switch (customerType) {
      default:
      case CustomerType.Domestic:
        return mainContactFullName ?? undefined
      case CustomerType.Commercial:
        if (mainContactFullName && companyName) {
          return `${mainContactFullName} (${companyName})`
        }
        return mainContactFullName ?? companyName ?? undefined
    }
  }

  const [confirmEvent] = useMutation(UpdateScheduleEventConfirmationDocument)
  const [updateStatus] = useMutation(UpdateScheduleEventStatusDocument)
  const [updateNotes] = useMutation(UpdateScheduleEventNotesDocument)
  const [sendMessage] = useMutation(SendScheduleEventMessageDocument)
  const [sendEmail] = useMutation(SendScheduleEventEmailDocument)
  const [dispatchEvent] = useMutation(DispatchScheduleEventDocument)
  const [deleteEvent] = useMutation(DeleteScheduleEventDocument)
  const [updateJobClassification] = useMutation(UpdateJobClassificationDocument)
  const [updateAppointmentJobClassification] = useMutation(UpdateAppointmentJobClassificationDocument)

  const onContextMenuActionClick = () => closeContextMenu()

  const select = () => {
    if (remainingSeconds === undefined || remainingSeconds > 0) {
      switch (selectionBehaviour) {
        case ScheduleEventCardSelectionBehaviour.Toggle:
          onSelect?.(!selected)
          return
        case ScheduleEventCardSelectionBehaviour.ReSelect:
          onSelect?.(true)
          return
      }
    }
  }

  const vaccinatedRequirement = !!event.appointmentRequirements?.attributes?.find(
    (a) => a.attributeId === 'VaccinatedCovid19'
  )
  const flexible = event?.appointmentRequirements?.flexibility?.schedule
    ? [ScheduleFlexibility.Flexible, ScheduleFlexibility.VeryFlexible].includes(
        event.appointmentRequirements.flexibility.schedule
      )
    : false

  const tokens = [
    ...(event.appointmentPriority === PriorityType.P1 ? [ScheduleTokenType.Priority] : []),
    ...(event.appointmentPriority === PriorityType.P2 ? [ScheduleTokenType.Priority2] : []),
    ...(vaccinatedRequirement ? [ScheduleTokenType.Vaccinated] : []),
    ...(flexible && showFlexibility ? [ScheduleTokenType.Flexible] : [])
  ]

  return (
    <div className="schedule-event-card-wrapper" style={style} data-test-id="schedule-event-card">
      <div
        className={`schedule-event-card schedule-event-card--status-${
          event.status?.status?.toLocaleLowerCase() ?? 'none'
        } ${onSelect ? 'schedule-event-card--selectable' : ''}`}
      >
        <div
          className={`schedule-event-card__status schedule-event-card__status--${
            event.status?.status?.toLocaleLowerCase() ?? 'none'
          }`}
        />
        <EuiFlexGroup direction="column" gutterSize="s" onClick={select}>
          <EuiFlexItem className="caption-text">
            <EuiFlexGroup gutterSize="none" alignItems="center" responsive={false}>
              <EuiFlexItem grow={false}>
                {event.type === ScheduleEventType.Appointment && event.job?.id ? (
                  <EuiButtonEmpty
                    color="text"
                    href={`/jobs/${event.job.id}`}
                    target="_blank"
                    iconType="wrench"
                    contentProps={{
                      style: { padding: '0' }
                    }}
                    title={titleForEvent(event)}
                    size="xs"
                    className="euiButtonEmpty--noBackgroundOnFocus"
                  >
                    {titleForEvent(event)}
                  </EuiButtonEmpty>
                ) : (
                  <EuiText size="s">{titleForEvent(event)}</EuiText>
                )}
              </EuiFlexItem>
              {showTokens && event.type === ScheduleEventType.Appointment && (
                <EuiFlexItem grow={false} style={{ marginLeft: '3px' }} gutter-size="small">
                  <EuiFlexGroup gutter-size="none" direction="row">
                    {tokens.map((token) => (
                      <EuiFlexItem grow={false} key={token}>
                        <ScheduleToken type={token} size="m" />
                      </EuiFlexItem>
                    ))}
                  </EuiFlexGroup>
                </EuiFlexItem>
              )}
              {event.type === ScheduleEventType.Appointment && (
                <EuiFlexItem grow={false}>
                  <EuiIconTip
                    size="s"
                    // iconProps={{
                    //   style: { marginLeft: '5px', marginBottom: '2px' }
                    // }}
                    content={
                      <div>
                        Trade: {decamelise(event.trade)}
                        <br />
                        Job Type: {decamelise(event.jobType)}
                        <br />
                        Category: {decamelise(event.jobCategory)}
                      </div>
                    }
                  />
                </EuiFlexItem>
              )}
              <EuiFlexItem grow={true}></EuiFlexItem>
              {ttlDisplay && (
                <EuiFlexItem grow={false} style={ttlHighlightColour ? { color: ttlHighlightColour } : {}}>
                  {ttlDisplay}
                </EuiFlexItem>
              )}
              <EuiFlexItem grow={false}>
                <EuiPopover
                  button={
                    <EuiButtonIcon
                      aria-label="toggle context menu"
                      iconType="boxesHorizontal"
                      style={{ marginLeft: '6px' }}
                      onClick={handleActionButtonClick}
                    />
                  }
                  isOpen={isContextMenuVisible}
                  closePopover={closeContextMenu}
                  panelPaddingSize="none"
                  anchorPosition="rightUp"
                >
                  <ScheduleEventContextMenu
                    event={event}
                    confirmEvent={confirmEvent}
                    updateStatus={updateStatus}
                    updateJobClassification={updateJobClassification}
                    updateAppointmentJobClassification={updateAppointmentJobClassification}
                    updateNotes={updateNotes}
                    sendMessage={sendMessage}
                    sendEmail={sendEmail}
                    dispatchEvent={dispatchEvent}
                    deleteEvent={deleteEvent}
                    onActionClick={onContextMenuActionClick}
                  />
                </EuiPopover>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
          {!!customerName() && (
            <EuiFlexItem>
              <EuiFlexGroup gutterSize="none" alignItems="center" responsive={false}>
                <EuiFlexItem grow={false}>
                  <EuiButtonEmpty
                    href={`/customers/${event.customerData?.id}`}
                    color="text"
                    target="_blank"
                    iconType="user"
                    contentProps={{
                      style: { padding: '0' }
                    }}
                    title={customerName()}
                    size="xs"
                    className="euiButtonEmpty--noBackgroundOnFocus"
                  >
                    {customerName()}
                  </EuiButtonEmpty>
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlexItem>
          )}
          <EuiFlexItem>
            <EuiFlexGroup className="large-text" gutterSize="none" responsive={false} alignItems="center">
              <EuiFlexItem grow={true}>{dateStr}</EuiFlexItem>
              {showStatus && (
                <EuiFlexItem grow={false}>
                  <div
                    className={`schedule-event-status schedule-event-status--${
                      event.status?.status?.toLowerCase() ?? 'none'
                    }`}
                  >
                    {event.status?.status ? labelOf(event?.status?.status) : 'Unknown status'}
                  </div>
                </EuiFlexItem>
              )}
            </EuiFlexGroup>
            <EuiSpacer size="s" />
            <EuiFlexGroup className="large-text" gutterSize="none" responsive={false} alignItems="center">
              <EuiFlexItem grow={true}>
                <div className="huge-text">
                  {fromStr} - {toStr}
                </div>
              </EuiFlexItem>
              <EuiFlexItem grow={false}>{plannedDurationFormatted}</EuiFlexItem>
              {actualDurationFormatted && (
                <EuiFlexItem
                  grow={false}
                  className="schedule-event-card__actual-duration"
                  title={`Clock on/off: ${actualStartEndTimesFormatted}`}
                >
                  {actualDurationFormatted}
                </EuiFlexItem>
              )}
            </EuiFlexGroup>
            <EuiSpacer size="s" />
          </EuiFlexItem>
          {props.children && <EuiFlexItem>{props.children}</EuiFlexItem>}
          <EuiFlexItem>
            <EuiHorizontalRule margin="xs" />
            <EuiFlexGroup gutterSize="none" alignItems="center">
              <EuiFlexItem grow={false}>
                <UserLink user={event.technician} />
              </EuiFlexItem>
              <EuiFlexItem grow={true} />
              <EuiFlexItem grow={false}>
                {selected && <EuiIcon type="checkInCircleFilled" color="primary" size="l" />}
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
          {actions && actions.length > 0 && (
            <EuiFlexItem>
              <EuiSpacer size="l" />
              <EuiFlexGroup>
                {actions.map((action, index) => (
                  <EuiFlexItem key={index}>{action}</EuiFlexItem>
                ))}
              </EuiFlexGroup>
            </EuiFlexItem>
          )}
        </EuiFlexGroup>
      </div>
    </div>
  )
}
