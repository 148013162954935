import { NetworkStatus, useMutation, useQuery } from '@apollo/client'
import { EuiButtonEmpty, EuiCallOut, EuiFlexGroup, EuiFlexItem, EuiSpacer, EuiTitle } from '@elastic/eui'
import { useCallback, useEffect, useState } from 'react'
import {
  ExtendScheduleEventReservationDocument,
  OnCreateScheduleEventDocument,
  OnDeleteScheduleEventDocument,
  OnUpdateScheduleEventDocument,
  ScheduleEventConfirmation,
  ScheduleEventFilters,
  ScheduleEventFragment,
  ScheduleEventSource,
  ScheduleEventType,
  SearchScheduleEventsDocument,
  SearchScheduleEventsQuery
} from '../api/generated-types'
import { useAuthenticated } from '../auth/authenticated-context'
import { ScheduleEventCard } from '../schedule/schedule-event-card/schedule-event-card'

import { DateTime } from 'luxon'

const currentTenant = 'fallonsolutions'

interface ReservedScheduleEventsProps {
  onSelect: (scheduleEvent: ScheduleEventFragment | undefined) => void
  scheduleLink: string
  isVisible?: boolean // This is used for when we need to keep subscriptions alive but no longer need to display the component on screen
  scheduleFilters?: ScheduleEventFilters
  includeTitle?: boolean
}

export const ReservedScheduleEvents = ({
  onSelect,
  scheduleLink,
  isVisible = true,
  scheduleFilters,
  includeTitle
}: ReservedScheduleEventsProps) => {
  const userFragment = useAuthenticated().userFragment
  const userId = userFragment.id
  const displayTitle = includeTitle ?? true

  const [extendScheduleEventReservation] = useMutation(ExtendScheduleEventReservationDocument)
  const { data, subscribeToMore, networkStatus, error, refetch } = useQuery(SearchScheduleEventsDocument, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
    variables: {
      input: {
        filters: scheduleFilters ?? {
          confirmation: ScheduleEventConfirmation.Tentative,
          createdBy: userId,
          type: [ScheduleEventType.ToBeAdvised],
          source: ScheduleEventSource.Platform.toString()
        }
      }
    }
  })

  const [selectedScheduleEvent, setSelectedScheduleEvent] = useState<ScheduleEventFragment | undefined>(undefined)

  const getRemainingReservationTimeInSeconds = useCallback(
    (scheduleEvent: ScheduleEventFragment): number | undefined => {
      if (!scheduleEvent.ttl) return undefined
      return Math.round(scheduleEvent.ttl - DateTime.now().toSeconds())
    },
    []
  )

  const isReservedScheduleReservationEventGoingToExpireSoon = useCallback(
    (scheduleEvent: ScheduleEventFragment) => {
      const remainingSeconds = getRemainingReservationTimeInSeconds(scheduleEvent)
      return remainingSeconds !== undefined && remainingSeconds < 260
    },
    [getRemainingReservationTimeInSeconds]
  )

  const makeSureReservedScheduleEventWillNotExpire = useCallback(
    async (scheduleEvent: ScheduleEventFragment | undefined) => {
      if (scheduleEvent && isReservedScheduleReservationEventGoingToExpireSoon(scheduleEvent)) {
        console.log(
          'Schedule event is going to expire - extending reservation now...',
          scheduleEvent.id,
          scheduleEvent.ttl,
          getRemainingReservationTimeInSeconds(scheduleEvent)
        )
        const response = await extendScheduleEventReservation({ variables: { input: { id: scheduleEvent.id } } })
        console.log(
          'Schedule event reservation extended.',
          scheduleEvent.id,
          response.data?.extendScheduleEventReservation.scheduleEvent?.ttl,
          response.data?.extendScheduleEventReservation.scheduleEvent
            ? getRemainingReservationTimeInSeconds(response.data?.extendScheduleEventReservation.scheduleEvent)
            : ''
        )
      }
    },
    [
      extendScheduleEventReservation,
      isReservedScheduleReservationEventGoingToExpireSoon,
      getRemainingReservationTimeInSeconds
    ]
  )

  const deleteSelection = useCallback(
    async (removedScheduleEventId: string) => {
      if (removedScheduleEventId === selectedScheduleEvent?.id) {
        setSelectedScheduleEvent(undefined)
        onSelect?.(undefined)
      }
    },
    [onSelect, selectedScheduleEvent]
  )

  const updateSelection = useCallback(
    async (scheduleEvent: ScheduleEventFragment) => {
      if (scheduleEvent?.id === selectedScheduleEvent?.id) {
        setSelectedScheduleEvent(scheduleEvent)
        onSelect?.(scheduleEvent)
      }
      await makeSureReservedScheduleEventWillNotExpire(scheduleEvent)
    },
    [selectedScheduleEvent, makeSureReservedScheduleEventWillNotExpire, onSelect]
  )

  const changeSelection = useCallback(
    async (scheduleEvent: ScheduleEventFragment | undefined, extendReservation: boolean) => {
      setSelectedScheduleEvent(scheduleEvent)
      onSelect?.(scheduleEvent)
      if (extendReservation) await makeSureReservedScheduleEventWillNotExpire(scheduleEvent)
    },
    [onSelect, makeSureReservedScheduleEventWillNotExpire]
  )

  useEffect(() => {
    console.log('setting event interval')
    const timer = setInterval(async () => {
      await makeSureReservedScheduleEventWillNotExpire(selectedScheduleEvent)
    }, 30 * 1000)
    return () => {
      console.log('clearing event interval!')
      clearInterval(timer)
    }
  }, [makeSureReservedScheduleEventWillNotExpire, selectedScheduleEvent])

  const isInScope = useCallback(
    (scheduleEvent: ScheduleEventFragment): boolean => {
      if (scheduleFilters) {
        return isScheduleEventInScope(scheduleEvent, scheduleFilters)
      }
      return (
        scheduleEvent.confirmation === ScheduleEventConfirmation.Tentative &&
        scheduleEvent.createdBy?.id === userId &&
        scheduleEvent.type === ScheduleEventType.ToBeAdvised &&
        scheduleEvent.source === ScheduleEventSource.Platform.toString()
      )
    },
    [userId, scheduleFilters]
  )

  useEffect(() => {
    if (subscribeToMore) {
      console.log('%cReservedScheduleEvents: Subscribing to schedule updates...', 'color:red')
      const removeOnCreateSub = subscribeToMore({
        document: OnCreateScheduleEventDocument,
        variables: { tenant: currentTenant },
        updateQuery: (prev, { subscriptionData }) =>
          updateScheduleOnScheduleEventAddedOrUpdated(
            prev,
            subscriptionData.data.onCreateScheduleEvent?.scheduleEvent,
            isInScope,
            updateSelection
          ),
        onError: (err) => console.error('OnCreateScheduleEventSubscription err', err)
      })
      const removeOnUpdateSub = subscribeToMore({
        document: OnUpdateScheduleEventDocument,
        variables: { tenant: currentTenant },
        updateQuery: (prev, { subscriptionData }) =>
          updateScheduleOnScheduleEventAddedOrUpdated(
            prev,
            subscriptionData.data.onUpdateScheduleEvent?.scheduleEvent,
            isInScope,
            updateSelection
          ),
        onError: (err) => console.error('OnUpdateScheduleEventSubscription err', err)
      })
      const removeOnDeleteSub = subscribeToMore({
        document: OnDeleteScheduleEventDocument,
        variables: { tenant: currentTenant },
        updateQuery: (prev, { subscriptionData }) =>
          updateScheduleOnScheduleEventDeleted(prev, subscriptionData.data.onDeleteScheduleEvent?.id, deleteSelection),
        onError: (err) => console.error('OnDeleteScheduleEventSubscription err', err)
      })
      return () => {
        console.log('%cReservedScheduleEvents: Unsubscribing from schedule updates... ', 'color:red')
        removeOnCreateSub()
        removeOnUpdateSub()
        removeOnDeleteSub()
      }
    }
  }, [subscribeToMore, isInScope, updateSelection, deleteSelection])

  return (
    <>
      {isVisible && (
        <>
          {displayTitle && (
            <>
              <EuiTitle size="xs">
                <h3>
                  Available time slots on{' '}
                  <a href={scheduleLink} target="_blank" rel="noreferrer">
                    Schedule
                  </a>
                </h3>
              </EuiTitle>
            </>
          )}

          {!!data?.searchScheduleEvents.results.length && (
            <>
              <EuiSpacer size="s" />
              <EuiFlexGroup justifyContent="flexStart" alignItems="center" wrap={true} gutterSize="m">
                {data.searchScheduleEvents.results.filter(isInScope).map((se) => (
                  <EuiFlexItem grow={false} key={se.id}>
                    <ScheduleEventCard
                      event={se}
                      selected={se.id === selectedScheduleEvent?.id}
                      selectedBadge="Reserved"
                      onSelect={(selected: boolean) => changeSelection(selected ? se : undefined, true)}
                      onExpire={() => console.log('Schedule event has expired', se.id)}
                    />
                  </EuiFlexItem>
                ))}
              </EuiFlexGroup>{' '}
            </>
          )}
          {error && <EuiCallOut color="danger">Encountered error: {error.message}</EuiCallOut>}
          <EuiSpacer size="s" />
          <EuiFlexGroup justifyContent="flexStart" gutterSize="none">
            <EuiFlexItem grow={false}>
              <EuiButtonEmpty
                iconType="refresh"
                color="text"
                size="s"
                onClick={() => refetch?.()}
                aria-label="Refresh"
                disabled={networkStatus === NetworkStatus.refetch}
              >
                {networkStatus === NetworkStatus.refetch ? 'Loading...' : 'Refresh'}
              </EuiButtonEmpty>
            </EuiFlexItem>
          </EuiFlexGroup>
          <EuiSpacer size="l" />
        </>
      )}
    </>
  )
}

const updateScheduleOnScheduleEventAddedOrUpdated = (
  prev: SearchScheduleEventsQuery,
  scheduleEvent: ScheduleEventFragment | null | undefined,
  isInScope: (scheduleEvent: ScheduleEventFragment) => boolean,
  updateSelection: Function
): SearchScheduleEventsQuery => {
  if (!scheduleEvent) return prev
  console.log('on[create|update]ScheduleEventSub: updating reserved schedule events')
  const inScope = isInScope(scheduleEvent)
  if (prev.searchScheduleEvents.results.find((s) => s.id === scheduleEvent.id)) {
    if (!inScope) return removeScheduleEvent(prev, scheduleEvent.id)
  } else {
    if (inScope) return addScheduleEvent(prev, scheduleEvent)
  }
  updateSelection(scheduleEvent)
  return prev
}

const updateScheduleOnScheduleEventDeleted = (
  prev: SearchScheduleEventsQuery,
  removedScheduleEventId: string | undefined | null,
  deleteSelection: Function
): SearchScheduleEventsQuery => {
  console.log('onDeleteScheduleEventSub: updating schedule')
  if (!removedScheduleEventId) return prev
  deleteSelection(removedScheduleEventId)
  return removeScheduleEvent(prev, removedScheduleEventId)
}

const addScheduleEvent = (
  prev: SearchScheduleEventsQuery,
  scheduleEvent: ScheduleEventFragment
): SearchScheduleEventsQuery => {
  return {
    searchScheduleEvents: {
      ...prev.searchScheduleEvents,
      results: [scheduleEvent, ...prev.searchScheduleEvents.results], // sort if needed
      count: prev.searchScheduleEvents.results.length + 1
    }
  }
}

const removeScheduleEvent = (
  prev: SearchScheduleEventsQuery,
  removedScheduleEventId: string
): SearchScheduleEventsQuery => {
  const newResults = prev.searchScheduleEvents.results.filter((s) => s.id !== removedScheduleEventId)
  return {
    searchScheduleEvents: {
      ...prev.searchScheduleEvents,
      results: newResults,
      count: newResults.length
    }
  }
}

const isScheduleEventInScope = (
  scheduleEvent: ScheduleEventFragment,
  scheduleFilters: ScheduleEventFilters
): boolean => {
  const hasCorrectStatus: boolean =
    (!!scheduleFilters.status &&
      scheduleEvent.status?.status &&
      scheduleFilters.status?.includes(scheduleEvent.status.status)) ||
    true

  const hasCorrectJob =
    (!!scheduleFilters.jobs && scheduleFilters.jobs.includes(scheduleEvent.job?.id ?? 'no-id')) || !scheduleFilters.jobs

  return hasCorrectJob && hasCorrectStatus && scheduleEvent.source === ScheduleEventSource.Platform.toString()
}
