import { EuiButton, EuiIcon, EuiKeyPadMenu, EuiKeyPadMenuItem, EuiSpacer, EuiText, IconType } from '@elastic/eui'
import { omit } from 'lodash-es'
import { useState } from 'react'
import { TradeType } from '../../api/generated-types'
import { Callout } from '../../common/callout'
import { decamelise } from '../../common/utils'
import AppliancesIcon from '../../static/images/trade-appliances.svg'
import CarpentryIcon from '../../static/images/trade-carpentry.svg'
import DataIcon from '../../static/images/trade-data.svg'
import DrainsIcon from '../../static/images/trade-drains.svg'
import ElectricalIcon from '../../static/images/trade-electrical.svg'
import HVACIcon from '../../static/images/trade-hvac.svg'
import PaintingIcon from '../../static/images/trade-painting.svg'
import PlumbingIcon from '../../static/images/trade-plumbing.svg'
import RoofingIcon from '../../static/images/trade-roofing.svg'
import SecurityIcon from '../../static/images/trade-security.svg'
import SolarIcon from '../../static/images/trade-solar.svg'
import { WorkflowAction } from '../workflow-action'
import { BaseActionInput, BaseActionResult, WorkflowActionProps } from '../workflow-model'

export type EmploymentJobOpportunitiesActionInput = BaseActionInput

export interface EmploymentJobOpportunitiesActionResult extends BaseActionResult {
  roleType?: EmploymentRoleType
}

export enum EmploymentRoleType {
  Technician = 'technician',
  Apprentice = 'apprentice',
  Office = 'office'
}

export const EmploymentJobOpportunitiesAction = (
  props: WorkflowActionProps<EmploymentJobOpportunitiesActionInput, EmploymentJobOpportunitiesActionResult>
) => {
  const { result, onUpdate } = props
  const { actionCompleted } = result ?? {}

  const [roleType, setRoleType] = useState<EmploymentRoleType | undefined>(undefined)
  const [trade, setTrade] = useState<TradeType | undefined>(undefined)
  const [transferredToManager, setTransferredToManager] = useState<boolean | undefined>(undefined)
  const [transferredToHR, setTransferredToHR] = useState<boolean | undefined>(undefined)

  const setActionCompleted = (actionCompleted: boolean | undefined) => onUpdate({ ...result, actionCompleted })
  const enquiryComplete =
    roleType === EmploymentRoleType.Apprentice ||
    transferredToManager === true ||
    transferredToHR === true ||
    transferredToHR === false
  const tradeOptions = Object.keys(omit(TradeType, [TradeType.None]))

  return (
    <WorkflowAction
      title="Job opportunities"
      value={actionCompleted ? 'Completed' : undefined}
      onClickChange={() => setActionCompleted(false)}
    >
      <Callout type="script" title="What kind of role are you looking for?" />
      <EuiSpacer />
      <EuiKeyPadMenu className="fs-keypadmenu">
        <EuiKeyPadMenuItem
          id="role-type-technician"
          isSelected={roleType === EmploymentRoleType.Technician}
          label="Fully qualified technician"
          onClick={() => setRoleType(EmploymentRoleType.Technician)}
        >
          <EuiIcon size="xl" type="wrench" />
        </EuiKeyPadMenuItem>
        <EuiKeyPadMenuItem
          id="role-type-apprentice"
          isSelected={roleType === EmploymentRoleType.Apprentice}
          label="Apprentice"
          onClick={() => setRoleType(EmploymentRoleType.Apprentice)}
        >
          <EuiIcon size="xl" type="training" />
        </EuiKeyPadMenuItem>
        <EuiKeyPadMenuItem
          id="role-type-office"
          isSelected={roleType === EmploymentRoleType.Office}
          label="Office job"
          onClick={() => setRoleType(EmploymentRoleType.Office)}
        >
          <EuiIcon size="xl" type="document" />
        </EuiKeyPadMenuItem>
      </EuiKeyPadMenu>

      {roleType === EmploymentRoleType.Apprentice && (
        <>
          <EuiSpacer />
          <Callout type="script">
            <EuiText>
              <div>I just need you to send through an email with your:</div>
              <EuiSpacer size="s" />
              <ul>
                <li>name</li>
                <li>contact details, and</li>
                <li>what trade you are interested</li>
              </ul>
              <div>
                to: <strong>enquiries@fallonsolutions.com.au</strong>
              </div>
            </EuiText>
          </Callout>
        </>
      )}

      {roleType === EmploymentRoleType.Office && (
        <>
          <EuiSpacer />
          <Callout
            type="script"
            title="What I will do is transfer you through to Tristan, the sales and service Manager, who will be able to assist you further okay?"
          />
        </>
      )}

      {roleType === EmploymentRoleType.Technician && (
        <>
          <EuiSpacer />
          <Callout type="script" title="What trade are you interested in?" />
          <EuiSpacer />
          <EuiKeyPadMenu className="fs-keypadmenu">
            {tradeOptions.map((t) => (
              <EuiKeyPadMenuItem
                key={t}
                id={`trade-${t}`}
                isSelected={trade === t}
                label={decamelise(t)}
                onClick={() => setTrade(t as TradeType)}
              >
                <EuiIcon size="xl" type={iconForTrade(t as TradeType)} />
              </EuiKeyPadMenuItem>
            ))}
          </EuiKeyPadMenu>

          {trade && (
            <>
              <EuiSpacer />
              <Callout type="script">
                <EuiText>
                  <div>What I will do is transfer you through to:</div>
                  <EuiSpacer size="s" />
                  <div>{contactForTrade(trade)}</div>
                </EuiText>
              </Callout>
            </>
          )}
        </>
      )}

      {(roleType === EmploymentRoleType.Office || (roleType === EmploymentRoleType.Technician && trade)) && (
        <>
          <EuiSpacer />
          <EuiKeyPadMenu className="fs-keypadmenu">
            <EuiKeyPadMenuItem
              id="transfer-success"
              isSelected={transferredToManager === true}
              label={`Call transferred to ${contactForRoleFirstName(trade, roleType)}`}
              onClick={() => setTransferredToManager(true)}
            >
              <EuiIcon size="xl" type="check" />
            </EuiKeyPadMenuItem>
            <EuiKeyPadMenuItem
              id="transfer-failure"
              isSelected={transferredToManager === false}
              label={`${contactForRoleFirstName(trade, roleType)} not available`}
              onClick={() => setTransferredToManager(false)}
            >
              <EuiIcon size="xl" type="cross" />
            </EuiKeyPadMenuItem>
          </EuiKeyPadMenu>

          {transferredToManager === false && (
            <>
              <EuiSpacer />
              <Callout type="script">
                <EuiText>
                  <div>Apologies, they are currently unavailable.</div>
                  <EuiSpacer size="s" />
                  <div>
                    I'll transfer you through to Natalie, our HR manager, who will be able to assist you further okay?
                  </div>
                </EuiText>
              </Callout>
              <EuiSpacer />
              <EuiKeyPadMenu className="fs-keypadmenu">
                <EuiKeyPadMenuItem
                  id="transfer-hr-success"
                  isSelected={transferredToHR === true}
                  label="Call transferred to HR"
                  onClick={() => setTransferredToHR(true)}
                >
                  <EuiIcon size="xl" type="check" />
                </EuiKeyPadMenuItem>
                <EuiKeyPadMenuItem
                  id="transfer-hr-failure"
                  isSelected={transferredToHR === false}
                  label="HR not available"
                  onClick={() => setTransferredToHR(false)}
                >
                  <EuiIcon size="xl" type="cross" />
                </EuiKeyPadMenuItem>
              </EuiKeyPadMenu>
            </>
          )}

          {transferredToManager === false && transferredToHR === false && (
            <>
              <EuiSpacer />
              <Callout type="script">
                <EuiText>
                  <div>Apologies, they are currently unavailable.</div>
                  <EuiSpacer size="s" />
                  <div>
                    I'll transfer you through to Tristan (or Vicki), the sales and service Manager, who will be able to
                    assist you further okay?
                  </div>
                </EuiText>
              </Callout>
            </>
          )}
        </>
      )}

      {enquiryComplete && (
        <>
          <EuiSpacer />
          <EuiButton fill color="primary" onClick={() => setActionCompleted(true)}>
            I've completed these steps
          </EuiButton>
        </>
      )}
    </WorkflowAction>
  )
}

const contactForTrade = (trade: TradeType): string => {
  switch (trade) {
    case TradeType.Electrical:
    case TradeType.Solar:
    case TradeType.Security:
    case TradeType.Data:
    case TradeType.SportsLighting:
      return 'Scott the General Manager of Electrical'
    case TradeType.HVAC:
      return 'Mike the General Manager of HVAC'
    case TradeType.Plumbing:
    case TradeType.Drains:
      return 'Mike the General Manager of Plumbing and Drains'
    case TradeType.Roofing:
    case TradeType.Painting:
      return 'Mike the General Manager of Roof Restoration and Painting'
    default:
      return `Mike the General Manager of ${trade}`
  }
}

const contactForRoleFirstName = (trade: TradeType | undefined, roleType: EmploymentRoleType): string => {
  if (roleType === EmploymentRoleType.Office) {
    return 'Tristan'
  }
  switch (trade) {
    case TradeType.Electrical:
    case TradeType.Solar:
    case TradeType.Security:
    case TradeType.Data:
    case TradeType.SportsLighting:
      return 'Scott'
    case TradeType.HVAC:
    case TradeType.Plumbing:
    case TradeType.Drains:
    case TradeType.Roofing:
    case TradeType.Painting:
    default:
      return 'Mike'
  }
}

const iconForTrade = (trade: TradeType | undefined): IconType => {
  switch (trade) {
    case TradeType.Appliances:
      return AppliancesIcon
    case TradeType.Carpentry:
      return CarpentryIcon
    case TradeType.Data:
      return DataIcon
    case TradeType.Drains:
      return DrainsIcon
    case TradeType.Electrical:
      return ElectricalIcon
    case TradeType.HVAC:
      return HVACIcon
    case TradeType.Painting:
      return PaintingIcon
    case TradeType.Plumbing:
      return PlumbingIcon
    case TradeType.Roofing:
      return RoofingIcon
    case TradeType.Security:
      return SecurityIcon
    case TradeType.Solar:
      return SolarIcon
    case TradeType.None:
      return 'wrench'
    default:
      return HVACIcon
  }
}
