import {
  HotWaterRepairIssue,
  HotWaterSystemAge,
  HotWaterThermostatsAge,
  Temperature,
  TopicHotWaterActionResult
} from './action-topic-hot-water-model'
import { SingleSelectField } from '../../question-fields/single-select-question-field'
import { NotesGenerator } from '../../helpers/notes-generator'
import { ResultUpdater } from '../../helpers/results-updater'
import { map } from 'lodash-es'

export interface HotWaterRepairElectricProps {
  result?: TopicHotWaterActionResult
  notesGenerator: NotesGenerator<TopicHotWaterActionResult>
  updateResult: ResultUpdater<TopicHotWaterActionResult>
}

export const HotWaterRepairElectric = (props: HotWaterRepairElectricProps) => {
  const { result, notesGenerator, updateResult } = props
  const { repairIssue, tankTemperature, systemAge, thermostatAge } = result ?? {}
  return (
    <>
      <SingleSelectField
        question={notesGenerator.getQuestion('repairIssue')}
        options={map(
          [
            HotWaterRepairIssue.Bulging,
            HotWaterRepairIssue.Burst,
            HotWaterRepairIssue.Leaking,
            HotWaterRepairIssue.LukeWarm,
            HotWaterRepairIssue.NotStayingHot,
            HotWaterRepairIssue.NoHotWaterAtTap,
            HotWaterRepairIssue.OnALean,
            HotWaterRepairIssue.Relocate,
            HotWaterRepairIssue.Remove,
            HotWaterRepairIssue.RustedAndAnode,
            HotWaterRepairIssue.TooLongToGetHot,
            HotWaterRepairIssue.ValvesOrPipes,
            HotWaterRepairIssue.ElementOrThermostat
          ],
          (i) => ({ id: i })
        )}
        answer={repairIssue}
        changeAnswer={(repairIssue) => updateResult({ repairIssue })}
      />

      {repairIssue && (
        <>
          {repairIssue === HotWaterRepairIssue.NoHotWaterAtTap && (
            <>
              <SingleSelectField
                question={notesGenerator.getQuestion('tankTemperature')}
                options={[
                  {
                    id: Temperature.Hot,
                    icon: 'cloudSunny'
                  },
                  {
                    id: Temperature.Cold,
                    icon: 'snowflake'
                  },
                  {
                    id: Temperature.Unknown,
                    label: `Don't know`,
                    icon: 'questionInCircle'
                  }
                ]}
                answer={tankTemperature}
                changeAnswer={(tankTemperature) => updateResult({ tankTemperature })}
              />

              {tankTemperature && tankTemperature !== Temperature.Hot && (
                <>
                  <SingleSelectField
                    question={notesGenerator.getQuestion('systemAge')}
                    options={[
                      {
                        id: HotWaterSystemAge.LessThanOrEqualTo8Years,
                        label: 'Less than 8 years old'
                      },
                      {
                        id: HotWaterSystemAge.Over8Years,
                        label: 'Over 8 years old'
                      },
                      {
                        id: HotWaterSystemAge.Unknown,
                        label: `Don't know`
                      }
                    ]}
                    answer={systemAge}
                    changeAnswer={(systemAge) => updateResult({ systemAge })}
                  />
                </>
              )}
            </>
          )}
          {repairIssue === HotWaterRepairIssue.ElementOrThermostat && (
            <>
              <SingleSelectField
                question={notesGenerator.getQuestion('thermostatAge')}
                options={[
                  {
                    id: HotWaterThermostatsAge.LessThan8Years,
                    label: 'Less than 8 years old'
                  },
                  {
                    id: HotWaterThermostatsAge.MoreThan8Years,
                    label: 'More than 8 years old'
                  }
                ]}
                answer={thermostatAge}
                changeAnswer={(thermostatAge) => updateResult({ thermostatAge })}
              />
            </>
          )}
        </>
      )}
    </>
  )
}
