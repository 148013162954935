import { EuiFlexGroup, EuiFlexItem, EuiHorizontalRule } from '@elastic/eui'
import React from 'react'
import '../static/css/horizontal-rule.css'

export interface HorizontalRuleProps {
  label?: string
}

export const HorizontalRule = ({ label }: HorizontalRuleProps) => {
  return (
    <div className="horizontal-rule">
      {label && (
        <div className="horizontal-rule__label-wrapper">
          <EuiFlexGroup alignItems="center" gutterSize="none" style={{ textAlign: 'center' }}>
            <EuiFlexItem>
              <div>
                <span className="horizontal-rule__label">{label}</span>
              </div>
            </EuiFlexItem>
          </EuiFlexGroup>
        </div>
      )}
      <EuiHorizontalRule />
    </div>
  )
}
