import { QuestionDefinitions, getNotesGenerator } from '../../helpers/notes-generator'
import {
  ApplianceRepairTopicActionResult,
  InstallEnum,
  RepairOrReplaceEnum,
  applianceRepairQuestionDefinitions
} from '../appliance-shared/appliance-model'
import { YesNoValue } from '../common/common-enums'

export const BOOK_TWO_CARPENTRY_SAME_DAY_TIME_MESSAGE = 'Book 2 Carpentry technicians same day and time'

export interface TopicDryerActionResult extends ApplianceRepairTopicActionResult {
  serviceType?: RepairOrReplaceEnum | InstallEnum
  wallMounted?: string
  customerHasBrackets?: YesNoValue
  dryerRequiresDucting?: YesNoValue
  customerHasDucting?: YesNoValue
  happenedBefore?: YesNoValue
}

const questionDefinitions: QuestionDefinitions<TopicDryerActionResult> = {
  ...applianceRepairQuestionDefinitions,
  serviceType: { question: 'Is this for an Install, Replace or Repair?', statement: 'Service:' },
  issue: { question: 'What is the fault with the dryer?' },
  dryerRequiresDucting: { question: 'Does the dryer need ducting?' },
  wallMounted: { question: 'Does the dryer need wall mounting?' },
  customerHasBrackets: { question: 'Do you have brackets to assist with the wall mounting?' },
  customerHasDucting: { question: 'Does the customer have ducting?' }
}

export const dryerNotesGenerator = getNotesGenerator(questionDefinitions)
