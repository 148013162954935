import { YesNoValue } from './common-enums'

export const yesNoOptions = [
  {
    id: YesNoValue.Yes,
    label: 'Yes',
    icon: 'check'
  },
  {
    id: YesNoValue.No,
    label: 'No',
    icon: 'cross'
  }
]
