import { InteractionMessageStatusType } from '../api/generated-types'
import '../static/css/interaction-message-status.css'

export interface InteractionMessageStatusBadgeProps {
  status: InteractionMessageStatusType
  title?: string
  size?: 'xs' | 'm'
}

export const InteractionMessageStatusBadge = ({ status, title, ...props }: InteractionMessageStatusBadgeProps) => {
  const size = props.size ?? 'm'
  return (
    <span
      className={`interaction-message-status-badge interaction-message-status-badge--${status.toLowerCase()} interaction-message-status-badge--${size}`}
      title={title}
    >
      {size === 'm' ? status : ''}
    </span>
  )
}
