import { EuiBetaBadge, EuiFlexGroup, EuiFlexItem, EuiSpacer, EuiText, IconSize, IconType } from '@elastic/eui'
import { JobCategory, getAllJobCategories, getLegacyJobTypes } from '@fallonsolutions/job'
import { includes, reject } from 'lodash-es'
import { CustomerType, JobType, TradeType } from '../api/generated-types'
import { decamelise } from '../common/utils'
import { Pill } from '../pill/pill'
import { PillGroup } from '../pill/pill-group'
import AntennaIcon from '../static/images/category-antenna.svg'
import HotWaterIcon from '../static/images/category-hotwater.svg'
import AppliancesIcon from '../static/images/trade-appliances.svg'
import CarpentryIcon from '../static/images/trade-carpentry.svg'
import DataIcon from '../static/images/trade-data.svg'
import DrainsIcon from '../static/images/trade-drains.svg'
import ElectricalIcon from '../static/images/trade-electrical.svg'
import HVACIcon from '../static/images/trade-hvac.svg'
import PaintingIcon from '../static/images/trade-painting.svg'
import PlumbingIcon from '../static/images/trade-plumbing.svg'
import RoofingIcon from '../static/images/trade-roofing.svg'
import SecurityIcon from '../static/images/trade-security.svg'
import SolarIcon from '../static/images/trade-solar.svg'
import { TradeSelector } from '../trade/trade-selector'

const iconForCategory = (category: string | undefined): IconType | undefined => {
  switch (category) {
    case 'General':
      return 'wrench'
    case 'Antenna':
      return AntennaIcon
    case 'Backflow':
      return 'menuLeft'
    case 'InspectionComplimentary':
      return 'search'
    case 'InspectionComplimentaryUpgrade':
      return 'search'
    case 'CCTV':
      return 'videoPlayer'
    case 'Cleaning':
      return 'broom'
    case 'Competitions':
      return 'cheer'
    case 'DrainCamera':
      return 'crosshairs'
    case 'Ducted':
      return 'indexMapping'
    case 'ElectronicSecurity':
      return 'securitySignalDetected'
    case 'HotWater':
      return HotWaterIcon
    case 'HotWaterConnections':
      return HotWaterIcon
    case 'Install':
      return 'plusInCircle'
    // NewUnits removed in favour of SplitSystem
    case 'NewUnits':
    case 'SplitSystem':
      return 'tag'
    case 'Reline':
      return 'partial'
    case 'Repairs':
      return 'visVisualBuilder'
    case 'Shower':
      return HotWaterIcon
    case 'StarDrain':
      return 'starFilled'
    case 'StarPlumbing':
      return 'starFilled'
    case 'Stormwater':
      return 'cloudStormy'
    case 'NoPower':
    case 'Switchboard':
      return 'bolt'
    case 'HPPRenewal':
      return 'plusInCircle'
    case 'ComplimentaryHPP':
      return 'starFilled'
    default:
      return 'empty'
  }
}

interface JobCategoryWithIcon extends JobCategory {
  icon?: IconType | undefined
}

const Categories: JobCategoryWithIcon[] = getAllJobCategories().map((category) => ({
  ...category,
  icon: iconForCategory(category.id)
}))

const labelForJobType = (type: JobType | undefined): string => {
  switch (type) {
    case JobType.Callback:
      return 'Revisit'
    case JobType.None:
      return 'Membership'
    default:
      return type as string
  }
}

export const iconForJobType = (type: JobType | undefined): IconType => {
  switch (type) {
    case JobType.Callback:
      return 'editorUndo'
    case JobType.Inspection:
      return 'search'
    case JobType.Quote:
      return 'documentEdit'
    case JobType.Maintenance:
      return 'calendar'
    case JobType.Project:
      return 'gear'
    case JobType.Parts:
      return 'package'
    case JobType.Warranty:
      return 'starEmpty'
    case JobType.None:
      return 'starFilled'
    case JobType.Service:
    default:
      return 'wrench'
  }
}

const iconForCustomerType = (customerType: CustomerType | undefined): IconType => {
  switch (customerType) {
    case CustomerType.Commercial:
      return 'visBarVertical'
    case CustomerType.Domestic:
    default:
      return 'home'
  }
}

export const labelForTrade = (trade: TradeType | undefined): string => {
  switch (trade) {
    case TradeType.Painting:
      return 'House Painting'
    case TradeType.None:
      return 'All'
    default:
      return trade ? (trade as string) : 'Unknown'
  }
}

const iconForTrade = (trade: TradeType | undefined): IconType => {
  switch (trade) {
    case TradeType.Appliances:
      return AppliancesIcon
    case TradeType.Carpentry:
      return CarpentryIcon
    case TradeType.Data:
      return DataIcon
    case TradeType.Drains:
      return DrainsIcon
    case TradeType.Electrical:
      return ElectricalIcon
    case TradeType.HVAC:
      return HVACIcon
    case TradeType.Painting:
      return PaintingIcon
    case TradeType.Plumbing:
      return PlumbingIcon
    case TradeType.Roofing:
      return RoofingIcon
    case TradeType.Security:
      return SecurityIcon
    case TradeType.Solar:
      return SolarIcon
    case TradeType.None:
      return 'wrench'
    default:
      return HVACIcon
  }
}

const customerTypes = [CustomerType.Domestic, CustomerType.Commercial].map((id) => ({
  id: id as string,
  label: decamelise(id),
  icon: iconForCustomerType(id as CustomerType)
}))

const excludedJobTypes = [JobType.None, JobType.Repair]
const jobTypes = reject(Object.keys(JobType), (v) => includes(excludedJobTypes, v))
  .map((id) => ({
    id: id as string,
    label: decamelise(labelForJobType(id as JobType)),
    icon: iconForJobType(id as JobType),
    margin: id === JobType.Service ? '30px' : undefined
  }))
  .sort((a, _b) => (a.id === JobType.Service ? -1 : 1))

export interface JobCategorySelectorProps {
  customerType: CustomerType | undefined | null
  clearCustomerType: () => void
  setCustomerType: (customerType: CustomerType | undefined) => void

  trade: TradeType | undefined
  clearTrade: () => void
  setTrade: (trade: TradeType | undefined) => void

  type: JobType | undefined
  clearType: () => void
  setType: (type: JobType | undefined) => void

  category: string | undefined
  clearCategory: () => void
  setCategory: (category: string | undefined) => void

  showAxiaCode?: boolean
}

export const JobCategorySelector = (props: JobCategorySelectorProps) => {
  const {
    customerType,
    trade,
    type,
    category,
    clearCustomerType,
    clearTrade,
    clearType,
    clearCategory,
    setCustomerType,
    setTrade,
    setType,
    setCategory
  } = props

  const showAxiaCode = props.showAxiaCode ?? true

  const jobCategory = Categories.find((c) => c.id === category)

  const selections = [
    {
      title: 'Customer type',
      id: customerType,
      label: customerType,
      icon: iconForCustomerType(customerType || undefined),
      clear: clearCustomerType
    },
    { title: 'Type', id: type, label: labelForJobType(type), icon: iconForJobType(type), clear: clearType },
    {
      title: 'Trade',
      id: trade,
      label: labelForTrade(trade),
      icon: iconForTrade(trade),
      iconSize: 'xl',
      clear: clearTrade
    },
    {
      title: 'Category',
      id: jobCategory?.id,
      label: jobCategory?.label,
      clear: clearCategory,
      icon: iconForCategory(jobCategory?.id)
    }
  ]

  const categories = Categories.filter((category) => {
    let match = true
    if (trade && !includes(category.trades, trade)) {
      match = false
    }
    if (type && category?.jobTypes && !includes(category.jobTypes, type)) {
      match = false
    }
    return match
  })

  const legacyJobTypes = getLegacyJobTypes({
    customerType: customerType ?? CustomerType.None,
    trade: trade ?? TradeType.None,
    jobType: type,
    category: category
  })

  return (
    <>
      <EuiFlexGroup gutterSize="m">
        {selections.map((selection, index) => (
          <EuiFlexItem grow={false} key={`selection-${index}`}>
            <EuiText size="s" style={{ fontWeight: 600 }}>
              {selection.title}
            </EuiText>
            <EuiSpacer size="s" />
            {selection.id ? (
              <Pill
                id={selection.id ?? 'none'}
                key={index}
                label={selection.label ?? 'Select'}
                isSelected={true}
                icon={selection.icon}
                iconSize={(selection.iconSize as IconSize) ?? 'l'}
                onSelect={() => selection.clear()}
              >
                <div className="pill__change">
                  <EuiBetaBadge
                    label="Change"
                    style={{ background: 'white', cursor: 'pointer', boxShadow: 'rgb(220 220 220) 0px 0px 18px 1px' }}
                  />
                </div>
              </Pill>
            ) : (
              <div className="pill pill--placeholder">
                <EuiFlexGroup
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                  gutterSize="s"
                  className="pill__content"
                ></EuiFlexGroup>
              </div>
            )}
          </EuiFlexItem>
        ))}
      </EuiFlexGroup>

      <EuiSpacer size="l" />

      {!customerType && (
        <>
          <PillGroup
            key="noCustomerType"
            title="Customer type"
            direction="row"
            options={customerTypes}
            value={customerType || undefined}
            iconSize="l"
            onSelect={(id) => setCustomerType(id as CustomerType)}
            onClear={clearCustomerType}
          />
          <EuiSpacer />
        </>
      )}

      {customerType && !type && (
        <>
          <PillGroup
            key="noJobType"
            title="Job type"
            options={jobTypes}
            direction="row"
            value={type || undefined}
            iconSize="l"
            onSelect={(id) => setType(id as JobType)}
            onClear={clearType}
          />
          <EuiSpacer />
        </>
      )}

      {type && !trade && (
        <>
          <TradeSelector
            title="Trade"
            direction="row"
            trade={trade}
            onSelect={(trade) => setTrade(trade)}
            onClear={clearTrade}
          />
          <EuiSpacer />
        </>
      )}

      {trade && !category && (
        <>
          <PillGroup
            key="noCategory"
            title="Category"
            direction="row"
            options={categories}
            value={undefined}
            onSelect={(id) => setCategory(id)}
            onClear={clearCategory}
            iconSize="l"
          />
        </>
      )}

      {showAxiaCode && trade && type && category && legacyJobTypes.length > 0 && (
        <>
          <EuiText size="s">
            <div className="small-label">Axia job type:</div>
            {legacyJobTypes.map((legacyJobType, index) => (
              <span key={index} style={index === 0 ? { fontWeight: 600 } : { opacity: 0.3 }}>
                {legacyJobType.code}: {legacyJobType.description}
              </span>
            ))}
          </EuiText>
        </>
      )}
    </>
  )
}
