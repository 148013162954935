import { EuiFlexGroup, EuiFlexGroupProps, EuiFlexItem, EuiLoadingSpinner, EuiSpacer, EuiText } from '@elastic/eui'

interface LoadingSpinnerProps extends EuiFlexGroupProps {
  message?: string
}

export const LoadingSpinner = ({ message, ...rest }: LoadingSpinnerProps) => (
  <EuiFlexGroup alignItems="center" justifyContent="center" direction="column" gutterSize="m" {...rest}>
    <EuiFlexItem grow={false}>
      <EuiSpacer size="l" />
      <EuiLoadingSpinner size="l" />
    </EuiFlexItem>
    <EuiFlexItem grow={false}>
      <EuiText size="xs">{message}</EuiText>
    </EuiFlexItem>
  </EuiFlexGroup>
)
