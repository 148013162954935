import { useQuery } from '@apollo/client'
import { EuiButtonEmpty, EuiComboBox, EuiFlexGroup, EuiFlexItem, EuiHighlight } from '@elastic/eui'
import { useCallback, useEffect, useState } from 'react'
import { SearchPropertiesDocument, SearchPropertiesInput } from '../api/generated-types'
import { useDebounce } from '../common/use-debounce'
import '../static/css/combo-box.css'

interface PropertyComboBoxProps {
  properties: any[]
  onChangeProperties: (properties: any[]) => void
}

export const PropertyComboBox = (props: PropertyComboBoxProps) => {
  const { properties, onChangeProperties } = props
  const [term, setTerm] = useState('')
  const [enabled, setEnabled] = useState(properties.length > 0)
  const [comboInput, setComboInput] = useState<HTMLInputElement | null>(null)

  const debounceTerm = useDebounce(term, 150)

  const input: SearchPropertiesInput = {
    filters: {
      ...(debounceTerm.length > 0 && { query: debounceTerm })
    },
    from: 0,
    size: 10
  }

  const variables = { input }
  const { data, loading, error } = useQuery(SearchPropertiesDocument, {
    variables
  })

  const results = data?.searchProperties?.results ?? []

  let options: any[]
  if (results.length > 0) {
    options =
      results.map((property) => {
        const address = property.searchAddress ?? 'No address'
        return {
          id: property.id,
          label: address
        }
      }) ?? []
  } else {
    options = []
  }

  if (error) {
    console.error('error', error)
  }

  const onSearchChange = useCallback((searchValue: string, _hasMatchingOptions?: boolean) => {
    setTerm(searchValue)
  }, [])

  useEffect(() => {
    // Simulate initial load
    onSearchChange('')
  }, [onSearchChange])

  const renderPropertyOption = (option: any, searchValue: any, contentClassName: any) => {
    console.log('is this working on dev???')
    return (
      // if used for cypress testing
      <div data-test-id="property-option">
        <EuiFlexGroup gutterSize="s" alignItems="center">
          <EuiFlexItem grow={true}>
            <span className={contentClassName}>
              <EuiHighlight search={searchValue}>{option.label}</EuiHighlight>
            </span>
          </EuiFlexItem>
          <EuiFlexItem grow={false} className="small-label" style={{ lineHeight: 'inherit' }}>
            {option.reference}
          </EuiFlexItem>
        </EuiFlexGroup>
      </div>
    )
  }

  const onAddFilter = () => {
    setEnabled(true)
    setTimeout(() => comboInput?.focus(), 50)
  }

  const onBlur = () => {
    if (properties.length <= 0) {
      setEnabled(false)
    }
  }
  const label = 'property'
  return (
    <form autoComplete="off">
      <EuiComboBox
        async
        inputRef={setComboInput}
        placeholder="Properties"
        options={options}
        selectedOptions={properties}
        onChange={onChangeProperties}
        isClearable={true}
        renderOption={renderPropertyOption}
        rowHeight={40}
        isLoading={loading}
        onSearchChange={onSearchChange}
        style={{ minWidth: '300px' }}
        onBlur={onBlur}
        hidden={!enabled}
        aria-label={label}
        data-test-subj={label}
      />
      {!enabled && (
        <EuiButtonEmpty iconType="filter" flush="both" onClick={onAddFilter}>
          Property
        </EuiButtonEmpty>
      )}
    </form>
  )
}
