import { QuestionDefinitions, getNotesGenerator } from '../../helpers/notes-generator'
import { ApplianceRepairTopicActionResult } from '../appliance-shared/appliance-model'
import { ServiceType } from '../action-topic-model'
import { YesNoValue } from '../common/common-enums'

export interface TopicFridgeFreezerActionResult extends ApplianceRepairTopicActionResult {
  serviceType?: ServiceType
  existingTap?: YesNoValue
  existingOrNew?: ExistingOrNewEnum
}

const questionDefinitions: QuestionDefinitions<TopicFridgeFreezerActionResult> = {
  serviceType: {
    question: 'Is this for installation or repair?',
    statement: 'Install or repair:'
  },
  existingTap: {
    question: 'Is there a tap behind where the fridge / freezer is going to go?',
    statement: 'Existing tap behind the fridge/freezer'
  },
  existingOrNew: {
    question: 'Is this for an existing or new fridge / freezer?'
  },
  issue: {
    question: 'What is the issue with your fridge / freezer?',
    statement: 'Issue:'
  },
  outletWorksWithOtherDevice: {
    question: 'Does the power point work with other devices?',
    statement: 'Outlet works with other device:'
  }
}

export enum ExistingOrNewEnum {
  Existing = 'Existing',
  New = 'New'
}
export const existingOrNewOptions = [
  { id: ExistingOrNewEnum.Existing, label: 'Existing' },
  { id: ExistingOrNewEnum.New, label: 'New' }
]
export const fridgeNotesGenerator = getNotesGenerator(questionDefinitions)
