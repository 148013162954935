import { useState } from 'react'
import { CustomerFragment, UpdateCustomerCompanyDocument } from '../api/generated-types'
import { EuiButton, EuiButtonEmpty, EuiFieldText, EuiForm, EuiFormLabel, EuiModal , EuiModalBody, EuiModalFooter, EuiModalHeader, EuiModalHeaderTitle, EuiOverlayMask } from '@elastic/eui'
import { ApolloError, useMutation } from '@apollo/client'
import { UrlUtils } from '@fallonsolutions/domain'

export interface CustomerCompanyEditProps {
  customer: CustomerFragment
  closeModal: () => void
}

export const CompanyEdit = (props: CustomerCompanyEditProps) => {
  const { customer, closeModal } = props
  const [name, setName] = useState<string>(customer?.company?.name ?? '')
  const [abn, setAbn] = useState<string>(customer?.company?.abn ?? '')
  const [web, setWeb] = useState<string>(customer?.company?.web ?? '')
  const [showValidationErrors, setShowValidationErrors] = useState(false)

  const [updateCompanyRequestError, setUpdateCompanyRequestError] = useState<ApolloError | undefined>(undefined)

  const [updateCompany, { loading }] = useMutation(UpdateCustomerCompanyDocument)

  const websiteParsed = UrlUtils.tryParseUrl(web)

  const nameValid = !!name?.trim()
  const webValid = web.trim().length === 0 || !!websiteParsed

  const formErrors = [!nameValid, !webValid].filter((invalid) => invalid)

  const handleUpdate = async () => {
    setShowValidationErrors(true)
    if (formErrors.length === 0) {
      const input = {
        id: customer.id,
        name: name?.trim() || null,
        abn: abn?.trim() || null,
        web: websiteParsed || null
      }
      try {
        await updateCompany({ variables: { input } })
        closeModal()
      } catch (error: any) {
        setUpdateCompanyRequestError(error)
        console.log(JSON.stringify(error, null, 2))
      }
    }
  }

  const hideValidationErrors = () => {
    setUpdateCompanyRequestError(undefined)
    setShowValidationErrors(false)
  }

  const form = (
    <EuiForm
      isInvalid={showValidationErrors && (formErrors.length > 0 || !!updateCompanyRequestError)}
      onInput={hideValidationErrors}
      error={updateCompanyRequestError?.message}
    >
      <EuiFormLabel isInvalid={showValidationErrors && !nameValid}>Name</EuiFormLabel>
      <EuiFieldText
        isInvalid={showValidationErrors && !nameValid}
        value={name}
        onChange={(e) => setName(e.target.value)}
      ></EuiFieldText>
      <EuiFormLabel>ABN</EuiFormLabel>
      <EuiFieldText value={abn} onChange={(e) => setAbn(e.target.value)}></EuiFieldText>
      <EuiFormLabel isInvalid={showValidationErrors && !webValid}>Website</EuiFormLabel>
      <EuiFieldText
        isInvalid={showValidationErrors && !webValid}
        value={web}
        onChange={(e) => setWeb(e.target.value)}
      ></EuiFieldText>
    </EuiForm>
  )

  return (
    <EuiOverlayMask>
      <EuiModal onClose={closeModal}>
        <EuiModalHeader>
          <EuiModalHeaderTitle>Edit Company details</EuiModalHeaderTitle>
        </EuiModalHeader>
        <EuiModalBody>
          <div>{form}</div>
        </EuiModalBody>
        <EuiModalFooter>
          <EuiButtonEmpty onClick={closeModal} isDisabled={loading}>
            Cancel
          </EuiButtonEmpty>
          <EuiButton onClick={handleUpdate} isLoading={loading} fill>
            Done
          </EuiButton>
        </EuiModalFooter>
      </EuiModal>
    </EuiOverlayMask>
  )
}
