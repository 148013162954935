import {
  EuiButton,
  EuiButtonGroup,
  EuiContextMenuPanel,
  EuiContextMenuPanelDescriptor,
  EuiContextMenuPanelItemDescriptor,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormLabel,
  EuiHorizontalRule,
  EuiIcon,
  EuiPanel,
  EuiSpacer,
  EuiTextAlign,
  EuiTextArea
} from '@elastic/eui'
import { CallbackChoice, CustomerMessageItemType, getMessage } from '@fallonsolutions/compose-message'
import { compact, first } from 'lodash-es'
import { useState } from 'react'
import {
  EmailTemplateCategory,
  ScheduleEventDetailFragment,
  ScheduleEventFragment,
  SendScheduleEventEmailMutationVariables,
  SendScheduleEventMessageMutationVariables
} from '../../api/generated-types'
import { useAuthenticated } from '../../auth/authenticated-context'
import { formatPhone, isMobile } from '../../common/phone'
import { decamelise } from '../../common/utils'

export interface ScheduleEventContextMenuMessageCustomerPanelProps {
  event: ScheduleEventFragment
  eventDetail?: ScheduleEventDetailFragment
  sendMessage: (params: { variables: SendScheduleEventMessageMutationVariables }) => void
  sendEmail: (params: { variables: SendScheduleEventEmailMutationVariables }) => void
  onActionClick?: (() => void) | undefined
}

enum CallbackEnum {
  dsr = 'dsr',
  direct = 'direct'
}

const callbackOptions = [
  { id: CallbackEnum.dsr, label: 'DSR' },
  { id: CallbackEnum.direct, label: 'Direct' }
]

export enum Channel {
  All = 'All',
  SMS = 'SMS',
  Email = 'Email'
}
/*eslint-disable */
export const ScheduleEventContextMenuMessageCustomerPanel = ({
  event,
  eventDetail,
  sendMessage,
  sendEmail,
  onActionClick
}: ScheduleEventContextMenuMessageCustomerPanelProps): EuiContextMenuPanelDescriptor[] => {
  const [selectedItem, setSelectedItem] = useState<CustomerMessageItemType | undefined>(undefined)
  const [messageOverride, setMessageOverride] = useState<string | undefined>(undefined)
  const [messageEditable, setMessageEditable] = useState<boolean>(false)
  const [callbackNumber, setCallbackNumber] = useState<CallbackEnum>(CallbackEnum.dsr)
  const [channel, setChannel] = useState<Channel>(Channel.SMS)
  const userFragment = useAuthenticated().userFragment
  const canSendIntro = userFragment.permissions?.developerFeatures === true

  const contactMobile =
    first(
      compact([event.customerData?.mainContact?.phone, event.customerData?.mainContact?.alternatePhone]).filter(
        isMobile
      )
    ) ?? null
  const contactEmail = event.customerData?.mainContact?.email ?? null
  const getSendCustomerSMSInput = (): SendScheduleEventMessageMutationVariables => {
    if (!eventDetail || !selectedItem) {
      throw new Error('No eventDetail')
    }

    //const userPhoneNumberAllocations = userFragment?.phoneAllocations?.map((phoneAllocation) => phoneAllocation.phone)
    const userPhone = formatPhone(userFragment?.contactDetail?.phone)
    const callbackPhoneNumber = callbackNumber === CallbackEnum.direct ? userPhone : undefined
    if (callbackNumber === CallbackEnum.direct) console.log('callbackPhoneNumber', callbackPhoneNumber)

    //preview message needs to render \n, with nbsp
    const message = messageOverride
      ? messageOverride
      : getMessage({
          item: selectedItem,
          eventDetail,
          ...(callbackPhoneNumber && { callbackPhoneNumber }),
          referToEmail: false,
          customerAppDomain: 'fallonsolutions.fsplatformdev.net' // FIXME: use tenant.customerApp.baseUrl
        })
    return {
      input: {
        customer: {
          scheduleEventId: event.id,
          message,
          to: [contactMobile]
        }
      }
    }
  }

  //most likely status options based on current state
  const primaryItems = () => {
    return [
      CustomerMessageItemType.PleaseCallToBook,
      CustomerMessageItemType.PleaseCallToRescheduleAppointment,
      CustomerMessageItemType.PleaseCallToBookPartsFitting,
      CustomerMessageItemType.TechnicianAvailableEarlyPleaseCall,
      CustomerMessageItemType.TechnicianDelayedPleaseCall,
      CustomerMessageItemType.TechnicianEnroute,
      ...(canSendIntro ? [CustomerMessageItemType.TechnicianIntro] : [])
    ]
  }

  const secondaryItems = () => {
    return [CustomerMessageItemType.BookingConfirmation, CustomerMessageItemType.AppointmentReminder]
  }

  const availableItems = primaryItems()

  const shortItemName = (item: CustomerMessageItemType) => {
    switch (item) {
      case CustomerMessageItemType.PleaseCallToBook:
        return 'Call to Book'
      case CustomerMessageItemType.PleaseCallToRescheduleAppointment:
        return 'Call to Reschedule'
      case CustomerMessageItemType.PleaseCallToBookPartsFitting:
        return 'Call to Book Parts Fitting'
      case CustomerMessageItemType.TechnicianAvailableEarlyPleaseCall:
        return 'Tech Available Early'
      case CustomerMessageItemType.TechnicianDelayedPleaseCall:
        return 'Tech Delayed'
      case CustomerMessageItemType.TechnicianEnroute:
        return 'Tech Enroute'
      case CustomerMessageItemType.TechnicianIntro:
        return 'Tech Enroute (with intro)'
      default:
        return decamelise(item)
    }
  }

  const disabledForItem = (item: CustomerMessageItemType) => {
    switch (item) {
      case CustomerMessageItemType.BookingConfirmation:
        return !(contactMobile || contactEmail)
      default:
        return !contactMobile
    }
  }
  const getSendMessageMenuItem = (item: CustomerMessageItemType): EuiContextMenuPanelItemDescriptor => {
    return {
      name: shortItemName(item),
      icon: getIconForItem(item),
      disabled: disabledForItem(item),
      panel: 'message-customer-confirmation',
      onClick: () => {
        setSelectedItem(item)
        setCallbackNumber(CallbackEnum.dsr)
      }
    }
  }

  interface SendCustomerMessageConfirmationContentProps {
    selectedItem?: CustomerMessageItemType
    emailEnabled?: boolean //per schedule event and config
    smsEnabled?: boolean //per customer settings
    callbackNumber?: CallbackEnum
  }

  const sendCustomerMessageConfirmationContent = ({
    callbackNumber,
    selectedItem,
    emailEnabled,
    smsEnabled
  }: SendCustomerMessageConfirmationContentProps) => {
    if (!selectedItem) return <>Please select an item</>
    const showCallbackOptions = CallbackChoice.includes(selectedItem)
    const variables = getSendCustomerSMSInput()
    const { message } = variables.input.customer

    const channelOptions =
      smsEnabled && emailEnabled
        ? Object.keys(Channel).map((key) => {
            return { id: Channel[key as keyof typeof Channel], label: Channel[key as keyof typeof Channel] }
          })
        : smsEnabled
          ? [{ id: Channel.SMS, label: 'SMS' }]
          : [{ id: Channel.Email, label: 'Email' }]

    console.log('message updated', messageOverride ?? message)
    return (
      <EuiContextMenuPanel>
        <EuiPanel>
          {showCallbackOptions && callbackNumber && (
            <>
              <EuiFormLabel>Callback number</EuiFormLabel>
              <EuiSpacer size="s" />
              <EuiFlexGroup>
                <EuiButtonGroup
                  name="callbackNumber"
                  idSelected={callbackNumber}
                  legend="Callback number"
                  onChange={(id: string) => {
                    setCallbackNumber(id as CallbackEnum)
                  }}
                  options={callbackOptions}
                ></EuiButtonGroup>
              </EuiFlexGroup>
              <EuiSpacer size="m" />
            </>
          )}
          {/* {hasDeveloperFeatures && (
            <>
              <EuiButtonIcon
                iconType="pencil"
                onClick={() => {
                  setMessageEditable(!messageEditable)
                  setMessageOverride(message)
                }}
              />

              <NotesView
                label="Preview"
                notes={messageOverride ?? message}
                isEditable={messageEditable}
                loading={false}
                onSave={(notes) => {
                  setMessageOverride(notes)
                }}
                onCancel={() => {
                  setMessageEditable(false)
                  setMessageOverride(undefined)
                }}
              />
            </>
          )} */}
          {emailEnabled && (
            <>
              <EuiButtonGroup
                legend={'Channel selection'}
                idSelected={channel}
                onChange={(id: string) => setChannel(id as Channel)}
                options={channelOptions}
              />
              <EuiSpacer size="m" />
            </>
          )}
          <EuiSpacer size="s" />
          <EuiFlexGroup gutterSize="xs">
            <EuiFlexItem grow={false}>
              <EuiIcon type={getIconForChannel(Channel.SMS)} size="m" />
            </EuiFlexItem>
            {(channel === Channel.SMS || channel === Channel.All) && smsEnabled ? (
              <EuiFlexItem grow={true}>
                <EuiTextAlign
                  style={{
                    overflowWrap: 'break-word',
                    wordWrap: 'break-word',
                    wordBreak: 'break-word',
                    hyphens: 'auto'
                  }}
                >
                  {messageOverride ?? message}
                </EuiTextAlign>
                {messageEditable ? (
                  <EuiTextArea
                    id="messageText"
                    readOnly={!messageEditable}
                    // contentEditable={messageEditable}
                    value={messageOverride}
                    onChange={(e) => setMessageOverride(e.target.value)}
                  />
                ) : (
                  <></>
                )}
              </EuiFlexItem>
            ) : (
              <EuiTextAlign>-</EuiTextAlign>
            )}
          </EuiFlexGroup>
          <EuiSpacer size="m" />
          {emailEnabled && (
            <EuiFlexGroup>
              <EuiFlexItem grow={false}>
                <EuiIcon type={getIconForChannel(Channel.Email)} size="m" />
              </EuiFlexItem>
              {emailEnabled ? (
                <EuiFlexItem grow={true}>
                  {channel === Channel.Email || channel === Channel.All ? (
                    <EuiTextAlign>{eventDetail?.customerData?.mainContact?.email}</EuiTextAlign>
                  ) : (
                    <div>
                      <EuiTextAlign style={{ textDecoration: 'line-through' }}>
                        {eventDetail?.customerData?.mainContact?.email}
                      </EuiTextAlign>
                    </div>
                  )}
                </EuiFlexItem>
              ) : (
                <EuiTextAlign>-</EuiTextAlign>
              )}
            </EuiFlexGroup>
          )}
          <EuiFlexItem grow={true}>
            <EuiSpacer size="m" />
            <EuiHorizontalRule />
          </EuiFlexItem>

          <EuiFlexGroup>
            <EuiFlexItem grow={true} />
            <EuiFlexItem grow={false}></EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiButton
                disabled={(channel === Channel.Email && !emailEnabled) || (channel === Channel.SMS && !smsEnabled)}
                onClick={() => {
                  if (selectedItem) {
                    if (channel === Channel.SMS || channel === Channel.All) {
                      sendMessage({
                        variables
                      })
                    }
                    if (channel === Channel.Email || channel === Channel.All) {
                      sendEmail({
                        variables: {
                          input: {
                            scheduleEventId: event.id,
                            emailCategory: getEmailCategoryForItem(selectedItem)
                          }
                        }
                      })
                    }
                    onActionClick && onActionClick()
                  }
                }}
              >
                Send
              </EuiButton>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiPanel>
      </EuiContextMenuPanel>
    )
  }

  const getEmailCategoryForItem = (item: CustomerMessageItemType) => {
    switch (item) {
      case CustomerMessageItemType.BookingConfirmation:
        return EmailTemplateCategory.BookingConfirmation
      default:
        return undefined
    }
  }

  const getIconForItem = (item: CustomerMessageItemType) => {
    switch (item) {
      case CustomerMessageItemType.TechnicianDelayedPleaseCall:
        return 'timeslider'
      case CustomerMessageItemType.TechnicianAvailableEarlyPleaseCall:
        return 'pencil'
      case CustomerMessageItemType.PleaseCallToBook:
      case CustomerMessageItemType.PleaseCallToRescheduleAppointment:
        return 'calendar'
      case CustomerMessageItemType.PleaseCallToBookPartsFitting:
        return 'package'
      case CustomerMessageItemType.TechnicianEnroute:
        return 'launch'
      case CustomerMessageItemType.BookingConfirmation:
        return 'calendar'
      case CustomerMessageItemType.AppointmentReminder:
        return 'bell'
      default:
        return 'push'
    }
  }

  const emailEnabled = selectedItem === CustomerMessageItemType.BookingConfirmation
  const smsEnabled = (contactMobile?.length ?? 0) > 0
  return [
    {
      id: 'message-customer',
      size: 's',
      title: 'Message customer',
      items: [
        ...compact(availableItems).map(getSendMessageMenuItem),
        // {
        //   name: 'Other',
        //   icon: 'visText',
        //   panel: 'message-customer-other'
        // },
        {
          isSeparator: true,
          key: 'sep'
        },
        ...compact(secondaryItems()).map(getSendMessageMenuItem),
        {
          isSeparator: true,
          key: 'sep2'
        },
        {
          name: `${event.customerData?.mainContact?.firstName} ${
            contactMobile ? formatPhone(contactMobile) : 'No Phone Number'
          }`,
          disabled: true
        }
      ]
    },
    // { id: 'message-customer-other', size: 's', title: 'Other', content: <div>Other</div> },
    {
      id: 'message-customer-confirmation',
      size: 's',
      title: decamelise(selectedItem ?? 'Confirm'),
      width: 400,
      content: sendCustomerMessageConfirmationContent({ callbackNumber, selectedItem, emailEnabled, smsEnabled })
    }
  ]
}

const getIconForChannel = (channel: Channel) => {
  // return 'email'
  switch (channel) {
    case Channel.Email:
      return 'email'
    case Channel.SMS:
      return 'editorComment'
    default:
      return 'questionInCircle'
  }
}
