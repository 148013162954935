import { EuiFlexGroup, EuiFlexItem } from '@elastic/eui'
import VerticalDivider from '../../common/vertical-divider'
import { InteractionMessageAggregationsFragment } from '../../api/generated-types'
import { Metric, MetricProps, MetricType } from '../../common/metrics'

interface InteractionMessageListMetricsProps {
  aggregations?: InteractionMessageAggregationsFragment
}

export const InteractionMessageListMetrics = (props: InteractionMessageListMetricsProps) => {
  const {
    count,
    answeredCount,
    answeredPercentage,
    missedCount,
    missedPercentage,
    abandonedCount,
    shortAbandonedCount,
    abandonedPercentage,
    shortAbandonedPercentage,
    overflowCount,
    overflowPercentage,
    afterHoursCount,
    afterHoursPercentage
  } = props.aggregations?.summary ?? {}

  const createMetric = (label: string, value: number, percentage: number): MetricProps => ({
    primary: {
      label,
      value: percentage * 100,
      type: MetricType.Percentage
    },
    secondary: {
      label: 'Calls',
      value: value
    }
  })

  const metrics = [
    createMetric('Answered', answeredCount ?? 0, answeredPercentage ?? 0),
    createMetric('Missed', missedCount ?? 0, missedPercentage ?? 0),
    createMetric('Abandoned', abandonedCount ?? 0, abandonedPercentage ?? 0),
    createMetric('Short abandoned', shortAbandonedCount ?? 0, shortAbandonedPercentage ?? 0),
    createMetric('Overflow', overflowCount ?? 0, overflowPercentage ?? 0),
    createMetric('After hours', afterHoursCount ?? 0, afterHoursPercentage ?? 0)
  ]

  return (
    <div>
      <div className="small-text" style={{ marginBottom: '12px' }}>
        Search summary
      </div>
      <EuiFlexGroup gutterSize="s" className="spacing">
        <EuiFlexItem grow={false}>
          <Metric
            primary={{
              label: 'Calls',
              value: count ?? 0
            }}
          />
        </EuiFlexItem>

        <EuiFlexItem grow={false}>
          <VerticalDivider />
        </EuiFlexItem>

        {metrics.map((metric, index) => (
          <EuiFlexItem grow={false} key={index}>
            <Metric primary={metric.primary} secondary={metric.secondary} />
          </EuiFlexItem>
        ))}

        <EuiFlexItem grow={true} />
      </EuiFlexGroup>
    </div>
  )
}
