import { YesNoValue } from '../common/common-enums'
import { AttributeRequirement, JobType, TradeType } from '../../../api/generated-types'
import {
  TopicQualification,
  TopicQualificationResult,
  TopicRequirementsResult,
  createOutcomeGenerator
} from '../common/common-outcome'
import {
  InsideOrOutside,
  TopicPlumbingWaterLeakDetectionActionResult,
  WaterLeakOutsideSymptom
} from './action-topic-plumbing-water-leak-detection-model'

export const getResponseSummary = (result?: TopicPlumbingWaterLeakDetectionActionResult) => {
  const {
    insideOrOutside,
    ceilingLeaking,
    canHearWaterRunningInside,
    canSeeWetPatchOnFloor,
    canSeeShowerLeakingBehindWall,
    suspectWaterIsLeakingBehindWall,
    canSmellDampOrWetCarpet,
    otherWetPatchesOnWalls,
    swollenFloorboards,
    canIsolateWater,
    bathroomLaundryOrToiletAboveTheWetArea,
    ductedACInstalled
  } = result ?? {}

  const wallOrFloorInsideLeak =
    insideOrOutside &&
    insideOrOutside === InsideOrOutside.Inside &&
    !!ceilingLeaking &&
    ceilingLeaking === YesNoValue.No

  const allWallOrFloorInsideLeakQuestionsAnswered =
    wallOrFloorInsideLeak &&
    !!canHearWaterRunningInside &&
    !!canSeeWetPatchOnFloor &&
    !!canSeeShowerLeakingBehindWall &&
    !!suspectWaterIsLeakingBehindWall &&
    !!canSmellDampOrWetCarpet &&
    !!otherWetPatchesOnWalls &&
    !!swollenFloorboards

  //FIXME: move the logic for outside competed flows out of canContinue
  const maybeHVACJob =
    ceilingLeaking === YesNoValue.Yes &&
    !!bathroomLaundryOrToiletAboveTheWetArea &&
    !!ductedACInstalled &&
    ductedACInstalled === YesNoValue.Yes

  const canContinue =
    (wallOrFloorInsideLeak && !!bathroomLaundryOrToiletAboveTheWetArea) ||
    !maybeHVACJob ||
    allWallOrFloorInsideLeakQuestionsAnswered

  const waitingForACQuestion = bathroomLaundryOrToiletAboveTheWetArea === YesNoValue.No && !ductedACInstalled

  const askIsolateWaterQuestion =
    !!canIsolateWater || (canContinue && !!ceilingLeaking && !canIsolateWater && !waitingForACQuestion)
  return {
    askIsolateWaterQuestion,
    waitingForACQuestion,
    canContinue,
    maybeHVACJob,
    wallOrFloorInsideLeak,
    allWallOrFloorInsideLeakQuestionsAnswered
  }
}

export const getRequirements = (result?: TopicPlumbingWaterLeakDetectionActionResult): TopicRequirementsResult => {
  const {
    insideOrOutside,
    ductedACRunning,
    ductedACInstalled,
    ductedACCheckedForLeaks,
    symptom,
    canIsolateWater,
    waterMeterSpinning,
    havePool,
    bathroomLaundryOrToiletAboveTheWetArea,
    poolWaterLevelDropping,
    ceilingLeaking,
    canHearWaterRunningInside,
    canSeeShowerLeakingBehindWall,
    canSeeWetPatchOnFloor,
    suspectWaterIsLeakingBehindWall,
    canSmellDampOrWetCarpet,
    swollenFloorboards,
    outsideSymptomDetail,
    otherWetPatchesOnWalls
  } = result ?? {}

  const GENERAL_PLUMBING_SKILL = { attributeId: 'GeneralPlumbing' }
  const LEAK_DETECTION_SKILL = { attributeId: 'LeakDetection' }
  const GENERAL_DRAINS_SKILL = { attributeId: 'GeneralDrains' }
  const POOL_PLUMBING_SKILL = { attributeId: 'PoolPlumbing' }
  const WATER_LEAK_DETECTION = 'WaterLeakDetection'

  let requiredTrade: TradeType = TradeType.Drains

  const attributes = [] as AttributeRequirement[]

  if (!insideOrOutside) return {} //first question unanswered
  if (insideOrOutside === InsideOrOutside.Inside) {
    if (
      ceilingLeaking === YesNoValue.Yes &&
      bathroomLaundryOrToiletAboveTheWetArea === YesNoValue.Yes &&
      !!canIsolateWater
    ) {
      attributes.push(GENERAL_PLUMBING_SKILL)
      requiredTrade = TradeType.Plumbing
      return {
        trade: requiredTrade,
        requirements: {
          attributes
        }
      }
    }
    if (
      bathroomLaundryOrToiletAboveTheWetArea === YesNoValue.No &&
      ductedACInstalled === YesNoValue.Yes &&
      ductedACRunning === YesNoValue.Yes &&
      ductedACCheckedForLeaks === YesNoValue.No
    ) {
      return {
        trade: TradeType.HVAC,
        type: JobType.Service,
        category: WATER_LEAK_DETECTION,
        requirements: {
          attributes: [{ attributeId: 'DuctedAC' }]
        }
      }
    } else {
      //all other cases lead to general if they are answered
      //WIP
      if (
        ceilingLeaking === YesNoValue.Yes &&
        bathroomLaundryOrToiletAboveTheWetArea === YesNoValue.No &&
        (ductedACInstalled === YesNoValue.No || ductedACCheckedForLeaks === YesNoValue.Yes)
      ) {
        return {
          trade: TradeType.Plumbing,
          requirements: {
            attributes: [GENERAL_PLUMBING_SKILL]
          }
        }
      }

      const wallOrFloorInsideLeak =
        !!insideOrOutside &&
        insideOrOutside === InsideOrOutside.Inside &&
        !!ceilingLeaking &&
        ceilingLeaking === YesNoValue.No

      const allWallOrFloorInsideLeakQuestionsAnswered =
        wallOrFloorInsideLeak &&
        !!canHearWaterRunningInside &&
        !!canSeeWetPatchOnFloor &&
        !!canSeeShowerLeakingBehindWall &&
        !!suspectWaterIsLeakingBehindWall &&
        !!canSmellDampOrWetCarpet &&
        !!otherWetPatchesOnWalls &&
        !!swollenFloorboards

      if (wallOrFloorInsideLeak && allWallOrFloorInsideLeakQuestionsAnswered && !!canIsolateWater) {
        attributes.push(GENERAL_PLUMBING_SKILL)
        requiredTrade = TradeType.Plumbing
      }
    }
  } else {
    switch (symptom) {
      case WaterLeakOutsideSymptom.WaterMeter:
        if (waterMeterSpinning === YesNoValue.No) {
          return {}
        } else {
          attributes.push(GENERAL_PLUMBING_SKILL)
          requiredTrade = TradeType.Plumbing
        }
        break
      case WaterLeakOutsideSymptom.Yard:
        if (waterMeterSpinning === YesNoValue.Yes) {
          attributes.push(LEAK_DETECTION_SKILL)
          requiredTrade = TradeType.Plumbing
        } else {
          if (havePool === YesNoValue.No) {
            attributes.push(GENERAL_DRAINS_SKILL)
            requiredTrade = TradeType.Drains
          } else {
            if (
              waterMeterSpinning === YesNoValue.No &&
              havePool === YesNoValue.Yes &&
              poolWaterLevelDropping === YesNoValue.Yes
            ) {
              attributes.push(POOL_PLUMBING_SKILL)
              requiredTrade = TradeType.Plumbing
            } else {
              if (
                waterMeterSpinning === YesNoValue.No &&
                havePool === YesNoValue.Yes &&
                poolWaterLevelDropping === YesNoValue.No
              ) {
                attributes.push(GENERAL_DRAINS_SKILL)
                requiredTrade = TradeType.Drains
              }
            }
          }
        }
        break
      case WaterLeakOutsideSymptom.Other:
        if (outsideSymptomDetail) {
          attributes.push(LEAK_DETECTION_SKILL)
          requiredTrade = TradeType.Plumbing
        }
    }
  }
  return {
    trade: requiredTrade,
    requirements: {
      attributes
    }
  }
}

const getQualification = (
  result: TopicPlumbingWaterLeakDetectionActionResult | undefined
): TopicQualificationResult => {
  const {
    insideOrOutside,
    ductedACRunning,
    ductedACInstalled,
    ductedACCheckedForLeaks,
    symptom,
    canIsolateWater,
    waterMeterSpinning,
    havePool,
    bathroomLaundryOrToiletAboveTheWetArea,
    poolWaterLevelDropping,
    ceilingLeaking,
    outsideSymptomDetail
  } = result ?? {}

  if (!insideOrOutside) return { qualification: TopicQualification.Unknown } //first question unanswered

  const { allWallOrFloorInsideLeakQuestionsAnswered, wallOrFloorInsideLeak } = getResponseSummary(result)

  if (insideOrOutside === InsideOrOutside.Inside) {
    if (
      ceilingLeaking === YesNoValue.Yes &&
      bathroomLaundryOrToiletAboveTheWetArea === YesNoValue.Yes &&
      !!canIsolateWater
    ) {
      return { qualification: TopicQualification.InScope }
    }
    if (
      bathroomLaundryOrToiletAboveTheWetArea === YesNoValue.No &&
      ductedACInstalled === YesNoValue.Yes &&
      ductedACRunning === YesNoValue.Yes &&
      !!ductedACCheckedForLeaks
    ) {
      return { qualification: TopicQualification.InScope }
    } else {
      //all other cases lead to general if they are answered
      if (
        ceilingLeaking === YesNoValue.Yes &&
        bathroomLaundryOrToiletAboveTheWetArea === YesNoValue.No &&
        ductedACInstalled === YesNoValue.No
      ) {
        return { qualification: TopicQualification.InScope }
      }

      if (wallOrFloorInsideLeak && allWallOrFloorInsideLeakQuestionsAnswered && !!canIsolateWater) {
        return { qualification: TopicQualification.InScope }
      }
    }
  } else {
    const OUT_OF_SCOPE_REFER_TO =
      'Unfortunately this type of work is out of scope for us and we strongly believe it is within your best interest to contact urban utilities / local council - 13 23 64'
    switch (symptom) {
      case WaterLeakOutsideSymptom.WaterMeter:
        if (waterMeterSpinning === YesNoValue.No) {
          return { qualification: TopicQualification.OutOfScope, qualificationMessage: OUT_OF_SCOPE_REFER_TO }
        } else {
          return { qualification: canIsolateWater ? TopicQualification.InScope : TopicQualification.Unknown }
        }
      case WaterLeakOutsideSymptom.Yard:
        if (waterMeterSpinning === YesNoValue.Yes) {
          return { qualification: canIsolateWater ? TopicQualification.InScope : TopicQualification.Unknown }
        } else {
          if (havePool === YesNoValue.No) {
            return { qualification: TopicQualification.InScope }
          } else {
            if (
              waterMeterSpinning === YesNoValue.No &&
              havePool === YesNoValue.Yes &&
              poolWaterLevelDropping === YesNoValue.Yes
            ) {
              return { qualification: TopicQualification.InScope }
            } else {
              if (
                waterMeterSpinning === YesNoValue.No &&
                havePool === YesNoValue.Yes &&
                poolWaterLevelDropping === YesNoValue.No
              ) {
                return { qualification: TopicQualification.InScope }
              }
            }
          }
        }
        break
      case WaterLeakOutsideSymptom.Other:
        if (outsideSymptomDetail) {
          return { qualification: TopicQualification.InScope }
        }
    }
  }
  return { qualification: TopicQualification.Unknown }
}

export const outcomeGenerator = createOutcomeGenerator<TopicPlumbingWaterLeakDetectionActionResult>(
  {
    qualification: TopicQualification.Unknown,
    trade: TradeType.Drains,
    type: JobType.Service,
    category: 'General'
  },
  getQualification,
  getRequirements
)
