import { CustomerLocationFragment, CustomerType, MembershipStatusType } from '@fallonsolutions/types'

export const isCustomerExistingMember = (
  customerLocation: CustomerLocationFragment | undefined,
  contactRole: CustomerType
): boolean => {
  if (!customerLocation) return false
  const doesLocationHaveActiveMembership =
    customerLocation?.memberships?.some((membership) => membership.status.status === MembershipStatusType.Active) ??
    false

  return doesLocationHaveActiveMembership && contactRole === CustomerType.Domestic
}
