import { CustomerType, JobType, TradeType } from '../../../api/generated-types'
import { ServiceType } from '../action-topic-model'
import { YesNoValue } from '../common/common-enums'
import { canProceedWithRepair, getMechanicalIssueOnlyMessage } from '../appliance-shared/appliance-model'
import {
  TopicQualification,
  TopicQualificationResult,
  TopicRequirementsResult,
  createOutcomeGenerator,
  createRequirements
} from '../common/common-outcome'
import { CoffeeMachineIssueEnum, TopicCoffeeMachineActionResult } from './action-topic-coffee-machine-model'

import {
  APPLIANCE_FAULT_OR_REPAIR_SKILL,
  GENERAL_DRAINS_SKILL,
  GENERAL_ELECTRICAL_SKILL,
  GENERAL_PLUMBING_SKILL
} from '../common/skill-constants'

const getRequirements = (result: TopicCoffeeMachineActionResult | undefined): TopicRequirementsResult => {
  const {
    customerType,
    serviceType,
    applianceIssue,
    installationLocation,
    applianceBrand,
    applianceAge,
    brandNew,
    outletWorksWithOtherDevice
  } = result ?? {}

  if (
    serviceType === ServiceType.Installation &&
    customerType === CustomerType.Commercial &&
    !!applianceBrand &&
    !!installationLocation &&
    !!brandNew
  ) {
    return { requirements: createRequirements([GENERAL_PLUMBING_SKILL]) }
  }

  if (serviceType === ServiceType.Repair) {
    if (!applianceAge || canProceedWithRepair(result) === undefined) {
      return {}
    }
    switch (applianceIssue) {
      case CoffeeMachineIssueEnum.PowerSupply:
        if (outletWorksWithOtherDevice) {
          return outletWorksWithOtherDevice === YesNoValue.Yes
            ? { requirements: createRequirements([APPLIANCE_FAULT_OR_REPAIR_SKILL]), trade: TradeType.Appliances }
            : { requirements: createRequirements([GENERAL_ELECTRICAL_SKILL]), trade: TradeType.Electrical }
        } else {
          return {}
        }
      case CoffeeMachineIssueEnum.Drain:
        return { requirements: createRequirements([GENERAL_DRAINS_SKILL]), trade: TradeType.Drains }
      case CoffeeMachineIssueEnum.WaterLine:
        return { requirements: createRequirements([GENERAL_PLUMBING_SKILL]) }
      case CoffeeMachineIssueEnum.OtherFault:
      default:
        return {}
    }
  }

  return {}
}

const getQualification = (result: TopicCoffeeMachineActionResult | undefined): TopicQualificationResult => {
  const { customerType, applianceAge, applianceIssue, serviceType, outletWorksWithOtherDevice } = result ?? {}

  if (serviceType === ServiceType.Installation) {
    switch (customerType) {
      case CustomerType.Commercial:
        return { qualification: TopicQualification.InScope }
      case CustomerType.Domestic:
        return { qualification: TopicQualification.OutOfScope }
      default:
        return { qualification: TopicQualification.Unknown }
    }
  } else {
    if (!applianceAge || canProceedWithRepair(result) === undefined) {
      return { qualification: TopicQualification.Unknown }
    }
    if (!canProceedWithRepair(result)) {
      return { qualification: TopicQualification.OutOfScope, qualificationMessage: `Coffee machine repair` }
    }

    switch (applianceIssue) {
      case CoffeeMachineIssueEnum.PowerSupply:
        if (outletWorksWithOtherDevice) {
          return { qualification: TopicQualification.InScope }
        } else {
          return { qualification: TopicQualification.Unknown }
        }
      case CoffeeMachineIssueEnum.Drain:
        return { qualification: TopicQualification.InScope }
      case CoffeeMachineIssueEnum.WaterLine:
        return { qualification: TopicQualification.InScope }
      case CoffeeMachineIssueEnum.OtherFault:
        return {
          qualification: TopicQualification.OutOfScope,
          qualificationMessage: getMechanicalIssueOnlyMessage('coffee machine')
        }
      default:
        return { qualification: TopicQualification.Unknown }
    }
  }
}
export const outcomeGenerator = createOutcomeGenerator<TopicCoffeeMachineActionResult>(
  { qualification: TopicQualification.Unknown, trade: TradeType.Plumbing, type: JobType.Service, category: 'General' },
  getQualification,
  getRequirements
)
