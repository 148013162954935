import { EuiButton, EuiSpacer } from '@elastic/eui'
import { JobType, TradeType } from '../../../api/generated-types'
import { Callout } from '../../../common/callout'
import { decameliseIfNotNil, makeHumanReadable } from '../../../common/utils'
import { CustomerSuppliedTopicActionResult } from '../../action-topic-customer-supplied'
import { SecurityResidencyCheck } from '../../helpers/gold-coast-security-questions'
import { CustomerSuppliedCompliant, IsItemAustralianCompliant } from '../../helpers/item-installer-questions'
import { QuestionDefinitions, getNotesGenerator } from '../../helpers/notes-generator'
import { OutOfScope } from '../../helpers/out-of-topic'
import { getResultUpdater } from '../../helpers/results-updater'
import { SecurityBrandQuestion } from '../../helpers/security-brand-question'
import { FreeTextQuestionField } from '../../question-fields/free-text-question-field'
import { SingleSelectField } from '../../question-fields/single-select-question-field'
import { WorkflowActionProps } from '../../workflow-model'
import { InstallType, ItemInstaller, ItemSupplier, TopicActionInput } from '../action-topic-model'
import { TopicWorkflowAction } from '../action-topic-view'

export interface TopicAccessControlActionResult extends CustomerSuppliedTopicActionResult {
  serviceType?: AccessControlServiceType
  itemSupplier?: ItemSupplier
  installType?: InstallType
  itemInstaller?: ItemInstaller
  installDate?: InstallDate
  numberOfDoors?: string
  systemAge?: string
  fault?: string
  faultAge?: string
  additionalFobsQuantity?: string
  additionalFobsType?: string
  additionalFobsFormat?: string
  brandType?: string
}

export enum InstallDate {
  LessThanOrEqualTo3Years = 'Less than 3 years ago',
  Over3Years = 'Over 3 years ago'
}

export enum AccessControlServiceType {
  Installation = 'Installation',
  Repair = 'Repair',
  AdditionalFobs = 'AdditionalFobs'
}

const questionDefinitions: QuestionDefinitions<TopicAccessControlActionResult> = {
  serviceType: {
    question: 'Is this for an installation, repair or additional fobs?',
    statement: 'Service type:',
    makeAnswerHumanReadable: decameliseIfNotNil
  },
  installType: {
    question: 'Is there a current system in place already?',
    statement: 'Existing system in place:',
    makeAnswerHumanReadable: decameliseIfNotNil
  },
  numberOfDoors: {
    question: 'Do you know how many doors you want to have access control?',
    statement: 'Number of doors for access control:'
  },
  itemSupplier: {
    question: 'Who is supplying the system?',
    statement: 'Fan supplied by:',
    makeAnswerHumanReadable: decameliseIfNotNil
  },
  itemInstaller: {
    question: 'Who installed the system?',
    statement: 'System installed by:',
    makeAnswerHumanReadable: decameliseIfNotNil
  },
  installDate: {
    question: 'When did Fallon install?',
    makeAnswerHumanReadable: makeHumanReadable
  },
  systemAge: {
    question: 'How old is the system?',
    statement: 'System age:'
  },
  fault: {
    question: 'What is the issue or fault?',
    statement: 'Issue or fault:'
  },
  faultAge: {
    question: 'How long has it been happening for?',
    statement: 'Fault age:'
  },
  additionalFobsQuantity: {
    question: 'How many additional fobs?',
    statement: 'Number of fobs:'
  },
  additionalFobsType: {
    question: 'What type of fobs?',
    statement: 'Type of fobs:'
  },
  additionalFobsFormat: {
    question: 'Cards or keyrings?',
    statement: 'Cards or keyrings:'
  },
  brandType: {
    question: 'What brand/manufacture is the system?',
    statement: 'Brand/Manufacture:'
  }
}
const notesGenerator = getNotesGenerator(questionDefinitions)

export const TopicAccessControlAction = (
  props: WorkflowActionProps<TopicActionInput, TopicAccessControlActionResult>
) => {
  const { result, input, onUpdate } = props
  const {
    serviceType,
    itemSupplier,
    itemInstaller,
    installDate,
    systemAge,
    fault,
    faultAge,
    additionalFobsQuantity,
    additionalFobsType,
    additionalFobsFormat,
    installType,
    numberOfDoors,
    australianCompliant,
    brandType
  } = result ?? {}
  const { serviceArea } = input
  const getJobType = () => {
    if (serviceType === AccessControlServiceType.AdditionalFobs) {
      return JobType.Quote
    } else if (serviceType === AccessControlServiceType.Repair) {
      return itemInstaller === ItemInstaller.Fallon && installDate === InstallDate.LessThanOrEqualTo3Years
        ? JobType.Callback
        : JobType.Service
    } else if (serviceType === AccessControlServiceType.Installation) {
      return JobType.Quote
    } else {
      return JobType.Service
    }
  }

  const onNext = () =>
    onUpdate({
      ...result,
      actionCompleted: true,
      trade: TradeType.Security,
      category: 'AccessControl',
      type: getJobType(),
      workRequiredNotes: generatedNotes,
      requirements: {
        travelFeeWaived: serviceType === AccessControlServiceType.Installation && itemSupplier === ItemSupplier.Fallon,
        attributes: [{ attributeId: 'AccessControl' }]
      }
    })

  const generatedNotes = notesGenerator.generateNotes(result ?? {})
  const updateResult = getResultUpdater(result ?? {}, onUpdate).updateResult
  const updateBrandTypeQuestion = (brandType?: string) => updateResult({ brandType })
  const additionalFobsComplete =
    serviceType === AccessControlServiceType.AdditionalFobs &&
    additionalFobsQuantity &&
    additionalFobsType &&
    additionalFobsFormat
  const { jobIsCompliant, jobIsNotCompliant } = CustomerSuppliedCompliant({ australianCompliant })
  const installComplete = serviceType === AccessControlServiceType.Installation && itemSupplier && jobIsCompliant
  const revisitComplete =
    serviceType === AccessControlServiceType.Repair && itemInstaller === ItemInstaller.Fallon && brandType
  const repairComplete =
    serviceType === AccessControlServiceType.Repair &&
    itemInstaller === ItemInstaller.SomeoneElse &&
    systemAge &&
    fault &&
    faultAge &&
    brandType
  const canComplete = additionalFobsComplete || installComplete || revisitComplete || repairComplete
  return (
    <TopicWorkflowAction input={input} onUpdate={onUpdate} result={result}>
      <SingleSelectField
        question={notesGenerator.getQuestion('serviceType')}
        options={[
          {
            id: AccessControlServiceType.Installation,
            label: 'Installation',
            icon: 'plus'
          },
          {
            id: AccessControlServiceType.Repair,
            label: 'Repair',
            icon: 'wrench'
          },
          {
            id: AccessControlServiceType.AdditionalFobs,
            label: 'Additional fobs',
            icon: 'empty'
          }
        ]}
        answer={serviceType}
        changeAnswer={(serviceType) =>
          updateResult({
            serviceType,
            itemInstaller: undefined,
            itemSupplier: undefined
          })
        }
      />

      {serviceType === AccessControlServiceType.AdditionalFobs && (
        <>
          <FreeTextQuestionField
            question={notesGenerator.getQuestion('additionalFobsQuantity')}
            answer={additionalFobsQuantity}
            changeAnswer={(additionalFobsQuantity) => updateResult({ additionalFobsQuantity })}
          />
          <FreeTextQuestionField
            question={notesGenerator.getQuestion('additionalFobsType')}
            answer={additionalFobsType}
            changeAnswer={(additionalFobsType) => updateResult({ additionalFobsType })}
          />
          <FreeTextQuestionField
            question={notesGenerator.getQuestion('additionalFobsFormat')}
            answer={additionalFobsFormat}
            changeAnswer={(additionalFobsFormat) => updateResult({ additionalFobsFormat })}
          />
        </>
      )}

      {serviceType === AccessControlServiceType.Installation && (
        <>
          <SingleSelectField
            question={notesGenerator.getQuestion('itemSupplier')}
            options={[
              {
                id: ItemSupplier.Fallon,
                label: 'Fallon',
                icon: 'check'
              },
              {
                id: ItemSupplier.Customer,
                label: 'Customer',
                icon: 'user'
              }
            ]}
            answer={itemSupplier}
            changeAnswer={(itemSupplier) => updateResult({ itemSupplier })}
          />
          <IsItemAustralianCompliant
            updateResult={updateResult}
            itemSupplier={itemSupplier}
            suppliedAnswer={australianCompliant}
          />
          {jobIsNotCompliant ? (
            <OutOfScope issue={'access control'} isOutOfScope={true} />
          ) : (
            <>
              <SingleSelectField
                question={notesGenerator.getQuestion('installType')}
                options={[
                  {
                    id: InstallType.NewInstall,
                    label: 'New install'
                  },
                  {
                    id: InstallType.Replacement,
                    label: 'Existing system in place'
                  }
                ]}
                answer={installType}
                changeAnswer={(installType) => updateResult({ installType })}
              />
              <FreeTextQuestionField
                question={notesGenerator.getQuestion('numberOfDoors')}
                answer={numberOfDoors}
                changeAnswer={(numberOfDoors) => updateResult({ numberOfDoors })}
              />
            </>
          )}
        </>
      )}

      {serviceType === AccessControlServiceType.Repair && (
        <>
          <SingleSelectField
            question={notesGenerator.getQuestion('itemInstaller')}
            options={[
              {
                id: ItemInstaller.Fallon,
                label: 'Fallon installed',
                icon: 'check'
              },
              {
                id: ItemInstaller.SomeoneElse,
                label: 'Someone else installed',
                icon: 'user'
              }
            ]}
            answer={itemInstaller}
            changeAnswer={(itemInstaller) => updateResult({ itemInstaller })}
          />
          <SecurityBrandQuestion
            question={notesGenerator.getQuestion('brandType')}
            questionAnswer={brandType}
            updateQuestion={updateBrandTypeQuestion}
          />
        </>
      )}

      {serviceType === AccessControlServiceType.Repair && itemInstaller === ItemInstaller.Fallon && (
        <>
          <SingleSelectField
            question={notesGenerator.getQuestion('installDate')}
            options={[
              {
                id: InstallDate.LessThanOrEqualTo3Years,
                label: 'Within last 3 years'
              },
              {
                id: InstallDate.Over3Years,
                label: 'Over 3 years ago'
              }
            ]}
            answer={installDate}
            changeAnswer={(installDate) => updateResult({ installDate })}
          />
          {itemInstaller === ItemInstaller.Fallon && !!installDate && (
            <>
              <Callout
                type="note"
                title={
                  installDate === InstallDate.LessThanOrEqualTo3Years
                    ? 'Revisit with no travel fee'
                    : 'Normal service job with travel fee'
                }
              />
              <EuiSpacer />
            </>
          )}
        </>
      )}

      {serviceType === AccessControlServiceType.Repair && itemInstaller === ItemInstaller.SomeoneElse && (
        <>
          <FreeTextQuestionField
            question={notesGenerator.getQuestion('systemAge')}
            answer={systemAge}
            changeAnswer={(systemAge) => updateResult({ systemAge })}
          />
          <FreeTextQuestionField
            question={notesGenerator.getQuestion('fault')}
            answer={fault}
            changeAnswer={(fault) => updateResult({ fault })}
            rows={3}
          />
          <FreeTextQuestionField
            question={notesGenerator.getQuestion('faultAge')}
            answer={faultAge}
            changeAnswer={(faultAge) => updateResult({ faultAge })}
          />
        </>
      )}

      <EuiSpacer />
      {!!serviceType && <SecurityResidencyCheck serviceArea={serviceArea} />}
      <EuiButton disabled={!canComplete} onClick={() => onNext()}>
        Next
      </EuiButton>
    </TopicWorkflowAction>
  )
}
