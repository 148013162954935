import { JobType, TradeType } from '../../../api/generated-types'
import {
  TopicQualification,
  TopicQualificationResult,
  TopicRequirementsResult,
  createOutcomeGenerator,
  createRequirements
} from '../common/common-outcome'
import { TopicWaterLeakActionResult } from './action-topic-water-leak-model'

const GENERAL_PLUMBING_SKILL = 'GeneralPlumbing'

const getRequirements = (result: TopicWaterLeakActionResult | undefined): TopicRequirementsResult => {
  console.log('result', result)
  return { requirements: createRequirements([GENERAL_PLUMBING_SKILL]) }
}

const getQualification = (result: TopicWaterLeakActionResult | undefined): TopicQualificationResult => {
  const { leakLocation, leakAge, canLeakBeIsolated } = result ?? {}
  if (!!leakLocation && !!leakAge && !!canLeakBeIsolated) {
    return { qualification: TopicQualification.InScope }
  } else {
    return { qualification: TopicQualification.Unknown }
  }
}

export const outcomeGenerator = createOutcomeGenerator<TopicWaterLeakActionResult>(
  {
    qualification: TopicQualification.Unknown,
    trade: TradeType.Plumbing,
    type: JobType.Service,
    category: 'WaterLeak'
  },
  getQualification,
  getRequirements
)
