import { useMutation, useQuery } from '@apollo/client'
import {
  EuiButton,
  EuiButtonEmpty,
  EuiButtonGroup,
  EuiButtonGroupOptionProps,
  EuiCallOut,
  EuiFormRow,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiOverlayMask
} from '@elastic/eui'
import { map } from 'lodash-es'
import { useEffect, useState } from 'react'
import { DataQualityStatus, GetCustomerDocument, UpdateCustomerDataQualityDocument } from '../api/generated-types'
import { decamelise } from '../common/utils'

export interface EditContactDataQualityFormProps {
  customerId: string
  onClose: () => void
}

export const UpdateCustomerDataQualityForm = (props: EditContactDataQualityFormProps) => {
  const { customerId, onClose } = props
  const [status, setStatus] = useState<DataQualityStatus>(DataQualityStatus.NotChecked)

  const { data: customerData, loading: customerLoading } = useQuery(GetCustomerDocument, {
    variables: { id: customerId },
    onCompleted: (data) => {
      setStatus(data?.getCustomer?.dataQuality?.status ?? DataQualityStatus.NotChecked)
    }
  })
  const customer = customerData?.getCustomer

  const [updateCustomerDataQuality, { data, loading: updateLoading, error }] = useMutation(
    UpdateCustomerDataQualityDocument,
    {
      refetchQueries: ['SearchCustomers', 'GetCustomer'],
      awaitRefetchQueries: true
    }
  )

  const loading = customerLoading || updateLoading

  const handleSubmit = () => {
    updateCustomerDataQuality({
      variables: {
        input: {
          customerId,
          status,
          issues: []
        }
      }
    })
  }

  useEffect(() => {
    if (data?.updateCustomerDataQuality?.customer) {
      onClose()
    }
  }, [data, onClose])

  enum DataQualityStatusSorted {
    NotChecked = DataQualityStatus.NotChecked,
    Spam = DataQualityStatus.Spam,
    Invalid = DataQualityStatus.Invalid,
    Valid = DataQualityStatus.Valid,
    Error = DataQualityStatus.Error
  }
  const statusOptions: EuiButtonGroupOptionProps[] = map(DataQualityStatusSorted, (id) => ({
    id: id as unknown as string,
    label: decamelise(id as unknown as string)
  }))

  return (
    <EuiOverlayMask>
      <EuiModal onClose={onClose}>
        <EuiModalHeader>
          <EuiModalHeaderTitle>{customer?.number}</EuiModalHeaderTitle>
        </EuiModalHeader>
        <EuiModalBody>
          {error && <EuiCallOut color="danger">Error editing customer: {error.message}</EuiCallOut>}
          <EuiFormRow label="Status">
            <EuiButtonGroup
              legend="Status"
              options={statusOptions}
              type="single"
              idSelected={status}
              onChange={(id: string) => setStatus(id as DataQualityStatus)}
              buttonSize="compressed"
              color="primary"
              isDisabled={loading}
            />
          </EuiFormRow>
        </EuiModalBody>

        <EuiModalFooter>
          {!loading && (
            <EuiButtonEmpty onClick={onClose} isLoading={updateLoading}>
              Cancel
            </EuiButtonEmpty>
          )}
          <EuiButton fill onClick={() => handleSubmit()} isLoading={loading} disabled={!customer}>
            Edit Customer
          </EuiButton>
        </EuiModalFooter>
      </EuiModal>
    </EuiOverlayMask>
  )
}
