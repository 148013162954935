import {
  EuiButton,
  EuiButtonEmpty,
  EuiFlexGroup,
  EuiFlexItem,
  EuiLink,
  EuiLoadingSpinner,
  EuiSpacer
} from '@elastic/eui'
import { ContentState, Editor, EditorState } from 'draft-js'
import 'draft-js/dist/Draft.css'
import { useEffect, useRef, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import '../static/css/comment.css'

interface NotesViewProps {
  label?: string | undefined
  labelSize?: 'small' | 'medium'
  notes: string
  isEditable: boolean
  startInEditMode?: boolean
  loading?: boolean
  onSave: (notes: string) => void
  onCancel?: () => void
}

export const NotesView = (props: NotesViewProps) => {
  const { label, notes, isEditable, onSave, onCancel, loading } = props

  const labelSize = props.labelSize ?? 'medium'

  const editorRef = useRef<Editor>(null)

  const [isEditorVisible, setIsEditorVisible] = useState(props.startInEditMode ?? false)

  const [editorState, setEditorState] = useState<EditorState>(
    EditorState.createWithContent(ContentState.createFromText(notes))
  )

  useEffect(() => {
    const newState = EditorState.createWithContent(ContentState.createFromText(notes))
    setEditorState(newState)
  }, [notes])

  const content = editorState.getCurrentContent()
  const hasContent = content.getPlainText().length > 0

  const submitClick = () => {
    if (isEditable) {
      const updatedNotes = content.getPlainText()
      onSave(updatedNotes)
      setIsEditorVisible(false)
    }
  }

  const cancelEditing = () => {
    setIsEditorVisible(false)
    onCancel && onCancel()
  }

  const startEditing = () => {
    setIsEditorVisible(true)
    editorRef.current?.focus()
  }
  const resetEditor = () => setEditorState(EditorState.createWithContent(ContentState.createFromText(notes[0] ?? '')))

  const displayNotes = notes.length ? notes : isEditable ? 'Add notes...' : ''
  const hasChanges = notes !== content.getPlainText()

  return (
    <div style={{ maxWidth: '720px' }}>
      <EuiFlexGroup gutterSize="none">
        {label && (
          <EuiFlexItem grow={true}>
            <div className={`${labelSize}-label`}>{label}</div>
          </EuiFlexItem>
        )}
        {isEditable && isEditorVisible && hasChanges && (
          <EuiFlexItem grow={false}>
            <EuiLink color="subdued" onClick={resetEditor}>
              Reset
            </EuiLink>
          </EuiFlexItem>
        )}
      </EuiFlexGroup>
      {isEditable && (
        <div style={{ display: isEditorVisible ? 'block' : 'none' }}>
          <div className={`editor ${hasContent ? 'editor--has-content' : ''}`}>
            <Editor editorState={editorState} onChange={setEditorState} placeholder="Enter notes..." />
          </div>
          <EuiSpacer size="s" />
          <EuiFlexGroup>
            <EuiFlexItem grow={false}>
              <EuiButton size="s" color="primary" isLoading={loading} onClick={submitClick} fill>
                {loading ? 'Saving...' : 'Save'}
              </EuiButton>
            </EuiFlexItem>
            {!loading && (
              <EuiFlexItem grow={false}>
                <EuiButtonEmpty size="s" onClick={cancelEditing}>
                  Cancel
                </EuiButtonEmpty>
              </EuiFlexItem>
            )}
          </EuiFlexGroup>
        </div>
      )}
      {!isEditorVisible && (
        <>
          <EuiFlexGroup gutterSize="xs" alignItems="center">
            <EuiFlexItem grow={true}>
              <div className={isEditable ? 'secondary-link' : ''} onClick={isEditable ? startEditing : undefined}>
                <ReactMarkdown children={loading ? content.getPlainText() : displayNotes} className="markdown-body" />
              </div>
              {!loading && isEditable && hasChanges && (
                <>
                  <EuiSpacer size="s" />
                  <span style={{ fontSize: '12px' }}>(Unsaved changes)</span>
                </>
              )}
            </EuiFlexItem>
            {loading && (
              <EuiFlexItem grow={false}>
                <EuiLoadingSpinner />
              </EuiFlexItem>
            )}
          </EuiFlexGroup>
        </>
      )}
    </div>
  )
}
