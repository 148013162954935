import { useRef } from 'react'

interface TextFieldPatternMatcherProps {
  textInput: HTMLTextAreaElement
  regexStrings: string[]
}
interface TextFieldPatternMatcherResults {
  start: number
  end: number
}
interface UseTextAreaHighlighterProps {
  matches: string[]
}
const useTextAreaHighlighter = ({ matches }: UseTextAreaHighlighterProps) => {
  const inputRef = useRef<HTMLTextAreaElement>(null)

  const clickHandler = () => {
    if (!inputRef || !inputRef.current) return
    const data = textFieldPatternMatcher({ textInput: inputRef.current, regexStrings: matches })
    data && inputRef.current.setSelectionRange(data.start, data.end)
  }

  return { inputRef, onClick: clickHandler }
}
interface MatchPayload {
  match: string
  start: number
  end: number
  radius: string
  found?: boolean
}

const getSubString = (match: string, textArea: string, startingPoint: number) => {
  const start = startingPoint - match.length > 0 ? startingPoint - match.length : 0
  const end = startingPoint + match.length < textArea.length ? startingPoint + match.length : textArea.length
  const result: MatchPayload = { match: match, start: start, end: end, radius: textArea.substring(start, end) }
  return result
}
const findTextMatchInRadius = (payload: MatchPayload) => {
  const { match, radius } = payload
  const regex = new RegExp(match, 'g')
  const matchResult = regex.exec(radius)
  if (matchResult) {
    //console.log(matchResult.index)
    const start = payload.start + matchResult.index
    const end = start + match.length
    return { ...payload, found: true, start, end }
  }
  return payload
}
const textFieldPatternMatcher = ({
  textInput: textArea,
  regexStrings
}: TextFieldPatternMatcherProps): TextFieldPatternMatcherResults | undefined => {
  return regexStrings
    .map((value) => getSubString(value, textArea.value, textArea.selectionStart))
    .map((payload) => findTextMatchInRadius(payload)) //
    .filter((payload) => payload.found) //[]
    .map((payload) => ({ start: payload.start, end: payload.end }))
    .find(() => true) // returns the first value of an array with the condition and returns it, otherwise undefined
}
export default useTextAreaHighlighter
