import { useMutation } from '@apollo/client'
import { SetUserTenantSignInDocument, UserTenantAccessStatus } from '../api/generated-types'
import createPersistedState from '../use-persisted-state'
import { EuiContextMenuItem, EuiContextMenuPanel, EuiHeaderLink, EuiLoadingSpinner, EuiPopover } from '@elastic/eui'
import { useState } from 'react'
import { useAuthService } from '../auth/auth-service'
import EuiCustomHeaderLink from '../common/eui-custom-header-link'
import { useAuthenticated } from '../auth/authenticated-context'
import { useSession } from '../auth/session-context'

const useSandbox = createPersistedState<boolean>('sandbox')

export const TenantSelectView = () => {
  const session = useAuthenticated().user
  const authentication = useSession()

  const { user, loading: authLoading } = useAuthService()

  const [loading, setLoading] = useState(false)
  const [tenantPopoverVisible, setTenantPopoverVisible] = useState(false)
  const [sandbox] = useSandbox(false)
  const [setUserTenantSignIn] = useMutation(SetUserTenantSignInDocument)

  const switchTenant = (tenantId: string) => {
    if (tenantId === session.tenantId) {
      console.log('Tenant is already selected')
      setTenantPopoverVisible(false)
      return
    }
    if (session.id) {
      setTenantPopoverVisible(false)
      setLoading(true)
      setUserTenantSignIn({
        variables: { input: { userId: session.id, tenantId } },
        onCompleted: (data) => {
          if (data) {
            authentication.data?.refresh()
          }
        }
      })
    }
  }

  const openPopover = () => setTenantPopoverVisible(true)
  const onClosePopover = () => setTenantPopoverVisible(false)

  const tenantList = (user?.tenants ?? [])
    .filter((ta) => ta.status === UserTenantAccessStatus.Active)
    .map((ta) => ({
      id: ta.tenantId,
      label: ta.tenant.name,
      selected: ta.tenantId === session.tenantId
    }))

  const contextMenuItems = tenantList.map((tenant) => {
    return (
      <EuiContextMenuItem
        key={tenant.id}
        icon={tenant.selected ? 'check' : 'empty'}
        onClick={() => switchTenant(tenant.id)}
      >
        {tenant.label}
      </EuiContextMenuItem>
    )
  })

  const getTenantName = (tenantId: string, sandbox: boolean): string => {
    if (sandbox) {
      return 'Fallon Solutions Sandbox'
    }
    const selectedTenant = tenantList.find((t) => t.id === tenantId)
    return selectedTenant?.label ?? 'Loading...'
  }
  const tenantName = getTenantName(session.tenantId ?? 'fallonsolutions', sandbox)

  return tenantList.length > 1 ? (
    <EuiPopover
      isOpen={tenantPopoverVisible}
      button={
        loading || authLoading ? (
          <EuiLoadingSpinner />
        ) : (
          <EuiHeaderLink onClick={openPopover}>{tenantName}</EuiHeaderLink>
        )
      }
      closePopover={onClosePopover}
    >
      <EuiContextMenuPanel size="s" items={contextMenuItems} />
    </EuiPopover>
  ) : (
    <EuiCustomHeaderLink to="/">{tenantName}</EuiCustomHeaderLink>
  )
}
