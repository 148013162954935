import { ContactCustomerRole } from '../api/generated-types'
import '../static/css/customer-contact-role-badge.css'

export interface ContactCustomerRoleBadgeProps {
  role?: ContactCustomerRole
  title?: string
}

export const ContactCustomerRoleBadge = ({ role, title }: ContactCustomerRoleBadgeProps) => {
  const roleString = role ?? 'Alternate'
  return (
    <span
      className={`customer-contact-role-badge customer-contact-role-badge--${roleString.toLowerCase()}`}
      title={title}
    >
      {roleString}
    </span>
  )
}
