import { useCallback, useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import { EuiButtonEmpty, EuiComboBox, EuiComboBoxOptionOption } from '@elastic/eui'
import { DispositionFragment, SearchDispositionsDocument, SearchDispositionsInput } from '../api/generated-types'
import { useDebouncedValue } from 'rooks'
import '../static/css/combo-box.css'

export interface DispositionComboBoxProps {
  dispositions: EuiComboBoxOptionOption[]
  onChange: (dispositions: EuiComboBoxOptionOption[]) => void
}

export const DispositionComboBox = (props: DispositionComboBoxProps) => {
  const { dispositions, onChange } = props
  const [term, setTerm] = useState('')
  const [enabled, setEnabled] = useState(dispositions.length > 0)
  const [comboInput, setComboInput] = useState<HTMLInputElement | null>(null)

  const [debouncedTerm] = useDebouncedValue(term, 150)

  const input: SearchDispositionsInput = {
    filters: {
      ...(debouncedTerm && debouncedTerm.length > 0 && { query: debouncedTerm })
    },
    from: 0,
    size: 50
  }

  const variables = { input }
  const { data, loading, error } = useQuery(SearchDispositionsDocument, {
    variables
  })

  const results = data?.searchDispositions?.results ?? []

  const options = (results ?? []).map((disposition: DispositionFragment) => {
    return {
      id: disposition.id,
      label: disposition.label
    }
  })

  if (error) {
    console.error('error', error)
  }

  const onSearchChange = useCallback((searchValue: string) => setTerm(searchValue), [])

  // Simulate initial load
  useEffect(() => onSearchChange(''), [onSearchChange])

  const onAddFilter = () => {
    setEnabled(true)
    setTimeout(() => comboInput?.focus(), 50)
  }

  const onBlur = () => dispositions.length <= 0 && setEnabled(false)

  const label = 'disposition'
  return (
    <form autoComplete="off">
      <EuiComboBox
        async
        inputRef={setComboInput}
        placeholder="Dispositions"
        options={options}
        selectedOptions={dispositions}
        onChange={onChange}
        isClearable={true}
        rowHeight={40}
        isLoading={loading}
        onSearchChange={onSearchChange}
        style={{ minWidth: '300px' }}
        onBlur={onBlur}
        hidden={!enabled}
        aria-label={label}
        data-test-subj={label}
      />
      {!enabled && (
        <EuiButtonEmpty iconType="filter" flush="both" onClick={onAddFilter}>
          Disposition
        </EuiButtonEmpty>
      )}
    </form>
  )
}
