import { useApp } from '../app/app-context'
import '../static/css/header.css'

interface EnvBannerProps {
  fixed: boolean
}

export const EnvBanner = (props: EnvBannerProps) => {
  const { fixed } = props
  const appContext = useApp()
  const stage = appContext.stage.toLowerCase()
  return <div className={`env-banner env-banner--${stage} ${fixed ? 'env-banner--fixed' : ''}`} />
}
