import { SingleSelectField } from '../../question-fields/single-select-question-field'
import { NotesGenerator } from '../../helpers/notes-generator'
import { ResultUpdater } from '../../helpers/results-updater'
import { GutterIssue, TopicGutterActionResult } from './action-topic-gutter-model'
import { EuiSpacer } from '@elastic/eui'
import { YesNoValue } from '../common/common-enums'
import { Callout } from '../../../common/callout'

export interface GutterBlockedProps {
  result?: TopicGutterActionResult
  notesGenerator: NotesGenerator<TopicGutterActionResult>
  updateResult: ResultUpdater<TopicGutterActionResult>
}

export const GutterBlocked = (props: GutterBlockedProps) => {
  const { result, notesGenerator, updateResult } = props
  const { issue, downpipesBlocked, gutterCleanInScope } = result ?? {}
  return (
    <>
      {issue === GutterIssue.Clean && (
        <>
          <SingleSelectField
            question={notesGenerator.getQuestion('downpipesBlocked')}
            options={[
              {
                id: YesNoValue.Yes,
                icon: 'check',
                label: 'Yes, downpipes are blocked'
              },
              {
                id: YesNoValue.No,
                icon: 'cross',
                label: 'No'
              }
            ]}
            answer={downpipesBlocked}
            changeAnswer={(downpipesBlocked) => updateResult({ downpipesBlocked })}
          />
          {downpipesBlocked === YesNoValue.No && (
            <>
              <Callout type="note" title="Check with DSR for scope for gutter clean (tech, time) or out of scope" />
              <EuiSpacer />
              <SingleSelectField
                question={notesGenerator.getQuestion('gutterCleanInScope')}
                options={[
                  {
                    id: YesNoValue.Yes,
                    icon: 'check',
                    label: 'Yes, DSR advised in scope'
                  },
                  {
                    id: YesNoValue.No,
                    icon: 'cross',
                    label: 'No'
                  }
                ]}
                answer={gutterCleanInScope}
                changeAnswer={(gutterCleanInScope) => updateResult({ gutterCleanInScope })}
              />
            </>
          )}
        </>
      )}
    </>
  )
}
