import { EuiButton, EuiSpacer, EuiText } from '@elastic/eui'
import { dateConfig } from '@fallonsolutions/date'
import { DateTime } from 'luxon'
import { useEffect } from 'react'
import { CustomerType, EnquiryAbortReason } from '../../../api/generated-types'
import { Callout } from '../../../common/callout'
import { HVACRoofAccessQuestion } from '../../helpers/hvac-commercial-roof-acccess-question'
import { getResultUpdater } from '../../helpers/results-updater'
import { useWorkflow } from '../../helpers/workflow-provider'
import { FreeTextQuestionField } from '../../question-fields/free-text-question-field'
import { SingleSelectField } from '../../question-fields/single-select-question-field'
import { WorkflowActionProps } from '../../workflow-model'
import { TopicActionInput } from '../action-topic-model'
import { TopicWorkflowAction } from '../action-topic-view'
import { YesNoValue } from '../common/common-enums'
import { TopicQualification } from '../common/common-outcome'
import { ACType } from '../hvac-quote-install/action-topic-hvac-quote-install'
import { TopicHVACInspectionActionResult, hvacInspectionNotesGenerator } from './action-topic-hvac-inspection-model'
import { outcomeGenerator } from './action-topic-hvac-inspection-outcome'

export const TopicHVACInspectionAction = (
  props: WorkflowActionProps<TopicActionInput, TopicHVACInspectionActionResult>
) => {
  const workflowContext = useWorkflow()
  const { result, input, onUpdate } = props
  const {
    isOutsidePeakPeriod,
    numberOfUnits,
    indoorUnitLocations,
    outdoorUnitLocations,
    hasManHoleAccess,
    lastServiceDate,
    systemAge,
    furnitureNeedToBeMoved,
    roofAccess,
    customerType,
    acType
  } = result ?? {}

  const notesGenerator = hvacInspectionNotesGenerator
  const isCommercial = customerType === CustomerType.Commercial
  const topicOutcome = outcomeGenerator(result)
  const isOutOfScope = topicOutcome.qualification === TopicQualification.OutOfScope
  const canComplete = topicOutcome.qualification === TopicQualification.InScope

  const onNext = () =>
    onUpdate({
      ...result,
      actionCompleted: true,
      ...topicOutcome,

      workRequiredNotes: generatedNotes
    })

  const generatedNotes = notesGenerator.generateNotes(result ?? {})
  const updateResult = getResultUpdater(result ?? {}, onUpdate).updateResult
  // set initial peak period response based on current date but allow user to override if needed
  // for example may be late march but customer might be interested in booking for April
  useEffect(() => {
    if (!isOutsidePeakPeriod) {
      const currentDate = DateTime.now().setZone(dateConfig.defaultTimezone)
      const calculatedIsOutsidePeakPeriod =
        currentDate.month >= 4 && currentDate.month <= 9 ? YesNoValue.Yes : YesNoValue.No
      updateResult({ isOutsidePeakPeriod: calculatedIsOutsidePeakPeriod })
    }
  }, [isOutsidePeakPeriod, updateResult])

  return (
    <TopicWorkflowAction input={input} onUpdate={onUpdate} result={result}>
      <SingleSelectField
        questionType="note"
        question={notesGenerator.getQuestion('isOutsidePeakPeriod')}
        options={[
          {
            id: YesNoValue.Yes,
            label: 'Yes, between April and September',
            icon: 'check'
          },
          {
            id: YesNoValue.No,
            label: 'No, in peak period',
            icon: 'cross'
          }
        ]}
        answer={isOutsidePeakPeriod}
        changeAnswer={(isOutsidePeakPeriod) => updateResult({ isOutsidePeakPeriod })}
      />

      {isOutsidePeakPeriod === YesNoValue.Yes && (
        <>
          <SingleSelectField
            options={[
              {
                id: ACType.SplitSystemOrMultiHead,
                label: 'Split System'
              },
              {
                id: ACType.Ducted,
                label: 'Ducted'
              },
              {
                id: ACType.UnsureOrOther,
                label: 'Unsure or Other'
              }
            ]}
            question={notesGenerator.getQuestion('acType')}
            answer={acType}
            changeAnswer={(acType) => updateResult({ acType })}
          />
          <FreeTextQuestionField
            question={notesGenerator.getQuestion('numberOfUnits')}
            answer={numberOfUnits}
            changeAnswer={(numberOfUnits) => updateResult({ numberOfUnits })}
          />
          <FreeTextQuestionField
            question={notesGenerator.getQuestion('indoorUnitLocations')}
            answer={indoorUnitLocations}
            changeAnswer={(indoorUnitLocations) => updateResult({ indoorUnitLocations })}
          />
          <FreeTextQuestionField
            question={notesGenerator.getQuestion('outdoorUnitLocations')}
            answer={outdoorUnitLocations}
            changeAnswer={(outdoorUnitLocations) => updateResult({ outdoorUnitLocations })}
          />
          <FreeTextQuestionField
            question={notesGenerator.getQuestion('hasManHoleAccess')}
            answer={hasManHoleAccess}
            changeAnswer={(hasManHoleAccess) => updateResult({ hasManHoleAccess })}
          />
          <FreeTextQuestionField
            question={notesGenerator.getQuestion('lastServiceDate')}
            answer={lastServiceDate}
            changeAnswer={(lastServiceDate) => updateResult({ lastServiceDate })}
          />
          <FreeTextQuestionField
            question={notesGenerator.getQuestion('systemAge')}
            answer={systemAge}
            changeAnswer={(systemAge) => updateResult({ systemAge })}
          />
          <FreeTextQuestionField
            question={notesGenerator.getQuestion('furnitureNeedToBeMoved')}
            answer={furnitureNeedToBeMoved}
            changeAnswer={(furnitureNeedToBeMoved) => updateResult({ furnitureNeedToBeMoved })}
          />
        </>
      )}
      <HVACRoofAccessQuestion updateResults={updateResult} isCommercial={isCommercial} roofAccess={roofAccess} />
      {isOutOfScope && (
        <>
          <Callout type="script">
            <EuiText>
              <div>
                Unfortunately, due to capacity this is only available in off peak times - Between April and September.
              </div>
            </EuiText>
          </Callout>
          <EuiSpacer />
          <EuiButton
            color="warning"
            onClick={() =>
              workflowContext.abort({
                abortReason: EnquiryAbortReason.CustomerNeedsAreOutOfService,
                notes: `Customer was enquiring about HVAC Inspection`
              })
            }
          >
            Offer a voucher and end call
          </EuiButton>
        </>
      )}

      {topicOutcome.requirements && (
        <>
          <EuiSpacer />
          <Callout type="note">{topicOutcome.requirements.attributes?.map((r) => r.attributeId).join(', ')}</Callout>
        </>
      )}

      {!isOutOfScope && (
        <>
          <EuiSpacer />
          <EuiButton disabled={!canComplete} onClick={() => onNext()}>
            Next
          </EuiButton>
        </>
      )}
    </TopicWorkflowAction>
  )
}
