import { EuiSpacer } from '@elastic/eui'
import { Callout } from '../../../common/callout'
import { NotesGenerator } from '../../helpers/notes-generator'
import { OutOfScope } from '../../helpers/out-of-topic'
import { ResultUpdater } from '../../helpers/results-updater'
import { ComboSelectField } from '../../question-fields/combo-select-question-field'
import { SingleSelectField } from '../../question-fields/single-select-question-field'
import {
  ApplianceAgeEnum,
  GeneralApplianceIssueType,
  RepairOrReplaceEnum,
  WARRANTY_SCRIPT,
  applianceAgeOptions,
  canProceedWithRepair,
  generalApplianceIssueTypeOptions,
  isBrandServiceable
} from '../appliance-shared/appliance-model'
import { YesNoValue } from '../common/common-enums'
import { yesNoOptions } from '../common/common-options'
import { TopicCooktopActionResult } from '../cooktop/action-topic-cooktop-model'
import { TopicDryerActionResult } from '../dryer/action-topic-dryer-model'
import { TopicOvenActionResult } from '../oven/action-topic-oven-model'
import { brandOptions, transformBrandSelections } from './appliance-brand-model'

export interface RepairElectricApplianceProps {
  result?: TopicCooktopActionResult | TopicOvenActionResult | TopicDryerActionResult
  notesGenerator: NotesGenerator<TopicCooktopActionResult | TopicOvenActionResult | TopicDryerActionResult>
  updateResult: ResultUpdater<TopicCooktopActionResult | TopicOvenActionResult | TopicDryerActionResult>
  isCooktopWorkflow: boolean
}

export const RepairElectricAppliance = (props: RepairElectricApplianceProps) => {
  const { result, notesGenerator, updateResult, isCooktopWorkflow } = props
  const {
    serviceType,
    confirmedPartsAvailableWithDSR,
    applianceAge,
    selectedBrand,
    brandServiceable,
    mayVoidWarrantyConfirmationToProceed,
    issue,
    outletWorksWithOtherDevice,
    happenedBefore,
    confirmCookTopIsElectric
  } = result ?? {}
  const mayProceed = canProceedWithRepair(result)

  //TODO: type on this
  const handleBrandSelect = (selectedOptions: any) => {
    const { selectedBrand, brand, brandServiceable } = transformBrandSelections(selectedOptions)
    updateResult({ selectedBrand, brand, brandServiceable, confirmedPartsAvailableWithDSR: undefined })
  }
  const brandWarning = selectedBrand?.warning
  //   console.log('applianceType', applianceType)
  const canContinueApplianceQuestions = !isCooktopWorkflow || confirmCookTopIsElectric === YesNoValue.Yes
  return (
    <>
      {serviceType === RepairOrReplaceEnum.Repair && (
        <>
          {isCooktopWorkflow && (
            <>
              <SingleSelectField
                question={notesGenerator.getQuestion('confirmCookTopIsElectric') ?? ''}
                options={yesNoOptions}
                answer={confirmCookTopIsElectric}
                changeAnswer={(confirmCookTopIsElectric) => updateResult({ confirmCookTopIsElectric })}
              />
              <EuiSpacer size="m" />
            </>
          )}
          <OutOfScope
            isOutOfScope={confirmCookTopIsElectric === YesNoValue.No}
            issue={'Repair cooktop'}
            reason={'We only perform repairs on electronic cook tops'}
          />
          {canContinueApplianceQuestions && (
            <>
              <SingleSelectField
                question={notesGenerator.getQuestion('applianceAge')}
                options={applianceAgeOptions}
                answer={applianceAge}
                changeAnswer={(applianceAge) => updateResult({ applianceAge })}
              />
              <EuiSpacer size="m" />

              {applianceAge === ApplianceAgeEnum.LessThan2yrsOld && (
                <>
                  <Callout type="script" title={WARRANTY_SCRIPT} />
                  <SingleSelectField
                    question={notesGenerator.getQuestion('mayVoidWarrantyConfirmationToProceed')}
                    options={yesNoOptions}
                    answer={mayVoidWarrantyConfirmationToProceed}
                    changeAnswer={(mayVoidWarrantyConfirmationToProceed) =>
                      updateResult({ mayVoidWarrantyConfirmationToProceed })
                    }
                  />
                </>
              )}

              {!!applianceAge && mayProceed && (
                <>
                  <ComboSelectField
                    question={notesGenerator.getQuestion('brand')}
                    options={brandOptions}
                    // FIXME: broken type here
                    answer={selectedBrand as any}
                    changeAnswer={handleBrandSelect}
                    brandWarning={brandWarning}
                  />

                  <EuiSpacer />
                  {!!selectedBrand && (
                    <>
                      {brandServiceable === YesNoValue.No && (
                        <>
                          <SingleSelectField
                            question={notesGenerator.getQuestion('confirmedPartsAvailableWithDSR')}
                            answer={confirmedPartsAvailableWithDSR}
                            changeAnswer={(confirmedPartsAvailableWithDSR) =>
                              updateResult({ confirmedPartsAvailableWithDSR })
                            }
                            options={yesNoOptions}
                          />
                        </>
                      )}
                      {isBrandServiceable(result) === true && (
                        <>
                          <SingleSelectField
                            question={notesGenerator.getQuestion('issue')}
                            options={generalApplianceIssueTypeOptions}
                            answer={issue}
                            changeAnswer={(issue) => updateResult({ issue })}
                          />
                          <EuiSpacer size="m" />

                          {issue === GeneralApplianceIssueType.PowerSupply && (
                            <>
                              <SingleSelectField
                                question={notesGenerator.getQuestion('outletWorksWithOtherDevice')}
                                answer={outletWorksWithOtherDevice}
                                changeAnswer={(outletWorksWithOtherDevice) =>
                                  updateResult({ outletWorksWithOtherDevice })
                                }
                                options={yesNoOptions}
                              />
                            </>
                          )}
                          {issue === GeneralApplianceIssueType.OtherFault && (
                            <SingleSelectField
                              question={notesGenerator.getQuestion('happenedBefore')}
                              answer={happenedBefore}
                              options={yesNoOptions}
                              changeAnswer={(happenedBefore) => updateResult({ happenedBefore })}
                            />
                          )}
                        </>
                      )}
                    </>
                  )}
                  <EuiSpacer size="m" />
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  )
}
