import { EuiFacetButton, EuiFacetGroup } from '@elastic/eui'
import { reject, uniq } from 'lodash-es'
import { TradeType } from '../api/generated-types'
import TradeTag from '../common/trade-tag'

interface TradeFacetsProps {
  trades: any[]
  setTrades: (facets: any[]) => void
  excludedTrades?: TradeType[]
}

const TradeFacets = (props: TradeFacetsProps) => {
  const excludedTrades = uniq([TradeType.None, ...(props.excludedTrades ?? [])]) ?? [
    (TradeType.None, TradeType.HotWater)
  ]

  const allTrades = reject(Object.keys(TradeType), (t) => excludedTrades.includes(t as TradeType))
  const tradeFacets = allTrades.map((trade) => {
    return {
      id: trade,
      label: trade,
      enabled: props.trades.includes(trade)
    }
  })

  const onClickFacet = (facet: any) => {
    let updatedTrades = props.trades
    if (props.trades.includes(facet.id)) {
      updatedTrades = updatedTrades.filter((t) => t !== facet.id)
    } else {
      updatedTrades = updatedTrades.concat(facet.id)
    }
    props.setTrades(updatedTrades)
  }

  return (
    <EuiFacetGroup layout="horizontal">
      {tradeFacets.map((facet: any) => (
        <EuiFacetButton
          key={facet.id}
          icon={<TradeTag trade={facet.enabled ? facet.id : 'none'} />}
          isSelected={facet.enabled}
          onClick={() => onClickFacet(facet)}
          style={{ minInlineSize: '40px', padding: '0px' }}
        >
          {facet.label}
        </EuiFacetButton>
      ))}
    </EuiFacetGroup>
  )
}

export default TradeFacets
