import { EuiButton, EuiSpacer } from '@elastic/eui'
import { JobType, TradeType } from '../../../api/generated-types'
import { Callout } from '../../../common/callout'
import { decameliseIfNotNil } from '../../../common/utils'
import { CustomerSuppliedTopicActionResult } from '../../action-topic-customer-supplied'
import { CustomerSuppliedCompliant, IsItemAustralianCompliant } from '../../helpers/item-installer-questions'
import { QuestionDefinitions, getNotesGenerator } from '../../helpers/notes-generator'
import { OutOfScope } from '../../helpers/out-of-topic'
import { getResultUpdater } from '../../helpers/results-updater'
import { FreeTextQuestionField } from '../../question-fields/free-text-question-field'
import { SingleSelectField } from '../../question-fields/single-select-question-field'
import { WorkflowActionProps } from '../../workflow-model'
import { ItemSupplier, TopicActionInput } from '../action-topic-model'
import { TopicWorkflowAction } from '../action-topic-view'
import { YesNoValue } from '../common/common-enums'
import { reset } from '../common/common-utils'

export interface TopicWallMountTVActionResult extends CustomerSuppliedTopicActionResult {
  itemSupplier?: ItemSupplier
  numberOfTVSetsToBeWallMounted?: string
  sizeOfTVs?: string
  isPowerPointNeeded?: YesNoValue
  isAntennaPointNeeded?: YesNoValue
}

const questionDefinitions: QuestionDefinitions<TopicWallMountTVActionResult> = {
  itemSupplier: {
    question: 'Who is supplying the TV mount?',
    makeAnswerHumanReadable: decameliseIfNotNil
  },
  numberOfTVSetsToBeWallMounted: {
    question: 'How many TVs are you wanting to have wall mounted?'
  },
  sizeOfTVs: {
    question: 'What size is the TV?'
  },
  isPowerPointNeeded: {
    question: 'Do you require a power point installed?',
    makeAnswerHumanReadable: decameliseIfNotNil
  },
  isAntennaPointNeeded: {
    question: 'Do you require an antenna point?',
    makeAnswerHumanReadable: decameliseIfNotNil
  }
}
const notesGenerator = getNotesGenerator(questionDefinitions)

export const TopicWallMountTVAction = (props: WorkflowActionProps<TopicActionInput, TopicWallMountTVActionResult>) => {
  const { result, input, onUpdate } = props
  const {
    itemSupplier,
    numberOfTVSetsToBeWallMounted,
    sizeOfTVs,
    isPowerPointNeeded,
    isAntennaPointNeeded,
    australianCompliant,
    jobDescription,
    customerType
  } = result ?? {}
  const { jobIsCompliant, jobIsNotCompliant } = CustomerSuppliedCompliant({ australianCompliant })

  const onNext = () =>
    onUpdate({
      ...result,
      actionCompleted: true,
      trade: TradeType.Data,
      category: 'WallMountTV',
      type: JobType.Service,

      workRequiredNotes: generatedNotes,
      requirements: {
        attributes: [
          { attributeId: 'TVAndAntenna' },
          ...(isPowerPointNeeded ? [{ attributeId: 'GeneralElectrical' }] : [])
        ]
      }
    })

  const generatedNotes = notesGenerator.generateNotes(result ?? {})
  const updateResult = getResultUpdater(result ?? {}, onUpdate).updateResult

  const canComplete =
    !!itemSupplier &&
    !!numberOfTVSetsToBeWallMounted &&
    !!sizeOfTVs &&
    !!isPowerPointNeeded &&
    !!isAntennaPointNeeded &&
    jobIsCompliant

  return (
    <TopicWorkflowAction input={input} onUpdate={onUpdate} result={result}>
      <SingleSelectField
        question={notesGenerator.getQuestion('itemSupplier')}
        options={[
          {
            id: ItemSupplier.Fallon,
            label: 'Fallon',
            icon: 'check'
          },
          {
            id: ItemSupplier.Customer,
            label: 'Customer',
            icon: 'user'
          }
        ]}
        answer={itemSupplier}
        changeAnswer={(itemSupplier) =>
          updateResult({
            ...reset(result ?? {}),
            customerType,
            itemSupplier,
            jobDescription
          })
        }
      />
      <IsItemAustralianCompliant
        updateResult={updateResult}
        itemSupplier={itemSupplier}
        suppliedAnswer={australianCompliant}
      />

      {jobIsNotCompliant ? (
        <OutOfScope issue={'Wall-mounted TV'} isOutOfScope={true} />
      ) : (
        <>
          <FreeTextQuestionField
            question={notesGenerator.getQuestion('numberOfTVSetsToBeWallMounted')}
            answer={numberOfTVSetsToBeWallMounted}
            changeAnswer={(numberOfTVSetsToBeWallMounted) => updateResult({ numberOfTVSetsToBeWallMounted })}
          />
          <FreeTextQuestionField
            question={notesGenerator.getQuestion('sizeOfTVs')}
            answer={sizeOfTVs}
            changeAnswer={(sizeOfTVs) => updateResult({ sizeOfTVs })}
          />
          <SingleSelectField
            question={notesGenerator.getQuestion('isPowerPointNeeded')}
            options={[
              {
                id: YesNoValue.Yes,
                label: 'Yes, a power point is needed',
                icon: 'check'
              },
              {
                id: YesNoValue.No,
                label: 'No',
                icon: 'cross'
              }
            ]}
            answer={isPowerPointNeeded}
            changeAnswer={(isPowerPointNeeded) => updateResult({ isPowerPointNeeded })}
          />
          <SingleSelectField
            question={notesGenerator.getQuestion('isAntennaPointNeeded')}
            options={[
              {
                id: YesNoValue.Yes,
                label: 'Yes, an antenna point is needed',
                icon: 'check'
              },
              {
                id: YesNoValue.No,
                label: 'No',
                icon: 'cross'
              }
            ]}
            answer={isAntennaPointNeeded}
            changeAnswer={(isAntennaPointNeeded) => updateResult({ isAntennaPointNeeded })}
          />

          {isPowerPointNeeded === YesNoValue.Yes && (
            <Callout type="note" title="Will need to book a dual Data + Electrical technician">
              <ul>
                <li>
                  Only have 1 technician who meets these dual skilling requirements and consequently is always quite
                  busy.
                </li>
                <li>
                  <strong>Instruction:</strong> Confirm tech's next available time is suitable with customer. If not,
                  Contact DSR to seek approval to send out 2x techs (one electrical and one data) with only 1x travel
                  fee.
                </li>
              </ul>
            </Callout>
          )}
          {isPowerPointNeeded === YesNoValue.No && <Callout type="note" title="Book a Data technician" />}
        </>
      )}

      <EuiSpacer />
      <EuiButton disabled={!canComplete} onClick={() => onNext()}>
        Next
      </EuiButton>
    </TopicWorkflowAction>
  )
}
