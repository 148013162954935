export enum ServiceType {
  Installation = 'Installation',
  Repair = 'Repair'
}

export const enum ItemSupplier {
  Fallon = 'Fallon',
  Customer = 'Customer'
}

export const enum ItemInstaller {
  Fallon = 'Fallon',
  SomeoneElse = 'SomeoneElse'
}

export const enum YesNoValue {
  Yes = 'Yes',
  No = 'No'
}
