import { EuiIcon, EuiToolTip } from '@elastic/eui'
import { DataQualityStatus } from '../api/generated-types'
import { decamelise } from '../common/utils'
import '../static/css/data-quality-status-badge.css'

export interface DataQualityStatusBadgeProps {
  status: DataQualityStatus
  title?: string
  size?: 's' | 'l'
}

const statusToIcon = (status: DataQualityStatus): { type: string; color: string } => {
  switch (status) {
    case DataQualityStatus.Valid:
      return {
        type: 'checkInCircleFilled',
        color: '#34C759'
      }
    case DataQualityStatus.Invalid:
      return {
        type: 'warning',
        color: '#da9c40'
      }
    case DataQualityStatus.Error:
    case DataQualityStatus.Spam:
      return {
        type: 'error',
        color: '#e96236'
      }
    case DataQualityStatus.NotChecked:
    default:
      return {
        type: 'questionInCircle',
        color: 'grey'
      }
  }
}

export const DataQualityStatusBadge = ({ status, title, size }: DataQualityStatusBadgeProps) => {
  switch (size ?? 'l') {
    case 's':
      return (
        <EuiToolTip content={title ?? decamelise(status)}>
          <EuiIcon {...statusToIcon(status)} title={title ?? decamelise(status)} />
        </EuiToolTip>
      )
    default:
      return (
        <span className={`data-quality-status-badge data-quality-status-badge--${status.toLowerCase()}`} title={title}>
          {decamelise(status)}
        </span>
      )
  }
}
