import { TopicActionResult } from '../action-topic-model'
import { QuestionDefinitions, getNotesGenerator } from '../../helpers/notes-generator'

export interface TopicWaterPressureActionResult extends TopicActionResult {
  location?: string
  waterTemperature?: string
  isolatedIssue?: string
  additionalInformation?: string
}

const questionDefinitions: QuestionDefinitions<TopicWaterPressureActionResult> = {
  location: {
    question: 'Where in the house are you experiencing water pressure issues?',
    statement: 'Issue location:'
  },
  waterTemperature: {
    question: 'Is it effecting hot water, cold water or both?',
    statement: 'Affected water temperature:'
  },
  isolatedIssue: {
    question: 'Is it an isolated issue or whole house?',
    statement: 'Isolated or whole house:'
  },
  additionalInformation: {
    question: 'Any other information that might be useful for our tech? (optional)',
    statement: 'Additional information:'
  }
}

export const waterPressureNotesGenerator = getNotesGenerator(questionDefinitions)
