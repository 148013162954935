import { EuiButton, EuiSpacer } from '@elastic/eui'
import { map } from 'lodash-es'
import { EnquiryAbortReason } from '../../../api/generated-types'
import { Callout } from '../../../common/callout'
import { getResultUpdater } from '../../helpers/results-updater'
import { useWorkflow } from '../../helpers/workflow-provider'
import { SingleSelectField } from '../../question-fields/single-select-question-field'
import { WorkflowActionProps } from '../../workflow-model'
import { TopicActionInput } from '../action-topic-model'
import { TopicWorkflowAction } from '../action-topic-view'
import { TopicQualification } from '../common/common-outcome'
import { GutterBlocked } from './action-topic-gutter-blocked'
import { GutterGeneral } from './action-topic-gutter-general'
import { GutterGuard } from './action-topic-gutter-guard'
import { GutterIssue, PropertyLevels, TopicGutterActionResult, gutterNotesGenerator } from './action-topic-gutter-model'
import { outcomeGenerator } from './action-topic-gutter-outcome'
import { GutterReplace } from './action-topic-gutter-replace'

export const TopicGutterAction = (props: WorkflowActionProps<TopicActionInput, TopicGutterActionResult>) => {
  const workflowContext = useWorkflow()
  const { result, input, onUpdate } = props
  const { issue, propertyLevels } = result ?? {}

  const notesGenerator = gutterNotesGenerator

  const topicOutcome = outcomeGenerator(result)
  const { requirements, qualification } = topicOutcome
  const isOutOfScope = qualification === TopicQualification.OutOfScope

  const onNext = () =>
    onUpdate({
      ...result,
      ...topicOutcome,
      actionCompleted: true,

      workRequiredNotes: generatedNotes
    })

  const generatedNotes = notesGenerator.generateNotes(result ?? {})
  const updateResult = getResultUpdater(result ?? {}, onUpdate).updateResult

  const canComplete = !!requirements

  const renderIssue = (issue: GutterIssue) => {
    switch (issue) {
      case GutterIssue.Damage:
      case GutterIssue.Leaking:
      case GutterIssue.Rusty:
        return <GutterGeneral result={result} updateResult={updateResult} notesGenerator={notesGenerator} />
      case GutterIssue.Blocked:
      case GutterIssue.Clean:
        return <GutterBlocked result={result} updateResult={updateResult} notesGenerator={notesGenerator} />
      case GutterIssue.InstallGutterGuard:
        return <GutterGuard result={result} updateResult={updateResult} notesGenerator={notesGenerator} />
      case GutterIssue.Replacement:
        return <GutterReplace result={result} updateResult={updateResult} notesGenerator={notesGenerator} />
      default:
        return undefined
    }
  }

  return (
    <TopicWorkflowAction input={input} onUpdate={onUpdate} result={result}>
      <SingleSelectField
        question={notesGenerator.getQuestion('propertyLevels')}
        options={map(PropertyLevels, (id) => ({ id }))}
        answer={propertyLevels}
        changeAnswer={(propertyLevels) =>
          updateResult({
            propertyLevels,
            issue: undefined,
            size: undefined,
            gutterGuardLength: undefined,
            replacementSectionSize: undefined,
            downpipesBlocked: undefined,
            gutterCleanInScope: undefined
          })
        }
      />

      {propertyLevels === PropertyLevels.SingleStorey && (
        <>
          <SingleSelectField
            question={notesGenerator.getQuestion('issue')}
            options={map(GutterIssue, (id) => ({ id }))}
            answer={issue}
            changeAnswer={(issue) =>
              updateResult({
                issue,
                size: undefined,
                gutterGuardLength: undefined,
                replacementSectionSize: undefined,
                downpipesBlocked: undefined,
                gutterCleanInScope: undefined
              })
            }
          />
          {issue && renderIssue(issue)}
        </>
      )}

      {isOutOfScope && (
        <>
          <Callout type="script">Unfortunately this type of work is out of scope for us.</Callout>
          <EuiSpacer />
          <EuiButton
            color="warning"
            onClick={() =>
              workflowContext.abort({
                abortReason: EnquiryAbortReason.CustomerNeedsAreOutOfService,
                notes: `Customer was enquiring about Gutter: ${issue}`
              })
            }
          >
            Offer a voucher and end call
          </EuiButton>
        </>
      )}

      {requirements && (
        <>
          <EuiSpacer />
          <Callout type="note">{requirements.attributes?.map((r) => r.attributeId).join(', ')}</Callout>
        </>
      )}

      {!isOutOfScope && (
        <>
          <EuiSpacer />
          <EuiButton disabled={!canComplete} onClick={() => onNext()}>
            Next
          </EuiButton>
        </>
      )}
    </TopicWorkflowAction>
  )
}
