import { EuiFlexGroup, EuiFlexItem, EuiText } from '@elastic/eui'
import { getAttributes } from '@fallonsolutions/attribute'
import { TimeAllowed } from '@fallonsolutions/job'
import { getPriorities } from '@fallonsolutions/priority'
import { JobType } from '../../api/generated-types'
import { Callout } from '../../common/callout'
import { decamelise } from '../../common/utils'
import VerticalDivider from '../../common/vertical-divider'
import { TopicActionResult } from '../topics/action-topic-model'

const allAttributes = getAttributes()

export interface JobRequirementsPreviewProps {
  topic: TopicActionResult
  timeAllowed: TimeAllowed
}

export const JobRequirementsPreview = (props: JobRequirementsPreviewProps) => {
  const { topic, timeAllowed } = props
  const { requirements } = topic
  return (
    <Callout type="note">
      <EuiText>
        <EuiFlexGroup>
          <EuiFlexItem grow={false}>
            <dl style={{ marginBottom: 0 }}>
              <dt>Priority</dt>
              <dd>
                {getPriorities().find((p) => p.id === topic?.requirements?.priority?.required)?.label ?? 'Standard'}
              </dd>
            </dl>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <VerticalDivider height="48px" />
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <dl style={{ marginBottom: 0 }}>
              <dt>Time</dt>
              <dd>{timeAllowed.label}</dd>
            </dl>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <VerticalDivider height="48px" />
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <dl style={{ marginBottom: 0 }}>
              <dt>Customer</dt>
              <dd>{topic.customerType}</dd>
            </dl>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <dl style={{ marginBottom: 0 }}>
              <dt>Trade</dt>
              <dd>{topic?.trade}</dd>
            </dl>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <dl style={{ marginBottom: 0 }}>
              <dt>Type</dt>
              <dd>{topic.type === JobType.Callback ? 'Revisit' : topic.type}</dd>
            </dl>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <dl style={{ marginBottom: 0 }}>
              <dt>Category</dt>
              <dd>{decamelise(topic.category)}</dd>
            </dl>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <VerticalDivider height="48px" />
          </EuiFlexItem>
          <EuiFlexItem>
            <dl style={{ marginBottom: 0 }}>
              <dt>Skills</dt>
              <dd>
                {(requirements?.attributes ?? [])
                  .map((requiredAttribute) => {
                    const attribute = allAttributes.find((a) => a.id === requiredAttribute.attributeId)
                    return attribute?.name ?? decamelise(requiredAttribute.attributeId)
                  })
                  .join(', ')}
              </dd>
            </dl>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiText>
    </Callout>
  )
}
