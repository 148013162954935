import { EuiButton, EuiSpacer, EuiText } from '@elastic/eui'
import { EnquiryAbortReason } from '../../../api/generated-types'
import { Callout } from '../../../common/callout'
import { makeHumanReadable } from '../../../common/utils'
import { AustralianCompliant } from '../../action-topic-customer-supplied'
import { ContactRole } from '../../actions/action-customer-qualification'
import { CustomerSuppliedCompliant, IsItemAustralianCompliant } from '../../helpers/item-installer-questions'
import { QuestionDefinitions, getNotesGenerator } from '../../helpers/notes-generator'
import { OutOfScope } from '../../helpers/out-of-topic'
import { getResultUpdater } from '../../helpers/results-updater'
import { useWorkflow } from '../../helpers/workflow-provider'
import { FreeTextQuestionField } from '../../question-fields/free-text-question-field'
import { SingleSelectField } from '../../question-fields/single-select-question-field'
import { WorkflowActionProps } from '../../workflow-model'
import { ItemSupplier, TopicActionInput } from '../action-topic-model'
import { TopicWorkflowAction } from '../action-topic-view'
import { YesNoValue } from '../common/common-enums'
import { yesNoOptions } from '../common/common-options'
import { TopicQualification } from '../common/common-outcome'
import { reset } from '../common/common-utils'
import {
  PreExistingOrNewInstall,
  PumpLocation,
  PumpSymptom,
  TopicPlumbingPumpActionResult,
  pumpUsageOptions,
  renovatingOldHouseStyleOption
} from './action-topic-plumbing-pump-model'
import { outcomeGenerator } from './action-topic-plumbing-pump-requirements'
import { PumpUsageType } from './action-topic-pump-usage-model'

const questionDefinitions: QuestionDefinitions<TopicPlumbingPumpActionResult> = {
  symptom: {
    question: 'Is it blocked/leaking or tripping power or not working?',
    makeAnswerHumanReadable: makeHumanReadable
  },
  pumpUsage: {
    question: 'What is the pump used for?',
    makeAnswerHumanReadable: makeHumanReadable
  },
  proceedWithReplacement: {
    question: 'Would you like to proceed with a replacement?',
    statement: 'Proceed with replacement:'
  },
  itemIssue: {
    question: 'What are the issues?'
  },
  itemAge: {
    question: 'How old is the current pump?'
  },
  itemLocation: {
    question: 'Is the pump above ground or under water (submersible)?',
    makeAnswerHumanReadable: makeHumanReadable
  },
  existingItemFailed: {
    question: 'Do you have an existing pump that has failed?'
  },
  buildingUnderHouse: {
    question: 'Are you building under the house?'
  },
  renovatingOldHouseStyle: {
    question: 'Are you renovating an old Queenslander style of house?'
  },
  preExistingPumpOrNewInstall: {
    question: 'Is this pre-existing setup or a brand new install?',
    makeAnswerHumanReadable: makeHumanReadable
  },
  itemSupplier: {
    question: 'Fallon to supply or customer to supply?'
  },
  poolSize: {
    question: 'What size is your pool (in litres)?'
  },
  reasonForInstall: {
    question: 'Why are you wanting to have a pump installed?'
  },
  hasPowerTripped: {
    question: 'Has the power tripped (test GPO to see if elec issue)?',
    makeAnswerHumanReadable: makeHumanReadable
  },
  tripsWithOtherAppliances: {
    question:
      'Have you tried plugging something else in to the same power point? If so, does that still cause tripping?'
  },
  dsrConfirmed: {
    question: 'DSR confirmed this job is in scope?'
  },
  itemUsageNote: {
    question: 'What is the pump used for?'
  },
  hasThisHappenedInThePast: {
    question: 'Has this happened in the past?'
  }
}
const notesGenerator = getNotesGenerator(questionDefinitions)

export const TopicPlumbingPumpAction = (
  props: WorkflowActionProps<TopicActionInput, TopicPlumbingPumpActionResult>
) => {
  const workflowContext = useWorkflow()
  const { result, input, onUpdate } = props
  const {
    jobDescription,
    customerType,
    trade,
    category,
    type,
    symptom,
    pumpUsage,
    proceedWithReplacement,
    itemAge,
    itemIssue,
    itemLocation,
    itemUsageNote,
    buildingUnderHouse,
    renovatingOldHouseStyle,
    hasThisHappenedInThePast,
    preExistingPumpOrNewInstall,
    itemSupplier,
    tripsWithOtherAppliances,
    existingItemFailed,
    reasonForInstall,
    hasPowerTripped,
    dsrConfirmed,
    poolSize,
    australianCompliant
  } = result ?? {}
  const { jobIsCompliant, jobIsNotCompliant } = CustomerSuppliedCompliant({ australianCompliant })

  const topicOutcome = outcomeGenerator(result)
  const { requirements } = topicOutcome
  const isOutOfScope = topicOutcome.qualification === TopicQualification.OutOfScope
  const canComplete = topicOutcome.qualification === TopicQualification.InScope
  const newTopic = topicOutcome.newTopic

  const onNext = () =>
    onUpdate({
      ...result,
      ...topicOutcome,
      actionCompleted: true,

      workRequiredNotes: generatedNotes,
      requirements
    })

  const generatedNotes = notesGenerator.generateNotes(result ?? {})
  const updateResult = getResultUpdater(result ?? {}, onUpdate).updateResult

  const isCommercial = input.customerContactRole === ContactRole.Commercial

  const canContinue = !!pumpUsage && !!symptom

  const blockedOrLeaking = !!symptom && [PumpSymptom.Blocked, PumpSymptom.Leaking].includes(symptom)
  const generalPump =
    !!pumpUsage &&
    [PumpUsageType.Rainwater, PumpUsageType.Stormwater, PumpUsageType.Sump, PumpUsageType.Rainbank].includes(pumpUsage)

  return (
    <TopicWorkflowAction input={input} onUpdate={onUpdate} result={result}>
      <SingleSelectField
        question={notesGenerator.getQuestion('pumpUsage')}
        options={pumpUsageOptions}
        answer={pumpUsage}
        changeAnswer={(pumpUsage) =>
          updateResult({
            ...reset(result),
            customerType,
            jobDescription,
            trade,
            type,
            category,
            pumpUsage
          })
        }
      />

      {!!pumpUsage && (
        <SingleSelectField
          question={notesGenerator.getQuestion('symptom')}
          options={[
            {
              id: PumpSymptom.Blocked,
              label: 'Blocked',
              icon: 'wrench'
            },
            {
              id: PumpSymptom.Leaking,
              label: 'Leaking',
              icon: 'tear'
            },
            {
              id: PumpSymptom.NotWorking,
              label: 'NotWorking',
              icon: 'cross'
            },
            {
              id: PumpSymptom.TrippingPower,
              label: 'TrippingPower',
              icon: 'bolt'
            }
          ]}
          answer={symptom}
          changeAnswer={(symptom) =>
            updateResult({
              ...reset(result ?? {}),
              symptom,
              pumpUsage,
              jobDescription,
              customerType,
              trade,
              type,
              category
            })
          }
        />
      )}

      {blockedOrLeaking && (
        <>
          {generalPump ? (
            <>
              <Callout type="script" className="callout--flat-list">
                <EuiText>
                  <ul>
                    <li>
                      <strong>Unfortunately we do not offer repairs on {pumpUsage} pumps.</strong>
                    </li>
                    <li>It is more cost effective to replace them as you get an upgrade and warranty.</li>
                  </ul>
                </EuiText>
              </Callout>

              <SingleSelectField
                question={notesGenerator.getQuestion('proceedWithReplacement')}
                options={[
                  {
                    id: YesNoValue.Yes,
                    label: 'Yes, proceed with replacement'
                  },
                  {
                    id: YesNoValue.No,
                    label: 'No'
                  }
                ]}
                answer={proceedWithReplacement}
                changeAnswer={(proceedWithReplacement) => updateResult({ proceedWithReplacement })}
              />
              {proceedWithReplacement === YesNoValue.Yes && (
                <>
                  <SingleSelectField
                    question={notesGenerator.getQuestion('itemLocation')}
                    options={[
                      {
                        id: PumpLocation.AboveGround,
                        label: 'Above ground'
                      },
                      {
                        id: PumpLocation.Submersible,
                        label: 'Submersible'
                      }
                    ]}
                    answer={itemLocation}
                    changeAnswer={(itemLocation) => updateResult({ itemLocation })}
                  />

                  <FreeTextQuestionField
                    question={notesGenerator.getQuestion('itemIssue')}
                    rows={3}
                    answer={itemIssue}
                    changeAnswer={(itemIssue) => updateResult({ itemIssue })}
                  />
                  <FreeTextQuestionField
                    question={notesGenerator.getQuestion('itemAge')}
                    answer={itemAge}
                    changeAnswer={(itemAge) => updateResult({ itemAge })}
                  />
                </>
              )}
            </>
          ) : (
            <>
              {pumpUsage && (pumpUsage === PumpUsageType.Sani || pumpUsage === PumpUsageType.Macerator) && (
                <>
                  <SingleSelectField
                    question={notesGenerator.getQuestion('existingItemFailed')}
                    options={yesNoOptions}
                    answer={existingItemFailed}
                    changeAnswer={(existingItemFailed) => updateResult({ existingItemFailed })}
                  />
                  <FreeTextQuestionField
                    question={notesGenerator.getQuestion('reasonForInstall')}
                    answer={reasonForInstall}
                    changeAnswer={(reasonForInstall) => updateResult({ reasonForInstall })}
                  />
                  <SingleSelectField
                    question={notesGenerator.getQuestion('buildingUnderHouse')}
                    options={yesNoOptions}
                    answer={buildingUnderHouse}
                    changeAnswer={(buildingUnderHouse) => updateResult({ buildingUnderHouse })}
                  />
                  <SingleSelectField
                    question={notesGenerator.getQuestion('renovatingOldHouseStyle')}
                    options={renovatingOldHouseStyleOption}
                    answer={renovatingOldHouseStyle}
                    changeAnswer={(renovatingOldHouseStyle) => updateResult({ renovatingOldHouseStyle })}
                  />
                </>
              )}
              {pumpUsage && pumpUsage === PumpUsageType.Pool && (
                <>
                  <EuiSpacer />
                  <SingleSelectField
                    question={notesGenerator.getQuestion('preExistingPumpOrNewInstall')}
                    options={[
                      {
                        id: PreExistingOrNewInstall.PreExisting,
                        label: 'Pre existing'
                      },
                      {
                        id: PreExistingOrNewInstall.NewInstall,
                        label: 'New Install'
                      }
                    ]}
                    answer={preExistingPumpOrNewInstall}
                    changeAnswer={(preExistingPumpOrNewInstall) =>
                      updateResult({ preExistingPumpOrNewInstall, australianCompliant: AustralianCompliant.Unknown })
                    }
                  />
                  <EuiSpacer />
                  {!!preExistingPumpOrNewInstall &&
                    preExistingPumpOrNewInstall === PreExistingOrNewInstall.NewInstall && (
                      <>
                        <SingleSelectField
                          question={notesGenerator.getQuestion('itemSupplier')}
                          options={[
                            {
                              id: ItemSupplier.Fallon,
                              label: 'Fallon',
                              icon: 'check'
                            },
                            {
                              id: ItemSupplier.Customer,
                              label: 'Customer',
                              icon: 'user'
                            }
                          ]}
                          answer={itemSupplier}
                          changeAnswer={(itemSupplier) =>
                            updateResult({
                              itemSupplier
                            })
                          }
                        />
                        <IsItemAustralianCompliant
                          updateResult={updateResult}
                          itemSupplier={itemSupplier}
                          suppliedAnswer={australianCompliant}
                        />
                        <EuiSpacer />
                      </>
                    )}
                  {jobIsNotCompliant ? (
                    <OutOfScope issue={'Pumps'} isOutOfScope={true} />
                  ) : (
                    <>
                      <FreeTextQuestionField
                        question={notesGenerator.getQuestion('poolSize')}
                        answer={poolSize}
                        changeAnswer={(poolSize) => {
                          updateResult({ poolSize })
                        }}
                      />
                    </>
                  )}
                </>
              )}
              {pumpUsage && pumpUsage === PumpUsageType.Greywater && (
                <>
                  <FreeTextQuestionField
                    question={notesGenerator.getQuestion('reasonForInstall')}
                    answer={reasonForInstall}
                    changeAnswer={(reasonForInstall) => {
                      updateResult({ reasonForInstall })
                    }}
                    rows={3}
                  />
                  <EuiSpacer />
                  <SingleSelectField
                    question={notesGenerator.getQuestion('hasPowerTripped')}
                    options={yesNoOptions}
                    answer={hasPowerTripped}
                    changeAnswer={(hasPowerTripped) =>
                      hasPowerTripped === YesNoValue.No
                        ? updateResult({ hasPowerTripped })
                        : updateResult({ hasPowerTripped, symptom: PumpSymptom.TrippingPower })
                    }
                  />
                  <EuiSpacer />
                  <FreeTextQuestionField
                    question={notesGenerator.getQuestion('itemAge')}
                    answer={itemAge}
                    changeAnswer={(itemAge) => {
                      updateResult({ itemAge })
                    }}
                  />

                  {hasPowerTripped === YesNoValue.Yes && (
                    <Callout type="warning" title="Please select the Tripping power type." />
                  )}
                </>
              )}
              <EuiSpacer />
            </>
          )}
        </>
      )}

      {(symptom === PumpSymptom.TrippingPower || symptom === PumpSymptom.NotWorking) && (
        <>
          <SingleSelectField
            question={notesGenerator.getQuestion('tripsWithOtherAppliances')}
            options={[
              {
                id: YesNoValue.Yes,
                label: 'Yes',
                icon: 'check'
              },
              {
                id: YesNoValue.No,
                label: 'No',
                icon: 'cross'
              }
            ]}
            answer={tripsWithOtherAppliances}
            changeAnswer={(tripsWithOtherAppliances) => updateResult({ tripsWithOtherAppliances })}
          />

          {tripsWithOtherAppliances === YesNoValue.No && (
            <>
              <FreeTextQuestionField
                question={notesGenerator.getQuestion('itemUsageNote')}
                answer={itemUsageNote}
                changeAnswer={(itemUsageNote) => {
                  updateResult({ itemUsageNote })
                }}
              />
              <EuiSpacer />
              <FreeTextQuestionField
                question={notesGenerator.getQuestion('itemAge')}
                answer={itemAge}
                changeAnswer={(itemAge) => {
                  updateResult({ itemAge })
                }}
              />
              <EuiSpacer />
              <SingleSelectField
                question={notesGenerator.getQuestion('hasThisHappenedInThePast')}
                answer={hasThisHappenedInThePast}
                changeAnswer={(hasThisHappenedInThePast) =>
                  updateResult({
                    hasThisHappenedInThePast
                  })
                }
                options={yesNoOptions}
              />
            </>
          )}
        </>
      )}

      <EuiSpacer />

      {canContinue && (
        <>
          {isCommercial && (
            <>
              <Callout type="note" title="Check answers with DSR to determine if in scope" />
              <SingleSelectField
                question={notesGenerator.getQuestion('dsrConfirmed')}
                options={yesNoOptions}
                answer={dsrConfirmed}
                changeAnswer={(dsrConfirmed) => updateResult({ dsrConfirmed })}
              />
            </>
          )}
        </>
      )}
      <EuiSpacer />
      {newTopic && (
        <EuiButton onClick={() => workflowContext.changeJobTopic(newTopic.reference)}>{newTopic.buttonLabel}</EuiButton>
      )}
      {isOutOfScope && !newTopic && (
        <>
          <Callout type="script">
            {topicOutcome.qualificationMessage
              ? topicOutcome.qualificationMessage
              : 'Unfortunately this type of work is out of scope for us.'}
          </Callout>
          <EuiSpacer />
          <EuiButton
            color="warning"
            onClick={() =>
              workflowContext.abort({
                abortReason: EnquiryAbortReason.CustomerNeedsAreOutOfService,
                notes: `Customer was enquiring about: ${topicOutcome.qualificationMessage}`
              })
            }
          >
            Offer a voucher and end call
          </EuiButton>
        </>
      )}

      {!isOutOfScope && !newTopic && (
        <EuiButton disabled={!canComplete} onClick={() => onNext()}>
          Next
        </EuiButton>
      )}
    </TopicWorkflowAction>
  )
}
