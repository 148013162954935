import { useQuery } from '@apollo/client'
import { EuiBasicTable, EuiCallOut, EuiLoadingSpinner } from '@elastic/eui'
import { Fragment } from 'react'
import { SearchInteractionMessagesDocument } from '../api/generated-types'
import { InteractionMessageListColumns } from '../interactions/interaction-message-list/interaction-message-list-columns'

interface ContactInteractionMessagesProps {
  contactId: string
}

export const ContactInteractionMessages = ({ contactId }: ContactInteractionMessagesProps) => {
  const columns = InteractionMessageListColumns({})

  const { data, loading, error } = useQuery(SearchInteractionMessagesDocument, {
    variables: {
      input: {
        filter: {
          contactId: [contactId]
        }
      }
    }
  })
  const interactionMessages = data?.searchInteractionMessages?.results ?? []

  return (
    <Fragment>
      {loading ? (
        <EuiLoadingSpinner />
      ) : error ? (
        <EuiCallOut title="Error loading calls" color="danger">
          {error.message}
        </EuiCallOut>
      ) : interactionMessages ? (
        <EuiBasicTable items={interactionMessages} columns={columns} />
      ) : (
        <EuiCallOut title="Error loading calls" color="danger" />
      )}
    </Fragment>
  )
}
