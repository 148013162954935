import { EuiButton, EuiSpacer } from '@elastic/eui'
import { EnquiryAbortReason, JobType, TradeType } from '../../../api/generated-types'
import { Callout } from '../../../common/callout'
import { decameliseIfNotNil, makeHumanReadable } from '../../../common/utils'
import { CustomerSuppliedTopicActionResult } from '../../action-topic-customer-supplied'
import { CustomerSuppliedCompliant, IsItemAustralianCompliant } from '../../helpers/item-installer-questions'
import { QuestionDefinitions, getNotesGenerator } from '../../helpers/notes-generator'
import { OutOfScope } from '../../helpers/out-of-topic'
import { getResultUpdater } from '../../helpers/results-updater'
import { useWorkflow } from '../../helpers/workflow-provider'
import { FreeTextQuestionField } from '../../question-fields/free-text-question-field'
import { SingleSelectField } from '../../question-fields/single-select-question-field'
import { WorkflowActionProps } from '../../workflow-model'
import { ItemSupplier, TopicActionInput } from '../action-topic-model'
import { TopicWorkflowAction } from '../action-topic-view'
import { ItemInstaller, ServiceType } from '../common/common-enums'
import { reset } from '../common/common-utils'

export enum InstallationType {
  New = 'New',
  Upgrade = 'Upgrade'
}

export enum HomeAutomationSystem {
  SmartSwitches = 'SmartSwitches',
  HomeAutomation = 'HomeAutomation',
  CBUS = 'CBUS'
}

export enum InstallDate {
  LessThanOrEqualTo12Months = 'Within last 12 months',
  Over12Months = 'Over 12 months ago'
}

export interface TopicHomeAutomationActionResult extends CustomerSuppliedTopicActionResult {
  serviceType?: ServiceType

  // install
  itemSupplier?: ItemSupplier
  installationType?: InstallationType
  requiredWorkDetails?: string

  // repair
  itemInstaller?: ItemInstaller
  installDate?: InstallDate
  currentAutomationSystem?: HomeAutomationSystem
  faultDetails?: string
}

const questionDefinitions: QuestionDefinitions<TopicHomeAutomationActionResult> = {
  serviceType: {
    question: 'Is that an installation or a repair?',
    statement: 'Service type:',
    makeAnswerHumanReadable: decameliseIfNotNil
  },

  // install
  itemSupplier: {
    question: 'Who is supplying the equipment?',
    statement: 'Equipment supplied by:',
    makeAnswerHumanReadable: decameliseIfNotNil
  },
  installationType: {
    question: 'Are you installing brand new or upgrading?',
    statement: 'Installation type:',
    makeAnswerHumanReadable: decameliseIfNotNil
  },
  requiredWorkDetails: {
    question: 'What work did you want completed?',
    statement: 'Work to be completed:'
  },

  // repair
  itemInstaller: {
    question: 'Who did the installation?',
    statement: 'Installation done by:',
    makeAnswerHumanReadable: decameliseIfNotNil
  },
  installDate: {
    question: 'When did Fallon install?',
    statement: 'Install date:',
    makeAnswerHumanReadable: makeHumanReadable
  },
  currentAutomationSystem: {
    question: 'What is the type of the current automation?',
    statement: 'Existing automation system:',
    makeAnswerHumanReadable: decameliseIfNotNil
  },
  faultDetails: {
    question: 'What is the fault?',
    statement: 'Fault details:'
  }
}
const notesGenerator = getNotesGenerator(questionDefinitions)

export const TopicHomeAutomationAction = (
  props: WorkflowActionProps<TopicActionInput, TopicHomeAutomationActionResult>
) => {
  const workflowContext = useWorkflow()
  const { result, input, onUpdate } = props
  const {
    serviceType,
    customerType,
    // install
    itemSupplier,
    installationType,
    requiredWorkDetails,
    // repair
    itemInstaller,
    installDate,
    currentAutomationSystem,
    faultDetails,
    australianCompliant,
    jobDescription
  } = result ?? {}

  const { jobIsCompliant, jobIsNotCompliant } = CustomerSuppliedCompliant({ australianCompliant })

  const getJobType = () => {
    if (serviceType === ServiceType.Repair) {
      return itemInstaller === ItemInstaller.Fallon && installDate === InstallDate.LessThanOrEqualTo12Months
        ? JobType.Callback
        : JobType.Service
    } else {
      return JobType.Service
    }
  }

  const onNext = () =>
    onUpdate({
      ...result,
      actionCompleted: true,
      trade: TradeType.Security,
      category: 'HomeAutomation',
      type: getJobType(),

      workRequiredNotes: generatedNotes,
      requirements: {
        attributes: [{ attributeId: 'Automation' }]
      }
    })

  const generatedNotes = notesGenerator.generateNotes(result ?? {})
  const updateResult = getResultUpdater(result ?? {}, onUpdate).updateResult

  const canComplete =
    (serviceType === ServiceType.Installation &&
      !!itemSupplier &&
      !!installationType &&
      !!requiredWorkDetails &&
      jobIsCompliant) ||
    (serviceType === ServiceType.Repair &&
      !!itemInstaller &&
      !!currentAutomationSystem &&
      !!faultDetails &&
      currentAutomationSystem !== HomeAutomationSystem.CBUS)

  const cannotAssist =
    serviceType === ServiceType.Repair &&
    itemInstaller === ItemInstaller.SomeoneElse &&
    currentAutomationSystem === HomeAutomationSystem.CBUS

  return (
    <TopicWorkflowAction input={input} onUpdate={onUpdate} result={result}>
      <SingleSelectField
        question={notesGenerator.getQuestion('serviceType')}
        options={[
          {
            id: ServiceType.Installation,
            label: 'Installation',
            icon: 'check'
          },
          {
            id: ServiceType.Repair,
            label: 'Repair',
            icon: 'wrench'
          }
        ]}
        answer={serviceType}
        changeAnswer={(serviceType) =>
          updateResult({
            ...reset(result ?? {}),
            customerType,
            serviceType,
            jobDescription
            // install
          })
        }
      />

      {serviceType === ServiceType.Installation && (
        <>
          <SingleSelectField
            question={notesGenerator.getQuestion('itemSupplier')}
            options={[
              {
                id: ItemSupplier.Fallon,
                label: 'Fallon',
                icon: 'check'
              },
              {
                id: ItemSupplier.Customer,
                label: 'Customer',
                icon: 'user'
              }
            ]}
            answer={itemSupplier}
            changeAnswer={(itemSupplier) =>
              updateResult({
                itemSupplier,
                itemInstaller: undefined,
                currentAutomationSystem: undefined,
                faultDetails: undefined
              })
            }
          />
          <IsItemAustralianCompliant
            updateResult={updateResult}
            itemSupplier={itemSupplier}
            suppliedAnswer={australianCompliant}
          />
          {jobIsNotCompliant ? (
            <OutOfScope issue={'Ceiling Fans'} isOutOfScope={true} />
          ) : (
            <>
              <SingleSelectField
                question={notesGenerator.getQuestion('installationType')}
                options={[
                  {
                    id: InstallationType.New,
                    label: 'New',
                    icon: 'cheer'
                  },
                  {
                    id: InstallationType.Upgrade,
                    label: 'Upgrade',
                    icon: 'stats'
                  }
                ]}
                answer={installationType}
                changeAnswer={(installationType) => updateResult({ installationType })}
              />
              <FreeTextQuestionField
                question={notesGenerator.getQuestion('requiredWorkDetails')}
                answer={requiredWorkDetails}
                changeAnswer={(requiredWorkDetails) => updateResult({ requiredWorkDetails })}
              />
            </>
          )}
        </>
      )}

      {serviceType === ServiceType.Repair && (
        <>
          <SingleSelectField
            question={notesGenerator.getQuestion('itemInstaller')}
            options={[
              {
                id: ItemInstaller.Fallon,
                label: 'Fallon installed',
                icon: 'check'
              },
              {
                id: ItemInstaller.SomeoneElse,
                label: 'Someone else installed',
                icon: 'user'
              }
            ]}
            answer={itemInstaller}
            changeAnswer={(itemInstaller) =>
              updateResult({
                itemInstaller,
                itemSupplier: undefined,
                installationType: undefined,
                requiredWorkDetails: undefined
              })
            }
          />
          <SingleSelectField
            question={notesGenerator.getQuestion('currentAutomationSystem')}
            options={[
              {
                id: HomeAutomationSystem.SmartSwitches,
                label: 'Smart Switches',
                icon: 'bullseye'
              },
              {
                id: HomeAutomationSystem.HomeAutomation,
                label: 'Home Automation',
                icon: 'home'
              },
              {
                id: HomeAutomationSystem.CBUS,
                label: 'CBUS',
                icon: 'grid'
              }
            ]}
            answer={currentAutomationSystem}
            changeAnswer={(currentAutomationSystem) => updateResult({ currentAutomationSystem })}
          />
          {!cannotAssist && (
            <FreeTextQuestionField
              question={notesGenerator.getQuestion('faultDetails')}
              answer={faultDetails}
              changeAnswer={(faultDetails) => updateResult({ faultDetails })}
            />
          )}
        </>
      )}

      {cannotAssist && (
        <>
          <Callout
            type="script"
            title="I do apologise but unfortunately this is currently out of scope and we wont be able to assist."
          />
          <EuiSpacer />
          <EuiButton
            color="warning"
            onClick={() =>
              workflowContext.abort({
                abortReason: EnquiryAbortReason.CustomerNeedsAreOutOfService,
                notes: 'Customer wanted us to repair a CBUS system.'
              })
            }
          >
            End call
          </EuiButton>
        </>
      )}

      {/* TODO: This looks like a very common question asked during almost every job booking
      <Callout type="note" title="Is it double or single story house?" /> */}

      {serviceType === ServiceType.Repair && itemInstaller === ItemInstaller.Fallon && (
        <>
          <SingleSelectField
            question={notesGenerator.getQuestion('installDate')}
            options={[
              {
                id: InstallDate.LessThanOrEqualTo12Months,
                label: 'Within last 12 months'
              },
              {
                id: InstallDate.Over12Months,
                label: 'Over 12 months ago'
              }
            ]}
            answer={installDate}
            changeAnswer={(installDate) => updateResult({ installDate })}
          />
          {itemInstaller === ItemInstaller.Fallon && !!installDate && (
            <>
              <Callout
                type="note"
                title={
                  installDate === InstallDate.LessThanOrEqualTo12Months
                    ? 'Revisit with no travel fee'
                    : 'Normal service job with travel fee'
                }
              />
              <EuiSpacer />
            </>
          )}

          {installDate === InstallDate.LessThanOrEqualTo12Months && (
            <Callout type="note">
              <ul>
                <li>
                  Book same technician as the original Job and send work chat to Electrical team or email Electrical DSR
                  Team
                </li>
                <li>If time not suitable or technician not available call Electrical DSR 99973</li>
              </ul>
            </Callout>
          )}
        </>
      )}

      {canComplete && (
        <>
          <EuiSpacer />
          <EuiButton onClick={() => onNext()}>Next</EuiButton>
        </>
      )}
    </TopicWorkflowAction>
  )
}
