import { TradeType } from '../api/generated-types'
import { EuiCallOut, EuiHealth, EuiLoadingSpinner, EuiSpacer, EuiText, EuiTitle } from '@elastic/eui'
import { includes } from 'lodash-es'
import { decamelise } from '../common/utils'
import { Callout } from '../common/callout'
import { GetServiceArea } from '../workflow/helpers/service-area'
import { useEffect } from 'react'
import { ServiceAreaServiceCheckStatus } from '../common/service-area-service-check'

interface ServiceAreaServiceCheckProps {
  companyId: string
  suburbId: string
  trade: TradeType
  onChangeServiceAreaServiceCheckStatus?: (status: ServiceAreaServiceCheckStatus) => void
}

export const ServiceAreaServiceCheck = (props: ServiceAreaServiceCheckProps) => {
  const { suburbId, trade, companyId, onChangeServiceAreaServiceCheckStatus } = props

  const { serviceArea, loading, error } = GetServiceArea({ companyId, suburbId })

  const serviced = includes(serviceArea?.trades ?? [], trade)

  useEffect(() => {
    const isServiced = serviced
      ? ServiceAreaServiceCheckStatus.WithinScope
      : ServiceAreaServiceCheckStatus.NotWithinScope
    onChangeServiceAreaServiceCheckStatus?.(isServiced)
  }, [serviced])
  const companyPhrase = companyId === 'fallonsolutions' ? '' : ` for ${companyId}`

  return (
    <>
      <EuiTitle size="xs">
        <h3>Service area check{companyPhrase}</h3>
      </EuiTitle>
      <EuiSpacer size="m" />
      {loading ? (
        <EuiLoadingSpinner />
      ) : error ? (
        <EuiCallOut color="danger" title="Error checking if suburb is in service area" />
      ) : (
        <>
          <EuiHealth color={serviced ? 'success' : 'danger'}>
            <div>
              {serviceArea?.name} is {serviced ? '' : <strong>NOT</strong>} within service area for {decamelise(trade)}
            </div>
          </EuiHealth>
          {!serviced && (
            <>
              <EuiSpacer />
              <Callout
                type="script"
                title={`Just to clarify, the suburb that you need us to come out to is ${serviceArea?.name} correct?`}
              >
                <EuiText>Unfortunately we currently don’t service that area.</EuiText>
              </Callout>
              <Callout type="warning" title="Out of scope (service area)" />
            </>
          )}
        </>
      )}
      <EuiSpacer />
    </>
  )
}
