import { EuiButton, EuiText } from '@elastic/eui'
import { JobTopicType } from '@fallonsolutions/types'
import { EnquiryAbortReason } from '../../../api/generated-types'
import { Callout } from '../../../common/callout'
import { makeHumanReadable } from '../../../common/utils'
import { QuestionDefinitions, getNotesGenerator } from '../../helpers/notes-generator'
import { getResultUpdater } from '../../helpers/results-updater'
import { useWorkflow } from '../../helpers/workflow-provider'
import { SingleSelectField } from '../../question-fields/single-select-question-field'
import { WorkflowActionProps } from '../../workflow-model'
import { TopicActionInput, TopicActionResult } from '../action-topic-model'
import { TopicWorkflowAction } from '../action-topic-view'
import { YesNoValue } from '../common/common-enums'

export interface TopicPlumbingBidetRepairActionResult extends TopicActionResult {
  newInstallWanted?: YesNoValue
}

const questionDefinitions: QuestionDefinitions<TopicPlumbingBidetRepairActionResult> = {
  newInstallWanted: {
    question: 'Would you like me to book in a technician to come out and arrange this?',
    makeAnswerHumanReadable: makeHumanReadable
  }
}
const notesGenerator = getNotesGenerator(questionDefinitions)

export const TopicPlumbingBidetRepairAction = (
  props: WorkflowActionProps<TopicActionInput, TopicPlumbingBidetRepairActionResult>
) => {
  const workflowContext = useWorkflow()
  const { result, input, onUpdate } = props
  const { newInstallWanted } = result ?? {}

  const updateResult = getResultUpdater(result ?? {}, onUpdate).updateResult

  return (
    <TopicWorkflowAction input={{ ...input, showJobDescription: false }} onUpdate={onUpdate} result={result}>
      <Callout type="script" className="callout--flat-list">
        <EuiText>
          <ul>
            <li>
              <strong>Unfortunately we do not repair Bidets.</strong>
            </li>
            <li>It is more cost effective to replace them as you get an upgrade and a warranty.</li>
          </ul>
        </EuiText>
      </Callout>
      <SingleSelectField
        question={notesGenerator.getQuestion('newInstallWanted')}
        options={[
          {
            id: YesNoValue.Yes,
            label: 'Yes'
          },
          {
            id: YesNoValue.No,
            label: 'No'
          }
        ]}
        answer={newInstallWanted}
        changeAnswer={(newInstallWanted) => updateResult({ newInstallWanted })}
      />

      {newInstallWanted === YesNoValue.Yes && (
        <>
          <EuiButton onClick={() => workflowContext.changeJobTopic(JobTopicType.BidetInstall)}>
            Switch topic to Bidet installation
          </EuiButton>
        </>
      )}
      {newInstallWanted === YesNoValue.No && (
        <>
          <Callout type="script">
            <EuiText>
              Sorry, we cannot help in this case. However, we can offer a $40 voucher for the next job (ask for email
              address)
            </EuiText>
          </Callout>
          <EuiButton
            color="warning"
            onClick={() =>
              workflowContext.abort({
                abortReason: EnquiryAbortReason.CustomerNeedsAreOutOfService,
                notes: 'Customer wanted to repair a bidet which was installed by someone else than Fallon'
              })
            }
          >
            Offer a voucher and end call
          </EuiButton>
        </>
      )}
    </TopicWorkflowAction>
  )
}
