import { EuiButton, EuiFormRow, EuiSpacer } from '@elastic/eui'
import { makeHumanReadable } from '@falloncore/util'
import { AttributeDetailFragment, CustomerType, JobType, TradeType } from '@fallonsolutions/types'
import { includes } from 'lodash-es'
import { useEffect, useState } from 'react'
import { ServiceAreaTradeCheck } from '../../common/service-area-service-check'
import { JobCategorySelector } from '../../jobs/job-category-selector'
import { getTopicName } from '../get-topic-name'
import { JobAttributeSelector } from '../job-attributes-selector'
import { TopicActionResult } from '../topics/action-topic-model'
import { TopicWorkflowAction } from '../topics/action-topic-view'
import { WorkflowActionProps } from '../workflow-model'
import { ContactRole } from './action-customer-qualification'
import { JobClassificationActionInput, JobClassificationActionResult } from './action-job-classification-types'

export const JobClassificationActionFastBooking = (
  props: WorkflowActionProps<JobClassificationActionInput, JobClassificationActionResult>
) => {
  const { input, result, onUpdate } = props

  const setTopic = (topic: TopicActionResult | undefined) => {
    const { customerType, trade, type, category, actionCompleted, requirements } = topic ?? {}
    const value =
      actionCompleted === true ? `${customerType} | ${trade} | ${makeHumanReadable(category)} | ${type}` : undefined
    const updatedTopic = { ...topic, value }
    onUpdate({
      ...result,
      topic: updatedTopic,
      customerType,
      trade,
      type,
      category,
      requirements,
      actionCompleted,
      value
    })
  }

  useEffect(() => {
    const customerType = includes(
      [ContactRole.Commercial, ContactRole.Tenant, ContactRole.RealEstateAgent, ContactRole.Other],
      input.customerContactRole
    )
      ? CustomerType.Commercial
      : CustomerType.Domestic
    if (customerType !== result?.customerType) {
      setTopic({ ...result, customerType })
    }
  }, [input.customerContactRole])

  const property = input.property

  const [customerType, setCustomerType] = useState<CustomerType | undefined>(
    input.customerContactRole === ContactRole.HomeOwner ? CustomerType.Domestic : CustomerType.Commercial
  )
  const [trade, setTrade] = useState<TradeType | undefined>(undefined)
  const [type, setType] = useState<JobType | undefined>(undefined)
  const [category, setCategory] = useState<string | undefined>(undefined)

  const [attributes, setAttributes] = useState<AttributeDetailFragment[]>([])

  const companyId = 'fallonsolutions'

  const canContinue = trade && type && category
  const showNextButton = trade && type && category

  const handleNextStep = () => {
    if (canContinue) {
      setTopic({
        ...result,
        customerType,
        trade,
        type,
        category,
        requirements: {
          attributes: attributes.map((attribute) => ({
            attributeId: attribute.id
          }))
        },
        actionCompleted: true
      })
    }
  }
  // onClick of done, update the job classification result with the above details
  return (
    <TopicWorkflowAction
      input={{ ...input, topicName: getTopicName(input.jobTopic.reference), showJobDescriptionFollowup: false }}
      onUpdate={onUpdate}
      result={result}
    >
      <JobCategorySelector
        customerType={customerType}
        trade={trade}
        type={type}
        category={category}
        clearCustomerType={() => setCustomerType(undefined)}
        clearTrade={() => setTrade(undefined)}
        clearType={() => setType(undefined)}
        clearCategory={() => setCategory(undefined)}
        setCustomerType={setCustomerType}
        setTrade={(trade) => {
          setTrade(trade)
          setCategory(undefined)
        }}
        setType={setType}
        setCategory={setCategory}
      />
      <EuiSpacer size="m" />
      {customerType && trade && type && category && property && (
        <>
          <ServiceAreaTradeCheck
            property={property}
            companyId={companyId}
            trade={trade}
            onChangeServiceAreaScope={() => ({})}
          />
        </>
      )}
      <EuiSpacer size="m" />
      <EuiFormRow label="Required attributes/skills">
        <JobAttributeSelector attributes={attributes} setSelectedAttributes={setAttributes} />
      </EuiFormRow>
      <EuiSpacer size="l" />
      {showNextButton && (
        <EuiButton fill onClick={handleNextStep} disabled={!canContinue}>
          Done
        </EuiButton>
      )}
    </TopicWorkflowAction>
  )
}
