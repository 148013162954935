import { EuiButton, EuiSpacer } from '@elastic/eui'
import { JobType, PriorityType, TradeType } from '../../../api/generated-types'
import { Callout } from '../../../common/callout'
import { QuestionDefinitions, getNotesGenerator } from '../../helpers/notes-generator'
import { getResultUpdater } from '../../helpers/results-updater'
import { SingleSelectField } from '../../question-fields/single-select-question-field'
import { WorkflowActionProps } from '../../workflow-model'
import { TopicActionInput, TopicActionResult } from '../action-topic-model'
import { TopicWorkflowAction } from '../action-topic-view'
import { YesNoValue } from '../common/common-enums'
import { yesNoOptions } from '../common/common-options'

export interface TopicPlumbingBurstWaterMainsActionResult extends TopicActionResult {
  canIsolateWater?: YesNoValue
}

const questionDefinitions: QuestionDefinitions<TopicPlumbingBurstWaterMainsActionResult> = {
  canIsolateWater: {
    question: 'Are you able to isolate the mains or turn off taps?'
  }
}
const notesGenerator = getNotesGenerator(questionDefinitions)

export const TopicPlumbingBurstWaterMainsAction = (
  props: WorkflowActionProps<TopicActionInput, TopicPlumbingBurstWaterMainsActionResult>
) => {
  const { result, input, onUpdate } = props
  const { canIsolateWater } = result ?? {}

  const onNext = () =>
    onUpdate({
      ...result,
      actionCompleted: true,
      trade: TradeType.Plumbing,
      category: 'WaterMains',
      type: JobType.Service,
      workRequiredNotes: generatedNotes,
      requirements: {
        attributes: [{ attributeId: 'GeneralPlumbing' }],
        priority:
          canIsolateWater === YesNoValue.No
            ? {
                required: PriorityType.P1
              }
            : undefined
      }
    })

  const generatedNotes = notesGenerator.generateNotes(result ?? {})
  const updateResult = getResultUpdater(result ?? {}, onUpdate).updateResult

  const canComplete = !!canIsolateWater

  return (
    <TopicWorkflowAction input={input} onUpdate={onUpdate} result={result}>
      <SingleSelectField
        question={notesGenerator.getQuestion('canIsolateWater')}
        answer={canIsolateWater}
        changeAnswer={(canIsolateWater) =>
          updateResult({
            canIsolateWater
          })
        }
        options={yesNoOptions}
      />
      <EuiSpacer size="m" />
      {!!canIsolateWater && canIsolateWater === YesNoValue.No && (
        <>
          <Callout type="warning" title="P1 priority job, contact DSR for schedule availability" />
          <EuiSpacer size="m" />
        </>
      )}

      <EuiButton disabled={!canComplete} onClick={() => onNext()}>
        Next
      </EuiButton>
    </TopicWorkflowAction>
  )
}
