import { DateTime, Duration } from 'luxon'
import { EuiButton, EuiHorizontalRule, EuiIcon, EuiLink, EuiSpacer, EuiText, EuiTitle } from '@elastic/eui'
import { dateConfig } from '@fallonsolutions/date'
import { useHistory } from 'react-router-dom'
import { WorkflowCompletionType } from './workflow-model'

export interface WorkflowCompleteProps {
  completionType: WorkflowCompletionType
  firstName?: string | undefined
  duration: Duration
  startNewEnquiryType: () => void
  startNewEnquiry: () => void
  loading?: boolean
}

export const WorkflowComplete = ({
  startNewEnquiry,
  startNewEnquiryType,
  firstName,
  completionType,
  loading
}: WorkflowCompleteProps) => {
  const history = useHistory()

  const now = DateTime.now().setZone(dateConfig.defaultTimezone)
  const timeOfDay = getTimeOfDayString(now)

  const iconType = completionType === WorkflowCompletionType.Abort ? 'error' : 'checkInCircleFilled'
  const iconColor = completionType === WorkflowCompletionType.Abort ? '#FFCC42' : '#34C759'

  return (
    <div style={{ textAlign: 'center', paddingTop: '200px', paddingBottom: '200px' }}>
      <EuiIcon type={iconType} size="xxl" color={iconColor} />
      <EuiSpacer size="s" />
      <EuiTitle size="xs">
        <h2>Workflow {completionType === WorkflowCompletionType.Abort ? 'aborted' : 'complete'}</h2>
      </EuiTitle>
      <EuiSpacer size="l" />
      <EuiLink onClick={startNewEnquiryType} disabled={loading}>
        Is there anything else I can help you with {timeOfDay} {firstName}?
      </EuiLink>
      <EuiSpacer size="l" />
      <EuiButton onClick={startNewEnquiry} disabled={loading}>
        Start a new enquiry
      </EuiButton>
      <EuiSpacer size="l" />
      <EuiLink onClick={() => history.push('/')} disabled={loading}>
        Back to home
      </EuiLink>
      <EuiSpacer size="xl" />
      <EuiHorizontalRule />
      <EuiText>
        <div>
          <strong>Feedback on the workflow?</strong>
        </div>
        <p>Please pass on any feedback or bugs to Tristan</p>
      </EuiText>
    </div>
  )
}

const getTimeOfDayString = (date: DateTime): string => {
  const hour = date.hour
  if (hour >= 4 && hour < 9) {
    return 'this morning'
  } else if (hour >= 9 && hour <= 19) {
    return 'today'
  } else {
    return 'tonight'
  }
}
