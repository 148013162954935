import { EuiButtonEmpty, EuiComboBox, EuiFlexGroup, EuiFlexItem, EuiHighlight } from '@elastic/eui'
import { keys, map } from 'lodash-es'
import { useState } from 'react'
import { JobType } from '../api/generated-types'

interface JobTypeComboBoxProps {
  jobTypes: any[]
  onChangeJobTypes: (jobTypes: any[]) => void
}

const JobTypesComboBox = (props: JobTypeComboBoxProps) => {
  const { jobTypes, onChangeJobTypes } = props

  const [enabled, setEnabled] = useState(jobTypes.length > 0)
  const [comboInput, setComboInput] = useState<HTMLInputElement | null>(null)

  const allJobTypes = map(keys(JobType), (jobType: string) => {
    return {
      id: jobType,
      label: jobType
    }
  })

  const renderJobTypeOption = (option: any, searchValue: any, contentClassName: any) => {
    return (
      <EuiFlexGroup gutterSize="s">
        <EuiFlexItem>
          <span className={contentClassName}>
            <EuiHighlight search={searchValue}>{option.label}</EuiHighlight>
          </span>
        </EuiFlexItem>
      </EuiFlexGroup>
    )
  }

  const onAddFilter = () => {
    setEnabled(true)
    setTimeout(() => comboInput?.focus(), 50)
  }

  const onBlur = () => {
    if (jobTypes.length <= 0) {
      setEnabled(false)
    }
  }

  return (
    <form autoComplete="off">
      <EuiComboBox
        inputRef={setComboInput}
        placeholder="Job types"
        options={allJobTypes}
        selectedOptions={jobTypes}
        onChange={onChangeJobTypes}
        isClearable={true}
        renderOption={renderJobTypeOption}
        rowHeight={40}
        isLoading={allJobTypes.length <= 0}
        style={{ minWidth: '200px' }}
        hidden={!enabled}
        onBlur={onBlur}
        aria-label="job-type"
        data-test-subj="job-type"
      />
      {!enabled && (
        <EuiButtonEmpty iconType="filter" flush="both" onClick={onAddFilter}>
          Job type
        </EuiButtonEmpty>
      )}
    </form>
  )
}

export default JobTypesComboBox
