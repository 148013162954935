import { EuiCheckbox, EuiFlexGroup, EuiFlexItem, EuiIcon, EuiText } from '@elastic/eui'
import '../static/css/task.css'

export interface TaskProps {
  label: string
  checked: boolean
  onChange: (checked: boolean) => void
  indent?: boolean | undefined
}

export const Task = (props: TaskProps) => {
  const { label, checked, onChange } = props
  const indent = props.indent ?? false
  return (
    <div style={{ marginBottom: '3px' }}>
      <EuiFlexGroup alignItems="center" gutterSize="m">
        {indent && (
          <EuiFlexItem grow={false} style={{ paddingLeft: '3px' }}>
            <EuiIcon type="kqlFunction" color="subdued" size="l" />
          </EuiFlexItem>
        )}
        <EuiFlexItem>
          <EuiFlexGroup
            alignItems="center"
            gutterSize="m"
            className={`task ${checked ? 'task--complete' : 'task--incomplete'}`}
            onClick={() => onChange(!checked)}
            style={{ padding: '8px' }}
          >
            <EuiFlexItem grow={false} style={{ paddingLeft: '4px' }}>
              <EuiCheckbox
                id={`action-task-${label}`}
                checked={checked}
                onChange={(event) => onChange(event.target.checked)}
                aria-label={label}
              />
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiText>{label}</EuiText>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
      </EuiFlexGroup>
    </div>
  )
}
