import { CustomerLocationFragment } from '@fallonsolutions/types'
import { SelectedProperty } from '../workflow/actions/action-customer-property'

export const getSelectCustomerProperty = (
  customerLocation: CustomerLocationFragment | undefined
): SelectedProperty | undefined => {
  if (!customerLocation) {
    return undefined
  }

  return {
    id: customerLocation.property.id,
    label: customerLocation.property.formattedAddress ?? 'Unknown',
    ...(customerLocation.property.address?.suburbId &&
      customerLocation.property.address?.suburb && {
        suburb: {
          id: customerLocation.property.address?.suburbId,
          label: customerLocation.property.address?.suburb
        }
      })
  }
}
