import { SingleSelectField } from '../../question-fields/single-select-question-field'
import { NotesGenerator } from '../../helpers/notes-generator'
import { ResultUpdater } from '../../helpers/results-updater'
import { PoolChlorinatorIssue, TopicPoolActionResult } from './action-topic-pool-model'
import { includes, map } from 'lodash-es'
import { YesNoValue } from '../common/common-enums'

export interface PoolChlorinatorsProps {
  result?: TopicPoolActionResult
  notesGenerator: NotesGenerator<TopicPoolActionResult>
  updateResult: ResultUpdater<TopicPoolActionResult>
}

export const PoolChlorinators = (props: PoolChlorinatorsProps) => {
  const { result, notesGenerator, updateResult } = props
  const { chlorinatorIssue, powerpointTripping } = result ?? {}

  const isTrippingPower = includes(
    [PoolChlorinatorIssue.TrippingPower, PoolChlorinatorIssue.NotTurningOn],
    chlorinatorIssue
  )
  return (
    <>
      <SingleSelectField
        question={notesGenerator.getQuestion('chlorinatorIssue')}
        options={map(PoolChlorinatorIssue, (id) => ({ id }))}
        answer={chlorinatorIssue}
        changeAnswer={(chlorinatorIssue) => updateResult({ chlorinatorIssue })}
      />

      {isTrippingPower && (
        <>
          <SingleSelectField
            question={notesGenerator.getQuestion('powerpointTripping')}
            options={[
              {
                id: YesNoValue.Yes,
                label: 'Yes, still trips',
                icon: 'check'
              },
              {
                id: YesNoValue.No,
                icon: 'cross'
              }
            ]}
            answer={powerpointTripping}
            changeAnswer={(powerpointTripping) => updateResult({ powerpointTripping })}
          />
        </>
      )}
    </>
  )
}
