import { EuiButton, EuiSpacer } from '@elastic/eui'
import { EnquiryAbortReason } from '../../../api/generated-types'
import { Callout } from '../../../common/callout'
import { NotesGenerator } from '../../helpers/notes-generator'
import { ResultUpdater } from '../../helpers/results-updater'
import { useWorkflow } from '../../helpers/workflow-provider'
import { SingleSelectField } from '../../question-fields/single-select-question-field'
import { YesNoValue } from '../common/common-enums'
import { yesNoOptions } from '../common/common-options'
import {
  InsideOrOutside,
  TopicPlumbingWaterLeakDetectionActionResult,
  WaterLeakOutsideSymptom,
  outsideSymptomDetailOptions,
  outsideSymptomOptions
} from './action-topic-plumbing-water-leak-detection-model'

export interface WaterLeakOutsideProps {
  result?: TopicPlumbingWaterLeakDetectionActionResult
  notesGenerator: NotesGenerator<TopicPlumbingWaterLeakDetectionActionResult>
  updateResult: ResultUpdater<TopicPlumbingWaterLeakDetectionActionResult>
}
export const WaterLeakOutside = (props: WaterLeakOutsideProps) => {
  const { result, notesGenerator, updateResult } = props
  const {
    insideOrOutside,
    canIsolateWater,
    symptom,
    outsideSymptomDetail,
    waterMeterSpinning,
    havePool,
    poolWaterLevelDropping
  } = result ?? {}

  const workflowContext = useWorkflow()

  return (
    <>
      {insideOrOutside === InsideOrOutside.Outside && (
        <>
          <SingleSelectField
            question={notesGenerator.getQuestion('symptom')}
            answer={symptom}
            changeAnswer={(symptom) =>
              updateResult({
                symptom: symptom as WaterLeakOutsideSymptom
              })
            }
            options={outsideSymptomOptions}
          />
          <EuiSpacer />
          {!!symptom && symptom === WaterLeakOutsideSymptom.Yard && (
            <>
              <SingleSelectField
                question={notesGenerator.getQuestion('waterMeterSpinning')}
                answer={waterMeterSpinning}
                changeAnswer={(waterMeterSpinning) =>
                  updateResult({
                    waterMeterSpinning
                  })
                }
                options={yesNoOptions}
              />
              <EuiSpacer size="m" />
              {!!waterMeterSpinning && waterMeterSpinning === YesNoValue.Yes && (
                <>
                  <SingleSelectField
                    question={notesGenerator.getQuestion('canIsolateWater')}
                    answer={canIsolateWater}
                    changeAnswer={(canIsolateWater) =>
                      updateResult({
                        canIsolateWater
                      })
                    }
                    options={yesNoOptions}
                  />
                  <Callout type="note" title="See Meter finding at leak stopping instructions" />
                </>
              )}
              {!!waterMeterSpinning && waterMeterSpinning === YesNoValue.No && (
                <>
                  <SingleSelectField
                    question={notesGenerator.getQuestion('havePool')}
                    answer={havePool}
                    changeAnswer={(havePool) =>
                      updateResult({
                        havePool
                      })
                    }
                    options={yesNoOptions}
                  />

                  {havePool === YesNoValue.Yes && (
                    <>
                      <SingleSelectField
                        question={notesGenerator.getQuestion('poolWaterLevelDropping')}
                        answer={poolWaterLevelDropping}
                        changeAnswer={(poolWaterLevelDropping) =>
                          updateResult({
                            poolWaterLevelDropping
                          })
                        }
                        options={yesNoOptions}
                      />
                      {poolWaterLevelDropping === YesNoValue.Yes && (
                        <Callout type="note" title="Book Pool plumber (Josh Fowler)" />
                      )}
                    </>
                  )}
                </>
              )}
            </>
          )}

          {!!symptom && symptom === WaterLeakOutsideSymptom.WaterMeter && (
            <>
              <SingleSelectField
                question={notesGenerator.getQuestion('waterMeterSpinning')}
                answer={waterMeterSpinning}
                changeAnswer={(waterMeterSpinning) =>
                  updateResult({
                    waterMeterSpinning
                  })
                }
                options={yesNoOptions}
              />
              <EuiSpacer size="m" />
              {waterMeterSpinning === YesNoValue.Yes && (
                <>
                  <SingleSelectField
                    question={notesGenerator.getQuestion('canIsolateWater')}
                    answer={canIsolateWater}
                    changeAnswer={(canIsolateWater) =>
                      updateResult({
                        canIsolateWater
                      })
                    }
                    options={yesNoOptions}
                  />
                </>
              )}

              {waterMeterSpinning === YesNoValue.No && (
                <>
                  <Callout
                    type="warning"
                    title="Unfortunately this type of work is out of scope for us and we strongly believe it is within your best interest to contact urban utilities / local council - 13 23 64"
                  />
                  <EuiSpacer />
                  <EuiButton
                    color="warning"
                    onClick={() =>
                      workflowContext.abort({
                        abortReason: EnquiryAbortReason.CustomerNeedsAreOutOfService,
                        notes: `Customer was enquiring about Water leak at the Meter when water meter is not spinning.`
                      })
                    }
                  >
                    Offer a voucher and end call
                  </EuiButton>
                </>
              )}
            </>
          )}
          {!!symptom && symptom === WaterLeakOutsideSymptom.Other && (
            <>
              <SingleSelectField
                question={notesGenerator.getQuestion('outsideSymptomDetail')}
                answer={outsideSymptomDetail}
                changeAnswer={(outsideSymptomDetail) =>
                  updateResult({
                    outsideSymptomDetail
                  })
                }
                options={outsideSymptomDetailOptions}
              />
            </>
          )}
          <EuiSpacer size="m" />
        </>
      )}
    </>
  )
}
