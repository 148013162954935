import { Callout } from '../../common/callout'
import { OutOfScopeButton } from './out-of-scope-button'

export interface OutOfScopeProps {
  issue: string
  isOutOfScope: boolean
  reason?: string
}

const defaultScriptStatement = 'Unfortunately this type of work is out of scope for us.'
export const OutOfScope = (props: OutOfScopeProps) => {
  const { issue, isOutOfScope, reason } = props
  if (!isOutOfScope) {
    return null
  }
  return (
    <>
      <Callout type="script">{reason ? reason : defaultScriptStatement}</Callout>
      <OutOfScopeButton issue={issue} />
    </>
  )
}
