import { MediaItemFragment } from '@fallonsolutions/types'
import { useAuthenticated } from '../auth/authenticated-context'
import { isomorphicLogic } from './decision-point-isomorphic-logic'
import { AllAction, AuthorizationError, AuthorizationResult } from './decision-point-types'

export * from './decision-point-types'

export const deny = (error?: AuthorizationError): AuthorizationResult => {
  return { ok: false, ...(error ? { error } : { error: AuthorizationError.UNAUTHORIZED }) }
}

export const useDecisionPoint = (action: keyof AllAction) => {
  const authContext = useAuthenticated()
  const actionProperty = `can${action}`

  // console.log('useDecisionPoint: actionProperty', actionProperty)
  //fixme: relying on a single untyped optional context
  return {
    DeletePhotosOnJob: (context?: { mediaItem: MediaItemFragment }) => {
      const { ok, error } = isomorphicLogic.DeletePhotosOnJob(authContext.userFragment, context)
      return {
        [`${actionProperty}Ok`]: ok,
        [`${actionProperty}Error`]: error,
        ok,
        error
      }
    },
    [actionProperty]: () => {
      const { ok, error } = isomorphicLogic[action](authContext.userFragment)
      return {
        [`${actionProperty}Ok`]: ok,
        [`${actionProperty}Error`]: error,
        ok,
        error
      }
    }
  }
}
