import { EuiBasicTableColumn, EuiFlexGroup, EuiFlexItem } from '@elastic/eui'
import { dateConfig } from '@fallonsolutions/date'
import { DateTime } from 'luxon'
import {
  ContactCustomerRole,
  CustomerContactCustomerFragment,
  CustomerLinkFragment,
  CustomerType,
  PropertyLinkFragment
} from '../api/generated-types'
import EuiCustomLink from '../common/eui-custom-link'
import { ContactCustomerRoleBadge } from '../customers/customer-contact-role-badge'
import { PropertyLink } from '../properties/property-link'

export const ContactCustomerListColumns = (): Array<EuiBasicTableColumn<CustomerContactCustomerFragment>> => {
  return [
    {
      field: 'customer.number',
      name: 'Customer reference',
      sortable: true,
      width: '140px',
      render: (value: string, customerContact: CustomerContactCustomerFragment) => (
        <EuiCustomLink to={`/customers/${customerContact.customerId}`}>
          <span className="mono-text">{value}</span>
        </EuiCustomLink>
      )
    },
    {
      field: 'roles',
      name: 'Role(s)',
      width: '180px',
      render: (roles: ContactCustomerRole[]) => (
        <EuiFlexGroup gutterSize="s" justifyContent="flexStart">
          {roles.map((role) => (
            <EuiFlexItem key={role} grow={false}>
              <ContactCustomerRoleBadge role={role} />
            </EuiFlexItem>
          ))}
        </EuiFlexGroup>
      )
    },
    {
      field: 'customer',
      name: 'Company / Domestic',
      width: '300px',
      render: (customer: CustomerLinkFragment) => {
        const companyName = customer.company?.name
        const type = customer.type ?? CustomerType.Domestic
        if (type === CustomerType.Domestic) {
          return 'Domestic'
        } else {
          return `${companyName ?? 'Unknown company'} (Commercial)`
        }
      }
    },
    {
      field: 'customer.properties',
      name: 'Properties',
      render: (properties: PropertyLinkFragment[] | undefined) => (
        <EuiFlexGroup direction="column" gutterSize="xs">
          {(properties ?? []).map((property) => (
            <EuiFlexItem grow={false} key={property.id}>
              <PropertyLink property={property} includeSuburb={true} />
            </EuiFlexItem>
          ))}
        </EuiFlexGroup>
      )
    },
    {
      field: 'customer.created',
      name: 'Customer created',
      align: 'right',
      width: '130px',
      render: (value: string) => {
        const created = DateTime.fromISO(value)
        if (created.isValid) {
          return (
            <span
              title={created.toFormat(dateConfig.luxonFormat.fullDate)}
              style={{ whiteSpace: 'nowrap', overflowX: 'hidden', textOverflow: 'ellipsis' }}
            >
              {created.toRelative({ style: 'narrow' })}
            </span>
          )
        } else {
          return ''
        }
      }
    },
    {
      name: '',
      width: '60px',
      actions: [
        {
          name: 'Edit association',
          description: 'Edit this association',
          icon: 'pencil',
          type: 'icon',
          onClick: (customerContact: CustomerContactCustomerFragment) =>
            console.log('click CustomerContactCustomerFragment', customerContact)
        }
      ]
    }
  ]
}
