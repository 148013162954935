import { YesNoValue } from '../common/common-enums'
import { AttributeRequirement, JobType, PriorityType, TradeType } from '../../../api/generated-types'
import { PriorityRequirement } from '@fallonsolutions/priority'

import {
  DrainServiceType,
  SewerIssue,
  SewerItemAffected,
  StormwaterIssue,
  TopicPlumbingDrainActionResult
} from './action-topic-plumbing-drain-model'
import {
  TopicQualification,
  TopicQualificationResult,
  TopicRequirementsResult,
  createOutcomeGenerator
} from '../common/common-outcome'
const getItemAffected = (issue?: StormwaterIssue | SewerIssue, result?: TopicPlumbingDrainActionResult) => {
  const { smellySewerAffected, itemAffected, slowDrainAffected } = result ?? {}
  if (!issue) {
    return undefined
  }
  switch (issue) {
    case SewerIssue.SmellySewer:
      return smellySewerAffected
    case SewerIssue.SlowDraining:
      return slowDrainAffected
    default:
      return itemAffected
  }
}

export const getRequirements =
  (isDrainsSpecial: boolean) =>
  (result?: TopicPlumbingDrainActionResult): TopicRequirementsResult => {
    const {
      drainIssue,
      itemIssue,
      stormwaterConnect,
      stormwaterSymptom,
      stormwaterBlockedPossibleCause,
      drainCameraEligibility,
      newInstallOrReplace,
      houseBuild,
      causeNote,
      blockedPrior,
      haveCouncilPlans,
      anotherToilet,
      overflowOrLeakedPrior,
      serviceType,
      pumpedOutRecently,
      issueDescription,
      otherBlockages
    } = result ?? {}

    const affectedItem = getItemAffected(drainIssue, result)
    const drainsSkill = { attributeId: 'GeneralDrains' }
    const stormwaterSpecialistSkill = { attributeId: 'StormwaterDrainageSolutions' }
    const priorityP1: PriorityRequirement = { required: PriorityType.P1 }
    const priorityP2: PriorityRequirement = { required: PriorityType.P2 }
    const priorityP3: PriorityRequirement = { required: PriorityType.P3 }

    const attributes = [] as AttributeRequirement[]
    let priority: PriorityRequirement | undefined = undefined

    let category = isDrainsSpecial ? '99 Drains Special' : 'Drains'
    if (serviceType === DrainServiceType.Stormwater) {
      // currently 99 drains special only applies to drains, not stormwater
      category = 'Stormwater'
      priority = priorityP3
      switch (drainIssue) {
        case StormwaterIssue.ConnectStormwater:
          if (stormwaterConnect) attributes.push(drainsSkill)
          break
        case StormwaterIssue.Broken:
          if (!!itemIssue && itemIssue.trim().length > 0) attributes.push(drainsSkill)
          break
        case StormwaterIssue.SolutionsOrImprovements:
          if (stormwaterSymptom) attributes.push(stormwaterSpecialistSkill)
          break
        case StormwaterIssue.BlockedOrOverflowing:
          if ((!!blockedPrior && blockedPrior === YesNoValue.Yes && !!causeNote) || blockedPrior === YesNoValue.No) {
            blockedPrior === YesNoValue.Yes ? attributes.push(stormwaterSpecialistSkill) : attributes.push(drainsSkill)
          }
          break
        case StormwaterIssue.CameraInspections:
          if (!!stormwaterBlockedPossibleCause && drainCameraEligibility === YesNoValue.Yes)
            attributes.push(drainsSkill)
          break
        case StormwaterIssue.NewOrReplace:
          const questionsAnswered = !!newInstallOrReplace && !!houseBuild
          const outOfScope = !!newInstallOrReplace && houseBuild === YesNoValue.Yes
          if (questionsAnswered && !outOfScope) attributes.push(drainsSkill)
          break
      }
    } else {
      switch (drainIssue) {
        case SewerIssue.Other:
          attributes.push(drainsSkill)
          break
        case SewerIssue.Reline:
          priority = priorityP1
          if (!!issueDescription && issueDescription.length > 0) attributes.push(drainsSkill)
          break
        case SewerIssue.Repair:
          priority = priorityP3
          if (!!issueDescription && issueDescription.length > 0) attributes.push(drainsSkill)
          break
        case SewerIssue.Replace:
          if (!!issueDescription && issueDescription.length > 0) attributes.push(drainsSkill)
          break
        case SewerIssue.CappingOff:
          priority = priorityP3
          if (haveCouncilPlans === YesNoValue.Yes) attributes.push(drainsSkill)
          break
        case SewerIssue.SlowDraining:
        case SewerIssue.SmellySewer:
        case SewerIssue.BlockedOrOverflowing:
          switch (affectedItem) {
            case SewerItemAffected.Toilet:
            case SewerItemAffected.WholeHouse:
              if (blockedPrior === YesNoValue.Yes && !!causeNote && !!anotherToilet) {
                attributes.push(drainsSkill)
              }
              if (blockedPrior === YesNoValue.No && !!anotherToilet) {
                attributes.push(drainsSkill)
              }
              break
            case SewerItemAffected.Basin:
            case SewerItemAffected.KitchenSink:
            case SewerItemAffected.Vanity:
            case SewerItemAffected.Shower:
            case SewerItemAffected.Bath:
            case SewerItemAffected.Bathroom:
            case SewerItemAffected.Laundry:
              attributes.push(drainsSkill)
              break
            case SewerItemAffected.GreaseTrap:
            case SewerItemAffected.SepticSystem:
              if (pumpedOutRecently === YesNoValue.Yes) {
                attributes.push(drainsSkill)
              }
              break
            case SewerItemAffected.OverflowingInYard:
            case SewerItemAffected.OverflowingInspectionPoint:
            case SewerItemAffected.SewerInYard:
            case SewerItemAffected.LeakingSewer:
              if (overflowOrLeakedPrior) {
                if (
                  overflowOrLeakedPrior === YesNoValue.No ||
                  (overflowOrLeakedPrior === YesNoValue.Yes && !!causeNote)
                ) {
                  attributes.push(drainsSkill)
                }
              }
          }

          break
      }
    }
    // all storm water issues are P3
    if (serviceType === DrainServiceType.Sewer && !!affectedItem) {
      switch (drainIssue) {
        case SewerIssue.SmellySewer:
          priority = priorityP2
          break
        case SewerIssue.SlowDraining:
          switch (affectedItem) {
            case SewerItemAffected.SepticSystem:
            case SewerItemAffected.GreaseTrap:
              priority = priorityP2
              break
            case SewerItemAffected.Basin:
            case SewerItemAffected.Vanity:
            case SewerItemAffected.KitchenSink:
            case SewerItemAffected.Shower:
            case SewerItemAffected.Bath:
              if (!!otherBlockages && otherBlockages === YesNoValue.Yes) {
                priority = priorityP1
              } else {
                priority = priorityP2
              }
              break
            case SewerItemAffected.WholeHouse:
            case SewerItemAffected.Toilet:
            case SewerItemAffected.Laundry:
              priority = priorityP1
              break
            default:
              break
          }
          break
        case SewerIssue.BlockedOrOverflowing:
          switch (affectedItem) {
            case SewerItemAffected.Toilet:
            case SewerItemAffected.WholeHouse:
            case SewerItemAffected.Laundry:
            case SewerItemAffected.GreaseTrap:
            case SewerItemAffected.SepticSystem:
            case SewerItemAffected.OverflowingInYard:
            case SewerItemAffected.OverflowingInspectionPoint:
            case SewerItemAffected.SewerInYard:
            case SewerItemAffected.LeakingSewer:
              priority = priorityP1
              break
            case SewerItemAffected.Basin:
            case SewerItemAffected.Vanity:
            case SewerItemAffected.KitchenSink:
            case SewerItemAffected.Shower:
            case SewerItemAffected.Bath:
              if (!!otherBlockages && otherBlockages === YesNoValue.Yes) {
                priority = priorityP1
              } else {
                priority = priorityP2
              }
              break
            default:
              break
          }
          break
      }
      // repeated to correctly align priority levels based on priority level given to each item
    }
    if (attributes.length === 0) {
      return {}
    } else {
      return {
        category,
        requirements: {
          attributes,
          priority: priority ? priority : undefined
        }
      }
    }
  }

const getQualification = (result: TopicPlumbingDrainActionResult | undefined): TopicQualificationResult => {
  const {
    drainIssue,
    itemIssue,
    stormwaterConnect,
    stormwaterSymptom,
    stormwaterBlockedPossibleCause,
    drainCameraEligibility,
    newInstallOrReplace,
    houseBuild,
    causeNote,
    haveCouncilPlans,
    overflowOrLeakedPrior,
    serviceType,
    pumpedOutRecently,
    dsrConfirmInScope,
    issueDescription,
    otherIssueDescription,
    otherIssueIsInScope,
    otherBlockages
  } = result ?? {}
  const affectedItem = getItemAffected(drainIssue, result)
  if (serviceType === DrainServiceType.Stormwater) {
    switch (drainIssue) {
      case StormwaterIssue.ConnectStormwater:
        if (stormwaterConnect) return { qualification: TopicQualification.InScope }
        break
      case StormwaterIssue.Broken:
        if (!!itemIssue && itemIssue.trim().length > 0) return { qualification: TopicQualification.InScope }
        break
      case StormwaterIssue.SolutionsOrImprovements:
        if (stormwaterSymptom) return { qualification: TopicQualification.InScope }
        break
      case StormwaterIssue.BlockedOrOverflowing:
        return { qualification: TopicQualification.InScope }
      case StormwaterIssue.CameraInspections:
        if (stormwaterBlockedPossibleCause) {
          return {
            qualification:
              drainCameraEligibility === YesNoValue.Yes ? TopicQualification.InScope : TopicQualification.OutOfScope
          }
        }
        break
      case StormwaterIssue.NewOrReplace:
        const outOfScope = !!newInstallOrReplace && houseBuild === YesNoValue.Yes
        return { qualification: outOfScope ? TopicQualification.InScope : TopicQualification.InScope }
    }
  } else {
    // for Smelly slow workflow we don't want to ask the usual followup question to questions with a default of priority2
    const needToAskP2Question = drainIssue !== SewerIssue.SmellySewer ? !!otherBlockages : true
    switch (drainIssue) {
      case SewerIssue.Other:
        if (!!otherIssueDescription && otherIssueIsInScope === YesNoValue.Yes)
          return { qualification: TopicQualification.InScope }
        break
      case SewerIssue.Reline:
      case SewerIssue.Repair:
      case SewerIssue.Replace:
        if (!!issueDescription && issueDescription.length > 0) return { qualification: TopicQualification.InScope }
        break
      case SewerIssue.CappingOff:
        if (haveCouncilPlans === YesNoValue.Yes) return { qualification: TopicQualification.InScope }
        break
      case SewerIssue.SlowDraining:
      case SewerIssue.SmellySewer:
      case SewerIssue.BlockedOrOverflowing:
        switch (affectedItem) {
          case SewerItemAffected.Toilet:
          case SewerItemAffected.WholeHouse:
            return { qualification: TopicQualification.InScope }
          // currently these are the priority 2 jobs that need to answer the question of whether the issue is appearing else where in the house
          case SewerItemAffected.Basin:
          case SewerItemAffected.KitchenSink:
          case SewerItemAffected.Vanity:
          case SewerItemAffected.Shower:
          case SewerItemAffected.Bath:
            if (needToAskP2Question) {
              return { qualification: TopicQualification.InScope }
            }
            break
          case SewerItemAffected.Bathroom:
          case SewerItemAffected.Laundry:
            return { qualification: TopicQualification.InScope }
          case SewerItemAffected.GreaseTrap:
          case SewerItemAffected.SepticSystem:
            if (pumpedOutRecently === YesNoValue.Yes) {
              return { qualification: TopicQualification.InScope }
            }
            break
          case SewerItemAffected.OverflowingInYard:
          case SewerItemAffected.OverflowingInspectionPoint:
          case SewerItemAffected.SewerInYard:
          case SewerItemAffected.LeakingSewer:
            if (overflowOrLeakedPrior) {
              if (
                overflowOrLeakedPrior === YesNoValue.No ||
                (overflowOrLeakedPrior === YesNoValue.Yes && !!causeNote)
              ) {
                return { qualification: TopicQualification.InScope }
              }
            }
        }
        break
    }
  }
  return {
    qualification: TopicQualification.Unknown
  }
}

export const outcomeGenerator = (isDrainsClearSpecial: boolean) =>
  createOutcomeGenerator<TopicPlumbingDrainActionResult>(
    {
      qualification: TopicQualification.Unknown,
      trade: TradeType.Drains,
      type: JobType.Service,
      category: 'Drains'
    },
    getQualification,
    getRequirements(isDrainsClearSpecial)
  )
