import { BaseActionInput, BaseActionResult, WorkflowActionProps } from '../workflow-model'
import { EuiButton, EuiSpacer, EuiText } from '@elastic/eui'
import { Callout } from '../../common/callout'
import { Task } from '../../common/task'
import { WorkflowAction } from '../workflow-action'

export type CaptureBlackMemberNomineesActionInput = BaseActionInput
export interface CaptureBlackMemberNomineesActionResult extends BaseActionResult {
  nomineesDetailsCaptured?: boolean
}

export const CaptureBlackMemberNomineesAction = (
  props: WorkflowActionProps<CaptureBlackMemberNomineesActionInput, CaptureBlackMemberNomineesActionResult>
) => {
  const { onUpdate, result } = props
  const { actionCompleted, nomineesDetailsCaptured } = result ?? {}

  const setNomineesDetailsCaptured = (nomineesDetailsCaptured: boolean) =>
    onUpdate({ ...result, nomineesDetailsCaptured })
  const setActionCompleted = (actionCompleted: boolean) => onUpdate({ ...result, actionCompleted })

  const allTasksCompleted = nomineesDetailsCaptured

  return (
    <WorkflowAction
      title="Nominees"
      value={actionCompleted ? 'Captured' : undefined}
      onClickChange={() => setActionCompleted(false)}
    >
      <Callout type="script">
        <EuiText>
          <p>
            Now you can nominate up to <strong>4 lucky people</strong> - can I please have their:
          </p>
          <ul>
            <li>full names</li>
            <li>email addresses, and</li>
            <li>contact numbers</li>
          </ul>
        </EuiText>
      </Callout>
      <Task
        label="I have captured all details of the lucky people"
        checked={!!nomineesDetailsCaptured}
        onChange={setNomineesDetailsCaptured}
        indent={true}
      />
      {!!nomineesDetailsCaptured && (
        <Callout type="script" title="Thank you for that. I'll get that processed after this call." />
      )}
      <EuiSpacer size="s" />
      <EuiButton disabled={!allTasksCompleted} onClick={() => setActionCompleted(true)}>
        Next
      </EuiButton>
      <EuiSpacer />
    </WorkflowAction>
  )
}
