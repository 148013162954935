import { EuiSpacer, EuiSwitch, EuiText } from '@elastic/eui'
import { Callout } from '../../common/callout'
import { BaseActionInput, BaseActionResult, WorkflowActionProps } from '../workflow-model'

export interface CustomerThreateningLegalOrMediaActionInput extends BaseActionInput {
  callTransferredToManager?: boolean
}
export interface CustomerThreateningLegalOrMediaActionResult extends BaseActionResult {
  customerThreateningLegalOrMediaAction?: boolean
}

export const CustomerThreateningLegalOrMediaAction = (
  props: WorkflowActionProps<CustomerThreateningLegalOrMediaActionInput, CustomerThreateningLegalOrMediaActionResult>
) => {
  const { onUpdate, result, input } = props
  return (
    <>
      <EuiSwitch
        checked={result?.customerThreateningLegalOrMediaAction === true}
        onChange={(e) => onUpdate({ customerThreateningLegalOrMediaAction: e.target.checked })}
        label="Customer is Threatening legal or media action"
        labelProps={{ style: { color: 'red' } }}
      />

      {result?.customerThreateningLegalOrMediaAction === true && (
        <>
          <EuiSpacer size="s" />
          <Callout type="failure" title="Threat of legal or media action">
            <EuiText>
              If customer is threatening legal or media action, inform GM, Take No further Action. For Electrical Call
              Scott Sinclair Ext 374 or 0448 700 15 for all other trades call Mike Preston Ext 361 or 0400 305 361. If
              General Manager is unavailable contact the CEO (Sean Simmons EXT 369 or 0400 808 915 or Managing Director
              Mark Denning EXT 366 or 0407 035 983
            </EuiText>
          </Callout>
        </>
      )}
      {input?.callTransferredToManager !== undefined && result?.customerThreateningLegalOrMediaAction !== true && (
        <>
          <EuiSpacer size="s" />
          <Callout type="warning" title="Follow-up action">
            <EuiText>
              Inform the General Manager Electrical Call Scott Sinclair Ext 374 or 0448 700 15. For all other trades
              call Mike Preston Ext 361 or 0400 305 361. If GM is not available call Supervisor Electrical Dean Westlake
              0448 800 148 for all other trades call Brendan Preston 0423 765 815 to arrange visit and any insurance
              claim.
            </EuiText>
          </Callout>
        </>
      )}
    </>
  )
}
