import { ApolloError, useMutation, useQuery } from '@apollo/client'
import {
  CriteriaWithPagination,
  DefaultItemAction,
  EuiBasicTable,
  EuiBasicTableColumn,
  EuiButtonEmpty,
  EuiCallOut,
  EuiConfirmModal,
  EuiFlexGroup,
  EuiFlexItem,
  EuiLoadingSpinner,
  EuiSpacer,
  EuiText,
  EuiTitle
} from '@elastic/eui'
import { Address } from '@fallonsolutions/address'
import { Property } from '@fallonsolutions/property'
import { orderBy } from 'lodash-es'
import { useState } from 'react'
import {
  CustomerSource,
  GetCustomerPropertiesDocument,
  MembershipStatusType,
  RemovePropertyFromCustomerDocument,
  RemovePropertyFromCustomerInput
} from '../api/generated-types'
import { useAuthenticated } from '../auth/authenticated-context'
import { PropertyAdd } from '../properties/property-add'
import { PropertyChange } from '../properties/property-change'
import '../static/css/customer-location-status.css'

interface CustomerPropertiesContainerProps {
  customerId: string
  source: CustomerSource | null | undefined
}

export const CustomerPropertiesContainer = (props: CustomerPropertiesContainerProps) => {
  const { customerId, source } = props

  const [selectedProperty, setSelectedProperty] = useState<Property | undefined>()
  const [isRemoveModalVisible, setIsRemoveModalVisible] = useState(false)
  const [isChangeModalVisible, setIsChangeModalVisible] = useState(false)
  const [isAddModalVisible, setIsAddModalVisible] = useState(false)

  const [propertyRemovalError, setPropertyRemovalError] = useState<ApolloError | undefined>()

  const { data, loading, refetch } = useQuery(GetCustomerPropertiesDocument, {
    variables: { id: customerId }
  })

  const selectedPropertyMemberships = data?.getCustomer?.properties
    ?.find((p) => p?.id === selectedProperty?.id)
    ?.memberships?.filter((m) => [MembershipStatusType.Active, MembershipStatusType.Pending].includes(m.status.status))
  const doesPropertyHaveMemberships = (selectedPropertyMemberships?.length ?? 0) > 0

  const [removePropertyFromCustomer, { loading: removePropertyRequestPending }] = useMutation(
    RemovePropertyFromCustomerDocument,
    {
      update: (cache) => {
        try {
          const removedPropertyId = selectedProperty?.id
          cache.modify({
            id: cache.identify({ id: customerId, __typename: 'Customer' }),
            fields: {
              properties(existingPropertyRefs, { readField }) {
                return existingPropertyRefs.filter(
                  (propertyRef: any) => removedPropertyId !== readField('id', propertyRef)
                )
              }
            }
          })
        } catch (exception) {
          console.log(exception)
        }
      }
    }
  )

  const sendPropertyRemovalRequest = async () => {
    try {
      await removePropertyFromCustomer({
        variables: {
          input: {
            customer: customerId,
            property: selectedProperty?.id,
            fullAddress: selectedProperty?.formattedAddress
          } as RemovePropertyFromCustomerInput
        }
      })
      setIsRemoveModalVisible(false)
    } catch (error: any) {
      setPropertyRemovalError(error.toString())
    }
  }

  const addNewProperty = () => {
    setSelectedProperty(undefined)
    setIsAddModalVisible(true)
  }

  const changeProperty = (property: Property) => {
    setSelectedProperty(property)
    setIsChangeModalVisible(true)
  }

  const removeProperty = (property: Property) => {
    setPropertyRemovalError(undefined)
    setSelectedProperty(property)
    setIsRemoveModalVisible(true)
  }

  const properties = () => {
    return (
      <>
        <EuiFlexGroup alignItems="center">
          <EuiFlexItem grow={false}>
            <EuiTitle size="s">
              <h2>Properties</h2>
            </EuiTitle>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiButtonEmpty iconType="refresh" onClick={() => refetch()} size="xs">
              Reload customer properties
            </EuiButtonEmpty>
          </EuiFlexItem>
          <EuiFlexItem grow={true} />
          <EuiFlexItem grow={false}>
            <EuiButtonEmpty onClick={addNewProperty}>Add new property</EuiButtonEmpty>
          </EuiFlexItem>
        </EuiFlexGroup>
        <EuiSpacer />
        {isRemoveModalVisible && (
          <EuiConfirmModal
            title="Do you want to remove this property?"
            onCancel={() => setIsRemoveModalVisible(false)}
            onConfirm={sendPropertyRemovalRequest}
            isLoading={removePropertyRequestPending}
            confirmButtonDisabled={doesPropertyHaveMemberships}
            cancelButtonText="No, don't do it"
            confirmButtonText="Yes, do it"
            buttonColor="danger"
            defaultFocusedButton="confirm"
          >
            {propertyRemovalError && (
              <>
                <EuiCallOut title="Sorry, there was an error" color="danger" iconType="alert">
                  <p>{propertyRemovalError.message}</p>
                </EuiCallOut>
                <EuiSpacer />
              </>
            )}
            {!doesPropertyHaveMemberships ? (
              <>
                <p>You&rsquo;re about to remove property: {selectedProperty?.formattedAddress}</p>
                <p>Are you sure you want to do this?</p>
              </>
            ) : (
              <>
                <EuiCallOut color="danger" iconType="alert">
                  <EuiText textAlign="center" color="default" size="m">
                    This property has at least one active membership and cannot be removed from the customer. Please
                    move the membership to another customer property before continuing
                  </EuiText>
                </EuiCallOut>
              </>
            )}
          </EuiConfirmModal>
        )}
        {isChangeModalVisible && selectedProperty && (
          <PropertyChange
            customerId={customerId}
            property={selectedProperty}
            closeModal={() => setIsChangeModalVisible(false)}
          />
        )}
        {isAddModalVisible && <PropertyAdd customerId={customerId} closeModal={() => setIsAddModalVisible(false)} />}
        {data?.getCustomer?.properties?.length ? (
          <PropertyTable
            properties={loading ? [] : (data!.getCustomer!.properties as any[] as Property[])}
            source={source}
            loading={loading}
            changeProperty={changeProperty}
            removeProperty={removeProperty}
          />
        ) : (
          <div>No properties found for this customer</div>
        )}
      </>
    )
  }

  return loading ? <EuiLoadingSpinner size="l" /> : properties()
}

interface PropertyTableProps {
  source: CustomerSource | null | undefined
  properties: Property[]
  loading: boolean
  changeProperty: (property: Property) => void
  removeProperty: (property: Property) => void
}
const PropertyTable = (props: PropertyTableProps) => {
  const { properties, loading, changeProperty, removeProperty, source } = props

  const isDev = useAuthenticated().userFragment?.permissions?.developerFeatures === true

  const [pageIndex, setPageIndex] = useState(0)
  const [pageSize, setPageSize] = useState(5)
  const [sortField, setSortField] = useState<any>('address.suburb')
  const [sortDirection, setSortDirection] = useState<any>('asc')

  const onTableChange = (c: CriteriaWithPagination<Property>) => {
    const { index: pageIndex, size: pageSize } = c.page
    setPageIndex(pageIndex)
    setPageSize(pageSize)
    setSortField(c.sort?.field)
    setSortDirection(c.sort?.direction)
  }

  const pagination = {
    pageIndex,
    pageSize,
    totalItemCount: properties.length,
    pageSizeOptions: [5, 10, 20]
  }

  const sorting = {
    sort: {
      field: sortField,
      direction: sortDirection
    }
  }

  const actions: DefaultItemAction<Property>[] = [
    ...(source === CustomerSource.Platform
      ? [
          {
            name: 'Change property',
            description: 'Change property',
            icon: 'inputOutput',
            type: 'icon',
            onClick: changeProperty
          } as DefaultItemAction<Property>
        ]
      : []),
    ...(source === CustomerSource.Platform && isDev
      ? [
          {
            name: 'Remove property',
            description: `Remove this property from customer's list`,
            icon: 'trash',
            type: 'icon',
            color: 'danger',
            onClick: removeProperty
          } as DefaultItemAction<Property>
        ]
      : [])
  ]

  const columns: EuiBasicTableColumn<Property>[] = [
    {
      field: 'address',
      name: 'Street',
      sortable: true,
      render: (address: Address | undefined, property: Property) => {
        if (address) {
          return (
            <a href={`/properties/${property.id}`}>{[address.street, address.street2].filter((n) => !!n).join(', ')}</a>
          )
        }
        return '-'
      }
    },
    {
      field: 'address.suburb',
      name: 'Suburb',
      sortable: true
    },
    {
      field: 'address.postcode',
      name: 'Postcode',
      sortable: true
    },
    {
      field: 'address.state',
      name: 'State',
      sortable: true
    },
    {
      name: 'Actions',
      actions: actions as any[]
    }
  ]

  const propertiesPage = orderBy(properties, sorting.sort.field, sorting.sort.direction).slice(
    pageIndex * pageSize,
    (pageIndex + 1) * pageSize
  )

  const isPaginationAndSortingEnabled = properties.length > 5
  const additionalProps = isPaginationAndSortingEnabled
    ? {
        pagination: pagination,
        onChange: onTableChange,
        sorting: sorting
      }
    : {}

  return (
    <EuiBasicTable
      className="basic-table--minimal"
      loading={loading}
      items={propertiesPage}
      columns={columns}
      {...additionalProps}
      noItemsMessage={loading ? 'Searching...' : 'No properties found'}
      data-test-id="customer-properties-table"
    />
  )
}
