import { DateTime } from 'luxon'
import { MediaContext, MediaType } from '../api/generated-types'

export interface MediaServiceUpload {
  id: string
  name?: string
  file: UploadFile
  date: DateTime
  type: MediaType
  jobId: string
  context: MediaContext
  subject: string
  status: MediaServiceUploadStatus
  progress: number
  lat?: number
  lon?: number
}

export interface UploadFile extends File {
  width?: number
  height?: number
  type: string
  sha256: string
}

export enum MediaServiceUploadStatus {
  Saving,
  Uploading,
  Complete,
  Error
}
