import { flow } from 'lodash-es'
import { CancelJobActionResult, JobCancellationReason, JobOffers, TravelFeeDiscountValid } from '../action-cancel-job'
import { YesNoValue } from '../../topics/common/common-enums'

interface HaveAllTasksBeenCompletedProps extends CancelJobActionResult {
  customerId: string
  discountValid: TravelFeeDiscountValid
  jobId: string
  jobCancellationOffer?: JobOffers
}

export enum VerificationResult {
  Invalid = 'Invalid', // validation failed, stop further validating
  Valid = 'Valid', // current step is valid, continue to next step
  Clear = 'Clear' // skip everything, workflow is valid
}
export const haveAllTasksBeenCompleted = (props: HaveAllTasksBeenCompletedProps): boolean => {
  const [valid]: VerificationFunctionResults = flow(
    verifyDetailsAreValid,
    verify(jobCancellationReasonIsNotAvailableOnTheDay),
    verify(doesCancellationReasonHaveADiscount),
    verify(saveOfferHasBeenAnswered),
    verify(hasDepositBeenPaid)
  )(props)

  return valid === VerificationResult.Valid || valid === VerificationResult.Clear
}
type VerificationFunctionResults = [VerificationResult, HaveAllTasksBeenCompletedProps]
type VerificationFunction = (props: HaveAllTasksBeenCompletedProps) => VerificationFunctionResults

const verify =
  (func: VerificationFunction) =>
  (props: [VerificationResult, HaveAllTasksBeenCompletedProps]): VerificationFunctionResults => {
    const [result, params] = props
    if (result === VerificationResult.Invalid || result === VerificationResult.Clear) {
      return [result, params]
    }
    return func(params)
  }

const verifyDetailsAreValid = (props: HaveAllTasksBeenCompletedProps): VerificationFunctionResults => {
  const { customerId, jobId, jobCancellationReason } = props
  if (!customerId || !jobId || !jobCancellationReason) {
    return [VerificationResult.Invalid, props]
  }
  return [VerificationResult.Valid, props]
}

const jobCancellationReasonIsNotAvailableOnTheDay = (
  props: HaveAllTasksBeenCompletedProps
): VerificationFunctionResults => {
  const { jobCancellationReason } = props

  if (jobCancellationReason === JobCancellationReason.NotAvailableOnTheDay) {
    return [VerificationResult.Clear, props]
  }
  return [VerificationResult.Valid, props]
}

const saveOfferHasBeenAnswered = (props: HaveAllTasksBeenCompletedProps): VerificationFunctionResults => {
  const { otherCancellationReason, acceptJobOffer, discountValid, jobCancellationOffer } = props
  const doesCancellationWorkflowHaveAnOffer =
    jobCancellationOffer !== undefined &&
    jobCancellationOffer !== JobOffers.None &&
    discountValid !== TravelFeeDiscountValid.Invalid

  if (otherCancellationReason !== undefined && acceptJobOffer === undefined && doesCancellationWorkflowHaveAnOffer) {
    return [VerificationResult.Invalid, props]
  }

  return [VerificationResult.Valid, props]
}

const doesCancellationReasonHaveADiscount = (props: HaveAllTasksBeenCompletedProps): VerificationFunctionResults => {
  const { jobCancellationOffer } = props
  if (jobCancellationOffer === JobOffers.None) {
    return [VerificationResult.Clear, props]
  }
  return [VerificationResult.Valid, props]
}

const hasDepositBeenPaid = (props: HaveAllTasksBeenCompletedProps): VerificationFunctionResults => {
  const { depositHasBeenPaid, jobValue, acceptJobOffer } = props
  const isDepositRequired = acceptJobOffer !== YesNoValue.Yes
  if (!isDepositRequired) {
    return [VerificationResult.Valid, props]
  }
  if (depositHasBeenPaid === undefined) {
    return [VerificationResult.Invalid, props]
  }

  if (depositHasBeenPaid === true && !jobValue) {
    return [VerificationResult.Invalid, props]
  }

  return [VerificationResult.Valid, props]
}
