import { EuiCallOut, IconType } from '@elastic/eui'
import { CustomerFragment, CustomerMergeStatus } from '../api/generated-types'
import { ReactNode } from 'react'
import EuiCustomLink from '../common/eui-custom-link'

export interface CustomerMergeStatusViewProps {
  customer: CustomerFragment
}

interface MergeStatusCallout {
  iconType: IconType
  color: 'warning' | 'primary' | 'success' | 'danger'
  title: ReactNode | string
}

export const CustomerMergeStatusView = ({ customer }: CustomerMergeStatusViewProps) => {
  const callout = getMergeStatusCallout(customer)
  if (!callout) {
    return <></>
  }

  return <EuiCallOut size="m" iconType={callout.iconType} title={callout.title} color={callout.color} />
}

const getMergeStatusCallout = (customer: CustomerFragment): MergeStatusCallout | undefined => {
  const mergedCustomers = customer.mergedCustomers ?? []
  const masterCustomer = customer.masterCustomer
  switch (customer.mergeStatus) {
    case CustomerMergeStatus.Master:
      return {
        color: 'primary',
        iconType: 'accessibility',
        title: (
          <>
            Master customer supersedes:{` `}
            {mergedCustomers.map((c, index) => (
              <span key={c.id}>
                <EuiCustomLink key={c.id} to={`/customers/${c.id}`}>
                  {c.number}: {c.mainContact?.detail?.fullName ?? 'No name'}
                </EuiCustomLink>
                {index < mergedCustomers.length - 1 && ', '}
              </span>
            ))}
          </>
        )
      }
    case CustomerMergeStatus.Merged:
      return {
        color: 'warning',
        iconType: 'branchUser',
        title: (
          <>
            Merged into master{' '}
            <EuiCustomLink to={`/customers/${masterCustomer?.id}`}>
              {masterCustomer?.number}: {masterCustomer?.mainContact?.detail?.fullName ?? 'No name'}
            </EuiCustomLink>
          </>
        )
      }
    case CustomerMergeStatus.None:
      return undefined
  }
}
