import { JobTopicType } from '@fallonsolutions/types'
import { JobType, TradeType } from '../../../api/generated-types'
import { CustomerSuppliedCompliant } from '../../helpers/item-installer-questions'
import { YesNoValue } from '../common/common-enums'
import {
  TopicQualification,
  TopicQualificationResult,
  TopicRequirementsResult,
  createOutcomeGenerator
} from '../common/common-outcome'
import { PreExistingOrNewInstall, PumpSymptom, TopicPlumbingPumpActionResult } from './action-topic-plumbing-pump-model'
import { PumpUsageType } from './action-topic-pump-usage-model'

const completedCaseSpecificQuestions = (result: TopicPlumbingPumpActionResult | undefined) => {
  const {
    symptom,
    pumpUsage,
    itemAge,
    itemIssue,
    itemLocation,
    itemUsageNote,
    buildingUnderHouse,
    renovatingOldHouseStyle,
    hasThisHappenedInThePast,
    preExistingPumpOrNewInstall,
    itemSupplier,
    existingItemFailed,
    reasonForInstall,
    hasPowerTripped,
    poolSize,
    australianCompliant
  } = result ?? {}

  switch (symptom) {
    case PumpSymptom.NotWorking:
    case PumpSymptom.TrippingPower:
      return !!itemAge?.trim() && !!itemUsageNote?.trim() && !!hasThisHappenedInThePast
    default:
      if (pumpUsage === PumpUsageType.Sani || pumpUsage === PumpUsageType.Macerator) {
        return !!existingItemFailed && !!reasonForInstall?.trim() && !!buildingUnderHouse && !!renovatingOldHouseStyle
      }
      if (pumpUsage === PumpUsageType.Pool) {
        const { jobIsCompliant } = CustomerSuppliedCompliant({ australianCompliant })

        return preExistingPumpOrNewInstall === PreExistingOrNewInstall.PreExisting
          ? !!poolSize?.trim()
          : !!preExistingPumpOrNewInstall && !!itemSupplier && !!poolSize?.trim() && jobIsCompliant
      }
      if (pumpUsage === PumpUsageType.Greywater) {
        return !!reasonForInstall?.trim() && !!hasPowerTripped && !!itemAge?.trim()
      }
      return !!itemAge?.trim() && !!itemIssue?.trim() && !!itemLocation
  }
}

export const getRequirements = (result?: TopicPlumbingPumpActionResult): TopicRequirementsResult => {
  if (completedCaseSpecificQuestions(result)) {
    return {
      requirements: {
        attributes: [{ attributeId: 'GeneralPlumbing' }]
      }
    }
  }
  return {}
}

const getQualification = (result: TopicPlumbingPumpActionResult | undefined): TopicQualificationResult => {
  const { tripsWithOtherAppliances, hasPowerTripped, dsrConfirmed, proceedWithReplacement, pumpUsage, symptom } =
    result ?? {}

  const generalPump =
    !!pumpUsage &&
    [PumpUsageType.Rainwater, PumpUsageType.Stormwater, PumpUsageType.Sump, PumpUsageType.Rainbank].includes(pumpUsage)
  const blockedOrLeaking = !!symptom && [PumpSymptom.Blocked, PumpSymptom.Leaking].includes(symptom)

  if (generalPump && blockedOrLeaking && proceedWithReplacement === YesNoValue.No) {
    return { qualification: TopicQualification.OutOfScope }
  }
  // const canComplete =
  // canContinue && ((completedCaseSpecificQuestions() && !isCommercial) || commercialJobConfirmedWithDSR)
  if (dsrConfirmed) {
    return {
      qualification: dsrConfirmed === YesNoValue.Yes ? TopicQualification.InScope : TopicQualification.OutOfScope
    }
  }

  if (tripsWithOtherAppliances === YesNoValue.Yes || hasPowerTripped === YesNoValue.Yes) {
    return {
      qualification: TopicQualification.NewTopic,
      newTopic: {
        reference: JobTopicType.TrippingPower,
        buttonLabel: 'Switch topic to Tripping Power'
      }
    }
  }

  if (completedCaseSpecificQuestions(result)) {
    console.log('completedCaseSpecificQuestions ' + completedCaseSpecificQuestions(result))
    return {
      qualification: TopicQualification.InScope
    }
  }

  return {
    qualification: TopicQualification.Unknown
  }
}

export const outcomeGenerator = createOutcomeGenerator<TopicPlumbingPumpActionResult>(
  {
    qualification: TopicQualification.Unknown,
    trade: TradeType.Plumbing,
    type: JobType.Service,
    category: 'Pump'
  },
  getQualification,
  getRequirements
)
