import { EuiIcon, EuiKeyPadMenu, EuiKeyPadMenuItem, EuiSpacer, IconType } from '@elastic/eui'
import { PlatformIcon } from '@fallonsolutions/types'
import { Callout } from '../../common/callout'
import { decameliseIfNotNil } from '../../common/utils'
import { QuestionFieldProps } from './question-field-props'

interface ConvertibleToString {
  toString: () => string
}

export interface AnswerOption<T> {
  id: T
  label?: string // defaults to decamelised id
  icon?: IconType
  platformIcon?: PlatformIcon
}

export interface MultiSelectQuestionFieldProps<TAnswer> extends QuestionFieldProps<TAnswer[]> {
  options: AnswerOption<TAnswer>[]
}

export const MultiSelectField = <TAnswer extends ConvertibleToString>(
  props: MultiSelectQuestionFieldProps<TAnswer>
) => {
  const { question, questionType, hint, answer, changeAnswer, options } = props
  const showQuestion = props.showQuestion ?? true

  const toggleOption = (option: TAnswer) => {
    const currentlySelected = answer?.includes(option)
    if (currentlySelected) {
      changeAnswer(answer?.filter((o) => o !== option))
    } else {
      changeAnswer([...(answer ?? []), option])
    }
  }

  return (
    <>
      {showQuestion && (
        <Callout type={questionType ?? 'script'} data-test-id="workflow-question">
          {question}
        </Callout>
      )}
      {hint && (
        <Callout type="note" data-test-id="workflow-question-hint">
          {hint}
        </Callout>
      )}
      <EuiKeyPadMenu className="fs-keypadmenu" data-test-id="workflow-answer-container">
        {options.map((option) => (
          <EuiKeyPadMenuItem
            key={option.id.toString()}
            id={option.id.toString()}
            label={option.label ?? decameliseIfNotNil(option.id.toString())}
            isSelected={answer?.includes(option.id)}
            onClick={() => toggleOption(option.id)}
          >
            <EuiIcon type={answer?.includes(option.id) ? 'plusInCircle' : (option.icon ?? 'empty')} size="xl" />
          </EuiKeyPadMenuItem>
        ))}
      </EuiKeyPadMenu>
      <EuiSpacer />
    </>
  )
}
