import { useQuery } from '@apollo/client'
import { EuiLoadingSpinner } from '@elastic/eui'
import { compact } from 'lodash-es'
import { GetContactActivityDocument } from '../api/generated-types'
import CommentList from '../comments/comment-list'

export interface ContactActivityListProps {
  contactId: string
}

export const ContactActivityList = (props: ContactActivityListProps) => {
  const { contactId } = props

  const { data, loading } = useQuery(GetContactActivityDocument, { variables: { input: { contactId } } })

  const comments = compact(data?.getContact?.contact?.comments ?? [])
  const activities = compact(data?.getContact?.contact?.activities ?? [])

  return loading ? <EuiLoadingSpinner /> : <CommentList comments={comments} activities={activities} />
}
