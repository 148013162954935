import React from 'react'
import { EuiCallOut, EuiSpacer } from '@elastic/eui'
import '../static/css/invoice.css'

export const InvoiceLegacyMessage = () => {
  return (
    <div>
      <EuiSpacer size="s" />
      <EuiCallOut
        size="s"
        title="This invoice was raised through Axia. Any payments or adjustments for this invoice will need to be managed by finance team directly in MYOB."
        iconType="alert"
        color="warning"
      />
      <EuiSpacer size="s" />
    </div>
  )
}
