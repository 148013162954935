import { EuiButton, EuiSpacer } from '@elastic/eui'
import { EnquiryAbortReason } from '../../../api/generated-types'
import { Callout } from '../../../common/callout'
import { QuestionDefinitions, getNotesGenerator } from '../../helpers/notes-generator'
import { getResultUpdater } from '../../helpers/results-updater'
import { useWorkflow } from '../../helpers/workflow-provider'
import { SingleSelectField } from '../../question-fields/single-select-question-field'
import { WorkflowActionProps } from '../../workflow-model'
import { TopicActionInput } from '../action-topic-model'
import { TopicWorkflowAction } from '../action-topic-view'
import { YesNoValue } from '../common/common-enums'
import { yesNoOptions } from '../common/common-options'
import { TopicQualification } from '../common/common-outcome'
import { reset } from '../common/common-utils'
import { GasServiceType, TopicPlumbingGasActionResult, gasServiceTypeOptions } from './action-topic-plumbing-gas-model'
import { outcomeGenerator } from './action-topic-plumbing-gas-requirements'

const questionDefinitions: QuestionDefinitions<TopicPlumbingGasActionResult> = {
  gasConversion: {
    question: 'Is this for a conversion from LPG to natural gas or natural gas to LPG?'
  },
  gasServiceType: {
    question: 'What gas service do you need?'
  },
  customerHasConversionKit: {
    question: 'Do you have a conversion kit?'
  }
}
const notesGenerator = getNotesGenerator(questionDefinitions)

export const TopicPlumbingGasAction = (props: WorkflowActionProps<TopicActionInput, TopicPlumbingGasActionResult>) => {
  const { result, input, onUpdate } = props
  const { jobDescription, customerType, gasConversion, customerHasConversionKit, gasServiceType } = result ?? {}
  const workflowContext = useWorkflow()

  const topicOutcome = outcomeGenerator(result)
  const { requirements } = topicOutcome
  const isOutOfScope = topicOutcome.qualification === TopicQualification.OutOfScope
  const canComplete = topicOutcome.qualification === TopicQualification.InScope
  const newTopic = topicOutcome.newTopic

  const onNext = () =>
    onUpdate({
      ...result,
      ...topicOutcome,
      actionCompleted: true,

      workRequiredNotes: generatedNotes,
      requirements
    })

  const generatedNotes = notesGenerator.generateNotes(result ?? {})
  const updateResult = getResultUpdater(result ?? {}, onUpdate).updateResult

  return (
    <TopicWorkflowAction input={input} onUpdate={onUpdate} result={result}>
      <SingleSelectField
        question={notesGenerator.getQuestion('gasConversion')}
        answer={gasConversion}
        changeAnswer={(gasConversion) =>
          updateResult({
            ...reset(result),
            customerType,
            jobDescription,
            gasConversion
          })
        }
        options={yesNoOptions}
      />
      <EuiSpacer size="m" />
      {gasConversion === YesNoValue.Yes && (
        <>
          <SingleSelectField
            question={notesGenerator.getQuestion('customerHasConversionKit')}
            answer={customerHasConversionKit}
            changeAnswer={(customerHasConversionKit) =>
              updateResult({
                customerHasConversionKit
              })
            }
            options={yesNoOptions}
          />
        </>
      )}
      {gasConversion === YesNoValue.No && (
        <>
          <SingleSelectField
            question={notesGenerator.getQuestion('gasServiceType')}
            answer={gasServiceType}
            changeAnswer={(gasServiceType) =>
              updateResult({
                gasServiceType
              })
            }
            options={gasServiceTypeOptions}
          />
          {gasServiceType === GasServiceType.GasLeak && (
            <Callout type="warning" title="TODO: Gas Safety questions are needed!" />
          )}
        </>
      )}

      <EuiSpacer />
      {newTopic && (
        <EuiButton onClick={() => workflowContext.changeJobTopic(newTopic.reference)}>{newTopic.buttonLabel}</EuiButton>
      )}

      {isOutOfScope && !newTopic && (
        <>
          <Callout type="script">
            {topicOutcome.qualificationMessage
              ? topicOutcome.qualificationMessage
              : 'Unfortunately this type of work is out of scope for us.'}
          </Callout>
          <EuiSpacer />
          <EuiButton
            color="warning"
            onClick={() =>
              workflowContext.abort({
                abortReason: EnquiryAbortReason.CustomerNeedsAreOutOfService,
                notes: `Customer was enquiring about: ${topicOutcome.qualificationMessage}`
              })
            }
          >
            Offer a voucher and end call
          </EuiButton>
        </>
      )}

      {!isOutOfScope && !newTopic && (
        <>
          {!!topicOutcome.qualificationMessage && (
            <>
              {topicOutcome.qualificationMessage}
              <EuiSpacer size="m" />
            </>
          )}

          <EuiButton disabled={!canComplete} onClick={() => onNext()}>
            Next
          </EuiButton>
        </>
      )}
    </TopicWorkflowAction>
  )
}
