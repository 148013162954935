import { BaseActionInput, BaseActionResult, EnquiryTypeReference, WorkflowActionProps } from '../workflow-model'
import { EuiButton, EuiLink, EuiSpacer } from '@elastic/eui'
import { Callout } from '../../common/callout'
import { PillGroup, PillGroupOption } from '../../pill/pill-group'
import { WorkflowAction } from '../workflow-action'
import { CustomerAction, CustomerActionResult } from './action-customer'
import { SelectJobAction, SelectJobActionResult } from './action-select-job'
import { ContactActionResult } from './action-contact'
import { JobSummaryContainer } from '../../jobs/job-summary-container'
import { EuiText } from '@elastic/eui'
import { SingleSelectField } from '../question-fields/single-select-question-field'
import { decamelise } from '../../common/utils'
import { YesNoValue } from '../topics/common/common-enums'
import {
  AllDiscountsDocument,
  ApplyDiscountDocument,
  ArrangementCancellationReason,
  CancelJobDocument,
  GetJobDocument,
  JobFragment,
  JobStatusType,
  UpdateJobNotesDocument,
  UpdateJobStatusDocument,
  UserFragment
} from '../../api/generated-types'
import { useMutation, useQuery } from '@apollo/client'
import { DateTime } from 'luxon'
import { toUpper } from 'lodash-es'
import { useAuthenticated } from '../../auth/authenticated-context'
import { getUserInitials } from '../helpers/get-user-initials'
import { haveAllTasksBeenCompleted } from './cancel-job/all-tasks-completed'
import { EnquiryTypeActionResult } from './action-enquiry-type'
import { useEffect } from 'react'

export enum TravelFeeDiscountValid {
  Valid = 'Valid',
  Invalid = 'Invalid',
  Unknown = 'Unknown'
}

export enum JobCancellationReason {
  NotAvailableOnTheDay = 'NotAvailableOnTheDay',
  Other = 'Other'
}

export interface CancelJobActionInput extends BaseActionInput {
  contact?: ContactActionResult
  updateEnquiryType?: (enquiryType: EnquiryTypeActionResult) => void
}

enum JobValue {
  Under1000 = 'Under $1000',
  Over1000 = 'Over $1000'
}
export interface CancelJobActionResult extends BaseActionResult {
  customer?: CustomerActionResult
  job?: SelectJobActionResult
  jobCancellationReason?: JobCancellationReason
  depositHasBeenPaid?: boolean
  isCustomerLiaisonManagerAvailable?: boolean
  otherCancellationReason?: CancellationReason
  jobValue?: JobValue
  acceptJobOffer?: YesNoValue
  acceptRebooking?: YesNoValue
}

export enum CancellationReason {
  NoLongerRequired = 'NoLongerRequired',
  FoundSomeoneSooner = 'FoundSomeoneSooner',
  Other = 'Other',
  NoReasonProvided = 'NoReasonProvided',
  UnableToBeHome = 'UnableToBeHome',
  ChangeOfMind = 'ChangeOfMind',
  AllOkAndWorkingNow = 'AllOkAndWorkingNow',
  NotProceedingAtThisTime = 'NotProceedingAtThisTime',
  FixedByCustomerOrFriend = 'FixedByCustomerOrFriend',
  Price = 'Price'
}
export enum JobOffers {
  HalfPriceTravelFee = 'HalfPriceTravelFee',
  Voucher40 = '$40Voucher',
  None = 'None'
}

export const cancellationReasonToJobOfferMap: Record<CancellationReason, JobOffers> = {
  NoLongerRequired: JobOffers.Voucher40,
  FoundSomeoneSooner: JobOffers.None,
  Other: JobOffers.HalfPriceTravelFee,
  NoReasonProvided: JobOffers.Voucher40,
  UnableToBeHome: JobOffers.None,
  ChangeOfMind: JobOffers.HalfPriceTravelFee,
  AllOkAndWorkingNow: JobOffers.HalfPriceTravelFee,
  NotProceedingAtThisTime: JobOffers.HalfPriceTravelFee,
  FixedByCustomerOrFriend: JobOffers.HalfPriceTravelFee,
  Price: JobOffers.HalfPriceTravelFee
}

export enum CancelJobTask {
  CancelJob = 'CancelJob',
  ApplyDiscount = 'ApplyDiscount',
  IHaveCompletedTheseSteps = 'IHaveCompletedTheseSteps',
  PutOnHold = 'PutOnHold',
  Unknown = 'Unknown'
}

const halfPriceDiscountCode = 'HalfPriceTravelFee'
const voucher40DiscountCode = 'web40'

export const CancelJobAction = (props: WorkflowActionProps<CancelJobActionInput, CancelJobActionResult>) => {
  const { input, onUpdate, result } = props
  const user = useAuthenticated()?.userFragment
  const { contact, updateEnquiryType } = input ?? {}
  const {
    customer,
    job,
    jobCancellationReason,
    depositHasBeenPaid,
    isCustomerLiaisonManagerAvailable,
    otherCancellationReason,
    jobValue,
    acceptJobOffer,
    acceptRebooking
  } = result ?? {}

  const jobId = job?.actionCompleted ? job?.job?.id : undefined
  const customerId = customer?.actionCompleted ? customer?.customer?.id : undefined
  const { data: discountData } = useQuery(AllDiscountsDocument)
  const halfPriceDiscount = discountData?.allDiscounts?.discounts?.find((d) => d?.code === halfPriceDiscountCode)?.id
  const voucher40Discount = discountData?.allDiscounts?.discounts?.find((d) => d?.code === voucher40DiscountCode)?.id
  const { data: getJob } = useQuery(GetJobDocument, {
    variables: {
      id: jobId ?? 'no-id'
    },
    skip: !jobId
  })

  const [cancelJob, { loading: cancelJobLoading }] = useMutation(CancelJobDocument, {
    onCompleted: () => onUpdate({ ...result, actionCompleted: true })
  })
  const [applyDiscount, { loading: applyDiscountLoading }] = useMutation(ApplyDiscountDocument, {
    onCompleted: () => onUpdate({ ...result, actionCompleted: true })
  })

  const [putJobOnHold, { loading: putJobOnHoldLoading }] = useMutation(UpdateJobStatusDocument, {
    onCompleted: () => onUpdate({ ...result, actionCompleted: true })
  })

  const [updateJobNotes, { loading: updateJobNotesLoading }] = useMutation(UpdateJobNotesDocument)
  const loading = cancelJobLoading || applyDiscountLoading || putJobOnHoldLoading || updateJobNotesLoading
  const setCustomer = (customer: CustomerActionResult | undefined) => onUpdate({ ...result, customer })
  const setJob = (job: SelectJobActionResult | undefined) => onUpdate({ ...result, job })
  const otherCancellationReasonOptions = Object.values(CancellationReason).map((reason) => ({
    id: reason,
    label: decamelise(reason)
  }))
  const jobCancellationOffer = otherCancellationReason
    ? cancellationReasonToJobOfferMap[otherCancellationReason]
    : JobOffers.None
  const discountValid = isHalfPriceTravelFeeValid(getJob?.getJob, jobCancellationOffer)

  const discountNotFound =
    (jobCancellationOffer === JobOffers.HalfPriceTravelFee && !halfPriceDiscount) ||
    (jobCancellationOffer === JobOffers.Voucher40 && !voucher40Discount)

  const jobTask = jobTaskOutcome(result)

  const rebookingQuestionAnswered = jobCancellationOffer === JobOffers.None && acceptRebooking !== undefined
  const askIfDepositHasBeenPaidQuestion =
    otherCancellationReason &&
    (acceptJobOffer === YesNoValue.No || discountValid === TravelFeeDiscountValid.Invalid || rebookingQuestionAnswered)

  // TODO, if acceptRebooking is set to true switch over workflow to rebooking workflow
  // TODO ideally job details will be automatically loaded in to save CSR time
  useEffect(() => {
    if (acceptRebooking === YesNoValue.Yes) {
      onUpdate({ ...result, acceptRebooking: undefined })
      updateEnquiryType?.({ enquiryType: EnquiryTypeReference.Rebooking })
    }
  }, [acceptRebooking, updateEnquiryType])

  const nextButtonText =
    jobTask === CancelJobTask.IHaveCompletedTheseSteps
      ? 'I have completed these steps'
      : jobTask === CancelJobTask.ApplyDiscount
        ? 'Apply discount to job'
        : jobTask === CancelJobTask.PutOnHold
          ? 'Put job on hold'
          : 'Cancel job'

  const handleNextButton = async () => {
    if (!jobId) {
      onUpdate({ ...result, actionCompleted: true })
      return
    }
    const jobNotes = getJobNotes({
      user,
      jobTask,
      cancellationReason: otherCancellationReason,
      offer: jobCancellationOffer
    })
    // append job notes first before firing off any other mutations
    if (jobNotes) {
      const notes = getJob?.getJob?.notes?.[0] ?? '' // currently all the notes are only in the first index
      const newNotes = jobNotes.concat('\n\n').concat(notes)
      await updateJobNotes({
        variables: {
          input: {
            job: jobId,
            notes: newNotes
          }
        }
      })
    }
    switch (jobTask) {
      case CancelJobTask.CancelJob:
        cancelJob({
          variables: {
            input: {
              id: jobId,
              reason: ArrangementCancellationReason.Complaint
            }
          }
        })
        break
      case CancelJobTask.ApplyDiscount:
        switch (jobCancellationOffer) {
          case JobOffers.HalfPriceTravelFee:
            if (halfPriceDiscount) {
              applyDiscount({
                variables: {
                  input: {
                    jobId,
                    discountId: halfPriceDiscount
                  }
                }
              })
            } else {
              // what if discount is not found
              onUpdate({ ...result, actionCompleted: true })
            }
            break
          case JobOffers.Voucher40:
            if (voucher40Discount) {
              applyDiscount({
                variables: {
                  input: {
                    jobId,
                    discountId: voucher40Discount
                  }
                }
              })
            } else {
              onUpdate({ ...result, actionCompleted: true })
            }
        }
        break
      case CancelJobTask.PutOnHold:
        putJobOnHold({
          variables: {
            input: {
              job: jobId,
              status: JobStatusType.OnHold
            }
          }
        })
        break
      default:
        onUpdate({ ...result, actionCompleted: true })
        break
    }
  }

  const allTasksCompleted =
    jobId &&
    customerId &&
    haveAllTasksBeenCompleted({
      ...result,
      jobId,
      jobCancellationReason,
      customerId,
      discountValid,
      jobCancellationOffer
    })
  return (
    <>
      <CustomerAction input={{ contact }} result={customer} onUpdate={setCustomer} />
      {customerId && (
        <>
          <SelectJobAction input={{ customerId }} result={job} onUpdate={setJob} />
        </>
      )}

      {jobId && (
        <CancellationReasonAction
          input={{}}
          result={result}
          onUpdate={(resultFragment) => onUpdate({ ...result, ...resultFragment })}
          onBack={() => console.log('back')}
        />
      )}

      {result?.jobCancellationReason === JobCancellationReason.NotAvailableOnTheDay && (
        <Callout type="note" title="Proceed with Rebooking flow by changing the Enquiry Type" />
      )}

      {result?.jobCancellationReason === JobCancellationReason.Other && (
        <>
          <SingleSelectField
            options={otherCancellationReasonOptions}
            question={''}
            showQuestion={false}
            answer={otherCancellationReason}
            changeAnswer={(answer) => onUpdate({ ...result, otherCancellationReason: answer })}
          />
          {otherCancellationReason &&
            !!jobCancellationOffer &&
            jobCancellationOffer !== JobOffers.None &&
            discountValid === TravelFeeDiscountValid.Valid && (
              <>
                <EuiSpacer />
                <Callout type="note" title="Save Offer">
                  <EuiText>Talk to customer about the following voucher if they don't cancel the job</EuiText>
                  <EuiSpacer size="xs" />
                </Callout>
                <EuiSpacer size="xs" />
                <Callout type="script">
                  <EuiText>
                    I would like to offer you a voucher for a {decamelise(jobCancellationOffer)} if you decide to keep
                    your booking with us
                  </EuiText>
                </Callout>
                <EuiSpacer size="xs" />
                <SingleSelectField
                  options={[
                    {
                      id: YesNoValue.Yes,
                      label: 'Accepted Offer'
                    },
                    {
                      id: YesNoValue.No,
                      label: 'Did not accept offer'
                    }
                  ]}
                  question={''}
                  showQuestion={false}
                  answer={acceptJobOffer}
                  changeAnswer={(acceptJobOffer) => onUpdate({ ...result, acceptJobOffer })}
                />
              </>
            )}
          {otherCancellationReason && jobCancellationOffer === JobOffers.None && (
            <>
              <EuiSpacer />
              <Callout type="note" title="Saves Offer">
                <EuiText>Talk to customer about rebooking for a later date</EuiText>
              </Callout>
              <SingleSelectField
                options={[
                  {
                    id: YesNoValue.Yes,
                    label: 'Customer wants to rebook'
                  },
                  {
                    id: YesNoValue.No,
                    label: 'Customer does not want to rebook'
                  }
                ]}
                question={''}
                showQuestion={false}
                answer={acceptRebooking}
                changeAnswer={(acceptRebooking) => onUpdate({ ...result, acceptRebooking })}
              />
              <EuiSpacer />
            </>
          )}
          {otherCancellationReason && acceptJobOffer === YesNoValue.Yes && discountNotFound && (
            <>
              <EuiSpacer size="xs" />
              <Callout type="note">
                <EuiText>Be sure to add the discount offered to the job notes</EuiText>
              </Callout>
            </>
          )}
          {otherCancellationReason && discountValid === TravelFeeDiscountValid.Invalid && (
            <>
              <EuiSpacer size="xs" />
              <Callout type="note">
                <EuiText>Job is not valid for a discount, proceed with cancelling the job</EuiText>
              </Callout>
            </>
          )}
          {askIfDepositHasBeenPaidQuestion && (
            <>
              <DepositPaidAction
                input={{}}
                result={result}
                onUpdate={(resultFragment) => onUpdate({ ...result, ...resultFragment })}
                onBack={() => console.log('back')}
              />

              {depositHasBeenPaid !== undefined && (
                <>
                  <Callout type="note" title="">
                    <EuiText>You are free to proceed with cancelling the job</EuiText>
                  </Callout>
                </>
              )}
              {depositHasBeenPaid === true && (
                <>
                  <EuiSpacer />
                  <SingleSelectField
                    options={[
                      {
                        id: JobValue.Over1000,
                        label: 'Value is over $1000'
                      },
                      {
                        id: JobValue.Under1000,
                        label: 'Value is under $1000'
                      }
                    ]}
                    question={'Is the job value over $1000?'}
                    answer={jobValue}
                    changeAnswer={(jobValue) => onUpdate({ ...result, jobValue: jobValue })}
                  />
                  {jobValue === JobValue.Over1000 && (
                    <>
                      <Callout type="script">
                        As there has been a deposit paid, I will need to transfer you through to our Customer Liaison
                        Manager (Alexander Takoushi - Plumbing Department) (Jade Dutton - Electrical Department) who
                        will be able to assist you further okay (customer name)?
                      </Callout>

                      {isCustomerLiaisonManagerAvailable !== false && (
                        <EuiButton
                          color="danger"
                          onClick={() => onUpdate({ ...result, isCustomerLiaisonManagerAvailable: false })}
                        >
                          Jade/Alex is not available at the moment
                        </EuiButton>
                      )}
                    </>
                  )}

                  {jobValue === JobValue.Under1000 && (
                    <>
                      <Callout type="note" title="Collect financial data">
                        <EuiText>
                          Obtain Bank account number and BSB number and name, send details to finance department to
                          process refund. Set expectation that it can take up to 10 business days
                        </EuiText>
                      </Callout>
                    </>
                  )}
                </>
              )}
              {isCustomerLiaisonManagerAvailable === false && (
                <>
                  <EuiSpacer />
                  <Callout type="script">
                    I'm sorry, our customer liaison manager is not available right now. Can I confirm your best contact
                    number? They will give you a call back shortly to discuss the refund/cancellation.
                  </Callout>
                  <Callout type="note">
                    <EuiText>
                      Send an email to <strong>team@C.Liaison@fallonsolutions.com.au</strong> and pass the following
                      information there:
                      <ul>
                        <li>Customer's full name and code</li>
                        <li>Customer's best contact number</li>
                        <li>The job number they want to cancel</li>
                      </ul>
                    </EuiText>
                  </Callout>
                </>
              )}
            </>
          )}
        </>
      )}

      {jobId && (
        <>
          <EuiSpacer />
          <div className="workflow__detail-wrapper">
            {jobId && (
              <>
                <JobSummaryContainer jobId={jobId} />
                <EuiSpacer />
                <EuiLink href={`/jobs/${jobId}`} target="_blank">
                  Open job card for more details
                </EuiLink>
              </>
            )}
          </div>
          <EuiSpacer />
          <EuiButton
            fill
            color="primary"
            onClick={handleNextButton}
            disabled={!allTasksCompleted || jobTask === CancelJobTask.Unknown}
            isLoading={loading}
          >
            {nextButtonText}
          </EuiButton>
        </>
      )}
    </>
  )
}

type CancellationReasonActionInput = BaseActionInput
interface CancellationReasonActionResult {
  jobCancellationReason?: JobCancellationReason
}

const CancellationReasonAction = (
  props: WorkflowActionProps<CancellationReasonActionInput, CancellationReasonActionResult>
) => {
  const { onUpdate, result } = props

  const options: PillGroupOption[] = [
    { id: JobCancellationReason.NotAvailableOnTheDay, label: 'Customer not available on the day', icon: 'calendar' },
    { id: JobCancellationReason.Other, label: 'Other', icon: 'questionInCircle' }
  ]

  const handleOnSelectOption = (option: string) => {
    onUpdate({ jobCancellationReason: option as JobCancellationReason })
  }

  const handleChange = () => {
    onUpdate({ jobCancellationReason: undefined })
  }

  const selectedOption = result?.jobCancellationReason
    ? (result.jobCancellationReason as JobCancellationReason)
    : undefined
  const selectedOptionLabel = options.filter((o) => o.id === selectedOption)?.[0]?.label

  return (
    <WorkflowAction title="Cancellation reason" value={selectedOptionLabel} onClickChange={handleChange}>
      <Callout type="script" title="For feedback purposes, may I ask the reason for cancelling?" />
      <EuiSpacer />
      <PillGroup options={options} value={selectedOption} onSelect={handleOnSelectOption} onClear={handleChange} />
    </WorkflowAction>
  )
}

type DepositPaidActionInput = BaseActionInput
interface DepositPaidActionResult {
  depositHasBeenPaid?: boolean
}

const DepositPaidAction = (props: WorkflowActionProps<DepositPaidActionInput, DepositPaidActionResult>) => {
  const { onUpdate, result } = props

  const completed = result?.depositHasBeenPaid !== undefined

  const options: PillGroupOption[] = [
    { id: 'true', label: 'Deposit paid', icon: 'check' },
    { id: 'false', label: 'No deposit', icon: 'cross' }
  ]

  const handleOnSelectOption = (id: string) => {
    onUpdate({ depositHasBeenPaid: id === 'true' })
  }

  const handleChange = () => {
    onUpdate({ depositHasBeenPaid: undefined })
  }

  const selectedOption = completed ? `${result?.depositHasBeenPaid}` : undefined
  const selectedOptionLabel = options.filter((o) => o.id === selectedOption)?.[0]?.label

  return (
    <WorkflowAction title="Deposit paid" value={selectedOptionLabel} onClickChange={handleChange}>
      <Callout type="note" title="Has a deposit been paid?" />
      <EuiSpacer />
      <PillGroup options={options} value={selectedOption} onSelect={handleOnSelectOption} onClear={handleChange} />
    </WorkflowAction>
  )
}
interface GetJobNotesParams {
  jobTask: CancelJobTask
  cancellationReason?: CancellationReason
  user: UserFragment
  offer?: JobOffers
}
const getJobNotes = (params: GetJobNotesParams): string | undefined => {
  const { jobTask, cancellationReason, user, offer } = params
  const date = DateTime.now().setLocale('en-au').toLocaleString()
  const time = DateTime.now().setLocale('en-au').toLocaleString(DateTime.TIME_SIMPLE)
  const userInitials = getUserInitials(user)
  switch (jobTask) {
    case CancelJobTask.CancelJob:
      return `${date} ${time} - Customer called to cancel job. Reason: ${decamelise(
        cancellationReason
      )} - ${userInitials}`
    case CancelJobTask.ApplyDiscount:
      return `**${toUpper(
        decamelise(offer)
      )}**\n\n   ${date} ${time} - Customer called to cancel job. Reason: ${decamelise(
        cancellationReason
      )}. Accepted offer ${offer ? `for ${decamelise(offer)}` : ''} - ${userInitials}`
    case CancelJobTask.PutOnHold:
      return `${date} ${time} - Customer called to cancel job. Reason: ${decamelise(
        cancellationReason
      )}. Job has been put on hold until deposit has been sorted - ${userInitials}`
    default:
      return undefined
  }
}

const jobTaskOutcome = (result: CancelJobActionResult | undefined): CancelJobTask => {
  const { acceptJobOffer, depositHasBeenPaid, jobValue } = result ?? {}
  if (result?.jobCancellationReason === JobCancellationReason.NotAvailableOnTheDay) {
    return CancelJobTask.IHaveCompletedTheseSteps
  } else if (acceptJobOffer === YesNoValue.Yes) {
    return CancelJobTask.ApplyDiscount
  } else if ((acceptJobOffer === YesNoValue.No || acceptJobOffer === undefined) && depositHasBeenPaid === true) {
    if (jobValue === JobValue.Over1000) {
      return CancelJobTask.PutOnHold
    } else if (jobValue === JobValue.Under1000) {
      return CancelJobTask.CancelJob
    }
  } else if (depositHasBeenPaid === false) {
    return CancelJobTask.CancelJob
  }

  return CancelJobTask.Unknown
}

const VALID_TEMPLATES = ['domestic', 'commercial']
const isHalfPriceTravelFeeValid = (
  job: JobFragment | undefined | null,
  jobOffer: JobOffers
): TravelFeeDiscountValid => {
  if (jobOffer !== JobOffers.HalfPriceTravelFee) {
    return TravelFeeDiscountValid.Valid
  }
  if (!job) {
    return TravelFeeDiscountValid.Unknown
  }
  const optionSheetTemplate = job?.requirements?.optionSheetTemplate ?? ''
  return VALID_TEMPLATES.includes(optionSheetTemplate) ? TravelFeeDiscountValid.Valid : TravelFeeDiscountValid.Invalid
}
