import { BaseActionInput, BaseActionResult, WorkflowActionProps } from '../workflow-model'
import { EuiButton, EuiLink, EuiSpacer } from '@elastic/eui'
import { Callout } from '../../common/callout'
import { CustomerAction, CustomerActionResult } from './action-customer'
import { SelectJobAction, SelectJobActionResult } from './action-select-job'
import { JobSummaryContainer } from '../../jobs/job-summary-container'
import { ContactActionResult } from './action-contact'

export interface FeedbackActionInput extends BaseActionInput {
  contact?: ContactActionResult
}
export interface FeedbackActionResult extends BaseActionResult {
  customer?: CustomerActionResult
  job?: SelectJobActionResult
}

export const FeedbackAction = (props: WorkflowActionProps<FeedbackActionInput, FeedbackActionResult>) => {
  const { onUpdate, result, input } = props
  const { contact } = input
  const { customer, job } = result ?? {}

  const setCustomer = (customer: CustomerActionResult) => onUpdate({ ...result, customer })
  const setJob = (job: SelectJobActionResult) => onUpdate({ ...result, job })
  const onCompleted = () => onUpdate({ ...result, actionCompleted: true })

  const jobId = job?.actionCompleted ? job?.job?.id : undefined
  const customerId = customer?.customer?.id

  const canComplete = !!jobId || (customer?.actionCompleted && !customer.customer?.id)

  return (
    <>
      <CustomerAction
        input={{ contact, allowContinueWithoutCustomer: true }}
        result={customer}
        onUpdate={setCustomer}
      />
      {customer?.actionCompleted && customerId && (
        <SelectJobAction input={{ customerId }} result={job} onUpdate={setJob} />
      )}
      {customer?.actionCompleted && customerId && jobId && (
        <>
          <div className="workflow__detail-wrapper">
            <JobSummaryContainer jobId={jobId} />
            <EuiSpacer />
            <EuiLink href={`/jobs/${jobId}`} target="_blank">
              Open job card for more details
            </EuiLink>
          </div>
          <EuiSpacer />
        </>
      )}
      {canComplete && (
        <>
          <Callout type="note" title="Collect all the kind words and post the feedback on Workplace 🥳" />
          <EuiSpacer />
          <EuiButton fill color="primary" onClick={onCompleted}>
            I've completed these steps
          </EuiButton>
        </>
      )}
    </>
  )
}
