import { EuiDescriptionList, EuiFlexGroup, EuiFlexItem } from '@elastic/eui'
import { chunk } from 'lodash-es'
import { ReactNode } from 'react'

interface DescriptionListProps {
  attributes: Array<{
    title: NonNullable<ReactNode>
    description: NonNullable<ReactNode>
  }>
  columns: number
}

export const DescriptionList = (props: DescriptionListProps) => {
  const { attributes, columns } = props

  if (attributes.length <= 0) {
    return <div></div>
  }

  const perColumn = Math.ceil(attributes.length / columns)
  const lists = chunk(attributes, perColumn)

  return (
    <EuiFlexGroup>
      {lists.map((attrs, index) => (
        <EuiFlexItem key={index}>
          <EuiDescriptionList listItems={attrs} textStyle="reverse" />
        </EuiFlexItem>
      ))}
    </EuiFlexGroup>
  )
}
