import { useQuery } from '@apollo/client'
import { EuiCallOut, EuiFieldText, EuiFlexGroup, EuiFlexItem, EuiLink, EuiSpacer } from '@elastic/eui'
import { ContactMediumRole, SearchContactsByPhoneDocument } from '../api/generated-types'
import { parsePhone } from '../common/phone'
import { ContactMediumRoleBadge } from './contact-medium-role-badge'

export interface ContactFormPhoneFieldProps {
  contactId?: string
  label?: string
  placeholder?: string
  phone: string
  isPrimary: boolean
  canRemove?: boolean
  onChange: (phone: string) => void
  onRemove?: () => void
  showValidationErrors: boolean
}

export const ContactFormPhoneField = ({
  contactId,
  placeholder,
  phone,
  isPrimary,
  onChange,
  onRemove,
  ...props
}: ContactFormPhoneFieldProps) => {
  const parsedPhone = parsePhone(phone)
  const phoneValid = !!parsedPhone

  const canRemove = (onRemove && props.canRemove) ?? false
  // No need to query until we have a valid email address and also don't need to query if
  // this email does not need to be unique
  const { data, error, loading } = useQuery(SearchContactsByPhoneDocument, {
    skip: !phoneValid,
    variables: {
      input: {
        phone: parsedPhone?.number
      }
    }
  })

  const contacts = data?.searchContactsByPhone?.results ?? []

  const duplicates = contacts.filter((contact) => contact.id !== contactId)
  const isDuplicate = duplicates.length > 0

  const showValidationErrors = props.showValidationErrors

  const canSwitchPrimary = false

  return (
    <>
      <EuiFlexGroup alignItems="center" gutterSize="s">
        <EuiFlexItem grow={false} style={{ minWidth: '260px' }}>
          <EuiFieldText
            autoComplete="off"
            icon={'mobile'}
            value={phone}
            placeholder={placeholder ?? 'Enter phone...'}
            onChange={(e) => onChange(e.target.value)}
            isInvalid={showValidationErrors && !phoneValid}
            data-test-id="contact-phone"
            isLoading={loading}
          />
        </EuiFlexItem>
        {canRemove && (
          <EuiFlexItem grow={false}>
            <EuiLink style={{ fontSize: '12px' }} color="subdued" onClick={onRemove}>
              Remove
            </EuiLink>
          </EuiFlexItem>
        )}
        <EuiFlexItem grow={false} style={{ width: '120px' }}>
          {isPrimary ? (
            <div style={{ display: 'inline-block' }}>
              <ContactMediumRoleBadge role={ContactMediumRole.Primary} />
            </div>
          ) : canSwitchPrimary ? (
            <EuiLink style={{ fontSize: '12px' }} color="subdued">
              Make primary
            </EuiLink>
          ) : (
            ''
          )}
        </EuiFlexItem>
        <EuiFlexItem grow={true} />
      </EuiFlexGroup>
      {error && (
        <>
          <EuiSpacer size="s" />
          <EuiCallOut iconType="branchUser" title="Error checking for duplicate phone number" color="danger" size="s">
            {error.message}
          </EuiCallOut>
        </>
      )}
      {isDuplicate && (
        <>
          <EuiSpacer size="s" />
          <EuiCallOut
            iconType="branchUser"
            title="Phone number already in use on another contact"
            color="warning"
            size="s"
          />
        </>
      )}
    </>
  )
}
