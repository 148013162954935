import { compact, forIn } from 'lodash-es'
import { decamelise } from '../../common/utils'
export interface QuestionDefinition<PropertyType> {
  question: string // interrogative mood
  statement?: string // declarative mood of the `question`
  makeAnswerHumanReadable?: (answer: PropertyType) => string
  excludeFromNotes?: boolean
  additionalStatement?: <Type extends {}>(results: Type) => string | undefined
  endOfTopicStatement?: <Type extends {}>(results: Type) => string | null
}

export type QuestionDefinitions<Type> = {
  [Property in keyof Type]?: QuestionDefinition<Type[Property]>
}

export interface NotesGenerator<Type> {
  getQuestion: <Property extends keyof Type>(key: Property) => string
  generateNotes: (answers: Type, title?: string) => string
  generateEndOfTopicNotes: <results extends {}, questions extends QuestionDefinitions<results>>(
    results: results,
    question: questions
  ) => string[] | null
}

export const getNotesGenerator = <Type,>(questionDefinitions: QuestionDefinitions<Type>): NotesGenerator<Type> => {
  const questionFallback = <Property extends keyof Type>(key: Property) => {
    const humanReadableKey = decamelise(key as string)
    return humanReadableKey[0].toUpperCase() + humanReadableKey.substring(1).toLowerCase()
  }

  const generateEndOfTopicNotes = <Results extends {}, Questions extends QuestionDefinitions<Results>>(
    results: Results,
    questionDefinitions: Questions
  ): string[] | null => {
    const notes: (string | null)[] = []
    // for in is a lodash function that loop through each key in an object
    forIn(questionDefinitions, (value) => {
      !!value && notes.push(value.endOfTopicStatement?.(results) ?? null)
    })
    const compactedNotes = compact(notes)
    return compactedNotes.length > 0 ? compactedNotes : null
  }

  const getQuestion = <Property extends keyof Type>(key: Property) => {
    return questionDefinitions[key]?.question ?? `${questionFallback(key)}:`
  }

  const toString = (answer: any): string => {
    if (!answer) return ''
    return typeof answer === 'string' ? answer : JSON.stringify(answer)
  }

  const formatAnswer = (answer: string | undefined, additionalStatement: string | undefined) => {
    const answerTrimmed = (answer ?? '').trim()
    const answerWithTrailingWhitespaceRemoved = answerTrimmed.replace(/[ \r\n\t]+$/g, '')
    const answerWithAdditionalStatement = additionalStatement
      ? `${answerWithTrailingWhitespaceRemoved}${NewLineCode}${NewLineCode}${additionalStatement}`
      : answerWithTrailingWhitespaceRemoved
    return answerWithAdditionalStatement
  }
  // answer is results of  questions from bouncing ball, the keys from the QuestionDenfition types is mapped to answer
  const generateNotes = (answers: Type) =>
    Object.keys(questionDefinitions)
      .map((key) => {
        const Property = key as keyof Type
        const questionDefinition = questionDefinitions[Property] as QuestionDefinition<any>
        const {
          statement,
          question,
          makeAnswerHumanReadable,
          excludeFromNotes: doNotIncludeInNotes,
          additionalStatement
        } = questionDefinition
        if (doNotIncludeInNotes === true) return undefined
        const extraStatement = additionalStatement?.(answers as {})
        const answer = answers?.[Property]
        return {
          question: statement ?? question,
          answer: makeAnswerHumanReadable ? makeAnswerHumanReadable(answer) : toString(answer),
          additionalStatement: extraStatement
          // additional conditional statement here
        }
      })
      .filter((e) => !!e && !!e.answer)
      .map((e) =>
        e ? `${e.question} ${formatAnswer(e.answer, e.additionalStatement)}${NewLineCode}${NewLineCode}` : ''
      )
      .join('')

  return {
    getQuestion,
    generateNotes,
    generateEndOfTopicNotes
  }
}

const NewLineCode = '\n'
