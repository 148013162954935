import { Action } from '@fallonsolutions/policy-decision-point'

export { Action }

// extended from policy decision point for clients using common
// Usage: once you add an action you must provide implementation in decision-point-isomorphic-logic
export enum WIPAction {
  DemoAction = 'DemoAction',
  ViewCosts = 'ViewCosts',
  ChangeEnquiryCustomerMembership = 'ChangeEnquiryCustomerMembership',
  UpdateJobClassification = 'UpdateJobClassification',
  ChangeUserIntegration = 'ChangeUserIntegration'
}

// https://stackoverflow.com/questions/48478361/how-to-merge-two-enums-in-typescript
export const AllAction = { ...Action, ...WIPAction }
export type AllAction = typeof AllAction

// duplicated for rapid client side change
export interface AuthorizationResult {
  ok: boolean
  error?: AuthorizationError
}

export enum AuthorizationError {
  UNAUTHORIZED = 'Unauthorized',
  PARAMETERS_REQUIRED = 'Parameters required'
}

//wip: type for dynamic key for specific action response
export type CanPerformAction<K extends keyof typeof Action> = {
  [key in K as `can${key}Ok`]: boolean
} & { [key in K as `can${key}Error`]?: AuthorizationError } & {
  ok: boolean
  error: AuthorizationError
}

//wip: type for dynamic key for specific action response
export type IsomorphicFn<K extends keyof typeof AllAction> = {
  [key in K as `${key}`]: Function
}

// enum Mammals {
//   Humans = 'Humans',
//   Bats = 'Bats',
//   Dolphins = 'Dolphins'
// }

// enum Reptiles {
//   Snakes = 'Snakes',
//   Alligators = 'Alligators',
//   Lizards = 'Lizards'
// }

// export const Animals = { ...Mammals, ...Reptiles };
// export type Animals = typeof Animals;
