import { EuiButton, EuiSpacer } from '@elastic/eui'
import { JobType, TradeType } from '../../../api/generated-types'
import { Callout } from '../../../common/callout'
import { decameliseIfNotNil } from '../../../common/utils'
import { QuestionDefinitions, getNotesGenerator } from '../../helpers/notes-generator'
import { getResultUpdater } from '../../helpers/results-updater'
import { JobTopicType } from '@fallonsolutions/types'
import { FreeTextQuestionField } from '../../question-fields/free-text-question-field'
import { SingleSelectField } from '../../question-fields/single-select-question-field'
import { WorkflowActionProps } from '../../workflow-model'
import { TopicActionInput } from '../action-topic-model'
import { TopicWorkflowAction } from '../action-topic-view'
import { ItemInstaller, ServiceType } from '../common/common-enums'
import { reset } from '../common/common-utils'
import { CustomerSuppliedTopicActionResult } from '../../action-topic-customer-supplied'
import { CustomerSuppliedCompliant, IsItemAustralianCompliant } from '../../helpers/item-installer-questions'
import { ItemSupplier } from '../action-topic-model'
import { OutOfScope } from '../../helpers/out-of-topic'
export interface TopicTelephoneDataPointActionInput extends TopicActionInput {
  topicReference: JobTopicType
}
export interface TopicTelephoneDataPointActionResult extends CustomerSuppliedTopicActionResult {
  serviceType?: ServiceType
  itemSupplier?: ItemSupplier
  itemInstaller?: ItemInstaller

  // installation
  desiredPhonePointsInTheBuilding?: string
  installationPointsPlacement?: string

  // repair
  faultDetails?: string
}

const questionDefinitions: QuestionDefinitions<TopicTelephoneDataPointActionResult> = {
  serviceType: {
    question: 'Is that an installation or a repair?',
    statement: 'Service type:',
    makeAnswerHumanReadable: decameliseIfNotNil
  },
  itemSupplier: {
    question: 'Who is supplying the equipment?',
    statement: 'Equipment supplied by:',
    makeAnswerHumanReadable: decameliseIfNotNil
  },
  itemInstaller: {
    question: 'Who did the installation?',
    statement: 'Installation done by:',
    makeAnswerHumanReadable: decameliseIfNotNil
  },
  // installation
  desiredPhonePointsInTheBuilding: {
    question: 'How many phone/data points do you want to go to in the house?',
    statement: 'Desired number of phone/data points in the house:'
  },
  installationPointsPlacement: {
    question: 'Where do you want it installed in the house?',
    statement: 'Phone/data points installation placement:'
  }
}
const notesGenerator = getNotesGenerator(questionDefinitions)

export const TopicTelephoneDataPointAction = (
  props: WorkflowActionProps<TopicTelephoneDataPointActionInput, TopicTelephoneDataPointActionResult>
) => {
  const { result, input, onUpdate } = props
  const {
    serviceType,
    itemSupplier,
    itemInstaller,
    // installation
    desiredPhonePointsInTheBuilding,
    installationPointsPlacement,
    jobDescription,
    australianCompliant,
    customerType
  } = result ?? {}
  const { jobIsCompliant, jobIsNotCompliant } = CustomerSuppliedCompliant({ australianCompliant })

  const isRevisit = serviceType === ServiceType.Repair && itemInstaller === ItemInstaller.Fallon

  const onNext = () =>
    onUpdate({
      ...result,
      actionCompleted: true,
      trade: TradeType.Data,
      category: input.topicReference === JobTopicType.PhoneLandLine ? 'Telephone' : 'General',
      type: isRevisit ? JobType.Callback : JobType.Service,

      workRequiredNotes: generatedNotes,
      requirements: {
        attributes: [{ attributeId: 'PhoneAndData' }]
      }
    })

  const generatedNotes = notesGenerator.generateNotes(result ?? {})
  const updateResult = getResultUpdater(result ?? {}, onUpdate).updateResult

  const canComplete =
    !!serviceType &&
    (!!itemSupplier || !!itemInstaller) &&
    ((serviceType === ServiceType.Installation &&
      !!desiredPhonePointsInTheBuilding &&
      !!installationPointsPlacement &&
      jobIsCompliant) ||
      serviceType === ServiceType.Repair)

  return (
    <TopicWorkflowAction input={input} onUpdate={onUpdate} result={result}>
      <SingleSelectField
        question={notesGenerator.getQuestion('serviceType')}
        options={[
          {
            id: ServiceType.Installation,
            label: 'Installation',
            icon: 'check'
          },
          {
            id: ServiceType.Repair,
            label: 'Repair',
            icon: 'wrench'
          }
        ]}
        answer={serviceType}
        changeAnswer={(serviceType) =>
          updateResult({
            ...reset(result ?? {}),
            customerType,
            serviceType,
            jobDescription
          })
        }
      />

      {serviceType === ServiceType.Installation && (
        <>
          <SingleSelectField
            question={notesGenerator.getQuestion('itemSupplier')}
            options={[
              {
                id: ItemSupplier.Fallon,
                label: 'Fallon',
                icon: 'check'
              },
              {
                id: ItemSupplier.Customer,
                label: 'Customer',
                icon: 'user'
              }
            ]}
            answer={itemSupplier}
            changeAnswer={(itemSupplier) =>
              updateResult({
                itemSupplier,
                faultDetails: undefined
              })
            }
          />
          <IsItemAustralianCompliant
            updateResult={updateResult}
            itemSupplier={itemSupplier}
            suppliedAnswer={australianCompliant}
          />
          {jobIsNotCompliant ? (
            <OutOfScope issue={'Telephone'} isOutOfScope={true} />
          ) : (
            <>
              <FreeTextQuestionField
                question={notesGenerator.getQuestion('desiredPhonePointsInTheBuilding')}
                answer={desiredPhonePointsInTheBuilding}
                changeAnswer={(desiredPhonePointsInTheBuilding) => updateResult({ desiredPhonePointsInTheBuilding })}
              />
              <FreeTextQuestionField
                question={notesGenerator.getQuestion('installationPointsPlacement')}
                answer={installationPointsPlacement}
                changeAnswer={(installationPointsPlacement) => updateResult({ installationPointsPlacement })}
              />
            </>
          )}
        </>
      )}

      {serviceType === ServiceType.Repair && (
        <>
          <SingleSelectField
            question={notesGenerator.getQuestion('itemInstaller')}
            options={[
              {
                id: ItemInstaller.Fallon,
                label: 'Fallon installed',
                icon: 'check'
              },
              {
                id: ItemInstaller.SomeoneElse,
                label: 'Someone else installed',
                icon: 'user'
              }
            ]}
            answer={itemInstaller}
            changeAnswer={(itemInstaller) =>
              updateResult({
                itemInstaller,
                desiredPhonePointsInTheBuilding: undefined,
                installationPointsPlacement: undefined
              })
            }
          />
        </>
      )}

      {/* TODO: This looks like a very common question asked during almost every job booking
      <Callout type="note" title="Is it double or single story house?" /> */}

      {isRevisit && (
        <>
          <Callout type="note">
            <ul>
              <li>
                Book same technician as the original Job and send work chat to Electrical team or email Electrical DSR
                Team
              </li>
              <li>If time not suitable or technician not available call Electrical DSR 99973</li>
            </ul>
          </Callout>
        </>
      )}

      {canComplete && (
        <>
          <EuiSpacer />
          <EuiButton onClick={() => onNext()}>Next</EuiButton>
        </>
      )}
    </TopicWorkflowAction>
  )
}
