import { YesNoValue } from '../common/common-enums'
import { ItemSupplier } from '../action-topic-model'
import { PumpUsageType } from './action-topic-pump-usage-model'
import { CustomerSuppliedTopicActionResult } from '../../action-topic-customer-supplied'

export enum PumpSymptom {
  Blocked = 'Blocked',
  Leaking = 'Leaking',
  NotWorking = 'NotWorking',
  TrippingPower = 'TrippingPower'
}

export enum HouseRenovationStyle {
  OldQueenslander = 'OldQueenslander',
  Other = 'Other'
}

export enum PreExistingOrNewInstall {
  PreExisting = 'PreExisting',
  NewInstall = 'NewInstall'
}

export enum PumpLocation {
  AboveGround = 'AboveGround',
  Submersible = 'Submersible'
}

export const renovatingOldHouseStyleOption = [
  { id: HouseRenovationStyle.OldQueenslander, label: 'Queenslander' },
  { id: HouseRenovationStyle.Other, label: 'Other' }
]

export interface TopicPlumbingPumpActionResult extends CustomerSuppliedTopicActionResult {
  symptom?: PumpSymptom
  pumpUsage?: PumpUsageType
  proceedWithReplacement?: YesNoValue
  itemIssue?: string
  itemAge?: string
  itemUsageNote?: string
  itemLocation?: string
  hasThisHappenedInThePast?: YesNoValue
  existingItemFailed?: YesNoValue
  buildingUnderHouse?: YesNoValue
  renovatingOldHouseStyle?: HouseRenovationStyle
  preExistingPumpOrNewInstall?: PreExistingOrNewInstall
  itemSupplier?: ItemSupplier
  poolSize?: string
  reasonForInstall?: string
  hasPowerTripped?: YesNoValue
  tripsWithOtherAppliances?: YesNoValue
  dsrConfirmed?: YesNoValue
}

export const pumpUsageOptions = [
  { id: PumpUsageType.Rainwater, label: 'Rain water' },
  { id: PumpUsageType.Stormwater, label: 'Storm water' },
  { id: PumpUsageType.Sump, label: 'Sump' },
  { id: PumpUsageType.Rainbank, label: 'Rain bank' },
  { id: PumpUsageType.Sani, label: 'Sani' },
  { id: PumpUsageType.Macerator, label: 'Macerator' },
  { id: PumpUsageType.Pool, label: 'Pool' },
  { id: PumpUsageType.Greywater, label: 'Grey water' }
]
