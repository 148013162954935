import { EuiFlexGroup, EuiFlexItem } from '@elastic/eui'
import { useHistory } from 'react-router-dom'
import { InteractionMessageLinkFragment } from '../api/generated-types'
import { InteractionMessageCard } from './interaction-message-card'
import '../static/css/recent-interaction-message-list.css'

export interface RecentInteractionMessageListProps {
  interactionMessages: InteractionMessageLinkFragment[]
  expanded: boolean
}

export const RecentInteractionMessageList = ({ interactionMessages, expanded }: RecentInteractionMessageListProps) => {
  const history = useHistory()

  return (
    <div
      className={`recent-interaction-message-list recent-interaction-message-list--${expanded ? 'expanded' : 'scroll'}`}
    >
      <EuiFlexGroup gutterSize="m" alignItems="flexStart" justifyContent="flexStart" wrap={expanded}>
        {interactionMessages.map((interactionMessage) => (
          <EuiFlexItem
            key={interactionMessage.id}
            grow={false}
            onClick={() => history.push(`/interaction-message/${interactionMessage.id}`)}
          >
            <InteractionMessageCard interactionMessage={interactionMessage} />
          </EuiFlexItem>
        ))}
      </EuiFlexGroup>
    </div>
  )
}
