import { EuiBasicTable, EuiKeyPadMenu, EuiKeyPadMenuItem, EuiSpacer, EuiText } from '@elastic/eui'
import { groupBy } from 'lodash-es'
import { MembershipStatusType } from '../../../api/generated-types'
import { Callout } from '../../../common/callout'
import { BouncingBallCustomerMembershipColumns } from '../../../memberships/membership-list-columns'
import { CustomerQualificationActionResult } from '../action-customer-qualification'
import { ExpiredMembershipsAccordion } from './customer-expired-membership-accordin'

export interface CustomerMembershipQuestionProps {
  excludeQuestion: boolean
  customerQualification: CustomerQualificationActionResult | undefined
  updateCustomer: (result: CustomerQualificationActionResult) => void
  alternativeDisplay?: JSX.Element // useful if we don't want to show the membership question and want to display something else. display question must be false
  displayMemberQuestion: boolean
}
/**
 * will ask render the question about the customer's membership status. Currently used in the customer qualification action and property qualification action
 * @param excludeQuestion if true, will not render anything and return null
 * @param customerQualification the customer object stored in the workflow
 * @param updateCustomer the function to update the customer object from higher up in the tree workflow
 * @param alternativeDisplay if we don't want to show the membership question and want to display something else. display member question must be false
 * @param displayMemberQuestion if true, will display the membership question. if false, will display the alternative display if it exists. Useful for conditional logic without needing to bake it in to the parent component
 * @return either the membership question or the alternative display or nothing at all
 */
export const CustomerMembershipQuestion = (props: CustomerMembershipQuestionProps) => {
  const { excludeQuestion, updateCustomer, customerQualification, alternativeDisplay, displayMemberQuestion } = props
  const { customerMemberships, existingMember } = customerQualification ?? {}

  const setExistingMember = (existingMember: boolean | undefined) =>
    updateCustomer({ ...customerQualification, existingMember })

  const groupMemberShips = groupBy(customerMemberships, (m) => m.status.status)
  if (excludeQuestion) {
    return null
  }
  return (
    <>
      <EuiSpacer />
      <>
        {displayMemberQuestion ? (
          <>
            <Callout type="script" title="Welcome back! Are you one of our members?" />
            <>
              {!!customerMemberships && (
                <>
                  {!!groupMemberShips[MembershipStatusType.Active] && (
                    <>
                      <EuiSpacer size="l" />
                      <EuiText> Active Membership(s)</EuiText>
                      <EuiSpacer size="l" />
                      <EuiBasicTable
                        items={groupMemberShips[MembershipStatusType.Active]}
                        columns={BouncingBallCustomerMembershipColumns()}
                      />
                      <EuiSpacer size="l" />
                    </>
                  )}
                  {!!groupMemberShips[MembershipStatusType.Expired] && (
                    <ExpiredMembershipsAccordion memberships={customerMemberships} />
                  )}
                </>
              )}
            </>
            <EuiKeyPadMenu className="fs-keypadmenu">
              <EuiKeyPadMenuItem
                id="existing-member-yes"
                isSelected={existingMember === true}
                label="Member"
                onClick={() => setExistingMember(true)}
              >
                <></>
              </EuiKeyPadMenuItem>
              <EuiKeyPadMenuItem
                id="existing-member-no"
                isSelected={existingMember === false}
                label="Not a member"
                onClick={() => setExistingMember(false)}
              >
                <></>
              </EuiKeyPadMenuItem>
            </EuiKeyPadMenu>

            {existingMember === true && (
              <>
                <EuiSpacer />
                <Callout
                  type="script"
                  title="Excellent, well as one of our members you will enjoy 15% off any work booked today"
                />
              </>
            )}

            {existingMember === false && (
              <>
                <EuiSpacer />
                <Callout
                  type="script"
                  title="That’s okay, our technician will discuss the additional benefits and discounts you would receive by taking this up on the day"
                />
              </>
            )}
          </>
        ) : (
          <> {alternativeDisplay} </>
        )}
      </>
    </>
  )
}
