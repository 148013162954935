import { isomorphicLogic as sharedIsomorphicLogic } from '@fallonsolutions/policy-decision-point'
import { MediaItemFragment, UserAuthFragment } from '../api/generated-types'
import { AuthorizationError, AuthorizationResult } from './decision-point-types'
// import { CanPerformAction } from './decision-point-types'

// const wasTechnicianAssigned = (user: UserAuthFragment, job: JobFragment) => {
//   return job.technicians?.map((t) => t?.id)?.includes(user?.id) ?? false
// }
//what is the right amount of restrictions operationally, e.g. technical issues and tech does it at night or next day.
// time limit? 7days after assignment to prevent accidental upload?
// const createMediaItemCheckAssignedTechsFn = (user: UserAuthFragment, context?: { job: JobFragment }) => {
//   return (context: { job: JobFragment }) => {
//     const { job } = context
//     const minPermissions = !!user.features?.jobPhotos && !!user?.permissions?.fieldApp
//     switch (user.role) {
//       case UserRole.Technician:
//         //if technician, verify that the technician has been assigned to the job
//         const assigned = wasTechnicianAssigned(user, job)
//         return {
//           ok: minPermissions && assigned
//         }
//       default:
//         return {
//           ok: minPermissions
//         }
//     }
//   }
// }

//supervisor manager, can always delete and restore
//technician, only if they were author
//duplicate of sharedIsomorphic
//todo: technician if they were assigned
//todo: time-restriction, avoid accidental deletions
const deleteMediaItemCheckFn = (user: UserAuthFragment, context?: { mediaItem: MediaItemFragment }) => {
  const supervisorAccess = !!user?.permissions?.customDiscounts && !!user.features?.jobPhotos
  if (supervisorAccess) {
    return {
      ok: supervisorAccess,
      error: undefined
    }
  } else {
    if (!context) {
      return { ok: false, error: AuthorizationError.PARAMETERS_REQUIRED }
    }
    const { mediaItem } = context
    const author = mediaItem?.author?.id === user.id
    // const assigned = wasTechnicianAssigned(user, context.job)
    return {
      ok: author && !!user.features?.jobPhotos,
      error: undefined
    }
  }
}

//over-ride shared Isomorphic logic here
//key of AllAction and Function with specific parameters and allways return AuthorizationResult
//one giant object, needs to be modularized so policies can segregated
export const isomorphicLogic = {
  ...sharedIsomorphicLogic,
  // CreateMediaItem: (userAuthFragment: UserAuthFragment): AuthorizationResult => ({
  //   ok: !!userAuthFragment.features?.jobPhotos && !!userAuthFragment?.permissions?.fieldApp
  // }),
  DeletePhotosOnJob: deleteMediaItemCheckFn,
  // CreateMediaItemCheckAssignedTechs: createMediaItemCheckAssignedTechsFn
  DemoAction: (): AuthorizationResult => ({
    ok: true,
    error: undefined
  }),

  ViewCosts: (user: UserAuthFragment): AuthorizationResult => ({
    ok: !!user?.permissions?.viewCosts,
    error: undefined
  }),
  ChangeEnquiryCustomerMembership: (user: UserAuthFragment): AuthorizationResult => ({
    ok: !!user?.permissions?.setJobMembershipLevel,
    error: undefined
  }),
  UpdateJobClassification: (user: UserAuthFragment): AuthorizationResult => ({
    ok: !!user?.permissions?.updateJobClassification,
    error: undefined
  }),
  ChangeUserIntegration: (user: UserAuthFragment): AuthorizationResult => ({
    ok: !!user?.permissions?.manageUsers,
    error: undefined
  })
}
