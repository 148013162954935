import { useQuery } from '@apollo/client'
import { createContext, useContext } from 'react'
import { AppConfigFragment, GetAppConfigDocument } from '../api/generated-types'
import { LoaderContext, initialLoaderContext, loaderContextDefaultRetry } from '../app/loader-context'
import { useSession } from '../auth/session-context'

// This is is using old AppConfig endpoint for now to mirror config used in iOS App
// TODO: migrate these config items to the new config endpoint and process

export type AppConfigLoaderContext = LoaderContext<AppConfigFragment>
export const AppConfigLoaderContext = createContext<AppConfigLoaderContext>(initialLoaderContext)

export const useAppConfigLoader = () => useContext(AppConfigLoaderContext)

interface AppConfigLoaderProviderProps {
  children: React.ReactNode
}

export const AppConfigLoaderProvider = (props: AppConfigLoaderProviderProps) => {
  const session = useSession()

  //todo detect graphql using apollo not sessions
  const graphqlReady = !!session.data?.user?.token

  const { loading, data, error } = useQuery(GetAppConfigDocument, {
    skip: !graphqlReady
  })

  const config: AppConfigLoaderContext = {
    loading,
    loaded: !error && !!data,
    error: error?.message,
    data: data?.getAppConfig,
    retry: loaderContextDefaultRetry
  }

  return <AppConfigLoaderContext.Provider value={config}>{props.children}</AppConfigLoaderContext.Provider>
}
