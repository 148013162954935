import { useQuery } from '@apollo/client'
import { Membership, MembershipStatusType, SearchMembershipsDocument } from '../../api/generated-types'

export interface GetCustomerMembershipProps {
  customerId: string | undefined
  tenantId: string
  status?: MembershipStatusType[]
}

/**
 * @param customerId customer to search memberships for
 * @param tenantId tenant to search memberships for
 * @param status defaults to active only.
 * @returns an array of memberships tied to that customer. Will return an empty array if no memberships are found
 */
export const GetCustomerMemberships = (props: GetCustomerMembershipProps): Membership[] => {
  const { customerId, tenantId, status } = props
  const {
    data: customerMemberships,
    loading,
    error
  } = useQuery(SearchMembershipsDocument, {
    variables: {
      input: {
        filters: {
          customerIds: [customerId ?? ''],
          statuses: status ? status : [MembershipStatusType.Active],
          tenantId
        }
      }
    },
    skip: !customerId
  })
  const memberships = (customerMemberships?.searchMemberships?.results ?? []) as unknown as Membership[]
  return memberships
}
