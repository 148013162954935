import {
  EuiBasicTable,
  EuiBasicTableColumn,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHighlight,
  EuiPopover,
  EuiRadio,
  useEuiTheme
} from '@elastic/eui'
import { compact } from 'lodash-es'
import { ReactNode, useState } from 'react'
import {
  ContactCustomerRowFragment,
  ContactDetailFragment,
  CustomerFragment,
  SearchContactCustomersQuery
} from '../../api/generated-types'
import { Callout } from '../../common/callout'
import { formatPhone } from '../../common/phone'
import { CustomerPreview } from '../../customers/customer-preview'
import '../../static/css/basic-table.css'
import { ContactPreview } from '../contact-preview'
import { useContactSearch } from './contact-search-hook'

export interface ContactSearchResultsTableProps {
  loading: boolean
  data?: SearchContactCustomersQuery
}

enum PreviewField {
  Contact = 'contact',
  Customer = 'customer'
}

export const ContactSearchResultsTable = (props: ContactSearchResultsTableProps) => {
  const { loading, data } = props
  const { euiTheme } = useEuiTheme()

  const [searchState, dispatch] = useContactSearch()
  const { searchFields } = searchState

  const [previewId, setPreviewId] = useState<string | undefined>(undefined)
  const [previewField, setPreviewField] = useState<PreviewField>(PreviewField.Contact)

  const isInactiveCustomerInResults =
    (data?.searchContactCustomers?.results?.rows?.filter((row) => row.customer?.active === false) ?? []).length > 0

  const onSelect = (contactCustomerRow: ContactCustomerRowFragment) => {
    return dispatch({
      type: 'select_contact_customer',
      payload: {
        selectedContact: contactCustomerRow.contact,
        selectedCustomer: contactCustomerRow.customer ?? undefined
      }
    })
  }

  const renderWithHighlight = (value: string, searchValue: string, defaultValue?: string) => (
    <EuiFlexGroup direction="column" gutterSize="none">
      <EuiFlexItem className="truncate" style={{ flexDirection: 'row', alignItems: 'center' }}>
        <EuiHighlight search={searchValue}>{value ?? defaultValue ?? ''}</EuiHighlight>
      </EuiFlexItem>
    </EuiFlexGroup>
  )

  const closePreview = () => {
    setPreviewId(undefined)
  }

  const openPreview = (id: string, field: PreviewField) => {
    setPreviewField(field)
    setPreviewId(id)
  }

  const customerPreviewWrapper = (
    id: string | undefined | null,
    field: PreviewField,
    row: ContactCustomerRowFragment,
    textContent: string,
    searchHighlight?: string
  ) => {
    const isActive = row.customer && row.customer?.active !== false
    const defaultStyle = { borderBottom: '1px dotted #ACAFB9', cursor: 'pointer', padding: 'none' }
    const style = isActive ? defaultStyle : { ...defaultStyle, color: euiTheme.colors.danger }
    const buttonElement: ReactNode = searchHighlight ? (
      <div
        key={row.id}
        onClick={() => (previewId ? closePreview() : openPreview(id ?? '', field))}
        style={style}
        role="button"
      >
        <EuiHighlight search={searchHighlight}>{textContent}</EuiHighlight>
      </div>
    ) : (
      <span
        key={row.id}
        onClick={() => (previewId ? closePreview() : openPreview(id ?? '', field))}
        style={style}
        role="button"
      >
        {textContent}
      </span>
    )

    const previewElement: any = id ? (
      <EuiPopover
        isOpen={previewId === id && !!previewField && previewField === field}
        button={buttonElement}
        closePopover={closePreview}
        panelPaddingSize="none"
        anchorPosition="downLeft"
      >
        {field === previewField && previewField === PreviewField.Contact && (
          <ContactPreview contactId={row.contact?.id} />
        )}
        {field === previewField && previewField === PreviewField.Customer && row.customerId && (
          <CustomerPreview customerId={row.customerId} />
        )}
      </EuiPopover>
    ) : null

    return !previewElement ? buttonElement : previewElement
  }

  const contactPreviewWrapper = (
    id: string | undefined | null,
    element: ReactNode,
    field: PreviewField,
    row: ContactCustomerRowFragment
  ) => {
    const buttonElement: ReactNode = (
      <div
        onClick={() => (previewId ? closePreview() : openPreview(id ?? '', field))}
        style={{ borderBottom: '1px dotted #ACAFB9', cursor: 'pointer', padding: 'none' }}
      >
        {element}
      </div>
    )

    const previewElement: any = id ? (
      <EuiPopover
        isOpen={previewId === id && !!previewField && previewField === field}
        button={buttonElement}
        closePopover={closePreview}
        panelPaddingSize="none"
        anchorPosition="downLeft"
      >
        {previewField === field && previewField === PreviewField.Contact && (
          <ContactPreview contactId={row.contact?.id} />
        )}
        {previewField === field &&
          previewField === PreviewField.Customer &&
          field === PreviewField.Customer &&
          row.customerId && <CustomerPreview customerId={row.customerId} />}
      </EuiPopover>
    ) : null

    return !previewElement ? element : previewElement
  }

  const columns: EuiBasicTableColumn<ContactCustomerRowFragment>[] = [
    {
      field: 'customer',
      name: 'Reference',
      width: '140px',
      render: (customer: CustomerFragment | undefined, row: ContactCustomerRowFragment) => {
        const isActive = customer && customer?.active !== false

        return (
          <EuiFlexGroup gutterSize="none" alignItems="center">
            <EuiFlexItem grow={false}>
              <EuiRadio
                checked={
                  (row.contact?.id === searchState.selectedContact?.id && !row.customer) ||
                  (row.contact?.id === searchState.selectedContact?.id && row.customerId) ===
                    searchState.selectedCustomer?.id
                }
                onChange={() => {
                  if (row.contact) onSelect(row)
                }}
                style={{ marginRight: '12px' }}
              />
            </EuiFlexItem>
            <EuiFlexItem className="truncate" style={{ flexDirection: 'row', alignItems: 'center' }}>
              {customer?.number ? (
                !isActive ? (
                  <EuiFlexGroup direction="column" gutterSize="none">
                    <EuiFlexItem grow={false}>
                      <span style={{ fontSize: '10px', color: euiTheme.colors.danger }}>INACTIVE</span>
                    </EuiFlexItem>
                    <EuiFlexItem grow={false}>
                      <span className="truncate" title={customer.number} style={{ color: euiTheme.colors.danger }}>
                        {customerPreviewWrapper(
                          row?.id,
                          PreviewField.Customer,
                          row,
                          customer.number,
                          searchFields.reference
                        )}
                      </span>
                    </EuiFlexItem>
                  </EuiFlexGroup>
                ) : (
                  customerPreviewWrapper(row?.id, PreviewField.Customer, row, customer.number, searchFields.reference)
                )
              ) : (
                'No customer'
              )}
            </EuiFlexItem>
          </EuiFlexGroup>
        )
      }
    },
    {
      field: 'contact.detail',
      name: 'Name',
      width: '25%',
      render: (detail: ContactDetailFragment, row: ContactCustomerRowFragment) => {
        const cellValue = (
          <>
            <EuiFlexGroup gutterSize="none">
              <EuiHighlight key={row.id} search={searchFields.firstName}>
                {detail?.firstName ?? 'No name'}
              </EuiHighlight>
              {detail?.lastName && (
                <EuiHighlight search={searchFields.lastName} style={{ marginLeft: '5px' }}>
                  {detail?.lastName}
                </EuiHighlight>
              )}
            </EuiFlexGroup>
            {row?.customer?.company && row?.customer?.company?.name && (
              <EuiFlexGroup>
                <EuiHighlight search={searchFields.company} style={{ marginLeft: '5px' }}>
                  {row?.customer?.company?.name ?? ''}
                </EuiHighlight>
              </EuiFlexGroup>
            )}
          </>
        )

        return (
          <EuiFlexGroup gutterSize="none" alignItems="center">
            <EuiFlexItem className="truncate" style={{ flexDirection: 'row', alignItems: 'center' }}>
              {contactPreviewWrapper(row.id, cellValue, PreviewField.Contact, row)}
            </EuiFlexItem>
          </EuiFlexGroup>
        )
      }
    },
    {
      field: 'contact.detail',
      name: 'Phone',
      width: '120px',
      render: (detail: ContactDetailFragment, row: ContactCustomerRowFragment) => {
        return (
          <EuiFlexGroup direction="column" gutterSize="none">
            <EuiFlexItem
              className="truncate"
              style={{ flexDirection: 'row', alignItems: 'center' }}
              key={row.id + '.phone'}
            >
              <EuiHighlight search={searchFields.phone}>{formatPhone(detail?.phone ?? '')}</EuiHighlight>
            </EuiFlexItem>
            {detail.alternatePhone && (
              <EuiFlexItem
                className="truncate"
                style={{ flexDirection: 'row', alignItems: 'center' }}
                key={row.id + '.alternate'}
              >
                <EuiHighlight search={searchFields.phone}>{formatPhone(detail.alternatePhone)}</EuiHighlight>
              </EuiFlexItem>
            )}
          </EuiFlexGroup>
        )
      }
    },
    {
      field: 'contact.detail',
      name: 'Email',
      render: (detail: ContactDetailFragment) => {
        return renderWithHighlight(detail?.email, '')
      }
    },
    {
      field: 'contact.detail',
      name: 'Address',
      render: (detail: ContactDetailFragment) => {
        const streetComponents = compact([detail.address?.street, detail.address?.street2])
        const street = streetComponents.length > 0 ? streetComponents.join(', ') : (detail?.suburb?.label ?? '')
        return renderWithHighlight(street, searchFields.street, 'No address')
      }
    },
    {
      field: 'contact.detail',
      name: 'Suburb',
      render: (detail: ContactDetailFragment, row: ContactCustomerRowFragment) => {
        return renderWithHighlight(
          detail.address?.suburb ?? detail?.suburb?.label ?? '',
          searchFields.suburb,
          'No suburb'
        )
      }
    }
  ]

  return (
    <>
      {isInactiveCustomerInResults && (
        <Callout
          type="warning"
          title="If an inactive customer record is a match please engage with 1-up for direction"
        />
      )}
      <EuiBasicTable
        className="basic-table--minimal"
        loading={loading}
        items={data?.searchContactCustomers?.results?.rows ?? []}
        itemId={'id'}
        columns={columns}
        rowProps={(row) => ({ onClick: () => onSelect(row) })}
        noItemsMessage={loading ? 'Searching...' : 'No contacts found'}
      />
    </>
  )
}
