import { EuiButton, EuiSpacer } from '@elastic/eui'
import { dateConfig } from '@fallonsolutions/date'
import { DateTime } from 'luxon'
import { JobSearchBetaResultFragment } from '../../api/generated-types'
import { CustomerJobsSelect } from '../../customers/customer-jobs-select'
import { WorkflowAction } from '../workflow-action'
import { BaseActionInput, BaseActionResult, WorkflowActionProps } from '../workflow-model'

export interface SelectJobActionInput extends BaseActionInput {
  customerId: string
}

export interface SelectedJob {
  id: string
  reference: string
  label: string
}

export interface SelectJobActionResult extends BaseActionResult {
  job?: SelectedJob
}

export const SelectJobAction = (props: WorkflowActionProps<SelectJobActionInput, SelectJobActionResult>) => {
  const { input, result, onUpdate } = props

  const { customerId } = input ?? {}
  const { actionCompleted, job } = result ?? {}

  const value = actionCompleted && job ? job.label : undefined
  const setJob = (job: JobSearchBetaResultFragment | undefined) => {
    if (job) {
      const startDateString = DateTime.fromISO(job.legacy?.startDate ?? job.created, {
        zone: dateConfig.defaultTimezone
      }).toFormat(dateConfig.luxonFormat.shortDate)
      onUpdate({
        ...result,
        job: {
          id: job.id,
          label: `${job.number}, ${startDateString} (${job.tradeType})`,
          reference: job.number
        }
      })
    } else {
      onUpdate({})
    }
  }
  const clearJob = () => onUpdate({})

  return (
    <WorkflowAction title="Select job" value={value} onClickChange={clearJob}>
      <CustomerJobsSelect customerId={customerId} selectedJobId={job?.id} onSelect={setJob} />

      <EuiSpacer size="s" />
      <EuiButton onClick={() => onUpdate({ ...result, actionCompleted: true })} disabled={!job?.id}>
        Select
      </EuiButton>
      <EuiSpacer />
    </WorkflowAction>
  )
}
