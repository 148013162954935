import { EuiSpacer } from '@elastic/eui'
import { Callout } from '../../../common/callout'
import { NotesGenerator } from '../../helpers/notes-generator'
import { ResultUpdater } from '../../helpers/results-updater'
import { SingleSelectField } from '../../question-fields/single-select-question-field'
import { YesNoValue } from '../common/common-enums'
import { yesNoOptions } from '../common/common-options'
import {
  InsideOrOutside,
  TopicPlumbingWaterLeakDetectionActionResult,
  WaterLeakInsideSymptom
} from './action-topic-plumbing-water-leak-detection-model'
import { getResponseSummary } from './action-topic-plumbing-water-leak-detection-requirements'

export interface WaterLeakInsideProps {
  result?: TopicPlumbingWaterLeakDetectionActionResult
  notesGenerator: NotesGenerator<TopicPlumbingWaterLeakDetectionActionResult>
  updateResult: ResultUpdater<TopicPlumbingWaterLeakDetectionActionResult>
}
export const WaterLeakInside = (props: WaterLeakInsideProps) => {
  const { result, notesGenerator, updateResult } = props
  const {
    insideOrOutside,
    ceilingLeaking,
    canHearWaterRunningInside,
    canSeeWetPatchOnFloor,
    canSeeShowerLeakingBehindWall,
    suspectWaterIsLeakingBehindWall,
    canSmellDampOrWetCarpet,
    otherWetPatchesOnWalls,
    swollenFloorboards,
    canIsolateWater,
    bathroomLaundryOrToiletAboveTheWetArea,
    ductedACInstalled,
    ductedACRunning,
    ductedACCheckedForLeaks
  } = result ?? {}

  const { askIsolateWaterQuestion } = getResponseSummary(result)
  return (
    <>
      {insideOrOutside === InsideOrOutside.Inside && (
        <>
          <SingleSelectField
            question={notesGenerator.getQuestion('ceilingLeaking')}
            answer={ceilingLeaking}
            changeAnswer={(ceilingLeaking) => updateResult({ ceilingLeaking })}
            options={yesNoOptions}
          />
          <EuiSpacer size="m" />

          {ceilingLeaking === YesNoValue.No && (
            <>
              <SingleSelectField
                question={notesGenerator.getQuestion('canHearWaterRunningInside')}
                answer={canHearWaterRunningInside}
                changeAnswer={(canHearWaterRunningInside) =>
                  updateResult({ canHearWaterRunningInside, symptom: WaterLeakInsideSymptom.HearWaterRunning })
                }
                options={yesNoOptions}
              />
              <EuiSpacer size="m" />
              <SingleSelectField
                question={notesGenerator.getQuestion('canSeeWetPatchOnFloor')}
                answer={canSeeWetPatchOnFloor}
                changeAnswer={(canSeeWetPatchOnFloor) =>
                  updateResult({ canSeeWetPatchOnFloor, symptom: WaterLeakInsideSymptom.WetPatchOnFloor })
                }
                options={yesNoOptions}
              />
              <SingleSelectField
                question={notesGenerator.getQuestion('canSeeShowerLeakingBehindWall')}
                answer={canSeeShowerLeakingBehindWall}
                changeAnswer={(canSeeShowerLeakingBehindWall) =>
                  updateResult({ canSeeShowerLeakingBehindWall, symptom: WaterLeakInsideSymptom.LeakingBehindWall })
                }
                options={yesNoOptions}
              />
              <SingleSelectField
                question={notesGenerator.getQuestion('suspectWaterIsLeakingBehindWall')}
                answer={suspectWaterIsLeakingBehindWall}
                changeAnswer={(suspectWaterIsLeakingBehindWall) =>
                  updateResult({ suspectWaterIsLeakingBehindWall, symptom: WaterLeakInsideSymptom.LeakingBehindWall })
                }
                options={yesNoOptions}
              />

              <SingleSelectField
                question={notesGenerator.getQuestion('canSmellDampOrWetCarpet')}
                answer={canSmellDampOrWetCarpet}
                changeAnswer={(canSmellDampOrWetCarpet) => updateResult({ canSmellDampOrWetCarpet })}
                options={yesNoOptions}
              />
              <SingleSelectField
                question={notesGenerator.getQuestion('otherWetPatchesOnWalls')}
                answer={otherWetPatchesOnWalls}
                changeAnswer={(otherWetPatchesOnWalls) => updateResult({ otherWetPatchesOnWalls })}
                options={yesNoOptions}
              />
              <SingleSelectField
                question={notesGenerator.getQuestion('swollenFloorboards')}
                answer={swollenFloorboards}
                changeAnswer={(swollenFloorboards) => updateResult({ swollenFloorboards })}
                options={yesNoOptions}
              />
              <EuiSpacer size="m" />
            </>
          )}

          {ceilingLeaking === YesNoValue.Yes && (
            <>
              <SingleSelectField
                question={notesGenerator.getQuestion('bathroomLaundryOrToiletAboveTheWetArea')}
                answer={bathroomLaundryOrToiletAboveTheWetArea}
                changeAnswer={(bathroomLaundryOrToiletAboveTheWetArea) =>
                  updateResult({
                    bathroomLaundryOrToiletAboveTheWetArea
                  })
                }
                options={yesNoOptions}
              />

              {bathroomLaundryOrToiletAboveTheWetArea === YesNoValue.No && (
                <>
                  <Callout type="note">If it is summer ask them if they have Ducted AC?</Callout>
                  <SingleSelectField
                    question={notesGenerator.getQuestion('ductedACInstalled')}
                    answer={ductedACInstalled}
                    changeAnswer={(ductedACInstalled) =>
                      updateResult({
                        ductedACInstalled
                      })
                    }
                    options={yesNoOptions}
                  />
                  <EuiSpacer size="m" />
                  {ductedACInstalled === YesNoValue.Yes && (
                    <>
                      <SingleSelectField
                        question={notesGenerator.getQuestion('ductedACRunning')}
                        answer={ductedACRunning}
                        changeAnswer={(ductedACRunning) =>
                          updateResult({
                            ductedACRunning
                          })
                        }
                        options={yesNoOptions}
                      />
                      {ductedACRunning === YesNoValue.Yes && (
                        <>
                          <SingleSelectField
                            question={notesGenerator.getQuestion('ductedACCheckedForLeaks')}
                            answer={ductedACCheckedForLeaks}
                            changeAnswer={(ductedACCheckedForLeaks) =>
                              updateResult({
                                ductedACCheckedForLeaks
                              })
                            }
                            options={yesNoOptions}
                          />
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          )}
          {askIsolateWaterQuestion && (
            <>
              <Callout type="note" title="Can the customer turn off a flood stop valve or mini-tap?" />
              <SingleSelectField
                question={notesGenerator.getQuestion('canIsolateWater')}
                answer={canIsolateWater}
                changeAnswer={(canIsolateWater) =>
                  updateResult({
                    canIsolateWater
                  })
                }
                options={yesNoOptions}
              />
              <EuiSpacer size="m" />
            </>
          )}
        </>
      )}
    </>
  )
}
