import { ScheduleEventStatusType } from '../api/generated-types'
import { makeHumanReadable } from '../common/utils'
import '../static/css/schedule-event-status-badge.css'

export interface ScheduleEventStatusBadgeProps {
  status: ScheduleEventStatusType
  size?: 'xs' | 'm'
}

export const ScheduleEventStatusBadge = ({ status, ...props }: ScheduleEventStatusBadgeProps) => {
  const size = props.size ?? 'm'
  return (
    <span
      className={`schedule-event-status-badge schedule-event-status-badge--${status.toLowerCase()} schedule-event-status-badge--${size}`}
    >
      {size === 'm' ? makeHumanReadable(status) : ''}
    </span>
  )
}
