import { useQuery } from '@apollo/client'
import { EuiSpacer } from '@elastic/eui'
import parsePhoneNumberFromString from 'libphonenumber-js'
import { isEmpty } from 'lodash-es'
import { SearchContactCustomersDocument } from '../../api/generated-types'
import RawJSON from '../../common/raw-json'
import { useDebounce } from '../../common/use-debounce'
import { ContactSearchForm } from './contact-search-form'
import { useContactSearch } from './contact-search-hook'
import { ContactSearchModel } from './contact-search-model'
import { ContactSearchResults } from './contact-search-results'

export interface ContactSearchContainerProps {
  allowCreate: boolean
  onAction?: (selectedAction: ContactSearchModel) => void
  hidden?: boolean
}

export const ContactSearchContainer = (props: ContactSearchContainerProps) => {
  const [searchState] = useContactSearch()

  const { searchFields } = searchState
  const { allowCreate, onAction, hidden } = props ?? {}

  const phone = useDebounce(searchFields.phone, 250)
  const firstName = useDebounce(searchFields.firstName, 250)
  const lastName = useDebounce(searchFields.lastName, 250)
  const street = useDebounce(searchFields.street, 250)
  const suburb = useDebounce(searchFields.suburb, 250)
  const fuzzy = searchFields.fuzzy

  //customer fields
  const reference = useDebounce(searchFields.reference, 250)
  const company = useDebounce(searchFields.company, 250)
  const activeOnly = searchFields.activeOnly

  const structuredPhone = !isEmpty(phone) ? parsePhoneNumberFromString(phone, 'AU')?.number : phone
  const { loading, data } = useQuery(SearchContactCustomersDocument, {
    variables: {
      input: {
        filter: {
          contact: { firstName, phone: structuredPhone, lastName, suburb, street, fuzzy },
          customer: { reference, companyName: company, ...(!!activeOnly && { active: activeOnly }) }
        },
        format: { rowPerContactCustomer: true },
        size: 10
      }
    },
    skip: !phone && !firstName && !lastName && !suburb && !street && !reference && !company && activeOnly === undefined
  })

  return (
    <div style={{ display: hidden ? 'none' : 'block' }}>
      <ContactSearchForm />
      <EuiSpacer />
      <ContactSearchResults
        allowCreate={allowCreate}
        loading={loading}
        searching={!!phone || !!firstName || !!lastName || !!suburb || !!street || !!company || !!reference}
        data={data}
        onSelectionConfirmed={() => onAction && onAction(searchState)}
      />
      <RawJSON data={searchState} />
    </div>
  )
}
