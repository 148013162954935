import { EuiCallOut, EuiSpacer } from '@elastic/eui'
import { ServiceAreaServiceCheck } from '../service-area/service-area-service-check'
import { TradeType } from '@fallonsolutions/types'
import { SelectedProperty } from '../workflow/actions/action-customer-property'

export enum ServiceAreaServiceCheckStatus {
  Loading = 'loading',
  WithinScope = 'within-scope',
  NotWithinScope = 'not-within-scope'
}
export interface ServiceAreaServiceCheckProps {
  companyId: string
  trade: TradeType
  property: SelectedProperty | undefined
  onChangeServiceAreaScope: (status: ServiceAreaServiceCheckStatus) => void
}
export const ServiceAreaTradeCheck = (props: ServiceAreaServiceCheckProps) => {
  const { companyId, trade, onChangeServiceAreaScope, property } = props

  return (
    <>
      {property?.suburb && trade !== TradeType.None && (
        <>
          {property.suburb.id ? (
            <>
              <ServiceAreaServiceCheck
                companyId={companyId}
                suburbId={property.suburb.id}
                trade={trade}
                onChangeServiceAreaServiceCheckStatus={onChangeServiceAreaScope}
              />
            </>
          ) : (
            <EuiCallOut
              color="warning"
              title={`Unable to identify service area to confirm service coverage for ${trade}`}
            />
          )}
          <EuiSpacer size="xl" />
        </>
      )}
    </>
  )
}
