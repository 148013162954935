import React, { ReactNode } from 'react'
import { EuiLink } from '@elastic/eui'
import { useHistory } from 'react-router-dom'

// interface EuiCustomLinkProps extends EuiLinkButtonProps {
//   to: string
// }

interface EuiCustomLinkProps {
  to: string
  children?: ReactNode
  target?: string
}

// Most of the content of this files are from https://github.com/elastic/eui/pull/1976.
const isModifiedEvent = (event: any) => !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey)

const isLeftClickEvent = (event: any) => event.button === 0

export default function EuiCustomLink({ to, ...props }: EuiCustomLinkProps) {
  const history = useHistory()

  function onClick(event: any) {
    if (event.defaultPrevented) {
      return
    }

    if (event.target.getAttribute('target')) {
      return
    }

    if (isModifiedEvent(event) || !isLeftClickEvent(event)) {
      return
    }

    event.preventDefault()
    history.push(to)
  }

  return <EuiLink {...props} href={to} onClick={onClick} target={props.target} external={false} />
}
