import { useMutation } from '@apollo/client'
import { EuiAvatar, EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiSpacer } from '@elastic/eui'
import { Editor, EditorState } from 'draft-js'
import 'draft-js/dist/Draft.css'
import { DateTime } from 'luxon'
import { useEffect, useRef, useState } from 'react'
import { CreateCommentDocument } from '../api/generated-types'
import { useMixpanel } from '../app/mixpanel-provider'
import { useAuthenticated } from '../auth/authenticated-context'
import '../static/css/comment.css'

interface CreateCommentFormProps {
  jobId?: string
  timesheetDayId?: string
  timesheetWeekId?: string
  performanceEntryId?: string
  contactId?: string
  comment?: EditorState
  onChange?: (comment: EditorState) => void
  onCreateComment?: () => void
  hideSend?: boolean
  errors?: string[]
}

const CreateCommentForm = ({
  jobId,
  timesheetDayId,
  timesheetWeekId,
  performanceEntryId,
  contactId,
  onCreateComment,
  comment,
  onChange,
  hideSend,
  errors
}: CreateCommentFormProps) => {
  const [editorState, setEditorState] = useState<EditorState>(EditorState.createEmpty())
  const session = useAuthenticated().user
  const userFragment = useAuthenticated().userFragment
  const mixpanel = useMixpanel()

  const content = editorState.getCurrentContent()
  const hasContent = content.getPlainText().length > 0

  const [createComment, { loading }] = useMutation(CreateCommentDocument, {
    refetchQueries: ['GetJob', 'GetJobActivity', 'GetTimesheetDay', 'GetPerformanceEntry'],
    awaitRefetchQueries: true,
    onCompleted: (data) => {
      const characterCount = data.createComment?.comment?.message?.length ?? 0
      mixpanel?.track('CommentCreated', { characterCount })
    }
  })

  const submitClick = async () => {
    const input = {
      jobId,
      timesheetDayId,
      timesheetWeekId,
      performanceEntryId,
      contactId,
      message: content.getPlainText(),
      created: DateTime.local().toISO()
    }
    await createComment({
      variables: { input },
      update: () => {
        onCreateComment?.()
        onChange?.(EditorState.createEmpty())
      }
    })
    setEditorState(EditorState.createEmpty())
  }

  useEffect(() => {
    comment && setEditorState(comment)
  }, [comment])

  const onChangeEditor = (editorState: EditorState) => {
    setEditorState(editorState)
    onChange?.(editorState)
  }

  const loadingMessage = <span>Sending...</span>

  const editorRef = useRef<Editor>(null)
  const onClick = () => editorRef.current?.focus()

  return (
    <EuiFlexGroup gutterSize="none" onClick={onClick}>
      <EuiFlexItem grow={false} style={{ minWidth: '40px', marginRight: '16px' }}>
        <div style={{ margin: '0 auto' }}>
          <EuiAvatar size="m" name={session?.username ?? 'unknown'} imageUrl={userFragment.avatar} />
        </div>
      </EuiFlexItem>
      <EuiFlexItem grow={true}>
        <div className={`editor ${hasContent ? 'editor--has-content' : ''}`}>
          {loading ? (
            loadingMessage
          ) : (
            <div>
              <EuiFormRow error={errors} isInvalid={!!errors}>
                <Editor
                  ref={editorRef}
                  editorState={editorState}
                  onChange={onChangeEditor}
                  placeholder="Write a comment..."
                />
              </EuiFormRow>

              {!hideSend && (
                <button className="editor__submit" onClick={submitClick}>
                  Send
                </button>
              )}
            </div>
          )}
        </div>
        <EuiSpacer />
      </EuiFlexItem>
    </EuiFlexGroup>
  )
}

export default CreateCommentForm
