import { EuiFlexGroup, EuiFlexItem } from '@elastic/eui'
import { Metric } from '../common/metrics'
import { JobSummaryAggregationFragment } from '../api/generated-types'
import { isNil } from 'lodash-es'

interface JobListMetricsNewDefinitionsProps {
  summary?: JobSummaryAggregationFragment
}

export const JobListMetricsNewDefinitions = ({ summary }: JobListMetricsNewDefinitionsProps) => {
  const { firstVisitCount, conversionCount, futileCount, cancelledCount, toBeAttendedCount, noSaleCount, diagnosticOnlyCount, offerOnlyCount, followOnCount } = summary ?? {}

  return (
    <div>
      <div className="small-text" style={{ marginBottom: '12px' }}>
        Search summary (updated)
        <EuiFlexGroup gutterSize="s" className="spacing">
          {!isNil(followOnCount) && !isNil(firstVisitCount) && (
            <EuiFlexItem grow={false}>
              <Metric
                primary={{ label: 'First visits', value: firstVisitCount }}
                secondary={{ label: 'Follow on', value: followOnCount }}
              />
            </EuiFlexItem>
          )}
          {!isNil(conversionCount) && (
            <EuiFlexItem grow={false}>
              <Metric
                primary={{ label: 'Conversions', value: conversionCount }}
              />
            </EuiFlexItem>
          )}
          {!isNil(futileCount) && !isNil(noSaleCount) && (
            <EuiFlexItem grow={false}>
              <Metric
                primary={{ label: 'Futiles', value: futileCount }}
                secondary={{ label: 'No Sale', value: noSaleCount }}
              />
            </EuiFlexItem>
          )}
          {!isNil(diagnosticOnlyCount) && !isNil(offerOnlyCount) && (
            <EuiFlexItem grow={false}>
              <Metric primary={{ label: 'Diagnostic Only', value: diagnosticOnlyCount }}
                secondary={{ label: 'Offer Only', value: offerOnlyCount }} />
            </EuiFlexItem>
          )}
          {!isNil(toBeAttendedCount) && !isNil(cancelledCount) && (
            <EuiFlexItem grow={false}>
              <Metric
                primary={{ label: 'To attend', value: toBeAttendedCount }}
                secondary={{ label: 'Cancelled', value: cancelledCount ?? 0 }}
              />
            </EuiFlexItem>
          )}
        </EuiFlexGroup>
      </div>
    </div>
  )
}
