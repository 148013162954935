import { EuiFlexGroup, EuiFlexItem, EuiSpacer, EuiText } from '@elastic/eui'
import { BookingWindow } from '@fallonsolutions/appointment'
import { JobType } from '@fallonsolutions/types'
import { map } from 'lodash-es'
import { DateTime } from 'luxon'
import { useMemo, useState } from 'react'
import { EnquiryAbortReason, TimeWindow } from '../../../api/generated-types'
import { Callout } from '../../../common/callout'
import { OutOfScopeButton } from '../../helpers/out-of-scope-button'
import { SingleSelectField } from '../../question-fields/single-select-question-field'
import { YesNoValue } from '../../topics/common/common-enums'
import {} from '../../workflow-model'

export enum CustomerIssueWithJobReason {
  TravelFee = 'Travel Fee',
  QuoteOrPrice = 'Quote/Price over the phone',
  DiagnosticFeePrice = 'Diagnostic Fee Price',
  DiagnosticFeeSelfDiagnosed = 'Diagnostic Fee Self Diagnosed'
}
export interface JobCloseArmResponseViewProps {
  continueWithWorkflow: () => void
  jobType: JobType | undefined
  timeWindow: TimeWindow | undefined // get date from the timewindow.from
  bookingWindow: BookingWindow | undefined // get scheduled time e.g 8-11 11-1 etc
  existingMember: boolean
}

const CustomerIssueToEnquiryAbortReasonMap: Record<CustomerIssueWithJobReason, EnquiryAbortReason> = {
  [CustomerIssueWithJobReason.TravelFee]: EnquiryAbortReason.CustomerDidNotLikeTheTravelFee,
  [CustomerIssueWithJobReason.QuoteOrPrice]: EnquiryAbortReason.CustomerNotWillingToPay,
  [CustomerIssueWithJobReason.DiagnosticFeePrice]: EnquiryAbortReason.CustomerNotWillingToPay,
  [CustomerIssueWithJobReason.DiagnosticFeeSelfDiagnosed]: EnquiryAbortReason.CustomerNotWillingToPay
}

export const JobCloseArmResponseView = (props: JobCloseArmResponseViewProps) => {
  const { continueWithWorkflow, jobType, bookingWindow, existingMember, timeWindow } = props
  const [selectedReason, setSelectedReason] = useState<CustomerIssueWithJobReason | undefined>(undefined)
  const [moveOn, setMoveOn] = useState<YesNoValue | undefined>(undefined)
  // get between hours from booking window and day of the week as well as the date from the booking window from
  const bookingWindowDate = useMemo(() => {
    if (!timeWindow?.from) {
      return ''
    }
    const date = DateTime.fromISO(timeWindow.from)
    return date.toFormat('ccc LLLL dd')
  }, [timeWindow])

  const bookingWindowTime = useMemo(() => {
    if (!bookingWindow?.startTime || !bookingWindow?.endTime) {
      return ''
    }
    const from = DateTime.fromISO(bookingWindow.startTime)
    const to = DateTime.fromISO(bookingWindow.endTime)
    return `${from.toLocaleString(DateTime.TIME_SIMPLE)} - ${to.toLocaleString(DateTime.TIME_SIMPLE)}`
  }, [bookingWindow])

  const abortReason = useMemo(() => {
    const reason = selectedReason
      ? (CustomerIssueToEnquiryAbortReasonMap[selectedReason] ?? EnquiryAbortReason.Other)
      : EnquiryAbortReason.Other
    return reason
  }, [selectedReason])

  const moveOnStatement = useMemo(() => {
    if (!bookingWindow?.startTime || !bookingWindow?.endTime) {
      // could be an afterhours booking window or something else
      return 'Are you happy to book this in?'
    }
    return `as I was saying, we can be there between ${bookingWindowTime} on ${bookingWindowDate}, are you happy to book this in?`
  }, [bookingWindow, bookingWindowDate, bookingWindowTime])
  return (
    <>
      <EuiFlexGroup direction="column" gutterSize="m">
        <EuiFlexItem grow={false}>
          <SingleSelectField
            question={`I am sorry to hear that, if I may ask, what's the reason for not wanting to book in?`}
            options={map(
              [
                CustomerIssueWithJobReason.TravelFee,
                CustomerIssueWithJobReason.QuoteOrPrice,
                CustomerIssueWithJobReason.DiagnosticFeePrice,
                CustomerIssueWithJobReason.DiagnosticFeeSelfDiagnosed
              ],
              (i) => ({ id: i })
            )}
            answer={selectedReason}
            changeAnswer={(selectedReason) => setSelectedReason(selectedReason)}
          />
        </EuiFlexItem>

        {selectedReason && (
          <EuiFlexItem grow={false}>
            {selectedReason === CustomerIssueWithJobReason.TravelFee && (
              <>
                <Callout title="" type="script">
                  <EuiText>I completely understand how you feel about the travel fee.</EuiText>
                  <EuiText>
                    Fallon’s has one of the cheaper travel fees in the industry, and all of our visits come with a
                    complimentary home safety inspection, valued at <strong>$200</strong>, so you can be sure you’re
                    getting great value
                  </EuiText>
                </Callout>
              </>
            )}
            {selectedReason === CustomerIssueWithJobReason.QuoteOrPrice && (
              <>
                <Callout title="" type="script">
                  <EuiText>I completely understand your desire to get a price over the phone</EuiText>
                  <EuiText>
                    As you can imagine, every home is different, which impacts what work is required for the {jobType}.
                    The only way to ensure you get a custom solution with a fair and accurate price that won't change,
                    is to come out and assess in person
                  </EuiText>
                </Callout>
              </>
            )}
            {selectedReason === CustomerIssueWithJobReason.DiagnosticFeeSelfDiagnosed && (
              <>
                <Callout title="" type="script">
                  <EuiText>
                    I understand the frustration of paying to diagnose the issue when you know the problem
                  </EuiText>
                  <EuiText>
                    However, it’s like going to your local GP or your mechanic: you can tell them the issue but they
                    will still run their own tests. we’re the same: we need to run our own diagnostic to verify.
                    Besides, it’s hard to tell if you have diagnosed the symptom or the disease
                  </EuiText>
                </Callout>
              </>
            )}

            {selectedReason === CustomerIssueWithJobReason.DiagnosticFeePrice && (
              <>
                <Callout title="" type="script">
                  <EuiText>I understand the diagnostic price seems high</EuiText>
                  <EuiText>
                    As I stated, that price covers the cost of the technician traveling to site, and pulling apart the
                    appliance to accurately diagnose the issue instead of a visual check which can be inaccurate.
                    Thankfully is comes with a{' '}
                    {existingMember ? '12 month extension of your membership' : 'new red plan membership'} which will
                    also get you <strong>15%</strong> off the cost of any work
                  </EuiText>
                </Callout>
              </>
            )}
          </EuiFlexItem>
        )}
        {selectedReason && (
          <>
            <EuiSpacer size="xs" />
            <SingleSelectField
              question={moveOnStatement}
              options={map([YesNoValue.Yes, YesNoValue.No], (i) => ({ id: i }))}
              answer={moveOn}
              changeAnswer={(moveOn) => {
                if (moveOn === YesNoValue.Yes) {
                  continueWithWorkflow()
                } else {
                  setMoveOn(moveOn as YesNoValue)
                }
              }}
            />
          </>
        )}
        {moveOn === YesNoValue.No && (
          <>
            <EuiFlexItem grow={false}>
              <div style={{ width: '200px' }}>
                <OutOfScopeButton issue={''} buttonText="End call" abortReason={abortReason} />
              </div>
            </EuiFlexItem>
          </>
        )}
      </EuiFlexGroup>
    </>
  )
}
