import { useMutation } from '@apollo/client'
import { EuiContextMenu, EuiContextMenuPanelDescriptor, EuiLoadingSpinner, EuiPopover } from '@elastic/eui'
import { TimeAllowedOptions } from '@fallonsolutions/job'
import { useState } from 'react'
import { JobFragment, JobSource, UpdateJobTimeAllowedDocument, UpdateJobTimeAllowedInput } from '../api/generated-types'
import { JobTimeAllowed } from './job-time-allowed'

interface JobTimeAllowedEditProps {
  job: JobFragment
  selectedTimeAllowed?: string //id
}

export const JobTimeAllowedEdit = (props: JobTimeAllowedEditProps) => {
  const { job, selectedTimeAllowed } = props

  const canUpdateJobTimeAllowed = job.source === JobSource.Platform

  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const [updateJobTimeAllowed, { loading }] = useMutation(UpdateJobTimeAllowedDocument)

  const menu: EuiContextMenuPanelDescriptor[] = [
    {
      id: 0,
      title: 'Set job time allowed',
      items: TimeAllowedOptions.map((option) => {
        return {
          name: option.label,
          disabled: !canUpdateJobTimeAllowed,
          toolTipContent: canUpdateJobTimeAllowed ? undefined : 'Time allowed can only be changed for Platform jobs',
          onClick: () => handleStatusUpdate(option.timeInHours)
        }
      })
    }
  ]

  const handleStatusUpdate = async (time: number) => {
    const input: UpdateJobTimeAllowedInput = {
      job: job.id,
      time
    }
    try {
      await updateJobTimeAllowed({ variables: { input }, refetchQueries: ['GetJob'], awaitRefetchQueries: true })
      setIsMenuOpen(false)
    } catch (err) {
      console.error('error changing time allowed', err)
    }
  }

  const currentTimeAllowed = TimeAllowedOptions.find(
    (o) => o.id === selectedTimeAllowed || o.timeInHours === job.legacy?.timeAllowed
  )

  return canUpdateJobTimeAllowed ? (
    <EuiPopover
      isOpen={isMenuOpen}
      button={
        <button onClick={() => !loading && setIsMenuOpen(!isMenuOpen)}>
          {loading ? (
            <EuiLoadingSpinner size="m" />
          ) : (
            <JobTimeAllowed timeAllowed={currentTimeAllowed?.label} editable={true} />
          )}
        </button>
      }
      closePopover={() => setIsMenuOpen(false)}
      ownFocus={true}
      panelPaddingSize="none"
    >
      <EuiContextMenu initialPanelId={0} panels={menu} />
    </EuiPopover>
  ) : (
    <JobTimeAllowed timeAllowed={currentTimeAllowed?.label} />
  )
}
