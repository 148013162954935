import { useMutation } from '@apollo/client'
import { EuiConfirmModal } from '@elastic/eui'
import { useEffect } from 'react'
import {
  CustomerContactContactFragment,
  CustomerLinkFragment,
  RemoveCustomerContactDocument
} from '../api/generated-types'

export interface RemoveCustomerContactFormProps {
  contact: CustomerContactContactFragment
  customer: CustomerLinkFragment
  onClose: () => void
}

export const RemoveCustomerContactForm = ({ contact, customer, onClose }: RemoveCustomerContactFormProps) => {
  const [removeContact, { data, loading }] = useMutation(RemoveCustomerContactDocument, {
    variables: {
      input: {
        contactId: contact.contactId,
        customerId: customer.id
      }
    }
  })

  const onConfirm = () => {
    removeContact()
    console.log('TODO: remove')
    onClose()
  }

  useEffect(() => {
    if (data?.removeCustomerContact?.contact) {
      onClose()
    }
  }, [data, onClose])

  return (
    <>
      <EuiConfirmModal
        title={`Remove ${contact.contact.detail?.fullName ?? 'unknown contact'} from customer ${customer.number}?`}
        onCancel={onClose}
        onConfirm={onConfirm}
        cancelButtonText="Cancel"
        confirmButtonText="Remove contact"
        defaultFocusedButton="confirm"
        buttonColor="danger"
        isLoading={loading}
      >
        <p>This contact will no longer be associated with this customer.</p>
      </EuiConfirmModal>
    </>
  )
}
