import { BaseActionInput, BaseActionResult, WorkflowActionProps } from '../workflow-model'
import { EuiButton, EuiSpacer, EuiText } from '@elastic/eui'
import { WorkflowAction } from '../workflow-action'
import { Callout } from '../../common/callout'
import { Task } from '../../common/task'
import { useState } from 'react'
import { CustomerAction, CustomerActionResult } from './action-customer'
import { ContactActionResult } from './action-contact'
import { SelectInvoiceAction, SelectInvoiceActionResult } from './action-select-invoice'
import { InvoiceSummaryContainer } from '../../invoices/invoice-summary-container'

export interface ResendInvoiceActionInput extends BaseActionInput {
  contact?: ContactActionResult
}
export interface ResendInvoiceActionResult extends BaseActionResult {
  introComplete?: boolean
  customer?: CustomerActionResult
  invoice?: SelectInvoiceActionResult
}

export const ResendInvoiceAction = (
  props: WorkflowActionProps<ResendInvoiceActionInput, ResendInvoiceActionResult>
) => {
  const { onUpdate, input, result } = props
  const { contact } = input ?? {}
  const { introComplete } = result ?? {}

  const [emailAddressConfirmed, setEmailAddressConfirmed] = useState(false)
  const [requestSentToAccounts, setRequestSentToAccounts] = useState(false)

  const setIntroComplete = (introComplete: boolean | undefined) => onUpdate({ ...result, introComplete })
  const setCustomer = (customer: CustomerActionResult | undefined) => onUpdate({ ...result, customer })
  const setInvoice = (invoice: SelectInvoiceActionResult | undefined) => onUpdate({ ...result, invoice })
  const setActionCompleted = (actionCompleted: boolean | undefined) => onUpdate({ ...result, actionCompleted })

  const customerResult = result?.customer
  const customer = customerResult?.actionCompleted && customerResult?.customer
  const invoiceResult = result?.invoice
  const invoice = invoiceResult?.actionCompleted && invoiceResult?.invoice

  return (
    <>
      <WorkflowAction
        title="Intro"
        value={introComplete ? 'Complete' : undefined}
        onClickChange={() => setIntroComplete(false)}
      >
        <Callout type="script" className="callout--flat-list">
          <EuiText>
            <ul>
              <li>Okay, I can definitely arrange for another copy to be sent out</li>
              <li>I'm just going to bring up your account now</li>
            </ul>
          </EuiText>
        </Callout>{' '}
        <EuiSpacer size="s" />
        <EuiButton onClick={() => setIntroComplete(true)}>Next</EuiButton>
        <EuiSpacer />
      </WorkflowAction>

      {introComplete && <CustomerAction input={{ contact }} result={customerResult} onUpdate={setCustomer} />}
      {customer && (
        <SelectInvoiceAction input={{ customerId: customer.id }} result={invoiceResult} onUpdate={setInvoice} />
      )}

      {invoice && (
        <>
          <WorkflowAction title="Resend invoice">
            <div className="workflow__detail-wrapper">
              <InvoiceSummaryContainer invoiceId={invoice.id} />
            </div>
            <EuiSpacer />

            <Task
              label="Confirm email address"
              checked={emailAddressConfirmed}
              onChange={setEmailAddressConfirmed}
              indent={true}
            />

            <EuiSpacer />
            <Callout type="script">
              <EuiText>
                <div>Now I'm just relaying this message to our accounts department</div>
                <EuiSpacer size="s" />
                <div>
                  We will then send you through a copy of that invoice to you in the near future okay{' '}
                  <strong>{contact?.firstName}</strong>?
                </div>
              </EuiText>
            </Callout>
            <Task
              label="Request sent to accounts"
              checked={requestSentToAccounts}
              onChange={setRequestSentToAccounts}
              indent={true}
            />

            <EuiSpacer />
            <EuiButton
              fill
              disabled={!(emailAddressConfirmed && requestSentToAccounts)}
              color="primary"
              onClick={() => setActionCompleted(true)}
            >
              I've completed these steps
            </EuiButton>
          </WorkflowAction>
        </>
      )}
    </>
  )
}
