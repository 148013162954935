import { EuiButtonIcon, EuiFlexGroup, EuiFlexItem, EuiRange } from '@elastic/eui'
import { useEffect, useState } from 'react'
import { useDebouncedValue } from 'rooks'

export interface MediaItemListViewOptionsProps {
  width: number
  onChangeWidth: (width: number) => void
  overrideMax?: number
}

export const MediaItemListViewOptions = ({ width, onChangeWidth, overrideMax }: MediaItemListViewOptionsProps) => {
  const [localWidth, setLocalWidth] = useState(width)
  const [debouncedWidth] = useDebouncedValue(localWidth, 150)

  useEffect(() => {
    if (debouncedWidth) {
      onChangeWidth(debouncedWidth)
    }
  }, [debouncedWidth])

  const min = 100
  const max = overrideMax ?? 350
  const step = 50

  return (
    <>
      <EuiFlexGroup alignItems="center" gutterSize="s">
        <EuiFlexItem grow={true}>
          <EuiRange
            min={min}
            max={max}
            step={step}
            value={localWidth}
            onChange={(event: any) => setLocalWidth(event.target.value ? parseInt(event.target.value, 10) : 120)}
            aria-label="Image scale"
            compressed
          />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiButtonIcon
            iconType="magnifyWithPlus"
            onClick={() => setLocalWidth(Math.min(localWidth + step, max))}
            aria-label="Zoom in"
            display="empty"
            size="xs"
            color="text"
            disabled={localWidth >= max}
          />
        </EuiFlexItem>
      </EuiFlexGroup>
    </>
  )
}
