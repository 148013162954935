import { EuiText } from '@elastic/eui'
import { ServiceArea } from '../../api/generated-types'
import { Callout } from '../../common/callout'
import { OutOfScopeButton } from './out-of-scope-button'

export interface IsGoldCoastSecurityJobProps {
  serviceArea: ServiceArea | undefined
}
export const SecurityResidencyCheck = (props: IsGoldCoastSecurityJobProps) => {
  const { serviceArea } = props
  const isGoldCoastResidency = serviceArea?.branchId === 'gold-coast'
  console.log(serviceArea)
  return (
    <>
      {isGoldCoastResidency && (
        <>
          <Callout title="Gold Coast Residency" type="warning">
            <EuiText>
              Please confirm with DSR there is available place on the schedule for gold coast security jobs before
              proceeding
            </EuiText>
          </Callout>
          <OutOfScopeButton issue={'CCTV'} buttonText="Out of scope" />
        </>
      )}
      {!serviceArea?.branchId && (
        <>
          <Callout title="Customer address unknown" type="warning">
            <EuiText>
              If customer is a gold coast resident, please check with DSR that there is space on the schedule for gold
              coast security jobs before proceeding
            </EuiText>
          </Callout>
          <OutOfScopeButton issue={'CCTV'} buttonText="Out of scope" />
        </>
      )}
    </>
  )
}
