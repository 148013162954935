import { useReducer } from 'react'
import { ContactSearchContext, ContactSearchDispatchContext } from './contact-search-context'
import { emptyContactSearchModel } from './contact-search-model'
import { contactSearchReducer } from './contact-search-reducer'

export interface ContactSearchAppProps {
  children: React.ReactNode
}

export const ContactSearchProvider = (props: ContactSearchAppProps) => {
  const { children } = props
  const [searchState, dispatch] = useReducer(contactSearchReducer, emptyContactSearchModel)

  return (
    <ContactSearchContext.Provider value={searchState}>
      <ContactSearchDispatchContext.Provider value={dispatch}>{children}</ContactSearchDispatchContext.Provider>
    </ContactSearchContext.Provider>
  )
}
