import { useMutation, useQuery } from '@apollo/client'
import {
  EuiButton,
  EuiIcon,
  EuiKeyPadMenu,
  EuiKeyPadMenuItem,
  EuiLink,
  EuiRadioGroup,
  EuiSpacer,
  EuiText,
  EuiTitle
} from '@elastic/eui'
import { BookingWindow, bookingWindowOptions, filterBookingWindowOptions } from '@fallonsolutions/appointment'
import { dateConfig } from '@fallonsolutions/date'
import { find, isNil } from 'lodash-es'
import { DateTime } from 'luxon'
import { useMemo, useState } from 'react'
import {
  CreateCommentDocument,
  GetJobDocument,
  LegacyBookingWindow,
  ScheduleEventFilters,
  ScheduleEventFragment,
  ScheduleEventStatusType,
  UserFragment
} from '../../api/generated-types'
import { useAuthenticated } from '../../auth/authenticated-context'
import { Callout } from '../../common/callout'
import { JobSummaryContainer } from '../../jobs/job-summary-container'
import { ReservedScheduleEvents } from '../../jobs/reserved-schedule-events'
import { YesNoValue } from '../topics/common/common-enums'
import { WorkflowAction } from '../workflow-action'
import { BaseActionInput, BaseActionResult, WorkflowActionProps } from '../workflow-model'
import { ContactActionResult } from './action-contact'
import { CustomerAction, CustomerActionResult } from './action-customer'
import { SelectJobAction, SelectJobActionResult } from './action-select-job'

enum JobStage {
  FirstVisit = 'first-visit',
  Project = 'complete-quoted-works'
}

export interface RebookingActionInput extends BaseActionInput {
  contact?: ContactActionResult
}

export interface RebookingActionResult extends BaseActionResult {
  customer?: CustomerActionResult
  job?: SelectJobActionResult
  jobStage?: JobStageActionResult
  cancelOriginalAppointment?: YesNoValue
}

enum SuitableTime {
  Suitable = 'suitable',
  NotSuitable = 'not-suitable'
}

const timedBookingWindowOptions = [
  LegacyBookingWindow.Guarantee7,
  LegacyBookingWindow.Window7to8,
  LegacyBookingWindow.Window8to11,
  LegacyBookingWindow.Window10to13,
  LegacyBookingWindow.Window10to14,
  LegacyBookingWindow.Window12to16
]

// eslint-disable-next-line complexity
export const RebookingAction = (props: WorkflowActionProps<RebookingActionInput, RebookingActionResult>) => {
  const { input, onUpdate, result } = props
  const { contact } = input ?? {}
  const { customer, job, jobStage } = result ?? {}

  const [bookingWindow, setBookingWindow] = useState<BookingWindow | undefined>(undefined)

  const setCustomer = (customer: CustomerActionResult) => onUpdate({ ...result, customer })
  const setJob = (job: SelectJobActionResult) => onUpdate({ ...result, job })
  const setJobStage = (jobStage: JobStageActionResult) => onUpdate({ ...result, jobStage })

  const [createJobComment, { loading }] = useMutation(CreateCommentDocument)
  const userFragment = useAuthenticated()?.userFragment
  const [suitableTimeFound, setSuitableTimeFound] = useState<SuitableTime | undefined>(undefined)
  const [reservedScheduleEvent, setReservedScheduleEvent] = useState<ScheduleEventFragment | undefined>(undefined)

  const suitableTimeOptions = [
    {
      id: SuitableTime.Suitable,
      label: 'Suitable time found',
      isSelected: suitableTimeFound === SuitableTime.Suitable,
      icon: 'check'
    },
    {
      id: SuitableTime.NotSuitable,
      label: 'Not suitable',
      isSelected: suitableTimeFound === SuitableTime.NotSuitable,
      icon: 'cross'
    }
  ]

  const jobId = job?.actionCompleted ? job?.job?.id : undefined
  const { data: jobData } = useQuery(GetJobDocument, {
    variables: {
      id: jobId ?? 'no-id'
    },
    skip: !jobId
  })

  const customerId = customer?.actionCompleted ? customer?.customer?.id : undefined
  const scheduleEventFilters: ScheduleEventFilters = {
    ...(jobId && {
      jobs: [jobId],
      status: [
        ScheduleEventStatusType.Dispatched,
        ScheduleEventStatusType.Acknowledged,
        ScheduleEventStatusType.InProgress,
        ScheduleEventStatusType.EnRoute,
        ScheduleEventStatusType.Pending,
        ScheduleEventStatusType.WaitingForAllocation
      ]
    })
  }
  const technicalAttributes =
    jobData?.getJob?.defaultAppointmentRequirements?.attributes?.map((attribute) => attribute.attributeId) ?? []
  const selectedJobTrade = jobData?.getJob?.tradeType
  const selectedScheduleEventFromDate = reservedScheduleEvent
    ? DateTime.fromISO(reservedScheduleEvent.scheduled.from).toFormat(dateConfig.luxonFormat.basic)
    : undefined
  const skillTechAttributeLinkParams =
    technicalAttributes?.length > 0 ? '' + technicalAttributes.map((a) => `&st_${a}=100`).join('') : ''
  const scheduleLink = `/schedule${
    selectedScheduleEventFromDate ? `/${selectedScheduleEventFromDate}` : ''
  }?onlyAvailable=1${selectedJobTrade ? `&trade=${selectedJobTrade}` : ''}${skillTechAttributeLinkParams}`

  const handleFormCompletion = async () => {
    if (!jobId || !reservedScheduleEvent || !bookingWindow) {
      onUpdate({ ...result, actionCompleted: true })
      return
    }
    const comment = getJobNotes(userFragment, DateTime.fromISO(reservedScheduleEvent.scheduled.from), bookingWindow)
    await createJobComment({
      variables: {
        input: {
          jobId,
          message: comment
        }
      }
    })
    onUpdate({ ...result, actionCompleted: true })
  }

  const updateTimeAllowed = (id: string) => setBookingWindow(find(bookingWindowOptions, { id }) as BookingWindow)

  const completedAllSteps = useMemo(
    () => reservedScheduleEvent && suitableTimeFound === SuitableTime.Suitable && !!bookingWindow,
    [reservedScheduleEvent, suitableTimeFound]
  )

  const filteredBookingWindowOptions = useMemo(
    () =>
      filterBookingWindowOptions(reservedScheduleEvent?.scheduled).map((option) => ({
        id: option.id,
        label: option.label
      })),
    [reservedScheduleEvent]
  )

  return (
    <>
      <CustomerAction input={{ contact }} result={customer} onUpdate={setCustomer} />
      {customerId && (
        <>
          <SelectJobAction input={{ customerId }} result={job} onUpdate={setJob} />
        </>
      )}

      {jobId && (
        <>
          <JobStageAction input={{ jobId }} result={jobStage} onUpdate={setJobStage} />
          {jobStage?.actionCompleted && jobStage.jobStage && (
            <>
              {jobStage.jobStage === JobStage.Project && (
                <>
                  <Callout type="script">
                    <EuiText>
                      I'll put you on a brief hold whilst I transfer you through to our dispatch team who can assist you
                      further okay <strong>{contact?.firstName}</strong>?
                    </EuiText>
                  </Callout>
                </>
              )}
              {jobStage.jobStage === JobStage.FirstVisit && (
                <>
                  <Callout type="script" className="callout--flat-list">
                    <EuiText>
                      <ul>
                        <li>
                          Excellent, I will just bring up the schedule to find out when our next available technician
                          will be able to attend
                          {contact?.firstName && <strong> {contact?.firstName}</strong>}
                        </li>
                        <li>Did you have a preferred date in mind?</li>
                      </ul>
                    </EuiText>
                  </Callout>
                  {!reservedScheduleEvent && <Callout type="note">Select appointment you wish to reschedule</Callout>}
                  <ReservedScheduleEvents
                    onSelect={setReservedScheduleEvent}
                    scheduleLink={''}
                    isVisible
                    scheduleFilters={jobId ? scheduleEventFilters : {}}
                    includeTitle={false}
                  />
                  {reservedScheduleEvent && (
                    <>
                      <Callout type="note" title="Select a booking window">
                        <EuiText>
                          <EuiLink href={scheduleLink} target="_blank" rel="noopener noreferrer">
                            Go to appointment
                          </EuiLink>{' '}
                          Right click on the appointment and select "Move to another day"
                        </EuiText>
                      </Callout>
                      <EuiSpacer />

                      <EuiTitle size="xs">
                        <h3>Time allowed</h3>
                      </EuiTitle>
                      <EuiSpacer size="s" />
                      <EuiRadioGroup
                        options={filteredBookingWindowOptions.map((t) => ({ id: t.id, label: t.label }))}
                        idSelected={bookingWindow?.id}
                        onChange={updateTimeAllowed}
                        name="timeAllowed"
                      />
                      <EuiSpacer />
                      <Callout
                        type="script"
                        title="Good news, I can rebook this one for as soon as (date and time) - does that suit?"
                      />
                      <EuiSpacer size="s" />
                      <EuiKeyPadMenu className="fs-keypadmenu">
                        {suitableTimeOptions.map((option) => (
                          <EuiKeyPadMenuItem
                            key={option.id}
                            id={option.id}
                            label={option.label}
                            isSelected={option.isSelected}
                            onClick={() => setSuitableTimeFound(option.id)}
                          >
                            <EuiIcon type={option.icon} size="xl" />
                          </EuiKeyPadMenuItem>
                        ))}
                      </EuiKeyPadMenu>
                    </>
                  )}
                  <EuiSpacer />

                  {suitableTimeFound === SuitableTime.NotSuitable && (
                    <>
                      <EuiSpacer />
                      <Callout type="script">
                        <EuiText>
                          Not a problem, we also have these times available: (give dates/liaise with customer to find
                          preferred date).
                        </EuiText>
                      </Callout>
                      <Callout type="note" title="Repeat until you find a satisfactory time window for the customer" />
                    </>
                  )}

                  {suitableTimeFound === SuitableTime.Suitable && (
                    <>
                      <EuiSpacer />
                      <Callout type="note" title="Assign the job to the suitable TBA on the schedule" />
                      <Callout type="script">
                        <EuiText>
                          Excellent, whilst I update the job notes, can you please confirm your email address
                          {customer?.customer?.email && (
                            <>
                              {' '}
                              is <strong>{customer.customer.email}</strong>
                            </>
                          )}
                          ?
                        </EuiText>
                      </Callout>
                      <EuiSpacer />
                      <div className="workflow__detail-wrapper">
                        <JobSummaryContainer jobId={jobId} />
                        <EuiSpacer />
                        <EuiLink href={`/jobs/${jobId}`} target="_blank">
                          Open job card for more details
                        </EuiLink>
                      </div>

                      <EuiSpacer />
                      <Callout type="script" title="Once confirmed">
                        <EuiText>
                          That’s all done now, job notes have been updated and I have resent you your confirmation email
                        </EuiText>
                      </Callout>
                      <Callout type="warning" title="Important">
                        <EuiText>
                          Please email DSR to advise them of the change to booking date/time. Include:
                          <ul>
                            <li>Job number,</li>
                            <li>Original Date/time,</li>
                            <li>New Date/time</li>
                          </ul>
                        </EuiText>
                      </Callout>
                    </>
                  )}
                </>
              )}
              <EuiSpacer />
              <EuiButton
                fill
                color="primary"
                onClick={handleFormCompletion}
                disabled={loading || !completedAllSteps}
                isLoading={loading}
              >
                I've completed these steps
              </EuiButton>
            </>
          )}
        </>
      )}
    </>
  )
}

interface JobStageActionInput extends BaseActionInput {
  jobId: string
}

interface JobStageActionResult {
  jobStage?: JobStage
  actionCompleted?: boolean
}

const JobStageAction = (props: WorkflowActionProps<JobStageActionInput, JobStageActionResult>) => {
  const { input, onUpdate, result } = props
  const { jobId } = input ?? {}
  const { jobStage, actionCompleted } = result ?? {}

  const options = [
    { id: JobStage.FirstVisit, label: 'First visit', icon: 'inspect', isSelected: jobStage === JobStage.FirstVisit },
    {
      id: JobStage.Project,
      label: 'Complete quoted works',
      icon: 'gear',
      isSelected: jobStage === JobStage.Project
    }
  ]

  const setJobStage = (jobStage: JobStage | undefined) => onUpdate({ ...result, jobStage })
  const setActionCompleted = (actionCompleted: boolean | undefined) => onUpdate({ ...result, actionCompleted })
  const handleChange = () => onUpdate({ ...result, actionCompleted: false })

  return (
    <WorkflowAction
      title="Job stage"
      value={
        actionCompleted ? (jobStage === JobStage.FirstVisit ? 'First visit' : 'Completed quoted works') : undefined
      }
      onClickChange={handleChange}
    >
      <div className="workflow__detail-wrapper">
        <JobSummaryContainer jobId={jobId} />
        <EuiSpacer />
        <EuiLink href={`/jobs/${jobId}`} target="_blank">
          Open job card for more details
        </EuiLink>
      </div>

      <EuiSpacer />
      <Callout type="script" title="Is this for a first visit or to complete quoted works?" />
      <EuiSpacer />
      <EuiKeyPadMenu className="fs-keypadmenu">
        {options.map((option) => (
          <EuiKeyPadMenuItem
            key={option.id}
            id={option.id}
            label={option.label}
            isSelected={option.isSelected}
            onClick={() => setJobStage(option.id)}
          >
            <EuiIcon type={option.icon} size="xl" />
          </EuiKeyPadMenuItem>
        ))}
      </EuiKeyPadMenu>
      <EuiSpacer size="s" />
      <EuiButton disabled={isNil(jobStage)} onClick={() => setActionCompleted(true)}>
        Next
      </EuiButton>
      <EuiSpacer />
    </WorkflowAction>
  )
}

const getJobNotes = (_user: UserFragment, dateTime: DateTime, bookingWindow: BookingWindow): string => {
  const bookingWindowOption = bookingWindowOptions.find((option) => option.id === bookingWindow.id)
  return `customer called to rebooked for ${dateTime.toFormat(dateConfig.luxonFormat.date)} ${bookingWindowOption?.label ? `at ${bookingWindowOption.label}` : `${dateTime.toFormat(dateConfig.luxonFormat.time)}`}`
}
