import { EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiLink, EuiSpacer } from '@elastic/eui'
import { Address, AddressEntryMode, addressFragmentToAddress } from '@fallonsolutions/address'
import { compact } from 'lodash-es'
import { AddressFragment } from '../api/generated-types'
import { AutocompleteAddressFieldV2 } from './autocomplete-address-field-v2'
import { ManualAddressForm } from './manual-address-form'

export interface AddressFieldProps {
  showValidationErrors: boolean
  initialEntryMode?: AddressEntryMode
  address?: AddressFragment
  onChange: (result: AddressFieldResult) => void
  // onValidationChange: (valid: boolean) => void
  allowManual?: boolean
}

export interface AddressFieldResult {
  address: AddressFragment
  isValid: boolean
}

export const AddressField = (props: AddressFieldProps) => {
  const { showValidationErrors, onChange, initialEntryMode } = props

  const allowManual = props.allowManual ?? true

  const address = props.address
    ? {
        ...props.address,
        street: props.address.street || '',
        suburb: props.address.suburb || '',
        postcode: props.address.postcode || '',
        state: props.address.state || '',
        ...(props.address.suburbId && { suburbId: props.address.suburbId }),
        country: props.address.country || ''
      }
    : createEmptyAddressFragment()

  const { isValid, streetValid, suburbValid, postcodeValid, stateValid, countryValid } = validateAddress(address)

  const setAddress = (address: AddressFragment) => {
    const { isValid } = validateAddress(address)
    onChange({ address, isValid })
  }

  const formattedAddress = !address
    ? ''
    : [address.street, address.street2, address.suburb, address.postcode, address.state, address.country]
        .filter((p) => p?.trim().length)
        .join(', ')

  const manualForm = address.entryMode
    ? address.entryMode === AddressEntryMode.Manual
    : !initialEntryMode || initialEntryMode === AddressEntryMode.Manual
  const toggleEntryMode = () =>
    setAddress({ ...address, entryMode: manualForm ? AddressEntryMode.Autocomplete : AddressEntryMode.Manual })

  return (
    <>
      {manualForm ? (
        <ManualAddressForm
          showValidationErrors={showValidationErrors}
          isStreetValid={streetValid}
          isSuburbValid={suburbValid}
          isPostcodeValid={postcodeValid}
          isStateValid={stateValid}
          isCountryValid={countryValid}
          setAddress={(address) => setAddress({ ...address, entryMode: AddressEntryMode.Manual })}
          address={addressFragmentToAddress(address) ?? createEmptyAddress(AddressEntryMode.Manual)}
        />
      ) : (
        <EuiFormRow label="Address" isInvalid={showValidationErrors && !isValid}>
          <AutocompleteAddressFieldV2
            initialQuery={formattedAddress}
            showValidationErrors={showValidationErrors}
            isAddressValid={isValid}
            onSelectAddress={(address) =>
              address
                ? setAddress({ ...address, entryMode: AddressEntryMode.Autocomplete })
                : setAddress({ ...createEmptyAddressFragment(), entryMode: AddressEntryMode.Autocomplete })
            }
          />
        </EuiFormRow>
      )}
      {((allowManual && address.entryMode === AddressEntryMode.Autocomplete) ||
        address.entryMode === AddressEntryMode.Manual) && (
        <>
          <EuiSpacer size="s" />
          <EuiFlexGroup gutterSize="none">
            <EuiFlexItem grow={false}>
              <EuiLink style={{ fontSize: '12px' }} onClick={toggleEntryMode}>
                {manualForm ? 'Use autocomplete' : 'Enter address manually'}
              </EuiLink>
            </EuiFlexItem>
          </EuiFlexGroup>
        </>
      )}
    </>
  )
}

const validateAddress = (address: AddressFragment) => {
  const streetValid = !!address.street?.trim().length
  const suburbValid = !!address.suburb?.trim().length
  const postcodeValid = !!address.postcode?.trim().length
  const stateValid = !!address.state?.trim().length
  const countryValid = !!address.country?.trim().length
  const formErrors: boolean[] = compact([!streetValid, !suburbValid, !postcodeValid, !stateValid, !countryValid])
  return {
    isValid: formErrors.length === 0,
    streetValid,
    suburbValid,
    postcodeValid,
    stateValid,
    countryValid
  }
}

const createEmptyAddressFragment = (): AddressFragment => {
  return {
    street: '',
    suburb: '',
    postcode: '',
    state: '',
    suburbId: '',
    country: '',
    entryMode: AddressEntryMode.Autocomplete
  }
}

const createEmptyAddress = (entryMode: AddressEntryMode): Address => {
  return {
    street: '',
    street2: '',
    suburb: '',
    postcode: '',
    state: '',
    suburbId: '',
    country: '',
    entryMode
  }
}
