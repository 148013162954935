import {
  EuiAccordion,
  EuiAvatar,
  EuiComboBoxOptionOption,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHighlight,
  EuiSpacer,
  EuiText
} from '@elastic/eui'
import { first } from 'lodash-es'
import { useState } from 'react'
import JobComboBox, { JobComboBoxValue } from '../jobs/job-combo-box'

export interface ParentJobProps {
  existingJobId?: string | undefined
  onChangeExistingJob: (jobId?: string | undefined) => void
}
export const ParentJob = (props: ParentJobProps) => {
  const { existingJobId, onChangeExistingJob } = props
  const [initialJobs] = useState(existingJobId ? [existingJobId] : [])

  const [comboBoxJobs, setComboBoxJobs] = useState<JobComboBoxValue[]>([])
  const job = first(comboBoxJobs)

  const handleOnChangeJob = (jobs: JobComboBoxValue[]) => {
    if (jobs.length === 0) {
      onChangeExistingJob(undefined)
    } else {
      const firstJob = first(jobs)
      onChangeExistingJob(firstJob?.id as string)
    }
    setComboBoxJobs(jobs)
  }

  const accordionButton = () => {
    return (
      <>
        {job && existingJobId ? (
          <>
            <EuiText size="m">
              <strong>Parent job {job.number}</strong>
            </EuiText>
          </>
        ) : (
          <>Would you like to link this job to an existing job?</>
        )}
      </>
    )
  }

  const comboBoxOptionRender = (
    _option: EuiComboBoxOptionOption<unknown>,
    searchValue: string,
    contentClassName: string
  ) => {
    const option = _option as JobComboBoxValue
    return (
      <EuiFlexGroup gutterSize="s" alignItems="center">
        <EuiFlexItem grow={false}>
          <EuiAvatar size="s" name={option.trade ?? ''} />
        </EuiFlexItem>
        <EuiFlexItem grow={true}>
          <span className={contentClassName}>
            <EuiHighlight search={searchValue}>{option.label}</EuiHighlight>
          </span>
        </EuiFlexItem>
      </EuiFlexGroup>
    )
  }

  return (
    <>
      <EuiAccordion
        id="accordion1"
        buttonContent={accordionButton()}
        arrowDisplay="right"
        className="customer-primary-contact"
      >
        <EuiSpacer size="m" />
        <JobComboBox
          initialJobIds={initialJobs}
          singleSelection={true}
          onChangeJobs={handleOnChangeJob}
          jobs={comboBoxJobs}
          renderOption={comboBoxOptionRender}
        />
      </EuiAccordion>
      <EuiSpacer size="m" />
    </>
  )
}
