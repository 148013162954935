import { Dinero } from 'dinero.js'
import { Money } from '../api/generated-types'
import { formatMoney, isZero } from '../common/money'
import { SummaryRow } from '../common/summary-row'
import '../static/css/option.css'
import '../static/css/price-summary.css'
import '../static/css/summary-row.css'

const nonZeroHighlightColor = '#FF3B30'

export interface PriceSummaryRowProps {
  amount: Money | Dinero
  label: string
  currencySymbol?: boolean
  nonZeroHighlight?: boolean
  bold?: boolean
  negative?: boolean
  paddingRight?: string
  labelAlignment?: 'left' | 'right'
  tooltip?: string
  color?: string
}

const PriceSummaryRow = ({
  label,
  amount,
  currencySymbol,
  nonZeroHighlight,
  negative,
  bold,
  paddingRight,
  labelAlignment,
  tooltip,
  color
}: PriceSummaryRowProps) => {
  const valueString = formatMoney(amount, {
    currencySymbol: currencySymbol ?? false,
    negative: negative ?? false
  })

  const isNonZero = !isZero(amount)

  return (
    <SummaryRow
      label={label}
      value={valueString}
      bold={bold}
      paddingRight={paddingRight}
      labelAlignment={labelAlignment}
      tooltip={tooltip}
      {...(nonZeroHighlight && isNonZero && { color: nonZeroHighlightColor })}
      {...(color && { color })}
    />
  )
}

export default PriceSummaryRow
