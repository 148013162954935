import { ReviewForm } from './review-form'

export interface CreateReviewFormProps {
  customerId?: string
  jobIds?: string[]
  userIds?: string[]
  onClose: () => void
  onFiveStarReview?: () => void
}

export const CreateReviewForm = (props: CreateReviewFormProps) => {
  return <ReviewForm {...props} />
}
