import { ApolloError, useQuery } from '@apollo/client'
import { GetServiceAreaByKeyDocument, GetSuburbDocument, ServiceArea } from '../../api/generated-types'

export interface GetServiceAreaProps {
  companyId: string
  suburbId: string | undefined
}

export interface GetServiceAreaResults {
  serviceArea: ServiceArea | undefined | null
  loading: boolean
  error: ApolloError | undefined
}
export const GetServiceArea = (props: GetServiceAreaProps): GetServiceAreaResults => {
  const { suburbId, companyId } = props
  const {
    data: suburbData,
    loading: suburbLoading,
    error: suburbError
  } = useQuery(GetSuburbDocument, { variables: { input: { id: suburbId ?? '' } }, skip: !suburbId })

  const {
    data: serviceAreaData,
    loading: serviceAreaLoading,
    error
  } = useQuery(GetServiceAreaByKeyDocument, {
    variables: { input: { key: `${companyId}|${suburbData?.getSuburb?.suburb?.key}` } },
    skip: !suburbData || !!suburbLoading || !!suburbError
  })

  const serviceArea = serviceAreaData?.getServiceAreaByKey?.serviceArea
    ? (serviceAreaData?.getServiceAreaByKey?.serviceArea as unknown as ServiceArea)
    : undefined
  return { serviceArea, loading: serviceAreaLoading || suburbLoading, error }
}
