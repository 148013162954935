import { useMutation } from '@apollo/client'
import {
  EuiButton,
  EuiButtonEmpty,
  EuiButtonGroup,
  EuiButtonGroupOptionProps,
  EuiCallOut,
  EuiCheckbox,
  EuiDatePicker,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiOverlayMask,
  EuiRange,
  EuiSpacer,
  EuiTextArea
} from '@elastic/eui'
import { dateConfig } from '@fallonsolutions/date'
import { compact, first } from 'lodash-es'
import moment, { Moment } from 'moment-timezone'
import { useEffect, useMemo, useState } from 'react'
import {
  CreateReviewDocument,
  EditReviewDocument,
  ReviewFlag,
  ReviewFragment,
  ReviewSource,
  ReviewStatusType,
  TradeType
} from '../api/generated-types'
import { EnumTypeComboBox } from '../common/enum-combo-box'
import CustomerComboBox, { CustomerComboBoxItem } from '../customers/customer-combo-box'
import JobComboBox, { JobComboBoxValue } from '../jobs/job-combo-box'
import UserComboBox, { UserComboItem } from '../users/user-combo-box'
import { ReviewFormSource } from './review-form-source'

export interface ReviewFormProps {
  review?: ReviewFragment
  customerId?: string
  jobIds?: string[]
  userIds?: string[]

  // customer?: CustomerLinkFragment
  // job?: JobLinkFragment
  // user?: UserLinkFragment
  onClose: () => void
  onFiveStarReview?: () => void
}

export const ReviewForm = (props: ReviewFormProps) => {
  const { review, onFiveStarReview, onClose } = props

  const reviewId = review?.id

  const [jobs, setJobs] = useState<JobComboBoxValue[]>([])
  const [customer, setCustomer] = useState<CustomerComboBoxItem | undefined>(undefined) //props.customer)
  const [users, setUsers] = useState<UserComboItem[]>([])

  const now = useMemo(() => moment().tz(dateConfig.defaultTimezone), [])

  const [status, setStatus] = useState<ReviewStatusType>(review?.status.status ?? ReviewStatusType.Draft)
  const [score, setScore] = useState<number | undefined>(review?.score ?? undefined)
  const [body, setBody] = useState(review?.body ?? '')
  const [source, setSource] = useState(review?.source ?? ReviewSource.Google)
  const [date, setDate] = useState<Moment>(review?.date ? moment(review.date) : now)
  const [firstName, setFirstName] = useState(review?.reviewer?.firstName ?? '')
  const [lastName, setLastName] = useState(review?.reviewer?.lastName ?? '')
  const [link, setLink] = useState(review?.link ?? '')
  const [trade, setTrade] = useState(review?.trade ?? TradeType.None)
  const [flag, setFlag] = useState(review?.flag ?? ReviewFlag.None)

  const showReviewUrlInput = [ReviewSource.Google, ReviewSource.Facebook, ReviewSource.ProductReview].includes(source)

  const [createReview, { data: createData, loading: createLoading, error: createError }] = useMutation(
    CreateReviewDocument,
    {
      refetchQueries: ['SearchReviews', 'GetJobReviews', 'GetReview'],
      awaitRefetchQueries: true
    }
  )
  const [editReview, { data: editData, loading: editLoading, error: editError }] = useMutation(EditReviewDocument, {
    refetchQueries: ['SearchReviews', 'GetJobReviews', 'GetReview'],
    awaitRefetchQueries: true
  })
  const loading = editLoading || createLoading
  const error = editError ?? createError

  const handleSubmit = () => {
    if (!score) {
      return
    }
    if (reviewId) {
      console.log('edit review')
      editReview({
        variables: {
          input: {
            reviewId,
            status,
            reviewer: {
              firstName,
              lastName
            },
            score,
            body,
            source,
            date,
            trade,
            flag,
            ...(link.trim().length > 0 && { link: link.trim() }),
            customerId: customer?.id ?? undefined,
            userIds: compact(users.map((user) => user.id)),
            jobIds: compact(jobs.map((job) => job.id))
          }
        }
      })
    } else {
      console.log('create review')
      createReview({
        variables: {
          input: {
            reviewer: {
              firstName,
              lastName
            },
            score,
            statusType: status,
            body,
            source,
            date,
            trade,
            flag,
            ...(link.trim().length > 0 && { link: link.trim() }),
            customerId: customer?.id ?? undefined,
            userIds: compact(users.map((user) => user.id)),
            jobIds: compact(jobs.map((job) => job.id))
          }
        }
      })
    }
  }

  useEffect(() => {
    if (createData?.createReview?.review || editData?.editReview?.review) {
      if ((createData?.createReview?.review?.score ?? 0) >= 5 && onFiveStarReview) {
        onFiveStarReview()
      }
      onClose()
    }
  }, [createData, editData, onClose, onFiveStarReview])

  const handleSelectCustomer = (customer: CustomerComboBoxItem | undefined) => {
    setCustomer(customer)
    if (customer?.firstName) {
      setFirstName(customer.firstName ?? '')
      setLastName(customer.lastName ?? '')
    } else {
      setFirstName('')
      setLastName('')
    }
  }

  const handleSelectJobs = (jobs: JobComboBoxValue[]) => {
    console.log('select jobs', jobs)
    const trade = first(jobs)?.trade
    setJobs(jobs)
    if (trade) {
      setTrade(trade as unknown as TradeType)
    }
  }

  const statusOptions: EuiButtonGroupOptionProps[] = [
    {
      id: ReviewStatusType.Draft,
      label: 'Draft'
    },
    {
      id: ReviewStatusType.Created,
      label: 'Created'
    },
    {
      id: ReviewStatusType.Replied,
      label: 'Replied'
    },
    {
      id: ReviewStatusType.Withdrawn,
      label: 'Withdrawn'
    },
    {
      id: ReviewStatusType.Changed,
      label: 'Changed'
    }
  ]

  return (
    <EuiOverlayMask>
      <EuiModal onClose={onClose} maxWidth={1300} style={{ minWidth: '720px' }}>
        <EuiModalHeader>
          <EuiModalHeaderTitle>{reviewId ? 'Edit' : 'Create'} review</EuiModalHeaderTitle>
        </EuiModalHeader>
        <EuiModalBody>
          {error && <EuiCallOut color="danger">Error creating review: {error.message}</EuiCallOut>}

          <EuiFlexGroup>
            <EuiFlexItem>
              <EuiFormRow label="Customer" fullWidth>
                <CustomerComboBox
                  initialCustomerIds={props.customerId ? [props.customerId] : []}
                  customers={customer ? [customer] : []}
                  singleSelection={true}
                  onChangeCustomers={(customers) => handleSelectCustomer(first(customers))}
                />
              </EuiFormRow>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiFormRow label="Reviewer first name">
                <EuiFieldText value={firstName} onChange={(e) => setFirstName(e.target.value)} />
              </EuiFormRow>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiFormRow label="Reviewer last name">
                <EuiFieldText value={lastName} onChange={(e) => setLastName(e.target.value)} />
              </EuiFormRow>
            </EuiFlexItem>
          </EuiFlexGroup>
          <EuiSpacer />

          <EuiFlexGroup>
            <EuiFlexItem>
              <EuiFormRow label="Job" fullWidth>
                <JobComboBox initialJobIds={props.jobIds} jobs={jobs} onChangeJobs={handleSelectJobs} />
              </EuiFormRow>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiFormRow label="Internal users mentioned">
                <UserComboBox
                  initialUserIds={props.userIds}
                  label="Users"
                  placeholder="Users"
                  users={users}
                  onChangeUsers={setUsers}
                />
              </EuiFormRow>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiFormRow label="Review date">
                <EuiDatePicker
                  selected={date}
                  onChange={(date) => setDate(date ?? now)}
                  maxDate={now.tz(dateConfig.defaultTimezone)}
                  dateFormat="ddd D MMM YYYY"
                  disabled={loading}
                  popoverPlacement="downRight"
                />
              </EuiFormRow>
            </EuiFlexItem>
          </EuiFlexGroup>
          <EuiSpacer />

          <EuiFlexGroup>
            <EuiFlexItem grow={false}>
              <EuiFormRow label="Primary trade">
                <EnumTypeComboBox
                  type={TradeType}
                  values={[trade]}
                  onChange={(trades) => setTrade(first(trades as TradeType[]) ?? TradeType.None)}
                  singleSelection={true}
                />
              </EuiFormRow>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiFormRow label="Flag">
                <>
                  <EuiSpacer size="s" />
                  <EuiCheckbox
                    label="Notable review"
                    id="notable-review"
                    checked={flag === ReviewFlag.Notable}
                    onChange={(event) => setFlag(event.target.checked ? ReviewFlag.Notable : ReviewFlag.None)}
                  />
                </>
              </EuiFormRow>
            </EuiFlexItem>
            <EuiFlexItem grow={true} />
          </EuiFlexGroup>

          <EuiSpacer />

          <EuiFlexGroup>
            <EuiFlexItem>
              <EuiFormRow label="Score">
                <EuiRange
                  min={0}
                  max={5}
                  step={0.5}
                  showRange
                  showTicks
                  tickInterval={0.5}
                  ticks={[
                    { label: '0', value: 0 },
                    { label: '1', value: 1 },
                    { label: '2', value: 2 },
                    { label: '3', value: 3 },
                    { label: '4', value: 4 },
                    { label: '5', value: 5 }
                  ]}
                  value={score ?? 0}
                  onChange={(e: any) => setScore(parseFloat(e.target.value) ?? 0)}
                />
              </EuiFormRow>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiFormRow label="Status">
                <EuiButtonGroup
                  legend="Status"
                  options={statusOptions}
                  idSelected={status}
                  onChange={(id: string) => setStatus(id as ReviewStatusType)}
                  buttonSize="compressed"
                  color="primary"
                />
              </EuiFormRow>
            </EuiFlexItem>
          </EuiFlexGroup>
          <EuiSpacer />

          <EuiFormRow label="Review">
            <EuiTextArea value={body} onChange={(e) => setBody(e.target.value)} />
          </EuiFormRow>

          <ReviewFormSource source={source} setSource={setSource} />
          <EuiFormRow label="URL" helpText="Link to the review (if applicable)">
            <EuiFieldText
              disabled={!showReviewUrlInput}
              type="url"
              value={link}
              onChange={(e) => setLink(e.target.value)}
            />
          </EuiFormRow>
        </EuiModalBody>
        <EuiModalFooter>
          {!loading && (
            <EuiButtonEmpty onClick={onClose} disabled={loading}>
              Cancel
            </EuiButtonEmpty>
          )}
          <EuiButton fill onClick={() => handleSubmit()} isLoading={loading}>
            {reviewId ? 'Edit' : 'Create'} review
          </EuiButton>
        </EuiModalFooter>
      </EuiModal>
    </EuiOverlayMask>
  )
}
