import { ContactLifecycleStage } from '../api/generated-types'
import { decamelise } from '../common/utils'
import '../static/css/lifecycle-stage-badge.css'

export interface LifecycleStageStatusBadgeProps {
  stage: ContactLifecycleStage
  title?: string
}

export const LifecycleStageBadge = ({ stage, title }: LifecycleStageStatusBadgeProps) => {
  return (
    <span className={`lifecycle-stage-badge lifecycle-stage-badge--${String(stage).toLowerCase()}`} title={title}>
      {decamelise(stage)}
    </span>
  )
}
