/* eslint-disable max-lines */
import { CustomerType, JobTopicType } from '@fallonsolutions/types'
import { includes } from 'lodash-es'
import { useEffect } from 'react'
import { makeHumanReadable } from '../../common/utils'
import { getTopicName } from '../get-topic-name'
import { OutOfScope } from '../helpers/out-of-topic'
import { TopicAccessControlAction } from '../topics/access-control/action-topic-access-control'
import { TopicActionResult } from '../topics/action-topic-model'
import { TopicAlarmAction } from '../topics/alarm/action-topic-alarm'
import { TopicAntennaAction } from '../topics/antenna/action-topic-antenna'
import { TopicPlumbingBackflowTestingAction } from '../topics/backflow-testing/action-topic-plumbing-backflow-testing'
import { TopicPlumbingBidetInstallAction } from '../topics/bidet/action-topic-plumbing-bidet-install'
import { TopicPlumbingBidetRepairAction } from '../topics/bidet/action-topic-plumbing-bidet-repair'
import { TopicPlumbingBurstWaterMainsAction } from '../topics/burst-water-mains/action-topic-plumbing-burst-water-mains'
import { TopicCCTVAction } from '../topics/cctv/action-topic-cctv'
import { TopicCeilingFanAction } from '../topics/ceiling-fan/action-topic-ceiling-fan'
import { TopicCoffeeMachineAction } from '../topics/coffee-machine/action-topic-coffee-machine'
import { TopicCommercialElectricalAction } from '../topics/commercial-electrical/action-topic-commercial-electrical'
import { TopicCooktopAction } from '../topics/cooktop/action-topic-cooktop'
import { TopicDishwasherAction } from '../topics/dishwasher/action-topic-dishwasher'
import { TopicDownpipeAction } from '../topics/downpipe/action-topic-downpipe'
import { TopicDrainRevisitAction } from '../topics/drain-revisit/action-topic-drain-revisit'
import { TopicPlumbingDrainAction } from '../topics/drain/action-topic-plumbing-drain'
import { TopicDryerAction } from '../topics/dryer/action-topic-dryer'
import { TopicExhaustFanAction } from '../topics/exhaust-fan/action-topic-exhaust-fan'
import { TopicPlumbingFlexiHoseInstallAction } from '../topics/flexi-hose/action-topic-plumbing-flexi-hose-install'
import { TopicPlumbingFlexiHoseRepairAction } from '../topics/flexi-hose/action-topic-plumbing-flexi-hose-repair'
import { TopicFridgeFreezerAction } from '../topics/fridge/action-topic-fridge'
import { TopicPlumbingGarbageDisposalInstallAction } from '../topics/garbage-disposal/action-topic-plumbing-garbage-disposal-install'
import { TopicPlumbingGasAction } from '../topics/gas/action-topic-plumbing-gas'
import { TopicGutterAction } from '../topics/gutter/action-topic-gutter'
import { TopicHighPowerBillAction } from '../topics/high-power-bill/action-topic-high-power-bill'
import { TopicHomeAutomationAction } from '../topics/home-automation/action-topic-home-automation'
import { TopicEnergyAuditAction } from '../topics/home-energy-audit/action-topic-home-energy-audit'
import { TopicHotWaterAction } from '../topics/hot-water/action-topic-hot-water'
import { TopicHVACInspectionAction } from '../topics/hvac-inspection/action-topic-hvac-inspection'
import { TopicHVACMaintenanceAction } from '../topics/hvac-maintenance/action-topic-hvac-maintenance'
import { TopicHVACQuoteInstallAction } from '../topics/hvac-quote-install/action-topic-hvac-quote-install'
import { TopicHVACRepairAction } from '../topics/hvac-repair/action-topic-hvac-repair'
import { TopicHVACServiceAction } from '../topics/hvac/action-topic-hvac-service'
import { TopicInspectionAction } from '../topics/inspection/action-topic-inspection'
import { TopicPlumbingInsuranceQuotesAction } from '../topics/insurance-quotes/action-topic-plumbing-insurance-quotes'
import { TopicIntercomAction } from '../topics/intercom/action-topic-intercom'
import { TopicCappingOffInterconnectionAction } from '../topics/interconnection/action-topic-plumbing-capping-off-interconnection'
import { TopicLightingAction } from '../topics/lighting/action-topic-lighting'
import { TopicMicrowaveAction } from '../topics/microwave/action-topic-microwave'
import { TopicOtherAction } from '../topics/other/action-topic-other'
import { TopicOvenAction } from '../topics/oven/action-topic-oven'
import { TopicPlumbingInspectionAction } from '../topics/plumbing-inspection/action-topic-plumbing-inspection'
import { TopicPlumbingRevisitAction } from '../topics/plumbing-revisit/action-topic-plumbing-revisit'
import { TopicPoolAction } from '../topics/pool/action-topic-pool'
import { TopicPowerPointAction } from '../topics/powerpoint/action-topic-powerpoint'
import { TopicPlumbingPrePurchaseInspectionAction } from '../topics/pre-purchase-inspection/action-topic-plumbing-pre-purchase-inspection'
import { TopicPlumbingPumpAction } from '../topics/pump/action-topic-plumbing-pump'
import { TopicRangehoodAction } from '../topics/rangehood/action-topic-rangehood'
import { TopicRewireAction } from '../topics/rewire/action-topic-rewire'
import { TopicSecurityInspectionAction } from '../topics/security-inspection/action-topic-security-inspection'
import { TopicSecurityMonitoringAction } from '../topics/security-monitoring/action-topic-security-monitoring'
import { TopicShowerAction } from '../topics/shower/action-topic-shower'
import { TopicPlumbingSinkInstallAction } from '../topics/sink/action-topic-plumbing-sink-install'
import { TopicSmokeAlarmInspectionAction } from '../topics/smoke-alarm-inspection/action-topic-smoke-alarm-inspection'
import { TopicSmokeAlarmAction } from '../topics/smoke-alarm/action-topic-smoke-alarm'
import { TopicSolarAction } from '../topics/solar/action-topic-solar'
import { TopicSolarInspection } from '../topics/solar/action-topic-solar-inspection'
import { TopicStereoAction } from '../topics/stereo/action-topic-stereo'
import { TopicPlumbingSubmeteringAction } from '../topics/submetering/action-topic-plumbing-submetering'
import { TopicSwitchboardAction } from '../topics/switchboard/action-topic-switchboard'
import { TopicPlumbingTapInstallAction } from '../topics/tap/action-topic-plumbing-tap-install'
import { TopicPlumbingTapRepairAction } from '../topics/tap/action-topic-plumbing-tap-repair'
import { TopicTelephoneDataPointAction } from '../topics/telephone/action-topic-telephone'
import { TopicPlumbingToiletInstallAction } from '../topics/toilet/action-topic-plumbing-toilet-install'
import { TopicPlumbingToiletRepairAction } from '../topics/toilet/action-topic-plumbing-toilet-repair'
import { TopicTrippingPowerAction } from '../topics/tripping-power/action-topic-tripping-power'
import { TopicWallMountTVAction } from '../topics/wall-mount-tv/action-topic-wall-mount-tv'
import { TopicWashingMachineAction } from '../topics/washing-machine/action-topic-washing-machine'
import { TopicPlumbingWaterEfficiencyCertificatesAction } from '../topics/water-efficiency/action-topic-plumbing-water-efficiency-certificates'
import { TopicPlumbingWaterFilterComponentsRepairAction } from '../topics/water-filter/action-topic-plumbing-water-filter-components-repair'
import { TopicPlumbingWaterFilterInstallAction } from '../topics/water-filter/action-topic-plumbing-water-filter-install'
import { TopicPlumbingWaterHammeringAction } from '../topics/water-hammering/action-topic-plumbing-water-hammering'
import { TopicPlumbingWaterLeakDetectionAction } from '../topics/water-leak-detection/action-topic-plumbing-water-leak-detection'
import { TopicWaterLeakAction } from '../topics/water-leak/action-topic-water-leak'
import { TopicWaterPressureAction } from '../topics/water-pressure/action-topic-water-pressure'
import { WorkflowActionProps } from '../workflow-model'
import { ContactRole } from './action-customer-qualification'
import { JobClassificationActionInput, JobClassificationActionResult } from './action-job-classification-types'

// eslint-disable-next-line max-lines-per-function
export const JobClassificationActionLegacy = (
  props: WorkflowActionProps<JobClassificationActionInput, JobClassificationActionResult>
) => {
  const { input, onUpdate, result } = props
  const { topic, customerType } = result ?? {}
  const { editable, contact, customer, customerContactRole, existingCustomer, property, serviceArea } = input
  const commonTopicActionInput = {
    editable,
    contact,
    customer,
    customerContactRole,
    existingCustomer,
    property,
    serviceArea
  }
  const isDomestic = customerType === CustomerType.Domestic
  const setTopic = (topic: TopicActionResult | undefined) => {
    const { customerType, trade, type, category, actionCompleted, requirements } = topic ?? {}
    const value =
      actionCompleted === true ? `${customerType} | ${trade} | ${makeHumanReadable(category)} | ${type}` : undefined
    const updatedTopic = { ...topic, value }
    onUpdate({
      ...result,
      topic: updatedTopic,
      customerType,
      trade,
      type,
      category,
      requirements,
      actionCompleted,
      value
    })
  }

  useEffect(() => {
    const customerType = includes(
      [ContactRole.Commercial, ContactRole.Tenant, ContactRole.RealEstateAgent, ContactRole.Other],
      input.customerContactRole
    )
      ? CustomerType.Commercial
      : CustomerType.Domestic
    if (customerType !== result?.customerType) {
      setTopic({ ...result, customerType })
    }
  }, [input.customerContactRole])

  // eslint-disable-next-line max-lines-per-function,complexity
  const actionForTopic = (topicReference: JobTopicType) => {
    switch (topicReference) {
      case JobTopicType.HVACConditionAndFilterCleanReport:
        return (
          <TopicHVACServiceAction
            input={{ ...commonTopicActionInput, topicName: getTopicName(topicReference), isSpecial: true }}
            result={topic}
            onUpdate={setTopic}
          />
        )
      case JobTopicType.HomeEnergyAudit159:
        return (
          <TopicEnergyAuditAction
            onUpdate={setTopic}
            result={topic}
            input={{
              ...commonTopicActionInput,
              topicName: getTopicName(topicReference)
            }}
          />
        )
      case JobTopicType.DrainClear99:
        return (
          <>
            {isDomestic ? (
              <>
                <TopicPlumbingDrainAction
                  input={{
                    ...commonTopicActionInput,
                    topicName: getTopicName(topicReference),
                    drainClearSpecial: true,
                    handleChangeJobTopic: input.handleJobTopicChanged
                  }}
                  result={topic}
                  onUpdate={setTopic}
                />
              </>
            ) : (
              <>
                <OutOfScope
                  issue={'$99 drain clear'}
                  reason="sorry we do not offer the $99 drain clear special to non domestic customers"
                  isOutOfScope={true}
                />
              </>
            )}
          </>
        )
      case JobTopicType.SwitchBoardInspection99:
        return (
          <>
            {isDomestic ? (
              <TopicSwitchboardAction
                input={{ ...commonTopicActionInput, topicName: getTopicName(topicReference), switchBoardSpecial: true }}
                result={topic}
                onUpdate={setTopic}
              />
            ) : (
              <>
                <OutOfScope
                  issue={'$99 switch board inspection'}
                  reason="sorry we do not offer the $99 switch board inspection special to non domestic customers"
                  isOutOfScope={true}
                />
              </>
            )}
          </>
        )
      case JobTopicType.HotWaterInspection99:
        return (
          <>
            {isDomestic ? (
              <TopicHotWaterAction
                input={{ ...commonTopicActionInput, topicName: getTopicName(topicReference), hotWaterSpecial: true }}
                result={topic}
                onUpdate={setTopic}
              />
            ) : (
              <>
                <OutOfScope
                  issue={'$99 hot water special'}
                  reason="sorry we do not offer the $99 hot water special to non domestic customers"
                  isOutOfScope={true}
                />
              </>
            )}
          </>
        )
      case JobTopicType.SolarInspection:
        return (
          <TopicSolarInspection
            input={{ ...commonTopicActionInput, topicName: getTopicName(topicReference) }}
            result={topic}
            onUpdate={setTopic}
          />
        )
      case JobTopicType.AccessControl:
        return (
          <TopicAccessControlAction
            input={{ ...commonTopicActionInput, topicName: getTopicName(topicReference), serviceArea }}
            result={topic}
            onUpdate={setTopic}
          />
        )
      case JobTopicType.Lighting:
        return (
          <TopicLightingAction
            input={{ ...commonTopicActionInput, topicName: getTopicName(topicReference) }}
            result={topic}
            onUpdate={setTopic}
          />
        )
      case JobTopicType.Alarm:
        return (
          <TopicAlarmAction
            input={{ ...commonTopicActionInput, topicName: getTopicName(topicReference) }}
            result={topic}
            onUpdate={setTopic}
          />
        )
      case JobTopicType.Antenna:
        return (
          <TopicAntennaAction
            input={{ ...commonTopicActionInput, topicName: getTopicName(topicReference) }}
            result={topic}
            onUpdate={setTopic}
          />
        )
      case JobTopicType.CCTV:
        return (
          <TopicCCTVAction
            input={{ ...commonTopicActionInput, topicName: getTopicName(topicReference), serviceArea }}
            result={topic}
            onUpdate={setTopic}
          />
        )
      case JobTopicType.CeilingFan:
        return (
          <TopicCeilingFanAction
            input={{ ...commonTopicActionInput, topicName: getTopicName(topicReference) }}
            result={topic}
            onUpdate={setTopic}
          />
        )
      case JobTopicType.CommercialElectrical:
        return (
          <TopicCommercialElectricalAction
            input={{ ...commonTopicActionInput, topicName: getTopicName(topicReference) }}
            result={topic}
            onUpdate={setTopic}
          />
        )
      case JobTopicType.ExhaustFan:
        return (
          <TopicExhaustFanAction
            input={{ ...commonTopicActionInput, topicName: getTopicName(topicReference) }}
            result={topic}
            onUpdate={setTopic}
          />
        )
      case JobTopicType.HighPowerBill:
        return (
          <TopicHighPowerBillAction
            input={{ ...commonTopicActionInput, topicName: getTopicName(topicReference) }}
            result={topic}
            onUpdate={setTopic}
          />
        )
      case JobTopicType.Intercom:
        return (
          <TopicIntercomAction
            input={{ ...commonTopicActionInput, topicName: getTopicName(topicReference), serviceArea }}
            result={topic}
            onUpdate={setTopic}
          />
        )
      case JobTopicType.TrippingPower:
        return (
          <TopicTrippingPowerAction
            input={{ ...commonTopicActionInput, topicReference, topicName: getTopicName(topicReference) }}
            result={topic}
            onUpdate={setTopic}
          />
        )
      case JobTopicType.LossOfPower:
        return (
          <TopicTrippingPowerAction
            input={{ ...commonTopicActionInput, topicReference, topicName: getTopicName(topicReference) }}
            result={topic}
            onUpdate={setTopic}
          />
        )
      case JobTopicType.PhoneLandLine:
        return (
          <TopicTelephoneDataPointAction
            input={{ ...commonTopicActionInput, topicReference, topicName: getTopicName(topicReference) }}
            result={topic}
            onUpdate={setTopic}
          />
        )
      case JobTopicType.DataPoint:
        return (
          <TopicTelephoneDataPointAction
            input={{ ...commonTopicActionInput, topicReference, topicName: getTopicName(topicReference) }}
            result={topic}
            onUpdate={setTopic}
          />
        )
      case JobTopicType.PowerPoint:
        return (
          <TopicPowerPointAction
            input={{ ...commonTopicActionInput, topicName: getTopicName(topicReference) }}
            result={topic}
            onUpdate={setTopic}
          />
        )
      case JobTopicType.WallMountTV:
        return (
          <TopicWallMountTVAction
            input={{ ...commonTopicActionInput, topicName: getTopicName(topicReference) }}
            result={topic}
            onUpdate={setTopic}
          />
        )
      case JobTopicType.HomeAutomation:
        return (
          <TopicHomeAutomationAction
            input={{ ...commonTopicActionInput, topicName: getTopicName(topicReference) }}
            result={topic}
            onUpdate={setTopic}
          />
        )
      case JobTopicType.Rewire:
        return (
          <TopicRewireAction
            input={{ ...commonTopicActionInput, topicName: getTopicName(topicReference) }}
            result={topic}
            onUpdate={setTopic}
          />
        )
      case JobTopicType.SmokeAlarm:
        return (
          <TopicSmokeAlarmAction
            input={{ ...commonTopicActionInput, topicName: getTopicName(topicReference) }}
            result={topic}
            onUpdate={setTopic}
          />
        )
      case JobTopicType.SecurityMonitoring:
        return (
          <TopicSecurityMonitoringAction
            input={{ ...commonTopicActionInput, topicName: getTopicName(topicReference) }}
            result={topic}
            onUpdate={setTopic}
          />
        )
      case JobTopicType.Solar:
        return (
          <TopicSolarAction
            input={{ ...commonTopicActionInput, topicName: getTopicName(topicReference) }}
            result={topic}
            onUpdate={setTopic}
          />
        )
      case JobTopicType.Switchboard:
        return (
          <TopicSwitchboardAction
            input={{ ...commonTopicActionInput, topicName: getTopicName(topicReference) }}
            result={topic}
            onUpdate={setTopic}
          />
        )
      case JobTopicType.Stereo:
        return (
          <TopicStereoAction
            input={{ ...commonTopicActionInput, topicName: getTopicName(topicReference) }}
            result={topic}
            onUpdate={setTopic}
          />
        )
      case JobTopicType.HVACInspection:
        return (
          <TopicHVACInspectionAction
            input={{ ...commonTopicActionInput, topicName: getTopicName(topicReference) }}
            result={topic}
            onUpdate={setTopic}
          />
        )
      case JobTopicType.HVACQuoteInstall:
        return (
          <TopicHVACQuoteInstallAction
            input={{ ...commonTopicActionInput, topicName: getTopicName(topicReference), serviceArea }}
            result={topic}
            onUpdate={setTopic}
          />
        )
      case JobTopicType.HVACRepair:
        return (
          <TopicHVACRepairAction
            input={{ ...commonTopicActionInput, topicName: getTopicName(topicReference) }}
            result={topic}
            onUpdate={setTopic}
          />
        )
      case JobTopicType.HVACService:
        return (
          <TopicHVACServiceAction
            input={{ ...commonTopicActionInput, topicName: getTopicName(topicReference), isSpecial: false }}
            result={topic}
            onUpdate={setTopic}
          />
        )
      case JobTopicType.HVACMaintenance:
        return (
          <TopicHVACMaintenanceAction
            input={{ ...commonTopicActionInput, topicName: getTopicName(topicReference) }}
            result={topic}
            onUpdate={setTopic}
          />
        )
      case JobTopicType.CappingOffInterconnectionPlumbing:
        return (
          <TopicCappingOffInterconnectionAction
            input={{ ...commonTopicActionInput, topicName: getTopicName(topicReference) }}
            result={topic}
            onUpdate={setTopic}
          />
        )
      case JobTopicType.PrePurchaseInspectionPlumbing:
        return (
          <TopicPlumbingPrePurchaseInspectionAction
            input={{ ...commonTopicActionInput, topicName: getTopicName(topicReference) }}
            result={topic}
            onUpdate={setTopic}
          />
        )
      case JobTopicType.VanitiesAndSinksInstall:
        return (
          <TopicPlumbingSinkInstallAction
            input={{ ...commonTopicActionInput, topicName: getTopicName(topicReference) }}
            result={topic}
            onUpdate={setTopic}
          />
        )
      case JobTopicType.TapInstall:
        return (
          <TopicPlumbingTapInstallAction
            input={{ ...commonTopicActionInput, topicName: getTopicName(topicReference) }}
            result={topic}
            onUpdate={setTopic}
          />
        )
      case JobTopicType.ToiletInstall:
        return (
          <TopicPlumbingToiletInstallAction
            input={{ ...commonTopicActionInput, topicName: getTopicName(topicReference) }}
            result={topic}
            onUpdate={setTopic}
          />
        )
      case JobTopicType.InsinkeratorsOrGarbageDisposalsInstall:
        return (
          <TopicPlumbingGarbageDisposalInstallAction
            input={{ ...commonTopicActionInput, topicName: getTopicName(topicReference) }}
            result={topic}
            onUpdate={setTopic}
          />
        )
      case JobTopicType.BidetInstall:
        return (
          <TopicPlumbingBidetInstallAction
            input={{ ...commonTopicActionInput, topicName: getTopicName(topicReference) }}
            result={topic}
            onUpdate={setTopic}
          />
        )
      case JobTopicType.WaterFilters:
        return (
          <TopicPlumbingWaterFilterInstallAction
            input={{ ...commonTopicActionInput, topicName: getTopicName(topicReference) }}
            result={topic}
            onUpdate={setTopic}
          />
        )
      case JobTopicType.FlexiHoseInstall:
        return (
          <TopicPlumbingFlexiHoseInstallAction
            input={{ ...commonTopicActionInput, topicName: getTopicName(topicReference) }}
            result={topic}
            onUpdate={setTopic}
          />
        )
      case JobTopicType.WaterHammering:
        return (
          <TopicPlumbingWaterHammeringAction
            input={{ ...commonTopicActionInput, topicName: getTopicName(topicReference) }}
            result={topic}
            onUpdate={setTopic}
          />
        )
      case JobTopicType.InsuranceQuotePlumbing:
        return (
          <TopicPlumbingInsuranceQuotesAction
            input={{ ...commonTopicActionInput, topicName: getTopicName(topicReference) }}
            result={topic}
            onUpdate={setTopic}
          />
        )
      case JobTopicType.WaterEfficiencyCertificates:
        return (
          <TopicPlumbingWaterEfficiencyCertificatesAction
            input={{ ...commonTopicActionInput, topicName: getTopicName(topicReference) }}
            result={topic}
            onUpdate={setTopic}
          />
        )
      case JobTopicType.TapRepair:
        return (
          <TopicPlumbingTapRepairAction
            input={{ ...commonTopicActionInput, topicName: getTopicName(topicReference) }}
            result={topic}
            onUpdate={setTopic}
          />
        )
      case JobTopicType.ToiletRepair:
        return (
          <TopicPlumbingToiletRepairAction
            input={{ ...commonTopicActionInput, topicName: getTopicName(topicReference) }}
            result={topic}
            onUpdate={setTopic}
          />
        )
      case JobTopicType.WaterFilterComponentsRepair:
        return (
          <TopicPlumbingWaterFilterComponentsRepairAction
            input={{ ...commonTopicActionInput, topicName: getTopicName(topicReference) }}
            result={topic}
            onUpdate={setTopic}
          />
        )
      case JobTopicType.FlexiHoseRepair:
        return (
          <TopicPlumbingFlexiHoseRepairAction
            input={{ ...commonTopicActionInput, topicName: getTopicName(topicReference) }}
            result={topic}
            onUpdate={setTopic}
          />
        )
      case JobTopicType.BidetRepair:
        return (
          <TopicPlumbingBidetRepairAction
            input={{ ...commonTopicActionInput, topicName: getTopicName(topicReference) }}
            result={topic}
            onUpdate={setTopic}
          />
        )
      case JobTopicType.HotWater:
        return (
          <TopicHotWaterAction
            input={{ ...commonTopicActionInput, topicName: getTopicName(topicReference), hotWaterSpecial: false }}
            result={topic}
            onUpdate={setTopic}
          />
        )
      case JobTopicType.Pump:
        return (
          <TopicPlumbingPumpAction
            input={{ ...commonTopicActionInput, topicName: getTopicName(topicReference) }}
            result={topic}
            onUpdate={setTopic}
          />
        )

      case JobTopicType.WaterLeakDetection:
        return (
          <TopicPlumbingWaterLeakDetectionAction
            input={{ ...commonTopicActionInput, topicName: getTopicName(topicReference) }}
            result={topic}
            onUpdate={setTopic}
          />
        )

      case JobTopicType.WaterLeak:
        return (
          <TopicWaterLeakAction
            input={{ ...commonTopicActionInput, topicName: getTopicName(topicReference) }}
            result={topic}
            onUpdate={setTopic}
          />
        )

      case JobTopicType.WaterPressure:
        return (
          <TopicWaterPressureAction
            input={{ ...commonTopicActionInput, topicName: getTopicName(topicReference) }}
            result={topic}
            onUpdate={setTopic}
          />
        )

      case JobTopicType.Shower:
        return (
          <TopicShowerAction
            input={{ ...commonTopicActionInput, topicName: getTopicName(topicReference) }}
            result={topic}
            onUpdate={setTopic}
          />
        )

      case JobTopicType.BackFlowTesting:
        return (
          <TopicPlumbingBackflowTestingAction
            input={{ ...commonTopicActionInput, topicName: getTopicName(topicReference) }}
            result={topic}
            onUpdate={setTopic}
          />
        )
      case JobTopicType.BurstWaterMains:
        return (
          <TopicPlumbingBurstWaterMainsAction
            input={{ ...commonTopicActionInput, topicName: getTopicName(topicReference) }}
            result={topic}
            onUpdate={setTopic}
          />
        )
      case JobTopicType.Drains:
        return (
          <TopicPlumbingDrainAction
            input={{ ...commonTopicActionInput, topicName: getTopicName(topicReference) }}
            result={topic}
            onUpdate={setTopic}
          />
        )
      case JobTopicType.Submetering:
        return (
          <TopicPlumbingSubmeteringAction
            input={{ ...commonTopicActionInput, topicName: getTopicName(topicReference) }}
            result={topic}
            onUpdate={setTopic}
          />
        )
      case JobTopicType.Pool:
        return (
          <TopicPoolAction
            input={{ ...commonTopicActionInput, topicName: getTopicName(topicReference) }}
            result={topic}
            onUpdate={setTopic}
          />
        )
      case JobTopicType.Gas:
        return (
          <TopicPlumbingGasAction
            input={{ ...commonTopicActionInput, topicName: getTopicName(topicReference) }}
            result={topic}
            onUpdate={setTopic}
          />
        )
      case JobTopicType.Gutters:
        return (
          <TopicGutterAction
            input={{ ...commonTopicActionInput, topicName: getTopicName(topicReference) }}
            result={topic}
            onUpdate={setTopic}
          />
        )
      case JobTopicType.Downpipes:
        return (
          <TopicDownpipeAction
            input={{ ...commonTopicActionInput, topicName: getTopicName(topicReference) }}
            result={topic}
            onUpdate={setTopic}
          />
        )
      case JobTopicType.InspectionPlumbing:
        return (
          <TopicPlumbingInspectionAction
            input={{ ...commonTopicActionInput, topicName: getTopicName(topicReference) }}
            result={topic}
            onUpdate={setTopic}
          />
        )
      case JobTopicType.DrainsRevisit:
        return (
          <TopicDrainRevisitAction
            input={{ ...commonTopicActionInput, topicName: getTopicName(topicReference) }}
            result={topic}
            onUpdate={setTopic}
          />
        )
      case JobTopicType.PlumbingRevisit:
        return (
          <TopicPlumbingRevisitAction
            input={{ ...commonTopicActionInput, topicName: getTopicName(topicReference) }}
            result={topic}
            onUpdate={setTopic}
          />
        )

      case JobTopicType.Microwave:
        return (
          <TopicMicrowaveAction
            input={{ ...commonTopicActionInput, topicName: getTopicName(topicReference) }}
            result={topic}
            onUpdate={setTopic}
          />
        )

      case JobTopicType.Rangehood:
        return (
          <TopicRangehoodAction
            input={{ ...commonTopicActionInput, topicName: getTopicName(topicReference) }}
            result={topic}
            onUpdate={setTopic}
          />
        )

      case JobTopicType.FridgeFreezer:
        return (
          <TopicFridgeFreezerAction
            input={{ ...commonTopicActionInput, topicName: getTopicName(topicReference) }}
            result={topic}
            onUpdate={setTopic}
          />
        )

      case JobTopicType.CoffeeMachine:
        return (
          <TopicCoffeeMachineAction
            input={{ ...commonTopicActionInput, topicName: getTopicName(topicReference) }}
            result={topic}
            onUpdate={setTopic}
          />
        )

      case JobTopicType.WashingMachine:
        return (
          <TopicWashingMachineAction
            input={{ ...commonTopicActionInput, topicName: getTopicName(topicReference) }}
            result={topic}
            onUpdate={setTopic}
          />
        )

      case JobTopicType.Inspection:
        return (
          <TopicInspectionAction
            input={{ ...commonTopicActionInput, topicName: getTopicName(topicReference) }}
            result={topic}
            onUpdate={setTopic}
          />
        )

      case JobTopicType.SecurityInspection:
        return (
          <TopicSecurityInspectionAction
            input={{ ...commonTopicActionInput, topicName: getTopicName(topicReference) }}
            result={topic}
            onUpdate={setTopic}
          />
        )
      case JobTopicType.SmokeAlarmInspection:
        return (
          <TopicSmokeAlarmInspectionAction
            input={{ ...commonTopicActionInput, topicName: getTopicName(topicReference) }}
            result={topic}
            onUpdate={setTopic}
          />
        )
      case JobTopicType.Dishwasher:
        return (
          <TopicDishwasherAction
            input={{ ...commonTopicActionInput, topicName: getTopicName(topicReference) }}
            result={topic}
            onUpdate={setTopic}
          />
        )

      case JobTopicType.Cooktop:
        return (
          <TopicCooktopAction
            input={{ ...commonTopicActionInput, topicName: getTopicName(topicReference) }}
            result={topic}
            onUpdate={setTopic}
          />
        )
      case JobTopicType.Oven:
        return (
          <TopicOvenAction
            input={{ ...commonTopicActionInput, topicName: getTopicName(topicReference) }}
            result={topic}
            onUpdate={setTopic}
          />
        )
      case JobTopicType.Dryer:
        return (
          <TopicDryerAction
            input={{ ...commonTopicActionInput, topicName: getTopicName(topicReference) }}
            result={topic}
            onUpdate={setTopic}
          />
        )
      case JobTopicType.Other:
      default:
        return (
          <TopicOtherAction
            input={{ ...commonTopicActionInput, topicName: getTopicName(topicReference) }}
            result={topic}
            onUpdate={setTopic}
          />
        )
    }
  }
  return actionForTopic(input.jobTopic.reference)
}
