import { JobType, TradeType } from '../../../api/generated-types'
import { RepairOrReplaceEnum, isBrandServiceable } from '../appliance-shared/appliance-model'
import { getOvenOrCooktopRequirementsElectricRepair } from '../appliance-shared/repair-electrical-outcome'
import { YesNoValue } from '../common/common-enums'
import {
  TopicQualification,
  TopicQualificationResult,
  TopicRequirementsResult,
  createOutcomeGenerator,
  createRequirements
} from '../common/common-outcome'
import { GENERAL_ELECTRICAL_SKILL } from '../common/skill-constants'
import { OvenTypeEnum, TopicOvenActionResult } from './action-topic-oven-model'

const GAS_APPLIANCE_LIMITED_SKILL = 'GasAppliancesLimited'
export const OVEN_COOKTOP_SKILL = 'OvensCooktops'

export const getQualification = (result: TopicOvenActionResult | undefined): TopicQualificationResult => {
  const {
    ovenType,
    serviceType,
    willNewOvenFitCutOut,
    isNewOvenOnsite,
    removeOldOven,
    gasType,
    issue,
    happenedBefore,
    outletWorksWithOtherDevice,
    duelFuelConfirmationToProceed
  } = result ?? {}
  const anwseredReplaceQuestions =
    serviceType === RepairOrReplaceEnum.Replace && !!removeOldOven && !!willNewOvenFitCutOut && !!isNewOvenOnsite
  const answeredGasReplaceQuestions = ovenType === OvenTypeEnum.Gas && anwseredReplaceQuestions && !!gasType
  const answeredElectricReplaceQuestions = ovenType === OvenTypeEnum.Electric && anwseredReplaceQuestions
  const answeredDuelFuelReplaceQuestions = ovenType === OvenTypeEnum.DuelFuel && anwseredReplaceQuestions && !!gasType
  const answeredRepairQuestions =
    (ovenType === OvenTypeEnum.Electric || ovenType === OvenTypeEnum.DuelFuel) && isBrandServiceable(result) && !!issue
  const answeredPowerFaultRepairQuestions = answeredRepairQuestions && !!outletWorksWithOtherDevice
  const answeredOtherFaultRepairQuestions = answeredRepairQuestions && !!happenedBefore

  if (
    ovenType === OvenTypeEnum.DuelFuel &&
    serviceType === RepairOrReplaceEnum.Repair &&
    duelFuelConfirmationToProceed === YesNoValue.No
  ) {
    return { qualification: TopicQualification.OutOfScope }
  }

  switch (ovenType) {
    case OvenTypeEnum.Gas:
      if (serviceType === RepairOrReplaceEnum.Repair) {
        return { qualification: TopicQualification.OutOfScope }
      } else {
        if (answeredGasReplaceQuestions) {
          return { qualification: TopicQualification.InScope }
        } else {
          return { qualification: TopicQualification.Unknown }
        }
      }
    case OvenTypeEnum.DuelFuel:
    case OvenTypeEnum.Electric:
      if (
        answeredElectricReplaceQuestions ||
        answeredDuelFuelReplaceQuestions ||
        (answeredRepairQuestions && (answeredOtherFaultRepairQuestions || answeredPowerFaultRepairQuestions))
      ) {
        return { qualification: TopicQualification.InScope }
      } else {
        return { qualification: TopicQualification.Unknown }
      }

    default:
      return { qualification: TopicQualification.Unknown }
  }
}

const getRequirements = (result: TopicOvenActionResult | undefined): TopicRequirementsResult => {
  const { ovenType, serviceType } = result ?? {}
  if (!ovenType || !serviceType) return {}
  switch (ovenType) {
    case OvenTypeEnum.Electric:
      if (serviceType === RepairOrReplaceEnum.Repair) {
        return getOvenOrCooktopRequirementsElectricRepair(result!)
      } else {
        return { requirements: createRequirements([GENERAL_ELECTRICAL_SKILL]), trade: TradeType.Electrical }
      }
    case OvenTypeEnum.DuelFuel:
    default:
      return {
        requirements: createRequirements([GAS_APPLIANCE_LIMITED_SKILL, OVEN_COOKTOP_SKILL]),
        trade: TradeType.Appliances
      }
  }
}

export const outcomeGenerator = createOutcomeGenerator<TopicOvenActionResult>(
  { qualification: TopicQualification.Unknown, trade: TradeType.Plumbing, type: JobType.Service, category: 'General' },
  getQualification,
  getRequirements
)
