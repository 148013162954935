import { useMemo } from 'react'
import {
  GetCustomerJobsDocument,
  GetCustomerJobsQuery,
  JobSearchBetaResultFragment,
  SearchJobsBetaInput
} from '../api/generated-types'
import ListContainer, { ListContainerData } from '../common/list-container'
import { useLocalStorage } from '../common/use-local-storage'
import { JobListColumns } from '../jobs/job-list-columns'

interface CustomerJobsContainerProps {
  customerId: string
}

export const CustomerJobsContainer = (props: CustomerJobsContainerProps) => {
  const { customerId } = props
  const [sandbox] = useLocalStorage('sandbox', false)

  const extractData = (response: GetCustomerJobsQuery): ListContainerData<JobSearchBetaResultFragment> => {
    return {
      rows: response?.searchJobsBeta?.results,
      total: response?.searchJobsBeta?.count
    }
  }

  const columns = JobListColumns({ showContextMenu: true })

  const listInput: SearchJobsBetaInput = useMemo(() => {
    return {
      filter: {
        customers: [customerId],
        sandbox
      }
    }
  }, [])

  return (
    <ListContainer
      input={listInput}
      query={GetCustomerJobsDocument}
      modelName="jobs"
      columns={columns}
      paginationOnInput={true}
      extractData={extractData}
    />
  )
}
