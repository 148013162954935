import { EuiButton, EuiSpacer } from '@elastic/eui'
import { JobType, TradeType } from '../../../api/generated-types'
import { makeHumanReadable } from '../../../common/utils'
import { QuestionDefinitions, getNotesGenerator } from '../../helpers/notes-generator'
import { getResultUpdater } from '../../helpers/results-updater'
import { FreeTextQuestionField } from '../../question-fields/free-text-question-field'
import { SingleSelectField } from '../../question-fields/single-select-question-field'
import { WorkflowActionProps } from '../../workflow-model'
import { TopicActionInput, TopicActionResult } from '../action-topic-model'
import { TopicWorkflowAction } from '../action-topic-view'

export enum SubjectOfCapping {
  GasLine = 'GasLine',
  SewerLine = 'SewerLine',
  WaterLine = 'WaterLine'
}

export interface TopicCappingOffInterconnectionActionResult extends TopicActionResult {
  subjectOfCapping?: SubjectOfCapping
  reasonForCapping?: string
  willItBeUncappedAtLaterDate?: string
  locationInTheProperty?: string
}

const questionDefinitions: QuestionDefinitions<TopicCappingOffInterconnectionActionResult> = {
  subjectOfCapping: {
    question: 'What are we capping off?',
    makeAnswerHumanReadable: makeHumanReadable
  },
  reasonForCapping: {
    question: 'What is the reason for capping?'
  },
  willItBeUncappedAtLaterDate: {
    question: 'Does it need to be uncapped at a later date?'
  },
  locationInTheProperty: {
    question: 'Where in the property?'
  }
}
const notesGenerator = getNotesGenerator(questionDefinitions)

export const TopicCappingOffInterconnectionAction = (
  props: WorkflowActionProps<TopicActionInput, TopicCappingOffInterconnectionActionResult>
) => {
  const { result, input, onUpdate } = props
  const { subjectOfCapping, reasonForCapping, willItBeUncappedAtLaterDate, locationInTheProperty } = result ?? {}

  const onNext = () =>
    onUpdate({
      ...result,
      actionCompleted: true,
      trade: TradeType.Plumbing,
      category: 'Interconnection',
      type: JobType.Service,

      workRequiredNotes: generatedNotes,
      requirements: {
        attributes: [{ attributeId: 'GeneralPlumbing' }]
      }
    })

  const generatedNotes = notesGenerator.generateNotes(result ?? {})
  const updateResult = getResultUpdater(result ?? {}, onUpdate).updateResult

  const canComplete =
    !!subjectOfCapping && !!reasonForCapping && !!willItBeUncappedAtLaterDate && !!locationInTheProperty

  return (
    <TopicWorkflowAction input={input} onUpdate={onUpdate} result={result}>
      <SingleSelectField
        question={notesGenerator.getQuestion('subjectOfCapping')}
        options={[
          {
            id: SubjectOfCapping.GasLine,
            label: 'Gas line'
          },
          {
            id: SubjectOfCapping.SewerLine,
            label: 'Sewer line'
          },
          {
            id: SubjectOfCapping.WaterLine,
            label: 'Water line'
          }
        ]}
        answer={subjectOfCapping}
        changeAnswer={(subjectOfCapping) => updateResult({ subjectOfCapping })}
      />
      <FreeTextQuestionField
        question={notesGenerator.getQuestion('reasonForCapping')}
        answer={reasonForCapping}
        rows={3}
        changeAnswer={(reasonForCapping) => updateResult({ reasonForCapping })}
      />
      <FreeTextQuestionField
        question={notesGenerator.getQuestion('willItBeUncappedAtLaterDate')}
        answer={willItBeUncappedAtLaterDate}
        changeAnswer={(willItBeUncappedAtLaterDate) => updateResult({ willItBeUncappedAtLaterDate })}
      />
      <FreeTextQuestionField
        question={notesGenerator.getQuestion('locationInTheProperty')}
        answer={locationInTheProperty}
        changeAnswer={(locationInTheProperty) => updateResult({ locationInTheProperty })}
      />
      <EuiSpacer />
      <EuiButton disabled={!canComplete} onClick={() => onNext()}>
        Next
      </EuiButton>
    </TopicWorkflowAction>
  )
}
