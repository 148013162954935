import { useLazyQuery } from '@apollo/client'
import { EuiFieldSearch } from '@elastic/eui'
import { Address, AddressEntryMode } from '@fallonsolutions/address'
import { dateConfig } from '@fallonsolutions/date'
import { createSuburbKey } from '@fallonsolutions/suburb'
import { compact } from 'lodash-es'
import { useCallback, useContext, useEffect, useState } from 'react'
import { usePlacesWidget } from 'react-google-autocomplete'
import { GetSuburbByKeyDocument } from '../api/generated-types'
import { AppContext } from '../app/app-context'
import '../static/css/autocomplete-address.css'

const countryRestriction = 'AU'
const types = ['address']
const defaultCountryCode = 'au'

export interface AutocompleteAddressFieldV2Props {
  initialQuery: string
  showValidationErrors: boolean
  isAddressValid: boolean
  onSelectAddress: (address: Address | undefined) => void
}

export const AutocompleteAddressFieldV2 = ({
  initialQuery,
  showValidationErrors,
  isAddressValid,
  onSelectAddress
}: AutocompleteAddressFieldV2Props) => {
  const appContext = useContext(AppContext)

  const [hasChanged, setHasChanged] = useState(false)
  const [selectedAddress, setSelectedAddress] = useState<Address | undefined>(undefined)

  const { ref } = usePlacesWidget<HTMLInputElement | null>({
    apiKey: appContext.googleApiKey,
    options: {
      types,
      componentRestrictions: {
        country: countryRestriction
      }
    },
    onPlaceSelected: (place) => {
      console.log('place selected', place)
      setHasChanged(true)
      const partialAddress = placeToAddress(place)
      const suburbKey = createSuburbKey(
        partialAddress?.suburb ?? '',
        partialAddress?.postcode ?? '',
        defaultCountryCode
      )
      if (!partialAddress) {
        console.log('no address selected')
        setSelectedAddress(undefined)
        return
      } else {
        fetchSuburbKey(partialAddress, suburbKey)
      }
    }
  })

  const fetchSuburbKey = useCallback(async (partialAddress: Address, suburbKey: string) => {
    const { data } = await getSuburbByKey({ variables: { input: { key: suburbKey } } })
    const suburb = data?.getSuburbByKey?.suburb
    if (!suburb) {
      console.error('suburb not found!')
      setSelectedAddress(undefined)
      return
    }
    const address: Address = {
      ...partialAddress,
      suburbId: suburb.id,
      entryMode: AddressEntryMode.Autocomplete
    }
    setSelectedAddress(address)
  }, [])

  const [getSuburbByKey, { loading }] = useLazyQuery(GetSuburbByKeyDocument)

  const onChangeQuery = (query: string) => {
    setHasChanged(true)
    if (query.trim().length <= 0) {
      setSelectedAddress(undefined)
    }
  }

  useEffect(() => {
    if (selectedAddress || hasChanged) {
      onSelectAddress(selectedAddress)
    }
  }, [selectedAddress, hasChanged])

  // Set initial query on load
  useEffect(() => {
    if (ref.current) {
      console.log('setting initial query for autocomplete address field', initialQuery)
      ref.current.value = initialQuery
    }
  }, [])

  return (
    <EuiFieldSearch
      placeholder="Enter address..."
      inputRef={(newRef) => ((ref as any).current = newRef)}
      style={{ minWidth: '400px' }}
      isLoading={loading}
      onChange={(e) => onChangeQuery(e.target.value)}
      isClearable={!loading}
      isInvalid={showValidationErrors && !isAddressValid}
      autoComplete="off"
      spellCheck={false}
      autoCorrect="off"
      autoCapitalize="off"
    />
  )
}

const subpremiseComponents = ['subpremise']
const streetComponents = ['street_number', 'route']
const suburbComponents = ['locality']
const postcodeComponents = ['postal_code']
const stateComponents = ['administrative_area_level_1']
const countryComponents = ['country']

const placeToAddress = (place: google.maps.places.PlaceResult): Address | undefined => {
  const extractComponents = (result: google.maps.places.PlaceResult, types: string[], useShortName = false) => {
    if (!result.address_components) {
      return []
    }
    return compact(
      result.address_components
        .filter((component) => types.some((r) => component.types.includes(r)))
        .map((component) => (useShortName ? component.short_name : component.long_name))
    )
  }
  // Note that c.long_name was initially used but found it expanded St Lucia to Saint Lucia which doesn't match
  // AusPost file

  const streetSubpremise = extractComponents(place, subpremiseComponents).join(' ')
  const streetNumberAndName = extractComponents(place, streetComponents).join(' ')

  const getStreetAddress = (): string => compact([streetSubpremise, streetNumberAndName]).join('/')

  const address: Address = {
    street: getStreetAddress(),
    suburb: extractComponents(place, suburbComponents, true).join(' '),
    postcode: extractComponents(place, postcodeComponents).join(' '),
    state: extractComponents(place, stateComponents, true).join(' '),
    country: extractComponents(place, countryComponents, true).join(' '),
    entryMode: AddressEntryMode.Autocomplete,
    timezone: dateConfig.defaultTimezone
  }

  return address
}
