import { useQuery } from '@apollo/client'
import { EuiFlexGroup, EuiFlexItem, EuiSpacer } from '@elastic/eui'
import { isAfterHours } from '@fallonsolutions/job'
import { CustomerType, GetCustomerDocument, JobFragment } from '../api/generated-types'
import { useAuthenticated } from '../auth/authenticated-context'
import { useCreateInitialEnquiryService } from '../common/create-initial-enquiry'
import { createNewJobUrl } from '../common/create-new-job-url'
import { openUrlInNewTab } from '../common/open-in-new-tab'
import TradeTag from '../common/trade-tag'
import VerticalDivider from '../common/vertical-divider'
import { JobContextMenu } from './job-context-menu'
import { JobMembershipStatusEdit } from './job-membership-status-edit'
import { JobRequirementLabel } from './job-requirement-label'
import { JobStatusEdit } from './job-status-edit'
import { JobTimeAllowedEdit } from './job-time-allowed-edit'

interface JobSummaryHeaderProps {
  job: JobFragment
}

export const JobSummaryHeader = ({ job }: JobSummaryHeaderProps) => {
  const { userFragment } = useAuthenticated()
  const hasDeveloperFeatures = userFragment.permissions?.developerFeatures === true

  const { data: customerData } = useQuery(GetCustomerDocument, {
    variables: {
      id: job.customer?.id ?? 'missing'
    },
    skip: !job.customer?.id
  })

  const customer = customerData?.getCustomer

  const [createInitialEnquiry] = useCreateInitialEnquiryService()

  const handleCreateNewEnquiry = async (link: boolean) => {
    if (!customer) return

    const enquiryId = await createInitialEnquiry({ customer })

    // url params to send through to enquiry wrapper

    const url = createNewJobUrl({
      enquiryId,
      contactId: job.contact?.id,
      customerId: customer.id,
      jobId: link ? job.id : undefined
    })

    openUrlInNewTab(url)
  }

  return (
    <>
      <EuiFlexGroup alignItems="center" gutterSize="l">
        <EuiFlexItem grow={false}>
          <div className="small-label truncate">Status</div>
          <JobStatusEdit job={job} />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <VerticalDivider height="48px" />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <div className="small-label">Trade</div>
          <span className="truncate">
            <TradeTag trade={job.tradeType ?? 'none'} />
            <span style={{ marginLeft: '3px' }}>{job.tradeType}</span>
          </span>
          {hasDeveloperFeatures && (
            <div>
              <EuiSpacer size="m" />
              <div className="small-label">Trade</div>
              <span className="truncate">
                <TradeTag trade={job.tradeObject?.name ?? 'none'} />
                <span style={{ marginLeft: '3px' }}>{job.tradeObject?.name ?? '-'}</span>
              </span>
            </div>
          )}
        </EuiFlexItem>
        {hasDeveloperFeatures && (
          <EuiFlexItem grow={false}>
            <div className="small-label truncate">Topic</div>
            <span className="truncate">{job.topic}</span>
            {hasDeveloperFeatures && (
              <div>
                <EuiSpacer size="m" />
                <div className="small-label">Topic</div>
                <span className="truncate">{job.topicObject?.name ?? '-'}</span>
              </div>
            )}
          </EuiFlexItem>
        )}
        <EuiFlexItem grow={false}>
          <div className="small-label">Category</div>
          <span>{job.category}</span>
          {hasDeveloperFeatures && (
            <div>
              <EuiSpacer size="m" />
              <div className="small-label">Subtopic</div>
              <span className="truncate">{job.subtopic?.name ?? '-'}</span>
            </div>
          )}
        </EuiFlexItem>
        <EuiFlexItem grow={false} style={{ minWidth: '72px' }}>
          <div className="small-label truncate">Job type</div>
          <span>{job.type}</span>
          {hasDeveloperFeatures && (
            <div>
              <EuiSpacer size="m" />
              <div className="small-label">Service type</div>
              <span className="truncate">{job.serviceType?.name ?? '-'}</span>
            </div>
          )}
        </EuiFlexItem>
        {hasDeveloperFeatures && (
          <EuiFlexItem grow={false}>
            <div className="small-label truncate">Offer</div>
            <span className="truncate">{job.offer?.name ?? '-'}</span>
            {hasDeveloperFeatures && (
              <div>
                <EuiSpacer size="m" />
                <div className="small-label">Offer</div>
                <span className="truncate">{job.offer?.name ?? '-'}</span>
              </div>
            )}
          </EuiFlexItem>
        )}
        <EuiFlexItem grow={false}>
          <div className="small-label truncate">Customer type</div>
          <span>{job.customerType}</span>
          {hasDeveloperFeatures && (
            <div>
              <EuiSpacer size="m" />
              <div className="small-label truncate">Customer type</div>
              <span>{job.customerType}</span>
            </div>
          )}
        </EuiFlexItem>
        {isAfterHours(job.afterHours) && (
          <EuiFlexItem grow={false}>
            <div className="small-label">after hours</div>
            <div>{job.afterHours}</div>
          </EuiFlexItem>
        )}
        <EuiFlexItem grow={true} />
        {job.customerType === CustomerType.Domestic && (
          <EuiFlexItem grow={false}>
            <div className="small-label truncate">Membership level</div>
            <JobMembershipStatusEdit job={job} />
          </EuiFlexItem>
        )}
        <EuiFlexItem grow={false}>
          <div className="small-label truncate">Time allowed</div>
          <JobTimeAllowedEdit job={job} />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <div className="small-label">Inspection</div>
          {job.requirements?.inspection && <JobRequirementLabel requirement={job.requirements?.inspection} />}
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <div className="small-label truncate">Primary option</div>
          {job.requirements?.primaryOption && <JobRequirementLabel requirement={job.requirements?.primaryOption} />}
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <div className="small-label truncate">Futile approval</div>
          {job.requirements?.futileApproval && <JobRequirementLabel requirement={job.requirements?.futileApproval} />}
        </EuiFlexItem>
        {/* <EuiFlexItem grow={false}>
            <div className="small-label">Travel fee</div>
            {job.requirements?.travelFee && (
              <JobRequirementLabel requirement={job.requirements?.travelFee} />
            )}
          </EuiFlexItem>
         */}
        <EuiFlexItem grow={false}>
          <VerticalDivider height="48px" />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <JobContextMenu job={job} createNewJob={handleCreateNewEnquiry} />
        </EuiFlexItem>
      </EuiFlexGroup>
    </>
  )
}
