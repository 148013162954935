import { EuiIcon, EuiKeyPadMenu, EuiKeyPadMenuItem, EuiSpacer, IconType } from '@elastic/eui'
import { PlatformIcon } from '@fallonsolutions/types'
import { Callout } from '../../common/callout'
import { decameliseIfNotNil } from '../../common/utils'
import { PlatformIconView } from '../../icon/platform-icon'
import '../../static/css/keypadmenu.css'
import { QuestionFieldProps } from './question-field-props'

interface ConvertibleToString {
  toString: () => string
}

export interface AnswerOption<T> {
  id: T
  label?: string // defaults to decamelised id
  icon?: IconType
  platformIcon?: PlatformIcon
}

export interface SingleSelectQuestionFieldProps<TAnswer> extends QuestionFieldProps<TAnswer> {
  options: AnswerOption<TAnswer>[]
}

export const SingleSelectField = <TAnswer extends ConvertibleToString>(
  props: SingleSelectQuestionFieldProps<TAnswer>
) => {
  const { question, questionType, hint, answer, changeAnswer, options } = props
  const showQuestion = props.showQuestion ?? true

  return (
    <>
      {showQuestion && (
        <Callout type={questionType ?? 'script'} data-test-id="workflow-question">
          {question}
        </Callout>
      )}
      {hint && (
        <Callout type="note" data-test-id="workflow-question-hint">
          {hint}
        </Callout>
      )}
      <EuiKeyPadMenu className="fs-keypadmenu" data-test-id="workflow-answer-container">
        {options.map((option) => (
          <EuiKeyPadMenuItem
            key={option.id.toString()}
            id={option.id.toString()}
            label={option.label ?? decameliseIfNotNil(option.id.toString())}
            isSelected={option.id === answer}
            onClick={() => changeAnswer(option.id)}
            className={`fs-keypadmenu-item ${option.id === answer ? 'fs-keypadmenu-item-selected' : ''}`}
          >
            {option.icon ? <EuiIcon type={option.icon} size="xl" /> : <></>}
            {option.platformIcon ? <PlatformIconView icon={option.platformIcon} size="xl" /> : <></>}
          </EuiKeyPadMenuItem>
        ))}
      </EuiKeyPadMenu>
      <EuiSpacer />
    </>
  )
}
