import { EuiButton, EuiSpacer } from '@elastic/eui'
import { EnquiryAbortReason, JobType, PriorityType, TradeType } from '../../../api/generated-types'
import { Callout } from '../../../common/callout'
import { makeHumanReadable } from '../../../common/utils'
import { CustomerSuppliedTopicActionResult } from '../../action-topic-customer-supplied'
import { CustomerSuppliedCompliant, IsItemAustralianCompliant } from '../../helpers/item-installer-questions'
import { QuestionDefinitions, getNotesGenerator } from '../../helpers/notes-generator'
import { OutOfScope } from '../../helpers/out-of-topic'
import { getResultUpdater } from '../../helpers/results-updater'
import { useWorkflow } from '../../helpers/workflow-provider'
import { SingleSelectField } from '../../question-fields/single-select-question-field'
import { WorkflowActionProps } from '../../workflow-model'
import { ItemSupplier, TopicActionInput } from '../action-topic-model'
import { TopicWorkflowAction } from '../action-topic-view'
import { YesNoValue } from '../common/common-enums'
import { reset } from '../common/common-utils'

export enum NewOrSecondHand {
  New = 'New',
  SecondHand = 'SecondHand'
}

export enum FlexHoseInstallationType {
  UnderSink = 'UnderSink',
  FlexiHoseLeadingToToiletCistern = 'FlexiHoseLeadingToToiletCistern',
  Other = 'Other'
}

export interface TopicPlumbingFlexiHoseInstallActionResult extends CustomerSuppliedTopicActionResult {
  itemSupplier?: ItemSupplier
  wasFlexiHoseNewOrSecondHandWhenPurchased?: NewOrSecondHand
  hasTheFlexiHoseBurst?: YesNoValue
  installationType?: FlexHoseInstallationType
}

const questionDefinitions: QuestionDefinitions<TopicPlumbingFlexiHoseInstallActionResult> = {
  itemSupplier: {
    question: 'Is this a Fallon supplied or customer supplied flexi hose?',
    makeAnswerHumanReadable: makeHumanReadable
  },
  wasFlexiHoseNewOrSecondHandWhenPurchased: {
    question: 'Was the flexi hose new or second-hand when purchased?',
    makeAnswerHumanReadable: makeHumanReadable
  },
  hasTheFlexiHoseBurst: {
    question: 'Has the flexi hose burst?',
    makeAnswerHumanReadable: makeHumanReadable
  },
  installationType: {
    question: 'Is this for under sink flexi hoses or for the flexi hose leading to toilet cistern?',
    makeAnswerHumanReadable: makeHumanReadable
  }
}
const notesGenerator = getNotesGenerator(questionDefinitions)

export const TopicPlumbingFlexiHoseInstallAction = (
  props: WorkflowActionProps<TopicActionInput, TopicPlumbingFlexiHoseInstallActionResult>
) => {
  const workflowContext = useWorkflow()
  const { result, input, onUpdate } = props
  const {
    itemSupplier,
    wasFlexiHoseNewOrSecondHandWhenPurchased,
    hasTheFlexiHoseBurst,
    installationType,
    australianCompliant,
    jobDescription,
    customerType
  } = result ?? {}
  const { jobIsCompliant, jobIsNotCompliant } = CustomerSuppliedCompliant({ australianCompliant })
  const onNext = () =>
    onUpdate({
      ...result,
      actionCompleted: true,
      trade: TradeType.Plumbing,
      category: 'FlexiHose',
      type: JobType.Service,

      workRequiredNotes: generatedNotes,
      requirements: {
        attributes: [{ attributeId: 'GeneralPlumbing' }],
        priority:
          hasTheFlexiHoseBurst === YesNoValue.Yes
            ? {
                required: PriorityType.P1
              }
            : undefined
      }
    })

  const generatedNotes = notesGenerator.generateNotes(result ?? {})
  const updateResult = getResultUpdater(result ?? {}, onUpdate).updateResult

  const canContinue =
    itemSupplier === ItemSupplier.Fallon ||
    (itemSupplier === ItemSupplier.Customer &&
      wasFlexiHoseNewOrSecondHandWhenPurchased === NewOrSecondHand.New &&
      jobIsCompliant)

  const canComplete = canContinue && !!hasTheFlexiHoseBurst && !!installationType

  return (
    <TopicWorkflowAction input={input} onUpdate={onUpdate} result={result}>
      <SingleSelectField
        question={notesGenerator.getQuestion('itemSupplier')}
        options={[
          {
            id: ItemSupplier.Fallon,
            label: 'Fallon',
            icon: 'check'
          },
          {
            id: ItemSupplier.Customer,
            label: 'Customer',
            icon: 'user'
          }
        ]}
        answer={itemSupplier}
        changeAnswer={(itemSupplier) =>
          updateResult({
            ...reset(result ?? {}),
            customerType,
            itemSupplier,
            jobDescription
          })
        }
      />
      {itemSupplier === ItemSupplier.Customer && (
        <>
          <SingleSelectField
            question={notesGenerator.getQuestion('wasFlexiHoseNewOrSecondHandWhenPurchased')}
            options={[
              {
                id: NewOrSecondHand.New,
                label: 'New'
              },
              {
                id: NewOrSecondHand.SecondHand,
                label: 'Second-hand'
              }
            ]}
            answer={wasFlexiHoseNewOrSecondHandWhenPurchased}
            changeAnswer={(wasFlexiHoseNewOrSecondHandWhenPurchased) =>
              updateResult({ wasFlexiHoseNewOrSecondHandWhenPurchased })
            }
          />
          {wasFlexiHoseNewOrSecondHandWhenPurchased === NewOrSecondHand.SecondHand ? (
            <>
              <Callout type="script">Unfortunately Fallon’s don’t install second-hand flexi hoses.</Callout>
              <EuiButton
                color="warning"
                onClick={() =>
                  workflowContext.abort({
                    abortReason: EnquiryAbortReason.CustomerNeedsAreOutOfService,
                    notes: 'Customer wanted to install a second-hand flexi hose'
                  })
                }
              >
                Offer a voucher and end call
              </EuiButton>
            </>
          ) : (
            <>
              <IsItemAustralianCompliant
                updateResult={updateResult}
                itemSupplier={itemSupplier}
                suppliedAnswer={australianCompliant}
              />
            </>
          )}
        </>
      )}

      {jobIsNotCompliant ? (
        <>
          <OutOfScope issue={'Flexi Hose Install'} isOutOfScope={true} />
        </>
      ) : (
        <>
          {canContinue && (
            <>
              <SingleSelectField
                question={notesGenerator.getQuestion('hasTheFlexiHoseBurst')}
                options={[
                  {
                    id: YesNoValue.Yes,
                    label: 'Yes'
                  },
                  {
                    id: YesNoValue.No,
                    label: 'No'
                  }
                ]}
                answer={hasTheFlexiHoseBurst}
                changeAnswer={(hasTheFlexiHoseBurst) => updateResult({ hasTheFlexiHoseBurst })}
              />
              <SingleSelectField
                question={notesGenerator.getQuestion('installationType')}
                options={[
                  {
                    id: FlexHoseInstallationType.UnderSink,
                    label: 'Under sink'
                  },
                  {
                    id: FlexHoseInstallationType.FlexiHoseLeadingToToiletCistern,
                    label: 'Flexi hose leading to toilet cistern'
                  },
                  {
                    id: FlexHoseInstallationType.Other,
                    label: 'Other'
                  }
                ]}
                answer={installationType}
                changeAnswer={(installationType) => updateResult({ installationType })}
              />
            </>
          )}
        </>
      )}

      <EuiSpacer />
      <EuiButton disabled={!canComplete} onClick={() => onNext()}>
        Next
      </EuiButton>
    </TopicWorkflowAction>
  )
}
