import { QuestionDefinitions, getNotesGenerator } from '../../helpers/notes-generator'
import { ServiceType } from '../common/common-enums'
import { ApplianceRepairTopicActionResult } from '../appliance-shared/appliance-model'
import { YesNoValue } from '../common/common-enums'

export interface TopicCoffeeMachineActionResult extends ApplianceRepairTopicActionResult {
  serviceType?: ServiceType
  applianceIssue?: CoffeeMachineIssueEnum
  outletWorksWithOtherDevice?: YesNoValue
  applianceBrand?: string
  installationLocation?: string
  brandNew?: YesNoValue
}

const questionDefinitions: QuestionDefinitions<TopicCoffeeMachineActionResult> = {
  serviceType: {
    question: 'Is this for installation or repair?',
    statement: 'Install or repair:'
  },
  mayVoidWarrantyConfirmationToProceed: { question: 'Are you happy to proceed?' },
  applianceBrand: { question: 'What brand is the machine?', statement: 'brand' },
  installationLocation: { question: 'Where does it need to be installed?', statement: 'Install location:' },
  brandNew: { question: 'Is the machine brand new?', statement: 'Is new:' },
  applianceIssue: {
    question:
      'To help send out the right technician, can I please ask which one following fault types best describes your issue?',
    statement: 'Issue:'
  },
  outletWorksWithOtherDevice: {
    question: 'Does the power point work with other devices?',
    statement: 'Outlet works with other device:'
  }
}

export enum ExistingOrNewEnum {
  Existing = 'Existing',
  New = 'New'
}

export enum DomesticOrCommercialEnum {
  Domestic = 'Domestic',
  Commercial = 'Commercial'
}

export const existingOrNewOptions = [
  { id: ExistingOrNewEnum.Existing, label: 'Existing' },
  { id: ExistingOrNewEnum.New, label: 'New' }
]

export enum CoffeeMachineIssueEnum {
  WaterLine = 'WaterLine',
  Drain = 'Drain',
  PowerSupply = 'PowerSupply',
  OtherFault = 'Other fault (mechanical)'
}

export const coffeeMachineIssueOptions = [
  { id: CoffeeMachineIssueEnum.WaterLine, label: 'Water line' },
  { id: CoffeeMachineIssueEnum.Drain, label: 'Drain' },
  { id: CoffeeMachineIssueEnum.PowerSupply, label: 'Power Supply' },
  { id: CoffeeMachineIssueEnum.OtherFault, label: 'Other mechanical fault' }
]
export const coffeeMachineNotesGenerator = getNotesGenerator(questionDefinitions)
