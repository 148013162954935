import { EuiButton, EuiSpacer } from '@elastic/eui'
import { EnquiryAbortReason, JobType, TradeType } from '../../../api/generated-types'
import { Callout } from '../../../common/callout'
import { decameliseIfNotNil } from '../../../common/utils'
import { QuestionDefinitions, getNotesGenerator } from '../../helpers/notes-generator'
import { getResultUpdater } from '../../helpers/results-updater'
import { useWorkflow } from '../../helpers/workflow-provider'
import { FreeTextQuestionField } from '../../question-fields/free-text-question-field'
import { SingleSelectField } from '../../question-fields/single-select-question-field'
import { WorkflowActionProps } from '../../workflow-model'
import { TopicActionInput, TopicActionResult } from '../action-topic-model'
import { TopicWorkflowAction } from '../action-topic-view'
import { YesNoValue } from '../common/common-enums'

export interface TopicStereoActionResult extends TopicActionResult {
  customerSupplyingEquipment?: YesNoValue

  // installation
  equipmentType?: string
  installationPlacement?: string
}

const questionDefinitions: QuestionDefinitions<TopicStereoActionResult> = {
  customerSupplyingEquipment: {
    question: 'Just to confirm, are you supplying the hardware?',
    statement: 'Customer supplies the hardware:',
    makeAnswerHumanReadable: decameliseIfNotNil,
    excludeFromNotes: true
  },
  equipmentType: {
    question: 'What equipment do you need installed?',
    statement: 'Equipment type:'
  },
  installationPlacement: {
    question: 'Where do you want it installed?',
    statement: 'Placement of installation:'
  }
}
const notesGenerator = getNotesGenerator(questionDefinitions)

export const TopicStereoAction = (props: WorkflowActionProps<TopicActionInput, TopicStereoActionResult>) => {
  const workflowContext = useWorkflow()
  const { result, input, onUpdate } = props
  const { customerSupplyingEquipment, equipmentType, installationPlacement } = result ?? {}

  const onNext = () =>
    onUpdate({
      ...result,
      actionCompleted: true,
      trade: TradeType.Data,
      category: 'Stereo',
      type: JobType.Service,

      workRequiredNotes: generatedNotes,
      requirements: {
        attributes: [{ attributeId: 'TVAndAntenna' }]
      }
    })

  const generatedNotes = notesGenerator.generateNotes(result ?? {})
  const updateResult = getResultUpdater(result ?? {}, onUpdate).updateResult

  const canComplete = customerSupplyingEquipment === YesNoValue.Yes && !!equipmentType && !!installationPlacement

  return (
    <TopicWorkflowAction input={input} onUpdate={onUpdate} result={result}>
      <SingleSelectField
        question={notesGenerator.getQuestion('customerSupplyingEquipment')}
        options={[
          {
            id: YesNoValue.Yes,
            label: 'Yes',
            icon: 'check'
          },
          {
            id: YesNoValue.No,
            label: 'No',
            icon: 'cross'
          }
        ]}
        answer={customerSupplyingEquipment}
        changeAnswer={(customerSupplyingEquipment) => updateResult({ customerSupplyingEquipment })}
      />

      {customerSupplyingEquipment === YesNoValue.No && (
        <>
          <Callout type="script">
            Unfortunately, we currently don’t supply any of this hardware. We are more then happy to install when you
            have purchased your own.
          </Callout>
          <EuiSpacer />
          <EuiButton
            color="warning"
            onClick={() =>
              workflowContext.abort({
                abortReason: EnquiryAbortReason.CustomerNeedsAreOutOfService,
                notes: 'Customer wanted us to provide the equipment.'
              })
            }
          >
            End call
          </EuiButton>
        </>
      )}
      {customerSupplyingEquipment === YesNoValue.Yes && (
        <>
          <FreeTextQuestionField
            question={notesGenerator.getQuestion('equipmentType')}
            answer={equipmentType}
            changeAnswer={(equipmentType) => updateResult({ equipmentType })}
          />
          <FreeTextQuestionField
            question={notesGenerator.getQuestion('installationPlacement')}
            answer={installationPlacement}
            changeAnswer={(installationPlacement) => updateResult({ installationPlacement })}
          />

          {/* TODO: This looks like a very common question asked during almost every job booking
          <Callout type="note" title="Is it double or single story house?" /> */}

          <Callout type="note" title="Book data technician" />
        </>
      )}

      {canComplete && (
        <>
          <EuiSpacer />
          <EuiButton onClick={() => onNext()}>Next</EuiButton>
        </>
      )}
    </TopicWorkflowAction>
  )
}
