import { EuiButton, EuiSpacer } from '@elastic/eui'
import { JobType, TradeType } from '../../../api/generated-types'
import { QuestionDefinitions, getNotesGenerator } from '../../helpers/notes-generator'
import { getResultUpdater } from '../../helpers/results-updater'
import { FreeTextQuestionField } from '../../question-fields/free-text-question-field'
import { WorkflowActionProps } from '../../workflow-model'
import { TopicActionInput, TopicActionResult } from '../action-topic-model'
import { TopicWorkflowAction } from '../action-topic-view'

export interface TopicPlumbingFlexiHoseRepairActionResult extends TopicActionResult {
  locationInTheHouse?: string
  areTheyLeaking?: string
  anyRustSpots?: string
}

const questionDefinitions: QuestionDefinitions<TopicPlumbingFlexiHoseRepairActionResult> = {
  locationInTheHouse: {
    question: 'Where are they in the house?'
  },
  areTheyLeaking: {
    question: 'Are they leaking?'
  },
  anyRustSpots: {
    question: 'Have you noticed any rust spots with them?'
  }
}
const notesGenerator = getNotesGenerator(questionDefinitions)

export const TopicPlumbingFlexiHoseRepairAction = (
  props: WorkflowActionProps<TopicActionInput, TopicPlumbingFlexiHoseRepairActionResult>
) => {
  const { result, input, onUpdate } = props
  const { locationInTheHouse, areTheyLeaking, anyRustSpots } = result ?? {}

  const onNext = () =>
    onUpdate({
      ...result,
      actionCompleted: true,
      trade: TradeType.Plumbing,
      category: 'FlexiHose',
      type: JobType.Service,

      workRequiredNotes: generatedNotes,
      requirements: {
        attributes: [{ attributeId: 'GeneralPlumbing' }]
      }
    })

  const generatedNotes = notesGenerator.generateNotes(result ?? {})
  const updateResult = getResultUpdater(result ?? {}, onUpdate).updateResult
  const canComplete = !!locationInTheHouse && !!areTheyLeaking && !!anyRustSpots

  return (
    <TopicWorkflowAction input={input} onUpdate={onUpdate} result={result}>
      <FreeTextQuestionField
        question={notesGenerator.getQuestion('locationInTheHouse')}
        answer={locationInTheHouse}
        changeAnswer={(locationInTheHouse) => updateResult({ locationInTheHouse })}
      />
      <FreeTextQuestionField
        question={notesGenerator.getQuestion('areTheyLeaking')}
        answer={areTheyLeaking}
        changeAnswer={(areTheyLeaking) => updateResult({ areTheyLeaking })}
      />
      <FreeTextQuestionField
        question={notesGenerator.getQuestion('anyRustSpots')}
        answer={anyRustSpots}
        changeAnswer={(anyRustSpots) => updateResult({ anyRustSpots })}
      />

      <EuiSpacer />
      <EuiButton disabled={!canComplete} onClick={() => onNext()}>
        Next
      </EuiButton>
    </TopicWorkflowAction>
  )
}
