import { EuiFlexGroup, EuiFlexItem } from '@elastic/eui'
import { ContactMergeStatus } from '../api/generated-types'
import '../static/css/contact-merge-status.css'
import { ContactMergeStatusIcon } from './contact-merge-status-icon'

export interface ContactMergeStatusBadgeProps {
  status: ContactMergeStatus
  includeIcon?: boolean
}

export const ContactMergeStatusBadge = ({ status, ...props }: ContactMergeStatusBadgeProps) => {
  const includeIcon = props.includeIcon ?? true
  const title = getTitle(status)
  return (
    <span className={`contact-merge-status-badge contact-merge-status-badge--${status.toLowerCase()}`} title={title}>
      {includeIcon ? (
        <EuiFlexGroup gutterSize="xs" alignItems="center" style={{ marginLeft: '3px', marginRight: '6px' }}>
          <EuiFlexItem grow={false}>
            <ContactMergeStatusIcon status={status} />
          </EuiFlexItem>
          <EuiFlexItem grow={false}>{status}</EuiFlexItem>
        </EuiFlexGroup>
      ) : (
        status
      )}
    </span>
  )
}

const getTitle = (status: ContactMergeStatus): string => {
  switch (status) {
    case ContactMergeStatus.Master:
      return 'This record supersedes merged records'
    case ContactMergeStatus.Merged:
      return 'Merged into a master contact'
    case ContactMergeStatus.None:
      return 'None'
  }
}
