import { EuiFlexGroup, EuiFlexItem, EuiSpacer } from '@elastic/eui'
import { dateConfig } from '@fallonsolutions/date'
import { DateTime } from 'luxon'
import staticMapIcon from '../static/images/static-map.png'
import UserLink from '../users/user-link'
import { ImageViewProps } from './image-view'

export const ImageViewCaption = ({ caption, author, captureDate, uploadDate, position, mediaItem }: ImageViewProps) => {
  const formatDate = (date?: string): string => {
    return date
      ? DateTime.fromISO(date).setZone(dateConfig.defaultTimezone).toFormat(dateConfig.luxonFormat.shortDateTime)
      : ''
  }

  // TODO: if upload date and capture date only differ by < 5 mins then no need to show upload date
  const showUploadDate = () => {
    if (!uploadDate || !captureDate) {
      return true
    }
    const cDate = DateTime.fromISO(captureDate)
    const uDate = DateTime.fromISO(uploadDate)
    const diff = Math.abs(uDate.toMillis() - cDate.toMillis())
    // const diff = uploadDateMoment.diff(captureDateMoment, 'minutes')
    const minimumDifference = 5 * 1000 * 60 // 5 minutes
    return diff > minimumDifference
  }
  const mapUrl = position && `https://www.google.com/maps/search/?api=1&query=${position.lat}%2C${position.lon}`

  const labels = mediaItem?.analysis?.labels ?? []
  const moderation = mediaItem?.analysis?.moderation ?? []
  const detectedText = mediaItem?.analysis?.detectedText ?? []
  const hasAnalysis = labels.length > 0 || moderation.length > 0 || detectedText.length > 0

  return (
    <div style={{ display: 'none' }} className="hidden-caption-content">
      <EuiFlexGroup gutterSize="m">
        {position && mapUrl && (
          <EuiFlexItem grow={false}>
            <a
              href={mapUrl}
              target="_blank"
              rel="noreferrer"
              style={{ width: '72px', height: '72px', borderRadius: '3px', overflow: 'hidden' }}
            >
              <img src={staticMapIcon} width="72" height="72" alt="View on map" />
            </a>
          </EuiFlexItem>
        )}
        <EuiFlexItem grow={true}>
          <EuiFlexGroup direction="column" gutterSize="xs">
            {author && <UserLink user={author} target="_blank" />}
            <EuiFlexItem grow={true}>{caption}</EuiFlexItem>
            <EuiFlexItem grow={false}>{formatDate(captureDate)}</EuiFlexItem>
            {showUploadDate() && (
              <EuiFlexItem
                grow={false}
                style={{ fontSize: '12px', fontStyle: 'italic', color: 'rgba(255, 255, 255, 0.5)' }}
              >
                Uploaded {formatDate(uploadDate)}
              </EuiFlexItem>
            )}

            {hasAnalysis && (
              <EuiFlexItem grow={false}>
                <div
                  style={{
                    maxHeight: '80vh'
                  }}
                >
                  <EuiFlexGroup direction="column" gutterSize="xs" style={{ fontSize: '12px' }}>
                    {labels.length > 0 && (
                      <EuiFlexItem grow={false}>
                        <strong className="small-label flat">Labels</strong>
                        <ul>
                          {labels.map((label, index) => (
                            <li key={index}>
                              {label.text} ({label.confidence.toFixed(0)})
                            </li>
                          ))}
                        </ul>
                        <EuiSpacer size="s" />
                      </EuiFlexItem>
                    )}
                    {moderation.length > 0 && (
                      <EuiFlexItem grow={false}>
                        <strong className="small-label flat">Moderation</strong>
                        <ul>
                          {moderation.map((label, index) => (
                            <li key={index}>
                              {label.text} ({label.confidence.toFixed(0)})
                            </li>
                          ))}
                        </ul>
                        <EuiSpacer size="s" />
                      </EuiFlexItem>
                    )}
                    {detectedText.length > 0 && (
                      <EuiFlexItem grow={false}>
                        <strong className="small-label flat">Detected text</strong>
                        <ul>
                          {detectedText.map((label, index) => (
                            <li key={index}>
                              {label.text} ({label.confidence.toFixed(0)})
                            </li>
                          ))}
                        </ul>
                        <EuiSpacer size="s" />
                      </EuiFlexItem>
                    )}
                  </EuiFlexGroup>
                </div>
              </EuiFlexItem>
            )}
          </EuiFlexGroup>
        </EuiFlexItem>
      </EuiFlexGroup>
    </div>
  )
}
