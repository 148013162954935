import { EuiPagination } from '@elastic/eui'
import { compact } from 'lodash-es'
import { MediaItemList, MediaItemListProps } from './media-item-list'
import { MediaItemsContainer } from './media-items-container'

export interface PaginatedMediaItemListProps extends MediaItemListProps {
  activePage: number
  totalPages: number
  totalMediaItemCount: number
  setActivePage: (activePageNumber: number) => void
  enableEditing?: boolean
  viewDeletedStateChanged?: () => void
}

export const PaginatedMediaItemList = (props: PaginatedMediaItemListProps) => {
  const {
    activePage,
    totalPages,
    setActivePage,
    totalMediaItemCount,
    mediaItems,
    mediaItemWidth,
    setMediaItemWidth,
    enableEditing,
    jobId,
    viewDeletedStateChanged,
    showJobLink
  } = props

  const allMedia = compact(mediaItems)
  return (
    <>
      {enableEditing ? (
        <>
          <MediaItemsContainer
            jobId={jobId}
            allMedia={allMedia}
            viewDeletedStateChanged={viewDeletedStateChanged}
            showJobLink={showJobLink}
          />
        </>
      ) : (
        <MediaItemList
          mediaItems={allMedia}
          totalMediaItemCount={totalMediaItemCount}
          showJobLink={true}
          mediaItemWidth={mediaItemWidth}
          setMediaItemWidth={setMediaItemWidth}
        />
      )}
      {totalPages > 0 && (
        <>
          <EuiPagination
            aria-label={'media-items-paginated-results'}
            pageCount={totalPages}
            activePage={activePage}
            onPageClick={(activePage) => setActivePage(activePage)}
          />
        </>
      )}
    </>
  )
}
