import { useState } from 'react'
import {
  CreateFutileJobApprovalDocument,
  CreateFutileJobApprovalInput,
  FutileApprovalReason,
  JobApprovalType
, JobFragment } from '../api/generated-types'
import {
  EuiButton,
  EuiFormRow,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiOverlayMask,
  EuiSelect,
  EuiSelectOption,
  EuiSpacer,
  EuiText,
  EuiTextArea
} from '@elastic/eui'
import { useMutation } from '@apollo/client'

import { map, omit } from 'lodash-es'
import { decamelise } from '../common/utils'

export interface CreateFutileJobApprovalFormProps {
  job: JobFragment
  closeModal: () => void
}

export const CreateFutileJobApprovalForm = ({ job, closeModal }: CreateFutileJobApprovalFormProps) => {
  const [reason, setReason] = useState<FutileApprovalReason>(FutileApprovalReason.AlreadyFixed)
  const [comments, setComments] = useState('')

  const type = JobApprovalType.Futile

  const [createFutileJobApproval, { loading }] = useMutation(CreateFutileJobApprovalDocument, {
    refetchQueries: ['GetJob'],
    awaitRefetchQueries: true
  })

  const handleCreateFutileJobApproval = async () => {
    if (!reason) {
      return
    }
    const input: CreateFutileJobApprovalInput = {
      jobId: job.id,
      reason,
      type,
      comments: comments.trim().length > 0 ? comments : undefined
    }
    try {
      await createFutileJobApproval({ variables: { input } })
      closeModal()
    } catch (error) {
      console.error('error performing mutation', error)
    }
  }

  const reasonOptions: EuiSelectOption[] = map(omit(FutileApprovalReason, FutileApprovalReason.Other), (reason) => ({
    value: reason,
    text: decamelise(reason)
  })).concat({ value: FutileApprovalReason.Other, text: 'Other (describe)' })

  const commentsRequired = reason === FutileApprovalReason.Other

  const isValid = !!reason && (!commentsRequired || comments.trim().length > 0)

  return (
    <EuiOverlayMask>
      <EuiModal onClose={closeModal}>
        <EuiModalHeader>
          <EuiModalHeaderTitle>{approvalTitle(type, job.number)}</EuiModalHeaderTitle>
        </EuiModalHeader>
        <EuiModalBody>
          <EuiText>Approval will allow creation of job report and invoice.</EuiText>
          <EuiSpacer />
          <EuiFormRow label="Reason">
            <EuiSelect
              value={reason}
              options={reasonOptions}
              onChange={(e) => setReason(e.target.value as FutileApprovalReason)}
            />
          </EuiFormRow>
          <EuiFormRow label="Comments">
            <EuiTextArea
              placeholder={commentsRequired ? '(required)' : '(optional)'}
              value={comments}
              onChange={(event) => setComments(event.target.value)}
            />
          </EuiFormRow>
        </EuiModalBody>
        <EuiModalFooter>
          <EuiButton onClick={handleCreateFutileJobApproval} isLoading={loading} fill disabled={!isValid}>
            Approve
          </EuiButton>
        </EuiModalFooter>
      </EuiModal>
    </EuiOverlayMask>
  )
}

const approvalTitle = (type: JobApprovalType, jobReference: string): string => {
  switch (type) {
    case JobApprovalType.Futile:
    default:
      return `Approve futile job ${jobReference}`
  }
}
