import { CSSProperties } from 'react'
import '../static/css/token.css'

export enum ScheduleTokenType {
  Vaccinated = 'vaccinated',
  Flexible = 'flexible',
  Priority = 'priority',
  Priority2 = 'priority-2',
  AfterHours = 'after-hours',
  Locked = 'locked'
}

export interface ScheduleTokenProps {
  type: ScheduleTokenType
  size?: 'l' | 'm' | 's'
  style?: CSSProperties
  label?: string // For locked events we have the user who locked it, so this label will be the name and date for the locked status
}

export const valueForScheduleTokenType = (type: ScheduleTokenType): string => {
  switch (type) {
    case ScheduleTokenType.Vaccinated:
      return 'V'
    case ScheduleTokenType.Priority:
      return '!'
    case ScheduleTokenType.Priority2:
      return '↑'
    case ScheduleTokenType.Flexible:
      return '~'
    case ScheduleTokenType.AfterHours:
      return '🌙'
    case ScheduleTokenType.Locked:
      return '🔒'
  }
}

export const labelForScheduleTokenType = (type: ScheduleTokenType): string => {
  switch (type) {
    case ScheduleTokenType.Vaccinated:
      return 'COVID19 Vaccinated'
    case ScheduleTokenType.Priority:
      return 'Priority 1 job'
    case ScheduleTokenType.Priority2:
      return 'Priority 2 job'
    case ScheduleTokenType.Flexible:
      return 'Flexible job'
    case ScheduleTokenType.AfterHours:
      return 'After hours job'
    case ScheduleTokenType.Locked:
      return 'Locked'
  }
}

export const sizeClassName = (size: 's' | 'm' | 'l'): string => {
  switch (size) {
    case 's':
      return 'fs-token--small'
    case 'm':
      return ''
    case 'l':
      return 'fs-token--large'
  }
}

export const ScheduleToken = (props: ScheduleTokenProps) => {
  const { type, style, label } = props
  const size = props.size ?? 'm'
  return (
    <span
      style={style}
      className={`fs-token fs-token--${type} ${sizeClassName(size)}`}
      title={label ?? labelForScheduleTokenType(type)}
    >
      {valueForScheduleTokenType(type)}
    </span>
  )
}
