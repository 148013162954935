import { EuiButton, EuiSpacer } from '@elastic/eui'
import { CustomerType, EnquiryAbortReason } from '../../../api/generated-types'
import { Callout } from '../../../common/callout'
import { OutOfScope } from '../../helpers/out-of-topic'
import { getResultUpdater } from '../../helpers/results-updater'
import { useWorkflow } from '../../helpers/workflow-provider'
import { SingleSelectField } from '../../question-fields/single-select-question-field'
import { WorkflowActionProps } from '../../workflow-model'
import { TopicActionInput } from '../action-topic-model'
import { TopicWorkflowAction } from '../action-topic-view'
import { YesNoValue } from '../common/common-enums'
import { TopicQualification } from '../common/common-outcome'
import { TopicSecurityInspectionActionResult, inspectionNotesGenerator } from './action-topic-security-inspection-model'
import { outcomeGenerator } from './action-topic-security-inspection-outcome'

export const TopicSecurityInspectionAction = (
  props: WorkflowActionProps<TopicActionInput, TopicSecurityInspectionActionResult>
) => {
  const workflowContext = useWorkflow()
  const { result, input, onUpdate } = props
  const { isSpecificInspection, customerType } = result ?? {}

  const notesGenerator = inspectionNotesGenerator

  const topicOutcome = outcomeGenerator(result)
  const { requirements, qualification } = topicOutcome
  const isOutOfScope = qualification === TopicQualification.OutOfScope

  const onNext = () =>
    onUpdate({
      ...result,
      ...topicOutcome,
      actionCompleted: true,

      workRequiredNotes: generatedNotes
    })

  const generatedNotes = notesGenerator.generateNotes(result ?? {})
  const updateResult = getResultUpdater(result ?? {}, onUpdate).updateResult
  const isDomestic = customerType === CustomerType.Domestic
  const canComplete = !!requirements

  return (
    <>
      {isDomestic ? (
        <>
          <TopicWorkflowAction input={input} onUpdate={onUpdate} result={result}>
            <Callout type="script">
              Please note that the $99 security inspection special does not cover{' '}
              <strong>access control systems.</strong>
            </Callout>
            <SingleSelectField
              question={notesGenerator.getQuestion('isSpecificInspection')}
              options={[
                {
                  id: YesNoValue.Yes,
                  label: 'Yes, specific issue to inspect',
                  icon: 'check'
                },
                {
                  id: YesNoValue.No,
                  icon: 'cross'
                }
              ]}
              answer={isSpecificInspection}
              changeAnswer={(isSpecificInspection) => updateResult({ isSpecificInspection })}
            />

            {isOutOfScope && (
              <>
                <Callout type="script">Unfortunately this type of work is out of scope for us.</Callout>
                <EuiSpacer />
                <EuiButton
                  color="warning"
                  onClick={() =>
                    workflowContext.abort({
                      abortReason: EnquiryAbortReason.CustomerNeedsAreOutOfService,
                      notes: `Customer was enquiring about SecurityInspection`
                    })
                  }
                >
                  Offer a voucher and end call
                </EuiButton>
              </>
            )}

            {requirements && (
              <>
                <EuiSpacer />
                <Callout type="note">{requirements.attributes?.map((r) => r.attributeId).join(', ')}</Callout>
              </>
            )}

            {!isOutOfScope && (
              <>
                <EuiSpacer />
                <EuiButton disabled={!canComplete} onClick={() => onNext()}>
                  Next
                </EuiButton>
              </>
            )}
          </TopicWorkflowAction>
        </>
      ) : (
        <>
          <OutOfScope
            isOutOfScope={true}
            issue={'commercial $99 security fee'}
            reason="We do not offer the $99 security inspection to non domestic customers"
          />
        </>
      )}
    </>
  )
}
