import { EuiButton, EuiSpacer } from '@elastic/eui'
import { EnquiryAbortReason } from '../../../api/generated-types'
import { Callout } from '../../../common/callout'
import { getResultUpdater } from '../../helpers/results-updater'
import { useWorkflow } from '../../helpers/workflow-provider'
import { SingleSelectField } from '../../question-fields/single-select-question-field'
import { WorkflowActionProps } from '../../workflow-model'
import { TopicActionInput } from '../action-topic-model'
import { TopicWorkflowAction } from '../action-topic-view'
import {
  NEW_APPLIANCE_NOT_ONSITE_MESSAGE,
  RepairOrReplaceEnum,
  repairOrReplaceOptions
} from '../appliance-shared/appliance-model'
import { RepairElectricAppliance } from '../appliance-shared/repair-electric-appliance'
import { YesNoValue } from '../common/common-enums'
import { yesNoOptions } from '../common/common-options'
import { TopicQualification } from '../common/common-outcome'
import {
  BOOK_PLUMBER_AND_ELECTRICIAN_SAME_TIME_MESSAGE,
  DUEL_FUEL_INTERNAL_NOTE_MESSAGE,
  DUEL_FUEL_SCRIPT,
  OvenTypeEnum,
  TopicOvenActionResult,
  gasTypeOptions,
  ovenNotesGenerator,
  ovenTypeOptions
} from './action-topic-oven-model'
import { outcomeGenerator } from './action-topic-oven-outcome'

export const TopicOvenAction = (props: WorkflowActionProps<TopicActionInput, TopicOvenActionResult>) => {
  // result === topic chosen
  const { result, input, onUpdate } = props
  const {
    ovenType,
    serviceType,
    removeOldOven,
    isNewOvenOnsite,
    willNewOvenFitCutOut,
    gasType,
    duelFuelConfirmationToProceed
  } = result ?? {}

  const workflowContext = useWorkflow()
  const notesGenerator = ovenNotesGenerator
  const generatedNotes = notesGenerator.generateNotes(result ?? {})

  const topicOutcome = outcomeGenerator(result)
  const { requirements, qualification } = topicOutcome
  const isOutOfScope = qualification === TopicQualification.OutOfScope
  const newTopic = topicOutcome.newTopic

  const updateResult = getResultUpdater(result ?? {}, onUpdate).updateResult

  const canComplete = !!requirements

  const onNext = () =>
    onUpdate({
      ...result,
      ...topicOutcome,
      actionCompleted: true,

      workRequiredNotes: generatedNotes
    })

  return (
    <TopicWorkflowAction input={input} onUpdate={onUpdate} result={result}>
      <SingleSelectField
        question={notesGenerator.getQuestion('ovenType')}
        options={ovenTypeOptions}
        answer={ovenType}
        changeAnswer={(ovenType) => updateResult({ ovenType })}
      />

      {ovenType && (
        <>
          <SingleSelectField
            question={notesGenerator.getQuestion('serviceType')}
            options={repairOrReplaceOptions}
            answer={serviceType}
            changeAnswer={(serviceType) => updateResult({ serviceType })}
          />
          <EuiSpacer size="m" />

          {!!ovenType && serviceType === RepairOrReplaceEnum.Replace && (
            <>
              {ovenType === OvenTypeEnum.DuelFuel && (
                <Callout type="warning" title={BOOK_PLUMBER_AND_ELECTRICIAN_SAME_TIME_MESSAGE} />
              )}

              <SingleSelectField
                question={notesGenerator.getQuestion('removeOldOven')}
                options={yesNoOptions}
                answer={removeOldOven}
                changeAnswer={(removeOldOven) => updateResult({ removeOldOven })}
              />
              <SingleSelectField
                question={notesGenerator.getQuestion('willNewOvenFitCutOut')}
                options={yesNoOptions}
                answer={willNewOvenFitCutOut}
                changeAnswer={(willNewOvenFitCutOut) => updateResult({ willNewOvenFitCutOut })}
              />

              {!!removeOldOven && !!willNewOvenFitCutOut && (
                <SingleSelectField
                  question={notesGenerator.getQuestion('isNewOvenOnsite')}
                  options={yesNoOptions}
                  answer={isNewOvenOnsite}
                  changeAnswer={(isNewOvenOnsite) => updateResult({ isNewOvenOnsite })}
                />
              )}
              {isNewOvenOnsite === YesNoValue.No && <Callout type="note" title={NEW_APPLIANCE_NOT_ONSITE_MESSAGE} />}
              {(ovenType === OvenTypeEnum.DuelFuel || ovenType === OvenTypeEnum.Gas) && !!isNewOvenOnsite && (
                <SingleSelectField
                  question={notesGenerator.getQuestion('gasType')}
                  options={gasTypeOptions}
                  answer={gasType}
                  changeAnswer={(gasType) => updateResult({ gasType })}
                />
              )}
            </>
          )}
        </>
      )}

      {ovenType === OvenTypeEnum.DuelFuel && serviceType === RepairOrReplaceEnum.Repair && (
        <>
          <Callout type="script" title={DUEL_FUEL_SCRIPT} />
          <EuiSpacer size="m" />
          <SingleSelectField
            question={notesGenerator.getQuestion('duelFuelConfirmationToProceed')}
            options={yesNoOptions}
            answer={duelFuelConfirmationToProceed}
            changeAnswer={(duelFuelConfirmationToProceed) => updateResult({ duelFuelConfirmationToProceed })}
          />
        </>
      )}
      {(ovenType === OvenTypeEnum.Electric ||
        (ovenType === OvenTypeEnum.DuelFuel && duelFuelConfirmationToProceed === YesNoValue.Yes)) &&
        serviceType === RepairOrReplaceEnum.Repair && (
          <RepairElectricAppliance
            notesGenerator={notesGenerator}
            updateResult={updateResult}
            result={result}
            isCooktopWorkflow={false}
          />
        )}

      {requirements && ovenType === OvenTypeEnum.DuelFuel && serviceType === RepairOrReplaceEnum.Repair && (
        <Callout type="warning" title={DUEL_FUEL_INTERNAL_NOTE_MESSAGE} />
      )}

      {requirements && requirements.attributes && (
        <>
          <EuiSpacer />
          <Callout type="note">
            Book a technician with {requirements.attributes?.map((r) => r.attributeId).join(', ')} skills
          </Callout>
        </>
      )}

      {!isOutOfScope && !newTopic && (
        <>
          <EuiSpacer />
          <EuiButton disabled={!canComplete} onClick={() => onNext()}>
            Next
          </EuiButton>
        </>
      )}
      {newTopic && (
        <EuiButton onClick={() => workflowContext.changeJobTopic(newTopic.reference)}>{newTopic.buttonLabel}</EuiButton>
      )}

      {isOutOfScope && (
        <>
          <Callout type="script">
            {topicOutcome.qualificationMessage
              ? topicOutcome.qualificationMessage
              : 'Unfortunately this type of work is out of scope for us.'}
          </Callout>
          <EuiSpacer />
          <EuiButton
            color="warning"
            onClick={() =>
              workflowContext.abort({
                abortReason: EnquiryAbortReason.CustomerNeedsAreOutOfService,
                notes: `Customer was enquiring about: ${topicOutcome.qualificationMessage}`
              })
            }
          >
            Offer a voucher and end call
          </EuiButton>
        </>
      )}
    </TopicWorkflowAction>
  )
}
