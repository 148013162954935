import { EuiText } from '@elastic/eui'
import { Callout } from '../../../../common/callout'
import { PriorityTypeKey } from '@fallonsolutions/priority'
import { PriorityType } from '@fallonsolutions/types'

const getPriorityLabel = (priority?: PriorityTypeKey) => {
  if (!priority) {
    return
  }
  switch (priority) {
    case PriorityType.P1:
      return 'Priority 1'
    case PriorityType.P2:
      return 'Priority 2'
    case PriorityType.P3:
      return 'Priority 3'
    default:
      break
  }
}

export const PriorityDisclaimer = ({ priority }: { priority?: PriorityTypeKey | undefined }) => {
  return (
    <>
      {!!priority && (
        <>
          <Callout type="script">
            <EuiText>
              <p>The priority for this job is {getPriorityLabel(priority)}</p>
            </EuiText>
          </Callout>
        </>
      )}
    </>
  )
}
