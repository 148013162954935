import { useMutation, useQuery } from '@apollo/client'
import {
  EuiButton,
  EuiButtonEmpty,
  EuiButtonIcon,
  EuiContextMenu,
  EuiContextMenuPanelDescriptor,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiFormRow,
  EuiIcon,
  EuiKeyPadMenu,
  EuiKeyPadMenuItem,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiOverlayMask,
  EuiPanel,
  EuiPopover,
  EuiSelect,
  EuiSelectOption,
  EuiSpacer,
  EuiSwitch,
  EuiText,
  EuiTitle
} from '@elastic/eui'
import { addressFragmentToAddressInput } from '@fallonsolutions/address'
import { contactDetailFragmentToContactInput } from '@fallonsolutions/contact'
import { dataQualityStatusValidOrError } from '@fallonsolutions/data-quality'
import { map } from 'lodash-es'
import { useEffect, useState } from 'react'
import {
  ContactDetailFragment,
  ContactInput,
  CreateContactDocument,
  EnquiryAbortReason,
  FollowUpActionType,
  GetContactDocument
} from '../../api/generated-types'
import { Callout } from '../../common/callout'
import { parsePhone } from '../../common/phone'
import { decamelise } from '../../common/utils'
import { ContactCard } from '../../contacts/contact-card'
import { ContactDetailForm, ContactDetailFormUpdate } from '../../contacts/contact-detail-form'
import { EditContactForm } from '../../contacts/edit-contact-form'
import { JobTopicSelect } from '../../job-topics/job-topic-select'
import { OnChangeContactProps } from '../workflow-interaction-message-model'
import { EnquiryAbortDetails } from '../workflow-model'

export interface AbortEnquiryActionProps {
  contactId?: string // optional contact if we have one at the stage the enquiry is aborted
  details: EnquiryAbortDetails
  onChangeContact: (contact: OnChangeContactProps) => void
  onProceed: (details: EnquiryAbortDetails) => void
  onCancel: () => void
}

const labelForReason = (reason: EnquiryAbortReason) => {
  switch (reason) {
    case EnquiryAbortReason.CustomerNeedsAreOutOfService:
      return 'Out of scope'
    case EnquiryAbortReason.CustomerPropertyIsOutOfServiceArea:
      return 'Out of area'
    case EnquiryAbortReason.TimeNotMet:
      return 'Time not met'
    case EnquiryAbortReason.CustomerNotWillingToPay:
      return 'Not willing to pay'
    case EnquiryAbortReason.CustomerWantedToGetAQuickQuote:
      return 'Quick quote only'
    case EnquiryAbortReason.CustomerDidNotLikeTheTravelFee:
      return 'Travel fee'
    case EnquiryAbortReason.CustomerThreateningLegalOrMediaAction:
      return 'Threatening legal or media action'
    case EnquiryAbortReason.OutOfScope:
      return 'Out of scope'
    case EnquiryAbortReason.CustomerWasTransferredToAnotherPerson:
      return 'Transferred to another CSR'
    default:
      return decamelise(reason)
  }
}

const iconForReason = (reason: EnquiryAbortReason) => {
  switch (reason) {
    case EnquiryAbortReason.CustomerNeedsAreOutOfService:
      return 'wrench'
    case EnquiryAbortReason.CustomerPropertyIsOutOfServiceArea:
      return 'mapMarker'
    case EnquiryAbortReason.CustomerWantedToGetAQuickQuote:
      return 'document'
    case EnquiryAbortReason.CustomerDidNotLikeTheTravelFee:
      return 'flag'
    case EnquiryAbortReason.TimeNotMet:
      return 'clock'
    default:
      return decamelise(reason)
  }
}

export const AbortEnquiryAction = (props: AbortEnquiryActionProps) => {
  const { onProceed, onCancel, details, onChangeContact } = props

  const [abortReasonId, setAbortReasonId] = useState<EnquiryAbortReason | undefined>(details.abortReason)
  const [notes, setNotes] = useState<string>(details.notes ?? '')
  const [isEditContactModalVisible, setIsEditContactModalVisible] = useState(false)
  const [isContactDetailsMenuOpen, setIsContactDetailsMenuOpen] = useState(false)
  const [sendVoucher, setSendVoucher] = useState(false)
  const [isMember, setIsMember] = useState(false)
  const [jobTopic, setJobTopic] = useState(details.jobTopicReference ?? undefined)

  const isHubspotVoucherSendEnabled = true

  const noValue = '-'

  const primaryReasons = [
    EnquiryAbortReason.CustomerNeedsAreOutOfService,
    EnquiryAbortReason.CustomerPropertyIsOutOfServiceArea,
    EnquiryAbortReason.TimeNotMet,
    EnquiryAbortReason.CustomerWantedToGetAQuickQuote,
    EnquiryAbortReason.CustomerDidNotLikeTheTravelFee
  ]
  const otherReasons = map(EnquiryAbortReason).filter(
    (reason) => !primaryReasons.includes(reason) && reason !== EnquiryAbortReason.OutOfScope
  )

  const primaryAbortReasonOptions: any[] = map(primaryReasons, (value) => ({
    id: value,
    text: decamelise(value),
    label: labelForReason(value),
    icon: iconForReason(value),
    value: value
  }))
  const otherAbortReasonOptions: EuiSelectOption[] = [{ id: noValue, text: '', value: noValue }].concat(
    map(otherReasons, (value) => ({
      id: value,
      text: decamelise(value),
      value: value
    }))
  )

  const setReason = (rawValue: string) =>
    setAbortReasonId(rawValue === noValue ? undefined : (rawValue as EnquiryAbortReason))

  const onSubmit = () => {
    onProceed({
      abortReason: abortReasonId,
      ...(jobTopic && jobTopic.length > 0 && { jobTopicReference: jobTopic }),
      notes,
      followUpActions: sendVoucher ? [FollowUpActionType.SendJobLostVoucher] : []
    })
  }

  const isAbortReasonValidForVoucher =
    !!abortReasonId &&
    [
      EnquiryAbortReason.CustomerNeedsAreOutOfService,
      EnquiryAbortReason.CustomerWantedToGetAQuickQuote,
      EnquiryAbortReason.CustomerDidNotLikeTheTravelFee,
      EnquiryAbortReason.CustomerPropertyIsOutOfServiceArea,
      EnquiryAbortReason.TimeNotMet,
      EnquiryAbortReason.OutOfScope
    ].includes(abortReasonId)

  const canOfferVoucher = isAbortReasonValidForVoucher && !isMember

  useEffect(() => {
    if (!canOfferVoucher && sendVoucher) {
      setSendVoucher(false)
    }
  }, [canOfferVoucher])

  const [contactDetail, setContactDetail] = useState<ContactDetailFragment>({})
  const [contactId, setContactId] = useState<string | undefined>(props.contactId)

  const [createContact, { loading, error }] = useMutation(CreateContactDocument, {
    onCompleted: (data) => {
      const contact = data.createContact.contact
      const contactId = contact?.id
      if (contactId) {
        setContactId(contactId)
        const contactInput = contact.detail ? contactDetailFragmentToContactInput(contact.detail) : undefined
        if (!contactInput) {
          return
        }
        console.log('abort enquiry calling setContact handler')
        onChangeContact({
          contactId,
          contactInput
        })
      }
    }
  })

  const { data } = useQuery(GetContactDocument, {
    variables: { input: { contactId: contactId ?? 'missing' } },
    skip: !contactId
  })
  const contact = data?.getContact.contact

  const isReasonValid = abortReasonId !== undefined
  const isVoucherAndContactIdValid =
    !sendVoucher || (!!contact && dataQualityStatusValidOrError(contact.dataQuality?.status))
  const isFormValid = isReasonValid && isVoucherAndContactIdValid

  const onChangeDetail = (updateFn: ContactDetailFormUpdate) => {
    const updated = updateFn({ detail: contactDetail, isValid: true })
    setContactDetail(updated.detail)
  }

  const handleCreateContact = () => {
    if (!contactDetail.firstName) {
      return
    }
    const { firstName, lastName, email, phone, alternatePhone, address } = contactDetail
    const detailInput: ContactInput = {
      firstName,
      lastName,
      email: email && email.length > 0 ? email : undefined,
      phone: parsePhone(phone)?.number.toString() ?? undefined,
      alternatePhone: parsePhone(alternatePhone)?.number.toString() ?? undefined,
      notes,
      ...(address && { address: addressFragmentToAddressInput(address) })
    }
    createContact({
      variables: {
        input: {
          detail: detailInput
        }
      }
    })
  }

  const contextMenu: EuiContextMenuPanelDescriptor[] = [
    {
      id: 0,
      items: [
        {
          name: 'Edit contact',
          icon: 'documentEdit',
          onClick: () => {
            setIsEditContactModalVisible(true)
            setIsContactDetailsMenuOpen(false)
          }
        }
      ]
    }
  ]

  return (
    <>
      <EuiOverlayMask>
        <EuiModal
          onClose={onCancel}
          style={{
            maxInlineSize: '820px',
            width: 'calc(100vw - 200px)',
            height: 'calc(100vh - 100px)'
          }}
        >
          <EuiModalHeader>
            <EuiModalHeaderTitle>Abort this enquiry</EuiModalHeaderTitle>
          </EuiModalHeader>
          <EuiModalBody>
            <EuiForm>
              <EuiFormRow label="Reason" fullWidth={true}>
                <>
                  <EuiKeyPadMenu className="fs-keypadmenu">
                    {primaryAbortReasonOptions.map((option) => (
                      <EuiKeyPadMenuItem
                        key={option.id}
                        id={option.id}
                        label={option.label}
                        isSelected={option.id === abortReasonId}
                        onClick={() => (option.id ? setReason(option.id) : null)}
                      >
                        <EuiIcon type={option.icon} size="xl" />
                      </EuiKeyPadMenuItem>
                    ))}
                  </EuiKeyPadMenu>
                  <EuiSpacer />
                  <EuiFlexGroup alignItems="center">
                    <EuiFlexItem grow={false}>Other reasons:</EuiFlexItem>
                    <EuiFlexItem grow={false}>
                      <EuiSelect
                        fullWidth={true}
                        options={otherAbortReasonOptions}
                        isInvalid={!isReasonValid}
                        value={isReasonValid ? abortReasonId : noValue}
                        onChange={(e) => setReason(e.target.value)}
                      />
                    </EuiFlexItem>
                    <EuiFlexItem grow={true} />
                  </EuiFlexGroup>

                  {abortReasonId && (
                    <>
                      {abortReasonId === EnquiryAbortReason.CustomerThreateningLegalOrMediaAction && (
                        <>
                          <EuiSpacer size="s" />
                          <Callout type="help" title="What to do next">
                            <EuiText>
                              If customer is threatening legal or media action, inform GM, Take No further Action. For
                              Electrical Call Scott Sinclair Ext 374 or 0448 700 15 for all other trades call Mike
                              Preston Ext 361 or 0400 305 361. If General Manager is unavailable contact the CEO (Mark
                              Denning EXT 366 or 0407 035 983)
                            </EuiText>
                          </Callout>
                        </>
                      )}

                      {isAbortReasonValidForVoucher && (
                        <>
                          <EuiSpacer size="m" />
                          <Callout type="note">
                            <EuiFlexGroup alignItems="center" gutterSize="none">
                              <EuiFlexItem grow={true}>Select job topic</EuiFlexItem>
                              <EuiFlexItem grow={false}>
                                <JobTopicSelect value={jobTopic} onChange={setJobTopic} />
                              </EuiFlexItem>
                            </EuiFlexGroup>
                          </Callout>
                          <EuiSpacer size="m" />

                          <EuiSpacer size="s" />
                          <Callout type="script">
                            <EuiFlexGroup alignItems="center">
                              <EuiFlexItem grow={true}>Is the contact an existing member?</EuiFlexItem>
                              <EuiFlexItem grow={false}>
                                <EuiSwitch
                                  label="Contact is member"
                                  checked={isMember}
                                  onChange={(e) => setIsMember(e.target.checked)}
                                />
                              </EuiFlexItem>
                            </EuiFlexGroup>
                          </Callout>
                          {isMember && (
                            <>
                              <EuiSpacer size="s" />
                              <Callout type="note">Note the $40 voucher is not applicable to existing members</Callout>
                            </>
                          )}
                        </>
                      )}

                      {canOfferVoucher && (
                        <>
                          <EuiSpacer size="s" />
                          <Callout type="script">
                            <EuiFlexGroup alignItems="center">
                              <EuiFlexItem grow={true}>
                                Send a $40 voucher for use on the next job you book with us?
                              </EuiFlexItem>
                              <EuiFlexItem grow={false}>
                                <EuiSwitch
                                  label="Send voucher"
                                  checked={sendVoucher}
                                  onChange={(e) => setSendVoucher(e.target.checked)}
                                />
                              </EuiFlexItem>
                            </EuiFlexGroup>
                          </Callout>
                        </>
                      )}

                      {sendVoucher && (
                        <>
                          <EuiSpacer size="xs" />
                          {contact ? (
                            <>
                              <EuiPanel>
                                <EuiFlexGroup>
                                  <EuiFlexItem grow={true}>
                                    <ContactCard contact={contact} />
                                  </EuiFlexItem>
                                  <EuiFlexItem grow={false}>
                                    <EuiPopover
                                      isOpen={isContactDetailsMenuOpen}
                                      button={
                                        <EuiButtonIcon
                                          aria-label="show actions"
                                          iconType="boxesHorizontal"
                                          color="text"
                                          onClick={() => setIsContactDetailsMenuOpen(true)}
                                        />
                                      }
                                      closePopover={() => setIsContactDetailsMenuOpen(false)}
                                      ownFocus={true}
                                      panelPaddingSize="none"
                                    >
                                      <EuiContextMenu initialPanelId={0} panels={contextMenu} />
                                    </EuiPopover>
                                  </EuiFlexItem>
                                </EuiFlexGroup>
                              </EuiPanel>
                              <EuiSpacer />
                              {isHubspotVoucherSendEnabled ? (
                                <Callout type="success" title="Voucher will be sent automatically via Hubspot">
                                  Once you submit this form a voucher will be automatically sent via Hubspot.
                                </Callout>
                              ) : (
                                <Callout type="warning" title="Action required: Send voucher through InfusionSoft">
                                  This step will soon be automated but for now please send through InfusionSoft.
                                </Callout>
                              )}
                            </>
                          ) : (
                            <>
                              <Callout type="script">
                                <EuiFlexGroup>
                                  <EuiFlexItem grow={true}>
                                    <EuiText>To send you the voucher I just need to note your contact details.</EuiText>
                                  </EuiFlexItem>
                                  <EuiFlexItem grow={false}></EuiFlexItem>
                                </EuiFlexGroup>
                              </Callout>
                              <EuiSpacer size="m" />
                              <EuiPanel style={{ maxWidth: '440px' }}>
                                <EuiTitle size="xs">
                                  <h3>Create contact</h3>
                                </EuiTitle>
                                <EuiSpacer size="m" />
                                <ContactDetailForm
                                  detail={contactDetail}
                                  onChange={onChangeDetail}
                                  addressRequired={false}
                                  showValidationErrors={false}
                                />
                                <EuiSpacer size="l" />
                                <EuiButton color="primary" fill isLoading={loading} onClick={handleCreateContact}>
                                  Create contact
                                </EuiButton>
                              </EuiPanel>
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              </EuiFormRow>
              {/* <EuiFormRow label="Notes" fullWidth={true}>
              <EuiTextArea fullWidth={true} value={notes} onChange={(e) => setNotes(e.target.value)} />
            </EuiFormRow> */}
            </EuiForm>
          </EuiModalBody>
          <EuiModalFooter>
            <EuiButtonEmpty onClick={onCancel}>Cancel</EuiButtonEmpty>
            <EuiButton fill onClick={onSubmit} disabled={!isFormValid} data-test-id="abort-button">
              Submit
            </EuiButton>
          </EuiModalFooter>
        </EuiModal>
      </EuiOverlayMask>
      {isEditContactModalVisible && contact && (
        <EditContactForm contact={contact} onClose={() => setIsEditContactModalVisible(false)} />
      )}
    </>
  )
}
