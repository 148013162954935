import { CognitoIdentityClient } from '@aws-sdk/client-cognito-identity'
import { PutObjectCommandInput, S3Client, Tag } from '@aws-sdk/client-s3'
import { fromCognitoIdentityPool } from '@aws-sdk/credential-provider-cognito-identity'
import { Upload } from '@aws-sdk/lib-storage'
import { AwsCredentialIdentity } from '@aws-sdk/types'
import { IAppContext } from '../app/app-context'

export interface GetManagedUploadParams {
  params: PutObjectCommandInput
  tags: Tag[]
}

export interface IIAMUtils {
  getManagedUpload(options: GetManagedUploadParams): Upload

  refreshCredentials(): Promise<void>
  ready: boolean
  getS3Client(): S3Client
  credentials?: AwsCredentialIdentity

  signOut(): void
}

type IAMUtilOption = (iamUtils: IAMUtils) => void

export class IAMUtils implements IIAMUtils {
  credentials?: AwsCredentialIdentity
  ready: boolean
  appContext: IAppContext | undefined
  s3Client: S3Client | undefined

  constructor(...options: IAMUtilOption[]) {
    this.appContext = undefined
    this.credentials = undefined
    this.ready = false

    for (const option of options) {
      option(this)
    }
  }

  async refreshCredentials(): Promise<void> {
    const appContext = this.appContext
    const idToken = this.getIdToken()
    if (!appContext || !idToken) {
      return
    }
    IAMUtils.getIdentityCredentials(appContext, idToken, true)
  }

  static getIdentityCredentials(
    appContext: IAppContext,
    idToken: string,
    forceRefresh = true
  ): Promise<AwsCredentialIdentity> {
    const getCredentials = async () => {
      // console.log('getIdentityCredentials', appContext.region, idToken)
      const cognitoIdentity = new CognitoIdentityClient({
        credentials: fromCognitoIdentityPool({
          client: new CognitoIdentityClient({ region: appContext.region }),
          identityPoolId: appContext.identityPoolId,
          logins: {
            [`${appContext.loginKeyPrefix}/${appContext.userPoolId}`]: idToken
          }
        }),
        region: appContext.region
      })
      // console.log('cognitoIdentity', cognitoIdentity)
      const credentials = await cognitoIdentity.config.credentials({ forceRefresh })
      // console.log('cognitoIdentity credentials', credentials)
      return credentials
    }
    return getCredentials()
  }

  static _getIdToken(clientId: string): string | null {
    const userIdKey = `CognitoIdentityServiceProvider.${clientId}.LastAuthUser`
    const userId = window.localStorage.getItem(userIdKey)

    const idTokenKey = `CognitoIdentityServiceProvider.${clientId}.${userId}.idToken`
    const item = window.localStorage.getItem(idTokenKey)
    return item
  }

  getIdToken(): string | null {
    const userIdKey = `CognitoIdentityServiceProvider.${this.appContext?.cognitoClientId}.LastAuthUser`
    const userId = window.localStorage.getItem(userIdKey)

    const idTokenKey = `CognitoIdentityServiceProvider.${this.appContext?.cognitoClientId}.${userId}.idToken`
    const item = window.localStorage.getItem(idTokenKey)
    return item
  }

  getS3Client() {
    if (!this.s3Client) {
      this.s3Client = new S3Client({ region: this.appContext?.region, credentials: this.credentials })
      return this.s3Client
    }
    return this.s3Client
  }

  getManagedUpload({ params, tags }: GetManagedUploadParams) {
    const multipartUpload = new Upload({
      client: this.getS3Client(),
      params: {
        Bucket: params.Bucket,
        Key: params.Key,
        Body: params.Body,
        Tagging: tags.map((t) => `${t.Key}=${t.Value}`).join('&')
      }
    })
    return multipartUpload
  }

  signOut() {
    console.log('iamUtils.signOut')
    // this.credentials?.clearCachedId() // What is equivalent for this in v3?
    this.credentials = undefined
  }

  public static async withContext(appContext: IAppContext): Promise<IAMUtilOption> {
    if (!IAMUtils._getIdToken(appContext.cognitoClientId)) {
      throw new Error('not authenticated')
    }
    console.log('iamUtils: withContext', appContext)
    const token: string = IAMUtils._getIdToken(appContext.cognitoClientId) ?? ''
    const credentials = await IAMUtils.getIdentityCredentials(appContext, token)
    console.log('iamUtils: cognitoIdentityCreds', 'expired', credentials.expiration, credentials)
    return (iamUtils: IAMUtils): void => {
      iamUtils.appContext = appContext
      iamUtils.credentials = credentials
      iamUtils.s3Client = new S3Client({ region: appContext?.region, credentials })
      iamUtils.ready = true
    }
  }
}
