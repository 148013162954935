import { useMutation } from '@apollo/client'
import {
  EuiButton,
  EuiButtonEmpty,
  EuiCallOut,
  EuiFlexGroup,
  EuiFlexItem,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiOverlayMask,
  EuiSpacer
} from '@elastic/eui'
import { useEffect, useState } from 'react'
import { ContactFragment, ContactLinkFragment, ContactMergeStatus, MergeContactsDocument } from '../api/generated-types'
import { ContactComboBox } from './contact-combo-box'
import { ContactMergeStatusBadge } from './contact-merge-status-badge'

export interface ContactMergeFormProps {
  contact: ContactFragment
  onClose: () => void
}

export const ContactMergeForm = ({ contact, onClose }: ContactMergeFormProps) => {
  const [mergeContacts, { data, loading, error }] = useMutation(MergeContactsDocument, {
    refetchQueries: ['GetContact', 'SearchContacts', 'SearchCustomerContacts'],
    awaitRefetchQueries: true
  })

  const masterContact = contact
  const [mergedContacts, setMergedContacts] = useState<ContactLinkFragment[]>(contact.mergedContacts ?? [])

  useEffect(() => {
    if (data?.mergeContacts?.masterContact?.id) {
      onClose()
    }
  }, [data?.mergeContacts?.masterContact?.id, onClose])

  const handleSubmit = () => {
    console.log('masterContact', masterContact.detail?.fullName ?? 'No name')
    console.log(
      'mergedContacts',
      mergedContacts.map((c) => c.detail?.fullName ?? 'No name')
    )
    mergeContacts({
      variables: {
        input: {
          masterContactId: masterContact.id,
          mergedContactIds: mergedContacts.map((c) => c.id)
        }
      }
    })
  }

  return (
    <>
      <EuiOverlayMask>
        <EuiModal onClose={onClose} style={{ minWidth: '720px', minHeight: '540px' }}>
          <EuiModalHeader>
            <EuiModalHeaderTitle>Merge contacts</EuiModalHeaderTitle>
          </EuiModalHeader>
          <EuiModalBody>
            {error && (
              <EuiCallOut title={`Error merging contacts`} color="danger">
                {error.message}
              </EuiCallOut>
            )}
            <EuiFlexGroup gutterSize="s" alignItems="center">
              <EuiFlexItem grow={false}>
                <ContactMergeStatusBadge status={ContactMergeStatus.Master} includeIcon={true} />
              </EuiFlexItem>
              <EuiFlexItem>{masterContact.detail?.fullName ?? 'No name'}</EuiFlexItem>
            </EuiFlexGroup>
            <EuiSpacer />

            <EuiFlexGroup gutterSize="s" alignItems="center">
              <EuiFlexItem grow={false}>
                <ContactMergeStatusBadge status={ContactMergeStatus.Merged} includeIcon={true} />
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <ContactComboBox
                  startEnabled={true}
                  contacts={mergedContacts}
                  onChangeContacts={(contacts) => setMergedContacts(contacts)}
                  width="400px"
                />
              </EuiFlexItem>
              <EuiFlexItem grow={true} />
            </EuiFlexGroup>
          </EuiModalBody>
          <EuiModalFooter>
            {!loading && (
              <EuiButtonEmpty onClick={onClose} disabled={loading}>
                Cancel
              </EuiButtonEmpty>
            )}
            <EuiButton fill onClick={() => handleSubmit()} isLoading={loading}>
              Merge contacts
            </EuiButton>
          </EuiModalFooter>
        </EuiModal>
      </EuiOverlayMask>
    </>
  )
}
