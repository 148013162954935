import { useLazyQuery, useQuery } from '@apollo/client'
import {
  EuiAvatar,
  EuiButtonEmpty,
  EuiComboBox,
  EuiComboBoxOptionOption,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHighlight
} from '@elastic/eui'
import { compact } from 'lodash-es'
import { useCallback, useEffect, useState } from 'react'
import { useDebouncedValue } from 'rooks'
import { AllUsersDocument, GetUsersDocument, User, UserFilters, UserRole } from '../api/generated-types'
import '../static/css/combo-box.css'

export type UserComboItem = EuiComboBoxOptionOption<UserComboBoxItem>

export interface UserComboBoxProps {
  initialUserIds?: string[]
  users: UserComboItem[]
  onChangeUsers: (users: UserComboItem[]) => void
  includeHistoricUsersActive?: boolean
  onChangeHistoricUsers?: (includeHistoricUsers: boolean) => void
  roles?: UserRole[]
  singleSelection?: boolean
  compact?: boolean
  label?: string
  placeholder?: string
  includeFinance?: boolean
  initialEnabled?: boolean
  includeInactive?: boolean
  includeHistoricUsers?: {
    from: string
    to: string
  }
  disabled?: boolean
  alwaysEnabled?: boolean
  performanceParticipation?: boolean
  timesheetParticipation?: boolean
}

export interface UserComboBoxItem {
  id: string
  label: string
  avatar?: string
  lastName?: string
  firstName?: string
  active?: boolean
}

const UserComboBox = (props: UserComboBoxProps) => {
  const {
    initialUserIds,
    users,
    onChangeUsers,
    initialEnabled,
    includeFinance,
    performanceParticipation,
    timesheetParticipation,
    alwaysEnabled,
    includeHistoricUsers
  } = props
  const roles = props.roles ?? []

  const includeInactive = props.includeInactive ?? false
  const [enabled, setEnabled] = useState(initialEnabled ?? users.length > 0)
  const [comboInput, setComboInput] = useState<HTMLInputElement | null>(null)
  const [initialUsers, setInitialUsers] = useState<User[]>([])

  const singleSelection = props.singleSelection ?? false
  const compressed = props.compact ?? false
  const placeholder = props.placeholder ?? 'Technicians'
  const [getInitialUsers, { data: initialUsersData }] = useLazyQuery(GetUsersDocument)
  const [term, setTerm] = useState('')

  useEffect(() => {
    if (initialUsersData?.getUsers?.users) {
      setInitialUsers(initialUsersData?.getUsers?.users as User[]) // potentially dangerous, initial UserData only has select fields from Users
    }
  }, [initialUsersData])

  useEffect(() => {
    if (initialUserIds && initialUserIds.length > 0) {
      getInitialUsers({
        variables: {
          input: {
            userIds: initialUserIds
          }
        }
      })
    }
  }, [initialUserIds, getInitialUsers])

  useEffect(() => {
    if (initialUsers.length > 0) {
      onChangeUsers(
        initialUsers.map((user) => ({
          label: user.contactDetail?.fullName ?? 'No name',
          id: user.id,
          avatar: user.avatar,
          firstName: user.contactDetail?.firstName,
          lastName: user.contactDetail?.lastName
        }))
      )
      // only update parent state once
      setInitialUsers([])
    }
  }, [initialUsers, onChangeUsers])

  // allow parent to update state
  useEffect(() => {
    if (users.length && !enabled) {
      setEnabled(true)
    }
  }, [users, enabled])

  const [debouncedTerm] = useDebouncedValue(term, 150)

  const input: UserFilters = {
    ...(debouncedTerm && debouncedTerm.length > 0 && { query: debouncedTerm }),
    ...(!includeInactive && { active: true }),
    ...(roles.length && { roles }),
    participation: { performance: performanceParticipation, timesheet: timesheetParticipation },
    ...(includeFinance !== undefined && { hasFinance: includeFinance }),
    ...(includeHistoricUsers && {
      includeHistoricUsers
    })
  }

  const variables = {
    input,
    from: 0,
    size: 20
  }
  const { loading, data } = useQuery(AllUsersDocument, { variables })
  const userData = data?.allUsers?.users ?? []

  const allUsers: UserComboItem[] = compact(userData).map((user) => {
    return {
      id: user.id,
      label: user.contactDetail?.fullName ?? user.username ?? 'unknown',
      avatar: user.avatar,
      lastName: user.contactDetail?.lastName,
      firstName: user.contactDetail?.firstName,
      className: singleSelection ? 'combo-box-single-select' : '',
      active: user.active ?? true
    }
  })

  // useEffect(() => {
  //   if (!includeHistoricUsers) {
  //     onChangeUsers(users.filter((user) => user.value?.active))
  //   }
  // }, [includeHistoricUsers, users])

  const renderUserOption = (option: UserComboItem, searchValue: string, contentClassName: string) => {
    return (
      <>
        <>
          <EuiFlexGroup gutterSize="s">
            <EuiFlexItem grow={false}>
              <EuiAvatar size="s" name={`${option.label}`} imageUrl={option.value?.avatar ?? ''} />
            </EuiFlexItem>
            <EuiFlexItem grow={true}>
              <span className={contentClassName}>
                <EuiHighlight search={searchValue}>{option.label}</EuiHighlight>
              </span>
            </EuiFlexItem>
          </EuiFlexGroup>
        </>
      </>
    )
  }

  const onAddFilter = () => {
    setEnabled(true)
    setTimeout(() => comboInput?.focus(), 50)
  }

  const onBlur = () => {
    if (users.length <= 0) {
      setEnabled(false)
    }
  }

  const onSearchChange = useCallback((searchValue: string, _hasMatchingOptions?: boolean) => {
    setTerm(searchValue)
  }, [])

  const label = props.label ?? 'Technician'

  return (
    <form autoComplete="off">
      <EuiComboBox
        inputRef={setComboInput}
        placeholder={placeholder}
        options={allUsers}
        selectedOptions={users}
        onChange={onChangeUsers}
        isClearable={true}
        renderOption={renderUserOption}
        rowHeight={40}
        isLoading={loading}
        style={{ minWidth: '240px' }}
        singleSelection={singleSelection}
        compressed={compressed}
        onSearchChange={onSearchChange}
        hidden={!enabled && !alwaysEnabled}
        onBlur={onBlur}
        aria-label={label.toLowerCase()}
        data-test-subj={label.toLowerCase()}
        isDisabled={props.disabled}
      />
      {!enabled && !alwaysEnabled && (
        <EuiButtonEmpty iconType="filter" flush="both" onClick={onAddFilter}>
          {label}
        </EuiButtonEmpty>
      )}
    </form>
  )
}

export default UserComboBox
