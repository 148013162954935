import { useQuery } from '@apollo/client'
import { EuiButton, EuiLoadingSpinner, EuiSpacer } from '@elastic/eui'
import { dateConfig } from '@fallonsolutions/date'
import { JobTopicType } from '@fallonsolutions/types'
import { DateTime } from 'luxon'
import { useState } from 'react'
import {
  AttendedStatusType,
  CustomerType,
  EnquiryAbortReason,
  JobSort,
  JobType,
  Maybe,
  PriorityType,
  SearchJobsWithAttendedDocument,
  SearchJobsWithAttendedQuery,
  TradeType
} from '../../../api/generated-types'
import { Callout } from '../../../common/callout'
import { makeHumanReadable } from '../../../common/utils'
import { QuestionDefinitions, getNotesGenerator } from '../../helpers/notes-generator'
import { getResultUpdater } from '../../helpers/results-updater'
import { useWorkflow } from '../../helpers/workflow-provider'
import { SingleSelectField } from '../../question-fields/single-select-question-field'
import { WorkflowActionProps } from '../../workflow-model'
import { TopicActionInput, TopicActionResult } from '../action-topic-model'
import { TopicWorkflowAction } from '../action-topic-view'
import { YesNoValue } from '../common/common-enums'

enum AffectedHouseAreasByTrippingPower {
  WholeHouseTripping = 'WholeHouseTripping',
  LightsTripping = 'LightsTripping',
  PowerPointsTripping = 'PowerPointsTripping',
  Other = 'Other'
}

enum AffectedHouseAreasByLossOfPower {
  CompleteLossOfPower = 'CompleteLossOfPower',
  NoPowerToLights = 'NoPowerToLights',
  NoPowerToPowerPoints = 'NoPowerToPowerPoints',
  Other = 'Other'
}

export interface TopicTrippingPowerActionInput extends TopicActionInput {
  topicReference: JobTopicType
}

export interface TopicTrippingPowerActionResult extends TopicActionResult {
  hasFallonDoneElectricalWorkInTheLast90Days?: YesNoValue
  affectedHouseAreasByTrippingPower?: AffectedHouseAreasByTrippingPower
  affectedHouseAreasByLossOfPower?: AffectedHouseAreasByLossOfPower
  receivedAShock?: YesNoValue
}

const questionDefinitions: QuestionDefinitions<TopicTrippingPowerActionResult> = {
  hasFallonDoneElectricalWorkInTheLast90Days: {
    question: 'Has Fallon done any electrical work in the last 90 days?',
    statement: 'Fallon work in last 90 days:',
    makeAnswerHumanReadable: makeHumanReadable
  },
  affectedHouseAreasByTrippingPower: {
    question: 'What areas of the house are being effected?',
    statement: 'Areas effected:',
    makeAnswerHumanReadable: makeHumanReadable
  },
  affectedHouseAreasByLossOfPower: {
    question: 'What areas are being effected?',
    statement: 'Areas effected:',
    makeAnswerHumanReadable: makeHumanReadable
  },
  receivedAShock: {
    question: 'Have you received a shock?',
    makeAnswerHumanReadable: makeHumanReadable
  }
}
const notesGenerator = getNotesGenerator(questionDefinitions)

interface PartialJob {
  id: string
  number: string
  trade?: Maybe<TradeType>
  type?: Maybe<JobType>
  category?: Maybe<string>
  customerType?: Maybe<CustomerType>
  created?: Maybe<any>
  updated?: Maybe<any>
  customer?: Maybe<{
    id: string
    number?: Maybe<string>
  }>
  appointments?: Maybe<
    Array<
      Maybe<{
        id: string
        attended?: Maybe<AttendedStatusType>
        bookingWindow?: Maybe<{ from: any; to: any }>
        scheduled?: Maybe<{ from: any; to: any }>
      }>
    >
  >
}

export const TopicTrippingPowerAction = (
  props: WorkflowActionProps<TopicTrippingPowerActionInput, TopicTrippingPowerActionResult>
) => {
  const workflowContext = useWorkflow()
  const { result, input, onUpdate } = props
  const {
    actionCompleted,
    hasFallonDoneElectricalWorkInTheLast90Days,
    affectedHouseAreasByTrippingPower,
    affectedHouseAreasByLossOfPower,
    receivedAShock
  } = result ?? {}
  const { topicReference } = input

  const [jobsWithMostRecentAppointmentInTheLast90Days, setJobsWithMostRecentAppointmentInTheLast90Days] = useState<
    PartialJob[] | undefined
  >(undefined)
  const [jobsLoaded, setJobsLoaded] = useState(false)

  const getJobsWithAnElectricalAppointmentInTheLast90Days = (jobsData: SearchJobsWithAttendedQuery) => {
    const jobsWithAnElectricalAppointmentInTheLast90Days = jobsData?.searchJobsBeta.results.filter((job) => {
      // TODO: once the job-full index migration is done (fixing the numeric value for job.appointments.attended)
      // then rely purely on the hasAttendedAppointment flag only
      const hasAttendedAppointment = !!(job?.appointments ?? []).find(
        (a) => a?.attended === AttendedStatusType.Attended
      )
      return hasAttendedAppointment || job?.attended === AttendedStatusType.Attended
    })
    return jobsWithAnElectricalAppointmentInTheLast90Days
  }

  const { loading: jobsDataLoading } = useQuery(SearchJobsWithAttendedDocument, {
    onError: (error) => {
      setJobsLoaded(true)
      console.log(JSON.stringify(error))
    },
    onCompleted: (data) => {
      if (!actionCompleted) {
        const jobsWithAnElectricalAppointmentInTheLast90Days =
          getJobsWithAnElectricalAppointmentInTheLast90Days(data) ?? []

        setJobsWithMostRecentAppointmentInTheLast90Days(jobsWithAnElectricalAppointmentInTheLast90Days as PartialJob[])
        updateResult({
          hasFallonDoneElectricalWorkInTheLast90Days:
            jobsWithAnElectricalAppointmentInTheLast90Days.length > 0 ? YesNoValue.Yes : YesNoValue.No
        })
        setJobsLoaded(true)
      }
    },
    variables: {
      input: {
        filter: {
          hasAppointment: true,
          trades: [TradeType.Electrical],
          customers: [input.customer?.id ?? '-'],
          properties: [input.property?.id ?? '-'],
          appointmentDate: {
            from: DateTime.now().setZone(dateConfig.defaultTimezone).minus({ days: 90 }).startOf('day').toISO(),
            to: DateTime.now().setZone(dateConfig.defaultTimezone).endOf('day').toISO()
          }
        },
        options: { sort: JobSort.LastAppointmentDesc }
      }
    }
  })

  const onNext = () =>
    onUpdate({
      ...result,
      requirements: {
        priority: {
          required: PriorityType.P1
        },
        attributes: [{ attributeId: 'LossOfPower' }]
      },
      actionCompleted: true,
      trade: TradeType.Electrical,
      category: 'TrippingPower',
      type: JobType.Service,

      workRequiredNotes: generatedNotes
    })

  const generatedNotes = notesGenerator.generateNotes(result ?? {})
  const updateResult = getResultUpdater(result ?? {}, onUpdate).updateResult

  const energexHasToComeFirst =
    hasFallonDoneElectricalWorkInTheLast90Days === YesNoValue.No && receivedAShock === YesNoValue.Yes

  const canComplete =
    !!hasFallonDoneElectricalWorkInTheLast90Days &&
    (!!affectedHouseAreasByTrippingPower || !!affectedHouseAreasByLossOfPower) &&
    !!receivedAShock &&
    !energexHasToComeFirst

  return (
    <TopicWorkflowAction input={input} onUpdate={onUpdate} result={result}>
      {jobsDataLoading && <EuiLoadingSpinner size="l" />}
      {jobsLoaded && (
        <>
          {!jobsWithMostRecentAppointmentInTheLast90Days && (
            <SingleSelectField
              questionType="note"
              question={notesGenerator.getQuestion('hasFallonDoneElectricalWorkInTheLast90Days')}
              options={[
                {
                  id: YesNoValue.Yes,
                  label: 'Yes, Fallon did some electrical work in the last 90 days',
                  icon: 'check'
                },
                {
                  id: YesNoValue.No,
                  label: 'No',
                  icon: 'cross'
                }
              ]}
              answer={hasFallonDoneElectricalWorkInTheLast90Days}
              changeAnswer={(hasFallonDoneElectricalWorkInTheLast90Days) =>
                updateResult({ hasFallonDoneElectricalWorkInTheLast90Days })
              }
            />
          )}
          {jobsWithMostRecentAppointmentInTheLast90Days && jobsWithMostRecentAppointmentInTheLast90Days.length > 0 && (
            <>
              <Callout
                type="note"
                title="There is at least one appointment in the last 90 days where some electrical work was done for this customer and property"
              >
                See these jobs for more details:
                <EuiSpacer size="s" />
                <ul>
                  {jobsWithMostRecentAppointmentInTheLast90Days.map((job) => (
                    <li key={job.id}>
                      <a href={`/jobs/${job.id}`} target="_blank" rel="noopener noreferrer">
                        Job {job.number}
                      </a>
                    </li>
                  ))}
                </ul>
              </Callout>
              <EuiSpacer size="l" />
            </>
          )}
          {jobsWithMostRecentAppointmentInTheLast90Days &&
            jobsWithMostRecentAppointmentInTheLast90Days.length === 0 && (
              <>
                <Callout
                  type="note"
                  title="No electrical work was done in the last 90 days for this customer and property"
                ></Callout>
                <EuiSpacer size="l" />
              </>
            )}
        </>
      )}

      {hasFallonDoneElectricalWorkInTheLast90Days && (
        <>
          {topicReference === JobTopicType.TrippingPower && (
            <SingleSelectField
              question={notesGenerator.getQuestion('affectedHouseAreasByTrippingPower')}
              options={[
                {
                  id: AffectedHouseAreasByTrippingPower.WholeHouseTripping,
                  label: 'Whole house tripping',
                  icon: 'home'
                },
                {
                  id: AffectedHouseAreasByTrippingPower.LightsTripping,
                  label: 'Lights tripping',
                  icon: 'bellSlash'
                },
                {
                  id: AffectedHouseAreasByTrippingPower.PowerPointsTripping,
                  label: 'Power points tripping',
                  icon: 'bolt'
                },
                {
                  id: AffectedHouseAreasByTrippingPower.Other,
                  label: 'Other',
                  icon: 'questionInCircle'
                }
              ]}
              answer={affectedHouseAreasByTrippingPower}
              changeAnswer={(affectedHouseAreasByTrippingPower) => updateResult({ affectedHouseAreasByTrippingPower })}
            />
          )}
          {topicReference === JobTopicType.LossOfPower && (
            <SingleSelectField
              question={notesGenerator.getQuestion('affectedHouseAreasByLossOfPower')}
              options={[
                {
                  id: AffectedHouseAreasByLossOfPower.CompleteLossOfPower,
                  label: 'Complete loss of power',
                  icon: 'home'
                },
                {
                  id: AffectedHouseAreasByLossOfPower.NoPowerToLights,
                  label: 'No power to lights',
                  icon: 'bellSlash'
                },
                {
                  id: AffectedHouseAreasByLossOfPower.NoPowerToPowerPoints,
                  label: 'No power to power points',
                  icon: 'bolt'
                },
                {
                  id: AffectedHouseAreasByLossOfPower.Other,
                  label: 'Other',
                  icon: 'questionInCircle'
                }
              ]}
              answer={affectedHouseAreasByLossOfPower}
              changeAnswer={(affectedHouseAreasByLossOfPower) => updateResult({ affectedHouseAreasByLossOfPower })}
            />
          )}
          <SingleSelectField
            question={notesGenerator.getQuestion('receivedAShock')}
            options={[
              {
                id: YesNoValue.Yes,
                label: 'Yes, received a shock',
                icon: 'alert'
              },
              {
                id: YesNoValue.No,
                label: 'No',
                icon: 'cross'
              }
            ]}
            answer={receivedAShock}
            changeAnswer={(receivedAShock) => updateResult({ receivedAShock })}
          />
        </>
      )}

      {energexHasToComeFirst && (
        <>
          <Callout type="warning" title="Customer must contact Energex first" />
          <Callout type="script">
            Due to the potentially hazardous nature of the work, we require Energex to assess their own infrastructure
            to the property to ensure its safe. Once cleared, we will be more then happy to attend. Energex's number is:
            (give customer Energex's number).
          </Callout>
          <EuiSpacer />
          <EuiButton
            color="warning"
            onClick={() =>
              workflowContext.abort({
                abortReason: EnquiryAbortReason.Other,
                notes: 'Customer must call Energex first.'
              })
            }
          >
            End call
          </EuiButton>
        </>
      )}

      {hasFallonDoneElectricalWorkInTheLast90Days === YesNoValue.No && receivedAShock === YesNoValue.No && (
        <>
          <Callout type="warning">
            After job booked sent work chat to Electrical team or Email Electrical DSR Team. If time not suitable or
            technician not avail call Electrical DSR 99973.
          </Callout>
        </>
      )}

      <EuiSpacer />
      <EuiButton disabled={!canComplete} onClick={() => onNext()}>
        Next
      </EuiButton>
    </TopicWorkflowAction>
  )
}
