import {
  HotWaterRepairIssue,
  HotWaterSystemAge,
  HotWaterThermostatsAge,
  TopicHotWaterActionResult
} from './action-topic-hot-water-model'
import { SingleSelectField } from '../../question-fields/single-select-question-field'
import { NotesGenerator } from '../../helpers/notes-generator'
import { ResultUpdater } from '../../helpers/results-updater'
import { map } from 'lodash-es'

export interface HotWaterRepairGasProps {
  result?: TopicHotWaterActionResult
  notesGenerator: NotesGenerator<TopicHotWaterActionResult>
  updateResult: ResultUpdater<TopicHotWaterActionResult>
}

export const HotWaterRepairGas = (props: HotWaterRepairGasProps) => {
  const { result, notesGenerator, updateResult } = props
  const { repairIssue, systemAge, thermostatAge } = result ?? {}
  return (
    <>
      <SingleSelectField
        question={notesGenerator.getQuestion('repairIssue')}
        options={map(
          [
            HotWaterRepairIssue.ColdAtTap,
            HotWaterRepairIssue.Leaking,
            HotWaterRepairIssue.LukeWarm,
            HotWaterRepairIssue.NoHotWater,
            HotWaterRepairIssue.NotLighting,
            HotWaterRepairIssue.NotStayingHot,
            HotWaterRepairIssue.OnALean,
            HotWaterRepairIssue.Relocate,
            HotWaterRepairIssue.Remove,
            HotWaterRepairIssue.RustedAndAnode,
            HotWaterRepairIssue.SmellGas,
            HotWaterRepairIssue.TooLongToGetHot,
            HotWaterRepairIssue.ElementOrThermostat
          ],
          (i) => ({ id: i })
        )}
        answer={repairIssue}
        changeAnswer={(repairIssue) => updateResult({ repairIssue })}
      />

      {repairIssue && (
        <>
          <SingleSelectField
            question={notesGenerator.getQuestion('systemAge')}
            options={[
              {
                id: HotWaterSystemAge.LessThanOrEqualTo8Years,
                label: 'Less than 8 years old'
              },
              {
                id: HotWaterSystemAge.Over8Years,
                label: 'Over 8 years old'
              },
              {
                id: HotWaterSystemAge.Unknown,
                label: `Don't know`
              }
            ]}
            answer={systemAge}
            changeAnswer={(systemAge) => updateResult({ systemAge })}
          />
          {repairIssue === HotWaterRepairIssue.ElementOrThermostat && (
            <>
              <SingleSelectField
                question={notesGenerator.getQuestion('thermostatAge')}
                options={[
                  {
                    id: HotWaterThermostatsAge.LessThan8Years,
                    label: 'Less than 8 years old'
                  },
                  {
                    id: HotWaterThermostatsAge.MoreThan8Years,
                    label: 'More than 8 years old'
                  }
                ]}
                answer={thermostatAge}
                changeAnswer={(thermostatAge) => updateResult({ thermostatAge })}
              />
            </>
          )}
        </>
      )}
    </>
  )
}
