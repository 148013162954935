import { map } from 'lodash-es'
import { BaseActionInput, BaseActionResult, WorkflowActionProps } from '../workflow-model'
import { EuiIcon, EuiKeyPadMenu, EuiKeyPadMenuItem, EuiSpacer } from '@elastic/eui'
import { Callout } from '../../common/callout'
import { decamelise } from '../../common/utils'
import { WorkflowAction } from '../workflow-action'
import { RenewMembershipAction, RenewMembershipActionResult } from './action-renew-membership'
import { ConfirmMembershipAction, ConfirmMembershipActionResult } from './action-confirm-membership'
import { ContactActionResult } from './action-contact'
import { BlackMemberNomineesAction, BlackMemberNomineesActionResult } from './action-black-member-nominees'

export enum MembershipEnquiryType {
  RenewMembership = 'renew-membership',
  ConfirmMembership = 'confirm-membership',
  BlackMemberNominees = 'black-member-nominees'
}

export interface MembershipActionInput extends BaseActionInput {
  contact?: ContactActionResult
}

export interface MembershipActionResult extends BaseActionResult {
  membershipEnquiryType?: MembershipEnquiryType
  renewMembership?: RenewMembershipActionResult
  confirmMembership?: ConfirmMembershipActionResult
  blackMemberNominees?: BlackMemberNomineesActionResult
}

export const labelForMembershipEnquiryType = (membershipEnquiryType: MembershipEnquiryType): string => {
  switch (membershipEnquiryType) {
    case MembershipEnquiryType.ConfirmMembership:
      return 'Confirm membership'
    case MembershipEnquiryType.RenewMembership:
      return 'Renew membership'
    case MembershipEnquiryType.BlackMemberNominees:
      return 'Set black member nominees'
    default:
      return decamelise(membershipEnquiryType)
  }
}

export const iconForMembershipEnquiryType = (membershipEnquiryType: MembershipEnquiryType): string => {
  switch (membershipEnquiryType) {
    case MembershipEnquiryType.RenewMembership:
      return 'plus'
    case MembershipEnquiryType.ConfirmMembership:
      return 'questionInCircle'
    case MembershipEnquiryType.BlackMemberNominees:
      return 'users'
    default:
      return 'empty'
  }
}

export const MembershipAction = (props: WorkflowActionProps<MembershipActionInput, MembershipActionResult>) => {
  const { input, result, onUpdate } = props
  const { contact } = input ?? {}
  const { membershipEnquiryType, renewMembership, confirmMembership, blackMemberNominees } = result ?? {}

  const isJobCreated = !!renewMembership?.createMembership?.jobId

  const membershipEnquiryTypeOptions = map(MembershipEnquiryType, (type) => ({
    id: type,
    isSelected: type === membershipEnquiryType,
    label: labelForMembershipEnquiryType(type),
    icon: iconForMembershipEnquiryType(type)
  }))

  const setMembershipEnquiryType = (membershipEnquiryType: MembershipEnquiryType | undefined) =>
    onUpdate({ ...result, membershipEnquiryType })
  const setRenewMembership = (renewMembership: RenewMembershipActionResult | undefined) =>
    onUpdate({ ...result, renewMembership, actionCompleted: renewMembership?.actionCompleted })
  const setConfirmMembership = (confirmMembership: ConfirmMembershipActionResult | undefined) =>
    onUpdate({ ...result, confirmMembership, actionCompleted: confirmMembership?.actionCompleted })
  const setBlackMemberNominees = (blackMemberNominees: ConfirmMembershipActionResult | undefined) =>
    onUpdate({ ...result, blackMemberNominees, actionCompleted: blackMemberNominees?.actionCompleted })

  return (
    <>
      <WorkflowAction
        title="Membership enquiry type"
        value={membershipEnquiryType && labelForMembershipEnquiryType(membershipEnquiryType)}
        onClickChange={() => setMembershipEnquiryType(undefined)}
        editable={!isJobCreated}
      >
        <Callout type="note" title="Select type of membership enquiry" />
        <EuiSpacer />
        <EuiKeyPadMenu className="fs-keypadmenu">
          {membershipEnquiryTypeOptions.map((option) => (
            <EuiKeyPadMenuItem
              key={option.id}
              id={option.id}
              label={option.label}
              isSelected={option.isSelected}
              onClick={() => setMembershipEnquiryType(option.id)}
            >
              <EuiIcon type={option.icon} size="xl" />
            </EuiKeyPadMenuItem>
          ))}
        </EuiKeyPadMenu>
      </WorkflowAction>

      {membershipEnquiryType === MembershipEnquiryType.ConfirmMembership && (
        <ConfirmMembershipAction input={{ contact }} result={confirmMembership} onUpdate={setConfirmMembership} />
      )}
      {membershipEnquiryType === MembershipEnquiryType.RenewMembership && (
        <RenewMembershipAction
          input={{ contact, isJobCreated }}
          result={renewMembership}
          onUpdate={setRenewMembership}
        />
      )}
      {membershipEnquiryType === MembershipEnquiryType.BlackMemberNominees && (
        <BlackMemberNomineesAction input={{ contact }} result={blackMemberNominees} onUpdate={setBlackMemberNominees} />
      )}
    </>
  )
}
