import { ContactFragment } from '../../api/generated-types'
import { SelectedCustomer } from '../workflow-customer-model'
import { BaseActionInput, BaseActionResult, WorkflowActionProps } from '../workflow-model'
import { ContactActionResult } from './action-contact'
import { ContactCustomerAction } from './action-contact-customer'

export interface CustomerActionInput extends BaseActionInput {
  contact?: ContactActionResult
  allowCreate?: boolean
  allowContinueWithoutCustomer?: boolean
  isNewCustomer?: boolean
}

export interface CustomerActionResult extends BaseActionResult {
  customer?: SelectedCustomer
  contact?: ContactFragment
}

export const CustomerAction = (props: WorkflowActionProps<CustomerActionInput, CustomerActionResult>) => {
  return <ContactCustomerAction {...props} />
}
