import { TopicActionResult } from '../action-topic-model'
import { YesNoValue } from '../common/common-enums'

export interface TopicHotWaterActionResult extends TopicActionResult {
  tankAge?: YesNoValue
  existingHwsType?: HotWaterSystemType
  serviceType?: HotWaterServiceType
  repairIssue?: HotWaterRepairIssue
  hasHotWaterWithBooster?: YesNoValue
  tankTemperature?: Temperature
  systemAge?: HotWaterSystemAge
  tankLocation?: HotWaterTankLocation
  boosterType?: HotWaterBoosterType
  propertyLevels?: PropertyLevels
  extensionLadder?: string
  replacementHwsType?: HotWaterSystemType
  replacementTimeframe?: string
  otherQuotes?: YesNoValue
  thermostatAge?: HotWaterThermostatsAge
}

export enum HotWaterSystemType {
  ElectricTank = 'Electric (tank)',
  Gas = 'Gas (tank)',
  GasInstant = 'Gas (instant)',
  Solar = 'Solar',
  HeatPump = 'Heat Pump',
  Other = 'Electric Instant, under bench, Zip, Billi or Tudor'
}

export enum HotWaterServiceType {
  Repair = 'Repair',
  Replace = 'Replace'
}

export enum Temperature {
  Hot = 'Hot',
  Cold = 'Cold',
  Unknown = `Don't know`
}

export enum HotWaterTankLocation {
  Ground = 'Ground',
  Roof = 'Roof'
}

export enum HotWaterBoosterType {
  Gas = 'Gas',
  Electric = 'Electric'
}

export enum PropertyLevels {
  SingleStorey = 'Single storey',
  DoubleStorey = 'Double storey'
}
export enum HotWaterThermostatsAge {
  MoreThan8Years = 'More than 8 years old',
  LessThan8Years = 'Less than 8 years old'
}
export enum HotWaterSystemAge {
  LessThanOrEqualTo8Years = 'Less than 8 years old',
  Over8Years = 'Over 8 years old',
  Unknown = `Don't know`
}

export enum HotWaterRepairIssue {
  Leaking = 'Leaking unit',
  ValvesOrPipes = 'Valves or pipes',
  Burst = 'Burst',
  Rusted = 'Rusted',
  Bulging = 'Bulging',
  NotStayingHot = 'Not staying hot',
  Remove = 'Remove',
  Relocate = 'Relocate',
  TooLongToGetHot = 'Too long to get hot water',
  OnALean = 'On a lean',
  LukeWarm = 'Luke warm water',
  NoHotWaterAtTap = 'No hot water at tap',
  RustedAndAnode = 'Rusted / Anode check',
  LeanAndAnode = 'Lean / Anode check',
  // Gas specific issues
  NoHotWater = 'No hot water',
  SmellGas = 'Smell gas',
  NotLighting = 'Not lighting',
  ColdAtTap = 'Cold at tap',

  // Solar specific issues
  LeakingPipes = 'Leaking pipes',
  DrainLineLeaking = 'Drain line leaking', // also heat pump
  SolarPanelsLeaking = 'Solar panels leaking',
  TankLeaking = 'Tank leaking',
  PumpNotWorking = 'Pump not working',
  PumpTrippingPower = 'Pump tripping power',

  // Heap pump specific issues
  FanNotWorking = 'Fan not working',
  FanNoise = 'Fan noisy',
  Error = 'Error - fault light on',
  ElementOrThermostat = 'Element/thermostat'
}

export const HEAT_PUMP_HVAC_ISSUES = [
  HotWaterRepairIssue.FanNoise,
  HotWaterRepairIssue.FanNotWorking,
  HotWaterRepairIssue.Error
]
