import { CountryCode, PhoneNumber, parsePhoneNumberFromString } from 'libphonenumber-js'

export const formatPhone = (phone: string, fallbackValue = '-', countryCode = 'AU'): string => {
  const phoneNumber = parsePhone(phone, countryCode)
  if (phoneNumber) {
    return phoneNumber.formatNational()
  } else {
    return phone || fallbackValue
  }
}

export const parsePhone = (phone: string, countryCode = 'AU'): PhoneNumber | null => {
  const parsed = phone ? parsePhoneNumberFromString(phone, countryCode as CountryCode) : null
  if (parsed?.isValid()) {
    return parsed
  } else {
    return null
  }
}

export const isMobile = (phone: string): boolean => parsePhoneNumberFromString(phone ?? '')?.getType() === 'MOBILE'
