import {
  MultipleChoiceSubmitWorkflowStepDetailInput,
  MultipleChoiceWorkflowStepDetailFragment
} from '@fallonsolutions/types'
import { AnswerOption, MultiSelectField } from '../../workflow/question-fields/multi-select-question-field'
import { WorkflowV2StepProps } from './workflow-v2-step-interface'

export interface MultipleChoiceWorkflowV2StepProps extends WorkflowV2StepProps {
  detail: MultipleChoiceWorkflowStepDetailFragment
  result: Partial<MultipleChoiceSubmitWorkflowStepDetailInput>
  onChange: (result: MultipleChoiceSubmitWorkflowStepDetailInput) => void
}

export const MultipleChoiceWorkflowV2Step = ({ detail }: MultipleChoiceWorkflowV2StepProps) => {
  const onChangeAnswer = (answers: string[] | undefined) => {
    console.log('onChangeAnswer', answers)
  }

  const answers: AnswerOption<string>[] = detail.choices.map((choice) => ({
    id: choice.id,
    label: choice.label,
    platformIcon: choice.icon
  }))

  return (
    <>
      <MultiSelectField question={detail.question} options={answers} answer={undefined} changeAnswer={onChangeAnswer} />
    </>
  )
}
