import { EuiButton, EuiFlexGroup, EuiFlexItem, EuiImage } from '@elastic/eui'
import '../static/css/mobile-app-launcher.css'
import fsSvg from '../static/images/fs-logo-square.svg'

export const MobileAppLauncher = () => {
  const mobileAppUrl = 'fs://launch'

  const launchMobileApp = () => {
    window.location.href = mobileAppUrl
  }

  return (
    <div className="eui-showFor--xs" style={{ paddingBottom: '24px', display: 'none' }}>
      <EuiFlexGroup className="mobile-app-launcher" gutterSize="s" alignItems="center" responsive={false}>
        <EuiFlexItem grow={false} style={{ minWidth: '40px' }}>
          <EuiImage
            src={fsSvg}
            alt="FS Mobile App"
            style={{
              width: '40px',
              height: '40px',
              borderRadius: '6px',
              padding: '6px',
              boxShadow: '0 2px 5px rgba(0,0,0,.25)'
            }}
          />
        </EuiFlexItem>
        <EuiFlexItem grow={false} style={{ fontSize: '15px' }}>
          Looking for the mobile app?
        </EuiFlexItem>
        <EuiFlexItem grow={true} />
        <EuiFlexItem grow={false}>
          <EuiButton size="s" fill color="primary" onClick={launchMobileApp}>
            Open app
          </EuiButton>
        </EuiFlexItem>
      </EuiFlexGroup>
    </div>
  )
}
