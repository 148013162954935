import {
  HEAT_PUMP_HVAC_ISSUES,
  HotWaterRepairIssue,
  HotWaterSystemAge,
  HotWaterThermostatsAge,
  Temperature,
  TopicHotWaterActionResult
} from './action-topic-hot-water-model'
import { SingleSelectField } from '../../question-fields/single-select-question-field'
import { NotesGenerator } from '../../helpers/notes-generator'
import { ResultUpdater } from '../../helpers/results-updater'
import { includes, map } from 'lodash-es'

export interface HotWaterRepairHeatPumpProps {
  result?: TopicHotWaterActionResult
  notesGenerator: NotesGenerator<TopicHotWaterActionResult>
  updateResult: ResultUpdater<TopicHotWaterActionResult>
}

export const HotWaterRepairHeatPump = (props: HotWaterRepairHeatPumpProps) => {
  const { result, notesGenerator, updateResult } = props
  const { repairIssue, systemAge, tankTemperature, thermostatAge } = result ?? {}

  const showSystemAgeQuestion =
    (!!tankTemperature && tankTemperature !== Temperature.Hot) || repairIssue === HotWaterRepairIssue.NotStayingHot

  return (
    <>
      <SingleSelectField
        question={notesGenerator.getQuestion('repairIssue')}
        options={map(
          [
            HotWaterRepairIssue.Bulging,
            HotWaterRepairIssue.Burst,
            HotWaterRepairIssue.DrainLineLeaking,
            HotWaterRepairIssue.Error,
            HotWaterRepairIssue.FanNoise,
            HotWaterRepairIssue.FanNotWorking,
            HotWaterRepairIssue.Leaking,
            HotWaterRepairIssue.LukeWarm,
            HotWaterRepairIssue.NoHotWaterAtTap,
            HotWaterRepairIssue.NotStayingHot,
            HotWaterRepairIssue.OnALean,
            HotWaterRepairIssue.Relocate,
            HotWaterRepairIssue.Remove,
            HotWaterRepairIssue.RustedAndAnode,
            HotWaterRepairIssue.TooLongToGetHot,
            HotWaterRepairIssue.ValvesOrPipes,
            HotWaterRepairIssue.ElementOrThermostat
          ],
          (i) => ({ id: i })
        )}
        answer={repairIssue}
        changeAnswer={(repairIssue) => updateResult({ repairIssue })}
      />
      {repairIssue && !includes(HEAT_PUMP_HVAC_ISSUES, repairIssue) && (
        <>
          {repairIssue === HotWaterRepairIssue.ElementOrThermostat && (
            <>
              <SingleSelectField
                question={notesGenerator.getQuestion('thermostatAge')}
                options={[
                  {
                    id: HotWaterThermostatsAge.LessThan8Years,
                    label: 'Less than 8 years old'
                  },
                  {
                    id: HotWaterThermostatsAge.MoreThan8Years,
                    label: 'More than 8 years old'
                  }
                ]}
                answer={thermostatAge}
                changeAnswer={(thermostatAge) => updateResult({ thermostatAge })}
              />
            </>
          )}
          {repairIssue === HotWaterRepairIssue.NoHotWaterAtTap && (
            <>
              <SingleSelectField
                question={notesGenerator.getQuestion('tankTemperature')}
                options={[
                  {
                    id: Temperature.Hot,
                    icon: 'cloudSunny'
                  },
                  {
                    id: Temperature.Cold,
                    icon: 'snowflake'
                  },
                  {
                    id: Temperature.Unknown,
                    label: `Don't know`,
                    icon: 'questionInCircle'
                  }
                ]}
                answer={tankTemperature}
                changeAnswer={(tankTemperature) => updateResult({ tankTemperature })}
              />
            </>
          )}

          {showSystemAgeQuestion && (
            <>
              <SingleSelectField
                question={notesGenerator.getQuestion('systemAge')}
                options={[
                  {
                    id: HotWaterSystemAge.LessThanOrEqualTo8Years,
                    label: 'Less than 8 years old'
                  },
                  {
                    id: HotWaterSystemAge.Over8Years,
                    label: 'Over 8 years old'
                  },
                  {
                    id: HotWaterSystemAge.Unknown,
                    label: `Don't know`
                  }
                ]}
                answer={systemAge}
                changeAnswer={(systemAge) => updateResult({ systemAge })}
              />
            </>
          )}
        </>
      )}
    </>
  )
}
