import { BrowserRouter as Router } from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params'
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5'
import AuthenticationProvider from '../auth/authentication-provider'
import AuthenticationSessionExpiry from '../auth/authentication-session-expiry'
import AuthorizationProvider from '../auth/authorization-provider'
import ThemeSelector from '../theme/theme-selector'
import { AppContextProvider } from './app-context'
import { FeatureToggleProvider } from './feature-toggle-service'
import GraphQLProvider from './graphql-provider'
import { MixpanelProvider } from './mixpanel-provider'
import { NotificationProvider } from './notification-provider'
import PageWrapper from './page-wrapper'
import { SentryProvider } from './sentry'

const App = () => {
  return (
    <ThemeSelector>
      <AppContextProvider>
        <FeatureToggleProvider>
          <Router>
            <QueryParamProvider adapter={ReactRouter5Adapter}>
              <MixpanelProvider>
                <SentryProvider>
                  <NotificationProvider>
                    <AuthenticationProvider>
                      <AuthenticationSessionExpiry>
                        <GraphQLProvider>
                          {/*<ConfigLoaderProvider>*/}
                          <AuthorizationProvider>
                            <PageWrapper />
                          </AuthorizationProvider>
                          {/*</ConfigLoaderProvider>*/}
                        </GraphQLProvider>
                      </AuthenticationSessionExpiry>
                    </AuthenticationProvider>
                  </NotificationProvider>
                </SentryProvider>
              </MixpanelProvider>
            </QueryParamProvider>
          </Router>
        </FeatureToggleProvider>
      </AppContextProvider>
    </ThemeSelector>
  )
}

export default App
