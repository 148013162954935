import { makeHumanReadable } from '@falloncore/util'
import {
  CustomerType,
  CustomerTypeSubmitWorkflowStepDetailInput,
  CustomerTypeWorkflowStepDetailFragment
} from '@fallonsolutions/types'
import { map } from 'lodash-es'
import { AnswerOption, SingleSelectField } from '../../workflow/question-fields/single-select-question-field'
import { WorkflowV2StepProps } from './workflow-v2-step-interface'

export interface CustomerTypeWorkflowV2StepProps extends WorkflowV2StepProps {
  detail: CustomerTypeWorkflowStepDetailFragment
  result: Partial<CustomerTypeSubmitWorkflowStepDetailInput>
  onChange: (result: CustomerTypeSubmitWorkflowStepDetailInput) => void
}

export const CustomerTypeWorkflowV2Step = ({ result, onChange, detail }: CustomerTypeWorkflowV2StepProps) => {
  const answers: AnswerOption<CustomerType>[] = map(CustomerType, (customerType) => ({
    id: customerType,
    label: makeHumanReadable(customerType)
  }))

  const onChangeAnswer = (customerType: CustomerType | undefined) => {
    if (!customerType) {
      return
    }
    onChange({ customerType })
  }

  const answer = result.customerType ?? undefined

  return (
    <>
      <SingleSelectField question={detail.question} options={answers} answer={answer} changeAnswer={onChangeAnswer} />
    </>
  )
}
