import { EuiLoadingSpinner } from '@elastic/eui'
import { Suspense, lazy } from 'react'
import { Route, Switch } from 'react-router-dom'
import Signout from '../auth/signout'

const SignIn = lazy(() => import('../auth/signin'))
const AuthCallback = lazy(() => import('../auth/authentication-callback'))
const SSORoutes = lazy(() => import('../sso/sso-routes'))

const UnauthenticatedRoutes = () => {
  return (
    <Suspense fallback={<EuiLoadingSpinner />}>
      <Switch>
        <Route exact path="/" component={SignIn} />
        <Route path="/auth/callback" component={AuthCallback} />
        <Route path="/launch" component={launchMobileApp} />
        <Route path="/sso" component={SSORoutes} />
        <Route path="/signout" component={Signout} />
        <Route path="*" component={SignIn} />
      </Switch>
    </Suspense>
  )
}

const launchMobileApp = (): null => {
  console.log('launching ios app...')
  window.location.href = 'fs://welcome'
  return null
}

export default UnauthenticatedRoutes
