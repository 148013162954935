import { QuestionDefinitions, getNotesGenerator } from '../../helpers/notes-generator'
import {
  ApplianceRepairTopicActionResult,
  GeneralApplianceIssueType,
  applianceRepairQuestionDefinitions
} from '../appliance-shared/appliance-model'
import { InstallEnum, RepairOrReplaceEnum } from '../appliance-shared/appliance-model'
import { YesNoValue } from '../common/common-enums'

export interface TopicDishwasherActionResult extends ApplianceRepairTopicActionResult {
  serviceType?: RepairOrReplaceEnum | InstallEnum
  fault?: GeneralApplianceIssueType | DrainFaultEnum
  spaceAvailableForAppliance?: YesNoValue
  powerOutletNeeded?: YesNoValue
  replacementApplianceWillFit?: YesNoValue
  installationNote?: string
  otherDrainsBlocked?: YesNoValue
}

const questionDefinitions: QuestionDefinitions<TopicDishwasherActionResult> = {
  ...applianceRepairQuestionDefinitions,
  serviceType: {
    question: 'Is this for a new install, replacement or repair?',
    statement: 'Service:'
  },
  fault: {
    question: 'What is the issue with your dishwasher?',
    statement: 'Issue:'
  },
  brand: {
    question: 'What brand is your dishwasher?',
    statement: 'Brand:'
  },
  applianceAge: {
    question: 'How old is your dishwasher?',
    statement: 'Age:'
  },
  outletWorksWithOtherDevice: {
    question: 'Does the power point work with other devices?',
    statement: 'Power point works with other device:'
  },
  spaceAvailableForAppliance: {
    question: 'Is there space in the kitchen for the appliance?',
    statement: 'Kitchen space available:'
  },
  replacementApplianceWillFit: {
    question: 'Will the dishwasher fit in the same space?',
    statement: 'New dishwasher will fit in same space:'
  },
  powerOutletNeeded: {
    question: 'Do you need a power point installed?',
    statement: 'Powerpoint installation:'
  },
  installationNote: {
    question:
      'Is there any other important information that our technician will need to know to install the dishwasher?',
    statement: 'Install note:'
  }
}

export const dishwasherRepairNotesGenerator = getNotesGenerator(questionDefinitions)

export const enum DrainFaultEnum {
  Drain = 'Drain'
}

export const dishwasherFaultOptions = [
  { id: DrainFaultEnum.Drain, label: 'Will not drain' },
  { id: GeneralApplianceIssueType.PowerSupply, label: 'Power Supply' },
  { id: GeneralApplianceIssueType.OtherFault, label: 'Other fault' }
]

export const BOOK_CARPENTER_FIRST_MESSAGE =
  'Book a Carpenter to make space and set expectation with customer to call up after carpentry work is completed to arrange the Dishwasher install'

export const BOOK_PLUMBER_AND_ELECTRICIAN_SAME_TIME_MESSAGE =
  'Book 2 trades a Plumber and Electrician same day and time (pref 7am), a few days after carpenter - if one was booked'

export const BOOK_PLUMBER_AFTER_CARPENTER_MESSAGE =
  'Book a Plumber to connect a few days after Carpenter - if one was booked'

//custom options maybe included
