import { SingleSelectField } from '../../question-fields/single-select-question-field'
import { NotesGenerator } from '../../helpers/notes-generator'
import { ResultUpdater } from '../../helpers/results-updater'
import { PoolHeatPumpAge, PoolHeatingType, TopicPoolActionResult } from './action-topic-pool-model'
import { FreeTextQuestionField } from '../../question-fields/free-text-question-field'
import { map } from 'lodash-es'

export interface PoolHeatingProps {
  result?: TopicPoolActionResult
  notesGenerator: NotesGenerator<TopicPoolActionResult>
  updateResult: ResultUpdater<TopicPoolActionResult>
}

export const PoolHeating = (props: PoolHeatingProps) => {
  const { result, notesGenerator, updateResult } = props
  const { additionalNotes, heatingType, heatPumpAge } = result ?? {}
  return (
    <>
      <FreeTextQuestionField
        question={notesGenerator.getQuestion('additionalNotes')}
        answer={additionalNotes}
        changeAnswer={(additionalNotes) => updateResult({ additionalNotes })}
        rows={3}
      />

      <SingleSelectField
        question={notesGenerator.getQuestion('heatingType')}
        options={map(PoolHeatingType, (id) => ({ id }))}
        answer={heatingType}
        changeAnswer={(heatingType) => updateResult({ heatingType, heatPumpAge: undefined })}
      />

      {heatingType === PoolHeatingType.HeatPump && (
        <>
          <SingleSelectField
            question={notesGenerator.getQuestion('heatPumpAge')}
            options={map(PoolHeatPumpAge, (id) => ({ id }))}
            answer={heatPumpAge}
            changeAnswer={(heatPumpAge) => updateResult({ heatPumpAge })}
          />
        </>
      )}
    </>
  )
}
