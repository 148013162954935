import { useQuery } from '@apollo/client'
import {
  EuiAvatar,
  EuiButtonEmpty,
  EuiCallOut,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHorizontalRule,
  EuiIcon,
  EuiPanel,
  EuiSpacer,
  EuiTitle
} from '@elastic/eui'
import { filter, isEmpty } from 'lodash-es'
import { useState } from 'react'
import { GetCustomerPropertiesDocument, GetCustomerPropertiesQueryVariables, JobFragment } from '../api/generated-types'
import { useAuthenticated } from '../auth/authenticated-context'
import { formatPhone } from '../common/phone'
import { JobSummaryAppointments } from './job-summary-appointments'

interface JobSummaryCardsProps {
  job: JobFragment
  setJobSummaryModal: (modal: JobSummaryModal) => void
}

enum JobSummaryModal {
  None,
  JobRequirements,
  JobProperty,
  JobBranch
}

// eslint-disable-next-line complexity
export const JobSummaryCards = (props: JobSummaryCardsProps) => {
  const { job, setJobSummaryModal } = props
  const userFragment = useAuthenticated().userFragment
  const isDev = userFragment.permissions?.developerFeatures === true
  const [warnAboutWrongPropertyAssigned, setWarnAboutWrongPropertyAssigned] = useState(false)

  const customerPropertiesQueryVariables: GetCustomerPropertiesQueryVariables = {
    id: job.customer?.id ?? 'missing'
  }
  useQuery(GetCustomerPropertiesDocument, {
    variables: customerPropertiesQueryVariables,
    onCompleted: (data) => {
      const customerProperties = data.getCustomer?.properties ?? []
      const isPropertyOnTheList = !!customerProperties.find((property) => property?.id === job.property?.id)
      if (!isPropertyOnTheList) setWarnAboutWrongPropertyAssigned(true)
    },
    skip: !job.customer?.id
  })

  const contact = job.customer?.mainContact
  const nominatedContact = job.contact

  const property = job.property
  const address = property?.address

  let propertyTitle: string
  if (address) {
    propertyTitle = filter([address.street, address.street2, address.suburb], (s) => !isEmpty(s)).join(', ')
  } else {
    propertyTitle = 'No address'
  }

  return (
    <>
      <EuiFlexGroup alignItems="flexStart">
        <EuiFlexItem style={{ minWidth: '300px', width: '33%', minHeight: '292px' }} grow={false}>
          <div className="small-label">Customer</div>

          {contact && (
            <EuiCard
              icon={<EuiAvatar size="m" name={`${contact?.detail?.fullName ?? 'No name'}`} />}
              title={contact?.detail?.fullName ?? 'No name'}
              description={
                <span>
                  {job.customer?.number}
                  <br />
                  {contact?.detail?.phone ? formatPhone(contact?.detail?.phone) : 'No phone'}
                  <br />
                  <a href={`mailto:${contact?.detail?.email}`}>{contact?.detail?.email}</a>
                </span>
              }
              footer={
                <div>
                  <EuiHorizontalRule />
                  <a href={`/customers/${job.customer?.id}`} target="_blank" rel="noopener noreferrer">
                    View customer {job.customer?.number}
                  </a>
                </div>
              }
              data-test-id="job-summary-customer"
            />
          )}
        </EuiFlexItem>
        {isDev && nominatedContact?.id !== contact?.id && (
          <EuiFlexItem style={{ minWidth: '300px', width: '33%', minHeight: '292px' }} grow={false}>
            <div className="small-label">Nominated contact</div>
            {nominatedContact && (
              <EuiCard
                icon={<EuiAvatar size="m" name={`${nominatedContact?.detail?.fullName ?? 'No name'}`} />}
                title={nominatedContact?.detail?.fullName ?? 'No name'}
                description={
                  <span>
                    {nominatedContact?.detail?.phone ? formatPhone(nominatedContact?.detail?.phone) : 'No phone'}
                    <br />
                    <a href={`mailto:${nominatedContact?.detail?.email}`}>{nominatedContact?.detail?.email}</a>
                  </span>
                }
                data-test-id="job-summary-nominated-contact"
              />
            )}
          </EuiFlexItem>
        )}
        <EuiFlexItem style={{ minWidth: '300px', width: '33%', minHeight: '292px' }} grow={false}>
          <div className="small-label">Property</div>
          <EuiPanel data-test-id="job-summary-property">
            <div className="eui-textCenter" style={{ marginTop: 7, marginBottom: 15 }}>
              <EuiIcon size="xl" type="home" />
            </div>
            <div className="eui-textCenter" style={{ marginBottom: 10 }}>
              <EuiTitle size="s">
                <h2>{propertyTitle}</h2>
              </EuiTitle>
            </div>
            <div className="eui-textCenter">
              {address && (
                <span style={{ lineHeight: '21px' }}>
                  <span>{address.street}</span>
                  <br />
                  {address.street2 && (
                    <div>
                      <span>{address.street2}</span>
                      <br />
                    </div>
                  )}
                  <span>
                    {address.suburb} {address.postcode} {address.state}
                  </span>
                  <EuiSpacer size="s" />
                  <a
                    href={`https://www.google.com/maps/search/?api=1&query=${property?.searchAddress}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View on Google Maps
                  </a>
                </span>
              )}
            </div>
            <EuiHorizontalRule margin="s" />
            <div className="eui-textCenter">
              <EuiButtonEmpty onClick={() => setJobSummaryModal(JobSummaryModal.JobProperty)}>Change</EuiButtonEmpty>
            </div>
            {warnAboutWrongPropertyAssigned && (
              <EuiCallOut color="warning" title="The customer no longer owns this property">
                <p>
                  If you believe this job should be linked to a property that the customer still owns, please use the
                  Change button to make changes.
                </p>
              </EuiCallOut>
            )}
          </EuiPanel>
        </EuiFlexItem>

        <EuiFlexItem style={{ minWidth: '300px', width: '33%' }} grow={false}>
          <div className="small-label">Appointments</div>
          <EuiCard icon={<EuiIcon size="xl" type="clock" />} title={''} description="">
            <JobSummaryAppointments job={job} />
          </EuiCard>
        </EuiFlexItem>
      </EuiFlexGroup>
    </>
  )
}
