import { EuiFieldText, EuiForm, EuiFormRow, EuiSpacer } from '@elastic/eui'
import { Address } from '@fallonsolutions/address'
import { compact, first } from 'lodash-es'
import { useState } from 'react'
import { SuburbSelect, SuburbSelectOption, SuburbSelectOptionDetail } from '../suburbs/suburb-select'

interface ManualAddressFormProps {
  showValidationErrors: boolean
  isStreetValid: boolean
  isSuburbValid: boolean
  isPostcodeValid: boolean
  isStateValid: boolean
  isCountryValid: boolean
  address: Address
  setAddress: (address: Address) => void
}

const defaultCountry = 'AU'

export const ManualAddressForm = (props: ManualAddressFormProps) => {
  const { address, setAddress, showValidationErrors, isStreetValid, isSuburbValid } = props

  const initialSuburbs = address.suburbId
    ? [
        {
          id: address.suburbId,
          label: compact([address.suburb, address.state, address.postcode]).join(' ') //createSuburbLabel(address)
        }
      ]
    : []

  const [selectedSuburbOptions, setSelectedSuburbOptions] = useState<SuburbSelectOption[]>(initialSuburbs)

  const setStreet = (street: string) => setAddress({ ...address, street })
  const setStreet2 = (street2: string) => setAddress({ ...address, street2 })

  const setSuburb = (suburb: SuburbSelectOptionDetail | undefined) => {
    if (suburb) {
      const { postcode, state } = suburb.data
      const country = defaultCountry
      const updatedAddress = { ...address, suburb: suburb.data.suburb, postcode, state, suburbId: suburb.id, country }
      setAddress(updatedAddress)
    } else {
      setAddress({ ...address, suburb: '', postcode: '', state: '', suburbId: '', country: '' })
    }
  }

  const handleSuburbSelect = (suburbs: SuburbSelectOptionDetail[]) => {
    const suburb = first(suburbs)
    if (suburb) {
      setSuburb(suburb)
      setSelectedSuburbOptions([{ id: suburb.id, label: suburb.label, color: suburb.color }])
    } else {
      setSuburb(undefined)
      setSelectedSuburbOptions([])
    }
  }

  return (
    <EuiForm>
      <EuiFormRow label="Address" isInvalid={showValidationErrors && !isStreetValid}>
        <EuiFieldText
          placeholder="Street 1"
          value={address.street || ''}
          onChange={(e) => setStreet(e.target.value)}
          isInvalid={showValidationErrors && !isStreetValid}
          style={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}
        />
      </EuiFormRow>
      <EuiFieldText
        placeholder="Street 2 (optional)"
        value={address.street2 || ''}
        onChange={(e) => setStreet2(e.target.value)}
        style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}
      />
      <EuiSpacer size="s" />

      <EuiFormRow label="Suburb" isInvalid={showValidationErrors && !isSuburbValid}>
        <SuburbSelect
          placeholder="Enter suburb..."
          suburbs={selectedSuburbOptions}
          onChangeSuburbs={handleSuburbSelect}
        />
      </EuiFormRow>
    </EuiForm>
  )
}
