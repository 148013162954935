import { useMutation } from '@apollo/client'
import {
  EuiCallOut,
  EuiFlexItem,
  EuiModal,
  EuiModalBody,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiOverlayMask,
  EuiSpacer
} from '@elastic/eui'
import { useEffect } from 'react'
import { AddCustomerContactDocument } from '../api/generated-types'
import { ContactSearchContainer } from '../contacts/contact-search/contact-search-container'
import { ContactSearchModel } from '../contacts/contact-search/contact-search-model'
import { ContactSearchProvider } from '../contacts/contact-search/contact-search-provider'

export interface AddCustomerContactFormProps {
  customerId: string
  onClose: () => void
}

export const AddCustomerContactForm = ({ customerId, onClose }: AddCustomerContactFormProps) => {
  const [addCustomerContact, { loading, data, error }] = useMutation(AddCustomerContactDocument, {
    refetchQueries: ['GetContact', 'GetCustomer'],
    awaitRefetchQueries: true
  })

  useEffect(() => {
    if (data?.addCustomerContact.contactId) {
      onClose()
    }
  }, [data, onClose])

  const handleSubmit = (contactId: string) => {
    addCustomerContact({
      variables: {
        input: {
          customerId,
          contactId
        }
      }
    })
  }

  const handleContactSearchAction = (action: ContactSearchModel) => {
    console.log('contact search action', action)
    if (action.selectedContact && !loading) {
      handleSubmit(action.selectedContact.id)
    }
  }

  return (
    <>
      <EuiOverlayMask>
        <EuiModal
          onClose={onClose}
          style={{ minHeight: '900px', maxHeight: '900px', maxInlineSize: 'calc(100vw - 300px)' }}
        >
          <EuiModalHeader>
            <EuiModalHeaderTitle>Add existing contact</EuiModalHeaderTitle>
          </EuiModalHeader>
          <EuiModalBody>
            {error && (
              <>
                <EuiCallOut title={`Error adding contact to customer`} color="danger">
                  {error.message}
                </EuiCallOut>
                <EuiSpacer />
              </>
            )}

            <ContactSearchProvider>
              <EuiFlexItem style={{ width: 'calc(100vw - 300px)' }}>
                <div className="eui-yScroll" style={{ maxHeight: '700px' }}>
                  <ContactSearchContainer allowCreate={false} onAction={handleContactSearchAction} hidden={false} />
                </div>
              </EuiFlexItem>
            </ContactSearchProvider>
          </EuiModalBody>
          {/* <EuiModalFooter>
            {!loading && (
              <EuiButtonEmpty onClick={onClose} disabled={loading}>
                Cancel
              </EuiButtonEmpty>
            )}
            <EuiButton fill onClick={() => handleSubmit()} isLoading={loading}>
              Add contact
            </EuiButton>
          </EuiModalFooter> */}
        </EuiModal>
      </EuiOverlayMask>
    </>
  )
}
