import { useQuery } from '@apollo/client'
import { EuiBasicTable, EuiBasicTableColumn, EuiLoadingSpinner } from '@elastic/eui'
import { dateConfig } from '@fallonsolutions/date'
import { DateTime } from 'luxon'
import { ContactVersionFragment, GetContactHistoryDocument } from '../api/generated-types'
import { formatPhone } from '../common/phone'

export interface ContactHistoryProps {
  contactId: string
}

export const ContactHistory = ({ contactId }: ContactHistoryProps) => {
  const { data, loading } = useQuery(GetContactHistoryDocument, {
    variables: { input: { contactId } }
  })

  const contactVersions = data?.getContact?.contact?.history ?? []

  const columns: EuiBasicTableColumn<ContactVersionFragment>[] = [
    {
      field: 'version',
      name: 'Ver.',
      width: '40px'
    },
    {
      field: 'contact.detail.fullName',
      name: 'Name',
      width: '180px'
    },
    {
      field: 'contact.detail.phone',
      name: 'Phone',
      width: '140px',
      render: (phone: string | undefined) => (phone ? formatPhone(phone) : 'No phone')
    },
    {
      field: 'contact.detail.email',
      name: 'Email'
    },
    {
      field: 'created',
      name: 'Date',
      width: '120px',
      align: 'right',
      render: (value: string | undefined) => {
        if (!value) {
          return ''
        }
        const eventDate = DateTime.fromISO(value)
        return eventDate.isValid ? eventDate.toFormat(dateConfig.luxonFormat.dateWithYear) : ''
      }
    }
  ]

  return <>{loading ? <EuiLoadingSpinner /> : <EuiBasicTable items={contactVersions} columns={columns} />}</>
}
