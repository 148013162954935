import { ApolloError, useMutation } from '@apollo/client'
import {
  EuiButton,
  EuiButtonEmpty,
  EuiFieldNumber,
  EuiForm,
  EuiFormRow,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiOverlayMask,
  EuiSelect,
  EuiSelectOption,
  EuiSwitch
} from '@elastic/eui'
import { AcquisitionChannel } from '@fallonsolutions/customer'
import { pick } from 'lodash-es'
import { useState } from 'react'
import {
  Currency,
  Customer,
  CustomerType,
  HoldStatus,
  Money,
  PaymentTerms,
  UpdateCustomerDocument,
  UpdateCustomerInput
} from '../api/generated-types'

export interface CustomerEditProps {
  customer: Customer
  closeModal: () => void
}

const CustomerTypes: EuiSelectOption[] = [
  { value: CustomerType.None, text: 'Not Set' },
  { value: CustomerType.Domestic, text: 'Domestic' },
  { value: CustomerType.Commercial, text: 'Commercial' }
]

const HoldStatuses: EuiSelectOption[] = [
  { value: HoldStatus.None, text: 'None' },
  { value: HoldStatus.OnHold, text: 'On Hold' },
  { value: HoldStatus.CreditHold, text: 'Credit Hold' }
]

const TermsOptions: EuiSelectOption[] = [
  { value: PaymentTerms.CashOnDelivery, text: 'Cash on Delivery' },
  { value: PaymentTerms.EndOfMonth30Days, text: '30 Days End of Month' },
  { value: PaymentTerms.Net7Days, text: 'Net 7 Days' },
  { value: PaymentTerms.Net14Days, text: 'Net 14 Days' },
  { value: PaymentTerms.Net21Days, text: 'Net 21 Days' },
  { value: PaymentTerms.Net30Days, text: 'Net 30 Days' },
  { value: PaymentTerms.Net60Days, text: 'Net 60 Days' },
  { value: PaymentTerms.Net90Days, text: 'Net 90 Days' },
  { value: PaymentTerms.Net120Days, text: 'Net 120 Days' },
  { value: PaymentTerms.Net180Days, text: 'Net 180 Days' }
]

const defaultCurrency = Currency.AUD

export const CustomerEdit = (props: CustomerEditProps) => {
  const { customer, closeModal } = props
  const [active, setActive] = useState<boolean>(customer?.active || false)
  const [type, setType] = useState<CustomerType>(customer?.type || CustomerType.None)
  const [holdStatus, setHoldStatus] = useState<HoldStatus>(customer?.holdStatus || HoldStatus.None)
  const [terms, setTerms] = useState<PaymentTerms>(customer?.terms || PaymentTerms.CashOnDelivery)
  const [acquisitionChannel, setAcquisitionChannel] = useState<string | undefined>(
    customer?.acquisitionChannel || undefined
  )
  const [creditLimit, setCreditLimit] = useState<Money | null>(
    (customer?.creditLimit ? pick(customer.creditLimit, ['amount', 'currency']) : null) as Money
  )
  const [writeOffLimit, setWriteOffLimit] = useState<Money | null>(
    (customer?.writeOffLimit ? pick(customer.writeOffLimit, ['amount', 'currency']) : null) as Money
  )
  const setCreditLimitAmount = (amount: string) => {
    const parsedAmount = parseFloat(amount)
    if (amount) {
      setCreditLimit({ amount: parsedAmount.toString(), currency: creditLimit?.currency || defaultCurrency })
    } else {
      setCreditLimit(null)
    }
  }
  const setWriteOffLimitAmount = (amount: string) => {
    const parsedAmount = parseFloat(amount)
    if (amount) {
      setWriteOffLimit({ amount: parsedAmount.toString(), currency: writeOffLimit?.currency || defaultCurrency })
    } else {
      setWriteOffLimit(null)
    }
  }

  const [showValidationErrors, setShowValidationErrors] = useState(false)
  const [updateCustomerRequestError, setUpdateCustomerRequestError] = useState<ApolloError | undefined>(undefined)
  const [updateCustomer, { loading }] = useMutation(UpdateCustomerDocument, {
    refetchQueries: ['GetCustomerLink']
  })

  const AcquisitionChannelOptions: EuiSelectOption[] = [
    ...(!acquisitionChannel ? [{ value: undefined, text: '' }] : []),
    { value: AcquisitionChannel.Existing, text: 'Existing' },
    { value: AcquisitionChannel.FallonWebsite, text: 'Fallon Website' },
    { value: AcquisitionChannel.Facebook, text: 'Facebook' },
    { value: AcquisitionChannel.Google, text: 'Google' },
    { value: AcquisitionChannel.HomeImpPages, text: 'Home Imp Pages' },
    { value: AcquisitionChannel.MagnetDLCard, text: 'Magnet/DL Card' },
    { value: AcquisitionChannel.NewspaperQuest, text: 'Newspaper (Quest)' },
    { value: AcquisitionChannel.NewspaperStar, text: 'Newspaper (Star)' },
    { value: AcquisitionChannel.Referral, text: 'Referral' },
    { value: AcquisitionChannel.SawVans, text: 'Saw Vans' },
    { value: AcquisitionChannel.YellowPagesBook, text: 'Yellow Pages Book' },
    { value: AcquisitionChannel.YellowPagesOnline, text: 'Yellow Pages Online' },
    { value: AcquisitionChannel.Youtube, text: 'Youtube' },
    { value: AcquisitionChannel.Radio, text: 'Radio' },
    { value: AcquisitionChannel.BillboardBus, text: 'Billboard/Bus' },
    { value: AcquisitionChannel.SMSPromo, text: 'SMS Promo' },
    { value: AcquisitionChannel.Other, text: 'Other' }
  ]

  const typeValid = !!type
  const holdStatusValid = !!holdStatus
  const termsValid = !!terms
  const creditLimitValid = !creditLimit || parseFloat(creditLimit.amount) >= 0
  const writeOffLimitValid = !writeOffLimit || parseFloat(writeOffLimit.amount) >= 0

  const formErrors = [!holdStatusValid, !termsValid, !creditLimitValid, !writeOffLimitValid, !typeValid].filter(
    (invalid) => invalid
  )

  const handleUpdate = async () => {
    setShowValidationErrors(true)
    if (formErrors.length === 0) {
      const input = {
        id: customer.id,
        type,
        active,
        holdStatus,
        terms,
        creditLimit,
        writeOffLimit,
        acquisitionChannel
      } as UpdateCustomerInput
      try {
        await updateCustomer({ variables: { input } })
        closeModal()
      } catch (error: any) {
        setUpdateCustomerRequestError(error)
        console.log(JSON.stringify(error, null, 2))
      }
    }
  }

  const hideValidationErrors = () => {
    setUpdateCustomerRequestError(undefined)
    setShowValidationErrors(false)
  }

  const form = (
    <EuiForm
      isInvalid={showValidationErrors && (formErrors.length > 0 || !!updateCustomerRequestError)}
      onInput={hideValidationErrors}
      error={updateCustomerRequestError?.message}
    >
      <EuiFormRow>
        <EuiSwitch label="Active" checked={active} onChange={(e) => setActive(e.target.checked)} />
      </EuiFormRow>
      <EuiFormRow label="Type" isInvalid={showValidationErrors && !typeValid}>
        <EuiSelect
          options={CustomerTypes}
          value={type?.toString()}
          onChange={(e) => setType(e.target.value as CustomerType)}
          isInvalid={showValidationErrors && !typeValid}
        />
      </EuiFormRow>
      <EuiFormRow label="Hold Status" isInvalid={showValidationErrors && !holdStatusValid}>
        <EuiSelect
          options={HoldStatuses}
          value={holdStatus?.toString()}
          onChange={(e) => setHoldStatus(e.target.value as HoldStatus)}
          isInvalid={showValidationErrors && !holdStatusValid}
        />
      </EuiFormRow>
      <EuiFormRow label="Terms" isInvalid={showValidationErrors && !holdStatusValid}>
        <EuiSelect
          options={TermsOptions}
          value={terms?.toString()}
          onChange={(e) => setTerms(e.target.value as PaymentTerms)}
          isInvalid={showValidationErrors && !termsValid}
        />
      </EuiFormRow>
      <EuiFormRow label="Credit Limit" isInvalid={showValidationErrors && !creditLimitValid}>
        <EuiFieldNumber
          value={creditLimit?.amount || ''}
          placeholder="Not set"
          onChange={(e) => setCreditLimitAmount(e.target.value)}
          isInvalid={showValidationErrors && !creditLimitValid}
        />
      </EuiFormRow>
      <EuiFormRow label="Write-off Limit" isInvalid={showValidationErrors && !writeOffLimitValid}>
        <EuiFieldNumber
          value={writeOffLimit?.amount || ''}
          placeholder="Not set"
          onChange={(e) => setWriteOffLimitAmount(e.target.value)}
          isInvalid={showValidationErrors && !writeOffLimitValid}
        />
      </EuiFormRow>
      <EuiFormRow label="Acquisition Channel">
        <EuiSelect
          options={AcquisitionChannelOptions}
          value={acquisitionChannel?.toString()}
          onChange={(e) => setAcquisitionChannel(e.target.value)}
        />
      </EuiFormRow>
    </EuiForm>
  )

  return (
    <EuiOverlayMask>
      <EuiModal onClose={closeModal}>
        <EuiModalHeader>
          <EuiModalHeaderTitle>Edit Customer details</EuiModalHeaderTitle>
        </EuiModalHeader>
        <EuiModalBody>
          <div>{form}</div>
        </EuiModalBody>
        <EuiModalFooter>
          <EuiButtonEmpty onClick={closeModal} isDisabled={loading}>
            Cancel
          </EuiButtonEmpty>
          <EuiButton onClick={handleUpdate} isLoading={loading} fill>
            Done
          </EuiButton>
        </EuiModalFooter>
      </EuiModal>
    </EuiOverlayMask>
  )
}
