import { EuiIcon } from '@elastic/eui'
import { JobStatusType } from '../api/generated-types'
import { decamelise } from '../common/utils'
import '../static/css/job-status-badge.css'

export interface JobStatusBadgeProps {
  status?: JobStatusType
  editable?: boolean | undefined
  tooltip?: string
}

export const JobStatusBadge = (props: JobStatusBadgeProps) => {
  const { status, tooltip } = props
  const editable = props.editable ?? false
  const label = decamelise(status)

  return (
    <span
      className={`job-status-badge job-status-badge--${status?.toLowerCase() ?? JobStatusType.NotStarted.toLowerCase()} ${editable ? 'job-status-badge--editable' : ''}`}
      style={editable ? { paddingLeft: '6px', paddingRight: '6px' } : {}}
    >
      {label}
      {editable && <EuiIcon size="s" type="arrowDown" style={{ marginLeft: '3px' }} />}
      {!!tooltip && <EuiIcon size="s" type="iInCircle" style={{ marginLeft: '3px' }} title={tooltip} />}
    </span>
  )
}
