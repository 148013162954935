import { MembershipStatusType } from '../api/generated-types'
import '../static/css/membership-status.css'

export interface MembershipStatusBadgeProps {
  status: MembershipStatusType
  title?: string
  size?: 'xs' | 'm'
}

export const MembershipStatusBadge = ({ status, title, ...props }: MembershipStatusBadgeProps) => {
  const size = props.size ?? 'm'
  return (
    <span
      className={`membership-status-badge membership-status-badge--${status.toLowerCase()} membership-status-badge--${size}`}
      title={title}
    >
      {size === 'm' ? status : ''}
    </span>
  )
}
