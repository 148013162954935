import { CustomerType } from '../../../api/generated-types'
import { QuestionDefinitions, getNotesGenerator } from '../../helpers/notes-generator'
import { TopicActionResult } from '../action-topic-model'
import { YesNoValue } from '../common/common-enums'

export interface TopicPoolActionResult extends TopicActionResult {
  issue?: PoolIssue
  pumpIssue?: PoolPumpIssue
  poolType?: CustomerType
  heatPumpAge?: PoolHeatPumpAge
  powerpointTripping?: YesNoValue
  heatingIssue?: string
  heatingType?: PoolHeatingType
  leakLocation?: PoolLeakLocation
  chlorinatorIssue?: PoolChlorinatorIssue
  filterIssue?: PoolFilterIssue
  additionalNotes?: string
}

export enum PoolIssue {
  ChemicalBalancing = 'Chemical balancing',
  Chlorinators = 'Chlorinators',
  Cleaning = 'Cleaning',
  Filters = 'Filters',
  Heating = 'Heating',
  Leaks = 'Leaks',
  NewConnections = 'New connections',
  PoolBoys = 'Pool boys',
  Pumps = 'Pumps',
  WaterQuality = 'Water quality',
  WaterTesting = 'Water testing'
}

export enum PoolPumpIssue {
  Blocked = 'Blocked',
  Leaking = 'Leaking',
  TrippingPower = 'Tripping power'
}

export enum PoolHeatingType {
  Solar = 'Solar',
  HeatPump = 'Heat pump'
}

export enum PoolHeatPumpAge {
  Years5OrLess = '5 years or younger',
  OlderThan5Years = 'Older than 5 years'
}

export enum PoolLeakLocation {
  PoolBody = 'Pool body',
  Pipes = 'Pipes',
  WaterLevelDropping = 'Water level dropping',
  WaterPooling = 'Water pooling',
  Unknown = `Don't know`
}

export enum PoolChlorinatorIssue {
  NotTurningOn = 'Not turning on',
  TrippingPower = 'Tripping power',
  NotWorking = 'Not working',
  Broken = 'Broken',
  Leaking = 'Leaking'
}

export enum PoolFilterIssue {
  Leaking = 'Leaking',
  Blocked = 'Blocked'
}

export const POOL_OUT_OF_SCOPE_ISSUES = [
  PoolIssue.ChemicalBalancing,
  PoolIssue.Cleaning,
  PoolIssue.NewConnections,
  PoolIssue.PoolBoys,
  PoolIssue.WaterQuality,
  PoolIssue.WaterTesting
]

const poolQuestionDefinitions: QuestionDefinitions<TopicPoolActionResult> = {
  issue: {
    question: 'What is the issue with your pool?',
    statement: 'Issue:'
  },
  pumpIssue: {
    question: 'Is it blocked, leaking or tripping power?',
    statement: 'Pump issue:'
  },
  poolType: {
    question: 'Is this for a domestic or commercial pool?',
    statement: 'Domestic/commercial:'
  },
  powerpointTripping: {
    question: 'Have you tried plugging something else into the power point and does it still trip?',
    statement: 'Powerpoint tripping:'
  },
  filterIssue: {
    question: 'Is the filter leaking or blocked?',
    statement: 'Filter issue:'
  },
  additionalNotes: {
    question: 'What exactly is the issue?',
    statement: 'Additional notes:'
  },
  leakLocation: {
    question: 'Where is it leaking?',
    statement: 'Leak location:'
  }
}

export const poolNotesGenerator = getNotesGenerator(poolQuestionDefinitions)
