import { DateTime } from 'luxon'
import { useEffect, useState } from 'react'
import { NewSessionState, SessionLoaderContext, useSession } from './session-context'
import { SessionExpired } from './session-expired'

interface AuthenticationSessionExpiryProps {
  children: React.ReactNode
}

const AuthenticationSessionExpiry = (props: AuthenticationSessionExpiryProps) => {
  const session: SessionLoaderContext = useSession()
  const { user } = session.data as NewSessionState

  const sessionExpiryEpoch = user?.expiryEpoch
  const [sessionExpired, setSessionExpired] = useState<boolean>(false)

  useEffect(() => {
    console.log('authn-session-expiry: start')
    if (user && !sessionExpired && sessionExpiryEpoch) {
      console.log('authn session expiry: setup', user.signedIn, sessionExpiryEpoch, user.id)
      const tokenExpiryInSeconds = sessionExpiryEpoch - DateTime.now().toSeconds()
      const timeoutTriggerInSeconds = Math.floor(Math.max(tokenExpiryInSeconds, 0))
      console.log(
        `authn session expiry: setting setSessionExpired to be triggered in ${timeoutTriggerInSeconds} seconds`
      )
      const timeoutId = setTimeout(() => {
        console.log('authn session expiry: setSessionExpired...')
        setSessionExpired(true)
      }, timeoutTriggerInSeconds * 1000)
      // cleanup function
      return () => {
        clearTimeout(timeoutId)
      }
    }
  }, [user, sessionExpiryEpoch, sessionExpired])

  const handleRefresh = async () => {
    console.log('authn-session-expiry: handleRefresh')
    setSessionExpired(false)
    await session.data?.refresh()
    console.log('authn-session-expiry: handleRefresh done')
  }

  //handle auth callback and session establishment
  return <>{sessionExpired ? <SessionExpired onRefresh={handleRefresh} /> : props.children}</>
}

export default AuthenticationSessionExpiry
