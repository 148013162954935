import { AUTH_TYPE, AuthOptions, createAuthLink } from 'aws-appsync-auth-link'

export const getAuthOptions = (jwtToken: string): AuthOptions => {
  return {
    type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
    jwtToken
  }
}

export interface CreateAppSyncAuthLinkProps {
  idToken: string
  region: string
  url: string
}

export const createAppSyncAuthLink = (props: CreateAppSyncAuthLinkProps) => {
  const { idToken, region, url } = props
  const auth = getAuthOptions(idToken)
  const authLink = createAuthLink({ url: `https://${url}`, region, auth })
  return authLink
}
