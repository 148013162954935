import { QuestionDefinitions } from '../../helpers/notes-generator'
import { TopicActionResult } from '../action-topic-model'

export interface TopicEnergyAuditResult extends TopicActionResult {
  noteWorthyAudits?: string
}

export const energyAuditQuestionDefinitions: QuestionDefinitions<TopicEnergyAuditResult> = {
  noteWorthyAudits: {
    question: 'Was there anything noteworthy to jot down for the attending technician ?',
    statement: 'Note worthy audits:'
  }
}
