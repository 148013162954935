import { EuiButton, EuiSpacer } from '@elastic/eui'
import { JobTopic } from '@fallonsolutions/types'
import { EnquiryAbortReason } from '../../../api/generated-types'
import { Callout } from '../../../common/callout'
import { QuestionDefinitions, getNotesGenerator } from '../../helpers/notes-generator'
import { getRequirementsGenerator } from '../../helpers/requirements-helper'
import { getResultUpdater } from '../../helpers/results-updater'
import { useWorkflow } from '../../helpers/workflow-provider'
import { WorkflowActionProps } from '../../workflow-model'
import { TopicActionInput } from '../action-topic-model'
import { TopicWorkflowAction } from '../action-topic-view'
import { TopicQualification } from '../common/common-outcome'
import { DrainServiceType, TopicPlumbingDrainActionResult } from './action-topic-plumbing-drain-model'
import { outcomeGenerator } from './action-topic-plumbing-drain-requirements'
import { DrainSewer } from './action-topic-plumbing-drain-sewer'
import { DrainStormwater } from './action-topic-plumbing-drain-stormwater'
import { DrainServiceSelectType } from './action-topic-plumbing-drains-select-type'

const questionDefinitions: QuestionDefinitions<TopicPlumbingDrainActionResult> = {
  serviceType: {
    question: 'Is this for a stormwater or sewer?',
    statement: 'Issue type:'
  },
  itemIssue: {
    question: 'Where is it broken?',
    statement: 'Location:'
  },
  stormwaterConnect: {
    question: 'Where do you want to connect the stormwater to?',
    statement: 'Stormwater connection location:'
  },
  stormwaterSymptom: {
    question: 'So you want to improve your stormwater drain solutions, are you experiencing?',
    statement: 'Stormwater symptom(s):'
  },
  drainIssue: {
    question: 'What is the issue with the system?',
    statement: 'Issue:'
  },
  drainCameraEligibility: {
    question:
      'Our camera can only fit in drains where the pipe diameter is between 90mm and 225 mm - does your pipe fit those dimensions?',
    statement: 'Pipe fits camera dimensions:'
  },
  newInstallOrReplace: {
    question: 'Is this for a new system installation or replacement?',
    statement: 'New system or replacement:'
  },
  houseBuild: {
    question: 'Is this part of a new house build?',
    statement: 'Part of new house build:'
  },
  stormwaterBlockedPossibleCause: {
    question: 'What do you think is the possible cause?',
    statement: 'Possible cause of issue:'
  },
  blockedPrior: {
    question: 'Has it been blocked before?',
    statement: 'Has been blocked before:'
  },
  causeNote: {
    question: 'Do you know what caused the issue?',
    statement: 'Known cause of issue:'
  },
  otherIssueDescription: {
    question: 'What is the issue?',
    statement: 'Issue:'
  },
  haveCouncilPlans: {
    question: 'Do you have council plans?',
    statement: 'Have council plans:'
  },
  itemAffected: {
    question: 'What is blocked or overflowing?',
    statement: 'Blocked or overflowing issue:'
  },
  smellySewerAffected: {
    question: 'What is the cause of the smelly drain?',
    statement: 'Caused of smelly drain: '
  },
  slowDrainAffected: {
    question: 'What is the cause of the slow drain?',
    statement: 'Caused of slow drain: '
  },
  anotherToilet: {
    question: 'Is there another toilet on the property?',
    statement: 'Another toilet on property:'
  },
  dsrConfirmInScope: {
    question: 'Check with DSR - Is this job in scope?',
    excludeFromNotes: true
  },
  issueDescription: {
    question: 'What is the issue?',
    statement: 'Issue description:'
  },
  pumpedOutRecently: {
    question: 'Has it been pumped out recently?',
    statement: 'Has been recently pumped:'
  },
  overflowOrLeakedPrior: {
    question: 'Has it happened before?',
    statement: 'Has happened before:'
  },
  otherBlockages: {
    question: 'Is there any other blockages in the property?',
    statement: 'Other blockages in property:'
  },
  otherIssueIsInScope: {
    question: 'Is this job in scope?'
  }
}
const notesGenerator = getNotesGenerator(questionDefinitions)
export type TopicPlumbingDrainInput = TopicActionInput & {
  drainClearSpecial?: boolean
  handleChangeJobTopic?: (jobTopic: JobTopic) => void
}
export const TopicPlumbingDrainAction = (
  props: WorkflowActionProps<TopicPlumbingDrainInput, TopicPlumbingDrainActionResult>
) => {
  const { result, input, onUpdate } = props
  const { serviceType } = result ?? {}
  const { drainClearSpecial } = input ?? {}
  const workflowContext = useWorkflow()
  const topicOutcome = outcomeGenerator(!!drainClearSpecial)(result)
  const { requirements } = topicOutcome
  const requirementsGenerator = getRequirementsGenerator(requirements)
  const requirementCallouts = requirementsGenerator.getCallouts()
  const isOutOfScope = topicOutcome.qualification === TopicQualification.OutOfScope
  const canComplete = topicOutcome.qualification === TopicQualification.InScope
  const newTopic = topicOutcome.newTopic
  const onNext = () =>
    onUpdate({
      ...result,
      actionCompleted: true,
      ...topicOutcome,
      workRequiredNotes: generatedNotes,
      requirements
    })

  const generatedNotes = notesGenerator.generateNotes(result ?? {})
  const updateResult = getResultUpdater<TopicPlumbingDrainActionResult>(result ?? {}, onUpdate).updateResult

  return (
    <TopicWorkflowAction input={input} onUpdate={onUpdate} result={result}>
      <DrainServiceSelectType result={result} updateResult={updateResult} notesGenerator={notesGenerator} />

      {serviceType === DrainServiceType.Stormwater && (
        <DrainStormwater result={result} updateResult={updateResult} notesGenerator={notesGenerator} input={input} />
      )}
      {serviceType === DrainServiceType.Sewer && (
        <DrainSewer
          result={result}
          updateResult={updateResult}
          notesGenerator={notesGenerator}
          requirements={requirements}
          input={input}
        />
      )}

      <EuiSpacer />
      {newTopic && (
        <EuiButton onClick={() => workflowContext.changeJobTopic(newTopic.reference)}>{newTopic.buttonLabel}</EuiButton>
      )}
      {isOutOfScope && !newTopic && (
        <>
          <Callout type="script">
            {topicOutcome.qualificationMessage
              ? topicOutcome.qualificationMessage
              : 'Unfortunately this type of work is out of scope for us.'}
          </Callout>
          <EuiSpacer />
          <EuiButton
            color="warning"
            onClick={() =>
              workflowContext.abort({
                abortReason: EnquiryAbortReason.CustomerNeedsAreOutOfService,
                notes: `Customer was enquiring about: ${topicOutcome.qualificationMessage}`
              })
            }
          >
            Offer a voucher and end call
          </EuiButton>
        </>
      )}

      {!isOutOfScope && !newTopic && (
        <>
          {/* {!!priority && isInScope && (
            <>
              <Callout type="script">
                <EuiText>the priority for this job is {priority}</EuiText>
              </Callout>
            </>
          )} */}
          {!!canComplete && (
            <>
              {requirementCallouts}
              <EuiSpacer />
            </>
          )}
          <EuiButton disabled={!canComplete} onClick={() => onNext()}>
            Next
          </EuiButton>
        </>
      )}
    </TopicWorkflowAction>
  )
}
