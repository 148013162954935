import {
  EuiAccordion,
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
  EuiRadioGroup,
  EuiRadioGroupOption,
  EuiSpacer,
  EuiText
} from '@elastic/eui'
import { CustomerContactContactFragment } from '../api/generated-types'
import '../static/css/action-create-job-contact-nomination.css'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import { ContactCustomerRoleBadge } from '../customers/customer-contact-role-badge'
import { Callout } from '../common/callout'

type ContactAlias = CustomerContactContactFragment

export interface PrimaryJobContactProps {
  contacts: ContactAlias[]
  selectedContactId?: string
  onChange: (contactId?: string) => void
  onCreateNewContact: () => void
}
export const PrimaryJobContact = (props: PrimaryJobContactProps) => {
  const { contacts, selectedContactId, onChange, onCreateNewContact } = props

  const radioGroupOptions: EuiRadioGroupOption[] = contacts.map((contact) => ({
    id: contact.contactId,
    label: ContactLabel(contact),
    labelProps: { style: { display: 'inline', width: '100%' } }
  }))

  const selectedContact = contacts.find((contact) => contact.contactId === selectedContactId)
  const currentContactName = selectedContact?.contact?.detail?.fullName
    ? selectedContact?.contact?.detail?.fullName
    : selectedContact?.contact?.detail?.firstName
    ? `${selectedContact?.contact?.detail?.firstName} ${selectedContact?.contact?.detail?.lastName ?? ''}`
    : 'No customer selected'

  const phoneNumber = selectedContact?.contact?.detail?.phone ?? selectedContact?.contact?.detail?.alternatePhone
  const customerPhoneNumber = phoneNumber
    ? parsePhoneNumberFromString(phoneNumber, { defaultCountry: 'AU' })?.formatNational()
    : ''
  const handleCreateContact = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault()
    onCreateNewContact()
  }
  const accordingButtonLabel = () => {
    if (selectedContact) {
      const detail = selectedContact.contact.detail
      const name = detail?.fullName ?? `${detail?.firstName} ${detail?.lastName}`
      const phone = detail?.phone
        ? parsePhoneNumberFromString(detail.phone, { defaultCountry: 'AU' })?.formatNational()
        : ''
      return (
        <div>
          <EuiFlexGroup direction="row" alignItems="center" justifyContent="spaceBetween" style={{ width: '100%' }}>
            <EuiFlexItem grow={true}>
              <EuiFlexGroup direction="row" gutterSize="l" alignItems="center">
                <EuiFlexItem grow={false}>
                  <EuiIcon type="user" size="l" />
                </EuiFlexItem>
                <EuiFlexItem>
                  <EuiText grow={true} textAlign="left" style={{ whiteSpace: 'nowrap', fontWeight: 'bold' }}>
                    <p>{name}</p>
                  </EuiText>
                </EuiFlexItem>
                {phone && (
                  <EuiFlexItem>
                    <EuiText grow={true} style={{ whiteSpace: 'nowrap' }}>
                      {phone}
                    </EuiText>
                  </EuiFlexItem>
                )}
                {detail?.email && (
                  <EuiFlexItem grow={true}>
                    <EuiText>{detail.email}</EuiText>
                  </EuiFlexItem>
                )}
              </EuiFlexGroup>
            </EuiFlexItem>

            <EuiFlexItem grow={false}>
              <EuiButton size="s" onClick={handleCreateContact}>
                Create new contact
              </EuiButton>
            </EuiFlexItem>
          </EuiFlexGroup>
        </div>
      )
    }
    return 'Select a contact'
  }

  return (
    <>
      <Callout title="Nominated job contact" type="script">
        So the contact our technician will be contacting is {currentContactName} on {customerPhoneNumber} correct?
      </Callout>
      <EuiAccordion
        id="accordion1"
        buttonContent={accordingButtonLabel()}
        arrowDisplay="right"
        className="customer-primary-contact"
      >
        <EuiSpacer size="m" />
        <EuiRadioGroup
          options={radioGroupOptions}
          idSelected={selectedContactId}
          onChange={(event) => onChange(event)}
        />
      </EuiAccordion>
    </>
  )
}

const ContactLabel = (contact: ContactAlias) => {
  const detail = contact.contact.detail
  const phone = detail?.phone
    ? parsePhoneNumberFromString(detail.phone, { defaultCountry: 'AU' })?.formatNational()
    : ''
  return (
    <>
      <EuiText style={{ display: 'inline' }}>
        <p style={{ display: 'inline', fontWeight: 'bold', paddingRight: '10px' }}>
          {detail?.firstName} {detail?.lastName}
        </p>

        {detail?.email && <p style={{ display: 'inline', paddingRight: '10px' }}>{detail?.email}</p>}
        {phone && <p style={{ display: 'inline' }}>{phone}</p>}
      </EuiText>
      {contact.roles && contact.roles.length > 0 && (
        <>
          {contact.roles.map((role) => {
            return <ContactCustomerRoleBadge role={role} />
          })}
        </>
      )}
    </>
  )
}
