import { ApolloError, useMutation } from '@apollo/client'
import {
  EuiButton,
  EuiButtonEmpty,
  EuiForm,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiOverlayMask
} from '@elastic/eui'
import { omit } from 'lodash-es'
import { useState } from 'react'
import {
  AddressInput,
  CreatePropertyDocument,
  CreatePropertyInput,
  PropertyFragment,
  RemovePropertyFromCustomerDocument,
  RemovePropertyFromCustomerInput
} from '../api/generated-types'
import { PropertyForm } from './property-form'

export interface PropertyChangeProps {
  customerId: string
  property: PropertyFragment
  closeModal: () => void
}

export const PropertyChange = (props: PropertyChangeProps) => {
  const { closeModal, customerId } = props

  const [showValidationErrors, setShowValidationErrors] = useState(false)
  const [property, setProperty] = useState<PropertyFragment>(props.property)
  const [propertyValid, setPropertyValid] = useState(true)
  const [updatePropertyRequestError, setUpdatePropertyRequestError] = useState<ApolloError | undefined>(undefined)

  const [removePropertyFromCustomer, { loading: removingProperty }] = useMutation(RemovePropertyFromCustomerDocument)

  const createPropertyInput: CreatePropertyInput = {
    customer: customerId,
    address: omit(property.address, ['__typename']) as AddressInput
  }
  const [createProperty, { loading: addingProperty }] = useMutation(CreatePropertyDocument)

  const handleUpdate = async () => {
    setShowValidationErrors(true)
    if (propertyValid && property.id) {
      try {
        const createPropertyResult = await createProperty({ variables: { input: createPropertyInput } })
        const newPropertyId = createPropertyResult.data?.createProperty.customerLocation?.property?.id
        if (newPropertyId && newPropertyId !== property.id) {
          await removePropertyFromCustomer({
            variables: {
              input: {
                customer: customerId,
                property: property?.id,
                fullAddress: property?.formattedAddress
              } as RemovePropertyFromCustomerInput
            }
          })
        }
        closeModal()
      } catch (error: any) {
        setUpdatePropertyRequestError(error)
        console.log(JSON.stringify(error, null, 2))
      }
    }
  }

  const hideValidationErrors = () => {
    setShowValidationErrors(false)
    setUpdatePropertyRequestError(undefined)
  }

  const loading = addingProperty || removingProperty

  return (
    <EuiOverlayMask>
      <EuiModal onClose={closeModal}>
        <EuiModalHeader>
          <EuiModalHeaderTitle>Change Property</EuiModalHeaderTitle>
        </EuiModalHeader>
        <EuiModalBody>
          <EuiForm
            isInvalid={showValidationErrors && (!propertyValid || !!updatePropertyRequestError)}
            onInput={hideValidationErrors}
            error={updatePropertyRequestError?.message}
          >
            <PropertyForm
              canAddressBeProvided={true}
              property={property}
              onChangeProperty={setProperty}
              showValidationErrors={showValidationErrors}
              onValidationChange={setPropertyValid}
            />
          </EuiForm>
        </EuiModalBody>
        <EuiModalFooter>
          <EuiButtonEmpty onClick={closeModal} isDisabled={loading}>
            Cancel
          </EuiButtonEmpty>
          <EuiButton onClick={handleUpdate} isLoading={loading} fill>
            Done
          </EuiButton>
        </EuiModalFooter>
      </EuiModal>
    </EuiOverlayMask>
  )
}
