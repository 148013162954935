import { HotWaterServiceType, HotWaterSystemType, TopicHotWaterActionResult } from './action-topic-hot-water-model'
import { SingleSelectField } from '../../question-fields/single-select-question-field'
import { NotesGenerator } from '../../helpers/notes-generator'
import { ResultUpdater } from '../../helpers/results-updater'

export interface HotWaterSelectTypeProps {
  result?: TopicHotWaterActionResult
  notesGenerator: NotesGenerator<TopicHotWaterActionResult>
  updateResult: ResultUpdater<TopicHotWaterActionResult>
}

export const HotWaterSelectType = (props: HotWaterSelectTypeProps) => {
  const { result, notesGenerator, updateResult } = props
  const { existingHwsType, serviceType } = result ?? {}
  return (
    <>
      <SingleSelectField
        question={notesGenerator.getQuestion('existingHwsType')}
        options={[
          {
            id: HotWaterSystemType.ElectricTank,
            icon: 'bolt',
            label: 'Electric (tank)'
          },
          {
            id: HotWaterSystemType.Gas,
            icon: 'visGauge',
            label: 'Gas (tank)'
          },
          {
            id: HotWaterSystemType.GasInstant,
            icon: 'visGauge',
            label: 'Gas (instant)'
          },
          {
            id: HotWaterSystemType.Solar,
            icon: 'cloudSunny'
          },
          {
            id: HotWaterSystemType.HeatPump,
            icon: 'temperature'
          },
          {
            id: HotWaterSystemType.Other,
            icon: 'apps',
            label: 'Electric (instant), under bench, Zip, Billi or Tudor'
          }
        ]}
        answer={existingHwsType}
        changeAnswer={(existingHwsType) =>
          updateResult({
            existingHwsType,
            repairIssue: undefined,
            hasHotWaterWithBooster: undefined,
            tankTemperature: undefined,
            systemAge: undefined,
            tankLocation: undefined,
            boosterType: undefined,
            propertyLevels: undefined,
            extensionLadder: undefined,
            replacementHwsType: undefined,
            replacementTimeframe: undefined,
            otherQuotes: undefined
          })
        }
      />

      {existingHwsType && (
        <SingleSelectField
          question={notesGenerator.getQuestion('serviceType')}
          options={[
            {
              id: HotWaterServiceType.Repair,
              icon: 'wrench'
            },
            {
              id: HotWaterServiceType.Replace
            }
          ]}
          answer={serviceType}
          changeAnswer={(serviceType) => updateResult({ serviceType })}
        />
      )}
    </>
  )
}
