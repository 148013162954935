import { EuiButton, EuiSpacer } from '@elastic/eui'
import { JobTopicType } from '@fallonsolutions/types'
import { includes, map } from 'lodash-es'
import { EnquiryAbortReason } from '../../../api/generated-types'
import { Callout } from '../../../common/callout'
import { getResultUpdater } from '../../helpers/results-updater'
import { useWorkflow } from '../../helpers/workflow-provider'
import { SingleSelectField } from '../../question-fields/single-select-question-field'
import { WorkflowActionProps } from '../../workflow-model'
import { TopicActionInput } from '../action-topic-model'
import { TopicWorkflowAction } from '../action-topic-view'
import { ShowerIssueType, TopicShowerActionResult, showerNotesGenerator } from './action-topic-shower-model'

export const TopicShowerAction = (props: WorkflowActionProps<TopicActionInput, TopicShowerActionResult>) => {
  const workflowContext = useWorkflow()

  const { result, input, onUpdate } = props
  const { issueType } = result ?? {}

  const notesGenerator = showerNotesGenerator

  const updateResult = getResultUpdater(result ?? {}, onUpdate).updateResult

  const isTapRepairIssue = includes([ShowerIssueType.ShowerTaps, ShowerIssueType.ReplaceShowerHead], issueType)
  const isFlexiHoseRepairIssue = includes([ShowerIssueType.FlexiHoseForDetachableShowerHead], issueType)
  const isWaterPressureIssue = includes([ShowerIssueType.WaterPressureIssue], issueType)

  const isOutOfScope = !!issueType && includes([ShowerIssueType.Other], issueType)

  return (
    <TopicWorkflowAction input={input} onUpdate={onUpdate} result={result}>
      <SingleSelectField
        question={notesGenerator.getQuestion('issueType')}
        options={map(ShowerIssueType, (id) => ({ id }))}
        answer={issueType}
        changeAnswer={(issueType) => updateResult({ issueType })}
      />

      {isOutOfScope && (
        <>
          <Callout type="script">Unfortunately this type of work is out of scope for us.</Callout>
          <EuiSpacer />
          <EuiButton
            color="warning"
            onClick={() =>
              workflowContext.abort({
                abortReason: EnquiryAbortReason.CustomerNeedsAreOutOfService,
                notes: `Customer was enquiring about Shower`
              })
            }
          >
            Offer a voucher and end call
          </EuiButton>
        </>
      )}

      {isTapRepairIssue && (
        <>
          <Callout type="note">Continue by following tap topic workflow.</Callout>
          <EuiSpacer size="m" />
          <EuiButton onClick={() => workflowContext.changeJobTopic(JobTopicType.TapRepair)}>
            Switch topic to Tap repair
          </EuiButton>
        </>
      )}

      {isFlexiHoseRepairIssue && (
        <>
          <Callout type="note">Continue by following flexi hose topic workflow.</Callout>
          <EuiSpacer size="m" />
          <EuiButton onClick={() => workflowContext.changeJobTopic(JobTopicType.FlexiHoseRepair)}>
            Switch topic to Flexi hose repair
          </EuiButton>
        </>
      )}

      {isWaterPressureIssue && (
        <>
          <Callout type="note">Continue by following water pressure topic workflow.</Callout>
          <EuiSpacer size="m" />
          <EuiButton onClick={() => workflowContext.changeJobTopic(JobTopicType.WaterPressure)}>
            Switch topic to Water pressure
          </EuiButton>
        </>
      )}
    </TopicWorkflowAction>
  )
}
