import { useMutation } from '@apollo/client'
import { contactFragmentToContactInput } from '@fallonsolutions/contact'
import { useParams } from 'react-router-dom'
import {
  ContactInput,
  GetInteractionMessageDocument,
  GetInteractionMessageQuery,
  SetInteractionMessageContactDocument
} from '../../api/generated-types'
import { DetailContainer, DetailContainerRouteParams } from '../../common/detail-container'
import { parsePhone } from '../../common/phone'
import { OnChangeContactProps } from '../../workflow/workflow-interaction-message-model'
import { InteractionMessageDetailView } from './interaction-message-detail-view'

interface InteractionMessageContainerRouteParams extends DetailContainerRouteParams {
  tab?: string
}

export const InteractionMessageDetailContainer = () => {
  const { id, tab } = useParams<InteractionMessageContainerRouteParams>()

  const [setInteractionMessageContact, { loading }] = useMutation(SetInteractionMessageContactDocument, {
    refetchQueries: ['GetInteractionMessage'],
    awaitRefetchQueries: true
  })

  const view = (data: GetInteractionMessageQuery) => {
    const interactionMessage = data.getInteractionMessage?.interactionMessage
    const onChangeContact = async ({ customer, contactId, contactInput }: OnChangeContactProps) => {
      //todo: maybe warn on change if there are multiple enquiries in flight?
      if (!interactionMessage) {
        console.log('onChangeContact, no interaction message')
        return
      }

      // transform user input phone number to E164 format
      const phone = parsePhone(contactInput?.phone)
      const transformedContactInput: ContactInput | undefined = contactInput
        ? { ...contactInput, phone: phone?.number }
        : undefined

      const isCustomerChange = interactionMessage && interactionMessage.customer?.id !== customer?.id
      if (isCustomerChange) {
        const mainContactInput = customer?.mainContact ? contactFragmentToContactInput(customer.mainContact) : undefined
        const customerContactInput: ContactInput | undefined = mainContactInput
          ? {
              ...mainContactInput,
              phone: phone?.number
            }
          : undefined
        setInteractionMessageContact({
          variables: {
            input: {
              interactionMessageId: interactionMessage.id,
              customerId: customer?.id,
              contactId,
              contactInput: customerContactInput ?? transformedContactInput
            }
          }
        })
      } else {
        setInteractionMessageContact({
          variables: {
            input: {
              interactionMessageId: interactionMessage.id,
              contactInput: transformedContactInput
            }
          }
        })
      }
    }

    return interactionMessage ? (
      <InteractionMessageDetailView
        interactionMessage={interactionMessage}
        initialTab={tab}
        onChangeContact={onChangeContact}
      />
    ) : (
      <></>
    )
  }

  return (
    <DetailContainer
      variables={{ input: { id } }}
      view={view}
      parentPath={'/call-centre/calls'}
      documentNode={GetInteractionMessageDocument}
      modelName="call"
      modelNamePlural="calls"
      getTitle={(data) => data?.getInteractionMessage?.interactionMessage?.id ?? 'loading'}
      showBreadcrumbs={false}
    />
  )
}
