import { browserTracingIntegration, init } from '@sentry/react'
import { ReactNode, useContext } from 'react'
import { AppContext } from './app-context'

interface SentryProviderProps {
  children: ReactNode
}

export const SentryProvider = (props: SentryProviderProps) => {
  const appContext = useContext(AppContext)

  if (appContext.sentryDsn) {
    console.log('sentry: init')
    init({
      dsn: appContext.sentryDsn,
      environment: appContext.stage,
      release: `fs-platform-web-react@${appContext.buildVersion ?? 'unknown'}`, //process.env.npm_package_version,
      integrations: [browserTracingIntegration()],
      tracesSampleRate: appContext.sentryTraceSampleRate ?? 0.1,
      ignoreErrors: [
        'ResizeObserver loop limit exceeded',
        'ResizeObserver loop completed with undelivered notifications.',
        '_f is undefined',
        'x is undefined'
      ],
      allowUrls: [appContext.appDomain, appContext.graphqlUrl]
    })
  }

  return <>{props.children}</>
}
