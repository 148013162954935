import { useMutation, useQuery } from '@apollo/client'
import {
  EuiBasicTable,
  EuiButtonEmpty,
  EuiCallOut,
  EuiLoadingSpinner,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiSpacer,
  EuiTitle
} from '@elastic/eui'
import { Fragment, useState } from 'react'
import {
  CustomerFragment,
  CustomerLocationFragment,
  CustomerLocationStatusType,
  EditMembershipLocationDocument,
  GetCustomerMembershipsDocument,
  Membership,
  MembershipFragment
} from '../api/generated-types'
import { useAuthenticated } from '../auth/authenticated-context'
import { CustomerLocationContainer } from './customer-location-table'
import { MembershipListColumns } from '../memberships/membership-list-columns'

interface CustomerMembershipsProps {
  customer: CustomerFragment
}

export const CustomerMemberships = (props: CustomerMembershipsProps) => {
  const { customer } = props
  const [selectedMembership, setSelectedMembership] = useState<MembershipFragment | undefined>(undefined)
  const userFragment = useAuthenticated()?.userFragment
  const userCanChangeProperty = userFragment.permissions?.editCustomerDetails === true
  const { data, loading: customerLoading } = useQuery(GetCustomerMembershipsDocument, {
    variables: {
      customerId: customer.id
    }
  })

  const memberships = (data?.getCustomer?.memberships as unknown as Membership[]) ?? []
  const loading = customerLoading

  return (
    <Fragment>
      <EuiTitle size="s">
        <h1>Memberships</h1>
      </EuiTitle>
      <EuiSpacer />
      {loading ? (
        <EuiLoadingSpinner />
      ) : memberships ? (
        <>
          {selectedMembership && (
            <CustomerLocationModal
              onClose={() => setSelectedMembership(undefined)}
              customer={customer}
              membershipId={selectedMembership.id}
            />
          )}
          <EuiBasicTable
            items={memberships}
            columns={MembershipListColumns({
              onChangeProperty: userCanChangeProperty ? setSelectedMembership : undefined
            })}
          />
        </>
      ) : (
        <EuiCallOut color="danger">Error loading memberships</EuiCallOut>
      )}
    </Fragment>
  )
}

interface CustomerLocationModalProps {
  onClose: () => void
  customer: CustomerFragment
  membershipId: string
}
export const CustomerLocationModal = (props: CustomerLocationModalProps) => {
  const { onClose, customer, membershipId } = props
  const customerId = customer.id
  const [highlightedLocation, setHighlightedLocation] = useState<CustomerLocationFragment | undefined>(undefined)
  const [editMembershipLocation, { loading }] = useMutation(EditMembershipLocationDocument, {
    refetchQueries: ['GetCustomerLocation', 'GetCustomer']
  })

  const handleChangeProperty = async () => {
    if (!highlightedLocation || !highlightedLocation?.id) {
      return
    }
    await editMembershipLocation({
      variables: {
        input: {
          locationId: highlightedLocation.id,
          membershipId
        }
      }
    })
    onClose()
  }

  return (
    <>
      <EuiModal onClose={onClose}>
        <EuiModalHeader>
          <EuiModalHeaderTitle>Change Membership Property</EuiModalHeaderTitle>
        </EuiModalHeader>
        <EuiModalBody>
          <CustomerLocationContainer
            highlightedLocation={highlightedLocation?.id}
            showSelected={true}
            customerId={customerId}
            onSelect={setHighlightedLocation}
            filter={(location: CustomerLocationFragment) =>
              location.status.status === CustomerLocationStatusType.Current &&
              !location.memberships?.some((m) => m.id === membershipId)
            }
            customer={customer}
            showActions={false}
            compactView={true}
          />
        </EuiModalBody>
        <EuiModalFooter>
          <EuiButtonEmpty onClick={onClose}>Cancel</EuiButtonEmpty>
          <EuiButtonEmpty size="m" disabled={!highlightedLocation} isLoading={loading} onClick={handleChangeProperty}>
            {' '}
            Save
          </EuiButtonEmpty>
        </EuiModalFooter>
      </EuiModal>
    </>
  )
}
