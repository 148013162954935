import { YesNoValue } from '../common/common-enums'
import { TopicActionResult } from '../action-topic-model'
import { decamelise } from '../../../common/utils'

export interface TopicPlumbingGasActionResult extends TopicActionResult {
  gasConversion?: YesNoValue
  customerHasConversionKit?: YesNoValue
  gasServiceType?: GasServiceType
}

export enum GasServiceType {
  InstallOrChangeCookTopOrOven = 'InstallOrChangeCookTopOrOven',
  NewGasService = 'NewGasService',
  RelocateGasServiceOrGasBottle = 'RelocateGasServiceOrGasBottle',
  ChangeRegulator = 'ChangeRegulator',
  DisconnectReconnectForRenovations = 'DisconnectReconnectForRenovations',
  ComplianceCertificate = 'ComplianceCertificate',
  BBQConnections = 'BBQConnections',
  GasLeak = 'GasLeak',
  InstallOrChangeOverGasCookTopWithElecOven = 'InstallOrChangeOverGasCookTopWithElecOven'
}

export const gasServiceTypeOptions = [
  { id: GasServiceType.InstallOrChangeCookTopOrOven, label: 'Install / Change Cooktop or Oven' },
  {
    id: GasServiceType.InstallOrChangeOverGasCookTopWithElecOven,
    label: 'Install / Change over Gas Cooktop with Electrical Oven'
  },
  { id: GasServiceType.NewGasService, label: decamelise('NewGasService') },
  { id: GasServiceType.RelocateGasServiceOrGasBottle, label: decamelise('RelocateGasServiceOrGasBottle') },
  { id: GasServiceType.ChangeRegulator, label: decamelise('ChangeRegulator/Pigtails') },
  { id: GasServiceType.DisconnectReconnectForRenovations, label: decamelise('DisconnectReconnectForRenovations') },
  { id: GasServiceType.ComplianceCertificate, label: decamelise('ComplianceCertificate') },
  { id: GasServiceType.BBQConnections, label: decamelise('BBQConnections') },
  { id: GasServiceType.GasLeak, label: decamelise('GasLeak') }
]
