import { useMutation } from '@apollo/client'
import { useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import {
  CreateEnquiryDocument,
  CreateEnquiryInput,
  EnquiryChannel,
  EnquiryDirection,
  EnquiryType
} from '../api/generated-types'

export interface CreateEnquiryServiceResponse {
  enquiryId?: string
  loading: boolean
  error?: any
}

export interface CreateEnquiryFnProps<T> {
  initialResult: T
  type?: EnquiryType
  direction?: EnquiryDirection
  channel?: EnquiryChannel
  webFormId?: string
}

export type CreateEnquiryFn<T> = (props: CreateEnquiryFnProps<T>) => Promise<string>
export declare type CreateEnquiryServiceTuple<T> = [CreateEnquiryFn<T>, CreateEnquiryServiceResponse]

export enum EnquiryPersistenceEnum {
  None = 'None',
  Basic = 'Basic',
  Advanced = 'Advanced'
}

export const useCreateEnquiryService = <T,>(): CreateEnquiryServiceTuple<T> => {
  //Advanced is experimental
  const persistence: EnquiryPersistenceEnum = EnquiryPersistenceEnum.Basic
  const persistenceEnabled = true

  const [enquiryId, setEnquiryId] = useState<string | undefined>(undefined)
  const [createEnquiry, { loading, error }] = useMutation(CreateEnquiryDocument)

  const handleCreateEnquiry: CreateEnquiryFn<T> = async ({ type, channel, direction, webFormId }) => {
    const input: CreateEnquiryInput = {
      type: type ?? EnquiryType.Other,
      channel: channel ?? EnquiryChannel.Phone,
      direction: direction ?? EnquiryDirection.Inbound,
      webFormId
    }

    if (!persistenceEnabled) {
      console.log('enquiry persistence disabled, generating uuid locally')
      const newEnquiryId = uuidv4()
      setEnquiryId(newEnquiryId)
      //setEnquiryType(EnquiryType.Other)
      return newEnquiryId
    } else {
      console.log(`enquiry persistence ${persistence}, creating enquiry on server`)
      //const changes = compare({} as any, initialResult as any)
      const newEnquiryResponse = await createEnquiry({
        variables: {
          input: {
            ...input
            //...(advancedPersistenceEnabled && { responseOperations: JSON.stringify(changes) })
          }
        },
        awaitRefetchQueries: true,
        refetchQueries: ['GetUser']
      })
      const newEnquiryId = newEnquiryResponse.data?.createEnquiry?.enquiry?.id
      if (!newEnquiryId) {
        throw new Error('failed to create new enquiry')
      }
      setEnquiryId(newEnquiryId)
      return newEnquiryId
    }
  }
  return [
    handleCreateEnquiry,
    {
      enquiryId,
      loading,
      error
    }
  ]
}
