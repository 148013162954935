import { TopicActionResult } from '../action-topic-model'
import { QuestionDefinitions, getNotesGenerator } from '../../helpers/notes-generator'

export interface TopicShowerActionResult extends TopicActionResult {
  issueType?: ShowerIssueType
}

const questionDefinitions: QuestionDefinitions<TopicShowerActionResult> = {
  issueType: {
    question: 'What is the issue with your shower?',
    statement: 'Issue:'
  }
}

export enum ShowerIssueType {
  ReplaceShowerHead = 'Replace shower head',
  ShowerTaps = 'Shower taps',
  FlexiHoseForDetachableShowerHead = 'Flexi hose for detachable shower head',
  WaterPressureIssue = 'Water pressure issue',
  Other = 'Other'
}

export const showerNotesGenerator = getNotesGenerator(questionDefinitions)
