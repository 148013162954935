import { PlatformIcon } from '@fallonsolutions/types'

export const platformIconToEuiIcon = (icon: PlatformIcon) => {
  switch (icon) {
    case PlatformIcon.Calendar:
      return 'calendar'
    case PlatformIcon.Check:
      return 'check'
    case PlatformIcon.Clock:
      return 'clock'
    case PlatformIcon.Empty:
      return 'empty'
    case PlatformIcon.MagnifyingGlass:
      return 'search'
    case PlatformIcon.Multiply:
      return 'cross'
    case PlatformIcon.Person:
      return 'user'
    case PlatformIcon.Plus:
      return 'plus'
    case PlatformIcon.Warning:
      return 'warning'
    case PlatformIcon.Wrench:
      return 'wrench'
    default:
      return 'empty'
  }
}
