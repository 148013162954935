import { EuiButton, EuiSpacer } from '@elastic/eui'
import { map } from 'lodash-es'
import { EnquiryAbortReason } from '../../../api/generated-types'
import { Callout } from '../../../common/callout'
import { getResultUpdater } from '../../helpers/results-updater'
import { useWorkflow } from '../../helpers/workflow-provider'
import { FreeTextQuestionField } from '../../question-fields/free-text-question-field'
import { SingleSelectField } from '../../question-fields/single-select-question-field'
import { WorkflowActionProps } from '../../workflow-model'
import { TopicActionInput } from '../action-topic-model'
import { TopicWorkflowAction } from '../action-topic-view'
import { TopicQualification } from '../common/common-outcome'
import {
  DownpipeIssue,
  PropertyLevels,
  TopicDownpipeActionResult,
  downpipeNotesGenerator
} from './action-topic-downpipe-model'
import { outcomeGenerator } from './action-topic-downpipe-outcome'

export const TopicDownpipeAction = (props: WorkflowActionProps<TopicActionInput, TopicDownpipeActionResult>) => {
  const workflowContext = useWorkflow()
  const { result, input, onUpdate } = props
  const { issue, propertyLevels, additionalNotes } = result ?? {}

  const notesGenerator = downpipeNotesGenerator

  const topicOutcome = outcomeGenerator(result)
  const { requirements, qualification } = topicOutcome
  const isOutOfScope = qualification === TopicQualification.OutOfScope

  const onNext = () =>
    onUpdate({
      ...result,
      ...topicOutcome,
      actionCompleted: true,

      workRequiredNotes: generatedNotes,
      ...(requirements && { requirements })
    })

  const generatedNotes = notesGenerator.generateNotes(result ?? {})
  const updateResult = getResultUpdater(result ?? {}, onUpdate).updateResult

  const canComplete = !!requirements

  return (
    <TopicWorkflowAction input={input} onUpdate={onUpdate} result={result}>
      <SingleSelectField
        question={notesGenerator.getQuestion('propertyLevels')}
        options={map(PropertyLevels, (id) => ({ id }))}
        answer={propertyLevels}
        changeAnswer={(propertyLevels) => updateResult({ propertyLevels })}
      />

      {propertyLevels === PropertyLevels.DoubleStorey && (
        <Callout type="note">Note that a longer ladder will be required for this job</Callout>
      )}

      {!!propertyLevels && (
        <>
          <SingleSelectField
            question={notesGenerator.getQuestion('issue')}
            options={map(DownpipeIssue, (id) => ({ id }))}
            answer={issue}
            changeAnswer={(issue) => updateResult({ issue })}
          />

          <FreeTextQuestionField
            question={notesGenerator.getQuestion('additionalNotes')}
            answer={additionalNotes}
            changeAnswer={(additionalNotes) => updateResult({ additionalNotes })}
            rows={3}
          />
        </>
      )}

      {isOutOfScope && (
        <>
          <Callout type="script">Unfortunately this type of work is out of scope for us.</Callout>
          <EuiSpacer />
          <EuiButton
            color="warning"
            onClick={() =>
              workflowContext.abort({
                abortReason: EnquiryAbortReason.CustomerNeedsAreOutOfService,
                notes: `Customer was enquiring about Downpipe: ${issue}`
              })
            }
          >
            Offer a voucher and end call
          </EuiButton>
        </>
      )}

      {requirements && (
        <>
          <EuiSpacer />
          <Callout type="note">{requirements.attributes?.map((r) => r.attributeId).join(', ')}</Callout>
        </>
      )}

      {!isOutOfScope && (
        <>
          <EuiSpacer />
          <EuiButton disabled={!canComplete} onClick={() => onNext()}>
            Next
          </EuiButton>
        </>
      )}
    </TopicWorkflowAction>
  )
}
