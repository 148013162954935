import { TextSubmitWorkflowStepDetailInput, TextWorkflowStepDetailFragment } from '@fallonsolutions/types'
import { FreeTextQuestionField } from '../../workflow/question-fields/free-text-question-field'
import { WorkflowV2StepProps } from './workflow-v2-step-interface'

export interface TextWorkflowV2StepProps extends WorkflowV2StepProps {
  detail: TextWorkflowStepDetailFragment
  result: Partial<TextSubmitWorkflowStepDetailInput>
  onChange: (result: TextSubmitWorkflowStepDetailInput) => void
}

export const TextWorkflowV2Step = ({ result, detail, onChange }: TextWorkflowV2StepProps) => {
  const onChangeAnswer = (answer: string | undefined) => {
    onChange({ value: answer ?? '' })
  }

  const value = result.value ?? ''

  return (
    <FreeTextQuestionField
      rows={detail.rows ?? undefined}
      question={detail.question}
      answer={value}
      changeAnswer={onChangeAnswer}
    />
  )
}
