import { DocumentNode, OperationVariables, TypedDocumentNode, useQuery } from '@apollo/client'
import { EuiCallOut, EuiFlexGroup, EuiFlexItem, EuiHorizontalRule, EuiLoadingSpinner } from '@elastic/eui'
import EuiCustomLink from '../eui-custom-link'

export interface PreviewCardProps<TData = any, TVariables extends OperationVariables = OperationVariables> {
  modelName: string // job, user, option etc
  query: DocumentNode | TypedDocumentNode<TData, TVariables>
  variables: TVariables
  children: ({ data }: { data: TData }) => React.ReactNode
  title?: string
  link?: PreviewCardLink
  padding?: string
}

export interface PreviewCardLink {
  label: string
  url: string
}

export const PreviewCard = <TData, TVariables extends OperationVariables>({
  query,
  modelName,
  variables,
  children,
  title,
  link,
  padding = '12px'
}: PreviewCardProps<TData, TVariables>) => {
  const { data, loading, error } = useQuery(query, {
    variables
  })
  return (
    <EuiFlexGroup direction="column" gutterSize="none" style={{ minWidth: '320px' }}>
      {(title || link) && (
        <EuiFlexItem grow={false}>
          <EuiFlexGroup gutterSize="none">
            {title && (
              <EuiFlexItem style={{ padding }}>
                <strong>{title}</strong>
              </EuiFlexItem>
            )}
            <EuiFlexItem grow={true} />
            {link && (
              <EuiFlexItem grow={false} style={{ padding }}>
                <EuiCustomLink to={link.url} target="_blank">
                  {link.label}
                </EuiCustomLink>
              </EuiFlexItem>
            )}
          </EuiFlexGroup>
          <EuiHorizontalRule margin="none" />
        </EuiFlexItem>
      )}
      <EuiFlexItem grow={true}>
        {loading ? (
          <EuiFlexGroup justifyContent="center">
            <EuiFlexItem grow={true} />
            <EuiFlexItem grow={false} style={{ padding: '12px' }}>
              <EuiLoadingSpinner />
            </EuiFlexItem>
            <EuiFlexItem grow={true} />
          </EuiFlexGroup>
        ) : error ? (
          <EuiCallOut title={`Error loading ${modelName} preview`} color="danger" />
        ) : data ? (
          <div style={{ padding }}>{children({ data })}</div>
        ) : (
          <EuiCallOut title={`${modelName} not found`} color="warning" />
        )}
      </EuiFlexItem>
    </EuiFlexGroup>
  )
}
