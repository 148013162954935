import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link'
import { getAuthOptions } from './auth-link'

export interface CreateAppSyncSubscriptionHandshakeLinkProps {
  jwtToken: string
  url: string
  region: string
}

export const createAppSyncSubscriptionHandshakeLink = (props: CreateAppSyncSubscriptionHandshakeLinkProps) => {
  const { jwtToken, url, region } = props
  const auth = getAuthOptions(jwtToken)
  return createSubscriptionHandshakeLink({ url: `https://${url}`, region, auth })
}
