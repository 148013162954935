import { useState } from 'react'
import { RequestInteractionMessageCSVDocument, SearchInteractionMessagesFilter } from '../../api/generated-types'
import { EuiButtonEmpty, EuiIcon, EuiText } from '@elastic/eui'
import { useMutation } from '@apollo/client'
import { useMixpanel } from '../../app/mixpanel-provider'

interface InteractionMessageCSVButtonProps {
  input: SearchInteractionMessagesFilter
  resultCount: number
}

export const InteractionMessageCSVButton = (props: InteractionMessageCSVButtonProps) => {
  const mixpanel = useMixpanel()
  const { input, resultCount } = props

  const modelName = resultCount === 1 ? 'call' : 'calls'
  const rowExportLimit = 20000
  const disabled = resultCount <= 0 || resultCount > rowExportLimit
  const disabledMessage =
    resultCount > rowExportLimit
      ? `Cannot export more than ${rowExportLimit} ${modelName}`
      : `Cannot export zero ${modelName}`

  const [showSuccess, setShowSuccess] = useState(false)
  const [requestCSV, { data, loading, error }] = useMutation(RequestInteractionMessageCSVDocument)

  const handleRequestCSV = () => {
    console.log('requestCSV', input)
    setShowSuccess(true)
    setTimeout(() => setShowSuccess(false), 3000)
    mixpanel?.track('ExportedInteractionMessageCSV')
    return requestCSV({ variables: { input } })
  }

  const successView = (
    <EuiText size="s" style={{ color: '#34C759' }}>
      Export requested
      <EuiIcon type="checkInCircleFilled" color="#34C759" style={{ marginLeft: '3px' }} />
    </EuiText>
  )
  const errorView = <EuiText size="s">Error encountered</EuiText>
  const button = (
    <EuiButtonEmpty
      size="xs"
      flush="both"
      onClick={handleRequestCSV}
      iconType="exportAction"
      iconSide="right"
      isLoading={loading}
      disabled={disabled}
      title={disabled ? disabledMessage : ''}
    >
      {disabled ? disabledMessage : `Export ${resultCount} ${modelName} to CSV`}
    </EuiButtonEmpty>
  )

  const success = data?.requestInteractionMessageCSV?.id && showSuccess

  const dataView = success ? successView : button

  return <div>{error ? errorView : dataView}</div>
}
