import { EuiButtonGroup, EuiFlexGroup, EuiFlexItem, EuiSelect } from '@elastic/eui'
import { first } from 'lodash-es'
import { useState } from 'react'

export interface ExtendedButtonOption {
  id: string
  text: string
}

export interface ExtendedButtonGroupProps {
  selected: string
  onChange: (option: string) => void
  primaryOptions: ExtendedButtonOption[]
  additionalOptions: ExtendedButtonOption[]
  legend: string
  otherLabel?: string
}

export const ExtendedButtonGroup = ({
  selected,
  onChange,
  primaryOptions,
  additionalOptions,
  legend,
  otherLabel
}: ExtendedButtonGroupProps) => {
  const otherValue = '_other'

  const [isOtherSelected, setIsOtherSelected] = useState(false)
  const buttonOptions = primaryOptions
    .concat([{ id: otherValue, text: otherLabel ?? '…' }])
    .map((o) => ({ id: o.id, label: o.text }))

  const handleButtonChange = (value: string) => {
    if (value === otherValue) {
      setIsOtherSelected(true)
      onChange(first(additionalOptions)?.id ?? '')
    } else {
      setIsOtherSelected(false)
      onChange(value)
    }
  }

  const handleSelectChange = (value: string) => {
    // value coming in is the text value of the other options list (not the id)
    const option = additionalOptions.find((o) => o.text === value)
    if (!option) {
      console.log('additional option not found for value  ', value)
      return
    }
    onChange(option.id)
  }
  // for some reason eui select goes off text instead of id. Assume selected value is the id
  const selectedOtherOption = additionalOptions.find((o) => o.id === selected)
  return (
    <EuiFlexGroup>
      <EuiFlexItem>
        <EuiButtonGroup
          legend={legend}
          idSelected={isOtherSelected ? otherValue : selected}
          options={buttonOptions}
          onChange={handleButtonChange}
          buttonSize="compressed"
          color="primary"
        />
      </EuiFlexItem>
      <EuiFlexItem>
        {isOtherSelected && (
          <EuiSelect
            value={selectedOtherOption?.text ?? selected}
            onChange={(e) => handleSelectChange(e.target.value as string)}
            options={additionalOptions}
            compressed
          />
        )}
      </EuiFlexItem>
    </EuiFlexGroup>
  )
}
