import { WebFormLinkFragment } from '../api/generated-types'
import EuiCustomLink from '../common/eui-custom-link'
import { decamelise } from '../common/utils'

export interface WebFormLinkProps {
  webForm: WebFormLinkFragment
}

export const WebFormLink = (props: WebFormLinkProps) => {
  return <EuiCustomLink to={`/marketing/forms/${props.webForm.id}`}>{decamelise(props.webForm.type)}</EuiCustomLink>
}
