import {
  TopicQualification,
  TopicQualificationResult,
  TopicRequirementsResult,
  createOutcomeGenerator,
  createRequirements
} from '../common/common-outcome'
import { JobType, TradeType } from '../../../api/generated-types'
import { YesNoValue } from '../common/common-enums'
import { GutterIssue, GutterSize, PropertyLevels, TopicGutterActionResult } from './action-topic-gutter-model'

const GENERAL_DRAINS_SKILLS = 'GeneralDrains'
const GENERAL_PLUMBING_SKILL = 'GeneralPlumbing'

const getRequirements = (result: TopicGutterActionResult | undefined): TopicRequirementsResult => {
  if (!result) return {}
  const { issue, size, downpipesBlocked, gutterCleanInScope, gutterGuardLength, replacementSectionSize } = result
  switch (issue) {
    case GutterIssue.Blocked:
      return { requirements: createRequirements([GENERAL_DRAINS_SKILLS]), trade: TradeType.Drains }
    case GutterIssue.Clean:
      return downpipesBlocked === YesNoValue.Yes || gutterCleanInScope === YesNoValue.Yes
        ? { requirements: createRequirements([GENERAL_DRAINS_SKILLS]), trade: TradeType.Drains }
        : {}
    case GutterIssue.Damage:
    case GutterIssue.Leaking:
    case GutterIssue.Rusty:
      return size === GutterSize.SixMetresOrLess ? { requirements: createRequirements([GENERAL_PLUMBING_SKILL]) } : {}
    case GutterIssue.InstallGutterGuard:
      return gutterGuardLength === GutterSize.SixMetresOrLess
        ? { requirements: createRequirements([GENERAL_PLUMBING_SKILL]) }
        : {}
    case GutterIssue.Replacement:
      return replacementSectionSize === GutterSize.SixMetresOrLess
        ? { requirements: createRequirements([GENERAL_PLUMBING_SKILL]) }
        : {}
    default:
      return {}
  }
}

const getQualification = (result: TopicGutterActionResult | undefined): TopicQualificationResult => {
  if (!result) return { qualification: TopicQualification.Unknown }
  const {
    issue,
    size,
    propertyLevels,
    gutterGuardLength,
    gutterCleanInScope,
    downpipesBlocked,
    replacementSectionSize
  } = result
  if (propertyLevels === PropertyLevels.DoubleStorey) {
    return { qualification: TopicQualification.OutOfScope }
  }
  if (size === GutterSize.GreaterThanSixMetres) {
    return { qualification: TopicQualification.OutOfScope }
  }
  if (issue === GutterIssue.InstallGutterGuard && gutterGuardLength === GutterSize.GreaterThanSixMetres) {
    return { qualification: TopicQualification.OutOfScope }
  }
  if (issue === GutterIssue.Clean && downpipesBlocked === YesNoValue.No && gutterCleanInScope === YesNoValue.No) {
    return { qualification: TopicQualification.OutOfScope }
  }
  if (issue === GutterIssue.Replacement && replacementSectionSize === GutterSize.GreaterThanSixMetres) {
    return { qualification: TopicQualification.OutOfScope }
  }
  return { qualification: TopicQualification.InScope }
}

export const outcomeGenerator = createOutcomeGenerator<TopicGutterActionResult>(
  {
    qualification: TopicQualification.Unknown,
    trade: TradeType.Plumbing,
    type: JobType.Service,
    category: 'Gutter'
  },
  getQualification,
  getRequirements
)
