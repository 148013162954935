import React, { CSSProperties } from 'react'
import '../static/css/trade-tag.css'

interface TradeTagProps {
  trade: string
  style?: CSSProperties
}

const TradeTag = (props: TradeTagProps) => {
  const trade = (props.trade ?? 'none').replace(' ', '').toLowerCase()
  const initial = trade !== 'none' ? trade[0].toUpperCase() : '-'

  return (
    <span className={`trade-tag trade-tag--tiny trade-tag--${trade}`} style={props.style}>{initial}</span>
  )
}

export default TradeTag
