import { useMutation } from '@apollo/client'
import { EuiButton, EuiButtonEmpty, EuiFlexGroup, EuiFlexItem, EuiLink, EuiSpacer } from '@elastic/eui'
import { ContentState, Editor, EditorState } from 'draft-js'
import 'draft-js/dist/Draft.css'
import { first } from 'lodash-es'
import { useRef, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import { UpdateJobNotesDocument, UpdateJobNotesInput } from '../api/generated-types'
import '../static/css/comment.css'

export interface JobNotesProps {
  jobId: string
  notes: (string | null)[]
  isEditable: boolean
}

export const JobNotes = (props: JobNotesProps) => {
  const { jobId, notes, isEditable } = props

  const editorRef = useRef<Editor>(null)

  const [isEditorVisible, setIsEditorVisible] = useState(false)

  const [editorState, setEditorState] = useState<EditorState>(
    EditorState.createWithContent(ContentState.createFromText(notes[0] ?? ''))
  )

  const content = editorState.getCurrentContent()
  const hasContent = content.getPlainText().length > 0

  const [updateJobWorkRequired, { loading }] = useMutation(UpdateJobNotesDocument, {
    // refetchQueries: ['GetJob'],
    // awaitRefetchQueries: true,
    onCompleted: (data) => {
      const updatedContent = first(data.updateJobNotes?.job?.notes ?? []) ?? ''
      setEditorState(EditorState.createWithContent(ContentState.createFromText(updatedContent)))
      setIsEditorVisible(false)
    }
  })

  const submitClick = () => {
    if (isEditable) {
      const input: UpdateJobNotesInput = {
        job: jobId,
        notes: content.getPlainText()
      }
      updateJobWorkRequired({
        variables: { input }
      })
    }
  }

  const cancelEditing = () => setIsEditorVisible(false)
  const startEditing = () => {
    setIsEditorVisible(true)
    editorRef.current?.focus()
  }
  const resetEditor = () => setEditorState(EditorState.createWithContent(ContentState.createFromText(notes[0] ?? '')))

  const firstNote = first(notes)
  const displayNotes = firstNote ? firstNote.split('\n').join('\n\n') : isEditable ? 'Add notes...' : ''
  const hasChanges = (firstNote ?? '') !== content.getPlainText()

  return (
    <div style={{ maxWidth: '720px' }}>
      <EuiFlexGroup gutterSize="none">
        <EuiFlexItem grow={true}>
          <div className="medium-label">NOTES</div>
        </EuiFlexItem>
        {isEditable && isEditorVisible && hasChanges && (
          <EuiFlexItem grow={false}>
            <EuiLink color="subdued" onClick={resetEditor}>
              Reset
            </EuiLink>
          </EuiFlexItem>
        )}
      </EuiFlexGroup>
      {isEditable && (
        <div style={{ display: isEditorVisible ? 'block' : 'none' }}>
          <div className={`editor ${hasContent ? 'editor--has-content' : ''}`}>
            <Editor editorState={editorState} onChange={setEditorState} placeholder="Enter notes..." />
          </div>
          <EuiSpacer size="s" />
          <EuiFlexGroup>
            <EuiFlexItem grow={false}>
              <EuiButton size="s" color="primary" isLoading={loading} onClick={submitClick} fill>
                {loading ? 'Saving...' : 'Save'}
              </EuiButton>
            </EuiFlexItem>
            {!loading && (
              <EuiFlexItem grow={false}>
                <EuiButtonEmpty size="s" onClick={cancelEditing}>
                  Cancel
                </EuiButtonEmpty>
              </EuiFlexItem>
            )}
          </EuiFlexGroup>
        </div>
      )}
      {!isEditorVisible && (
        <>
          <div
            className={isEditable ? 'secondary-link' : ''}
            onClick={isEditable ? startEditing : undefined}
            data-test-id="job-notes-text"
          >
            <ReactMarkdown className="markdown-body">{displayNotes}</ReactMarkdown>
          </div>
          {isEditable && hasChanges && (
            <>
              <EuiSpacer size="s" />
              <span style={{ fontSize: '12px' }}>(Unsaved changes)</span>
            </>
          )}
        </>
      )}
    </div>
  )
}
