import { useMutation, useQuery } from '@apollo/client'
import { contactFragmentToContactInput } from '@fallonsolutions/contact'
import { dateConfig } from '@fallonsolutions/date'
import { findLastIndex, omit, uniq } from 'lodash-es'
import { DateTime } from 'luxon'
import { Dict } from 'mixpanel-browser'
import { useEffect, useState } from 'react'
import {
  AbortEnquiryDocument,
  AbortEnquiryForInteractionMessageDocument,
  ClearEnquiryDetailDocument,
  ClearInteractionMessageEnquiryDetailDocument,
  ClearableEnquiryDetailField,
  CompleteEnquiryDocument,
  CompleteEnquiryForInteractionMessageDocument,
  ContactInput,
  EnquiryAbortReason,
  EnquiryFragment,
  EnquiryType,
  GetEnquiryDocument,
  GetInteractionMessageDocument,
  InteractionMessageSetEnquiryDetailInput,
  SetEnquiryContactDocument,
  SetEnquiryDetailDocument,
  SetEnquiryDetailInput,
  SetInteractionMessageContactDocument,
  SetInteractionMessageEnquiryDetailDocument
} from '../api/generated-types'
import { useMixpanel } from '../app/mixpanel-provider'
import { useAuthenticated } from '../auth/authenticated-context'
import { useContactSearch } from '../contacts/contact-search/contact-search-hook'
import { EnquiryPersistenceEnum } from './create-enquiry-service'
import { enquiryTypeAdapter } from './helpers/enquiry-type-adapter'
import { EnquiryAbortDetails, WorkflowActionResponse, WorkflowCompletionType, WorkflowState } from './workflow-model'
import { NewEnquiryResult, getWorkflowFields } from './workflow-new-enquiry-model'
//import { EnquiryTypeReference } from './actions/action-enquiry-type'

export interface EnquiryServiceResponse<T> {
  enquiry?: EnquiryFragment
  loading: boolean
  error?: any
  updateResult: (updateResultFn: (result: T) => T) => void
  setContact: (params: SetContactParams) => void
  addResponse: (response: AddResponseProps, trackProperties?: Dict) => void
  goBack: (reference: string) => void
  completeEnquiry: (trackProperties?: Dict) => void
  clearResponses: (references: string[]) => void
  abortEnquiry: (details: EnquiryAbortDetails) => void
  resetEnquiry: () => void
}

export interface AddResponseProps {
  reference: string
  value?: string
  isEnquiryComplete?: boolean
}

export interface SetContactParams {
  contactId?: string
  customerId?: string
  contactInput?: ContactInput
}

export declare type EnquiryServiceTuple<T> = [WorkflowState<T>, EnquiryServiceResponse<T>]

const CURRENT_RESULT_VERSION = '1.0.0'

export interface EnquiryServiceProps<T> {
  enquiryId: string
  initialResult: T
  initialEnquiry?: string
  persistence?: EnquiryPersistenceEnum
}

export const useEnquiryService = <T extends NewEnquiryResult>(
  props: EnquiryServiceProps<T>
): EnquiryServiceTuple<T> => {
  //  console.log('useEnquiryService with enquiryId', props.enquiryId)
  const mixpanel = useMixpanel()
  const { enquiryId, initialResult } = props
  const persistence = props.persistence ?? EnquiryPersistenceEnum.None
  const persistenceEnabled = persistence !== EnquiryPersistenceEnum.None
  // const advancedPersistenceEnabled = persistence === EnquiryPersistenceEnum.Advanced

  const session = useAuthenticated().user
  const userId = session.id ?? 'unknown'

  const [, dispatch] = useContactSearch()

  //use network-only policy to avoid re-renders on job topic
  const {
    data,
    loading: queryLoading,
    error: queryError
  } = useQuery(GetEnquiryDocument, {
    skip: !persistenceEnabled,
    variables: { input: { id: enquiryId } },
    fetchPolicy: 'cache-first'
  })

  //old pre interaction message
  const [setEnquiryContact] = useMutation(SetEnquiryContactDocument, {
    refetchQueries: [GetEnquiryDocument],
    awaitRefetchQueries: true
  })
  const [setInteractionMessageContact] = useMutation(SetInteractionMessageContactDocument, {
    refetchQueries: [GetEnquiryDocument, GetInteractionMessageDocument],
    awaitRefetchQueries: true
  })
  const [completeEnquiry, { error: completeError }] = useMutation(CompleteEnquiryDocument, {
    refetchQueries: [GetEnquiryDocument], //'GetEnquiry'],
    awaitRefetchQueries: true
  })
  const [completeEnquiryForInteractionMessage] = useMutation(CompleteEnquiryForInteractionMessageDocument, {
    refetchQueries: [GetEnquiryDocument], //'GetEnquiry'],
    awaitRefetchQueries: true
  })
  const [abortEnquiry] = useMutation(AbortEnquiryDocument, {
    refetchQueries: [GetEnquiryDocument], //'GetEnquiry'],
    awaitRefetchQueries: true
  })
  const [abortEnquiryForInteractionMessage] = useMutation(AbortEnquiryForInteractionMessageDocument, {
    refetchQueries: [GetInteractionMessageDocument],
    awaitRefetchQueries: true
  })

  const [setInteractionMessageEnquiryDetail] = useMutation(SetInteractionMessageEnquiryDetailDocument)

  const [setEnquiryDetail] = useMutation(SetEnquiryDetailDocument)

  const [clearInteractionMessageEnquiryDetail] = useMutation(ClearInteractionMessageEnquiryDetailDocument)

  const [clearEnquiryDetail] = useMutation(ClearEnquiryDetailDocument)

  const error = queryError || completeError

  if (error) {
    console.error('error', error)
  }

  const enquiry = data?.getEnquiry.enquiry

  const createEmptyState = (initialResult: T): WorkflowState<T> => {
    return {
      enquiryId,
      startDate: DateTime.now().setZone(dateConfig.defaultTimezone).toISO(),
      fullPath: [],
      path: [],
      input: {},
      result: persistence !== EnquiryPersistenceEnum.None ? initialResult : ({} as T),
      resultVersion: CURRENT_RESULT_VERSION,
      notes: ''
    }
  }

  const [workflowState, setWorkflowState] = useState<WorkflowState<T>>(createEmptyState(initialResult))

  // useEffect(() => {
  //   if (advancedPersistenceEnabled) {
  //     console.log('server enquiry data has changed', enquiry)
  //     if (enquiry?.responseSnapshot) {
  //       try {
  //         const newResult = JSON.parse(enquiry.responseSnapshot) as any
  //         console.log('newResult', newResult.enquiryType)
  //         setWorkflowState((current) => ({
  //           ...current,
  //           result: newResult,
  //           resultVersion: enquiry.resultVersion ?? 'unknown'
  //         }))
  //       } catch (err) {
  //         console.error('error parsing json string', err)
  //         throw err
  //       }
  //     }
  //   }
  // }, [enquiry, advancedPersistenceEnabled])

  useEffect(() => {
    mixpanel?.track('EnquiryStarted')
  }, [workflowState.startDate])

  const handleUpdateResult = (updateResultFn: (result: T) => T) => {
    setWorkflowState((current: WorkflowState<T>) => {
      const newWorkflowState: WorkflowState<T> = {
        ...current,
        result: updateResultFn(current.result)
      }
      handlePersistence(newWorkflowState, current)
      return newWorkflowState
    })
  }

  type AnySetEnquiryDetailInput = SetEnquiryDetailInput | InteractionMessageSetEnquiryDetailInput

  const _setEnquiryDetail = (anyInput: AnySetEnquiryDetailInput) => {
    if (enquiry?.interactionMessageId) {
      const input = anyInput as InteractionMessageSetEnquiryDetailInput
      setInteractionMessageEnquiryDetail({
        variables: { input },
        awaitRefetchQueries: true,
        refetchQueries: ['GetInteractionMessage']
      })
    } else {
      const input = anyInput as SetEnquiryDetailInput
      setEnquiryDetail({
        variables: { input },
        awaitRefetchQueries: true,
        refetchQueries: ['GetEnquiry']
      })
    }
  }

  const handlePersistence = (newWorkflowState: WorkflowState<T>, workflowState: WorkflowState<T>) => {
    console.log('handlePersistence', persistence, newWorkflowState.enquiryId)
    const enquiryId = newWorkflowState.enquiryId
    if (persistence === EnquiryPersistenceEnum.Basic && enquiryId) {
      const {
        enquiryTypeReference: oldEnquiryTypeReference,
        jobId: oldJobId,
        contactId: oldContactId,
        customerId: oldCustomerId,
        topicReference: oldTopicReference,
        membershipLevel: oldMembershipLevel,
        membershipStatus: oldMembershipStatus
      } = getWorkflowFields(workflowState.result)

      const {
        enquiryTypeReference,
        jobId,
        contactId,
        customerId,
        contact,
        topicReference,
        membershipLevel,
        membershipStatus
      } = getWorkflowFields(newWorkflowState.result)
      const type: EnquiryType | null = enquiryTypeReference ? enquiryTypeAdapter(enquiryTypeReference) : null

      const clearFields: ClearableEnquiryDetailField[] = []

      const interactionMessageId = enquiry?.interactionMessageId ?? null
      if (enquiryTypeReference === undefined && oldEnquiryTypeReference !== undefined) {
        //clear everything or nothing as enquiryType is mandatory?
        //clearFields.concat([ClearableEnquiryDetailField.Job, ClearableEnquiryDetailField.MemberStatus])
      } else {
        if (enquiryTypeReference !== oldEnquiryTypeReference && type) {
          _setEnquiryDetail({
            type: {
              ...(interactionMessageId && { interactionMessageId }),
              enquiryId,
              type
            }
          }) //reset job, but not customer
        }
      }

      if (enquiryId && customerId === undefined && oldCustomerId !== undefined) {
        clearFields.push(ClearableEnquiryDetailField.Contact)
        clearFields.push(ClearableEnquiryDetailField.Customer)
        clearFields.push(ClearableEnquiryDetailField.Job)
        clearFields.push(ClearableEnquiryDetailField.MemberStatus)
      } else {
        const isCustomerIdChange = customerId !== oldCustomerId
        const isContactIdChange = contactId !== oldContactId
        // const isContactChange = contact !== oldContact
        if (customerId && (isCustomerIdChange || isContactIdChange)) {
          console.log('enquiry service: new booking job customerId, contactId or contact fragment changed')
          const contactInput = contact ? contactFragmentToContactInput(contact) : undefined
          if (interactionMessageId) {
            setInteractionMessageContact({
              variables: {
                input: {
                  interactionMessageId,
                  customerId,
                  contactId,
                  contactInput
                }
              }
            })
          } else {
            setEnquiryContact({
              variables: {
                input: {
                  enquiryId,
                  customerId,
                  contactId,
                  contactInput
                }
              }
            })
          }
        }
      }

      if (
        enquiryId &&
        ((membershipLevel === undefined && oldMembershipLevel !== undefined) ||
          (membershipStatus === undefined && oldMembershipStatus !== undefined))
      ) {
        clearFields.push(ClearableEnquiryDetailField.MemberStatus)
      } else {
        if (
          enquiryId &&
          membershipLevel &&
          membershipStatus &&
          (membershipLevel !== oldMembershipLevel || membershipStatus !== oldMembershipStatus)
        ) {
          _setEnquiryDetail({
            memberStatus: {
              ...(interactionMessageId && { interactionMessageId }),
              enquiryId,
              membershipLevel,
              membershipStatus
            }
          })
        }
      }

      if (jobId === undefined && oldJobId !== undefined) {
        clearFields.push(ClearableEnquiryDetailField.Job)
      } else {
        if (jobId !== oldJobId && jobId) {
          _setEnquiryDetail({
            job: {
              ...(interactionMessageId && { interactionMessageId }),
              enquiryId,
              jobId
            }
          }) //reset job, but not customer
        }
      }

      if (enquiryId && topicReference === undefined && oldTopicReference !== undefined) {
        clearFields.push(ClearableEnquiryDetailField.TopicReference)
      } else {
        if (enquiryId && topicReference !== oldTopicReference && topicReference) {
          _setEnquiryDetail({
            topic: {
              ...(interactionMessageId && { interactionMessageId }),
              enquiryId,
              topicReference
            }
          })
        }
      }

      if (clearFields.length > 0) {
        console.log('clear enquiry detail', clearFields.join(','))
        if (interactionMessageId) {
          clearInteractionMessageEnquiryDetail({
            variables: { input: { interactionMessageId, enquiryId, fields: uniq(clearFields) } }
          })
        } else {
          clearEnquiryDetail({
            variables: { input: { enquiryId, fields: uniq(clearFields) } }
          })
        }
      }
    }
  }
  const resetState = () => {
    console.log('reset search and workflow state')
    dispatch({ type: 'reset' })
    setWorkflowState((current) => ({
      ...current,
      ...endEnquiry(WorkflowCompletionType.Complete)
    }))
  }

  const handleAddResponse = (response: AddResponseProps, trackProperties?: Dict) => {
    console.log('enquiry service: add response')
    const now = DateTime.now().setZone(dateConfig.defaultTimezone)
    const newResponse: WorkflowActionResponse = {
      reference: response.reference,
      value: response.value ?? '',
      userId,
      date: now.toISO()
    }
    const enquiryEndDate = response.isEnquiryComplete === true ? now : undefined
    setWorkflowState((current) => ({
      ...current,
      fullPath: current.fullPath.concat(newResponse),
      path: current.path.concat(newResponse),
      ...(enquiryEndDate && { endDate: enquiryEndDate.toISO() })
    }))
    mixpanel?.track('EnquiryResponse', { enquiryReference: newResponse.reference, enquiryValue: newResponse.value })

    if (enquiryEndDate) {
      const enquiryDuration = enquiryEndDate
        .diff(DateTime.fromISO(workflowState.startDate), 'milliseconds')
        .as('seconds')
      mixpanel?.track('EnquiryCompleted', {
        enquiryDuration,
        ...trackProperties
      })
    }
  }

  const handleGoBack = (reference: string) => {
    console.log('enquiry service: go back')
    const lastIndex = findLastIndex(workflowState.path, (p) => p.reference === reference)
    setWorkflowState((current) => ({
      ...current,
      path: current.path.slice(0, lastIndex)
    }))
  }

  const endEnquiry = (completionType: WorkflowCompletionType): Pick<WorkflowState<T>, 'endDate' | 'completionType'> => {
    const endDate = DateTime.now().setZone(dateConfig.defaultTimezone).toISO()
    return { endDate, completionType }
  }

  const handleCompleteEnquiry = (trackProperties?: Dict) => {
    console.log('enquiry service: complete enquiry', enquiry?.interactionMessageId, persistenceEnabled, enquiryId)
    if (persistenceEnabled && enquiryId) {
      if (enquiry?.interactionMessageId) {
        console.log('completeEnquiryForInteractionMessage')
        completeEnquiryForInteractionMessage({
          variables: { input: { enquiryId, interactionMessageId: enquiry.interactionMessageId } },
          onCompleted: resetState
        })
      } else {
        console.log('completeEnquiry')
        completeEnquiry({ variables: { input: { enquiryId } }, onCompleted: resetState })
      }
    }
    const endDate = DateTime.now().setZone(dateConfig.defaultTimezone).toISO()
    const enquiryDuration = DateTime.fromISO(endDate)
      .diff(DateTime.fromISO(workflowState.startDate), 'milliseconds')
      .as('seconds')
    mixpanel?.track('EnquiryCompleted', {
      enquiryDuration,
      ...trackProperties
    })
  }

  const handleAbortEnquiry = (details: EnquiryAbortDetails) => {
    console.log('enquiry service: abort enquiry')

    const abortResetState = () => {
      console.log('reset search and workflow state')
      dispatch({ type: 'reset' })
      setWorkflowState((current) => ({
        ...current,
        result: { ...current.result, abort: { details } },
        ...endEnquiry(WorkflowCompletionType.Abort)
      }))
    }

    const { abortReason, notes, followUpActions, jobTopicReference } = details
    if (persistenceEnabled && enquiryId) {
      if (enquiry?.interactionMessageId) {
        abortEnquiryForInteractionMessage({
          variables: {
            input: {
              enquiryId,
              interactionMessageId: enquiry.interactionMessageId,
              jobTopicReference,
              reason: abortReason ?? EnquiryAbortReason.Other,
              notes,
              followUpActions
            }
          },
          onCompleted: abortResetState
        })
      } else {
        abortEnquiry({
          variables: {
            input: {
              enquiryId,
              jobTopicReference,
              reason: abortReason ?? EnquiryAbortReason.Other,
              notes,
              followUpActions
            }
          },
          onCompleted: abortResetState
        })
      }
    }
    mixpanel?.track('EnquiryAborted', details)
  }

  const handleSetContact = ({ contactId, customerId, contactInput }: SetContactParams) => {
    console.log('enquiry service: contact change')
    if (persistenceEnabled) {
      setEnquiryContact({
        variables: {
          input: {
            enquiryId,
            contactId,
            customerId,
            contactInput
          }
        }
      })
    }
  }

  const handleResetEnquiry = () => {
    console.log('enquiry service: reset enquiry')
    handleUpdateResult(() => initialResult) //set workflowstate and persist changes
    setWorkflowState(createEmptyState(initialResult))
  }

  const handleClearResponses = (references: string[]) => {
    console.log('enquiry service: clear responses', references)
    //determine which responses to clear based on references
    handleUpdateResult((result: T) => omit(result, references) as T) //set workflowstate and persist changes
  }

  return [
    workflowState,
    {
      enquiry: enquiry ?? undefined,
      loading: queryLoading,
      updateResult: handleUpdateResult,
      completeEnquiry: handleCompleteEnquiry,
      addResponse: handleAddResponse,
      goBack: handleGoBack,
      abortEnquiry: handleAbortEnquiry,
      resetEnquiry: handleResetEnquiry,
      setContact: handleSetContact,
      clearResponses: handleClearResponses
    }
  ]
}
