import {
  TopicQualification,
  TopicQualificationResult,
  TopicRequirementsResult,
  createOutcomeGenerator,
  createRequirements
} from '../common/common-outcome'
import { JobType, TradeType } from '../../../api/generated-types'
import { DownpipeIssue, TopicDownpipeActionResult } from './action-topic-downpipe-model'

const GENERAL_DRAINS_SKILLS = 'GeneralDrains'
const GENERAL_PLUMBING_SKILL = 'GeneralPlumbing'

const getRequirements = (result: TopicDownpipeActionResult | undefined): TopicRequirementsResult => {
  if (!result) return {}
  const { issue, additionalNotes } = result
  if (!additionalNotes || additionalNotes.length <= 0) return {}
  switch (issue) {
    case DownpipeIssue.Repair:
    case DownpipeIssue.Rusted:
    case DownpipeIssue.Broken:
    case DownpipeIssue.Damaged:
    case DownpipeIssue.Leaking:
      return { requirements: createRequirements([GENERAL_PLUMBING_SKILL]) }
    case DownpipeIssue.Blocked:
    case DownpipeIssue.ConnectToStormwater:
    case DownpipeIssue.LocationOfStormwaterForDownpipes:
      return { requirements: createRequirements([GENERAL_DRAINS_SKILLS]), trade: TradeType.Drains }
    default:
      return {}
  }
}

const getQualification = (result: TopicDownpipeActionResult | undefined): TopicQualificationResult => {
  return { qualification: TopicQualification.InScope }
}

export const outcomeGenerator = createOutcomeGenerator<TopicDownpipeActionResult>(
  {
    qualification: TopicQualification.Unknown,
    trade: TradeType.Plumbing,
    type: JobType.Service,
    category: 'Downpipe'
  },
  getQualification,
  getRequirements
)
