import { EuiContextMenuPanelItemDescriptor } from '@elastic/eui'
import { ScheduleEventDetailFragment, ScheduleEventFragment } from '../../api/generated-types'

export interface ScheduleEventContextMenuCutEventProps {
  event: ScheduleEventFragment
  onCutEvent?: (event: ScheduleEventFragment | ScheduleEventDetailFragment) => void
  onActionClick?: (() => void) | undefined
}

export const ScheduleEventContextMenuCutEvent = ({
  event,
  onCutEvent,
  onActionClick
}: ScheduleEventContextMenuCutEventProps): EuiContextMenuPanelItemDescriptor => {
  return {
    name: 'Move to another day',
    icon: 'cut',
    onClick: () => {
      onCutEvent && onCutEvent(event)
      onActionClick && onActionClick()
    },
    // assume undefined means true
    disabled: event.restrictions?.canMoveDay === false
  }
}
