import { EuiSpacer, EuiTextArea } from '@elastic/eui'
import { InteractionMessageFragment } from '../../api/generated-types'
import { InteractionMessageDetailContact } from './interaction-message-detail-contact'
import createPersistedState from '../../use-persisted-state'
import { OnChangeContactProps } from '../../workflow/workflow-interaction-message-model'

export interface InteractionMessageDetailSidebarProps {
  interactionMessage: InteractionMessageFragment
  onChangeContact?: (props: OnChangeContactProps) => Promise<void>
}

const useInteractionMessageNotes = createPersistedState<string>('interaction-message-notes')

export const InteractionMessageDetailSidebar = ({
  interactionMessage,
  onChangeContact
}: InteractionMessageDetailSidebarProps) => {
  const [comments, setComments] = useInteractionMessageNotes('')
  const canCapture = interactionMessage?.status?.status === 'Active' && !interactionMessage.customer
  //for existing customers, should this be convenient way to update existing customer contact details?
  return (
    <>
      <InteractionMessageDetailContact
        interactionMessage={interactionMessage}
        canCapture={canCapture}
        onChangeContact={onChangeContact}
      />
      <EuiSpacer />
      <EuiTextArea
        placeholder="Interaction comments"
        value={comments}
        style={{ minHeight: '340px' }}
        onChange={(e) => setComments(e.target.value)}
      />
    </>
  )
}
