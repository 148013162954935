import { EuiButton, EuiSpacer } from '@elastic/eui'
import { JobType, PriorityType, TradeType } from '../../../api/generated-types'
import { Callout } from '../../../common/callout'
import { decameliseIfNotNil } from '../../../common/utils'
import { QuestionDefinitions, getNotesGenerator } from '../../helpers/notes-generator'
import { getResultUpdater } from '../../helpers/results-updater'
import { FreeTextQuestionField } from '../../question-fields/free-text-question-field'
import { SingleSelectField } from '../../question-fields/single-select-question-field'
import { WorkflowActionProps } from '../../workflow-model'
import { ItemInstaller, TopicActionInput, TopicActionResult } from '../action-topic-model'
import { TopicWorkflowAction } from '../action-topic-view'

export interface TopicRewireActionResult extends TopicActionResult {
  itemInstaller?: ItemInstaller
  propertyAge?: string
  rewireReason?: string
}

const questionDefinitions: QuestionDefinitions<TopicRewireActionResult> = {
  // 'Have we previously done the wiring at the property?'
  itemInstaller: {
    question: 'Have Fallon previously done the wiring at the property?',
    statement: 'Wiring done by:',
    makeAnswerHumanReadable: decameliseIfNotNil
  },
  propertyAge: {
    question: 'How old is the house?',
    statement: 'House age:'
  },
  rewireReason: {
    question: 'What is the reason for a rewire?',
    statement: 'Rewire reason:'
  }
}
const notesGenerator = getNotesGenerator(questionDefinitions)

export const TopicRewireAction = (props: WorkflowActionProps<TopicActionInput, TopicRewireActionResult>) => {
  const { result, input, onUpdate } = props
  const { itemInstaller, propertyAge, rewireReason } = result ?? {}

  const isRevisit = itemInstaller === ItemInstaller.Fallon

  const onNext = () =>
    onUpdate({
      ...result,
      actionCompleted: true,
      trade: TradeType.Electrical,
      category: 'Rewire',
      type: isRevisit ? JobType.Callback : JobType.Quote,

      workRequiredNotes: generatedNotes,
      requirements: {
        priority: {
          required: PriorityType.P1
        },
        attributes: [{ attributeId: 'Rewire' }],
        travelFeeWaived: true
      }
    })

  const generatedNotes = notesGenerator.generateNotes(result ?? {})
  const updateResult = getResultUpdater(result ?? {}, onUpdate).updateResult

  const canComplete = isRevisit || (itemInstaller === ItemInstaller.SomeoneElse && !!propertyAge && !!rewireReason)

  return (
    <TopicWorkflowAction input={input} onUpdate={onUpdate} result={result}>
      <SingleSelectField
        question={notesGenerator.getQuestion('itemInstaller')}
        options={[
          {
            id: ItemInstaller.Fallon,
            label: 'Wired by Fallon Solutions',
            icon: 'check'
          },
          {
            id: ItemInstaller.SomeoneElse,
            label: 'Wired by someone else',
            icon: 'user'
          }
        ]}
        answer={itemInstaller}
        changeAnswer={(itemInstaller) =>
          updateResult({
            itemInstaller,
            propertyAge: undefined,
            rewireReason: undefined
          })
        }
      />

      {itemInstaller === ItemInstaller.SomeoneElse && (
        <>
          <FreeTextQuestionField
            question={notesGenerator.getQuestion('propertyAge')}
            answer={propertyAge}
            changeAnswer={(propertyAge) => updateResult({ propertyAge })}
          />
          <FreeTextQuestionField
            question={notesGenerator.getQuestion('rewireReason')}
            answer={rewireReason}
            changeAnswer={(rewireReason) => updateResult({ rewireReason })}
            rows={3}
          />
        </>
      )}

      {isRevisit && (
        <>
          <Callout type="note" title="Book this as an Electrical Revisit and follow standard revisit process" />
          <Callout type="note">
            <ul>
              <li>
                Book same technician as the original Job and send work chat to Electrical team or email Electrical DSR
                Team
              </li>
              <li>If time not suitable or technician not available call Electrical DSR 99973</li>
            </ul>
          </Callout>
          <EuiSpacer />
        </>
      )}

      {canComplete && (
        <>
          <EuiSpacer />
          <Callout
            type="note"
            title="Contact DSR as this is a priority job and they need to make space on the schedule"
          />
          <Callout type="warning" title="This is a priority 1 job" />
          <EuiSpacer />
          <EuiButton onClick={() => onNext()}>Next</EuiButton>
        </>
      )}
    </TopicWorkflowAction>
  )
}
