import { useMutation } from '@apollo/client'
import { EuiButtonGroup, EuiButtonGroupOptionProps, EuiFlexGroup, EuiFlexItem, EuiIcon, EuiText } from '@elastic/eui'
import { dateConfig } from '@fallonsolutions/date'
import { DateTime } from 'luxon'
import {
  MarketingChannel,
  MarketingConsentType,
  MarketingSubscriptionFragment,
  MarketingSubscriptionStatus,
  MarketingSubscriptionTypeLinkFragment,
  SetContactMarketingSubscriptionStatusDocument
} from '../api/generated-types'

export interface ContactMarketingSubscriptionViewProps {
  contactId: string
  subscriptionType: MarketingSubscriptionTypeLinkFragment
  subscription: MarketingSubscriptionFragment | undefined
}

export const ContactMarketingSubscriptionView = ({
  contactId,
  subscriptionType,
  subscription
}: ContactMarketingSubscriptionViewProps) => {
  const [setContactMarketingSubscriptionStatus, { loading }] = useMutation(
    SetContactMarketingSubscriptionStatusDocument,
    {
      refetchQueries: ['GetContact'],
      awaitRefetchQueries: true
    }
  )

  const status = subscription?.status ?? MarketingSubscriptionStatus.NotSpecified
  const canOptIn = status === MarketingSubscriptionStatus.NotSpecified

  const options: EuiButtonGroupOptionProps[] = [
    {
      id: MarketingSubscriptionStatus.OptedOut,
      label: 'Opted out'
    },
    {
      id: MarketingSubscriptionStatus.NotSpecified,
      label: 'Not specified',
      isDisabled: !canOptIn
    },
    {
      id: MarketingSubscriptionStatus.OptedIn,
      label: 'Opted in',
      isDisabled: !canOptIn
    }
  ]

  const onChange = (status: MarketingSubscriptionStatus) => {
    setContactMarketingSubscriptionStatus({
      variables: {
        input: {
          contactId,
          subscriptionTypeId: subscriptionType.id,
          status,
          consentType: MarketingConsentType.Express
        }
      }
    })
  }

  const subscriptionDate = subscription?.date
    ? DateTime.fromISO(subscription.date)
        .setZone(dateConfig.defaultTimezone)
        .toFormat(dateConfig.luxonFormat.fullDateAndTime)
    : undefined

  const consentType = subscription?.consentType ? `${subscription.consentType} consent` : 'Not specified'
  return (
    <EuiFlexGroup alignItems="center" gutterSize="s">
      <EuiFlexItem grow={false}>
        <EuiIcon type={iconForMarketingChannel(subscriptionType.channel)} />
      </EuiFlexItem>
      <EuiFlexItem grow={false} style={{ width: '120px' }}>
        <EuiText size="s">{subscriptionType.name}</EuiText>
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <EuiButtonGroup
          isDisabled={loading}
          legend={subscriptionType.name}
          options={options}
          type="single"
          idSelected={status}
          onChange={(id) => onChange(id as MarketingSubscriptionStatus)}
          color="primary"
          buttonSize="compressed"
        />
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <EuiText size="xs" color="subdued">
          {[consentType, subscriptionDate].join(', ')}
        </EuiText>
      </EuiFlexItem>
      <EuiFlexItem grow={true} />
    </EuiFlexGroup>
  )
}

const iconForMarketingChannel = (channel: MarketingChannel) => {
  switch (channel) {
    case MarketingChannel.Email:
      return 'email'
    case MarketingChannel.SMS:
      return 'mobile'
    case MarketingChannel.Phone:
      return 'mobile'
    case MarketingChannel.Mail:
      return 'pencil'
    default:
      return 'empty'
  }
}
