import { ImgHTMLAttributes, useMemo } from 'react'
import { minidenticon } from 'minidenticons'
import '../static/css/identicon-avatar.css'

export interface IdenticonAvatarProps extends ImgHTMLAttributes<HTMLImageElement> {
  username: string
}

export const IdenticonAvatar = ({ username, ...props }: IdenticonAvatarProps) => {
  const svgURI = useMemo(() => 'data:image/svg+xml;utf8,' + encodeURIComponent(minidenticon(username)), [username])
  return (
    <div className={`identicon-avatar identicon-avatar--small`}>
      <img src={svgURI} alt={username} {...props} />
    </div>
  )
}
