import { EuiButton, EuiFlexGroup, EuiFlexItem, EuiForm, EuiSpacer } from '@elastic/eui'
import { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'
// import MapContainer, { MapMarker } from '../maps/map'
import { ApolloError, useMutation } from '@apollo/client'
import { AddressEntryMode, addressFragmentToAddressInput } from '@fallonsolutions/address'
import { Property } from '@fallonsolutions/property'
import {
  CreatePropertyDocument,
  CreatePropertyInput,
  CustomerLocationFragment,
  PropertyFragment
} from '../api/generated-types'
import { PropertyForm } from './property-form'

const defaultCountry = 'Australia'

interface CreatePropertyFormProps {
  query?: string
  customerId?: string
  redirectToProfileAfterCreation: boolean
  onCustomerLocationCreated?: (customerLocation: CustomerLocationFragment) => void
}

export const CreatePropertyForm = (props: CreatePropertyFormProps) => {
  const [query, setQuery] = useState(props.query ?? '')
  const [property, setProperty] = useState<PropertyFragment>({
    id: '',
    created: '',
    updated: '',
    accessInstructions: '',
    address: {
      street: '',
      street2: '',
      suburb: '',
      postcode: '',
      state: '',
      country: defaultCountry,
      entryMode: AddressEntryMode.Autocomplete
    }
  } as Property)

  const [propertyValid, setPropertyValid] = useState(false)
  const [createPropertyRequestError, setCreatePropertyRequestError] = useState<ApolloError | undefined>(undefined)
  const [showValidationErrors, setShowValidationErrors] = useState(false)

  const input: CreatePropertyInput = {
    customer: props.customerId,
    ...(property.address && { address: addressFragmentToAddressInput(property.address) })
  }

  const [createProperty, { data, loading }] = useMutation(CreatePropertyDocument, {
    variables: { input },
    onError: (e) => setCreatePropertyRequestError(e)
    // update: (cache, mutationResult) => {
    //   try {
    //     const newProperty = mutationResult.data?.createProperty.customerLocation?.property
    //     cache.modify({
    //       id: cache.identify({ id: props.customerId, __typename: 'Customer' }),
    //       fields: {
    //         properties(existingPropertyRefs: [], { readField }) {
    //           if (existingPropertyRefs.some((ref) => readField('id', ref) === newProperty?.id)) {
    //             return existingPropertyRefs
    //           }
    //           const newPropertyRef = cache.writeFragment({
    //             data: newProperty,
    //             fragment: PropertyFragmentDoc,
    //             fragmentName: 'Property'
    //           })
    //           return [...existingPropertyRefs, newPropertyRef]
    //         }
    //       }
    //     })
    //   } catch (exception) {
    //     console.log(exception)
    //   }
    // }
  })

  const newProperty = data?.createProperty.customerLocation?.property

  useEffect(() => {
    if (!query.length && props.query) {
      setQuery(props.query)
    }
  }, [props.query, query.length])

  const submit = async () => {
    setShowValidationErrors(true)
    if (propertyValid) {
      const mutationResponse = await createProperty()
      const customerLocation = mutationResponse.data?.createProperty.customerLocation
      if (customerLocation && props.onCustomerLocationCreated) {
        props.onCustomerLocationCreated(customerLocation)
      }
    }
  }

  if (props.redirectToProfileAfterCreation && newProperty?.id) {
    return <Redirect to={`/properties/${newProperty?.id}`} />
  }

  return (
    <EuiForm
      error={createPropertyRequestError?.message}
      isInvalid={showValidationErrors && (!propertyValid || !!createPropertyRequestError)}
    >
      <EuiFlexGroup alignItems="flexStart">
        <EuiFlexItem grow={false} style={{ minWidth: '22%' }}>
          <PropertyForm
            canAddressBeProvided={true}
            property={property}
            onChangeProperty={setProperty}
            showValidationErrors={showValidationErrors}
            onValidationChange={setPropertyValid}
          />
        </EuiFlexItem>
        {/* <EuiFlexItem>
          <div style={{ width: '100%', height: '400px' }}>
            <MapContainer center={location}>
              <MapMarker lat={location.lat} lng={location.lng} text={property.formattedAddress ?? 'No address'} />
            </MapContainer>
          </div>
        </EuiFlexItem> */}
      </EuiFlexGroup>
      <EuiSpacer />
      <EuiButton fill isLoading={loading} isDisabled={loading} onClick={submit}>
        Create property
      </EuiButton>
    </EuiForm>
  )
}
