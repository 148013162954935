import { BaseActionInput, BaseActionResult, WorkflowActionProps } from '../workflow-model'
import {
  EmploymentJobOpportunitiesAction,
  EmploymentJobOpportunitiesActionResult
} from './action-employment-job-opportunities'
import { EmploymentConfirmationAction, EmploymentConfirmationActionResult } from './action-employment-confirmation'
import { EmploymentReferenceAction, EmploymentReferenceActionResult } from './action-employment-reference'
import {
  EmploymentEnquiryType,
  EmploymentEnquiryTypeAction,
  EmploymentEnquiryTypeActionResult
} from './action-employment-enquiry-type'

export type EmploymentActionInput = BaseActionInput

export interface EmploymentActionResult extends BaseActionResult {
  enquiryType?: EmploymentEnquiryTypeActionResult
  jobOpportunities?: EmploymentJobOpportunitiesActionResult
  employmentConfirmation?: EmploymentConfirmationActionResult
  employmentReference?: EmploymentReferenceActionResult
}

export const EmploymentAction = (props: WorkflowActionProps<EmploymentActionInput, EmploymentActionResult>) => {
  const { result, onUpdate } = props
  const { enquiryType, jobOpportunities, employmentReference, employmentConfirmation } = result ?? {}

  const setEnquiryType = (enquiryType: EmploymentEnquiryTypeActionResult | undefined) =>
    onUpdate({ ...result, enquiryType })
  const setJobOpportunities = (jobOpportunities: EmploymentJobOpportunitiesActionResult | undefined) =>
    onUpdate({ ...result, jobOpportunities, actionCompleted: jobOpportunities?.actionCompleted })
  const setEmploymentConfirmation = (employmentConfirmation: EmploymentConfirmationActionResult | undefined) =>
    onUpdate({ ...result, employmentConfirmation, actionCompleted: employmentConfirmation?.actionCompleted })
  const setEmploymentReference = (employmentReference: EmploymentReferenceActionResult | undefined) =>
    onUpdate({ ...result, employmentReference, actionCompleted: employmentReference?.actionCompleted })

  return (
    <>
      <EmploymentEnquiryTypeAction input={{}} result={enquiryType} onUpdate={setEnquiryType} />

      {enquiryType?.enquiryType === EmploymentEnquiryType.JobOpportunities && (
        <EmploymentJobOpportunitiesAction input={{}} result={jobOpportunities} onUpdate={setJobOpportunities} />
      )}

      {enquiryType?.enquiryType === EmploymentEnquiryType.ConfirmingEmployment && (
        <EmploymentConfirmationAction input={{}} result={employmentConfirmation} onUpdate={setEmploymentConfirmation} />
      )}

      {enquiryType?.enquiryType === EmploymentEnquiryType.WorkReference && (
        <EmploymentReferenceAction input={{}} result={employmentReference} onUpdate={setEmploymentReference} />
      )}
    </>
  )
}
