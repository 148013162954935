import { QuestionDefinitions, getNotesGenerator } from '../../helpers/notes-generator'
import { TopicActionResult } from '../action-topic-model'
import { YesNoValue } from '../common/common-enums'

export interface TopicInspectionActionResult extends TopicActionResult {
  currentMembership?: CurrentMembershipStatus
  inspectionType?: InspectionType
  isSpecificInspection?: YesNoValue
  additionalNotes?: string
  drainDiameter?: DrainDiameter
}

export enum CurrentMembershipStatus {
  Current1YearPlan = 'Current 1 year plan member',
  Current3YearPlan = 'Current 3 year plan member',
  NotCurrent = 'Not a current member'
}

export enum InspectionType {
  Electrical = 'Electrical',
  Plumbing = 'General Plumbing',
  Drains = 'Drain Camera Check',
  PlumbingAndDrains = 'Both Drain Camera Check and General Plumbing',
  Solar = 'Solar',
  FilterClean = 'FilterClean',
  Security = 'Security'
}

export enum DrainDiameter {
  Between90and150 = 'Between 90mm and 150mm',
  OutsideOf90and150 = 'Outside of 90mm and 150mm'
}

const questionDefinitions: QuestionDefinitions<TopicInspectionActionResult> = {
  currentMembership: {
    question: 'Check to see if customer is a valid, current member',
    statement: 'Is current member:'
  },
  inspectionType: {
    question:
      'We can certainly arrange to redeem one of your complimentary  Inspections. Which one did you want to redeem??',
    statement: 'Inspection type:'
  },
  isSpecificInspection: {
    question: 'Was there anything in particular you wanted the technician to look at or just a general check up?',
    statement: 'Specific issue:',
    excludeFromNotes: true
  },
  additionalNotes: {
    question: 'Not a problem at all. Can you please explain what you would like the technician to look at?',
    statement: 'Specific issue to inspect:'
  }
}

export const inspectionNotesGenerator = getNotesGenerator(questionDefinitions)
