import { useMutation } from '@apollo/client'
import { EuiContextMenu, EuiContextMenuPanelDescriptor, EuiLoadingSpinner, EuiPopover } from '@elastic/eui'
import { LegacyJobStatusName } from '@fallonsolutions/job'
import { useState } from 'react'
import {
  JobFragment,
  JobSource,
  JobStatusType,
  UpdateJobStatusDocument,
  UpdateJobStatusInput
} from '../api/generated-types'
import { JobStatusBadge } from './job-status-badge'
import { JobStatusBadgeExtended } from './job-status-badge-extended'

interface JobStatusEditProps {
  job: JobFragment
}

export const JobStatusEdit = (props: JobStatusEditProps) => {
  const { job } = props
  const jobStatus =
    job.source === JobSource.Platform
      ? (job.status?.status ?? JobStatusType.NotStarted)
      : toPlatformStatus(job.legacy?.status as LegacyJobStatusName)

  const finalJobStates = [JobStatusType.Cancelled]
  const canUpdateJobStatus = job.source === JobSource.Platform && !finalJobStates.includes(jobStatus)

  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const [updateJobStatus, { loading }] = useMutation(UpdateJobStatusDocument)

  const menu: EuiContextMenuPanelDescriptor[] = [
    {
      id: 0,
      title: 'Set job status',
      items: [
        JobStatusType.NotStarted,
        JobStatusType.InProgress,
        JobStatusType.OnHold,
        JobStatusType.WaitingForParts,
        JobStatusType.ActionRequired,
        JobStatusType.Complete
      ].map((status) => {
        return {
          name: <JobStatusBadge status={status} />,
          disabled: !canUpdateJobStatus,
          toolTipContent: canUpdateJobStatus ? undefined : 'Status can only be changed for non-cancelled Platform jobs',
          onClick: () => handleStatusUpdate(status)
        }
      })
    }
  ]

  const handleStatusUpdate = async (status: JobStatusType) => {
    const input: UpdateJobStatusInput = {
      job: job.id,
      status
    }
    setIsMenuOpen(false)
    try {
      await updateJobStatus({
        variables: { input },
        refetchQueries: ['GetJob'],
        awaitRefetchQueries: true
      })
    } catch (err) {
      console.error('error changing job status', err)
    }
  }

  return canUpdateJobStatus ? (
    <EuiPopover
      isOpen={isMenuOpen}
      button={
        <div onClick={() => !loading && setIsMenuOpen(!isMenuOpen)}>
          {loading ? (
            <EuiLoadingSpinner size="m" />
          ) : (
            <JobStatusBadgeExtended status={job.status ?? undefined} editable={true} />
          )}
        </div>
      }
      closePopover={() => setIsMenuOpen(false)}
      ownFocus={true}
      panelPaddingSize="none"
    >
      <EuiContextMenu initialPanelId={0} panels={menu} />
    </EuiPopover>
  ) : (
    <JobStatusBadgeExtended status={job.status ?? undefined} />
  )
}

const toPlatformStatus = (legacyStatus: LegacyJobStatusName): JobStatusType => {
  switch (legacyStatus) {
    case LegacyJobStatusName.Allocated:
      return JobStatusType.NotStarted
    case LegacyJobStatusName.Cancelled:
      return JobStatusType.Cancelled
    case LegacyJobStatusName.AwaitingJobCard:
      return JobStatusType.InProgress
    case LegacyJobStatusName.Entered:
      return JobStatusType.OnHold
    case LegacyJobStatusName.Finalised:
      return JobStatusType.Complete
    case LegacyJobStatusName.OnHold:
      return JobStatusType.OnHold
    case LegacyJobStatusName.Invoiced:
      return JobStatusType.Complete
    case LegacyJobStatusName.InProgress:
      return JobStatusType.InProgress
    case LegacyJobStatusName.Revisit:
      return JobStatusType.NotStarted
    default:
      return JobStatusType.NotStarted
  }
}
