import { dateConfig } from '@fallonsolutions/date'
import { DateTime } from 'luxon'
import { QuestionDefinitions, getNotesGenerator } from '../../helpers/notes-generator'
import { TopicActionResult } from '../action-topic-model'
import { YesNoValue } from '../common/common-enums'
import { ACType } from '../hvac-quote-install/action-topic-hvac-quote-install'

export interface TopicHVACInspectionActionResult extends TopicActionResult {
  isOutsidePeakPeriod?: YesNoValue
  numberOfUnits?: string
  indoorUnitLocations?: string
  outdoorUnitLocations?: string
  hasManHoleAccess?: string
  lastServiceDate?: string
  systemAge?: string
  furnitureNeedToBeMoved?: string
  roofAccess?: YesNoValue
  acType?: ACType
}

const currentDateString = DateTime.now().setZone(dateConfig.defaultTimezone).toFormat(dateConfig.luxonFormat.fullDate)

const hvacInspectionQuestionDefinitions: QuestionDefinitions<TopicHVACInspectionActionResult> = {
  isOutsidePeakPeriod: {
    question: `Internal: Is this request between April and September? (current date is ${currentDateString})`,
    statement: 'Outside of peak period:',
    excludeFromNotes: true
  },
  numberOfUnits: {
    question: 'How many AC units do you have?',
    statement: 'Number of AC units:'
  },
  indoorUnitLocations: {
    question: 'Indoor location of AC units?',
    statement: 'Indoor location(s):'
  },
  outdoorUnitLocations: {
    question: 'Outdoor unit location? (if relevant)',
    statement: 'Outdoor location(s):'
  },
  hasManHoleAccess: {
    question: 'Does property have man hole access?',
    statement: 'Man hole access:'
  },
  lastServiceDate: {
    question: 'Last service date?',
    statement: 'Last service date:'
  },
  systemAge: {
    question: 'Age of AC?',
    statement: 'AC unit age:'
  },
  furnitureNeedToBeMoved: {
    question: 'Does any furniture need to be moved?',
    statement: 'Furniture to be moved:'
  },
  acType: {
    question: 'What type of AC unit do you have?',
    statement: 'AC type:'
  }
}

export const hvacInspectionNotesGenerator = getNotesGenerator(hvacInspectionQuestionDefinitions)
