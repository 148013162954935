import { BaseActionInput, BaseActionResult, WorkflowActionProps } from '../workflow-model'
import { EuiButton } from '@elastic/eui'
import { MakePaymentAction, MakePaymentActionResult } from './action-make-payment'
import { ContactActionResult } from './action-contact'
import { CustomerAction, CustomerActionResult } from './action-customer'
import { CreateMembershipAction, CreateMembershipActionResult } from './action-create-membership'
import { RenewMembershipOptionAction, RenewMembershipOptionActionResult } from './action-renew-membership-option'
import { JobCategoryId } from '../../api/generated-types'

export enum RenewMembershipOption {
  HppExtensionRed = 'HppExtensionRed',
  HppExtensionBlack = 'HppExtensionBlack'
}

export interface RenewMembershipActionInput extends BaseActionInput {
  isJobCreated: boolean
  contact?: ContactActionResult
}

export interface RenewMembershipActionResult extends BaseActionResult {
  customer?: CustomerActionResult
  renewMembershipOption?: RenewMembershipOptionActionResult
  createMembership?: CreateMembershipActionResult
  makePaymentResult?: MakePaymentActionResult
  welcomePackExplained?: boolean
}

export const RenewMembershipAction = (
  props: WorkflowActionProps<RenewMembershipActionInput, RenewMembershipActionResult>
) => {
  const { input, onUpdate, result } = props
  const { contact, isJobCreated } = input ?? {}
  const { customer, renewMembershipOption, makePaymentResult, createMembership } = result ?? {}

  const setCustomer = (customer: CustomerActionResult | undefined) => onUpdate({ ...result, customer })
  const setRenewMembershipOption = (renewMembershipOption: RenewMembershipOptionActionResult | undefined) =>
    onUpdate({ ...result, renewMembershipOption })
  const setCreateMembership = (createMembership: CreateMembershipActionResult) =>
    onUpdate({ ...result, createMembership })

  const paymentRequired =
    renewMembershipOption?.renewMembershipOption &&
    [RenewMembershipOption.HppExtensionRed, RenewMembershipOption.HppExtensionBlack].includes(
      renewMembershipOption.renewMembershipOption
    )
  const paymentComplete = makePaymentResult?.actionCompleted
  const allTasksCompleted =
    renewMembershipOption?.renewMembershipOption &&
    (!paymentRequired || (paymentComplete && createMembership?.actionCompleted))

  const customerId = customer?.actionCompleted ? customer?.customer?.id : undefined

  return (
    <>
      <CustomerAction input={{ contact, editable: !isJobCreated }} result={customer} onUpdate={setCustomer} />
      {customer?.customer?.id && customer.actionCompleted && (
        <RenewMembershipOptionAction
          input={{ editable: !isJobCreated }}
          result={renewMembershipOption}
          onUpdate={setRenewMembershipOption}
        />
      )}
      {renewMembershipOption?.actionCompleted && (
        <>
          {paymentRequired && (
            <MakePaymentAction
              input={{ editable: !isJobCreated }}
              result={result?.makePaymentResult}
              onUpdate={(makePaymentResult) => onUpdate({ ...result, makePaymentResult })}
            />
          )}

          {!paymentRequired ||
            (paymentComplete && customerId && (
              <CreateMembershipAction
                input={{ customerId, jobCategoryId: JobCategoryId.HPPRenewal }}
                result={createMembership}
                onUpdate={setCreateMembership}
              />
            ))}

          {allTasksCompleted && (
            <>
              <EuiButton
                fill
                color="primary"
                onClick={() => onUpdate({ ...result, actionCompleted: true })}
                disabled={!allTasksCompleted}
              >
                I've completed these steps
              </EuiButton>
            </>
          )}
        </>
      )}
    </>
  )
}
