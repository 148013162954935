import { BaseActionInput, BaseActionResult, WorkflowActionProps } from '../workflow-model'
import { EuiButton, EuiKeyPadMenu, EuiKeyPadMenuItem, EuiSpacer, EuiText } from '@elastic/eui'
import { WorkflowAction } from '../workflow-action'
import { Callout } from '../../common/callout'
import { Task } from '../../common/task'

export enum CardType {
  CreditCard = 'credit-card',
  DebitCard = 'debit-card'
}

export type MakePaymentActionInput = BaseActionInput
export interface MakePaymentActionResult extends BaseActionResult {
  customerOnHold?: boolean
  recordingPaused?: boolean
  eftposGrabbed?: boolean
  cardDetailsCollected?: boolean
  paymentSuccessful?: boolean
  recordingResumed?: boolean
  emailAddressConfirmed?: boolean
  cardType?: CardType
}

export const MakePaymentAction = (props: WorkflowActionProps<MakePaymentActionInput, MakePaymentActionResult>) => {
  const { onUpdate, result, input } = props
  const { editable } = input
  const {
    customerOnHold,
    recordingPaused,
    eftposGrabbed,
    cardDetailsCollected,
    paymentSuccessful,
    recordingResumed,
    emailAddressConfirmed,
    cardType,
    actionCompleted
  } = result ?? {}

  const setCustomerOnHold = (checked: boolean) => onUpdate({ ...result, customerOnHold: checked })
  const setRecordingPaused = (checked: boolean) => onUpdate({ ...result, recordingPaused: checked })
  const setEftposGrabbed = (checked: boolean) => onUpdate({ ...result, eftposGrabbed: checked })
  const setCardType = (cardType: CardType) => onUpdate({ ...result, cardType })
  const setCardDetailsCollected = (checked: boolean) => onUpdate({ ...result, cardDetailsCollected: checked })
  const setPaymentSuccessful = (checked: boolean) => onUpdate({ ...result, paymentSuccessful: checked })
  const setRecordingResumed = (checked: boolean) => onUpdate({ ...result, recordingResumed: checked })
  const setEmailAddressConfirmed = (checked: boolean) => onUpdate({ ...result, emailAddressConfirmed: checked })
  const setActionCompleted = (actionCompleted: boolean | undefined) => onUpdate({ ...result, actionCompleted })

  const handleChange = () => setActionCompleted(false)

  return (
    <WorkflowAction
      title="Make payment"
      value={actionCompleted ? 'Payment successful' : undefined}
      onClickChange={handleChange}
      editable={editable}
    >
      <Callout type="script">
        <EuiText>
          <div>I will just place you on a quick hold whilst I grab the EFTPOS machine</div>
          <EuiSpacer size="s" />
          <div>Do you mind getting your card details ready please?</div>
        </EuiText>
      </Callout>
      <Task label="Place customer on hold" checked={!!customerOnHold} onChange={setCustomerOnHold} indent={true} />
      <Task label="Grab EFTPOS machine" checked={!!eftposGrabbed} onChange={setEftposGrabbed} indent={true} />
      <Task
        label="Stop recording audio to capture card details"
        checked={!!recordingPaused}
        onChange={setRecordingPaused}
        indent={true}
      />

      {recordingPaused && (
        <>
          <EuiSpacer />
          <Callout type="script">
            <EuiText>
              <div>Thanks for waiting, I appreciate your patience</div>
              <EuiSpacer size="s" />
              <div>So I will confirm we are paying X amount and was that on credit or debit card?</div>
            </EuiText>
          </Callout>
          <EuiSpacer />
          <EuiKeyPadMenu className="fs-keypadmenu">
            <EuiKeyPadMenuItem
              id="credit-card"
              isSelected={cardType === CardType.CreditCard}
              label="Credit card"
              onClick={() => setCardType(CardType.CreditCard)}
            >
              <></>
            </EuiKeyPadMenuItem>
            <EuiKeyPadMenuItem
              id="debit-card"
              isSelected={cardType === CardType.DebitCard}
              label="Debit card"
              onClick={() => setCardType(CardType.DebitCard)}
            >
              <></>
            </EuiKeyPadMenuItem>
          </EuiKeyPadMenu>

          <EuiSpacer />
          {cardType === CardType.CreditCard && (
            <>
              <Callout type="warning" title="Inform customer of 1% surcharge for credit card" />
              <EuiSpacer />
            </>
          )}
        </>
      )}

      {cardType && (
        <Task
          label="Collect card details and confirm with customer as you go along"
          checked={!!cardDetailsCollected}
          onChange={setCardDetailsCollected}
          indent={true}
        />
      )}

      {cardDetailsCollected && (
        <>
          <EuiSpacer />
          <Callout type="script" title="Just processing that one now..." />
          <Task label="Process payment" checked={!!paymentSuccessful} onChange={setPaymentSuccessful} indent={true} />
        </>
      )}

      {paymentSuccessful && (
        <>
          <Callout type="script" title="And its all done" />
          <Task
            label="Resume recording audio"
            checked={!!recordingResumed}
            onChange={setRecordingResumed}
            indent={true}
          />
        </>
      )}

      {recordingResumed && (
        <>
          <EuiSpacer />
          <Callout
            type="script"
            title="I'll just confirm that your email address as I will be scanning the receipt and sending it through to you."
          />
          <Task
            label="Confirm email address"
            checked={!!emailAddressConfirmed}
            onChange={setEmailAddressConfirmed}
            indent={true}
          />
        </>
      )}

      {emailAddressConfirmed && (
        <>
          <Callout type="script" title="Thank you for that. I'll get that processed after this call." />
          <EuiSpacer size="s" />
          <EuiButton onClick={() => setActionCompleted(true)}>Next</EuiButton>
          <EuiSpacer />
        </>
      )}
    </WorkflowAction>
  )
}
