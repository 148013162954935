import { HotWaterSystemType, TopicHotWaterActionResult } from './action-topic-hot-water-model'
import { SingleSelectField } from '../../question-fields/single-select-question-field'
import { FreeTextQuestionField } from '../../question-fields/free-text-question-field'
import { NotesGenerator } from '../../helpers/notes-generator'
import { ResultUpdater } from '../../helpers/results-updater'

export interface HotWaterReplaceProps {
  result?: TopicHotWaterActionResult
  notesGenerator: NotesGenerator<TopicHotWaterActionResult>
  updateResult: ResultUpdater<TopicHotWaterActionResult>
}

export const HotWaterReplace = (props: HotWaterReplaceProps) => {
  const { result, notesGenerator, updateResult } = props
  const { existingHwsType, replacementHwsType, replacementTimeframe, otherQuotes } = result ?? {}
  return (
    <>
      {existingHwsType !== HotWaterSystemType.Other && (
        <>
          <SingleSelectField
            question={notesGenerator.getQuestion('replacementHwsType')}
            options={[
              {
                id: HotWaterSystemType.ElectricTank,
                icon: 'bolt',
                label: 'Electric (tank)'
              },
              {
                id: HotWaterSystemType.Gas,
                icon: 'visGauge',
                label: 'Gas (tank)'
              },
              {
                id: HotWaterSystemType.GasInstant,
                icon: 'visGauge',
                label: 'Gas (instant)'
              },
              {
                id: HotWaterSystemType.Solar,
                icon: 'cloudSunny'
              },
              {
                id: HotWaterSystemType.HeatPump,
                icon: 'temperature'
              }
            ]}
            answer={replacementHwsType}
            changeAnswer={(replacementHwsType) => updateResult({ replacementHwsType })}
          />
          {replacementHwsType && (
            <>
              <FreeTextQuestionField
                question={notesGenerator.getQuestion('replacementTimeframe')}
                answer={replacementTimeframe}
                changeAnswer={(replacementTimeframe) => updateResult({ replacementTimeframe })}
              />
              {/* removed due to redundancy */}
              {/* <SingleSelectField
                question={notesGenerator.getQuestion('otherQuotes')}
                options={[
                  {
                    id: YesNoValue.Yes,
                    icon: 'check'
                  },
                  {
                    id: YesNoValue.No,
                    label: 'No other quotes',
                    icon: 'cross'
                  }
                ]}
                answer={otherQuotes}
                changeAnswer={(otherQuotes) => updateResult({ otherQuotes })}
              /> */}
            </>
          )}
        </>
      )}
    </>
  )
}
