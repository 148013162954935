import { EuiButton, EuiSpacer, EuiText } from '@elastic/eui'
import { includes, map } from 'lodash-es'
import { EnquiryAbortReason } from '../../../api/generated-types'
import { Callout } from '../../../common/callout'
import { getResultUpdater } from '../../helpers/results-updater'
import { useWorkflow } from '../../helpers/workflow-provider'
import { SingleSelectField } from '../../question-fields/single-select-question-field'
import { WorkflowActionProps } from '../../workflow-model'
import { TopicActionInput } from '../action-topic-model'
import { TopicWorkflowAction } from '../action-topic-view'
import { TopicQualification } from '../common/common-outcome'
import {
  DrainDiameter,
  PlumbingInspectionType,
  TopicPlumbingInspectionActionResult,
  plumbingInspectionNotesGenerator
} from './action-topic-plumbing-inspection-model'
import { outcomeGenerator } from './action-topic-plumbing-inspection-outcome'

export const TopicPlumbingInspectionAction = (
  props: WorkflowActionProps<TopicActionInput, TopicPlumbingInspectionActionResult>
) => {
  const workflowContext = useWorkflow()
  const { result, input, onUpdate } = props
  const { inspectionType, drainDiameter } = result ?? {}

  const notesGenerator = plumbingInspectionNotesGenerator

  const topicOutcome = outcomeGenerator(result)
  const { qualification, requirements } = topicOutcome
  const isOutOfScope = qualification === TopicQualification.OutOfScope

  const onNext = () =>
    onUpdate({
      ...result,
      ...topicOutcome,
      actionCompleted: true,

      workRequiredNotes: generatedNotes,
      ...(requirements && { requirements })
    })

  const generatedNotes = notesGenerator.generateNotes(result ?? {})
  const updateResult = getResultUpdater(result ?? {}, onUpdate).updateResult

  const canComplete = !!requirements

  const isDrainsInspection = includes(
    [PlumbingInspectionType.DrainCameraCheck, PlumbingInspectionType.Both],
    inspectionType
  )

  return (
    <TopicWorkflowAction input={input} onUpdate={onUpdate} result={result}>
      <SingleSelectField
        question={notesGenerator.getQuestion('inspectionType')}
        options={map(PlumbingInspectionType, (id) => ({ id }))}
        answer={inspectionType}
        changeAnswer={(inspectionType) => updateResult({ inspectionType })}
      />

      {isDrainsInspection && (
        <>
          <SingleSelectField
            question={notesGenerator.getQuestion('drainDiameter')}
            options={[
              {
                id: DrainDiameter.Between90and150,
                label: 'Between 90mm and 150mm'
              },
              {
                id: DrainDiameter.OutsideOf90and150,
                label: 'Outside of 90mm and 150mm'
              }
            ]}
            answer={drainDiameter}
            changeAnswer={(drainDiameter) => updateResult({ drainDiameter })}
          />
        </>
      )}

      {!isOutOfScope && (
        <Callout type="note">
          <EuiText>
            {inspectionType === PlumbingInspectionType.GeneralPlumbing && (
              <div>
                <div>
                  <strong>1 &times; Complimentary Plumbing Inspection- HPP Red &amp; Black Plans</strong>
                </div>
                Complimentary visual safety inspection of your plumbing system by a licensed plumber - value $220. If
                you need any plumbing work done, book it on this visit and pay no travel fee.
              </div>
            )}
            {isDrainsInspection && (
              <div>
                <div>
                  <strong>1 &times; Complimentary Drain Camera Examination - HPP Red &amp; Black Plans</strong>
                </div>
                To ensure correct flow and operation of sewer drains, we conduct a 30min CCTV camera inspection - value
                $290. If you need any drain work done, book it on this visit and pay no travel fee.
              </div>
            )}
          </EuiText>
        </Callout>
      )}

      {isOutOfScope && (
        <>
          <Callout type="script">Unfortunately this type of work is out of scope for us.</Callout>
          <EuiSpacer />
          <EuiButton
            color="warning"
            onClick={() =>
              workflowContext.abort({
                abortReason: EnquiryAbortReason.CustomerNeedsAreOutOfService,
                notes: `Customer was enquiring about Plumbing Inspection`
              })
            }
          >
            Offer a voucher and end call
          </EuiButton>
        </>
      )}

      {requirements && (
        <>
          <EuiSpacer />
          <Callout type="note">{requirements.attributes?.map((r) => r.attributeId).join(', ')}</Callout>
        </>
      )}

      {!isOutOfScope && (
        <>
          <EuiSpacer />
          <EuiButton disabled={!canComplete} onClick={() => onNext()}>
            Next
          </EuiButton>
        </>
      )}
    </TopicWorkflowAction>
  )
}
