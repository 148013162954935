/* eslint-disable max-lines */
import { EuiButton, EuiSpacer, EuiText } from '@elastic/eui'
import { CustomerType, EnquiryAbortReason } from '../../../api/generated-types'
import { Callout } from '../../../common/callout'
import { makeHumanReadable } from '../../../common/utils'
import { HVACRoofAccessQuestion } from '../../helpers/hvac-commercial-roof-acccess-question'
import { QuestionDefinitions, getNotesGenerator } from '../../helpers/notes-generator'
import { OutOfScope } from '../../helpers/out-of-topic'
import { getResultUpdater } from '../../helpers/results-updater'
import { useWorkflow } from '../../helpers/workflow-provider'
import { SingleSelectField } from '../../question-fields/single-select-question-field'
import { WorkflowActionProps } from '../../workflow-model'
import { ItemSupplier, TopicActionInput, TopicActionResult } from '../action-topic-model'
import { TopicWorkflowAction } from '../action-topic-view'
import { YesNoValue } from '../common/common-enums'
import { yesNoOptions } from '../common/common-options'
import { TopicQualification } from '../common/common-outcome'
import { hvacOutComeGenerator } from './action-topic-hvac-quote-install-outcome'

export enum DwellingBuildStatus {
  CompleteDwelling = 'CompleteDwelling',
  IncompleteDwelling = 'IncompleteDwelling'
}

export enum ACType {
  SplitSystemOrMultiHead = 'SplitSystemOrMultiHead',
  Ducted = 'Ducted',
  UnsureOrOther = 'UnsureOrOther'
}

export enum HVACInstallType {
  NewInstall = 'NewInstall',
  ReplacingGoodUnit = 'ReplacingGoodUnit',
  ReplacingBrokenUnit = 'ReplacingBrokenUnit',
  ReplaceExisting = 'ReplaceExisting'
}

export enum InstallationTimeFrame {
  Within4Weeks = 'Within4Weeks',
  Outside4Weeks = 'Outside4Weeks'
}

export enum DwellingType {
  StandardDomesticSingleOrDoubleStoreyHouse = 'StandardDomesticSingleOrDoubleStoreyHouse',
  MultiStoreyBuilding = 'MultiStoreyBuilding',
  ApartmentBuilding = 'ApartmentBuilding',
  TownHouse = 'TownHouse',
  Unit = 'Unit',
  HouseOrTownHouse = 'HouseOrTownHouse',
  ApartmentOrUnit = 'ApartmentOrUnit'
}

export enum UnitType {
  FirstToThirdFloor = 'FirstToThirdFloor',
  FourthFloorAndAbove = 'FourthFloorAndAbove'
}

export interface TopicHVACQuoteInstallActionResult extends TopicActionResult {
  compressorAvailability?: YesNoValue
  organizeCompressAvailability?: YesNoValue
  itemSupplier?: ItemSupplier
  dwellingBuildStatus?: DwellingBuildStatus
  acType?: ACType
  installType?: HVACInstallType
  otherQuotes?: YesNoValue
  installationTimeFrame?: InstallationTimeFrame
  dwellingType?: DwellingType
  roofAccess?: YesNoValue
  unitType?: UnitType
  balconyAccess?: YesNoValue
}

const questionDefinitions: QuestionDefinitions<TopicHVACQuoteInstallActionResult> = {
  itemSupplier: {
    question: 'Who is supplying the system?',
    statement: 'AC unit supplied by:',
    makeAnswerHumanReadable: makeHumanReadable
  },
  dwellingBuildStatus: {
    question: 'Is this for a complete dwelling?',
    makeAnswerHumanReadable: makeHumanReadable,
    excludeFromNotes: true
  },
  acType: {
    question: 'What type of AC do you want to install?',
    statement: 'AC type to be installed:',
    makeAnswerHumanReadable: makeHumanReadable
  },
  installType: {
    question: 'Is this to replace existing or for new service?',
    makeAnswerHumanReadable: makeHumanReadable
  },
  otherQuotes: {
    question: 'Have you had other quotes?',
    makeAnswerHumanReadable: makeHumanReadable
  },
  installationTimeFrame: {
    question: 'When are you looking at getting installed?',
    makeAnswerHumanReadable: makeHumanReadable
  },
  dwellingType: {
    question: 'What type of Dwelling is it?',
    makeAnswerHumanReadable: makeHumanReadable
  },
  unitType: {
    question: 'what floor are you on?',
    makeAnswerHumanReadable: makeHumanReadable
  },
  balconyAccess: {
    question: 'Do you have a balcony to possibly install the unit on?',
    makeAnswerHumanReadable: makeHumanReadable
  },
  compressorAvailability: {
    question: 'Are the compressors accessible/on your balcony?',
    makeAnswerHumanReadable: makeHumanReadable
  },
  organizeCompressAvailability: {
    question: 'Can you organize someone on site to provide access?',
    makeAnswerHumanReadable: makeHumanReadable
  }
}
const notesGenerator = getNotesGenerator(questionDefinitions)

// eslint-disable-next-line max-lines-per-function
export const TopicHVACQuoteInstallAction = (
  props: WorkflowActionProps<TopicActionInput, TopicHVACQuoteInstallActionResult>
) => {
  const workflowContext = useWorkflow()
  const { result, input, onUpdate } = props
  const {
    itemSupplier,
    dwellingBuildStatus,
    acType,
    installType,
    otherQuotes,
    installationTimeFrame,
    dwellingType,
    roofAccess,
    customerType,
    compressorAvailability,
    organizeCompressAvailability,
    balconyAccess
  } = result ?? {}

  const isCommercial = customerType === CustomerType.Commercial
  const { serviceArea, existingCustomer } = input

  const outcome = hvacOutComeGenerator({ ...result, existingCustomer, serviceArea })
  const requirements = outcome.requirements
  const trade = outcome.trade
  const category = outcome.category
  const type = outcome.type

  const onNext = () =>
    onUpdate({
      ...result,
      actionCompleted: true,
      trade,
      requirements,
      category,
      type,
      workRequiredNotes: generatedNotes
    })

  const generatedNotes = notesGenerator.generateNotes(result ?? {})
  const updateResult = getResultUpdater(result ?? {}, onUpdate).updateResult

  const doableACTypes = [ACType.SplitSystemOrMultiHead, ACType.Ducted]

  const withinScope = outcome.qualification === TopicQualification.InScope

  const apartmentSplitSystemInstallNotValid =
    !!dwellingType &&
    dwellingType === DwellingType.ApartmentOrUnit &&
    installType === HVACInstallType.NewInstall &&
    acType === ACType.SplitSystemOrMultiHead &&
    balconyAccess === YesNoValue.No

  const isDuctedApartmentJob =
    !!dwellingType && dwellingType === DwellingType.ApartmentOrUnit && acType === ACType.Ducted

  const apartmentSplitSystemRepairNotValid = organizeCompressAvailability === YesNoValue.No

  // const isSupportedDwellingType = supportedDwellingType(dwellingType) === SupportedDwellingType.InScope

  return (
    <TopicWorkflowAction input={input} onUpdate={onUpdate} result={result}>
      <SingleSelectField
        question={notesGenerator.getQuestion('itemSupplier')}
        options={[
          {
            id: ItemSupplier.Fallon,
            label: 'Fallon',
            icon: 'check'
          },
          {
            id: ItemSupplier.Customer,
            label: 'Customer',
            icon: 'user'
          }
        ]}
        answer={itemSupplier}
        changeAnswer={(itemSupplier) =>
          updateResult({
            itemSupplier,
            dwellingBuildStatus: undefined,
            acType: undefined,
            unitType: undefined,
            dwellingType: undefined
          })
        }
      />
      {itemSupplier === ItemSupplier.Customer && (
        <>
          <Callout type="script">
            <ul>
              <li>
                Unfortunately, this type of work is out of scope for us as we do not install customer supplied units.
              </li>
              <li>
                What I can do is organise for a technician to do a complimentary on-site quotation to supply and install
                brand new.
              </li>
            </ul>
          </Callout>
          <Callout type="script">Would you like to book that in?</Callout>
          <EuiButton
            color="warning"
            onClick={() =>
              workflowContext.abort({
                abortReason: EnquiryAbortReason.CustomerNeedsAreOutOfService,
                notes: 'Customer wanted to install self-provided AC'
              })
            }
          >
            Customer does not want to continue
          </EuiButton>
          <EuiButton onClick={() => updateResult({ itemSupplier: ItemSupplier.Fallon })} style={{ marginLeft: 10 }}>
            Customer is happy for Fallon to supply and install AC
          </EuiButton>
        </>
      )}

      {itemSupplier === ItemSupplier.Fallon && (
        <>
          <SingleSelectField
            question={notesGenerator.getQuestion('dwellingBuildStatus')}
            options={[
              {
                id: DwellingBuildStatus.CompleteDwelling,
                label: 'Yes, Complete Dwelling',
                icon: 'check'
              },
              {
                id: DwellingBuildStatus.IncompleteDwelling,
                label: 'No, Incomplete Dwelling'
              }
            ]}
            answer={dwellingBuildStatus}
            changeAnswer={(dwellingBuildStatus) => updateResult({ dwellingBuildStatus, acType: undefined })}
          />
          {dwellingBuildStatus === DwellingBuildStatus.IncompleteDwelling && (
            <>
              <Callout type="script">
                Unfortunately, this type of work is out of scope for us as we do not supply and install new units to new
                builds/floor plans
              </Callout>
              <EuiButton
                color="warning"
                onClick={() =>
                  workflowContext.abort({
                    abortReason: EnquiryAbortReason.CustomerNeedsAreOutOfService,
                    notes: 'Customer wanted to install AC in a new build'
                  })
                }
              >
                Offer a voucher and end call
              </EuiButton>
            </>
          )}
        </>
      )}

      {dwellingBuildStatus === DwellingBuildStatus.CompleteDwelling && (
        <>
          <SingleSelectField
            question={notesGenerator.getQuestion('acType')}
            options={[
              {
                id: ACType.SplitSystemOrMultiHead,
                label: 'Split System / Multi-Head'
              },
              {
                id: ACType.Ducted,
                label: 'Ducted'
              }
            ]}
            answer={acType}
            changeAnswer={(acType) =>
              updateResult({
                acType,
                balconyAccess: undefined,
                compressorAvailability: undefined,
                organizeCompressAvailability: undefined
              })
            }
          />

          {acType && !doableACTypes.includes(acType) && (
            <>
              <Callout type="script">
                Due to the landscape of AC during this time of year - We do not offer supply and installs for this type
                of AC
              </Callout>
              <EuiButton
                color="warning"
                onClick={() =>
                  workflowContext.abort({
                    abortReason: EnquiryAbortReason.CustomerNeedsAreOutOfService,
                    notes: 'Customer wanted to install ducted AC'
                  })
                }
              >
                Offer a voucher and end call
              </EuiButton>
            </>
          )}
        </>
      )}

      {acType && doableACTypes.includes(acType) && (
        <>
          <SingleSelectField
            question={notesGenerator.getQuestion('installType')}
            options={[
              {
                id: HVACInstallType.NewInstall,
                label: 'New install'
              },
              {
                id: HVACInstallType.ReplaceExisting
              }
            ]}
            answer={installType}
            changeAnswer={(installType) =>
              updateResult({
                installType,
                balconyAccess: undefined,
                compressorAvailability: undefined,
                organizeCompressAvailability: undefined
              })
            }
          />
          <SingleSelectField
            question={notesGenerator.getQuestion('otherQuotes')}
            options={[
              {
                id: YesNoValue.No,
                label: 'No, it will be the first quote'
              },
              {
                id: YesNoValue.Yes,
                label: 'Yes, I have other quotes'
              }
            ]}
            answer={otherQuotes}
            changeAnswer={(otherQuotes) => updateResult({ otherQuotes })}
          />
          <SingleSelectField
            question={notesGenerator.getQuestion('installationTimeFrame')}
            options={[
              {
                id: InstallationTimeFrame.Within4Weeks,
                label: 'Within 4 weeks'
              },
              {
                id: InstallationTimeFrame.Outside4Weeks,
                label: 'Outside 4 weeks'
              }
            ]}
            answer={installationTimeFrame}
            changeAnswer={(installationTimeFrame) => updateResult({ installationTimeFrame })}
          />
          <SingleSelectField
            question={notesGenerator.getQuestion('dwellingType')}
            options={[
              {
                id: DwellingType.HouseOrTownHouse,
                label: 'House or Town House'
              },
              {
                id: DwellingType.ApartmentOrUnit,
                label: 'Apartment or Unit'
              }
            ]}
            answer={dwellingType}
            changeAnswer={(dwellingType) =>
              updateResult({
                dwellingType,
                balconyAccess: undefined,
                compressorAvailability: undefined,
                organizeCompressAvailability: undefined
              })
            }
          />
        </>
      )}
      {/* {!!dwellingType && dwellingType === DwellingType.Unit && (
        <>
          {dwellingType === DwellingType.Unit && (
            <>
              <SingleSelectField
                question={notesGenerator.getQuestion('unitType')}
                options={[
                  {
                    id: UnitType.FirstToThirdFloor,
                    label: '1st to 3rd floor'
                  },
                  {
                    id: UnitType.FourthFloorAndAbove,
                    label: '4th floor and above'
                  }
                ]}
                answer={unitType}
                changeAnswer={(unitType) => updateResult({ unitType })}
              />
            </>
          )}
        </>
      )}  */}
      {!!dwellingType &&
        dwellingType === DwellingType.ApartmentOrUnit &&
        installType === HVACInstallType.ReplaceExisting &&
        acType === ACType.SplitSystemOrMultiHead && (
          <>
            <SingleSelectField
              question={notesGenerator.getQuestion('compressorAvailability')}
              options={yesNoOptions}
              answer={compressorAvailability}
              changeAnswer={(compressorAvailability) =>
                updateResult({ compressorAvailability, balconyAccess: undefined })
              }
              hint={
                'A compressor is the engine of an air conditioning system, it is the unit that pumps out the hot/cool air'
              }
            />
            {compressorAvailability === YesNoValue.No && (
              <>
                <SingleSelectField
                  question={notesGenerator.getQuestion('organizeCompressAvailability')}
                  options={yesNoOptions}
                  answer={organizeCompressAvailability}
                  changeAnswer={(organizeCompressAvailability) =>
                    updateResult({ organizeCompressAvailability, balconyAccess: undefined })
                  }
                />
              </>
            )}
          </>
        )}

      {!!dwellingType &&
        dwellingType === DwellingType.ApartmentOrUnit &&
        installType === HVACInstallType.NewInstall &&
        acType === ACType.SplitSystemOrMultiHead && (
          <>
            <SingleSelectField
              question={notesGenerator.getQuestion('balconyAccess')}
              options={[
                {
                  id: YesNoValue.Yes,
                  label: 'Yes has balcony access'
                },
                {
                  id: YesNoValue.No,
                  label: 'No balcony access'
                }
              ]}
              answer={balconyAccess}
              changeAnswer={(balconyAccess) =>
                updateResult({
                  balconyAccess,
                  organizeCompressAvailability: undefined,
                  compressorAvailability: undefined
                })
              }
            />
          </>
        )}
      <OutOfScope
        issue={'split system HVAC install quote for apartment/unit without balcony access'}
        isOutOfScope={apartmentSplitSystemInstallNotValid}
        reason={'cannot offer HVAC install quote without balcony access'}
      />
      <OutOfScope
        issue={'ducted HVAC install/replace quote for apartment/unit'}
        isOutOfScope={isDuctedApartmentJob}
        reason={'cannot offer HVAC replace ducted quote to an apartment or unit building'}
      />
      <OutOfScope
        issue={'split system HVAC replace quote for apartment/unit without access to site compressor'}
        isOutOfScope={apartmentSplitSystemRepairNotValid}
        reason={'cannot offer HVAC replace quote without access to site compressor'}
      />
      {/* {!!dwellingType && supportedDwellingType(dwellingType) === SupportedDwellingType.OutOfScope && (
        <>
          <Callout type="script">Unfortunately this type of work is out of scope for us.</Callout>
          <EuiSpacer />
          <EuiButton
            color="warning"
            onClick={() =>
              workflowContext.abort({
                abortReason: EnquiryAbortReason.CustomerNeedsAreOutOfService,
                notes: `Customer was enquiring about: install to unserviceable building type`
              })
            }
          >
            Offer a voucher and end call
          </EuiButton>
        </>
      )} */}
      <HVACRoofAccessQuestion updateResults={updateResult} isCommercial={isCommercial} roofAccess={roofAccess} />

      {/* {canComplete && (
        <>
          <EuiText>Calculated priority: {getPriorityType()}</EuiText>
        </>
      )} */}

      {withinScope && outcome.requirements?.priority?.required && (
        <>
          <EuiText>Calculated priority: {outcome.requirements.priority.required}</EuiText>
        </>
      )}

      <EuiSpacer />
      <EuiButton disabled={!withinScope} onClick={() => onNext()}>
        Next
      </EuiButton>
    </TopicWorkflowAction>
  )
}
