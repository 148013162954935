import { EuiFlexGroup, EuiFlexItem, EuiIcon } from '@elastic/eui'
import { compact } from 'lodash-es'
import { PropertyFragment } from '../api/generated-types'

export interface PropertyLinkProps {
  property: PropertyFragment
  includeSuburb?: boolean
}

// Useful for displaying link to property in table rows or smaller spaces
export const PropertyLink = (props: PropertyLinkProps) => {
  const { property } = props
  const includeSuburb = props.includeSuburb ?? false

  const addressComponents = compact([
    property.address?.street,
    property.address?.street2,
    ...(includeSuburb ? [property.address?.suburb] : [])
  ])

  const addressString = addressComponents.length > 0 ? addressComponents.join(', ') : 'No address'

  return (
    <EuiFlexGroup gutterSize="xs" alignItems="center">
      <EuiFlexItem grow={false}>
        <EuiIcon type="home" />
      </EuiFlexItem>
      <EuiFlexItem>
        <a href={`/properties/${property.id}`} target="_blank" rel="noopener noreferrer">
          {addressString}
        </a>
      </EuiFlexItem>
    </EuiFlexGroup>
  )
}
