import { useState } from 'react'
import { EuiLink } from '@elastic/eui'
import { CreateCreditNote } from './create-credit-note'
import { InvoiceFragment } from '../api/generated-types'

export interface CreateCreditNoteButtonProps {
  invoice: InvoiceFragment
}

export const CreateCreditNoteButton = (props: CreateCreditNoteButtonProps) => {
  const { invoice } = props
  const [isModalVisible, setIsModalVisible] = useState(false)

  const closeModal = () => setIsModalVisible(false)
  const showModal = () => setIsModalVisible(true)

  return (
    <div>
      <EuiLink onClick={showModal}>Create Credit Note</EuiLink>
      {isModalVisible && <CreateCreditNote invoice={invoice} closeModal={closeModal} />}
    </div>
  )
}
