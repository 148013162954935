import React, { useContext } from 'react'
import mixpanel, { Mixpanel } from 'mixpanel-browser'
import { useApp } from '../app/app-context'

interface MixpanelContextProviderProps {
  children: React.ReactNode
}

export const MixpanelContext = React.createContext<Mixpanel | undefined>(undefined)

export const MixpanelProvider = (props: MixpanelContextProviderProps) => {
  const appContext = useApp()
  mixpanel.init(appContext.mixpanelToken)
  return <MixpanelContext.Provider value={mixpanel}>{props.children}</MixpanelContext.Provider>
}

export const useMixpanel = () => useContext(MixpanelContext)
