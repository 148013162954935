import { JobType, TradeType } from '../../../api/generated-types'
import {
  TopicQualification,
  TopicQualificationResult,
  TopicRequirementsResult,
  createOutcomeGenerator,
  createRequirements
} from '../common/common-outcome'
import { TopicRangehoodActionResult } from './action-topic-rangehood-model'
import { GeneralApplianceIssueType, getMechanicalIssueOnlyMessage } from '../appliance-shared/appliance-model'
import { ServiceType } from '../action-topic-model'
import { YesNoValue } from '../common/common-enums'
import { APPLIANCE_FAULT_OR_REPAIR_SKILL, GENERAL_ELECTRICAL_SKILL } from '../common/skill-constants'

const getRequirements = (result: TopicRangehoodActionResult | undefined): TopicRequirementsResult => {
  const { issue, serviceType, outletWorksWithOtherDevice } = result ?? {}

  if (serviceType === ServiceType.Installation) {
    return { requirements: createRequirements([GENERAL_ELECTRICAL_SKILL]) }
  }

  if (serviceType === ServiceType.Repair && issue === GeneralApplianceIssueType.PowerSupply) {
    if (outletWorksWithOtherDevice) {
      return outletWorksWithOtherDevice === YesNoValue.Yes
        ? { requirements: createRequirements([APPLIANCE_FAULT_OR_REPAIR_SKILL]), trade: TradeType.Appliances }
        : { requirements: createRequirements([GENERAL_ELECTRICAL_SKILL]), trade: TradeType.Electrical }
    }
  }
  return { requirements: createRequirements([]) }
}
const getQualification = (result: TopicRangehoodActionResult | undefined): TopicQualificationResult => {
  const { serviceType, issue, outletWorksWithOtherDevice } = result ?? {}

  if (serviceType === ServiceType.Installation) {
    return { qualification: TopicQualification.InScope }
  }

  if (serviceType === ServiceType.Repair) {
    return {
      qualification: TopicQualification.OutOfScope,
      qualificationMessage: 'Repairs on a rangehood job'
    }
  }

  switch (issue) {
    case GeneralApplianceIssueType.PowerSupply:
      if (outletWorksWithOtherDevice) {
        return { qualification: TopicQualification.InScope }
      } else {
        return { qualification: TopicQualification.Unknown }
      }
    case GeneralApplianceIssueType.OtherFault:
      return {
        qualification: TopicQualification.OutOfScope,
        qualificationMessage: getMechanicalIssueOnlyMessage('rangehood')
      }
    default:
      return { qualification: TopicQualification.Unknown }
  }
}
export const outcomeGenerator = createOutcomeGenerator<TopicRangehoodActionResult>(
  { qualification: TopicQualification.Unknown, trade: TradeType.Electrical, type: JobType.Service, category: 'General' },
  getQualification,
  getRequirements
)
