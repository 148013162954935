import { TopicActionResult } from '../action-topic-model'
import { map, merge } from 'lodash-es'

export const reset = (result: TopicActionResult | undefined) => {
  return merge(
    {},
    ...map(Object.keys(result ?? {}), (k) => {
      return { [`${k}`]: undefined }
    })
  )
}
/**
 * Used to reset the result of a topic workflow up to description, Keeping both the description and customerType intact
 * @param result the workflows result object
 * @returns
 */
export const resetTopic = (result: TopicActionResult | undefined) => {
  const { jobDescription, customerType } = result ?? {}
  return merge(
    {},
    ...map(Object.keys(result ?? {}), (k) => {
      return { [`${k}`]: undefined }
    }),
    jobDescription,
    customerType
  )
}
