export const MapsAPI = () => {
  // const appContext = useContext(AppContext)
  // if (appContext.googleApiKey) {
  //   const bodyScripts = document.body.querySelectorAll("script")
  //   const src = `https://maps.googleapis.com/maps/api/js?key=${appContext.googleApiKey}&libraries=places&callback=initAutocomplete`
  //   if (!_.some(bodyScripts, (s) => s.src === src)) {
  //     const script = document.createElement('script')
  //     script.src = src
  //     script.async = true
  //     script.defer = true
  //     document.body.appendChild(script)
  //   }
  // }
  return <></>
}
