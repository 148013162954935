import { useQuery } from '@apollo/client'
import { GetCustomerLinkDocument } from '../api/generated-types'
import { EuiCallOut, EuiLoadingSpinner } from '@elastic/eui'
import CustomerCard from './customer-card'

export interface CustomerCardContainerProps {
  customerId: string
  showReference?: boolean
}

export const CustomerCardContainer = ({ customerId, showReference }: CustomerCardContainerProps) => {
  const { data, error, loading } = useQuery(GetCustomerLinkDocument, {
    variables: {
      id: customerId
    }
  })
  const customer = data?.getCustomer

  return loading ? (
    <EuiLoadingSpinner />
  ) : error ? (
    <EuiCallOut title="Error loading customer" color="danger" />
  ) : customer ? (
    <CustomerCard customer={customer} showReference={showReference} />
  ) : (
    <EuiCallOut title="Customer not found" />
  )
}
