import { useQuery } from '@apollo/client'
import { EuiComboBox, EuiComboBoxOptionOption } from '@elastic/eui'
import { AttributeDetailFragment } from '@fallonsolutions/types'
import { SearchAttributesDocument } from '../api/generated-types'

export interface JobAttributeSelectorProps {
  attributes: AttributeDetailFragment[]
  setSelectedAttributes: (attributeIds: AttributeDetailFragment[]) => void
}
export const JobAttributeSelector = (props: JobAttributeSelectorProps) => {
  const { attributes, setSelectedAttributes } = props

  const { data: attributesData } = useQuery(SearchAttributesDocument, { variables: { input: {} } })

  const allAttributes: AttributeDetailFragment[] = attributesData?.searchAttributes.results ?? []

  const options: EuiComboBoxOptionOption<unknown>[] = allAttributes.map((attribute) => ({
    label: attribute.name,
    id: attribute.id
  }))

  const selectedOptions = attributes.map((attribute) => ({
    label: attribute.name,
    id: attribute.id
  }))

  const onSelectAttribute = (selectedOptions: EuiComboBoxOptionOption<unknown>[]) => {
    const selectedAttributes = allAttributes.filter((attribute) =>
      selectedOptions.some((option) => option.id === attribute.id)
    )
    setSelectedAttributes(selectedAttributes)
  }

  return (
    <>
      <EuiComboBox
        placeholder="Select attributes"
        options={options}
        selectedOptions={selectedOptions}
        onChange={onSelectAttribute}
        isClearable={true}
      />
    </>
  )
}
