import { ApolloError, useMutation, useQuery } from '@apollo/client'
import { EuiButton, EuiCallOut, EuiLink, EuiLoadingSpinner, EuiSpacer } from '@elastic/eui'
import { dateConfig } from '@fallonsolutions/date'
import { JobTopicType } from '@fallonsolutions/types'
import { DateTime } from 'luxon'
import { useState } from 'react'
import {
  CreateJobDocument,
  CreateJobInput,
  CustomerType,
  GetCustomerPropertiesDocument,
  JobCategoryId,
  JobType,
  LegacyBookingWindow,
  TradeType
} from '../../api/generated-types'
import { Callout } from '../../common/callout'
import { Task } from '../../common/task'
import { JobSummaryContainer } from '../../jobs/job-summary-container'

import { WorkflowAction } from '../workflow-action'
import { BaseActionInput, BaseActionResult, WorkflowActionProps } from '../workflow-model'

export interface CreateMembershipActionInput extends BaseActionInput {
  jobCategoryId: JobCategoryId
  customerId: string
}

export interface CreateMembershipActionResult extends BaseActionResult {
  processed?: boolean
  jobId?: string
}

export const CreateMembershipAction = (
  props: WorkflowActionProps<CreateMembershipActionInput, CreateMembershipActionResult>
) => {
  const { onUpdate, result, input } = props
  const { customerId, jobCategoryId } = input
  const { actionCompleted, processed, jobId } = result ?? {}

  const setProcessed = (processed: boolean | undefined) => onUpdate({ ...result, processed })
  const setActionCompleted = (actionCompleted: boolean | undefined) => onUpdate({ ...result, actionCompleted })
  const clearResult = () => onUpdate({ ...result, actionCompleted: false })
  const setJobId = (jobId: string) => onUpdate({ ...result, jobId })

  const { data: customerPropertyData, loading: loadingProperties } = useQuery(GetCustomerPropertiesDocument, {
    variables: { id: customerId }
  })

  const [createRequestError, setCreateRequestError] = useState<ApolloError | Error | undefined>(undefined)

  const firstPropertyId = customerPropertyData?.getCustomer?.properties?.[0]?.id

  const [createJob, { loading: creatingJob }] = useMutation(CreateJobDocument)

  const createHPPJob = async () => {
    if (!customerId || !firstPropertyId || !jobCategoryId) return

    const createJobInput: CreateJobInput = {
      customer: customerId,
      customerId: customerId,
      property: firstPropertyId,
      propertyId: firstPropertyId,
      notes: 'HPP Purchase',
      workRequired: 'HPP Purchase',
      trade: TradeType.None,
      type: JobType.None,
      category: jobCategoryId,
      topic: JobTopicType.HPPPurchase,
      customerType: CustomerType.Domestic,
      startDate: DateTime.now().setZone(dateConfig.defaultTimezone).toISO(),
      bookingWindow: LegacyBookingWindow.ToBeAdvised,
      timeAllowed: 1
    }
    try {
      const jobResponse = await createJob({ variables: { input: createJobInput } })
      const jobId = jobResponse.data?.createJob?.job?.id
      if (jobId) setJobId(jobId)
    } catch (e: any) {
      console.log('createJob mutation error', e?.message)
      setCreateRequestError(e)
    }
  }

  return (
    <WorkflowAction
      title="Create membership(s)"
      value={actionCompleted ? 'Completed' : undefined}
      onClickChange={clearResult}
    >
      {loadingProperties && <EuiLoadingSpinner />}
      {!loadingProperties && (
        <>
          {!firstPropertyId && (
            <>
              <Callout type="warning">Sorry, the customer needs at least one property to continue</Callout>
              <Callout type="note">Go back to the customer step and add a property</Callout>
            </>
          )}
          {firstPropertyId && (
            <>
              {!jobId && (
                <EuiButton color="primary" fill onClick={() => createHPPJob()} isLoading={creatingJob}>
                  Create HPP job
                </EuiButton>
              )}

              {createRequestError && (
                <>
                  <EuiSpacer />
                  <EuiCallOut color="danger" title={`Error occurred when creating job.`}>
                    <p>{createRequestError.message}</p>
                    <p>Details: {JSON.stringify(createRequestError)}</p>
                    <p>Stack: {createRequestError.stack}</p>
                  </EuiCallOut>
                </>
              )}
            </>
          )}

          {jobId && (
            <>
              <div className="workflow__detail-wrapper">
                <JobSummaryContainer jobId={jobId} />
                <EuiSpacer />
                <EuiLink href={`/jobs/${jobId}`} target="_blank">
                  Open job card for more details
                </EuiLink>
              </div>
              <EuiSpacer />
              <Task
                label="I have these processed changes in Keap (InfusionSoft)"
                checked={processed ?? false}
                onChange={setProcessed}
                indent={true}
              />

              <EuiSpacer size="s" />
              <EuiButton disabled={!processed} onClick={() => setActionCompleted(true)}>
                Next
              </EuiButton>
              <EuiSpacer />
            </>
          )}
        </>
      )}
    </WorkflowAction>
  )
}
