import { EuiButton, EuiSpacer } from '@elastic/eui'
import { map } from 'lodash-es'
import { EnquiryAbortReason } from '../../../api/generated-types'
import { Callout } from '../../../common/callout'
import { getResultUpdater } from '../../helpers/results-updater'
import { useWorkflow } from '../../helpers/workflow-provider'
import { SingleSelectField } from '../../question-fields/single-select-question-field'
import { WorkflowActionProps } from '../../workflow-model'
import { TopicActionInput } from '../action-topic-model'
import { TopicWorkflowAction } from '../action-topic-view'
import { TopicQualification } from '../common/common-outcome'
import { PoolChlorinators } from './action-topic-pool-chlorinators'
import { PoolFilters } from './action-topic-pool-filters'
import { PoolHeating } from './action-topic-pool-heating'
import { PoolLeaks } from './action-topic-pool-leaks'
import { PoolIssue, TopicPoolActionResult, poolNotesGenerator } from './action-topic-pool-model'
import { outcomeGenerator } from './action-topic-pool-outcome'
import { PoolPumps } from './action-topic-pool-pumps'

export const TopicPoolAction = (props: WorkflowActionProps<TopicActionInput, TopicPoolActionResult>) => {
  const workflowContext = useWorkflow()
  const { result, input, onUpdate } = props
  const { issue } = result ?? {}

  const notesGenerator = poolNotesGenerator

  const topicOutcome = outcomeGenerator(result)
  const { requirements, qualification } = topicOutcome
  const isOutOfScope = qualification === TopicQualification.OutOfScope

  const onNext = () =>
    onUpdate({
      ...result,
      ...topicOutcome,
      actionCompleted: true,

      workRequiredNotes: generatedNotes
    })

  const generatedNotes = notesGenerator.generateNotes(result ?? {})
  const updateResult = getResultUpdater(result ?? {}, onUpdate).updateResult

  const canComplete = !!requirements

  const renderIssue = (issue: PoolIssue) => {
    switch (issue) {
      case PoolIssue.Filters:
        return <PoolFilters result={result} updateResult={updateResult} notesGenerator={notesGenerator} />
      case PoolIssue.Leaks:
        return <PoolLeaks result={result} updateResult={updateResult} notesGenerator={notesGenerator} />
      case PoolIssue.Heating:
        return <PoolHeating result={result} updateResult={updateResult} notesGenerator={notesGenerator} />
      case PoolIssue.Pumps:
        return <PoolPumps result={result} updateResult={updateResult} notesGenerator={notesGenerator} />
      case PoolIssue.Chlorinators:
        return <PoolChlorinators result={result} updateResult={updateResult} notesGenerator={notesGenerator} />
      case PoolIssue.ChemicalBalancing:
      case PoolIssue.Cleaning:
      case PoolIssue.NewConnections:
      case PoolIssue.PoolBoys:
      case PoolIssue.WaterQuality:
      case PoolIssue.WaterTesting:
      default:
        return undefined
    }
  }

  return (
    <TopicWorkflowAction input={input} onUpdate={onUpdate} result={result}>
      <SingleSelectField
        question={notesGenerator.getQuestion('issue')}
        options={map(PoolIssue, (id) => ({ id }))}
        answer={issue}
        changeAnswer={(issue) => updateResult({ issue })}
      />

      {issue && renderIssue(issue)}

      {isOutOfScope && (
        <>
          <Callout type="script">Unfortunately this type of work is out of scope for us.</Callout>
          <EuiSpacer />
          <EuiButton
            color="warning"
            onClick={() =>
              workflowContext.abort({
                abortReason: EnquiryAbortReason.CustomerNeedsAreOutOfService,
                notes: `Customer was enquiring about Pool: ${issue}`
              })
            }
          >
            Offer a voucher and end call
          </EuiButton>
        </>
      )}

      {requirements && (
        <>
          <EuiSpacer />
          <Callout type="note">{requirements.attributes?.map((r) => r.attributeId).join(', ')}</Callout>
        </>
      )}

      {!isOutOfScope && (
        <>
          <EuiSpacer />
          <EuiButton disabled={!canComplete} onClick={() => onNext()}>
            Next
          </EuiButton>
        </>
      )}
    </TopicWorkflowAction>
  )
}
