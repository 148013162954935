import { EuiSelect, EuiSelectOption } from '@elastic/eui'
import { JobTopicType } from '@fallonsolutions/types'
import { map } from 'lodash-es'
import { decamelise } from '../common/utils'

export interface JobTopicSelectProps {
  value: JobTopicType | undefined
  onChange: (value: JobTopicType) => void
}

export const JobTopicSelect = ({ value, onChange }: JobTopicSelectProps) => {
  const emptyOption: EuiSelectOption = { value: undefined, text: 'Select a topic' }
  const options: EuiSelectOption[] = map(JobTopicType, (value, key) => ({
    value: value,
    text: decamelise(key)
  }))

  return (
    <EuiSelect
      value={value}
      options={[emptyOption, ...options]}
      onChange={(event) => onChange(event.target.value as JobTopicType)}
    />
  )
}
