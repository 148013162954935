import { useMutation } from '@apollo/client'
import { useState } from 'react'
import {
  ContactInput,
  CreateEnquiryForInteractionMessageDocument,
  InteractionMessageCreateEnquiryInput
} from '../api/generated-types'

export interface InteractionMessageCreateEnquiryServiceResponse {
  enquiryId?: string
  loading: boolean
  error?: any
}

export interface CreateEnquiryFnProps<T> {
  interactionMessageId: string
  customerId?: string
  contactId?: string
  contact?: ContactInput
  initialResult?: T
  webFormId?: string
}

export type CreateEnquiryFn<T> = (props: CreateEnquiryFnProps<T>) => Promise<string>
export declare type InteractionMessageCreateEnquiryServiceTuple<T> = [
  CreateEnquiryFn<T>,
  InteractionMessageCreateEnquiryServiceResponse
]

export enum EnquiryPersistenceEnum {
  None = 'None',
  Basic = 'Basic'
}

export const useInteractionMessageCreateEnquiryService = <T,>(): InteractionMessageCreateEnquiryServiceTuple<T> => {
  const [enquiryId, setEnquiryId] = useState<string | undefined>(undefined)
  const [createEnquiry, { loading, error }] = useMutation(CreateEnquiryForInteractionMessageDocument)

  const handleCreateEnquiry: CreateEnquiryFn<T> = async ({
    interactionMessageId,
    customerId,
    contactId,
    contact,
    webFormId
  }) => {
    const input: InteractionMessageCreateEnquiryInput = {
      id: interactionMessageId,
      ...(contactId && { contactId }),
      ...(customerId && { customerId }),
      ...(contact && { contact }),
      webFormId
    }

    const newEnquiryResponse = await createEnquiry({
      variables: { input },
      awaitRefetchQueries: true,
      refetchQueries: ['GetUser', 'GetInteractionMessage']
    })

    const newEnquiryId = newEnquiryResponse.data?.createEnquiryForInteractionMessage?.enquiry?.id
    if (!newEnquiryId) {
      throw new Error('failed to create new enquiry')
    }
    setEnquiryId(newEnquiryId)
    return newEnquiryId
  }
  return [
    handleCreateEnquiry,
    {
      enquiryId,
      loading,
      error
    }
  ]
}
