import { useQuery } from '@apollo/client'
import { EuiCallOut, EuiEmptyPrompt, EuiFlexGroup, EuiFlexItem, EuiLoadingSpinner, EuiText } from '@elastic/eui'
import { validate } from 'email-validator'
import { useDebouncedValue } from 'rooks'
import { ContactDetailFragment, ContactFragment, SearchContactsDocument } from '../api/generated-types'
import { parsePhone } from '../common/phone'
import { ContactListCardView } from './contact-list-card-view'

export interface ContactDuplicateCheckProps {
  contactId?: string
  detail: ContactDetailFragment
  onDuplicateSelected: (contact: ContactFragment) => void
  buttonLabel?: string
}

export const ContactDuplicateCheck = ({
  contactId,
  detail,
  onDuplicateSelected,
  ...props
}: ContactDuplicateCheckProps) => {
  const buttonLabel = props.buttonLabel ?? 'Select'
  const [firstName] = useDebouncedValue(detail.firstName, 500)
  const [lastName] = useDebouncedValue(detail.lastName, 500)
  const [email] = useDebouncedValue(detail.email, 500)
  const [phone] = useDebouncedValue(detail.phone, 500)

  const parsedPhone = parsePhone(phone)?.number.toString()
  const parsedEmail = validate(email) ? email : undefined

  // Require either full email, full phone or full name to search
  const shouldSearch = !!parsedEmail || !!parsedPhone || (!!firstName && !!lastName)

  const { loading, data } = useQuery(SearchContactsDocument, {
    variables: {
      input: {
        filter: {
          ...(contactId && { excludeContactId: [contactId] }), // exclude the current contact from duplicate search
          ...(firstName && { firstName }),
          ...(lastName && { lastName }),
          ...(parsedEmail && { email: parsedEmail }),
          ...(parsedPhone && { phone: parsedPhone }),
          fuzzy: false
        }
      }
    },
    skip: !shouldSearch
  })

  const count = data?.searchContacts.count ?? 0
  const contacts = data?.searchContacts.results ?? []

  const onSelectContact = (contact: ContactFragment | undefined) => {
    if (contact) {
      onDuplicateSelected(contact)
    }
  }

  return (
    <>
      {shouldSearch ? (
        <>
          {loading ? (
            <EuiFlexGroup alignItems="center" gutterSize="s" style={{ padding: '8px', background: '#F7F8FC' }}>
              <EuiFlexItem grow={false}>
                <EuiLoadingSpinner />
              </EuiFlexItem>
              <EuiFlexItem grow={true}>
                <EuiText size="s">Searching for potential duplicates...</EuiText>
              </EuiFlexItem>
            </EuiFlexGroup>
          ) : (
            <EuiCallOut
              title={`${count > 0 ? count : 'No'} potential duplicates found`}
              color={count > 0 ? 'warning' : 'success'}
              size="s"
              iconType={count > 0 ? 'alert' : 'checkInCircleFilled'}
            />
          )}
          {contacts.length > 0 && (
            <div style={{ background: '#F7F8FC', padding: '24px' }}>
              <ContactListCardView
                contacts={contacts}
                buttonLabel={buttonLabel}
                loading={loading}
                compareWith={detail}
                selection={{
                  selectedContactId: contactId,
                  onSelectContact
                }}
              />
            </div>
          )}
        </>
      ) : (
        <EuiEmptyPrompt
          iconType="branchUser"
          iconColor="default"
          title={<h2>Duplicate contact check</h2>}
          titleSize="xs"
          body={<p>Enter full name, phone and/or email</p>}
        />
      )}
    </>
  )
}
