import { EuiButton, EuiCallOut, EuiSpacer, EuiText } from '@elastic/eui'

export interface UnauthorizedErrorProps {
  message?: string
}

export const UnauthorizedError = ({ message }: UnauthorizedErrorProps) => {
  return (
    <EuiCallOut title={message ?? 'You do not seem to be logged in'} iconType="alert" size="m">
      <div>
        Please refresh the page and sign in if required.
        <br />
        <EuiSpacer />
        <EuiButton iconType="refresh" aria-label="refresh" onClick={() => window.location.reload()}>
          Refresh
        </EuiButton>
        <EuiSpacer />
        <p>Please contact support if you need access.</p>
      </div>
      <EuiSpacer />
      <EuiText size="s">If the problem persists please check your network connection.</EuiText>
    </EuiCallOut>
  )
}
