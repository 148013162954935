import { EuiAvatar, EuiBasicTableColumn, EuiFlexGroup, EuiFlexItem } from '@elastic/eui'
import { dateConfig } from '@fallonsolutions/date'
import { MoneyUtils } from '@fallonsolutions/money'
import { JobStatusFragment, JobTopicType, getTopic } from '@fallonsolutions/types'
import { compact, first, isNil } from 'lodash-es'
import { DateTime } from 'luxon'
import { AddressFragment, AttendedStatusType, JobSearchBetaResultFragment, TradeType } from '../api/generated-types'
import { formatMoney } from '../common/money'
import TradeTag from '../common/trade-tag'
import { JobContextMenu } from './job-context-menu'
import { JobStatusBadgeExtended } from './job-status-badge-extended'
import { jobDateString } from './job-utils'

enum ColorScaleOrder {
  Ascending,
  Descending
}

interface ColorScaleProps {
  order: ColorScaleOrder
  min: number
  max: number
  rgb: string
}

const MAX_COLOR_ALPHA = 0.25

const renderAmount = (amount: string, scale: ColorScaleProps) => {
  if (amount) {
    const text = formatMoney(MoneyUtils.fromString(amount), {
      currencySymbol: true
    })
    return renderColorScale(parseFloat(amount), text, scale)
  } else {
    return ''
  }
}

const renderColorScale = (value: number, display: string, scale: ColorScaleProps): any => {
  let normalised = 0
  switch (scale.order) {
    case ColorScaleOrder.Ascending:
      normalised = Math.min((value - scale.min) / scale.max, 1)
      break
    case ColorScaleOrder.Descending:
    default:
      normalised = Math.min((value - scale.min) / scale.max, 1)
  }
  const alpha = normalised * MAX_COLOR_ALPHA
  return (
    <EuiFlexGroup gutterSize="m" style={{ background: `rgba(${scale.rgb}, ${alpha})`, textAlign: 'right' }}>
      <EuiFlexItem>{display}</EuiFlexItem>
    </EuiFlexGroup>
  )
}

const JobStatusColumn: EuiBasicTableColumn<JobSearchBetaResultFragment> = {
  field: 'status',
  name: 'Status',
  truncateText: true,
  width: '230px',
  render: (status: JobStatusFragment) => <JobStatusBadgeExtended status={status} />
}

const AttendedColumn: EuiBasicTableColumn<JobSearchBetaResultFragment> = {
  field: 'attended',
  name: 'Attended',
  truncateText: true,
  width: '80px',
  render: (attended: AttendedStatusType) => (attended === AttendedStatusType.Attended ? 'Attended' : '')
}

export interface SearchJobListColumns {
  currentJobId?: string | undefined
}

export const SearchJobListColumns = (
  props: SearchJobListColumns
): Array<EuiBasicTableColumn<JobSearchBetaResultFragment>> => {
  const { currentJobId } = props
  const columns: Array<EuiBasicTableColumn<JobSearchBetaResultFragment>> = [
    {
      field: 'number',
      name: 'Job No',
      width: '96px',
      render: (value: string, job: JobSearchBetaResultFragment) => {
        const trade = job.tradeType ?? TradeType.None
        return (
          <div>
            <TradeTag trade={trade} style={{ marginRight: '9px' }} />
            {job.id !== currentJobId ? (
              <a href={`/jobs/${job.id}`} target="_blank" rel="noopener noreferrer">
                {job.number}
              </a>
            ) : (
              job.number
            )}
          </div>
        )
      }
    },
    {
      field: 'tradeType',
      name: 'Trade',
      truncateText: true
    },
    {
      field: 'type',
      name: 'Type',
      truncateText: true
    },
    {
      field: 'topic',
      name: 'Topic',
      truncateText: true,
      render: (topic: JobTopicType) => getTopic(topic)?.label ?? ''
    },
    {
      field: 'technicians',
      name: 'Technician',
      render: (technicians: any) => {
        const technician: any = first(technicians)
        if (!technician) {
          return ''
        } else {
          return (
            <a href={`/users/${technician?.id}`} target="_blank" rel="noopener noreferrer" className="truncate">
              <EuiAvatar size="s" name={technician.contactDetail?.fullName ?? 'No name'} imageUrl={technician.avatar} />
              <span style={{ marginLeft: '7px' }}>{technician?.contactDetail?.fullName}</span>
            </a>
          )
        }
      }
    },
    AttendedColumn,
    JobStatusColumn,
    {
      field: 'legacy.startDate',
      name: 'Start date',
      width: '100px',
      render: (startDate: string) => {
        if (!startDate) {
          return ''
        } else {
          const date = DateTime.fromISO(startDate)
          const now = DateTime.now()
          const format = date.year === now.year ? dateConfig.luxonFormat.shortDate : dateConfig.luxonFormat.fullDate
          return date.toFormat(format)
        }
      }
    },
    {
      field: 'id',
      name: 'Date',
      width: '140px',
      truncateText: true,
      render: (id: string, job: JobSearchBetaResultFragment) => jobDateString(job)
    },
    {
      field: 'optionSheet.optionCount',
      name: 'Presented',
      align: 'right',
      width: '60px',
      render: (value?: number) =>
        renderColorScale(!isNil(value) ? value : 0, value ? `${value}` : '', {
          rgb: '13, 255, 60',
          min: 1,
          max: 6,
          order: ColorScaleOrder.Descending
        })
    },
    {
      field: 'optionSheet.acceptedOptionCount',
      name: 'Accepted',
      align: 'right',
      width: '60px',
      render: (value?: number) =>
        renderColorScale(!isNil(value) ? value : 0, value ? `${value}` : '', {
          rgb: '13, 255, 60',
          min: 1,
          max: 6,
          order: ColorScaleOrder.Descending
        })
    },
    {
      field: 'optionSheet.estimates.time',
      name: 'Minutes sold',
      align: 'right',
      width: '60px'
    }
  ]

  return columns
}

export interface JobListColumnsProps {
  showContextMenu?: boolean
}

export const JobListColumns = (props: JobListColumnsProps): Array<EuiBasicTableColumn<JobSearchBetaResultFragment>> => {
  const showContextMenu = props.showContextMenu ?? false

  const columns: Array<EuiBasicTableColumn<JobSearchBetaResultFragment>> = [
    {
      field: 'number',
      name: 'Job No',
      width: '96px',
      render: (value: string, job: JobSearchBetaResultFragment) => {
        const trade = job.tradeType ?? TradeType.None
        return (
          <div>
            <TradeTag trade={trade} style={{ marginRight: '9px' }} />
            <a href={`/jobs/${job.id}`} target="_blank" rel="noopener noreferrer">
              {job.number}
            </a>
          </div>
        )
      }
    },
    {
      field: 'tradeType',
      name: 'Trade',
      truncateText: true
    },
    {
      field: 'type',
      name: 'Type',
      truncateText: true
    },
    {
      field: 'topic',
      name: 'Topic',
      truncateText: true,
      render: (topic: JobTopicType) => getTopic(topic)?.label ?? ''
    },
    {
      field: 'customer',
      name: 'Customer',
      truncateText: true,
      render: (customer: any) => {
        const contact = customer?.mainContact.detail
        const fullName = contact?.fullName ?? 'No name'
        if (!customer) {
          return 'No customer'
        } else {
          return (
            <a className="truncate" href={`/customers/${customer?.id}`} target="_blank" rel="noopener noreferrer">
              {fullName}
            </a>
          )
        }
      }
    },
    {
      field: 'property.address',
      name: 'Address',
      truncateText: true,
      render: (address: AddressFragment | undefined, job: JobSearchBetaResultFragment) => {
        return (
          <a className="truncate" href={`/properties/${job.property?.id}`} target="_blank" rel="noopener noreferrer">
            {compact([address?.street, address?.street2]).join(', ')}
          </a>
        )
      }
    },
    {
      field: 'property.address.suburb',
      name: 'Suburb',
      truncateText: true,
      width: '100px'
    },
    {
      field: 'technicians',
      name: 'Technician',
      render: (technicians: any) => {
        const technician: any = first(technicians)
        if (!technician) {
          return ''
        } else {
          return (
            <a href={`/users/${technician?.id}`} target="_blank" rel="noopener noreferrer" className="truncate">
              <EuiAvatar size="s" name={technician.contactDetail?.fullName ?? 'No name'} imageUrl={technician.avatar} />
              <span style={{ marginLeft: '7px' }}>{technician?.contactDetail?.fullName}</span>
            </a>
          )
        }
      }
    },
    AttendedColumn,
    JobStatusColumn,
    {
      field: 'legacy.startDate',
      name: 'Start date',
      width: '100px',
      render: (startDate: string) => {
        if (!startDate) {
          return ''
        } else {
          const date = DateTime.fromISO(startDate)
          const now = DateTime.now()
          const format = date.year === now.year ? dateConfig.luxonFormat.shortDate : dateConfig.luxonFormat.fullDate
          return date.toFormat(format)
        }
      }
    },
    {
      field: 'id',
      name: 'Date',
      width: '140px',
      truncateText: true,
      render: (id: string, job: JobSearchBetaResultFragment) => jobDateString(job)
    },
    {
      field: 'optionSheet.optionCount',
      name: 'Presented',
      align: 'right',
      width: '60px',
      render: (value?: number) =>
        renderColorScale(!isNil(value) ? value : 0, value ? `${value}` : '', {
          rgb: '13, 255, 60',
          min: 1,
          max: 6,
          order: ColorScaleOrder.Descending
        })
    },
    {
      field: 'optionSheet.acceptedOptionCount',
      name: 'Accepted',
      align: 'right',
      width: '60px',
      render: (value?: number) =>
        renderColorScale(!isNil(value) ? value : 0, value ? `${value}` : '', {
          rgb: '13, 255, 60',
          min: 1,
          max: 6,
          order: ColorScaleOrder.Descending
        })
    },
    {
      field: 'optionSheet.estimates.time',
      name: 'Minutes sold',
      align: 'right',
      width: '60px'
    }
  ]

  const financeColumns: Array<EuiBasicTableColumn<JobSearchBetaResultFragment>> = [
    {
      field: 'sold',
      name: 'Sold',
      width: '100px',
      render: (sold: any) =>
        renderAmount(sold?.total?.amount, {
          rgb: '13, 255, 60',
          min: 200,
          max: 2000,
          order: ColorScaleOrder.Descending
        }),
      align: 'right'
    },
    {
      field: 'invoiced',
      name: 'Invoiced',
      width: '100px',
      render: (invoiced: any) =>
        renderAmount(invoiced?.total?.amount, {
          rgb: '13, 255, 60',
          min: 200,
          max: 2000,
          order: ColorScaleOrder.Descending
        }),
      align: 'right'
    }
  ]

  const dateColumns: Array<EuiBasicTableColumn<JobSearchBetaResultFragment>> = [
    {
      field: 'created',
      name: 'Date booked',
      align: 'right',
      width: '100px',
      render: (value: any) => {
        const created = DateTime.fromISO(value)
        return created.isValid ? created.toRelative({ style: 'narrow' }) : ''
      }
    }
  ]

  const actionColumns: Array<EuiBasicTableColumn<JobSearchBetaResultFragment>> = [
    {
      field: 'id',
      name: '',
      width: '40px',
      render: (id: string, job: JobSearchBetaResultFragment) => {
        return <JobContextMenu job={job} />
      },
      align: 'right'
    }
  ]

  return [...columns, ...financeColumns, ...dateColumns, ...(showContextMenu ? actionColumns : [])]
}
