import { EuiFlexGroup, EuiFlexItem, EuiIcon } from '@elastic/eui'
import { JobRequirement } from '../api/generated-types'

export interface JobRequirementLabelProps {
  requirement: JobRequirement
}

export const JobRequirementLabel = (props: JobRequirementLabelProps) => {
  const { requirement } = props
  let text: string
  let icon: string
  let iconColor: string
  let textColor: string | undefined
  switch (requirement) {
    case JobRequirement.Must:
      text = 'Required'
      icon = 'check'
      iconColor = '#34C759'
      break
    case JobRequirement.Should:
    case JobRequirement.Could:
      text = 'Optional'
      icon = 'minus'
      iconColor = '#ACAFB9'
      textColor = '#ACAFB9'
      break
    case JobRequirement.NotApplicable:
      text = 'Not applicable'
      icon = 'cross'
      iconColor = '#ACAFB9'
      textColor = '#ACAFB9'
      break
  }
  return (
    <EuiFlexGroup gutterSize="none" alignItems="center">
      <EuiFlexItem grow={false} style={{ paddingRight: '3px' }}>
        <EuiIcon type={icon} color={iconColor} />
      </EuiFlexItem>
      <EuiFlexItem style={textColor ? { color: textColor } : {}} className="nowrap">
        {text}
      </EuiFlexItem>
    </EuiFlexGroup>
  )
}
