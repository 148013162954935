import { BaseActionInput, BaseActionResult, WorkflowActionProps } from '../workflow-model'
import { EuiIcon, EuiKeyPadMenu, EuiKeyPadMenuItem, EuiSpacer } from '@elastic/eui'
import { WorkflowAction } from '../workflow-action'
import { Callout } from '../../common/callout'

export enum EmploymentEnquiryType {
  ConfirmingEmployment = 'confirming-employment',
  WorkReference = 'work-reference',
  JobOpportunities = 'job-opportunities'
}

export type EmploymentEnquiryTypeActionInput = BaseActionInput

export interface EmploymentEnquiryTypeActionResult extends BaseActionResult {
  enquiryType?: EmploymentEnquiryType
}

export const EmploymentEnquiryTypeAction = (
  props: WorkflowActionProps<EmploymentEnquiryTypeActionInput, EmploymentEnquiryTypeActionResult>
) => {
  const { result, onUpdate } = props
  const { enquiryType, actionCompleted } = result ?? {}

  const enquiryOptions = [
    {
      id: EmploymentEnquiryType.JobOpportunities,
      label: 'Asking for job opportunities',
      icon: 'questionInCircle',
      isSelected: enquiryType === EmploymentEnquiryType.JobOpportunities
    },
    {
      id: EmploymentEnquiryType.ConfirmingEmployment,
      label: 'Confirming employment',
      icon: 'checkInCircleFilled',
      isSelected: enquiryType === EmploymentEnquiryType.ConfirmingEmployment
    },
    {
      id: EmploymentEnquiryType.WorkReference,
      label: 'Work reference',
      icon: 'quote',
      isSelected: enquiryType === EmploymentEnquiryType.WorkReference
    }
  ]

  const setEnquiryType = (enquiryType: EmploymentEnquiryType | undefined) =>
    onUpdate({ ...result, enquiryType, actionCompleted: true })

  const clearResult = () => onUpdate({})

  const enquiryTypeLabel = enquiryOptions.filter((o) => o.id === enquiryType)?.[0]?.label

  return (
    <WorkflowAction
      title="Employment enquiry type"
      value={actionCompleted ? enquiryTypeLabel : undefined}
      onClickChange={clearResult}
    >
      <Callout type="note" title="Select employment enquiry type" />
      <EuiSpacer />
      <EuiKeyPadMenu className="fs-keypadmenu">
        {enquiryOptions.map((option) => (
          <EuiKeyPadMenuItem
            key={option.id}
            id={option.id}
            label={option.label}
            isSelected={option.isSelected}
            onClick={() => setEnquiryType(option.id)}
          >
            <EuiIcon type={option.icon} size="xl" />
          </EuiKeyPadMenuItem>
        ))}
      </EuiKeyPadMenu>
    </WorkflowAction>
  )
}
