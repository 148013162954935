import { useEffect, useRef, useState } from 'react'
import {
  EuiContextMenu,
  EuiContextMenuPanelDescriptor,
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
  EuiPopover,
  EuiText
} from '@elastic/eui'
import { v4 as uuidv4 } from 'uuid'
import { MediaContext, MediaItemFragment, MediaType } from '../api/generated-types'
import '../static/css/job-photo-uploader.css'
import { merge } from 'lodash-es'
import { DateTime } from 'luxon'
import { dateConfig } from '@fallonsolutions/date'
import { MediaServiceUpload, MediaServiceUploadStatus, UploadFile } from '../media/media-service-upload'
import { useMediaService } from '../media/media-service'
import Dropzone, { Accept } from 'react-dropzone'
import { hash } from '../common/hash-util'

interface JobPhotoUploaderProps {
  jobId: string
  label?: string
  context: MediaContext
  subject?: string
  onCreateMediaItems?: (mediaItems: MediaItemFragment[]) => void
  overrideCompressed?: boolean
  overrideDisplay?: boolean
}

export const JobPhotoUploader = (props: JobPhotoUploaderProps) => {
  const mediaService = useMediaService()

  const { jobId, label, context, onCreateMediaItems } = props
  const [subject, setSubject] = useState<string | undefined>(props.subject ?? undefined)

  const [isContextMenuOpen, setIsContextMenuOpen] = useState(false)

  const filePickerRef = useRef<any>()

  const [fileList, setFileList] = useState<FileList | File[] | null>(null)

  const handleFileSelect = async () => {
    if (!fileList?.length) return
    // Only allow image types
    const files = Array.from(fileList) //.filter((file: File) => /^image\/(png|jpe?g)$/.test(file.type))

    // Determine width and height for each image before upload
    // Why do we need this? Storing dimensions against the media item helps with rendering display prior to image download
    const uploadFiles = await Promise.all<UploadFile>(
      files.map(async (file: File) => {
        const fileBuffer = await file.arrayBuffer()
        const sha256 = await hash(fileBuffer)
        console.log('sha256 for file', sha256)
        return new Promise((resolve) => {
          if (file.type.startsWith('image/')) {
            // Create image in memory to get dimensions so we can include them in tags
            const img = new Image()
            img.addEventListener('load', () => {
              const width = img.width
              const height = img.height
              window.URL.revokeObjectURL(img.src) // Free some memory
              resolve(merge(file, { width, height, sha256 }))
            })
            img.src = window.URL.createObjectURL(file)
          } else {
            resolve(merge(file, { sha256 }))
          }
        })
      })
    )

    const uploads: MediaServiceUpload[] = uploadFiles.map((file) => {
      const isImage = file.type.startsWith('image/')
      return {
        id: uuidv4(),
        file,
        ...(!isImage && { name: file.name }),
        type: isImage ? MediaType.Image : MediaType.Document,
        date: (DateTime.fromMillis(file.lastModified) ?? DateTime.now()).setZone(dateConfig.defaultTimezone),
        jobId,
        context,
        subject: subject ?? 'General',
        status: MediaServiceUploadStatus.Saving,
        progress: 0
      }
    })
    console.log('kicking off upload through media service')
    await mediaService.upload(jobId, uploads, onCreateMediaItems)
    setFileList(null)
    filePickerRef.current?.removeFiles()
    setSubject(props.subject ?? undefined)
  }

  useEffect(() => {
    if (fileList && fileList?.length > 0) {
      handleFileSelect()
    }
  }, [fileList])

  const panelId = 'select-subject-panel'
  const panels: EuiContextMenuPanelDescriptor[] = [
    {
      id: panelId,
      title: 'Add subject',
      items: ['Air Conditioner', 'Form', 'Test Result', 'General'].map((subject) => ({
        name: subject,
        onClick: () => {
          setSubject(subject)
          setIsContextMenuOpen(false)
        }
      }))
    }
  ]

  const selectSubjectButton = (
    <div onClick={() => setIsContextMenuOpen(!isContextMenuOpen)}>
      <EuiIcon type="plus" size="xl" color="primary" />
    </div>
  )

  const handleDrop = (dropped: File[]) => setFileList(dropped)

  const accept: Accept = {
    'image/png': ['.png'],
    'image/jpeg': ['.jpg', '.jpeg'],
    'image/webp': ['.webp'],
    'application/pdf': ['.pdf']
  }

  return (
    <>
      {subject ? (
        <Dropzone onDrop={handleDrop} noKeyboard accept={accept}>
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()} className="job-photo-uploader" style={{ width: '100%', height: '100%' }}>
              <EuiFlexGroup
                alignItems="center"
                justifyContent="center"
                direction="column"
                gutterSize="none"
                className="job-photo-uploader__button"
              >
                <EuiFlexItem grow={false} className="job-photo-uploader__icon">
                  <EuiIcon size="l" type="plus" color="primary" />
                  <input {...getInputProps()} capture accept="image/*" />
                </EuiFlexItem>
                {label && (
                  <EuiFlexItem grow={false}>
                    <EuiText size="m" color="#006bb8" style={{ fontWeight: 500 }}>
                      {label}
                    </EuiText>
                  </EuiFlexItem>
                )}
              </EuiFlexGroup>
            </div>
          )}
        </Dropzone>
      ) : (
        <EuiPopover
          id="select-subject-popover"
          button={selectSubjectButton}
          isOpen={isContextMenuOpen}
          closePopover={() => setIsContextMenuOpen(false)}
          panelPaddingSize="none"
          anchorPosition="downLeft"
        >
          <EuiContextMenu panels={panels} initialPanelId={panelId} />
        </EuiPopover>
      )}
    </>
  )
}
