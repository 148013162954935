import { EuiFacetButton, EuiFacetGroup, EuiIcon } from '@elastic/eui'
import { filter, includes } from 'lodash-es'
import { decamelise } from './utils'

interface EnumFacetsProps {
  enumType: any
  types: any[]
  setTypes: (facets: any[]) => void
  labels?: { [key in string]: string }

  allowExclude?: boolean
  typesExclude?: any[]
  setTypesExclude?: (facets: any[]) => void
}

const EnumTypeFacets = (props: EnumFacetsProps) => {
  const { labels, allowExclude, enumType, types, setTypes, typesExclude, setTypesExclude } = props

  const offColor = '#E5E8F0'
  const includeColor = '#34C759'
  const excludeColor = '#C73434'

  const options = Object.keys(enumType)

  type Facet = ReturnType<typeof typeToFacet>
  const typeToFacet = (type: string) => {
    const included = includes(types, enumType[type] ?? type)
    const excluded = allowExclude && includes(typesExclude, enumType[type] ?? type)
    const enabled = included || excluded
    return {
      id: type,
      label: labels?.[enumType[type]] ?? decamelise(enumType[type] ?? type),
      value: enumType[type] ?? type,
      enabled: enabled,
      included,
      excluded,
      iconColor: included ? includeColor : excluded ? excludeColor : offColor
    }
  }

  const facets = options.map(typeToFacet)

  const onClickFacet = (facet: Facet) => {
    if (allowExclude && facet.excluded) {
      setTypesExclude?.(filter(typesExclude, (t) => t !== facet.value))
    } else if (facet.included) {
      setTypes(filter(types, (t) => t !== facet.value))
      setTypesExclude?.(typesExclude?.concat(facet.value) ?? [])
    } else {
      setTypes(types.concat(facet.value))
    }
  }

  return (
    <EuiFacetGroup layout="horizontal">
      {facets.map((facet) => (
        <EuiFacetButton
          key={facet.id}
          icon={<EuiIcon type="dot" color={facet.iconColor} />}
          isSelected={facet.enabled}
          onClick={() => onClickFacet(facet)}
          style={{ minInlineSize: '40px', padding: '0px' }}
        >
          {facet.label}
        </EuiFacetButton>
      ))}
    </EuiFacetGroup>
  )
}

export default EnumTypeFacets
