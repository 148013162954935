import { ApolloClient, ApolloLink, ApolloProvider, InMemoryCache, NormalizedCacheObject } from '@apollo/client'
import { loadDevMessages, loadErrorMessages } from '@apollo/client/dev'
import { onError } from '@apollo/client/link/error'
import { EuiLoadingSpinner } from '@elastic/eui'
import { LocalStorageWrapper, persistCache } from 'apollo3-cache-persist'
import { useCallback, useContext, useEffect, useState } from 'react'
import { useSession } from '../auth/session-context'
import createPersistedState from '../use-persisted-state'
import { AppContext } from './app-context'
import { createAppSyncAuthLink } from './data-layer/auth-link'
import { createAppSyncSubscriptionHandshakeLink } from './data-layer/subscription-link'
import { possibleTypes } from './graphql-possible-types'
import { typePolicies } from './graphql-type-policies'

const useApolloPersistedCache = createPersistedState<boolean>('apollo-persisted-cache')

//import { RetryLink } from '@apollo/client/link/retry'

interface GraphQLProviderProps {
  children: React.ReactNode | React.ReactNode[] | null
}

const GraphQLProvider = (props: GraphQLProviderProps) => {
  const appContext = useContext(AppContext)
  const session = useSession()

  const [persistedCache] = useApolloPersistedCache(false)
  const [client, setClient] = useState<ApolloClient<NormalizedCacheObject> | undefined>(undefined)

  const region = appContext.region
  const url = appContext.graphqlUrl
  const connectToDevTools = appContext.isDev || appContext.isTest

  //works
  // const domain = 'fsplatformdev.net'
  // document.cookie = `media_token=${idToken}; SameSite=Strict; Secure; Domain=${domain}`

  const initClient = useCallback(async () => {
    console.debug('graphql: initClient')
    console.time('graphql: initClient')
    // if (!session.data?.user?.token || !session.data?.user?.accessToken) {
    //   return
    // }
    const idToken = session.data?.user?.token ?? 'missing'
    const jwtToken = session.data?.user?.accessToken ?? 'missing'
    const authLink = createAppSyncAuthLink({ idToken, region, url })
    const subscriptionLink = createAppSyncSubscriptionHandshakeLink({ jwtToken, region, url })

    // const retryLink = new RetryLink({
    //   delay: {
    //     initial: 300,
    //     max: Infinity,
    //     jitter: true
    //   },
    //   attempts: {
    //     max: 5,
    //     retryIf: (error, _operation) => {
    //       console.log('RetryLink:retryIf', error, _operation)
    //       return !!error
    //     }
    //   }
    // })

    const errorLink = onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) =>
          console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
        )
      if (networkError) console.log(`[Network error]: ${networkError}`)
    })

    const link = ApolloLink.from([errorLink, authLink, subscriptionLink])

    const cache = new InMemoryCache({ typePolicies, possibleTypes })

    if (persistedCache) {
      console.log('graphql: using persisted cache')
      try {
        await persistCache({
          cache,
          maxSize: 1048576 * 10, // 10MB
          debounce: 1000, // default
          storage: new LocalStorageWrapper(window.localStorage)
        })
      } catch (err) {
        console.warn('Unable to use local storage for apollo cache, falling back to in memory cache', err)
      }
    } else {
      console.log('graphql: using in memory cache')
    }
    console.log('graphql: cache garbage collection')
    cache.gc()
    console.log('graphql: setting apolloClient')
    const newClient = new ApolloClient({
      cache,
      link,
      connectToDevTools,
      defaultOptions: {
        watchQuery: {
          fetchPolicy: persistedCache ? 'cache-and-network' : 'cache-first' // useQuery
        },
        query: {
          fetchPolicy: persistedCache ? 'network-only' : 'cache-first'
        }
      }
    })
    setClient(newClient)
    window.apolloClient = newClient

    if (connectToDevTools) {
      // Adds messages only in a dev environment
      loadDevMessages()
      loadErrorMessages()
    }
    console.timeEnd('graphql: initClient')
  }, [connectToDevTools, session.data?.user?.token, session.data?.user?.accessToken, region, url, persistedCache])

  useEffect(() => {
    initClient()
  }, [initClient])

  // Clear local storage cache if persistedCache is toggled off
  useEffect(() => {
    if (!persistedCache) {
      console.log('graphql: clearing cache')
      window.localStorage.removeItem('apollo-cache-persist')
    }
  }, [persistedCache])

  return client ? <ApolloProvider client={client}>{props.children}</ApolloProvider> : <EuiLoadingSpinner />
}

export default GraphQLProvider

declare global {
  interface Window {
    apolloClient: ApolloClient<NormalizedCacheObject> | undefined
  }
}
