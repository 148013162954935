import { useQuery } from '@apollo/client'
import { EuiBasicTable, EuiCallOut, EuiLoadingSpinner, EuiSpacer, EuiTitle } from '@elastic/eui'
import { Fragment } from 'react'
import { CustomerFragment, GetCustomerInteractionMessagesDocument } from '../api/generated-types'
import { InteractionMessageListColumns } from '../interactions/interaction-message-list/interaction-message-list-columns'

interface CustomerInteractionMessagesProps {
  customer: CustomerFragment
}

export const CustomerInteractionMessages = (props: CustomerInteractionMessagesProps) => {
  const { customer } = props

  const columns = InteractionMessageListColumns({})

  const { data, loading } = useQuery(GetCustomerInteractionMessagesDocument, {
    variables: {
      customerId: customer.id
    }
  })
  const interactionMessages = data?.getCustomer?.interactionMessages ?? []

  return (
    <Fragment>
      <EuiTitle size="s">
        <h1>Calls</h1>
      </EuiTitle>

      <EuiSpacer />
      {loading ? (
        <EuiLoadingSpinner />
      ) : interactionMessages ? (
        <EuiBasicTable items={interactionMessages} columns={columns} />
      ) : (
        <EuiCallOut color="danger">Error loading calls</EuiCallOut>
      )}
    </Fragment>
  )
}
