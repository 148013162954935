import { EuiBadge, EuiFlexGroup, EuiFlexItem, EuiLink, EuiPopover, useEuiTheme } from '@elastic/eui'
import { useState } from 'react'

import { useHistory } from 'react-router-dom'
import { InteractionMessageLinkFragment } from '../api/generated-types'
import { InteractionMessageCard, InteractionMessageCardDisplay } from './interaction-message-card'

export interface RecentInteractionMessageMenuProps {
  interactionMessages: InteractionMessageLinkFragment[]
}

export const RecentInteractionMessageMenu = ({ interactionMessages }: RecentInteractionMessageMenuProps) => {
  const history = useHistory()

  const { euiTheme } = useEuiTheme()

  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const menuButton = (
    <EuiBadge
      color={euiTheme.colors.darkestShade}
      iconType="arrowDown"
      iconSide="right"
      onClick={() => setIsMenuOpen(!isMenuOpen)}
      onClickAriaLabel="InteractionMessage menu"
    >
      Recent calls ({interactionMessages.length})
    </EuiBadge>
  )

  const onSelectInteractionMessage = (interactionMessage: InteractionMessageLinkFragment) => {
    history.push(`/interaction-message/${interactionMessage?.id}`)
    setIsMenuOpen(false)
  }
  const backToHome = () => {
    history.push('/')
    setIsMenuOpen(false)
  }

  return (
    <EuiPopover
      id="settingsPanel"
      button={menuButton}
      isOpen={isMenuOpen}
      closePopover={() => setIsMenuOpen(false)}
      panelPaddingSize="s"
      anchorPosition="downCenter"
    >
      <EuiFlexGroup direction="column" gutterSize="none">
        {interactionMessages.map((interactionMessage) => (
          <EuiFlexItem
            grow={false}
            key={interactionMessage.id}
            onClick={() => onSelectInteractionMessage(interactionMessage)}
            style={{ cursor: 'pointer', borderBottom: '1px solid #F7F8FC', padding: '6px 0' }}
          >
            <InteractionMessageCard
              interactionMessage={interactionMessage}
              display={InteractionMessageCardDisplay.ContextMenuItem}
            />
          </EuiFlexItem>
        ))}
        <EuiFlexItem grow={false} key="all" style={{ padding: '15px 0 6px' }}>
          <EuiFlexGroup gutterSize="none">
            <EuiFlexItem grow={true} />
            <EuiFlexItem grow={false}>
              <EuiLink onClick={backToHome}>View all calls</EuiLink>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiPopover>
  )
}
