import {
  EuiBreadcrumbs,
  EuiButtonIcon,
  EuiContextMenu,
  EuiContextMenuPanelDescriptor,
  EuiFlexGroup,
  EuiFlexItem,
  EuiLoadingSpinner,
  EuiPopover
} from '@elastic/eui'
import { useState } from 'react'
import { WorkflowFragment } from '../api/generated-types'
import VerticalDivider from '../common/vertical-divider'

export interface WorkflowV2ViewHeaderProps {
  workflow: WorkflowFragment
  loading?: boolean
  onBack: () => void
  onReset: () => void
}

export const WorkflowV2Header = ({ loading, workflow, onBack, onReset }: WorkflowV2ViewHeaderProps) => {
  const [isContextMenuOpen, setIsContextMenuOpen] = useState(false)

  const breadcrumbs = workflow.steps.length
    ? workflow.steps.map((step) => ({
        text: step.choice?.label ?? step.step.name
      }))
    : [{ text: 'Workflow' }]

  const closeContextMenu = () => setIsContextMenuOpen(false)

  const contextMenuPanels: EuiContextMenuPanelDescriptor[] = [
    {
      id: 0,
      items: [
        {
          name: 'Reset workflow',
          icon: 'refresh',
          onClick: () => {
            onReset()
            closeContextMenu()
          }
        }
      ]
    }
  ]

  return (
    <EuiFlexGroup alignItems="center" gutterSize="m">
      <EuiFlexItem grow={false}>
        <EuiButtonIcon
          aria-label="Back"
          iconType="arrowLeft"
          onClick={onBack}
          isDisabled={loading}
          color="text"
          display="empty"
        />
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <VerticalDivider height="24px" />
      </EuiFlexItem>
      <EuiFlexItem grow={true}>
        <EuiBreadcrumbs max={4} breadcrumbs={breadcrumbs} aria-label="Workflow steps" />
      </EuiFlexItem>
      {loading && (
        <EuiFlexItem grow={false}>
          <EuiLoadingSpinner size="m" />
        </EuiFlexItem>
      )}
      <EuiFlexItem grow={false}>
        <VerticalDivider height="24px" />
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        <EuiPopover
          isOpen={isContextMenuOpen}
          button={
            <EuiButtonIcon
              aria-label="Show actions"
              iconType="boxesHorizontal"
              color="text"
              onClick={() => setIsContextMenuOpen(true)}
            />
          }
          closePopover={() => setIsContextMenuOpen(false)}
          ownFocus={true}
          panelPaddingSize="none"
        >
          <EuiContextMenu initialPanelId={0} panels={contextMenuPanels} />
        </EuiPopover>
      </EuiFlexItem>
    </EuiFlexGroup>
  )
}
