import { EuiButton, EuiSpacer } from '@elastic/eui'
import { Callout } from '../../common/callout'
import { PillGroup, PillGroupOption } from '../../pill/pill-group'
import { ContactActionResult } from './action-contact'
import { CustomerAction, CustomerActionResult } from './action-customer'
import { CustomerThreateningLegalOrMediaAction } from './action-customer-threatening-legal-or-media'
import { TransferToCustomerExperienceManagerAction } from './action-transfer-to-customer-experience-manager'
import { WorkflowAction } from '../workflow-action'
import { BaseActionInput, BaseActionResult, WorkflowActionProps } from '../workflow-model'

export enum ComplaintTypeReference {
  SignificantPropertyDamage = 'complaint-significant-property-damage',
  DangerousTechnicalIssue = 'complaint-dangerous-technical-issue',
  InjuryOrDeath = 'complaint-injury-or-death',
  Other = 'complaint-other'
}

export interface CrisisActionInput extends BaseActionInput {
  contact?: ContactActionResult
}
export interface CrisisActionResult extends BaseActionResult {
  customer?: CustomerActionResult
  crisisType?: ComplaintTypeReference
  callTransferredToManager?: boolean
  customerThreateningLegalOrMediaAction?: boolean
}

export const CrisisAction = (props: WorkflowActionProps<CrisisActionInput, CrisisActionResult>) => {
  const { onUpdate, result, input } = props
  const { contact } = input
  const { customer } = result ?? {}

  const setCustomer = (customer: CustomerActionResult) => onUpdate({ ...result, customer })
  const onCompleted = () => onUpdate({ ...result, actionCompleted: true })

  return (
    <>
      <CustomerAction input={{ contact }} result={customer} onUpdate={setCustomer} />

      <CrisisTypeAction input={{}} result={result} onUpdate={onUpdate} onBack={() => console.log('back')} />

      {!!result?.crisisType && (
        <>
          <TransferToCustomerExperienceManagerAction
            input={{
              situationRequiringEmergencyServices: result.crisisType === ComplaintTypeReference.InjuryOrDeath,
              hasFirstCallResolutionBeenOffered: false
            }}
            result={result}
            onUpdate={(resultFragment) => onUpdate({ ...result, ...resultFragment })}
            onBack={() => console.log('back')}
          />
        </>
      )}

      <EuiSpacer size="s" />
      <CustomerThreateningLegalOrMediaAction
        input={result ?? {}}
        result={result}
        onUpdate={(resultFragment) => onUpdate({ ...result, ...resultFragment })}
        onBack={() => console.log('back')}
      />

      {!!result?.crisisType && result.callTransferredToManager !== undefined && (
        <>
          <EuiSpacer />
          <EuiButton fill onClick={onCompleted}>
            I've completed these steps
          </EuiButton>
        </>
      )}
    </>
  )
}

const CrisisTypeAction = (props: WorkflowActionProps<CrisisActionInput, CrisisActionResult>) => {
  const { onUpdate, result } = props

  const crisisTypeOptions: PillGroupOption[] = [
    {
      id: ComplaintTypeReference.SignificantPropertyDamage,
      label: 'Significant property damage',
      icon: 'color'
    },
    { id: ComplaintTypeReference.DangerousTechnicalIssue, label: 'Dangerous technical issue', icon: 'bolt' },
    { id: ComplaintTypeReference.InjuryOrDeath, label: 'Someone has been injured or died', icon: 'alert' }
  ]

  const handleOnSelectCrisisType = (id: string) => {
    onUpdate({ crisisType: id as ComplaintTypeReference })
  }

  const handleChange = () => {
    onUpdate({ crisisType: undefined })
  }

  const crisisTypeLabel = crisisTypeOptions.filter((o) => o.id === result?.crisisType)?.[0]?.label

  return (
    <WorkflowAction title="Crisis type" value={crisisTypeLabel} onClickChange={handleChange}>
      <Callout type="script">
        <ul>
          <li>I'm sorry to hear that</li>
          <li>Can you can you explain in your own words what has happened?</li>
        </ul>
      </Callout>
      <Callout type="note" title="Gather all details">
        <ul>
          <li>Gather all customer details, job details and details of damage to property.</li>
          <li>Remain calm, friendly and empathetic. Listen to customer, do not interrupt and take notes.</li>
        </ul>
      </Callout>
      <EuiSpacer />
      <PillGroup
        options={crisisTypeOptions}
        value={result?.crisisType}
        onSelect={handleOnSelectCrisisType}
        onClear={handleChange}
      />
    </WorkflowAction>
  )
}
