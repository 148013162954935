import { EuiDescriptionList, EuiFlexGroup, EuiFlexItem, EuiPanel, EuiSpacer } from '@elastic/eui'
import { dateConfig } from '@fallonsolutions/date'
import { DateTime } from 'luxon'
import ReactMarkdown from 'react-markdown'
import { EnquiryFragment, GetJobPreviewDocument, JobPreviewFragment } from '../../api/generated-types'
import { formatCurrency } from '../../common/metrics'
import { PreviewCard } from '../../common/preview/preview-card'
import RawJSON from '../../common/raw-json'
import TradeTag from '../../common/trade-tag'
import { decamelise } from '../../common/utils'

export interface InteractionMessageDetailEnquiryDetailProps {
  enquiry: EnquiryFragment
  label?: string
}

const formatDate = (date: string): string => {
  return DateTime.fromISO(date).toFormat(dateConfig.luxonFormat.fullDateAndTime)
}

export const InteractionMessageDetailEnquiryDetail = ({
  enquiry,
  label
}: InteractionMessageDetailEnquiryDetailProps) => {
  const renderJob = (job: JobPreviewFragment | undefined) => {
    if (!job) {
      return <></>
    }
    return (
      <div style={{ padding: '6px' }}>
        {/* <EuiFlexGroup gutterSize="s">
          <EuiFlexItem grow={false}>
            <TradeTag trade={job.trade ?? 'none'} />
          </EuiFlexItem>
          <EuiFlexItem grow={false}>{job.type}</EuiFlexItem>
          <EuiFlexItem grow={false}>{job.topic}</EuiFlexItem>
        </EuiFlexGroup> */}
        <EuiFlexGroup gutterSize="m">
          <EuiFlexItem grow={false}>
            <div className="small-label">Trade</div>
            <span className="truncate">
              <TradeTag trade={job.tradeType ?? 'none'} />
              <span style={{ marginLeft: '3px' }}>{job.tradeType}</span>
            </span>
          </EuiFlexItem>
          <EuiFlexItem grow={true} />
          <EuiFlexItem grow={false} style={{ textAlign: 'right' }}>
            <div className="small-label truncate">Sold</div>
            <span>{formatCurrency(parseFloat(job.sold?.total.amount ?? '0.00'))}</span>
          </EuiFlexItem>
          <EuiFlexItem grow={false} style={{ textAlign: 'right' }}>
            <div className="small-label truncate">Invoiced</div>
            <span>{formatCurrency(parseFloat(job.invoiced?.total.amount ?? '0.00'))}</span>
          </EuiFlexItem>
        </EuiFlexGroup>
        <EuiSpacer size="m" />
        <div style={{ maxWidth: '420px', fontSize: '12px' }}>
          <ReactMarkdown children={job.workRequired ?? ''} className="markdown-body" />
        </div>
      </div>
    )
  }

  const list = [
    {
      title: 'Type',
      description: enquiry.type ? decamelise(enquiry.type) : ''
    },
    {
      title: 'Date',
      description: formatDate(enquiry.created)
    },
    ...(enquiry.topicReference ? [{ title: 'Topic', description: enquiry.topicReference }] : []),
    ...(enquiry.outcome ? [{ title: 'Outcome', description: enquiry.outcome }] : []),
    ...(enquiry.aborted
      ? [
          { title: 'Aborted', description: enquiry.aborted.reason },
          ...(enquiry.aborted.notes ? [{ title: 'Aborted notes', description: enquiry.aborted.notes }] : [])
        ]
      : [])
  ]

  return (
    <>
      <EuiPanel>
        <EuiDescriptionList listItems={list} />
        {enquiry.job && (
          <>
            <EuiSpacer />
            <PreviewCard
              query={GetJobPreviewDocument}
              variables={{ id: enquiry.job.id }}
              modelName="job"
              title={`Job ${enquiry.job.number ?? ''}`}
              link={{ label: 'Open job', url: `/jobs/${enquiry.job.id}` }}
            >
              {({ data }) => renderJob(data.getJob ?? undefined)}
            </PreviewCard>
          </>
        )}
      </EuiPanel>
      <RawJSON data={enquiry} />
    </>
  )
}
