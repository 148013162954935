import {
  EuiBasicTable,
  EuiBasicTableColumn,
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
  EuiRadio,
  EuiSpacer
} from '@elastic/eui'
import { Property } from '@fallonsolutions/property'
import { useState } from 'react'
import { GetCustomerPropertiesQuery, Membership, MembershipLevel, MembershipStatusType } from '../api/generated-types'
import { ContactRole, CustomerQualificationActionResult } from '../workflow/actions/action-customer-qualification'

import { Callout } from '../common/callout'
import PremiumMemberLogo from '../static/images/member-logo-premium.svg'
import StandardMemberLogo from '../static/images/member-logo-standard.svg'
import { ExpiredMembershipsAccordion } from '../workflow/actions/customer-qualifications/customer-expired-membership-accordin'

interface PropertySearchResultsProps {
  data?: GetCustomerPropertiesQuery
  loading: boolean
  onSelect: (property: Property) => void
  onNew: () => void
  customerMemberships?: Membership[]
  customerQualification?: CustomerQualificationActionResult
}

enum ResultDisplayType {
  Table,
  HorizontalCards,
  Grid
}

export const PropertySearchResults = (props: PropertySearchResultsProps) => {
  const { loading, data, onSelect, onNew, customerQualification } = props
  const { customerMemberships, contactRole } = customerQualification ?? {}
  const showMemberships = contactRole === ContactRole.HomeOwner
  const [displayType] = useState(ResultDisplayType.Table)
  const [highlightedProperty, setHighlightedProperty] = useState<Property | undefined>()
  const [newPropertyRequested, setNewPropertyRequested] = useState(false)
  const properties = (data?.getCustomer?.properties || []) as any as Property[]
  //const canContinueWithHighlightedProperty = highlightedProperty &&

  const doesHighlightedPropertyHaveAMembership = customerMemberships
    ?.filter((m) => m.status.status === MembershipStatusType.Active)
    .find((membership) => membership.propertyId === highlightedProperty?.id)

  const createResultView = () => {
    switch (displayType) {
      case ResultDisplayType.Table:
        return (
          <>
            {showMemberships && <ExpiredMembershipsAccordion memberships={customerMemberships} />}
            <PropertyTable
              properties={loading ? [] : properties}
              onSelect={(property) => {
                setHighlightedProperty(property)
                setNewPropertyRequested(false)
              }}
              loading={loading}
              selectedProperty={highlightedProperty?.id}
              customerMemberships={customerMemberships}
              showMembership={showMemberships}
            />
            {!loading &&
              (properties.length ? (
                <>
                  <div style={{ padding: '12px 6px', fontSize: '15px', color: '#999', fontWeight: 300 }}>OR</div>

                  <div className="basic-table--minimal">
                    <div className="euiTable euiTable--responsive">
                      <div className="euiTableRow euiTableRow-isClickable">
                        <div
                          className="euiTableRowCell"
                          onClick={() => {
                            setHighlightedProperty(undefined)
                            setNewPropertyRequested(true)
                          }}
                        >
                          <div className="euiTableCellContent euiTableCellContent--overflowingContent">
                            <EuiFlexGroup gutterSize="none" alignItems="center">
                              <EuiFlexItem grow={false} style={{ paddingRight: '24px' }}>
                                <EuiRadio
                                  onChange={() => {
                                    setHighlightedProperty(undefined)
                                    setNewPropertyRequested(true)
                                  }}
                                  checked={newPropertyRequested}
                                />
                              </EuiFlexItem>
                              <EuiFlexItem data-test-id="create-new-property-radio" style={{ userSelect: 'none' }}>
                                Create new property
                              </EuiFlexItem>
                            </EuiFlexGroup>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {doesHighlightedPropertyHaveAMembership && highlightedProperty?.id && (
                    <>
                      <Callout
                        type="script"
                        title="As one of our members you will enjoy 15% off any work booked today"
                      />
                      <EuiSpacer size="s" />
                    </>
                  )}

                  <EuiSpacer />
                  <EuiButton
                    disabled={!highlightedProperty && !newPropertyRequested}
                    onClick={() => (highlightedProperty ? onSelect(highlightedProperty) : onNew())}
                    color="primary"
                    fill
                    data-test-id="select-property-button"
                  >
                    Select
                  </EuiButton>
                </>
              ) : (
                <>
                  <EuiSpacer />
                  <EuiButton onClick={onNew} color="primary" fill>
                    Create new property
                  </EuiButton>
                </>
              ))}
          </>
        )
      case ResultDisplayType.HorizontalCards:
      case ResultDisplayType.Grid:
        return <span>Not implemented</span>
    }
  }

  return <>{createResultView()}</>
}

export interface PropertyTableProps {
  selectedProperty: string | undefined
  properties: Property[]
  onSelect: (property: Property) => void
  loading: boolean
  additionalClassName?: string
  customerMemberships?: Membership[]
  showMembership?: boolean // e.g customer is commercial so show memberships will be false
}

export const PropertyTable = (props: PropertyTableProps) => {
  const { properties, onSelect, loading, selectedProperty, additionalClassName, customerMemberships, showMembership } =
    props
  const activeCustomerMemberships = customerMemberships?.filter((m) => m.status.status === MembershipStatusType.Active)
  const canViewMemberships = showMembership ?? true
  const columns: EuiBasicTableColumn<Property>[] = [
    {
      field: 'id',
      name: '',
      width: '40px',
      render: (id: string, property: Property) => (
        <EuiRadio checked={id === selectedProperty} onChange={() => onSelect(property)} />
      )
    },
    ...(canViewMemberships
      ? [
          {
            field: 'id',
            name: '',
            width: '40px',
            render: (id: string, property: Property) => {
              const propertyMembership = activeCustomerMemberships?.find((m) => m.propertyId === property.id)
              if (!propertyMembership) return <></>
              return (
                <EuiIcon
                  type={propertyMembership.level === MembershipLevel.Premium ? PremiumMemberLogo : StandardMemberLogo}
                  style={{ marginRight: '6px' }}
                  size="l"
                />
              )
            }
          }
        ]
      : []),
    {
      field: 'formattedAddress',
      name: 'Address'
    }
  ]
  const onClickRow = (property: any) => {
    onSelect(property)
  }

  return (
    <EuiBasicTable
      className={`basic-table--minimal ${additionalClassName}`}
      loading={loading}
      items={properties}
      columns={columns}
      data-test-subj="property-search-results-table"
      rowProps={(row) => ({ onClick: () => onClickRow(row), 'data-test-id': 'property-search-result-row' })}
      noItemsMessage={loading ? 'Loading...' : 'No properties found'}
    />
  )
}
