import { useQuery } from '@apollo/client'
import { EuiFlexGroup, EuiFlexItem, EuiLoadingSpinner, EuiSpacer } from '@elastic/eui'
import { dateConfig, dateUtils } from '@fallonsolutions/date'
import { ISODate } from '@fallonsolutions/scalar'
import { compact, first } from 'lodash-es'
import {
  AppointmentLinkFragment,
  GetAppointmentDocument,
  ScheduleEventStatusType,
  UserFragment
} from '../api/generated-types'
import { ScheduleEventStatusBadge } from '../schedule/schedule-event-status-badge'
import UserLink from '../users/user-link'

export interface JobSummaryTimelineVisitProps {
  appointment: AppointmentLinkFragment
}

export const JobSummaryTimelineVisit = ({ appointment }: JobSummaryTimelineVisitProps) => {
  const { data, loading } = useQuery(GetAppointmentDocument, {
    variables: {
      id: appointment.id
    }
  })

  const appointmentDetail = data?.getAppointment
  const scheduleEvent = first(appointmentDetail?.scheduleEvents)
  const technician = first(appointment.technicians)

  const status = scheduleEvent?.status?.status ?? ScheduleEventStatusType.Pending

  const showStatus = [
    ScheduleEventStatusType.EnRoute,
    ScheduleEventStatusType.OnSite,
    ScheduleEventStatusType.InProgress,
    ScheduleEventStatusType.Dispatched,
    ScheduleEventStatusType.Cancelled
  ].includes(status)

  return (
    <>
      <EuiFlexGroup direction="column" gutterSize="none">
        {technician && (
          <EuiFlexItem>
            <UserLink
              subtitle={[formatDateStr(appointment.scheduled?.from)]}
              avatarSize="l"
              subtitleSize="s"
              showCompanyName={true}
              user={technician as UserFragment | null | undefined}
            />
          </EuiFlexItem>
        )}
        <EuiFlexItem>
          {loading ? (
            <EuiLoadingSpinner size="s" />
          ) : showStatus ? (
            <div style={{ paddingLeft: '48px' }}>
              <EuiSpacer size="xs" />
              <ScheduleEventStatusBadge status={status} />
            </div>
          ) : null}
        </EuiFlexItem>
      </EuiFlexGroup>
    </>
  )
}

const formatDateStr = (date: ISODate | undefined | null) => {
  const dateObj = date ? dateUtils.fromISO(date) : undefined
  return compact([
    dateObj?.toFormat(dateConfig.luxonFormat.time).toLowerCase() ?? undefined,
    dateObj?.toFormat(dateConfig.luxonFormat.fullDate) ?? ''
  ]).join(' ')
}
