import { useQuery } from '@apollo/client'
import { EuiButtonEmpty, EuiComboBox, EuiComboBoxOptionOption } from '@elastic/eui'
import { dateConfig } from '@fallonsolutions/date'
import { compact } from 'lodash-es'
import { DateTime } from 'luxon'
import { useState } from 'react'
import { AllDiscountsDocument } from '../api/generated-types'
import '../static/css/combo-box.css'

export type DiscountComboItem = EuiComboBoxOptionOption<DiscountComboBoxItem>

interface DiscountComboBoxProps {
  discounts: DiscountComboItem[]
  onChangeDiscounts: (discounts: DiscountComboItem[]) => void
  singleSelection?: boolean
  compact?: boolean
  label?: string
  placeholder?: string
}

export interface DiscountComboBoxItem {
  id: string
  label: string
  code: string
  avatar?: string
}

export const DiscountComboBox = (props: DiscountComboBoxProps) => {
  const { discounts, onChangeDiscounts } = props

  const [enabled, setEnabled] = useState(discounts.length > 0)
  const [comboInput, setComboInput] = useState<HTMLInputElement | null>(null)

  const singleSelection = props.singleSelection ?? false
  const compressed = props.compact ?? false
  const placeholder = props.placeholder ?? 'Discounts'

  const { data } = useQuery(AllDiscountsDocument)

  const now = DateTime.now().setZone(dateConfig.defaultTimezone)
  const allDiscounts: DiscountComboItem[] = compact(data?.allDiscounts.discounts).map((discount) => {
    const validFrom = !discount.validFrom || now >= DateTime.fromISO(discount.validFrom)
    const validTo = !discount.validTo || now <= DateTime.fromISO(discount.validTo)
    const isValid = validFrom && validTo
    return {
      id: discount.id,
      label: `${discount.name ?? 'unknown'}${
        isValid ? '' : ` (exp. ${DateTime.fromISO(discount.validTo).toFormat(dateConfig.luxonFormat.standard)})`
      }`,
      code: discount.code,
      className: singleSelection ? 'combo-box-single-select' : ''
    }
  })

  const onAddFilter = () => {
    setEnabled(true)
    setTimeout(() => comboInput?.focus(), 50)
  }

  const onBlur = () => {
    if (discounts.length <= 0) {
      setEnabled(false)
    }
  }

  const label = props.label ?? 'Discount'

  return (
    <form autoComplete="off">
      <EuiComboBox
        inputRef={setComboInput}
        placeholder={placeholder}
        options={allDiscounts}
        selectedOptions={discounts}
        onChange={onChangeDiscounts}
        isClearable={true}
        rowHeight={40}
        isLoading={allDiscounts.length <= 0}
        style={{ minWidth: '300px' }}
        singleSelection={singleSelection}
        compressed={compressed}
        hidden={!enabled}
        onBlur={onBlur}
        aria-label={label.toLowerCase()}
        data-test-subj={label.toLowerCase()}
      />
      {!enabled && (
        <EuiButtonEmpty iconType="filter" flush="both" onClick={onAddFilter}>
          {label}
        </EuiButtonEmpty>
      )}
    </form>
  )
}
