import { BaseActionInput, BaseActionResult, WorkflowActionProps } from '../workflow-model'
import { EuiButton, EuiSpacer } from '@elastic/eui'
import { Callout } from '../../common/callout'
import { useState } from 'react'
import { PillGroup, PillGroupOption } from '../../pill/pill-group'
import { Task } from '../../common/task'

export type OtherActionInput = BaseActionInput
export type OtherActionResult = BaseActionResult

export enum OtherActionCanHelp {
  CanHelp = 'can-help',
  CannotHelp = 'cannot-help'
}

// const email = 'dev@fallonsolutions.com.au'
// const defaultDescription = 'Hi, I have encountered a scenario not supported by current workflow.'
// const subject = 'Other enquiry scenario encountered'

export const OtherAction = (props: WorkflowActionProps<OtherActionInput, OtherActionResult>) => {
  const { onUpdate } = props

  const [canHelp, setCanHelp] = useState<OtherActionCanHelp | undefined>(undefined)
  const [detailsNoted, setDetailsNoted] = useState(false)

  const canHelpOptions: PillGroupOption[] = [
    {
      id: OtherActionCanHelp.CanHelp,
      label: 'Can help',
      icon: 'check'
    },
    {
      id: OtherActionCanHelp.CannotHelp,
      label: 'Cannot help',
      icon: 'cross'
    }
  ]

  return (
    <>
      <PillGroup
        value={canHelp as string | undefined}
        options={canHelpOptions}
        onSelect={(id) => setCanHelp(id as OtherActionCanHelp)}
        onClear={() => setCanHelp(undefined)}
        collapseOnSelect={false}
      />

      {!!canHelp && (
        <>
          <EuiSpacer />

          {canHelp === OtherActionCanHelp.CanHelp && (
            <Callout type="script" title="Not a problem, I will be able to assist you with that." />
          )}
          {canHelp === OtherActionCanHelp.CannotHelp && (
            <Callout type="script" title="Unfortunately that's not something we offer as a service." />
          )}
          <Task
            label="Note down enquiry so we add this in for future"
            checked={detailsNoted}
            onChange={setDetailsNoted}
            indent={true}
          />
          {detailsNoted && (
            <>
              <EuiSpacer />

              <EuiButton fill color="primary" onClick={() => onUpdate({})}>
                I've completed these steps
              </EuiButton>
            </>
          )}
        </>
      )}
    </>
  )
}
