import { EuiFlexGroup, EuiFlexItem, EuiLink, EuiSpacer, EuiText, IconSize, IconType } from '@elastic/eui'
import { useEffect, useState } from 'react'
import { Pill } from './pill'

export interface PillGroupOption {
  id: string
  label: string
  icon?: IconType
  margin?: string | undefined
  badge?: string | undefined
  badgeColor?: 'accent' | 'subdued' | 'hollow' | undefined
}

export interface PillGroupProps {
  title?: string | undefined
  value: string | undefined
  options: PillGroupOption[]
  onSelect: (id: string) => void
  onClear?: () => void
  iconSize?: IconSize | undefined
  direction?: any | undefined
  collapseOnSelect?: boolean | undefined
}

export const PillGroup = (props: PillGroupProps) => {
  const { title, onSelect, onClear, options, value, iconSize } = props
  const [hideOptions, setHideOptions] = useState(false)
  const direction = props.direction ?? 'row'
  const collapseOnSelect = props.collapseOnSelect ?? true

  useEffect(() => {
    if (value && collapseOnSelect) {
      setTimeout(() => setHideOptions(true), 1200)
    } else {
      setHideOptions(false)
    }
  }, [value, collapseOnSelect])

  return (
    <>
      {title && (
        <>
          <EuiText size="s" style={{ fontWeight: 600 }}>
            {title}
          </EuiText>
          <EuiSpacer size="s" />
        </>
      )}
      <EuiFlexGroup
        gutterSize="m"
        direction={direction}
        wrap={true}
        className="pill-group"
        alignItems="center"
        justifyContent="flexStart"
      >
        {options.map((option) => (
          <EuiFlexItem
            key={option.id}
            grow={false}
            style={
              hideOptions && value !== option.id
                ? { display: 'none', marginRight: option.margin ?? '0px' }
                : { marginRight: option.margin ?? '0px' }
            }
          >
            <Pill
              id={option.id}
              label={option.label}
              badge={option.badge}
              badgeColor={option.badgeColor}
              icon={option.icon}
              onSelect={onSelect}
              isSelected={value === option.id}
              iconSize={iconSize}
            />
          </EuiFlexItem>
        ))}
        {hideOptions && onClear && (
          <EuiFlexItem grow={false}>
            <EuiLink onClick={onClear}>Change</EuiLink>
          </EuiFlexItem>
        )}
      </EuiFlexGroup>
    </>
  )
}
