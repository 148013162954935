import { EuiFlexGroup, EuiFlexItem, EuiIcon } from '@elastic/eui'
import { MembershipLevel } from '../api/generated-types'
import { MembershipLabel } from '../memberships/membership-label'

interface JobMembershipStatusProps {
  membershipLevel: MembershipLevel
  editable?: boolean
}

export const JobMembershipStatus = (props: JobMembershipStatusProps) => {
  const { membershipLevel, editable } = props
  return (
    <EuiFlexGroup gutterSize="none" alignItems="center">
      <EuiFlexItem grow={false}>
        <MembershipLabel membershipLevel={membershipLevel} />
      </EuiFlexItem>
      <EuiFlexItem grow={false}>
        {editable && <EuiIcon size="s" type="arrowDown" style={{ marginLeft: '3px' }} />}
      </EuiFlexItem>
    </EuiFlexGroup>
  )
}
