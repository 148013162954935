import { GetObjectCommand } from '@aws-sdk/client-s3'
import { getSignedUrl } from '@aws-sdk/s3-request-presigner'
import { useEffect, useState } from 'react'
import { useAuthenticated } from '../auth/authenticated-context'
import { S3ObjectRequest } from './use-s3-signed-urls'

export interface UseS3SignedUrlProps {
  bucket?: string
  key?: string
  filename?: string
}

export interface UseS3SignedUrlResult {
  signedUrl: string | undefined
}

export const useS3SignedUrl = ({ bucket, key, filename }: UseS3SignedUrlProps): UseS3SignedUrlResult => {
  const { iamUtils } = useAuthenticated()

  const [signedUrl, setSignedUrl] = useState<string | undefined>(undefined)

  useEffect(() => {
    if (bucket && key) {
      fetchSignedUrl({ bucket, key, filename })
    }
  }, [bucket, key, filename])

  const fetchSignedUrl = async ({ bucket, key, filename }: S3ObjectRequest) => {
    const s3Client = iamUtils.getS3Client()
    const command = new GetObjectCommand({
      Bucket: bucket,
      Key: key,
      ...(filename && { ResponseContentDisposition: `attachment; filename="${filename}"` })
    })
    console.log('fetching image with command', command)
    const signedUrl = await getSignedUrl(s3Client, command)
    setSignedUrl(signedUrl)
  }

  return {
    signedUrl
  }
}
