import { EuiBasicTable } from '@elastic/eui'
import { JobSearchBetaResultFragment, SearchJobsBetaInput } from '@fallonsolutions/types'
import { compact } from 'lodash-es'
import { useEffect, useMemo, useState } from 'react'
import {
  JobSearchOptions,
  JobSummaryAggregationFragment,
  SearchJobsBetaDocument,
  SearchJobsBetaQuery
} from '../api/generated-types'
import { useMixpanel } from '../app/mixpanel-provider'
import { useAuthenticated } from '../auth/authenticated-context'
import ListContainer, { ListContainerData } from '../common/list-container'
import { JobCSVButton } from './job-csv-export'
import { JobListColumns, SearchJobListColumns } from './job-list-columns'
import { JobListFilters } from './job-list-filters'
import { JobListMetrics } from './job-list-metrics'
import { JobListMetricsNewDefinitions } from './job-list-metrics-new-definitions'
import { useJobListMetricsNewDefinitions } from '../common/persisted-state'

export const JobListContainer = () => {
  const mixpanel = useMixpanel()
  useEffect(() => {
    mixpanel?.track('JobList')
  }, [mixpanel])

  const userFragment = useAuthenticated().userFragment
  const exportJobsPermission = userFragment.permissions?.exportJobs === true
  const [jobListMetricNewDefinitionsEnabled] = useJobListMetricsNewDefinitions(false)
  const options: JobSearchOptions = {
    countByDate: true
  }

  const initialInput = useMemo(() => {
    return {
      filter: {},
      options
    }
  }, [])
  const [listInput, setListInput] = useState<SearchJobsBetaInput>(initialInput)

  // const [input, setInput] = useState<JobFilters>({})
  const [total, setTotal] = useState(0)

  const filters = useMemo(
    () => <JobListFilters onChangeFilters={(filter) => setListInput((input) => ({ ...input, filter }))} />,
    []
  )

  const extractData = (response: SearchJobsBetaQuery): ListContainerData<JobSearchBetaResultFragment> => {
    return {
      rows: compact(response?.searchJobsBeta?.results),
      total: response?.searchJobsBeta?.count ?? 0,
      summary: response?.searchJobsBeta?.aggregations?.summary
    }
  }

  const columns = JobListColumns({})
  const metrics = (summary?: JobSummaryAggregationFragment) => <>
    {jobListMetricNewDefinitionsEnabled && (<JobListMetricsNewDefinitions summary={summary} />)}<JobListMetrics summary={summary} /></>

  const csvButton = <JobCSVButton filters={listInput.filter ?? {}} resultCount={total} />

  return (
    <ListContainer
      query={SearchJobsBetaDocument}
      title="Jobs"
      modelName="jobs"
      filters={filters}
      input={listInput}
      options={options}
      columns={columns}
      metrics={metrics}
      extractData={extractData}
      actions={exportJobsPermission ? csvButton : <></>}
      onChangeResultTotal={setTotal}
      paginationOnInput={true}
    />
  )
}

export interface JobListProps {
  jobs: JobSearchBetaResultFragment[]
  currentJobId?: string | undefined
}

export const JobList = (props: JobListProps) => {
  const { jobs, currentJobId } = props
  const columns = SearchJobListColumns({ currentJobId })

  return (
    <>
      <EuiBasicTable columns={columns} items={jobs} />
    </>
  )
}
