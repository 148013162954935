import { AttributeRequirement } from '../../../api/generated-types'
import { decamelise } from '../../../common/utils'
import { TopicActionResult } from '../action-topic-model'
import { YesNoValue } from '../common/common-enums'

export interface TopicPlumbingDrainActionResult extends TopicActionResult {
  serviceType?: DrainServiceType
  drainIssue?: StormwaterIssue | SewerIssue
  itemIssue?: string
  stormwaterConnect?: ConnectStormwater
  stormwaterSymptom?: StormwaterSymptom
  stormwaterBlockedPossibleCause?: StormwaterBlockedPossibleCause
  drainCameraEligibility?: YesNoValue
  newInstallOrReplace?: NewInstallOrReplace
  houseBuild?: YesNoValue
  blockedPrior?: YesNoValue
  causeNote?: string
  haveCouncilPlans?: YesNoValue
  itemAffected?: SewerItemAffected
  anotherToilet?: YesNoValue
  overflowOrLeakedPrior?: YesNoValue
  dsrConfirmInScope?: YesNoValue
  pumpedOutRecently?: YesNoValue
  issueDescription?: string
  otherBlockages?: YesNoValue
  otherIssueDescription?: string
  otherIssueIsInScope?: YesNoValue
  smellySewerAffected?: SewerItemAffected
  slowDrainAffected?: SewerItemAffected
}

export enum DrainServiceType {
  Stormwater = 'Stormwater',
  Sewer = 'Sewer'
}

export const drainServiceTypeOptions = [
  {
    id: DrainServiceType.Stormwater,
    label: 'Stormwater'
  },
  { id: DrainServiceType.Sewer, label: 'Sewer' }
]

export const getRequirements = (result?: TopicPlumbingDrainActionResult): any => {
  const attributes = [] as AttributeRequirement[] //{ attributeId: 'GeneralPlumbing' }
  return {
    attributes,
    priority: undefined
  }
}

export enum StormwaterIssue {
  BlockedOrOverflowing = 'BlockedOrOverflowing',
  Leaking = 'Leaking',
  Broken = 'Broken',
  ConnectStormwater = 'ConnectStormwater',
  SolutionsOrImprovements = 'SolutionsOrImprovements', //NotWorking might be a better description?
  CameraInspections = 'CameraInspections',
  NewOrReplace = 'NewOrReplace',
  Downpipe = 'Downpipe'
}

export const stormwaterOptions = [
  { id: StormwaterIssue.BlockedOrOverflowing, label: 'Blocked / Overflowing' },
  { id: StormwaterIssue.Leaking, label: 'Leaking' },
  { id: StormwaterIssue.Broken, label: 'Broken' },
  { id: StormwaterIssue.ConnectStormwater, label: 'Connect Stormwater' },
  { id: StormwaterIssue.SolutionsOrImprovements, label: 'Solutions / Improvements' },
  { id: StormwaterIssue.CameraInspections, label: 'Camera Inspections' },
  { id: StormwaterIssue.NewOrReplace, label: 'New / Replace' },
  { id: StormwaterIssue.Downpipe, label: 'Downpipe' }
]

export enum StormwaterSymptom {
  WaterPooling = 'WaterPooling',
  WaterNotDraining = 'WaterNotDraining',
  DampYard = 'DampYard'
}
export const stormwaterSymptomOptions = [
  { id: StormwaterSymptom.WaterPooling, label: decamelise('WaterPooling') },
  { id: StormwaterSymptom.WaterNotDraining, label: decamelise('WaterNotDraining') },
  { id: StormwaterSymptom.DampYard, label: decamelise('DampYard') }
]

export enum ConnectStormwater {
  Downpipe = 'Downpipe',
  Line = 'Line',
  Other = 'Other'
}

export const stormwaterConnectOptions = [
  { id: ConnectStormwater.Downpipe, label: 'Downpipe' },
  { id: ConnectStormwater.Line, label: 'Line' },
  { id: ConnectStormwater.Other, label: 'Other' }
]

export enum StormwaterBlockedPossibleCause {
  CollapsedPipe = 'CollapsedPipe',
  CementOrDirtDebris = 'CementOrDirtDebris',
  Other = 'Other'
}

export const stormwaterBlockedPossibleCauseOptions = [
  { id: StormwaterBlockedPossibleCause.CollapsedPipe, label: decamelise('CollapsedPipe') },
  { id: StormwaterBlockedPossibleCause.CementOrDirtDebris, label: decamelise('CementOrDirtDebris') },
  { id: StormwaterBlockedPossibleCause.Other, label: decamelise('Other') }
]
//serviceType, maybe better represented as a type hierarchy, so Stormwater | Sewer with extended StormwaterServiceTypes | SewerServiceTypes and collapse the additional fields and use type guards for what menu levels to display

export enum NewInstallOrReplace {
  NewInstall = 'NewInstall',
  Replace = 'Replace'
}

export const newInstallOrReplaceOptions = [
  { id: NewInstallOrReplace.NewInstall, label: 'New Install' },
  { id: NewInstallOrReplace.Replace, label: 'Replace' }
]

//Sewer
export enum SewerIssue {
  BlockedOrOverflowing = 'BlockedOrOverflowing',
  CappingOff = 'CappingOff',
  Reline = 'Reline',
  Repair = 'Repair',
  Replace = 'Replace',
  SmellySewer = 'SmellySewer',
  SlowDraining = 'SlowDraining',
  Other = 'Other'
}
export const sewerIssueOptions = [
  { id: SewerIssue.BlockedOrOverflowing, label: decamelise('Blocked / Overflowing') },
  { id: SewerIssue.CappingOff, label: decamelise('CappingOff') },
  { id: SewerIssue.Reline, label: decamelise('Reline') },
  { id: SewerIssue.Repair, label: decamelise('Repair') },
  { id: SewerIssue.SmellySewer, label: decamelise('Smelly Sewer') },
  { id: SewerIssue.SlowDraining, label: decamelise('Slow Draining') },
  { id: SewerIssue.Other, label: decamelise('Other') }
]

export enum SewerItemAffected {
  Toilet = 'Toilet',
  WholeHouse = 'WholeHouse',
  KitchenSink = 'KitchenSink',
  Vanity = 'Vanity',
  Basin = 'Basin',
  Shower = 'Shower',
  Bath = 'Bath',
  Bathroom = 'Bathroom',
  Laundry = 'Laundry',
  SepticSystem = 'SepticSystem',
  GreaseTrap = 'GreaseTrap',
  OverflowingInYard = 'OverflowingInYard',
  OverflowingInspectionPoint = 'OverflowingInspectionPoint',
  SewerInYard = 'SewerInYard',
  LeakingSewer = 'LeakingSewer'
}

export const sewerItemAffectedOptions = [
  { id: SewerItemAffected.Toilet, label: decamelise('Toilet') },
  { id: SewerItemAffected.WholeHouse, label: decamelise('WholeHouse') },
  { id: SewerItemAffected.KitchenSink, label: decamelise('KitchenSink') },
  { id: SewerItemAffected.Vanity, label: decamelise('Vanity') },
  { id: SewerItemAffected.Basin, label: decamelise('Basin') },
  { id: SewerItemAffected.Shower, label: 'Shower' },
  { id: SewerItemAffected.Bath, label: decamelise('Bath') },
  { id: SewerItemAffected.Laundry, label: decamelise('Laundry') },
  { id: SewerItemAffected.SepticSystem, label: decamelise('SepticSystem') },
  { id: SewerItemAffected.GreaseTrap, label: decamelise('GreaseTrap') },
  { id: SewerItemAffected.OverflowingInYard, label: decamelise('OverflowingInYard') },
  { id: SewerItemAffected.OverflowingInspectionPoint, label: decamelise('OverflowingInspectionPoint') },
  { id: SewerItemAffected.SewerInYard, label: decamelise('SewerInYard') },
  { id: SewerItemAffected.LeakingSewer, label: decamelise('LeakingSewer') }
]
