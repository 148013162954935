import { useQuery } from '@apollo/client'
import { EuiFlexGroup, EuiFlexItem, EuiLoadingSpinner } from '@elastic/eui'
import { dateConfig, dateUtils } from '@fallonsolutions/date'
import { compact } from 'lodash-es'
import { GetJobStatusHistoryDocument } from '../api/generated-types'
import UserLink from '../users/user-link'
import { JobStatusBadge } from './job-status-badge'

export interface JobStatusHistoryProps {
  jobId: string
}

export const JobStatusHistory = ({ jobId }: JobStatusHistoryProps) => {
  const { data, loading } = useQuery(GetJobStatusHistoryDocument, {
    variables: { jobId }
  })

  const statusHistory = compact(data?.getJob?.statusHistory ?? [])

  return loading ? (
    <EuiLoadingSpinner />
  ) : (
    <EuiFlexGroup direction="column" gutterSize="s">
      {statusHistory.map((status, index) => (
        <EuiFlexItem key={index}>
          <EuiFlexGroup alignItems="center" gutterSize="s">
            <EuiFlexItem grow={false} style={{ width: '100px' }}>
              <JobStatusBadge status={status.status} />
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              {dateUtils.fromISO(status.date).toFormat(dateConfig.luxonFormat.fullDate)}
              {` `}
              {dateUtils.fromISO(status.date).toFormat(dateConfig.luxonFormat.time).toLowerCase()}
            </EuiFlexItem>
            {status.user && (
              <EuiFlexItem grow={false}>
                <UserLink user={status.user} />
              </EuiFlexItem>
            )}
            <EuiFlexItem grow={true} />
          </EuiFlexGroup>
        </EuiFlexItem>
      ))}
    </EuiFlexGroup>
  )
}
