import { dateConfig as config } from '@fallonsolutions/date'

import moment from 'moment-timezone/moment-timezone-utils'
// import 'moment/locale/en-au'

const { defaultTimezone } = config
moment.tz.setDefault(defaultTimezone)
moment.locale('en-au', {
  week: {
    dow: 1 //monday
  }
})

export const dateConfig = { ...config.format, defaultTimezone }
