import { EuiBetaBadge, EuiFlexGroup, EuiFlexItem, EuiIcon, IconSize, IconType } from '@elastic/eui'
import '../static/css/pill.css'

export interface PillProps {
  id: string
  label: string
  isSelected: boolean
  badge?: string | undefined
  badgeColor?: 'accent' | 'subdued' | 'hollow' | undefined
  icon?: IconType
  iconSize?: IconSize | undefined
  onSelect: (id: string) => void
  children?: React.ReactNode
}

export const Pill = (props: PillProps) => {
  const { id, label, badge, badgeColor, isSelected, icon, onSelect } = props
  const iconSize = props.iconSize ?? 'xl'

  const onKeyDown = (event: any) => {
    switch (event.keyCode) {
      case 13: // Return
      case 32: // Space
        onSelect(id)
        break
      default:
    }
  }

  return (
    <div
      className={isSelected ? `pill pill--selected` : 'pill'}
      tabIndex={0}
      onKeyDown={onKeyDown}
      onClick={() => onSelect(id)}
      role="button"
    >
      {props.children}
      <EuiFlexGroup
        direction="column"
        alignItems="center"
        justifyContent="center"
        gutterSize="s"
        className="pill__content"
      >
        {icon && (
          <EuiFlexItem className="pill__icon" grow={false}>
            <EuiIcon type={icon} size={iconSize} color={isSelected ? 'primary' : 'subdued'} />
          </EuiFlexItem>
        )}
        <EuiFlexItem grow={false} className="pill__label">
          <span>{label}</span>
        </EuiFlexItem>
      </EuiFlexGroup>
      {badge && (
        <div style={{ position: 'absolute', top: '-8px', left: '0px', width: '100%', textAlign: 'center' }}>
          <EuiBetaBadge
            label={badge}
            size="s"
            color={badgeColor ?? 'hollow'}
            style={{
              margin: '0 auto',
              backgroundColor: '#fff',
              fontSize: '8px',
              padding: '0 8px',
              lineHeight: '15px'
            }}
          />
        </div>
      )}
    </div>
  )
}
