import { QuestionDefinitions, getNotesGenerator } from '../../helpers/notes-generator'
import { TopicActionResult } from '../action-topic-model'
import { YesNoValue } from '../common/common-enums'

export interface TopicHVACRepairActionResult extends TopicActionResult {
  acType?: ACType
  underWarranty?: ACWarrantyStatus
  systemAge?: ACSystemAge
  brand?: ACBrand
  willingToVoidWarranty?: YesNoValue
  faultDescription?: string
  indoorUnitLocation?: string
  outdoorUnitLocation?: string
  fallonInstalled?: YesNoValue
  ladderRequired?: string
  lastServiceDate?: string
  furnitureNeedToBeMoved?: string
  replacement?: YesNoValue
  replacementType?: ACType
  roofAccess?: YesNoValue
}

export enum ACType {
  SplitSystemOrMultiHead = 'Split System or Multi Head',
  Ducted = 'Ducted or Cassette',
  WindowBox = 'Window box',
  Car = 'Car or vehicle',
  Portable = 'Portable'
}

export enum ACWarrantyStatus {
  UnderWarranty = 'Under warranty',
  OutsideOfWarranty = 'Outside of warranty',
  Unsure = 'Unsure'
}

export enum ACSystemAge {
  FiveOrLess = '5 years old or less',
  OverFiveYears = 'Over 5 years old'
}

export enum ACBrand {
  Daikin = 'Daikin',
  Fujitsu = 'Fujitsu',
  Kelvinator = 'Kelvinator',
  Mitsubishi = 'Mitsubishi',
  Panasonic = 'Panasonic',
  Pioneer = 'Pioneer',
  Other = 'Other'
}

const hvacRepairQuestionDefinitions: QuestionDefinitions<TopicHVACRepairActionResult> = {
  acType: {
    question: 'What type of AC needs repairing?',
    statement: 'AC type:'
  },
  underWarranty: {
    question: 'Is the unit under warranty?',
    statement: 'Under warranty:'
  },
  systemAge: {
    question: 'How old is the AC?',
    statement: 'AC unit age:'
  },
  brand: {
    question: 'What type of brand is it?',
    statement: 'AC unit brand:'
  },
  fallonInstalled: {
    question: 'Was the unit installed by Fallon?',
    statement: 'Installed by Fallon:'
  },
  willingToVoidWarranty: {
    question: 'We may void your warranty by coming out to do the repair. Did you still want to proceed?',
    statement: 'Willing to void warranty:'
  },
  faultDescription: {
    question: 'What is the fault or issue?',
    statement: 'Fault or issue:'
  },
  indoorUnitLocation: {
    question: 'Where is the indoor unit located?',
    statement: 'Indoor unit location:'
  },
  outdoorUnitLocation: {
    question: 'Where is the outdoor unit located?',
    statement: 'Outdoor unit location:'
  },
  ladderRequired: {
    question: 'Is a ladder over 2 metres required to reach outdoor motor?',
    statement: 'Over 2m ladder required:'
  },
  lastServiceDate: {
    question: 'What was it last serviced?',
    statement: 'Last service date:'
  },
  furnitureNeedToBeMoved: {
    question: 'Any furniture to be moved?',
    statement: 'Furniture to be moved:'
  },
  replacement: {
    question:
      'Unfortunately for that type of AC we cant offer repair solutions. What we can do is organise to have that unit replaced. Is this something you are interested in?',
    statement: 'Replace window box AC:'
  }
}

export const hvacRepairNotesGenerator = getNotesGenerator(hvacRepairQuestionDefinitions)
