import { JobTopicType } from '@fallonsolutions/types'
import { EnquiryAbortReason, FollowUpActionType } from '../api/generated-types'

export interface WorkflowState<ResultType> {
  enquiryId?: string
  startDate: string
  endDate?: string
  completionType?: WorkflowCompletionType
  fullPath: WorkflowActionResponse[]
  path: WorkflowActionResponse[]
  input: any
  result: ResultType
  resultVersion: string
  notes: string
}

export interface WorkflowActionResponse {
  userId: string
  reference: string
  date: string
  value: any
}

export enum WorkflowCompletionType {
  Complete = 'complete',
  Abort = 'abort'
}

export interface EnquiryAbortActionResult {
  details: EnquiryAbortDetails
}

export interface EnquiryAbortDetails {
  jobTopicReference?: JobTopicType
  abortReason?: EnquiryAbortReason
  notes?: string
  followUpActions?: FollowUpActionType[]
}

export interface BaseActionInput {
  editable?: boolean
}

export interface BaseActionResult {
  actionCompleted?: boolean
  value?: string
}

export interface WorkflowActionProps<ActionInput extends BaseActionInput, ResultType> {
  input: ActionInput
  result?: ResultType
  onUpdate: (result: ResultType) => void
  onBack?: () => void
  children?: React.ReactNode
}

export enum EnquiryTypeReference {
  CancelJob = 'cancel-job',
  Crisis = 'crisis',
  Complaint = 'complaint',
  ConfirmJob = 'confirm-job',
  DisputeInvoice = 'dispute-invoice',
  Employment = 'employment',
  ETA = 'eta',
  Feedback = 'feedback',
  Media = 'media',
  Membership = 'membership',
  NewBooking = 'new-booking',
  Other = 'other',
  PayInvoice = 'pay-invoice',
  Rebooking = 'rebooking',
  ResendInvoice = 'resend-invoice',
  Telemarketing = 'telemarketing',
  Transfer = 'transfer',
  UpdateCustomer = 'updated-customer',
  UpdateJob = 'update-job'
}
