import { EuiButtonEmpty, EuiComboBox, EuiComboBoxOptionOption } from '@elastic/eui'
import { compact, map } from 'lodash-es'
import { useState } from 'react'
import '../static/css/combo-box.css'
import { decamelise } from './utils'

interface EnumTypeComboBoxProps<T> {
  type: T
  values: string[]
  onChange: (values: string[]) => void
  singleSelection?: boolean
  compact?: boolean
  label?: string
  placeholder?: string
  isClearable?: boolean
  minWidth?: string
}

export interface ComboBoxItem {
  id: string
  label: string
}

export const EnumTypeComboBox = <T extends { [key: string]: any }>(props: EnumTypeComboBoxProps<T>) => {
  const { type, values, onChange } = props

  const minWidth = props.minWidth ?? '200px'
  const [enabled, setEnabled] = useState(values.length > 0)
  const [comboInput, setComboInput] = useState<HTMLInputElement | null>(null)

  const singleSelection = props.singleSelection ?? false
  const compressed = props.compact ?? false
  const placeholder = props.placeholder ?? 'Select'
  const isClearable = props.isClearable ?? true

  const allOptions = map(type, (value) => ({
    id: value,
    label: decamelise(value),
    className: singleSelection ? 'combo-box-single-select' : ''
  }))

  const selectedOptions = map(values, (value) => ({
    id: value,
    label: decamelise(value)
  }))

  const onAddFilter = () => {
    setEnabled(true)
    setTimeout(() => comboInput?.focus(), 50)
  }

  const onBlur = () => {
    if (values.length <= 0) {
      setEnabled(false)
    }
  }

  const label = props.label ?? 'Select'

  const handleOnChange = (options: EuiComboBoxOptionOption<T>[]) => {
    const newValues = options.map((option) => option.id)
    onChange(compact(newValues))
  }

  return (
    <form autoComplete="off">
      <EuiComboBox
        inputRef={setComboInput}
        placeholder={placeholder}
        options={allOptions}
        selectedOptions={selectedOptions}
        onChange={handleOnChange}
        isClearable={isClearable}
        rowHeight={40}
        isLoading={allOptions.length <= 0}
        style={{ minWidth }}
        singleSelection={singleSelection}
        compressed={compressed}
        hidden={!enabled}
        onBlur={onBlur}
        aria-label={label.toLowerCase()}
        data-test-subj={label.toLowerCase()}
      />
      {!enabled && (
        <EuiButtonEmpty iconType="filter" flush="both" onClick={onAddFilter}>
          {label}
        </EuiButtonEmpty>
      )}
    </form>
  )
}
