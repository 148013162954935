/**
 * Given a url, will open a new tab on the current pages host damain
 * @param url - everything after the host protocol and host
 * @example url = /workflow/1234?param=1 will open a new tab to https://app.fsplatform.com/workflow/1234?param=1
 */
export const openUrlInNewTab = (url: string) => {
  const location = new URL(window.location.href)
  const baseUrl = `${location.protocol}//${location.host}` // host = app.fsplatform.com, protocol = https:
  window.open(`${baseUrl}${url}`, '_blank', 'noreferrer')
}
