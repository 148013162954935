import { EuiButton, EuiSpacer, EuiText } from '@elastic/eui'
import { EnquiryAbortReason, JobType, TradeType } from '../../../api/generated-types'
import { Callout } from '../../../common/callout'
import { decameliseIfNotNil, makeHumanReadable } from '../../../common/utils'
import { QuestionDefinitions, getNotesGenerator } from '../../helpers/notes-generator'
import { getResultUpdater } from '../../helpers/results-updater'
import { useWorkflow } from '../../helpers/workflow-provider'
import { FreeTextQuestionField } from '../../question-fields/free-text-question-field'
import { SingleSelectField } from '../../question-fields/single-select-question-field'
import { WorkflowActionProps } from '../../workflow-model'
import { InstallType, ItemInstaller, ServiceType, TopicActionInput, TopicActionResult } from '../action-topic-model'
import { TopicWorkflowAction } from '../action-topic-view'

export enum FanControlType {
  Remote = 'Remote',
  WallSwitch = 'WallSwitch'
}

export enum RepairToReplacement {
  Yes = 'Yes',
  No = 'No'
}

export enum InstallDate {
  LessThanOrEqualTo5Years = 'Within last 5 years',
  Over5Years = 'Over 5 years ago'
}

export interface TopicSmokeAlarmActionResult extends TopicActionResult {
  serviceType?: ServiceType
  itemInstaller?: ItemInstaller
  installDate?: InstallDate
  installType?: InstallType
  numberOfRooms?: string
  repairToReplacement?: RepairToReplacement
}

const questionDefinitions: QuestionDefinitions<TopicSmokeAlarmActionResult> = {
  serviceType: {
    question: 'Is this for an install or repair?',
    statement: 'Service type:',
    makeAnswerHumanReadable: decameliseIfNotNil
  },
  itemInstaller: {
    question: 'Who installed the smoke alarm(s)?',
    statement: 'Smoke alarm(s) installed by:',
    makeAnswerHumanReadable: decameliseIfNotNil
  },
  installDate: {
    question: 'When did Fallon install?',
    makeAnswerHumanReadable: makeHumanReadable
  },
  repairToReplacement: {
    question: 'Would you like me to book in a technician to come out and arrange this?',
    statement: 'Repair to new install:',
    makeAnswerHumanReadable: decameliseIfNotNil
  },
  installType: {
    question: 'Is this for brand new install or replacement?',
    statement: 'Install type:',
    makeAnswerHumanReadable: decameliseIfNotNil
  },
  numberOfRooms: {
    question: 'How many rooms in the house?',
    statement: 'Number of rooms:'
  }
}
const notesGenerator = getNotesGenerator(questionDefinitions)

export const TopicSmokeAlarmAction = (props: WorkflowActionProps<TopicActionInput, TopicSmokeAlarmActionResult>) => {
  const workflowContext = useWorkflow()
  const { result, input, onUpdate } = props
  const { serviceType, itemInstaller, installDate, installType, numberOfRooms, repairToReplacement } = result ?? {}

  const isRevisit =
    serviceType === ServiceType.Repair &&
    itemInstaller === ItemInstaller.Fallon &&
    installDate === InstallDate.LessThanOrEqualTo5Years
  const isOutsideRevisitScope =
    serviceType === ServiceType.Repair &&
    itemInstaller === ItemInstaller.Fallon &&
    installDate === InstallDate.Over5Years

  const onNext = () =>
    onUpdate({
      ...result,
      actionCompleted: true,
      trade: TradeType.Electrical,
      category: 'SmokeAlarm',
      type: isRevisit ? JobType.Callback : JobType.Service,

      workRequiredNotes: generatedNotes,
      requirements: {
        attributes: [{ attributeId: 'SmokeAlarms' }]
      }
    })

  const generatedNotes = notesGenerator.generateNotes(result ?? {})
  const updateResult = getResultUpdater(result ?? {}, onUpdate).updateResult
  const fallonInstalledOver5Years = installDate === InstallDate.Over5Years && itemInstaller === ItemInstaller.Fallon
  const canComplete = isRevisit || isOutsideRevisitScope || (!!serviceType && !!installType && !!numberOfRooms)
  const canInstallNewItem = installDate === InstallDate.Over5Years || itemInstaller === ItemInstaller.SomeoneElse
  const bookReplacement = () => {
    return (
      <>
        <Callout type="script" className="callout--flat-list">
          <EuiText>
            <ul>
              <li>Unfortunately due to the complexity of smoke alarms we do not repair them</li>
              <li>It is more cost effective to replace them as you get an upgrade and a warranty</li>
            </ul>
          </EuiText>
        </Callout>
        <SingleSelectField
          question={notesGenerator.getQuestion('repairToReplacement')}
          options={[
            {
              id: RepairToReplacement.Yes,
              label: 'Yes, book in replacement',
              icon: 'check'
            },
            {
              id: RepairToReplacement.No,
              label: 'No thanks',
              icon: 'cross'
            }
          ]}
          answer={repairToReplacement}
          changeAnswer={(repairToReplacement) =>
            updateResult({
              repairToReplacement,
              installType: undefined,
              numberOfRooms: undefined
            })
          }
        />
        {repairToReplacement === RepairToReplacement.No && (
          <>
            <Callout type="script">
              <EuiText>
                <p>
                  Can I please get your email address as I would like to send you through a <strong>$40 voucher</strong>{' '}
                  that you can use next time you chose to book with us
                </p>
              </EuiText>
            </Callout>
            <EuiSpacer />
            <EuiButton
              color="warning"
              onClick={() => workflowContext.abort({ abortReason: EnquiryAbortReason.CustomerNeedsAreOutOfService })}
            >
              End call
            </EuiButton>
          </>
        )}
      </>
    )
  }
  return (
    <TopicWorkflowAction input={input} onUpdate={onUpdate} result={result}>
      <SingleSelectField
        question={notesGenerator.getQuestion('serviceType')}
        options={[
          {
            id: ServiceType.Installation,
            label: 'Installation',
            icon: 'check'
          },
          {
            id: ServiceType.Repair,
            label: 'Repair',
            icon: 'wrench'
          }
        ]}
        answer={serviceType}
        changeAnswer={(serviceType) =>
          updateResult({
            serviceType,
            itemInstaller: undefined,
            installType: undefined,
            numberOfRooms: undefined,
            repairToReplacement: undefined
          })
        }
      />

      {serviceType === ServiceType.Repair && (
        <>
          <SingleSelectField
            question={notesGenerator.getQuestion('itemInstaller')}
            options={[
              {
                id: ItemInstaller.Fallon,
                label: 'Fallon installed',
                icon: 'check'
              },
              {
                id: ItemInstaller.SomeoneElse,
                label: 'Someone else installed',
                icon: 'user'
              }
            ]}
            answer={itemInstaller}
            changeAnswer={(itemInstaller) => updateResult({ itemInstaller, repairToReplacement: undefined })}
          />
        </>
      )}

      {serviceType === ServiceType.Repair && itemInstaller === ItemInstaller.SomeoneElse && <>{bookReplacement()}</>}

      {serviceType === ServiceType.Repair && itemInstaller === ItemInstaller.Fallon && (
        <>
          <SingleSelectField
            question={notesGenerator.getQuestion('installDate')}
            options={[
              {
                id: InstallDate.LessThanOrEqualTo5Years,
                label: 'Within last 5 years'
              },
              {
                id: InstallDate.Over5Years,
                label: 'Over 5 years ago'
              }
            ]}
            answer={installDate}
            changeAnswer={(installDate) => updateResult({ installDate })}
          />
          {itemInstaller === ItemInstaller.Fallon && installDate === InstallDate.LessThanOrEqualTo5Years && (
            <>
              <Callout type="note" title={'Revisit with no travel fee'} />
              <EuiSpacer />
            </>
          )}
          {fallonInstalledOver5Years && <>{bookReplacement()}</>}
        </>
      )}

      {(serviceType === ServiceType.Installation ||
        (serviceType === ServiceType.Repair &&
          repairToReplacement === RepairToReplacement.Yes &&
          canInstallNewItem)) && (
        <>
          <SingleSelectField
            question={notesGenerator.getQuestion('installType')}
            options={[
              {
                id: InstallType.NewInstall,
                label: 'New install'
              },
              {
                id: InstallType.Replacement,
                label: 'Replacement'
              }
            ]}
            answer={installType}
            changeAnswer={(installType) => updateResult({ installType })}
          />
          <FreeTextQuestionField
            question={notesGenerator.getQuestion('numberOfRooms')}
            answer={numberOfRooms}
            changeAnswer={(numberOfRooms) => updateResult({ numberOfRooms })}
          />
        </>
      )}

      <EuiSpacer />
      <EuiButton disabled={!canComplete} onClick={() => onNext()}>
        Next
      </EuiButton>
    </TopicWorkflowAction>
  )
}
