import { BaseActionInput, BaseActionResult, WorkflowActionProps } from '../workflow-model'
import { EuiButton, EuiSpacer, EuiText } from '@elastic/eui'
import { Callout } from '../../common/callout'
import { WorkflowAction } from '../workflow-action'
import React from 'react'
import { DateTime } from 'luxon'
import { dateConfig } from '@fallonsolutions/date'

export type AdditionalTradeActionInput = BaseActionInput

export type AdditionalTradeActionResult = BaseActionResult

const getTimeOfDayString = (date: DateTime): string => {
  const hour = date.hour
  if (hour >= 4 && hour < 9) {
    return 'this morning'
  } else if (hour >= 9 && hour <= 19) {
    return 'today'
  } else {
    return 'tonight'
  }
}

export const AdditionalTradeAction = (
  props: WorkflowActionProps<AdditionalTradeActionInput, AdditionalTradeActionResult>
) => {
  const { input, onUpdate, result } = props

  const { actionCompleted } = result ?? {}

  const setActionCompleted = (actionCompleted: boolean) => onUpdate({ ...result, actionCompleted })

  const timeOfDay = getTimeOfDayString(DateTime.now().setZone(dateConfig.defaultTimezone))

  return (
    <WorkflowAction
      title="Additional trades"
      value={actionCompleted ? 'Communicated' : undefined}
      onClickChange={() => setActionCompleted(false)}
      editable={input.editable}
    >
      <Callout type="script" className="callout--flat-list">
        <EuiText>
          <ul>
            <li>Fallon Solutions is a multi trade company</li>
            <li>
              We offer a <strong>$33 discount</strong> to anyone who books a second trade with us
            </li>
          </ul>
          <ul>
            <li>Is there anything else you'd like booked in {timeOfDay}?</li>
          </ul>
        </EuiText>
      </Callout>

      <EuiSpacer size="s" />
      <EuiButton onClick={() => setActionCompleted(true)}>Next</EuiButton>
      <EuiSpacer />
    </WorkflowAction>
  )
}
