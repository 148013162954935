import { ApolloError, useMutation } from '@apollo/client'
import {
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiRadioGroup,
  EuiRadioGroupOption,
  EuiSpacer,
  EuiTabbedContent,
  EuiTabbedContentTab,
  EuiText,
  EuiTitle
} from '@elastic/eui'
import {
  BookingWindow,
  bookingWindowOptions,
  createBookingWindowDate,
  filterBookingWindowOptions
} from '@fallonsolutions/appointment'
import { find, take } from 'lodash-es'
import { useState } from 'react'
import {
  AssignAppointmentToScheduleEventDocument,
  AssignAppointmentToScheduleEventInput,
  CreateAppointmentDocument,
  CreateAppointmentInput,
  LegacyBookingWindow,
  ScheduleEventFragment
} from '../api/generated-types'
import { HistoryItemType, usePlatformHistory } from '../history/history-item'
import JobComboBox, { JobComboBoxLabelType, JobComboBoxValue } from '../jobs/job-combo-box'

interface CreateAppointmentFormProps {
  event: ScheduleEventFragment
  onSubmit: () => void
}

export const CreateAppointmentForm = (props: CreateAppointmentFormProps) => {
  const { event, onSubmit } = props

  const { historyItems } = usePlatformHistory()

  const filteredBookingWindows = (): EuiRadioGroupOption[] => {
    const timedBookingWindowOptions = filterBookingWindowOptions(event.scheduled)
    if (
      bookingWindow.id !== defaultBookingWindow.id &&
      timedBookingWindowOptions.findIndex((bw) => bw.id === bookingWindow.id) < 0
    ) {
      setBookingWindow(defaultBookingWindow)
    }
    return timedBookingWindowOptions.map((bw) => ({ id: bw.id, label: bw.label, className: bw.className }))
  }

  const defaultBookingWindow = find(bookingWindowOptions, { id: LegacyBookingWindow.ToBeAdvised }) as BookingWindow
  const [bookingWindow, setBookingWindow] = useState<BookingWindow>(defaultBookingWindow)

  const updateBookingWindow = (id: string) => {
    const newBookingWindow = find(bookingWindowOptions, { id }) as BookingWindow
    setBookingWindow(newBookingWindow)
  }

  //

  const radios: JobComboBoxValue[] = take(
    (historyItems ?? [])
      .filter((i) => i.type === HistoryItemType.Job)
      .map((item) => ({
        id: item.id,
        label: item.label,
        number: undefined,
        trade: undefined,
        tradeId: undefined,
        labelProps: {
          style: { fontSize: '12px' }
        }
      })),
    5
  )

  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)
  const [job, setJob] = useState<JobComboBoxValue | undefined>(undefined)

  //handle the error and avoid subscription errors propagated from createHttpLink if the observer/mutation is missing an error handler
  const handleCreateError = (error: ApolloError | undefined) => {
    console.error('handleCreateAppointment error', JSON.stringify(error, null, 2))
    setErrorMessage(error?.message)
  }

  const [createAppointment, { loading: creatingAppointment }] = useMutation(CreateAppointmentDocument, {
    onError: handleCreateError
  })
  const [assignAppointmentToScheduleEvent, { loading: assigningAppointmentToScheduleEvent }] = useMutation(
    AssignAppointmentToScheduleEventDocument
  )

  const handleSubmit = async () => {
    if (job) {
      const from = bookingWindow.startTime
        ? createBookingWindowDate(event.scheduled.from, bookingWindow.startTime)
        : undefined
      const to = bookingWindow.endTime ? createBookingWindowDate(event.scheduled.to, bookingWindow.endTime) : undefined
      const legacyBookingWindow = bookingWindow.id as LegacyBookingWindow

      const createAppointmentInput: CreateAppointmentInput = {
        jobId: job.id,
        ...(from && { bookingWindow: { from, to } }),
        legacyBookingWindow
      }
      console.log('Creating appointment...', createAppointmentInput)
      const appointmentResponse = await createAppointment({ variables: { input: createAppointmentInput } })
      const newAppointmentId = appointmentResponse.data?.createAppointment.appointment?.id
      if (newAppointmentId) {
        console.log('Appointment created', newAppointmentId)

        // link appointment with the reserved schedule event
        const assignAppointmentToScheduleEventInput: AssignAppointmentToScheduleEventInput = {
          id: event.id,
          appointment: newAppointmentId
        }

        console.log('Assigning appointment to reserved schedule event...')
        await assignAppointmentToScheduleEvent({ variables: { input: assignAppointmentToScheduleEventInput } })
        console.log('Reserved schedule event assigned to appointment.')
        onSubmit()
      }
    }
  }

  const isLoading = creatingAppointment || assigningAppointmentToScheduleEvent

  const tabs: EuiTabbedContentTab[] = [
    {
      id: 'search',
      name: 'Search',
      content: (
        <>
          <EuiSpacer />
          <JobComboBox
            jobs={job ? [job] : []}
            onChangeJobs={(jobs) => {
              setJob(jobs && jobs.length > 0 ? jobs[0] : undefined)
            }}
            singleSelection={true}
            labelType={JobComboBoxLabelType.Address}
            startEnabled={true}
            placeholder="Job number"
          />
        </>
      )
    },
    {
      id: 'recent',
      name: 'Recent',
      content: (
        <>
          <EuiSpacer />
          <EuiRadioGroup
            options={radios}
            idSelected={job?.id}
            onChange={(id) => setJob(find(radios, { id }))}
            name="job"
            disabled={isLoading}
          />
        </>
      )
    }
  ]

  return (
    <EuiForm>
      {/* @ts-ignore: React types issue with autoFocus="selected" */}
      <EuiTabbedContent size="s" tabs={tabs} initialSelectedTab={tabs[0]} autoFocus="selected" />

      {job && (
        <>
          <EuiSpacer />
          <div>TODO: we will show brief summary of selected job here</div>
        </>
      )}
      {errorMessage && <EuiText color="danger">{errorMessage}</EuiText>}
      <EuiSpacer />

      <EuiFlexGroup alignItems="flexStart">
        <EuiFlexItem grow={false} style={{ minWidth: '180px' }}>
          <EuiTitle size="xs">
            <h3>Time window</h3>
          </EuiTitle>
          <EuiSpacer size="s" />
          <EuiRadioGroup
            options={filteredBookingWindows()}
            idSelected={bookingWindow.id}
            onChange={updateBookingWindow}
            name="bookingWindow"
            data-test-subj="appointment-times"
            aria-label="appointment-times"
          />
        </EuiFlexItem>
        {/* <EuiFlexItem grow={false} style={{ minWidth: '180px' }}>
            <EuiTitle size="xs">
              <h3>Time allowed</h3>
            </EuiTitle>
            <EuiSpacer />
            <EuiRadioGroup
              options={TimeAllowedOptions.map((t) => ({ id: t.id, label: t.label }))}
              idSelected={timeAllowed.id}
              onChange={updateTimeAllowed}
              name="timeAllowed"
            />
          </EuiFlexItem> */}
      </EuiFlexGroup>

      <EuiSpacer />
      <EuiFlexGroup>
        <EuiFlexItem grow={true} />
        <EuiFlexItem>
          <EuiButton
            onClick={handleSubmit}
            fill
            color="primary"
            disabled={!job}
            isLoading={isLoading}
            data-test-id="CreateAppointment"
          >
            Create appointment
          </EuiButton>
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiForm>
  )
}
