import { useQuery } from '@apollo/client'
import {
  EuiBasicTable,
  EuiButton,
  EuiCallOut,
  EuiFlexGroup,
  EuiFlexItem,
  EuiLoadingSpinner,
  EuiSpacer,
  EuiTitle
} from '@elastic/eui'
import { Fragment, useEffect, useState } from 'react'
import { CustomerFragment, GetCustomerReviewsDocument, ReviewFragment } from '../api/generated-types'
import { ConfettiView } from '../common/confetti'
import { AddReviewReplyForm } from '../reviews/add-review-reply-form'
import { CreateReviewForm } from '../reviews/create-review-form'
import { EditReviewForm } from '../reviews/edit-review-form'
import { ReviewListColumns } from '../reviews/review-list-columns'

interface CustomerReviewsProps {
  customer: CustomerFragment
}

export const CustomerReviews = (props: CustomerReviewsProps) => {
  const { customer } = props

  const [isCreateReviewVisible, setIsCreateReviewVisible] = useState(false)
  const [showConfetti, setShowConfetti] = useState(false)
  const [reviewReplyId, setReviewReplyId] = useState<string | undefined>(undefined)
  const [editReviewId, setEditReviewId] = useState<string | undefined>(undefined)

  useEffect(() => {
    if (showConfetti) {
      setTimeout(() => setShowConfetti(false), 5000)
    }
  }, [showConfetti])

  const onClickReply = (review: ReviewFragment) => setReviewReplyId(review.id)
  const onClickEdit = (review: ReviewFragment) => setEditReviewId(review.id)

  const reviewListColumns = ReviewListColumns({ onClickReply, onClickEdit })

  const { data, loading } = useQuery(GetCustomerReviewsDocument, {
    variables: {
      customerId: customer.id
    }
  })
  const reviews = data?.getCustomer?.reviews

  return (
    <Fragment>
      <EuiFlexGroup gutterSize="none" alignItems="center">
        <EuiFlexItem grow={true}>
          <EuiTitle size="s">
            <h1>Reviews</h1>
          </EuiTitle>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiButton iconType="plus" fill size="s" onClick={() => setIsCreateReviewVisible(true)}>
            New review
          </EuiButton>
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiSpacer />
      {loading ? (
        <EuiLoadingSpinner />
      ) : reviews ? (
        <>
          <EuiBasicTable items={reviews} columns={reviewListColumns} />

          {isCreateReviewVisible && (
            <CreateReviewForm
              customerId={customer.id}
              onClose={() => setIsCreateReviewVisible(false)}
              onFiveStarReview={() => setShowConfetti(true)}
            />
          )}
        </>
      ) : (
        <EuiCallOut color="danger">Error loading reviews</EuiCallOut>
      )}
      {showConfetti && <ConfettiView />}

      {reviewReplyId && <AddReviewReplyForm reviewId={reviewReplyId} onClose={() => setReviewReplyId(undefined)} />}
      {editReviewId && <EditReviewForm reviewId={editReviewId} onClose={() => setEditReviewId(undefined)} />}
    </Fragment>
  )
}
