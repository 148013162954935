import { CognitoAuth } from 'amazon-cognito-auth-js'
import { CognitoUserPool, CognitoUserSession } from 'amazon-cognito-identity-js'
import { compact } from 'lodash-es'
import { DateTime } from 'luxon'
import { IAppContext } from '../app/app-context'
import { IAuthenticationInteractor } from './authentication-interactor'
import { SessionState } from './session'

export interface CognitoConfig {
  region: string
  userPool: string
  clientId: string
  userPoolBaseUri: string
  callbackUri: string
  signoutUri: string
  tokenScopes: string[]
}

export class CognitoInteractor implements IAuthenticationInteractor {
  readonly appContext: IAppContext
  readonly _config: CognitoConfig
  readonly _cognitoAuth: CognitoAuth
  constructor(appContext: IAppContext) {
    this.appContext = appContext
    this._config = CognitoInteractor.getConfig(appContext)
    this._cognitoAuth = CognitoInteractor.createCognitoAuth(this._config)
  }

  signInUri() {
    const { userPoolBaseUri, callbackUri, clientId } = this._config
    return `${userPoolBaseUri}/login?response_type=code&client_id=${clientId}&redirect_uri=${callbackUri}`
  }

  signOut() {
    this._cognitoAuth.signOut()
  }

  async handleCallback(url: string, redirectUrl?: string): Promise<SessionState> {
    console.log('handleCallback', url, redirectUrl)
    await this.parseCognitoWebResponse(url)
    return this.getSessionState()
  }

  refresh(): Promise<SessionState | undefined> {
    return new Promise((resolve, reject) => {
      const cognitoUser = this.getUser()
      if (!cognitoUser) {
        console.error('Failure getting Cognito session: no user')
        window.location.reload()
        return
      }
      cognitoUser.getSession((err: any, result: CognitoUserSession | null) => {
        if (err || !result || !result.isValid()) {
          //NotAuthorizedError, Refresh Token probably expired
          console.error('Failure getting Cognito session: ' + err)
          window.location.reload()
          return resolve({
            signedIn: false
          })
        }
        try {
          return cognitoUser.refreshSession(result.getRefreshToken(), (err) => {
            if (err) {
              console.error('refreshSession cb', err)
              resolve({
                signedIn: false
              })
            } else {
              resolve(this.getSessionState())
            }
          })
        } catch (e: any) {
          console.error('refreshSession handled', e)
          reject(e)
        }
      })
    })
  }

  getSessionState(): Promise<SessionState> {
    return new Promise((resolve) => {
      const cognitoUser = this.getUser()
      if (!cognitoUser) {
        console.log('cognito utils: no user')
        return resolve({
          signedIn: false
        })
      }

      return cognitoUser.getSession((err: any, cognitoUserSession: CognitoUserSession) => {
        if (err || !cognitoUserSession) {
          console.error('Failure getting Cognito session: ' + JSON.stringify(err))
          return resolve({ signedIn: false })
        } else {
          return resolve(this.toSessionState(cognitoUserSession))
        }
      })
    })
  }

  private toSessionState(cognitoUserSession: CognitoUserSession): SessionState {
    console.log('cognitoUserSession', cognitoUserSession)
    const payload = cognitoUserSession.getIdToken().decodePayload()
    const firstName = payload?.given_name
    const lastName = payload?.family_name
    const fullName = compact([firstName, lastName]).join(' ')
    const email = payload?.email
    const exp = payload?.exp ?? new Date().getUTCSeconds()
    const avatar = payload?.picture
    // const username = cognitoUserSession.idToken.payload['cognito:username']
    const username = payload['preferred_username']
    const id = payload['fsp:user_id']
    const tenantId = payload['fsp:tenant_id'] ?? 'fallonsolutions'
    const expiry = DateTime.fromSeconds(exp).toISO()
    const expiryEpoch = exp

    const session: SessionState = {
      credentials: {
        accessToken: cognitoUserSession.getAccessToken().getJwtToken(),
        idToken: cognitoUserSession.getIdToken().getJwtToken()
      },
      id,
      tenantId,
      username,
      email,
      firstName,
      lastName,
      fullName,
      avatar,
      expiry,
      expiryEpoch,
      signedIn: true,
      token: cognitoUserSession.getIdToken().getJwtToken(),
      accessToken: cognitoUserSession.getAccessToken().getJwtToken()
    }
    return session
  }

  static createCognitoAuth(config: CognitoConfig): CognitoAuth {
    const { userPoolBaseUri, userPool, clientId, tokenScopes, callbackUri, signoutUri } = config
    const appWebDomain = userPoolBaseUri.replace('https://', '').replace('http://', '')
    const auth = new CognitoAuth({
      UserPoolId: userPool,
      ClientId: clientId,
      AppWebDomain: appWebDomain,
      TokenScopesArray: tokenScopes,
      RedirectUriSignIn: callbackUri,
      RedirectUriSignOut: signoutUri
    })
    return auth
  }

  parseCognitoWebResponse(href: any) {
    return new Promise((resolve, reject) => {
      console.log('parseCognitoWebResponse')
      // userHandler will trigger the promise
      this._cognitoAuth.userhandler = {
        onSuccess: resolve,
        onFailure: (err) => reject(new Error('Failure parsing Cognito web response: ' + JSON.stringify(err)))
      }
      this._cognitoAuth.parseCognitoWebResponse(href)
    })
  }

  private getUser() {
    const pool = this.getUserPool()
    return pool.getCurrentUser()
  }

  // Creates a CognitoUserPool instance
  private getUserPool() {
    const { userPool, clientId } = this._config
    return new CognitoUserPool({
      UserPoolId: userPool,
      ClientId: clientId
    })
  }

  private static getConfig(appContext: IAppContext): CognitoConfig {
    return {
      region: appContext.region,
      userPool: appContext.userPoolId,
      clientId: appContext.cognitoClientId,
      userPoolBaseUri: `https://${appContext.authDomain}`,
      callbackUri: `https://${appContext.authCallbackUri}`,
      signoutUri: `https://${appContext.authSignoutUri}`,
      tokenScopes: appContext.tokenScopes
    }
  }
}
