import {
  GetInteractionMessageDocument,
  InteractionMessageFragment,
  InteractionRecordingType
} from '../api/generated-types'
import { PreviewCard } from '../common/preview/preview-card'
import { AudioPlayer } from '../common/audio-player'
import { EuiHorizontalRule, EuiLink } from '@elastic/eui'
import { VideoPlayer } from '../common/video-player'
import { compact } from 'lodash-es'

export interface InteractionRecordingsPreviewProps {
  interactionMessageId: string
  firstAudio?: boolean
  autoplay?: boolean
}

export const InteractionRecordingsPreview = ({
  interactionMessageId,
  firstAudio,
  autoplay = false
}: InteractionRecordingsPreviewProps) => {
  const renderPlayers = (interactionMessage: InteractionMessageFragment | undefined) => {
    if (!interactionMessage) {
      return <></>
    }
    const recordings =
      (firstAudio
        ? compact([interactionMessage.recordings?.find((r) => r.type === InteractionRecordingType.Audio)])
        : interactionMessage.recordings) ?? []

    return recordings.map(({ url, type, transcription }, i) => {
      const autoPlayFirst = autoplay && i === 0
      const player =
        type === InteractionRecordingType.Audio ? (
          renderAudio(url, autoPlayFirst)
        ) : type === InteractionRecordingType.Video ? (
          renderVideo(url, autoPlayFirst)
        ) : (
          <div>Missing Recording Renderer</div>
        )
      return (
        <div>
          {player}
          <div>{transcription}</div>
          <div>
            <EuiLink href={url} target="_blank" rel="noopener noreferrer" style={{ fontSize: '10px' }}>
              {url.replace('https://', '')}
            </EuiLink>
          </div>
          <EuiHorizontalRule />
        </div>
      )
    })
  }

  const renderAudio = (url: string, autoplay: boolean) => (
    <div style={{ padding: '9px' }}>
      <AudioPlayer url={url} autoplay={autoplay} />
    </div>
  )

  const renderVideo = (url: string, autoplay: boolean) => (
    <div style={{ padding: '9px' }}>
      <VideoPlayer url={url} autoplay={autoplay} />
    </div>
  )

  return (
    <>
      <PreviewCard
        query={GetInteractionMessageDocument}
        variables={{ input: { id: interactionMessageId } }}
        modelName="recording"
        padding="0"
      >
        {({ data }) => renderPlayers(data.getInteractionMessage?.interactionMessage ?? undefined)}
      </PreviewCard>
    </>
  )
}
