import { useEffect, useRef } from 'react'
import { useAuthenticated } from '../auth/authenticated-context'
import { useApp } from './app-context'
import { SessionState } from '../auth/session'
import { UserAuthFragment } from '../api/generated-types'
import { useLocalstorageState } from 'rooks'

export interface CrispConfig {
  websiteId: string
  websiteSource: string
}

const CrispWidget = () => {
  const app = useApp()
  const crispConfig: CrispConfig = {
    websiteId: app.crispWebsiteId,
    websiteSource: 'https://client.crisp.chat/l.js'
  }
  const session = useAuthenticated().user
  const userFragment = useAuthenticated().userFragment
  // setting it as unary operator type allows for type changing on the fly
  const script = useRef<HTMLScriptElement | null>(null)
  //changed in the settings.tsx file
  const [chatToggle] = useLocalstorageState('crispStatus', true)
  const [crispSoundToggle] = useLocalstorageState('crispSoundToggle', false)
  const [crispPositionToggle] = useLocalstorageState('crispPositionToggle', false)

  const isEnabled = session.signedIn && chatToggle && crispConfig.websiteId
  const isLoaded = script.current !== null

  const addCrispToWebsite = () => {
    window.CRISP_WEBSITE_ID = crispConfig.websiteId // crisp checks this value internally
    script.current = document.createElement('script')
    script.current.src = crispConfig.websiteSource
    script.current.async = true
    document.getElementsByTagName('head')[0].appendChild(script.current)
  }

  useEffect(() => {
    if (!isEnabled || isLoaded) {
      return
    }
    try {
      window.$crisp = createCrispSettings({ session, userFragment, crispSoundToggle, crispPositionToggle })
      addCrispToWebsite()
    } catch (err) {
      console.error('error loading crisp', err)
      script.current = null
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userFragment, session, isLoaded, isEnabled])

  return <></>
}

interface CreateCrispSettingsProps {
  session: SessionState
  userFragment: UserAuthFragment
  crispSoundToggle: boolean
  crispPositionToggle: boolean
}

const createCrispSettings = ({
  session,
  userFragment,
  crispSoundToggle,
  crispPositionToggle
}: CreateCrispSettingsProps): any[][] => {
  if (!session.id) throw new Error('user Attributes not properly loaded')
  window.CRISP_TOKEN_ID = session.id // this ties a users session (chat) to their email so its cross browser/platform
  const crisp: any[][] = [] // vales pushed into this are used internally by crisp
  crisp.push(['set', 'user:email', [session.email ?? '']])
  crisp.push(['set', 'user:nickname', [session.fullName ?? '']])
  crisp.push(['set', 'user:avatar', [userFragment.avatar]])
  crisp.push(['config', 'sound:mute', [crispSoundToggle]])
  crisp.push(['config', 'position:reverse', crispPositionToggle]) // puts the widget on the bottom left or right
  return crisp
}

export default CrispWidget

declare global {
  interface Window {
    $crisp: any
    CRISP_WEBSITE_ID: string
    CRISP_TOKEN_ID: string
  }
}
