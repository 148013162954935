import { EuiButton, EuiSpacer } from '@elastic/eui'
import { map } from 'lodash-es'
import { JobType } from '../../../api/generated-types'
import { Callout } from '../../../common/callout'
import { getResultUpdater } from '../../helpers/results-updater'
import { SingleSelectField } from '../../question-fields/single-select-question-field'
import { WorkflowActionProps } from '../../workflow-model'
import { TopicActionInput } from '../action-topic-model'
import { TopicWorkflowAction } from '../action-topic-view'
import { TopicQualification } from '../common/common-outcome'
import {
  DrainRevisitAttendanceDate,
  DrainRevisitIssue,
  DrainServiceType,
  TopicDrainRevisitActionResult,
  drainRevisitNotesGenerator
} from './action-topic-drain-revisit-model'
import { outcomeGenerator } from './action-topic-drain-revisit-outcome'

export const TopicDrainRevisitAction = (
  props: WorkflowActionProps<TopicActionInput, TopicDrainRevisitActionResult>
) => {
  const { result, input, onUpdate } = props
  const { serviceType, issue, attendanceDate } = result ?? {}

  const notesGenerator = drainRevisitNotesGenerator

  const topicOutcome = outcomeGenerator(result)
  const canComplete = topicOutcome.qualification === TopicQualification.InScope

  const onNext = () =>
    onUpdate({
      ...result,
      actionCompleted: true,
      ...topicOutcome,

      workRequiredNotes: generatedNotes
    })

  const generatedNotes = notesGenerator.generateNotes(result ?? {})
  const updateResult = getResultUpdater(result ?? {}, onUpdate).updateResult

  const getIssueTypes = () => {
    switch (serviceType) {
      case DrainServiceType.Reline:
        return [DrainRevisitIssue.DrainBlockedAfterReline]
      default:
        return [DrainRevisitIssue.DrainBlockedAgain, DrainRevisitIssue.QualityOfWork]
    }
  }

  return (
    <TopicWorkflowAction input={input} onUpdate={onUpdate} result={result}>
      <SingleSelectField
        question={notesGenerator.getQuestion('serviceType')}
        options={map(DrainServiceType, (id) => ({ id }))}
        answer={serviceType}
        changeAnswer={(serviceType) => updateResult({ serviceType })}
      />

      {serviceType && (
        <SingleSelectField
          question={notesGenerator.getQuestion('issue')}
          options={map(getIssueTypes(), (id) => ({ id }))}
          answer={issue}
          changeAnswer={(issue) => updateResult({ issue })}
        />
      )}

      {issue === DrainRevisitIssue.DrainBlockedAgain && (
        <>
          <SingleSelectField
            question={notesGenerator.getQuestion('attendanceDate')}
            options={map(DrainRevisitAttendanceDate, (id) => ({ id }))}
            answer={attendanceDate}
            changeAnswer={(attendanceDate) => updateResult({ attendanceDate })}
          />
        </>
      )}

      {topicOutcome.requirements && (
        <>
          <EuiSpacer />
          <Callout
            type="note"
            title={topicOutcome.type === JobType.Callback ? 'Revisit job' : 'Normal service job with travel fee'}
          />
          <Callout type="note">{topicOutcome.requirements.attributes?.map((r) => r.attributeId).join(', ')}</Callout>
        </>
      )}

      <EuiSpacer />
      <EuiButton disabled={!canComplete} onClick={() => onNext()}>
        Next
      </EuiButton>
    </TopicWorkflowAction>
  )
}
