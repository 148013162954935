import { EuiButton, EuiSpacer, EuiText } from '@elastic/eui'
import { JobTopicType } from '@fallonsolutions/types'
import { includes } from 'lodash-es'
import { CustomerType, EnquiryAbortReason } from '../../../api/generated-types'
import { Callout } from '../../../common/callout'
import { OutOfScope } from '../../helpers/out-of-topic'
import { getResultUpdater } from '../../helpers/results-updater'
import { useWorkflow } from '../../helpers/workflow-provider'
import { SingleSelectField } from '../../question-fields/single-select-question-field'
import { WorkflowActionProps } from '../../workflow-model'
import { TopicActionInput } from '../action-topic-model'
import { TopicWorkflowAction } from '../action-topic-view'
import { TopicQualification } from '../common/common-outcome'
import { InspectionElectrical } from './action-topic-inspection-electrical'
import {
  CurrentMembershipStatus,
  InspectionType,
  TopicInspectionActionResult,
  inspectionNotesGenerator
} from './action-topic-inspection-model'
import { outcomeGenerator } from './action-topic-inspection-outcome'
import { InspectionPlumbing } from './action-topic-inspection-plumbing'

export const TopicInspectionAction = (props: WorkflowActionProps<TopicActionInput, TopicInspectionActionResult>) => {
  const workflowContext = useWorkflow()
  const { result, input, onUpdate } = props
  const { currentMembership, inspectionType, customerType } = result ?? {}

  const notesGenerator = inspectionNotesGenerator

  const topicOutcome = outcomeGenerator(result)
  const { requirements, qualification } = topicOutcome
  const isOutOfScope = qualification === TopicQualification.OutOfScope

  const onNext = () =>
    onUpdate({
      ...result,
      ...topicOutcome,
      actionCompleted: true,

      workRequiredNotes: generatedNotes
    })

  const generatedNotes = notesGenerator.generateNotes(result ?? {})
  const updateResult = getResultUpdater(result ?? {}, onUpdate).updateResult

  const canComplete = !!requirements

  const renderIssue = (inspectionType: InspectionType) => {
    switch (inspectionType) {
      case InspectionType.Drains:
      case InspectionType.Plumbing:
      case InspectionType.PlumbingAndDrains:
        return <InspectionPlumbing result={result} updateResult={updateResult} notesGenerator={notesGenerator} />
      case InspectionType.Electrical:
      case InspectionType.Solar:
      case InspectionType.Security:
        return <InspectionElectrical result={result} updateResult={updateResult} notesGenerator={notesGenerator} />
      default:
        return undefined
    }
  }

  const isCurrentMember = includes(
    [CurrentMembershipStatus.Current1YearPlan, CurrentMembershipStatus.Current3YearPlan],
    currentMembership
  )
  const isNotCurrentMember = currentMembership === CurrentMembershipStatus.NotCurrent
  const isDomestic = customerType === CustomerType.Domestic
  return (
    <>
      {isDomestic ? (
        <>
          <TopicWorkflowAction input={input} onUpdate={onUpdate} result={result}>
            <Callout type="script">
              <EuiText>
                <div>So you want to redeem one of your complimentary inspections?</div>
                <EuiSpacer size="s" />
                <div>Let me just check your membership details on your account...</div>
              </EuiText>
            </Callout>
            <SingleSelectField
              question={notesGenerator.getQuestion('currentMembership')}
              options={[
                {
                  id: CurrentMembershipStatus.Current1YearPlan,
                  label: 'Current red plan (1yr) member'
                },
                {
                  id: CurrentMembershipStatus.Current3YearPlan,
                  label: 'Current black plan (3yr) member'
                },
                {
                  id: CurrentMembershipStatus.NotCurrent,
                  label: 'Not a current member'
                }
              ]}
              answer={currentMembership}
              changeAnswer={(currentMembership) => updateResult({ currentMembership, inspectionType: undefined })}
              questionType="note"
            />

            {isCurrentMember && (
              <SingleSelectField
                question={notesGenerator.getQuestion('inspectionType')}
                options={[
                  {
                    id: InspectionType.Electrical
                  },
                  {
                    id: InspectionType.FilterClean
                  },
                  {
                    id: InspectionType.Plumbing
                  },
                  {
                    id: InspectionType.Drains
                  },
                  {
                    id: InspectionType.PlumbingAndDrains
                  },
                  ...(currentMembership === CurrentMembershipStatus.Current3YearPlan
                    ? [
                        {
                          id: InspectionType.Solar,
                          label: 'Solar'
                        },
                        {
                          id: InspectionType.Security,
                          label: 'Security'
                        }
                      ]
                    : [])
                ]}
                answer={inspectionType}
                changeAnswer={(inspectionType) => updateResult({ inspectionType })}
              />
            )}

            {isNotCurrentMember && (
              <>
                <Callout type="script">
                  <EuiText>
                    <div>I'm sorry but it appears that you don’t have an active membership with us.</div>
                    <EuiSpacer size="s" />
                    <div>
                      If you would like, I can process a new membership over the phone with you now and then book in an
                      inspection OR
                    </div>
                    <EuiSpacer size="s" />
                    <div>Alternatively, I can process a general booking for you?</div>
                  </EuiText>
                </Callout>
                <EuiSpacer />
                <Callout type="note">
                  <EuiText>
                    <div>Use change buttons to either go back and make a membership purchase or book a general job</div>
                  </EuiText>
                </Callout>
              </>
            )}

            {inspectionType && renderIssue(inspectionType)}

            {inspectionType === InspectionType.FilterClean && (
              <EuiButton onClick={() => workflowContext.changeJobTopic(JobTopicType.HVACInspection)} color="warning">
                Switch topic to HVAC Inspection
              </EuiButton>
            )}

            {isOutOfScope && (
              <>
                <Callout type="script">Unfortunately this type of work is out of scope for us.</Callout>
                <EuiSpacer />
                <EuiButton
                  color="warning"
                  onClick={() =>
                    workflowContext.abort({
                      abortReason: EnquiryAbortReason.CustomerNeedsAreOutOfService,
                      notes: `Customer was enquiring about Inspection: ${inspectionType}`
                    })
                  }
                >
                  Offer a voucher and end call
                </EuiButton>
              </>
            )}

            {!isOutOfScope &&
              includes(
                [InspectionType.Drains, InspectionType.PlumbingAndDrains, InspectionType.Plumbing],
                inspectionType
              ) && (
                <Callout type="note">
                  <EuiText>
                    {includes([InspectionType.Plumbing, InspectionType.PlumbingAndDrains], inspectionType) && (
                      <div>
                        <div>
                          <strong>1 &times; Complimentary Plumbing Inspection- HPP Red &amp; Black Plans</strong>
                        </div>
                        Complimentary visual safety inspection of your plumbing system by a licensed plumber - value
                        $220. If you need any plumbing work done, book it on this visit and pay no travel fee.
                      </div>
                    )}
                    {inspectionType === InspectionType.PlumbingAndDrains && <EuiSpacer size="m" />}
                    {includes([InspectionType.Drains, InspectionType.PlumbingAndDrains], inspectionType) && (
                      <div>
                        <div>
                          <strong>1 &times; Complimentary Drain Camera Examination - HPP Red &amp; Black Plans</strong>
                        </div>
                        To ensure correct flow and operation of sewer drains, we conduct a 30min CCTV camera inspection
                        - value $290. If you need any drain work done, book it on this visit and pay no travel fee.
                      </div>
                    )}
                  </EuiText>
                </Callout>
              )}

            {requirements && (
              <>
                <EuiSpacer />
                <Callout type="note">{requirements.attributes?.map((r) => r.attributeId).join(', ')}</Callout>
              </>
            )}

            {!isOutOfScope && inspectionType !== InspectionType.FilterClean && (
              <>
                <EuiSpacer />
                <EuiButton disabled={!canComplete} onClick={() => onNext()}>
                  Next
                </EuiButton>
              </>
            )}
          </TopicWorkflowAction>
        </>
      ) : (
        <>
          <OutOfScope
            isOutOfScope={true}
            issue={'commercial HPP inspection'}
            reason="We do not offer our HPP member inspection promotion to non domestic customers"
          />
        </>
      )}
    </>
  )
}
