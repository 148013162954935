import { useMutation } from '@apollo/client'
import {
  EuiButton,
  EuiCallOut,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiFormRow,
  EuiRadioGroup,
  EuiRadioGroupOption,
  EuiSpacer
} from '@elastic/eui'
import { useState } from 'react'
import {
  AbortAppointmentDocument,
  AbortAppointmentInput,
  ArrangementCancellationReason,
  AttendedStatusType,
  CancelAppointmentDocument,
  CancelAppointmentInput,
  CancelJobDocument,
  CancelJobInput,
  JobStatusType,
  UpdateJobStatusDocument,
  UpdateJobStatusInput
} from '../api/generated-types'
import { decamelise } from '../common/utils'
import { ArrangementCancellationReasonSelector } from './arrangement-cancellation-reason-selector'

interface CancelAppointmentFormProps {
  appointmentId: string
  appointmentAttended: boolean
  job: any // TODO: JobFragment doesn't work here as EventDetail uses a different set of fields
  onSubmit: () => void
}

export const CancelAppointmentForm = (props: CancelAppointmentFormProps) => {
  const { appointmentId, job, appointmentAttended, onSubmit } = props
  const canCancelJob =
    (job?.status?.status !== JobStatusType.Complete || job?.status?.status !== JobStatusType.Cancelled) &&
    (!job?.attended || job.attended === AttendedStatusType.NotAttended)

  const canCompleteJob =
    job?.status?.status !== JobStatusType.Complete &&
    job?.status?.status !== JobStatusType.Cancelled &&
    job?.status?.status !== JobStatusType.CancelledWork &&
    job?.attended &&
    job.attended === AttendedStatusType.Attended

  const canCancelWorksOnJob =
    job?.status?.status !== JobStatusType.Complete &&
    job?.status?.status !== JobStatusType.CancelledWork &&
    job?.attended &&
    job.attended === AttendedStatusType.Attended

  const DoNotChangeJobStatus = 'Do not change, cancel this appointment only'
  const jobStatusOptions: EuiRadioGroupOption[] = [
    DoNotChangeJobStatus,
    ...(canCancelJob ? [JobStatusType.Cancelled, JobStatusType.OnHold, JobStatusType.WaitingForParts] : []),
    ...(canCompleteJob ? [JobStatusType.Complete] : []),
    ...(canCancelWorksOnJob ? [JobStatusType.CancelledWork] : [])
  ].map((s) => ({
    id: s,
    label: s.indexOf(' ') >= 0 ? s : decamelise(s),
    value: s.indexOf(' ') >= 0 ? s : decamelise(s)
  }))

  const [jobStatus, setJobStatus] = useState<JobStatusType | string | undefined>(undefined)
  const [reason, setReason] = useState<ArrangementCancellationReason | undefined>(undefined)
  const reasonSet = (reason: ArrangementCancellationReason | undefined) => {
    setReason(reason)
  }

  const [cancelAppointment, { loading: cancellingAppointment }] = useMutation(CancelAppointmentDocument)
  const [abortAppointment, { loading: abortingAppointment }] = useMutation(AbortAppointmentDocument)
  const [updateJobStatus, { loading: updatingJobStatus }] = useMutation(UpdateJobStatusDocument)
  const [cancelJob, { loading: cancellingJob }] = useMutation(CancelJobDocument)

  const loading = cancellingAppointment || abortingAppointment || updatingJobStatus || cancellingJob

  const canCancelAppointment = reason && jobStatus && !!appointmentId && !appointmentAttended
  const canAbortAppointment = reason && jobStatus && !!appointmentId && appointmentAttended

  // console.log('jobStatus', job?.status?.status)
  // console.log('canCompleteJob', canCompleteJob)
  // console.log('canCancelWorksJob', canCancelWorksOnJob)
  // console.log('canAbortAppointment', canAbortAppointment)
  // console.log('canCancelAppointment', canCancelAppointment)
  const handleSubmit = async () => {
    console.log('handle appointment cancellation', reason, jobStatus, appointmentId)
    if (canCancelAppointment) {
      const jobId = job?.id
      console.log('job id ' + jobId)
      const cancelAppointmentInput: CancelAppointmentInput = {
        id: appointmentId,
        reason: reason as ArrangementCancellationReason
      }
      await cancelAppointment({ variables: { input: cancelAppointmentInput } })
      if (jobId && jobStatus !== DoNotChangeJobStatus) {
        if (jobStatus === JobStatusType.Cancelled) {
          const cancelJobInput: CancelJobInput = {
            id: jobId,
            reason: reason as ArrangementCancellationReason
          }
          await cancelJob({ variables: { input: cancelJobInput } })
        } else {
          const updateJobStatusInput: UpdateJobStatusInput = {
            job: jobId,
            status: jobStatus as JobStatusType
          }
          await updateJobStatus({ variables: { input: updateJobStatusInput } })
        }
      }
      onSubmit()
    }
    if (canAbortAppointment) {
      const jobId = job?.id
      const abortAppointmentInput: AbortAppointmentInput = {
        id: appointmentId,
        reason: reason as ArrangementCancellationReason
      }
      await abortAppointment({ variables: { input: abortAppointmentInput } })
      if (jobId && jobStatus !== DoNotChangeJobStatus) {
        if (jobStatus === JobStatusType.CancelledWork) {
          const cancelJobInput: CancelJobInput = {
            id: jobId,
            reason: reason as ArrangementCancellationReason
          }
          await cancelJob({ variables: { input: cancelJobInput } })
        } else {
          const updateJobStatusInput: UpdateJobStatusInput = {
            job: jobId,
            status: jobStatus as JobStatusType
          }
          await updateJobStatus({ variables: { input: updateJobStatusInput } })
        }
      }
      onSubmit()
    }
  }

  return (
    <EuiForm>
      {
        <>
          <EuiFormRow label="Reason">
            <ArrangementCancellationReasonSelector reason={reason} changeReason={reasonSet} />
          </EuiFormRow>

          <>
            <EuiFormRow label="Job status">
              <>
                <EuiRadioGroup
                  options={jobStatusOptions}
                  idSelected={jobStatus}
                  onChange={(id) => setJobStatus(id)}
                  data-test-subj="cancelled-options"
                />
              </>
            </EuiFormRow>
            <EuiSpacer />
            {!loading && !canCancelJob && (canCompleteJob || canCancelWorksOnJob) && (
              <>
                <EuiCallOut size="s">
                  Please set <b>Job status</b> to{' '}
                  <b>
                    <i>Complete</i>
                  </b>{' '}
                  if all works have been completed and no more appointments are needed.&nbsp;OR{' '}
                  <b>
                    <i>Cancelled Works</i>
                  </b>{' '}
                  if the job was attended and if any works sold were cancelled.
                </EuiCallOut>
                <EuiSpacer />
              </>
            )}

            <EuiFlexGroup>
              <EuiFlexItem grow={true} />
              <EuiFlexItem>
                <EuiButton
                  onClick={handleSubmit}
                  fill
                  color="danger"
                  disabled={!(canCancelAppointment || canAbortAppointment)}
                  isLoading={loading}
                  data-test-id="cancelAppointmentButton"
                >
                  Cancel appointment
                </EuiButton>
              </EuiFlexItem>
            </EuiFlexGroup>
          </>
        </>
      }
    </EuiForm>
  )
}
