import { EuiButton, EuiSpacer } from '@elastic/eui'
import { WorkflowAction } from '../workflow-action'
import { BaseActionInput, BaseActionResult, WorkflowActionProps } from '../workflow-model'
import { ContactActionResult } from './action-contact'
import { CustomerAction, CustomerActionResult } from './action-customer'

export interface ConfirmMembershipActionInput extends BaseActionInput {
  contact?: ContactActionResult
}

export interface ConfirmMembershipActionResult extends BaseActionResult {
  customer?: CustomerActionResult
}

export const ConfirmMembershipAction = (
  props: WorkflowActionProps<ConfirmMembershipActionInput, ConfirmMembershipActionResult>
) => {
  const { input, result, onUpdate } = props
  const { contact } = input
  const { actionCompleted, customer } = result ?? {}

  const setCustomer = (customer: CustomerActionResult | undefined) => onUpdate({ ...result, customer })
  const setActionCompleted = (actionCompleted: boolean | undefined) => onUpdate({ ...result, actionCompleted })

  return (
    <>
      <CustomerAction input={{ contact }} result={customer} onUpdate={setCustomer} />
      {customer?.customer?.id && (
        <WorkflowAction
          title="Confirm membership"
          value={actionCompleted ? 'Completed' : undefined}
          onClickChange={() => setActionCompleted(false)}
        >
          <EuiSpacer />
          <EuiButton fill color="primary" onClick={() => setActionCompleted(true)}>
            I've completed these steps
          </EuiButton>
        </WorkflowAction>
      )}
    </>
  )
}
