import { CustomerLinkFragment } from '../api/generated-types'
import createPersistedState from '../use-persisted-state'
import { ContactFormV2 } from './contact-form-v2'

const useContactDuplicateCheck = createPersistedState<boolean>('contactDuplicateCheck')

export interface CreateContactFormProps {
  customer?: CustomerLinkFragment // optional customer to link contact to
  onClose: () => void
}

export const CreateContactForm = (props: CreateContactFormProps) => {
  const [duplicateCheck] = useContactDuplicateCheck(false)

  return <ContactFormV2 duplicateCheck={duplicateCheck} {...props} />
}
