import { useMutation, useQuery } from '@apollo/client'
import {
  EuiButton,
  EuiButtonEmpty,
  EuiCallOut,
  EuiFlexGrid,
  EuiFlexItem,
  EuiForm,
  EuiFormRow,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiOverlayMask,
  EuiSpacer,
  EuiSwitch
} from '@elastic/eui'
import { RiskAssessmentTemplateFragment } from '@fallonsolutions/types'
import { useMemo, useState } from 'react'
import {
  GetRiskAssessmentTemplatesDocument,
  JobFragment,
  JobRequirement,
  UpdateJobRequirementsDocument,
  UpdateJobRequirementsInput
} from '../api/generated-types'
import { useAuthenticated } from '../auth/authenticated-context'

export interface JobRequirementsEditProps {
  job: JobFragment
  closeModal: () => void
}

export const JobRequirementsEdit = (props: JobRequirementsEditProps) => {
  const { job, closeModal } = props

  const userFragment = useAuthenticated().userFragment
  const hasDeveloperFeatures = userFragment.permissions?.developerFeatures === true

  const [primaryOption, setPrimaryOption] = useState<JobRequirement>(
    job.requirements?.primaryOption ?? JobRequirement.Must
  )
  const [inspection, setInspection] = useState<JobRequirement>(job.requirements?.inspection ?? JobRequirement.Must)
  const [futileApproval, setFutileApproval] = useState<JobRequirement>(
    job.requirements?.futileApproval ?? JobRequirement.NotApplicable
  )

  const [selectedRiskAssessmentTemplate, setSelectedRiskAssessmentTemplate] = useState<string | undefined>(
    job.requirements?.riskAssessmentTemplate ?? undefined
  )

  const [updateJobRequirements, { error, loading }] = useMutation(UpdateJobRequirementsDocument, {
    refetchQueries: ['GetJob'],
    awaitRefetchQueries: true
  })

  const { data: templatesData, loading: templatesLoading } = useQuery(GetRiskAssessmentTemplatesDocument, {
    variables: {}
  })

  const templates: RiskAssessmentTemplateFragment[] = useMemo(
    () => templatesData?.getRiskAssessmentTemplates?.riskAssessmentTemplates ?? [],
    [templatesData]
  )

  const onSelectNewRiskAssessmentTemplate = (templateId: string) => {
    const template = templates.find((t) => t.id === templateId)
    if (template) {
      setSelectedRiskAssessmentTemplate(template.id)
    }
  }

  const handleUpdate = async () => {
    const input: UpdateJobRequirementsInput = {
      job: job.id,
      primaryOption,
      inspection,
      futileApproval,
      riskAssessmentTemplate: selectedRiskAssessmentTemplate
    }
    try {
      await updateJobRequirements({ variables: { input } })
      closeModal()
    } catch (error) {
      console.error('error performing mutation', error)
    }
  }

  const form = (
    <EuiForm>
      {error && (
        <>
          <EuiCallOut color="danger" title="Error updating job requirements" />
          <EuiSpacer />
        </>
      )}
      <EuiFormRow label="Pricing">
        <EuiSwitch
          label="Primary option required"
          onChange={(e) => setPrimaryOption(e.target.checked ? JobRequirement.Must : JobRequirement.Could)}
          checked={primaryOption === JobRequirement.Must}
        />
      </EuiFormRow>

      <EuiFormRow label="Inspection">
        <EuiSwitch
          label="Home Safety Inspection required"
          onChange={(e) => setInspection(e.target.checked ? JobRequirement.Must : JobRequirement.Could)}
          checked={inspection === JobRequirement.Must}
        />
      </EuiFormRow>

      {hasDeveloperFeatures && (
        <EuiFormRow label="Futile">
          <EuiSwitch
            label="Futile approval required"
            onChange={(e) => setFutileApproval(e.target.checked ? JobRequirement.Must : JobRequirement.Could)}
            checked={futileApproval === JobRequirement.Must}
          />
        </EuiFormRow>
      )}

      <EuiFormRow label="Risk assessment template">
        {templatesLoading ? (
          <EuiSpacer size="s" />
        ) : (
          <>
            <EuiFlexGrid columns={2} style={{}}>
              {templates.map((template) => (
                <EuiFlexItem>
                  <EuiButton
                    key={template.id}
                    onClick={() => onSelectNewRiskAssessmentTemplate(template.id)}
                    fill={template.id === selectedRiskAssessmentTemplate}
                  >
                    {template.name}
                  </EuiButton>
                </EuiFlexItem>
              ))}
            </EuiFlexGrid>
          </>
        )}
      </EuiFormRow>
    </EuiForm>
  )

  return (
    <EuiOverlayMask>
      <EuiModal onClose={closeModal}>
        <EuiModalHeader>
          <EuiModalHeaderTitle>Job requirements: {job.number}</EuiModalHeaderTitle>
        </EuiModalHeader>
        <EuiModalBody>
          <div>{form}</div>
        </EuiModalBody>
        <EuiModalFooter>
          <EuiButtonEmpty onClick={closeModal} isDisabled={loading}>
            Cancel
          </EuiButtonEmpty>
          <EuiButton onClick={handleUpdate} isLoading={loading} fill>
            Done
          </EuiButton>
        </EuiModalFooter>
      </EuiModal>
    </EuiOverlayMask>
  )
}
