import { EuiText } from '@elastic/eui'
import { Callout } from '../../../../common/callout'

export const ApplianceGoldCoastDisclaimer = ({
  isAppliance,
  isGoldCoastJob
}: {
  isGoldCoastJob: boolean
  isAppliance: boolean
}) => {
  return (
    <>
      {isAppliance && isGoldCoastJob && (
        <>
          <Callout type="warning">
            <EuiText>
              <p>Only book Gold Coast appliance jobs on a Tuesday or Thursday </p>
            </EuiText>
          </Callout>
        </>
      )}
    </>
  )
}
