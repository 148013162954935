import { EuiButton, EuiSpacer } from '@elastic/eui'
import { EnquiryAbortReason } from '../../../api/generated-types'
import { Callout } from '../../../common/callout'
import { getResultUpdater } from '../../helpers/results-updater'
import { useWorkflow } from '../../helpers/workflow-provider'
import { ComboSelectField } from '../../question-fields/combo-select-question-field'
import { FreeTextQuestionField } from '../../question-fields/free-text-question-field'
import { SingleSelectField } from '../../question-fields/single-select-question-field'
import { WorkflowActionProps } from '../../workflow-model'
import { TopicActionInput } from '../action-topic-model'
import { TopicWorkflowAction } from '../action-topic-view'
import { brandOptions, transformBrandSelections } from '../appliance-shared/appliance-brand-model'
import {
  ApplianceAgeEnum,
  GeneralApplianceIssueType,
  InstallEnum,
  RepairOrReplaceEnum,
  WARRANTY_SCRIPT,
  applianceAgeOptions,
  canProceedWithRepair,
  fullServiceOptions,
  isBrandServiceable
} from '../appliance-shared/appliance-model'
import { YesNoValue } from '../common/common-enums'
import { yesNoOptions } from '../common/common-options'
import { TopicQualification } from '../common/common-outcome'
import {
  BOOK_CARPENTER_FIRST_MESSAGE,
  BOOK_PLUMBER_AFTER_CARPENTER_MESSAGE,
  BOOK_PLUMBER_AND_ELECTRICIAN_SAME_TIME_MESSAGE,
  DrainFaultEnum,
  TopicDishwasherActionResult,
  dishwasherFaultOptions,
  dishwasherRepairNotesGenerator
} from './action-topic-dishwasher-model'
import { outcomeGenerator } from './action-topic-dishwasher-outcome'

export const TopicDishwasherAction = (props: WorkflowActionProps<TopicActionInput, TopicDishwasherActionResult>) => {
  const { result, input, onUpdate } = props
  const {
    applianceAge,
    installationNote,
    fault,
    mayVoidWarrantyConfirmationToProceed,
    outletWorksWithOtherDevice,
    otherDrainsBlocked,
    powerOutletNeeded,
    replacementApplianceWillFit,
    selectedBrand,
    confirmedPartsAvailableWithDSR,
    brandServiceable,
    serviceType,
    spaceAvailableForAppliance
  } = result ?? {}
  const workflowContext = useWorkflow()
  const notesGenerator = dishwasherRepairNotesGenerator

  const topicOutcome = outcomeGenerator(result)
  const { requirements, qualification } = topicOutcome
  const isOutOfScope = qualification === TopicQualification.OutOfScope
  const newTopic = topicOutcome.newTopic

  const generatedNotes = notesGenerator.generateNotes(result ?? {})
  const brandWarning = selectedBrand?.warning ?? null //notesGenerator.getWarning('brand', selectedBrand ?? {})
  const onNext = () =>
    onUpdate({
      ...result,
      ...topicOutcome,
      actionCompleted: true,

      workRequiredNotes: generatedNotes
    })

  const updateResult = getResultUpdater(result ?? {}, onUpdate).updateResult

  const mayProceed = canProceedWithRepair(result)
  const canComplete = !!requirements

  const handleBrandSelect = (selectedOptions: any) => {
    const { selectedBrand, brand, brandServiceable } = transformBrandSelections(selectedOptions)
    updateResult({ selectedBrand, brand, brandServiceable, confirmedPartsAvailableWithDSR: undefined })
  }

  return (
    <TopicWorkflowAction input={input} onUpdate={onUpdate} result={result}>
      <SingleSelectField
        question={notesGenerator.getQuestion('serviceType')}
        options={fullServiceOptions}
        answer={serviceType}
        changeAnswer={(serviceType) => updateResult({ serviceType })}
      />
      <EuiSpacer size="m" />

      {serviceType === RepairOrReplaceEnum.Repair && (
        <>
          <SingleSelectField
            question={notesGenerator.getQuestion('applianceAge')}
            options={applianceAgeOptions}
            answer={applianceAge}
            changeAnswer={(applianceAge) => updateResult({ applianceAge })}
          />
          <EuiSpacer size="m" />

          {applianceAge === ApplianceAgeEnum.LessThan2yrsOld && (
            <>
              <Callout type="script" title={WARRANTY_SCRIPT} />
              <SingleSelectField
                question={notesGenerator.getQuestion('mayVoidWarrantyConfirmationToProceed')}
                options={yesNoOptions}
                answer={mayVoidWarrantyConfirmationToProceed}
                changeAnswer={(mayVoidWarrantyConfirmationToProceed) =>
                  updateResult({ mayVoidWarrantyConfirmationToProceed })
                }
              />
            </>
          )}

          {!!applianceAge && mayProceed && (
            <>
              <ComboSelectField
                question={notesGenerator.getQuestion('brand')}
                options={brandOptions}
                // FIXME: broken type here
                answer={selectedBrand as any}
                changeAnswer={handleBrandSelect}
                brandWarning={brandWarning}
              />
              <EuiSpacer size="m" />
              {!!selectedBrand && (
                <>
                  {brandServiceable === YesNoValue.No && (
                    <>
                      <SingleSelectField
                        question={notesGenerator.getQuestion('confirmedPartsAvailableWithDSR')}
                        answer={confirmedPartsAvailableWithDSR}
                        changeAnswer={(confirmedPartsAvailableWithDSR) =>
                          updateResult({ confirmedPartsAvailableWithDSR })
                        }
                        options={yesNoOptions}
                      />
                    </>
                  )}
                  {!!selectedBrand && isBrandServiceable(result) === true && (
                    <SingleSelectField
                      question={notesGenerator.getQuestion('fault')}
                      options={dishwasherFaultOptions}
                      answer={fault}
                      changeAnswer={(fault) => updateResult({ fault })}
                    />
                  )}
                  <EuiSpacer size="m" />
                  {fault === GeneralApplianceIssueType.PowerSupply && (
                    <>
                      <SingleSelectField
                        question={notesGenerator.getQuestion('outletWorksWithOtherDevice')}
                        answer={outletWorksWithOtherDevice}
                        changeAnswer={(outletWorksWithOtherDevice) => updateResult({ outletWorksWithOtherDevice })}
                        options={yesNoOptions}
                      />
                    </>
                  )}
                  <EuiSpacer size="m" />

                  {fault === DrainFaultEnum.Drain && (
                    <SingleSelectField
                      question={notesGenerator.getQuestion('otherDrainsBlocked')}
                      answer={otherDrainsBlocked}
                      changeAnswer={(otherDrainsBlocked) => updateResult({ otherDrainsBlocked })}
                      options={yesNoOptions}
                    />
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
      {serviceType === InstallEnum.NewInstall && (
        <>
          <SingleSelectField
            question={notesGenerator.getQuestion('spaceAvailableForAppliance')}
            options={yesNoOptions}
            answer={spaceAvailableForAppliance}
            changeAnswer={(spaceAvailableForAppliance) => updateResult({ spaceAvailableForAppliance })}
          />
          {spaceAvailableForAppliance === YesNoValue.No && <Callout title={BOOK_CARPENTER_FIRST_MESSAGE} />}
          {!!spaceAvailableForAppliance && (
            <>
              <SingleSelectField
                question={notesGenerator.getQuestion('powerOutletNeeded')}
                options={yesNoOptions}
                answer={powerOutletNeeded}
                changeAnswer={(powerOutletNeeded) => updateResult({ powerOutletNeeded })}
              />
              {powerOutletNeeded === YesNoValue.No && (
                <>
                  <FreeTextQuestionField
                    question={notesGenerator.getQuestion('installationNote')}
                    answer={installationNote}
                    changeAnswer={(installationNote) => updateResult({ installationNote })}
                  />
                  <Callout type="warning" title={BOOK_PLUMBER_AFTER_CARPENTER_MESSAGE} />
                </>
              )}
              {powerOutletNeeded === YesNoValue.Yes && (
                <Callout type="warning" title={BOOK_PLUMBER_AND_ELECTRICIAN_SAME_TIME_MESSAGE} />
              )}
            </>
          )}
        </>
      )}
      {serviceType === RepairOrReplaceEnum.Replace && (
        <>
          <SingleSelectField
            question={notesGenerator.getQuestion('replacementApplianceWillFit')}
            options={yesNoOptions}
            answer={replacementApplianceWillFit}
            changeAnswer={(replacementApplianceWillFit) => updateResult({ replacementApplianceWillFit })}
          />
          <FreeTextQuestionField
            question={notesGenerator.getQuestion('installationNote')}
            answer={installationNote}
            changeAnswer={(installationNote) => updateResult({ installationNote })}
          />

          {replacementApplianceWillFit === YesNoValue.No && (
            <Callout type="note" title={BOOK_CARPENTER_FIRST_MESSAGE} />
          )}
        </>
      )}

      {requirements && requirements.attributes && (
        <>
          <EuiSpacer />
          <Callout type="note">
            Book a technician with {requirements.attributes?.map((r) => r.attributeId).join(', ')} skills
          </Callout>
        </>
      )}

      {!isOutOfScope && !newTopic && (
        <>
          <EuiSpacer />
          <EuiButton disabled={!canComplete} onClick={() => onNext()}>
            Next
          </EuiButton>
        </>
      )}
      {newTopic && (
        <EuiButton onClick={() => workflowContext.changeJobTopic(newTopic.reference)}>{newTopic.buttonLabel}</EuiButton>
      )}

      {isOutOfScope && (
        <>
          <Callout type="script">
            {topicOutcome.qualificationMessage
              ? topicOutcome.qualificationMessage
              : 'Unfortunately this type of work is out of scope for us.'}
          </Callout>
          <EuiSpacer />
          <EuiButton
            color="warning"
            onClick={() =>
              workflowContext.abort({
                abortReason: EnquiryAbortReason.CustomerNeedsAreOutOfService,
                notes: `Customer was enquiring about: ${topicOutcome.qualificationMessage}`
              })
            }
          >
            Offer a voucher and end call
          </EuiButton>
        </>
      )}
    </TopicWorkflowAction>
  )
}
