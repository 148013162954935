import { YesNoValue } from '../common/common-enums'
import { AttributeRequirement, CustomerType, JobType, TradeType } from '../../../api/generated-types'

import { ACBrand, ACType, ServiceLevel, TopicHVACServiceActionResult } from './action-topic-hvac-service-model'
import {
  TopicQualification,
  TopicQualificationResult,
  TopicRequirementsResult,
  createOutcomeGenerator
} from '../common/common-outcome'
import { JobTopicType } from '@fallonsolutions/types'
import { Callout } from '../../../common/callout'
import { is } from '@elastic/eui/src/utils/prop_types/is'

// const GENERAL_HVAC = { attributeId: 'GeneralHVAC' }
const HVAC_SPLIT = { attributeId: 'SplitSystemAC' }
const HVAC_DUCTED = { attributeId: 'DuctedAC' }
const SPLIT_CLEAN = { attributeId: 'SplitSystemACClean' }
const CARPENTRY_QUOTING = { attributeId: 'CarpentryQuoting' }
const SUMMER_ONLY_MESSAGE = <Callout type="note" title="SUMMER TIME: Can only book between 7-8am or 8-11am ONLY" />
const WINDOW_BOX_INSTALL_MESSAGE =
  'Unfortunately we are unable to supply and install a Window Box unit – However if you were to purchase one brand new it is something we can arrange for a carpenter to assist with. '
const TRADE_ASSISTANT_MESSAGE = <Callout type="note" title="Book on a Trade Assistant" />

const answeredAllQuestions = (result: TopicHVACServiceActionResult | undefined, isSpecial: boolean) => {
  const { brand, brandOther, doesFurnitureNeedMoving, lastServiceDate, unitAge, unitCount, unitLocations } =
    result ?? {}
  const brandEntered = brand !== ACBrand.Other || (brand === ACBrand.Other && !!brandOther)
  const answer =
    !!brandEntered &&
    !!unitAge &&
    !!unitCount &&
    !!lastServiceDate &&
    (isSpecial ? true : !!doesFurnitureNeedMoving) &&
    !!unitLocations
  return answer
}

export const getRequirements =
  (isSpecial: boolean) =>
  (result?: TopicHVACServiceActionResult): TopicRequirementsResult => {
    const { hasIssue, hvacType, serviceLevel, considerReplacement, replacementType } = result ?? {}

    let attributes = [] as AttributeRequirement[]
    let category = 'General'

    if (hasIssue === YesNoValue.Yes) {
      return {}
    }
    if (serviceLevel === ServiceLevel.Clean && answeredAllQuestions(result, isSpecial)) {
      attributes = hvacType === ACType.DuctedOrCassette ? [HVAC_DUCTED] : [HVAC_SPLIT]
      category = 'Clean'
      return { requirements: { attributes }, category }
    }

    if (serviceLevel === ServiceLevel.Report || isSpecial) {
      switch (hvacType) {
        case ACType.Portable:
        case ACType.WindowBox:
          if (considerReplacement === YesNoValue.Yes) {
            if (replacementType) {
              attributes = replacementType === ACType.WindowBox ? [CARPENTRY_QUOTING] : []
            }
          }
          break
        case ACType.SplitSystemOrMultiHead:
          category = 'SplitSystem'
          if (answeredAllQuestions(result, isSpecial)) {
            attributes = [HVAC_SPLIT]
          }
          break
        case ACType.DuctedOrCassette:
          category = 'Ducted'
          if (answeredAllQuestions(result, isSpecial)) {
            attributes = [HVAC_DUCTED]
          }
          break
        default:
          return {}
      }
    }

    return attributes?.length > 0
      ? {
          requirements: { attributes },
          category
        }
      : {}
  }

const getQualification =
  (isSpecial: boolean) =>
  (result: TopicHVACServiceActionResult | undefined): TopicQualificationResult => {
    if (!result) return { qualification: TopicQualification.Unknown }
    const { hvacType, hasIssue, serviceLevel, considerReplacement, replacementType, customerType, roofAccess } =
      result ?? {}
    const CAR_REPAIR_MESSAGE =
      'Unfortunately we do not repair car ACs. I would recommend contacting a car mechanic to fix it.'

    if (
      isSpecial &&
      [ACType.Car, ACType.Portable, ACType.WindowBox].includes(hvacType ?? ACType.SplitSystemOrMultiHead)
    ) {
      return {
        qualification: TopicQualification.OutOfScope,
        qualificationMessage: 'We do not offer the special for these types of units'
      }
    }

    if (customerType === CustomerType.Commercial) {
      if (!roofAccess) {
        return { qualification: TopicQualification.Unknown }
      }
      if (roofAccess === YesNoValue.No) {
        return { qualification: TopicQualification.OutOfScope }
      }
    }

    if (hasIssue === YesNoValue.Yes) {
      return {
        qualification: TopicQualification.NewTopic,
        newTopic: { reference: JobTopicType.HVACRepair, buttonLabel: 'Switch to HVAC Repair' }
      }
    }

    if (serviceLevel === ServiceLevel.Clean && hvacType !== ACType.DuctedOrCassette) {
      return {
        qualification: TopicQualification.InScope,
        qualificationMessage: TRADE_ASSISTANT_MESSAGE
      }
    }

    switch (hvacType) {
      case ACType.Portable:
      case ACType.WindowBox:
        if (considerReplacement === YesNoValue.No) return { qualification: TopicQualification.OutOfScope }
        if (considerReplacement === YesNoValue.Yes) {
          if (!replacementType) return { qualification: TopicQualification.Unknown }
          if (replacementType === ACType.SplitSystemOrMultiHead) {
            return {
              qualification: TopicQualification.NewTopic,
              newTopic: { reference: JobTopicType.HVACQuoteInstall, buttonLabel: 'Switch to HVAC Quote Install' }
            }
          } else {
            return { qualification: TopicQualification.OutOfScope, qualificationMessage: WINDOW_BOX_INSTALL_MESSAGE }
          }
        }
        break
      case ACType.Car:
        return {
          qualification: TopicQualification.OutOfScope,
          qualificationMessage: CAR_REPAIR_MESSAGE
        }

      case ACType.SplitSystemOrMultiHead:
        return answeredAllQuestions(result, isSpecial)
          ? { qualification: TopicQualification.InScope, qualificationMessage: SUMMER_ONLY_MESSAGE }
          : { qualification: TopicQualification.Unknown }
      case ACType.DuctedOrCassette:
        return answeredAllQuestions(result, isSpecial)
          ? { qualification: TopicQualification.InScope, qualificationMessage: SUMMER_ONLY_MESSAGE }
          : { qualification: TopicQualification.Unknown }
      default:
        return { qualification: TopicQualification.Unknown }
    }

    return { qualification: TopicQualification.Unknown }
  }

export const outcomeGenerator = (isSpecial: boolean) =>
  createOutcomeGenerator<TopicHVACServiceActionResult>(
    {
      qualification: TopicQualification.Unknown,
      trade: TradeType.HVAC,
      type: JobType.Service,
      category: ''
    },
    getQualification(isSpecial),
    getRequirements(isSpecial)
  )
