import { makeHumanReadable } from '@falloncore/util'
import { AttributeRequirement, BookingWorkflowOutcomeFragment, JobType, TradeType, WorkflowFragment } from '@fallonsolutions/types'
import { JobClassificationActionResult } from '../actions/action-job-classification-types'
import { TopicActionResult } from '../topics/action-topic-model'

export const enquiryBridge = (workflow?: WorkflowFragment): JobClassificationActionResult | undefined => {
  if (!workflow) {
    console.log('no workflow')
    return undefined
  }
  console.log('workflow v2 bridge', workflow)
  const { state } = workflow
  const customerType = state.outcome.customerType
  const jobDescription = state.outcome.notes

  const {
    category,
    tradeType: trade,
    jobType
  } = workflow.legacyValues
      ? workflow.legacyValues
      : { category: 'General', tradeType: TradeType.None, jobType: JobType.Service }

  const actionCompleted = !workflow.currentStep //workflow.currentStep?.nextSteps.length === 0
  console.log('action completed', actionCompleted)
  const value =
    actionCompleted === true ? `${customerType} | ${trade} | ${makeHumanReadable(category)} | ${jobType}` : undefined
  const travelFee = state.outcome.travelFee
    ? {
      label: state.outcome.travelFee?.name,
      amount: state.outcome.travelFee.amount.amount
    }
    : undefined
  const tags = state.outcome.tags ?? []

  const { attributes } = fromWorkflowV2(trade, state.outcome)
  const topic: TopicActionResult = {
    trade,
    category,
    type: jobType,
    jobDescription,
    value: workflow.state.outcome.topic?.name,
    requirements: {
      attributes
    },
    actionCompleted
  }
  return { category, topic, customerType, trade, type: jobType, tags, travelFee, actionCompleted, value }
}


//initial skills mapping for solar
function fromWorkflowV2(tradeType: TradeType, { topic, serviceType, subtopic, tags, }: BookingWorkflowOutcomeFragment) {
  let attributes: AttributeRequirement[] = []

  if (tradeType !== TradeType.Solar) {
    return { attributes }
  }
  console.log('fromWorkflowV2', topic, serviceType, subtopic)
  if (topic?.reference === 'solar') {
    if (subtopic?.reference?.toLowerCase()?.includes('battery')) {
      attributes.push({ attributeId: 'SolarHybridOrBattery' })
    }
    if (serviceType?.reference?.toLowerCase() === 'repair') {
      attributes.push({ attributeId: 'SolarFaultOrRepair' })
    }
    if (serviceType?.reference?.toLowerCase() === 'inspection') {
      attributes.push({ attributeId: 'SolarInspection' })
    }
    if (serviceType?.reference === 'install') {
      attributes.push({ attributeId: 'SolarQuote' })
    }
  }
  return {
    attributes
  }
}

