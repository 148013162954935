import { JobType, TradeType } from '../../../api/generated-types'
import {
  TopicQualification,
  TopicQualificationResult,
  TopicRequirementsResult,
  createOutcomeGenerator,
  createRequirements
} from '../common/common-outcome'
import { DrainFaultEnum, TopicDishwasherActionResult } from './action-topic-dishwasher-model'
import {
  GeneralApplianceIssueType,
  InstallEnum,
  RepairOrReplaceEnum,
  canProceedWithRepair,
  getOutOfScopeMessage,
  isBrandServiceable
} from '../appliance-shared/appliance-model'
import { YesNoValue } from '../common/common-enums'

import {
  APPLIANCE_FAULT_OR_REPAIR_SKILL,
  CARPENTRY_QUOTING_SKILL,
  GENERAL_DRAINS_SKILL,
  GENERAL_ELECTRICAL_SKILL,
  GENERAL_PLUMBING_SKILL
} from '../common/skill-constants'

const getReplacementRequirements = (result: TopicDishwasherActionResult | undefined): TopicRequirementsResult => {
  const { replacementApplianceWillFit, installationNote } = result ?? {}

  if (!installationNote) {
    return {}
  }

  switch (replacementApplianceWillFit) {
    case YesNoValue.Yes:
      return {
        requirements: createRequirements([GENERAL_PLUMBING_SKILL])
      }
    case YesNoValue.No:
      return {
        requirements: createRequirements([CARPENTRY_QUOTING_SKILL]),
        trade: TradeType.Carpentry
      }
    default:
      return {}
  }
}

const getRepairRequirements = (result: TopicDishwasherActionResult | undefined): TopicRequirementsResult => {
  const { serviceType, fault, applianceAge, outletWorksWithOtherDevice, otherDrainsBlocked } = result ?? {}
  if (serviceType === RepairOrReplaceEnum.Repair && !!applianceAge) {
    if (canProceedWithRepair(result) === undefined || !canProceedWithRepair(result)) {
      return {}
    }
  }

  switch (fault) {
    case GeneralApplianceIssueType.PowerSupply:
      if (outletWorksWithOtherDevice) {
        return outletWorksWithOtherDevice === YesNoValue.Yes
          ? { requirements: createRequirements([APPLIANCE_FAULT_OR_REPAIR_SKILL]), trade: TradeType.Appliances }
          : { requirements: createRequirements([GENERAL_ELECTRICAL_SKILL]), trade: TradeType.Electrical }
      } else {
        return {}
      }
    case GeneralApplianceIssueType.OtherFault:
      if (isBrandServiceable(result)) {
        return { requirements: createRequirements([APPLIANCE_FAULT_OR_REPAIR_SKILL]), trade: TradeType.Appliances }
      } else {
        return {}
      }
    case DrainFaultEnum.Drain:
      if (otherDrainsBlocked) {
        if (otherDrainsBlocked === YesNoValue.Yes) {
          return { requirements: createRequirements([GENERAL_DRAINS_SKILL]), trade: TradeType.Plumbing }
        } else {
          return { requirements: createRequirements([APPLIANCE_FAULT_OR_REPAIR_SKILL]), trade: TradeType.Appliances }
        }
      } else {
        return {}
      }
    default:
      return {}
  }
}

const getRequirements = (result: TopicDishwasherActionResult | undefined): TopicRequirementsResult => {
  const { serviceType, spaceAvailableForAppliance, powerOutletNeeded, installationNote } = result ?? {}

  switch (serviceType) {
    case InstallEnum.NewInstall:
      if (!!spaceAvailableForAppliance && !!powerOutletNeeded && !!installationNote) {
        if (spaceAvailableForAppliance === YesNoValue.Yes) {
          return { requirements: createRequirements([GENERAL_PLUMBING_SKILL]), trade: TradeType.Plumbing }
        } else {
          return { requirements: createRequirements([CARPENTRY_QUOTING_SKILL]), trade: TradeType.Carpentry }
        }
      }
      return {}
    case RepairOrReplaceEnum.Repair:
      return getRepairRequirements(result)
    case RepairOrReplaceEnum.Replace:
      return getReplacementRequirements(result)
    default:
      return {}
  }
}

const getInstallOrReplacementQualification = (
  result: TopicDishwasherActionResult | undefined
): TopicQualificationResult => {
  const { serviceType, spaceAvailableForAppliance, powerOutletNeeded, replacementApplianceWillFit, installationNote } =
    result ?? {}

  switch (serviceType) {
    case InstallEnum.NewInstall:
      if (!!spaceAvailableForAppliance && !!powerOutletNeeded && !!installationNote) {
        return { qualification: TopicQualification.InScope }
      }
      return { qualification: TopicQualification.Unknown }
    case RepairOrReplaceEnum.Replace:
      switch (replacementApplianceWillFit) {
        case YesNoValue.Yes:
          return {
            qualification: TopicQualification.InScope
          }
        case YesNoValue.No:
          return {
            qualification: TopicQualification.InScope,
            qualificationMessage: 'Book a carpenter to make space, then book a plumber to connect new appliance'
          }
        default:
          return { qualification: TopicQualification.Unknown }
      }

    default:
      return { qualification: TopicQualification.Unknown }
  }
}

const getRepairQualification = (result: TopicDishwasherActionResult | undefined): TopicQualificationResult => {
  const { fault, outletWorksWithOtherDevice, applianceAge, selectedBrand, confirmedPartsAvailableWithDSR } =
    result ?? {}

  if (!applianceAge || canProceedWithRepair(result) === undefined) {
    return { qualification: TopicQualification.Unknown }
  }
  if (!canProceedWithRepair(result)) {
    return {
      qualification: TopicQualification.OutOfScope,
      qualificationMessage: getOutOfScopeMessage(' Dishwasher repair')
    }
  }

  if (fault) {
    switch (fault) {
      case GeneralApplianceIssueType.PowerSupply:
        return {
          qualification: outletWorksWithOtherDevice ? TopicQualification.InScope : TopicQualification.Unknown
        }
      case DrainFaultEnum.Drain:
      case GeneralApplianceIssueType.OtherFault:
        if (!!selectedBrand && !isBrandServiceable(result)) {
          if (confirmedPartsAvailableWithDSR === YesNoValue.No)
            return {
              qualification: TopicQualification.OutOfScope,
              qualificationMessage: getOutOfScopeMessage(' Dishwasher repair')
            }
        }
        return {
          qualification: isBrandServiceable(result) ? TopicQualification.InScope : TopicQualification.Unknown
        }
      default:
        return { qualification: TopicQualification.Unknown }
    }
  } else {
    return { qualification: TopicQualification.Unknown }
  }
}

const getQualification = (result: TopicDishwasherActionResult | undefined): TopicQualificationResult => {
  const { serviceType, spaceAvailableForAppliance, powerOutletNeeded, installationNote } = result ?? {}
  switch (serviceType) {
    case InstallEnum.NewInstall:
      if (spaceAvailableForAppliance && powerOutletNeeded && installationNote) {
        return { qualification: TopicQualification.InScope }
      }
      return { qualification: TopicQualification.Unknown }
    case RepairOrReplaceEnum.Replace:
      return getInstallOrReplacementQualification(result)
    case RepairOrReplaceEnum.Repair:
      return getRepairQualification(result)
    default:
      return { qualification: TopicQualification.Unknown }
  }
}

export const outcomeGenerator = createOutcomeGenerator<TopicDishwasherActionResult>(
  { qualification: TopicQualification.Unknown, trade: TradeType.Plumbing, type: JobType.Service, category: 'General' },
  getQualification,
  getRequirements
)
