import { EuiFieldText, EuiFlexGroup, EuiFlexItem, EuiFormRow } from '@elastic/eui'
import { UrlUtils } from '@fallonsolutions/domain'
import { compact } from 'lodash-es'
import { useEffect } from 'react'
import { CustomerCompanyFragment } from '../api/generated-types'

export interface CustomerCompanyDetailFormProps {
  company: CustomerCompanyFragment
  onChange: (company: CustomerCompanyFragment) => void
  showValidationErrors: boolean
  onValidationChange?: (valid: boolean) => void
}

export const CustomerCompanyDetailForm = ({
  company,
  onChange,
  showValidationErrors,
  onValidationChange
}: CustomerCompanyDetailFormProps) => {
  const setCompanyName = (name: string) => onChange({ ...company, name })
  const setCompanyAbn = (abn: string) => onChange({ ...company, abn })
  const setCompanyWeb = (web: string) => onChange({ ...company, web })

  const companyWebNormalised = UrlUtils.tryParseUrl(company.web)

  const companyNameValid = company.name && company.name.length > 0
  const companyWebValid = company.web && company.web.length > 0 ? !!companyWebNormalised : true

  const formErrors: boolean[] = compact([!companyNameValid, !companyWebValid])

  useEffect(() => {
    if (onValidationChange) {
      onValidationChange(formErrors.length === 0)
    }
  }, [formErrors, onValidationChange])

  return (
    <>
      <EuiFlexGroup>
        <EuiFlexItem grow={false}>
          <EuiFormRow label="Name" isInvalid={showValidationErrors && !companyNameValid}>
            <EuiFieldText
              value={company.name ?? ''}
              isInvalid={showValidationErrors && !companyNameValid}
              onChange={(e) => setCompanyName(e.target.value)}
            />
          </EuiFormRow>
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiFormRow label="ABN">
            <EuiFieldText value={company.abn ?? ''} onChange={(e) => setCompanyAbn(e.target.value)} />
          </EuiFormRow>
        </EuiFlexItem>
        <EuiFlexItem grow={true}>
          <EuiFormRow label="Website" isInvalid={showValidationErrors && !companyWebValid}>
            <EuiFieldText
              value={company.web ?? ''}
              onChange={(e) => setCompanyWeb(e.target.value)}
              isInvalid={showValidationErrors && !companyWebValid}
            />
          </EuiFormRow>
        </EuiFlexItem>
      </EuiFlexGroup>
    </>
  )
}
