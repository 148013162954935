import { useMutation } from '@apollo/client'
import {
  EuiButton,
  EuiButtonEmpty,
  EuiCallOut,
  EuiFormRow,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiOverlayMask,
  EuiTextArea
} from '@elastic/eui'
import { useEffect, useState } from 'react'
import { AddReviewReplyDocument } from '../api/generated-types'

export interface AddReviewReplyFormProps {
  reviewId: string
  onClose: () => void
}

export const AddReviewReplyForm = ({ reviewId, onClose }: AddReviewReplyFormProps) => {
  const [message, setMessage] = useState('')

  const [addReviewReply, { data, loading, error }] = useMutation(AddReviewReplyDocument, {
    refetchQueries: ['SearchReviews', 'GetJobReviews'],
    awaitRefetchQueries: true
  })
  const handleSubmit = () => {
    if (!message.length) {
      return
    }
    addReviewReply({
      variables: {
        input: {
          reviewId,
          message
        }
      }
    })
  }

  useEffect(() => {
    if (data?.addReviewReply?.review) {
      onClose()
    }
  }, [data, onClose])

  return (
    <EuiOverlayMask>
      <EuiModal onClose={onClose}>
        <EuiModalHeader>
          <EuiModalHeaderTitle>Add review reply</EuiModalHeaderTitle>
        </EuiModalHeader>
        <EuiModalBody>
          {error && <EuiCallOut color="danger">Error adding review reply: {error.message}</EuiCallOut>}

          <EuiFormRow label="Reply">
            <EuiTextArea value={message} onChange={(e) => setMessage(e.target.value)} />
          </EuiFormRow>
        </EuiModalBody>
        <EuiModalFooter>
          {!loading && (
            <EuiButtonEmpty onClick={onClose} disabled={loading}>
              Cancel
            </EuiButtonEmpty>
          )}
          <EuiButton fill onClick={() => handleSubmit()} isLoading={loading}>
            Add reply
          </EuiButton>
        </EuiModalFooter>
      </EuiModal>
    </EuiOverlayMask>
  )
}
