import { EuiButton, EuiSpacer, EuiText } from '@elastic/eui'
import { EnquiryAbortReason, JobType, TradeType } from '../../../api/generated-types'
import { Callout } from '../../../common/callout'
import { Task } from '../../../common/task'
import { makeHumanReadable } from '../../../common/utils'
import { CustomerSuppliedTopicActionResult } from '../../action-topic-customer-supplied'
import { ContactRole } from '../../actions/action-customer-qualification'
import { CustomerSuppliedCompliant, IsItemAustralianCompliant } from '../../helpers/item-installer-questions'
import { QuestionDefinitions, getNotesGenerator } from '../../helpers/notes-generator'
import { OutOfScope } from '../../helpers/out-of-topic'
import { getResultUpdater } from '../../helpers/results-updater'
import { useWorkflow } from '../../helpers/workflow-provider'
import { FreeTextQuestionField } from '../../question-fields/free-text-question-field'
import { MultiSelectField } from '../../question-fields/multi-select-question-field'
import { SingleSelectField } from '../../question-fields/single-select-question-field'
import { WorkflowActionProps } from '../../workflow-model'
import { ItemInstaller, ItemSupplier, ServiceType, TopicActionInput } from '../action-topic-model'
import { TopicWorkflowAction } from '../action-topic-view'
import { YesNoValue } from '../common/common-enums'
import { reset } from '../common/common-utils'

export enum IndoorOutdoorPlacement {
  Indoor = 'Indoor',
  Outdoor = 'Outdoor'
}

export enum DomesticLightType {
  DownLights = 'DownLights',
  Fluro = 'Fluro',
  HeatLamps = 'HeatLamps',
  SensorLights = 'SensorLights',
  PoolLights = 'PoolLights',
  LowVoltageGardenLights = 'LowVoltageGardenLights',
  StripLighting = 'StripLighting',
  Other = 'Other' // (Call DSR Q 99973)
}

export enum CommercialLightType {
  SportsLights = 'SportsLights', // (Slide 4 ASLS)
  LightingAudits = 'LightingAudits', // (Slide 4 ASLS)
  LightPoles = 'LightPoles', // (Slide 3 Commercial)
  CommercialFactoryLights = 'CommercialFactoryLights', // (Slide 3 Commercial)
  EmergencyExitLights = 'EmergencyExitLights', // (Slide 3 Commercial)
  SignLights = 'SignLights', // (Slide 3 Commercial)
  Other = 'Other' // (Call DSR Q 99973)
}

export enum InstallationNature {
  ReplacementOfExistingLights = 'ReplacementOfExistingLights',
  BrandNewLightFittings = 'BrandNewLightFittings'
}

export enum QuoteOrService {
  Quote = 'Quote',
  Service = 'Service'
}

export enum InstallDate {
  LessThanOrEqualTo3Years = 'Less than 3 years ago',
  Over3Years = 'Over 3 years ago'
}

export interface TopicLightingActionResult extends CustomerSuppliedTopicActionResult {
  // domestic
  serviceType?: ServiceType
  indoorOutdoorPlacement?: IndoorOutdoorPlacement
  domesticLightType?: DomesticLightType[]

  // install
  installationLightPlacement?: string
  installationNature?: InstallationNature
  itemSupplier?: ItemSupplier

  // repair
  itemInstaller?: ItemInstaller
  installDate?: InstallDate
  existingLightPlacement?: string
  faultDescription?: string

  // commercial
  companyName?: string
  isAccountCustomer?: YesNoValue
  commercialLightType?: CommercialLightType
  quoteOrService?: QuoteOrService
  workUrgency?: string

  // when DSR needs to be contacted
  customerPutOnHold?: boolean
  finishedTalkingToDsr?: boolean
  areDsrHappyToProceed?: YesNoValue
}

const questionDefinitions: QuestionDefinitions<TopicLightingActionResult> = {
  // domestic only
  serviceType: {
    question: 'Is that an installation or repair?',
    statement: 'Service type:',
    makeAnswerHumanReadable: makeHumanReadable
  },
  indoorOutdoorPlacement: {
    question: 'Is this for indoor or outdoor lighting?',
    makeAnswerHumanReadable: makeHumanReadable
  },
  domesticLightType: {
    question: 'What is the type of light?',
    makeAnswerHumanReadable: (types) => types?.map(makeHumanReadable).join(', ') ?? ''
  },

  // domestic install
  installationLightPlacement: {
    question: 'Whereabouts did you want this installed?'
  },
  installationNature: {
    question: 'Replacement an existing or a brand new light fittings?',
    statement: 'Installation nature:',
    makeAnswerHumanReadable: makeHumanReadable
  },
  itemSupplier: {
    question: 'Who is supplying the lights?',
    makeAnswerHumanReadable: makeHumanReadable
  },

  // domestic repair
  itemInstaller: {
    question: 'Who installed the system?',
    makeAnswerHumanReadable: makeHumanReadable
  },
  installDate: {
    question: 'When did Fallon install?',
    makeAnswerHumanReadable: makeHumanReadable
  },
  existingLightPlacement: {
    question: 'Whereabouts are the faulty lights?'
  },
  faultDescription: {
    question: 'What is the actual fault taking place?'
  },

  // commercial
  companyName: {
    question: 'What is the company name?'
  },
  isAccountCustomer: {
    question: 'Do you have an account with Fallon’s (or are you an account customer)?',
    makeAnswerHumanReadable: makeHumanReadable
  },
  commercialLightType: {
    question: 'What is the type of light?',
    makeAnswerHumanReadable: makeHumanReadable
  },
  quoteOrService: {
    question: 'Is this for a quote or a do-and-charge?',
    makeAnswerHumanReadable: makeHumanReadable
  },
  workUrgency: {
    question: 'How urgent it is (what date for work)?'
  }
}

const notesGenerator = getNotesGenerator(questionDefinitions)

export const TopicLightingAction = (props: WorkflowActionProps<TopicActionInput, TopicLightingActionResult>) => {
  const workflowContext = useWorkflow()
  const { result, input, onUpdate } = props
  const {
    // domestic path
    indoorOutdoorPlacement,
    domesticLightType,
    serviceType,
    // install
    installationLightPlacement,
    installationNature,
    itemSupplier,
    // repair
    itemInstaller,
    installDate,
    existingLightPlacement,
    faultDescription,
    // commercial
    commercialLightType,
    companyName,
    isAccountCustomer,
    quoteOrService,
    workUrgency,

    // commercial and domestic when light type === Other
    customerPutOnHold,
    finishedTalkingToDsr,
    areDsrHappyToProceed,
    australianCompliant,
    jobDescription,
    customerType
  } = result ?? {}
  const { jobIsCompliant, jobIsNotCompliant } = CustomerSuppliedCompliant({ australianCompliant })

  const getJobType = () => {
    if (serviceType === ServiceType.Repair) {
      return itemInstaller === ItemInstaller.Fallon && installDate === InstallDate.LessThanOrEqualTo3Years
        ? JobType.Callback
        : JobType.Service
    } else {
      return JobType.Service
    }
  }

  const onNext = () =>
    onUpdate({
      ...result,
      actionCompleted: true,
      trade: sportLightingSelected ? TradeType.SportsLighting : TradeType.Electrical,
      category: 'Lighting',
      type: getJobType(),
      workRequiredNotes: generatedNotes,
      requirements: {
        attributes: [{ attributeId: sportLightingSelected ? 'SportsLighting' : 'Lighting' }]
      }
    })

  const generatedNotes = notesGenerator.generateNotes(result ?? {})
  const updateResult = getResultUpdater(result ?? {}, onUpdate).updateResult

  const isItCommercialCustomer = input.customerContactRole === ContactRole.Commercial

  const canAssistWithDomesticJob =
    !isItCommercialCustomer &&
    !!domesticLightType &&
    !!domesticLightType.length &&
    ((domesticLightType.includes(DomesticLightType.Other) && areDsrHappyToProceed === YesNoValue.Yes) ||
      !domesticLightType.includes(DomesticLightType.Other))

  const sportLightingSelected =
    commercialLightType &&
    [CommercialLightType.SportsLights, CommercialLightType.LightingAudits].includes(commercialLightType)

  const canCompleteDomesticPath =
    !!serviceType &&
    !!indoorOutdoorPlacement &&
    !!domesticLightType &&
    !!domesticLightType.length &&
    canAssistWithDomesticJob &&
    jobIsCompliant &&
    ((!!installationLightPlacement && !!installationNature && !!itemSupplier) ||
      (!!itemInstaller && !!existingLightPlacement && !!faultDescription))

  const canCompleteCommercialPath =
    !!companyName &&
    !!isAccountCustomer &&
    !!commercialLightType &&
    !!quoteOrService &&
    !!workUrgency &&
    !!customerPutOnHold &&
    !!finishedTalkingToDsr &&
    areDsrHappyToProceed === YesNoValue.Yes

  const canComplete = canCompleteDomesticPath || canCompleteCommercialPath

  const consultTheCaseWithDsr = () => (
    <>
      <Callout type="script">
        <EuiText>
          <div>I will just put you on a brief hold whilst I check with our dispatch team.</div>
          <EuiSpacer size="s" />
          <div>Please bear with me for a moment.</div>
        </EuiText>
      </Callout>
      <Task
        label="I've put customer on hold"
        checked={customerPutOnHold === true}
        onChange={(customerPutOnHold) => updateResult({ customerPutOnHold })}
        indent={true}
      />
      {customerPutOnHold && (
        <>
          <Callout type="note" title="Provide DSR with all details and ask:">
            <ul>
              <li>If we will take the job (yes/no)?</li>
              <li>What technician to book on and when?</li>
              <li>What pricing we give?</li>
            </ul>
          </Callout>
          <Task
            label="I finished talking to DSR"
            checked={finishedTalkingToDsr === true}
            onChange={(finishedTalkingToDsr) => updateResult({ finishedTalkingToDsr })}
            indent={true}
          />
          {finishedTalkingToDsr && (
            <SingleSelectField
              question="Are DSR happy to proceed?"
              questionType="note"
              options={[
                {
                  id: YesNoValue.Yes,
                  label: 'Yes, DSR are happy to continue with booking',
                  icon: 'check'
                },
                {
                  id: YesNoValue.No,
                  label: 'No, the required work is out of scope',
                  icon: 'cross'
                }
              ]}
              answer={areDsrHappyToProceed}
              changeAnswer={(areDsrHappyToProceed) => updateResult({ areDsrHappyToProceed })}
            />
          )}
          {areDsrHappyToProceed === YesNoValue.Yes && (
            <>
              <Callout type="script" title="Great news! Our dispatch team is happy to proceed." />
              <EuiSpacer />
            </>
          )}
          {areDsrHappyToProceed === YesNoValue.No && (
            <>
              <Callout
                type="script"
                title="I do apologise but unfortunately this is currently out of scope and we wont be able to assist."
              />
              <EuiSpacer />
              <EuiButton
                color="warning"
                onClick={() =>
                  workflowContext.abort({
                    abortReason: EnquiryAbortReason.CustomerNeedsAreOutOfService,
                    notes: 'Customer wanted a type of lighting that we do not do.'
                  })
                }
              >
                End call
              </EuiButton>
            </>
          )}
        </>
      )}
    </>
  )

  return (
    <TopicWorkflowAction input={input} onUpdate={onUpdate} result={result}>
      {!isItCommercialCustomer && (
        <>
          <SingleSelectField
            question={notesGenerator.getQuestion('serviceType')}
            options={[
              {
                id: ServiceType.Installation,
                label: 'Installation',
                icon: 'check'
              },
              {
                id: ServiceType.Repair,
                label: 'Repair',
                icon: 'wrench'
              }
            ]}
            answer={serviceType}
            changeAnswer={(serviceType) =>
              updateResult({
                ...reset(result ?? {}),
                customerType,
                serviceType,
                jobDescription
              })
            }
          />
          {!!serviceType && (
            <SingleSelectField
              question={notesGenerator.getQuestion('indoorOutdoorPlacement')}
              options={[
                {
                  id: IndoorOutdoorPlacement.Indoor,
                  label: 'Indoor',
                  icon: 'home'
                },
                {
                  id: IndoorOutdoorPlacement.Outdoor,
                  label: 'Outdoor',
                  icon: 'cloudSunny'
                }
              ]}
              answer={indoorOutdoorPlacement}
              changeAnswer={(indoorOutdoorPlacement) => updateResult({ indoorOutdoorPlacement })}
            />
          )}
          {!!indoorOutdoorPlacement && (
            <MultiSelectField
              question={notesGenerator.getQuestion('domesticLightType')}
              options={[
                {
                  id: DomesticLightType.DownLights,
                  label: 'Down Lights'
                },
                {
                  id: DomesticLightType.Fluro,
                  label: 'Fluro'
                },
                {
                  id: DomesticLightType.HeatLamps,
                  label: 'Heat Lamps'
                },
                {
                  id: DomesticLightType.SensorLights,
                  label: 'Sensor Lights'
                },
                {
                  id: DomesticLightType.PoolLights,
                  label: 'Pool Lights'
                },
                {
                  id: DomesticLightType.LowVoltageGardenLights,
                  label: 'Low Voltage Garden Lights'
                },
                {
                  id: DomesticLightType.StripLighting,
                  label: 'Strip Lighting'
                },
                {
                  id: DomesticLightType.Other,
                  label: 'Other',
                  icon: 'questionInCircle'
                }
              ]}
              answer={domesticLightType}
              changeAnswer={(domesticLightType) => updateResult({ domesticLightType })}
            />
          )}

          {domesticLightType?.includes(DomesticLightType.Other) && consultTheCaseWithDsr()}

          {canAssistWithDomesticJob && (
            <>
              {serviceType === ServiceType.Installation && (
                <>
                  <FreeTextQuestionField
                    question={notesGenerator.getQuestion('installationLightPlacement')}
                    answer={installationLightPlacement}
                    rows={3}
                    changeAnswer={(installationLightPlacement) => updateResult({ installationLightPlacement })}
                  />
                  <SingleSelectField
                    question={notesGenerator.getQuestion('installationNature')}
                    options={[
                      {
                        id: InstallationNature.ReplacementOfExistingLights,
                        label: 'Replacement of existing lights',
                        icon: 'returnKey'
                      },
                      {
                        id: InstallationNature.BrandNewLightFittings,
                        label: 'Brand new light fittings',
                        icon: 'package'
                      }
                    ]}
                    answer={installationNature}
                    changeAnswer={(installationNature) => updateResult({ installationNature })}
                  />
                  <SingleSelectField
                    question={notesGenerator.getQuestion('itemSupplier')}
                    options={[
                      {
                        id: ItemSupplier.Fallon,
                        label: 'Fallon',
                        icon: 'check'
                      },
                      {
                        id: ItemSupplier.Customer,
                        label: 'Customer',
                        icon: 'user'
                      }
                    ]}
                    answer={itemSupplier}
                    changeAnswer={(itemSupplier) => updateResult({ itemSupplier })}
                  />
                  <IsItemAustralianCompliant
                    updateResult={updateResult}
                    itemSupplier={itemSupplier}
                    suppliedAnswer={australianCompliant}
                  />
                  <OutOfScope issue={'Lighting'} isOutOfScope={jobIsNotCompliant} />
                </>
              )}
              {serviceType === ServiceType.Repair && (
                <>
                  <SingleSelectField
                    question={notesGenerator.getQuestion('itemInstaller')}
                    options={[
                      {
                        id: ItemInstaller.Fallon,
                        label: 'Fallon installed',
                        icon: 'check'
                      },
                      {
                        id: ItemInstaller.SomeoneElse,
                        label: 'Someone else installed',
                        icon: 'user'
                      }
                    ]}
                    answer={itemInstaller}
                    changeAnswer={(itemInstaller) => updateResult({ itemInstaller })}
                  />
                  {itemInstaller === ItemInstaller.Fallon && (
                    <SingleSelectField
                      question={notesGenerator.getQuestion('installDate')}
                      options={[
                        {
                          id: InstallDate.LessThanOrEqualTo3Years,
                          label: 'Within last 3 years'
                        },
                        {
                          id: InstallDate.Over3Years,
                          label: 'Over 3 years ago'
                        }
                      ]}
                      answer={installDate}
                      changeAnswer={(installDate) => updateResult({ installDate })}
                    />
                  )}
                  {itemInstaller === ItemInstaller.Fallon && !!installDate && (
                    <>
                      <Callout
                        type="note"
                        title={
                          installDate === InstallDate.LessThanOrEqualTo3Years
                            ? 'Revisit with no travel fee'
                            : 'Normal service job with travel fee'
                        }
                      />
                      <EuiSpacer />
                    </>
                  )}

                  {!!itemInstaller && (
                    <>
                      <FreeTextQuestionField
                        question={notesGenerator.getQuestion('existingLightPlacement')}
                        answer={existingLightPlacement}
                        changeAnswer={(existingLightPlacement) => updateResult({ existingLightPlacement })}
                      />
                      <FreeTextQuestionField
                        question={notesGenerator.getQuestion('faultDescription')}
                        answer={faultDescription}
                        changeAnswer={(faultDescription) => updateResult({ faultDescription })}
                      />
                    </>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}

      {isItCommercialCustomer && (
        <>
          <FreeTextQuestionField
            question={notesGenerator.getQuestion('companyName')}
            answer={companyName}
            changeAnswer={(companyName) => updateResult({ companyName })}
          />
          <SingleSelectField
            question={notesGenerator.getQuestion('isAccountCustomer')}
            options={[
              {
                id: YesNoValue.Yes,
                label: 'Yes, account customer',
                icon: 'check'
              },
              {
                id: YesNoValue.No,
                label: 'No',
                icon: 'cross'
              }
            ]}
            answer={isAccountCustomer}
            changeAnswer={(isAccountCustomer) => updateResult({ isAccountCustomer })}
          />
          <SingleSelectField
            question={notesGenerator.getQuestion('commercialLightType')}
            options={[
              {
                id: CommercialLightType.SportsLights,
                label: 'Sports Lights',
                icon: 'starEmpty'
              },
              {
                id: CommercialLightType.LightingAudits,
                label: 'Lighting Audits',
                icon: 'starEmpty'
              },
              {
                id: CommercialLightType.LightPoles,
                label: 'Light Poles',
                icon: 'starEmpty'
              },
              {
                id: CommercialLightType.CommercialFactoryLights,
                label: 'Commercial Factory Lights',
                icon: 'starEmpty'
              },
              {
                id: CommercialLightType.EmergencyExitLights,
                label: 'Emergency Exit Lights',
                icon: 'starEmpty'
              },
              {
                id: CommercialLightType.SignLights,
                label: 'Sign Lights',
                icon: 'starEmpty'
              },
              {
                id: CommercialLightType.Other,
                label: 'Other',
                icon: 'questionInCircle'
              }
            ]}
            answer={commercialLightType}
            changeAnswer={(commercialLightType) => updateResult({ commercialLightType })}
          />
          {sportLightingSelected && (
            <Callout
              type="note"
              title="While it's sports lighting, please ask the following questions and put in a box below:"
              className="callout--flat-list"
            >
              <EuiText>
                <ul>
                  <li>Name of club or organisation?</li>
                  <li>What do you need done?</li>
                  <li>Is this part of a grant application?</li>
                  <li>Preferred contact number and email for contact to be made?</li>
                </ul>
              </EuiText>
            </Callout>
          )}
          <SingleSelectField
            question={notesGenerator.getQuestion('quoteOrService')}
            options={[
              {
                id: QuoteOrService.Quote,
                label: 'Quote',
                icon: 'check'
              },
              {
                id: QuoteOrService.Service,
                label: 'Service',
                icon: 'wrench'
              }
            ]}
            answer={quoteOrService}
            changeAnswer={(quoteOrService) => updateResult({ quoteOrService })}
          />
          <FreeTextQuestionField
            question={notesGenerator.getQuestion('workUrgency')}
            answer={workUrgency}
            changeAnswer={(workUrgency) => updateResult({ workUrgency })}
          />
          {consultTheCaseWithDsr()}
        </>
      )}

      <EuiSpacer />
      <EuiButton disabled={!canComplete} onClick={() => onNext()}>
        Next
      </EuiButton>
    </TopicWorkflowAction>
  )
}
