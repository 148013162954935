import { EuiButton, EuiSpacer } from '@elastic/eui'
import { JobType, TradeType } from '../../../api/generated-types'
import { makeHumanReadable } from '../../../common/utils'
import { QuestionDefinitions, getNotesGenerator } from '../../helpers/notes-generator'
import { getResultUpdater } from '../../helpers/results-updater'
import { FreeTextQuestionField } from '../../question-fields/free-text-question-field'
import { SingleSelectField } from '../../question-fields/single-select-question-field'
import { WorkflowActionProps } from '../../workflow-model'
import { TopicActionInput, TopicActionResult } from '../action-topic-model'
import { TopicWorkflowAction } from '../action-topic-view'

export enum PrePurchaseInspectionScope {
  General = 'General',
  GeneralAndDrains = 'GeneralAndDrains'
}

export interface TopicPlumbingPrePurchaseInspectionActionResult extends TopicActionResult {
  prePurchaseInspectionScope?: PrePurchaseInspectionScope
  anyParticularPointOfInspection?: string
  numberOfHouseLevels?: string
  ageOfTheHome?: string
  expectedCompletionDate?: string
}

const questionDefinitions: QuestionDefinitions<TopicPlumbingPrePurchaseInspectionActionResult> = {
  prePurchaseInspectionScope: {
    question: 'Is this for general or both general and drains?',
    makeAnswerHumanReadable: makeHumanReadable
  },
  anyParticularPointOfInspection: {
    question: 'Anything in particular that you need us to inspect?'
  },
  numberOfHouseLevels: {
    question: 'How many levels is the house?'
  },
  ageOfTheHome: {
    question: 'Idea on the age of the home?'
  },
  expectedCompletionDate: {
    question: 'When does this need to be done by?'
  }
}
const notesGenerator = getNotesGenerator(questionDefinitions)

export const TopicPlumbingPrePurchaseInspectionAction = (
  props: WorkflowActionProps<TopicActionInput, TopicPlumbingPrePurchaseInspectionActionResult>
) => {
  const { result, input, onUpdate } = props
  const {
    prePurchaseInspectionScope,
    anyParticularPointOfInspection,
    numberOfHouseLevels,
    ageOfTheHome,
    expectedCompletionDate
  } = result ?? {}

  const onNext = () =>
    onUpdate({
      ...result,
      actionCompleted: true,
      trade: prePurchaseInspectionScope === PrePurchaseInspectionScope.GeneralAndDrains ? TradeType.Drains : TradeType.Plumbing,
      category: 'PrePurchaseInspection',
      type: JobType.Service,

      workRequiredNotes: generatedNotes,
      requirements: {
        attributes: [
          {
            attributeId:
              prePurchaseInspectionScope === PrePurchaseInspectionScope.GeneralAndDrains
                ? 'GeneralDrains'
                : 'GeneralPlumbing'
          }
        ]
      }
    })

  const generatedNotes = notesGenerator.generateNotes(result ?? {})
  const updateResult = getResultUpdater(result ?? {}, onUpdate).updateResult

  const canComplete =
    !!prePurchaseInspectionScope &&
    !!anyParticularPointOfInspection &&
    !!numberOfHouseLevels &&
    !!ageOfTheHome &&
    !!expectedCompletionDate

  return (
    <TopicWorkflowAction input={input} onUpdate={onUpdate} result={result}>
      <SingleSelectField
        question={notesGenerator.getQuestion('prePurchaseInspectionScope')}
        options={[
          {
            id: PrePurchaseInspectionScope.General,
            label: 'General'
          },
          {
            id: PrePurchaseInspectionScope.GeneralAndDrains,
            label: 'General and drains'
          }
        ]}
        answer={prePurchaseInspectionScope}
        changeAnswer={(prePurchaseInspectionScope) => updateResult({ prePurchaseInspectionScope })}
      />
      <FreeTextQuestionField
        question={notesGenerator.getQuestion('anyParticularPointOfInspection')}
        answer={anyParticularPointOfInspection}
        rows={3}
        changeAnswer={(anyParticularPointOfInspection) => updateResult({ anyParticularPointOfInspection })}
      />
      <FreeTextQuestionField
        question={notesGenerator.getQuestion('numberOfHouseLevels')}
        answer={numberOfHouseLevels}
        changeAnswer={(numberOfHouseLevels) => updateResult({ numberOfHouseLevels })}
      />
      <FreeTextQuestionField
        question={notesGenerator.getQuestion('ageOfTheHome')}
        answer={ageOfTheHome}
        changeAnswer={(ageOfTheHome) => updateResult({ ageOfTheHome })}
      />
      <FreeTextQuestionField
        question={notesGenerator.getQuestion('expectedCompletionDate')}
        answer={expectedCompletionDate}
        changeAnswer={(expectedCompletionDate) => updateResult({ expectedCompletionDate })}
      />

      <EuiSpacer />
      <EuiButton disabled={!canComplete} onClick={() => onNext()}>
        Next
      </EuiButton>
    </TopicWorkflowAction>
  )
}
