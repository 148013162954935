import { EuiFlexGroup, EuiFlexItem, EuiHorizontalRule, EuiIcon, EuiSwitch, EuiText } from '@elastic/eui'
import { compact, first } from 'lodash-es'
import { useState } from 'react'
import { UserRole } from '../api/generated-types'
import ReferralIcon from '../static/images/icon-referral.svg'
import UserComboBox, { UserComboItem } from '../users/user-combo-box'

export interface CrossTradeReferrerProps {
  referrer?: UserComboItem
  onChange: (referrer?: UserComboItem) => void
}

export const CrossTradeReferrer = ({ referrer, onChange }: CrossTradeReferrerProps) => {
  const [isEnabled, setIsEnabled] = useState(!!referrer)

  const changeIsEnabled = (enabled: boolean) => {
    setIsEnabled(enabled)
    if (!enabled) {
      onChange(undefined)
    }
  }

  return (
    <EuiFlexGroup
      gutterSize="none"
      direction="column"
      style={{ background: '#F3F4F8', borderRadius: '6px', padding: '12px' }}
    >
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="none" alignItems="center" justifyContent="spaceBetween">
          <EuiFlexItem grow={false}>
            <EuiIcon type={ReferralIcon} size="l" style={{ marginRight: '9px' }} />
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <div style={{ fontSize: '16px', fontWeight: 600 }}>Cross trade referral</div>
          </EuiFlexItem>
          <EuiFlexItem grow={true} />
          <EuiFlexItem grow={false}>
            <EuiSwitch label="" checked={isEnabled} onChange={(e) => changeIsEnabled(e.target.checked)} />
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
      {isEnabled && (
        <EuiFlexItem>
          <EuiHorizontalRule margin="s" />
          <EuiFlexGroup alignItems="center">
            <EuiFlexItem grow={true}>
              <EuiText size="s">
                Cross trade referrals are used for technician commissions and business reporting
              </EuiText>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <UserComboBox
                users={compact([referrer])}
                onChangeUsers={(users) => onChange(first(users))}
                singleSelection={true}
                roles={[UserRole.Technician, UserRole.Apprentice, UserRole.Supervisor]}
                placeholder="Select referring technician"
                label="Select referring technician"
                initialEnabled={true}
              />
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
      )}
    </EuiFlexGroup>
  )
}
