import { TopicActionResult } from '../action-topic-model'
import { YesNoValue } from '../common/common-enums'
import { decamelise } from '../../../common/utils'
import { AppointmentRequirements } from '../../../api/generated-types'

export enum WaterLeakOutsideSymptom {
  Yard = 'Yard',
  WaterMeter = 'WaterMeter',
  Other = 'Other'
}

export enum InsideOrOutside {
  Inside = 'Inside',
  Outside = 'Outside'
}

export enum WaterLeakInsideSymptom {
  LeakingCeiling = 'Leaking ceiling',
  HearWaterRunning = 'Hear water running',
  WetPatchOnFloor = 'Wet patch on floor',
  WetPatchOnWall = 'Wet patch on wall',
  ShowerLeaking = 'Shower leaking / behind wall',
  LeakingBehindWall = 'Leaking behind wall (inside)'
}

export const outsideSymptomOptions = [
  {
    id: 'Yard',
    label: 'Yard'
  },
  { id: 'WaterMeter', label: 'Water Meter' },
  { id: 'Other', label: 'Other' }
]

export enum WaterLeakOutsideOtherSymptom {
  HearWaterRunningOutside = 'HearWaterRunningOutside',
  CouncilNoticeOfExcessWaterUsage = 'CouncilNoticeOfExcessWaterUsage',
  WaterMeterSpinningWithoutUsage = 'WaterMeterSpinningWithoutUsage'
}

export const outsideSymptomDetailOptions = [
  { id: WaterLeakOutsideOtherSymptom.HearWaterRunningOutside, label: decamelise('HearWaterRunningOutside') },
  {
    id: WaterLeakOutsideOtherSymptom.CouncilNoticeOfExcessWaterUsage,
    label: decamelise('CouncilNoticeOfExcessWaterUsage')
  },
  {
    id: WaterLeakOutsideOtherSymptom.WaterMeterSpinningWithoutUsage,
    label: decamelise('WaterMeterSpinningWithoutUsage  ')
  }
]

export interface TopicPlumbingWaterLeakDetectionActionResult extends TopicActionResult {
  symptom?: WaterLeakInsideSymptom | WaterLeakOutsideSymptom | undefined
  insideOrOutside?: InsideOrOutside
  ceilingLeaking?: YesNoValue
  canHearWaterRunningInside?: YesNoValue
  canSeeWetPatchOnFloor?: YesNoValue
  canSeeShowerLeakingBehindWall?: YesNoValue
  suspectWaterIsLeakingBehindWall?: YesNoValue
  canSmellDampOrWetCarpet?: YesNoValue
  otherWetPatchesOnWalls?: YesNoValue
  swollenFloorboards?: YesNoValue
  canIsolateWater?: YesNoValue
  waterMeterSpinning?: YesNoValue
  havePool?: YesNoValue
  poolWaterLevelDropping?: YesNoValue
  bathroomLaundryOrToiletAboveTheWetArea?: YesNoValue
  ductedACInstalled?: YesNoValue
  ductedACRunning?: YesNoValue
  ductedACCheckedForLeaks?: YesNoValue
  outsideSymptomDetail?: WaterLeakOutsideOtherSymptom
}

interface Question<T> {
  kind: 'Question'
  qa?: (Question<T> | Answer<any>)[]
}

export interface Answer<T> {
  kind: 'Answer'
  qa?: (Question<any> | Answer<any>)[]
  answer?: T | undefined
}
export interface CompletedResult {
  qa?: (Question<any> | Answer<any>)[]
  kind: 'Completed'
}

export interface InProgressResult {
  qa?: (Question<any> | Answer<any>)[]
  kind: 'InProgress'
}

export interface BookableResult {
  qa?: (Question<any> | Answer<any>)[]
  kind: 'Bookable'
  requirements: AppointmentRequirements
}

//experimental domain model to be more expressive and clearly show the result and maybe tree of questions
//BookableResult, which includes requirements with skills
export type ExperimentalResult = CompletedResult | CompletedResult | InProgressResult

export const toResultType = (result: TopicPlumbingWaterLeakDetectionActionResult) => {
  const { actionCompleted } = result ?? {}

  const qa: (Question<any> | Answer<any>)[] = []
  const xResult: ExperimentalResult = {
    qa,
    kind: actionCompleted ? 'Completed' : 'InProgress'
  }
  return xResult
}
