import React, { useContext } from 'react'
import { UserAuthFragment } from '../api/generated-types'
import { UserAttributeState } from '../user-attributes/user-attributes'
import { IIAMUtils } from './iam-utils'
import { LoaderContext, initialLoaderContext } from '../app/loader-context'

export interface IAuthorizationContext {
  userFragment?: UserAuthFragment
  userAttributes?: UserAttributeState
  iamUtils?: IIAMUtils
}

export type AuthorizationLoaderContext = LoaderContext<IAuthorizationContext>

export const AuthorizationContext = React.createContext<AuthorizationLoaderContext>(initialLoaderContext)

export const useAuthorization = () => {
  const context = useContext(AuthorizationContext)
  if (!context) {
    throw new Error('needs AuthorizationContext as provider')
  }
  return context
}
