import { EuiBasicTableColumn } from '@elastic/eui'
import { dateConfig } from '@fallonsolutions/date'
import { DateTime } from 'luxon'
import {
  CustomerLinkFragment,
  PropertyLinkFragment,
  ScheduledServiceFragment,
  ScheduledServicePaymentType,
  ScheduledServiceStatusType,
  ScheduledServiceType
} from '../api/generated-types'
import { decamelise } from '../common/utils'
import CustomerLink from '../customers/customer-link'
import { PropertyLink } from '../properties/property-link'
import { ScheduledServiceStatusBadge } from './scheduled-service-status-badge'

export interface ScheduledServiceListColumnsProps {
  onClickEdit: (scheduledService: ScheduledServiceFragment) => void
}

export const ScheduledServiceListColumns = ({
  onClickEdit
}: ScheduledServiceListColumnsProps): Array<EuiBasicTableColumn<ScheduledServiceFragment>> => {
  return [
    {
      field: 'status.status',
      name: 'Status',
      width: '100px',
      render: (status: ScheduledServiceStatusType) => <ScheduledServiceStatusBadge status={status} />
    },
    {
      field: 'serviceType',
      name: 'Type',
      width: '160px',
      render: (serviceType: ScheduledServiceType) => decamelise(serviceType)
    },
    {
      field: 'paymentType',
      name: 'Payment',
      width: '160px',
      render: (paymentType: ScheduledServicePaymentType) => decamelise(paymentType)
    },
    {
      field: 'name',
      name: 'Name'
    },
    {
      field: 'customer',
      name: 'Customer',
      render: (customer: CustomerLinkFragment) => <CustomerLink customer={customer} />
    },
    {
      field: 'property',
      name: 'Property',
      render: (property: PropertyLinkFragment) => <PropertyLink property={property} />
    },
    {
      field: 'description',
      name: 'Description'
    },
    {
      field: 'targetDate',
      name: 'Target date',
      width: '200px',
      render: (targetDate: string) => {
        const date = DateTime.fromISO(targetDate, { zone: dateConfig.defaultTimezone })
        return date.toFormat(dateConfig.luxonFormat.fullDate)
      }
    },
    {
      field: 'created',
      name: 'Date created',
      width: '160px',
      align: 'right',
      render: (created: string) => {
        const date = DateTime.fromISO(created)
        const now = DateTime.now().setZone(dateConfig.defaultTimezone)
        if (date.isValid) {
          return (
            <span title={date.toFormat(dateConfig.luxonFormat.shortDateTime)}>
              {now.diff(date, 'days').days <= 1 ? date.toRelative() : date.toFormat(dateConfig.luxonFormat.fullDate)}
            </span>
          )
        } else {
          return ''
        }
      }
    },
    {
      name: '',
      width: '60px',
      actions: [
        {
          name: 'Edit scheduled service',
          description: 'Edit this scheduled service',
          icon: 'pencil',
          type: 'icon',
          onClick: (scheduledService: ScheduledServiceFragment) => onClickEdit(scheduledService)
        }
      ]
    }
  ]
}
