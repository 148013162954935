import { useState } from 'react'
import { BaseActionInput, BaseActionResult, WorkflowActionProps } from '../workflow-model'
import { EuiButton, EuiSpacer, EuiText } from '@elastic/eui'
import { Callout } from '../../common/callout'
import { Task } from '../../common/task'
import { ContactActionResult } from './action-contact'
import { CustomerAction, CustomerActionResult } from './action-customer'
import { useAuthenticated } from '../../auth/authenticated-context'

export interface TransferCallActionInput extends BaseActionInput {
  contact?: ContactActionResult
}

export interface TransferCallActionResult extends BaseActionResult {
  customer?: CustomerActionResult
}

export const TransferCallAction = (props: WorkflowActionProps<TransferCallActionInput, TransferCallActionResult>) => {
  const { onUpdate, input, result } = props
  const { contact } = input
  const { customer } = result ?? {}

  const session = useAuthenticated().user
  const userFirstName = session.firstName

  const [customerOnHold, setCustomerOnHold] = useState(false)
  const [customerTransferred, setCustomerTransferred] = useState(false)

  const setCustomer = (customer: CustomerActionResult) => onUpdate({ ...result, customer })
  const customerId = customer?.actionCompleted ? customer?.customer?.id : undefined

  const onComplete = () => onUpdate({ ...result, actionCompleted: true })

  return (
    <>
      <CustomerAction input={{ contact }} result={customer} onUpdate={setCustomer} />

      {customerId && (
        <>
          <Callout type="script">
            <EuiText>
              <div>
                I'm just going to put you on a brief hold, bring them up to speed and then I'll transfer you across.
              </div>
              <EuiSpacer size="s" />
              <div>Won't be too long {customer?.customer?.firstName}.</div>
            </EuiText>
          </Callout>
          <Task
            label="Place customer on hold, select correct transfer destination/extension number"
            checked={customerOnHold}
            onChange={setCustomerOnHold}
            indent={true}
          />

          <EuiSpacer />
          <Callout type="script" title="Internal transfer">
            <EuiText>
              <div>Hi (Fallon employee name), it's {userFirstName ?? '(my name)'}.</div>
              <EuiSpacer size="s" />
              <div>
                I just have {customer?.customer?.fullName ?? 'someone'} here that (reason for transfer/explain issue).
              </div>
              <EuiSpacer size="s" />
              <div>Their details are (give customer details) - are you able to assist?</div>
            </EuiText>
          </Callout>
          <Task
            label="Bring customer through and join together with no handover"
            checked={customerTransferred}
            onChange={setCustomerTransferred}
            indent={true}
          />

          <EuiSpacer />
          <EuiButton fill color="primary" onClick={onComplete} disabled={!(customerOnHold && customerTransferred)}>
            I've completed these steps
          </EuiButton>
        </>
      )}
    </>
  )
}
