import { TradeType } from '../../../api/generated-types'
import { GeneralApplianceIssueType, RepairOrReplaceEnum } from '../appliance-shared/appliance-model'
import { TopicRequirementsResult, createRequirements } from '../common/common-outcome'
import { APPLIANCE_FAULT_OR_REPAIR_SKILL, GENERAL_ELECTRICAL_SKILL } from '../common/skill-constants'
import { TopicCooktopActionResult } from '../cooktop/action-topic-cooktop-model'
import { TopicDryerActionResult } from '../dryer/action-topic-dryer-model'
import { TopicOvenActionResult } from '../oven/action-topic-oven-model'
import { YesNoValue } from '../common/common-enums'
import { OVEN_COOKTOP_SKILL } from '../oven/action-topic-oven-outcome'

// Ovens / cooktops require an additional skill compared to dryers
export const getOvenOrCooktopRequirementsElectricRepair = (
  result: TopicCooktopActionResult | TopicDryerActionResult | TopicOvenActionResult
): TopicRequirementsResult => {
  const { serviceType, issue, outletWorksWithOtherDevice, happenedBefore } = result ?? {}

  const answeredAllElectricRepairQuestions =
    serviceType === RepairOrReplaceEnum.Repair &&
    ((issue === GeneralApplianceIssueType.PowerSupply && !!outletWorksWithOtherDevice) ||
      (issue === GeneralApplianceIssueType.OtherFault && !!happenedBefore))

  if (!answeredAllElectricRepairQuestions) {
    return {}
  }

  return issue === GeneralApplianceIssueType.OtherFault ||
    (issue === GeneralApplianceIssueType.PowerSupply && outletWorksWithOtherDevice === YesNoValue.Yes)
    ? {
        requirements: createRequirements([APPLIANCE_FAULT_OR_REPAIR_SKILL, OVEN_COOKTOP_SKILL]),
        trade: TradeType.Appliances
      }
    : { requirements: createRequirements([GENERAL_ELECTRICAL_SKILL]), trade: TradeType.Electrical }
}

export const getDryerRequirementsElectricRepair = (
  result: TopicCooktopActionResult | TopicDryerActionResult | TopicOvenActionResult
): TopicRequirementsResult => {
  const { serviceType, issue, outletWorksWithOtherDevice, happenedBefore } = result ?? {}

  const answeredAllElectricRepairQuestions =
    serviceType === RepairOrReplaceEnum.Repair &&
    ((issue === GeneralApplianceIssueType.PowerSupply && !!outletWorksWithOtherDevice) ||
      (issue === GeneralApplianceIssueType.OtherFault && !!happenedBefore))

  if (!answeredAllElectricRepairQuestions) {
    return {}
  }

  return issue === GeneralApplianceIssueType.OtherFault ||
    (issue === GeneralApplianceIssueType.PowerSupply && outletWorksWithOtherDevice === YesNoValue.Yes)
    ? {
        requirements: createRequirements([APPLIANCE_FAULT_OR_REPAIR_SKILL]),
        trade: TradeType.Appliances
      }
    : { requirements: createRequirements([GENERAL_ELECTRICAL_SKILL]), trade: TradeType.Electrical }
}
