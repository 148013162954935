import { CustomerFragment, InvoiceFragment, InvoiceType, PaymentFragment, PaymentType } from '../api/generated-types'

const myobCompany = 'FallonGroup'

const myobBaseUrl = `https://fallonsolutions.myobadvanced.com/Main?CompanyID=${myobCompany}`

export const myobInvoiceLink = (invoice: InvoiceFragment) => {
  const docType = getInvoiceDocType(invoice)
  return `${myobBaseUrl}&ScreenId=AR301000&DocType=${docType}&RefNbr=${invoice.number}`
}

export const myobPaymentLink = (payment: PaymentFragment) => {
  const docType = getPaymentDocType(payment)
  // Voided payments in MYOB use original payment reference - replace our V prefix with P
  const reference = docType === PaymentType.Void ? `P${payment.reference.substring(1)}` : payment.reference
  return `${myobBaseUrl}&ScreenId=AR302000&DocType=${docType}&RefNbr=${reference}`
}

export const myobCustomerLink = (customer: CustomerFragment) => {
  return `${myobBaseUrl}&ScreenId=AR303000&AcctCD=${customer.number}`
}

const getInvoiceDocType = (invoice: InvoiceFragment): string => {
  switch (invoice.type) {
    case InvoiceType.CreditNote:
      return 'CRM'
    case InvoiceType.Invoice:
      return 'INV'
    default:
      return 'INV'
  }
}

const getPaymentDocType = (payment: PaymentFragment): string => {
  switch (payment.type) {
    case PaymentType.CashRounding:
      return 'SMB'
    case PaymentType.CreditNote:
      return 'CRM'
    case PaymentType.Payment:
      return 'PMT'
    case PaymentType.Refund:
      return 'REF'
    case PaymentType.Void:
      return 'RPM'
    case PaymentType.WriteOff:
      return 'SMB'
    default:
      return 'PMT'
  }
}
