import { EuiButton, EuiSpacer } from '@elastic/eui'
import { JobType, TradeType } from '../../../api/generated-types'
import { Callout } from '../../../common/callout'
import { decameliseIfNotNil, makeHumanReadable } from '../../../common/utils'
import { CustomerSuppliedTopicActionResult } from '../../action-topic-customer-supplied'
import { SecurityResidencyCheck } from '../../helpers/gold-coast-security-questions'
import { CustomerSuppliedCompliant, IsItemAustralianCompliant } from '../../helpers/item-installer-questions'
import { QuestionDefinitions, getNotesGenerator } from '../../helpers/notes-generator'
import { OutOfScope } from '../../helpers/out-of-topic'
import { getResultUpdater } from '../../helpers/results-updater'
import { SecurityBrandQuestion } from '../../helpers/security-brand-question'
import { FreeTextQuestionField } from '../../question-fields/free-text-question-field'
import { SingleSelectField } from '../../question-fields/single-select-question-field'
import { WorkflowActionProps } from '../../workflow-model'
import { ItemInstaller, ItemSupplier, ServiceType, TopicActionInput } from '../action-topic-model'
import { TopicWorkflowAction } from '../action-topic-view'

export interface TopicCCTVActionResult extends CustomerSuppliedTopicActionResult {
  serviceType?: ServiceType
  itemSupplier?: ItemSupplier
  itemInstaller?: ItemInstaller
  installDate?: InstallDate
  quantityAndLocation?: string
  installReason?: string
  systemAge?: string
  fault?: string
  brandType?: string
}

export enum InstallDate {
  LessThanOrEqualTo3Years = 'Less than 3 years ago',
  Over3Years = 'Over 3 years ago'
}

const questionDefinitions: QuestionDefinitions<TopicCCTVActionResult> = {
  serviceType: {
    question: 'Is that an installation or repair?',
    statement: 'Service type:',
    makeAnswerHumanReadable: decameliseIfNotNil
  },
  itemSupplier: {
    question: 'Who is supplying the CCTV system?',
    statement: 'Hardware supplied by:',
    makeAnswerHumanReadable: decameliseIfNotNil
  },
  itemInstaller: {
    question: 'Who installed the system?',
    statement: 'System installed by:',
    makeAnswerHumanReadable: decameliseIfNotNil
  },
  installDate: {
    question: 'When did Fallon install?',
    makeAnswerHumanReadable: makeHumanReadable
  },
  quantityAndLocation: {
    question: 'How many cameras and which internal or external locations?',
    statement: 'Quantity and location(s):'
  },
  installReason: {
    question: 'Reason for CCTV?',
    statement: 'Reason:'
  },
  systemAge: {
    question: 'How old is the system?',
    statement: 'System age:'
  },
  fault: {
    question: 'What is the issue or fault?',
    statement: 'Issue or fault:'
  },
  brandType: {
    question: 'What brand/manufacture is the system?',
    statement: 'Brand/Manufacture:'
  }
}
const notesGenerator = getNotesGenerator(questionDefinitions)

export const TopicCCTVAction = (props: WorkflowActionProps<TopicActionInput, TopicCCTVActionResult>) => {
  const { result, input, onUpdate } = props
  const {
    serviceType,
    itemSupplier,
    itemInstaller,
    installDate,
    quantityAndLocation,
    installReason,
    systemAge,
    fault,
    australianCompliant,
    brandType
  } = result ?? {}
  const { serviceArea } = input
  const getJobType = () => {
    if (serviceType === ServiceType.Repair) {
      return itemInstaller === ItemInstaller.Fallon && installDate === InstallDate.LessThanOrEqualTo3Years
        ? JobType.Callback
        : JobType.Service
    } else if (serviceType === ServiceType.Installation) {
      return JobType.Quote
    } else {
      return JobType.Service
    }
  }

  const onNext = () =>
    onUpdate({
      ...result,
      actionCompleted: true,
      trade: TradeType.Security,
      category: 'CCTV',
      type: getJobType(),

      workRequiredNotes: generatedNotes,
      requirements: {
        travelFeeWaived: serviceType === ServiceType.Installation && itemSupplier === ItemSupplier.Fallon,
        attributes: [{ attributeId: 'CCTV' }]
      }
    })
  const generatedNotes = notesGenerator.generateNotes(result ?? {})
  const updateResult = getResultUpdater(result ?? {}, onUpdate).updateResult
  const updateBrandTypeQuestion = (brandType?: string) => updateResult({ brandType })
  const { jobIsCompliant, jobIsNotCompliant } = CustomerSuppliedCompliant({ australianCompliant })
  const installComplete =
    serviceType === ServiceType.Installation && itemSupplier && quantityAndLocation && installReason && jobIsCompliant
  const revisitComplete = serviceType === ServiceType.Repair && itemInstaller === ItemInstaller.Fallon && !!brandType
  const repairComplete =
    serviceType === ServiceType.Repair &&
    itemInstaller === ItemInstaller.SomeoneElse &&
    systemAge &&
    quantityAndLocation &&
    fault &&
    !!brandType
  const canComplete = installComplete || revisitComplete || repairComplete
  // need to do a check for service area branch and display a message if the residency is in the gold coast
  return (
    <TopicWorkflowAction input={input} onUpdate={onUpdate} result={result}>
      <SingleSelectField
        question={notesGenerator.getQuestion('serviceType')}
        options={[
          {
            id: ServiceType.Installation,
            label: 'Installation',
            icon: 'plus'
          },
          {
            id: ServiceType.Repair,
            label: 'Repair',
            icon: 'wrench'
          }
        ]}
        answer={serviceType}
        changeAnswer={(serviceType) =>
          updateResult({
            serviceType,
            itemInstaller: undefined,
            itemSupplier: undefined
          })
        }
      />

      {serviceType === ServiceType.Installation && (
        <>
          <SingleSelectField
            question={notesGenerator.getQuestion('itemSupplier')}
            options={[
              {
                id: ItemSupplier.Fallon,
                label: 'Fallon',
                icon: 'check'
              },
              {
                id: ItemSupplier.Customer,
                label: 'Customer',
                icon: 'user'
              }
            ]}
            answer={itemSupplier}
            changeAnswer={(itemSupplier) => updateResult({ itemSupplier })}
          />
          <IsItemAustralianCompliant
            updateResult={updateResult}
            itemSupplier={itemSupplier}
            suppliedAnswer={australianCompliant}
          />
          {jobIsNotCompliant ? (
            <OutOfScope issue={'CCTV'} isOutOfScope={true} />
          ) : (
            <>
              <FreeTextQuestionField
                question={notesGenerator.getQuestion('quantityAndLocation')}
                answer={quantityAndLocation}
                changeAnswer={(quantityAndLocation) => updateResult({ quantityAndLocation })}
                rows={3}
              />
              <FreeTextQuestionField
                question={notesGenerator.getQuestion('installReason')}
                answer={installReason}
                changeAnswer={(installReason) => updateResult({ installReason })}
                rows={3}
              />
            </>
          )}
        </>
      )}

      {serviceType === ServiceType.Repair && (
        <>
          <SingleSelectField
            question={notesGenerator.getQuestion('itemInstaller')}
            options={[
              {
                id: ItemInstaller.Fallon,
                label: 'Fallon installed',
                icon: 'check'
              },
              {
                id: ItemInstaller.SomeoneElse,
                label: 'Someone else installed',
                icon: 'user'
              }
            ]}
            answer={itemInstaller}
            changeAnswer={(itemInstaller) => updateResult({ itemInstaller })}
          />
          <SecurityBrandQuestion
            question={notesGenerator.getQuestion('brandType')}
            questionAnswer={brandType}
            updateQuestion={updateBrandTypeQuestion}
          />
        </>
      )}

      {itemInstaller === ItemInstaller.Fallon && (
        <SingleSelectField
          question={notesGenerator.getQuestion('installDate')}
          options={[
            {
              id: InstallDate.LessThanOrEqualTo3Years,
              label: 'Within last 3 years'
            },
            {
              id: InstallDate.Over3Years,
              label: 'Over 3 years ago'
            }
          ]}
          answer={installDate}
          changeAnswer={(installDate) => updateResult({ installDate })}
        />
      )}
      {itemInstaller === ItemInstaller.Fallon && !!installDate && (
        <>
          <Callout
            type="note"
            title={
              installDate === InstallDate.LessThanOrEqualTo3Years
                ? 'Revisit with no travel fee'
                : 'Normal service job with travel fee'
            }
          />
          <EuiSpacer />
        </>
      )}

      {serviceType === ServiceType.Repair && itemInstaller === ItemInstaller.SomeoneElse && (
        <>
          <FreeTextQuestionField
            question={notesGenerator.getQuestion('systemAge')}
            answer={systemAge}
            changeAnswer={(systemAge) => updateResult({ systemAge })}
          />
          <FreeTextQuestionField
            question={notesGenerator.getQuestion('quantityAndLocation')}
            answer={quantityAndLocation}
            changeAnswer={(quantityAndLocation) => updateResult({ quantityAndLocation })}
            rows={3}
          />
          <FreeTextQuestionField
            question={notesGenerator.getQuestion('fault')}
            answer={fault}
            changeAnswer={(fault) => updateResult({ fault })}
            rows={3}
          />
        </>
      )}

      <EuiSpacer />
      <SecurityResidencyCheck serviceArea={serviceArea} />
      <EuiButton disabled={!canComplete} onClick={() => onNext()}>
        Next
      </EuiButton>
    </TopicWorkflowAction>
  )
}
