import { EuiContextMenuPanelItemDescriptor, EuiFlexGroup, EuiFlexItem, EuiLoadingSpinner } from '@elastic/eui'
import { ScheduleEventDetailFragment, ScheduleEventFragment, ScheduleEventType } from '../../api/generated-types'

export interface ScheduleEventContextMenuCopyEventProps {
  event: ScheduleEventFragment
  eventDetail?: ScheduleEventDetailFragment
  loading: boolean
  onCopyEvent?: (event: ScheduleEventDetailFragment) => void
  onActionClick?: () => void
  cutEvent?: ScheduleEventFragment
  copyEvent?: ScheduleEventDetailFragment
}

export const ScheduleEventContextMenuCopyEvent = ({
  event,
  eventDetail,
  cutEvent,
  copyEvent,
  onCopyEvent,
  onActionClick,
  loading
}: ScheduleEventContextMenuCopyEventProps): EuiContextMenuPanelItemDescriptor => {
  const disabled = loading || !!copyEvent || !!cutEvent || event.type === ScheduleEventType.ToBeAdvised

  return {
    name: (
      <EuiFlexGroup gutterSize="none" alignItems="center" justifyContent="spaceBetween">
        <EuiFlexItem grow={true}>Copy</EuiFlexItem>
        <EuiFlexItem grow={false}>{loading && <EuiLoadingSpinner />}</EuiFlexItem>
      </EuiFlexGroup>
    ),
    icon: 'copy',
    onClick: () => {
      onCopyEvent && eventDetail && onCopyEvent(eventDetail)
      onActionClick && onActionClick()
    },
    disabled
  }
}
