import { EuiButton, EuiSpacer } from '@elastic/eui'
import { JobType, TradeType } from '../../../api/generated-types'
import { decameliseIfNotNil } from '../../../common/utils'
import { ContactRole } from '../../actions/action-customer-qualification'
import { QuestionDefinitions, getNotesGenerator } from '../../helpers/notes-generator'
import { OutOfScope } from '../../helpers/out-of-topic'
import { getResultUpdater } from '../../helpers/results-updater'
import { FreeTextQuestionField } from '../../question-fields/free-text-question-field'
import { SingleSelectField } from '../../question-fields/single-select-question-field'
import { WorkflowActionProps } from '../../workflow-model'
import { TopicActionInput, TopicActionResult } from '../action-topic-model'
import { TopicWorkflowAction } from '../action-topic-view'
import { YesNoValue } from '../common/common-enums'

export interface TopicHighPowerBillActionResult extends TopicActionResult {
  solarSystemInstalled?: YesNoValue
  solarKw?: string
  solarInverterFaults?: string
  newAppliances?: string
  firstIncrease?: string
}

const questionDefinitions: QuestionDefinitions<TopicHighPowerBillActionResult> = {
  solarSystemInstalled: {
    question: 'Do you have a Solar System installed?',
    statement: 'Solar System installed:',
    makeAnswerHumanReadable: decameliseIfNotNil
  },
  solarKw: {
    question: 'How many kilowatts does it produce',
    statement: 'Solar kw:'
  },
  solarInverterFaults: {
    question: 'Have you noticed any faults with your inverter?',
    statement: 'Inverter faults:'
  },
  newAppliances: {
    question: 'Have you had any new appliances installed like an air conditioner or fridge?',
    statement: 'Recent appliances installed:'
  },
  firstIncrease: {
    question: 'Is this the first increase of your power bill?',
    statement: 'First increase:'
  }
}
const notesGenerator = getNotesGenerator(questionDefinitions)

export const TopicHighPowerBillAction = (
  props: WorkflowActionProps<TopicActionInput, TopicHighPowerBillActionResult>
) => {
  const { result, input, onUpdate } = props
  const { solarSystemInstalled, solarKw, solarInverterFaults, newAppliances, firstIncrease } = result ?? {}

  const onNext = () => {
    const isSolarInspection = solarSystemInstalled === YesNoValue.Yes
    onUpdate({
      ...result,
      actionCompleted: true,
      trade: isSolarInspection ? TradeType.Solar : TradeType.Electrical,
      category: isSolarInspection ? 'General' : 'EnergyAudit',
      type: isSolarInspection ? JobType.Inspection : JobType.Service,

      workRequiredNotes: generatedNotes,
      requirements: {
        attributes: [{ attributeId: isSolarInspection ? 'General' : 'EnergyAudit' }]
      }
    })
  }

  const generatedNotes = notesGenerator.generateNotes(result ?? {})
  const updateResult = getResultUpdater(result ?? {}, onUpdate).updateResult

  const validEnergyAudit = solarSystemInstalled === YesNoValue.No && !!newAppliances && !!firstIncrease
  const validSolarInspection = solarSystemInstalled === YesNoValue.Yes && !!solarKw && !!solarInverterFaults

  const canComplete = validEnergyAudit || validSolarInspection
  const isTenant = input?.customerContactRole === ContactRole.Tenant
  return (
    <>
      {isTenant ? (
        <>
          <OutOfScope
            isOutOfScope={true}
            reason={'we do not offer high power bill services to tenants'}
            issue={'High power bill'}
          />
        </>
      ) : (
        <>
          <TopicWorkflowAction input={input} onUpdate={onUpdate} result={result}>
            <SingleSelectField
              question={notesGenerator.getQuestion('solarSystemInstalled')}
              options={[
                {
                  id: YesNoValue.Yes,
                  label: 'Yes, solar system installed',
                  icon: 'check'
                },
                {
                  id: YesNoValue.No,
                  label: 'No',
                  icon: 'cross'
                }
              ]}
              answer={solarSystemInstalled}
              changeAnswer={(solarSystemInstalled) =>
                updateResult({
                  solarSystemInstalled,
                  solarKw: undefined,
                  solarInverterFaults: undefined,
                  newAppliances: undefined,
                  firstIncrease: undefined
                })
              }
            />

            {solarSystemInstalled === YesNoValue.Yes && (
              <>
                <FreeTextQuestionField
                  question={notesGenerator.getQuestion('solarKw')}
                  answer={solarKw}
                  changeAnswer={(solarKw) => updateResult({ solarKw })}
                />
                <FreeTextQuestionField
                  question={notesGenerator.getQuestion('solarInverterFaults')}
                  answer={solarInverterFaults}
                  changeAnswer={(solarInverterFaults) => updateResult({ solarInverterFaults })}
                />
              </>
            )}

            {solarSystemInstalled === YesNoValue.No && (
              <>
                <FreeTextQuestionField
                  question={notesGenerator.getQuestion('newAppliances')}
                  answer={newAppliances}
                  changeAnswer={(newAppliances) => updateResult({ newAppliances })}
                />
                <FreeTextQuestionField
                  question={notesGenerator.getQuestion('firstIncrease')}
                  answer={firstIncrease}
                  changeAnswer={(firstIncrease) => updateResult({ firstIncrease })}
                />
              </>
            )}

            {canComplete && (
              <>
                <EuiSpacer />
                <EuiButton onClick={() => onNext()}>Next</EuiButton>
              </>
            )}
          </TopicWorkflowAction>
        </>
      )}
    </>
  )
}
