import { useQuery } from '@apollo/client'
import { EuiLoadingSpinner } from '@elastic/eui'
import { compact, take } from 'lodash-es'
import { GetJobActivityDocument } from '../api/generated-types'
import CommentList from '../comments/comment-list'

export interface JobActivityListProps {
  jobId: string
  maxComments?: number
  includeActivities?: boolean
}

export const JobActivityList = ({ jobId, maxComments, ...props }: JobActivityListProps) => {
  const includeActivities = props.includeActivities ?? true

  const { data, loading } = useQuery(GetJobActivityDocument, { variables: { id: jobId } })

  const comments = maxComments
    ? take(compact(data?.getJob?.comments ?? []), maxComments)
    : compact(data?.getJob?.comments ?? [])
  const activities = includeActivities ? compact(data?.getJob?.activities ?? []) : []

  return loading ? <EuiLoadingSpinner /> : <CommentList comments={comments} activities={activities} />
}
