import { EuiFlexGroup, EuiFlexItem, EuiSpacer } from '@elastic/eui'
import ReactMarkdown from 'react-markdown'
import { GetJobPreviewDocument, JobPreviewFragment } from '../api/generated-types'
import { formatCurrency } from '../common/metrics'
import { PreviewCard } from '../common/preview/preview-card'
import TradeTag from '../common/trade-tag'

export interface JobPreviewProps {
  jobId: string
  jobNumber?: string
}

export const JobPreview = ({ jobId, jobNumber }: JobPreviewProps) => {
  const renderJob = (job: JobPreviewFragment | undefined) => {
    if (!job) {
      return <></>
    }
    return (
      <div style={{ padding: '6px' }}>
        <EuiFlexGroup gutterSize="m">
          <EuiFlexItem grow={false}>
            <div className="small-label">Trade</div>
            <span className="truncate">
              <TradeTag trade={job.tradeType ?? 'none'} />
              <span style={{ marginLeft: '3px' }}>{job.tradeType}</span>
            </span>
          </EuiFlexItem>
          <EuiFlexItem grow={true} />
          <EuiFlexItem grow={false} style={{ textAlign: 'right' }}>
            <div className="small-label truncate">Sold</div>
            <span>{formatCurrency(parseFloat(job.sold?.total.amount ?? '0.00'))}</span>
          </EuiFlexItem>
          <EuiFlexItem grow={false} style={{ textAlign: 'right' }}>
            <div className="small-label truncate">Invoiced</div>
            <span>{formatCurrency(parseFloat(job.invoiced?.total.amount ?? '0.00'))}</span>
          </EuiFlexItem>
        </EuiFlexGroup>
        <EuiSpacer size="m" />
        <div style={{ maxWidth: '420px', fontSize: '12px' }}>
          <ReactMarkdown children={job.workRequired ?? ''} className="markdown-body" />
        </div>
      </div>
    )
  }

  return (
    <PreviewCard
      query={GetJobPreviewDocument}
      variables={{ id: jobId }}
      modelName="job"
      title={`Job ${jobNumber ?? ''}`}
      link={{ label: 'Open job', url: `/jobs/${jobId}` }}
    >
      {({ data }) => renderJob(data.getJob ?? undefined)}
    </PreviewCard>
  )
}
