import { useApolloClient, useMutation } from '@apollo/client'
import {
  EuiContextMenu,
  EuiContextMenuPanelDescriptor,
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
  EuiLink,
  EuiText
} from '@elastic/eui'
import { dateConfig, dateUtils } from '@fallonsolutions/date'
import {
  NotificationFragment,
  NotificationFragmentDoc,
  NotificationMutationType,
  NotificationStatusType
} from '@fallonsolutions/types'
import { useState } from 'react'
import ReactMarkdown from 'react-markdown'
import { UpdateNotificationStatusDocument } from '../api/generated-types'
import '../static/css/notification-context-menu.css'
import '../static/css/notification-row.css'

export interface NotificationRowProps {
  notification: NotificationFragment
  onClose?: () => void
}

export const NotificationRow = ({ notification, onClose }: NotificationRowProps) => {
  const client = useApolloClient()

  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const dateStr = dateUtils.fromISO(notification.created).toRelative({ style: 'narrow' })
  const fullDateStr = dateUtils.fromISO(notification.created).toFormat(dateConfig.luxonFormat.fullDateAndTime)
  const subjectColor = notification.status.status === NotificationStatusType.Unread ? 'default' : 'subdued'
  const subjectWeight = notification.status.status === NotificationStatusType.Unread ? 600 : 400
  const linkColor = notification.status.status === NotificationStatusType.Unread ? 'primary' : 'subdued'

  const [updateNotificationStatus] = useMutation(UpdateNotificationStatusDocument, {
    refetchQueries: ['SearchNotifications']
  })

  const changeStatus = (status: NotificationStatusType) => {
    updateNotificationStatus({
      variables: {
        input: {
          notificationId: notification.id,
          status
        }
      },
      optimisticResponse: (vars, { IGNORE }) => {
        const notificationFragment = client.readFragment({
          id: `Notification:${vars.input.notificationId}`,
          fragment: NotificationFragmentDoc,
          fragmentName: 'Notification'
        })
        if (!notificationFragment) {
          console.log('optimisticResponse: no notificationFragment')
          return IGNORE as any
        }
        return {
          updateNotificationStatus: {
            __typename: 'AnyNotificationPayload',
            tenantId: notificationFragment.tenantId,
            kind: NotificationMutationType.UpdateNotificationStatus,
            userId: notificationFragment.userId,
            notification: {
              ...notificationFragment,
              status: {
                ...notificationFragment.status,
                status
              }
            }
          }
        }
      }
    })
  }

  const menu: EuiContextMenuPanelDescriptor[] = [
    {
      id: 0,
      items: [
        {
          name: notification.status.status === NotificationStatusType.Read ? 'Mark as unread' : 'Mark as read',
          // icon: <EuiIcon type={notification.status.status === NotificationStatusType.Read ? 'eyeClosed' : 'eye'} />,
          onClick: () => {
            changeStatus(
              notification.status.status === NotificationStatusType.Read
                ? NotificationStatusType.Unread
                : NotificationStatusType.Read
            )
            setIsMenuOpen(false)
          }
        }
      ]
    }
  ]

  const gotoLink = () => {
    changeStatus(NotificationStatusType.Read)
    setIsMenuOpen(false)
    if (onClose) {
      onClose()
    }
    if (notification.link) {
      window.open(notification.link.url, '_blank')
    }
  }

  const onRowClick = () => {
    if (isMenuOpen) {
      setIsMenuOpen(false)
    } else if (notification.status.status === NotificationStatusType.Unread) {
      changeStatus(NotificationStatusType.Read)
    }
  }

  const onContextMenu = (e: React.MouseEvent) => {
    e.preventDefault()
    setIsMenuOpen(!isMenuOpen)
  }

  return (
    <>
      <EuiFlexGroup
        gutterSize="s"
        alignItems="flexStart"
        onClick={onRowClick}
        onContextMenu={onContextMenu}
        className={`notification-row notification-row--${notification.status.status.toLowerCase()}`}
      >
        <EuiFlexItem grow={false} style={{ paddingTop: '3px' }}>
          <EuiIcon type="bell" size="m" />
        </EuiFlexItem>
        <EuiFlexItem grow={true}>
          <EuiFlexGroup gutterSize="none" direction="column">
            <EuiFlexItem grow={false}>
              <EuiFlexGroup gutterSize="s">
                <EuiFlexItem grow={true}>
                  <EuiText size="s" color={subjectColor} style={{ fontWeight: subjectWeight }}>
                    {notification.subject}
                  </EuiText>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <EuiText size="s" color="subdued" title={fullDateStr}>
                    {dateStr}
                  </EuiText>
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlexItem>

            {notification.body !== notification.subject && (
              <EuiFlexItem grow={false}>
                <EuiText size="s" color="subdued" className="notification-body">
                  <ReactMarkdown>{notification.body}</ReactMarkdown>
                </EuiText>
              </EuiFlexItem>
            )}
            {notification.link && (
              <EuiFlexItem grow={false}>
                <EuiFlexGroup>
                  <EuiFlexItem grow={false}>
                    <EuiLink
                      onClick={gotoLink}
                      target="_blank"
                      color={linkColor}
                      style={{ fontWeight: 400, marginTop: '3px' }}
                    >
                      {notification.link.label}
                    </EuiLink>
                  </EuiFlexItem>
                  <EuiFlexItem grow={true} />
                </EuiFlexGroup>
              </EuiFlexItem>
            )}
          </EuiFlexGroup>
        </EuiFlexItem>
      </EuiFlexGroup>
      {isMenuOpen && (
        <div style={{ position: 'absolute' }} className="notification-context-menu">
          <EuiContextMenu initialPanelId={0} panels={menu} />
        </div>
      )}
    </>
  )
}
