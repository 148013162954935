import { EuiFlexGroup, EuiFlexItem, EuiSkeletonRectangle } from '@elastic/eui'
import { ListNavOption, ListNavOptionView } from './list-nav-option'

export interface ListNavProps<T extends ListNavOption> {
  value: T | undefined
  options: T[]
  onChange: (value: T) => void
  loading?: boolean
  skeletonCount?: number
}

export const ListNav = <T extends ListNavOption>({ value, options, onChange, loading, ...props }: ListNavProps<T>) => {
  const skeletonCount = props.skeletonCount ?? 5
  return (
    <EuiFlexGroup direction="column" gutterSize="xs" style={{ width: '100%' }}>
      {loading
        ? [...Array(skeletonCount).keys()].map((i) => (
            <EuiFlexItem grow={false} key={i}>
              <EuiSkeletonRectangle key={i} height="61px" borderRadius="s" width="100%" />
            </EuiFlexItem>
          ))
        : options.map((option) => (
            <EuiFlexItem grow={false} key={option.value}>
              <ListNavOptionView
                key={option.value}
                option={option}
                isSelected={value?.value === option.value}
                onClick={() => onChange(option)}
              />
            </EuiFlexItem>
          ))}
    </EuiFlexGroup>
  )
}
