import { QuestionDefinitions, getNotesGenerator } from '../../helpers/notes-generator'
import { ApplianceRepairTopicActionResult } from '../appliance-shared/appliance-model'

export type TopicMicrowaveActionResult = ApplianceRepairTopicActionResult

const questionDefinitions: QuestionDefinitions<TopicMicrowaveActionResult> = {
  issue: {
    question: 'What is the issue with your microwave?',
    statement: 'Issue:'
  },
  outletWorksWithOtherDevice: {
    question: 'Does the power point work with other devices?',
    statement: 'Outlet works with other device:'
  }
}

export const microwaveNotesGenerator = getNotesGenerator(questionDefinitions)
