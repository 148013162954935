import { EuiButton, EuiSpacer } from '@elastic/eui'
import { JobType, TradeType } from '../../../api/generated-types'
import { Callout } from '../../../common/callout'
import { decameliseIfNotNil, makeHumanReadable } from '../../../common/utils'
import { CustomerSuppliedTopicActionResult } from '../../action-topic-customer-supplied'
import { SecurityResidencyCheck } from '../../helpers/gold-coast-security-questions'
import { CustomerSuppliedCompliant, IsItemAustralianCompliant } from '../../helpers/item-installer-questions'
import { QuestionDefinitions, getNotesGenerator } from '../../helpers/notes-generator'
import { OutOfScope } from '../../helpers/out-of-topic'
import { getResultUpdater } from '../../helpers/results-updater'
import { SecurityBrandQuestion } from '../../helpers/security-brand-question'
import { FreeTextQuestionField } from '../../question-fields/free-text-question-field'
import { SingleSelectField } from '../../question-fields/single-select-question-field'
import { WorkflowActionProps } from '../../workflow-model'
import { ItemInstaller, ItemSupplier, ServiceType, TopicActionInput } from '../action-topic-model'
import { TopicWorkflowAction } from '../action-topic-view'
import { reset } from '../common/common-utils'

export interface TopicIntercomActionResult extends CustomerSuppliedTopicActionResult {
  serviceType?: ServiceType
  itemSupplier?: ItemSupplier
  itemInstaller?: ItemInstaller
  installDate?: InstallDate
  location?: string
  intercomType?: string
  systemAge?: string
  fault?: string
  faultAge?: string
  brandType?: string
}

export enum InstallDate {
  LessThanOrEqualTo3Years = 'Less than 3 years ago',
  Over3Years = 'Over 3 years ago'
}

const questionDefinitions: QuestionDefinitions<TopicIntercomActionResult> = {
  serviceType: {
    question: 'Is this for an installation or repair?',
    statement: 'Service type:',
    makeAnswerHumanReadable: decameliseIfNotNil
  },
  itemSupplier: {
    question: 'Who is supplying the intercom system?',
    statement: 'Hardware supplied by:',
    makeAnswerHumanReadable: decameliseIfNotNil
  },
  itemInstaller: {
    question: 'Who installed the system?',
    statement: 'System installed by:',
    makeAnswerHumanReadable: decameliseIfNotNil
  },
  installDate: {
    question: 'When did Fallon install?',
    makeAnswerHumanReadable: makeHumanReadable
  },
  location: {
    question: 'Front door or electric gate?',
    statement: 'Location:'
  },
  intercomType: {
    question: 'Audio, visual or either?',
    statement: 'Intercom type:'
  },
  systemAge: {
    question: 'How old is the system?',
    statement: 'System age:'
  },
  fault: {
    question: 'What is the issue or fault?',
    statement: 'Issue or fault:'
  },
  faultAge: {
    question: 'How long has it been happening for?',
    statement: 'Fault age:'
  },
  brandType: {
    question: 'What brand/manufacture is the system?',
    statement: 'Brand/Manufacture:'
  }
}
const notesGenerator = getNotesGenerator(questionDefinitions)

export const TopicIntercomAction = (props: WorkflowActionProps<TopicActionInput, TopicIntercomActionResult>) => {
  const { result, input, onUpdate } = props
  const {
    serviceType,
    customerType,
    itemSupplier,
    itemInstaller,
    installDate,
    location,
    intercomType,
    systemAge,
    fault,
    faultAge,
    australianCompliant,
    jobDescription,
    brandType
  } = result ?? {}
  const { jobIsCompliant, jobIsNotCompliant } = CustomerSuppliedCompliant({ australianCompliant })
  const { serviceArea } = input
  const getJobType = () => {
    if (serviceType === ServiceType.Repair) {
      return itemInstaller === ItemInstaller.Fallon && installDate === InstallDate.LessThanOrEqualTo3Years
        ? JobType.Callback
        : JobType.Service
    } else if (serviceType === ServiceType.Installation) {
      return JobType.Quote
    } else {
      return JobType.Service
    }
  }

  const onNext = () =>
    onUpdate({
      ...result,
      actionCompleted: true,
      trade: TradeType.Security,
      category: 'Intercom',
      type: getJobType(),

      workRequiredNotes: generatedNotes,
      requirements: {
        travelFeeWaived: serviceType === ServiceType.Installation && itemSupplier === ItemSupplier.Fallon,
        attributes: [{ attributeId: 'Intercom' }]
      }
    })

  const generatedNotes = notesGenerator.generateNotes(result ?? {})
  const updateResult = getResultUpdater(result ?? {}, onUpdate).updateResult
  const updateBrandTypeQuestion = (brandType?: string) => updateResult({ brandType })
  const installComplete =
    serviceType === ServiceType.Installation && itemSupplier && location && intercomType && jobIsCompliant
  const revisitComplete =
    serviceType === ServiceType.Repair && itemInstaller === ItemInstaller.Fallon && installDate && brandType
  const repairComplete =
    serviceType === ServiceType.Repair &&
    itemInstaller === ItemInstaller.SomeoneElse &&
    systemAge &&
    fault &&
    faultAge &&
    intercomType &&
    brandType
  const canComplete = installComplete || revisitComplete || repairComplete

  return (
    <TopicWorkflowAction input={input} onUpdate={onUpdate} result={result}>
      <SingleSelectField
        question={notesGenerator.getQuestion('serviceType')}
        options={[
          {
            id: ServiceType.Installation,
            label: 'Installation',
            icon: 'plus'
          },
          {
            id: ServiceType.Repair,
            label: 'Repair',
            icon: 'wrench'
          }
        ]}
        answer={serviceType}
        changeAnswer={(serviceType) =>
          updateResult({
            ...reset(result ?? {}),
            customerType,
            serviceType,
            jobDescription
          })
        }
      />

      {serviceType === ServiceType.Installation && (
        <>
          <SingleSelectField
            question={notesGenerator.getQuestion('itemSupplier')}
            options={[
              {
                id: ItemSupplier.Fallon,
                label: 'Fallon',
                icon: 'check'
              },
              {
                id: ItemSupplier.Customer,
                label: 'Customer',
                icon: 'user'
              }
            ]}
            answer={itemSupplier}
            changeAnswer={(itemSupplier) => updateResult({ itemSupplier })}
          />
          <IsItemAustralianCompliant
            updateResult={updateResult}
            itemSupplier={itemSupplier}
            suppliedAnswer={australianCompliant}
          />
          {jobIsNotCompliant ? (
            <OutOfScope issue={'Intercoms'} isOutOfScope={true} />
          ) : (
            <>
              <FreeTextQuestionField
                question={notesGenerator.getQuestion('location')}
                answer={location}
                changeAnswer={(location) => updateResult({ location })}
              />
              <FreeTextQuestionField
                question={notesGenerator.getQuestion('intercomType')}
                answer={intercomType}
                changeAnswer={(intercomType) => updateResult({ intercomType })}
              />
            </>
          )}
        </>
      )}

      {serviceType === ServiceType.Repair && (
        <>
          <SingleSelectField
            question={notesGenerator.getQuestion('itemInstaller')}
            options={[
              {
                id: ItemInstaller.Fallon,
                label: 'Fallon installed',
                icon: 'check'
              },
              {
                id: ItemInstaller.SomeoneElse,
                label: 'Someone else installed',
                icon: 'user'
              }
            ]}
            answer={itemInstaller}
            changeAnswer={(itemInstaller) => updateResult({ itemInstaller })}
          />
          <SecurityBrandQuestion
            question={notesGenerator.getQuestion('brandType')}
            questionAnswer={brandType}
            updateQuestion={updateBrandTypeQuestion}
          />
        </>
      )}

      {serviceType === ServiceType.Repair && itemInstaller === ItemInstaller.Fallon && (
        <>
          <SingleSelectField
            question={notesGenerator.getQuestion('installDate')}
            options={[
              {
                id: InstallDate.LessThanOrEqualTo3Years,
                label: 'Within last 3 years'
              },
              {
                id: InstallDate.Over3Years,
                label: 'Over 3 years ago'
              }
            ]}
            answer={installDate}
            changeAnswer={(installDate) => updateResult({ installDate })}
          />
          {!!installDate && (
            <>
              <Callout
                type="note"
                title={
                  installDate === InstallDate.LessThanOrEqualTo3Years
                    ? 'Revisit with no travel fee'
                    : 'Normal service job with travel fee'
                }
              />
              <EuiSpacer />
            </>
          )}
        </>
      )}

      {serviceType === ServiceType.Repair && itemInstaller === ItemInstaller.SomeoneElse && (
        <>
          <FreeTextQuestionField
            question={notesGenerator.getQuestion('systemAge')}
            answer={systemAge}
            changeAnswer={(systemAge) => updateResult({ systemAge })}
          />
          <FreeTextQuestionField
            question={notesGenerator.getQuestion('fault')}
            answer={fault}
            changeAnswer={(fault) => updateResult({ fault })}
            rows={3}
          />
          <FreeTextQuestionField
            question={notesGenerator.getQuestion('faultAge')}
            answer={faultAge}
            changeAnswer={(faultAge) => updateResult({ faultAge })}
          />
          <FreeTextQuestionField
            question={notesGenerator.getQuestion('intercomType')}
            answer={intercomType}
            changeAnswer={(intercomType) => updateResult({ intercomType })}
          />
        </>
      )}

      <EuiSpacer />
      {!!serviceType && (
        <>
          <SecurityResidencyCheck serviceArea={serviceArea} />
        </>
      )}
      <EuiButton disabled={!canComplete} onClick={() => onNext()}>
        Next
      </EuiButton>
    </TopicWorkflowAction>
  )
}
