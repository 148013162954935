import { EuiButton, EuiSpacer } from '@elastic/eui'
import { JobType, TradeType } from '../../../api/generated-types'
import { decameliseIfNotNil } from '../../../common/utils'
import { SecurityResidencyCheck } from '../../helpers/gold-coast-security-questions'
import { QuestionDefinitions, getNotesGenerator } from '../../helpers/notes-generator'
import { getResultUpdater } from '../../helpers/results-updater'
import { FreeTextQuestionField } from '../../question-fields/free-text-question-field'
import { SingleSelectField } from '../../question-fields/single-select-question-field'
import { WorkflowActionProps } from '../../workflow-model'
import { InstallType, ItemInstaller, TopicActionInput, TopicActionResult } from '../action-topic-model'
import { TopicWorkflowAction } from '../action-topic-view'

export interface TopicSecurityMonitoringActionResult extends TopicActionResult {
  installType?: InstallType
  itemInstaller?: ItemInstaller
  itemInstallerName?: string
  systemAge?: string
}

const questionDefinitions: QuestionDefinitions<TopicSecurityMonitoringActionResult> = {
  installType: {
    question: 'Do you currently have monitoring?',
    statement: 'Existing monitoring in place:',
    makeAnswerHumanReadable: decameliseIfNotNil
  },
  itemInstaller: {
    question: 'Is it being monitored by Fallon Solutions or a third party?',
    statement: 'Monitoring by:',
    makeAnswerHumanReadable: decameliseIfNotNil
  },
  itemInstallerName: {
    question: 'What company is that third party? (if applicable)',
    statement: 'Monitoring company name:'
  },
  systemAge: {
    question: 'How old is your alarm system?',
    statement: 'Alarm system age:'
  }
}
const notesGenerator = getNotesGenerator(questionDefinitions)

export const TopicSecurityMonitoringAction = (
  props: WorkflowActionProps<TopicActionInput, TopicSecurityMonitoringActionResult>
) => {
  const { result, input, onUpdate } = props
  const { installType, itemInstaller, itemInstallerName, systemAge } = result ?? {}

  const onNext = () =>
    onUpdate({
      ...result,
      actionCompleted: true,
      trade: TradeType.Security,
      category: 'Monitoring',
      type: JobType.Quote,

      workRequiredNotes: generatedNotes,
      requirements: {
        attributes: [{ attributeId: 'Monitoring' }],
        travelFeeWaived: true
      }
    })

  const generatedNotes = notesGenerator.generateNotes(result ?? {})
  const updateResult = getResultUpdater(result ?? {}, onUpdate).updateResult
  const { serviceArea } = input
  const newComplete = installType === InstallType.NewInstall && systemAge
  const existingComplete = installType === InstallType.Replacement && itemInstaller && systemAge
  const canComplete = newComplete || existingComplete

  return (
    <TopicWorkflowAction input={input} onUpdate={onUpdate} result={result}>
      <SingleSelectField
        question={notesGenerator.getQuestion('installType')}
        options={[
          {
            id: InstallType.NewInstall,
            label: 'New install'
          },
          {
            id: InstallType.Replacement,
            label: 'Existing system in place'
          }
        ]}
        answer={installType}
        changeAnswer={(installType) => updateResult({ installType })}
      />

      {installType === InstallType.NewInstall && (
        <>
          <FreeTextQuestionField
            question={notesGenerator.getQuestion('systemAge')}
            answer={systemAge}
            changeAnswer={(systemAge) => updateResult({ systemAge })}
          />
        </>
      )}

      {installType === InstallType.Replacement && (
        <>
          <SingleSelectField
            question={notesGenerator.getQuestion('itemInstaller')}
            options={[
              {
                id: ItemInstaller.Fallon,
                label: 'Fallon Solutions',
                icon: 'check'
              },
              {
                id: ItemInstaller.SomeoneElse,
                label: 'Third party',
                icon: 'user'
              }
            ]}
            answer={itemInstaller}
            changeAnswer={(itemInstaller) => updateResult({ itemInstaller })}
          />
          {itemInstaller && (
            <>
              {itemInstaller === ItemInstaller.SomeoneElse && (
                <FreeTextQuestionField
                  question={notesGenerator.getQuestion('itemInstallerName')}
                  answer={itemInstallerName}
                  changeAnswer={(itemInstallerName) => updateResult({ itemInstallerName })}
                />
              )}
              <FreeTextQuestionField
                question={notesGenerator.getQuestion('systemAge')}
                answer={systemAge}
                changeAnswer={(systemAge) => updateResult({ systemAge })}
              />
            </>
          )}
        </>
      )}

      <EuiSpacer />
      {!!installType && (
        <>
          <SecurityResidencyCheck serviceArea={serviceArea} />
        </>
      )}
      <EuiButton disabled={!canComplete} onClick={() => onNext()}>
        Next
      </EuiButton>
    </TopicWorkflowAction>
  )
}
