import { EuiHorizontalRule, EuiSpacer, EuiTabbedContent, EuiTabbedContentTab, EuiText , EuiTitle } from '@elastic/eui'
import { GetCustomerDocument, InteractionMessageFragment } from '../../api/generated-types'
import { InteractionRecordingsPreview } from '../interaction-recording-preview'
import { useQuery } from '@apollo/client'
import { CustomerDetail } from '../../customers/customer-detail'
import { CustomerPropertiesContainer } from '../../customers/customer-properties-container'
import { CustomerJobsContainer } from '../../customers/customer-jobs-container'
import { CustomerInteractionMessages } from '../../customers/customer-interaction-messages'
import { CustomerMedia } from '../../customers/customer-media'
import { CustomerMemberships } from '../../customers/customer-memberships'
import { CustomerReviews } from '../../customers/customer-reviews'

export interface InteractionMessageDetailSummaryProps {
  interactionMessage: InteractionMessageFragment
}

export const InteractionMessageDetailSummary = ({ interactionMessage }: InteractionMessageDetailSummaryProps) => {
  const customerId = interactionMessage.customer?.id

  const { loading, error, data } = useQuery(GetCustomerDocument, {
    variables: { id: customerId ?? 'missing' },
    skip: !customerId
  })

  const customer = data?.getCustomer

  const recordings = interactionMessage.recordings ?? []

  const customerTabs: EuiTabbedContentTab[] = customer
    ? [
        {
          id: 'customer',
          name: 'Customer',
          content: (
            <>
              <EuiSpacer size="m" />
              <CustomerDetail customer={customer} />
            </>
          )
        },
        {
          id: 'properties',
          name: 'Properties',
          content: (
            <>
              <EuiSpacer size="m" />
              <CustomerPropertiesContainer customerId={customer.id} source={customer.source} />
            </>
          )
        },
        {
          id: 'jobs',
          name: 'Jobs',
          content: (
            <>
              <EuiSpacer size="m" />
              <EuiTitle size="s">
                <h3>Recent jobs</h3>
              </EuiTitle>
              <CustomerJobsContainer customerId={customer.id} />
            </>
          )
        },
        {
          id: 'photos',
          name: 'Photos',
          content: (
            <>
              <EuiSpacer size="m" />
              <CustomerMedia customerId={customer.id} />
            </>
          )
        },
        {
          id: 'memberships',
          name: 'Memberships',
          content: (
            <>
              <EuiSpacer />
              <CustomerMemberships customer={customer} />
            </>
          )
        },
        {
          id: 'calls',
          name: 'Calls',
          content: (
            <>
              <EuiSpacer size="m" />
              <CustomerInteractionMessages customer={customer} />
            </>
          )
        },
        {
          id: 'reviews',
          name: 'Reviews',
          content: (
            <>
              <EuiSpacer size="m" />
              <CustomerReviews customer={customer} />
            </>
          )
        }
      ]
    : []

  return (
    <>
      <EuiTitle size="m">
        <h2>{interactionMessage.type}</h2>
      </EuiTitle>
      <EuiText>Coming soon - basic overview of call journey</EuiText>
      <EuiSpacer size="m" />
      <EuiTitle size="m">
        <h2>Dispositions</h2>
      </EuiTitle>
      {(interactionMessage.dispositions ?? []).map((disposition) => (
        <EuiText key={disposition.id}>{disposition.label}</EuiText>
      ))}
      <EuiText>{interactionMessage.dispositionNotes}</EuiText>
      {customer && (
        <>
          <EuiHorizontalRule />
          <EuiTitle size="m">
            <h2>Customer</h2>
          </EuiTitle>
          <EuiSpacer size="s" />
          <EuiTabbedContent tabs={customerTabs} />
        </>
      )}
      {recordings.length > 0 && (
        <>
          <EuiHorizontalRule />
          <EuiTitle size="s">
            <h2>Recording(s)</h2>
          </EuiTitle>
          <InteractionRecordingsPreview interactionMessageId={interactionMessage.id} />
        </>
      )}
    </>
  )
}
