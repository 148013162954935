import { ApolloError, useMutation } from '@apollo/client'
import {
  EuiButton,
  EuiButtonEmpty,
  EuiForm,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiOverlayMask
} from '@elastic/eui'
import { AddressEntryMode, addressFragmentToAddressInput } from '@fallonsolutions/address'
import { Property } from '@fallonsolutions/property'
import { useState } from 'react'
import { CreatePropertyDocument, CreatePropertyInput, PropertyFragment } from '../api/generated-types'
import { PropertyForm } from './property-form'

export interface PropertyAddProps {
  customerId: string
  closeModal: () => void
}

export const PropertyAdd = (props: PropertyAddProps) => {
  const { customerId, closeModal } = props
  const [showValidationErrors, setShowValidationErrors] = useState(false)
  const [property, setProperty] = useState<PropertyFragment>({
    id: '',
    created: '',
    updated: '',
    accessInstructions: '',
    address: {
      street: '',
      street2: '',
      suburb: '',
      postcode: '',
      state: '',
      country: '',
      entryMode: AddressEntryMode.Autocomplete
    }
  } as Property)
  const [propertyValid, setPropertyValid] = useState(true)

  const [propertyRequestError, setPropertyRequestError] = useState<ApolloError | undefined>(undefined)

  const input: CreatePropertyInput = {
    customer: customerId,
    ...(property.address && { address: addressFragmentToAddressInput(property.address) })
  }

  const [createProperty, { loading }] = useMutation(CreatePropertyDocument)

  const handleUpdate = async () => {
    setShowValidationErrors(true)
    if (propertyValid) {
      try {
        await createProperty({ variables: { input } })
        closeModal()
      } catch (error: any) {
        setPropertyRequestError(error)
        console.log(JSON.stringify(error, null, 2))
      }
    }
  }

  const hideValidationErrors = () => {
    setShowValidationErrors(false)
    setPropertyRequestError(undefined)
  }

  return (
    <EuiOverlayMask>
      <EuiModal onClose={closeModal}>
        <EuiModalHeader>
          <EuiModalHeaderTitle>Add property</EuiModalHeaderTitle>
        </EuiModalHeader>
        <EuiModalBody>
          <EuiForm
            isInvalid={showValidationErrors && (!propertyValid || !!propertyRequestError)}
            onInput={hideValidationErrors}
            error={propertyRequestError?.message}
          >
            <PropertyForm
              canAddressBeProvided={true}
              property={property}
              onChangeProperty={setProperty}
              showValidationErrors={showValidationErrors}
              onValidationChange={setPropertyValid}
            />
          </EuiForm>
        </EuiModalBody>
        <EuiModalFooter>
          <EuiButtonEmpty onClick={closeModal} isDisabled={loading}>
            Cancel
          </EuiButtonEmpty>
          <EuiButton onClick={handleUpdate} isLoading={loading} fill>
            Done
          </EuiButton>
        </EuiModalFooter>
      </EuiModal>
    </EuiOverlayMask>
  )
}
