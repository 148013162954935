import { CustomerLocationStatusType } from '../api/generated-types'
import '../static/css/customer-location-status.css'

export interface CustomerLocationStatusBadgeProps {
  status: CustomerLocationStatusType
  title?: string
  size?: 'xs' | 'm'
}

export const CustomerLocationStatusBadge = ({ status, title, ...props }: CustomerLocationStatusBadgeProps) => {
  const size = props.size ?? 'm'
  return (
    <span
      className={`customer-location-status-badge customer-location-status-badge--${status.toLowerCase()} customer-location-status-badge--${size}`}
      title={title}
    >
      {size === 'm' ? status : ''}
    </span>
  )
}
