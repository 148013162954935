import { EuiButton, EuiSpacer } from '@elastic/eui'
import { JobType, TradeType } from '../../../api/generated-types'
import { QuestionDefinitions, getNotesGenerator } from '../../helpers/notes-generator'
import { getResultUpdater } from '../../helpers/results-updater'
import { FreeTextQuestionField } from '../../question-fields/free-text-question-field'
import { WorkflowActionProps } from '../../workflow-model'
import { TopicActionInput, TopicActionResult } from '../action-topic-model'
import { TopicWorkflowAction } from '../action-topic-view'

export interface TopicPlumbingTapRepairActionResult extends TopicActionResult {
  installationLocation?: string
  tapType?: string
  faultDescription?: string
}

const questionDefinitions: QuestionDefinitions<TopicPlumbingTapRepairActionResult> = {
  installationLocation: {
    question: 'Where are the taps/shower heads located?',
    statement: 'Tap/shower head location(s):'
  },
  tapType: {
    question: 'What type of tap/shower head is it? (e.g. standard or flick mixer)',
    statement: 'Tap type(s):'
  },
  faultDescription: {
    question: 'What seems to be the issue with the tap/shower head?',
    statement: 'Issue:'
  }
}
const notesGenerator = getNotesGenerator(questionDefinitions)

export const TopicPlumbingTapRepairAction = (
  props: WorkflowActionProps<TopicActionInput, TopicPlumbingTapRepairActionResult>
) => {
  const { result, input, onUpdate } = props
  const { installationLocation, tapType, faultDescription } = result ?? {}

  const onNext = () =>
    onUpdate({
      ...result,
      actionCompleted: true,
      trade: TradeType.Plumbing,
      category: 'Tap',
      type: JobType.Service,

      workRequiredNotes: generatedNotes,
      requirements: {
        attributes: [{ attributeId: 'GeneralPlumbing' }]
      }
    })

  const generatedNotes = notesGenerator.generateNotes(result ?? {})
  const updateResult = getResultUpdater(result ?? {}, onUpdate).updateResult

  const canComplete = !!tapType && !!faultDescription

  return (
    <TopicWorkflowAction input={input} onUpdate={onUpdate} result={result}>
      <FreeTextQuestionField
        question={notesGenerator.getQuestion('installationLocation')}
        answer={installationLocation}
        changeAnswer={(installationLocation) => updateResult({ installationLocation })}
      />
      <FreeTextQuestionField
        question={notesGenerator.getQuestion('tapType')}
        answer={tapType}
        changeAnswer={(tapType) => updateResult({ tapType })}
      />
      <FreeTextQuestionField
        question={notesGenerator.getQuestion('faultDescription')}
        rows={3}
        answer={faultDescription}
        changeAnswer={(faultDescription) => updateResult({ faultDescription })}
      />

      <EuiSpacer />
      <EuiButton disabled={!canComplete} onClick={() => onNext()}>
        Next
      </EuiButton>
    </TopicWorkflowAction>
  )
}
