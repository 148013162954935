import { JobType, TradeType } from '../../../api/generated-types'
import { YesNoValue } from '../common/common-enums'

import {
  TopicQualification,
  TopicQualificationResult,
  TopicRequirementsResult,
  createOutcomeGenerator,
  createRequirements
} from '../common/common-outcome'
import { TopicMicrowaveActionResult } from './action-topic-microwave-model'
import { GeneralApplianceIssueType, getMechanicalIssueOnlyMessage } from '../appliance-shared/appliance-model'

import { APPLIANCE_FAULT_OR_REPAIR_SKILL, GENERAL_ELECTRICAL_SKILL } from '../common/skill-constants'

const getRequirements = (result: TopicMicrowaveActionResult | undefined): TopicRequirementsResult => {
  const { issue, outletWorksWithOtherDevice } = result ?? {}
  if (issue === GeneralApplianceIssueType.PowerSupply) {
    if (outletWorksWithOtherDevice) {
      return outletWorksWithOtherDevice === YesNoValue.Yes
        ? { requirements: createRequirements([APPLIANCE_FAULT_OR_REPAIR_SKILL]), trade: TradeType.Appliances }
        : { requirements: createRequirements([GENERAL_ELECTRICAL_SKILL]), trade: TradeType.Electrical }
    }
  }
  return { requirements: createRequirements([]) }
}
const getQualification = (result: TopicMicrowaveActionResult | undefined): TopicQualificationResult => {
  const { issue, outletWorksWithOtherDevice } = result ?? {}
  switch (issue) {
    case GeneralApplianceIssueType.PowerSupply:
      if (outletWorksWithOtherDevice) {
        return { qualification: TopicQualification.InScope }
      } else {
        return { qualification: TopicQualification.Unknown }
      }
    case GeneralApplianceIssueType.OtherFault:
      return {
        qualification: TopicQualification.OutOfScope,
        qualificationMessage: getMechanicalIssueOnlyMessage('microwave')
      }
    default:
      return { qualification: TopicQualification.Unknown }
  }
}
export const outcomeGenerator = createOutcomeGenerator<TopicMicrowaveActionResult>(
  { qualification: TopicQualification.Unknown, trade: TradeType.Electrical, type: JobType.Service, category: 'General' },
  getQualification,
  getRequirements
)
