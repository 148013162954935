import { EuiFlexGroup, EuiFlexItem, EuiIcon, EuiLoadingSpinner, EuiText, EuiToken } from '@elastic/eui'
import { dateConfig } from '@fallonsolutions/date'
import { DateTime } from 'luxon'
import { useEffect, useState } from 'react'
import { InteractionMessageLinkFragment, InteractionMessageSource } from '../../api/generated-types'
import { formatPhone } from '../../common/phone'
import { timeFormatterSeconds } from '../../common/time'
import VerticalDivider from '../../common/vertical-divider'
import '../../static/css/active-interaction-message-bar.css'
import DialpadIcon from '../../static/images/dialpad-icon.svg?react'
import PhoneIcon from '../../static/images/icon-phone.svg?react'
import { colorForInteractionMessageStatus } from '../interaction-message-card'
import { InteractionMessageStatusBadge } from '../interaction-message-status-badge'

export interface ActiveInteractionMessageBarViewProps {
  interactionMessage: InteractionMessageLinkFragment
  loading?: boolean
}

export const ActiveInteractionMessageBarView = ({
  interactionMessage,
  loading
}: ActiveInteractionMessageBarViewProps) => {
  const startTimeValue = DateTime.fromISO(interactionMessage.startDate, { zone: dateConfig.defaultTimezone })
    .toFormat(dateConfig.luxonFormat.time)
    .toLowerCase()
  const name = interactionMessage.contact?.fullName ?? 'Unknown'
  const phoneNumber = interactionMessage.externalNumber
    ? formatPhone(interactionMessage.externalNumber)
    : 'Private number'

  const [durationValue, setDurationValue] = useState(
    calculateDuration(interactionMessage.startDate, interactionMessage.endDate)
  )

  useEffect(() => {
    const interval = setInterval(
      () => setDurationValue(calculateDuration(interactionMessage.startDate, interactionMessage.endDate)),
      1000
    )
    return () => clearInterval(interval)
  }, [interactionMessage.startDate, interactionMessage.endDate])

  const startTime = <EuiFlexItem grow={false}>{startTimeValue}</EuiFlexItem>

  const icon = (
    <EuiFlexItem grow={false} style={{ width: '12px' }}>
      {loading ? (
        <EuiLoadingSpinner />
      ) : (
        <EuiIcon size="s" type={PhoneIcon} color={colorForInteractionMessageStatus(interactionMessage.status.status)} />
      )}
    </EuiFlexItem>
  )

  const source = (
    <EuiFlexItem grow={false} style={{ height: '24px' }}>
      {interactionMessage.source === InteractionMessageSource.Dialpad ? (
        <DialpadIcon style={{ width: '24px' }} />
      ) : (
        <EuiToken iconType="unlink" shape="square" color="#FFFAD1" size="s" style={{ margin: 'auto' }} />
      )}
    </EuiFlexItem>
  )

  const contactName = (
    <EuiFlexItem grow={false}>
      <EuiText color="text" size="s">
        <strong>{name}</strong>
      </EuiText>
    </EuiFlexItem>
  )

  const contactPhone = (
    <EuiFlexItem grow={false}>
      <EuiText color="text" size="s">
        {phoneNumber}
      </EuiText>
    </EuiFlexItem>
  )

  const status = (size: 'xs' | 'm') => (
    <EuiFlexItem grow={false}>
      <InteractionMessageStatusBadge status={interactionMessage.status.status} size={size} />
    </EuiFlexItem>
  )

  const duration = (
    <EuiFlexItem style={{ textAlign: 'right' }} grow={false}>
      {timeFormatterSeconds(durationValue.as('seconds'))}
    </EuiFlexItem>
  )

  const verticalDivider = (
    <EuiFlexItem grow={false}>
      <VerticalDivider height="24px" color="#65666A" />
    </EuiFlexItem>
  )

  return (
    <EuiFlexGroup alignItems="center" gutterSize="m" className="active-interaction-message-bar--link">
      {icon}
      {startTime}
      {verticalDivider}
      {contactPhone}
      {contactName}
      <EuiFlexItem grow={true} />
      {status('m')}
      {duration}
      {source}
    </EuiFlexGroup>
  )
}

const calculateDuration = (startDate: string, endDate?: string) => {
  const now = endDate
    ? DateTime.fromISO(endDate, { zone: dateConfig.defaultTimezone })
    : DateTime.now().setZone(dateConfig.defaultTimezone)
  const date = DateTime.fromISO(startDate, { zone: dateConfig.defaultTimezone })
  const runningDuration = now.diff(date, ['minutes', 'seconds'])
  return runningDuration
}
