import { BaseActionInput } from '../../workflow-model'
export type TopicActionInput = BaseActionInput

export interface TopicActionResult {
  pumpUsage: PumpUsageType
}

export enum PumpUsageType {
  Rainwater = 'Rain water',
  Stormwater = 'Storm water',
  Sump = 'Sump',
  Rainbank = 'Rain bank',
  Sani = 'Sani',
  Macerator = 'Macerator',
  Pool = 'Pool',
  Greywater = 'Grey water'
}
