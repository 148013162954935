import {
  TopicQualification,
  TopicQualificationResult,
  TopicRequirementsResult,
  createOutcomeGenerator,
  createRequirements
} from '../common/common-outcome'
import { JobType, TradeType } from '../../../api/generated-types'
import { YesNoValue } from '../common/common-enums'
import {
  CurrentMembershipStatus,
  DrainDiameter,
  InspectionType,
  TopicInspectionActionResult
} from './action-topic-inspection-model'
import { includes } from 'lodash-es'

const DRAINS_INSPECTION_SKILL = 'DrainInspection'
const GENERAL_ELECTRICAL_SKILL = 'GeneralElectrical'
const SOLAR_INSPECTION_SKILL = 'SolarInspection'
const SECURITY_INSPECTION_SKILL = 'SecurityAlarm'
const GENERAL_PLUMBING_SKILL = 'GeneralPlumbing'

const getRequirements = (result: TopicInspectionActionResult | undefined): TopicRequirementsResult => {
  if (!result) return {}
  const { inspectionType, currentMembership } = result
  switch (inspectionType) {
    case InspectionType.Drains:
    case InspectionType.PlumbingAndDrains:
      return { requirements: createRequirements([DRAINS_INSPECTION_SKILL]), trade: TradeType.Drains }
    case InspectionType.Solar:
      return { requirements: createRequirements([SOLAR_INSPECTION_SKILL]), trade: TradeType.Solar }
    case InspectionType.Security: {
      const travelFeeWaived = currentMembership === CurrentMembershipStatus.Current3YearPlan
      return {
        requirements: {
          ...createRequirements([SECURITY_INSPECTION_SKILL]),
          travelFeeWaived
        },
        trade: TradeType.Security
      }
    }
    case InspectionType.Electrical:
      return { requirements: createRequirements([GENERAL_ELECTRICAL_SKILL]), trade: TradeType.Electrical }
    case InspectionType.Plumbing:
      return { requirements: createRequirements([GENERAL_PLUMBING_SKILL]), trade: TradeType.Plumbing }
    default:
      return {}
  }
}

const getQualification = (result: TopicInspectionActionResult | undefined): TopicQualificationResult => {
  if (!result) return { qualification: TopicQualification.Unknown }
  const { inspectionType, currentMembership, additionalNotes, drainDiameter, isSpecificInspection } = result
  if (currentMembership === CurrentMembershipStatus.NotCurrent) {
    return { qualification: TopicQualification.Unknown }
  } else if (
    includes([CurrentMembershipStatus.Current1YearPlan, CurrentMembershipStatus.Current3YearPlan], currentMembership) &&
    inspectionType
  ) {
    switch (inspectionType) {
      case InspectionType.Electrical:
      case InspectionType.Security:
      case InspectionType.Solar:
        if (isSpecificInspection === YesNoValue.No) {
          return { qualification: TopicQualification.InScope }
        } else {
          return {
            qualification:
              additionalNotes && additionalNotes.length > 0 ? TopicQualification.InScope : TopicQualification.Unknown
          }
        }

      case InspectionType.Drains:
      case InspectionType.PlumbingAndDrains:
        if (!drainDiameter) return { qualification: TopicQualification.Unknown }
        return {
          qualification:
            drainDiameter === DrainDiameter.Between90and150 ? TopicQualification.InScope : TopicQualification.OutOfScope
        }
      default:
        return { qualification: TopicQualification.InScope }
    }
  } else {
    return { qualification: TopicQualification.Unknown }
  }
}

export const outcomeGenerator = createOutcomeGenerator<TopicInspectionActionResult>(
  {
    qualification: TopicQualification.Unknown,
    trade: TradeType.None,
    type: JobType.Inspection,
    category: 'HPP Inspection'
  },
  getQualification,
  getRequirements
)
