import { useQuery } from '@apollo/client'
import {
  EuiCallOut,
  EuiFlexGroup,
  EuiFlexItem,
  EuiLink,
  EuiLoadingSpinner,
  EuiSpacer,
  EuiText,
  EuiTitle
} from '@elastic/eui'
import { useEffect, useState } from 'react'
import { ContactSource, SearchInteractionMessagesDocument } from '../api/generated-types'
import { useAuthenticated } from '../auth/authenticated-context'
import { useInteractionService } from './interaction-service'
import { RecentInteractionMessageList } from './recent-interaction-message-list'
import { RecentInteractionMessageMenu } from './recent-interaction-message-menu'

export enum InteractionMessageListType {
  List = 'list',
  Menu = 'menu'
}

export interface RecentInteractionMessageListContainerProps {
  type?: InteractionMessageListType
}

export const RecentInteractionMessageListContainer = (props: RecentInteractionMessageListContainerProps) => {
  const type = props.type ?? InteractionMessageListType.List
  const { activeInteractionMessage } = useInteractionService()

  const [expanded, setExpanded] = useState(false)

  const { userFragment } = useAuthenticated()
  const { data, error, loading, refetch } = useQuery(SearchInteractionMessagesDocument, {
    variables: {
      input: {
        filter: {
          assignedUserId: [userFragment.id],
          contactSource: [ContactSource.External]
        },
        size: 15
      }
    }
  })

  useEffect(() => {
    refetch()
  }, [activeInteractionMessage, refetch])

  const interactionMessages = data?.searchInteractionMessages?.results ?? []
  const interactionMessageCount = interactionMessages?.length

  return type === InteractionMessageListType.List ? (
    <>
      <EuiTitle size="s">
        <h2>Recent calls {interactionMessageCount > 0 ? `(${interactionMessageCount})` : ''}</h2>
      </EuiTitle>

      <EuiSpacer size="m" />
      {loading ? (
        <EuiLoadingSpinner />
      ) : error ? (
        <EuiCallOut title="Error loading recent calls" color="danger" iconType="alert">
          <EuiText size="s">{error.message}</EuiText>
        </EuiCallOut>
      ) : interactionMessages ? (
        <>
          <RecentInteractionMessageList interactionMessages={interactionMessages} expanded={expanded} />
          <EuiSpacer size="xs" />
          <EuiFlexGroup justifyContent="center">
            <EuiFlexItem grow={false}>
              <EuiLink color="subdued" onClick={() => setExpanded((expanded) => !expanded)}>
                {expanded ? 'Less' : 'More'}
              </EuiLink>
            </EuiFlexItem>
          </EuiFlexGroup>
        </>
      ) : (
        <></>
      )}
    </>
  ) : (
    <>{loading ? <EuiLoadingSpinner /> : <RecentInteractionMessageMenu interactionMessages={interactionMessages} />}</>
  )
}
