import { useMutation, useQuery } from '@apollo/client'
import {
  EuiButton,
  EuiButtonEmpty,
  EuiCallOut,
  EuiForm,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiOverlayMask,
  EuiSpacer
} from '@elastic/eui'
import { Property } from '@fallonsolutions/property'
import { useState } from 'react'
import {
  GetCustomerPropertiesDocument,
  GetCustomerPropertiesQueryVariables,
  JobFragment,
  UpdateJobPropertyDocument,
  UpdateJobPropertyInput
} from '../api/generated-types'
import { PropertyTable } from '../properties/property-search-results'

export interface JobPropertyChangeProps {
  job: JobFragment
  closeModal: () => void
}

export const JobPropertyChange = (props: JobPropertyChangeProps) => {
  const { job, closeModal } = props

  const [selectedProperty, seSelectedProperty] = useState<string | undefined>(job.property?.id)
  const [updateJobProperty, { error, loading: updatingJobProperty }] = useMutation(UpdateJobPropertyDocument, {
    refetchQueries: ['GetJob'],
    awaitRefetchQueries: true
  })

  const variables: GetCustomerPropertiesQueryVariables = {
    id: job.customer?.id ?? 'missing'
  }

  const { data: customerPropertiesData, loading: loadingProperties } = useQuery(GetCustomerPropertiesDocument, {
    variables,
    skip: !job.customer?.id
  })

  const properties = (customerPropertiesData?.getCustomer?.properties as Property[]) ?? []

  const handleUpdate = async () => {
    if (selectedProperty) {
      const input: UpdateJobPropertyInput = {
        job: job.id,
        property: selectedProperty
      }
      try {
        await updateJobProperty({ variables: { input } })
        closeModal()
      } catch (error) {
        console.error('error performing mutation', error)
      }
    }
  }

  const form = (
    <EuiForm>
      {error && (
        <>
          <EuiCallOut color="danger" title="Error updating job property" />
          <EuiSpacer />
        </>
      )}
      <PropertyTable
        loading={loadingProperties}
        properties={properties}
        selectedProperty={selectedProperty}
        onSelect={(property) => seSelectedProperty(property.id)}
        additionalClassName="basic-table--white-background"
      />
    </EuiForm>
  )

  return (
    <EuiOverlayMask>
      <EuiModal onClose={closeModal}>
        <EuiModalHeader>
          <EuiModalHeaderTitle>Change property for job: {job.number}</EuiModalHeaderTitle>
        </EuiModalHeader>
        <EuiModalBody>
          <div>{form}</div>
        </EuiModalBody>
        <EuiModalFooter>
          <EuiButtonEmpty onClick={closeModal} isDisabled={updatingJobProperty}>
            Cancel
          </EuiButtonEmpty>
          <EuiButton
            onClick={handleUpdate}
            isLoading={updatingJobProperty}
            fill
            disabled={!selectedProperty || updatingJobProperty}
          >
            Done
          </EuiButton>
        </EuiModalFooter>
      </EuiModal>
    </EuiOverlayMask>
  )
}
