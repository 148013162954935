import { EuiFlexGroup, EuiFlexItem, EuiHorizontalRule, EuiLink, EuiSpacer, EuiText } from '@elastic/eui'
import { useEffect, useState } from 'react'
import { elementScrollIntoView } from 'seamless-scroll-polyfill'
import { v4 as uuidv4 } from 'uuid'
import '../static/css/workflow.css'

interface WorkflowActionProps {
  title: string
  actionElement?: React.ReactNode
  value?: string | undefined // string representation of currently selected value
  onClickChange?: () => void
  editable?: boolean
  hideChildrenOnComplete?: boolean
  children?: React.ReactNode
}

export const WorkflowAction = (props: WorkflowActionProps) => {
  const { title, value, children, onClickChange, actionElement } = props
  const editable = props.editable ?? true
  const hideChildrenOnComplete = props.hideChildrenOnComplete ?? true

  const completed = value !== undefined
  const [actionId] = useState(uuidv4())

  useEffect(() => {
    document.title = `${title} - FS`
  }, [completed, title])

  useEffect(() => {
    if (!completed) {
      const scheduleEventDomElement = document.getElementById(`workflow-action-${actionId}`)
      if (scheduleEventDomElement) {
        const scrollOptions: ScrollIntoViewOptions = {
          block: 'start',
          behavior: 'smooth'
        }
        setTimeout(() => {
          elementScrollIntoView(scheduleEventDomElement, scrollOptions)
        }, 0)
      }
    }
  }, [actionId, completed])

  const visible = !completed || !hideChildrenOnComplete

  return (
    <>
      <EuiFlexGroup alignItems="center" id={`workflow-action-${actionId}`}>
        <EuiFlexItem grow={true}>
          <div className="small-label">{title}</div>
          {value && (
            <>
              <EuiSpacer size="xs" />
              <EuiText>
                <span className="workflow-action__value">{value}</span>
              </EuiText>
            </>
          )}
        </EuiFlexItem>
        {editable && onClickChange && value ? (
          <EuiFlexItem grow={false}>
            <EuiLink onClick={() => onClickChange()}>Change</EuiLink>
          </EuiFlexItem>
        ) : actionElement ? (
          <EuiFlexItem grow={false}>{actionElement}</EuiFlexItem>
        ) : (
          <></>
        )}
      </EuiFlexGroup>
      {!value && <EuiSpacer size="l" />}
      {visible === true && children}
      {value && <EuiHorizontalRule />}
    </>
  )
}
