import {
  EuiButtonEmpty,
  EuiButtonIcon,
  EuiContextMenu,
  EuiContextMenuPanelDescriptor,
  EuiDescriptionList,
  EuiDescriptionListDescription,
  EuiDescriptionListTitle,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFlyout,
  EuiFlyoutBody,
  EuiFlyoutHeader,
  EuiHorizontalRule,
  EuiPanel,
  EuiPopover,
  EuiSpacer,
  EuiTitle
} from '@elastic/eui'
import { useState } from 'react'
import { ContactExpandedFragment } from '../api/generated-types'
import { useApp } from '../app/app-context'
import CreateCommentForm from '../comments/create-comment-form'
import RawJSON from '../common/raw-json'
import hubspotIcon from '../static/images/hubspot-icon.png'
import { ContactActivityList } from './contact-activity-list'
import { ContactCard } from './contact-card'
import { ContactCommunicationPreferences } from './contact-communication-preferences'
import { ContactCustomerAssociationList } from './contact-customer-association-list'
import { ContactHistory } from './contact-history'
import { ContactInteractionMessages } from './contact-interaction-messages'
import { ContactMergeForm } from './contact-merge-form'
import { EditContactForm } from './edit-contact-form'
import { UpdateContactDataQualityForm } from './update-contact-data-quality-form'

export interface ContactDetailViewProps {
  contact: ContactExpandedFragment
}

enum ContactDetailModalType {
  Edit = 'edit',
  Merge = 'merge',
  History = 'history'
}

export const ContactDetailView = ({ contact }: ContactDetailViewProps) => {
  const { hubspotAccountId } = useApp()

  const [isContactDetailsMenuOpen, setIsContactDetailsMenuOpen] = useState(false)
  const [contactDetailModal, setContactDetailModal] = useState<ContactDetailModalType | undefined>(undefined)
  const [updateContactDataQualityId, setUpdateContactDataQualityId] = useState<string | undefined>(undefined)

  const customerDetailsMenu = [
    {
      id: 0,
      items: [
        {
          name: 'Edit contact details',
          icon: 'documentEdit',
          onClick: () => {
            setIsContactDetailsMenuOpen(false)
            setContactDetailModal(ContactDetailModalType.Edit)
          }
        },
        {
          name: 'Edit contact data quality',
          icon: 'check',
          onClick: () => {
            setUpdateContactDataQualityId(contact.id)
          }
        },
        {
          name: 'View change history',
          icon: 'clock',
          onClick: () => {
            setIsContactDetailsMenuOpen(false)
            setContactDetailModal(ContactDetailModalType.History)
          }
        },
        {
          isSeparator: true
        },
        {
          name: 'Merge contacts',
          icon: 'branch',
          onClick: () => {
            setIsContactDetailsMenuOpen(false)
            setContactDetailModal(ContactDetailModalType.Merge)
          }
        }
      ]
    }
  ] as EuiContextMenuPanelDescriptor[]

  const onCloseModal = () => setContactDetailModal(undefined)

  return (
    <>
      <EuiTitle size="s">
        <h1>{contact.detail?.fullName ?? 'No name'}</h1>
      </EuiTitle>
      <EuiSpacer />
      {contact.detail && (
        <>
          <EuiPanel>
            <EuiFlexGroup alignItems="flexStart">
              <EuiFlexItem grow={true}>
                <EuiTitle size="xs">
                  <h2>Contact detail</h2>
                </EuiTitle>
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                {contact.hubspotId && (
                  <EuiButtonEmpty
                    href={`https://app.hubspot.com/contacts/${hubspotAccountId}/${contact.hubspotId}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    iconType={hubspotIcon}
                    size="xs"
                    style={{ fontWeight: 400, fontSize: '13px' }}
                  >
                    View in Hubspot
                  </EuiButtonEmpty>
                )}
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiPopover
                  isOpen={isContactDetailsMenuOpen}
                  button={
                    <EuiButtonIcon
                      aria-label="show actions"
                      iconType="boxesHorizontal"
                      color="text"
                      onClick={() => setIsContactDetailsMenuOpen(!isContactDetailsMenuOpen)}
                    />
                  }
                  closePopover={() => setIsContactDetailsMenuOpen(false)}
                  ownFocus={true}
                  panelPaddingSize="none"
                >
                  <EuiContextMenu initialPanelId={0} panels={customerDetailsMenu} />
                </EuiPopover>
              </EuiFlexItem>
            </EuiFlexGroup>
            <EuiSpacer size="m" />

            <ContactCard contact={contact} />
          </EuiPanel>
          <EuiSpacer />
        </>
      )}

      <EuiPanel>
        <EuiTitle size="xs">
          <h2>Customer associations</h2>
        </EuiTitle>
        <EuiSpacer size="s" />
        <ContactCustomerAssociationList contact={contact} />
      </EuiPanel>

      <EuiSpacer />

      <EuiPanel>
        <EuiTitle size="xs">
          <h2>Communication preferences</h2>
        </EuiTitle>
        <EuiSpacer size="s" />
        <ContactCommunicationPreferences contact={contact} />
      </EuiPanel>

      <EuiSpacer />

      <EuiPanel>
        <EuiTitle size="xs">
          <h2>Recent calls</h2>
        </EuiTitle>
        <EuiSpacer size="s" />
        <ContactInteractionMessages contactId={contact.id} />
      </EuiPanel>

      {contact.webForm && (
        <>
          <EuiSpacer />
          <EuiPanel>
            <EuiTitle size="xs">
              <h2>Web form</h2>
            </EuiTitle>
            <EuiSpacer size="s" />
            <EuiDescriptionList>
              <EuiDescriptionListTitle>Type</EuiDescriptionListTitle>
              <EuiDescriptionListDescription>{contact.webForm.type}</EuiDescriptionListDescription>

              <EuiDescriptionListTitle>Date</EuiDescriptionListTitle>
              <EuiDescriptionListDescription>{contact.webForm.created}</EuiDescriptionListDescription>
            </EuiDescriptionList>

            <RawJSON data={contact.webForm} />
          </EuiPanel>
        </>
      )}

      {updateContactDataQualityId && (
        <UpdateContactDataQualityForm
          contactId={updateContactDataQualityId}
          onClose={() => setUpdateContactDataQualityId(undefined)}
        />
      )}

      {contactDetailModal === ContactDetailModalType.Edit && (
        <EditContactForm contact={contact} onClose={onCloseModal} />
      )}
      {contactDetailModal === ContactDetailModalType.Merge && (
        <ContactMergeForm contact={contact} onClose={onCloseModal} />
      )}
      {contactDetailModal === ContactDetailModalType.History && (
        <EuiFlyout ownFocus onClose={onCloseModal}>
          <EuiFlyoutHeader hasBorder>
            <EuiTitle size="m">
              <h2>Contact history {contact.detail?.fullName ?? 'No name'}</h2>
            </EuiTitle>
          </EuiFlyoutHeader>
          <EuiFlyoutBody>
            <ContactHistory contactId={contact.id} />
          </EuiFlyoutBody>
        </EuiFlyout>
      )}

      <EuiSpacer />

      <EuiHorizontalRule />
      <EuiTitle size="s">
        <h2>Comments and activity</h2>
      </EuiTitle>
      <EuiSpacer size="m" />
      <CreateCommentForm contactId={contact.id} />
      <ContactActivityList contactId={contact.id} />

      <RawJSON data={contact} />

      <EuiSpacer size="xl" />
    </>
  )
}
