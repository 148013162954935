import { InvoiceFragment, JobFragment, UserAuthFragment, UserFragment } from '@fallonsolutions/types'
import { flow } from 'lodash-es'

export interface SelectedUser {
  avatar: string | undefined
  id: string | undefined
  label: string
}

type SelectUserBias = 'Optionsheet' | 'User' | 'Invoice'
export interface GetDefaultUserForJobParams {
  job: JobFragment | undefined
  user: UserAuthFragment
  invoice?: InvoiceFragment | undefined
  bias: SelectUserBias // which object to get the user from first if possible
}

export const getDefaultUserForJob = ({
  job,
  invoice,
  user,
  bias = 'User'
}: GetDefaultUserForJobParams): SelectedUser | undefined => {
  if (!job && !invoice && !user) {
    return undefined
  }
  // can only select users that have valid finance details, if user does not meet requirements fall back to tech on option sheet
  return getSelectUser(job, invoice, user, bias)
}

const getSelectUser = (
  job: JobFragment | undefined,
  invoice: InvoiceFragment | undefined,
  user: UserAuthFragment,
  bias: SelectUserBias
): SelectedUser | undefined => {
  return flow([
    generateUserDetails,
    (data: GenerateUserDetailsPayload) => {
      switch (bias) {
        case 'Optionsheet':
          return data.optionSheetUser ?? data.invoiceUser ?? data.loggedInUser
        case 'User':
          return data.loggedInUser ?? data.optionSheetUser ?? data.invoiceUser
        case 'Invoice':
          return data.invoiceUser ?? data.optionSheetUser ?? data.loggedInUser
      }
    }
  ])(job, invoice, user)
}
interface GenerateUserDetailsPayload {
  loggedInUser: SelectedUser | undefined
  invoiceUser: SelectedUser | undefined
  optionSheetUser: SelectedUser | undefined
}
const generateUserDetails = (
  job: JobFragment | undefined,
  invoice: InvoiceFragment | undefined,
  user: UserAuthFragment
) => {
  return {
    loggedInUser: getLoggedInUser(user),
    invoiceUser: getInvoiceUser(invoice),
    optionSheetUser: getOptionSheetUser(job)
  }
}

const getLoggedInUser = (user: UserAuthFragment | undefined): SelectedUser | undefined => {
  if (isUserValid(user)) {
    return {
      avatar: user?.avatar,
      id: user?.id,
      label: user?.contactDetail?.fullName ?? ''
    }
  }
}

const getInvoiceUser = (invoice: InvoiceFragment | undefined): SelectedUser | undefined => {
  if (!invoice) {
    return undefined
  }
  const user = invoice.technician
  if (isUserValid(user)) {
    return {
      avatar: user?.avatar,
      id: user?.id,
      label: user?.contactDetail?.fullName ?? ''
    }
  }
}

const getOptionSheetUser = (job: JobFragment | undefined): SelectedUser | undefined => {
  const user = job?.optionSheet?.technician
  if (isUserValid(user)) {
    return {
      avatar: user?.avatar,
      id: user?.id,
      label: user?.contactDetail?.fullName ?? ''
    }
  }
}

const isUserValid = (user: UserFragment | undefined | null): boolean => {
  return !!user?.finance && !!user?.finance?.paymentAccount && !!user?.finance?.salesPersonId
}
