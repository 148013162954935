import { ArrangementCancellationReason } from '../api/generated-types'
import { EuiSelect, EuiSelectOption } from '@elastic/eui'
import { decamelise } from '../common/utils'

interface ArrangementCancellationReasonSelectorProps {
  reason: ArrangementCancellationReason | undefined
  changeReason: (value: ArrangementCancellationReason | undefined) => void
}

export const ArrangementCancellationReasonSelector = (props: ArrangementCancellationReasonSelectorProps) => {
  const { reason, changeReason } = props

  const noValue = '-'
  const allReasons: EuiSelectOption[] = [
    {
      id: noValue,
      text: '',
      value: noValue
    }
  ].concat(
    Object.keys(ArrangementCancellationReason).map((r) => ({
      id: r,
      text: decamelise(r),
      value: r
    }))
  )

  const setReason = (rawValue: string) => {
    changeReason(rawValue === noValue ? undefined : (rawValue as ArrangementCancellationReason))
  }

  return (
    <EuiSelect
      options={allReasons}
      isInvalid={reason === undefined}
      value={reason === undefined ? noValue : reason}
      onChange={(e) => setReason(e.target.value)}
      data-test-subj="appointment-cancellation-options"
      aria-label="appointment-cancellation-options"
    />
  )
}
