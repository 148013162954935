import { EuiButton, EuiSpacer, EuiText } from '@elastic/eui'
import { CustomerType, EnquiryAbortReason, JobType, TradeType } from '../../../api/generated-types'
import { Callout } from '../../../common/callout'
import { HVACRoofAccessQuestion } from '../../helpers/hvac-commercial-roof-acccess-question'
import { getResultUpdater } from '../../helpers/results-updater'
import { useWorkflow } from '../../helpers/workflow-provider'
import { SingleSelectField } from '../../question-fields/single-select-question-field'
import { WorkflowActionProps } from '../../workflow-model'
import { TopicActionInput } from '../action-topic-model'
import { TopicWorkflowAction } from '../action-topic-view'
import { YesNoValue } from '../common/common-enums'
import { TopicHVACMaintenanceActionResult, hvacMaintenanceNotesGenerator } from './action-topic-hvac-maintenance-model'
import {
  getHVACMaintenanceOutOfScope,
  getHVACMaintenanceRequirements
} from './action-topic-hvac-maintenance-requirements'

export const TopicHVACMaintenanceAction = (
  props: WorkflowActionProps<TopicActionInput, TopicHVACMaintenanceActionResult>
) => {
  const workflowContext = useWorkflow()
  const { result, input, onUpdate } = props
  const { existingContract, dsrAdviseToBook, roofAccess, customerType } = result ?? {}
  const isCommercial = customerType === CustomerType.Commercial
  const notesGenerator = hvacMaintenanceNotesGenerator

  const requirements = getHVACMaintenanceRequirements(result)
  const isOutOfScope = getHVACMaintenanceOutOfScope(result)

  const onNext = () =>
    onUpdate({
      ...result,
      actionCompleted: true,
      trade: TradeType.HVAC,
      category: 'General',
      type: JobType.Maintenance,

      workRequiredNotes: generatedNotes,
      ...(requirements && { requirements })
    })

  const generatedNotes = notesGenerator.generateNotes(result ?? {})
  const updateResult = getResultUpdater(result ?? {}, onUpdate).updateResult

  const canComplete = !!requirements

  return (
    <TopicWorkflowAction input={input} onUpdate={onUpdate} result={result}>
      <SingleSelectField
        question={notesGenerator.getQuestion('existingContract')}
        options={[
          {
            id: YesNoValue.Yes,
            label: 'Yes, have maintenance contract'
          },
          {
            id: YesNoValue.No,
            label: 'No'
          }
        ]}
        answer={existingContract}
        changeAnswer={(existingContract) => updateResult({ existingContract })}
      />

      {!!existingContract && (
        <>
          <Callout type="script">
            <EuiText>
              <div>
                I will need to transfer you through to our Maintenance contract team who will be able to assist you.
              </div>
              <EuiSpacer size="s" />
              <div>Please hold the line whilst I get in contact with them.</div>
            </EuiText>
          </Callout>

          <EuiSpacer />

          <Callout type="note">
            <EuiText>
              <ol>
                <li>
                  Transfer through to <strong>Ellie</strong> (DSR)
                </li>
                <li>
                  If no answer, then to <strong>Lauren</strong> (DSR)
                </li>
                <li>
                  If no answer, then to <strong>Sharon</strong> (DSR)
                </li>
              </ol>
            </EuiText>
          </Callout>

          <EuiSpacer />

          <SingleSelectField
            question={notesGenerator.getQuestion('dsrAdviseToBook')}
            options={[
              {
                id: YesNoValue.Yes,
                label: 'Yes, DSR requested I book this job'
              },
              {
                id: YesNoValue.No,
                label: 'No, DSR will handle'
              }
            ]}
            answer={dsrAdviseToBook}
            changeAnswer={(dsrAdviseToBook) => updateResult({ dsrAdviseToBook })}
          />
        </>
      )}
      <HVACRoofAccessQuestion updateResults={updateResult} isCommercial={isCommercial} roofAccess={roofAccess} />
      {isOutOfScope && (
        <>
          <EuiButton
            color="warning"
            onClick={() =>
              workflowContext.abort({
                abortReason: EnquiryAbortReason.CustomerWasTransferredToAnotherPerson,
                notes: `Customer was enquiring about HVAC Maintenance`
              })
            }
          >
            End call
          </EuiButton>
        </>
      )}

      {requirements && (
        <>
          <EuiSpacer />
          <Callout type="note">{requirements.attributes?.map((r) => r.attributeId).join(', ')}</Callout>
        </>
      )}

      {!isOutOfScope && (
        <>
          <EuiSpacer />
          <EuiButton disabled={!canComplete} onClick={() => onNext()}>
            Next
          </EuiButton>
        </>
      )}
    </TopicWorkflowAction>
  )
}
