import { useQuery } from '@apollo/client'
import { EuiFlexGroup, EuiFlexItem, EuiLoadingSpinner, EuiSpacer, EuiSwitch } from '@elastic/eui'
import { compact } from 'lodash-es'
import { Fragment, useEffect, useState } from 'react'
import { AllMediaItemsDocument, OnPublishMediaItemUpdatedForCustomerDocument } from '../api/generated-types'
import { PaginatedMediaItemList } from '../media/paginated-media-item-list'
import createPersistedState from '../use-persisted-state'

const useSandbox = createPersistedState<boolean>('sandbox')
const useMediaItemWidth = createPersistedState<number>('media-item-width')

interface CustomerMediaProps {
  customerId: string
}

export const CustomerMedia = ({ customerId }: CustomerMediaProps) => {
  const [sandbox] = useSandbox(false)
  const [itemWidth, setItemWidth] = useMediaItemWidth(120)
  const [pageNumber, setPageNumber] = useState<number>(0)
  const [viewDeletedPhotos, setViewDeletedPhotos] = useState(false)
  const [showJobNumber, setShowJobNumber] = useState(true)
  const size = 100
  const from = size * pageNumber
  const tenantId = sandbox ? 'fallonsolutions-sandbox' : 'fallonsolutions'

  const { data, loading, subscribeToMore } = useQuery(AllMediaItemsDocument, {
    variables: {
      input: {
        customerIds: [customerId],
        tenantId,
        includeDeleted: true
      },
      from,
      size
    },
    fetchPolicy: 'cache-and-network'
  })

  useEffect(() => {
    const subscriptionVariables = {
      customerId,
      tenantId
    }

    const unsubscribeToPublishMediaItemUpdated = subscribeToMore({
      document: OnPublishMediaItemUpdatedForCustomerDocument,
      variables: subscriptionVariables
    })

    return () => {
      unsubscribeToPublishMediaItemUpdated()
    }
  }, [subscribeToMore, data, customerId, tenantId])
  const mediaItems = compact(data?.allMediaItems.results ?? []).filter((m) => !m.deleted)
  const count = Math.min(data?.allMediaItems.count ?? 0, 10000) // 10000 is the max allowed by esScroll
  const totalPages = Math.ceil(count / size)
  const changeViewDeletedMediaToggle = () => {
    setViewDeletedPhotos(!viewDeletedPhotos)
  }
  return (
    <Fragment>
      <EuiSpacer />
      {loading ? (
        <EuiLoadingSpinner />
      ) : (
        <>
          <EuiFlexGroup direction="column" gutterSize="m">
            <EuiFlexItem grow={false}>
              <EuiSwitch
                checked={showJobNumber}
                label="Show job number"
                onChange={() => setShowJobNumber(!showJobNumber)}
              />
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <PaginatedMediaItemList
                mediaItemWidth={itemWidth}
                setMediaItemWidth={setItemWidth}
                showJobLink={showJobNumber}
                mediaItems={mediaItems}
                totalMediaItemCount={count}
                totalPages={totalPages}
                activePage={pageNumber}
                setActivePage={setPageNumber}
                enableEditing={true}
                viewingDeletedMedia={viewDeletedPhotos}
                viewDeletedStateChanged={changeViewDeletedMediaToggle}
              />
            </EuiFlexItem>
          </EuiFlexGroup>
        </>
      )}
    </Fragment>
  )
}
