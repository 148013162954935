import { QuestionDefinitions, getNotesGenerator } from '../../helpers/notes-generator'
import { TopicActionResult } from '../action-topic-model'

export interface TopicDownpipeActionResult extends TopicActionResult {
  propertyLevels?: PropertyLevels
  issue?: DownpipeIssue
  additionalNotes?: string
}

export enum DownpipeIssue {
  Repair = 'Repair',
  Rusted = 'Rusted',
  Broken = 'Broken',
  Damaged = 'Damaged',
  Leaking = 'Leaking',
  Blocked = 'Blocked',
  ConnectToStormwater = 'Connect to stormwater',
  LocationOfStormwaterForDownpipes = 'Location of stormwater for downpipes'
}

export enum PropertyLevels {
  SingleStorey = 'Single storey',
  DoubleStorey = 'Double storey'
}

const questionDefinitions: QuestionDefinitions<TopicDownpipeActionResult> = {
  propertyLevels: {
    question: 'Is this a single or double storey?',
    statement: 'Property levels:'
  },
  issue: {
    question: 'What is the issue?',
    statement: 'Issue:'
  },

  additionalNotes: {
    question: 'Can you please elaborate on the issue?',
    statement: 'Additional notes:'
  }
}

export const downpipeNotesGenerator = getNotesGenerator(questionDefinitions)
