import { ScheduleEventStatusType } from '../api/generated-types'

export const colorForScheduleEventStatusType = (status: ScheduleEventStatusType | undefined | null): string => {
  switch (status ?? '') {
    case ScheduleEventStatusType.Completed:
      return '#BEC7CA'
    case ScheduleEventStatusType.Dispatched:
      return '#BD8AF1'
    case ScheduleEventStatusType.EnRoute:
      return '#467CE5'
    case ScheduleEventStatusType.OnSite:
      return '#84E6EC'
    case ScheduleEventStatusType.InProgress:
      return '#63DD94'
    case ScheduleEventStatusType.Pending:
      return '#FFB82F'
    case ScheduleEventStatusType.Acknowledged:
      return '#F890ED'
    case ScheduleEventStatusType.WaitingForAllocation:
      return '#C4C4C4'
    default:
      return '#BEC7CA'
  }
}
