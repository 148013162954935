import { decamelise } from '../../../common/utils'
import { TopicActionResult } from '../action-topic-model'
import { YesNoValue } from '../common/common-enums'

export interface TopicHVACServiceActionResult extends TopicActionResult {
  hasIssue?: YesNoValue
  hvacType?: ACType
  brand?: ACBrand
  brandOther?: string
  serviceLevel?: ServiceLevel
  unitLocations?: string
  unitCount?: string
  outdoorUnitLocations?: string
  manHoleAccess?: YesNoValue
  lastServiceDate?: string
  unitAge?: string
  doesFurnitureNeedMoving?: YesNoValue
  considerReplacement?: YesNoValue
  replacementType?: ACType
  roofAccess?: YesNoValue
}

export enum ACType {
  SplitSystemOrMultiHead = 'SplitSystemOrMultiHead',
  DuctedOrCassette = 'DuctedOrCassette',
  WindowBox = 'WindowBox',
  Car = 'Car',
  Portable = 'Portable'
}

export const acTypeOptions = [
  { id: ACType.SplitSystemOrMultiHead, label: decamelise('SplitSystemOrMultiHead') },
  { id: ACType.DuctedOrCassette, label: decamelise('DuctedOrCassette') },
  { id: ACType.WindowBox, label: decamelise('WindowBox') },
  { id: ACType.Car, label: decamelise('Car') },
  { id: ACType.Portable, label: decamelise('Portable') }
]

export enum ServiceLevel {
  Clean = 'Clean',
  Report = 'Report'
}

export const serviceLevelOptions = [
  { id: ServiceLevel.Clean, label: 'Bag Clean' },
  { id: ServiceLevel.Report, label: 'Bag Clean and Condition Report' }
]

export enum ACBrand {
  Daikin = 'Daikin',
  Fujitsu = 'Fujitsu',
  Kelvinator = 'Kelvinator',
  Mitsubishi = 'Mitsubishi',
  MitsubishiElectric = 'Mitsubishi Electric',
  Panasonic = 'Panasonic',
  Pioneer = 'Pioneer',
  Other = 'Other'
}

export const splitSystemBrandOptions = [
  { id: ACBrand.Daikin, label: 'Daikin' },
  { id: ACBrand.Fujitsu, label: 'Fujitsu' },
  { id: ACBrand.Kelvinator, label: 'Kelvinator' },
  { id: ACBrand.Mitsubishi, label: 'Mitsubishi' },
  { id: ACBrand.MitsubishiElectric, label: 'Mitsubishi Electric' },
  { id: ACBrand.Panasonic, label: 'Panasonic' },
  { id: ACBrand.Pioneer, label: 'Pioneer' },
  { id: ACBrand.Other, label: 'Other' }
]

export const windowBoxReplacementOptions = [
  { id: ACType.SplitSystemOrMultiHead, label: decamelise('SplitSystemOrMultiHead') },
  { id: ACType.WindowBox, label: 'Window Box INSTALL ONLY' }
]

// export enum WindowBoxReplacementType {
//   WindowBox,
//   SplitSystemOrMultiHead
// }
