import { EuiButton, EuiLink, EuiSpacer } from '@elastic/eui'
import { JobSummaryContainer } from '../../jobs/job-summary-container'
import { ContactActionResult } from './action-contact'
import { CustomerAction, CustomerActionResult } from './action-customer'
import { CustomerThreateningLegalOrMediaAction } from './action-customer-threatening-legal-or-media'
import { FirstCallComplaintResolutionAction } from './action-first-call-complaint-resolution'
import { SelectJobAction, SelectJobActionResult } from './action-select-job'
import { TransferToCustomerExperienceManagerAction } from './action-transfer-to-customer-experience-manager'
import { BaseActionInput, BaseActionResult, WorkflowActionProps } from '../workflow-model'

export interface ComplaintActionInput extends BaseActionInput {
  contact?: ContactActionResult
}
export interface ComplaintActionResult extends BaseActionResult {
  customer?: CustomerActionResult
  job?: SelectJobActionResult
  callTransferredToManager?: boolean
  customerThreateningLegalOrMediaAction?: boolean
  firstCallResolutionWorked?: boolean
}

export const ComplaintAction = (props: WorkflowActionProps<ComplaintActionInput, ComplaintActionResult>) => {
  const { onUpdate, result, input } = props
  const { contact } = input
  const { customer, job } = result ?? {}

  const setCustomer = (customer: CustomerActionResult) => onUpdate({ ...result, customer })
  const setJob = (job: SelectJobActionResult) => onUpdate({ ...result, job })
  const onCompleted = () => onUpdate({ ...result, actionCompleted: true })

  const jobId = job?.actionCompleted ? job?.job?.id : undefined
  const customerId = customer?.actionCompleted ? customer?.customer?.id : undefined

  return (
    <>
      <CustomerAction input={{ contact }} result={customer} onUpdate={setCustomer} />
      {customerId && <SelectJobAction input={{ customerId }} result={job} onUpdate={setJob} />}
      {customerId && jobId && (
        <>
          <div className="workflow__detail-wrapper">
            <JobSummaryContainer jobId={jobId} />
            <EuiSpacer />
            <EuiLink href={`/jobs/${jobId}`} target="_blank">
              Open job card for more details
            </EuiLink>
          </div>
          <EuiSpacer />
        </>
      )}

      {customerId && (
        <>
          <FirstCallComplaintResolutionAction
            input={{}}
            result={result}
            onUpdate={(resultFragment) => onUpdate({ ...result, ...resultFragment })}
            onBack={() => console.log('back')}
          />

          {result?.firstCallResolutionWorked === false && (
            <>
              <TransferToCustomerExperienceManagerAction
                input={{ situationRequiringEmergencyServices: false, hasFirstCallResolutionBeenOffered: true }}
                result={result}
                onUpdate={(resultFragment) => onUpdate({ ...result, ...resultFragment })}
                onBack={() => console.log('back')}
              />
            </>
          )}

          <EuiSpacer size="s" />
          <CustomerThreateningLegalOrMediaAction
            input={result ?? {}}
            result={result}
            onUpdate={(resultFragment) => onUpdate({ ...result, ...resultFragment })}
            onBack={() => console.log('back')}
          />

          {(result?.firstCallResolutionWorked === true || result?.callTransferredToManager !== undefined) && (
            <>
              <EuiSpacer />
              <EuiButton fill onClick={onCompleted}>
                I've completed these steps
              </EuiButton>
            </>
          )}
        </>
      )}
    </>
  )
}
