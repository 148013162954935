import { useMutation, useQuery } from '@apollo/client'
import {
  EuiCallOut,
  EuiCheckboxGroup,
  EuiCheckboxGroupOption,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiHorizontalRule,
  EuiLoadingSpinner,
  EuiSpacer,
  EuiSwitch,
  EuiText,
  EuiTitle
} from '@elastic/eui'
import { map } from 'lodash-es'
import {
  ContactExpandedFragment,
  SearchMarketingSubscriptionTypesDocument,
  SetContactMarketingGlobalOptOutDocument,
  TransactionalPreferenceStatus
} from '../api/generated-types'
import { makeHumanReadable } from '../common/utils'
import { ContactMarketingSubscriptionView } from './contact-marketing-subscription'

export interface ContactCommunicationPreferencesProps {
  contact: ContactExpandedFragment
}

export const ContactCommunicationPreferences = ({ contact }: ContactCommunicationPreferencesProps) => {
  const subscriptions = contact.communicationPreferences?.marketing?.subscriptions ?? []
  const transactionalPreferences = map(contact.communicationPreferences?.transactional ?? {}, (value, key) => ({
    preference: key,
    status: value
  }))

  const { data, loading, error } = useQuery(SearchMarketingSubscriptionTypesDocument, {
    variables: {
      input: {}
    }
  })

  const [setContactMarketingGlobalOptOut] = useMutation(SetContactMarketingGlobalOptOutDocument)

  const handleOptOutChange = async () => {
    await setContactMarketingGlobalOptOut({ variables: { input: { contactId: contact.id } } })
  }

  const transactionalPreferenceKeys = ['appointmentReminders']

  const transactionalPreferenceOptions: EuiCheckboxGroupOption[] = transactionalPreferenceKeys.map((key) => ({
    id: key,
    label: makeHumanReadable(key)
  }))
  const activeTransactionalPreferenceMap = transactionalPreferences.reduce((result, preference) => {
    return {
      ...result,
      [preference.preference]: preference.status === TransactionalPreferenceStatus.OptedIn
    }
  }, {})

  const subscriptionTypes = data?.searchMarketingSubscriptionTypes.results ?? []

  const onChangeTransactionalPreference = (id: string) => {
    console.log('transactional preference changed', id)
  }

  const subscriptionForSubscriptionType = (subscriptionTypeId: string) =>
    subscriptions.find((s) => s.subscriptionTypeId === subscriptionTypeId)

  return (
    <>
      <EuiTitle size="xxs">
        <h3>Marketing</h3>
      </EuiTitle>
      <EuiSpacer size="s" />

      <EuiFormRow
        label="Global opt-out"
        helpText="This is irreversible unless the contact opts back in themselves via Hubspot web form"
        fullWidth
      >
        <EuiSwitch
          label="Opt-out of all marketing "
          checked={contact.communicationPreferences?.marketing?.globalOptOut === true}
          onChange={handleOptOutChange}
        />
      </EuiFormRow>

      <EuiFormRow label="Subscriptions" fullWidth>
        {loading ? (
          <EuiLoadingSpinner />
        ) : error ? (
          <EuiCallOut title="Error loading subscriptions" color="danger" size="s">
            {error.message}
          </EuiCallOut>
        ) : (
          <>
            <EuiSpacer size="s" />
            <EuiFlexGroup direction="column" gutterSize="m">
              {subscriptionTypes.map((subscriptionType) => (
                <EuiFlexItem grow={false} key={subscriptionType.id}>
                  <ContactMarketingSubscriptionView
                    contactId={contact.id}
                    subscriptionType={subscriptionType}
                    subscription={subscriptionForSubscriptionType(subscriptionType.id)}
                  />
                </EuiFlexItem>
              ))}
            </EuiFlexGroup>
          </>
        )}
      </EuiFormRow>
      <EuiSpacer />
      <EuiCallOut color="warning" title="Note: collateral changes apply" size="s">
        <EuiText size="s">
          <ul>
            <li>Opt in to Email → Opt in to everything</li>
            <li>Opt out of Direct Mail → Opt out of everything </li>
          </ul>
        </EuiText>
      </EuiCallOut>

      <EuiHorizontalRule />

      <EuiTitle size="xxs">
        <h3>Transactional</h3>
      </EuiTitle>
      <EuiSpacer size="s" />

      <EuiCheckboxGroup
        options={transactionalPreferenceOptions}
        idToSelectedMap={activeTransactionalPreferenceMap}
        onChange={(id) => onChangeTransactionalPreference(id)}
      />
    </>
  )
}
