export interface AudioPlayerProps {
  url: string
  autoplay?: boolean
}

export const AudioPlayer = ({ url, ...props }: AudioPlayerProps) => {
  const autoplay = props.autoplay ?? false
  return (
    <audio controls autoPlay={autoplay}>
      <source src={url} type="audio/mpeg" />
    </audio>
  )
}
