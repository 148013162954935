import { AdditionalTradeActionResult } from '../workflow/actions/action-additional-trade'
import { BookingWrapUpActionResult } from '../workflow/actions/wrap-up/action-booking-wrapup'
import { JobCloseActionResult } from '../workflow/actions/action-job-close'
import { JobSummaryActionResult } from '../workflow/actions/action-job-summary'
import { NewJobActionResult } from '../workflow/actions/action-new-job'

export interface SkipWrapUpSectionsParams {
  onUpdate: (result: NewJobActionResult) => void
  result: NewJobActionResult | undefined
}
export const skipWrapUpSections = (params: SkipWrapUpSectionsParams) => {
  const { onUpdate, result } = params

  const jobClose: JobCloseActionResult = {
    actionCompleted: true
  }
  const bookingWrapUp: BookingWrapUpActionResult = {
    actionCompleted: true
  }
  const jobSummary: JobSummaryActionResult = {
    actionCompleted: true
  }

  const additionalTrade: AdditionalTradeActionResult = {
    actionCompleted: true
  }
  onUpdate({ ...result, jobClose, bookingWrapUp, jobSummary, additionalTrade })
}
