import React, { useContext } from 'react'
import { Permissions, UserAuthFragment, UserFragment } from '../api/generated-types'
import { UserAttributeState } from '../user-attributes/user-attributes'
import { AuthorizationLoaderContext } from './authorization-context'
import { IIAMUtils } from './iam-utils'
import { SessionState } from './session'
import { SessionLoaderContext } from './session-context'

// Combination of Authenticated and Authorized States into a convenient clear syncronous state for front-end

export enum AuthorizeError {
  NotAuthorized = 'not-authorized'
}
export interface AuthorizeResult {
  ok: boolean
  error?: AuthorizeError
}

export type ActionType = Permissions

//actionType: CreateJobMutation, variables: {job: Job, customer: Customer}

//action types require different subjects and types ot evaluate access
// export interface ActionType<Variables = {
//   [key: string]: any;
// }> extends DocumentNode {
//   /**
//    * This type is used to ensure that the variables you pass in to the query are assignable to Variables
//    * and that the Result is assignable to whatever you pass your result to. The method is never actually
//    * implemented, but the type is valid because we list it as optional
//    */
//   __apiType?: (variables: Variables) => Result;
// }
// /**
// * Helper for extracting a TypeScript type for operation variables from a TypedDocumentNode.
// * @example
// * const myQuery = { ... }; // TypedDocumentNode<R, V>
// * type VariablesType = ResultOf<typeof myQuery>; // Now it's V
// */
// export declare type VariablesOf<T> = T extends TypedDocumentNode<infer ResultType, infer VariablesType> ? VariablesType : never;

const authorizeFirstPass = (_user: UserFragment) => (action: ActionType) => {
  switch (action) {
    default:
      return { ok: false, error: AuthorizeError.NotAuthorized }
  }
}

export interface IAuthenticatedContext {
  user: SessionState
  userFragment: UserAuthFragment
  userAttributes: UserAttributeState
  developerFeatures: boolean
  iamUtils: IIAMUtils
  signOut: () => void
  authorize: (action: ActionType) => AuthorizeResult
}

export const AuthenticatedContext = React.createContext<IAuthenticatedContext | undefined>(undefined)

export const useAuthenticated = () => {
  const context = useContext(AuthenticatedContext)
  if (!context) {
    throw new Error('needs AuthContext as provider')
  }
  return context
}

// Temporary approach to bridge redux to new context interface
export interface CreateAuthenticatedContextProps {
  session: SessionLoaderContext
  authorization: AuthorizationLoaderContext
}

export const createAuthenticatedContext = ({ session, authorization }: CreateAuthenticatedContextProps) => {
  if (
    !session.data?.user ||
    !authorization?.data?.userFragment ||
    !authorization?.data.userAttributes ||
    !authorization?.data.iamUtils ||
    !authorization?.data.iamUtils.ready
  ) {
    throw new Error('unable to create authenticated context')
  }
  const authenticatedContext: IAuthenticatedContext = {
    user: session.data?.user,
    userAttributes: authorization?.data.userAttributes,
    userFragment: authorization?.data.userFragment,
    developerFeatures: authorization?.data.userFragment?.permissions?.developerFeatures === true,
    iamUtils: authorization?.data.iamUtils,
    authorize: authorizeFirstPass(authorization?.data.userFragment),
    signOut: () => {
      session.data?.signOut()
      authorization?.data?.iamUtils?.signOut()
    }
  }
  return authenticatedContext
}
