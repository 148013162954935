import { EuiFlexGroup, EuiFlexItem, EuiIcon, EuiLoadingSpinner, IconSize, IconType } from '@elastic/eui'
import { AuditCheckResultType } from '../api/generated-types'
import '../static/css/audit-check.css'
import FailIcon from '../static/images/audit-check-fail.svg'
import GoodIcon from '../static/images/audit-check-good.svg'
import PassIcon from '../static/images/audit-check-pass.svg'

export enum AuditCheckResultSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large'
}

export interface AuditCheckResultProps {
  result: AuditCheckResultType
  selected: boolean
  editable: boolean
  loading?: boolean
  size?: AuditCheckResultSize | undefined
  onClick?: () => void
}

export const AuditCheckResult = (props: AuditCheckResultProps) => {
  const { result, selected, editable, onClick, loading } = props
  const size = props.size ?? AuditCheckResultSize.Large
  const iconSize: IconSize = size === AuditCheckResultSize.Small ? 'm' : 'l'

  let iconType: IconType
  let color = '#ffffff'
  switch (result) {
    case AuditCheckResultType.Good:
      iconType = GoodIcon
      break
    case AuditCheckResultType.Pass:
      iconType = PassIcon
      break
    case AuditCheckResultType.Fail:
      iconType = FailIcon
      break
    case AuditCheckResultType.NotApplicable:
    case AuditCheckResultType.None:
    default:
      color = '#ACAFB9'
      iconType = 'minus'
  }

  const handleOnClick = () => {
    if (!loading && editable && onClick) {
      onClick()
    }
  }

  return (
    <EuiFlexGroup
      className={`audit-check-result audit-check-result--${size} audit-check-result--${result.toLowerCase()} ${
        selected && 'audit-check-result--active'
      } ${editable && 'audit-check-result--editable'}`}
      alignItems="center"
      justifyContent="center"
      gutterSize="none"
      onClick={handleOnClick}
    >
      <EuiFlexItem grow={false}>
        {selected &&
          (loading ? (
            <EuiLoadingSpinner
              size="m"
              color={{
                border: 'rgba(0,0,0,0.3)',
                highlight: 'rgba(0,0,0,0.3)'
              }}
            />
          ) : (
            <EuiIcon type={iconType} size={iconSize} color={color} />
          ))}
      </EuiFlexItem>
    </EuiFlexGroup>
  )
}
