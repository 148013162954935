import { SingleSelectField } from '../../question-fields/single-select-question-field'
import { NotesGenerator } from '../../helpers/notes-generator'
import { ResultUpdater } from '../../helpers/results-updater'
import { PoolFilterIssue, TopicPoolActionResult } from './action-topic-pool-model'
import { FreeTextQuestionField } from '../../question-fields/free-text-question-field'

export interface PoolFiltersProps {
  result?: TopicPoolActionResult
  notesGenerator: NotesGenerator<TopicPoolActionResult>
  updateResult: ResultUpdater<TopicPoolActionResult>
}

export const PoolFilters = (props: PoolFiltersProps) => {
  const { result, notesGenerator, updateResult } = props
  const { filterIssue, additionalNotes } = result ?? {}
  return (
    <>
      <SingleSelectField
        question={notesGenerator.getQuestion('filterIssue')}
        options={[
          {
            id: PoolFilterIssue.Leaking
          },
          {
            id: PoolFilterIssue.Blocked
          }
        ]}
        answer={filterIssue}
        changeAnswer={(filterIssue) => updateResult({ filterIssue })}
      />

      <FreeTextQuestionField
        question={notesGenerator.getQuestion('additionalNotes')}
        answer={additionalNotes}
        changeAnswer={(additionalNotes) => updateResult({ additionalNotes })}
        rows={3}
      />
    </>
  )
}
