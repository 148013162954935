import { useMutation } from '@apollo/client'
import { includes } from 'lodash-es'
import {
  JobFragment,
  MembershipLevel,
  OptionInvoiceStatusType,
  SetJobMembershipLevelDocument
} from '../api/generated-types'
import { useAuthenticated } from '../auth/authenticated-context'
import { MembershipLevelToggle } from '../jobs/membership-level-toggle'
import '../static/css/member-status.css'

interface MemberStatusProps {
  job: JobFragment
  compressed?: boolean
  label?: string
}

export const getPricingChangesLocked = (job: JobFragment): boolean => {
  const allOptions = (job.optionSheet?.optionGroups ?? []).flatMap((group) => group?.options ?? [])
  return (
    allOptions.filter((option) =>
      includes(
        [OptionInvoiceStatusType.Invoiced, OptionInvoiceStatusType.PartiallyInvoiced],
        option?.invoiceStatus?.status
      )
    ).length > 0
  )
}

export const MemberStatus = ({ job, compressed, label }: MemberStatusProps) => {
  const { userFragment } = useAuthenticated()

  // Fallback to optionSheet.membershipLevel until data has been migrated on old jobs
  const membershipLevel = job.membershipLevel ?? job.optionSheet?.membershipLevel ?? MembershipLevel.None
  const hasSetJobMembershipLevel = userFragment.permissions?.setJobMembershipLevel === true
  const canEditMembershipLevel = hasSetJobMembershipLevel && !getPricingChangesLocked(job)

  const [setMembershipLevel, { loading }] = useMutation(SetJobMembershipLevelDocument, {
    awaitRefetchQueries: true,
    refetchQueries: ['GetJob']
  })

  const onChangeMembershipLevel = (membershipLevel: MembershipLevel) => {
    setMembershipLevel({ variables: { input: { jobId: job.id, membershipLevel } } })
  }

  return (
    <MembershipLevelToggle
      editable={canEditMembershipLevel}
      membershipLevel={membershipLevel}
      onChange={onChangeMembershipLevel}
      loading={loading}
      compressed={compressed}
      label={label}
    />
  )
}
