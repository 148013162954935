export enum ScheduleLayout {
  Regular = 'Regular',
  Compact = 'Compact',
  Nano = 'Nano'
}

export interface ScheduleDisplayConfiguration {
  theme?: ScheduleTheme
  layout?: ScheduleLayout
}

export enum ScheduleTheme {
  Light = 'light',
  Dark = 'dark',
  Classic = 'classic'
}

export interface ScheduleLayoutOptions {
  sidebarWidth: number
  lineHeight: number
  itemHeightRatio: number
  groupTitleHeight: number
}

export interface WindowPosition {
  left?: string | undefined
  right?: string | undefined
  top?: string | undefined
  bottom?: string | undefined
}
