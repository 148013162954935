import { EuiSpacer, EuiText } from '@elastic/eui'
import { Callout } from '../../../../common/callout'

export const InspectionDisclaimer = ({ isInspection }: { isInspection: boolean }) => {
  return (
    <>
      {isInspection && (
        <>
          <Callout type="warning">
            <EuiText>
              <p>
                Please do not book at 7am for inspection jobs where possible, save that time slot for emergency bookings
              </p>
            </EuiText>
          </Callout>
          <EuiSpacer />
        </>
      )}
    </>
  )
}
