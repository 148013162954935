import { useQuery } from '@apollo/client'
import { EuiButton, EuiLink, EuiSpacer, EuiText } from '@elastic/eui'
import { isHubspotEmailEnabled } from '@fallonsolutions/feature-flag-config'
import { uniq } from 'lodash-es'
import { useEffect } from 'react'
import {
  CommunicationChannelSelection,
  CustomerType,
  GetCustomerLinkDocument,
  TradeType
} from '../../../api/generated-types'
import { useApp } from '../../../app/app-context'
import {
  BookingConfirmationPreferenceComponent,
  BookingConfirmationPreferenceDisplayOptions
} from '../../../bookings/booking-confirmation-preference'
import { Callout } from '../../../common/callout'
import { formatPhone, isMobile } from '../../../common/phone'
import { SingleSelectField } from '../../question-fields/single-select-question-field'
import { YesNoValue } from '../../topics/common/common-enums'
import { WorkflowAction } from '../../workflow-action'
import { SelectedCustomer } from '../../workflow-customer-model'
import { BaseActionInput, BaseActionResult, WorkflowActionProps } from '../../workflow-model'
import { toSelectedCustomer } from '../action-contact-customer'
import { CustomerDogQuestion } from './dog-question'

export interface BookingWrapUpActionInput extends BaseActionInput {
  existingCustomer: boolean
  customerType: CustomerType
  customer: SelectedCustomer
  trade: TradeType
  //contact: SelectedContact
  defaultBookingConfirmation?: CommunicationChannelSelection
  bookingConfirmation?: CommunicationChannelSelection | undefined
  reserveNow: boolean
}

export interface BookingWrapUpActionResult extends BaseActionResult {
  paymentTermsAdvised?: boolean
  membershipAdvised?: boolean
  covidCheckStep1?: YesNoValue
  covidCheckStep2?: YesNoValue
  covidCheckPassed?: boolean
  requireCovidProtections?: YesNoValue
  bookingConfirmation?: CommunicationChannelSelection | undefined
  updateCustomerDetails?: boolean
  doYouHaveADog?: YesNoValue
  canYouRestrainYourDog?: YesNoValue
  safetyFollowUp?: YesNoValue
}

// eslint-disable-next-line complexity
export const BookingWrapUpAction = (
  props: WorkflowActionProps<BookingWrapUpActionInput, BookingWrapUpActionResult>
) => {
  const { input, onUpdate, result } = props

  const {
    customerType,
    bookingConfirmation,
    defaultBookingConfirmation,
    customer: selectedCustomer,
    reserveNow,
    trade
  } = input ?? {}
  const {
    covidCheckPassed,
    covidCheckStep1,
    requireCovidProtections,
    actionCompleted,
    safetyFollowUp,
    doYouHaveADog,
    canYouRestrainYourDog
  } = result ?? {}
  const appContext = useApp()
  const hubspotAutoEmailForJobEnabled = isHubspotEmailEnabled(appContext.stage, trade, customerType)

  const setBookingConfirmation = (bookingConfirmation: CommunicationChannelSelection) =>
    onUpdate({ ...result, bookingConfirmation })

  const setActionCompleted = (actionCompleted: boolean) => onUpdate({ ...result, actionCompleted })

  const covidRiskIdentified = covidCheckStep1 === YesNoValue.Yes

  const getCovidCheckPassed = (step1?: YesNoValue) => step1 === YesNoValue.No

  const allStepsCompleted = covidCheckPassed && !!requireCovidProtections

  const hubspotBookingConfirmationEmail = true
  const setShowCustomerCard = (value: boolean) => {
    onUpdate({ ...result, updateCustomerDetails: value })
  }

  const { data: customerQuery } = useQuery(GetCustomerLinkDocument, {
    variables: {
      id: selectedCustomer?.id
    },
    skip: !selectedCustomer?.id
  })

  const customer = customerQuery?.getCustomer ? toSelectedCustomer(customerQuery?.getCustomer) : selectedCustomer

  const contactMobiles = uniq([customer?.phone ?? '', customer?.alternatePhone ?? ''].filter(isMobile))
  const mobilePhoneNumberNotProvided = contactMobiles.length === 0

  //hubspot contacts with email addresses and data quality flag of error or valid
  const hubspotContactEmails = customer?.email ? [customer.email] : []

  const emailNotProvided = hubspotContactEmails.length === 0
  const firstMobileDisplay = formatPhone(contactMobiles[0]) ?? 'not provided'
  useEffect(() => {
    if (bookingConfirmation === undefined) {
      setBookingConfirmation(defaultBookingConfirmation ?? CommunicationChannelSelection.None)
    }
  }, [defaultBookingConfirmation, bookingConfirmation])

  const summary = actionCompleted
    ? `Communicated. ${
        bookingConfirmation === CommunicationChannelSelection.All
          ? 'confirm with ' + firstMobileDisplay + ', ' + hubspotContactEmails.join(', ')
          : 'skip confirmation'
      }`
    : undefined

  const dogQuestionOutcomeIsValid =
    doYouHaveADog === YesNoValue.No || canYouRestrainYourDog === YesNoValue.Yes || safetyFollowUp === YesNoValue.Yes

  const showNextButton = covidRiskIdentified !== true && dogQuestionOutcomeIsValid

  return (
    <div data-test-subj="booking-wrap-up">
      <WorkflowAction
        title="Booking wrap-up"
        value={summary}
        onClickChange={() => setActionCompleted(false)}
        editable={input.editable}
      >
        <Callout type="script" title="Wrap-up">
          <EuiText>
            <div>
              Great, that's been reserved. What will happen from here is I will send you an email and/or sms
              confirmation of your booking.
            </div>
          </EuiText>
        </Callout>
        <EuiSpacer size="m" />
        <>
          {!reserveNow && (
            <Callout type="warning" title="Reserve time on the schedule to enable booking confirmation(s)"></Callout>
          )}
          {hubspotAutoEmailForJobEnabled && hubspotContactEmails.length === 0 && (
            <Callout
              type="warning"
              title="Resolve Primary contact issues to enable email confirmation. Confirm contact details."
            ></Callout>
          )}
          {hubspotAutoEmailForJobEnabled && !customer.canSendTransactionalEmail && (
            <Callout
              type="warning"
              title="Please confirm contact details to ensure booking confirmation email is sent automatically."
            ></Callout>
          )}
          <BookingConfirmationPreferenceComponent
            isDisabled={reserveNow}
            isChecked={bookingConfirmation === CommunicationChannelSelection.All}
            value={bookingConfirmation ?? defaultBookingConfirmation ?? CommunicationChannelSelection.All}
            setCommunicationChannel={setBookingConfirmation}
            mobileNumbers={contactMobiles}
            emailAddresses={hubspotContactEmails}
            hubspotEmailEnabled={(hubspotAutoEmailForJobEnabled && customer.canSendTransactionalEmail) ?? false}
            displayType={BookingConfirmationPreferenceDisplayOptions.SwitchAtEnd}
          />
          <>
            {hubspotBookingConfirmationEmail &&
              (emailNotProvided || mobilePhoneNumberNotProvided) &&
              bookingConfirmation === CommunicationChannelSelection.All && (
                <>
                  <EuiLink onClick={() => setShowCustomerCard(true)}>
                    Please capture{' '}
                    {emailNotProvided && mobilePhoneNumberNotProvided
                      ? 'a mobile number or email address'
                      : emailNotProvided
                        ? 'an email address'
                        : 'a mobile number'}
                  </EuiLink>
                  <EuiSpacer size="m" />
                  <EuiLink onClick={() => setBookingConfirmation(CommunicationChannelSelection.None)}>
                    OR skip sending confirmation
                  </EuiLink>
                </>
              )}
          </>
          <EuiSpacer size="l" />
        </>
        <Callout type="script">
          <EuiText>
            <EuiSpacer size="m" />
            <div>On the day the technician will call you 15-30 mins prior to arriving.</div>
            <EuiSpacer size="m" />
            <div>
              Payment is required on the day, we accept cash cheque and card. Card fees apply to both credit cards and
              debit cards.
            </div>
            <EuiSpacer size="m" />
            <div>Our technicians arrive with the intention of completing the work</div>
            <EuiSpacer size="m" />
            {customerType === CustomerType.Domestic && (
              <>
                <div>Our technician will also conduct a free home safety inspection</div>
                <EuiSpacer size="m" />
              </>
            )}
          </EuiText>
        </Callout>
        <EuiSpacer />

        <SingleSelectField
          question="Do you or anyone at your property currently have COVID or are you or anyone at your property quarantining?"
          options={[
            {
              id: YesNoValue.Yes,
              label: 'Yes, have COVID or quarantining or close contact is',
              icon: 'bug'
            },
            {
              id: YesNoValue.No,
              label: 'No',
              icon: 'cross'
            }
          ]}
          answer={covidCheckStep1}
          changeAnswer={(covidCheckStep1) =>
            onUpdate({
              ...result,
              covidCheckStep1,
              covidCheckPassed: getCovidCheckPassed(covidCheckStep1)
            })
          }
        />

        {covidRiskIdentified === true && (
          <>
            <EuiSpacer />
            <Callout type="warning">
              <EuiText>
                <div>I’m sorry but we are unable to attend site and help you.</div>
                <EuiSpacer size="m" />
                <div>Please contact us back once you and everyone on the property has tested negative</div>
              </EuiText>
            </Callout>
          </>
        )}

        {covidCheckStep1 === YesNoValue.No && (
          <>
            <EuiSpacer />
            <SingleSelectField
              question="Do you require our technician to use covid precautions for your protection (eg masks)?"
              showQuestion={true}
              options={[
                {
                  id: YesNoValue.Yes,
                  label: 'Yes',
                  icon: 'check'
                },
                {
                  id: YesNoValue.No,
                  label: 'No',
                  icon: 'cross'
                }
              ]}
              answer={requireCovidProtections}
              changeAnswer={(requireCovidProtections) =>
                onUpdate({
                  ...result,
                  requireCovidProtections
                })
              }
            />
          </>
        )}

        {!!requireCovidProtections && (
          <CustomerDogQuestion
            result={result}
            onUpdate={onUpdate}
            doYouHaveADog={doYouHaveADog}
            canYouRestrainYourDog={canYouRestrainYourDog}
            safetyFollowUp={safetyFollowUp}
          />
        )}

        {showNextButton && (
          <>
            <EuiSpacer size="m" />
            <EuiButton disabled={!allStepsCompleted} onClick={() => setActionCompleted(true)}>
              Next
            </EuiButton>
            <EuiSpacer />
          </>
        )}
      </WorkflowAction>
    </div>
  )
}
