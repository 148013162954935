import { EuiFlexGroup, EuiFlexItem } from '@elastic/eui'
import { getTagColorValue } from '@fallonsolutions/tag'
import { TagFragment } from '@fallonsolutions/types'
import '../../static/css/tag.css'

export interface TagProps {
  tag: TagFragment
}

export const Tag = ({ tag }: TagProps) => {
  const { light, lightBorder } = getTagColorValue(tag.color)

  return (
    <div
      className="tag"
      style={{ backgroundColor: light, ...(lightBorder ? { border: `1px solid ${lightBorder}` } : {}) }}
    >
      <EuiFlexGroup gutterSize="s" alignItems="center">
        <EuiFlexItem grow={false} style={{ fontSize: '16px' }}>
          {tag.emoji}
        </EuiFlexItem>
        <EuiFlexItem grow={false}>{tag.label}</EuiFlexItem>
      </EuiFlexGroup>
    </div>
  )
}
