import { dateConfig } from '@fallonsolutions/date'
import { take } from 'lodash-es'
import { DateTime } from 'luxon'
import createPersistedState from '../use-persisted-state'

const useHistoryItems = createPersistedState<HistoryItem[]>('historyItems')

export enum HistoryItemType {
  Job = 'Job',
  Customer = 'Customer',
  Property = 'Property',
  Invoice = 'Invoice',
  User = 'User'
}

export interface HistoryItem {
  id: string
  label: string
  type: HistoryItemType
  date: string
}

export interface AddHistoryItemProps {
  id: string
  label: string
  type: HistoryItemType
}

export interface UsePlatformHistoryResult {
  historyItems: HistoryItem[] | undefined
  addHistoryItem: (props: AddHistoryItemProps) => void
  clearHistoryItems: () => void
}

export const usePlatformHistory = (): UsePlatformHistoryResult => {
  const [historyItems, setHistoryItems] = useHistoryItems([])
  const maxHistory = 30

  function addHistoryItem(props: any) {
    console.log('adding history item', JSON.stringify(props))
    const { id, label, type } = props
    const date = DateTime.now().setZone(dateConfig.defaultTimezone).toISO()
    const newItem: HistoryItem = { id, label, type, date }
    setHistoryItems(
      (items: any[] | undefined) =>
        take([newItem].concat((items || []).filter((h: HistoryItem) => h.id !== id)), maxHistory) as any
    )
  }

  const clearHistoryItems = () => {
    setHistoryItems([])
  }

  return { historyItems, addHistoryItem, clearHistoryItems }
}

export const iconForHistoryItem = (type: HistoryItemType): string => {
  switch (type) {
    case HistoryItemType.Job:
      return 'wrench'
    case HistoryItemType.Customer:
      return 'user'
    case HistoryItemType.User:
      return 'user'
    case HistoryItemType.Invoice:
      return 'document'
    case HistoryItemType.Property:
      return 'home'
    default:
      return 'empty'
  }
}

export const urlForItem = (id: string, type: HistoryItemType): string => {
  switch (type) {
    case HistoryItemType.Job:
      return `/jobs/${id}`
    case HistoryItemType.Customer:
      return `/customers/${id}`
    case HistoryItemType.User:
      return `/users/${id}`
    case HistoryItemType.Invoice:
      return `/invoices/${id}`
    case HistoryItemType.Property:
      return `/properties/${id}`
    default:
      return '/'
  }
}
