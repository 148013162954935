import { useCallback, useEffect, useRef, useState } from 'react'
import useEventListener from '@use-it/event-listener'

import createGlobalState from './createGlobalState'

const usePersistedState = (
  initialState: any,
  key: string,
  operations: { get: (key: string, state: any) => any; set: (key: string, state: any) => void }
) => {
  const { get, set } = operations
  const globalState = useRef<{ deregister(): void; emit(value: any): void } | null>(null)
  const [state, setState] = useState(() => get(key, initialState))
  //const [unmounted, setUnmounted] = useState(false)

  // subscribe to `storage` change events
  useEventListener('storage', (event) => {
    const k = (event as any).key
    const newValue = (event as any).newValue
    //console.log('storage event handlers', key, k, newValue, unmounted)
    // if (unmounted) {
    //   console.log('WALK AWAY, the component is already unmounted', key)
    //   return
    // }
    if (k === key) {
      const newState = JSON.parse(newValue)
      if (state !== newState) {
        setState(newState)
      }
    }
  })

  // only called on mount
  useEffect(() => {
    //console.log('Mounting persisted state', key)
    // register a listener that calls `setState` when another instance emits
    if (globalState.current) {
      //console.log('Update: globalState.current.deregister', key)
      globalState.current.deregister()
    }
    globalState.current = createGlobalState(key, setState, initialState)

    return () => {
      //console.log('Unmounting persisted state', key)
      //setUnmounted(true)
      //console.log('Unmounting: globalState.current.deregister', key)
      globalState.current?.deregister()
      globalState.current = null
    }
  }, [initialState, key])

  const persistentSetState = useCallback(
    (newState: any) => {
      const newStateValue = typeof newState === 'function' ? newState(state) : newState
      // persist to localStorage
      set(key, newStateValue)
      setState(newStateValue)
      // inform all of the other instances in this tab
      globalState.current?.emit(newState)
    },
    [state, set, key]
  )

  return [state, persistentSetState]
}

export default usePersistedState
