import { compact } from 'lodash-es'
import { EnquiryTypeReference } from '../workflow/workflow-model'
import {
  campaignIdParam,
  contactIdParam,
  customerLocationIdParam,
  jobIdParam,
  webFormIdParam
} from '../workflow/workflow-new-enquiry-wrapper'

export interface createNewJobUrlParams {
  enquiryId: string
  customerId?: string
  jobId?: string | null
  contactId?: string | undefined | null
  customerLocationId?: string | undefined | null
  webFormId?: string | undefined | null
  campaignId?: string | undefined | null
}

export const createNewJobUrl = (params: createNewJobUrlParams) => {
  const { enquiryId, customerId, customerLocationId, contactId, jobId } = params
  const enquiryTypeParam = `enquiryType=${EnquiryTypeReference.NewBooking}`
  const contactParam = contactId ? `${contactIdParam}=${contactId}` : undefined
  const jobParam = jobId ? `${jobIdParam}=${jobId}` : undefined
  const webFormParam = params.webFormId ? `${webFormIdParam}=${params.webFormId}` : undefined
  const campaignParam = params.campaignId ? `${campaignIdParam}=${params.campaignId}` : undefined
  const customerLocationParam = customerLocationId ? `${customerLocationIdParam}=${customerLocationId}` : undefined
  const urlParameters = compact([
    enquiryTypeParam,
    contactParam,
    jobParam,
    customerLocationParam,
    webFormParam,
    campaignParam
  ]).join('&')
  const pathComponent = ['workflow', enquiryId, customerId].join('/')
  const url = `/${pathComponent}?${urlParameters}`
  return url
}
