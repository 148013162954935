import React from 'react'
import { ContactFragment, CustomerFragment } from '../api/generated-types'
import { EuiAvatar } from '@elastic/eui'

export enum ContactType {
  Main = 'Main',
  Billing = 'Billing'
}

interface CustomerLinkProps {
  customer: CustomerFragment | null | undefined
  contactType?: ContactType
}

// Useful for displaying link to customer in table rows or smaller spaces
const CustomerLink = (props: CustomerLinkProps) => {
  const { customer } = props
  const contactType = props.contactType ?? ContactType.Main

  if (!customer) {
    return <span>No customer</span>
  }

  let contact: ContactFragment
  switch (contactType) {
    case ContactType.Main:
      contact = customer.mainContact as ContactFragment
      break
    case ContactType.Billing:
      contact = customer.billingContact as ContactFragment
  }

  let name: string
  if (contact?.detail?.fullName) {
    name = contact.detail.fullName
  } else {
    name = 'No name'
  }

  return (
    <a href={`/customers/${customer.id}`} target="_blank" rel="noopener noreferrer">
      <EuiAvatar size="s" name={name} />
      <span style={{ marginLeft: '7px' }}>
        {name} ({customer.number})
      </span>
    </a>
  )
}

export default CustomerLink
