import { EuiButton, EuiSpacer } from '@elastic/eui'
import { JobType, TradeType } from '../../../api/generated-types'
import { QuestionDefinitions, getNotesGenerator } from '../../helpers/notes-generator'
import { getResultUpdater } from '../../helpers/results-updater'
import { FreeTextQuestionField } from '../../question-fields/free-text-question-field'
import { WorkflowActionProps } from '../../workflow-model'
import { TopicActionInput, TopicActionResult } from '../action-topic-model'
import { TopicWorkflowAction } from '../action-topic-view'

export interface TopicPlumbingWaterFilterComponentsRepairActionResult extends TopicActionResult {
  faultDescription?: string
  lastFilterChangeDate?: string
}

const questionDefinitions: QuestionDefinitions<TopicPlumbingWaterFilterComponentsRepairActionResult> = {
  faultDescription: {
    question: 'What issue are you having the with set up?'
  },
  lastFilterChangeDate: {
    question: 'When were the filters last changed?'
  }
}
const notesGenerator = getNotesGenerator(questionDefinitions)

export const TopicPlumbingWaterFilterComponentsRepairAction = (
  props: WorkflowActionProps<TopicActionInput, TopicPlumbingWaterFilterComponentsRepairActionResult>
) => {
  const { result, input, onUpdate } = props
  const { faultDescription, lastFilterChangeDate } = result ?? {}

  const onNext = () =>
    onUpdate({
      ...result,
      actionCompleted: true,
      trade: TradeType.Plumbing,
      category: 'WaterFilter',
      type: JobType.Service,

      workRequiredNotes: generatedNotes,
      requirements: {
        attributes: [{ attributeId: 'GeneralPlumbing' }]
      }
    })

  const generatedNotes = notesGenerator.generateNotes(result ?? {})
  const updateResult = getResultUpdater(result ?? {}, onUpdate).updateResult

  const canComplete = !!faultDescription && !!lastFilterChangeDate

  return (
    <TopicWorkflowAction input={input} onUpdate={onUpdate} result={result}>
      <FreeTextQuestionField
        question={notesGenerator.getQuestion('faultDescription')}
        answer={faultDescription}
        rows={3}
        changeAnswer={(faultDescription) => updateResult({ faultDescription })}
      />
      <FreeTextQuestionField
        question={notesGenerator.getQuestion('lastFilterChangeDate')}
        answer={lastFilterChangeDate}
        changeAnswer={(lastFilterChangeDate) => updateResult({ lastFilterChangeDate })}
      />

      <EuiSpacer />
      <EuiButton disabled={!canComplete} onClick={() => onNext()}>
        Next
      </EuiButton>
    </TopicWorkflowAction>
  )
}
