import { BaseActionInput, BaseActionResult, WorkflowActionProps } from '../workflow-model'
import { WorkflowAction } from '../workflow-action'
import { EuiButton, EuiLink, EuiSpacer, EuiText } from '@elastic/eui'
import { Callout } from '../../common/callout'
import { CustomerAction, CustomerActionResult } from './action-customer'
import { SelectJobAction, SelectJobActionResult } from './action-select-job'
import { ContactActionResult } from './action-contact'
import { SelectAppointmentAction, SelectAppointmentActionResult } from './action-select-appointment'
import { JobSummaryContainer } from '../../jobs/job-summary-container'

export interface ConfirmJobActionInput extends BaseActionInput {
  contact?: ContactActionResult
}

export interface ConfirmJobActionResult extends BaseActionResult {
  customer?: CustomerActionResult
  job?: SelectJobActionResult
  appointment?: SelectAppointmentActionResult
}

export const ConfirmJobAction = (props: WorkflowActionProps<ConfirmJobActionInput, ConfirmJobActionResult>) => {
  const { input, result, onUpdate } = props
  const { contact } = input ?? {}
  const { job, appointment } = result ?? {}
  const customer = result?.customer

  const setCustomer = (customer: CustomerActionResult | undefined) => onUpdate({ ...result, customer })
  const setJob = (job: SelectJobActionResult | undefined) => onUpdate({ ...result, job })
  const setAppointment = (appointment: SelectAppointmentActionResult | undefined) =>
    onUpdate({ ...result, appointment })
  const setActionCompleted = (actionCompleted: boolean | undefined) => onUpdate({ ...result, actionCompleted })

  const jobId = job?.actionCompleted ? job?.job?.id : undefined
  const customerId = customer?.actionCompleted ? customer?.customer?.id : undefined

  return (
    <WorkflowAction title="Confirm job date/time">
      <Callout type="script">
        <EuiText>TODO: Tristan to provide content for this flow</EuiText>
      </Callout>
      <EuiSpacer />

      <CustomerAction input={{ contact }} result={customer} onUpdate={setCustomer} />
      {customerId && <SelectJobAction input={{ customerId }} result={job} onUpdate={setJob} />}
      {job?.actionCompleted && job.job && (
        <SelectAppointmentAction input={{ jobId: jobId! }} result={appointment} onUpdate={setAppointment} />
      )}

      {appointment?.actionCompleted && (
        <>
          <EuiSpacer />
          <div className="workflow__detail-wrapper">
            {jobId && (
              <>
                <JobSummaryContainer jobId={jobId} />
                <EuiSpacer />
                <EuiLink href={`/jobs/${jobId}`} target="_blank">
                  Open job card for more details
                </EuiLink>
              </>
            )}
          </div>
          <EuiSpacer />
          <EuiButton
            fill
            disabled={!appointment?.actionCompleted}
            color="primary"
            onClick={() => setActionCompleted(true)}
          >
            I've completed these steps
          </EuiButton>
        </>
      )}
    </WorkflowAction>
  )
}
