import { BaseActionInput, BaseActionResult, WorkflowActionProps } from '../workflow-model'
import { EuiButton, EuiSpacer, EuiText } from '@elastic/eui'
import { WorkflowAction } from '../workflow-action'
import { Callout } from '../../common/callout'
import { MakePaymentAction, MakePaymentActionResult } from './action-make-payment'
import { CustomerAction, CustomerActionResult } from './action-customer'
import { SelectInvoiceAction, SelectInvoiceActionResult } from './action-select-invoice'
import { ContactActionResult } from './action-contact'
import { InvoiceSummaryContainer } from '../../invoices/invoice-summary-container'

export interface PayInvoiceActionInput extends BaseActionInput {
  contact?: ContactActionResult
}

export interface PayInvoiceActionResult extends BaseActionResult {
  introComplete?: boolean
  amountToPayConfirmed?: boolean
  customer?: CustomerActionResult
  invoice?: SelectInvoiceActionResult
  makePaymentResult?: MakePaymentActionResult
}

export const PayInvoiceAction = (props: WorkflowActionProps<PayInvoiceActionInput, PayInvoiceActionResult>) => {
  const { input, onUpdate, result } = props
  const { contact } = input ?? {}
  const { introComplete, amountToPayConfirmed } = result ?? {}
  const customerResult = result?.customer
  const invoiceResult = result?.invoice

  const setIntroComplete = (introComplete: boolean | undefined) => onUpdate({ ...result, introComplete })
  const setCustomer = (customer: CustomerActionResult | undefined) => onUpdate({ ...result, customer })
  const setInvoice = (invoice: SelectInvoiceActionResult | undefined) => onUpdate({ ...result, invoice })
  const setAmountToPayConfirmed = (checked: boolean | undefined) =>
    onUpdate({ ...result, amountToPayConfirmed: checked })

  const customer = customerResult?.actionCompleted ? customerResult.customer : undefined
  const invoice = invoiceResult?.actionCompleted ? invoiceResult.invoice : undefined

  return (
    <>
      <WorkflowAction
        title="Intro"
        value={introComplete ? 'Complete' : undefined}
        onClickChange={() => setIntroComplete(false)}
      >
        <Callout
          type="warning"
          title="If you do not have access to an EFTPOS machine please transfer call to a team member who does"
        />
        <EuiSpacer />
        <Callout type="script">
          <EuiText>
            <div>I'm just going to bring up your account now</div>
          </EuiText>
        </Callout>
        <EuiSpacer size="s" />
        <EuiButton onClick={() => setIntroComplete(true)}>Next</EuiButton>
        <EuiSpacer />
      </WorkflowAction>

      {introComplete && <CustomerAction input={{ contact }} result={customerResult} onUpdate={setCustomer} />}
      {customer && (
        <SelectInvoiceAction input={{ customerId: customer.id }} result={invoiceResult} onUpdate={setInvoice} />
      )}

      {invoice && (
        <>
          <WorkflowAction
            title="Confirm amount to pay"
            value={amountToPayConfirmed ? 'Completed' : undefined}
            onClickChange={() => setAmountToPayConfirmed(undefined)}
          >
            <div className="workflow__detail-wrapper">
              <InvoiceSummaryContainer invoiceId={invoice.id} />
            </div>
            <EuiSpacer />
            <Callout type="script">
              <EuiText>
                <div>Can I confirm how much you want to pay?</div>
              </EuiText>
            </Callout>

            <EuiSpacer size="s" />
            <EuiButton onClick={() => setAmountToPayConfirmed(true)}>Next</EuiButton>
            <EuiSpacer />
          </WorkflowAction>

          {amountToPayConfirmed && (
            <MakePaymentAction
              input={{}}
              result={result?.makePaymentResult}
              onUpdate={(makePaymentResult) => onUpdate({ ...result, makePaymentResult })}
            />
          )}

          {result?.makePaymentResult?.actionCompleted && (
            <>
              <EuiButton fill color="primary" onClick={() => onUpdate({ ...result, actionCompleted: true })}>
                I've completed these steps
              </EuiButton>
            </>
          )}
        </>
      )}
    </>
  )
}
