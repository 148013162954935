import { EuiSpacer, EuiText } from '@elastic/eui'
import { Callout } from '../../../common/callout'
import { NotesGenerator } from '../../helpers/notes-generator'
import { ResultUpdater } from '../../helpers/results-updater'
import { SingleSelectField } from '../../question-fields/single-select-question-field'
import { reset } from '../common/common-utils'
import { TopicPlumbingDrainActionResult, drainServiceTypeOptions } from './action-topic-plumbing-drain-model'

export interface DrainServiceSelectTypeProps {
  result?: TopicPlumbingDrainActionResult
  notesGenerator: NotesGenerator<TopicPlumbingDrainActionResult>
  updateResult: ResultUpdater<TopicPlumbingDrainActionResult>
}

export const DrainServiceSelectType = (props: DrainServiceSelectTypeProps) => {
  const { result, notesGenerator, updateResult } = props
  const { serviceType, jobDescription, customerType } = result ?? {}
  return (
    <>
      <SingleSelectField
        question={notesGenerator.getQuestion('serviceType')}
        options={drainServiceTypeOptions}
        answer={serviceType}
        changeAnswer={(serviceType) => {
          updateResult({
            ...reset(result ?? {}),
            customerType,
            jobDescription,
            serviceType
          })
        }}
      />
      <EuiSpacer size="m" />
      <Callout type="note">
        <EuiText>
          <div>Any water leaving the property from</div>
          <EuiSpacer size="s" />
          <ul>
            <li>
              <b>inside are Sewer pipes</b>, <i>such as sinks, toilets or showers.</i>
            </li>
            <li>
              <b>outside are Stormwater pipes</b>, <i>such as gutters, downpipes or rainwater.</i>
            </li>
          </ul>
        </EuiText>
      </Callout>
      <EuiSpacer />
    </>
  )
}
