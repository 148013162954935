import { EuiDatePicker, EuiLink } from '@elastic/eui'
import { first } from 'lodash-es'
import { Moment } from 'moment-timezone'
import { ReactNode, useState } from 'react'
import { InvoiceFragment } from '../api/generated-types'
import { useAuthenticated } from '../auth/authenticated-context'
import { dateConfig } from '../common/date-config'
import { DescriptionList } from '../common/description-list'
import UserCard from '../users/user-card'
import UserComboBox from '../users/user-combo-box'

interface CreatePaymentHeaderProps {
  invoice: InvoiceFragment
  postDate: Moment
  user: any
  onChangePostDate: (postDate: Moment) => void
  onChangeTechnician: (technician?: any) => void
  onResetPostDate: () => void
  onResetTechnician: () => void
  editable?: boolean
}

export const CreatePaymentHeader = (props: CreatePaymentHeaderProps) => {
  const { invoice, onChangePostDate, onChangeTechnician, onResetPostDate, onResetTechnician, postDate, user } = props
  const customer = invoice.customer
  const editable = props.editable ?? false

  const userFragment = useAuthenticated().userFragment

  const officeInvoicePermission = userFragment.permissions?.officeInvoice === true

  const [modifyUser, setModifyUser] = useState(false)
  const [modifyDate, setModifyDate] = useState(false)

  const toggleModifyUser = () => {
    if (modifyUser) {
      onResetTechnician()
    }
    setModifyUser(!modifyUser)
  }

  const toggleModifyDate = () => {
    if (modifyDate) {
      onResetPostDate()
    }
    setModifyDate(!modifyDate)
  }

  const onChangeUsers = (users: any[]) => {
    const newUser = first(users)
    onChangeTechnician(newUser ?? null)
  }

  const handlePostDateChange = (newDate: Moment) => {
    onChangePostDate(newDate.startOf('day'))
  }

  const users = user ? [user] : []

  const summaryAttributes: Array<{
    title: NonNullable<ReactNode>
    description: NonNullable<ReactNode>
  }> = [
    {
      title: 'Invoice',
      description: (
        <div style={{ paddingTop: '9px' }}>
          <a
            href={`/invoices/${invoice.id}`}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: '#000' }}
            className="secondary-link"
          >
            {invoice.number}
          </a>
        </div>
      )
    },
    {
      title: 'Customer',
      description: (
        <div style={{ paddingTop: '9px' }}>
          <a
            href={`/customers/${customer?.id}`}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: '#000' }}
            className="secondary-link"
          >
            {customer?.mainContact?.detail?.fullName ?? 'No name'}
          </a>
        </div>
      )
    },
    {
      title: (
        <div>
          <span>Technician</span>
          {officeInvoicePermission && editable && (
            <EuiLink onClick={toggleModifyUser} style={{ fontSize: '11px', marginLeft: '6px' }}>
              {modifyUser ? 'Reset' : 'Change'}
            </EuiLink>
          )}
        </div>
      ),
      description: (
        <div style={officeInvoicePermission ? { minWidth: '240px' } : {}}>
          {officeInvoicePermission && editable && modifyUser ? (
            <UserComboBox
              users={users}
              onChangeUsers={onChangeUsers}
              singleSelection={true}
              placeholder="Select technician"
              includeFinance={true}
            />
          ) : (
            <div style={{ paddingTop: '9px' }}>
              <UserCard avatarSize="s" user={user} showContact={false} />
            </div>
          )}
        </div>
      )
    },
    {
      title: (
        <div>
          <span>Post date</span>
          {officeInvoicePermission && editable && (
            <EuiLink onClick={toggleModifyDate} style={{ fontSize: '11px', marginLeft: '6px' }}>
              {modifyDate ? 'Reset' : 'Change'}
            </EuiLink>
          )}
        </div>
      ),
      description: (
        <div style={officeInvoicePermission ? { minWidth: '240px' } : {}}>
          {officeInvoicePermission && editable && modifyDate ? (
            <EuiDatePicker
              showTimeSelect
              selected={postDate}
              onChange={handlePostDateChange}
              dateFormat="ddd D MMM YYYY"
              popoverPlacement="downRight"
            />
          ) : (
            <div style={{ paddingTop: '9px' }}>{postDate.format(dateConfig.fullDate)}</div>
          )}
        </div>
      )
    }
  ]

  return (
    <div style={{ minHeight: '90px' }}>
      <DescriptionList attributes={summaryAttributes} columns={4} />
    </div>
  )
}
