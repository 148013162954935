import React from 'react'
import '../static/css/trade-tag.css'

interface VerticalDividerProps {
  height?: string
  color?: string
}

const VerticalDivider = (props: VerticalDividerProps) => {
  const height = props.height ?? '100%'
  const color = props.color ?? '#D3DAE6'

  return <div style={{ height, borderRight: `1px solid ${color}` }} />
}

export default VerticalDivider
