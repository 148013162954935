// TODO: this needs to be kept in sync with changes made to Inspection Template and Items as new subjects are added
// Would also be preferable to move this to a separate data source at some stage
export enum MediaItemSubject {
  General = 'General',
  AirConditioner = 'Air conditioner',
  CircuitBreakers = 'Circuit breakers',
  ConsumerMains = 'Consumer mains',
  Data = 'Data',
  Dishwasher = 'Dishwasher',
  Drains = 'Drains',
  EarthStake = 'Earth stake',
  FlexiHose = 'Flexi hose',
  Fuse = 'Fuse',
  GasCompliance = 'Gas compliance',
  HotWaterSystem = 'Hot Water System',
  Lighting = 'Lighting',
  Mains = 'Mains',
  MeterWiring = 'Meter wiring',
  MeteringEnclosure = 'Metering enclosure',
  None = 'None',
  OvenAndStove = 'Oven and stove',
  PlumbingPipework = 'Plumbing pipework',
  PowerPoint = 'Power point',
  PressureReliefValve = 'Pressure relief valve',
  RiskAssessment = 'Risk assessment',
  SafetySwitches = 'Safety switches',
  SecuritySystem = 'Security system',
  ShutoffAndStopValves = 'Shut off and stop valves',
  SmokeAlarm = 'Smoke alarm',
  Solar = 'Solar',
  SolarACIsolator = 'Solar AC isolator',
  SolarDCIsolator = 'Solar DC isolator',
  SolarCable = 'Solar cable',
  SolarEarthBond = 'Solar earth bond',
  SolarInverter = 'Solar inverter',
  SolarLabelling = 'Solar labelling',
  SolarPanel = 'Solar panel',
  SolarRoofPenetrations = 'Solar roof penetrations',
  SolarRooftopIsolators = 'Solar rooftop isolators',
  SurgeProtection = 'Surge protection',
  Switchboard = 'Switchboard',
  Tap = 'Tap',
  TemperingValve = 'Tempering valve',
  ToiletCisterns = 'Toilet cisterns',
  ToiletValvesAndSeals = 'Toilet valves and seals',
  WashingMachine = 'Washing machine',
  WaterIsolationValve = 'Water isolation valve',
  WaterPressure = 'Water pressure',
  Wiring = 'Wiring'
}
