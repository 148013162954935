import { includes } from 'lodash-es'
import { NotesGenerator } from '../../helpers/notes-generator'
import { ResultUpdater } from '../../helpers/results-updater'
import { SingleSelectField } from '../../question-fields/single-select-question-field'
import { DrainDiameter, InspectionType, TopicInspectionActionResult } from './action-topic-inspection-model'

export interface InspectionPlumbingProps {
  result?: TopicInspectionActionResult
  notesGenerator: NotesGenerator<TopicInspectionActionResult>
  updateResult: ResultUpdater<TopicInspectionActionResult>
}

export const InspectionPlumbing = (props: InspectionPlumbingProps) => {
  const { result, notesGenerator, updateResult } = props
  const { inspectionType, drainDiameter } = result ?? {}

  const isDrainsInspection = includes([InspectionType.PlumbingAndDrains, InspectionType.Drains], inspectionType)

  return (
    <>
      {isDrainsInspection && (
        <SingleSelectField
          question={notesGenerator.getQuestion('drainDiameter')}
          options={[
            {
              id: DrainDiameter.Between90and150,
              label: 'Between 90mm and 150mm'
            },
            {
              id: DrainDiameter.OutsideOf90and150,
              label: 'Outside of 90mm and 150mm'
            }
          ]}
          answer={drainDiameter}
          changeAnswer={(drainDiameter) => updateResult({ drainDiameter })}
        />
      )}
    </>
  )
}
