import { useQuery } from '@apollo/client'
import { EuiCallOut, EuiLoadingSpinner } from '@elastic/eui'
import { GetInvoiceDocument } from '../api/generated-types'
import InvoiceCard from './invoice-card'

export interface InvoiceSummaryContainerProps {
  invoiceId: string
}

export const InvoiceSummaryContainer = (props: InvoiceSummaryContainerProps) => {
  const { invoiceId } = props
  const { loading, error, data } = useQuery(GetInvoiceDocument, { variables: { id: invoiceId } })

  const invoice = data?.getInvoice ?? null

  const errorView = () => <EuiCallOut color="danger">Encountered error: {error?.message}</EuiCallOut>

  const dataView = () => (
    <>
      {loading && <EuiLoadingSpinner size="l" />}
      {invoice && <InvoiceCard invoice={invoice} />}
    </>
  )

  return <>{error ? errorView() : dataView()}</>
}
