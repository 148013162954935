import React, { useContext } from 'react'
import { useApp } from './app-context'

interface FeatureToggleContextProps {
  children: React.ReactNode
}

export interface IFeatureToggleService {
  workflow: {
    // bouncing ball
    scheduledService: boolean
  }
}

export const FeatureToggleContext = React.createContext<IFeatureToggleService | undefined>(undefined)

export const FeatureToggleProvider = (props: FeatureToggleContextProps) => {
  const { isDev } = useApp()
  const value: IFeatureToggleService = {
    workflow: {
      scheduledService: isDev
    }
  }
  return <FeatureToggleContext.Provider value={value}>{props.children}</FeatureToggleContext.Provider>
}

export const useFeatureToggle = () => useContext(FeatureToggleContext)
